import React, { Component } from 'react'
import classNames from 'classnames'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../../elements/text-label/text-label'
import PropTypes from '../../../../misc/prop-types'

export default class DateSwitcherItem extends Component {
  static propTypes = {
    date: PropTypes.moment.isRequired,
    available: PropTypes.bool.isRequired,
    price: PropTypes.number,
    onSwitchDate: PropTypes.func.isRequired,
    selected: PropTypes.bool
  }

  render () {
    const className = classNames('date-switcher__item', {
      'state--is-selected': this.props.selected && this.props.available,
      'state--is-disabled': !this.props.available,
      'state--is-available': this.props.available
    })

    return (
      <li className={className}>
        <div className='date-tile' onClick={this.props.onSwitchDate}>
          <div className='date-notation'>
            <TextLabel name='date-day-name-short' text={_t.formatMomentDate(this.props.date, 'dayOfTheWeekShort')} />
            <TextLabel name='date-day' text={_t.formatMomentDate(this.props.date, 'dayOfMonth')} />
            <TextLabel name='date-month-name-short' text={_t.formatMomentDate(this.props.date, 'monthShort')} />
          </div>
          {this.props.price >= 0
            ? (
              <div className='date-price'>
                <TextLabel name='from' text={_t.message('journey-result-set.date-switcher.price-from')} />
                <TextLabel name='from-price' text={_t.formatCurrency(this.props.price)} />
              </div>
            ) : null}
        </div>
      </li>
    )
  }
}
