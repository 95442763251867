import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import ComponentContainer from '../../../elements/component-container/component-container'
import Panel from '../../../elements/panel/panel'
import TextLabel from '../../../elements/text-label/text-label'
import Form from '../../../elements/form/form'
import Input from '../../../elements/input/input'
import Label from '../../../elements/label/label'
import './waive-fees.scss'

export default class WaiveFees extends Component {
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    onToggleWaiveFees: PropTypes.func.isRequired
  }

  render () {
    return (
      <ComponentContainer name='waive-fees'>
        <Panel
          header={
            <Panel.Header
              name='waive-fees'
              title={_t.message('aftersales.waive-fees.title')}
              description={_t.message('aftersales.waive-fees.description')}
            />
          }
        >
          <TextLabel
            name='information'
            text={_t.message('aftersales.waive-fees.information')}
          />
          <Form>
            <fieldset>
              <Form.InputGroup name='waive-fees'>
                <Form.InputWrapper name='waive-fees'>
                  <Input.Checkbox
                    name='waive-fees'
                    id='waive-fees'
                    checked={this.props.selected}
                    onChange={this.props.onToggleWaiveFees}
                    disabled={this.props.disabled}
                  >
                    <Label
                      name='waive-fees'
                      htmlFor='waive-fees'
                      text={_t.message('aftersales.waive-fees.label')}
                    />
                  </Input.Checkbox>
                </Form.InputWrapper>
              </Form.InputGroup>
            </fieldset>
          </Form>
        </Panel>
      </ComponentContainer>
    )
  }
}
