import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../elements/text-label/text-label'
import Form from '../../../elements/form/form'
import Modal from '../../../elements/modal/modal'
import PassengerSubForm from '../../../containers/tickets/passenger-details-modal/passenger-sub-form'
import classNames from 'classnames'

export const PASSENGER_DETAILS_MODAL = 'passenger-details'

export default class PassengerDetailsModal extends Component {
  static propTypes = {
    form: PropTypes.shape({
      subFormNames: PropTypes.arrayOf(PropTypes.string).isRequired,
      valid: PropTypes.bool
    }),
    handleSave: PropTypes.func.isRequired,
    isRebookFlow: PropTypes.bool,
    isReservationsFlow: PropTypes.bool
  }

  render () {
    return (
      <Modal
        name={PASSENGER_DETAILS_MODAL}
        header={<Modal.Header title={_t.message('product-search.passenger-details.title')} />}
        footer={<Modal.FooterSingle
          disabled={this.props.isRebookFlow || !this.props.form.valid}
          onClick={this.props.handleSave}
          hotkey='proceed'
        >
          <TextLabel text={_t.message('product-search.passenger-details.save')} />
        </Modal.FooterSingle>}
        className={classNames({'scroll': this.props.form.subFormNames.length > 6})}
      >
        <Form formContext='product-search.passenger-details'>
          <Form.InputGroup name='passenger-details'>
            <Form.InputWrapper name='passenger'>
              <TextLabel
                text={_t.message('product-search.passenger-details.passenger')}
                name='passenger'
              />
            </Form.InputWrapper>
            <Form.InputWrapper name='wheelchair'>
              <TextLabel
                text={_t.message('product-search.passenger-details.wheelchair')}
                name='wheelchair'
              />
            </Form.InputWrapper>
            {this.props.isReservationsFlow || this.props.isRebookFlow
              ? (
                <Form.InputWrapper name='seat-only'>
                  <TextLabel
                    text={_t.message('product-search.passenger-details.seat-only')}
                    name='seat-only'
                  />
                </Form.InputWrapper>
              ) : null}
            <Form.InputWrapper name='discount-card'>
              <TextLabel
                text={_t.message('product-search.passenger-details.discount-card')}
                name='discount-card'
              />
            </Form.InputWrapper>
            <Form.InputWrapper name='discount-card-number'>
              <TextLabel
                text={_t.message('product-search.passenger-details.discount-card-number')}
                name='discount-card-number'
              />
            </Form.InputWrapper>
          </Form.InputGroup>
          {this.props.form.subFormNames.map(
            (subFormName, index) => (
              <PassengerSubForm
                key={subFormName}
                number={index + 1}
                formName={subFormName}
                isRebookFlow={this.props.isRebookFlow}
                isReservationsFlow={this.props.isReservationsFlow}
              />
            )
          )}
        </Form>
      </Modal>
    )
  }
}
