import {
  UI_ACCORDION_GROUP_OPEN_ITEM,
  UI_ACCORDION_GROUP_CLOSE_ITEM
} from 's3p-js-lib/src/redux/actions/types'

export default (state = {}, action) => {
  switch (action.type) {
    case UI_ACCORDION_GROUP_OPEN_ITEM:
      return {...state, [action.name]: {...state[action.name], openItem: action.itemName}}
    case UI_ACCORDION_GROUP_CLOSE_ITEM:
      return {...state, [action.name]: {...state[action.name], openItem: ''}}
    default:
      return state
  }
}
