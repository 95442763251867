import {
  USER_INPUT_FORM_LOAD_VALUES,
  USER_INPUT_FORM_CHANGE_FIELD
} from 's3p-js-lib/src/redux/actions/types'
import { FORM_EDIT_SHORTCUT } from '../../../../constants'
import { USER_INPUT_SELECT_PRODUCT } from '../../../../redux/actions/types'
import { disabledOriginDestination } from '../../../../forms/product-search'

const emptyOriginDestinationState = state => {
  return {
    ...state,
    fields: {
      ...state.fields,
      origin: {
        ...state.fields.origin,
        value: null
      },
      destination: {
        ...state.fields.destination,
        value: null
      }
    }
  }
}

export default (state = {}, action) => {
  switch (action.type) {
    case USER_INPUT_FORM_CHANGE_FIELD:
      if (action.formName === FORM_EDIT_SHORTCUT) {
        if (action.fieldName === 'productFamily' &&
          action.value != null &&
          disabledOriginDestination(action.value.attributes)
        ) {
          return emptyOriginDestinationState(state)
        } else if (action.fieldName !== 'productFilter') {
          return state.fields && state.fields.productFilter
            ? {
              ...state,
              fields: {
                ...state.fields,
                productFilter: {
                  ...state.fields.productFilter,
                  value: ''
                }
              }
            } : null
        }
      }
      return state
    case USER_INPUT_FORM_LOAD_VALUES:
      if (action.formName === FORM_EDIT_SHORTCUT &&
        state.fields &&
        action.values.productFamily &&
        disabledOriginDestination(action.values.productFamily.attributes)
      ) {
        return emptyOriginDestinationState(state)
      }
      return state
    case USER_INPUT_SELECT_PRODUCT: {
      const productId = action.productId === state.fields.product.value ? null : action.productId

      return {
        ...state,
        fields: {
          ...state.fields,
          product: {
            ...state.fields.product,
            value: productId
          }
        }
      }
    }
    default:
      return state
  }
}
