import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TileSet from '../../../elements/tile-set/tile-set'
import Carriage from '../../../containers/base/seat-selector/carriage-selector/carriage'

export default class CarriageSelector extends Component {
  static propTypes = {
    carriages: PropTypes.arrayOf(PropTypes.shape({
      carriageNumber: PropTypes.string.isRequired
    })),
    aftersales: PropTypes.bool
  }

  render () {
    return (
      <div className='carriage-selector-container'>
        <div className='carriage-selector'>
          <TileSet>
            {this.props.carriages.map(carriage => (
              <Carriage key={carriage.carriageNumber} carriageNumber={carriage.carriageNumber} aftersales={this.props.aftersales} />
            ))}
          </TileSet>
        </div>
      </div>
    )
  }
}
