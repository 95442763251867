import React, { Component } from 'react'
import GenericLayout from '../generic-layout'
import MainMenu from '../../containers/base/main-menu/main-menu'
import CancellationVoid from '../../containers/aftersales/cancellation/cancellation-void'
import ProgressNavigation from '../../components/aftersales/cancellation/progress-navigation'
import Operation from '../../containers/aftersales/operation/operation'

export default class CancellationVoidLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <MainMenu />
        <Operation
          labelTitle='aftersales.cancellation.header.title'
          labelInformation='aftersales.cancellation.header.information'
        />
        <CancellationVoid />
        <ProgressNavigation />
      </GenericLayout>
    )
  }
}
