import loadingReducer from 's3p-js-lib/src/redux/lib/loading-reducer'

import {
  MACHINE_PRINT_MAGNETIC_TICKET_REQUEST,
  MACHINE_PRINT_MAGNETIC_TICKET_SUCCESS,
  MACHINE_PRINT_MAGNETIC_TICKET_FAILURE,
  MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_REQUEST,
  MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_SUCCESS,
  MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_FAILURE,
  MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_REQUEST,
  MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_SUCCESS,
  MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_FAILURE,
  MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_REQUEST,
  MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_SUCCESS,
  MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_FAILURE,
  MACHINE_VOID_MAGNETIC_TICKET_REQUEST,
  MACHINE_VOID_MAGNETIC_TICKET_SUCCESS,
  MACHINE_VOID_MAGNETIC_TICKET_FAILURE,
  MACHINE_READ_MAGNETIC_TICKET_REQUEST,
  MACHINE_READ_MAGNETIC_TICKET_SUCCESS,
  MACHINE_READ_MAGNETIC_TICKET_FAILURE,
  MACHINE_EJECT_MAGNETIC_TICKET_REQUEST,
  MACHINE_EJECT_MAGNETIC_TICKET_SUCCESS,
  MACHINE_EJECT_MAGNETIC_TICKET_FAILURE,
  MACHINE_DISPLAY_LINES_REQUEST,
  MACHINE_DISPLAY_LINES_SUCCESS,
  MACHINE_DISPLAY_LINES_FAILURE,
  MACHINE_CLEAR_DISPLAY_REQUEST,
  MACHINE_CLEAR_DISPLAY_SUCCESS,
  MACHINE_CLEAR_DISPLAY_FAILURE,
  MACHINE_TEST_DISPLAY_REQUEST,
  MACHINE_TEST_DISPLAY_SUCCESS,
  MACHINE_TEST_DISPLAY_FAILURE,
  MACHINE_RECEIPT_PRINT_REQUEST,
  MACHINE_RECEIPT_PRINT_SUCCESS,
  MACHINE_RECEIPT_PRINT_FAILURE,
  MACHINE_TEST_RECEIPT_PRINT_REQUEST,
  MACHINE_TEST_RECEIPT_PRINT_SUCCESS,
  MACHINE_TEST_RECEIPT_PRINT_FAILURE,
  MACHINE_GET_STATUS_DEVICES_REQUEST,
  MACHINE_GET_STATUS_DEVICES_SUCCESS,
  MACHINE_GET_STATUS_DEVICES_FAILURE,
  MACHINE_READ_BARCODE_REQUEST,
  MACHINE_READ_BARCODE_SUCCESS,
  MACHINE_READ_BARCODE_FAILURE,
  MACHINE_START_PIN_PAYMENT_REQUEST,
  MACHINE_START_PIN_PAYMENT_SUCCESS,
  MACHINE_START_PIN_PAYMENT_FAILURE,
  MACHINE_COMPLETE_PIN_PAYMENT_REQUEST,
  MACHINE_COMPLETE_PIN_PAYMENT_SUCCESS,
  MACHINE_COMPLETE_PIN_PAYMENT_FAILURE,
  MACHINE_CANCEL_PIN_PAYMENT_REQUEST,
  MACHINE_CANCEL_PIN_PAYMENT_SUCCESS,
  MACHINE_CANCEL_PIN_PAYMENT_FAILURE,
  MACHINE_REFUND_PIN_PAYMENT_REQUEST,
  MACHINE_REFUND_PIN_PAYMENT_SUCCESS,
  MACHINE_REFUND_PIN_PAYMENT_FAILURE,
  MACHINE_SET_DARKNESS_REQUEST,
  MACHINE_SET_DARKNESS_SUCCESS,
  MACHINE_SET_DARKNESS_FAILURE,
  MACHINE_LEAP_READ_REQUEST,
  MACHINE_LEAP_READ_SUCCESS,
  MACHINE_LEAP_READ_FAILURE,
  MACHINE_LEAP_TOP_UP_REQUEST,
  MACHINE_LEAP_TOP_UP_SUCCESS,
  MACHINE_LEAP_TOP_UP_FAILURE,
  MACHINE_LEAP_ADD_PRODUCT_REQUEST,
  MACHINE_LEAP_ADD_PRODUCT_SUCCESS,
  MACHINE_LEAP_ADD_PRODUCT_FAILURE,
  MACHINE_LEAP_APPLY_CD_REQUEST,
  MACHINE_LEAP_APPLY_CD_SUCCESS,
  MACHINE_LEAP_APPLY_CD_FAILURE,
  MACHINE_LEAP_REVERSE_REQUEST,
  MACHINE_LEAP_REVERSE_SUCCESS,
  MACHINE_LEAP_REVERSE_FAILURE,
  MACHINE_LEAP_STATUS_REQUEST,
  MACHINE_LEAP_STATUS_SUCCESS,
  MACHINE_LEAP_STATUS_FAILURE
} from '../../actions/types'

export default {
  printMagneticTicket: loadingReducer(
    MACHINE_PRINT_MAGNETIC_TICKET_REQUEST,
    MACHINE_PRINT_MAGNETIC_TICKET_SUCCESS,
    MACHINE_PRINT_MAGNETIC_TICKET_FAILURE
  ),
  readMagneticTicket: loadingReducer(
    MACHINE_READ_MAGNETIC_TICKET_REQUEST,
    MACHINE_READ_MAGNETIC_TICKET_SUCCESS,
    MACHINE_READ_MAGNETIC_TICKET_FAILURE
  ),
  voidMagneticTicket: loadingReducer(
    MACHINE_VOID_MAGNETIC_TICKET_REQUEST,
    MACHINE_VOID_MAGNETIC_TICKET_SUCCESS,
    MACHINE_VOID_MAGNETIC_TICKET_FAILURE
  ),
  ejectMagneticTicket: loadingReducer(
    MACHINE_EJECT_MAGNETIC_TICKET_REQUEST,
    MACHINE_EJECT_MAGNETIC_TICKET_SUCCESS,
    MACHINE_EJECT_MAGNETIC_TICKET_FAILURE
  ),
  updateMagneticTicketTemplate: loadingReducer(
    MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_REQUEST,
    MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_SUCCESS,
    MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_FAILURE
  ),
  getMagneticTicketTemplates: loadingReducer(
    MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_REQUEST,
    MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_SUCCESS,
    MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_FAILURE
  ),
  removeMagneticTicketTemplate: loadingReducer(
    MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_REQUEST,
    MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_SUCCESS,
    MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_FAILURE
  ),
  sendMachineDisplayLines: loadingReducer(
    MACHINE_DISPLAY_LINES_REQUEST,
    MACHINE_DISPLAY_LINES_SUCCESS,
    MACHINE_DISPLAY_LINES_FAILURE
  ),
  clearMachineDisplay: loadingReducer(
    MACHINE_CLEAR_DISPLAY_REQUEST,
    MACHINE_CLEAR_DISPLAY_SUCCESS,
    MACHINE_CLEAR_DISPLAY_FAILURE
  ),
  testMachineDisplay: loadingReducer(
    MACHINE_TEST_DISPLAY_REQUEST,
    MACHINE_TEST_DISPLAY_SUCCESS,
    MACHINE_TEST_DISPLAY_FAILURE
  ),
  receiptMachinePrint: loadingReducer(
    MACHINE_RECEIPT_PRINT_REQUEST,
    MACHINE_RECEIPT_PRINT_SUCCESS,
    MACHINE_RECEIPT_PRINT_FAILURE
  ),
  testReceiptMachinePrint: loadingReducer(
    MACHINE_TEST_RECEIPT_PRINT_REQUEST,
    MACHINE_TEST_RECEIPT_PRINT_SUCCESS,
    MACHINE_TEST_RECEIPT_PRINT_FAILURE
  ),
  getMachineStatusDevices: loadingReducer(
    MACHINE_GET_STATUS_DEVICES_REQUEST,
    MACHINE_GET_STATUS_DEVICES_SUCCESS,
    MACHINE_GET_STATUS_DEVICES_FAILURE
  ),
  readBarcode: loadingReducer(
    MACHINE_READ_BARCODE_REQUEST,
    MACHINE_READ_BARCODE_SUCCESS,
    MACHINE_READ_BARCODE_FAILURE
  ),
  startPinPayment: loadingReducer(
    MACHINE_START_PIN_PAYMENT_REQUEST,
    MACHINE_START_PIN_PAYMENT_SUCCESS,
    MACHINE_START_PIN_PAYMENT_FAILURE
  ),
  completePinPayment: loadingReducer(
    MACHINE_COMPLETE_PIN_PAYMENT_REQUEST,
    MACHINE_COMPLETE_PIN_PAYMENT_SUCCESS,
    MACHINE_COMPLETE_PIN_PAYMENT_FAILURE
  ),
  cancelPinPayment: loadingReducer(
    MACHINE_CANCEL_PIN_PAYMENT_REQUEST,
    MACHINE_CANCEL_PIN_PAYMENT_SUCCESS,
    MACHINE_CANCEL_PIN_PAYMENT_FAILURE
  ),
  refundPinPayment: loadingReducer(
    MACHINE_REFUND_PIN_PAYMENT_REQUEST,
    MACHINE_REFUND_PIN_PAYMENT_SUCCESS,
    MACHINE_REFUND_PIN_PAYMENT_FAILURE
  ),
  setDarkness: loadingReducer(
    MACHINE_SET_DARKNESS_REQUEST,
    MACHINE_SET_DARKNESS_SUCCESS,
    MACHINE_SET_DARKNESS_FAILURE
  ),
  readLeap: loadingReducer(
    MACHINE_LEAP_READ_REQUEST,
    MACHINE_LEAP_READ_SUCCESS,
    MACHINE_LEAP_READ_FAILURE
  ),
  topUpLeap: loadingReducer(
    MACHINE_LEAP_TOP_UP_REQUEST,
    MACHINE_LEAP_TOP_UP_SUCCESS,
    MACHINE_LEAP_TOP_UP_FAILURE
  ),
  addProductleap: loadingReducer(
    MACHINE_LEAP_ADD_PRODUCT_REQUEST,
    MACHINE_LEAP_ADD_PRODUCT_SUCCESS,
    MACHINE_LEAP_ADD_PRODUCT_FAILURE
  ),
  applyCdLeap: loadingReducer(
    MACHINE_LEAP_APPLY_CD_REQUEST,
    MACHINE_LEAP_APPLY_CD_SUCCESS,
    MACHINE_LEAP_APPLY_CD_FAILURE
  ),
  reverseLeap: loadingReducer(
    MACHINE_LEAP_REVERSE_REQUEST,
    MACHINE_LEAP_REVERSE_SUCCESS,
    MACHINE_LEAP_REVERSE_FAILURE
  ),
  statusLeap: loadingReducer(
    MACHINE_LEAP_STATUS_REQUEST,
    MACHINE_LEAP_STATUS_SUCCESS,
    MACHINE_LEAP_STATUS_FAILURE
  )
}
