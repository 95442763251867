import {
  API_V2_UPDATE_PAYMENTS_REQUEST,
  API_V2_UPDATE_PAYMENTS_SUCCESS,
  API_V2_UPDATE_PAYMENTS_FAILURE
} from '../../../types-v2'
import Client from '../../../../../../../src/api/client'
import ensureToken from '../../../../../api/ensure-token'
import ResponseCodes from 's3p-js-lib/src/api/response-codes'

const updatePaymentsRequest = (payments, bookingNumber) => ({type: API_V2_UPDATE_PAYMENTS_REQUEST, payments, bookingNumber})
const updatePaymentsSuccess = response => ({type: API_V2_UPDATE_PAYMENTS_SUCCESS, response})
const updatePaymentsFailure = error => ({type: API_V2_UPDATE_PAYMENTS_FAILURE, error})

export const updatePayments = (payments, bookingNumber) => ensureToken(async (token, dispatch) => {
  try {
    dispatch(updatePaymentsRequest(payments, bookingNumber))
    const response = await Client.updatePayments(token, payments, bookingNumber)
    dispatch(updatePaymentsSuccess(response))
  } catch (error) {
    dispatch(updatePaymentsFailure(error))
    if (ResponseCodes.isSystemError(error)) {
      throw error
    }
    return false
  }
})
