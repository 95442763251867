import {
  API_AFTERSALES_REVERT_SUCCESS,
  API_AFTERSALES_CONFIRM_SUCCESS,
  API_CANCELLATION_INITIATE_REQUEST,
  CLEAR_STATE
} from 's3p-js-lib/src/redux/actions/types'
import {
  UI_VOID_TICKET_START,
  UI_VOID_TICKET_NUMBER,
  UI_VOID_TICKET_SUCCESS,
  UI_VOID_TICKET_STOP,
  UI_VOID_TICKET_ERROR
} from '../../../actions/types'
import { CONTAINERS_AFTERSALES_VOID_TICKETS } from '../../../../constants'

const initialState = {
  isRunning: false,
  totalTickets: 0,
  ticketNumber: 0,
  success: false,
  voidedTicketCounters: [],
  errorMessage: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UI_VOID_TICKET_START:
      return {
        ...state,
        isRunning: true,
        totalTickets: action.total,
        voidedTicketCounters: action.voidedTicketCounters || [],
        errorMessage: null
      }
    case UI_VOID_TICKET_NUMBER:
      return {...state, ticketNumber: action.number}
    case UI_VOID_TICKET_SUCCESS:
      return {...state, voidedTicketCounters: state.voidedTicketCounters.concat([action.counter])}
    case UI_VOID_TICKET_STOP:
      return {...state, isRunning: false, success: state.voidedTicketCounters.length === state.totalTickets}
    case UI_VOID_TICKET_ERROR:
      return {...state, errorMessage: action.errorMessage}
    case API_AFTERSALES_CONFIRM_SUCCESS:
    case API_AFTERSALES_REVERT_SUCCESS:
    case API_CANCELLATION_INITIATE_REQUEST:
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_AFTERSALES_VOID_TICKETS) ? initialState : state
    default:
      return state
  }
}
