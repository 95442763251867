import {
  USER_INPUT_SEAT_SELECTOR_SELECT_PASSENGER,
  USER_INPUT_SEAT_SELECTOR_SELECT_CARRIAGE,
  USER_INPUT_SEAT_SELECTOR_SELECT_SEAT,
  USER_INPUT_SEAT_SELECTOR_DESTROY,
  USER_INPUT_SEAT_SELECTOR_SELECT_SEGMENT,
  CLEAR_STATE
} from '../../../actions/types'

export const initialState = {
  selectedPassengerId: null,
  selectedCarriageNumber: null,
  selectedSegmentId: null,
  selectedSeats: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_INPUT_SEAT_SELECTOR_SELECT_PASSENGER:
      return {...state, selectedPassengerId: action.passengerId}
    case USER_INPUT_SEAT_SELECTOR_SELECT_CARRIAGE:
      return {...state, selectedCarriageNumber: action.carriageNumber}
    case USER_INPUT_SEAT_SELECTOR_SELECT_SEAT:
      return {
        ...state,
        selectedSeats: [
          ...state.selectedSeats.filter(
            selectedSeat => selectedSeat.passengerId !== action.passengerId
          ),
          {
            passengerId: action.passengerId,
            carriageNumber: action.carriageNumber,
            seatNumber: action.seatNumber
          }
        ]
      }
    case USER_INPUT_SEAT_SELECTOR_SELECT_SEGMENT:
      return {...state, selectedSegmentId: action.segmentId}
    case USER_INPUT_SEAT_SELECTOR_DESTROY:
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes('userInput.base.seatSelector') ? initialState : state
    default:
      return state
  }
}
