import { createSelector } from 'reselect'
import { TRAVEL_DIRECTION_OUTBOUND, TRAVEL_DIRECTION_INBOUND } from '../../../../constants'
import { camelCaseKeys, mapDirectionToTravelDirection } from '../../../../misc/utils'
import { toUtcDateMoment } from '../../../../misc/date'
import { journeySearchSelector } from './journey-search'

const baseTravelsSelector = direction => {
  const travelDirection = mapDirectionToTravelDirection(direction)
  const journeySearchParameter = direction === TRAVEL_DIRECTION_OUTBOUND ? 'departure_date' : 'return_date'

  return createSelector(
    [
      state => state.api.orientation.cheapestPrice,
      journeySearchSelector
    ],
    (cheapestPrice, journeySearch) => cheapestPrice ? cheapestPrice.travels.reduce(
      (travels, travel) => {
        if (travel.direction === travelDirection) {
          const date = toUtcDateMoment(travel.date)

          travels.push({
            ...camelCaseKeys(travel),
            disabled: date.isBefore(toUtcDateMoment()),
            active: journeySearch[journeySearchParameter] &&
              date.unix() === journeySearch[journeySearchParameter].unix(),
            date,
            direction,
            currency: cheapestPrice.currency
          })
        }

        return travels
      },
      []
    ) : []
  )
}

export const outboundTravelsSelector = baseTravelsSelector(TRAVEL_DIRECTION_OUTBOUND)

export const inboundTravelsSelector = baseTravelsSelector(TRAVEL_DIRECTION_INBOUND)
