import {
  API_V2_ADD_ADDITIONAL_DETAILS_REQUEST,
  API_V2_ADD_ADDITIONAL_DETAILS_SUCCESS,
  API_V2_ADD_ADDITIONAL_DETAILS_FAILURE,
  API_V2_UPDATE_ADDITIONAL_DETAILS_REQUEST,
  API_V2_UPDATE_ADDITIONAL_DETAILS_SUCCESS,
  API_V2_UPDATE_ADDITIONAL_DETAILS_FAILURE
} from '../../types-v2'
import Client from '../../../../../../src/api/client'
import ensureToken from '../../../../api/ensure-token'

const addAdditionalDetailsBookingRequest = (bookingNumber, additionalDetails) => ({
  type: API_V2_ADD_ADDITIONAL_DETAILS_REQUEST,
  bookingNumber,
  additionalDetails
})
const addAdditionalDetailsBookingSuccess = response => ({
  type: API_V2_ADD_ADDITIONAL_DETAILS_SUCCESS,
  response
})
const addAdditionalDetailsBookingFailure = error => ({type: API_V2_ADD_ADDITIONAL_DETAILS_FAILURE, error})

const updateAdditionalDetailsBookingRequest = (bookingNumber, additionalDetails) => ({
  type: API_V2_UPDATE_ADDITIONAL_DETAILS_REQUEST,
  bookingNumber,
  additionalDetails
})

const updateAdditionalDetailsBookingSuccess = response => ({
  type: API_V2_UPDATE_ADDITIONAL_DETAILS_SUCCESS,
  response
})

const updateAdditionalDetailsBookingFailure = error => ({
  type: API_V2_UPDATE_ADDITIONAL_DETAILS_FAILURE,
  error
})

export const addAdditionalDetails = (bookingNumber, additionalDetails) => ensureToken(async (token, dispatch) => {
  try {
    dispatch(addAdditionalDetailsBookingRequest(bookingNumber, additionalDetails))
    const response = await Client.addAdditionalDetails(token, bookingNumber, additionalDetails)
    dispatch(addAdditionalDetailsBookingSuccess(response))
  } catch (error) {
    dispatch(addAdditionalDetailsBookingFailure(error))
    throw error
  }
})

export const updateAdditionalDetails = (bookingNumber, additionalDetails) => ensureToken(async (token, dispatch) => {
  try {
    dispatch(updateAdditionalDetailsBookingRequest(bookingNumber, additionalDetails))
    const response = await Client.updateAdditionalDetails(token, bookingNumber, additionalDetails)
    dispatch(updateAdditionalDetailsBookingSuccess(response))
  } catch (error) {
    dispatch(updateAdditionalDetailsBookingFailure(error))
    throw error
  }
})
