import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../elements/text-label/text-label'
import Button from '../../../elements/button/button'
import Modal from '../../../elements/modal/modal'
import RetryReceiptButton from '../../../containers/base/finalize-booking/footer/retry-receipt'

const NEXT_SALE_ID = 'next-sale'
const CHANGE_FULFILLMENT_ID = 'change-fulfillment'
const RETRY_PRINT_ID = 'retry-print'
const CONTINUE_PRINT_ID = 'continue-print'

const onContinuePrintingValidator = (props, ...args) => {
  if (props.showContinuePrinting) {
    return PropTypes.func.isRequired(props, ...args)
  }
}

const onRetryPrintingValidator = (props, ...args) => {
  if (props.showRetryPrinting) {
    return PropTypes.func.isRequired(props, ...args)
  }
}

export default class FinalizeBookingFooter extends Component {
  static propTypes = {
    disableNextSale: PropTypes.bool.isRequired,
    aftersales: PropTypes.bool.isRequired,
    onNextSale: PropTypes.func.isRequired,
    onChangeFulfillment: PropTypes.func.isRequired,
    onContinuePrinting: onContinuePrintingValidator,
    showContinuePrinting: PropTypes.bool,
    showRetryReceiptPrinter: PropTypes.bool,
    onRetryPrinting: onRetryPrintingValidator,
    showRetryPrinting: PropTypes.bool,
    allTicketsPrinted: PropTypes.bool,
    hasPrintErrors: PropTypes.bool,
    isOfflineTicketPrinter: PropTypes.bool,
    changeFulfillmentAvailable: PropTypes.bool,
    showChangeFulfillment: PropTypes.bool,
    loadingNextSale: PropTypes.bool,
    isOfflineReceiptPrinter: PropTypes.bool,
    isPrinting: PropTypes.bool
  }

  static defaultProps = {
    aftersales: false,
    showRetryReceiptPrinter: true,
    showChangeFulfillment: true
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!nextProps.disableNextSale && nextProps.hasPrintErrors) {
      const element = document.getElementById(CHANGE_FULFILLMENT_ID) || document.getElementById(CONTINUE_PRINT_ID)
      element && setTimeout(() => element.focus(), 200)
    } else if (this.props.disableNextSale && !nextProps.disableNextSale) {
      const element = document.getElementById(NEXT_SALE_ID)
      element && setTimeout(() => element.focus(), 200)
    }
  }

  render () {
    return (
      <Modal.CustomFooter>
        {this.props.changeFulfillmentAvailable && this.props.showChangeFulfillment
          ? (
            <Button
              name='change-fulfillment'
              className='tertiary'
              onClick={this.props.onChangeFulfillment}
              disabled={this.props.disableNextSale}
              id={CHANGE_FULFILLMENT_ID}
              hotkey='cancel'
              tabIndex={-1}
            >
              <TextLabel name='change-fulfillment' text={_t.message('finalize-booking-modal.change-fulfillment')} />
            </Button>
          ) : null}
        {this.props.showRetryReceiptPrinter ? <RetryReceiptButton disabled={this.props.isPrinting} /> : null}
        {this.props.showContinuePrinting && (!this.props.allTicketsPrinted || this.props.hasPrintErrors)
          ? (
            <Button
              name='continue-printing'
              className='secondary'
              disabled={this.props.disableNextSale || this.props.isOfflineTicketPrinter || this.props.isOfflineReceiptPrinter}
              onClick={this.props.onContinuePrinting}
              hotkey='refresh'
              id={CONTINUE_PRINT_ID}
            >
              <TextLabel name='continue-printing' text={_t.message('finalize-booking-modal.continue-printing')} />
            </Button>
          ) : null}
        {this.props.showRetryPrinting
          ? (
            <Button
              name='retry-printing'
              className='secondary'
              disabled={this.props.disableNextSale}
              onClick={this.props.onRetryPrinting}
              hotkey='refresh'
              id={RETRY_PRINT_ID}
            >
              <TextLabel name='retry-printing' text={_t.message('finalize-booking-modal.retry-printing')} />
            </Button>
          ) : null}
        <Button
          id={NEXT_SALE_ID}
          name='finish-booking'
          className='primary'
          onClick={this.props.onNextSale}
          disabled={this.props.disableNextSale}
          loading={this.props.loadingNextSale}
          hotkey='proceed'
        >
          <TextLabel name='finish-booking' text={_t.message(
            this.props.aftersales ? 'finalize-booking-modal.booking-details' : 'finalize-booking-modal.next-sale'
          )} />
        </Button>
      </Modal.CustomFooter>
    )
  }
}
