import {
  API_REQUEST_REFUND_REQUEST,
  API_REQUEST_REFUND_SUCCESS,
  API_REQUEST_REFUND_FAILURE
} from '../../types'
import Client from '../../../../../../src/api/client'
import ResponseCodes from '../../../../api/response-codes'
import ensureToken from '../../../../api/ensure-token'
import { overviewBookingSelector, bookingNumberSelector } from '../../../selectors/api/booking/booking'
import { API_REQUEST_REFUND_TYPE_VOUCHER, API_REQUEST_REFUND_TYPE_PSP } from '../../../../constants'

const requestRefundRequest = refundType => ({type: API_REQUEST_REFUND_REQUEST, refundType})
const requestRefundRequestSuccess = response => ({type: API_REQUEST_REFUND_SUCCESS, response})
const requestRefundRequestFailure = error => ({type: API_REQUEST_REFUND_FAILURE, error})

const requestRefund = type => ensureToken(async function (token, dispatch, getState) {
  try {
    dispatch(requestRefundRequest(type))
    const bookingNumber = bookingNumberSelector(overviewBookingSelector)(getState())
    const response = await Client.requestRefund(token, bookingNumber, type)

    dispatch(requestRefundRequestSuccess(response))
  } catch (error) {
    dispatch(requestRefundRequestFailure(error))
    if (ResponseCodes.isSystemError(error)) {
      throw error
    }
    return false
  }
})

// eslint-disable-next-line @babel/no-invalid-this
export const requestRefundPsp = requestRefund.bind(this, API_REQUEST_REFUND_TYPE_PSP)

// eslint-disable-next-line @babel/no-invalid-this
export const requestRefundVoucher = requestRefund.bind(this, API_REQUEST_REFUND_TYPE_VOUCHER)
