import { createSelector } from 'reselect'

export const deviceMessagesSelector = createSelector(
  [state => state.machine.status.data],
  statusDevices => {
    return statusDevices && statusDevices.length
      ? statusDevices.reduce((list, statusDevice) => {
        list.push(statusDevice)
        return list
      }, [])
      : []
  }
)

export const hasDeviceMessages = createSelector(
  [deviceMessagesSelector, state => state.machine.status.failure],
  (deviceMessages, hasGeneralFailure) => hasGeneralFailure || deviceMessages.some(device => device.messages && device.messages.length > 0)
)

export const deviceIdsWithErrorsSelector = createSelector(
  [deviceMessagesSelector],
  deviceMessages => {
    return deviceMessages.filter(device => {
      return device.messages.length ? device.messages.some(message => message.severity === 'error') : false
    }).map(device => device.id)
  }
)
