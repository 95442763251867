import { connect } from 'react-redux'
import { completedOrProvisionalOrOriginalOrOverviewBookingSelector } from '../../../redux/selectors/api/booking/booking'
import { travelSelector } from 's3p-js-lib/src/redux/selectors/api/booking/travel'
import {
  outboundTariffSegmentsSelector,
  inboundTariffSegmentsSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/tariff-segments'
import JourneySummary from '../../../components/base/booking-summary/journey-summary'

const _outboundTariffSegmentsSelector = outboundTariffSegmentsSelector(completedOrProvisionalOrOriginalOrOverviewBookingSelector)
const _inboundTariffSegmentsSelector = inboundTariffSegmentsSelector(completedOrProvisionalOrOriginalOrOverviewBookingSelector)
const _outboundTravelSelector = travelSelector(_outboundTariffSegmentsSelector)
const _inboundTravelSelector = travelSelector(_inboundTariffSegmentsSelector)

const mapStateToProps = state => ({
  outboundTravel: _outboundTravelSelector(state),
  inboundTravel: _inboundTravelSelector(state)
})

export default connect(mapStateToProps)(JourneySummary)
