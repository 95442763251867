import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import './incremental-input-wrapper.scss'

class IncrementalInputWrapper extends Component {
  static propTypes = {
    name: PropTypes.string,
    className: PropTypes.string
  }

  render () {
    const {className, name, children, ...rest} = this.props

    const newClassName = classNames(
      'incremental-input-wrapper',
      name ? `incremental-input-wrapper-${name}` : '',
      className
    )

    return (
      <div {...rest} className={newClassName}>
        {children}
      </div>
    )
  }
}

const Input = ({children}) => <div className='incremental-input-wrapper__input'>{children}</div>
const Modifier = ({children}) => <div className='incremental-input-wrapper__modifier'>{children}</div>

IncrementalInputWrapper.Input = Input
IncrementalInputWrapper.Modifier = Modifier

export default IncrementalInputWrapper
