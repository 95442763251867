import { createSelector } from 'reselect'
import { camelCaseKeys } from '../../../../misc/utils'
import { fulfillmentMethodSelector } from './booking'

export const availableFulfillmentMethodsSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking && booking.fulfillment_methods.map(camelCaseKeys)
)

export const fulfillmentMethodsSelector = bookingSelector => createSelector(
  [
    bookingSelector,
    fulfillmentMethodSelector(bookingSelector)
  ],
  (booking, selectedMethod) => booking
    ? booking.fulfillment_methods.map(fulfillmentMethod => ({
      ...camelCaseKeys(fulfillmentMethod),
      selected: Boolean(selectedMethod && selectedMethod.code === fulfillmentMethod.code)
    }))
    : []
)
