import { connect } from 'react-redux'
import { bookingFlowSelector } from '../../redux/selectors/containers/base/booking-flow'
import {createProvisionalBooking, replaceProvisionalBooking} from 's3p-js-lib/src/redux/actions/api/booking/create'
import { routerActions } from 'react-router-redux'
import { currentPathnameSelector } from '../../redux/selectors/containers/base/routing'
import _t from 's3p-js-lib/src/translate'
import {
  passengersWithDisabilityTypeSelector,
  passengersWithoutDisabilityTypeSelector
} from 's3p-js-lib/src/redux/selectors/api/orientation/offer/passengers'
import { rebookingCreateBooking } from '../../redux/actions/api/aftersales/rebooking/booking'
import { updateSelectBundleWithSeatPreferences } from 's3p-js-lib/src/redux/actions/api/orientation/update-select-bundle'
import { routesSelector } from 's3p-js-lib/src/redux/selectors/api/orientation/offer'
import { DISABILITY_TYPE_WHEELCHAIR, SEAT_PROPERTY_NO_DISABILITY } from '../../constants'
import { canOverrideAftersalesRulesSelector, overrideValidationRulesSelector } from '../../redux/selectors/api/auth/auth'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import { waiveFeesSelector } from 's3p-js-lib/src/redux/selectors/user-input/aftersales/waive-fees'
import { rebookingAllowedSelector } from '../../redux/selectors/api/booking/after-sales'
import {bookingSelector} from 's3p-js-lib/src/redux/selectors/api/booking/booking'

const passengersWithDisabilityTypeWheelChair = passengersWithDisabilityTypeSelector(DISABILITY_TYPE_WHEELCHAIR)
const passengersWithoutDisabilityTypeWheelChair = passengersWithoutDisabilityTypeSelector(DISABILITY_TYPE_WHEELCHAIR)

const hasPassengerWithDisabilityTypeWheelChair = state => passengersWithDisabilityTypeWheelChair(state).length > 0

export default bookingFlow => {
  const mapStateToProps = state => {
    const result = bookingFlow(currentPathnameSelector(state), bookingFlowSelector(state))
    return {
      nextPageUrl: result.next,
      previousPageUrl: result.previous,
      isLoadingCreateProvisionalBooking: state.api.loading.createProvisionalBooking
    }
  }

  const mapDispatchToProps = {
    goForward: alternativeCurrentPage => async (dispatch, getState) => {
      const alternativePage = typeof alternativeCurrentPage === 'string' ? alternativeCurrentPage : null
      const result = bookingFlow(
        alternativePage || currentPathnameSelector(getState()),
        bookingFlowSelector(getState())
      )
      const journeySearch = getState().api.orientation.journeySearch
      let shouldGoToNextPage = true
      if (result.setSeatPreferences && hasPassengerWithDisabilityTypeWheelChair(getState())) {
        const passengers = passengersWithoutDisabilityTypeWheelChair(getState())
        for (const selectedBundle of getState().api.orientation.selectedBundles) {
          const route = routesSelector(getState()).find(route => route.id === selectedBundle.route_id)
          const seatPreferences = passengers.reduce(
            (seatPreferences, passenger) => seatPreferences.concat(route.legs.map(leg => ({
              passenger_id: passenger.id,
              leg_id: leg.id,
              seat_property_codes: [SEAT_PROPERTY_NO_DISABILITY]
            }))), [])
          dispatch(updateSelectBundleWithSeatPreferences(route, seatPreferences))
        }
      }
      if (result.shouldCreateProvisionalBooking && journeySearch) {
        const provisionalBooking = bookingSelector(getState())
        shouldGoToNextPage = (provisionalBooking && provisionalBooking.booking_number)
          ? await dispatch(replaceProvisionalBooking(provisionalBooking))
          : await dispatch(createProvisionalBooking())
      }

      if (result.shouldCreateBooking) {
        const state = getState()
        shouldGoToNextPage = await dispatch(rebookingCreateBooking(
          journeySearch.id,
          waiveFeesSelector(state),
          !rebookingAllowedSelector(originalBookingSelector)(state) && canOverrideAftersalesRulesSelector(originalBookingSelector)(state),
          overrideValidationRulesSelector(originalBookingSelector)(state)
        ))

        if (!shouldGoToNextPage) {
          if (result.createBookingFailurePage) {
            dispatch(routerActions.push(`/${_t.getLocales()}${result.createBookingFailurePage}`))
          }
          return
        }
      }

      if (shouldGoToNextPage) {
        dispatch(routerActions.push(`/${_t.getLocales()}${result.next}`))
      } else if (result.createProvisionalBookingFailurePage) {
        dispatch(routerActions.push(`/${_t.getLocales()}${result.createProvisionalBookingFailurePage}`))
      }
    },
    goBack: () => (dispatch, getState) => {
      const result = bookingFlow(currentPathnameSelector(getState()), bookingFlowSelector(getState()))
      dispatch(routerActions.push(`/${_t.getLocales()}${result.previous}`))
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)
}
