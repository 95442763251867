import configuration from './configuration'
import schemeWideCode from './scheme-wide-code'
import getActions from './get-actions'
import confirmAction from './confirm-action'

export default {
  configuration,
  schemeWideCode,
  getActions,
  confirmAction
}
