import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Icon from '../../../../elements/icon/icon'
import Loader, { TYPE_COMPONENT } from '../../../../elements/loader/loader'
import Button from '../../../../elements/button/button'
import JourneyDetailsSections from './sections'
import HimMessage from './him-message'
import './journey-details.scss'

export default class JourneyDetails extends Component {
  static propTypes = {
    routeId: PropTypes.string.isRequired,
    isLoadingJourneyDetails: PropTypes.bool.isRequired,
    showJourneyDetails: PropTypes.func.isRequired,
    stations: PropTypes.arrayOf(PropTypes.object).isRequired,
    himMessages: PropTypes.arrayOf(PropTypes.shape({
      content: PropTypes.string,
      title: PropTypes.string
    })),
    legs: PropTypes.array
  }

  constructor (...args) {
    super(...args)

    this.handleHideDetails = this.handleHideDetails.bind(this)
  }

  handleHideDetails () {
    this.props.showJourneyDetails(null)
  }

  renderMessages () {
    return this.props.himMessages.map((info, index) =>
      <HimMessage title={info.title} content={info.content} key={index} className='journey-him-message' />
    )
  }

  render () {
    return (
      <div className='route--journey-detail'>
        {this.renderMessages()}
        {this.props.isLoadingJourneyDetails
          ? <Loader type={TYPE_COMPONENT} />
          : (
            <div className='journey-details'>
              <div className='journey-details__sections-overview-display'>
                <Button
                  className='cancel'
                  name='close'
                  onClick={this.handleHideDetails}
                >
                  <Icon name='cross' className='medium align-right' />
                  <span className='text'>{_t.message('journey-result-set.journey-details.close')}</span>
                </Button>
              </div>
              <div className='journey-details__sections-overview'>
                <div className='sections-overview'>
                  <JourneyDetailsSections stations={this.props.stations} legs={this.props.legs} />
                </div>
              </div>
            </div>
          )
        }
      </div>
    )
  }
}
