import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Button from '../../../elements/button/button'
import Icon from '../../../elements/icon/icon'
import Modal from '../../../elements/modal/modal'
import TextLabel from '../../../elements/text-label/text-label'
import _t from 's3p-js-lib/src/translate'
import ComponentContainer from '../../../elements/component-container/component-container'

export const ABORT_DIALOG_NAME = 'abort-seat-selection'

const SegmentType = PropTypes.shape({
  leg: PropTypes.shape({
    id: PropTypes.string,
    departureStation: PropTypes.shape({
      name: PropTypes.string
    }),
    arrivalStation: PropTypes.shape({
      name: PropTypes.string
    })
  })
})

export default class ProgressNavigationContainer extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    hasSelectedSeats: PropTypes.bool,
    hasPendingChanges: PropTypes.bool,
    isValid: PropTypes.bool,
    showAbortDialog: PropTypes.bool,
    selectionsLeft: PropTypes.number,
    previousSegment: SegmentType,
    nextSegment: SegmentType,
    unavailableSegments: PropTypes.arrayOf(SegmentType),
    onAbortContinue: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    handlePrevious: PropTypes.func.isRequired,
    onAbort: PropTypes.func.isRequired
  }

  render () {
    return (
      <ComponentContainer name='sticky-footer'>
        <div className='sticky-footer-content'>
          {this.renderAbortModal()}
          {this.renderAbort()}
          <div className='segment-notification'>
            {this.renderSegmentNotification()}
          </div>
          <div className='segment-navigation'>
            {this.renderPrevious()}
            {this.renderNext()}
          </div>
        </div>
      </ComponentContainer>
    )
  }

  renderAbort () {
    return (
      <div className='abort-operation'>
        <Button name='abort-operation' className='alert' onClick={this.props.onAbort}>
          <TextLabel>
            <Icon name='cross' className='align-left' />
            <TextLabel.Text text={_t.message('seat-selector.abort.button')} />
          </TextLabel>
        </Button>
      </div>
    )
  }

  renderSegmentNotification () {
    const unavailableSegmentsText = _t.message(
      'seat-selector.unavailable-segments.information', {
        segments: this.props.unavailableSegments.map(segment =>
          _t.message('seat-selector.unavailable-segments.segment', {
            departureStation: segment.leg.departureStation.name,
            arrivalStation: segment.leg.arrivalStation.name
          })
        ).join(', ')
      }
    )

    if (this.props.unavailableSegments.length > 0) {
      return (
        <TextLabel name='segment-notification'>
          <Icon name='information' className='align-left' />
          <TextLabel.Text text={unavailableSegmentsText} />
        </TextLabel>
      )
    } else {
      return null
    }
  }

  renderPrevious () {
    return this.props.previousSegment
      ? (
        <Button
          name='previous-segment'
          className='primary'
          onClick={this.props.handlePrevious}
          loading={this.props.isLoading}
        >
          <TextLabel name='previous-segment'>
            <Icon name='chevron-left' className='align-left' />
            <TextLabel.Text text={_t.message(`seat-selector.progress-navigation.previous-leg`)} />
          </TextLabel>
          <TextLabel
            name='previous-segment-description'
            text={_t.message(`seat-selector.progress-navigation.od`, {
              departureStation: this.props.previousSegment.leg.departureStation.name,
              arrivalStation: this.props.previousSegment.leg.arrivalStation.name
            })}
          />
        </Button>
      ) : null
  }

  renderNext () {
    const {hasPendingChanges, hasSelectedSeats, isValid, nextSegment, selectionsLeft} = this.props

    const primaryText = _t.message(
      !hasPendingChanges
        ? (hasSelectedSeats
          ? `seat-selector.progress-navigation.keep-selection`
          : `seat-selector.progress-navigation.assign-automatically`
        )
        : (nextSegment
          ? `seat-selector.progress-navigation.next-leg`
          : `seat-selector.progress-navigation.confirm`
        )
    )

    const secondaryText = !hasPendingChanges
      ? _t.message(`seat-selector.progress-navigation.skip-leg`)
      : selectionsLeft === 0 && nextSegment
        ? _t.message(`seat-selector.progress-navigation.od`, {
          departureStation: nextSegment.leg.departureStation.name,
          arrivalStation: nextSegment.leg.arrivalStation.name
        })
        : selectionsLeft === 0 && !nextSegment
          ? _t.message(`seat-selector.progress-navigation.finish`)
          : _t.message(`seat-selector.progress-navigation.selections-left`, {selectionsLeft})

    return (
      <Button
        name='next-segment'
        className='tertiary'
        disabled={hasPendingChanges && (selectionsLeft > 0 || !isValid)}
        onClick={this.props.onNext}
        loading={this.props.isLoading}
      >
        <TextLabel name='next-segment'>
          <TextLabel.Text text={primaryText} />
          <Icon name='chevron-right' className='align-right' />
        </TextLabel>
        <TextLabel name='next-segment-description' text={secondaryText} />
      </Button>
    )
  }

  renderAbortModal () {
    return this.props.showAbortDialog
      ? (
        <Modal
          name={ABORT_DIALOG_NAME}
          header={<Modal.Header title={_t.message('seat-selector.abort.confirm.title')} />}
          footer={<Modal.Footer
            onContinue={this.props.onAbortContinue}
            isLoadingContinue={this.props.isLoading}
          />}
        >
          <TextLabel name='information' text={_t.message('seat-selector.abort.confirm.information')} />
        </Modal>
      ) : null
  }
}
