import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import connectFormContainer from '../form'
import PassengerDetailsModal, {
  PASSENGER_DETAILS_MODAL
} from '../../../components/base/passenger-details-search/passenger-details-modal'
import {
  FORM_PRODUCT_SEARCH_PASSENGERS_DETAILS,
  FORM_JOURNEY_SEARCH_PASSENGERS_DETAILS,
  FORM_REBOOK_SEARCH_PASSENGERS_DETAILS
} from '../../../constants'
import { hideElement } from '../../../redux/actions/containers/base/visible-element'
import {
  loadPassengerDetailsTicketsFlow,
  loadPassengerDetailsReservationFlow,
  savePassengerDetailsTicketsFlow,
  savePassengerDetailsReservationFlow,
  loadPassengerDetailsRebookFlow
} from '../../../redux/actions/containers/base/passenger-details-search/passenger-details-modal'

export default formName => {
  class PassengerDetailsFormContainer extends Component {
    static propTypes = {
      handleLoadPassengerDetails: PropTypes.func.isRequired,
      handleSave: PropTypes.func.isRequired,
      form: PropTypes.object.isRequired
    }

    UNSAFE_componentWillMount () {
      this.props.handleLoadPassengerDetails()
    }

    render () {
      return (
        <PassengerDetailsModal
          form={this.props.form}
          handleSave={this.props.handleSave}
          isRebookFlow={formName === FORM_REBOOK_SEARCH_PASSENGERS_DETAILS}
          isReservationsFlow={formName === FORM_JOURNEY_SEARCH_PASSENGERS_DETAILS}
        />
      )
    }
  }

  const mapDispatchToProps = {
    handleLoadPassengerDetails: formName === FORM_PRODUCT_SEARCH_PASSENGERS_DETAILS
      ? loadPassengerDetailsTicketsFlow
      : formName === FORM_JOURNEY_SEARCH_PASSENGERS_DETAILS
        ? loadPassengerDetailsReservationFlow
        : loadPassengerDetailsRebookFlow,
    handleSave: () => [formName === FORM_PRODUCT_SEARCH_PASSENGERS_DETAILS
      ? savePassengerDetailsTicketsFlow()
      : formName === FORM_JOURNEY_SEARCH_PASSENGERS_DETAILS
        ? savePassengerDetailsReservationFlow()
        : null,
    hideElement(PASSENGER_DETAILS_MODAL)
    ]
  }

  return connect(null, mapDispatchToProps)(
    connectFormContainer({formName})(PassengerDetailsFormContainer)
  )
}
