import PropTypes from 'prop-types'
import React, { Component } from 'react'
import pick from 'lodash/pick'
import './select.scss'

class Option extends Component {
  static propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }

  render () {
    const {text, ...rest} = this.props
    return <option {...rest}>{text}</option>
  }
}

export default class Select extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    required: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    data: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func
  }

  constructor (...args) {
    super(...args)

    this.onChange = this.onChange.bind(this)
  }

  onChange (event) {
    this.props.onChange(event.target.value)
  }

  shouldComponentUpdate (nextProps) {
    return nextProps.data !== this.props.data ||
            nextProps.placeholder !== this.props.placeholder ||
            nextProps.className !== this.props.className ||
            nextProps.value !== this.props.value ||
            nextProps.disabled !== this.props.disabled
  }

  render () {
    let {value, data, placeholder, ...rest} = pick(this.props, Object.keys(Select.propTypes))

    if (!value) {
      value = ''
    }

    if (placeholder) {
      data = [{
        value: '',
        text: placeholder
      }, ...data]
    }

    return (
      <select {...rest} onChange={this.onChange} value={value}>
        {data.map((item, index) => <Option key={index} {...item} />)}
      </select>
    )
  }
}
