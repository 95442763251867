import localStorage from 's3p-js-lib/src/local-storage'
import moment from 'moment'
import _t from 's3p-js-lib/src/translate'
import {
  DOCUMENT_TEMPLATE_NAME_PED_SIGNATURE_CODE,
  PAYMENT_METHOD_CASH,
  PED_VERIFICATION_TYPE_SIGNATURE_VERIFIED,
  PED_VERIFICATION_TYPE_SIGNATURE_AND_PIN_VERIFIED,
  PED_VERIFICATION_TYPE_CONSUMER_DEVICE_VERIFICATION,
  PED_VERIFICATION_TYPE_PIN_VERIFIED,
  PED_PAYMENT_METHOD_CONTACTLESS_92
} from '../constants'
import padStart from 'lodash/padStart'

const printSignature = [
  PED_VERIFICATION_TYPE_SIGNATURE_VERIFIED,
  PED_VERIFICATION_TYPE_SIGNATURE_AND_PIN_VERIFIED
]

const maskText = (text, textLength) => {
  const length = text.length - textLength
  let maskedString = ''
  for (let i = 0; i < length; i++) {
    maskedString += 'X'
  }
  return `${maskedString}${text.substring(length, text.length)}`
}

export const createReceipt = (templateName, data, amount, station, bookingNumber) => {
  let template = localStorage.get(templateName)

  if (template) {
    const getVerificationTypeMessage = () => {
      let verificationType = data.verificationType
      if (verificationType && _t.hasMessage(`credit-debit-payment.verification-type.${verificationType}`)) {
        if (parseInt(data.paymentMethod) === PED_PAYMENT_METHOD_CONTACTLESS_92 &&
          parseInt(verificationType) === PED_VERIFICATION_TYPE_PIN_VERIFIED
        ) {
          verificationType = PED_VERIFICATION_TYPE_CONSUMER_DEVICE_VERIFICATION
        }
        return _t.message(`credit-debit-payment.verification-type.${verificationType}`)
      }
    }

    const paymentMethod = data.paymentMethod && _t.hasMessage(`credit-debit-payment.paymentMethod.${data.paymentMethod}`)
      ? _t.message(`credit-debit-payment.paymentMethod.${data.paymentMethod}`)
      : data.paymentMethod === PAYMENT_METHOD_CASH ? _t.message('payment-methods.NONE_CASH.name') : ''

    template = template.replace(/{{current_date}}/g, moment().format('L'))
    template = template.replace(/{{current_time}}/g, moment().format('LTS'))
    template = template.replace(/{{issuer}}/g, data.emvApplicationLabel || '')
    template = template.replace(/{{verification_type}}/g, getVerificationTypeMessage())
    template = template.replace(/{{auth_code}}/g, data.authCode || '')
    template = template.replace(/{{masked_pan}}/g, data.maskedPan || '')
    template = template.replace(/{{application_id}}/g, data.applicationId || '')
    template = template.replace(/{{masked_merchant_id}}/g, data.merchantId ? maskText(data.merchantId, 5) : '')
    template = template.replace(/{{masked_terminal_id}}/g, data.terminalId ? maskText(data.terminalId, 4) : '')
    template = template.replace(/{{merchant_id}}/g, data.merchantId || '')
    template = template.replace(/{{terminal_id}}/g, data.terminalId || '')
    template = template.replace(/{{receipt_no}}/g, data.receiptNo || '')
    template = template.replace(/{{issue_number}}/g, data.issueNumber || '')
    template = template.replace(/{{transaction_data_source}}/g, data.transactionDataSource || paymentMethod)
    template = template.replace(/{{expiry_date}}/g, data.expiryDate || '')
    template = template.replace(/{{payment_method}}/g, paymentMethod || '')
    template = template.replace(/{{pgtr}}/g, data.pgtr || '')
    template = template.replace(/{{transaction_date}}/g, data.transactionDate || '')
    template = template.replace(/{{transaction_time}}/g, data.transactionTime || '')
    template = template.replace(/{{transaction_id}}/g, data.transactionId || '')
    template = template.replace(/{{amount}}/g, amount ? padStart(`EUR ${_t.formatNumber(amount, 'decimal')}`, 36, ' ') : '')
    template = template.replace(/{{result}}/g, data.result || '')
    template = template.replace(/{{station_name}}/g, station ? station.name : '')
    template = template.replace(/{{emv_application_label}}/g, data.emvApplicationLabel || '')
    template = template.replace(/{{device_id}}/g, data.deviceId || '')
    template = template.replace(/{{shift_id}}/g, data.shiftId || '')
    template = template.replace(/{{ticket_numbers}}/g, data.ticketNumbers || '')
    template = template.replace(/{{print_signature}}/g, printSignature.includes(parseInt(data.verificationType))
      ? localStorage.get(DOCUMENT_TEMPLATE_NAME_PED_SIGNATURE_CODE)
      : ''
    )
    template = template.replace(/{{booking_number}}/g, bookingNumber || '')

    return JSON.parse(template)
  }
}

const createLine = text => ({
  hsize: 'normal',
  vsize: 'normal',
  align: 'left',
  style: 'plain',
  text: text || ' '
})

export const createSignatureReceipt = (templateName, data) => {
  let template = localStorage.get(templateName)

  if (template) {
    const parsedTemplate = JSON.parse(template)
    const receiptLines = data.customerReceipt.split('\r\n')
    const newLines = [
      createLine('MERCHANT RECEIPT'),
      createLine('Iarnród Éireann - Irish Rail'),
      createLine('Connolly Station, Amiens Street'),
      createLine('Dublin 1, D01 V6V6'),
      createLine(`${moment().format('L')} ${moment().format('LTS')}`)
    ]
    let addLines = false
    for (const text of receiptLines) {
      if (text.match(/RECEIPT NO/)) {
        addLines = true
      }

      if (addLines) {
        newLines.push(createLine(text))
      }

      if (text.match(/AUTH CODE/)) {
        break
      }
    }
    parsedTemplate.lines.textline1 = newLines

    return parsedTemplate
  }
}

export const createPEDErrorReceipt = data => {
  return {
    lines: {
      textline1: [
        createLine('There is a technical problem with your'),
        createLine('booking. Please inform your support'),
        createLine('department within 2 hours so they can'),
        createLine('cancel / refund the payment.'),
        createLine(' '),
        createLine(`Booking number: ${data.bookingNumber || ''}`),
        createLine('Payment reference booking system:'),
        createLine(` - ${data.paymentReference || ''}`),
        createLine('Payment reference payment system:'),
        createLine(` - ${data.transactionId || ''}`),
        createLine(`Booking value: EUR ${_t.formatNumber(data.amountToBePaid || 0.0, 'decimal')}`),
        createLine(' '),
        createLine('Required action by the support team:'),
        createLine('Please cancel this payment in'),
        createLine('the WorldPay system.')
      ]
    }
  }
}
