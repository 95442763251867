import { API_V2_UPDATE_FULFILLMENT_METHOD_SUCCESS } from 's3p-js-lib/src/redux/actions/types-v2'
import { initialState } from 's3p-js-lib/src/redux/reducers/user-input/base/fulfillment-methods'

export default (state = initialState, action) => {
  switch (action.type) {
    case API_V2_UPDATE_FULFILLMENT_METHOD_SUCCESS:
      return initialState
    default:
      return state
  }
}
