export default {
  date: {
    full: {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    },
    fullWithoutDay: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    },
    fullWithoutYear: {
      weekday: 'long',
      day: 'numeric',
      month: 'long'
    },
    fullWithTimeZone: {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timeZoneName: 'long'
    },
    fullWithoutMonthAndYear: {
      weekday: 'long',
      day: 'numeric'
    },
    shortWithoutMonthAndYear: {
      weekday: 'short',
      day: 'numeric'
    },
    short: {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    },
    shortDate: {
      month: 'short',
      day: 'numeric'
    },
    shortWithoutYear: {
      weekday: 'short',
      day: 'numeric',
      month: 'short'
    },
    shortWeekdayAndDate: {
      weekday: 'short',
      day: 'numeric'
    },
    shortWeekday: {
      weekday: 'short'
    },
    dateOnly: {
      day: 'numeric'
    },
    shortMonth: {
      month: 'short'
    }
  },
  time: {
    'short': {
      'hour': '2-digit',
      'minute': '2-digit'
    },
    'medium': {
      'hour': '2-digit',
      'minute': '2-digit',
      'seconds': '2-digit'
    }
  },
  number: {
    currency: {
      style: 'currency',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: 'EUR'
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  },
  momentDateTime: {
    full: 'YYYY-MM-DD HH:mm'
  },
  momentTime: {
    short: 'HH:mm',
    localeTime: 'LT'
  },
  momentDate: {
    default: 'YYYY-MM-DD',
    dayOfTheWeekShort: 'ddd',
    dayOfTheWeek: 'dddd',
    dayOfTheWeekWithDate: 'ddd, D MMM',
    monthShort: 'MMM',
    dayOfMonth: 'DD',
    short: 'MM-DD',
    dayMonthShort: 'D MMM',
    full: 'MMMM Do Y'
  }
}
