import PropTypes from 'prop-types'
import React, { Component } from 'react'
import SegmentNavigation from './segment-navigation/segment-navigation'
import ConfirmDiscardPendingChanges from './segment-navigation/confirm-discard-pending-changes'

export const MODAL_PENDING_CHANGES_ELEMENT = 'change-segment-confirm-discard-pending-changes'

export default class SegmentNavigationWrapper extends Component {
  static propTypes = {
    segmentId: PropTypes.string,
    hasPendingChanges: PropTypes.bool,
    showPendingChangesModal: PropTypes.bool,
    outboundSegments: PropTypes.array,
    inboundSegments: PropTypes.array,
    onSelectSegment: PropTypes.func.isRequired,
    onContinueChangeSegment: PropTypes.func.isRequired
  }

  render () {
    return (
      <react-wrapper class='react-wrapper'>
        {this._renderShowConfirm()}
        <SegmentNavigation
          segmentId={this.props.segmentId}
          onSelectSegment={this.props.onSelectSegment}
          outboundSegments={this.props.outboundSegments}
          inboundSegments={this.props.inboundSegments}
        />
      </react-wrapper>
    )
  }

  _renderShowConfirm () {
    return this.props.showPendingChangesModal ? (
      <ConfirmDiscardPendingChanges
        onContinue={this.props.onContinueChangeSegment}
        name='change-segment-confirm-discard-pending-changes'
      />
    ) : null
  }
}
