import { compose } from 'redux'
import { goToCurrentAftersalesBooking } from '../../redux/actions/containers/base/aftersales'
import { AGENT_ROLE_OPERATOR } from '../../constants'
import { authContainer } from '../base/auth'
import checkAccessBySelector from '../base/check-access-by-selector'

export default (selector, Component) => compose(
  authContainer({roles: [AGENT_ROLE_OPERATOR]}),
  checkAccessBySelector({
    selector,
    onNotAllowed: goToCurrentAftersalesBooking
  })
)(Component)
