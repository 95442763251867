import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../elements/text-label/text-label'
import Label from '../../../elements/label/label'
import Form from '../../../elements/form/form'

const FieldPropType = PropTypes.shape({
  active: PropTypes.bool.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool
})

export default class PassengerFieldSet extends Component {
  static propTypes = {
    passenger: PropTypes.shape({
      number: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      cancelled: PropTypes.bool
    }).isRequired,
    form: PropTypes.shape({
      disabled: PropTypes.bool,
      fields: PropTypes.shape({
        firstName: FieldPropType,
        lastName: FieldPropType,
        emailAddress: FieldPropType,
        phoneNumber: FieldPropType
      })
    })
  }

  render () {
    return (
      <fieldset>
        <TextLabel
          name='mandatory-fields'
          text={_t.message('passenger-details.required-fields')}
        />
        <Form.InputGroup name='passenger-number-first-last-name'>
          <Form.InputWrapper name='passenger-number'>
            <Label
              htmlFor={this._getFieldId('first-name')}
              text={_t.message(
                'passenger-details.passenger-number',
                this.props.passenger
              )}
              name='passenger-number'
            />
            <Label
              htmlFor={this._getFieldId('first-name')}
              text={_t.message(
                'passenger-details.passenger-type',
                this.props.passenger
              )}
              name='passenger-type'
            />
            {this.props.passenger.cancelled
              ? (
                <Label
                  text={_t.message('passenger-details.cancelled')}
                  name='passenger-cancelled'
                />
              ) : null}
          </Form.InputWrapper>
          <Form.InputWrapper name='passenger-first-name'>
            <Label
              htmlFor={this._getFieldId('first-name')}
              text={_t.message('passenger-details.form.first-name.label')}
              name='first-name'
            />
            <Form.TextField
              {...this.props.form.fields.firstName}
              name='first-name'
              id={this._getFieldId('first-name')}
              disabled={this.props.passenger.cancelled || this.props.form.disabled || this.props.form.fields.firstName?.disabled}
            />
          </Form.InputWrapper>
          <Form.InputWrapper name='passenger-last-name'>
            <Label
              htmlFor={this._getFieldId('last-name')}
              text={_t.message('passenger-details.form.last-name.label')}
              name='last-name'
            />
            <Form.TextField
              {...this.props.form.fields.lastName}
              name='last-name'
              id={this._getFieldId('last-name')}
              disabled={this.props.passenger.cancelled || this.props.form.disabled || this.props.form.fields.lastName?.disabled}
            />
          </Form.InputWrapper>
        </Form.InputGroup>
        <Form.InputGroup name='passenger-email-address-phone-number'>
          <Form.InputWrapper name='passenger-email-address'>
            <Form.Label
              htmlFor={this._getFieldId('email-address')}
              name='email-address' />
            <Form.EmailField
              {...this.props.form.fields.emailAddress}
              name='email-address'
              id={this._getFieldId('email-address')}
              disabled={this.props.passenger.cancelled || this.props.form.disabled || this.props.form.fields.emailAddress?.disabled}
            />
          </Form.InputWrapper>
          <Form.InputWrapper name='passenger-phone-number'>
            <Label
              htmlFor={this._getFieldId('phone-number')}
              name='phone-number'
              text={_t.message('passenger-details.form.phone-number.label')}
            />
            <Form.TextField
              {...this.props.form.fields.phoneNumber}
              disabled={this.props.passenger.cancelled || this.props.form.disabled || this.props.form.fields.phoneNumber?.disabled}
              name='phone-number'
              id={this._getFieldId('phone-number')}
            />
          </Form.InputWrapper>
        </Form.InputGroup>
      </fieldset>

    )
  }

  _getFieldId (fieldName) {
    return `passenger-${this.props.passenger.number}-${fieldName}`
  }
}
