import React, { Component } from 'react'
import GridLayout from '../../elements/grid/grid-layout'
import GridColumn from '../../elements/grid/grid-column'
import MainMenu from '../../containers/base/main-menu/main-menu'
import GenericLayout from '../generic-layout'
import CustomerDetails from '../../containers/reservations/booking-details/customer-details'
import FulfillmentMethods from '../../containers/reservations/booking-details/fulfillment-methods'
import PassengerDetails from '../../containers/reservations/booking-details/passenger-details'
import StickyFooter from '../../elements/sticky-footer/sticky-footer'
import BookingSummary from '../../components/reservations/booking-summary'
import NextButton from '../../containers/reservations/booking-details/next-button'
import Taskbar from '../../containers/base/taskbar/taskbar'
import BookingProgressBar from '../../containers/reservations/progress-bar'
import '../../components/reservations/booking-details/booking-details.scss'

export default class DetailsLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <MainMenu />
            <BookingProgressBar />
            <CustomerDetails />
            <FulfillmentMethods />
            <PassengerDetails />
            <StickyFooter>
              <BookingSummary nextButton={<NextButton />} />
              <Taskbar />
            </StickyFooter>
          </GridColumn>
        </GridLayout>
      </GenericLayout>
    )
  }
}
