import {
  API_OAUTH_LOGOUT,
  API_AGENT_LOGIN_SUCCESS,
  API_START_AGENT_SHIFT_SUCCESS,
  API_END_AGENT_SHIFT_SUCCESS,
  CLEAR_STATE
} from 's3p-js-lib/src/redux/actions/types'
import {
  API_V2_GET_PRODUCTS_REQUEST,
  API_V2_CREATE_BOOKING_SUCCESS,
  API_V2_CREATE_BOOKING_FAILURE,
  API_V2_UPDATE_SEGMENTS_SUCCESS,
  API_V2_UPDATE_SEGMENTS_FAILURE,
  API_V2_REMOVE_ITEMS_SUCCESS
} from 's3p-js-lib/src/redux/actions/types-v2'
import { CONTAINERS_TICKETS_PRODUCT_SEARCH_RESULT } from '../../../../constants'
import { UI_LAST_SELECTED_PRODUCT, USER_INPUT_PENDING_ADD_PRODUCT } from '../../../actions/types'

const initialState = {pendingAddProduct: null, lastSelectedProduct: null}

export default (state = initialState, action) => {
  switch (action.type) {
    case UI_LAST_SELECTED_PRODUCT:
      return {...state, lastSelectedProduct: action.product}
    case USER_INPUT_PENDING_ADD_PRODUCT:
      return {...state, pendingProduct: action.product}
    case API_V2_CREATE_BOOKING_SUCCESS:
    case API_V2_UPDATE_SEGMENTS_SUCCESS:
    case API_V2_CREATE_BOOKING_FAILURE:
    case API_V2_UPDATE_SEGMENTS_FAILURE:
      return {...state, pendingProduct: null}
    case API_V2_GET_PRODUCTS_REQUEST:
    case API_V2_REMOVE_ITEMS_SUCCESS:
    case API_END_AGENT_SHIFT_SUCCESS:
    case API_START_AGENT_SHIFT_SUCCESS:
    case API_AGENT_LOGIN_SUCCESS:
    case API_OAUTH_LOGOUT:
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_TICKETS_PRODUCT_SEARCH_RESULT) ? initialState : state
    default:
      return state
  }
}
