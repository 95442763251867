import { createSelector } from 'reselect'
import { originalBookingSelector, bookingSelector } from '../../../api/aftersales/booking'
import {
  tariffSegmentsSelector,
  outboundTariffSegmentsSelector,
  inboundTariffSegmentsSelector
} from '../../../api/booking/booking'
import {
  SEAT_SELECTION_NOT_AVAILABLE,
  SEAT_SELECTION_AVAILABLE,
  SEAT_SELECTION_READONLY
} from '../../../../../constants'
import { getUniqueBookingTariffSegmentIdentifier } from '../../../../../misc/utils'
import { toTimezoneMoment } from '../../../../../misc/date'

const getTariffSegment = (key, tariffSegment) => ({
  id: tariffSegment.id,
  key,
  seatProducts: [],
  departureStation: {
    name: tariffSegment.departure_station.name || tariffSegment.departure_station._u_i_c_station_code
  },
  departureDateTime: toTimezoneMoment(
    tariffSegment.booking_journey_segments[0].departure_date_time,
    tariffSegment.booking_journey_segments[0].departure_station.timezone
  ),
  arrivalStation: {
    name: tariffSegment.arrival_station.name || tariffSegment.arrival_station._u_i_c_station_code
  },
  arrivalDateTime: toTimezoneMoment(
    tariffSegment.booking_journey_segments[0].arrival_date_time,
    tariffSegment.booking_journey_segments[0].arrival_station.timezone
  ),
  direction: tariffSegment.direction
})

const getTariffSegments = tariffSegments => Object.values(tariffSegments.reduce(
  (segments, tariffSegment) => {
    if (tariffSegment.booking_journey_segments.length === 1 && !tariffSegment.required_products.every(({cancelled}) => cancelled)
    ) {
      const key = getUniqueBookingTariffSegmentIdentifier(tariffSegment)
      if (!(key in segments)) {
        segments[key] = getTariffSegment(key, tariffSegment)
      }
      segments[key].seatProducts = [
        ...segments[key].seatProducts,
        ...tariffSegment.required_products.filter(product => Boolean(product.seat) && !product.cancelled)
      ]
    }

    return segments
  },
  {}
))

const getStatus = seatProducts => seatProducts.some(product => product.can_change_seat)
  ? SEAT_SELECTION_AVAILABLE
  : (seatProducts.length > 0 ? SEAT_SELECTION_READONLY : SEAT_SELECTION_NOT_AVAILABLE)

const baseSeatSelectionOptionsSelector = tariffSegmentsSelector => createSelector(
  [tariffSegmentsSelector(originalBookingSelector), tariffSegmentsSelector(bookingSelector)],
  (originalTariffSegments, updatedTariffSegments) => {
    const originalSegments = getTariffSegments(originalTariffSegments)
    const getSeatsIdentifier = seatProducts => seatProducts.reduce(
      (id, product) => `${id},${product.passenger_id}|${product.seat.carriage}|${product.seat.number}`,
      ''
    )

    return getTariffSegments(updatedTariffSegments).map(segment => {
      const originalSegment = originalSegments.find(originalSegment => originalSegment.key === segment.key)

      return {
        leg: segment,
        selectedSeats: segment.seatProducts.map(product => ({
          passengerId: product.passenger_id,
          carriageNumber: product.seat.carriage,
          seatNumber: product.seat.number,
          inventoryClass: product.inventory_class
        })),
        status: getStatus(segment.seatProducts),
        updated: Boolean(
          originalSegment &&
          getSeatsIdentifier(originalSegment.seatProducts) !== getSeatsIdentifier(segment.seatProducts)
        )
      }
    })
  }
)

export const seatSelectionOptionsSelector = baseSeatSelectionOptionsSelector(tariffSegmentsSelector)

export const outboundSeatSelectionOptionsSelector = baseSeatSelectionOptionsSelector(outboundTariffSegmentsSelector)

export const inboundSeatSelectionOptionsSelector = baseSeatSelectionOptionsSelector(inboundTariffSegmentsSelector)
