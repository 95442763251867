import { createSelector } from 'reselect'
import { stationsSelector } from 's3p-js-lib/src/redux/selectors/api/orientation/stations'
import { productFamiliesSelector } from '../../api/v2/meta/product-families'
import { META_DATA_SHORTCUTS } from '../../../../constants'
import range from 'lodash/range'
import { agentMetaDataSelector } from 's3p-js-lib/src/redux/selectors/api/user/agent'

const emptyShortcut = sequenceNumber => ({
  isEmpty: true,
  id: `empty-shortcut-${sequenceNumber}`,
  sequenceNumber
})

const agentMetaDataShortcutsSelector = createSelector(
  [agentMetaDataSelector],
  metaData => {
    const shortcuts = metaData.find(meta => meta.key === META_DATA_SHORTCUTS)
    return shortcuts ? JSON.parse(shortcuts.value) : null
  }
)

const agentShortcutsSelector = createSelector(
  [agentMetaDataShortcutsSelector],
  shortcuts => {
    const shortcutsArray = shortcuts || []
    return shortcutsArray.length >= 10
      ? shortcutsArray.slice(0, 10)
      : shortcutsArray.concat(range(shortcutsArray.length + 1, 11).map(emptyShortcut))
  }
)

export const shortcutsSelector = createSelector(
  [agentShortcutsSelector, stationsSelector, productFamiliesSelector],
  (shortcuts, stations, productFamilies) => shortcuts && stations
    ? shortcuts.map(shortcut => ({
      ...shortcut,
      productFamily: productFamilies.find(family => family.code === shortcut.productFamilyCode),
      origin: stations.find(station => station.UICStationCode === shortcut.origin),
      destination: stations.find(station => station.UICStationCode === shortcut.destination)
    }))
    : []
)
