import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Feedback, { FEEDBACK_INFORMATION } from '../../../elements/feedback/feedback'
import Loader, { TYPE_COMPONENT } from '../../../elements/loader/loader'
import ProductTile from './product-search-result-tile'
import TilesWithModal from '../../../containers/base/tiles/tiles-with-modal'

const MAX_PRODUCTS = 5
export const PRODUCTS_TILES_NAME = 'product'

export default class ProductSearchResult extends Component {
  static propTypes = {
    products: PropTypes.array.isRequired,
    handleSelect: PropTypes.func.isRequired,
    handleShowModal: PropTypes.func.isRequired,
    hasSearch: PropTypes.bool,
    isLoadingProductSearch: PropTypes.bool,
    isLoadingAddProduct: PropTypes.bool,
    disabled: PropTypes.bool,
    showTiles: PropTypes.bool.isRequired,
    noProductsFound: PropTypes.bool
  }

  constructor (props, ...args) {
    super(props, ...args)

    this.hotkey = {
      more: 'showMore',
      tiles: ['selectProductNr1', 'selectProductNr2', 'selectProductNr3', 'selectProductNr4', 'selectProductNr5']
    }
  }

  render () {
    return this.props.isLoadingProductSearch || this.props.noProductsFound || this.props.showTiles
      ? (
        <div className='product-results'>
          {this.props.isLoadingProductSearch ? <Loader type={TYPE_COMPONENT} /> : this.props.noProductsFound
            ? (
              <Feedback
                status={FEEDBACK_INFORMATION}
                text={_t.message('products-result.no-products-found')}
              />
            ) : (
              <TilesWithModal
                data={this.props.products}
                maxTiles={MAX_PRODUCTS}
                name={PRODUCTS_TILES_NAME}
                translatePrefix='products-result'
                tile={ProductTile}
                handleSelect={this.props.handleSelect}
                handleShowModal={this.props.handleShowModal}
                rowCount={5}
                hotkey={this.hotkey}
                disabled={this.props.disabled}
                loading={this.props.isLoadingAddProduct}
              />
            )}
        </div>
      ) : null
  }
}
