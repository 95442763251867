import { connect } from 'react-redux'
import ReadTicketOutput from '../../../components/aftersales/read-ticket/read-ticket-output'
import {
  readMachineMagneticTicket,
  ejectMachineMagneticTicket
} from '../../../redux/actions/machine/magnetic-ticket-printer'
import { readBarcodeData } from '../../../redux/actions/containers/aftersales/read-ticket/read-ticket'
import {
  FULFILLMENT_METHOD_CODE_MAGSTRIPE,
  FULFILLMENT_METHOD_CODE_BARCODE
} from '../../../constants'
import { ticketDetailsSelector } from '../../../redux/selectors/api/v2/ticket-details'
import { goToAftersalesBooking } from '../../../redux/actions/containers/base/routing'

const mapStateToProps = state => {
  const selectedFulfillmentCode = state.containers.aftersales.readTicket.selected
  const isLoadingReadTicket = state.machine.loading.readMagneticTicket || state.containers.aftersales.readTicket.loadingReadBarcode
  const isLoadingEjectTicket = state.machine.loading.ejectMagneticTicket

  return {
    showReadTicketButton: Boolean(selectedFulfillmentCode && !isLoadingReadTicket && !isLoadingEjectTicket),
    showGoToBookingButton: Boolean(selectedFulfillmentCode === FULFILLMENT_METHOD_CODE_BARCODE && ticketDetailsSelector(state)),
    showSelectFulfillment: !selectedFulfillmentCode,
    selectedFulfillmentCode: state.containers.aftersales.readTicket.selected,
    hasReadTicketError: state.containers.aftersales.readTicket.error,
    isLoadingReadTicket
  }
}

const mapDispatchToProps = {
  onReadTicket: () => async (dispatch, getState) => {
    if (getState().containers.aftersales.readTicket.selected === FULFILLMENT_METHOD_CODE_MAGSTRIPE) {
      await dispatch(readMachineMagneticTicket())
      dispatch(ejectMachineMagneticTicket())
    } else {
      dispatch(readBarcodeData())
    }
  },
  onGoToBooking: () => (dispatch, getState) => {
    const ticketDetails = ticketDetailsSelector(getState())
    if (ticketDetails) {
      dispatch(goToAftersalesBooking(ticketDetails.bookingNumber))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReadTicketOutput)
