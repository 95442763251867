import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../text-label/text-label'
import Icon from '../icon/icon'
import classNames from 'classnames'

class JourneySummary extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
    travelDate: PropTypes.string
  }

  render () {
    const {label, travelDate, direction} = {...this.props}
    return (
      <react-wrapper class='react-wrapper'>
        <TextLabel name='heading'>
          <Icon name={direction} className='large align-left' />
          <TextLabel.Text text={label} />
        </TextLabel>
        {travelDate ? <TextLabel name='departure-date' text={travelDate} /> : null}
      </react-wrapper>
    )
  }
}

class SegmentSet extends Component {
  static propTypes = {
    summary: PropTypes.node.isRequired,
    actions: PropTypes.node,
    children: PropTypes.node.isRequired,
    direction: PropTypes.string.isRequired,
    className: PropTypes.string
  }

  render () {
    const {summary, actions, children, direction, className} = this.props
    return (
      <div className={classNames('segment-set', direction, className)}>
        <div className='segment-set__header'>
          <div className='segment-set__header-summary'>{summary}</div>
          {actions && <div className='segment-set__header-actions'>{actions}</div>}
        </div>
        <div className='segment-set__content'>{children}</div>
      </div>
    )
  }
}

SegmentSet.JourneySummary = JourneySummary

export default SegmentSet
