import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import connectFormContainer from '../../../../containers/base/form'
import VouchersSearchForm from '../../../../components/aftersales/voucher-to-cash/form/voucher-search-form'
import { FORM_TYPE_VOUCHER_SEARCH } from 's3p-js-lib/src/constants-form'
import { getVouchers } from 's3p-js-lib/src/redux/actions/api/booking/vouchers'

class VoucherSearchFormContainer extends Component {
  static propTypes = {
    getVouchers: PropTypes.func.isRequired,
    form: PropTypes.shape({
      valid: PropTypes.bool,
      fields: PropTypes.shape({
        code: PropTypes.object
      })
    })
  }

  constructor (...args) {
    super(...args)
    this.handleGetVouchers = this.handleGetVouchers.bind(this)
  }

  handleGetVouchers () {
    this.props.getVouchers(this.props.form.fields.code.value)
  }

  render () {
    return (
      <VouchersSearchForm {...this.props} handleGetVouchers={this.handleGetVouchers} />
    )
  }
}

const mapStateToProps = state => ({
  loadingGetVouchers: state.api.loading.getVouchers
})

const mapDispatchToProps = {
  getVouchers
}

export default connect(mapStateToProps, mapDispatchToProps)(
  connectFormContainer({formName: FORM_TYPE_VOUCHER_SEARCH})(VoucherSearchFormContainer)
)
