/* globals S3P_SETTINGS, fetch */

import _t from '../translate'
import { timerRequest } from '../misc/utils'
import qs from 'qs'
import {newRelicFetchRequestWrapper, newRelicTracerWrapper} from '../../../src/telemetry/new-relic'

/**
 *
 * @param {string} uri
 * @param {string} endpoint
 * @param {{}} params
 * @returns {string}
 */
const createUrl = (uri, endpoint, params = undefined) => {
  let url = (uri || S3P_SETTINGS.s3Passenger.baseUri) + endpoint
  if (params) {
    url += (endpoint.indexOf('?') === -1 ? '?' : '') + qs.stringify(params, {encodeValuesOnly: true})
  }
  return url
}

const idempotencyRetryHeaderCounter = {}

/**
 *
 * @param {{token: string, params: {}=}} options
 * @returns {Promise<Response|void>}
 */
const request = async function (options) {
  const {uri, endpoint, token, data, authorizationMethod = 'Bearer', method, params, s3IdempotencyKey = null, ...rest} = options
  const requestMethod = method ? method.toUpperCase() : 'GET'
  const requestEndpoint = createUrl(uri, endpoint, params)
  const startDate = new Date()
  const handle = setInterval(() => {
    if (timerRequest(endpoint, startDate) > 600) {
      clearInterval(handle)
    }
  }, 10000)
  const fetchOptions = {
    ...rest,
    method: requestMethod,
    headers: {
      'Accept-Language': _t.getLocales(),
      Accept: 'application/json',
      Authorization: authorizationMethod + ' ' + token
    }
  }

  if (requestMethod !== 'GET') {
    fetchOptions.body = fetchOptions.body || JSON.stringify(data)
    fetchOptions.headers['Content-Type'] = 'application/json'
  }

  if (s3IdempotencyKey) {
    fetchOptions.headers['X-S3-Idempotency-Key'] = s3IdempotencyKey
    if (idempotencyRetryHeaderCounter[s3IdempotencyKey]) {
      fetchOptions.headers['X-S3-Idempotency-Retry'] = idempotencyRetryHeaderCounter[s3IdempotencyKey]
      idempotencyRetryHeaderCounter[s3IdempotencyKey]++
    } else {
      idempotencyRetryHeaderCounter[s3IdempotencyKey] = 1
    }
  }

  const response = await newRelicFetchRequestWrapper(
    'apiRequest',
    requestMethod,
    requestEndpoint,
    fetchOptions,
    data,
    fetch,
    error => {
      clearInterval(handle)
      throw error
    }
  )

  clearInterval(handle)
  if (response.status >= 200 && response.status < 300) {
    const hasEmptyResponse = response.status === 204 || requestMethod === 'HEAD'
    if (s3IdempotencyKey) {
      delete idempotencyRetryHeaderCounter[s3IdempotencyKey]
    }
    return hasEmptyResponse ? {} : response.json()
  } else {
    const error = new Error(`${endpoint}: ${response.statusText}`)
    error.statusCode = response.status
    try {
      error.response = await response.json()
      error.errorCode = error.response?.error?.code
        ? error.response.error.code
        : error.response?.errors?.length && error.response?.errors[0]?.code
          ? error.response?.errors[0]?.code
          : response.headers.has('x-oauth-errorcode')
            ? parseInt(response.headers.get('x-oauth-errorcode'))
            : null
      if (response.headers.has('x-sentry-event-id')) {
        error.sentryEventId = response.headers.get('x-sentry-event-id')
      }
      window?.newrelic?.noticeError(error)
    } catch (jsonError) {
      // response content could not be decoded as json, can be ignored
      window?.newrelic?.noticeError(jsonError)
    }
    throw error
  }
}

export default newRelicTracerWrapper('apiRequestTracer', request)
