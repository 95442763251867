import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { overviewBookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { refundVoucherSelector } from 's3p-js-lib/src/redux/selectors/api/payment/refund-voucher'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import { elementVisibleSelector } from '../../../../redux/selectors/containers/base/visible-element'
import { REFUND_MODAL, REFUND_VOUCHER_MODAL } from '../../../../constants'
import PaymentDetails from '../../../../components/aftersales/booking/payment-details/payment-details'
import { paymentsWithoutFailedSelector } from '../../../../redux/selectors/api/booking/payments'
import { isRefundAvailableSelector } from '../../../../redux/selectors/containers/aftersales/booking/payments'

class PaymentDetailsContainer extends Component {
  static propTypes = {
    clearState: PropTypes.func.isRequired
  }

  componentWillUnmount () {
    this.props.clearState('api.payment.refundVoucher')
  }

  render () {
    return (
      <PaymentDetails {...this.props} />
    )
  }
}

const showRefundVoucherModalSelector = elementVisibleSelector(REFUND_VOUCHER_MODAL)
const paymentsSelector = paymentsWithoutFailedSelector(overviewBookingSelector)
const _isRefundAvailableSelector = isRefundAvailableSelector(overviewBookingSelector)
const _refundModal = elementVisibleSelector(REFUND_MODAL)

const mapStateToProps = state => ({
  showRefundModal: _refundModal(state),
  payments: paymentsSelector(state),
  canRequestRefund: _isRefundAvailableSelector(state),
  refundedVoucher: refundVoucherSelector(state),
  isLoadingRefund: state.api.loading.requestRefund,
  refundSuccess: state.containers.aftersales.booking.refund.success,
  showRefundVoucherModal: showRefundVoucherModalSelector(state)
})

const mapDispatchToProps = {
  clearState
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetailsContainer)
