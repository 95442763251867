import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import sortBy from 'lodash/sortBy'
import { TRAVEL_DIRECTION_OUTBOUND } from 's3p-js-lib/src/constants'
import SegmentSet from '../../../../elements/segment-set/segment-set'
import PriceSpecificationBox from './price-specification-box'

export default class TravelBox extends Component {
  static propTypes = {
    salesPresentation: PropTypes.string.isRequired,
    travelDirection: PropTypes.string.isRequired,
    travelDate: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    priceSpecifications: PropTypes.arrayOf(PropTypes.object).isRequired,
    onUpdateProduct: PropTypes.func.isRequired,
    isQuantityMaximumAvailable: PropTypes.array
  }

  render () {
    const {travelDirection, travelDate, priceSpecifications, disabled, onUpdateProduct, salesPresentation, isQuantityMaximumAvailable} = this.props
    const specifications = sortBy(
      priceSpecifications,
      specification => (specification.origin.departureTime && specification.origin.departureTime.format()) ||
      specification.origin.name
    )
    const isMaximumAvailable = isQuantityMaximumAvailable
      ? isQuantityMaximumAvailable[travelDirection === TRAVEL_DIRECTION_OUTBOUND ? 0 : 1]
      : false
    return (
      <SegmentSet
        direction={travelDirection}
        summary={<SegmentSet.JourneySummary
          label={_t.message(`additional-products.journey.${travelDirection}`)}
          travelDate={_t.message(
            'additional-products.journey.travel-date',
            {date: _t.formatMomentDate(travelDate, 'dayOfTheWeekWithDate')}
          )}
          direction={travelDirection}
        />}
      >
        {specifications.map((priceSpecification, index) =>
          <PriceSpecificationBox
            key={index}
            salesPresentation={salesPresentation}
            priceIndex={index}
            priceSpecification={priceSpecification}
            disabled={disabled}
            onUpdateProduct={onUpdateProduct}
            isQuantityMaximumAvailable={isMaximumAvailable}
          />)}
      </SegmentSet>
    )
  }
}
