import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formFieldValueSelectorCreator } from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import { maxPersonsSelector } from '../../../redux/selectors/containers/base/max-passengers'
import containVisibleElement from '../visible-element'
import { MAX_PASSENGER_MODAL } from '../../../components/base/max-passengers-modal'
import { PASSENGER_DETAILS_MODAL } from '../../../components/base/passenger-details-search/passenger-details-modal'
import PassengerDetails from '../../../components/base/passenger-details-search/passenger-details'
import { FORM_PRODUCT_SEARCH, FORM_JOURNEY_SEARCH, FORM_TYPE_REBOOK_JOURNEY_SEARCH } from 's3p-js-lib/src/constants-form'
import { EMPTY_ARRAY } from 's3p-js-lib/src/constants'

class PassengerDetailsContainer extends Component {
  static propTypes = {
    onShowModal: PropTypes.func.isRequired,
    isElementVisible: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    passengerCount: PropTypes.number.isRequired,
    maxPassengers: PropTypes.number.isRequired,
    isReservationFlow: PropTypes.bool,
    formName: PropTypes.oneOf([FORM_JOURNEY_SEARCH, FORM_TYPE_REBOOK_JOURNEY_SEARCH, FORM_PRODUCT_SEARCH])
  }

  constructor (props, ...args) {
    super(props, ...args)
    this.handleShowModal = this.handleShowModal.bind(this)
  }

  handleShowModal () {
    this.props.onShowModal(
      this.props.passengerCount > this.props.maxPassengers ? MAX_PASSENGER_MODAL : PASSENGER_DETAILS_MODAL
    )
  }

  render () {
    return (
      <PassengerDetails
        disabled={this.props.disabled || this.props.passengerCount === 0}
        showModal={this.props.isElementVisible(PASSENGER_DETAILS_MODAL)}
        handleShowModal={this.handleShowModal}
        isReservationFlow={this.props.formName === FORM_JOURNEY_SEARCH}
        isRebookingFlow={this.props.formName === FORM_TYPE_REBOOK_JOURNEY_SEARCH}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const formFieldValuesSelector = formFieldValueSelectorCreator(ownProps.formName, 'passengers')

  return state => ({
    passengerCount: (formFieldValuesSelector(state) || EMPTY_ARRAY).length,
    maxPassengers: maxPersonsSelector(state)
  })
}

export default connect(mapStateToProps)(
  containVisibleElement(PASSENGER_DETAILS_MODAL)(
    PassengerDetailsContainer
  )
)
