import { CLEAR_STATE } from 's3p-js-lib/src/redux/actions/types'
import {
  UI_START_START_SHIFT,
  UI_END_START_SHIFT
} from '../../../actions/types'
import { CONTAINERS_AGENT_SHIFT } from '../../../../constants'

const initialState = {
  loadingInitiateShift: false,
  loadingStartShift: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UI_START_START_SHIFT:
      return {...state, loadingStartShift: true}
    case UI_END_START_SHIFT:
      return {...state, loadingStartShift: false}
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_AGENT_SHIFT) ? initialState : state
    default:
      return state
  }
}
