/* globals S3P_SETTINGS: true */

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import classNames from 'classnames'
import TextLabel from '../../../../elements/text-label/text-label'
import Icon from '../../../../elements/icon/icon'
import Mousetrap from 'mousetrap'
import _t from 's3p-js-lib/src/translate'

export default class Bundle extends Component {
  static propTypes = {
    handleSelectBundle: PropTypes.func.isRequired,
    bundle: PropTypes.shape({
      id: PropTypes.string,
      selected: PropTypes.bool,
      isAvailable: PropTypes.bool
    }).isRequired
  }

  constructor (props, ...args) {
    super(props, ...args)

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount () {
    const mousetrap = new Mousetrap(this._element)
    mousetrap.bind('enter', this.handleClick)
    mousetrap.bind('space', this.handleClick)
  }

  handleClick () {
    if (this.props.bundle.isAvailable && !this.props.bundle.selected) {
      this.props.handleSelectBundle(this.props.bundle)
    }
  }

  render () {
    const bundle = this.props.bundle
    const isUpgrade = bundle ? bundle.priceOffsetWithSelectedBundle > 0 : false
    const isAvailable = bundle.isAvailable && !bundle.isFilteredOut
    const familyClasses = classNames(
      'product-family',
      `product-family-${bundle.productFamily.code.toLowerCase()}`,
      {
        'state--is-available': isAvailable && !bundle.selected,
        'state--is-selected': Boolean(isAvailable && bundle.selected),
        'state--is-unavailable': !isAvailable
      }
    )

    return (
      <div
        className={familyClasses}
        tabIndex={isAvailable && !bundle.selected ? '0' : undefined}
        aria-selected={isAvailable && bundle.selected ? 'true' : 'false'}
        onClick={this.handleClick}
        ref={element => { this._element = element }}
      >
        <div className='product-family__content'>
          <TextLabel name='product-family-type' text={bundle.productFamily.name} />
          {isAvailable && isUpgrade
            ? [
              <TextLabel
                key='upgrade-1'
                name='upgrade'
                text={_t.message('journey-result-set.route.bundle.upgrade-for')}
              />,
              <TextLabel key='upgrade-2' name='upgrade-price'>
                <TextLabel.Text text={_t.formatCurrency(bundle.priceOffsetWithSelectedBundle)} />
                <Icon name='upgrade' className='medium align-left' />
              </TextLabel>
            ] : null}
          {isAvailable && !isUpgrade
            ? <TextLabel name='price' text={_t.formatCurrency(bundle.price)} /> : null
          }
          {!isAvailable
            ? <TextLabel name='sold-out' text={_t.message('journey-result-set.route.bundle.sold-out')} /> : null
          }
          {bundle.isFilteredOut
            ? (
              <TextLabel
                name='filtered-out'
                text={_t.message('journey-result-set.route.bundle.filtered-out')}
              />
            ) : null
          }
          {isAvailable &&
          bundle.availability > 0 &&
          bundle.availability <= S3P_SETTINGS.s3Passenger.features.availabilityThreshold
            ? (
              <TextLabel
                name='capacity'
                text={_t.message('journey-result-set.route.bundle.availability', {
                  availability: bundle.availability
                })}
              />
            ) : null
          }
        </div>
      </div>
    )
  }
}
