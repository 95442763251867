import {
  API_GET_LEAP_ACTIONS_REQUEST,
  API_GET_LEAP_ACTIONS_SUCCESS,
  API_GET_LEAP_ACTIONS_FAILURE
} from '../../../../actions/types'
import { CLEAR_STATE } from 's3p-js-lib/src/redux/actions/types'

const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_GET_LEAP_ACTIONS_REQUEST:
    case API_GET_LEAP_ACTIONS_FAILURE:
      return initialState
    case API_GET_LEAP_ACTIONS_SUCCESS:
      return action.response.data
    case CLEAR_STATE:
      return action.namespaces.includes('api.modules.leap.getActions') ? initialState : state
    default:
      return state
  }
}
