import React from 'react'
import { Route } from 'react-router'
import AdditionalProductsReviewPaymentLayout from '../../../layouts/aftersales/additional-products/review-payment'
import { isAdditionalProductsAvailableSelector } from '../../../redux/selectors/containers/base/aftersales'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import container from '../../../containers/aftersales/auth'

export default (
  <Route
    key='aftersales-additional-products-review-payment'
    path='/:lang/aftersales/additional-products/review-payment'
    component={container(
      isAdditionalProductsAvailableSelector(originalBookingSelector),
      AdditionalProductsReviewPaymentLayout
    )}
  />
)
