import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { leapReverse } from '../../redux/actions/containers/leap/leap-reverse'
import LeapReverseModal from '../../components/leap/leap-reverse-modal'
import { hideElement } from '../../redux/actions/containers/base/visible-element'
import { isOfflineSelectorCreator } from '../../redux/selectors/machine/offline'
import {
  leapDataSelector
} from '../../redux/selectors/machine/leap'
import { DEVICE_ID_LEAP, LEAP_REVERSE_MODAL } from '../../constants'
import { lastMachineErrorsSelector } from '../../redux/selectors/machine/last-machine-errors'

class LeapReverseModalContainer extends Component {
  static propTypes = {
    leapReverse: PropTypes.func.isRequired,
    bookingNumber: PropTypes.string
  }

  UNSAFE_componentWillMount () {
    this.props.leapReverse()
  }

  render () {
    return (
      <LeapReverseModal
        {...this.props}
        onRetry={this.props.leapReverse}
        disableNextSale
        isCloseable={false}
      />
    )
  }
}

const leapDeviceOfflineSelector = isOfflineSelectorCreator(DEVICE_ID_LEAP)
const _lastMachineErrorsSelector = lastMachineErrorsSelector([DEVICE_ID_LEAP])

const mapStateToProps = state => {
  const reverse = state.containers.leap.reverse
  const isLoading = reverse.loading
  const reverseError = reverse.error
  const reverseSuccess = reverse.success
  const leapCardData = leapDataSelector(state)
  const disableFinishButton = reverseError && !reverseSuccess
  const disableRetryCancelButtons = leapCardData && reverseSuccess

  return {
    isLoading,
    leapCardData,
    isLoadingLeapRead: state.containers.leap.read.loading,
    isOfflineLeapDevice: leapDeviceOfflineSelector(state),
    leapActionError: reverseError,
    leapActionSuccess: reverseSuccess && !_lastMachineErrorsSelector(state).length,
    disableFinish: isLoading || disableFinishButton,
    disableCancel: isLoading || disableRetryCancelButtons,
    disableRetry: isLoading || disableRetryCancelButtons
  }
}

const mapDispatchToProps = {
  leapReverse,
  onHide: () => (hideElement(LEAP_REVERSE_MODAL))
}

export default connect(mapStateToProps, mapDispatchToProps)(LeapReverseModalContainer)
