import React from 'react'
import { connect } from 'react-redux'
import GenericLayout from '../generic-layout'
import MainMenu from '../../containers/base/main-menu/main-menu'
import ProgressNavigation from '../../components/aftersales/cancellation/progress-navigation'
import Operation from '../../containers/aftersales/operation/operation'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import Cancellation from '../../components/aftersales/cancellation/cancellation'
import { actionNotPossibleWithinTimeSelector } from '../../redux/selectors/containers/aftersales/void-tickets'
import PropTypes from '../../misc/prop-types'
import TextLabel from '../../elements/text-label/text-label'
import Icon from '../../elements/icon/icon'
import _t from 's3p-js-lib/src/translate'

const NotPossibleWithinTime = () => (
  <TextLabel name='information'>
    <Icon name='information' className='align-left' />
    <TextLabel.Text text={_t.message('aftersales.void-tickets.voiding-not-possible-within-time')} />
  </TextLabel>
)

const BaseCancellationLayout = ({hasOriginalBooking, actionNotPossibleWithinTime}) => (
  <GenericLayout>
    <MainMenu />

    {actionNotPossibleWithinTime ? <NotPossibleWithinTime /> : hasOriginalBooking && <div>
      <Operation
        labelTitle='aftersales.cancellation.header.title'
        labelInformation='aftersales.cancellation.header.information'
      />
      <Cancellation />
    </div>}
    <ProgressNavigation />
  </GenericLayout>
)

BaseCancellationLayout.propTypes = {
  hasOriginalBooking: PropTypes.bool.isRequired,
  actionNotPossibleWithinTime: PropTypes.bool.isRequired
}

const cancelNotPossibleWithinTimeSelector = actionNotPossibleWithinTimeSelector('CANCEL')

const mapStateToProps = state => ({
  hasOriginalBooking: Boolean(originalBookingSelector(state)),
  actionNotPossibleWithinTime: cancelNotPossibleWithinTimeSelector(state)
})

const CancellationLayout = connect(mapStateToProps)(BaseCancellationLayout)

export default CancellationLayout
