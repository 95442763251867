import {
  MACHINE_READ_BARCODE_REQUEST,
  MACHINE_READ_BARCODE_SUCCESS,
  MACHINE_READ_BARCODE_FAILURE
} from '../../actions/types'

export default (state = null, action) => {
  switch (action.type) {
    case MACHINE_READ_BARCODE_REQUEST:
    case MACHINE_READ_BARCODE_FAILURE:
      return null
    case MACHINE_READ_BARCODE_SUCCESS:
      return (action.response.results && action.response.results.Barcode) || null
    default:
      return state
  }
}
