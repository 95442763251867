import {
  addAdditionalDetails,
  updateAdditionalDetails
} from 's3p-js-lib/src/redux/actions/api/v2/additional-details'
import {
  bookingNumberSelector,
  overviewBookingSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { productsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/products'
import { additionalDetailsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/additional-details'
import {
  CLEAR_DISPLAY_AFTER_SHORT,
  LEAP_ADDITIONAL_DETAIL_KEY_CARD_IDENTIFIER
} from '../../../../constants'
import { leapDataSelector } from '../../../selectors/machine/leap'
import {
  sendMachineDisplayLines,
  timeoutClearDisplay
} from '../../machine/display'
import { setConfigurationLeap } from '../../machine/leap'
import { checkDoubtfulTransactionMessageSelector, configurationExpiryDurationSelector } from '../../../selectors/containers/base/leap'
import _t from 's3p-js-lib/src/translate'
import { cardUsedForDoubtfulTransaction } from './leap-add-product'

export const LEAP_STATUS_OK = 'OK'
export const LEAP_STATUS_ERROR = 'ERROR'
export const LEAP_STATUS_DOUBTFULL = 'DOUBT'
export const LEAP_TRANSACTION_KEY = 'transaction_1'
export const LEAP_STATUS_KEY = 'status'
export const LEAP_TOP_UP_KEY = 'topup'
export const LEAP_ADD_PRODUCT_KEY = 'addproduct'
export const LEAP_REVERSE_KEY = 'reverse'

export const setLeapConfiguration = () => dispatch => {
  const cardExpiryDuration = configurationExpiryDurationSelector()
  return dispatch(setConfigurationLeap({cardExpiryDuration}))
}

export const leapDoubtfulTransaction = () => async (dispatch, getState) => {
  const requestId = 'doubtful_transaction'
  await dispatch(sendMachineDisplayLines([
    _t.message('machine.customer-display.lines.doubtful-transaction.upper'),
    _t.message('machine.customer-display.lines.doubtful-transaction.lower')
  ], requestId))
  dispatch([
    timeoutClearDisplay(CLEAR_DISPLAY_AFTER_SHORT, requestId),
    cardUsedForDoubtfulTransaction(leapDataSelector(getState()).cardIdentifier)
  ])
}

export const addOrUpdateAdditionalDetails = (data, result, leapActionKey, addCardIdentifier = true) => async (dispatch, getState) => {
  const state = getState()
  const products = productsSelector(overviewBookingSelector)(state)
  const statusKey = `${leapActionKey}_${LEAP_STATUS_KEY}`
  const transactionKey = `${leapActionKey}_${LEAP_TRANSACTION_KEY}`

  if (data && data[LEAP_TRANSACTION_KEY] && products[0]) {
    const transaction = data[LEAP_TRANSACTION_KEY]
    let status = result ? LEAP_STATUS_OK : LEAP_STATUS_ERROR
    if (checkDoubtfulTransactionMessageSelector(state)) {
      status = LEAP_STATUS_DOUBTFULL
    }

    const additionalDetailsBooking = additionalDetailsSelector(overviewBookingSelector)(state) || []
    const additionalDetailTransaction = additionalDetailsBooking.find(detail => detail.key === transactionKey)
    const additionalDetailStatus = additionalDetailsBooking.find(detail => detail.key === statusKey)

    const statusValue = additionalDetailStatus
      ? `${additionalDetailStatus.value},${status}`
      : status

    const transactionValue = additionalDetailTransaction
      ? `${additionalDetailTransaction.value},${transaction}`
      : transaction

    const productItemRef = products[0].itemRef
    const additionalDetails = [
      {
        key: statusKey,
        value: statusValue,
        itemRefId: productItemRef
      },
      {
        key: transactionKey,
        value: transactionValue,
        itemRefId: productItemRef
      }
    ]

    if (addCardIdentifier) {
      additionalDetails.push({
        key: LEAP_ADDITIONAL_DETAIL_KEY_CARD_IDENTIFIER,
        value: `${leapDataSelector(state).cardIdentifier}`,
        itemRefId: productItemRef
      })
    }

    const bookingNumber = bookingNumberSelector(overviewBookingSelector)(state)
    if (additionalDetailTransaction && leapActionKey !== LEAP_REVERSE_KEY) {
      await dispatch(updateAdditionalDetails(bookingNumber, additionalDetails))
    } else {
      await dispatch(addAdditionalDetails(bookingNumber, additionalDetails))
    }
  }
}
