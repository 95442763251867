import PropTypes from 'prop-types'
import React, { Component } from 'react'
import StickyFooter from '../../../base/sticky-footer-with-taskbar'
import Button from '../../../../elements/button/button'
import Icon from '../../../../elements/icon/icon'
import Modal from '../../../../elements/modal/modal'
import TextLabel from '../../../../elements/text-label/text-label'
import ComponentContainer from '../../../../elements/component-container/component-container'
import _t from 's3p-js-lib/src/translate'

const ABORT_DIALOG_NAME = 'abort-seat-selection'

export default class ProgressNavigation extends Component {
  static propTypes = {
    segment: PropTypes.shape({
      leg: PropTypes.shape({
        id: PropTypes.any.isRequired
      })
    }),
    messageBase: PropTypes.string,
    previousSegment: PropTypes.shape({
      leg: PropTypes.shape({
        id: PropTypes.string.isRequired,
        departureStation: PropTypes.shape({
          name: PropTypes.string
        }).isRequired,
        arrivalStation: PropTypes.shape({
          name: PropTypes.string
        }).isRequired
      })
    }),
    nextSegment: PropTypes.shape({
      leg: PropTypes.shape({
        id: PropTypes.string.isRequired,
        departureStation: PropTypes.shape({
          name: PropTypes.string
        }).isRequired,
        arrivalStation: PropTypes.shape({
          name: PropTypes.string
        }).isRequired
      })
    }),
    hasSelectedSeats: PropTypes.bool,
    hasPendingChanges: PropTypes.bool,
    selectedSeats: PropTypes.arrayOf(PropTypes.shape({
      passengerId: PropTypes.string.isRequired,
      carriageNumber: PropTypes.string.isRequired,
      seatNumber: PropTypes.string.isRequired
    })).isRequired,
    selectionsLeft: PropTypes.number.isRequired,
    isValid: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    segmentUrl: PropTypes.string.isRequired,
    goForward: PropTypes.func.isRequired,
    confirmSeatSelection: PropTypes.func.isRequired,
    unavailableSegments: PropTypes.arrayOf(PropTypes.shape({
      arrivalStation: PropTypes.shape({
        name: PropTypes.string.isRequired
      }).isRequired,
      departureStation: PropTypes.shape({
        name: PropTypes.string.isRequired
      }).isRequired
    })),
    proceed: PropTypes.func.isRequired,
    abortOrShowDialog: PropTypes.func.isRequired,
    cancelAbort: PropTypes.func.isRequired,
    abortAndGoToMainFlow: PropTypes.func.isRequired,
    confirmAndGoTo: PropTypes.func.isRequired,
    goToSegmentOrMainFlow: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    onAbort: PropTypes.func.isRequired,
    showAbortDialog: PropTypes.bool,
    onAbortContinue: PropTypes.func.isRequired,
    isLoadingUpdateSegment: PropTypes.bool
  }

  constructor (...args) {
    super(...args)
    this.renderAbort = this.renderAbort.bind(this)
  }

  render () {
    return (
      <StickyFooter>
        <ComponentContainer name='sticky-footer'>
          <div className='sticky-footer-content'>
            {this.renderAbortModal()}
            {this.renderAbort()}
            {this.renderSegmentNotification()}
            <div className='segment-navigation'>
              {this.renderPreviousButton()}
              {this.renderNextButton()}
            </div>
          </div>
        </ComponentContainer>
      </StickyFooter>
    )
  }

  renderAbort () {
    return (
      <div className='abort-operation'>
        <Button
          name='abort-operation'
          className='secondary'
          onClick={this.props.onAbort}
        >
          <span className='text'>{_t.message('seat-selector.abort.button')}</span>
        </Button>
      </div>
    )
  }

  renderAbortModal () {
    return this.props.showAbortDialog
      ? (
        <Modal
          name={ABORT_DIALOG_NAME}
          header={<Modal.Header title={_t.message('seat-selector.abort.confirm.title')} />}
          footer={<Modal.Footer
            onContinue={this.props.onAbortContinue}
            isLoadingContinue={this.props.isLoadingUpdateSegment}
          />}
        >
          <TextLabel name='information' text={_t.message('seat-selector.abort.confirm.information')} />
        </Modal>
      ) : null
  }

  renderSegmentNotification () {
    return (
      <div className='segment-notification'>
        {this.props.unavailableSegments && this.props.unavailableSegments.length > 0
          ? (
            <TextLabel name='segment-notification'>
              <Icon name='information' className='medium align-left' />
              <TextLabel.Text text={_t.message('seat-selector.unavailable-segments.information', {
                segments: this.props.unavailableSegments.map(
                  segment => _t.message('seat-selector.unavailable-segments.segment', {
                    departureStation: segment.leg.departureStation.name,
                    arrivalStation: segment.leg.arrivalStation.name
                  })
                ).join(', ')
              })} />
            </TextLabel>
          ) : null}
      </div>
    )
  }

  renderPreviousButton () {
    const {
      previousSegment,
      isLoading,
      messageBase = 'seat-selector.footer'
    } = this.props
    return previousSegment
      ? (
        <Button
          name='previous-segment'
          onClick={this.props.goBack}
          loading={isLoading}
          className='secondary'
        >
          <TextLabel name='next-segment'>
            <Icon name='chevron-left' className='medium align-left' />
            <TextLabel name='previous-segment' text={_t.message(`${messageBase}.previous-leg`)} />
          </TextLabel>
          <TextLabel
            name='previous-segment-description'
            text={_t.message(`${messageBase}.od`, {
              departureStation: previousSegment.leg.departureStation.name,
              arrivalStation: previousSegment.leg.arrivalStation.name
            })}
          />
        </Button>
      ) : null
  }

  renderNextButton () {
    const {
      selectionsLeft,
      selectedSeats,
      nextSegment,
      isLoading,
      isValid,
      hasPendingChanges,
      messageBase = 'seat-selector.footer'
    } = this.props

    const primaryText = _t.message(
      !hasPendingChanges
        ? (
          selectedSeats.length > 0
            ? `${messageBase}.keep-selection`
            : `${messageBase}.assign-automatically`
        )
        : nextSegment
          ? `${messageBase}.next-leg`
          : `${messageBase}.confirm`
    )
    const secondaryText =
      !hasPendingChanges
        ? _t.message(`${messageBase}.skip-leg`)
        : selectionsLeft === 0 && nextSegment
          ? _t.message(`${messageBase}.od`, {
            departureStation: nextSegment.leg.departureStation.name,
            arrivalStation: nextSegment.leg.arrivalStation.name
          })
          : selectionsLeft === 0 && !nextSegment
            ? _t.message(`${messageBase}.finish`)
            : _t.message(`${messageBase}.selections-left`, {selectionsLeft})

    return (
      <Button
        name='next-segment'
        className='primary'
        disabled={hasPendingChanges && (selectionsLeft > 0 || !isValid)}
        onClick={this.props.proceed}
        loading={isLoading}
      >
        <TextLabel name='next-segment'>
          <TextLabel.Text name='next-segment' text={primaryText} />
          <Icon name='chevron-right' className='medium align-right' />
        </TextLabel>
        <TextLabel name='next-segment-description' text={secondaryText} />
      </Button>
    )
  }
}
