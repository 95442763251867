import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ComponentContainer from '../../../elements/component-container/component-container'
import Panel from '../../../elements/panel/panel'
import BookingsSearchForm from '../../../containers/aftersales/bookings/bookings-search-form'
import LastApiErrorFeedback from '../../../containers/base/last-api-error-feedback'
import BookingsSearchResults from './bookings-search-results'
import Feedback, { FEEDBACK_INFORMATION } from '../../../elements/feedback/feedback'
import _t from 's3p-js-lib/src/translate'
import './bookings.scss'

export default class Bookings extends Component {
  static propTypes = {
    bookings: PropTypes.array
  }

  render () {
    return (
      <ComponentContainer name='bookings'>
        <Panel
          header={
            <Panel.Header
              name='bookings'
              title={_t.message('bookings-filter.title')}
              description={_t.message('bookings-filter.description')}
            />
          }
        >
          <BookingsSearchForm />
          {this.props.bookings && this.props.bookings.length
            ? <BookingsSearchResults bookings={this.props.bookings} />
            : this.props.bookings
              ? <Feedback
                status={FEEDBACK_INFORMATION}
                text={_t.message('bookings-results.table.no-results')}
              />
              : null}
          <LastApiErrorFeedback namespaces={['getBookings']} defaultMessage='messages.booking-search-not-specific' />
        </Panel>
      </ComponentContainer>
    )
  }
}
