import {
  API_UPDATE_PASSENGERS_UPDATE_REQUEST,
  API_UPDATE_PASSENGERS_UPDATE_SUCCESS,
  API_UPDATE_PASSENGERS_UPDATE_FAILURE
} from 's3p-js-lib/src/redux/actions/types'
import ensureToken from 's3p-js-lib/src/api/ensure-token'
import ResponseCodes from 's3p-js-lib/src/api/response-codes'
import Client from '../../../../../api/client'

const updatePassengersUpdateRequest = (bookingNumber, passengers, waiveFees, overrideAftersalesRules) => ({
  type: API_UPDATE_PASSENGERS_UPDATE_REQUEST,
  bookingNumber,
  passengers,
  waiveFees,
  overrideAftersalesRules
})
const updatePassengersUpdateSuccess = response => ({type: API_UPDATE_PASSENGERS_UPDATE_SUCCESS, response})
const updatePassengersUpdateFailure = error => ({type: API_UPDATE_PASSENGERS_UPDATE_FAILURE, error})

export const updatePassengersUpdate = (bookingNumber, passengers, waiveFees, overrideAftersalesRules, overrideValidationsRules) => ensureToken(async function (token, dispatch) {
  try {
    dispatch(updatePassengersUpdateRequest(bookingNumber, passengers, waiveFees, overrideAftersalesRules))
    const response = await Client.updatePassengersUpdate(token, bookingNumber, passengers, waiveFees, overrideAftersalesRules, overrideValidationsRules)
    dispatch(updatePassengersUpdateSuccess(response))
  } catch (error) {
    dispatch(updatePassengersUpdateFailure(error))
    const handledErrors = [ResponseCodes.AFTER_SALES_OPERATION_HAS_INVALID_ITEMS]
    if (!handledErrors.includes(error.errorCode)) {
      throw error
    }
    return false
  }
})
