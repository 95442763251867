import _t from 's3p-js-lib/src/translate'
import {sendMachineDisplayLines, timeoutClearDisplay} from '../../../machine/display'
import {CLEAR_DISPLAY_AFTER_MEDIUM} from '../../../../../constants'

export const displayNextCustomer = () => async dispatch => {
  const requestId = 'next-customer'

  await dispatch(sendMachineDisplayLines([_t.message('finalize-booking-modal.next-customer')], requestId))

  dispatch(timeoutClearDisplay(CLEAR_DISPLAY_AFTER_MEDIUM, requestId))
}
