import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Panel from '../../../elements/panel/panel'
import ComponentContainer from '../../../elements/component-container/component-container'
import Feedback, { FEEDBACK_INFORMATION } from '../../../elements/feedback/feedback'
import TextLabel from '../../../elements/text-label/text-label'
import Icon from '../../../elements/icon/icon'
import SegmentSet from './cancel-items/segment-set'
import './cancel-items.scss'

export default class CancelItems extends Component {
  static propTypes = {
    hasProvisionalBooking: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    outboundSegments: PropTypes.array,
    outboundTravelInfo: PropTypes.object,
    inboundSegments: PropTypes.array,
    inboundTravelInfo: PropTypes.object,
    onToggleItem: PropTypes.func.isRequired
  }

  render () {
    const {
      outboundSegments,
      outboundTravelInfo,
      inboundSegments,
      inboundTravelInfo,
      hasProvisionalBooking,
      disabled,
      onToggleItem
    } = this.props

    return (
      <ComponentContainer name='cancellation-items'>
        <Panel header={<Panel.Header
          name='cancellation-segments'
          title={_t.message('aftersales.cancellation.individual-items.title')}
          description={_t.message('aftersales.cancellation.individual-items.description')} />
        }>
          {hasProvisionalBooking
            ? (
              <Feedback
                status={FEEDBACK_INFORMATION}
                text={_t.message('aftersales.cancellation.confirmation.information')}
              />
            ) : null}
          <div className='travel-details'>
            {outboundSegments
              ? (
                <SegmentSet
                  segments={outboundSegments}
                  travelInfo={outboundTravelInfo}
                  onToggleItem={onToggleItem}
                  disabled={disabled}
                  confirm={hasProvisionalBooking}
                />
              ) : null}
            {inboundSegments
              ? (
                <SegmentSet
                  segments={inboundSegments}
                  travelInfo={inboundTravelInfo}
                  onToggleItem={onToggleItem}
                  disabled={disabled}
                  confirm={hasProvisionalBooking}
                />
              ) : null}
            {!hasProvisionalBooking
              ? (
                <div className='refund-details'>
                  <TextLabel name='information'>
                    <Icon name='information' className='medium align-left' />
                    <TextLabel.Text text={_t.message('aftersales.cancellation.refund-details.next-step')} />
                  </TextLabel>
                </div>
              ) : null}
          </div>
        </Panel>
      </ComponentContainer>
    )
  }
}
