import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classnames from 'classnames'
import Icon from '../icon/icon'
import TextLabel from '../text-label/text-label'
import Mousetrap from 'mousetrap'
import './accordion.scss'

export default class Accordion extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    available: PropTypes.bool,
    expanded: PropTypes.bool,
    header: PropTypes.node.isRequired,
    collapsedContent: PropTypes.element,
    onClick: PropTypes.func,
    icon: PropTypes.element,
    description: PropTypes.string,
    price: PropTypes.string,
    autoFocus: PropTypes.bool
  }

  static defaultProps = {
    available: true,
    expanded: false,
    autoFocus: false
  }

  constructor (...args) {
    super(...args)
    this.onClick = this.onClick.bind(this)
  }

  componentDidMount () {
    const mousetrap = new Mousetrap(this._element)
    mousetrap.bind('enter', this.onClick)
    mousetrap.bind('space', this.onClick)
    if (this.props.autoFocus) {
      this._element.focus()
    }
  }

  onClick (event) {
    event && event.preventDefault()
    this.props.onClick(this.props)
  }

  render () {
    const {
      expanded, available, name, icon, header, collapsedContent, children, description,
      price
    } = this.props
    const className = classnames(
      'accordion',
      {
        'state--is-selected': expanded,
        'state--is-available': available && !expanded,
        'state--is-unavailable': !available
      }
    )

    return (
      <div
        className={className}
        role='tablist'>
        <div className='accordion__header' role='tab' id={`${name}-accordion-header`}
          aria-controls={`${name}-accordion-content`}
          aria-selected={expanded ? 'true' : 'false'}
          aria-expanded={expanded ? 'true' : 'false'}
          tabIndex={available ? '0' : null}
          onClick={this.onClick}
          ref={element => { this._element = element }}
        >
          {icon ? <div className='accordion__header-icon'>{icon}</div> : null}
          <div className='accordion__header-content'>
            {typeof header === 'string' ? <TextLabel name='heading' text={header} /> : header}
            <TextLabel name='price' text={price} />
            <TextLabel name='description' text={description} />
          </div>
          <div className='accordion__header-indicator'>
            <Icon name={expanded ? 'chevron-up' : 'chevron-down'} />
          </div>
        </div>
        {expanded || collapsedContent
          ? (
            <div
              className='accordion__content' role='tabpanel'
              id={`${name}-accordion-content`}
              aria-labelledby={`${name}-accordion-header`}
              aria-hidden='false'
            >
              {expanded ? children : collapsedContent}
            </div>
          )
          : null}
      </div>
    )
  }
}
