import {
  API_AFTERSALES_REVERT_REQUEST,
  API_AFTERSALES_REVERT_SUCCESS,
  API_AFTERSALES_REVERT_FAILURE
} from '../../types'
import Client from '../../../../api/client'
import ensureToken from '../../../../api/ensure-token'

const aftersalesRevertRequest = bookingNumber => ({type: API_AFTERSALES_REVERT_REQUEST, bookingNumber})
const aftersalesRevertSuccess = response => ({type: API_AFTERSALES_REVERT_SUCCESS, response})
const aftersalesRevertFailure = error => ({type: API_AFTERSALES_REVERT_FAILURE, error})

export const aftersalesRevert = bookingNumber => ensureToken(async function (token, dispatch) {
  try {
    dispatch(aftersalesRevertRequest(bookingNumber))
    const response = await Client.aftersalesRevert(token, bookingNumber)
    dispatch(aftersalesRevertSuccess(response))
  } catch (error) {
    dispatch(aftersalesRevertFailure(error))
    throw error
  }
})
