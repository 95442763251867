import { UI_LEAP_DOUBTFUL_TRANSACTION_CARD_IDENTIFIER, UI_LEAP_DOUBTFUL_TRANSACTION_TOP_UP_VALUE } from '../../../actions/types'
import { CLEAR_STATE } from 's3p-js-lib/src/redux/actions/types'
import { CONTAINERS_LEAP_DOUBTFUL_TRANSACTION } from '../../../../constants'

const initialState = {
  cardIdentifier: null,
  currentPurseBalance: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UI_LEAP_DOUBTFUL_TRANSACTION_CARD_IDENTIFIER:
      return {...state, cardIdentifier: action.cardIdentifier}
    case UI_LEAP_DOUBTFUL_TRANSACTION_TOP_UP_VALUE:
      return {...state, currentPurseBalance: action.currentPurseBalance}
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_LEAP_DOUBTFUL_TRANSACTION) ? initialState : state
    default:
      return state
  }
}
