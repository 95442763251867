import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Form from '../../../../elements/form/form'
import Checkbox from './checkbox'
import _t from 's3p-js-lib/src/translate'

export default class CancelByPassenger extends Component {
  static propTypes = {
    passengerCancellationData: PropTypes.shape({
      id: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    }).isRequired,
    onToggle: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  }

  render () {
    const {passengerCancellationData, disabled, onToggle} = this.props
    const labelData = {
      firstName: (
        passengerCancellationData.firstName ||
        _t.message('aftersales.cancellation.passenger-unknown-name', {number: passengerCancellationData.number})
      ),
      lastName: passengerCancellationData.lastName,
      type: passengerCancellationData.type
    }

    return (
      <Form.InputGroup name='passenger'>
        <Form.InputWrapper name='passenger'>
          <Checkbox
            id={passengerCancellationData.id}
            name='passenger'
            onToggle={onToggle}
            cancellationData={passengerCancellationData}
            labelText={_t.message('aftersales.cancellation.cancel-by.form.passenger.label', labelData)}
            disabled={disabled}
          />
        </Form.InputWrapper>
      </Form.InputGroup>
    )
  }
}
