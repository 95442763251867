import { createSelector } from 'reselect'
import {
  subFormFieldValuesSelectorCreator,
  formFieldValueSelectorCreator
} from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import { groupNameSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/passengers'
import {
  passengersCompareSelector,
  passengerCompareString,
  protectPrivacySelector,
  passengersSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/passengers'
import { FORM_PASSENGER_DETAILS_AFTERSALES } from 's3p-js-lib/src/constants'

export const changedPassengersSelector = createSelector(
  [
    passengersSelector(originalBookingSelector),
    subFormFieldValuesSelectorCreator(FORM_PASSENGER_DETAILS_AFTERSALES),
    formFieldValueSelectorCreator(FORM_PASSENGER_DETAILS_AFTERSALES, 'protectPrivacy')
  ],
  (originalPassengers, formPassengers, protectPrivacy) => {
    protectPrivacy = Boolean(protectPrivacy)

    return originalPassengers.reduce(
      (changes, passenger) => {
        const formPassenger = formPassengers[passenger.id]
        if (formPassenger && (
          passengerCompareString(passenger) !== passengerCompareString(formPassenger) ||
          formPassenger.protectPrivacy !== protectPrivacy
        )) {
          changes.push({
            id: passenger.id,
            firstName: formPassenger.firstName || '',
            lastName: formPassenger.lastName || '',
            emailAddress: formPassenger.emailAddress || '',
            phoneNumber: formPassenger.phoneNumber,
            protectPrivacy
          })
        }
        return changes
      },
      []
    )
  }
)

export const hasChangedPassengersSelector = createSelector(
  [
    protectPrivacySelector(originalBookingSelector),
    formFieldValueSelectorCreator(FORM_PASSENGER_DETAILS_AFTERSALES, 'protectPrivacy'),
    passengersCompareSelector(originalBookingSelector),
    subFormFieldValuesSelectorCreator(FORM_PASSENGER_DETAILS_AFTERSALES)
  ],
  (originalProtectPrivacy, protectPrivacy, originalPassengersCompare, subFormValues) => (
    Boolean(protectPrivacy) !== originalProtectPrivacy ||
    Object.keys(subFormValues).some(key => passengerCompareString(subFormValues[key]) !== originalPassengersCompare[key])
  )
)

export const hasChangedGroupNameSelector = createSelector(
  [
    groupNameSelector(originalBookingSelector),
    formFieldValueSelectorCreator(FORM_PASSENGER_DETAILS_AFTERSALES, 'groupName')
  ],
  (originalGroupName, groupName) => Boolean(
    originalGroupName && groupName && originalGroupName.trim() !== groupName.trim()
  )
)
