import { createSelector } from 'reselect'
import { passengersSelector } from './passengers'
import {
  outboundTariffSegmentsSelector,
  inboundTariffSegmentsSelector
} from './tariff-segments'
import { travelSelector } from './travel'

export const journeySearchFromBookingSelector = bookingSelector => createSelector(
  [
    passengersSelector(bookingSelector),
    travelSelector(outboundTariffSegmentsSelector(bookingSelector)),
    travelSelector(inboundTariffSegmentsSelector(bookingSelector))
  ],
  (passengers, outboundTravel, inboundTravel) =>
    passengers && outboundTravel
      ? {
        origin: outboundTravel.departureStation && outboundTravel.departureStation.UICStationCode,
        destination: outboundTravel.arrivalStation && outboundTravel.arrivalStation.UICStationCode,
        outboundDate: outboundTravel.departureDate && outboundTravel.departureDate.format('YYYY-MM-DD'),
        inboundDate: inboundTravel && inboundTravel.departureDate && inboundTravel.departureDate.format('YYYY-MM-DD'),
        passengers: passengers.map(passenger => ({type: passenger.type}))
      }
      : null
)
