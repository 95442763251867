import {createSelector} from 'reselect'
import {
  baseOfferSelector,
  bundleByIdSelector,
  firstAndLastLegsByIdsSelector,
  passengerByIdSelector
} from 's3p-js-lib/src/redux/selectors/api/orientation/offer'
import {selectedBundlesByJourneyIdSelector} from 's3p-js-lib/src/redux/selectors/api/orientation/selected-bundles'

export const createV1AdditionalProductsResponseSelector = journeyId => createSelector(
  baseOfferSelector,
  selectedBundlesByJourneyIdSelector(journeyId),
  state => state,
  (offer, selectedBundles, state) => {
    return selectedBundles.reduce(
      (addons, selectedBundle) => {
        const bundle = bundleByIdSelector(selectedBundle.id)(state)
        bundle && (bundle?.additionalProductsSummaries || []).forEach(addonProductSummary => {
          const bundleItems = bundle.items.filter(
            item => !item.required && item.product_type === addonProductSummary.product_type
          )
          if (bundleItems.length) {
            let bucketCode, inventoryClass
            addons.push({
              lowest_price: addonProductSummary.lowest_price,
              id: `product-${bundleItems[0].product_code}`,
              name: bundleItems[0].name,
              description: bundleItems[0].description,
              type: addonProductSummary.product_type,
              price_specifications: bundleItems.map(bundleItem => {
                const firstAndLastLegs = firstAndLastLegsByIdsSelector(bundleItem.leg_ids)(state)
                return firstAndLastLegs.length === 2 ? {
                  origin: firstAndLastLegs[0].departureStation.name,
                  destination: firstAndLastLegs[1].arrivalStation.name,
                  legs: bundleItem.leg_ids,
                  bundle_id: bundle.id,
                  travel_id: selectedBundle.travel_id,
                  item_id: bundleItem.id,
                  passengers: bundleItem.passenger_fares.map(passengerFare => {
                    bucketCode = passengerFare.bucket_code
                    inventoryClass = passengerFare.inventory_class
                    return {
                      id: passengerFare.passenger_id,
                      type: passengerByIdSelector(passengerFare.passenger_id)(state)?.type,
                      price: passengerFare.price
                    }
                  })
                } : null
              }),
              tariff: {
                bucket: {
                  code: bucketCode,
                  inventory_class: inventoryClass
                }
              }
            })
          }
        })
        return addons
      },
      []
    )
  }
)
