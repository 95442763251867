import {
  API_CREATE_PROVISIONAL_BOOKING_REQUEST,
  API_CREATE_PROVISIONAL_BOOKING_SUCCESS,
  API_CREATE_PROVISIONAL_BOOKING_FAILURE
} from '../../types'
import ResponseCodes from '../../../../api/response-codes'
import Client from '../../../../../../src/api/client'
import ensureToken from '../../../../api/ensure-token'
import {createProvisionalBookingRequestSelector} from 's3p-js-lib/src/redux/actions/api/booking/create-mapper'

const createProvisionalBookingRequest = journeyId => ({type: API_CREATE_PROVISIONAL_BOOKING_REQUEST, journeyId})
const createProvisionalBookingSuccess = response => ({type: API_CREATE_PROVISIONAL_BOOKING_SUCCESS, response})
const createProvisionalBookingFailure = error => ({type: API_CREATE_PROVISIONAL_BOOKING_FAILURE, error})

export const createProvisionalBooking = () => ensureToken(async (token, dispatch, getState) => {
  try {
    const request = createProvisionalBookingRequestSelector(getState())
    dispatch(createProvisionalBookingRequest(request))
    const response = await Client.createProvisionalBooking(token, request)
    dispatch(createProvisionalBookingSuccess(response))
  } catch (error) {
    dispatch(createProvisionalBookingFailure(error))
    if (ResponseCodes.isSystemError(error)) {
      throw error
    }
    return false
  }
})

export const replaceProvisionalBooking = booking => ensureToken(async (token, dispatch, getState) => {
  try {
    await Client.cancelBooking(token, booking.booking_number)
    const request = createProvisionalBookingRequestSelector(getState())
    if (booking.customer) {
      request.customer = booking.customer
    }
    dispatch(createProvisionalBookingRequest(request))
    const response = await Client.createProvisionalBooking(token, request)
    dispatch(createProvisionalBookingSuccess(response))
  } catch (error) {
    dispatch(createProvisionalBookingFailure(error))
    if (ResponseCodes.isSystemError(error)) {
      throw error
    }
    return false
  }
})
