import React from 'react'
import { Route } from 'react-router'
import TicketsLayout from '../layouts/tickets'
import containAuth from '../containers/base/auth'
import { AGENT_ROLE_OPERATOR, AGENT_ROLE_TECHNICIAN } from '../constants'

export default (
  <Route
    key='tickets'
    path='/:lang/tickets'
    component={containAuth(TicketsLayout, {roles: [AGENT_ROLE_OPERATOR, AGENT_ROLE_TECHNICIAN]})}
  />
)
