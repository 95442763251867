import { connect } from 'react-redux'
import { tableSortSelector } from '../../../../redux/selectors/containers/base/table'
import { SORT_FIND_CUSTOMERS } from '../../../../constants'
import { toggleSortColumn } from '../../../../redux/actions/containers/base/table'
import FindCustomersHeader from '../../../../components/reservations/booking-details/find-customers/find-customers-header'

const sortSelector = tableSortSelector(SORT_FIND_CUSTOMERS.TABLE_NAME)

const mapStateToProps = state => {
  const sort = sortSelector(state)

  return {
    sortedByFullName: sort.column === SORT_FIND_CUSTOMERS.FULL_NAME ? sort.order : null,
    sortedByEmailAddress: sort.column === SORT_FIND_CUSTOMERS.EMAIL_ADDRESS ? sort.order : null
  }
}

const mapDispatchToProps = {
  onSortByFullName: toggleSortColumn(SORT_FIND_CUSTOMERS.TABLE_NAME, SORT_FIND_CUSTOMERS.FULL_NAME),
  onSortByEmailAddress: toggleSortColumn(SORT_FIND_CUSTOMERS.TABLE_NAME, SORT_FIND_CUSTOMERS.EMAIL_ADDRESS)
}

export default connect(mapStateToProps, mapDispatchToProps)(FindCustomersHeader)
