import {createSelector} from 'reselect'
import {
  journeySegmentsSelector,
  productsSelector,
  selectedSeatsSelector,
  passengersSelector,
  seatPreferencesSelector
} from 's3p-js-lib/src/redux/selectors/api/orientation/specification'
import moment from 'moment-timezone'

export const createProvisionalBookingRequestSelector = createSelector(
  journeySegmentsSelector,
  productsSelector,
  selectedSeatsSelector,
  passengersSelector,
  seatPreferencesSelector,
  (journeySegments, products, selectedSeats, passengers, seatPreferences) => {
    return {
      segments: journeySegments.map(journeySegment => ({
        origin: journeySegment.departure_station._u_i_c_station_code,
        destination: journeySegment.arrival_station._u_i_c_station_code,
        direction: journeySegment.direction,
        start_validity_date: moment(journeySegment.departure_date_time).format('YYYY-MM-DD'),
        service_name: journeySegment.service_name,
        service_identifier: journeySegment.service_identifier,
        items: products.reduce(
          (productItems, product) => {
            if (product.legs.includes(journeySegment.leg_id)) {
              const productItem = {
                passenger_id: product.passenger_id,
                tariff_code: product.tariff_code,
                price: product.price
              }
              if (product.isRequired) {
                const matchedSelectedSeat = selectedSeats.find(
                  selectedSeat => selectedSeat.passenger_id === product.passenger_id && product.legs.includes(selectedSeat.leg_id)
                )
                if (matchedSelectedSeat) {
                  productItem.seat = {
                    number: matchedSelectedSeat.seat_number,
                    carriage: matchedSelectedSeat.carriage_number
                  }
                }
                const matchedSeatPreference = seatPreferences.find(
                  seatPreference => seatPreference.passenger_id === product.passenger_id && product.legs.includes(seatPreference.leg_id)
                )
                if (matchedSeatPreference && matchedSeatPreference?.seat_property_codes?.length) {
                  productItem.seat_preferences = matchedSeatPreference.seat_property_codes
                }
              }
              productItems.push(productItem)
            }
            return productItems
          }, []
        )
      })),
      passengers: passengers.map(passenger => ({...passenger, protect_privacy: false}))
    }
  }
)
