import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

const container = WrappedComponent => (class CheckAccessBySelectorContainer extends Component {
  static propTypes = {
    isAllowed: PropTypes.bool,
    onNotAllowed: PropTypes.func.isRequired
  }

  UNSAFE_componentWillMount () {
    if (!this.props.isAllowed && this.props.onNotAllowed) {
      this.props.onNotAllowed()
    }
  }

  render () {
    const {isAllowed, ...props} = this.props
    delete props.onNotAllowed

    return isAllowed ? <WrappedComponent {...props} /> : null
  }
})

const connectState = options => {
  const mapStateToProps = state => {
    const props = {isAllowed: !options.selector || options.selector(state)}
    return () => props
  }

  const mapDispatchToProps = {
    onNotAllowed: options.onNotAllowed
  }

  return connect(mapStateToProps, mapDispatchToProps)
}

export default (options = {}) => compose(
  connectState(options),
  container
)
