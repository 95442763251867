import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../elements/text-label/text-label'
import TileSet from '../../../elements/tile-set/tile-set'
import Modal from '../../../elements/modal/modal'
import Loader, {TYPE_COMPONENT} from '../../../elements/loader/loader'

export const MORE_MODAL = 'more-results'

export default class TilesWithModal extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    translatePrefix: PropTypes.string.isRequired,
    maxTiles: PropTypes.number.isRequired,
    rowCount: PropTypes.number,
    handleSelect: PropTypes.func.isRequired,
    handleShowModal: PropTypes.func.isRequired,
    handleHideModal: PropTypes.func.isRequired,
    showMoreModal: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    tile: PropTypes.any.isRequired,
    hotkey: PropTypes.shape({
      tiles: PropTypes.arrayOf(PropTypes.string),
      more: PropTypes.string
    })
  }

  static defaultProps = {
    hotkey: {},
    disabled: false
  }

  render () {
    const tiles = this.props.data || []
    const showModalTile = tiles.length > this.props.maxTiles
    const data = showModalTile ? tiles.slice(0, this.props.maxTiles - 1) : tiles

    return (
      <TileSet rowCount={this.props.rowCount} disabled={this.props.disabled}>
        {this.renderTiles(data)}
        {showModalTile
          ? (
            <TileSet.Tile
              className='more'
              onClick={this.props.handleShowModal}
              disabled={this.props.disabled}
              hotkey={this.props.hotkey.more}
              tabIndex={-1}
            >
              <TextLabel name={this.props.name} text={_t.message(`${this.props.translatePrefix}.more-button`)} />
              {this.props.showMoreModal ? this.renderMoreModal() : null}
            </TileSet.Tile>
          ) : null}
      </TileSet>
    )
  }

  renderMoreModal () {
    return (
      <Modal
        name={MORE_MODAL}
        header={<Modal.Header title={_t.message(`${this.props.translatePrefix}.title-more-modal`)} />}
        onHideElement={this.props.handleHideModal}
      >
        <div className={`more-${this.props.name}`}>
          {this.props.loading ? <Loader type={TYPE_COMPONENT} /> : null}
          <TileSet rowCount={this.props.rowCount}>
            {this.renderTiles(this.props.data, {disabled: false, hotkey: undefined})}
          </TileSet>
        </div>
      </Modal>
    )
  }

  renderTiles (data, tileProps = {}) {
    const Tile = this.props.tile
    return (data || []).map((object, index) => (
      <Tile
        key={index}
        index={index}
        autoFocus={index === 0}
        handleSelect={this.props.handleSelect}
        object={object}
        disabled={this.props.disabled || object.disabled}
        hotkey={this.props.hotkey.tiles && this.props.hotkey.tiles[index]}
        {...tileProps}
      />
    ))
  }
}
