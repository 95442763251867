import React, {Component} from 'react'
import _t from 's3p-js-lib/src/translate'
import PropTypes from '../../../../misc/prop-types'
import Icon from '../../../../elements/icon/icon'
import TextLabel from '../../../../elements/text-label/text-label'
import ClickableRow from '../../../../elements/table/clickable-row'
import BookingStatus from '../../../base/booking/status'

export default class BookingRow extends Component {
  static propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    outboundRoute: PropTypes.shape({
      origin: PropTypes.string,
      destination: PropTypes.string,
      dateTime: PropTypes.moment
    }),
    inboundRoute: PropTypes.shape({
      dateTime: PropTypes.moment
    }),
    outboundStartValidityDate: PropTypes.moment,
    bookingDateTime: PropTypes.moment,
    bookingReference: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    passengerCount: PropTypes.number,
    products: PropTypes.array,
    price: PropTypes.number
  }

  createCustomerName (lastName, firstName) {
    let fullName
    if (firstName && lastName) {
      fullName = lastName + ', ' + firstName
    } else if (firstName) {
      fullName = firstName
    } else if (lastName) {
      fullName = lastName
    }
    return fullName
  }

  render () {
    return (
      <ClickableRow onClick={this.props.onClick}>
        <td>
          <TextLabel name='customer' text={this.createCustomerName(this.props.lastName, this.props.firstName)} />
        </td>
        <td>
          {this.props.products[0]?.name
            ? <TextLabel name='product-description' text={this.props.products[0].name} />
            : null }
        </td>
        <td>
          {this.props.outboundStartValidityDate
            ? <TextLabel name='date-outbound' text={_t.formatMomentDate(this.props.outboundStartValidityDate, 'dayMonthShort')} />
            : null}
          {this.props.inboundRoute
            ? (
              <TextLabel name='date-inbound'>
                <Icon name='return' className='align-left' />
                <TextLabel.Text text={_t.formatMomentDate(this.props.inboundRoute.dateTime, 'dayMonthShort')} />
              </TextLabel>
            ) : null}
        </td>
        <td>
          {this.props.outboundRoute && this.props.outboundRoute.origin && this.props.outboundRoute.destination
            ? (
              <TextLabel
                name='route'
                text={`${this.props.outboundRoute.origin} - ${this.props.outboundRoute.destination}`}
              />
            )
            : <TextLabel name='route' text={_t.message('bookings-results.table.no-route')} />
          }
        </td>
        <td>
          <TextLabel name='date-booked' text={_t.formatMomentDate(this.props.bookingDateTime, 'dayMonthShort')} />
          <TextLabel name='time-booked' text={_t.formatMomentTime(this.props.bookingDateTime, 'short')} />
        </td>
        <td>
          <TextLabel name='booking-reference' text={this.props.bookingReference} />
        </td>
        <td>
          <BookingStatus {...this.props} />
        </td>
        <td>
          <TextLabel name='pax' text={this.props.passengerCount.toString()} />
        </td>
        <td>
          <TextLabel name='price' text={_t.formatCurrency(this.props.price, 'EUR')} />
        </td>
      </ClickableRow>
    )
  }
}
