import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../elements/text-label/text-label'
import Icon from '../../../elements/icon/icon'
import Button from '../../../elements/button/button'
import { PAYMENT_METHOD_CODE_VOUCHER } from '../../../constants'
import { PAYMENT_STATUS_P } from 's3p-js-lib/src/constants'

export default class PaymentOverviewPayment extends Component {
  static propTypes = {
    payment: PropTypes.shape({
      id: PropTypes.string.isRequired,
      method: PropTypes.string.isRequired,
      paymentStatus: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      voucher: PropTypes.shape({
        code: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired
      })
    }),
    removeVoucher: PropTypes.func.isRequired,
    returnPayments: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.bool
  }

  static defaultProps = {
    disabled: false
  }

  constructor (...args) {
    super(...args)
    this.handleRemoveVoucher = this.handleRemoveVoucher.bind(this)
  }

  handleRemoveVoucher () {
    if (this.props.payment.voucher) {
      this.props.removeVoucher(this.props.payment.voucher.code)
    }
  }

  render () {
    const isVoucher = PAYMENT_METHOD_CODE_VOUCHER === this.props.payment.method
    const value = isVoucher ? this.props.payment.voucher : this.props.payment
    return (
      <tr className='payment'>
        <td>
          {this.props.payment.paymentStatus === PAYMENT_STATUS_P
            ? <Button
              tabIndex='-1'
              type='button'
              name='remove-voucher'
              className='alert'
              onClick={isVoucher ? this.handleRemoveVoucher : this.props.returnPayments}
              disabled={this.props.disabled}
              loading={this.props.loading}
            >
              <TextLabel name='remove-voucher'>
                <Icon name='subtract' />
                <TextLabel.Text name='remove-voucher' text={_t.message('review-payment.remove-voucher')} />
              </TextLabel>
            </Button> : null}
          <TextLabel
            name='payment-description'
            text={_t.message(
              'review-payment.payment-description',
              {method: this.props.payment.method, code: value.code}
            )}
          />
        </td>
        <td><TextLabel name='payment-value' text={_t.formatCurrency(value.amount, value.currency)} /></td>
      </tr>
    )
  }
}
