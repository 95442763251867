import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Indicator from '../../../elements/indicator/indicator'

export default class BookingStatus extends Component {
  static propTypes = {
    isCancelled: PropTypes.bool,
    isProvisional: PropTypes.bool,
    isConfirmed: PropTypes.bool,
    isOnHoldProvisional: PropTypes.bool,
    isOnHoldExpired: PropTypes.bool,
    isExpired: PropTypes.bool
  }

  render () {
    const status = (
      ((this.props.isOnHoldExpired || this.props.isExpired) && 'expired') ||
      (this.props.isOnHoldProvisional && 'on-hold') ||
      (this.props.isCancelled && 'cancelled') ||
      (this.props.isProvisional && 'provisional') ||
      (this.props.isConfirmed && 'confirmed') ||
      null
    )

    return status ? <Indicator name='status' className={status} text={_t.message(`booking-status.${status}`)} /> : null
  }
}
