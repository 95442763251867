import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { stationNameToSearchString } from 's3p-js-lib/src/redux/selectors/api/base/stations'

export default class ListItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      searchString: PropTypes.string.isRequired
    }).isRequired,
    text: PropTypes.string.isRequired,
    searchValue: PropTypes.string
  }

  render () {
    const lowerSearch = this.props.searchValue ? this.props.searchValue.toLowerCase() : ''
    const search = stationNameToSearchString(lowerSearch)
    const index = this.props.item.searchString.indexOf(search)
    const text = this.props.text

    let preText = ''
    let searchText = text
    let postText = ''
    if (index >= 0 && search.length) {
      const lowerText = text.toLowerCase()
      const startIndex = lowerText.indexOf(lowerSearch.charAt(0), index > 1 ? index - 1 : 0)
      const endIndex = lowerText.indexOf(lowerSearch.slice(-1), index + search.length - 1) + 1
      preText = text.substring(0, startIndex)
      searchText = text.substring(startIndex, endIndex)
      postText = text.substring(endIndex)
    }

    return searchText === text
      ? <span>{text}</span>
      : <span>{preText}
        <mark>{searchText}</mark>
        {postText}</span>
  }
}
