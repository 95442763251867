import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _t from 's3p-js-lib/src/translate'
import { routerActions } from 'react-router-redux'
import {
  completedBookingSelector,
  bookingNumberSelector,
  paymentRequiredSelector,
  bookingSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import FinalizeBooking from '../../base/finalize-booking/finalize-booking'
import { hideElement, showModal } from '../../../redux/actions/containers/base/visible-element'
import { resetReservations } from '../../../redux/actions/containers/reservations/finalize-booking'
import { FINALIZE_BOOKING_MODAL } from '../../../constants'
import { clearMachineDisplay } from '../../../redux/actions/machine/display'
import { confirmBooking } from 's3p-js-lib/src/redux/actions/api/v2/confirm-booking'
import { printReceipt } from '../../../redux/actions/containers/base/tiles/payment-methods'
import { goToChangeFulfillment } from '../../../redux/actions/containers/base/void-tickets-routing'
import {displayNextCustomer} from '../../../redux/actions/containers/base/finalize-booking/next-customer'

class FinalizeBookingContainer extends Component {
  static propTypes = {
    finalizeBooking: PropTypes.func.isRequired
  }

  UNSAFE_componentWillMount () {
    this.props.finalizeBooking()
  }

  render () {
    return <FinalizeBooking {...this.props} />
  }
}

const _paymentRequiredSelector = paymentRequiredSelector(bookingSelector)
const _bookingNumberSelector = bookingNumberSelector(completedBookingSelector)

const mapDispatchToProps = {
  onChangeFulfillment: () => (dispatch, getState) => {
    const bookingNumber = bookingNumberSelector(completedBookingSelector)(getState())
    dispatch([
      hideElement(FINALIZE_BOOKING_MODAL),
      resetReservations(),
      goToChangeFulfillment(bookingNumber)
    ])
  },
  onNextSale: () => [
    hideElement(FINALIZE_BOOKING_MODAL),
    resetReservations(),
    clearMachineDisplay(),
    routerActions.push(`/${_t.getLocales()}/reservations/journey-search`),
    displayNextCustomer()
  ],
  finalizeBooking: () => async (dispatch, getState) => {
    if (!_paymentRequiredSelector(getState())) {
      dispatch(showModal(FINALIZE_BOOKING_MODAL))
      if (!_bookingNumberSelector(getState())) {
        await dispatch(confirmBooking())
        await dispatch(printReceipt())
      }
    }
  }
}

export default connect(null, mapDispatchToProps)(FinalizeBookingContainer)
