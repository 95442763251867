import { connect } from 'react-redux'
import LeapReadCard from '../../components/leap/leap-read-card'
import { readLeapCard } from '../../redux/actions/containers/leap/leap-read'
import {
  leapDataSelector,
  leapCardIsExpiredSelector,
  leapCardIsBlockedSelector,
  leapPurseIsBlockedSelector,
  leapCardIsValidSelector,
  reverseTransationIsAllowedSelector,
  addProductIsAllowedSelector
} from '../../redux/selectors/machine/leap'
import {
  LEAP_REVERSE_MODAL,
  MACHINE_LEAP_READ,
  FULFILLMENT_METHOD_CODE_LEAP_TOP_UP,
  FULFILLMENT_METHOD_CODE_LEAP
} from '../../constants'
import { showModal } from '../../redux/actions/containers/base/visible-element'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import { elementVisibleSelector } from '../../redux/selectors/containers/base/visible-element'
import { hasValidLeapConfiguration } from '../../misc/storage'
import { goToTickets } from '../../redux/actions/containers/base/routing'

const mapStateToProps = state => {
  const showReverseModalSelector = elementVisibleSelector(LEAP_REVERSE_MODAL)

  return {
    isLoadingReadLeapCard: state.containers.leap.read.loading,
    leapCardData: leapDataSelector(state),
    addProductIsAllowed: addProductIsAllowedSelector(state),
    cardIsExpired: leapCardIsExpiredSelector(state),
    cardIsBlocked: leapCardIsBlockedSelector(state),
    purseIsBlocked: leapPurseIsBlockedSelector(state),
    cardIsValid: leapCardIsValidSelector(state),
    hasValidLeapConfiguration: hasValidLeapConfiguration(),
    reverseTransationIsAllowed: reverseTransationIsAllowedSelector(state) && !state.machine.leap.reverse,
    showReverseModal: showReverseModalSelector(state)
  }
}

const mapDispatchToProps = dispatch => ({
  readLeapCard: () => dispatch(readLeapCard()),
  onShowModal: () => dispatch(showModal(LEAP_REVERSE_MODAL)),
  clearState: () => dispatch(clearState(MACHINE_LEAP_READ)),
  onAddProduct: () => dispatch(goToTickets(FULFILLMENT_METHOD_CODE_LEAP)),
  onTopUp: () => dispatch(goToTickets(FULFILLMENT_METHOD_CODE_LEAP_TOP_UP))
})

export default connect(mapStateToProps, mapDispatchToProps)(LeapReadCard)
