import { createSelector } from 'reselect'
import { camelCaseKeys } from '../../../../../misc/utils'

const getUniqueSeatPropertyCodes = seats => Array.from(seats.reduce(
  (codes, seat) => {
    seat.propertyCodes.forEach(code => codes.add(code))
    return codes
  },
  new Set()
))

export const carriagesSelector = createSelector(
  [
    state => state.api.base.seatSelector.carriageLayouts
  ],
  carriageLayouts => (carriageLayouts ? carriageLayouts.carriages : [])
    .map(carriage => {
      const seats = carriage.seats.map(camelCaseKeys)

      return {
        ...camelCaseKeys(carriage),
        seats,
        carriageName: carriage.carriage_name,
        propertyCodes: getUniqueSeatPropertyCodes(seats)
      }
    })
    .sort((carriageA, carriageB) => carriageA.sequenceNumber - carriageB.sequenceNumber)
)
