import { createSelector } from 'reselect'
import {isGroupBookingSelector, isTicketOnlyBookingSelector} from '../booking/booking'
import {
  SALES_CHANNEL_PROPERTY_NORMAL_BOOKINGS,
  SALES_CHANNEL_PROPERTY_GROUP_BOOKINGS,
  SALES_CHANNEL_PROPERTY_FIELD_PRESENCE_HIDDEN
} from '../../../../constants'

export const salesChannelPropertiesSelector = state => (state.api.user.user && state.api.user.user.sales_channel_properties) || {}

export const salesChannelPropertySelector = property => state => salesChannelPropertiesSelector(state)[property]

export const passengerDetailsFieldPresenceSelector = bookingSelector => createSelector(
  isGroupBookingSelector(bookingSelector),
  isTicketOnlyBookingSelector(bookingSelector),
  salesChannelPropertySelector('request_passengers'),
  (isGroupBooking, isTicketOnlyBooking, requestPassengers) => {
    if (!requestPassengers) {
      return {}
    }

    return requestPassengers[isGroupBooking && !isTicketOnlyBooking ? SALES_CHANNEL_PROPERTY_GROUP_BOOKINGS : SALES_CHANNEL_PROPERTY_NORMAL_BOOKINGS]
  }
)

export const useAutomaticPassengerDetailsSelector = bookingSelector => createSelector(
  [
    salesChannelPropertySelector('request_passengers'),
    passengerDetailsFieldPresenceSelector(bookingSelector)
  ],
  (requestPassengers, fieldPresence) => !requestPassengers || !requestPassengers['requestPassenger'] ||
  Object.values(fieldPresence).every(presence => presence === SALES_CHANNEL_PROPERTY_FIELD_PRESENCE_HIDDEN)
)
