import {
  MACHINE_START_PIN_PAYMENT_REQUEST,
  MACHINE_START_PIN_PAYMENT_SUCCESS,
  MACHINE_START_PIN_PAYMENT_FAILURE,
  MACHINE_COMPLETE_PIN_PAYMENT_REQUEST,
  MACHINE_COMPLETE_PIN_PAYMENT_SUCCESS,
  MACHINE_COMPLETE_PIN_PAYMENT_FAILURE,
  MACHINE_CANCEL_PIN_PAYMENT_REQUEST,
  MACHINE_CANCEL_PIN_PAYMENT_SUCCESS,
  MACHINE_CANCEL_PIN_PAYMENT_FAILURE,
  MACHINE_REFUND_PIN_PAYMENT_SUCCESS,
  MACHINE_REFUND_PIN_PAYMENT_REQUEST,
  MACHINE_REFUND_PIN_PAYMENT_FAILURE
} from '../../actions/types'
import { CLEAR_STATE } from 's3p-js-lib/src/redux/actions/types'

const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case MACHINE_REFUND_PIN_PAYMENT_SUCCESS:
    case MACHINE_START_PIN_PAYMENT_SUCCESS:
    case MACHINE_COMPLETE_PIN_PAYMENT_SUCCESS:
    case MACHINE_CANCEL_PIN_PAYMENT_SUCCESS:
      return action.response.results
    case MACHINE_REFUND_PIN_PAYMENT_REQUEST:
    case MACHINE_REFUND_PIN_PAYMENT_FAILURE:
    case MACHINE_START_PIN_PAYMENT_REQUEST:
    case MACHINE_START_PIN_PAYMENT_FAILURE:
    case MACHINE_COMPLETE_PIN_PAYMENT_REQUEST:
    case MACHINE_COMPLETE_PIN_PAYMENT_FAILURE:
    case MACHINE_CANCEL_PIN_PAYMENT_REQUEST:
    case MACHINE_CANCEL_PIN_PAYMENT_FAILURE:
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes('machine.pinPayment') ? initialState : state
    default:
      return state
  }
}
