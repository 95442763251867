import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import {
  formFieldValuesSelectorCreator,
  formStateSelectorCreator,
  formFieldsSelectorCreator,
  subFormNamesSelectorCreator,
  formIsValidSelectorCreator
} from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import {
  touchField,
  focusField,
  loadValues,
  blurField,
  changeField,
  validate,
  destroy,
  reset,
  unTouchFields
} from 's3p-js-lib/src/redux/actions/user-input/base/form'

export default (config = {}) => {
  const formName = config.formName

  const FormComponent = Component => (class Form extends React.Component {
    static propTypes = {
      formName: PropTypes.string,
      subFormNames: PropTypes.arrayOf(PropTypes.string),
      formState: PropTypes.object,
      fields: PropTypes.object,
      isValid: PropTypes.bool
    }

    static contextTypes = {
      store: PropTypes.object
    }

    static defaultProps = {
      formName
    }

    constructor (props, ...args) {
      super(props, ...args)
      this.getFormProps = this.getFormProps.bind(this)

      this._formProps = this.getFormProps(props)
    }

    componentWillUnmount () {
      this.context.store.dispatch(destroy(this.props.formName))
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
      if (this.props.fields !== nextProps.fields ||
        this.props.subFormNames !== nextProps.subFormNames ||
        this.props.formState !== nextProps.formState ||
        this.props.isValid !== nextProps.isValid
      ) {
        this._formProps = this.getFormProps(nextProps)
      }
    }

    getFormProps (props) {
      const {dispatch, getState} = this.context.store
      const formFieldValuesSelector = formFieldValuesSelectorCreator(props.formName)
      const form = {
        ...props.formState,
        validateForm: () => dispatch(validate(props.formName)),
        loadFormValues: values => dispatch(loadValues(props.formName, values)),
        getValues: () => formFieldValuesSelector(getState()),
        reset: () => dispatch(reset(props.formName)),
        destroy: () => dispatch(destroy(props.formName)),
        unTouchFields: () => dispatch(unTouchFields(props.formName)),
        fields: Object.keys(props.fields).reduce(
          (fields, fieldName) => {
            fields[fieldName] = {
              ...props.fields[fieldName],
              name: fieldName,
              onBlur: event => dispatch(blurField(props.formName, fieldName, event)),
              onFocus: () => dispatch(focusField(props.formName, fieldName)),
              onChange: event => {
                dispatch(changeField(
                  props.formName, fieldName, event, formFieldValuesSelector(getState())
                ))
              },
              onTouch: () => dispatch(touchField(props.formName, fieldName))
            }
            return fields
          },
          {}
        ),
        subFormNames: props.subFormNames,
        valid: props.isValid
      }
      delete form.subForms
      return form
    }

    render () {
      const {...props} = this.props
      delete props.fields
      delete props.subFormNames
      delete props.formState
      delete props.isValid

      return <Component {...props} form={this._formProps} />
    }
  })

  const mapStateToProps = (state, ownProps) => {
    const formFieldsSelector = formFieldsSelectorCreator(ownProps.formName || formName)
    const subFormNamesSelector = subFormNamesSelectorCreator(ownProps.formName || formName)
    const formStateSelector = formStateSelectorCreator(ownProps.formName || formName)
    const formIsValidSelector = formIsValidSelectorCreator(ownProps.formName || formName)

    return state => ({
      fields: formFieldsSelector(state),
      subFormNames: subFormNamesSelector(state),
      formState: formStateSelector(state),
      isValid: formIsValidSelector(state)
    })
  }

  return Component => connect(mapStateToProps)(FormComponent(Component))
}
