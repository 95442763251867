import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Modal from '../../../elements/modal/modal'
import TextLabel from '../../../elements/text-label/text-label'
import Feedback, { FEEDBACK_ALERT } from '../../../elements/feedback/feedback'
import MachineFeedback from '../../../containers/base/last-machine-error-feedback'

export default class StartShiftErrorModal extends Component {
  static propTypes = {
    onLogout: PropTypes.func.isRequired,
    machineFeedbackNamespaces: PropTypes.arrayOf(PropTypes.string),
    headerText: PropTypes.string,
    feedbackText: PropTypes.string
  }

  static defaultProps = {
    headerText: 'agent.shift.start-error-modal.title',
    feedbackText: 'agent.shift.start-error-modal.updating-leap-cd-failed',
    machineFeedbackNamespaces: ['leapDevice']
  }

  render () {
    return (
      <Modal
        name='shift-information'
        isCloseable={false}
        header={
          <Modal.Header
            title={_t.message(this.props.headerText)}
            disabled
          />}
        footer={
          <Modal.FooterSingle onClick={this.props.onLogout} hotkey='logout'>
            <TextLabel name='logout' text={_t.message('agent.shift.start-error-modal.logout')} />
          </Modal.FooterSingle>
        }
      >
        <Feedback
          status={FEEDBACK_ALERT}
          text={_t.message(this.props.feedbackText)}
        />
        <MachineFeedback namespaces={this.props.machineFeedbackNamespaces} />
      </Modal>
    )
  }
}
