import DOMPurify from 'dompurify'
import { createSelector } from 'reselect'

const cannotBeEmptyIfDiscountCardNumberIsSet = (value, {values}) =>
  (!value || !value.code) && values.discountCardNumber
    ? 'discount-card-is-empty'
    : null

const cannotBeEmptyIfDiscountCardIsSet = (value, {values}) =>
  !value && values.discountCard && values.discountCard.code
    ? 'discount-card-number-is-empty'
    : null

export default (passengersSelector, seatOnly = false) => createSelector(
  [passengersSelector],
  passengers => ({
    subForms: (passengers || []).reduce(
      (passengerForms, passenger, index) => {
        const fields = {
          wheelchair: {},
          passengerType: {},
          discountCard: {
            validators: [
              cannotBeEmptyIfDiscountCardNumberIsSet
            ]
          },
          discountCardNumber: {
            normalizers: [
              DOMPurify.sanitize
            ],
            validators: [
              cannotBeEmptyIfDiscountCardIsSet
            ]
          }
        }
        if (seatOnly) {
          fields.seatOnly = {}
        }
        passengerForms[`passenger_${index + 1}`] = {
          fields
        }
        return passengerForms
      },
      {}
    )
  })
)
