import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import {
  API_START_AGENT_SHIFT_REQUEST,
  API_START_AGENT_SHIFT_SUCCESS,
  API_START_AGENT_SHIFT_FAILURE,
  API_GET_AGENT_SHIFT_REQUEST,
  API_GET_AGENT_SHIFT_SUCCESS,
  API_GET_AGENT_SHIFT_FAILURE,
  API_ADD_NOTES_AGENT_SHIFT_REQUEST,
  API_ADD_NOTES_AGENT_SHIFT_SUCCESS,
  API_ADD_NOTES_AGENT_SHIFT_FAILURE,
  API_END_AGENT_SHIFT_REQUEST,
  API_END_AGENT_SHIFT_SUCCESS,
  API_END_AGENT_SHIFT_FAILURE,
  API_HEAD_AGENT_SHIFT_REQUEST,
  API_HEAD_AGENT_SHIFT_SUCCESS,
  API_HEAD_AGENT_SHIFT_FAILURE
} from '../../../types'
import ensureToken from '../../../../../api/ensure-token'
import Client from '../../../../../api/client'
import ResponseCodes from '../../../../../api/response-codes'

const startAgentShiftRequest = (amount, currency, deviceId, notes, metaData, location) => ({
  type: API_START_AGENT_SHIFT_REQUEST,
  amount,
  currency,
  deviceId,
  notes,
  metaData,
  location
})
const startAgentShiftSuccess = response => ({type: API_START_AGENT_SHIFT_SUCCESS, response})
const startAgentShiftFailure = error => ({type: API_START_AGENT_SHIFT_FAILURE, error})

const getAgentShiftRequest = () => ({type: API_GET_AGENT_SHIFT_REQUEST})
const getAgentShiftSuccess = response => ({type: API_GET_AGENT_SHIFT_SUCCESS, response})
const getAgentShiftFailure = error => ({type: API_GET_AGENT_SHIFT_FAILURE, error})

const addNotesAgentShiftRequest = notes => ({type: API_ADD_NOTES_AGENT_SHIFT_REQUEST, notes})
const addNotesAgentShiftSuccess = response => ({type: API_ADD_NOTES_AGENT_SHIFT_SUCCESS, response})
const addNotesAgentShiftFailure = error => ({type: API_ADD_NOTES_AGENT_SHIFT_FAILURE, error})

const endAgentShiftRequest = (notes, metaData) => ({type: API_END_AGENT_SHIFT_REQUEST, notes, metaData})
const endAgentShiftSuccess = response => ({type: API_END_AGENT_SHIFT_SUCCESS, response})
const endAgentShiftFailure = error => ({type: API_END_AGENT_SHIFT_FAILURE, error})

const headAgentShiftRequest = () => ({type: API_HEAD_AGENT_SHIFT_REQUEST})
const headAgentShiftSuccess = response => ({type: API_HEAD_AGENT_SHIFT_SUCCESS, response})
const headAgentShiftFailure = error => ({type: API_HEAD_AGENT_SHIFT_FAILURE, error})

export const startAgentShift = (amount, currency, deviceId, notes, metaData, location) =>
  ensureToken(async (token, dispatch) => {
    try {
      dispatch(startAgentShiftRequest(amount, currency, deviceId, notes, metaData, location))
      const response = await Client.startAgentShift(token, amount, currency, deviceId, notes, metaData, location)
      dispatch(startAgentShiftSuccess(response))
    } catch (error) {
      dispatch(startAgentShiftFailure(error))
      if (ResponseCodes.USER_AGENT_SHIFT_ALREADY_STARTED === error.errorCode) {
        return false
      }
      throw error
    }
  })

export const getAgentShift = () => ensureToken(async (token, dispatch) => {
  try {
    dispatch(getAgentShiftRequest())
    const response = await Client.getAgentShift(token)
    dispatch(getAgentShiftSuccess(response))
  } catch (error) {
    dispatch(getAgentShiftFailure(error))
    if (ResponseCodes.USER_AGENT_SHIFT_NOT_FOUND === error.errorCode) {
      return false
    }
    throw error
  }
})

export const addNotesAgentShift = notes => ensureToken(async (token, dispatch) => {
  try {
    dispatch(addNotesAgentShiftRequest(notes))
    const response = await Client.addNotesAgentShift(token, notes)
    dispatch(addNotesAgentShiftSuccess(response))
  } catch (error) {
    dispatch(addNotesAgentShiftFailure(error))
    throw error
  }
})

export const endAgentShift = (notes, metaData) => ensureToken(async (token, dispatch) => {
  try {
    dispatch(endAgentShiftRequest(notes, metaData))
    const response = await Client.endAgentShift(token, notes, metaData)
    dispatch(endAgentShiftSuccess(response))
  } catch (error) {
    dispatch(endAgentShiftFailure(error))
    throw error
  }
})

export const headAgentShift = () => ensureToken(async (token, dispatch) => {
  try {
    dispatch(headAgentShiftRequest())
    const response = await Client.headAgentShift(token)
    dispatch(headAgentShiftSuccess(response))
  } catch (error) {
    if (error.statusCode === 404) {
      dispatch(clearState('api.user.agent.shift'))
      return false
    }
    dispatch(headAgentShiftFailure(error))

    throw error
  }
})
