import {
  goToAftersalesBooking,
  goToAftersalesBookings
} from './routing'
import { bookingNumberSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { originalOrOverviewBookingSelector } from '../../../selectors/api/booking/booking'

export const goToCurrentAftersalesBooking = () => (dispatch, getState) => {
  const bookingNumber = bookingNumberSelector(originalOrOverviewBookingSelector)(getState())
  dispatch(bookingNumber ? goToAftersalesBooking(bookingNumber) : goToAftersalesBookings())
}

