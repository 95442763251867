import {
  API_AGENT_LOGIN_SUCCESS,
  API_OAUTH_LOGOUT,
  CLEAR_STATE
} from 's3p-js-lib/src/redux/actions/types'
import {API_V2_GET_CALENDAR_REQUEST, API_V2_GET_CALENDAR_SUCCESS} from '../../../../actions/types-v2'

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_AGENT_LOGIN_SUCCESS:
    case API_V2_GET_CALENDAR_REQUEST:
    case API_OAUTH_LOGOUT:
      return initialState
    case API_V2_GET_CALENDAR_SUCCESS:
      return {...state, ...action.response.data.calendars}
    case CLEAR_STATE:
      return action.namespaces.includes('api.v2.orientation.calendar') ? initialState : state
    default:
      return state
  }
}
