import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Feedback, { FEEDBACK_INFORMATION, FEEDBACK_SUCCESS } from '../../../../../elements/feedback/feedback'
import RefundCancel from '../../../../../containers/aftersales/booking/payment-details/refund/refund-cancel'
import Modal from '../../../../../elements/modal/modal'
import TextLabel from '../../../../../elements/text-label/text-label'
import Button from '../../../../../elements/button/button'
import MachineFeedback from '../../../../../containers/base/last-machine-error-feedback'
import _t from 's3p-js-lib/src/translate'

export default class RefundCreditCard extends Component {
  static propTypes = {
    amount: PropTypes.number,
    updatePaymentsLoading: PropTypes.bool,
    disabledNextButton: PropTypes.bool,
    showRetryButton: PropTypes.bool,
    showRetryPrintButton: PropTypes.bool,
    refundSuccess: PropTypes.bool,
    refundLoading: PropTypes.bool,
    confirmCreditCard: PropTypes.func,
    cancelRefund: PropTypes.func,
    retryRefundCreditCard: PropTypes.func,
    retryPrintRefundReceipt: PropTypes.func.isRequired
  }

  _renderFooter () {
    return (
      <Modal.CustomFooter>
        {this.props.showRetryPrintButton
          ? <Button
            name='retry-print'
            className='secondary'
            onClick={this.props.retryPrintRefundReceipt}
          >
            <TextLabel name='next-step' text={_t.message('aftersales.refund-modal.creditcard.retry-refund-print')} />
          </Button>
          : null
        }
        {this.props.showRetryButton
          ? <Button
            name='retry'
            className='primary'
            onClick={this.props.retryRefundCreditCard}
          >
            <TextLabel name='next-step' text={_t.message('aftersales.refund-modal.creditcard.retry')} />
          </Button>
          : null
        }
        <RefundCancel disabled={this.props.refundLoading} />
        <Button
          name='confirm'
          hotkey='proceed'
          className='primary'
          disabled={this.props.disabledNextButton}
          onClick={this.props.confirmCreditCard}
          loading={this.props.updatePaymentsLoading}
        >
          <TextLabel name='next-step' text={_t.message('aftersales.refund-modal.creditcard.confirm')} />
        </Button>
      </Modal.CustomFooter>
    )
  }

  render () {
    return (
      <div>
        <MachineFeedback namespaces={['pinPayment']} />
        {this.props.refundSuccess
          ? <Feedback status={FEEDBACK_SUCCESS} text={_t.message('aftersales.refund-modal.creditcard.success')} />
          : null}
        <Feedback
          status={FEEDBACK_INFORMATION}
          text={_t.message('aftersales.refund-modal.creditcard.refund-description', {
            amount: _t.formatCurrency(this.props.amount)
          })}
        />
        {this._renderFooter()}
      </div>
    )
  }
}
