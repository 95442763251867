import {
  API_REBOOKING_JOURNEY_SEARCH_REQUEST,
  API_REBOOKING_JOURNEY_SEARCH_SUCCESS,
  API_REBOOKING_JOURNEY_SEARCH_FAILURE
} from '../../../types'
import Client from '../../../../../api/client'
import ensureToken from '../../../../../api/ensure-token'
import {getUpdatedJourneySearchForPagination} from '../../orientation/journey-search'
import {v2ToV1JourneySearchResponse} from 's3p-js-lib/src/redux/actions/api/v2/journey-search-mapper'

const journeySearchRequest = offer => ({type: API_REBOOKING_JOURNEY_SEARCH_REQUEST, offer})
const journeySearchSuccess = (response, stations) => ({type: API_REBOOKING_JOURNEY_SEARCH_SUCCESS, response, stations})
const journeySearchFailure = error => ({type: API_REBOOKING_JOURNEY_SEARCH_FAILURE, error})

export const executeJourneySearch = offer => ensureToken(async (token, dispatch, getState) => {
  try {
    dispatch(journeySearchRequest(offer))
    const response = await Client.rebookingJourneySearch(token, offer)
    dispatch(journeySearchSuccess(v2ToV1JourneySearchResponse(response, offer), getState().api.orientation.stations))
  } catch (error) {
    dispatch(journeySearchFailure(error))
    throw error
  }
})

export const paginateJourneySearch = (direction, later) => {
  return (dispatch, getState) => dispatch(
    executeJourneySearch(getUpdatedJourneySearchForPagination(getState(), direction, later))
  )
}
