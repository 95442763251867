import { connect } from 'react-redux'
import { SHIFT_MODAL } from '../../../constants'
import { showModal } from '../../../redux/actions/containers/base/visible-element'
import { routerActions } from '../../../redux/actions/containers/base/routing'
import { agentShiftSelector } from 's3p-js-lib/src/redux/selectors/api/user/agent/shift'
import { willPasswordExpireSoon } from '../../../redux/selectors/containers/agent/shift/shift'
import Resume from '../../../components/agent/shift/resume'
import { getCurrencyRates } from 's3p-js-lib/src/redux/actions/api/v2/meta/currency-rates'

const mapStateToProps = state => {
  const shift = agentShiftSelector(state)
  return {
    startDateTime: shift ? shift.startDateTime : null,
    loading: state.api.v2.loading.currencyRates,
    willPasswordExpireSoon: willPasswordExpireSoon(state)
  }
}

const mapDispatchToProps = {
  onEndShift: () => showModal(SHIFT_MODAL),
  onLogout: () => routerActions.push('/agent/login'),
  onResumeShift: () => async dispatch => {
    await dispatch(getCurrencyRates())
    dispatch(routerActions.push('/tickets'))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Resume)
