import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../../../elements/text-label/text-label'
import Icon from '../../../../elements/icon/icon'
import Route from '../../../../containers/base/journey-search/journey-result-set/route'
import Sorting from '../../../../containers/base/journey-search/journey-result-set/sorting'
import DateSwitcher from '../../../../containers/base/journey-search/date-switcher/date-switcher'
import Pagination from '../../../../containers/base/journey-search/journey-result-set/pagination'
import Feedback, { FEEDBACK_INFORMATION } from '../../../../elements/feedback/feedback'
import _t from 's3p-js-lib/src/translate'
import './journey-result-set.scss'

export default class JourneyResultSet extends Component {
  static propTypes = {
    direction: PropTypes.string.isRequired,
    travel: PropTypes.shape({
      originStation: PropTypes.object.isRequired,
      destinationStation: PropTypes.object.isRequired
    }),
    routeIds: PropTypes.array.isRequired,
    rebooking: PropTypes.bool
  }

  static defaultProps = {
    rebooking: false
  }

  render () {
    return this.props.travel
      ? (
        <div
          id={`journey-result-set-${this.props.direction}`}
          className={`journey-result-set-${this.props.direction}-container`}
        >
          <div className={`journey-result-set-${this.props.direction}`}>
            <div className='route-set-header'>
              <div className='route-set-header__summary'>
                <TextLabel name='header-origin' text={this.props.travel.originStation.name} />
                <Icon name='arrow-right' className='align-left' />
                <TextLabel name='header-destination' text={this.props.travel.destinationStation.name} />
              </div>
              <DateSwitcher rebooking={this.props.rebooking} direction={this.props.direction} />
              <Sorting direction={this.props.direction} />
            </div>
            <div className='route-set'>
              {!this.props.routeIds.length
                ? (
                  <Feedback status={FEEDBACK_INFORMATION}>
                    <span dangerouslySetInnerHTML={
                      { __html: _t.message('journey-result-set.route.no-routes-available') }
                    } />
                  </Feedback>
                ) : null}
              {this.props.routeIds.map(routeId => (
                <Route
                  key={routeId}
                  routeId={routeId}
                  rebooking={this.props.rebooking}
                />
              ))}
            </div>
            <Pagination direction={this.props.direction} aftersales={this.props.rebooking} />
          </div>
        </div>
      ) : null
  }
}
