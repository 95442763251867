import {routerActions} from 'react-router-redux'
import {endAgentShift, addNotesAgentShift} from 's3p-js-lib/src/redux/actions/api/user/agent/shift'
import {openMachineCashDrawer} from '../../../machine/cash-drawer'
import {sendMachineDisplayLines, timeoutClearDisplay} from '../../../machine/display'
import {SHIFT_MODAL, DOCUMENT_TEMPLATE_NAME_SHIFT_REPORT, CLEAR_DISPLAY_AFTER_SHORT} from '../../../../../constants'
import {FORM_TYPE_AGENT_SHIFT_NOTE} from 's3p-js-lib/src/constants-form'
import {UI_SHIFT_ENDED_REMOTELY, UI_MANUALLY_SAVE_NOTE_SHIFT} from '../../../types'
import _t from 's3p-js-lib/src/translate'
import {focusField, loadValues} from 's3p-js-lib/src/redux/actions/user-input/base/form'
import {formFieldValueSelectorCreator} from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import {hideElement} from '../../base/visible-element'
import {agentShiftSelector} from 's3p-js-lib/src/redux/selectors/api/user/agent/shift'
import {clearState} from 's3p-js-lib/src/redux/actions/clear-state'
import {getShiftReport} from 's3p-js-lib/src/redux/actions/api/v2/user/agent/shift'
import {sendMachineReceiptPrint} from '../../../machine/receipt-printer'

export const shiftEndedRemotely = () => ({type: UI_SHIFT_ENDED_REMOTELY})
export const manuallySavedShiftNote = () => ({type: UI_MANUALLY_SAVE_NOTE_SHIFT})

export const endShift = () => async (dispatch, getState) => {
  const state = getState()
  const shift = agentShiftSelector(state)
  if (shift && state.machine.terminalInformation.data.terminalId !== shift.deviceId) {
    await dispatch(addNotesAgentShift([`Shift was ended by ${shift.agentUserName} on another BOM`]))
  }

  await dispatch(endAgentShift(null, shift ? shift.metaData : []))
  const requestId = 'end_shift'

  await dispatch(sendMachineDisplayLines([
    _t.message('machine.customer-display.lines.end-of-shift.upper'),
    _t.message('machine.customer-display.lines.end-of-shift.lower')
  ], requestId))

  await dispatch(printShiftReport())

  dispatch([
    openMachineCashDrawer(),
    timeoutClearDisplay(CLEAR_DISPLAY_AFTER_SHORT, requestId),
    hideElement(SHIFT_MODAL),
    routerActions.push(`/${_t.getLocales()}/agent/shift`),
    clearState('containers.tickets.repeatLastBooking')
  ])
}

export const saveNoteShift = () => async (dispatch, getState) => {
  const note = formFieldValueSelectorCreator(FORM_TYPE_AGENT_SHIFT_NOTE, 'note')(getState())
  if (note) {
    const response = await dispatch(addNotesAgentShift([note]))
    if (response) {
      dispatch([
        loadValues(
          FORM_TYPE_AGENT_SHIFT_NOTE,
          {note: ''}
        ),
        focusField(FORM_TYPE_AGENT_SHIFT_NOTE, 'note')
      ])
    }
  }
  dispatch(manuallySavedShiftNote())
}

export const printShiftReport = () => async (dispatch, getState) => {
  const shift = agentShiftSelector(getState())
  if (!shift) {
    return null
  }

  await dispatch(getShiftReport(shift.shiftId, DOCUMENT_TEMPLATE_NAME_SHIFT_REPORT))
  const report = getState().api.v2.user.agent.shiftReport
  if (report) {
    try {
      const jsonReport = processReport(report)

      // Shift report need to be printed twice
      await dispatch(sendMachineReceiptPrint(jsonReport))
      await dispatch(sendMachineReceiptPrint(jsonReport))
    } catch (error) {
      // error
    } finally {
      clearState('api.v2.user.agent.shiftReport')
    }
  }
}

const processReport = report => {
  const reportArray = report.split(/\r?\n/g)

  let index
  let image = {}
  let textline1 = []

  const first = reportArray.findIndex(data => data !== '')
  for (index = first; index < reportArray.length; index++) {
    let text
    if (index === first) {
      image = {
        'width': 300,
        'align': 'center',
        'text': reportArray[first]
      }
    } else {
      text = {
        'hsize': 'normal',
        'vsize': 'normal',
        'align': 'left',
        'style': 'plain',
        'text': reportArray[index]
      }
      textline1.push(text)
    }
  }
  return {
    'lines': {
      'imageline1': image,
      'textline1': textline1
    }
  }
}
