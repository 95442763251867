import composeReducers from 's3p-js-lib/src/redux/lib/compose-reducers'
import baseReducers from 's3p-js-lib/src/redux/reducers/api/v2'
import baseSearch from 's3p-js-lib/src/redux/reducers/api/v2/search'
import search from './search'
import user from './user'
import loading from './loading'
import orientation from './orientation'

export default {
  ...baseReducers,
  loading,
  search: composeReducers(baseSearch, search),
  user,
  orientation
}
