import { createSelector } from 'reselect'
import { camelCaseKeys } from '../../../../misc/utils'

export const relatedVouchersSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => {
    if (!booking || !booking.related_vouchers) {
      return []
    }

    return booking.related_vouchers.map(voucher => ({
      ...camelCaseKeys(voucher),
      currency: booking.currency
    }))
  }
)
