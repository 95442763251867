import { USER_INPUT_FORM_CHANGE_FIELD, CLEAR_STATE } from 's3p-js-lib/src/redux/actions/types'
import { UI_SHOW_FEEDBACK } from '../../../../../actions/types'
import { CONTAINERS_RESERVATION_BOOKING_DETAILS_CUSTOMER_DETAILS } from '../../../../../../constants'
import { FORM_CUSTOMER_DETAILS } from 's3p-js-lib/src/constants-form'

export default (state = false, action) => {
  switch (action.type) {
    case USER_INPUT_FORM_CHANGE_FIELD:
      if (action.formName === FORM_CUSTOMER_DETAILS && ['emailAddress', 'phoneNumber'].includes(action.fieldName)) {
        return false
      }
      return state
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_RESERVATION_BOOKING_DETAILS_CUSTOMER_DETAILS) ? false : state
    case UI_SHOW_FEEDBACK:
      return action.name === CONTAINERS_RESERVATION_BOOKING_DETAILS_CUSTOMER_DETAILS ? true : state
    default:
      return state
  }
}
