import { createSelector } from 'reselect'
import { toTimezoneMoment } from '../../../../misc/date'
import { getProductFamily } from '../booking/product-families'
import { TRAVEL_DIRECTION_OUTBOUND, TRAVEL_DIRECTION_INBOUND } from '../../../../constants'
import { currencySelector } from '../../api/base/currency'

export const journeySegmentsSelector = createSelector(
  [state => state.api.orientation.specification],
  specification => {
    let journeySegments = []
    if (specification && specification.outbound_booking_journey_segments) {
      journeySegments = journeySegments.concat(specification.outbound_booking_journey_segments.map(
        journeySegment => ({...journeySegment, direction: 'outbound'})
      ))
    }
    if (specification && specification.inbound_booking_journey_segments) {
      journeySegments = journeySegments.concat(specification.inbound_booking_journey_segments.map(
        journeySegment => ({...journeySegment, direction: 'inbound'})
      ))
    }

    return journeySegments
  }
)

const travelInfoSelector = (specification, journeySegments, direction) => {
  if (!(journeySegments && journeySegments.length)) {
    return null
  }

  const firstSegment = journeySegments[0]

  return {
    direction,
    departureTime: toTimezoneMoment(firstSegment.departure_date_time, firstSegment.departure_station.timezone)
  }
}

export const outboundTravelInfoSelector = createSelector(
  [state => state.api.orientation.specification],
  specification =>
    specification && travelInfoSelector(specification, specification.outbound_booking_journey_segments, 'outbound')
)

export const inboundTravelInfoSelector = createSelector(
  [state => state.api.orientation.specification],
  specification =>
    specification && travelInfoSelector(specification, specification.inbound_booking_journey_segments, 'inbound')
)

const productMapper = (product, specification, offer, currency, isRequired) => {
  let segments = specification.outbound_booking_journey_segments.filter(
    segment => product.legs.includes(segment.leg_id)
  )
  const isOutbound = segments.length > 0

  if (!isOutbound) {
    segments = specification.inbound_booking_journey_segments.filter(
      segment => product.legs.includes(segment.leg_id)
    )
  }
  const firstSegment = segments.length > 0 && segments[0]
  const lastSegment = segments.length > 0 && segments[segments.length - 1]

  return {
    ...product,
    departure_station: firstSegment && firstSegment.departure_station,
    arrival_station: lastSegment && lastSegment.arrival_station,
    direction: isOutbound ? TRAVEL_DIRECTION_OUTBOUND : TRAVEL_DIRECTION_INBOUND,
    passenger: offer.passengers.find(passenger => passenger.id === product.passenger_id),
    name: product.product_name,
    code: product.product_code,
    currency,
    isRequired
  }
}

const requiredProductsSelector = createSelector(
  [
    state => state.api.orientation.specification,
    state => state.api.orientation.offer,
    currencySelector
  ],
  (specification, offer, currency) =>
    specification && specification.required_products.map(
      product => productMapper(product, specification, offer, currency, true)
    )
)

const additionalProductsSelector = createSelector(
  [
    state => state.api.orientation.specification,
    state => state.api.orientation.offer,
    currencySelector
  ],
  (specification, offer, currency) =>
    specification && specification.additional_products.map(
      product => productMapper(product, specification, offer, currency, false)
    )
)

export const productsSelector = createSelector(
  [requiredProductsSelector, additionalProductsSelector],
  (requiredProducts, additionalProducts) => requiredProducts.concat(additionalProducts)
)

export const outboundProductFamilySelector = createSelector(
  [state => state.api.orientation.specification],
  specification =>
    specification && getProductFamily(specification.product_families, specification.outbound_product_family_id)
)

export const inboundProductFamilySelector = createSelector(
  [state => state.api.orientation.specification],
  specification =>
    specification && getProductFamily(specification.product_families, specification.inbound_product_family_id)
)

export const totalPriceSelector = state =>
  state.api.orientation.specification ? state.api.orientation.specification.total_price : 0.0

export const selectedSeatsSelector = state =>
  state.api.orientation?.specification?.selected_seats || []

export const seatPreferencesSelector = state =>
  state.api.orientation?.specification?.seat_preferences || []

export const passengersSelector = state =>
  state.api.orientation?.specification?.passengers || []
