import React, { Component } from 'react'
import ReviewPaymentLayout from '../review-payment'

export default class AdditionalProductsReviewPaymentLayout extends Component {
  render () {
    return (
      <ReviewPaymentLayout
        labelTitle='aftersales.additional-products.header.title'
        labelInformation='aftersales.additional-products.header.information'
      />
    )
  }
}
