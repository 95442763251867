import { connect } from 'react-redux'
import { routeSelector } from '../../../../redux/selectors/containers/base/journey-search/journey-result-set'
import { showJourneyDetails } from '../../../../redux/actions/containers/base/journey-search/journey-details'
import { handleSelectBundle } from '../../../../redux/actions/containers/reservations/journey-search/journey-result-set'
import { handleRebookingSelectBundle } from '../../../../redux/actions/containers/aftersales/rebooking/journey-result-set'
import { servicePropertySelector } from '../../../../redux/selectors/containers/base/journey-search/journey-details'
import Route from '../../../../components/base/journey-search/journey-result-set/route'

const mapStateToProps = (state, ownProps) => {
  const _routeSelector = routeSelector(ownProps.routeId)
  const _servicePropertySelector = servicePropertySelector(_routeSelector)

  return state => ({
    ..._routeSelector(state) || {},
    serviceProperties: _servicePropertySelector(state)
  })
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const _handleSelectBundle = ownProps.rebooking ? handleRebookingSelectBundle : handleSelectBundle

  return {
    onShowJourneyDetails: () => dispatch(showJourneyDetails(ownProps.routeId)),
    handleSelectBundle: bundle => dispatch(_handleSelectBundle(bundle))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Route)
