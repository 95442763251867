import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Form from '../../../../elements/form/form'
import Hotkeys from '../../../../elements/utils/hotkeys'

export default class PassengerPreferences extends Component {
  static propTypes = {
    enableBikeFilter: PropTypes.func.isRequired,
    disableBikeFilter: PropTypes.func.isRequired,
    isEnabledBikeFilter: PropTypes.bool
  }

  constructor (...args) {
    super(...args)

    this.handleChangeBikeFilter = this.handleChangeBikeFilter.bind(this)
    this.keyHandlers = {
      'toggleBikeAvailability': this.handleChangeBikeFilter.bind(this)
    }
  }

  handleChangeBikeFilter () {
    if (this.props.isEnabledBikeFilter) {
      this.props.disableBikeFilter()
    } else {
      this.props.enableBikeFilter()
    }
  }

  render () {
    return (
      <Hotkeys handlers={this.keyHandlers}>
        <Form.InputWrapper
          name='bike-availability'
          hotkey='toggleBikeAvailability'
        >
          <Form.Checkbox
            id='bike-availability'
            name='bike-availability'
            onChange={this.handleChangeBikeFilter}
            checked={this.props.isEnabledBikeFilter}
          >
            <Form.Label
              htmlFor='bike-availability'
              name='bike-availability'
            />
          </Form.Checkbox>
        </Form.InputWrapper>
      </Hotkeys>
    )
  }
}
