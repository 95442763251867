import React, {Component} from 'react'
import ComponentContainer from '../../../elements/component-container/component-container'
import ProductSearchResult from '../../../containers/tickets/product-process/product-search-result'
import FulfillmentMethods from '../../../containers/tickets/fulfillment-methods'
import PaymentMethods from '../../../containers/base/tiles/payment-methods'
import FinalizeBooking from '../../../containers/tickets/finalize-booking'
import './product-process.scss'

export default class ProductProcess extends Component {
  render () {
    return (
      <ComponentContainer name='product-process'>
        <ProductSearchResult />
        <FulfillmentMethods />
        <PaymentMethods />
        <FinalizeBooking />
      </ComponentContainer>
    )
  }
}
