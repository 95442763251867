import additionalProducts from './additional-products'
import affiliateCode from './affiliate-code'
import currency from './currency'
import specification from './specification'
import selectedBundles from './selected-bundles'
import journeySearch from './journey-search'
import journeyDetails from './journey-details'
import offer from './offer'
import cheapestPrice from './cheapest-price'
import seatSelectionOptions from './seat-selection-options'
import stations from './stations'

export default {
  affiliateCode,
  currency,
  journeySearch,
  journeyDetails,
  offer,
  additionalProducts,
  specification,
  selectedBundles,
  seatSelectionOptions,
  cheapestPrice,
  stations
}
