import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ComponentContainer from '../../../../elements/component-container/component-container'
import DateSwitcherItem from '../../../../containers/base/journey-search/date-switcher/date-switcher-item'
import PreviousDateSet from '../../../../containers/base/journey-search/date-switcher/previous-date-set'
import NextDateSet from '../../../../containers/base/journey-search/date-switcher/next-date-set'
import './date-switcher.scss'

export default class DateSwitcher extends Component {
  static propTypes = {
    direction: PropTypes.string.isRequired,
    travels: PropTypes.object.isRequired,
    rebooking: PropTypes.bool
  }

  render () {
    const {travels} = {...this.props}
    return (
      <ComponentContainer name='date-switcher'>
        <div name='date-switcher'>
          <nav>
            <ul>
              {Object.keys(travels).length
                ? [
                  <PreviousDateSet
                    rebooking={this.props.rebooking}
                    key='previous'
                    date={travels[-1].date}
                    direction={this.props.direction}
                  />,
                  <DateSwitcherItem
                    rebooking={this.props.rebooking}
                    key={-2}
                    direction={this.props.direction}
                    {...travels[-2]}
                  />,
                  <DateSwitcherItem
                    rebooking={this.props.rebooking}
                    key={-1}
                    direction={this.props.direction}
                    {...travels[-1]}
                  />,
                  <DateSwitcherItem
                    rebooking={this.props.rebooking}
                    key={0}
                    direction={this.props.direction}
                    {...travels[0]}
                    selected
                  />,
                  <DateSwitcherItem
                    rebooking={this.props.rebooking}
                    key={1}
                    direction={this.props.direction}
                    {...travels[1]}
                  />,
                  <DateSwitcherItem
                    rebooking={this.props.rebooking}
                    key={2}
                    direction={this.props.direction}
                    {...travels[2]}
                  />,
                  <NextDateSet
                    rebooking={this.props.rebooking}
                    key='next'
                    date={travels[1].date}
                    direction={this.props.direction}
                  />
                ] : null}
            </ul>
          </nav>
        </div>
      </ComponentContainer>
    )
  }
}
