import { createSelector } from 'reselect'

export const outboundFeesSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => ((booking && booking.outbound_booking_tariff_segments) || []).reduce(
    (fees, segment) => {
      fees.push(...segment.fees)

      return fees
    },
    []
  )
)

export const inboundFeesSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => ((booking && booking.inbound_booking_tariff_segments) || []).reduce(
    (fees, segment) => {
      fees.push(...segment.fees)
      return fees
    },
    []
  )
)

export const feesSelector = bookingSelector => createSelector(
  [outboundFeesSelector(bookingSelector), inboundFeesSelector(bookingSelector)],
  (outboundFees, inboundFees) => outboundFees.concat(inboundFees)
)

export const withoutCancelledSelector = feesSelector => createSelector(
  [feesSelector],
  fees => fees.filter(fee => !fee.cancelled)
)

export const provisionalFeesSelector = feesSelector => createSelector(
  [feesSelector],
  fees => fees.filter(fee => fee.provisional)
)
