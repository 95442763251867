import moment from 'moment'
import { PhoneNumberUtil } from 'google-libphonenumber'
import _t from '../translate'
import { getStrengthStatus } from '../misc/password-strength'
import { PASSENGER_TYPE_ADULT, PASSENGER_TYPE_CHILD, PASSENGER_TYPE_STUDENT } from '../constants'

const phoneUtil = PhoneNumberUtil.getInstance()
const validPassengers = [PASSENGER_TYPE_ADULT, PASSENGER_TYPE_CHILD, PASSENGER_TYPE_STUDENT]

export default class Validator {
  /**
   * @param {string} value
   * @param {object} context object {field, values, completeFormState}
   * @return {null|string} is-required or null
   */
  static isRequired (value) {
    return value === undefined || value === false || (typeof value === 'string' && value.trim().length === 0) ||
    value === null ? 'is-required' : null
  }

  static isNonEmptyArray (value) {
    return !Array.isArray(value) || value.length === 0 ? 'is-required' : null
  }

  static isRepeated (...fields) {
    return (value, context) =>
      value && !fields.every(field => value === context.values[field]) ? 'is-not-repeated' : null
  }

  static passwordStrength (value) {
    return getStrengthStatus(value).length === 0 ? null : 'is-not-strong-enough'
  }

  static isEmail (value) {
    return Validator.maxLength(254)(value) || (value && !value.match(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/
    )) ? 'invalid-email-address' : null
  }

  static isValidDate (value) {
    const date = value instanceof moment ? value : moment(value)
    return value && !date.isValid() ? 'invalid-date' : null
  }

  static isTime (value) {
    return value && !value.match(/^\d{2}:\d{2}:\d{2}$/) ? 'invalid-time' : null
  }

  static maxLength (maxLength) {
    return value => value && value.length > maxLength ? 'invalid-max-length' : null
  }

  static minLength (minLength) {
    return value => value && value.length < minLength ? 'invalid-min-length' : null
  }

  static cannotBeInThePast (value) {
    return value && moment(value).startOf('day').isBefore(moment().startOf('day')) ? 'date-is-in-the-past' : null
  }

  static cannotBeInTheFuture (value) {
    return value && moment(value).startOf('day').isAfter(moment().startOf('day')) ? 'date-is-in-the-future' : null
  }

  static isValidStationObject (value) {
    return value && (typeof value !== 'object' || !('UICStationCode' in value)) ? 'invalid-station' : null
  }

  static isPhoneNumber (value) {
    const maxLength = Validator.maxLength(50)(value)
    if (maxLength) {
      return maxLength
    }

    if (!value) {
      return null
    }

    try {
      return phoneUtil.isValidNumber(
        phoneUtil.parse(value, _t.getDefaultRegion())
      ) ? null : 'invalid-phone-number'
    } catch (e) {
      return 'invalid-phone-number'
    }
  }

  static isValidPassenger (values) {
    return values && values.length && !values.every(value => validPassengers.includes(value.type)) ? 'invalid-passenger' : null
  }

  static isFirstName = Validator.maxLength(50)

  static isLastName = Validator.maxLength(50)

  static isStreet = Validator.maxLength(254)

  static isHouseNumber = Validator.maxLength(8)

  static isPostalCode = Validator.maxLength(15)

  static isCity = Validator.maxLength(50)

  static isCountryCode = Validator.maxLength(2)

  static isProvince = Validator.maxLength(6)

  static isCounty = Validator.maxLength(6)

  static arrayAtLeastOneItem = value => !Array.isArray(value) || value.length === 0 ? 'is-required' : null

  static minValue = minimum => value => value < minimum ? 'invalid-min-value' : null
}
