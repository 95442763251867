import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import ComponentContainer from '../../../elements/component-container/component-container'
import ToolbarElement from '../../../elements/toolbar/toolbar'
import ToolbarItemChangeSeat from '../../../containers/aftersales/booking/toolbar-item/seat-change'
import ToolbarItemCancel from '../../../containers/aftersales/booking/toolbar-item/cancellation'
import ToolbarItemRebook from '../../../containers/aftersales/booking/toolbar-item/rebooking'
import ToolbarItemAdditionalProducts from '../../../containers/aftersales/booking/toolbar-item/additional-products'
import ToolbarItemChangeFulfillment from '../../../containers/aftersales/booking/toolbar-item/change-fulfillment'
import ToolbarItemUpdatePassengers from '../../../containers/aftersales/booking/toolbar-item/update-passengers'
import ToolbarItemBookAgain from '../../../containers/aftersales/booking/toolbar-item/book-again'
import ToolbarItemBackHome from '../../../containers/aftersales/booking/toolbar-item/back-home'
import ToolbarItemManualConfirm from '../../../containers/aftersales/booking/toolbar-item/manual-confirm'
import './toolbar.scss'

export default class Toolbar extends Component {
  static propTypes = {
    bookingNumber: PropTypes.string.isRequired
  }

  render () {
    return (
      <ComponentContainer name='after-sales-manage-booking'>
        <ToolbarElement ariaLabel={_t.message('booking.toolbar.aria-label')}>
          <ToolbarItemBackHome
            name='back-homepage'
            iconName='chevron-left'
            label='booking.toolbar.back-homepage'
            available
          />
          <ToolbarItemChangeFulfillment
            bookingNumber={this.props.bookingNumber}
            name='change-fulfillment'
            label='booking.toolbar.change-fulfillment'
          />
          <ToolbarItemRebook
            bookingNumber={this.props.bookingNumber}
            name='rebook'
            iconName='rebook'
            label='booking.toolbar.rebooking'
          />
          <ToolbarItemChangeSeat
            bookingNumber={this.props.bookingNumber}
            name='change-seat'
            iconName='passenger-seat'
            label='booking.toolbar.change-seats'
          />
          <ToolbarItemUpdatePassengers
            bookingNumber={this.props.bookingNumber}
            name='change-name'
            iconName='edit'
            label='booking.toolbar.update-passengers'
          />
          <ToolbarItemCancel
            bookingNumber={this.props.bookingNumber}
            name='cancel'
            iconName='cancel-journey'
            label='booking.toolbar.cancellation'
          />
          <ToolbarItemAdditionalProducts
            bookingNumber={this.props.bookingNumber}
            name='add-services'
            iconName='add'
            label='booking.toolbar.additional-products'
          />
          <ToolbarItemManualConfirm
            bookingNumber={this.props.bookingNumber}
            name='manual-confirm'
            iconName='tick-circle'
            label='booking.toolbar.manual-confirm'
          />
          <ToolbarItemBookAgain
            bookingNumber={this.props.bookingNumber}
            name='book-again'
            iconName='chevron-right'
            label='booking.toolbar.book-again'
          />
        </ToolbarElement>
      </ComponentContainer>
    )
  }
}
