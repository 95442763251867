import React, { Component } from 'react'
import PropTypes from '../../../misc/prop-types'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../elements/text-label/text-label'
import DescriptionList from '../../../elements/description-list/description-list'
import {
  ISSUER_LEAP_CARD_NUMBER,
  ISSUER_DSP_PSC_CARD_NUMBER,
  CURRENCY_EURO
} from '../../../constants'

const LEAP_READ_LIST = 'leap.data.output'

export default class LeapReadList extends Component {
  static propTypes = {
    leapCard: PropTypes.shape({
      expiryDate: PropTypes.moment,
      cardIdentifier: PropTypes.number,
      issuerIdentifier: PropTypes.number,
      cardStatus: PropTypes.string,
      purseBalance: PropTypes.number,
      purseStatus: PropTypes.string,
      minTopUp: PropTypes.number,
      maxTopUp: PropTypes.number,
      cardProfile: PropTypes.shape({
        description: PropTypes.string
      }),
      lastTransaction: PropTypes.shape({
        dateTime: PropTypes.moment,
        value: PropTypes.number,
        eventCode: PropTypes.string,
        reasonCode: PropTypes.string,
        paymentMethod: PropTypes.string
      })
    }).isRequired
  }

  render () {
    const {leapCard} = this.props
    return (
      <div className='description-list-group'>
        <div className='description-list-group__column'>
          <div className='description-list-block'>
            <div className='description-list-block__header'>
              <TextLabel name='sub-heading' text={_t.message(`${LEAP_READ_LIST}.header.card`)} />
            </div>
            <div className='description-list-block__content'>
              <DescriptionList>
                <dt><TextLabel text={_t.message(`${LEAP_READ_LIST}.card-identifier`)} /></dt>
                <dd><TextLabel text={`${leapCard.cardIdentifier}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_READ_LIST}.card-profile`)} /></dt>
                <dd><TextLabel text={`${leapCard.cardProfile.description}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_READ_LIST}.issuer-identifier`)} /></dt>
                <dd><TextLabel text={leapCard.issuerIdentifier === ISSUER_LEAP_CARD_NUMBER
                  ? `${_t.message(`${LEAP_READ_LIST}.issuer-leap-card-name`)}`
                  : leapCard.issuerIdentifier === ISSUER_DSP_PSC_CARD_NUMBER
                    ? `${_t.message(`${LEAP_READ_LIST}.issuer-dsp-psc-card-name`)}`
                    : _t.message(`${LEAP_READ_LIST}.issuer-identifier-unknown`)}
                /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_READ_LIST}.card-status`)} /></dt>
                <dd><TextLabel text={`${leapCard.cardStatus}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_READ_LIST}.expiry-date`)} /></dt>
                <dd><TextLabel text={leapCard.expiryDate.format('L LT')} /></dd>
              </DescriptionList>
            </div>
          </div>
          <div className='description-list-block'>
            <div className='description-list-block__header'>
              <TextLabel name='sub-heading' text={_t.message(`${LEAP_READ_LIST}.header.purse`)} />
            </div>
            <div className='description-list-block__content'>
              <DescriptionList>
                <dt><TextLabel text={_t.message(`${LEAP_READ_LIST}.purse-balance`)} /></dt>
                <dd><TextLabel text={`${_t.formatCurrency(leapCard.purseBalance)}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_READ_LIST}.purse-status`)} /></dt>
                <dd><TextLabel text={`${leapCard.purseStatus}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_READ_LIST}.min-max-top-up`)} /></dt>
                <dd><TextLabel text={`${_t.formatCurrency(leapCard.minTopUp)} / ${_t.formatCurrency(leapCard.maxTopUp)}`} /></dd>
              </DescriptionList>
            </div>
          </div>
        </div>
        <div className='description-list-group__column'>
          <div className='description-list-block'>
            <div className='description-list-block__header'>
              <TextLabel name='sub-heading' text={_t.message(`${LEAP_READ_LIST}.header.last-event`)} />
            </div>
            <div className='description-list-block__content'>
              <DescriptionList>
                <dt><TextLabel text={_t.message(`${LEAP_READ_LIST}.last-transaction.date-time`)} /></dt>
                <dd><TextLabel text={`${leapCard.lastTransaction.dateTime && leapCard.lastTransaction.dateTime.format('L LT')}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_READ_LIST}.last-transaction.event-code`)} /></dt>
                <dd><TextLabel text={`${leapCard.lastTransaction.eventCode}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_READ_LIST}.last-transaction.transit-event-code`)} /></dt>
                <dd><TextLabel text={`${leapCard.lastTransaction.transitEventCode}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_READ_LIST}.last-transaction.value`)} /></dt>
                <dd><TextLabel text={_t.formatCurrency(leapCard.lastTransaction.value, CURRENCY_EURO)} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_READ_LIST}.last-transaction.payment-method`)} /></dt>
                <dd><TextLabel text={`${leapCard.lastTransaction.paymentMethod}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_READ_LIST}.last-transaction.reason-code`)} /></dt>
                <dd><TextLabel text={`${leapCard.lastTransaction.reasonCode || ''}`} /></dd>
              </DescriptionList>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
