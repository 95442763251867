import {
  UI_PRINTING_TICKET_NUMBER,
  UI_PRINTING_TICKET_START,
  UI_PRINTING_TICKET_STOP,
  UI_PRINTING_TICKET_ERROR,
  UI_RETRIEVE_AND_PRINT_CYCLE_START,
  UI_RETRIEVE_AND_PRINT_CYCLE_END
} from '../../../types'

export const startPrintingTickets = total => ({type: UI_PRINTING_TICKET_START, total})
export const printingTicketNumber = number => ({type: UI_PRINTING_TICKET_NUMBER, number})
export const stopPrintingTickets = () => ({type: UI_PRINTING_TICKET_STOP})
export const errorPrintingTickets = errorMessage => ({type: UI_PRINTING_TICKET_ERROR, errorMessage})

export const retrieveAndPrintCycleStart = () => ({type: UI_RETRIEVE_AND_PRINT_CYCLE_START})
export const retrieveAndPrintCycleEnd = () => ({type: UI_RETRIEVE_AND_PRINT_CYCLE_END})
