import moment from 'moment'
import { TRAVEL_DIRECTION_INBOUND, TRAVEL_DIRECTION_OUTBOUND } from 's3p-js-lib/src/constants'
import { enrichedJourneySearchSelector } from 's3p-js-lib/src/redux/selectors/api/orientation/journey-search'
import { travelSelector } from 's3p-js-lib/src/redux/selectors/api/orientation/offer/travel'
import { JOURNEY_SEARCH_PRODUCT_FAMILIES } from '../../../../../constants'
import { getCalendar } from '../../../api/v2/orientation/calendar'
import { getCalendarTravelId, snakeCaseKeys } from 's3p-js-lib/src/misc/utils'

const createTravelObjectBestPrices = (direction, departure, travel) => ({
  direction,
  id: getCalendarTravelId(direction),
  origin: travel.departureStation.UICStationCode,
  destination: travel.arrivalStation.UICStationCode,
  departure,
  productFamilies: JOURNEY_SEARCH_PRODUCT_FAMILIES
})

const _outboundOfferTravelSelector = travelSelector(TRAVEL_DIRECTION_OUTBOUND)
const _inboundOfferTravelSelector = travelSelector(TRAVEL_DIRECTION_INBOUND)

export const loadBestPrices = (isAftersales = false) => (dispatch, getState) => {
  const state = getState()
  const journeySearch = enrichedJourneySearchSelector(state)
  if (!journeySearch) {
    return
  }

  let travels = []
  if (journeySearch.departureDate) {
    const outboundTravel = _outboundOfferTravelSelector(state)
    outboundTravel && travels.push(createTravelObjectBestPrices(TRAVEL_DIRECTION_OUTBOUND, journeySearch.departureDate, outboundTravel))
  }

  if (journeySearch.returnDate) {
    const inboundTravel = _inboundOfferTravelSelector(state)
    inboundTravel && travels.push(createTravelObjectBestPrices(TRAVEL_DIRECTION_INBOUND, journeySearch.returnDate, inboundTravel))
  }

  if (travels.length) {
    const data = {
      is_aftersales: isAftersales,
      currency: journeySearch.currency,
      passengers: journeySearch.passengers.map(snakeCaseKeys),
      requested_product_types: [{
        product_type: 'ST',
        quantity: journeySearch.passengers.length
      }],
      calendars: travels.map(travel => ({
        id: travel.id,
        origin: travel.origin,
        destination: travel.destination,
        direction: travel.direction,
        entries: {
          begin: moment(travel.departure).clone().subtract(2, 'day').format('YYYY-MM-DD'),
          end: moment(travel.departure).clone().add(3, 'day').format('YYYY-MM-DD')
        }
      }))
    }

    dispatch(getCalendar(data))
  }
}
