import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import ComponentContainer from '../../../elements/component-container/component-container'
import Panel from '../../../elements/panel/panel'
import TextLabel from '../../../elements/text-label/text-label'
import DescriptionList from '../../../elements/description-list/description-list'
import './agent-details.scss'

export default class AgentDetails extends Component {
  static propTypes = {
    agent: PropTypes.shape({
      username: PropTypes.string,
      office: PropTypes.string,
      contractCode: PropTypes.string
    }),
    salesChannelCode: PropTypes.string,
    affiliateCode: PropTypes.string
  }

  render () {
    const {agent, salesChannelCode, affiliateCode} = {...this.props}
    return agent ? (
      <ComponentContainer name='booking-agent-details'>
        <Panel
          header={
            <Panel.Header
              name='booking-agent-details'
              title={_t.message('aftersales.booking.agent.header.title')}
              description={_t.message('aftersales.booking.agent.header.description')}
            />
          }
        >
          <DescriptionList>
            <dt>
              <TextLabel name='term' text={_t.message('aftersales.booking.agent.name')} />
            </dt>
            <dd>
              <TextLabel name='description' text={`${agent.username || ''}`} />
            </dd>
            <dt>
              <TextLabel name='term' text={_t.message('aftersales.booking.agent.office')} />
            </dt>
            <dd>
              <TextLabel name='description' text={`${agent.office || ''}`} />
            </dd>
            <dt>
              <TextLabel name='term' text={_t.message('aftersales.booking.agent.contract')} />
            </dt>
            <dd>
              <TextLabel name='description' text={`${agent.contractCode || ''}`} />
            </dd>
            <dt>
              <TextLabel name='term' text={_t.message('aftersales.booking.agent.saleschannel')} />
            </dt>
            <dd>
              <TextLabel name='description' text={`${salesChannelCode || ''}`} />
            </dd>
            <dt>
              <TextLabel name='term' text={_t.message('aftersales.booking.agent.affiliate')} />
            </dt>
            <dd>
              <TextLabel name='description' text={`${affiliateCode || ''}`} />
            </dd>
          </DescriptionList>
        </Panel>
      </ComponentContainer>
    ) : null
  }
}
