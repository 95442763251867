import DOMPurify from 'dompurify'
import Validator from 's3p-js-lib/src/forms/validator'
import { createSelector } from 'reselect'
import { passengerDetailsFieldPresenceSelector } from 's3p-js-lib/src/redux/selectors/api/user/sales-channel-properties'
import { passengersSelector } from 's3p-js-lib/src/redux/selectors/api/booking/passengers'
import {
  fulfillmentMethodSelector,
  isGroupBookingSelector,
  isTicketOnlyBookingSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { noCommaValidator } from './validators'
import {
  SALES_CHANNEL_PROPERTY_FIELD_PRESENCE_HIDDEN,
  SALES_CHANNEL_PROPERTY_FIELD_PRESENCE_REQUIRED
} from 's3p-js-lib/src/constants'
import {
  FULFILLMENT_METHOD_CODE_EMAIL,
  FULFILLMENT_METHOD_CODE_HOMEPRINT,
  FULFILLMENT_METHOD_CODE_SMS
} from '../constants'

const FIELD_EMAIL_ADDRESS = 'emailAddress'
const FIELD_PHONE_NUMBER = 'phoneNumber'

const passengerFormFields = {
  firstName: {
    normalizers: [
      DOMPurify.sanitize
    ],
    validators: [
      Validator.isFirstName,
      noCommaValidator
    ]
  },
  lastName: {
    normalizers: [
      DOMPurify.sanitize
    ],
    validators: [
      Validator.isLastName,
      noCommaValidator
    ]
  },
  [FIELD_EMAIL_ADDRESS]: {
    normalizers: [
      DOMPurify.sanitize
    ],
    validators: [
      Validator.isEmail
    ]
  },
  [FIELD_PHONE_NUMBER]: {
    normalizers: [
      DOMPurify.sanitize
    ],
    validators: [
      Validator.isPhoneNumber
    ]
  }
}

const passengerFormSelector = bookingSelector => createSelector(
  [
    passengerDetailsFieldPresenceSelector(bookingSelector),
    fulfillmentMethodSelector(bookingSelector)
  ],
  (fieldPresence, fulfillmentMethod) => {
    const fulfillmentMethodCode = fulfillmentMethod && fulfillmentMethod.code
    return {
      fields: Object.keys(passengerFormFields).reduce(
        (fields, name) => {
          if (fieldPresence[name] !== SALES_CHANNEL_PROPERTY_FIELD_PRESENCE_HIDDEN) {
            const validators = [...(passengerFormFields[name].validators || [])]
            if (fieldPresence[name] === SALES_CHANNEL_PROPERTY_FIELD_PRESENCE_REQUIRED ||
              (name === FIELD_EMAIL_ADDRESS &&
                [FULFILLMENT_METHOD_CODE_EMAIL, FULFILLMENT_METHOD_CODE_HOMEPRINT].includes(fulfillmentMethodCode)) ||
              (name === FIELD_PHONE_NUMBER && fulfillmentMethodCode === FULFILLMENT_METHOD_CODE_SMS)
            ) {
              validators.push(Validator.isRequired)
            }

            fields[name] = {
              ...passengerFormFields[name],
              validators
            }
          }

          return fields
        },
        {}
      )
    }
  }
)

export default bookingSelector => createSelector(
  passengersSelector(bookingSelector),
  passengerFormSelector(bookingSelector),
  isGroupBookingSelector(bookingSelector),
  isTicketOnlyBookingSelector(bookingSelector),
  (passengers, passengerForm, isGroupBooking, isTicketOnlyBooking) => ({
    fields: {
      groupName: {
        normalizers: [DOMPurify.sanitize],
        validators: [noCommaValidator].concat(isGroupBooking && !isTicketOnlyBooking ? Validator.isRequired : [])
      },
      protectPrivacy: {}
    },
    subForms: (passengers || []).reduce(
      (passengerForms, passenger) => {
        passengerForms[passenger.id] = {...passengerForm}
        return passengerForms
      },
      {}
    )
  })
)
