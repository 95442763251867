import { connect } from 'react-redux'
import { actionNotPossibleWithinTimeSelector } from '../../../../redux/selectors/containers/aftersales/void-tickets'
import VoidTickets from '../../void-tickets/void-tickets'

const _voidingNotPossibleWithinTimeSelector = actionNotPossibleWithinTimeSelector('NAMECHANGE')

const mapStateToProps = state => {
  return ({
    voidingNotPossibleWithinTime: _voidingNotPossibleWithinTimeSelector(state)
  })
}

export default connect(mapStateToProps)(VoidTickets)
