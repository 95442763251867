import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Panel from '../../../elements/panel/panel'
import TextLabel from '../../../elements/text-label/text-label'
import Table from '../../../elements/table/table'
import PaymentOverviewPayment from './payment-overview-payment'

export default class PaymentOverview extends Component {
  static propTypes = {
    totalPrice: PropTypes.number.isRequired,
    totalPriceToBePaid: PropTypes.number,
    removeVoucher: PropTypes.func.isRequired,
    returnPayments: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    hasCompletedBooking: PropTypes.bool,
    payments: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired
    })).isRequired
  }

  render () {
    return (
      <div className='payment-overview'>
        <Panel
          header={<Panel.Header
            name='review-payment'
            title={_t.message('review-payment.title')}
            description={_t.message('review-payment.description')}
          />}
        >
          <Table name='payment-overview' numColumns={2}>
            <tbody>
              <tr className='total'>
                <td><TextLabel name='total-description' text={_t.message('review-payment.total-amount')} /></td>
                <td><TextLabel name='total-value' text={_t.formatCurrency(this.props.totalPrice)} /></td>
              </tr>
              {this.props.payments.map(payment => (
                <PaymentOverviewPayment
                  key={payment.id}
                  payment={payment}
                  removeVoucher={this.props.removeVoucher}
                  returnPayments={this.props.returnPayments}
                  loading={this.props.isLoading}
                  disabled={this.props.isLoading || this.props.hasCompletedBooking}
                />
              ))}
            </tbody>
            <tfoot>
              <tr className='to-be-paid'>
                <td><TextLabel name='to-be-paid-description' text={_t.message('review-payment.to-be-paid')} /></td>
                <td><TextLabel name='to-be-paid-value' text={_t.formatCurrency(this.props.totalPriceToBePaid)} /></td>
              </tr>
            </tfoot>
          </Table>
        </Panel>
      </div>
    )
  }
}
