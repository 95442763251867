import { createSelector } from 'reselect'
import { camelCaseKeys } from '../../../../../misc/utils'
import { productFamiliesSelector } from './product-families'
import { passengersSelector } from '../offer/passengers'

const productMapper = (
  {
    product_family_id: productFamilyId,
    passenger_id: passengerId,
    ...product
  },
  productFamilies,
  passengers
) => ({
  ...camelCaseKeys(product),
  productFamily: productFamilies.find(productFamily => productFamily.id === productFamilyId),
  passenger: passengers.find(passenger => passenger.id === passengerId)
})

const productsSelectorCreator = propertyName => createSelector(
  [
    state => state.api.orientation.specification,
    productFamiliesSelector,
    passengersSelector
  ],
  (specification, productFamilies, passengers) => specification ? specification[propertyName].map(
    product => productMapper(product, productFamilies, passengers)
  ) : []
)

export const requiredProductsSelector = productsSelectorCreator('required_products')
export const additionalProductsSelector = productsSelectorCreator('additional_products')

export const productsSelector = createSelector(
  [requiredProductsSelector, additionalProductsSelector],
  (requiredProducts, additionalProducts) => requiredProducts.concat(additionalProducts)
)
