import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { lastMachineErrorsSelector } from '../../redux/selectors/machine/last-machine-errors'
import FeedbackMachine from '../../elements/feedback/feedback-machine'
import { clearLastMachineErrors } from '../../redux/actions/machine/machine'

class LastMachineErrorFeedbackContainer extends Component {
  static propTypes = {
    handleClearMachineErrors: PropTypes.func.isRequired
  }

  componentWillUnmount () {
    this.props.handleClearMachineErrors()
  }

  render () {
    return <FeedbackMachine {...this.props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  const _lastMachineErrorsSelector = lastMachineErrorsSelector(ownProps.namespaces)
  return state => ({
    messages: _lastMachineErrorsSelector(state)
  })
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClearMachineErrors: () => dispatch(clearLastMachineErrors(ownProps.namespaces))
})

export default connect(mapStateToProps, mapDispatchToProps)(LastMachineErrorFeedbackContainer)
