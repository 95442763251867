import { connect } from 'react-redux'
import CashFooter from '../../../components/base/payment-modal/cash-footer'
import { addCashPayment } from '../../../redux/actions/containers/base/payment-modal/cash-form'
import { loadingSelector } from '../../../redux/selectors/containers/base/payment-modal/payment-modal'

const mapStateToProps = state => ({
  loading: loadingSelector(state),
  disabled: !state.containers.base.paymentModal.cashForm.received
})

const mapDispatchToProps = {
  onClick: addCashPayment
}

export default connect(mapStateToProps, mapDispatchToProps)(CashFooter)
