/* globals S3P_SETTINGS: true */

import request from './request'

export default {

  async publicAccess (code) {
    return this._process({
      grant_type: 'https://com.sqills.s3.oauth.public',
      code: code || ''
    })
  },

  async agentLogin (username, password) {
    return this._process({
      grant_type: 'https://com.sqills.s3.oauth.agent',
      username: username.trim(),
      password: password.trim()
    })
  },

  async bookingLogin (bookingNumber, email) {
    return this._process({
      grant_type: 'https://com.sqills.s3.oauth.booking',
      booking_number: bookingNumber.trim(),
      email: email.trim()
    })
  },

  async bookingLoginByPassenger (bookingNumber, passengerFirstName, passengerLastName) {
    return this._process({
      grant_type: 'https://com.sqills.s3.oauth.booking',
      booking_number: bookingNumber.trim(),
      passenger_first_name: passengerFirstName.trim(),
      passenger_last_name: passengerLastName.trim()
    })
  },

  async stewardLogin (username, password) {
    return this._process({
      grant_type: 'https://com.sqills.s3.oauth.steward',
      username: username.trim(),
      password: password.trim()
    })
  },

  async refreshToken (refreshToken) {
    return this._process({
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    })
  },

  async _process (data) {
    return request({
      endpoint: '/oauth/v2/token',
      method: 'post',
      authorizationMethod: 'Basic',
      token: S3P_SETTINGS.s3Passenger.oAuth.secret,
      data
    })
  }
}
