import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Modal from '../../../elements/modal/modal'

export const ONE_LEAP_PRODUCT_BOOKING_MODAL = 'one-leap-product-booking-modal'

export default class OneLeapProductBookingModal extends Component {
  render () {
    return (
      <Modal
        name={ONE_LEAP_PRODUCT_BOOKING_MODAL}
        header={
          <Modal.Header
            title={_t.message(`product-search.one-leap-product-booking.title`)}
          />
        }
      >
        <p>{_t.message(`product-search.one-leap-product-booking.information`)}</p>
      </Modal>
    )
  }
}
