import { RETRYABLE_ERROR_HTTP_CODES } from '../constants'
import {
  apiRetryAttempt,
  apiRetryFinished
} from '../redux/actions/api/offline'
import moment from 'moment'
import {v4 as uuidV4} from 'uuid'

export const linearTimeout = attempt => attempt * 3000
export const exponentialTimeout = attempt => Math.pow(3, attempt) * 500

const isRetryableError = (error, retryableErrorHttpCodes) =>
  error instanceof Error && retryableErrorHttpCodes.includes(error.statusCode)

export const RetryManager = function () {
  this.config = {
    maxRetries: 3,
    nextInterval: exponentialTimeout,
    firstIntervalDelay: 0,
    defaultRetryableErrorHttpCodes: RETRYABLE_ERROR_HTTP_CODES,
    isRetryableError,
    offlineFeedbackMessage: 'offline.title'
  }

  this.retryRunning = false

  this.stop = () => {
    this.retryRunning = false
    clearTimeout(this.timeout)
  }

  this.patchCallWithRetry = (call, options = {}) => (...args) => {
    if (options?.injectS3IdempotencyKeyHeader) {
      args.push({s3IdempotencyKey: uuidV4()})
    }
    return this.runAndRetry(window.reduxStore.dispatch, () => call(...args), options)
  }

  this.runAndRetry = function (dispatch, request, options) {
    const instanceConfig = {...this.config, ...options}

    const retryStart = (resolve, reject) => error => {
      if (instanceConfig.isRetryableError(error, instanceConfig.defaultRetryableErrorHttpCodes)) {
        this.retryRunning = true

        const tick = (lastError, attempt) => {
          const nextTimeout = instanceConfig.nextInterval(attempt)

          dispatch(apiRetryAttempt(attempt, moment().add(nextTimeout, 'ms'), instanceConfig.offlineFeedbackMessage))

          if (attempt > instanceConfig.maxRetries) {
            dispatch(apiRetryFinished(lastError))
            this.retryRunning = false
            reject(lastError)
          } else {
            this.timeout = setTimeout(() => {
              request()
                .then(result => {
                  dispatch(apiRetryFinished(result))
                  this.retryRunning = false
                  resolve(result)
                })
                .catch(error => tick(error, attempt + 1))
            }, nextTimeout)
          }
        }

        tick(error, 2)
      } else {
        reject(error)
      }
    }

    return new Promise((resolve, reject) => {
      if (!this.retryRunning) {
        setTimeout(() => {
          request()
            .then(resolve)
            .catch(retryStart(resolve, reject))
        }, instanceConfig.firstIntervalDelay)
      }
    })
  }
}

export const defaultRetryInstance = new RetryManager()
