import _t from 's3p-js-lib/src/translate'
import {
  barcodeDocumentSelector,
  completedOrOverviewBookingSelector,
  additionalOrCompletedOrOverviewBookingSelector
} from '../../../../selectors/api/booking/booking'
import { sendMachineReceiptPrint } from '../../../machine/receipt-printer'
import { getBarcodeDocument } from '../../../api/booking/ticket-documents'
import { barcodeDocumentSelector as barcodeDocumentTicketSelector } from '../../../../selectors/api/booking/ticket-documents'
import {
  startPrintingTickets,
  printingTicketNumber,
  stopPrintingTickets,
  errorPrintingTickets,
  retrieveAndPrintCycleStart,
  retrieveAndPrintCycleEnd
} from './print-ticket-modal'
import { productsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/products'
import { additionalDetailsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/additional-details'
import {
  addAdditionalDetails,
  updateAdditionalDetails
} from 's3p-js-lib/src/redux/actions/api/v2/additional-details'
import { bookingNumberSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { ADDITIONAL_DETAILS_BARCODE_STATUS } from '../../../../../constants'

const _bookingNumberSelector = bookingNumberSelector(completedOrOverviewBookingSelector)
const _productsSelector = productsSelector(completedOrOverviewBookingSelector)
const _additionalDetailsSelector = additionalDetailsSelector(additionalOrCompletedOrOverviewBookingSelector)

const persistAdditionalDetails = ticketNumber => (dispatch, getState) => {
  const state = getState()
  const status = state.machine.receiptPrinter.lastStatus
  if (status) {
    const bookingNumber = _bookingNumberSelector(state)
    const additionalDetailsBooking = _additionalDetailsSelector(state)
    const product = _productsSelector(state).find(product => product.ticketNumber === ticketNumber)
    const additionalBarcodeStatusItem = additionalDetailsBooking.find(additionalDetail =>
      additionalDetail.itemRef === product.itemId &&
      additionalDetail.key === ADDITIONAL_DETAILS_BARCODE_STATUS
    )

    const action = additionalBarcodeStatusItem ? updateAdditionalDetails : addAdditionalDetails
    return dispatch(action(
      bookingNumber,
      [{
        key: ADDITIONAL_DETAILS_BARCODE_STATUS,
        value: additionalBarcodeStatusItem ? `${additionalBarcodeStatusItem.value},${status}` : status,
        itemRefId: product.itemRef
      }]
    ))
  }
}

export const printBarcodeTickets = () => async (dispatch, getState) => {
  await dispatch(retrieveAndPrintCycleStart())
  const barcodeDocumentBooking = barcodeDocumentTicketSelector(completedOrOverviewBookingSelector)(getState())

  if (barcodeDocumentBooking && barcodeDocumentBooking.url) {
    await dispatch(getBarcodeDocument(barcodeDocumentBooking.url))
  }

  await dispatch(printTickets())
  await dispatch(retrieveAndPrintCycleEnd())
}

export const continueBarcodeTickets = () => (dispatch, getState) => {
  dispatch(printTickets(getState().containers.base.finalizeBooking.printTicketsModal.ticketNumber - 1))
}

const printTickets = (start = 0) => async (dispatch, getState) => {
  const barcodeDocumentText = barcodeDocumentSelector(getState())
  if (barcodeDocumentText) {
    try {
      const barcodeDocuments = JSON.parse(barcodeDocumentText)
      if (!barcodeDocuments) {
        dispatch(errorPrintingTickets(_t.message('finalize-booking-modal.barcode.default-error')))
      } else {
        dispatch(startPrintingTickets(barcodeDocuments.length))
        let index
        for (index = start; index < barcodeDocuments.length; index++) {
          await dispatch(printingTicketNumber(index + 1))
          const result = await dispatch(sendMachineReceiptPrint(barcodeDocuments[index]))
          await dispatch(persistAdditionalDetails(barcodeDocuments[index].ticketNumber))
          if (!result) {
            break
          }
        }
        dispatch(stopPrintingTickets())
      }
    } catch (error) {
      dispatch(errorPrintingTickets(_t.message('finalize-booking-modal.barcode.default-error')))
    }
  } else {
    dispatch(errorPrintingTickets(_t.message('finalize-booking-modal.barcode.fetch-error')))
  }
}
