import PropTypes from 'prop-types'
import React, { Component } from 'react'
import BasicLayout from '../../components/base/basic-layout'
import ErrorBookingConfirmation from '../../containers/error/booking-confirmation'

export default class ErrorBookingConfirmationLayout extends Component {
  static propTypes = {
    routeParams: PropTypes.shape({
      bookingNumber: PropTypes.string.isRequired
    })
  }

  render () {
    return (
      <BasicLayout>
        <ErrorBookingConfirmation bookingNumber={this.props.routeParams.bookingNumber} />
      </BasicLayout>
    )
  }
}
