import { createSelector } from 'reselect'
import { camelCaseKeys } from '../../../../misc/utils'
import moment from 'moment'
import { BOOKING_AGENT_NOTE, BOOKING_NOTE } from '../../../../constants'

const mapNote = note => ({
  ...camelCaseKeys(note),
  createDateTime: moment(note.create_date_time)
})

export const bookingNotesSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking && booking.notes
    ? booking.notes
      .filter(note => note.note_type === BOOKING_AGENT_NOTE || note.note_type === BOOKING_NOTE)
      .map(mapNote)
    : []
)

export const isBookingQueueEnabled = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking
    ? !booking.notes.some(
      note => note.booking_queue && note.note_type === BOOKING_AGENT_NOTE && !note.queue_resolution
    )
    : false
)
