import {
  UI_CLEAR_LAST_MACHINE_ERROR,
  MACHINE_OPEN_CASH_DRAWER_FAILURE,
  MACHINE_OPEN_CASH_DRAWER_REQUEST,
  MACHINE_OPEN_CASH_DRAWER_SUCCESS,
  MACHINE_PRINT_MAGNETIC_TICKET_FAILURE,
  MACHINE_PRINT_MAGNETIC_TICKET_REQUEST,
  MACHINE_PRINT_MAGNETIC_TICKET_SUCCESS,
  MACHINE_READ_MAGNETIC_TICKET_REQUEST,
  MACHINE_READ_MAGNETIC_TICKET_SUCCESS,
  MACHINE_READ_MAGNETIC_TICKET_FAILURE,
  MACHINE_EJECT_MAGNETIC_TICKET_REQUEST,
  MACHINE_EJECT_MAGNETIC_TICKET_SUCCESS,
  MACHINE_EJECT_MAGNETIC_TICKET_FAILURE,
  MACHINE_VOID_MAGNETIC_TICKET_REQUEST,
  MACHINE_VOID_MAGNETIC_TICKET_SUCCESS,
  MACHINE_VOID_MAGNETIC_TICKET_FAILURE,
  MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_REQUEST,
  MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_SUCCESS,
  MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_FAILURE,
  MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_REQUEST,
  MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_SUCCESS,
  MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_FAILURE,
  MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_REQUEST,
  MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_SUCCESS,
  MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_FAILURE,
  MACHINE_RECEIPT_PRINT_FAILURE,
  MACHINE_RECEIPT_PRINT_REQUEST,
  MACHINE_RECEIPT_PRINT_SUCCESS,
  MACHINE_TEST_RECEIPT_PRINT_REQUEST,
  MACHINE_TEST_RECEIPT_PRINT_SUCCESS,
  MACHINE_TEST_RECEIPT_PRINT_FAILURE,
  MACHINE_DISPLAY_LINES_FAILURE,
  MACHINE_DISPLAY_LINES_REQUEST,
  MACHINE_DISPLAY_LINES_SUCCESS,
  MACHINE_CLEAR_DISPLAY_FAILURE,
  MACHINE_CLEAR_DISPLAY_REQUEST,
  MACHINE_CLEAR_DISPLAY_SUCCESS,
  MACHINE_TEST_DISPLAY_REQUEST,
  MACHINE_TEST_DISPLAY_SUCCESS,
  MACHINE_TEST_DISPLAY_FAILURE,
  MACHINE_READ_BARCODE_REQUEST,
  MACHINE_READ_BARCODE_SUCCESS,
  MACHINE_READ_BARCODE_FAILURE,
  MACHINE_REFUND_PIN_PAYMENT_FAILURE,
  MACHINE_REFUND_PIN_PAYMENT_SUCCESS,
  MACHINE_REFUND_PIN_PAYMENT_REQUEST,
  MACHINE_LEAP_READ_REQUEST,
  MACHINE_LEAP_READ_SUCCESS,
  MACHINE_LEAP_READ_FAILURE,
  MACHINE_LEAP_TOP_UP_REQUEST,
  MACHINE_LEAP_TOP_UP_SUCCESS,
  MACHINE_LEAP_TOP_UP_FAILURE,
  MACHINE_LEAP_PROCESS_ACTION_LIST_REQUEST,
  MACHINE_LEAP_PROCESS_ACTION_LIST_SUCCESS,
  MACHINE_LEAP_PROCESS_ACTION_LIST_FAILURE,
  MACHINE_LEAP_ADD_PRODUCT_REQUEST,
  MACHINE_LEAP_ADD_PRODUCT_SUCCESS,
  MACHINE_LEAP_ADD_PRODUCT_FAILURE,
  MACHINE_LEAP_APPLY_CD_REQUEST,
  MACHINE_LEAP_APPLY_CD_FAILURE,
  MACHINE_LEAP_APPLY_CD_SUCCESS,
  MACHINE_LEAP_SET_CONFIGURATION_REQUEST,
  MACHINE_LEAP_SET_CONFIGURATION_FAILURE,
  MACHINE_LEAP_SET_CONFIGURATION_SUCCESS,
  MACHINE_LEAP_REVERSE_REQUEST,
  MACHINE_LEAP_REVERSE_SUCCESS,
  MACHINE_LEAP_REVERSE_FAILURE
} from '../../actions/types'
import { clearErrors } from 's3p-js-lib/src/redux/reducers/api/base/last-api-errors/last-api-errors'

const createNewState = (namespace, state, action) => {
  return {
    ...state,
    [namespace]: action.machineError && action.machineError.response ? action.machineError.response.messages : []
  }
}

export default function lastMachineError (state = {}, action) {
  switch (action.type) {
    case UI_CLEAR_LAST_MACHINE_ERROR:
      return clearErrors(state, action.namespaces)
    case MACHINE_REFUND_PIN_PAYMENT_FAILURE:
    case MACHINE_REFUND_PIN_PAYMENT_REQUEST:
    case MACHINE_REFUND_PIN_PAYMENT_SUCCESS:
      return createNewState('pinPayment', state, action)
    case MACHINE_OPEN_CASH_DRAWER_SUCCESS:
    case MACHINE_OPEN_CASH_DRAWER_FAILURE:
    case MACHINE_OPEN_CASH_DRAWER_REQUEST:
      return createNewState('cashDrawer', state, action)
    case MACHINE_CLEAR_DISPLAY_FAILURE:
    case MACHINE_CLEAR_DISPLAY_REQUEST:
    case MACHINE_CLEAR_DISPLAY_SUCCESS:
    case MACHINE_DISPLAY_LINES_FAILURE:
    case MACHINE_DISPLAY_LINES_REQUEST:
    case MACHINE_DISPLAY_LINES_SUCCESS:
    case MACHINE_TEST_DISPLAY_REQUEST:
    case MACHINE_TEST_DISPLAY_SUCCESS:
    case MACHINE_TEST_DISPLAY_FAILURE:
      return createNewState('customerDisplay', state, action)
    case MACHINE_RECEIPT_PRINT_FAILURE:
    case MACHINE_RECEIPT_PRINT_REQUEST:
    case MACHINE_RECEIPT_PRINT_SUCCESS:
    case MACHINE_TEST_RECEIPT_PRINT_REQUEST:
    case MACHINE_TEST_RECEIPT_PRINT_SUCCESS:
    case MACHINE_TEST_RECEIPT_PRINT_FAILURE:
      return createNewState('receiptPrinter', state, action)
    case MACHINE_PRINT_MAGNETIC_TICKET_REQUEST:
    case MACHINE_PRINT_MAGNETIC_TICKET_SUCCESS:
    case MACHINE_PRINT_MAGNETIC_TICKET_FAILURE:
      return createNewState('magneticTicketPrinterPrint', state, action)
    case MACHINE_READ_MAGNETIC_TICKET_REQUEST:
    case MACHINE_READ_MAGNETIC_TICKET_SUCCESS:
    case MACHINE_READ_MAGNETIC_TICKET_FAILURE:
      return createNewState('magneticTicketPrinterRead', state, action)
    case MACHINE_EJECT_MAGNETIC_TICKET_REQUEST:
    case MACHINE_EJECT_MAGNETIC_TICKET_SUCCESS:
    case MACHINE_EJECT_MAGNETIC_TICKET_FAILURE:
      return createNewState('magneticTicketPrinterEject', state, action)
    case MACHINE_VOID_MAGNETIC_TICKET_REQUEST:
    case MACHINE_VOID_MAGNETIC_TICKET_SUCCESS:
    case MACHINE_VOID_MAGNETIC_TICKET_FAILURE:
      return createNewState('magneticTicketPrinterVoid', state, action)
    case MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_REQUEST:
    case MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_SUCCESS:
    case MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_FAILURE:
      return createNewState('magneticTicketPrinterUpdateTicketTemplate', state, action)
    case MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_REQUEST:
    case MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_SUCCESS:
    case MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_FAILURE:
      return createNewState('magneticTicketPrinterGetTicketTemplates', state, action)
    case MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_REQUEST:
    case MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_SUCCESS:
    case MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_FAILURE:
      return createNewState('magneticTicketPrinterRemoveTicketTemplate', state, action)
    case MACHINE_READ_BARCODE_REQUEST:
    case MACHINE_READ_BARCODE_SUCCESS:
    case MACHINE_READ_BARCODE_FAILURE:
      return createNewState('barcodeScanner', state, action)
    case MACHINE_LEAP_READ_REQUEST:
    case MACHINE_LEAP_READ_SUCCESS:
    case MACHINE_LEAP_READ_FAILURE:
    case MACHINE_LEAP_TOP_UP_REQUEST:
    case MACHINE_LEAP_TOP_UP_SUCCESS:
    case MACHINE_LEAP_TOP_UP_FAILURE:
    case MACHINE_LEAP_PROCESS_ACTION_LIST_REQUEST:
    case MACHINE_LEAP_PROCESS_ACTION_LIST_SUCCESS:
    case MACHINE_LEAP_PROCESS_ACTION_LIST_FAILURE:
    case MACHINE_LEAP_ADD_PRODUCT_REQUEST:
    case MACHINE_LEAP_ADD_PRODUCT_SUCCESS:
    case MACHINE_LEAP_ADD_PRODUCT_FAILURE:
    case MACHINE_LEAP_APPLY_CD_REQUEST:
    case MACHINE_LEAP_APPLY_CD_SUCCESS:
    case MACHINE_LEAP_APPLY_CD_FAILURE:
    case MACHINE_LEAP_SET_CONFIGURATION_REQUEST:
    case MACHINE_LEAP_SET_CONFIGURATION_FAILURE:
    case MACHINE_LEAP_SET_CONFIGURATION_SUCCESS:
    case MACHINE_LEAP_REVERSE_REQUEST:
    case MACHINE_LEAP_REVERSE_SUCCESS:
    case MACHINE_LEAP_REVERSE_FAILURE:
      return createNewState('leapDevice', state, action)
    default:
      return state
  }
}
