import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Feedback, { FEEDBACK_SUCCESS, FEEDBACK_INFORMATION } from '../../../../../elements/feedback/feedback'
import Modal from '../../../../../elements/modal/modal'
import LastApiErrorFeedback from '../../../../../containers/base/last-api-error-feedback'
import TextLabel from '../../../../../elements/text-label/text-label'
import Button from '../../../../../elements/button/button'
import _t from 's3p-js-lib/src/translate'

export default class RefundVoucher extends Component {
  static propTypes = {
    amount: PropTypes.number,
    refundSuccess: PropTypes.bool,
    isLoadingRefund: PropTypes.bool,
    refundedVoucher: PropTypes.shape({
      code: PropTypes.string
    }),
    onRefundVoucher: PropTypes.func.isRequired
  }

  render () {
    return (
      <div>
        {this.props.amount
          ? (
            <Feedback
              status={FEEDBACK_INFORMATION}
              text={_t.message('aftersales.refund-modal.voucher.refund-description', {
                amount: _t.formatCurrency(this.props.amount)
              })}
            />
          ) : null }
        <LastApiErrorFeedback namespaces={['requestRefund']} />
        {this.props.refundSuccess
          ? (
            <Feedback
              key='success'
              status={FEEDBACK_SUCCESS}
              text={this.props.refundedVoucher
                ? _t.message('aftersales.booking.payment-details.refund-requested-voucher',
                  {voucherCode: this.props.refundedVoucher.code}
                )
                : _t.message('aftersales.booking.payment-details.refund-requested')}
            />
          ) : null}
        <Modal.CustomFooter>
          <Button
            name='confirm'
            hotkey='proceed'
            className='primary'
            onClick={this.props.onRefundVoucher}
            loading={this.props.isLoadingRefund}
          >
            <TextLabel name='next-step' text={_t.message('aftersales.refund-modal.voucher.confirm')} />
          </Button>
        </Modal.CustomFooter>
      </div>
    )
  }
}
