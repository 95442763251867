import { createSelector } from 'reselect'
import moment from 'moment'
import padStart from 'lodash/padStart'
import { camelCaseKeys } from 's3p-js-lib/src/misc/utils'
import localStorage from 's3p-js-lib/src/local-storage'
import { stationsSelector } from 's3p-js-lib/src/redux/selectors/api/base/stations'
import { completedBookingSelector, totalPriceBookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { productsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/products'
import { outboundTariffSegmentsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/tariff-segments'
import {
  configurationExpiryDurationSelector,
  maximumTransactionValueSelector,
  minimumTransactionValueSelector,
  transactionReversalDurationSelector
} from '../containers/base/leap'
import camelCase from 'lodash/camelCase'
import { isPedPaymentMethod, parseToInt } from '../../../misc/utils'
import {
  STORAGE_ITEM_NAME_LEAP_SCHEME_WIDE_CODE,
  SCHEME_WIDE_CODE_MAP_BLOCKING_REASON,
  SCHEME_WIDE_CODE_MAP_PRODUCT_TYPE_LONGNAMES,
  SCHEME_WIDE_CODE_MAP_PARTICIPANT_ID_LONGNAME,
  SCHEME_WIDE_CODE_MAP_PAYMENT_REFUND_METHOD,
  SCHEME_WIDE_CODE_MAP_LOCATION_ID,
  SCHEME_WIDE_CODE_MAP_ROUTE_CODE,
  SCHEME_WIDE_CODE_MAP_SERVICE_CODE,
  SCHEME_WIDE_CODE_MAP_EVENT_CODE,
  SCHEME_WIDE_CODE_MAP_CARD_PROFILE,
  LEAP_PAYMENT_CODE_CASH,
  LEAP_PAYMENT_CODE_CREDIT_DEBIT_CARD,
  LEAP_PAYMENT_CODE_VOUCHER,
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_CODE_VOUCHER,
  EXTERNAL_REFERENCE_LEAP_PRODUCT_CLASS_NAME,
  EXTERNAL_REFERENCE_LEAP_PRODUCT_VERSION_NAME,
  EXTERNAL_REFERENCE_LEAP_PRODUCT_OWNER_ID_NAME,
  EXTERNAL_REFERENCE_LEAP_PRODUCT_SINGLE_OPERATOR_ACCESS_NAME,
  EXTERNAL_REFERENCE_LEAP_PRODUCT_COUPONS_NAME,
  EXTERNAL_REFERENCE_LEAP_PRODUCT_TICKET_SPECIFIC_DATA,
  EXTERNAL_REFERENCE_LEAP_PRODUCT_CARD_PROFILE_RESTRICTIONS,
  EXTERNAL_REFERENCE_LEAP_PRODUCT_SALE_REPORT_LIMIT,
  EXTERNAL_REFERENCE_LEAP_PRODUCT_SALES_MAXIMUM_JOURNEYS,
  LEAP_TRANSACTION_EVENT_CODE_CHECK_IN,
  LEAP_MAX_ACTIVE_PRODUCTS_ON_CARD
} from '../../../constants'
import { paymentsSelector } from '../api/booking/payments'

export const leapCardIsExpiredSelector = state => state.machine.leap.read ? state.machine.leap.read.expiry_date <= moment().unix() : false
export const leapCardIsBlockedSelector = state => state.machine.leap.read ? state.machine.leap.read.card_status !== 8 && state.machine.leap.read.card_status !== 15 : false
export const leapPurseIsBlockedSelector = state => state.machine.leap.read ? state.machine.leap.read.purse_status !== 8 : false

export const leapCardIsValidSelector = createSelector(
  [
    leapCardIsExpiredSelector,
    leapCardIsBlockedSelector,
    leapPurseIsBlockedSelector
  ],
  (cardIsExpired, cardIsBlocked, purseIsBlocked) => !cardIsExpired && !cardIsBlocked && !purseIsBlocked
)

const mapSchemeWideCodeToString = (schemeWideCode, map, value) => {
  return schemeWideCode && schemeWideCode[map] && schemeWideCode[map][value]
    ? schemeWideCode[map][value]
    : `UNKNOWN ${map} (${value})`
}

export const leapDataSelector = createSelector(
  [state => state.machine.leap.read],
  leapData => {
    if (!leapData) {
      return null
    }

    const schemeWideCode = localStorage.get(STORAGE_ITEM_NAME_LEAP_SCHEME_WIDE_CODE)

    const minimumTransactionValue = minimumTransactionValueSelector()
    const maximumTransactionValue = maximumTransactionValueSelector()

    const data = {
      ...camelCaseKeys(leapData),
      cardNumber: parseToInt(`${leapData.issuer_identifier}${padStart(leapData.card_identifier, 9, '0')}`),
      purseBalance: leapData.purse_balance / 100,
      cardProfile: {code: leapData.card_profile, description: mapSchemeWideCodeToString(schemeWideCode, SCHEME_WIDE_CODE_MAP_CARD_PROFILE, leapData.card_profile)},
      cardStatus: mapSchemeWideCodeToString(schemeWideCode, SCHEME_WIDE_CODE_MAP_BLOCKING_REASON, leapData.card_status),
      purseStatus: mapSchemeWideCodeToString(schemeWideCode, SCHEME_WIDE_CODE_MAP_BLOCKING_REASON, leapData.purse_status),
      expiryDate: moment.unix(leapData.expiry_date),
      minTopUp: parseInt(leapData.purse_balance > minimumTransactionValue ? 0 : minimumTransactionValue - leapData.purse_balance) / 100,
      maxTopUp: parseInt(leapData.purse_balance > maximumTransactionValue ? 0 : maximumTransactionValue - leapData.purse_balance) / 100
    }

    data.products = Object.keys(leapData).reduce((cardProducts, key) => {
      if (key.match(/product_{1}([0-9]+)/)) {
        delete data[camelCase(key)]
        const product = {
          ...camelCaseKeys(leapData[key]),
          issueSamId: leapData[key].IssueSAMID,
          price: (leapData[key].Price / 100),
          typeCode: leapData[key].Type,
          type: mapSchemeWideCodeToString(schemeWideCode, SCHEME_WIDE_CODE_MAP_PRODUCT_TYPE_LONGNAMES, leapData[key].Type),
          ownerId: mapSchemeWideCodeToString(schemeWideCode, SCHEME_WIDE_CODE_MAP_PARTICIPANT_ID_LONGNAME, leapData[key].OwnerID),
          status: mapSchemeWideCodeToString(schemeWideCode, SCHEME_WIDE_CODE_MAP_BLOCKING_REASON, leapData[key].Status),
          issueParticipantId: mapSchemeWideCodeToString(schemeWideCode, SCHEME_WIDE_CODE_MAP_PARTICIPANT_ID_LONGNAME, leapData[key].IssueParticipantID),
          paymentMethod: mapSchemeWideCodeToString(schemeWideCode, SCHEME_WIDE_CODE_MAP_PAYMENT_REFUND_METHOD, leapData[key].PaymentMethod),
          origin: mapSchemeWideCodeToString(schemeWideCode, SCHEME_WIDE_CODE_MAP_LOCATION_ID, leapData[key].Origin),
          destination: mapSchemeWideCodeToString(schemeWideCode, SCHEME_WIDE_CODE_MAP_LOCATION_ID, leapData[key].Destination),
          route: mapSchemeWideCodeToString(schemeWideCode, SCHEME_WIDE_CODE_MAP_ROUTE_CODE, leapData[key].Route),
          service: mapSchemeWideCodeToString(schemeWideCode, SCHEME_WIDE_CODE_MAP_SERVICE_CODE, leapData[key].Service),
          activationDateTime: leapData[key].ActivationDateTime ? moment.unix(leapData[key].ActivationDateTime).utc() : undefined,
          blockDateTime: leapData[key].BlockDateTime ? moment.unix(leapData[key].BlockDateTime).utc() : undefined,
          expiryDateTime: leapData[key].ExpiryDateTime
            ? moment.unix(leapData[key].ExpiryDateTime).utc()
            : moment.unix(leapData[key].IssueDateTime + configurationExpiryDurationSelector()).utc(),
          issueDateTime: leapData[key].IssueDateTime ? moment.unix(leapData[key].IssueDateTime).utc() : undefined,
          startDateTime: leapData[key].StartDateTime ? moment.unix(leapData[key].StartDateTime).utc() : undefined,
          key
        }
        delete product.issueSamid
        cardProducts.push(product)
      }
      return cardProducts
    }, [])

    data.lastTransaction = {
      ...camelCaseKeys(leapData.last_transaction),
      eventCode: mapSchemeWideCodeToString(schemeWideCode, SCHEME_WIDE_CODE_MAP_EVENT_CODE, leapData.last_transaction.EventCode),
      transitEventCode: mapSchemeWideCodeToString(schemeWideCode, SCHEME_WIDE_CODE_MAP_EVENT_CODE, leapData.last_transaction.TransitEventCode),
      value: leapData.last_transaction.Value / 100,
      dateTime: moment.unix(leapData.last_transaction.DateTime),
      samId: leapData.last_transaction.SAMID,
      paymentMethod: mapSchemeWideCodeToString(schemeWideCode, SCHEME_WIDE_CODE_MAP_PAYMENT_REFUND_METHOD, leapData.last_transaction.PaymentMethod),
      reasonCode: leapData.last_transaction.ReasonCode !== null ? mapSchemeWideCodeToString(schemeWideCode, SCHEME_WIDE_CODE_MAP_BLOCKING_REASON, leapData.last_transaction.ReasonCode) : ''
    }
    delete data.lastTransaction.samid

    return data
  }
)

export const reverseTransationIsAllowedSelector = createSelector(
  [
    transactionReversalDurationSelector,
    leapDataSelector
  ],
  (duration, leapCardData) => {
    if (!(duration && leapCardData)) {
      return false
    }
    const maxCheckinTime = moment().subtract(duration, 'seconds')
    return leapCardData.lastTransaction.dateTime.isAfter(maxCheckinTime) &&
      leapCardData.lastTransaction.transitEventCode === LEAP_TRANSACTION_EVENT_CODE_CHECK_IN
  }
)
const purseBalanceSelector = createSelector(
  [
    leapDataSelector,
    totalPriceBookingSelector(completedBookingSelector)
  ],
  (data, price) => data && data.purseBalance + price
)

export const purseAmountTooLowSelector = createSelector(
  [
    purseBalanceSelector,
    minimumTransactionValueSelector
  ],
  (purseBalance, minimumTransactionValue) => purseBalance < minimumTransactionValue
)

export const purseAmountTooHighSelector = createSelector(
  [
    purseBalanceSelector,
    maximumTransactionValueSelector
  ],
  (purseBalance, maximumTransactionValue) => purseBalance > maximumTransactionValue
)

export const addProductIsAllowedSelector = createSelector(
  [leapDataSelector],
  leapCardData => {
    let activeProductCount = 0
    if (leapCardData && leapCardData.products && leapCardData.products.length) {
      activeProductCount = leapCardData.products.reduce((count, product) => {
        if (moment().isBefore(product.expiryDateTime)) {
          return count + 1
        }
        return count
      }, 0)
    }
    return activeProductCount < LEAP_MAX_ACTIVE_PRODUCTS_ON_CARD
  }
)

export const leapProcessActionListSelector = createSelector(
  [
    state => state.machine.leap.processActionList
  ],
  processActionList => {
    let result = []
    processActionList && Object.keys(processActionList).forEach(key => {
      if (key.match(/(transaction_)[0-9]+/)) {
        const number = key.match(/\d+/)[0]
        result.push({
          id: number,
          status: processActionList[`action_${number}`].match(/\d+/)[0],
          response: processActionList[`transaction_${number}`]
        })
      }
    })
    return result
  }
)

export const leapTopUpPaymentCode = createSelector(
  [paymentsSelector(completedBookingSelector)],
  paymentMethods => {
    if (paymentMethods.find(payment => payment.method === PAYMENT_METHOD_CASH)) {
      return LEAP_PAYMENT_CODE_CASH
    } else if (paymentMethods.find(payment => isPedPaymentMethod(payment.method))) {
      return LEAP_PAYMENT_CODE_CREDIT_DEBIT_CARD
    } else if (paymentMethods.find(payment => payment.method === PAYMENT_METHOD_CODE_VOUCHER)) {
      return LEAP_PAYMENT_CODE_VOUCHER
    }
  }
)

const LEAP_ADD_PRODUCT_USN_VALUE = 0
const LEAP_ADD_PRODUCT_ISSUE_PARTICIPANT_ID = 21
const LEAP_ADD_PRODUCT_SALES_REPORT_LIMIT = 0
const EXTERNAL_REFERENCE_STATION_LEAP_LOCATION_ID_NAME = 'leap_location_id'

const mapBooleanTextToInt = value => value === 'true' ? 1 : value === 'false' ? 0 : undefined

export const leapProductSelector = createSelector(
  [
    productsSelector(completedBookingSelector),
    totalPriceBookingSelector(completedBookingSelector),
    outboundTariffSegmentsSelector(completedBookingSelector),
    leapTopUpPaymentCode,
    stationsSelector
  ],
  (products, price, segments, leapPaymentCode, stations) => {
    if (!segments.length) {
      return {}
    }

    const product = products[0]

    let productClass
    let productVersion
    let ownerId
    let singleOperatorAccess
    let coupons
    let ticketSpecificData
    let cardProfileRestrictions
    let saleReportLimit
    let maximumJourneys

    for (const data of product.metaData) {
      switch (data.key) {
        case EXTERNAL_REFERENCE_LEAP_PRODUCT_CLASS_NAME:
          productClass = data.value
          break
        case EXTERNAL_REFERENCE_LEAP_PRODUCT_VERSION_NAME:
          productVersion = data.value
          break
        case EXTERNAL_REFERENCE_LEAP_PRODUCT_OWNER_ID_NAME:
          ownerId = data.value
          break
        case EXTERNAL_REFERENCE_LEAP_PRODUCT_SINGLE_OPERATOR_ACCESS_NAME:
          singleOperatorAccess = data.value
          break
        case EXTERNAL_REFERENCE_LEAP_PRODUCT_SALE_REPORT_LIMIT:
          saleReportLimit = data.value || LEAP_ADD_PRODUCT_SALES_REPORT_LIMIT
          break
        case EXTERNAL_REFERENCE_LEAP_PRODUCT_COUPONS_NAME:
          coupons = data.value
          break
        case EXTERNAL_REFERENCE_LEAP_PRODUCT_TICKET_SPECIFIC_DATA:
          ticketSpecificData = data.value
          break
        case EXTERNAL_REFERENCE_LEAP_PRODUCT_CARD_PROFILE_RESTRICTIONS:
          cardProfileRestrictions = data.value.replace(/]|\[/g, '')
          break
        case EXTERNAL_REFERENCE_LEAP_PRODUCT_SALES_MAXIMUM_JOURNEYS:
          maximumJourneys = data.value
          break
      }
    }

    const segment = segments[0]
    const origin = segment.departureStation ? stations.find(station => station.UICStationCode === segment.departureStation.UICStationCode) : {}
    const originLeapLocationId = (origin.metaData || []).find(data => data.key === EXTERNAL_REFERENCE_STATION_LEAP_LOCATION_ID_NAME)

    const destination = segment.arrivalStation ? stations.find(station => station.UICStationCode === segment.arrivalStation.UICStationCode) : {}
    const destinationLeapLocationId = (destination.metaData || []).find(data => data.key === EXTERNAL_REFERENCE_STATION_LEAP_LOCATION_ID_NAME)

    const productCodeSplit = product.productCode.split('-')
    return {
      Class: parseToInt(productClass) || undefined,
      Type: productCodeSplit.length ? parseToInt(productCodeSplit[0]) : undefined,
      Version: parseToInt(productVersion) || undefined,
      USN: LEAP_ADD_PRODUCT_USN_VALUE,
      OwnerID: parseToInt(ownerId) || undefined,
      SOAC: mapBooleanTextToInt(singleOperatorAccess),
      SaleReportLimit: parseToInt(saleReportLimit) || undefined,
      IssueDateTime: moment().unix(),
      // note: these values will be written to leap card as utc based timestamps
      StartDateTime: segments[0].departureDate.hour(0).minute(0).second(0).unix(),
      ExpiryDateTime: segments[0].arrivalDate.hour(23).minute(59).second(59).unix(),
      ActivationDateTime: moment().unix(),
      IssueParticipantID: LEAP_ADD_PRODUCT_ISSUE_PARTICIPANT_ID,
      Price: price * 100,
      PaymentMethod: leapPaymentCode,
      Coupons: parseToInt(coupons) || undefined,
      Origin: originLeapLocationId ? parseToInt(originLeapLocationId.value) : undefined,
      Destination: destinationLeapLocationId ? parseToInt(destinationLeapLocationId.value) : undefined,
      Journeys: parseToInt(maximumJourneys) !== null ? parseToInt(maximumJourneys) : undefined,
      TicketSpecificData: ticketSpecificData || undefined,
      CardProfileRestrictions: cardProfileRestrictions ? cardProfileRestrictions.split(',') : undefined
    }
  }
)
