export const FORM_AGENT_CHANGE_PASSWORD = 'agentChangePassword'
export const FORM_AGENT_LOGIN = 'agentLogin'
export const FORM_TYPE_AGENT_MODIFY = 'agentModify'
export const FORM_TYPE_AGENT_SHIFT_NOTE = 'agentShiftNote'
export const FORM_TYPE_AGENT_SHIFT_START = 'agentShiftStart'
export const FORM_BOOKING_NOTE = 'bookingNote'
export const FORM_BOOKINGS_FILTER = 'bookingsFilter'
export const FORM_TYPE_BOOKING_LOGIN_CUSTOMER = 'bookingLoginCustomer'
export const FORM_TYPE_BOOKING_LOGIN_PASSENGER = 'BookingLoginPassenger'
export const FORM_TYPE_BOOKING_RESEND_TICKETS = 'BookingResendTickets'
export const FORM_CUSTOMER_DETAILS = 'customerDetails'
export const FORM_FIND_CUSTOMERS = 'findCustomers'
export const FORM_JOURNEY_SEARCH = 'journeySearch'
export const FORM_PASSENGER_DETAILS = 'passengerDetails'
export const FORM_PASSENGER_DETAILS_AFTERSALES = 'passengerDetailsAftersales'
export const FORM_PAYMENT_METHOD = 'paymentMethod'
export const FORM_PRODUCT_SEARCH = 'productSearch'
export const FORM_TYPE_REBOOK_JOURNEY_SEARCH = 'rebookJourneySearch'
export const FORM_TYPE_REDEEM_VOUCHER = 'redeemVoucher'
export const FORM_TYPE_VOUCHER_SEARCH = 'voucherSearch'
