import {
  API_GET_BOOKINGS_REQUEST,
  API_GET_BOOKINGS_SUCCESS,
  API_GET_BOOKINGS_FAILURE
} from '../../types'
import Client from '../../../../../../src/api/client'
import ensureToken from '../../../../api/ensure-token'
import ResponseCodes from 's3p-js-lib/src/api/response-codes'

const getBookingsRequest = (field, value) => ({type: API_GET_BOOKINGS_REQUEST, field, value})
const getBookingsSuccess = response => ({type: API_GET_BOOKINGS_SUCCESS, response})
const getBookingsFailure = error => ({type: API_GET_BOOKINGS_FAILURE, error})

const handledErrors = [
  ResponseCodes.BOOKING_SEARCH_NOT_SPECIFIC_CUSTOMER,
  ResponseCodes.BOOKING_SEARCH_NOT_SPECIFIC_PASSENGER
]

export const getBookings = (field, value) => ensureToken(async (token, dispatch) => {
  try {
    dispatch(getBookingsRequest(field, value))
    const response = await Client.getBookings(token, field, value)
    dispatch(getBookingsSuccess(response))
  } catch (error) {
    dispatch(getBookingsFailure(error))
    if (handledErrors.includes(error.errorCode)) {
      return false
    }

    throw error
  }
})
