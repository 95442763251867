import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Button from '../../../elements/button/button'
import Icon from '../../../elements/icon/icon'
import TextLabel from '../../../elements/text-label/text-label'

class NextButton extends Component {
  static propTypes = {
    goForward: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasSelectedSeats: PropTypes.bool.isRequired
  }

  render () {
    return (
      this.props.hasSelectedSeats
        ? (
          <react-wrapper class='react-wrapper step-navigation-wrapper'>
            <Button
              name='next-step'
              className='tertiary'
              onClick={this.props.goForward}
              loading={this.props.isLoading}
            >
              <TextLabel>
                <TextLabel.Text text={_t.message('booking-summary.action.next')} />
                <Icon name='chevron-right' className='align-right' />
              </TextLabel>
            </Button>
          </react-wrapper>
        )
        : null
    )
  }
}

export default NextButton
