import { createSelector } from 'reselect'
import { currentAgentSelector } from '../user/agent'
import { salesChannelPropertySelector } from '../user/sales-channel-properties'
import { agentSelector as bookingAgentSelector } from '../booking/agent'
import {
  AGENT_PERMISSION_BOOKING_ACCESS_OUTSIDE_CONTRACT,
  AGENT_PERMISSION_BOOKING_ACCESS_AGENT_RESTRICTION,
  AGENT_PERMISSION_BOOKING_ACCESS_OFFICE_RESTRICTION,
  AGENT_PERMISSION_BOOKING_FUNCTIONS_VOUCHER,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_REBOOKING,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_CANCELLATION,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_PARTIAL_CANCELLATION,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_SEAT_CHANGE,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_NAME_CHANGE,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_ADDONS,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_WAIVE_FEES,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_OVERRIDE_AFTERSALES_RULES,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_OVERRIDE_VALIDATION_RULES_FULL,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_OVERRIDE_VALIDATION_RULES_ISSUE,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_REBOOKING,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_CANCELLATION,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_PARTIAL_CANCELLATION,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_SEAT_CHANGE,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_NAME_CHANGE,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_ADDONS,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_WAIVE_FEES,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_OVERRIDE_AFTERSALES_RULES,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_OVERRIDE_VALIDATION_RULES_FULL,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_OVERRIDE_VALIDATION_RULES_ISSUE,
  SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_REBOOK,
  SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_CANCEL,
  SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_OVERRIDE_FEES,
  SALES_CHANNEL_PROPERTY_ALLOW_VOUCHER_PAYMENT,
  ROLE_AGENT,
  ROLE_BOOKING,
  ROLE_CRM,
  ROLE_PUBLIC,
  AGENT_PERMISSION_ADMINISTRATION_LIST,
  SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_OVERRIDE_RULES,
  SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_OVERRIDE_VALIDATIONS
} from '../../../../constants'

// eslint-disable-next-line func-style
export function createAuthSelector (options) {
  if ('role' in options) {
    return createSelector(
      [userRolesSelector],
      roles => roles.includes(options.role)
    )
  } else if ('oneOfRoles' in options) {
    return createSelector(
      [userRolesSelector],
      roles => options.oneOfRoles.some(role => roles.includes(role))
    )
  } else {
    return isLoggedInSelector
  }
}

export const isLoggedInSelector = createSelector(
  [state => state.api.auth.oauthToken, state => state.api.user.user],
  (token, user) => Boolean(token && user)
)

export const userRolesSelector = createSelector(
  [state => state.api.user.user],
  user => user ? user.roles : []
)

export const isAgentSelector = createSelector(
  [userRolesSelector],
  roles => roles.includes(ROLE_AGENT)
)

export const isBookingUserSelector = createSelector(
  [userRolesSelector],
  roles => roles.includes(ROLE_BOOKING)
)

export const isCrmUserSelector = createSelector(
  [userRolesSelector],
  roles => roles.includes(ROLE_CRM)
)

export const isPublicUserSelector = createSelector(
  [userRolesSelector],
  roles => roles.includes(ROLE_PUBLIC)
)

export const isInternalBookingSelector = bookingSelector => createSelector(
  [
    bookingAgentSelector(bookingSelector),
    currentAgentSelector
  ],
  (bookingAgent, currentAgent) => {
    if (!bookingAgent || !currentAgent) {
      return false
    }

    let internal = currentAgent.permissions.includes(AGENT_PERMISSION_BOOKING_ACCESS_OUTSIDE_CONTRACT)
      ? Boolean(currentAgent.organization && currentAgent.organization.code === bookingAgent.organization)
      : currentAgent.contracts.some(contract => contract.code === bookingAgent.contractCode)

    if (internal && currentAgent.permissions.includes(AGENT_PERMISSION_BOOKING_ACCESS_AGENT_RESTRICTION)) {
      internal = currentAgent.username === bookingAgent.username
    }

    if (internal && currentAgent.permissions.includes(AGENT_PERMISSION_BOOKING_ACCESS_OFFICE_RESTRICTION)) {
      internal = currentAgent.offices.some(office => office.name === bookingAgent.office)
    }

    return internal
  }
)

export const hasAccessToAftersalesSelector = (bookingSelector, internalPermission, externalPermission) => createSelector(
  [isInternalBookingSelector(bookingSelector), currentAgentSelector],
  (isInternalBooking, currentAgent) => !currentAgent ||
  currentAgent.permissions.includes(isInternalBooking ? internalPermission : externalPermission)
)

export const hasAccessToAftersalesRebookingSelector = bookingSelector => state =>
  hasAccessToAftersalesSelector(
    bookingSelector,
    AGENT_PERMISSION_AFTERSALES_CONTRACT_REBOOKING,
    AGENT_PERMISSION_AFTERSALES_EXTERNAL_REBOOKING
  )(state) &&
salesChannelPropertySelector(SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_REBOOK)(state)

export const hasAccessToAftersalesCancellationSelector = bookingSelector => state =>
  hasAccessToAftersalesSelector(
    bookingSelector,
    AGENT_PERMISSION_AFTERSALES_CONTRACT_CANCELLATION,
    AGENT_PERMISSION_AFTERSALES_EXTERNAL_CANCELLATION
  )(state) &&
salesChannelPropertySelector(SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_CANCEL)(state)

export const hasAccessToAftersalesPartialCancellationSelector = bookingSelector => state =>
  hasAccessToAftersalesCancellationSelector(bookingSelector)(state) &&
hasAccessToAftersalesSelector(
  bookingSelector,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_PARTIAL_CANCELLATION,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_PARTIAL_CANCELLATION
)(state)

export const hasAccessToAftersalesChangeSeatsSelector = bookingSelector => hasAccessToAftersalesSelector(
  bookingSelector,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_SEAT_CHANGE,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_SEAT_CHANGE
)

export const hasAccessToAftersalesUpdatePassengersSelector = bookingSelector => hasAccessToAftersalesSelector(
  bookingSelector,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_NAME_CHANGE,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_NAME_CHANGE
)

export const hasAccessToAftersalesAdditionalProductsSelector = bookingSelector => hasAccessToAftersalesSelector(
  bookingSelector,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_ADDONS,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_ADDONS
)

export const hasAccessToWaiveFeesSelector = bookingSelector => state =>
  Boolean(currentAgentSelector(state)) &&
hasAccessToAftersalesSelector(
  bookingSelector,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_WAIVE_FEES,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_WAIVE_FEES
)(state) &&
salesChannelPropertySelector(SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_OVERRIDE_FEES)(state)

export const hasAccessToVoucherPaymentSelector = state => {
  const currentAgent = currentAgentSelector(state)
  const hasPermission = !currentAgent || currentAgent.permissions.includes(
    AGENT_PERMISSION_BOOKING_FUNCTIONS_VOUCHER
  )
  const allowedBySalesChannel = salesChannelPropertySelector(SALES_CHANNEL_PROPERTY_ALLOW_VOUCHER_PAYMENT)(state)

  return hasPermission && allowedBySalesChannel
}

export const hasAccessToAgentManagementSelector = state => {
  const currentAgent = currentAgentSelector(state)
  return Boolean(currentAgent && currentAgent.permissions.includes(AGENT_PERMISSION_ADMINISTRATION_LIST))
}

export const canOverrideRulesSelector = (bookingSelector, internalPermission, externalPermission, salesChannelProperty) => {
  const _isInternalBookingSelector = isInternalBookingSelector(bookingSelector)
  const _salesChannelPropertySelector = salesChannelPropertySelector(salesChannelProperty)

  return state => {
    const currentAgent = _salesChannelPropertySelector(state) && currentAgentSelector(state)

    return Boolean(
      currentAgent &&
      currentAgent.permissions.includes(_isInternalBookingSelector(state) ? internalPermission : externalPermission)
    )
  }
}

export const canOverrideAftersalesRulesSelector = bookingSelector => canOverrideRulesSelector(
  bookingSelector,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_OVERRIDE_AFTERSALES_RULES,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_OVERRIDE_AFTERSALES_RULES,
  SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_OVERRIDE_RULES
)

export const canOverrideValidationRulesFullSelector = bookingSelector => canOverrideRulesSelector(
  bookingSelector,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_OVERRIDE_VALIDATION_RULES_FULL,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_OVERRIDE_VALIDATION_RULES_FULL,
  SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_OVERRIDE_VALIDATIONS
)

export const canOverrideValidationRulesIssueSelector = bookingSelector => canOverrideRulesSelector(
  bookingSelector,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_OVERRIDE_VALIDATION_RULES_ISSUE,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_OVERRIDE_VALIDATION_RULES_ISSUE,
  SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_OVERRIDE_VALIDATIONS
)
