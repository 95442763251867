import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Modal from '../../elements/modal/modal'
import containVisibleElement from '../../containers/base/visible-element'
import _t from 's3p-js-lib/src/translate'
import FeedbackApi from '../../elements/feedback/feedback-api'
import TextLabel from '../../elements/text-label/text-label'

const LAST_API_ERROR_FEEDBACK_MODAL = 'last-api-error-feedback'

class FeedbackApiModal extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onShowModal: PropTypes.func.isRequired,
    onHideElement: PropTypes.func.isRequired,
    isElementVisible: PropTypes.func.isRequired,
    messages: PropTypes.array.isRequired,
    defaultMessage: PropTypes.string
  }

  constructor (...args) {
    super(...args)

    this.onHideModal = this.onHideModal.bind(this)
  }

  UNSAFE_componentWillMount () {
    if (this.props.open) {
      this.props.onShowModal(LAST_API_ERROR_FEEDBACK_MODAL)
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.open && !nextProps.isElementVisible(LAST_API_ERROR_FEEDBACK_MODAL)) {
      nextProps.onShowModal(LAST_API_ERROR_FEEDBACK_MODAL)
    }
  }

  onHideModal () {
    this.props.onHideElement(LAST_API_ERROR_FEEDBACK_MODAL)
    this.props.onClose && this.props.onClose()
  }

  render () {
    const {messages, defaultMessage} = this.props
    return this.props.isElementVisible(LAST_API_ERROR_FEEDBACK_MODAL) ? (
      <Modal
        onHideElement={this.onHideModal}
        name={LAST_API_ERROR_FEEDBACK_MODAL}
        header={<Modal.Header title={_t.message('feedback.title')} />}
        footer={
          <Modal.FooterSingle onHideElement={this.onHideModal}>
            <TextLabel text={_t.message('modal.close')} />
          </Modal.FooterSingle>
        }
      >
        <FeedbackApi messages={messages} defaultMessage={defaultMessage} />
      </Modal>
    ) : null
  }
}

export default containVisibleElement(LAST_API_ERROR_FEEDBACK_MODAL)(FeedbackApiModal)
