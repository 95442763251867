import {paymentResultSelector} from '../../../../selectors/machine/pin-payment'
import {sendMachineReceiptPrint} from '../../../machine/receipt-printer'
import {createPEDErrorReceipt} from '../../../../../misc/receipt-template-parser'
import _t from 's3p-js-lib/src/translate'
import {addBookingNote} from 's3p-js-lib/src/redux/actions/api/booking/add-booking-note'
import {addNotesAgentShift} from 's3p-js-lib/src/redux/actions/api/user/agent/shift'
import {bookingNumberSelector} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import {
  completedOrProvisionalOrOriginalOrOverviewBookingSelector
} from '../../../../selectors/api/booking/booking'
import {paymentsWithoutFailedSelector} from '../../../../selectors/api/booking/payments'
import {clearLastApiErrors} from 's3p-js-lib/src/redux/actions/api/base/last-api-errors'

export const handleTicketFulfillmentApiErrorIfPinPaymentSucceeded = errorMessage => async (dispatch, getState) => {
  const paymentResult = paymentResultSelector(getState())
  if ('result' in paymentResult && paymentResult.result) {
    dispatch(handleTicketFulfillmentApiError(errorMessage))
  }
}

export const handleTicketFulfillmentApiError = errorMessage => async (dispatch, getState) => {
  try {
    const bookingNumber = bookingNumberSelector(completedOrProvisionalOrOriginalOrOverviewBookingSelector)(getState())
    const paymentResult = paymentResultSelector(getState())
    const transactionId = paymentResult && paymentResult.transactionId
    const pedPayment = paymentsWithoutFailedSelector(completedOrProvisionalOrOriginalOrOverviewBookingSelector)(getState()).find(
      payment => payment.externalReference === transactionId
    )
    const paymentReference = pedPayment && pedPayment.refId
    const amountToBePaid = getState().containers.base.tiles.paymentMethods.amountToBePaid

    await dispatch(sendMachineReceiptPrint(createPEDErrorReceipt({
      bookingNumber,
      paymentReference,
      transactionId,
      amountToBePaid
    })))
    const note = `There was a technical problem with booking ${bookingNumber}. Cancellation in WorldPay is required. Booking number: ${bookingNumber} Payment reference booking system: ${paymentReference || ''} Payment reference payment system: ${transactionId || ''} Booking value: ${_t.formatNumber(amountToBePaid || 0.0, 'decimal')} Error: ${errorMessage}`
    await dispatch(addBookingNote(bookingNumber, {queue: '', content: note}))
    await dispatch(addNotesAgentShift([note]))
  } finally {
    // ignore errors
  }
  dispatch(clearLastApiErrors(['addBookingNote', 'addNotesAgentShift']))
}
