import { initialState } from 's3p-js-lib/src/redux/reducers/api/v2/user/agent/shift-report'
import { MACHINE_RECEIPT_PRINT_SUCCESS } from '../../../../../actions/types'

export default (state = initialState, action) => {
  switch (action.type) {
    case MACHINE_RECEIPT_PRINT_SUCCESS:
      return initialState
    default:
      return state
  }
}
