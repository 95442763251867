import Validator from './validator'
import { createSelector } from 'reselect'
import { passengerDetailsFieldPresenceSelector } from '../redux/selectors/api/user/sales-channel-properties'
import { passengersSelector } from '../redux/selectors/api/booking/passengers'
import {
  SALES_CHANNEL_PROPERTY_FIELD_PRESENCE_HIDDEN,
  SALES_CHANNEL_PROPERTY_FIELD_PRESENCE_OPTIONAL,
  SALES_CHANNEL_PROPERTY_FIELD_PRESENCE_REQUIRED
} from '../constants'

const PassengerFormFields = {
  title: {},
  firstName: {
    validators: [Validator.isFirstName, Validator.isRequired]
  },
  lastName: {
    validators: [Validator.isLastName, Validator.isRequired]
  },
  emailAddress: {
    validators: [Validator.isEmail]
  },
  phoneNumber: {
    validators: [Validator.isPhoneNumber]
  }
}

const fieldPresenceValidators = {
  [SALES_CHANNEL_PROPERTY_FIELD_PRESENCE_REQUIRED]: [Validator.isRequired],
  [SALES_CHANNEL_PROPERTY_FIELD_PRESENCE_OPTIONAL]: [],
  [SALES_CHANNEL_PROPERTY_FIELD_PRESENCE_HIDDEN]: []
}

const passengerFormSelector = bookingSelector => createSelector(
  [passengerDetailsFieldPresenceSelector(bookingSelector)],
  fieldPresence => ({
    fields: Object.keys(PassengerFormFields).reduce((fields, name) => {
      if (fieldPresence[name] !== SALES_CHANNEL_PROPERTY_FIELD_PRESENCE_HIDDEN) {
        fields[name] = {
          ...PassengerFormFields[name],
          validators: [
            ...(PassengerFormFields[name].validators || []),
            ...fieldPresenceValidators[fieldPresence[name] || SALES_CHANNEL_PROPERTY_FIELD_PRESENCE_OPTIONAL]
          ]
        }
      }
      return fields
    }, {})
  })
)

export default bookingSelector => createSelector(
  [
    passengersSelector(bookingSelector),
    passengerFormSelector(bookingSelector)
  ],
  (passengers, passengerForm) => ({
    fields: {
      groupName: {},
      protectPrivacy: {}
    },
    subForms: (passengers || []).reduce(
      (passengerForms, passenger) => {
        passengerForms[passenger.id] = {...passengerForm}

        return passengerForms
      }, {})
  })
)
