import { connect } from 'react-redux'
import FulfillmentMethods from '../../../components/reservations/booking-details/fulfillment-methods'
import { selectFulfillmentMethod } from '../../../redux/actions/containers/reservations/booking-details/fulfillment-methods'
import { provisionalOrCompletedBookingSelector } from '../../../redux/selectors/api/booking/booking'
import { fulfillmentMethodsSelector } from '../../../redux/selectors/containers/base/tiles/fulfillment-methods'
import { modalIsActiveSelector } from '../../../redux/selectors/containers/base/visible-element'

const _fulfillmentMethodsSelector = fulfillmentMethodsSelector(provisionalOrCompletedBookingSelector)

const mapStateToProps = state => {
  const modalIsActive = modalIsActiveSelector(state)

  return ({
    fulfillmentMethods: _fulfillmentMethodsSelector(state),
    loading: !modalIsActive && state.api.v2.loading.updateFulfillmentMethod,
    loadingFulfillmentMethod: modalIsActive && state.api.v2.loading.updateFulfillmentMethod,
    disabled: (modalIsActive || state.api.v2.loading.updateFulfillmentMethod || state.machine.loading.getMachineStatusDevices)
  })
}

const mapDispatchToProps = {
  handleSelect: selectFulfillmentMethod
}

export default connect(mapStateToProps, mapDispatchToProps)(FulfillmentMethods)
