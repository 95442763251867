import { connect } from 'react-redux'
import { bookingNumberSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { completedOrProvisionalOrOriginalOrOverviewBookingSelector } from '../../../redux/selectors/api/booking/booking'
import { revertAftersales } from '../../../redux/actions/containers/aftersales/operation/operation'
import Operation from '../../../components/aftersales/operation/operation'
import { isLoadingMagstripeActionsSelector } from '../../../redux/selectors/machine/magnetic-ticket-printer'

const _bookingNumberSelector = bookingNumberSelector(completedOrProvisionalOrOriginalOrOverviewBookingSelector)

const mapStateToProps = state => ({
  bookingNumber: _bookingNumberSelector(state),
  loading: state.api.loading.aftersalesRevert,
  disabled: isLoadingMagstripeActionsSelector(state)
})

const mapDispatchToProps = {
  onClickAbort: revertAftersales
}

export default connect(mapStateToProps, mapDispatchToProps)(Operation)
