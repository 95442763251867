import Validator from './validator'

export const CATEGORY_FIRST_NAME = 'firstName'
export const CATEGORY_LAST_NAME = 'lastName'
export const CATEGORY_EMAIL_ADDRESS = 'emailAddress'
export const CATEGORY_PHONE_NUMBER = 'phoneNumber'
export const CATEGORY_COMPANY_NAME = 'companyName'
export const CATEGORY_STREET_NAME = 'streetName'
export const CATEGORY_HOUSE_NUMBER = 'houseNumber'
export const CATEGORY_POSTAL_CODE = 'postalCode'
export const CATEGORY_CITY = 'city'

export default {
  fields: {
    term: {
      validators: [
        Validator.isRequired
      ]
    },
    category: {
      validators: [
        Validator.isRequired
      ]
    }
  }
}
