import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Form from '../../../elements/form/form'
import TextLabel from '../../../elements/text-label/text-label'
import Icon from '../../../elements/icon/icon'
import Button from '../../../elements/button/button'
import PassengerDetailsModalTickets from '../../../containers/tickets/passenger-details-modal'
import PassengerDetailsModalReservations from '../../../containers/reservations/journey-search/passenger-details-modal'
import PassengerDetailsModalRebooking from '../../../containers/aftersales/rebooking/journey-search/passenger-details-modal'

export default class PassengerDetails extends Component {
  static propTypes = {
    handleShowModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool,
    disabled: PropTypes.bool,
    isReservationFlow: PropTypes.bool,
    isRebookingFlow: PropTypes.bool
  }

  render () {
    return (
      <Form.InputWrapper name='passenger-details'>
        <Button type='button'
          name='passenger-details'
          className='secondary'
          disabled={this.props.disabled}
          hotkey='focusProduct'
          onClick={this.props.handleShowModal}
        >
          <TextLabel>
            <Icon name='passengers' className='align-left' />
            <TextLabel.Text text={_t.message('product-search.passenger-details-button')} />
          </TextLabel>
        </Button>
        {this.props.showModal
          ? (this.props.isReservationFlow
            ? <PassengerDetailsModalReservations />
            : (this.props.isRebookingFlow
              ? <PassengerDetailsModalRebooking />
              : <PassengerDetailsModalTickets />
            ))
          : null
        }
      </Form.InputWrapper>
    )
  }
}
