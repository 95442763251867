export const USER_INPUT_REBOOKING_OFFER_REBOOK_SELECTION = 'USER_INPUT_REBOOKING_OFFER_REBOOK_SELECTION'
export const USER_INPUT_CANCELLATION_SELECT_ITEM_IDS = 'USER_INPUT_CANCELLATION_SELECT_ITEM_IDS'
export const USER_INPUT_CANCELLATION_DESELECT_ITEM_IDS = 'USER_INPUT_CANCELLATION_DESELECT_ITEM_IDS'
export const USER_INPUT_WAIVE_FEES_SELECT = 'USER_INPUT_WAIVE_FEES_SELECT'
export const USER_INPUT_WAIVE_FEES_DESELECT = 'USER_INPUT_WAIVE_FEES_DESELECT'
export const USER_INPUT_JOURNEY_SEARCH_SWAP_ORIGIN_DESTINATION = 'USER_INPUT_JOURNEY_SEARCH_SWAP_ORIGIN_DESTINATION'
export const USER_INPUT_SEAT_SELECTOR_SELECT_PASSENGER = 'USER_INPUT_SEAT_SELECTOR_SELECT_PASSENGER'
export const USER_INPUT_SEAT_SELECTOR_SELECT_CARRIAGE = 'USER_INPUT_SEAT_SELECTOR_SELECT_CARRIAGE'
export const USER_INPUT_SEAT_SELECTOR_SELECT_SEAT = 'USER_INPUT_SEAT_SELECTOR_SELECT_SEAT'
export const USER_INPUT_SEAT_SELECTOR_DESTROY = 'USER_INPUT_SEAT_SELECTOR_DESTROY'
export const USER_INPUT_SEAT_SELECTOR_SET_NEXT_SEGMENT_ID = 'USER_INPUT_SEAT_SELECTOR_SET_NEXT_SEGMENT_ID'
export const USER_INPUT_SEAT_SELECTOR_SELECT_SEGMENT = 'USER_INPUT_SEAT_SELECTOR_SELECT_SEGMENT'
export const USER_INPUT_SET_CURRENCY = 'USER_INPUT_SET_CURRENCY'
export const USER_INPUT_SET_LOCALE = 'USER_INPUT_SET_LOCALE'
export const USER_INPUT_FORM_VALIDATE = 'USER_INPUT_FORM_VALIDATE'
export const USER_INPUT_FORM_TOUCH_FIELD = 'USER_INPUT_FORM_TOUCH_FIELD'
export const USER_INPUT_FORM_TOUCH_FIELDS = 'USER_INPUT_FORM_TOUCH_FIELDS'
export const USER_INPUT_FORM_FOCUS_FIELD = 'USER_INPUT_FORM_FOCUS_FIELD'
export const USER_INPUT_FORM_BLUR_FIELD = 'USER_INPUT_FORM_BLUR_FIELD'
export const USER_INPUT_FORM_CHANGE_FIELD = 'USER_INPUT_FORM_CHANGE_FIELD'
export const USER_INPUT_FORM_UNTOUCH_FIELDS = 'USER_INPUT_FORM_UNTOUCH_FIELDS'
export const USER_INPUT_FORM_RESET = 'USER_INPUT_FORM_RESET'
export const USER_INPUT_FORM_LOAD_VALUES = 'USER_INPUT_FORM_LOAD_VALUES'
export const USER_INPUT_FORM_DESTROY = 'USER_INPUT_FORM_DESTROY'
export const USER_INPUT_PENDING_SELECT_FULFILLMENT_METHOD = 'USER_INPUT_PENDING_SELECT_FULFILLMENT_METHOD'
export const USER_INPUT_SET_AFFILIATE_CODE = 'USER_INPUT_SET_AFFILIATE_CODE'
export const USER_INPUT_SET_SELECTED_UNIQUE_BUNDLE_IDS = 'USER_INPUT_SET_SELECTED_UNIQUE_BUNDLE_IDS'
export const USER_INPUT_RESET_SELECTED_UNIQUE_BUNDLE_IDS = 'USER_INPUT_RESET_SELECTED_UNIQUE_BUNDLE_IDS'
export const USER_INPUT_FORGOT_PASSWORD_PREFILL_EMAIL = 'USER_INPUT_FORGOT_PASSWORD_PREFILL_EMAIL'
