import provisionalBooking from './provisional-booking'
import aftersalesOriginalBooking from './aftersales-original-booking'
import completedBooking from './completed-booking'
import overviewBooking from './overview-booking'
import additionalDetailsBooking from './additional-details-booking'
import receiptDocument from './receipt-document'
import bookingList from './booking-list'
import voucherList from './voucher-list'

export default {
  provisionalBooking,
  aftersalesOriginalBooking,
  completedBooking,
  overviewBooking,
  additionalDetailsBooking,
  receiptDocument,
  bookingList,
  voucherList
}
