import { USER_INPUT_SEAT_SELECTOR_SELECT_SEGMENT } from '../../types'
import {
  selectSeat as baseSelectSeat,
  selectCarriage as baseSelectCarriage
} from 's3p-js-lib/src/redux/actions/user-input/base/seat-selector'
import {
  selectedPassengerIdSelector,
  selectedCarriageNumberSelector
} from 's3p-js-lib/src/redux/selectors/user-input/base/seat-selector'
import { carriagesSelector } from 's3p-js-lib/src/redux/selectors/api/base/seat-selector/carriage-layouts'

export const selectSegment = segmentId => ({type: USER_INPUT_SEAT_SELECTOR_SELECT_SEGMENT, segmentId})

export const selectSeat = seatNumber => (dispatch, getState) => {
  const selectedPassengerId = selectedPassengerIdSelector(getState())
  const selectedCarriageNumber = selectedCarriageNumberSelector(getState())

  return dispatch(baseSelectSeat(selectedPassengerId, selectedCarriageNumber, seatNumber))
}

export const selectCarriage = carriageNumber => (dispatch, getState) => {
  const carriages = carriagesSelector(getState())

  const carriageIndex = carriages.findIndex(carriage => carriage.carriageNumber === carriageNumber)
  if (carriageIndex >= 0) {
    dispatch(baseSelectCarriage(carriages[carriageIndex].carriageNumber))
  }
}
