import React, { Component } from 'react'
import PropTypes from '../../../../misc/prop-types'
import _t from 's3p-js-lib/src/translate'
import Segment from './segment'
import BaseSegmentSet from '../../../../elements/segment-set/segment-set'

export default class SegmentSet extends Component {
  static propTypes = {
    onSelectSegment: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      available: PropTypes.bool.isRequired,
      status: PropTypes.any.isRequired,
      hasSelectedSeats: PropTypes.bool.isRequired
    })),
    travelInfo: PropTypes.shape({
      direction: PropTypes.oneOf(['outbound', 'inbound']).isRequired,
      departureTime: PropTypes.moment.isRequired
    })
  }

  render () {
    const {options, travelInfo, onSelectSegment} = this.props
    return (
      <BaseSegmentSet
        direction={travelInfo.direction}
        summary={<BaseSegmentSet.JourneySummary
          label={_t.message(`allocated-seats.journey.${travelInfo.direction}`)}
          travelDate={_t.message('allocated-seats.journey.travel-date', {
            date: travelInfo.departureTime.format('ddd, D MMM')
          })}
          direction={travelInfo.direction}
        />}
      >
        {options && options.map(option =>
          <Segment key={option.leg.id} onSelectSegment={onSelectSegment} option={option} />
        )}
      </BaseSegmentSet>
    )
  }
}
