import {
  API_CANCELLATION_CANCEL_REQUEST,
  API_CANCELLATION_CANCEL_SUCCESS,
  API_CANCELLATION_CANCEL_FAILURE
} from '../../../types'
import Client from '../../../../../api/client'
import ensureToken from '../../../../../api/ensure-token'
import ResponseCodes from '../../../../../api/response-codes'

const cancelItemsRequest = (bookingNumber, itemIds, waiveFees, overrideAftersalesRules, overrideValidationRules) =>
  ({type: API_CANCELLATION_CANCEL_REQUEST, bookingNumber, itemIds, waiveFees, overrideAftersalesRules, overrideValidationRules})
const cancelItemsSuccess = response => ({type: API_CANCELLATION_CANCEL_SUCCESS, response})
const cancelItemsFailure = error => ({type: API_CANCELLATION_CANCEL_FAILURE, error})

export const cancelItems = (bookingNumber, itemIds, waiveFees, overrideAftersalesRules = false, overrideValidationRules = []) => ensureToken(async function (token, dispatch) {
  try {
    dispatch(cancelItemsRequest(bookingNumber, itemIds, waiveFees, overrideAftersalesRules, overrideValidationRules))
    const response = await Client.cancellationCancel(token, bookingNumber, itemIds, waiveFees, overrideAftersalesRules, overrideValidationRules)
    dispatch(cancelItemsSuccess(response))
  } catch (error) {
    dispatch(cancelItemsFailure(error))
    const handledErrors = [ResponseCodes.AFTER_SALES_OPERATION_HAS_INVALID_ITEMS]
    if (!handledErrors.includes(error.errorCode)) {
      throw error
    }
    return false
  }
})
