import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import ComponentContainer from '../../elements/component-container/component-container'
import Panel from '../../elements/panel/panel'
import Button from '../../elements/button/button'
import TextLabel from '../../elements/text-label/text-label'
import Feedback, { FEEDBACK_SUCCESS, FEEDBACK_ALERT, FEEDBACK_INFORMATION } from '../../elements/feedback/feedback'
import { STATUS_UNKNOWN, STATUS_OK, STATUS_ERROR } from '../../machine/device-manager'
import './test.scss'

export default class TestThermalPrinter extends Component {
  static propTypes = {
    isLoadingTestReceiptMachinePrint: PropTypes.bool.isRequired,
    sendMachineTestReceiptPrint: PropTypes.func.isRequired,
    testStatus: PropTypes.oneOf([STATUS_UNKNOWN, STATUS_OK, STATUS_ERROR])
  }

  render () {
    let status, text
    if (this.props.isLoadingTestReceiptMachinePrint) {
      status = FEEDBACK_INFORMATION
      text = _t.message('maintenance.test-thermal-printer.loading')
    } else if (STATUS_OK === this.props.testStatus) {
      status = FEEDBACK_SUCCESS
      text = _t.message('maintenance.test-thermal-printer.feedback.success')
    } else if (STATUS_ERROR === this.props.testStatus) {
      status = FEEDBACK_ALERT
      text = _t.message('maintenance.test-thermal-printer.feedback.error')
    }

    return (
      <ComponentContainer name='maintenance-test'>
        <Panel
          header={
            <Panel.Header
              name='maintenance-test-thermal-printer'
              title={_t.message('maintenance.test-thermal-printer.title')}
              description={_t.message('maintenance.test-thermal-printer.description')}
            />
          }
        >
          {status && text ? <Feedback status={status} text={text} /> : null}
          <Button
            name='test-display'
            className='primary'
            loading={this.props.isLoadingTestReceiptMachinePrint}
            onClick={this.props.sendMachineTestReceiptPrint}
          >
            <TextLabel text={_t.message('maintenance.test-thermal-printer.retry')} />
          </Button>
        </Panel>
      </ComponentContainer>
    )
  }
}
