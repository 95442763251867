import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import DescriptionList from '../../../elements/description-list/description-list'
import TextLabel from '../../../elements/text-label/text-label'
import FeedbackMachine from '../../../containers/base/last-machine-error-feedback'
import './read-ticket.scss'

const BD = 'aftersales.read-ticket.barcode-data'

export default class BarcodeData extends Component {
  static propTypes = {
    barcodeData: PropTypes.object,
    isLoadingReadTicket: PropTypes.bool
  }

  renderFeedbackMachine () {
    return !this.props.isLoadingReadTicket
      ? <FeedbackMachine namespaces={['barcodeScanner']} />
      : null
  }

  render () {
    const {barcodeData} = this.props

    if (!barcodeData) {
      return this.renderFeedbackMachine()
    }

    const validityFullWeekdays = barcodeData.validityFullWeekdays.map(day => {
      return _t.message(`read-ticket.days-of-week.${day}`)
    })

    const additionalProducts = Object.keys(barcodeData.additionalProducts).map(key => {
      return _t.hasMessage(`${BD}.additional-products.type.${key}`)
        ? _t.message(`${BD}.additional-products.type.${key}`) + ': ' + barcodeData.additionalProducts[key]
        : _t.message(`${BD}.additional-products.type.default`) + ': ' + barcodeData.additionalProducts[key]
    }).join(', ')

    return (
      <div className='description-list-group'>
        <div className='description-list-group__column'>
          <div className='description-list-block'>
            <div className='description-list-block__content'>
              {this.renderFeedbackMachine()}
              <DescriptionList>
                <dt><TextLabel text={_t.message(`${BD}.booking-number`)} /></dt>
                <dd><TextLabel text={`${barcodeData.bookingNumber}`} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.ticket-number`)} /></dt>
                <dd><TextLabel text={`${barcodeData.ticketNumber}`} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.create-date`)} /></dt>
                <dd><TextLabel text={_t.formatMomentDateTime(barcodeData.createDate, 'full')} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.update-date`)} /></dt>
                <dd><TextLabel
                  text={barcodeData.updateDate ? _t.formatMomentDateTime(barcodeData.updateDate, 'full') : ''} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.cancel-date`)} /></dt>
                <dd><TextLabel
                  text={barcodeData.cancelDate ? _t.formatMomentDateTime(barcodeData.cancelDate, 'full') : ''} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.departure-station`)} /></dt>
                <dd><TextLabel text={barcodeData.departureStation ? `${barcodeData.departureStation.name}` : ''} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.arrival-station`)} /></dt>
                <dd><TextLabel text={barcodeData.arrivalStation ? `${barcodeData.arrivalStation.name}` : ''} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.validity-start-date`)} /></dt>
                <dd><TextLabel text={`${barcodeData.validityStartDate}`} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.validity-end-date`)} /></dt>
                <dd><TextLabel text={`${barcodeData.validityEndDate}`} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.validity-weekdays`)} /></dt>
                <dd><TextLabel text={validityFullWeekdays.join(', ')} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.validity-count`)} /></dt>
                <dd><TextLabel text={`${barcodeData.validityCount}`} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.validity-bi-directional`)} /></dt>
                <dd><TextLabel text={barcodeData.validityBiDirectional
                  ? _t.message('aftersales.read-ticket.barcode-data.exists')
                  : _t.message('aftersales.read-ticket.barcode-data.not-exists')} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.validity-service`)} /></dt>
                <dd><TextLabel text={barcodeData.validityService
                  ? _t.message('aftersales.read-ticket.barcode-data.exists')
                  : _t.message('aftersales.read-ticket.barcode-data.not-exists')} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.coach`)} /></dt>
                <dd><TextLabel text={barcodeData.carriage ? `${barcodeData.carriage}` : ''} /></dd>
              </DescriptionList>
            </div>
          </div>
        </div>
        <div className='description-list-group__column'>
          <div className='description-list-block'>
            <div className='description-list-block__content'>
              <DescriptionList>
                <dt><TextLabel text={_t.message(`${BD}.seat-number`)} /></dt>
                <dd><TextLabel text={barcodeData.seatNumber ? `${barcodeData.seatNumber}` : ''} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.product-code`)} /></dt>
                <dd><TextLabel text={`${barcodeData.productCode}`} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.tariff-code`)} /></dt>
                <dd><TextLabel text={`${barcodeData.tariffCode}`} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.inventory-class`)} /></dt>
                <dd><TextLabel text={barcodeData.inventoryClass ? `${barcodeData.inventoryClass}` : ''} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.comfort-zone`)} /></dt>
                <dd><TextLabel text={`${barcodeData.comfortZone}`} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.price`)} /></dt>
                <dd><TextLabel text={_t.formatCurrency(barcodeData.price)} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.currency`)} /></dt>
                <dd><TextLabel text={`${barcodeData.currency}`} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.payment-method`)} /></dt>
                <dd><TextLabel text={`${barcodeData.paymentMethod}`} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.sold-by`)} /></dt>
                <dd><TextLabel text={`${barcodeData.soldBy}`} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.sales-channel-code`)} /></dt>
                <dd><TextLabel text={`${barcodeData.salesChannelCode}`} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.service-conflict`)} /></dt>
                <dd><TextLabel text={barcodeData.serviceConflict ? `${barcodeData.serviceConflict}` : ''} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.material-conflict`)} /></dt>
                <dd><TextLabel text={barcodeData.materialConflict ? `${barcodeData.materialConflict}` : ''} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.connection.arrival-station`)} /></dt>
                <dd><TextLabel text={barcodeData.connection ? barcodeData.connection.arrivalStation.name : ''} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.connection.departure-station`)} /></dt>
                <dd><TextLabel text={barcodeData.connection ? barcodeData.connection.departureStation.name : ''} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.connection.departure-time`)} /></dt>
                <dd>
                  <TextLabel
                    text={barcodeData.connection ? _t.formatMomentDateTime(barcodeData.connection.departureTimestamp, 'full') : ''} />
                </dd>
                <dt><TextLabel text={_t.message(`${BD}.connection.validity-service`)} /></dt>
                <dd><TextLabel text={barcodeData.connection ? barcodeData.connection.validityService : ''} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.connection.ticket-number`)} /></dt>
                <dd><TextLabel text={barcodeData.connection ? barcodeData.connection.ticketNumber : ''} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.passenger-first-name`)} /></dt>
                <dd><TextLabel text={`${barcodeData.passenger.firstName}`} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.passenger-last-name`)} /></dt>
                <dd><TextLabel text={`${barcodeData.passenger.lastName}`} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.passenger-email`)} /></dt>
                <dd><TextLabel text={barcodeData.passenger.email ? `${barcodeData.passenger.email}` : ''} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.passenger-phone-number`)} /></dt>
                <dd><TextLabel text={barcodeData.passenger.phoneNumber ? `${barcodeData.passenger.phoneNumber}` : ''} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.passenger-type`)} /></dt>
                <dd><TextLabel text={`${barcodeData.passenger.type}`} /></dd>
                <dt><TextLabel text={_t.message(`${BD}.additional-products.name`)} /></dt>
                <dd><TextLabel text={`${additionalProducts}`} /></dd>
              </DescriptionList>
            </div>
          </div>
          <div className='description-list-block'>
            <div className='description-list-block__content'>
              <div className='description-list-block__header'>
                <TextLabel name='sub-heading' text={_t.message(`${BD}.header.validations`)} />
              </div>
              {(barcodeData.validations || []).map((validation, index) =>
                <div key={index} className='description-list-block'>
                  <div className='description-list-block__content'>
                    <DescriptionList>
                      <dt><TextLabel text={`${_t.message(`${BD}.validations.date-time`)}`} /></dt>
                      <dd><TextLabel text={`${_t.formatMomentDateTime(validation.dateTime, 'full')}`} /></dd>
                      <dt><TextLabel text={`${_t.message(`${BD}.validations.light-validation`)}`} /></dt>
                      <dd><TextLabel text={validation.lightValidation
                        ? _t.message('aftersales.read-ticket.barcode-data.exists')
                        : _t.message('aftersales.read-ticket.barcode-data.not-exists')} /></dd>
                      <dt><TextLabel text={`${_t.message(`${BD}.validations.location`)}`} /></dt>
                      <dd><TextLabel text={`${validation.location}`} /></dd>
                      <dt><TextLabel text={`${_t.message(`${BD}.validations.steward`)}`} /></dt>
                      <dd><TextLabel text={`${validation.steward}`} /></dd>
                      <dt><TextLabel text={`${_t.message(`${BD}.validations.ticket-number`)}`} /></dt>
                      <dd><TextLabel text={`${validation.ticketNumber}`} /></dd>
                      <dt><TextLabel text={`${_t.message(`${BD}.validations.uid`)}`} /></dt>
                      <dd><TextLabel text={`${validation.uid}`} /></dd>
                      <dt><TextLabel text={`${_t.message(`${BD}.validations.validation-type`)}`} /></dt>
                      <dd><TextLabel text={`${validation.validationType}`} /></dd>
                    </DescriptionList>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
