import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import without from 'lodash/without'
import intersection from 'lodash/intersection'
import CancelByDirection from '../../../../components/aftersales/cancellation/cancel-by/cancel-by-direction'

export default class CancelByDirectionContainer extends Component {
  static propTypes = {
    bookingCancellationData: PropTypes.shape({
      noItemsSelected: PropTypes.bool.isRequired,
      allItemsSelected: PropTypes.bool.isRequired,
      selectedItemIds: PropTypes.arrayOf(PropTypes.string).isRequired
    }).isRequired,
    outboundCancellationData: PropTypes.shape({
      someItemsSelected: PropTypes.bool.isRequired,
      itemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
      selectedItemIds: PropTypes.arrayOf(PropTypes.string).isRequired
    }),
    inboundCancellationData: PropTypes.shape({
      someItemsSelected: PropTypes.bool.isRequired,
      itemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
      selectedItemIds: PropTypes.arrayOf(PropTypes.string).isRequired
    }),
    passengersCancellationData: PropTypes.arrayOf(PropTypes.shape({
      allItemsSelected: PropTypes.bool.isRequired,
      someItemsSelected: PropTypes.bool.isRequired,
      itemIds: PropTypes.arrayOf(PropTypes.string).isRequired
    })).isRequired,
    onSelectItemIds: PropTypes.func.isRequired,
    onDeselectItemIds: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  }

  constructor (...args) {
    super(...args)
    this.onToggleOutbound = this.onToggleOutbound.bind(this)
    this.onToggleInbound = this.onToggleInbound.bind(this)
    this._getWarningMessage = this._getWarningMessage.bind(this)
  }

  onToggleOutbound () {
    this._onToggle(this.props.outboundCancellationData)
  }

  onToggleInbound () {
    this._onToggle(this.props.inboundCancellationData)
  }

  _onToggle (cancellationData) {
    if (cancellationData.someItemsSelected) {
      this._deselectItems(cancellationData)
    } else {
      this._selectItems(cancellationData)
    }
  }

  _selectItems (cancellationData) {
    const {
      passengersCancellationData,
      bookingCancellationData,
      onSelectItemIds
    } = this.props

    if (bookingCancellationData.noItemsSelected) {
      onSelectItemIds(cancellationData.itemIds)
    } else {
      onSelectItemIds(passengersCancellationData.reduce(
        (selectItemIds, passengerCancellationData) => {
          if (passengerCancellationData.someItemsSelected) {
            const intersect = intersection(passengerCancellationData.itemIds, cancellationData.itemIds)
            selectItemIds.push(...intersect)
          }
          return selectItemIds
        },
        []
      ))
    }
  }

  _deselectItems (cancellationData) {
    const {
      passengersCancellationData,
      bookingCancellationData,
      onDeselectItemIds
    } = this.props

    if (!bookingCancellationData.allItemsSelected &&
      passengersCancellationData.length > 1 &&
      passengersCancellationData.every(
        ({allItemsSelected, noItemsSelected}) => allItemsSelected || noItemsSelected
      )
    ) {
      onDeselectItemIds(without(bookingCancellationData.selectedItemIds, ...cancellationData.itemIds))
    } else {
      onDeselectItemIds(cancellationData.selectedItemIds)
    }
  }

  _getWarningMessage () {
    const {
      bookingCancellationData,
      outboundCancellationData,
      inboundCancellationData,
      passengersCancellationData
    } = this.props

    let message
    if (!bookingCancellationData.allItemsSelected && passengersCancellationData.length > 1) {
      if (outboundCancellationData && outboundCancellationData.allItemsSelected && inboundCancellationData.noItemsSelected) {
        message = _t.message('aftersales.cancellation.cancel-by.outbound-warning')
      } else if (inboundCancellationData && inboundCancellationData.allItemsSelected && outboundCancellationData.noItemsSelected) {
        message = _t.message('aftersales.cancellation.cancel-by.inbound-warning')
      }
    }

    return message
  }

  render () {
    return <CancelByDirection
      bookingCancellationData={this.props.bookingCancellationData}
      outboundCancellationData={this.props.outboundCancellationData}
      inboundCancellationData={this.props.inboundCancellationData}
      onToggleOutbound={this.onToggleOutbound}
      onToggleInbound={this.onToggleInbound}
      disabled={this.props.disabled}
      message={this._getWarningMessage()}
    />
  }
}
