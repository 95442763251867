import React, { Component } from 'react'
import PropTypes from '../../../misc/prop-types'
import Form from '../../../elements/form/form'
import TextLabel from '../../../elements/text-label/text-label'

class ShiftModalForm extends Component {
  static propTypes = {
    shiftEndedRemotely: PropTypes.bool.isRequired,
    username: PropTypes.string.isRequired,
    shift: PropTypes.shape({
      startDateTime: PropTypes.moment
    }),
    form: PropTypes.shape({
      fields: PropTypes.shape({
        note: PropTypes.object.isRequired
      })
    })
  }

  render () {
    return (
      <Form formContext='shift-modal'>
        <fieldset>
          <Form.InputGroup name='account'>
            <Form.InputWrapper name='label'>
              <Form.Label
                htmlFor='account-name'
                name='account-name' />
            </Form.InputWrapper>
            <Form.InputWrapper name='value'>
              <TextLabel
                id='account-name'
                text={this.props.username}
                name='account-name' />
            </Form.InputWrapper>
          </Form.InputGroup>
          {this.props.shift
            ? (
              <Form.InputGroup name='shift-start'>
                <Form.InputWrapper name='label'>
                  <Form.Label
                    htmlFor='shift-start'
                    name='shift-start' />
                </Form.InputWrapper>
                <Form.InputWrapper name='value'>
                  <TextLabel
                    id='shift-start'
                    text={this.props.shift.startDateTime.format('l LT')}
                    name='shift-start-value' />
                </Form.InputWrapper>
              </Form.InputGroup>
            ) : null}
          {!this.props.shiftEndedRemotely && this.props.shift
            ? <Form.InputGroup name='shift-notes'>
              <Form.InputWrapper name='label'>
                <Form.Label
                  htmlFor='shift-notes'
                  name='shift-notes' />
              </Form.InputWrapper>
              <Form.InputWrapper name='value'>
                <Form.TextArea
                  {...this.props.form.fields.note}
                  name='shift-notes'
                  id='shift-notes'
                  autoComplete='off'
                  autoFocus
                  tabIndex='1'
                  hotkey='focusNote' />
              </Form.InputWrapper>
            </Form.InputGroup> : null}
        </fieldset>
      </Form>
    )
  }
}

export default ShiftModalForm
