import {
  MACHINE_LEAP_READ_REQUEST,
  MACHINE_LEAP_READ_SUCCESS,
  MACHINE_LEAP_READ_FAILURE,
  MACHINE_LEAP_TOP_UP_REQUEST,
  MACHINE_LEAP_TOP_UP_SUCCESS,
  MACHINE_LEAP_TOP_UP_FAILURE,
  MACHINE_LEAP_PROCESS_ACTION_LIST_REQUEST,
  MACHINE_LEAP_PROCESS_ACTION_LIST_SUCCESS,
  MACHINE_LEAP_PROCESS_ACTION_LIST_FAILURE,
  MACHINE_LEAP_ADD_PRODUCT_REQUEST,
  MACHINE_LEAP_ADD_PRODUCT_SUCCESS,
  MACHINE_LEAP_ADD_PRODUCT_FAILURE,
  MACHINE_LEAP_APPLY_CD_REQUEST,
  MACHINE_LEAP_APPLY_CD_SUCCESS,
  MACHINE_LEAP_APPLY_CD_FAILURE,
  MACHINE_LEAP_REVERSE_REQUEST,
  MACHINE_LEAP_REVERSE_FAILURE,
  MACHINE_LEAP_REVERSE_SUCCESS,
  UI_READ_LEAP_CARD_START
} from '../../actions/types'
import { CLEAR_STATE } from 's3p-js-lib/src/redux/actions/types'
import {
  MACHINE_LEAP,
  MACHINE_LEAP_READ
} from '../../../constants'

const initialState = {
  read: null,
  topUp: null,
  processActionList: null,
  addProduct: null,
  applyCd: null,
  reverse: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case MACHINE_LEAP_READ_REQUEST:
    case MACHINE_LEAP_READ_FAILURE:
      return {...state, read: null}
    case MACHINE_LEAP_READ_SUCCESS:
      return {...state, read: action.response.results}
    case MACHINE_LEAP_TOP_UP_REQUEST:
    case MACHINE_LEAP_TOP_UP_FAILURE:
      return {...state, topUp: null}
    case MACHINE_LEAP_TOP_UP_SUCCESS:
      return {...state, topUp: action.response.results}
    case MACHINE_LEAP_PROCESS_ACTION_LIST_REQUEST:
    case MACHINE_LEAP_PROCESS_ACTION_LIST_FAILURE:
      return {...state, processActionList: null}
    case MACHINE_LEAP_PROCESS_ACTION_LIST_SUCCESS:
      return {
        ...state,
        processActionList: action.response.results,
        read: action.response.results
      }
    case MACHINE_LEAP_ADD_PRODUCT_REQUEST:
      return {...state, addProduct: null}
    case MACHINE_LEAP_ADD_PRODUCT_FAILURE:
      return {
        ...state,
        addProduct: (action.machineError && action.machineError.response && action.machineError.response.results) || null
      }
    case MACHINE_LEAP_ADD_PRODUCT_SUCCESS:
      return {...state, addProduct: action.response.results}
    case MACHINE_LEAP_APPLY_CD_REQUEST:
    case MACHINE_LEAP_APPLY_CD_FAILURE:
      return {...state, applyCd: null}
    case MACHINE_LEAP_APPLY_CD_SUCCESS:
      return {...state, applyCd: action.response}
    case MACHINE_LEAP_REVERSE_REQUEST:
    case MACHINE_LEAP_REVERSE_FAILURE:
    case UI_READ_LEAP_CARD_START:
      return {...state, reverse: null}
    case MACHINE_LEAP_REVERSE_SUCCESS:
      return {...state, reverse: action.response.results}
    case CLEAR_STATE:
      return action.namespaces.includes(MACHINE_LEAP_READ)
        ? {...state, read: null}
        : action.namespaces.includes(MACHINE_LEAP) ? initialState : state
    default:
      return state
  }
}
