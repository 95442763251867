import {createSelector} from 'reselect'

export const modalIsActiveSelector = createSelector([
  state => state.containers.tickets.shortcuts.editModeEnabled,
  state => state.containers.base.elementVisibility
], (editShortcutModal, elements) =>
  editShortcutModal || Object.keys(elements).some(
    name => typeof elements[name] === 'object' ? elements[name].visible && elements[name].isModal : false
  )
)

/**
 * Retrieves any extra properties added to the modal action
 * @param name: string
 * @returns {function(*): undefined|*}
 */
export const modalExtraPropsSelector = name => state => state.containers.base.elementVisibility[name]?.extraProps

export const elementVisibleSelector = name => state => {
  const nameState = state.containers.base.elementVisibility[name]
  return Boolean(typeof nameState === 'object' ? nameState.visible : nameState)
}
