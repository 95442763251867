import {compose} from 'redux'
import {connect} from 'react-redux'
import {additionalProductsInitiate} from '../../../../redux/actions/api/aftersales/additional-products/initiate'
import {overviewBookingSelector} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import containVisibleElement from '../../../../containers/base/visible-element'
import {routerActions} from '../../../../redux/actions/containers/base/routing'
import {
  isLoadingToolbarActionsSelector,
  isOverrideAftersalesRulesSelector
} from '../../../../redux/selectors/containers/aftersales/booking/toolbar'
import {additionalProductsAllowedSelector} from '../../../../redux/selectors/api/booking/after-sales'
import ToolbarItem from '../../../../components/aftersales/booking/toolbar/toolbar-item'
import {isAdditionalProductsAvailableSelector} from '../../../../redux/selectors/containers/base/aftersales'

const _isAdditionalProductsAvailableSelector = isAdditionalProductsAvailableSelector(overviewBookingSelector)
const _isOverrideAftersalesRulesSelector = isOverrideAftersalesRulesSelector(additionalProductsAllowedSelector)

const mapStateToProps = state => ({
  loading: state.api.loading.additionalProductsInitiate,
  disabled: isLoadingToolbarActionsSelector(state),
  available: _isAdditionalProductsAvailableSelector(state),
  overrideAftersalesRules: _isOverrideAftersalesRulesSelector(state)
})

const mapDispatchToProps = (_, ownProps) => dispatch => ({
  onClick: async () => {
    if (await dispatch(additionalProductsInitiate(ownProps.bookingNumber))) {
      dispatch(routerActions.push('/aftersales/additional-products'))
    }
  }
})

const container = compose(
  connect(mapStateToProps, mapDispatchToProps),
  containVisibleElement()
)

export default container(ToolbarItem)
