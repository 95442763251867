// API

export const API_GET_MAGSTRIPE_TICKETS_REQUEST = 'API_GET_MAGSTRIPE_TICKETS_REQUEST'
export const API_GET_MAGSTRIPE_TICKETS_SUCCESS = 'API_GET_MAGSTRIPE_TICKETS_SUCCESS'
export const API_GET_MAGSTRIPE_TICKETS_FAILURE = 'API_GET_MAGSTRIPE_TICKETS_FAILURE'

export const API_CONFIRM_MAGSTRIPE_REQUEST = 'API_CONFIRM_MAGSTRIPE_REQUEST'
export const API_CONFIRM_MAGSTRIPE_SUCCESS = 'API_CONFIRM_MAGSTRIPE_SUCCESS'
export const API_CONFIRM_MAGSTRIPE_FAILURE = 'API_CONFIRM_MAGSTRIPE_FAILURE'

export const API_GET_BARCODE_DOCUMENT_REQUEST = 'API_GET_BARCODE_DOCUMENT_REQUEST'
export const API_GET_BARCODE_DOCUMENT_SUCCESS = 'API_GET_BARCODE_DOCUMENT_SUCCESS'
export const API_GET_BARCODE_DOCUMENT_FAILURE = 'API_GET_BARCODE_DOCUMENT_FAILURE'

export const API_GET_GIFT_VOUCHER_DOCUMENT_REQUEST = 'API_GET_GIFT_VOUCHER_DOCUMENT_REQUEST'
export const API_GET_GIFT_VOUCHER_DOCUMENT_SUCCESS = 'API_GET_GIFT_VOUCHER_DOCUMENT_SUCCESS'
export const API_GET_GIFT_VOUCHER_DOCUMENT_FAILURE = 'API_GET_GIFT_VOUCHER_DOCUMENT_FAILURE'

export const API_GET_REFUND_VOUCHER_DOCUMENT_REQUEST = 'API_GET_GIFT_VOUCHER_DOCUMENT_REQUEST'
export const API_GET_REFUND_VOUCHER_DOCUMENT_SUCCESS = 'API_GET_GIFT_VOUCHER_DOCUMENT_SUCCESS'
export const API_GET_REFUND_VOUCHER_DOCUMENT_FAILURE = 'API_GET_GIFT_VOUCHER_DOCUMENT_FAILURE'

export const API_GET_TICKET_DETAILS_REQUEST = 'API_GET_TICKET_DETAILS_REQUEST'
export const API_GET_TICKET_DETAILS_SUCCESS = 'API_GET_TICKET_DETAILS_SUCCESS'
export const API_GET_TICKET_DETAILS_FAILURE = 'API_GET_TICKET_DETAILS_FAILURE'

export const API_GET_LEAP_CONFIGURATION_DATA_REQUEST = 'API_GET_LEAP_CONFIGURATION_DATA_REQUEST'
export const API_GET_LEAP_CONFIGURATION_DATA_SUCCESS = 'API_GET_LEAP_CONFIGURATION_DATA_SUCCESS'
export const API_GET_LEAP_CONFIGURATION_DATA_FAILURE = 'API_GET_LEAP_CONFIGURATION_DATA_FAILURE'

export const API_GET_LEAP_CONFIGURATION_DATA_SET_REQUEST = 'API_GET_LEAP_CONFIGURATION_DATA_SET_REQUEST'
export const API_GET_LEAP_CONFIGURATION_DATA_SET_SUCCESS = 'API_GET_LEAP_CONFIGURATION_DATA_SET_SUCCESS'
export const API_GET_LEAP_CONFIGURATION_DATA_SET_FAILURE = 'API_GET_LEAP_CONFIGURATION_DATA_SET_FAILURE'

export const API_CONFIRM_LEAP_CONFIGURATION_DATA_SET_REQUEST = 'API_CONFIRM_LEAP_CONFIGURATION_DATA_SET_REQUEST'
export const API_CONFIRM_LEAP_CONFIGURATION_DATA_SET_SUCCESS = 'API_CONFIRM_LEAP_CONFIGURATION_DATA_SET_SUCCESS'
export const API_CONFIRM_LEAP_CONFIGURATION_DATA_SET_FAILURE = 'API_CONFIRM_LEAP_CONFIGURATION_DATA_SET_FAILURE'

export const API_GET_LEAP_SCHEME_WIDE_CODE_REQUEST = 'API_GET_LEAP_SCHEME_WIDE_CODE_REQUEST'
export const API_GET_LEAP_SCHEME_WIDE_CODE_SUCCESS = 'API_GET_LEAP_SCHEME_WIDE_CODE_SUCCESS'
export const API_GET_LEAP_SCHEME_WIDE_CODE_FAILURE = 'API_GET_LEAP_SCHEME_WIDE_CODE_FAILURE'

export const API_GET_LEAP_ACTIONS_REQUEST = 'API_GET_LEAP_ACTIONS_REQUEST'
export const API_GET_LEAP_ACTIONS_SUCCESS = 'API_GET_LEAP_ACTIONS_SUCCESS'
export const API_GET_LEAP_ACTIONS_FAILURE = 'API_GET_LEAP_ACTIONS_FAILURE'

export const API_CONFIRM_LEAP_ACTION_REQUEST = 'API_CONFIRM_LEAP_ACTION_REQUEST'
export const API_CONFIRM_LEAP_ACTION_SUCCESS = 'API_CONFIRM_LEAP_ACTION_SUCCESS'
export const API_CONFIRM_LEAP_ACTION_FAILURE = 'API_CONFIRM_LEAP_ACTION_FAILURE'

export const API_V2_UPDATE_PASSENGERS_REQUEST = 'API_V2_UPDATE_PASSENGERS_REQUEST'
export const API_V2_UPDATE_PASSENGERS_SUCCESS = 'API_V2_UPDATE_PASSENGERS_SUCCESS'
export const API_V2_UPDATE_PASSENGERS_FAILURE = 'API_V2_UPDATE_PASSENGERS_FAILURE'

// Machine

export const MACHINE_GET_STATION_INFO_REQUEST = 'MACHINE_GET_STATION_INFO_REQUEST'
export const MACHINE_GET_STATION_INFO_SUCCESS = 'MACHINE_GET_STATION_INFO_SUCCESS'
export const MACHINE_GET_STATION_INFO_FAILURE = 'MACHINE_GET_STATION_INFO_FAILURE'

export const MACHINE_GET_TERMINAL_INFO_REQUEST = 'MACHINE_GET_TERMINAL_INFO_REQUEST'
export const MACHINE_GET_TERMINAL_INFO_SUCCESS = 'MACHINE_GET_TERMINAL_INFO_SUCCESS'
export const MACHINE_GET_TERMINAL_INFO_FAILURE = 'MACHINE_GET_TERMINAL_INFO_FAILURE'

export const MACHINE_DISPLAY_LINES_REQUEST = 'MACHINE_DISPLAY_LINES_REQUEST'
export const MACHINE_DISPLAY_LINES_SUCCESS = 'MACHINE_DISPLAY_LINES_SUCCESS'
export const MACHINE_DISPLAY_LINES_FAILURE = 'MACHINE_DISPLAY_LINES_FAILURE'

export const MACHINE_CLEAR_DISPLAY_REQUEST = 'MACHINE_CLEAR_DISPLAY_REQUEST'
export const MACHINE_CLEAR_DISPLAY_SUCCESS = 'MACHINE_CLEAR_DISPLAY_SUCCESS'
export const MACHINE_CLEAR_DISPLAY_FAILURE = 'MACHINE_CLEAR_DISPLAY_FAILURE'

export const MACHINE_TEST_DISPLAY_REQUEST = 'MACHINE_TEST_DISPLAY_REQUEST'
export const MACHINE_TEST_DISPLAY_SUCCESS = 'MACHINE_TEST_DISPLAY_SUCCESS'
export const MACHINE_TEST_DISPLAY_FAILURE = 'MACHINE_TEST_DISPLAY_FAILURE'

export const MACHINE_OPEN_CASH_DRAWER_REQUEST = 'MACHINE_OPEN_CASH_DRAWER_REQUEST'
export const MACHINE_OPEN_CASH_DRAWER_SUCCESS = 'MACHINE_OPEN_CASH_DRAWER_SUCCESS'
export const MACHINE_OPEN_CASH_DRAWER_FAILURE = 'MACHINE_OPEN_CASH_DRAWER_FAILURE'

export const MACHINE_RECEIPT_PRINT_REQUEST = 'MACHINE_RECEIPT_PRINT_REQUEST'
export const MACHINE_RECEIPT_PRINT_SUCCESS = 'MACHINE_RECEIPT_PRINT_SUCCESS'
export const MACHINE_RECEIPT_PRINT_FAILURE = 'MACHINE_RECEIPT_PRINT_FAILURE'

export const MACHINE_TEST_RECEIPT_PRINT_REQUEST = 'MACHINE_TEST_RECEIPT_PRINTER_REQUEST'
export const MACHINE_TEST_RECEIPT_PRINT_SUCCESS = 'MACHINE_TEST_RECEIPT_PRINTER_SUCCESS'
export const MACHINE_TEST_RECEIPT_PRINT_FAILURE = 'MACHINE_TEST_RECEIPT_PRINTER_FAILURE'

export const MACHINE_PRINT_MAGNETIC_TICKET_REQUEST = 'MACHINE_PRINT_MAGNETIC_TICKET_REQUEST'
export const MACHINE_PRINT_MAGNETIC_TICKET_SUCCESS = 'MACHINE_PRINT_MAGNETIC_TICKET_SUCCESS'
export const MACHINE_PRINT_MAGNETIC_TICKET_FAILURE = 'MACHINE_PRINT_MAGNETIC_TICKET_FAILURE'

export const MACHINE_READ_MAGNETIC_TICKET_REQUEST = 'MACHINE_READ_MAGNETIC_TICKETS_REQUEST'
export const MACHINE_READ_MAGNETIC_TICKET_SUCCESS = 'MACHINE_READ_MAGNETIC_TICKETS_SUCCESS'
export const MACHINE_READ_MAGNETIC_TICKET_FAILURE = 'MACHINE_READ_MAGNETIC_TICKETS_FAILURE'

export const MACHINE_VOID_MAGNETIC_TICKET_REQUEST = 'MACHINE_VOID_MAGNETIC_TICKETS_REQUEST'
export const MACHINE_VOID_MAGNETIC_TICKET_SUCCESS = 'MACHINE_VOID_MAGNETIC_TICKETS_SUCCESS'
export const MACHINE_VOID_MAGNETIC_TICKET_FAILURE = 'MACHINE_VOID_MAGNETIC_TICKETS_FAILURE'

export const MACHINE_EJECT_MAGNETIC_TICKET_REQUEST = 'MACHINE_EJECT_MAGNETIC_TICKETS_REQUEST'
export const MACHINE_EJECT_MAGNETIC_TICKET_SUCCESS = 'MACHINE_EJECT_MAGNETIC_TICKETS_SUCCESS'
export const MACHINE_EJECT_MAGNETIC_TICKET_FAILURE = 'MACHINE_EJECT_MAGNETIC_TICKETS_FAILURE'

export const MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_REQUEST = 'MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_REQUEST'
export const MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_SUCCESS = 'MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_SUCCESS'
export const MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_FAILURE = 'MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_FAILURE'

export const MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_REQUEST = 'MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_REQUEST'
export const MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_SUCCESS = 'MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_SUCCESS'
export const MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_FAILURE = 'MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_FAILURE'

export const MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_REQUEST = 'MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_REQUEST'
export const MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_SUCCESS = 'MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_SUCCESS'
export const MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_FAILURE = 'MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_FAILURE'

export const MACHINE_GET_STATUS_DEVICES_REQUEST = 'MACHINE_GET_STATUS_DEVICES_REQUEST'
export const MACHINE_GET_STATUS_DEVICES_SUCCESS = 'MACHINE_GET_STATUS_DEVICES_SUCCESS'
export const MACHINE_GET_STATUS_DEVICES_FAILURE = 'MACHINE_GET_STATUS_DEVICES_FAILURE'

export const MACHINE_LEAP_ADD_PRODUCT_REQUEST = 'MACHINE_LEAP_ADD_PRODUCT_REQUEST'
export const MACHINE_LEAP_ADD_PRODUCT_SUCCESS = 'MACHINE_LEAP_ADD_PRODUCT_SUCCESS'
export const MACHINE_LEAP_ADD_PRODUCT_FAILURE = 'MACHINE_LEAP_ADD_PRODUCT_FAILURE'

export const MACHINE_LEAP_READ_REQUEST = 'MACHINE_LEAP_READ_REQUEST'
export const MACHINE_LEAP_READ_SUCCESS = 'MACHINE_LEAP_READ_SUCCESS'
export const MACHINE_LEAP_READ_FAILURE = 'MACHINE_LEAP_READ_FAILURE'

export const MACHINE_LEAP_TOP_UP_REQUEST = 'MACHINE_LEAP_TOP_UP_REQUEST'
export const MACHINE_LEAP_TOP_UP_SUCCESS = 'MACHINE_LEAP_TOP_UP_SUCCESS'
export const MACHINE_LEAP_TOP_UP_FAILURE = 'MACHINE_LEAP_TOP_UP_FAILURE'

export const MACHINE_LEAP_PROCESS_ACTION_LIST_REQUEST = 'MACHINE_LEAP_PROCESS_ACTION_LIST_REQUEST'
export const MACHINE_LEAP_PROCESS_ACTION_LIST_SUCCESS = 'MACHINE_LEAP_PROCESS_ACTION_LIST_SUCCESS'
export const MACHINE_LEAP_PROCESS_ACTION_LIST_FAILURE = 'MACHINE_LEAP_PROCESS_ACTION_LIST_FAILURE'

export const MACHINE_LEAP_APPLY_CD_REQUEST = 'MACHINE_LEAP_APPLY_CD_REQUEST'
export const MACHINE_LEAP_APPLY_CD_SUCCESS = 'MACHINE_LEAP_APPLY_CD_SUCCESS'
export const MACHINE_LEAP_APPLY_CD_FAILURE = 'MACHINE_LEAP_APPLY_CD_FAILURE'

export const MACHINE_LEAP_SET_CONFIGURATION_REQUEST = 'MACHINE_LEAP_SET_CONFIGURATION_REQUEST'
export const MACHINE_LEAP_SET_CONFIGURATION_SUCCESS = 'MACHINE_LEAP_SET_CONFIGURATION_SUCCESS'
export const MACHINE_LEAP_SET_CONFIGURATION_FAILURE = 'MACHINE_LEAP_SET_CONFIGURATION_FAILURE'

export const MACHINE_LEAP_REVERSE_REQUEST = 'MACHINE_LEAP_REVERSE_REQUEST'
export const MACHINE_LEAP_REVERSE_SUCCESS = 'MACHINE_LEAP_REVERSE_SUCCESS'
export const MACHINE_LEAP_REVERSE_FAILURE = 'MACHINE_LEAP_REVERSE_FAILURE'

export const MACHINE_LEAP_STATUS_REQUEST = 'MACHINE_LEAP_STATUS_REQUEST'
export const MACHINE_LEAP_STATUS_SUCCESS = 'MACHINE_LEAP_STATUS_SUCCESS'
export const MACHINE_LEAP_STATUS_FAILURE = 'MACHINE_LEAP_STATUS_FAILURE'

export const MACHINE_READ_BARCODE_REQUEST = 'MACHINE_READ_BARCODE_REQUEST'
export const MACHINE_READ_BARCODE_SUCCESS = 'MACHINE_READ_BARCODE_SUCCESS'
export const MACHINE_READ_BARCODE_FAILURE = 'MACHINE_READ_BARCODE_FAILURE'

export const MACHINE_START_PIN_PAYMENT_REQUEST = 'MACHINE_START_PIN_PAYMENT_REQUEST'
export const MACHINE_START_PIN_PAYMENT_SUCCESS = 'MACHINE_START_PIN_PAYMENT_SUCCESS'
export const MACHINE_START_PIN_PAYMENT_FAILURE = 'MACHINE_START_PIN_PAYMENT_FAILURE'

export const MACHINE_COMPLETE_PIN_PAYMENT_REQUEST = 'MACHINE_COMPLETE_PIN_PAYMENT_REQUEST'
export const MACHINE_COMPLETE_PIN_PAYMENT_SUCCESS = 'MACHINE_COMPLETE_PIN_PAYMENT_SUCCESS'
export const MACHINE_COMPLETE_PIN_PAYMENT_FAILURE = 'MACHINE_COMPLETE_PIN_PAYMENT_FAILURE'

export const MACHINE_CANCEL_PIN_PAYMENT_REQUEST = 'MACHINE_CANCEL_PIN_PAYMENT_REQUEST'
export const MACHINE_CANCEL_PIN_PAYMENT_SUCCESS = 'MACHINE_CANCEL_PIN_PAYMENT_SUCCESS'
export const MACHINE_CANCEL_PIN_PAYMENT_FAILURE = 'MACHINE_CANCEL_PIN_PAYMENT_FAILURE'

export const MACHINE_REFUND_PIN_PAYMENT_REQUEST = 'MACHINE_REFUND_PIN_PAYMENT_REQUEST'
export const MACHINE_REFUND_PIN_PAYMENT_SUCCESS = 'MACHINE_REFUND_PIN_PAYMENT_SUCCESS'
export const MACHINE_REFUND_PIN_PAYMENT_FAILURE = 'MACHINE_REFUND_PIN_PAYMENT_FAILURE'

export const MACHINE_SET_DARKNESS_REQUEST = 'MACHINE_SET_DARKNESS_REQUEST'
export const MACHINE_SET_DARKNESS_SUCCESS = 'MACHINE_SET_DARKNESS_SUCCESS'
export const MACHINE_SET_DARKNESS_FAILURE = 'MACHINE_SET_DARKNESS_FAILURE'

export const MACHINE_IS_OFFLINE = 'MACHINE_IS_OFFLINE'

export const MACHINE_FAILURES_ACTIONS = [
  MACHINE_GET_STATION_INFO_FAILURE,
  MACHINE_DISPLAY_LINES_FAILURE,
  MACHINE_CLEAR_DISPLAY_FAILURE,
  MACHINE_OPEN_CASH_DRAWER_FAILURE,
  MACHINE_RECEIPT_PRINT_FAILURE,
  MACHINE_PRINT_MAGNETIC_TICKET_FAILURE
]

// UI

export const UI_LAST_SELECTED_PRODUCT = 'UI_LAST_SELECTED_PRODUCT'
export const UI_RESET_TICKETS = 'UI_RESET_TICKETS'
export const UI_SHORTCUT_SAVED = 'UI_SHORTCUT_SAVED'
export const UI_PRINTING_TICKET_NUMBER = 'UI_PRINTING_TICKET_NUMBER'
export const UI_PRINTING_TICKET_START = 'UI_PRINTING_TICKET_START'
export const UI_PRINTING_TICKET_STOP = 'UI_PRINTING_TICKET_STOP'
export const UI_PRINTING_TICKET_ERROR = 'UI_PRINTING_TICKET_ERROR'
export const UI_SHIFT_ENDED_REMOTELY = 'UI_SHIFT_ENDED_REMOTELY'
export const UI_JOURNEY_RESULT_SET_SORT_KEY = 'UI_JOURNEY_RESULT_SET_SORT_KEY'
export const UI_PRINTING_RECEIPT_ERROR = 'UI_PRINTING_RECEIPT_ERROR'
export const UI_PRINTING_PED_RECEIPT_ERROR = 'UI_PRINTING_PED_RECEIPT_ERROR'
export const UI_PRINTING_PED_RECEIPT_SUCCESS = 'UI_PRINTING_PED_RECEIPT_SUCCESS'
export const UI_PRINTING_PED_FAILED_RECEIPT_ERROR = 'UI_PRINTING_PED_PAYMENT_FAILED_RECEIPT_ERROR'
export const UI_ADD_PED_PAYMENT = 'UI_ADD_PED_PAYMENT'
export const UI_PRINTING_BARCODE_ERROR = 'UI_PRINTING_BARCODE_ERROR'
export const UI_ABORT_AFTERSALES_START = 'UI_ABORT_AFTERSALES_START'
export const UI_SELECT_READ_TICKET = 'UI_SELECT_READ_TICKET'
export const UI_SELECT_VOUCHER = 'UI_SELECT_VOUCHER'
export const UI_PAYOUT_VOUCHER_REQUEST = 'UI_PAYOUT_VOUCHER_REQUEST'
export const UI_PAYOUT_VOUCHER_SUCCESS = 'UI_PAYOUT_VOUCHER_SUCCESS'
export const UI_SHOW_FEEDBACK = 'UI_SHOW_FEEDBACK'
export const UI_VOID_TICKET_START = 'UI_VOID_TICKET_START'
export const UI_VOID_TICKET_NUMBER = 'UI_VOID_TICKET_NUMBER'
export const UI_VOID_TICKET_SUCCESS = 'UI_VOID_TICKET_SUCCESS'
export const UI_VOID_TICKET_STOP = 'UI_VOID_TICKET_STOP'
export const UI_VOID_TICKET_ERROR = 'UI_VOID_TICKET_ERROR'
export const UI_RETRIEVE_AND_PRINT_CYCLE_START = 'UI_RETRIEVE_AND_PRINT_CYCLE_START'
export const UI_RETRIEVE_AND_PRINT_CYCLE_END = 'UI_RETRIEVE_AND_PRINT_CYCLE_END'
export const UI_READ_LEAP_CARD_START = 'UI_READ_LEAP_CARD_START'
export const UI_READ_LEAP_CARD_END = 'UI_READ_LEAP_CARD_END'
export const UI_SELECT_LEAP_PRODUCT = 'UI_SELECT_LEAP_PRODUCT'
export const UI_AGENT_HAS_INVALID_ROLES = 'UI_AGENT_HAS_INVALID_ROLES'
export const UI_AGENT_INCORRECT_CONFIGURATION = 'UI_AGENT_INCORRECT_CONFIGURATION'
export const UI_READ_BARCODE_REQUEST = 'UI_READ_BARCODE_REQUEST'
export const UI_READ_BARCODE_SUCCESS = 'UI_READ_BARCODE_SUCCESS'
export const UI_READ_BARCODE_FAILURE = 'UI_READ_BARCODE_FAILURE'
export const UI_PED_STATUS = 'UI_PED_STATUS'
export const UI_ERROR_PAGE_REDIRECT = 'UI_ERROR_PAGE_REDIRECT'
export const UI_ERROR_BOOKING_CONFIRMATION_PAGE_REDIRECT = 'UI_ERROR_BOOKING_CONFIRMATION_PAGE_REDIRECT'
export const UI_CLEAR_LAST_MACHINE_ERROR = 'UI_CLEAR_LAST_MACHINE_ERROR'
export const UI_START_START_SHIFT = 'UI_START_START_SHIFT'
export const UI_END_START_SHIFT = 'UI_END_START_SHIFT'
export const UI_START_REPRINT_RESEND = 'UI_START_REPRINT_RESEND'
export const UI_END_REPRINT_RESEND = 'UI_END_REPRINT_RESEND'
export const UI_LEAP_TOP_UP_START = 'UI_LEAP_TOP_UP_START'
export const UI_LEAP_TOP_UP_END = 'UI_LEAP_TOP_UP_END'
export const UI_LEAP_ADD_PRODUCT_START = 'UI_LEAP_ADD_PRODUCT_START'
export const UI_LEAP_ADD_PRODUCT_END = 'UI_LEAP_ADD_PRODUCT_END'
export const UI_LEAP_REVERSE_START = 'UI_LEAP_REVERSE_START'
export const UI_LEAP_REVERSE_END = 'UI_LEAP_REVERSE_END'
export const UI_MANUALLY_SAVE_NOTE_SHIFT = 'UI_MANUALLY_SAVE_NOTE_SHIFT'
export const UI_BOOKING_OVERVIEW_INITIATE = 'UI_BOOKING_OVERVIEW_INITIATE'
export const UI_LEAP_DOUBTFUL_TRANSACTION_CARD_IDENTIFIER = 'UI_LEAP_DOUBTFUL_TRANSACTION_CARD_IDENTIFIER'
export const UI_LEAP_DOUBTFUL_TRANSACTION_TOP_UP_VALUE = 'UI_LEAP_DOUBTFUL_TRANSACTION_TOP_UP_VALUE'

// USER_INPUT

export const USER_INPUT_PAYMENT_METHOD_SELECT = 'USER_INPUT_PAYMENT_METHOD_SELECT'
export const USER_INPUT_PAYMENT_METHOD_DESELECT = 'USER_INPUT_PAYMENT_METHOD_DESELECT'
export const USER_INPUT_PAYMENT_CASH_AMOUNT = 'USER_INPUT_PAYMENT_CASH_AMOUNT'
export const USER_INPUT_USE_SHORTCUT = 'USER_INPUT_USE_SHORTCUT'
export const USER_INPUT_ENABLE_SHORTCUT_EDIT_MODE = 'USER_INPUT_ENABLE_SHORTCUT_EDIT_MODE'
export const USER_INPUT_DISABLE_SHORTCUT_EDIT_MODE = 'USER_INPUT_DISABLE_SHORTCUT_EDIT_MODE'
export const USER_INPUT_EDIT_SHORTCUT = 'USER_INPUT_EDIT_SHORTCUT'
export const USER_INPUT_REPEAT_LAST_BOOKING_REQUEST = 'USER_INPUT_REPEAT_LAST_BOOKING_REQUEST'
export const USER_INPUT_REPEAT_LAST_BOOKING_SUCCESS = 'USER_INPUT_REPEAT_LAST_BOOKING_SUCCESS'
export const USER_INPUT_REPEAT_LAST_BOOKING_FAILURE = 'USER_INPUT_REPEAT_LAST_BOOKING_FAILURE'
export const USER_INPUT_PENDING_ADD_PRODUCT = 'USER_INPUT_PENDING_SELECT_FULFILLMENT_METHOD'
export const USER_INPUT_PENDING_SELECT_FULFILLMENT_METHOD = 'USER_INPUT_PENDING_SELECT_FULFILLMENT_METHOD'
export const USER_INPUT_SELECT_PRODUCT = 'USER_INPUT_SELECT_PRODUCT'
export const USER_INPUT_SEAT_SELECTOR_SELECT_SEGMENT = 'USER_INPUT_SEAT_SELECTOR_SELECT_SEGMENT'
export const USER_INPUT_JOURNEY_SEARCH_SHOW_DETAILS = 'USER_INPUT_JOURNEY_SEARCH_SHOW_DETAILS'
export const USER_INPUT_ENABLE_JOURNEY_SEARCH_BIKE_FILTER = 'USER_INPUT_ENABLE_JOURNEY_SEARCH_BIKE_FILTER'
export const USER_INPUT_DISABLE_JOURNEY_SEARCH_BIKE_FILTER = 'USER_INPUT_DISABLE_JOURNEY_SEARCH_BIKE_FILTER'
export const USER_INPUT_SET_REFUND_STEP = 'USER_INPUT_SET_REFUND_STEP'
export const USER_INPUT_REFUND_ABORT = 'USER_INPUT_REFUND_ABORT'
export const USER_INPUT_REFUND_DONE = 'USER_INPUT_REFUND_DONE'
