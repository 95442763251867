import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Operation from '../../../containers/aftersales/operation/operation'
import Selection from '../../../containers/aftersales/rebooking/journey-search/selection'

export default class RebookingOperation extends Component {
  static propTypes = {
    showSelection: PropTypes.bool
  }

  static defaultProps = {
    showSelection: false
  }

  render () {
    return (
      <Operation
        labelTitle='aftersales.rebooking.header.title'
        labelInformation='aftersales.rebooking.header.information'
        showPassengersSummary
      >
        {this.props.showSelection ? <Selection /> : null}
      </Operation>
    )
  }
}
