import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../../elements/text-label/text-label'
import Modal from '../../../elements/modal/modal'
import _t from 's3p-js-lib/src/translate'

export default class VoucherFooter extends Component {
  static propTypes = {
    handleAddVoucher: PropTypes.func,
    isLoading: PropTypes.bool
  }

  render () {
    return (
      <Modal.FooterSingle
        loading={this.props.isLoading}
        onClick={this.props.handleAddVoucher}
        hotkey='proceed'
      >
        <TextLabel name='validate-voucher' text={_t.message('voucher-payment.form.validate-voucher.label')} />
      </Modal.FooterSingle>
    )
  }
}
