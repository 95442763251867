import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Table from '../../../elements/table/table'
import VouchersHeader from './table/voucher-header'
import VouchersRow from '../../../containers/aftersales/voucher-to-cash/table/voucher-row'

export default class VoucherToCashTable extends Component {
  static propTypes = {
    vouchers: PropTypes.array.isRequired
  }

  render () {
    return (
      <Table name='vouchers' numColumns={5} zebraRows>
        <VouchersHeader />
        <tbody>
          {this.props.vouchers.map(voucher =>
            <VouchersRow
              key={voucher.code}
              voucher={voucher}
            />
          )}
        </tbody>
      </Table>
    )
  }
}
