import {
  API_ADDITIONAL_PRODUCTS_INITIATE_REQUEST,
  API_ADDITIONAL_PRODUCTS_INITIATE_SUCCESS,
  API_ADDITIONAL_PRODUCTS_INITIATE_FAILURE
} from 's3p-js-lib/src/redux/actions/types'
import Client from '../../../../../api/client'
import ensureToken from 's3p-js-lib/src/api/ensure-token'
import {createProductSearchRequest, mapAdditionalProducts} from './initiate-mapper'

const additionalProductsInitiateRequest = bookingNumber => ({type: API_ADDITIONAL_PRODUCTS_INITIATE_REQUEST, bookingNumber})
const additionalProductsInitiateSuccess = response => ({type: API_ADDITIONAL_PRODUCTS_INITIATE_SUCCESS, response})
const additionalProductsInitiateFailure = error => ({type: API_ADDITIONAL_PRODUCTS_INITIATE_FAILURE, error})

export const additionalProductsInitiate = bookingNumber => ensureToken(async (token, dispatch, getState) => {
  try {
    dispatch(additionalProductsInitiateRequest(bookingNumber))
    const bookingResponse = await Client.additionalProductsInitiate(token, bookingNumber)
    const productSeachResponse = await Client.productSearch(
      token,
      createProductSearchRequest(bookingResponse.data.booking)
    )
    const result = {
      original_booking: bookingResponse.data.booking,
      additional_products: mapAdditionalProducts(productSeachResponse.data.products, bookingResponse.data.booking)
    }

    dispatch(additionalProductsInitiateSuccess({data: result, messages: []}))
  } catch (error) {
    dispatch(additionalProductsInitiateFailure(error))
    throw error
  }
})
