import { connect } from 'react-redux'
import LeapProductTabs from '../../../components/leap/products/leap-product-tabs'
import { UI_SELECT_LEAP_PRODUCT } from '../../../redux/actions/types'
import { leapDataSelector } from '../../../redux/selectors/machine/leap'

const selectLeapProduct = product => ({type: UI_SELECT_LEAP_PRODUCT, product})

const mapStateToProps = state => ({
  selectedProduct: state.containers.leap.read.selectedProduct,
  products: leapDataSelector(state) ? leapDataSelector(state).products : []
})

const mapDispatchToProps = {
  selectLeapProduct
}

export default connect(mapStateToProps, mapDispatchToProps)(LeapProductTabs)
