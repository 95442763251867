/* globals S3P_SETTINGS, fetch */
import { STATUS_ERROR } from './device-manager'
import {
  timerRequest,
  logNadManResponse,
  logNadManRequest
} from 's3p-js-lib/src/misc/utils'
import {newRelicFetchRequestWrapper, newRelicTracerWrapper} from '../telemetry/new-relic'

const hasError = json => {
  if (Array.isArray(json)) {
    return json.some(item => hasError(item.Device || item))
  } else {
    return (json.status || json.Status) === STATUS_ERROR
  }
}

const createErrorObject = (endpoint, response, json) => {
  const error = new Error(endpoint)
  error.statusCode = response.status
  error.response = json
  return error
}

const attachMessages = (json, translateResponse) => {
  if (translateResponse) {
    json.messages = translateResponse(json)
  }
  return json
}

let nadmanSentryLoggingEnabled = S3P_SETTINGS.s3Passenger.sentry.enableNadmanLogging
export const isNadmanSentryLoggingEnabled = () => {
  return !!nadmanSentryLoggingEnabled
}
export const enableNadmanSentryLogging = () => {
  nadmanSentryLoggingEnabled = true
}
export const disableNadmanSentryLogging = () => {
  nadmanSentryLoggingEnabled = false
}

const request = async options => {
  const {endpoint, data, method, ignoreErrors, translateResponse, ...rest} = options
  const requestMethod = method ? method.toUpperCase() : 'GET'
  const requestEndpoint = `${S3P_SETTINGS.s3Passenger.NADManager.host}${endpoint}`
  const startDate = new Date()
  const handle = setInterval(() => {
    if (timerRequest(endpoint, startDate) > 600) {
      clearInterval(handle)
    }
  }, 10000)

  // eslint-disable-next-line no-extra-boolean-cast
  if (isNadmanSentryLoggingEnabled()) {
    try {
      logNadManRequest(`Request ${method}: ${endpoint}`, startDate, data)
    } finally {
      // ignore errors
    }
  }

  const fetchOptions = {
    ...rest,
    method: requestMethod,
    headers: {
      Accept: 'application/json'
    }
  }

  if (requestMethod !== 'GET') {
    fetchOptions.body = fetchOptions.body || JSON.stringify(data)
    fetchOptions.headers['Content-Type'] = 'text/plain'
  }

  const response = await newRelicFetchRequestWrapper(
    'machineRequest',
    requestMethod,
    requestEndpoint,
    fetchOptions,
    data,
    fetch,
    error => {
      clearInterval(handle)
      throw error
    }
  )

  clearInterval(handle)
  if (response.status >= 200 && response.status < 300) {
    // eslint-disable-next-line no-extra-boolean-cast
    if (isNadmanSentryLoggingEnabled()) {
      try {
        const data = await response.clone().json()
        logNadManResponse(`Response ${method}: ${endpoint}`, startDate, data)
      } finally {
        // ignore errors
      }
    }
    if (response.status !== 204) {
      const json = attachMessages(await response.json(), translateResponse)
      if (!ignoreErrors && hasError(json)) {
        const error = createErrorObject(endpoint, response, json)
        window?.newrelic?.noticeError(error)
        throw error
      } else {
        return json
      }
    } else {
      return {}
    }
  } else {
    const error = createErrorObject(
      endpoint,
      response,
      attachMessages(await response.json(), translateResponse)
    )
    window?.newrelic?.noticeError(error)
    throw error
  }
}

export default newRelicTracerWrapper('machineRequestTracer', request)
