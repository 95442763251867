import {
  API_GET_LEAP_SCHEME_WIDE_CODE_REQUEST,
  API_GET_LEAP_SCHEME_WIDE_CODE_SUCCESS,
  API_GET_LEAP_SCHEME_WIDE_CODE_FAILURE
} from '../../../../actions/types'
import { API_END_AGENT_SHIFT_SUCCESS, API_OAUTH_LOGOUT, CLEAR_STATE } from 's3p-js-lib/src/redux/actions/types'

const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_GET_LEAP_SCHEME_WIDE_CODE_REQUEST:
    case API_GET_LEAP_SCHEME_WIDE_CODE_FAILURE:
    case API_END_AGENT_SHIFT_SUCCESS:
    case API_OAUTH_LOGOUT:
      return initialState
    case API_GET_LEAP_SCHEME_WIDE_CODE_SUCCESS:
      return action.response.data
    case CLEAR_STATE:
      return action.namespaces.includes('api.modules.leap.schemeWideCode') ? initialState : state
    default:
      return state
  }
}
