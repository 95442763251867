import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'

class InputGroup extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string
  }

  render () {
    const {name, className, children, ...props} = this.props
    return (
      <div className={classNames('input-group', name ? `input-group-${name}` : '', className)} {...props}>
        {children}
      </div>
    )
  }
}

export default InputGroup
