import {API_JOURNEY_SEARCH_SWITCH_DATE} from '../../types'
import {executeJourneySearch, buildJourneySearchFromState} from '../../api/orientation/journey-search'
import {TRAVEL_DIRECTION_OUTBOUND} from '../../../../constants'

// eslint-disable-next-line func-style
function dateSwitcherSwitchDate (direction, date) {
  return {
    type: API_JOURNEY_SEARCH_SWITCH_DATE,
    direction,
    date
  }
}

// eslint-disable-next-line func-style
export function buildSwitchDateJourneySearch (getState, direction, nextDate) {
  const journeySearch = buildJourneySearchFromState(getState())
  if (direction === TRAVEL_DIRECTION_OUTBOUND) {
    journeySearch.outboundDate = nextDate
    journeySearch.outboundCursor = null
  } else {
    journeySearch.inboundDate = nextDate
    journeySearch.inboundCursor = null
  }
  // outbound should always be before or same day of inbound
  if (journeySearch.inboundDate && journeySearch.inboundDate.isBefore(journeySearch.outboundDate, 'day')) {
    journeySearch.inboundDate = nextDate
    journeySearch.inboundCursor = null
    journeySearch.outboundDate = nextDate
    journeySearch.outboundCursor = null
  }
  return journeySearch
}

// eslint-disable-next-line func-style
function switchDateJourneySearch (executeJourneySearch, dispatch, getState, direction, nextDate) {
  const journeySearch = buildSwitchDateJourneySearch(getState, direction, nextDate)
  return dispatch(executeJourneySearch(journeySearch))
}

// eslint-disable-next-line func-style
export function createSwitchDateAction (executeJourneySearch) {
  return (direction, date) => (dispatch, getState) => {
    dispatch(dateSwitcherSwitchDate(direction, date))
    return switchDateJourneySearch(executeJourneySearch, dispatch, getState, direction, date)
  }
}

export const switchDate = createSwitchDateAction(executeJourneySearch)
