import React from 'react'
import { Route } from 'react-router'
import containAuth from '../../containers/base/auth'
import TestCfdLayout from '../../layouts/maintenance/test-cfd'
import { AGENT_ROLE_OPERATOR, AGENT_ROLE_TECHNICIAN } from '../../constants'

export default (
  <Route
    key='maintenance-test-cfd'
    path='/:lang/maintenance/test-cfd'
    component={containAuth(TestCfdLayout, {roles: [AGENT_ROLE_OPERATOR, AGENT_ROLE_TECHNICIAN]})}
  />
)
