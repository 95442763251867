import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { returnPayments } from '../../../redux/actions/containers/base/payment-modal/return-payment-modal'
import { openMachineCashDrawer } from '../../../redux/actions/machine/cash-drawer'
import {
  returnPaymentModalVisibleSelector
} from '../../../redux/selectors/containers/base/payment-modal/return-payment-modal'
import ReturnPaymentModal from '../../base/payment-modal/return-payment-modal'

class ReturnPaymentModalContainer extends Component {
  static propTypes = {
    showReturnPaymentModal: PropTypes.bool
  }

  render () {
    return this.props.showReturnPaymentModal ? <ReturnPaymentModal {...this.props} /> : null
  }
}

const mapDispatchToProps = {
  handleClick: () => async dispatch => {
    await dispatch(returnPayments())
    dispatch(openMachineCashDrawer())
  }
}

const mapStateToProps = state => ({
  showReturnPaymentModal: returnPaymentModalVisibleSelector(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(ReturnPaymentModalContainer)
