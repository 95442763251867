import React, { Component } from 'react'
import ComponentContainer from '../../../elements/component-container/component-container'
import Sprite from '../../../elements/sprite/sprite'
import './header.scss'

export default class Header extends Component {
  render () {
    return (
      <ComponentContainer name='main-header'>
        <div className='logo'>
          <Sprite name='logo' tabIndex='-1' />
        </div>
      </ComponentContainer>
    )
  }
}
