import { UI_AGENT_HAS_INVALID_ROLES, UI_AGENT_INCORRECT_CONFIGURATION } from '../../../actions/types'
import { API_AGENT_LOGIN_REQUEST, CLEAR_STATE } from 's3p-js-lib/src/redux/actions/types'
import { CONTAINERS_AGENT_LOGIN } from '../../../../constants'

const initialState = {
  invalidRoles: false,
  incorrectConfiguration: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case API_AGENT_LOGIN_REQUEST:
      return initialState
    case UI_AGENT_HAS_INVALID_ROLES:
      return {invalidRoles: true, incorrectConfiguration: false}
    case UI_AGENT_INCORRECT_CONFIGURATION:
      return {invalidRoles: false, incorrectConfiguration: true}
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_AGENT_LOGIN) ? initialState : state
    default:
      return state
  }
}
