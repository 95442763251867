import {
  USER_INPUT_PAYMENT_METHOD_SELECT,
  USER_INPUT_PAYMENT_METHOD_DESELECT,
  UI_PRINTING_RECEIPT_ERROR
} from '../../../types'
import {
  SIDEBAR_CART,
  FINALIZE_BOOKING_MODAL,
  CURRENCY_BRITISH_POUND,
  PED_STATUS_FAILURE,
  PAYMENT_METHOD_CODE_CREDIT_DEBIT_CARD,
  REFUND_MODAL
} from '../../../../../constants'
import {
  provisionalOrCompletedBookingSelector,
  pendingTotalPriceToBePaidSelector,
  completedOrOverviewBookingSelector
} from '../../../../selectors/api/booking/booking'
import {
  bookingNumberSelector,
  completedBookingSelector,
  bookingSelector,
  receiptDocumentSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { getPaymentMethods as baseGetPaymentMethods } from 's3p-js-lib/src/redux/actions/api/payment/payment-methods'
import {
  showModal,
  showElement,
  hideElement
} from '../visible-element'
import { sendMachineReceiptPrint } from '../../../machine/receipt-printer'
import {
  sendMachineDisplayLines,
  clearMachineDisplay
} from '../../../machine/display'
import { addPayments as baseAddPayments } from 's3p-js-lib/src/redux/actions/api/v2/payment/add-payments'
import { updatePayments as baseUpdatePayments } from 's3p-js-lib/src/redux/actions/api/v2/payment/update-payments'
import { redirectToTicketErrorPage } from '../../tickets/product-search-result'
import { confirmBooking } from 's3p-js-lib/src/redux/actions/api/v2/confirm-booking'
import { displayFormatAmount } from '../../../../../misc/utils'
import { currentPathnameSelector } from '../../../../selectors/containers/base/routing'
import _t from 's3p-js-lib/src/translate'
import { getReceiptDocument } from 's3p-js-lib/src/redux/actions/api/booking/ticket-documents'
import {
  receiptDocumentSelector as receiptTicketDocumentSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/ticket-documents'
import { currencyRateValueSelector } from 's3p-js-lib/src/redux/selectors/api/v2/meta/currency-rates'
import { pedPaymentStatusSelector } from '../../../../selectors/containers/base/payment-modal/payment-modal'
import { selectedPaymentMethodSelector } from '../../../../selectors/containers/base/tiles/payment-methods'
import {
  PAYMENT_STATUS_P,
  PAYMENT_STATUS_S
} from 's3p-js-lib/src/constants'
import { elementVisibleSelector } from '../../../../selectors/containers/base/visible-element'
import { paymentsWithoutFailedSelector } from '../../../../selectors/api/booking/payments'

const _receiptTicketDocumentSelector = receiptTicketDocumentSelector(completedOrOverviewBookingSelector)
const _pendingTotalPriceToBePaidSelector = pendingTotalPriceToBePaidSelector(bookingSelector)

const selectPaymentMethod = (code, amountToBePaid, amountToBePaidGBP) => ({
  type: USER_INPUT_PAYMENT_METHOD_SELECT,
  code,
  amountToBePaid,
  amountToBePaidGBP
})
const deselectPaymentMethod = () => ({type: USER_INPUT_PAYMENT_METHOD_DESELECT})
export const setPrintReceiptError = message => ({type: UI_PRINTING_RECEIPT_ERROR, errorMessage: message})

export const PAYMENT_MODAL = 'payment-method'

export const handleSelectPaymentMethod = paymentMethod => (dispatch, getState) => {
  const state = getState()
  const amountToBePaid = _pendingTotalPriceToBePaidSelector(state)
  const currencyRate = currencyRateValueSelector(CURRENCY_BRITISH_POUND)(state)
  const amountToBePaidGBP = Math.round(100 * amountToBePaid * currencyRate) / 100

  dispatch([
    selectPaymentMethod(paymentMethod.code, amountToBePaid, amountToBePaidGBP),
    showModal(PAYMENT_MODAL),
    sendMachineDisplayLines([
      displayFormatAmount(_t.message('machine.customer-display.lines.payment-amount.upper'), amountToBePaid),
      _t.message('machine.customer-display.lines.payment-amount.lower')
    ])
  ])
}

export const getPaymentMethods = () => (dispatch, getState) => {
  const bookingNumber = bookingNumberSelector(provisionalOrCompletedBookingSelector)(getState())
  if (bookingNumber) {
    dispatch(baseGetPaymentMethods(bookingNumber))
  }
}

export const updatePendingPayments = status => async (dispatch, getState) => {
  const state = getState()
  const bookingNumber = bookingNumberSelector(bookingSelector)(state)
  const payments = paymentsWithoutFailedSelector(bookingSelector)(state).reduce(
    (_payments, payment) => {
      if (!payment.voucher && payment.paymentStatus === PAYMENT_STATUS_P) {
        _payments.push({
          ref: payment.refId,
          status
        })
      }

      return _payments
    },
    []
  )

  if (payments.length) {
    return dispatch(baseUpdatePayments(payments, bookingNumber))
  } else {
    return true
  }
}

export const pendingPaymentOrConfirmBooking = () => async (dispatch, getState) => {
  let hideModal = true
  let response = true
  const state = getState()
  const statusFailedPEDPayment = selectedPaymentMethodSelector(state) === PAYMENT_METHOD_CODE_CREDIT_DEBIT_CARD &&
    pedPaymentStatusSelector(state) === PED_STATUS_FAILURE
  if (!_pendingTotalPriceToBePaidSelector(state)) {
    response = await dispatch(updatePendingPayments(PAYMENT_STATUS_S))
    hideModal = false

    if (response && !statusFailedPEDPayment) {
      response = await dispatch(confirmBooking())
      await dispatch(printReceipt())
    }
  }
  !statusFailedPEDPayment && hideModal && dispatch(hidePaymentModal())

  return response
}

export const showFinalizeBookingModal = () => (dispatch, getState) => {
  if (completedBookingSelector(getState())) {
    dispatch(showModal(FINALIZE_BOOKING_MODAL))
  }
}

export const addPayments = payments => async (dispatch, getState) => {
  const isTicketsFlow = currentPathnameSelector(getState()).indexOf('/tickets') !== -1
  const isRefund =
    currentPathnameSelector(getState()).indexOf('/aftersales') !== -1 &&
    elementVisibleSelector(REFUND_MODAL)(getState())
  const bookingNumber = bookingNumberSelector(bookingSelector)(getState())
  let response = payments.length === 0
  if (bookingNumber && payments.length) {
    response = await dispatch(baseAddPayments(
      payments,
      bookingNumber
    ))
  }
  if (isTicketsFlow && !response) {
    redirectToTicketErrorPage(dispatch, getState().api.base.lastApiErrors.v2.updatePayments)
  }

  isTicketsFlow && dispatch(showElement(SIDEBAR_CART))
  if (!isRefund && response) {
    return dispatch(pendingPaymentOrConfirmBooking())
  }

  return false
}

export const printReceipt = () => async (dispatch, getState) => {
  const receiptTicketDocument = _receiptTicketDocumentSelector(getState())

  if (receiptTicketDocument && receiptTicketDocument.url) {
    const response = await dispatch(getReceiptDocument(receiptTicketDocument.url))

    if (response) {
      const receiptDocumentText = receiptDocumentSelector(getState())

      try {
        const receiptDocument = JSON.parse(receiptDocumentText)
        const printDispatch = sendMachineReceiptPrint(receiptDocument)

        await dispatch(printDispatch)
      } catch (error) {
        dispatch(setPrintReceiptError(_t.message('finalize-booking-modal.receipt.default-error')))
      }
    } else {
      dispatch(setPrintReceiptError(_t.message('finalize-booking-modal.receipt.get-receipt-document-error')))
    }
  }
}

export const hidePaymentModal = () => dispatch => {
  dispatch([hideElement(PAYMENT_MODAL), clearMachineDisplay(), deselectPaymentMethod()])
}
