import { additionalDetailValueSelector } from 's3p-js-lib/src/redux/selectors/api/booking/additional-details'
import {
  bookingNumberSelector,
  totalPriceBookingSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import { getBooking } from 's3p-js-lib/src/redux/actions/api/booking/booking'
import { UI_LEAP_REVERSE_START, UI_LEAP_REVERSE_END } from '../../../types'
import { readLeapCard } from '../../leap/leap-read'
import { leapDataSelector } from '../../../../selectors/machine/leap'
import { reverseLeap } from '../../../machine/leap'
import { readLeapStepsBeforeActionSelector, transactionReversalDurationSelector } from '../../../../selectors/containers/base/leap'
import { addOrUpdateAdditionalDetails, LEAP_REVERSE_KEY } from '../../leap/leap'
import {
  LEAP_TOP_UP_TRANSACTION_CODE,
  LEAP_ADDITIONAL_DETAIL_KEY_CARD_IDENTIFIER,
  LEAP_REVERSE_ERROR_MESSAGE_BOOKING_HAS_NO_INFORMATION,
  LEAP_REVERSE_ERROR_MESSAGE_CANCEL_TIME_EXCEEDED,
  LEAP_REVERSE_ERROR_MESSAGE_REVERSE_FAILED,
  LEAP_REVERSE_ERROR_MESSAGE_WRONG_CARD_PRESENTED,
  LEAP_REVERSE_ERROR_MESSAGE_WRONG_LAST_EVENT_VALUE,
  LEAP_REVERSE_ERROR_MESSAGE_WRONG_LAST_TRANSACTION_CODE,
  LEAP_TIMEOUT
} from '../../../../../constants'
import moment from 'moment'
import {createShiftNotesForReverseLeapResultError, createShiftNotesForReverseLeapResultSuccess} from '../../leap/leap-reverse'
import {addNotesAgentShift} from 's3p-js-lib/src/redux/actions/api/user/agent/shift'

const _bookingNumberSelector = bookingNumberSelector(originalBookingSelector)
const _additionalDetailCardIdentifierSelector = additionalDetailValueSelector(originalBookingSelector, LEAP_ADDITIONAL_DETAIL_KEY_CARD_IDENTIFIER)
const _totalPriceBookingSelector = totalPriceBookingSelector(originalBookingSelector)

const startLeapReverse = () => ({type: UI_LEAP_REVERSE_START})
const endLeapReverse = errorMessage => ({type: UI_LEAP_REVERSE_END, errorMessage})

export const processReverseLeap = () => async (dispatch, getState) => {
  dispatch(startLeapReverse())
  let errorMessage = null
  let shiftNotes = []

  const result = await dispatch(readLeapCard())
  if (result) {
    const state = getState()
    errorMessage = readLeapStepsBeforeActionSelector(state)
    if (!errorMessage) {
      const data = leapDataSelector(state)
      const cardIdentifierProduct = _additionalDetailCardIdentifierSelector(state)
      if (cardIdentifierProduct) {
        if (parseInt(cardIdentifierProduct) === data.cardIdentifier) {
          const totalPrice = _totalPriceBookingSelector(state)
          if (data.lastTransaction.value === totalPrice) {
            if (data.lastTransaction.eventCode === LEAP_TOP_UP_TRANSACTION_CODE) {
              const booking = originalBookingSelector(state)
              if (booking.confirmed_timestamp && moment(booking.confirmed_timestamp).add(transactionReversalDurationSelector(), 'seconds').isAfter(moment())) {
                const result = await dispatch(reverseLeap(LEAP_TIMEOUT, data.cardIdentifier))
                if (result) {
                  shiftNotes = createShiftNotesForReverseLeapResultSuccess(getState())
                } else {
                  errorMessage = LEAP_REVERSE_ERROR_MESSAGE_REVERSE_FAILED
                }
                await dispatch(getBooking(_bookingNumberSelector(state)))
                await dispatch(addOrUpdateAdditionalDetails(getState().machine.leap.reverse, result, LEAP_REVERSE_KEY, false))
              } else {
                errorMessage = LEAP_REVERSE_ERROR_MESSAGE_CANCEL_TIME_EXCEEDED
              }
            } else {
              errorMessage = LEAP_REVERSE_ERROR_MESSAGE_WRONG_LAST_TRANSACTION_CODE
            }
          } else {
            errorMessage = LEAP_REVERSE_ERROR_MESSAGE_WRONG_LAST_EVENT_VALUE
          }
        } else {
          errorMessage = LEAP_REVERSE_ERROR_MESSAGE_WRONG_CARD_PRESENTED
        }
      } else {
        errorMessage = LEAP_REVERSE_ERROR_MESSAGE_BOOKING_HAS_NO_INFORMATION
      }
    }
  }
  if (errorMessage) {
    shiftNotes = createShiftNotesForReverseLeapResultError(errorMessage)
  }
  await dispatch(addNotesAgentShift(shiftNotes))
  dispatch(endLeapReverse(errorMessage))
}
