import React, { Component } from 'react'
import ComponentContainer from '../component-container/component-container'
import './sticky-footer-taskbar.scss'

export default class StickyFooter extends Component {
  render () {
    return (
      <ComponentContainer name='sticky-footer-taskbar'>
        {this.props.children}
      </ComponentContainer>
    )
  }
}
