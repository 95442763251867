import {
  API_GET_STATIONS_REQUEST,
  API_GET_STATIONS_SUCCESS,
  API_GET_STATIONS_FAILURE,
  CLEAR_STATE
} from '../../../actions/types'

export const initialState = null

export default function (state = initialState, action) {
  switch (action.type) {
    case API_GET_STATIONS_REQUEST:
    case API_GET_STATIONS_FAILURE:
      return initialState
    case API_GET_STATIONS_SUCCESS:
      return action.response
    case CLEAR_STATE:
      return action.namespaces.includes('api.orientation.stations') ? initialState : state
    default:
      return state
  }
}
