import {
  API_V2_USER_LOGOUT_REQUEST,
  API_V2_USER_LOGOUT_SUCCESS,
  API_V2_USER_LOGOUT_FAILURE
} from '../../../types-v2'
import Client from '../../../../../api/client'
import ensureToken from 's3p-js-lib/src/api/ensure-token'

const userLogoutRequest = () => ({type: API_V2_USER_LOGOUT_REQUEST})
const userLogoutSuccess = response => ({type: API_V2_USER_LOGOUT_SUCCESS, response})
const userLogoutFailure = error => ({type: API_V2_USER_LOGOUT_FAILURE, error})

export const userLogout = () => ensureToken(async (token, dispatch) => {
  if (token) {
    try {
      dispatch(userLogoutRequest())
      const response = await Client.logoutUser(token)
      dispatch(userLogoutSuccess(response))
    } catch (error) {
      dispatch(userLogoutFailure(error))
      throw error
    }
  }
})
