import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../elements/text-label/text-label'
import Icon from '../../../elements/icon/icon'
import TileSet from '../../../elements/tile-set/tile-set'
import {
  PAYMENT_METHOD_CODE_CASH,
  PAYMENT_METHOD_CODE_VOUCHER,
  PAYMENT_METHOD_CODE_CREDIT_DEBIT_CARD
} from '../../../constants'

const HOTKEY_MAP = {
  [PAYMENT_METHOD_CODE_CASH]: 'selectCash',
  [PAYMENT_METHOD_CODE_VOUCHER]: 'selectVoucher',
  [PAYMENT_METHOD_CODE_CREDIT_DEBIT_CARD]: 'selectCard'
}

const ICON_MAP = {
  [PAYMENT_METHOD_CODE_CASH]: 'payment-method-cash',
  [PAYMENT_METHOD_CODE_VOUCHER]: 'payment-method-voucher',
  [PAYMENT_METHOD_CODE_CREDIT_DEBIT_CARD]: 'payment-method-credit-debit-card'
}

export default class PaymentMethodsTile extends Component {
  static propTypes = {
    object: PropTypes.shape({
      code: PropTypes.string.isRequired,
      available: PropTypes.bool,
      selected: PropTypes.bool,
      icon: PropTypes.element
    }),
    handleSelect: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool
  }

  constructor (props, ...args) {
    super(props, ...args)
    this.handleClick = props.handleSelect.bind(null, props.object)
  }

  render () {
    return (
      <TileSet.Tile
        autoFocus={this.props.autoFocus}
        onClick={this.handleClick}
        selected={this.props.object.selected}
        disabled={!this.props.object.available || this.props.disabled}
        icon={<Icon name={ICON_MAP[this.props.object.code]} />}
        hotkey={HOTKEY_MAP[this.props.object.code]}
      >
        <TextLabel name='payment-method' text={_t.message(`payment-methods.${this.props.object.code}.name`)} />
      </TileSet.Tile>
    )
  }
}
