import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Form from '../../../elements/form/form'
import PasswordStrengthIndicator from './password-strength-indicator'
import './change-password.scss'

export default class ChangePasswordForm extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    form: PropTypes.shape({
      fields: PropTypes.shape({
        currentPassword: PropTypes.object.isRequired,
        newPassword: PropTypes.shape({
          value: PropTypes.string
        }).isRequired,
        newPasswordRepeated: PropTypes.object.isRequired
      }).isRequired
    }).isRequired
  }

  render () {
    return (
      <Form formContext='change-password'>
        <fieldset>
          <Form.InputWrapper name='current-password'>
            <Form.PasswordField
              {...this.props.form.fields.currentPassword}
              name='current-password'
              id='input-change-password-current-password'
              autoComplete='off' />
          </Form.InputWrapper>
        </fieldset>
        <PasswordStrengthIndicator
          password={this.props.form.fields.newPassword.value || ''}
          passwordRepeated={this.props.form.fields.newPasswordRepeated.value || ''} />
        <fieldset>
          <Form.InputWrapper name='new-password'>
            <Form.PasswordField
              {...this.props.form.fields.newPassword}
              name='new-password'
              id='input-change-password-new-password'
              autoComplete='off' />
          </Form.InputWrapper>
          <Form.InputWrapper name='repeat-new-password'>
            <Form.PasswordField
              {...this.props.form.fields.newPasswordRepeated}
              name='repeat-new-password'
              id='input-password-repeat-new-password'
              autoComplete='off' />
          </Form.InputWrapper>
        </fieldset>
      </Form>
    )
  }
}
