import {
  CLEAR_STATE,
  API_REQUEST_REFUND_REQUEST,
  API_REQUEST_REFUND_SUCCESS,
  API_REQUEST_REFUND_FAILURE
} from 's3p-js-lib/src/redux/actions/types'
import {
  USER_INPUT_SET_REFUND_STEP,
  USER_INPUT_REFUND_DONE,
  USER_INPUT_REFUND_ABORT
} from '../../../../actions/types'

const initialState = {
  success: false,
  activeStep: null,
  isAborting: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_INPUT_REFUND_ABORT:
      return {...state, isAborting: true}
    case USER_INPUT_SET_REFUND_STEP:
      return {...state, activeStep: action.step}
    case USER_INPUT_REFUND_DONE:
      return {...state, isAborting: false, activeStep: null}
    case CLEAR_STATE:
      return action.namespaces.includes('api.payment.refundVoucher') ? initialState : state
    case API_REQUEST_REFUND_REQUEST:
    case API_REQUEST_REFUND_FAILURE:
      return {...state, success: false}
    case API_REQUEST_REFUND_SUCCESS:
      return {...state, success: true}
    default:
      return state
  }
}
