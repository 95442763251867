import PropTypes from 'prop-types'
import React, { Component } from 'react'
import GenericLayout from '../generic-layout'
import Booking from '../../containers/aftersales/booking/booking'
import MainMenu from '../../containers/base/main-menu/main-menu'
import GridLayout from '../../elements/grid/grid-layout'
import GridColumn from '../../elements/grid/grid-column'

export default class BookingLayout extends Component {
  static propTypes = {
    routeParams: PropTypes.shape({
      bookingNumber: PropTypes.string.isRequired
    })
  }

  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <MainMenu />
            <Booking bookingNumber={this.props.routeParams.bookingNumber} />
          </GridColumn>
        </GridLayout>
      </GenericLayout>
    )
  }
}
