import { USER_INPUT_REBOOKING_OFFER_REBOOK_SELECTION } from 's3p-js-lib/src/redux/actions/types'
import { executeJourneySearch } from 's3p-js-lib/src/redux/actions/api/aftersales/rebooking/journey-search'
import {
  rebookingSelectionSelector,
  rebookingDirectionSelector
} from '../../../../selectors/user-input/aftersales/rebooking/selection'
import {
  TRAVEL_DIRECTION_OUTBOUND,
  TRAVEL_DIRECTION_INBOUND,
  TRAVEL_DIRECTION_BOTH
} from 's3p-js-lib/src/constants'
import { loadValues } from 's3p-js-lib/src/redux/actions/user-input/base/form'
import { baseJourneySearch } from '../../base/journey-search/journey-search'
import { journeySearchFormValuesSelector } from '../../../../selectors/containers/base/journey-search/journey-search'
import { FORM_TYPE_REBOOK_JOURNEY_SEARCH } from 's3p-js-lib/src/constants-form'
import { JOURNEY_SEARCH_PRODUCT_FAMILIES } from '../../../../../constants'

export const rebookingSelection = selection => {
  return {type: USER_INPUT_REBOOKING_OFFER_REBOOK_SELECTION, selection}
}

export const loadDefaultFormValues = () => (dispatch, getState) => {
  dispatch(loadValues(
    FORM_TYPE_REBOOK_JOURNEY_SEARCH,
    journeySearchFormValuesSelector(getState())
  ))
}

const rebookPerformJourneySearch = values => (dispatch, getState) => {
  const rebookingSelection = rebookingSelectionSelector(getState())
  const rebookingDirection = rebookingDirectionSelector(getState())

  if (!rebookingSelection ||
    (rebookingSelection !== rebookingDirection && rebookingDirection !== TRAVEL_DIRECTION_BOTH)
  ) {
    throw new Error(`Direction(s) selected for rebook not allowed: ${rebookingSelection} `)
  }

  const outboundSelection = [TRAVEL_DIRECTION_OUTBOUND, TRAVEL_DIRECTION_BOTH].includes(rebookingSelection)
  const inboundSelection = [TRAVEL_DIRECTION_INBOUND, TRAVEL_DIRECTION_BOTH].includes(rebookingSelection)

  return dispatch(executeJourneySearch({
    origin: values.origin.UICStationCode,
    destination: values.destination.UICStationCode,
    outboundDate: outboundSelection ? values.outboundDate : undefined,
    outboundTimeEarliest: outboundSelection ? values.outboundTimeEarliest : undefined,
    outboundTimeLatest: outboundSelection ? values.outboundTimeLatest : undefined,
    inboundDate: inboundSelection ? values.inboundDate : undefined,
    inboundTimeEarliest: inboundSelection ? values.inboundTimeEarliest : undefined,
    inboundTimeLatest: inboundSelection ? values.inboundTimeLatest : undefined,
    currency: getState().api.orientation.currency,
    passengers: values.passengers.map(passenger => ({
      id: passenger.id,
      type: passenger.type,
      disability_type: passenger.disabilityType,
      discount_cards: passenger.discountCards
    })),
    productFamilies: values.productFamilies
  }))
}

export const rebookingJourneySearch = values => dispatch => {
  const newValues = {
    ...values,
    productFamilies: JOURNEY_SEARCH_PRODUCT_FAMILIES
  }
  return dispatch(baseJourneySearch(true)(rebookPerformJourneySearch)(newValues))
}
