/* globals DEBUG_MODE: true */
/* globals S3P_SETTINGS: true */
import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { routerMiddleware } from 'react-router-redux'
import apiMiddleware from 's3p-js-lib/src/redux/middleware/api-middleware'
import errorMiddleware from './middleware/error-middleware'
import errorPageMiddleware from './middleware/error-page-middleware'
import machineErrorMiddleware from './middleware/machine-error-middleware'
import batchActionsMiddleware from 's3p-js-lib/src/redux/middleware/batch-actions-middleware'
import offlineMiddleware from './middleware/offline'
import shiftEnded from './middleware/shift-ended'
import rootReducer from './reducers/root-reducer'
import {maskData, sentryMiddleware, createReduxEnhancer, isSentryEnabled} from '@s3p-js-deep-purple/sentry'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import {filterDeep, omitDeep} from 'deepdash-es/standalone'

let store
const getStore = () => store
const setStore = _store => {
  store = _store
  return store
}

const initialState = {}

const createReduxStore = () => {
  if (getStore()) {
    return getStore()
  }

  const extensions = []
  const middleware = [
    batchActionsMiddleware,
    routerMiddleware(window.reacthistory),
    offlineMiddleware,
    shiftEnded,
    machineErrorMiddleware,
    errorMiddleware,
    sentryMiddleware,
    errorPageMiddleware,
    apiMiddleware,
    thunkMiddleware
  ]

  if (DEBUG_MODE && window.__REDUX_DEVTOOLS_EXTENSION__) {
    extensions.push(window.__REDUX_DEVTOOLS_EXTENSION__({
      name: 'Irish rail BOM',
      trace: true,
      traceLimit: 25
    }))
  }

  if (isSentryEnabled() && S3P_SETTINGS.s3Passenger.sentry.includeReduxState) {
    const filterKeys = [
      'auth',
      'loading',
      'stations',
      'documentTemplates',
      'leap'
    ]
    extensions.push(createReduxEnhancer({
      actionTransformer: action => ({
        type: action?.action?.type || action?.type
      }),
      stateTransformer: state => {
        const s = state?.computedStates && state?.currentStateIndex && state.computedStates[state.currentStateIndex]
        if (s) {
          return maskData(
            filterDeep(
              omitDeep(s, filterKeys, {onMatch: {skipChildren: true}}),
              value => !isNil(value) && !isEmpty(value)
            )
          )
        }
        return null
      }
    }))
  }

  const store = createStore(
    rootReducer,
    initialState,
    compose(...[applyMiddleware(...middleware), ...extensions])
  )

  if (module.hot) {
    module.hot.accept('./reducers/root-reducer', () => {
      store.replaceReducer(require('./reducers/root-reducer'))
    })
  }

  return setStore(store)
}

export default createReduxStore
