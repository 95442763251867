import {
  API_REBOOKING_CREATE_BOOKING_FAILURE,
  API_REBOOKING_CREATE_BOOKING_REQUEST,
  API_REBOOKING_CREATE_BOOKING_SUCCESS
} from 's3p-js-lib/src/redux/actions/types'
import ResponseCodes from 's3p-js-lib/src/api/response-codes'
import ensureToken from 's3p-js-lib/src/api/ensure-token'
import Client from '../../../../../api/client'
import {createRebookingV2RequestSelector} from './booking-mapper'

const createBookingRequest = (journeyId, waiveFees, overrideAftersalesRules, overrideValidationRules) => ({
  type: API_REBOOKING_CREATE_BOOKING_REQUEST,
  journeyId,
  waiveFees,
  overrideAftersalesRules,
  overrideValidationRules
})
const createBookingSuccess = response => ({type: API_REBOOKING_CREATE_BOOKING_SUCCESS, response})
const createBookingFailure = error => ({type: API_REBOOKING_CREATE_BOOKING_FAILURE, error})

export const rebookingCreateBooking = (journeyId, waiveFees, overrideAftersalesRules, overrideValidationRules) => ensureToken(async (token, dispatch, getState) => {
  try {
    dispatch(createBookingRequest(journeyId, waiveFees, overrideAftersalesRules, overrideValidationRules))
    const requestData = createRebookingV2RequestSelector(waiveFees, overrideAftersalesRules, overrideValidationRules)(getState())
    const response = await Client.rebookingCreateBooking(token, requestData)
    dispatch(createBookingSuccess(response))
  } catch (error) {
    dispatch(createBookingFailure(error))
    const handledErrors = [ResponseCodes.AFTER_SALES_OPERATION_HAS_INVALID_ITEMS]
    if (!handledErrors.includes(error.errorCode)) {
      throw error
    }
    return false
  }
})
