import {connect} from 'react-redux'
import {
  startPedPayment,
  addCreditDebitPayment,
  validatePedPayment,
  declinePedPayment,
  retryPrintReceipts,
  printSignatureReceipt,
  printPedFailedReceipt,
  abandonOperation
} from '../../../redux/actions/containers/base/payment-modal/credit-debit-modal'
import {
  abandonPedOperationSelector,
  loadingSelector
} from '../../../redux/selectors/containers/base/payment-modal/payment-modal'
import {formIsValidSelectorCreator} from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import {
  PED_STATUS_SUCCESS,
  PED_STATUS_DECLINED,
  PED_STATUS_FAILURE,
  PED_STATUS_AUTHORIZATION_REQUIRED,
  FORM_CREDIT_DEBIT
} from '../../../constants'
import CreditDebitFooter from '../../../components/base/payment-modal/credit-debit-footer'
import {openMachineCashDrawer} from '../../../redux/actions/machine/cash-drawer'

const formIsValidSelector = formIsValidSelectorCreator(FORM_CREDIT_DEBIT)

const mapStateToProps = state => {
  const pedPaymentStatusSuccess = state.containers.base.paymentModal.creditDebit.status === PED_STATUS_SUCCESS
  return {
    disableRetry:
      ![PED_STATUS_DECLINED, PED_STATUS_FAILURE].includes(state.containers.base.paymentModal.creditDebit.status) ||
      state.api.v2.loading.addPayments || state.api.v2.loading.updatePayments,
    disablePaymentReceived: !pedPaymentStatusSuccess || state.containers.base.paymentModal.creditDebit.retryReceipt,
    disableValidate: (
      state.containers.base.paymentModal.creditDebit.loadingDecline ||
      (state.containers.base.paymentModal.creditDebit.showForm && !formIsValidSelector(state))
    ),
    disableDecline: state.containers.base.paymentModal.creditDebit.loadingValidate,
    isLoadingPaymentReceived: loadingSelector(state) && !state.containers.base.paymentModal.creditDebit.retryReceipt,
    isLoadingValidate: state.containers.base.paymentModal.creditDebit.loadingValidate,
    isLoadingDecline: state.containers.base.paymentModal.creditDebit.loadingDecline,
    isLoadingRetryPrint: state.machine.loading.receiptMachinePrint,
    showValidationButtons: state.containers.base.paymentModal.creditDebit.status === PED_STATUS_AUTHORIZATION_REQUIRED,
    showRetryPrintButton: state.containers.base.paymentModal.creditDebit.retryReceipt,
    showRetryPedFailedButton: !pedPaymentStatusSuccess,
    showAbandonOperation: abandonPedOperationSelector(state)
  }
}

const mapDispatchToProps = {
  handleRetry: startPedPayment,
  handlePaymentReceived: () => [openMachineCashDrawer(), addCreditDebitPayment()],
  handleValidate: validatePedPayment,
  handleDecline: declinePedPayment,
  handleRetryPrint: retryPrintReceipts,
  handleRetrySignaturePrint: printSignatureReceipt,
  handleRetryPedFailedReceipt: printPedFailedReceipt,
  handleAbandonOperation: abandonOperation
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditDebitFooter)
