import {
  UI_ELEMENT_SHOW,
  UI_ELEMENT_HIDE,
  UI_ELEMENT_TOGGLE,
  UI_ELEMENTS_CLEAR
} from 's3p-js-lib/src/redux/actions/types'

export const showElement = name => ({type: UI_ELEMENT_SHOW, name})

/**
 *
 * @param name: string
 * @param extraProps: Object | undefined
 * @returns {{name: string, type: string, isModal: boolean, extraProps: Object | undefined}}
 */
export const showModal = (name, extraProps = undefined) => ({type: UI_ELEMENT_SHOW, isModal: true, name, extraProps})
export const hideElement = name => ({type: UI_ELEMENT_HIDE, name})
export const toggleElement = name => ({type: UI_ELEMENT_TOGGLE, name})
export const clearElements = names => ({type: UI_ELEMENTS_CLEAR, names})
