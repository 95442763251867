import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../../../elements/text-label/text-label'
import _t from 's3p-js-lib/src/translate'
import Table from '../../../../elements/table/table'
import SegmentPassengerRow from './segment-passengers/segment-passenger-row'

export default class SegmentPassengers extends Component {
  static propTypes = {
    passengers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired
    })).isRequired,
    feeSummary: PropTypes.shape({
      fees: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired
      })).isRequired,
      totalPrice: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired
    })
  }

  render () {
    return (
      <div className='segment-passengers'>
        <Table name='segment-passengers' numColumns={4} lastCellsAlignRight>
          <thead>
            <tr>
              <th scope='col'>
                <TextLabel name='thead' text='Passenger' />
              </th>
              <th scope='col'>
                <TextLabel name='thead' text='Seat' />
              </th>
              <th scope='col'>
                <TextLabel name='thead' text='Add-ons' />
              </th>
              <th scope='col'>
                <TextLabel name='thead' text='Price' />
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.passengers.map(passenger => <SegmentPassengerRow key={passenger.id} {...passenger} />)}
          </tbody>
          {this.props.feeSummary
            ? (
              <tfoot>
                <tr>
                  <td colSpan='2'>&nbsp;</td>
                  <td data-th-label={_t.message('aftersales.booking.journey-overview.table.footer')}>
                    {this.props.feeSummary.fees.length === 1
                      ? <TextLabel name='fee-name' text={this.props.feeSummary.fees[0].name} />
                      : (
                        <TextLabel
                          name='applicable-fees-description'
                          text={_t.message(`aftersales.booking.journey-overview.fees_description`)}
                        />
                      )}
                  </td>
                  <td data-th-label={_t.message('aftersales.booking.journey-overview.table.footer')}>
                    {this.props.feeSummary.fees.length === 1
                      ? (
                        <TextLabel
                          name='fee-sum'
                          text={_t.formatCurrency(this.props.feeSummary.totalPrice, this.props.feeSummary.currency)}
                        />
                      ) : null}
                  </td>
                </tr>
              </tfoot>
            ) : null}
        </Table>
      </div>
    )
  }
}
