import {
  API_GET_DOCUMENT_TEMPLATES_REQUEST,
  API_GET_DOCUMENT_TEMPLATES_SUCCESS,
  API_GET_DOCUMENT_TEMPLATES_FAILURE
} from '../../types'
import Client from '../../../../api/client'
import ensureToken from '../../../../api/ensure-token'
import ResponseCodes from '../../../../api/response-codes'

const getDocumentTemplatesRequest = documentTemplateTypes => ({
  type: API_GET_DOCUMENT_TEMPLATES_REQUEST, documentTemplateTypes
})
const getDocumentTemplatesSuccess = response => ({type: API_GET_DOCUMENT_TEMPLATES_SUCCESS, response})
const getDocumentTemplatesFailure = error => ({type: API_GET_DOCUMENT_TEMPLATES_FAILURE, error})

export const getDocumentTemplates = documentTemplateTypes => ensureToken(async (token, dispatch) => {
  try {
    dispatch(getDocumentTemplatesRequest(documentTemplateTypes))
    const response = await Client.getDocumentTemplates(token, documentTemplateTypes)
    dispatch(getDocumentTemplatesSuccess(response))
  } catch (error) {
    dispatch(getDocumentTemplatesFailure(error))
    if (ResponseCodes.API_DOCUMENT_TEMPLATES_NOT_FOUND) {
      return false
    }
    throw error
  }
})
