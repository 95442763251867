import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  DISCOUNT_CARD_PRIVILEGE_CARD,
  DISCOUNT_CARD_STUDENT_CARD,
  DISCOUNT_CARD_WELFARE_CARD
} from '../../../../constants'
import DiscountCardSelect from '../../../../elements/discount-card-select/discount-card-select'
import TextLabel from '../../../../elements/text-label/text-label'
import Icon from '../../../../elements/icon/icon'
import Button from '../../../../elements/button/button'
import Form from '../../../../elements/form/form'
import Label from '../../../../elements/label/label'
import {
  PASSENGER_TYPE_ADULT,
  PASSENGER_TYPE_CHILD,
  PASSENGER_TYPE_STUDENT
} from 's3p-js-lib/src/constants'
import _t from 's3p-js-lib/src/translate'
import AmountInput from '../../../../elements/amount-input/amount-input'
import ProductFamilySelect from '../../../../elements/product-family-select/product-family-select'
import ProductTile from './product-tile'
import Feedback, {
  FEEDBACK_SUCCESS,
  FEEDBACK_INFORMATION
} from '../../../../elements/feedback/feedback'
import TileSet from '../../../../elements/tile-set/tile-set'

const FIELD_ID_ORIGIN = 'editOrigin'
const FIELD_ID_DESTINATION = 'editDestination'
const FIELD_ID_PRODUCT_FAMILY = 'product-family'
const FIELD_ID_VALID_FROM = 'date-interval'
const FIELD_ID_PRODUCT_FILTER = 'product-filter'

export default class EditModalForm extends Component {
  static propTypes = {
    sequenceNumber: PropTypes.number.isRequired,
    searchProducts: PropTypes.func.isRequired,
    onChangeAdult: PropTypes.func.isRequired,
    onChangeChild: PropTypes.func.isRequired,
    onChangeStudent: PropTypes.func.isRequired,
    selectProduct: PropTypes.func.isRequired,
    stations: PropTypes.array.isRequired,
    maxPerPassengerType: PropTypes.shape({
      [PASSENGER_TYPE_STUDENT]: PropTypes.number,
      [PASSENGER_TYPE_CHILD]: PropTypes.number,
      [PASSENGER_TYPE_ADULT]: PropTypes.number
    }),
    valueAdult: PropTypes.number.isRequired,
    valueChild: PropTypes.number.isRequired,
    valueStudent: PropTypes.number.isRequired,
    isLoadingProductsSearch: PropTypes.bool.isRequired,
    isLoadingUpdateAgent: PropTypes.bool.isRequired,
    products: PropTypes.arrayOf(PropTypes.object),
    searchedProducts: PropTypes.bool.isRequired,
    shortcutSavedSuccessful: PropTypes.bool,
    form: PropTypes.shape({
      getValues: PropTypes.func.isRequired,
      valid: PropTypes.bool,
      fields: PropTypes.shape({
        productFamily: PropTypes.object,
        origin: PropTypes.object,
        destination: PropTypes.object,
        dateInterval: PropTypes.shape({
          value: PropTypes.number
        }),
        product: PropTypes.object,
        productFilter: PropTypes.object,
        discountCard: PropTypes.object
      })
    })
  }

  constructor (props, ...args) {
    super(props, ...args)

    this.handleProductFamilySelectChange = this.handleProductFamilySelectChange.bind(this)
    this.handleOriginChange = this.handleOriginChange.bind(this)
    this.handleDestinationChange = this.handleDestinationChange.bind(this)
    this.discountCardTypes = this.discountCardTypes.bind(this)
    this.keyHandlers = {
      refresh: props.searchProducts
    }
  }

  componentDidMount () {
    this._focusProductFamily()
  }

  UNSAFE_componentWillReceiveProps ({sequenceNumber}) {
    if (this.props.sequenceNumber !== sequenceNumber) {
      this._focusProductFamily()
    }
  }

  _focusProductFamily () {
    const element = document.getElementById(`${FIELD_ID_PRODUCT_FAMILY}_input`)
    element && setTimeout(() => element.focus(), 100)
  }

  handleInputChange (id, onChange, value) {
    onChange(value)
    const element = document.getElementById(id)
    element && setTimeout(() => element.focus(), 200)
  }

  _renderStationSelectorField (props) {
    return (
      <Form.InputGroup name={props.name}>
        <Form.InputWrapper
          name='label'
          className={!props.valid && props.touched ? 'state--is-invalid' : ''}
        >
          <Form.Label htmlFor={props.name} name={props.name} />
        </Form.InputWrapper>
        <Form.InputWrapper
          name='input'
          hotkey={props.hotkey}
        >
          <Form.StationSelect
            {...props}
            autoComplete='off'
            stations={this.props.stations || []}
          />
        </Form.InputWrapper>
      </Form.InputGroup>
    )
  }

  _getPropsOriginField () {
    return {
      ...this.props.form.fields.origin,
      fieldName: 'origin',
      id: FIELD_ID_ORIGIN,
      hotkey: 'focusOrigin',
      onChange: this.handleOriginChange
    }
  }

  _getPropsDestinationField () {
    return {
      ...this.props.form.fields.destination,
      fieldName: 'destination',
      id: FIELD_ID_DESTINATION,
      hotkey: 'focusDestination',
      onChange: this.handleDestinationChange
    }
  }

  handleProductFamilySelectChange (value) {
    this.handleInputChange(
      `${FIELD_ID_ORIGIN}_input`,
      this.props.form.fields.productFamily.onChange,
      value
    )
  }

  handleOriginChange (value) {
    this.handleInputChange(
      `${FIELD_ID_DESTINATION}_input`,
      this.props.form.fields.origin.onChange,
      value
    )
  }

  handleDestinationChange (value) {
    this.handleInputChange(
      FIELD_ID_VALID_FROM,
      this.props.form.fields.destination.onChange,
      value
    )
  }

  discountCardTypes () {
    const discountCardTypes = [
      {
        code: '',
        text: _t.message('product-search.passenger-details.discount-card-types.no-card')
      },
      {
        code: DISCOUNT_CARD_PRIVILEGE_CARD,
        text: _t.message('product-search.passenger-details.discount-card-types.privilege-card')
      },
      {
        code: DISCOUNT_CARD_WELFARE_CARD,
        text: _t.message('product-search.passenger-details.discount-card-types.welfare-card')
      }
    ]

    if (this.props.valueAdult === 0 &&
      this.props.valueChild === 0 &&
      this.props.valueStudent > 0
    ) {
      discountCardTypes.push({
        code: DISCOUNT_CARD_STUDENT_CARD,
        text: _t.message('product-search.passenger-details.discount-card-types.student-card')
      })
    }

    return discountCardTypes
  }

  render () {
    const discountCardCode = get(
      this.props.form.fields.discountCard,
      'value.code',
      this.props.form.fields.discountCard.value
    )

    return (
      <react-wrapper>
        {this.props.shortcutSavedSuccessful
          ? <Feedback text={_t.message('edit-shortcut.saved-successful')} status={FEEDBACK_SUCCESS} />
          : null}
        <Form formContext='edit-shortcut'>
          <Form.InputGroup name='shortcut-input'>
            <Form.InputGroup name='product-family'>
              <Form.InputWrapper name='label'>
                <Form.Label
                  name='product-family'
                  htmlFor='product-family'
                />
              </Form.InputWrapper>
              <Form.InputWrapper name='input'>
                <ProductFamilySelect
                  {...this.props.form.fields.productFamily}
                  name='product-family'
                  id={FIELD_ID_PRODUCT_FAMILY}
                  hotkey='focusProductFamily'
                  placeholder={_t.message('edit-shortcut.form.product-family.placeholder')}
                  onChange={this.handleProductFamilySelectChange}
                  autoFocus
                />
              </Form.InputWrapper>
            </Form.InputGroup>
            {this._renderStationSelectorField(this._getPropsOriginField())}
            {this._renderStationSelectorField(this._getPropsDestinationField())}
            <Form.InputGroup name='date-interval'>
              <Form.InputWrapper name='label'>
                <Label htmlFor='date-interval' name='date-interval'>
                  <span dangerouslySetInnerHTML={
                    { __html: _t.message('edit-shortcut.form.date-interval.label') }
                  } />
                </Label>
              </Form.InputWrapper>
              <Form.InputWrapper name='input'>
                <AmountInput
                  {...this.props.form.fields.dateInterval}
                  value={Math.max(this.props.form.fields.dateInterval.value, 0)}
                  name='date-interval'
                  id={FIELD_ID_VALID_FROM}
                  max={31}
                  min={0}
                  hotkey={{
                    increment: 'focusValidFrom',
                    decrement: 'decrementDate'
                  }}
                />
              </Form.InputWrapper>
            </Form.InputGroup>
            <Form.InputGroup name='adults'>
              <Form.InputWrapper name='label'>
                <Form.Label htmlFor='adults' name='adults' />
              </Form.InputWrapper>
              <Form.InputWrapper name='input'>
                <AmountInput
                  id='adults'
                  name='adults'
                  max={this.props.maxPerPassengerType[PASSENGER_TYPE_ADULT]}
                  value={this.props.valueAdult}
                  onChange={this.props.onChangeAdult}
                  hotkey={{
                    increment: 'incrementAdult',
                    decrement: 'decrementAdult'
                  }}
                />
              </Form.InputWrapper>
            </Form.InputGroup>
            <Form.InputGroup name='children'>
              <Form.InputWrapper name='label'>
                <Form.Label htmlFor='children' name='children' />
              </Form.InputWrapper>
              <Form.InputWrapper name='input'>
                <AmountInput
                  id='children'
                  name='children'
                  max={this.props.maxPerPassengerType[PASSENGER_TYPE_CHILD]}
                  value={this.props.valueChild}
                  onChange={this.props.onChangeChild}
                  hotkey={{
                    increment: 'cancel',
                    decrement: 'decrementChild'
                  }}
                />
              </Form.InputWrapper>
            </Form.InputGroup>
            <Form.InputGroup name='students'>
              <Form.InputWrapper name='label'>
                <Form.Label htmlFor='students' name='students' />
              </Form.InputWrapper>
              <Form.InputWrapper name='input'>
                <AmountInput
                  id='students'
                  name='students'
                  max={this.props.maxPerPassengerType[PASSENGER_TYPE_STUDENT]}
                  value={this.props.valueStudent}
                  onChange={this.props.onChangeStudent}
                  hotkey={{
                    increment: 'incrementStudent',
                    decrement: 'decrementStudent'
                  }}
                />
              </Form.InputWrapper>
            </Form.InputGroup>
            <Form.InputGroup name='discount-card'>
              <Form.InputWrapper name='label'>
                <Form.Label
                  name='discount-card'
                  htmlFor='discount-card'
                />
              </Form.InputWrapper>
              <Form.InputWrapper name='input'>
                <DiscountCardSelect
                  name='discount-card'
                  id='discount-card'
                  placeholder={_t.message('product-search.passenger-details.form.discount-card.placeholder')}
                  discountCardTypes={this.discountCardTypes()}
                  value={this.props.form.fields.discountCard.value}
                  onChange={this.props.form.fields.discountCard.onChange}
                />
              </Form.InputWrapper>
            </Form.InputGroup>
          </Form.InputGroup>
          <Form.InputGroup name='shortcut-output'>
            {
              this.props.form.valid && discountCardCode
                ? (
                  <Feedback
                    status={FEEDBACK_INFORMATION}
                    text={_t.message('edit-shortcut.discount-card-feedback')}
                    className='discount-card'
                  />
                )
                : this.props.form.valid
                  ? (
                    <Form.InputWrapper name='product'>
                      {this.props.searchedProducts && !this.props.isLoadingProductsSearch
                        ? (
                          <Form.TextField
                            {...this.props.form.fields.productFilter}
                            name='product-filter'
                            id={FIELD_ID_PRODUCT_FILTER}
                            autoComplete='off'
                            autoFocus
                            disabled={
                              this.props.products.length === 0 && !this.props.form.fields.productFilter.value
                            }
                          />
                        ) : null}
                      {!this.props.searchedProducts || this.props.isLoadingProductsSearch
                        ? (
                          <Button
                            type='button'
                            name='search'
                            className='secondary'
                            hotkey='refresh'
                            onClick={this.props.searchProducts}
                            loading={this.props.isLoadingProductsSearch}
                            disabled={!this.props.form.valid}
                          >
                            <TextLabel>
                              <Icon name='search' className='align-left' />
                              <TextLabel.Text text={_t.message('edit-shortcut.search-products-button')} />
                            </TextLabel>
                          </Button>
                        ) : null}
                    </Form.InputWrapper>
                  ) : (
                    <Feedback
                      status={FEEDBACK_INFORMATION}
                      text={_t.message('edit-shortcut.fill-in-all-fields')}
                      className='product-results'
                    />
                  )
            }
            {
              this.props.products.length > 0 && !discountCardCode
                ? (
                  <div className='matching-products'>
                    <TileSet>
                      {this.props.products.map((product, index) =>
                        <ProductTile
                          key={index}
                          product={product}
                          selected={product.id === this.props.form.fields.product.value}
                          handleSelectProduct={this.props.selectProduct}
                        />
                      )}
                    </TileSet>
                  </div>
                ) : null
            }
            {
              this.props.searchedProducts && !this.props.isLoadingProductsSearch &&
              this.props.products.length === 0
                ? (
                  <Feedback
                    status={FEEDBACK_INFORMATION}
                    text={_t.message('edit-shortcut.no-products-found')}
                    className='product-search-results'
                  />
                ) : null
            }
          </Form.InputGroup>
        </Form>
      </react-wrapper>
    )
  }
}
