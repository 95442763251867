import { createSelector } from 'reselect'
import moment from 'moment'
import { outboundLegsSelector, inboundLegsSelector } from './legs'

export const travelSelector = legsSelector => createSelector(
  [legsSelector],
  legs => {
    const mainLegs = legs && legs.filter(leg => leg.requiredProducts.length > 0)
    if (!mainLegs || mainLegs.length === 0) {
      return null
    }

    const firstLeg = mainLegs[0]
    const lastLeg = mainLegs[mainLegs.length - 1]

    return {
      direction: firstLeg.direction,
      departureDate: firstLeg.departureDate,
      arrivalDate: lastLeg.arrivalDate,
      departureStation: firstLeg.departureStation,
      arrivalStation: lastLeg.arrivalStation,
      duration: moment.duration(lastLeg.arrivalDate.diff(firstLeg.departureDate))
    }
  }
)

export const isInboundBeforeOutboundSelector = createSelector(
  [
    travelSelector(outboundLegsSelector),
    travelSelector(inboundLegsSelector)
  ],
  (outboundTravel, inboundTravel) => Boolean(
    outboundTravel &&
    inboundTravel &&
    inboundTravel.departureDate.isBefore(outboundTravel.arrivalDate)
  )
)
