import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../../elements/text-label/text-label'
import Button from '../../../../elements/button/button'

export default class RetryReceiptButton extends Component {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    onRetry: PropTypes.func,
    loading: PropTypes.bool
  }

  render () {
    return (
      <Button
        name='retry-receipt-printing'
        className='secondary'
        disabled={this.props.disabled}
        onClick={this.props.onRetry}
        loading={this.props.loading}
      >
        <TextLabel name='retry-printing' text={_t.message('finalize-booking-modal.retry-receipt-printing')} />
      </Button>
    )
  }
}
