import { bookingSelector, bookingNumberSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { aftersalesRevert } from 's3p-js-lib/src/redux/actions/api/aftersales/revert'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import { PAYMENT_STATUS_F } from 's3p-js-lib/src/constants'
import { UI_ABORT_AFTERSALES_START } from '../../../types'
import { completedOrProvisionalOrOriginalOrOverviewBookingSelector } from '../../../../selectors/api/booking/booking'
import { hasPendingCashPaymentsSelector } from '../../../../selectors/api/booking/payments'
import { updatePendingPayments } from '../../base/tiles/payment-methods'
import { showReturnPaymentModal, hideReturnPaymentModal } from '../../../containers/base/payment-modal/return-payment-modal'
import {
  CONTAINERS_AFTERSALES_OPERATION,
  CONTAINERS_BASE_ELEMENT_VISIBILITY
} from '../../../../../constants'
import { goToAftersalesBooking } from '../../base/routing'

export const abortAftersales = () => clearState([
  CONTAINERS_AFTERSALES_OPERATION,
  CONTAINERS_BASE_ELEMENT_VISIBILITY,
  'userInput.aftersales.cancellation',
  'api.booking.aftersalesOriginalBooking',
  'userInput.aftersales.waiveFees',
  'userInput.base.fulfillmentMethods'
])
export const abortAftersalesStart = () => ({type: UI_ABORT_AFTERSALES_START})

const _bookingNumberSelector = bookingNumberSelector(completedOrProvisionalOrOriginalOrOverviewBookingSelector)
const _hasPendingCashPaymentsSelector = hasPendingCashPaymentsSelector(bookingSelector)

export const revertAndRedirect = () => async (dispatch, getState) => {
  const state = getState()

  const isAbortOperation = state.containers.aftersales.operation.isAbortOperation
  if (_hasPendingCashPaymentsSelector(state)) {
    await dispatch(updatePendingPayments(PAYMENT_STATUS_F))
    dispatch(hideReturnPaymentModal())
  }

  if (isAbortOperation) {
    if (bookingSelector(state)) {
      await dispatch(aftersalesRevert(_bookingNumberSelector(state)))
    }

    dispatch([
      abortAftersales(),
      goToAftersalesBooking(_bookingNumberSelector(state))
    ])
  }
}

export const revertAftersales = () => (dispatch, getState) => {
  if (_hasPendingCashPaymentsSelector(getState())) {
    dispatch([abortAftersalesStart(), showReturnPaymentModal()])
  } else {
    dispatch([abortAftersalesStart(), revertAndRedirect()])
  }
}
