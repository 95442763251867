import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import HotkeyLabel from '../../elements/hotkey-label/hotkey-label'
import './label.scss'

class Label extends Component {
  static propTypes = {
    name: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string,
    hotkey: PropTypes.string
  }

  render () {
    const {className, name, children, text, hotkey, ...rest} = this.props
    return (
      <label {...rest} className={classNames('text-label', name ? `text-label-${name}` : '', className)}>
        {text ? <span className='text'>{text}</span> : children}
        {hotkey && <HotkeyLabel hotkey={hotkey} />}
      </label>
    )
  }
}

export default Label
