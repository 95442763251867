import React from 'react'
import { Route } from 'react-router'
import containAuth from '../../containers/base/auth'
import JourneySearchLayout from '../../layouts/reservations/journey-search'
import { AGENT_ROLE_OPERATOR } from '../../constants'

export default (
  <Route
    key='reservations-journey-search'
    path='/:lang/reservations/journey-search'
    component={containAuth(JourneySearchLayout, {roles: [AGENT_ROLE_OPERATOR]})}
  />
)
