import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Loader, { TYPE_BLOCKING } from '../../../elements/loader/loader'
import Toolbar from '../../../components/aftersales/booking/toolbar'
import StickyFooter from '../../../elements/sticky-footer/sticky-footer'
import CustomerAgentDetails from '../../../components/aftersales/booking/customer-agent-details'
import DetailsOptions from '../../../containers/aftersales/booking/details-options'
import JourneyOverview from '../../../containers/aftersales/booking/journey-overview'
import FulfillmentPaymentDetails from '../../../components/aftersales/booking/fulfillment-payment-details'
import NotesRelatedVouchers from '../../../components/aftersales/booking/notes-related-vouchers'
import Taskbar from '../../../containers/base/taskbar/taskbar'

export default class Booking extends Component {
  static propTypes = {
    bookingNumber: PropTypes.string.isRequired,
    loading: PropTypes.bool
  }

  render () {
    return (
      <react-wrapper class='react-wrapper'>
        {this.props.loading ? <Loader type={TYPE_BLOCKING} /> : null}
        <Toolbar bookingNumber={this.props.bookingNumber} />
        <DetailsOptions />
        <CustomerAgentDetails />
        <JourneyOverview />
        <FulfillmentPaymentDetails />
        <NotesRelatedVouchers />
        <StickyFooter>
          <Taskbar />
        </StickyFooter>
      </react-wrapper>
    )
  }
}
