import pick from 'lodash/pick'
import {
  API_AGENT_CHANGE_PASSWORD_REQUEST,
  API_AGENT_CHANGE_PASSWORD_SUCCESS,
  API_AGENT_CHANGE_PASSWORD_FAILURE
} from '../../../types'
import Client from '../../../../../api/client'
import ResponseCodes from '../../../../../api/response-codes'
import ensureToken from '../../../../../api/ensure-token'

const agentChangePasswordRequest = (currentPassword, newPassword) => ({type: API_AGENT_CHANGE_PASSWORD_REQUEST, currentPassword, newPassword})
const agentChangePasswordSuccess = response => ({type: API_AGENT_CHANGE_PASSWORD_SUCCESS, response})
const agentChangePasswordFailure = error => ({type: API_AGENT_CHANGE_PASSWORD_FAILURE, error})

const validApiKeys = [
  'username',
  'password',
  'current_password',
  'first_name',
  'last_name',
  'email',
  'language',
  'active',
  'agent_roles',
  'offices',
  'contract_codes',
  'meta_data'
]

export const agentChangePassword = ({currentPassword, newPassword}) => ensureToken(async (token, dispatch, getState) => {
  try {
    const agentInformation = getState().api.user.agent.user
    const agentUserInfo = {
      ...pick(agentInformation, validApiKeys),
      active: true,
      contract_codes: agentInformation.contracts.map(contract => contract.code),
      offices: agentInformation.offices.map(office => office.name),
      username: getState().api.user.user.username,
      current_password: currentPassword,
      password: newPassword
    }

    dispatch(agentChangePasswordRequest(currentPassword, newPassword))
    const response = await Client.updateAgent(token, agentUserInfo)
    dispatch(agentChangePasswordSuccess(response))
  } catch (error) {
    dispatch(agentChangePasswordFailure(error))

    if (ResponseCodes.isSystemError(error)) {
      throw error
    }

    return false
  }
})
