import { createSelector } from 'reselect'

export const priceSummarySelector = createSelector(
  [
    state => state.api.orientation.journeySearch,
    state => state.api.orientation.specification
  ],
  (journeySearch, specification) => {
    if (specification) {
      return {
        passengersCount: specification.passengers.length,
        price: specification.total_price
      }
    } else if (journeySearch) {
      return {
        passengersCount: journeySearch.passengers.length,
        price: 0.0
      }
    }

    return {}
  }
)
