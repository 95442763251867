import { createSelector, createStructuredSelector } from 'reselect'
import memoize from 'lodash/memoize'
import Validator from '../../../../forms/validator'

let formRegistry
const emptyObject = {}

const formFieldSelectorsSelectorCreator = memoize(formName => createSelector(
  [formTypeSelectorCreator(formName)],
  formType => {
    return Object.keys(formType.fields || {}).reduce(
      (fields, fieldName) => {
        fields[fieldName] = formFieldSelectorCreator(formName, fieldName)
        return fields
      }, {}
    )
  }
))

export const baseFormTypeSelectorCreator = memoize(formName => {
  const [baseFormName] = formName.split('.')

  return state => {
    if (!formRegistry) {
      formRegistry = require('../../../../forms/form-registry')
    }

    const formTypeDefinition = formRegistry.getFormType(formName)

    const formType = (typeof formTypeDefinition === 'function' ? formTypeDefinition(state) : formTypeDefinition)
    formType.name = baseFormName
    return formType
  }
})

export const formTypeSelectorCreator = memoize(formName => {
  const [baseFormName, ...subFormNames] = formName.split('.')

  return createSelector(
    [baseFormTypeSelectorCreator(baseFormName)],
    baseFormType => {
      const formType = subFormNames.reduce(
        (formType, subFormName) => {
          if (!('subForms' in formType) || !(subFormName in formType.subForms)) {
            throw new Error(`No form could be found with name: ${formName}`)
          }
          return formType.subForms[subFormName]
        },
        baseFormType
      )
      formType.name = formName
      return formType
    }
  )
})

export const baseFormStateSelectorCreator = memoize(formName => {
  const [baseFormName] = formName.split('.')
  return state => {
    const formState = state.userInput.forms[baseFormName]
    if (!formState) {
      throw new Error(`Form type "${formName}" does not exists in the state, check the form reducers`)
    }
    return formState
  }
})

export const formStateSelectorCreator = memoize(formName => {
  const baseFormStateSelector = baseFormStateSelectorCreator(formName)
  const formNames = formName.split('.')
  formNames.shift()

  return state => formNames.reduce(
    (formState, subFormName) => 'subForms' in formState && subFormName in formState.subForms
      ? formState.subForms[subFormName] : emptyObject,
    baseFormStateSelector(state)
  )
})

// by default, lodash uses the first argument of the memoized function as cache key. In case of form fields, both the
// first and second argument should be used as cache key
// eslint-disable-next-line func-style
function formFieldMemoizeResolver (formName, fieldName) {
  return formName + ':' + fieldName
}

export const formFieldTypeSelectorCreator = memoize(
  (formName, fieldName) => createSelector(
    [formTypeSelectorCreator(formName)],
    formType => {
      if (!(fieldName in formType.fields)) {
        throw new Error(`Form type "${formName}" does not contain a field "${fieldName}"`)
      }
      return formType.fields[fieldName]
    }
  ),
  formFieldMemoizeResolver
)

export const formFieldStateSelectorCreator = memoize(
  (formName, fieldName) => createSelector(
    [formStateSelectorCreator(formName)],
    formState => (formState.fields && formState.fields[fieldName]) || emptyObject
  ),
  formFieldMemoizeResolver
)

const getFormValues = formState => formState && formState.fields
  ? Object.keys(formState.fields).reduce((fields, fieldName) => {
    fields[fieldName] = formState.fields[fieldName].value

    return fields
  }, {})
  : {}

export const formFieldValuesSelectorCreator = memoize(formName => createSelector(
  [formStateSelectorCreator(formName)],
  getFormValues
))

export const subFormFieldValuesSelectorCreator = memoize(formName => createSelector(
  [formStateSelectorCreator(formName)],
  formState => formState && formState.subForms
    ? Object.keys(formState.subForms).reduce((subForms, subFormName) => {
      subForms[subFormName] = getFormValues(formState.subForms[subFormName])
      return subForms
    }, {})
    : {}
))

export const formFieldValueSelectorCreator = memoize(
  (formName, fieldName) => createSelector(
    [formFieldStateSelectorCreator(formName, fieldName)],
    formFieldState => formFieldState ? formFieldState.value : undefined
  ),
  formFieldMemoizeResolver
)

export const formFieldNormalizersSelectorCreator = memoize(
  (formName, fieldName) => createSelector(
    [formFieldTypeSelectorCreator(formName, fieldName)],
    fieldType => fieldType.normalizers || []
  ),
  formFieldMemoizeResolver
)

export const formFieldValidatorsSelectorCreator = memoize(
  (formName, fieldName) => createSelector(
    [formFieldTypeSelectorCreator(formName, fieldName)],
    fieldType => fieldType.validators || []
  ),
  formFieldMemoizeResolver
)

export const formFieldContextSelectorCreator = memoize(
  (formName, fieldName) => createSelector(
    [
      formFieldValuesSelectorCreator(formName),
      formStateSelectorCreator(formName)
    ],
    (values, formState) => ({fieldName, values, formState})
  ),
  formFieldMemoizeResolver
)

export const formFieldErrorSelectorCreator = memoize(
  (formName, fieldName) => createSelector(
    [
      formFieldContextSelectorCreator(formName, fieldName),
      formFieldValidatorsSelectorCreator(formName, fieldName)
    ],
    (context, validators) => validators.reduce(
      (error, validator) => error || validator(context.values[fieldName], context),
      null
    )
  ),
  formFieldMemoizeResolver
)

export const formFieldSelectorCreator = memoize((formName, fieldName) => createSelector(
  [
    formFieldTypeSelectorCreator(formName, fieldName),
    formFieldStateSelectorCreator(formName, fieldName),
    formFieldValueSelectorCreator(formName, fieldName),
    formFieldErrorSelectorCreator(formName, fieldName),
    formFieldValidatorsSelectorCreator(formName, fieldName)
  ],
  (fieldType, fieldState, fieldValue, fieldError, fieldValidators) => ({
    ...fieldState,
    disabled: fieldType.disabled || false,
    required: fieldValidators.includes(Validator.isRequired),
    error: !fieldType.disabled ? fieldError : null,
    checked: fieldValue === true || fieldValue === 1,
    valid: fieldType.disabled || fieldError === null,
    data: fieldType.data || [],
    active: Boolean(fieldState.active),
    touched: Boolean(fieldState.touched),
    dirty: Boolean(fieldState.dirty)
  })
),
formFieldMemoizeResolver
)

export const formSelectorCreator = memoize(formName => createSelector(
  [
    state => state,
    formStateSelectorCreator(formName),
    formTypeSelectorCreator(formName)
  ],
  (state, formState, formType) => {
    const fields = Object.keys(formType.fields || {}).reduce(
      (fields, fieldName) => {
        fields[fieldName] = formFieldSelectorCreator(formName, fieldName)(state)
        return fields
      },
      {}
    )

    const subForms = Object.keys(formType.subForms || {}).reduce(
      (subForms, subFormName) => {
        subForms[subFormName] = formSelectorCreator(`${formName}.${subFormName}`)(state)
        return subForms
      },
      {}
    )

    return {
      name: formType.name,
      ...formState,
      valid: Object.keys(fields).every(fieldName => fields[fieldName].valid) &&
      Object.keys(subForms).every(subFormName => subForms[subFormName].valid),
      dirty: Object.keys(fields).some(fieldName => fields[fieldName].dirty) ||
      Object.keys(subForms).some(subFormName => subForms[subFormName].dirty),
      fields,
      subForms
    }
  }
))

export const formFieldsSelectorCreator = memoize(formName => {
  const _formFieldsSelector = formFieldSelectorsSelectorCreator(formName)
  let lastResult = {}

  return state => {
    const fieldsSelectors = _formFieldsSelector(state)
    const result = createStructuredSelector(fieldsSelectors)(state)

    if (Object.keys(lastResult).length !== Object.keys(result).length ||
      Object.keys(result).some(key => lastResult[key] !== result[key])
    ) {
      lastResult = result
    }

    return lastResult
  }
})

export const formIsValidSelectorCreator = memoize(formName => {
  const formFieldsValidSelector = formFieldsValidSelectorCreator(formName)
  const subFormsFieldsValidSelectorsSelector = subFormsFieldsValidSelectorsSelectorCreator(formName)

  return state => {
    const selectors = subFormsFieldsValidSelectorsSelector(state).concat([formFieldsValidSelector])
    return selectors.every(selector => selector(state))
  }
})

export const subFormNamesSelectorCreator = memoize(formName => createSelector(
  [formTypeSelectorCreator(formName)],
  formType => Object.keys(formType.subForms || {}).map(subFormName => `${formName}.${subFormName}`)
))

const subFormsFieldsValidSelectorsSelectorCreator = memoize(formName => createSelector(
  [subFormNamesSelectorCreator(formName)],
  subFromNames => subFromNames.map(subFormName => formFieldsValidSelectorCreator(subFormName))
))

const formFieldsValidSelectorCreator = memoize(formName => createSelector(
  [formFieldsSelectorCreator(formName)],
  fields => Object.keys(fields).every(fieldName => fields[fieldName].valid)
))
