import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../../elements/text-label/text-label'
import TileSet from '../../../../elements/tile-set/tile-set'

export default class Passenger extends Component {
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    type: PropTypes.string.isRequired,
    seat: PropTypes.shape({
      carriageNumber: PropTypes.string.isRequired,
      seatNumber: PropTypes.string.isRequired
    }),
    handleSelect: PropTypes.func.isRequired
  }

  render () {
    return (
      <TileSet.Tile
        onClick={this.props.handleSelect}
        selected={this.props.selected}
      >
        <TextLabel
          name='passenger-number'
          text={_t.message('seat-selector.passenger-manifest.passenger-number', {number: this.props.number})}
        />
        <TextLabel
          name='passenger-type'
          text={_t.message('seat-selector.passenger-manifest.passenger-type', {type: this.props.type})}
        />
        {this.props.seat ? <TextLabel
          name='passenger-carriage-seat-number'
          text={_t.message('seat-selector.passenger-manifest.passenger-seat', this.props.seat)}
        /> : null}
        {!this.props.seat && this.props.selected ? <TextLabel
          name='select-seat'
          text={_t.message('seat-selector.passenger-manifest.select-seat')}
        /> : null}
        {!this.props.seat && !this.props.selected ? <TextLabel
          name='no-seat-selected'
          text={_t.message('seat-selector.passenger-manifest.no-seat-selected')}
        /> : null}
      </TileSet.Tile>
    )
  }
}
