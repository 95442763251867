import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import ComponentContainer from '../../../elements/component-container/component-container'
import Sprite from '../../../elements/sprite/sprite'
import MainMenuItem from './main-menu-item'
import AftersalesMenu from './aftersales-menu'
import MaintenanceMenu from './maintenance-menu'
import './main-menu.scss'

export default class MainMenu extends Component {
  static propTypes = {
    handleClickTickets: PropTypes.func,
    handleClickReservations: PropTypes.func,
    handleClickBookings: PropTypes.func,
    handleClickReadTicket: PropTypes.func,
    handleClickVoucherToCash: PropTypes.func,
    handleClickLeap: PropTypes.func,
    handleClickShift: PropTypes.func,
    handleClickSetDarkness: PropTypes.func,
    statusTickets: PropTypes.string.isRequired,
    statusReservations: PropTypes.string.isRequired,
    statusAftersales: PropTypes.string.isRequired,
    statusBookings: PropTypes.string.isRequired,
    statusReadTicket: PropTypes.string.isRequired,
    statusVoucherToCash: PropTypes.string.isRequired,
    statusLeap: PropTypes.string.isRequired,
    statusShift: PropTypes.string.isRequired,
    statusMaintenance: PropTypes.string.isRequired,
    statusTestCfd: PropTypes.string.isRequired,
    statusTestThermalPrinter: PropTypes.string.isRequired,
    statusSetDarkness: PropTypes.string.isRequired,
    handleClickTestCfd: PropTypes.func.isRequired,
    handleClickTestThermalPrinter: PropTypes.func.isRequired,
    isAgentOperator: PropTypes.bool
  }

  render () {
    return (
      <ComponentContainer name='main-menu'>
        <div className='logo'>
          <Sprite name='logo' tabIndex='-1' />
        </div>
        <div className='main-menu-navigation'>
          <nav>
            <ul>
              <MainMenuItem
                status={this.props.statusTickets}
                onClick={this.props.handleClickTickets}
                icon='tickets'
                text={_t.message('main-menu.tickets')}
                hotkey='menuOpenTickets'
              />
              {this.props.isAgentOperator
                ? [
                  <MainMenuItem
                    key='reservations'
                    status={this.props.statusReservations}
                    onClick={this.props.handleClickReservations}
                    icon='calendar'
                    text={_t.message('main-menu.reservations')}
                    hotkey='menuReservations'
                  />,
                  <AftersalesMenu
                    key='aftersales'
                    status={this.props.statusAftersales}
                    statusBookings={this.props.statusBookings}
                    statusReadTicket={this.props.statusReadTicket}
                    statusVoucherToCash={this.props.statusVoucherToCash}
                    handleClickBookings={this.props.handleClickBookings}
                    handleClickReadTicket={this.props.handleClickReadTicket}
                    handleClickVoucherToCash={this.props.handleClickVoucherToCash}
                  />
                ]
                : null
              }
              <MaintenanceMenu
                status={this.props.statusMaintenance}
                statusTestCfd={this.props.statusTestCfd}
                statusTestThermalPrinter={this.props.statusTestThermalPrinter}
                statusSetDarkness={this.props.statusSetDarkness}
                handleClickTestCfd={this.props.handleClickTestCfd}
                handleClickTestThermalPrinter={this.props.handleClickTestThermalPrinter}
                handleClickSetDarkness={this.props.handleClickSetDarkness}
              />
              {this.props.isAgentOperator
                ? (
                  <MainMenuItem
                    status={this.props.statusLeap}
                    onClick={this.props.handleClickLeap}
                    icon='leap'
                    text={_t.message('main-menu.leap')}
                    hotkey='menuLeap'
                  />
                )
                : null
              }
              <MainMenuItem
                status={this.props.statusShift}
                onClick={this.props.handleClickShift}
                icon='loader'
                text={_t.message('main-menu.shift')}
                hotkey='menuShift'
              />
            </ul>
          </nav>
        </div>
      </ComponentContainer>
    )
  }
}
