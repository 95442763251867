import { leapDataSelector } from '../../../selectors/machine/leap'
import { reverseLeap } from '../../machine/leap'
import { LEAP_TIMEOUT, LEAP_ERROR_MESSAGE_REVERSE_TRANSACTION_FAILED } from '../../../../constants'
import { readLeapStepsBeforeActionSelector } from '../../../selectors/containers/base/leap'
import { UI_LEAP_REVERSE_END, UI_LEAP_REVERSE_START } from '../../types'
import { addNotesAgentShift } from 's3p-js-lib/src/redux/actions/api/user/agent/shift'

const leapReverseStart = () => ({type: UI_LEAP_REVERSE_START})
const leapReverseEnd = error => ({type: UI_LEAP_REVERSE_END, error})

export const leapReverse = () => async (dispatch, getState) => {
  dispatch(leapReverseStart())
  const state = getState()
  let errorMessage = readLeapStepsBeforeActionSelector(state)
  let shiftNotes
  if (!errorMessage) {
    const data = leapDataSelector(state)
    const result = await dispatch(reverseLeap(LEAP_TIMEOUT, data.cardIdentifier))
    if (result) {
      shiftNotes = createShiftNotesForReverseLeapResultSuccess(getState())
    } else {
      errorMessage = LEAP_ERROR_MESSAGE_REVERSE_TRANSACTION_FAILED
      shiftNotes = createShiftNotesForReverseLeapResultError(errorMessage)
    }
  } else {
    shiftNotes = createShiftNotesForReverseLeapResultError(errorMessage)
  }
  await dispatch(addNotesAgentShift(shiftNotes))
  dispatch(leapReverseEnd(errorMessage))
}

export const createShiftNotesForReverseLeapResultSuccess = state => {
  const notes = ['LEAP_reverse_transaction: OK']
  if (state && state.machine && state.machine.leap && state.machine.leap.reverse && state.machine.leap.reverse.transaction_1) {
    notes.push(`LEAP_reverse_transaction: ${state.machine.leap.reverse.transaction_1}`)
  }
  return notes
}

export const createShiftNotesForReverseLeapResultError = errorMessage => {
  const notes = ['LEAP_reverse_transaction: ERROR']
  if (errorMessage) {
    notes.push(`LEAP_reverse_transaction: ${errorMessage}`)
  }
  return notes
}
