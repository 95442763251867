import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'
import _t from 's3p-js-lib/src/translate'
import Modal from '../../elements/modal/modal'
import OnlinePoller from './offline/poller'
import NextTry from './offline/next-try'
import {CMS_BLOCK_OFFLINE} from '../../constants'
import CmsBlockOffline from './cms/cms-block-offline'

const noop = () => null

class Offline extends Component {
  static propTypes = {
    isOffline: PropTypes.bool,
    isRetry: PropTypes.bool,
    nextTry: PropTypes.any,
    attempt: PropTypes.number,
    feedbackMessage: PropTypes.string
  }

  render () {
    return this.props.isOffline
      ? (
        <Modal
          name='OFFLINE'
          header={<Modal.Title title={_t.message(this.props.feedbackMessage)} />}
          onHideElement={noop}
        >
          <CmsBlockOffline name={CMS_BLOCK_OFFLINE} />
          {this.props.isRetry
            ? <NextTry nextTry={this.props.nextTry} attempt={this.props.attempt} />
            : <OnlinePoller />
          }
        </Modal>
      ) : null
  }
}

const mapStateToProps = state => ({
  isOffline: state.containers.base.offline.isOffline,
  isRetry: state.containers.base.offline.isRetry,
  nextTry: state.containers.base.offline.nextTry,
  attempt: state.containers.base.offline.attempt,
  feedbackMessage: state.containers.base.offline.feedbackMessage
})

export default connect(mapStateToProps)(Offline)
