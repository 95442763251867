import { CLEAR_STATE, USER_INPUT_WAIVE_FEES_SELECT, USER_INPUT_WAIVE_FEES_DESELECT } from '../../../actions/types'

export const initialState = false

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_INPUT_WAIVE_FEES_SELECT:
      return true
    case USER_INPUT_WAIVE_FEES_DESELECT:
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes('user-input.aftersales.waive-fees') ||
      action.namespaces.includes('userInput.aftersales.waiveFees') ? initialState : state
    default:
      return state
  }
}
