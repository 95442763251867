import { createSelector } from 'reselect'
import { carriagesSelector } from '../../api/base/seat-selector/carriage-layouts'
import { seatSelectionOptionsSelector as orientationSeatSelectionOptionsSelector } from '../../api/orientation/seat-selection-options'
import { seatSelectionOptionsSelector as aftersalesSeatSelectionOptionsSelector } from '../aftersales/change-seats/seat-selection-options'
import { passengersSelector as orientationPassengersSelector } from '../../api/orientation/offer/passengers'
import {withoutCancelledSelector, passengersSelector as aftersalesPassengersSelector} from '../../api/booking/passengers'
import { bookingSelector } from '../../api/aftersales/booking'
import {
  inventoryClassBySegmentIdAndPassengerIdSelector as offerInventoryClassBySegmentIdAndPassengerIdSelector
} from 's3p-js-lib/src/redux/selectors/api/orientation/offer'
import {
  inventoryClassBySegmentIdAndPassengerIdSelector as bookingInventoryClassBySegmentIdAndPassengerIdSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'

export const selectedPassengerIdSelector = state => state.userInput.base.seatSelector.selectedPassengerId

export const selectedCarriageNumberSelector = state => state.userInput.base.seatSelector.selectedCarriageNumber

export const selectedSeatsSelector = state => state.userInput.base.seatSelector.selectedSeats

export const selectedSegmentIdSelector = state => state.userInput.base.seatSelector.selectedSegmentId

const seatSelectionOptionsSelector = (state, ownProps) => ownProps && ownProps.aftersales
  ? aftersalesSeatSelectionOptionsSelector(state)
  : orientationSeatSelectionOptionsSelector(state)

export const seatSelectionOptionSelector = createSelector(
  [
    seatSelectionOptionsSelector,
    selectedSegmentIdSelector
  ],
  (options, segmentId) => options && options.find(option => option.leg.id === segmentId)
)

export const selectedCarriageSelector = createSelector(
  [
    carriagesSelector,
    selectedCarriageNumberSelector
  ],
  (carriages, selectedCarriageNumber) => carriages.find(
    ({carriageNumber}) => carriageNumber === selectedCarriageNumber
  )
)

export const carriageSelector = () => createSelector(
  [
    carriagesSelector,
    selectedSegmentIdSelector,
    selectedPassengerIdSelector,
    selectedCarriageNumberSelector,
    (state, ownProps) => ({state, ownProps})
  ],
  (carriages, selectedSegmentId, selectedPassengerId, selectedCarriageNumber, {state, ownProps}) => {
    const currentCarriageNumber = ownProps.carriageNumber
    const carriage = carriages.find(({carriageNumber}) => carriageNumber === currentCarriageNumber)
    if (!carriage) {
      return null
    }
    const passengerInventoryClass = ownProps.aftersales
      ? bookingInventoryClassBySegmentIdAndPassengerIdSelector(bookingSelector, selectedSegmentId, selectedPassengerId)(state)
      : offerInventoryClassBySegmentIdAndPassengerIdSelector(selectedSegmentId, selectedPassengerId)(state)

    const selected = selectedCarriageNumber === carriage.carriageNumber
    const availableSeats = carriage.seats.filter(
      seat => seat.available &&
        !seat.blockedPassengers.includes(selectedPassengerId) &&
        passengerInventoryClass === seat.inventoryClass
    ).length

    return {
      ...carriage,
      selected,
      available: !carriage.blocked && availableSeats > 0,
      availableSeats
    }
  }
)

export const seatsSelector = state => {
  const carriage = selectedCarriageSelector(state)
  return carriage ? carriage.seats : []
}

export const templateSelector = state => {
  const carriage = selectedCarriageSelector(state)
  return carriage && carriage.template
}

export const enrichedSelectedSeatsSelector = createSelector(
  [
    selectedSeatsSelector,
    seatSelectionOptionSelector
  ],
  (selectedSeats, seatSelectionOption) => {
    const passengerIds = selectedSeats ? selectedSeats.map(({passengerId}) => passengerId) : []
    const addSelectedSeats = seatSelectionOption && seatSelectionOption.selectedSeats.filter(
      selectedSeat => !passengerIds.includes(selectedSeat.passengerId)
    )

    if (addSelectedSeats && addSelectedSeats.length > 0) {
      selectedSeats = selectedSeats.concat(addSelectedSeats)
    }

    return selectedSeats
  }
)

export const seatSelector = () => createSelector(
  [
    seatsSelector,
    selectedSegmentIdSelector,
    selectedPassengerIdSelector,
    enrichedSelectedSeatsSelector,
    selectedCarriageNumberSelector,
    (state, ownProps) => ({state, ownProps})
  ],
  (
    seats,
    selectedSegmentId,
    selectedPassengerId,
    selectedSeats,
    selectedCarriageNumber,
    {state, ownProps}
  ) => {
    const seatNumber = ownProps.seatNumber
    const seat = seats.find(seat => seat.seatNumber === seatNumber)
    if (!seat) {
      return null
    }

    const selected = selectedSeats.some(selectedSeat =>
      selectedSeat.carriageNumber === selectedCarriageNumber &&
      selectedSeat.seatNumber === seatNumber
    )
    const passengerInventoryClass = ownProps.aftersales
      ? bookingInventoryClassBySegmentIdAndPassengerIdSelector(bookingSelector, selectedSegmentId, selectedPassengerId)(state)
      : offerInventoryClassBySegmentIdAndPassengerIdSelector(selectedSegmentId, selectedPassengerId)(state)

    return {
      ...seat,
      selected,
      selectedByPassenger: selected && selectedSeats.some(selectedSeat =>
        selectedSeat.carriageNumber === selectedCarriageNumber &&
        selectedSeat.seatNumber === seatNumber &&
        selectedSeat.passengerId === selectedPassengerId
      ),
      available: !selected &&
        seat.available &&
        !seat.blockedPassengers.includes(selectedPassengerId) &&
        passengerInventoryClass === seat.inventoryClass
    }
  }
)

const _aftersalesPassengersSelector = withoutCancelledSelector(aftersalesPassengersSelector(bookingSelector))

export const passengersSelector = (state, ownProps) => ownProps && ownProps.aftersales
  ? _aftersalesPassengersSelector(state)
  : orientationPassengersSelector(state)

const basePassengerSelector = passengerIdSelector =>
  createSelector(
    [
      passengersSelector,
      selectedPassengerIdSelector,
      enrichedSelectedSeatsSelector,
      passengerIdSelector
    ],
    (passengers, selectedPassengerId, selectedSeats, passengerId) => {
      const passenger = passengers && passengers.find(passenger => passenger.id === passengerId)
      return passenger && {
        ...passenger,
        selected: passenger.id === selectedPassengerId,
        seat: selectedSeats && selectedSeats.find(selectedSeat => selectedSeat.passengerId === passenger.id)
      }
    }
  )

export const passengerSelector = () => basePassengerSelector((state, ownProps) => ownProps.id)
export const selectedPassengerSelector = basePassengerSelector(selectedPassengerIdSelector)

export const isSeatSelectionValidSelector = state =>
  enrichedSelectedSeatsSelector(state).length === orientationPassengersSelector(state).length

export const hasPendingSeatSelectionSelector = state => selectedSeatsSelector(state).length > 0
