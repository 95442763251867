import { createSelector } from 'reselect'
import { clearLastApiErrors } from 's3p-js-lib/src/redux/actions/api/base/last-api-errors'
import { updatePayments as baseUpdatePayments } from 's3p-js-lib/src/redux/actions/api/v2/payment/update-payments'
import _t from 's3p-js-lib/src/translate'
import { formFieldValueSelectorCreator } from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import { currentPathnameSelector } from '../../../../selectors/containers/base/routing'
import { resetReservations } from '../../reservations/finalize-booking'
import { resetTickets } from '../../tickets/products'
import { displayNextCustomer } from '../finalize-booking/next-customer'
import {
  hidePaymentModal,
  pendingPaymentOrConfirmBooking,
  showFinalizeBookingModal
} from '../tiles/payment-methods'
import {
  startPinPayment,
  completePinPayment
} from '../../../machine/pin-payment'
import { sendMachineDisplayLines } from '../../../machine/display'
import { sendMachineReceiptPrint } from '../../../machine/receipt-printer'
import { displayFormatAmount } from '../../../../../misc/utils'
import {
  createReceipt,
  createSignatureReceipt
} from '../../../../../misc/receipt-template-parser'
import {
  UI_PED_STATUS,
  UI_PRINTING_PED_RECEIPT_ERROR,
  UI_PRINTING_PED_RECEIPT_SUCCESS,
  UI_PRINTING_PED_FAILED_RECEIPT_ERROR,
  UI_ADD_PED_PAYMENT
} from '../../../types'
import { paymentResultSelector } from '../../../../selectors/machine/pin-payment'
import {
  PAYMENT_STATUS_F,
  PAYMENT_STATUS_P,
  PAYMENT_STATUS_S
} from 's3p-js-lib/src/constants'
import {
  PAYMENT_PROVIDER_NONE,
  PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD,
  CURRENCY_EURO,
  FORM_CREDIT_DEBIT,
  PED_STATUS_AWAITING_PAYMENT,
  PED_STATUS_SUCCESS,
  PED_STATUS_AUTHORIZATION_REQUIRED,
  PED_STATUS_DECLINED,
  PED_STATUS_FAILURE,
  PED_STATUS_NO_RESPONSE,
  PED_RESULT_CODE_APPROVED_ONLINE,
  PED_RESULT_CODE_APPROVED_OFFLINE,
  PED_RESULT_CODE_APPROVED_MANUAL,
  PED_RESULT_CODE_VOICE_AUTHORIZATION,
  PED_RESULT_CODE_SIGNATURE_VERIFICATION,
  PED_RESULT_CODE_FALLBACK_CONFIRMATION,
  DOCUMENT_TEMPLATE_NAME_PED_CUSTOMER_RECEIPT,
  DOCUMENT_TEMPLATE_NAME_PED_MERCHANT_RECEIPT,
  DOCUMENT_TEMPLATE_NAME_PED_SIGNATURE_RECEIPT,
  DOCUMENT_TEMPLATE_NAME_PED_FAILED_CUSTOMER_RECEIPT,
  DOCUMENT_TEMPLATE_NAME_PED_FAILED_MERCHANT_RECEIPT,
  PED_VERIFICATION_TYPE_SIGNATURE_VERIFIED,
  PED_VERIFICATION_TYPE_SIGNATURE_AND_PIN_VERIFIED,
  SIDEBAR_CART
} from '../../../../../constants'
import { machineStationSelector } from '../../../../selectors/containers/base/stations'
import {
  completedOrOverviewBookingSelector,
  provisionalOrCompletedBookingSelector
} from '../../../../selectors/api/booking/booking'
import { additionalDetailsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/additional-details'
import { paymentsWithoutFailedSelector } from '../../../../selectors/api/booking/payments'
import {
  addAdditionalDetails,
  updateAdditionalDetails
} from 's3p-js-lib/src/redux/actions/api/v2/additional-details'
import {
  additionalDetailsBookingSelector,
  bookingNumberSelector,
  bookingSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { addPayments as baseAddPayments } from 's3p-js-lib/src/redux/actions/api/v2/payment/add-payments'
import { showElement } from '../visible-element'
import {handleTicketFulfillmentApiError} from '../finalize-booking/error'

export const paymentApproved = [
  PED_RESULT_CODE_APPROVED_ONLINE,
  PED_RESULT_CODE_APPROVED_OFFLINE,
  PED_RESULT_CODE_APPROVED_MANUAL
]
const paymentAuthorization = [
  PED_RESULT_CODE_VOICE_AUTHORIZATION,
  PED_RESULT_CODE_SIGNATURE_VERIFICATION,
  PED_RESULT_CODE_FALLBACK_CONFIRMATION
]
const printSignature = [
  PED_VERIFICATION_TYPE_SIGNATURE_VERIFIED,
  PED_VERIFICATION_TYPE_SIGNATURE_AND_PIN_VERIFIED
]

export const pedStatus = status => ({type: UI_PED_STATUS, status})
export const printPedReceiptError = () => ({type: UI_PRINTING_PED_RECEIPT_ERROR})
export const printPedFailedReceiptError = () => ({type: UI_PRINTING_PED_FAILED_RECEIPT_ERROR})
const printPedReceiptSuccess = () => ({type: UI_PRINTING_PED_RECEIPT_SUCCESS})
export const addPedPayment = () => ({type: UI_ADD_PED_PAYMENT})

const _additionalDetailsSelector = additionalDetailsSelector(completedOrOverviewBookingSelector)
const _additionalDetailsAdditionalDetailsBookingSelector = additionalDetailsSelector(additionalDetailsBookingSelector)
const _paymentsWithoutFailedSelector = paymentsWithoutFailedSelector(provisionalOrCompletedBookingSelector)
const _bookingNumberSelector = bookingNumberSelector(provisionalOrCompletedBookingSelector)
const _additionalDetailsBookingNumberSelector = bookingNumberSelector(additionalDetailsBookingSelector)

const pendingPedPaymentSelector = createSelector(
  state => state.containers.base.tiles.paymentMethods.amountToBePaid,
  _paymentsWithoutFailedSelector,
  (amount, payments) => payments.find(payment =>
    payment.amount === amount &&
    payment.currency === CURRENCY_EURO &&
    payment.method === PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD &&
    payment.paymentStatus === PAYMENT_STATUS_P
  )
)

const updatePedPaymentBooking = status => (dispatch, getState) => {
  const transactionId = paymentResultSelector(getState()).transactionId
  const pendingPayment = pendingPedPaymentSelector(getState()) || {}

  return dispatch(baseUpdatePayments(
    [{
      ref: pendingPayment.refId,
      externalReference: transactionId,
      status
    }],
    bookingNumberSelector(bookingSelector)(getState())
  ))
}

const validateResponse = (response, status) => (dispatch, getState) => {
  if (!response) {
    dispatch(pedStatus(PED_STATUS_NO_RESPONSE))
    return
  }
  const state = getState()
  const paymentResult = paymentResultSelector(state)
  if (paymentApproved.includes(paymentResult.result)) {
    dispatch(pedStatus(status))
  } else if (paymentAuthorization.includes(paymentResult.result)) {
    dispatch(pedStatus(PED_STATUS_AUTHORIZATION_REQUIRED))

    if (paymentResult.result === PED_RESULT_CODE_SIGNATURE_VERIFICATION) {
      dispatch(printSignatureReceipt())
    }
  } else {
    dispatch([pedStatus(PED_STATUS_FAILURE), updatePedPaymentBooking(PAYMENT_STATUS_F), printPedFailedReceipt()])
  }
}

export const startPedPayment = () => async (dispatch, getState) => {
  dispatch(pedStatus(PED_STATUS_AWAITING_PAYMENT))

  let paymentResponse = true
  if (!pendingPedPaymentSelector(getState())) { // avoid duplicate pending payments
    paymentResponse = await dispatch(baseAddPayments(
      [{
        amount: getState().containers.base.tiles.paymentMethods.amountToBePaid,
        currency: CURRENCY_EURO,
        method: PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD,
        status: PAYMENT_STATUS_P,
        provider: PAYMENT_PROVIDER_NONE
      }],
      bookingNumberSelector(bookingSelector)(getState())
    ))
  }

  if (paymentResponse) {
    const response = await dispatch(startPinPayment({
      TransactionID: '',
      Value: Math.round(getState().containers.base.tiles.paymentMethods.amountToBePaid * 100)
    }))

    dispatch(validateResponse(response, PED_STATUS_SUCCESS))
    return true
  }

  dispatch(pedStatus(PED_STATUS_FAILURE))
  return false
}

const completePedPayment = valid => async (dispatch, getState) => {
  const paymentResult = paymentResultSelector(getState())
  const data = {
    'TransactionID': paymentResult.transactionId,
    'Valid': valid.toString()
  }
  if (valid && paymentResult.result === PED_RESULT_CODE_VOICE_AUTHORIZATION) {
    data.AuthorisationCode = formFieldValueSelectorCreator(FORM_CREDIT_DEBIT, 'authorizationCode')(getState())
  }
  const response = await dispatch(completePinPayment(data))

  dispatch(validateResponse(response, valid ? PED_STATUS_SUCCESS : PED_STATUS_DECLINED))
}

export const validatePedPayment = () => async dispatch => {
  await dispatch(completePedPayment(true))
}

export const declinePedPayment = () => async dispatch => {
  await dispatch(completePedPayment(false))
}

export const hideCreditDebitModal = () => async (dispatch, getState) => {
  if (getState().containers.base.paymentModal.creditDebit.status === PED_STATUS_AUTHORIZATION_REQUIRED) {
    await dispatch(declinePedPayment())
  }

  let hideModal = true
  if (pendingPedPaymentSelector(getState())) {
    hideModal = await dispatch(updatePedPaymentBooking(PAYMENT_STATUS_F))
  }
  hideModal && dispatch(hidePaymentModal())
}

const finalizePaymentBooking = () => async (dispatch, getState) => {
  const transactionId = paymentResultSelector(getState()).transactionId

  if (await dispatch(updatePedPaymentBooking(PAYMENT_STATUS_S))) {
    const isTicketsFlow = currentPathnameSelector(getState()).indexOf('/tickets') !== -1
    isTicketsFlow && dispatch(showElement(SIDEBAR_CART))

    const response = await dispatch(pendingPaymentOrConfirmBooking())
    if (response) {
      await dispatch(persistAdditionalDetails(transactionId))
    }

    return response
  }
  return false
}

const persistAdditionalDetails = transactionId => async (dispatch, getState) => {
  const state = getState()
  const pedPayment = _paymentsWithoutFailedSelector(state).find(payment => payment.externalReference === transactionId)
  const paymentRefId = pedPayment && pedPayment.refId
  const paymentId = pedPayment && pedPayment.id
  const bookingNumber = _bookingNumberSelector(state)
  const additionalDetailsBookingNumber = _additionalDetailsBookingNumberSelector(state)
  const newAdditionalDetails = []
  const updatedAdditionalDetails = []
  const processAdditionalDetail = (paymentRefId, key, value) => {
    if (value) {
      const additionalDetailsBooking = additionalDetailsBookingNumber && additionalDetailsBookingNumber === bookingNumber
        ? _additionalDetailsAdditionalDetailsBookingSelector(state)
        : _additionalDetailsSelector(state)
      const additionalDetail = additionalDetailsBooking.find(additionalDetail =>
        additionalDetail.paymentRef === paymentId &&
        additionalDetail.key === key
      )
      const additionalDetails = additionalDetail ? updatedAdditionalDetails : newAdditionalDetails
      additionalDetails.push({
        paymentRefId,
        key,
        value: value.toString()
      })
    }
  }
  const paymentResult = paymentResultSelector(state)
  processAdditionalDetail(paymentRefId, 'mid', paymentResult.merchantId)
  processAdditionalDetail(paymentRefId, 'tid', paymentResult.terminalId)

  if (updatedAdditionalDetails.length) {
    await dispatch(updateAdditionalDetails(bookingNumber, updatedAdditionalDetails))
  }
  if (newAdditionalDetails.length) {
    await dispatch(addAdditionalDetails(bookingNumber, newAdditionalDetails))
  }
}

export const addCreditDebitPayment = () => async (dispatch, getState) => {
  dispatch(sendMachineDisplayLines([
    displayFormatAmount(
      _t.message('machine.customer-display.lines.payment-display.credit-card.upper'),
      getState().containers.base.tiles.paymentMethods.amountToBePaid
    ),
    displayFormatAmount(_t.message('machine.customer-display.lines.payment-display.credit-card.lower'), 0.0)
  ]))

  await dispatch(printReceipts())
  dispatch(addPedPayment())
  if (await dispatch(finalizePaymentBooking())) {
    dispatch(showFinalizeBookingModal())
  }
}

export const abandonOperation = () => async dispatch => {
  try {
    await dispatch(handleTicketFulfillmentApiError('Operation was abandoned.'))
    dispatch([
      resetTickets(),
      resetReservations(),
      hidePaymentModal(),
      displayNextCustomer()
    ])
  } finally {
    // ignore errors
  }
  dispatch(clearLastApiErrors(['addPayments', 'updatePayments']))
}

export const printSignatureReceipt = () => async dispatch => {
  dispatch(printReceipt(DOCUMENT_TEMPLATE_NAME_PED_SIGNATURE_RECEIPT, createSignatureReceipt))
}

export const printPedFailedReceipt = () => async dispatch => {
  await dispatch(printReceipt(DOCUMENT_TEMPLATE_NAME_PED_FAILED_CUSTOMER_RECEIPT))
  dispatch(printReceipt(DOCUMENT_TEMPLATE_NAME_PED_FAILED_MERCHANT_RECEIPT))
}

const printReceipt = (templateName, parser = createReceipt) => async (dispatch, getState) => {
  const state = getState()
  const amount = state.containers.base.tiles.paymentMethods.amountToBePaid
  const paymentResult = paymentResultSelector(state)

  try {
    return await dispatch(sendMachineReceiptPrint(parser(
      templateName,
      paymentResult,
      amount,
      machineStationSelector(state),
      _bookingNumberSelector(state)
    )))
  } catch (error) {
    dispatch(printPedReceiptError())
    return false
  }
}

const printReceipts = () => async (dispatch, getState) => {
  const paymentResult = paymentResultSelector(getState())

  if (await dispatch(printReceipt(DOCUMENT_TEMPLATE_NAME_PED_CUSTOMER_RECEIPT))) {
    return !printSignature.includes(parseInt(paymentResult.verificationType))
      ? dispatch(printReceipt(DOCUMENT_TEMPLATE_NAME_PED_MERCHANT_RECEIPT))
      : true
  } else {
    return false
  }
}

export const retryPrintReceipts = () => async dispatch => {
  if (await dispatch(printReceipts())) {
    dispatch([printPedReceiptSuccess(), showFinalizeBookingModal()])
  }
}
