import { toUtcDateMoment } from 's3p-js-lib/src/misc/date'
import { USER_INPUT_FORM_LOAD_VALUES } from 's3p-js-lib/src/redux/actions/types'
import { UI_RESET_TICKETS } from '../../../actions/types'
import { FORM_PRODUCT_SEARCH } from 's3p-js-lib/src/constants-form'
import { PASSENGER_TYPE_ADULT } from 's3p-js-lib/src/constants'
import { disabledOriginDestination } from '../../../../forms/product-search'

export default (state = {}, action) => {
  switch (action.type) {
    case USER_INPUT_FORM_LOAD_VALUES: {
      const fields = {...state.fields}
      if (action.formName === FORM_PRODUCT_SEARCH &&
        action.values.productFamily &&
        state.fields &&
        disabledOriginDestination(action.values.productFamily.attributes)
      ) {
        return {
          ...state,
          fields: {
            ...fields,
            origin: {
              ...fields.origin,
              value: null
            },
            destination: {
              ...fields.destination,
              value: null
            }
          }
        }
      }
      return state
    }
    case UI_RESET_TICKETS:
      return {
        fields: {
          validFrom: {
            value: toUtcDateMoment()
          },
          passengers: {
            value: [{type: PASSENGER_TYPE_ADULT}]
          },
          origin: {
            value: action.station || null
          },
          productFamily: {
            value: action.productFamily || null
          }
        }
      }
    default:
      return state
  }
}
