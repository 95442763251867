import React, { Component } from 'react'
import PropTypes from '../../../misc/prop-types'
import moment from 'moment'
import Form from '../../../elements/form/form'
import { KEYCODE_ENTER } from '../../../constants'

export default class DateTextInputField extends Component {
  static propTypes = {
    minDate: PropTypes.moment,
    value: PropTypes.moment,
    onChange: PropTypes.func.isRequired,
    onEnter: PropTypes.func.isRequired,
    required: PropTypes.bool.isRequired,
    placeholder: PropTypes.string
  }

  constructor (...args) {
    super(...args)

    this.handleLocalChange = this.handleLocalChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)

    this.state = {
      value: ''
    }
  }

  componentDidMount () {
    this.updateStateValue(this.props.value)
  }

  updateStateValue (value) {
    this.setState({
      value: this.formatValue(value)
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.value !== nextProps.value) {
      this.updateStateValue(nextProps.value)
    }
  }

  formatValue (date) {
    return (date && date.format('L')) || ''
  }

  handleKeyDown (event) {
    if (event.keyCode === KEYCODE_ENTER) {
      const date = this.getMoment()
      if (date || !this.props.required) {
        this.props.onChange(date)
        this.props.onEnter()
      }
    }
  }

  getMoment () {
    const date = moment(this.state.value, 'DD-MM-YYYY')
    if (date.isValid()) {
      return this.props.minDate && date.isBefore(this.props.minDate) ? this.props.minDate : date
    } else {
      return undefined
    }
  }

  handleLocalChange (event) {
    this.setState({
      value: event.target.value
    })
  }

  handleBlur () {
    const date = this.getMoment()
    if (date || !this.props.required) {
      this.props.onChange(date)
    }
  }

  render () {
    return (
      <Form.TextField
        {...this.props}
        placeholder={this.props.placeholder || ''}
        value={this.state.value}
        onChange={this.handleLocalChange}
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleBlur}
      />
    )
  }
}
