import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ComboBox from '../combo-box/combo-box'
import ListItem from './list-item'
import { stationNameToSearchString } from 's3p-js-lib/src/redux/selectors/api/base/stations'

class StationSelect extends Component {
  static propTypes = {
    stations: PropTypes.array,
    isLoadingStations: PropTypes.bool,
    value: PropTypes.any,
    onBlur: PropTypes.func,
    onChange: PropTypes.func
  }

  constructor (...args) {
    super(...args)

    this.onBlur = this.onBlur.bind(this)
    this._filter = this._filter.bind(this)

    this.search = {
      original: '',
      stripped: ''
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (typeof nextProps.value === 'string') {
      const firstItem = this.props.stations.find(item => this._filter(item, nextProps.value))
      if (firstItem) {
        this.props.onChange && this.props.onChange(firstItem)
      }
    }
  }

  _filter (item, text) {
    if (this.search.original !== text) {
      this.search.original = text
      this.search.stripped = stationNameToSearchString(text)
    }

    return (item.searchString && item.searchString.indexOf(this.search.stripped) !== -1) ||
      item.UICStationCode === this.search.stripped.toUpperCase() ||
      item.synonyms.some(synonym => synonym.toLowerCase().indexOf(this.search.stripped) !== -1)
  }

  _sort (itemA, itemB, searchTerm) {
    const strippedSearchTerm = stationNameToSearchString(searchTerm)

    const itemAMatch = Boolean(strippedSearchTerm && itemA.searchString.indexOf(strippedSearchTerm) === 0)
    const itemBMatch = Boolean(strippedSearchTerm && itemB.searchString.indexOf(strippedSearchTerm) === 0)

    if (itemAMatch && !itemBMatch) {
      return -1
    } else if (!itemAMatch && itemBMatch) {
      return 1
    } else {
      return itemA.searchString.localeCompare(itemB.searchString)
    }
  }

  onBlur (value) {
    if (typeof value === 'string' && value.length > 0) {
      const list = this.props.stations
        .filter(item => this._filter(item, value))
        .sort((itemA, itemB) => this._sort(itemA, itemB, value))
      if (list.length) {
        value = list[0]
      }
    }
    this.props.onBlur && this.props.onBlur(typeof value !== 'string' ? value : undefined)
  }

  render () {
    const {stations, isLoadingStations, ...props} = this.props

    return (
      <ComboBox
        {...props}
        duration={150}
        textField='name'
        itemComponent={ListItem}
        filter={this._filter}
        sort={this._sort}
        onBlur={this.onBlur}
        valueField='UICStationCode'
        data={stations}
        busy={isLoadingStations}
      />
    )
  }
}

export default StationSelect
