import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import countBy from 'lodash/countBy'
import { toUtcDateMoment } from 's3p-js-lib/src/misc/date'
import { ticketsApiLoadingSelector } from '../../../redux/selectors/containers/tickets/loading'
import connectFormContainer from '../../base/form'
import ProductSearch from '../../../components/tickets/product-search/product-search'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import {
  PASSENGER_TYPE_ADULT,
  PASSENGER_TYPE_STUDENT,
  PASSENGER_TYPE_CHILD
} from 's3p-js-lib/src/constants'
import { FORM_PRODUCT_SEARCH } from 's3p-js-lib/src/constants-form'
import { modalIsActiveSelector } from '../../../redux/selectors/containers/base/visible-element'
import { disabledOriginDestination } from '../../../forms/product-search'
import { repeatLastBooking } from '../../../redux/actions/containers/tickets/repeat-last-booking'
import { maxPerPassengerTypeSelector } from '../../../redux/selectors/containers/tickets/product-search'
import { disableRepeatLastBookingSelector } from '../../../redux/selectors/containers/tickets/repeat-last-booking'

class ProductSearchContainer extends Component {
  static propTypes = {
    passengersDisabled: PropTypes.bool.isRequired,
    defaultOriginStation: PropTypes.object,
    maxPerPassengerType: PropTypes.object.isRequired,
    stations: PropTypes.array,
    form: PropTypes.shape({
      fields: PropTypes.shape({
        passengers: PropTypes.shape({
          onChange: PropTypes.func,
          value: PropTypes.array
        }).isRequired,
        origin: PropTypes.shape({
          value: PropTypes.any
        }).isRequired,
        productFamily: PropTypes.shape({
          value: PropTypes.object
        }).isRequired
      }),
      loadFormValues: PropTypes.func.isRequired,
      getValues: PropTypes.func.isRequired,
      validateForm: PropTypes.func.isRequired,
      valid: PropTypes.bool.isRequired
    }),
    handleLoadFormValues: PropTypes.func
  }

  constructor (...args) {
    super(...args)

    this.handleChangeAdult = this.handleChangeAdult.bind(this)
    this.handleChangeChild = this.handleChangeChild.bind(this)
    this.handleChangeStudent = this.handleChangeStudent.bind(this)
    this.getOriginStations = this.getOriginStations.bind(this)
    this.getDestinationStations = this.getDestinationStations.bind(this)
  }

  UNSAFE_componentWillMount () {
    let origin
    if (this.props.defaultOriginStation) {
      origin = this.props.defaultOriginStation
    }
    const values = this.props.form.getValues()

    if (!values.validFrom && !values.passengers && !values.destination && !values.origin) {
      this.props.handleLoadFormValues({
        origin,
        validFrom: toUtcDateMoment(),
        passengers: [{type: PASSENGER_TYPE_ADULT}]
      })
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const hasEmptyOriginAndNoDisabledOD = !nextProps.form.fields.origin.value &&
      nextProps.form.fields.productFamily.value && !disabledOriginDestination(nextProps.form.fields.productFamily.value.attributes)

    if (nextProps.defaultOriginStation && (hasEmptyOriginAndNoDisabledOD || !this.props.defaultOriginStation)) {
      nextProps.form.loadFormValues({origin: nextProps.defaultOriginStation})
    }
  }

  _getPassengersForType (type) {
    return (this.props.form.fields.passengers.value || []).filter(passenger => passenger.type === type)
  }

  _getPassengersWithoutType (type) {
    return (this.props.form.fields.passengers.value || []).filter(passenger => passenger.type !== type)
  }

  handlePassengerChange (value, type) {
    if (!this.props.passengersDisabled) {
      const removedPassengers = this._getPassengersForType(type)
      const newPassengers = this._getPassengersWithoutType(type)

      for (let i = 0; i < parseInt(value) || 0; i++) {
        if (removedPassengers[i]) {
          newPassengers.push(removedPassengers[i])
        } else {
          newPassengers.push({type})
        }
      }
      newPassengers.sort((passengerA, passengerB) => passengerA.type.localeCompare(passengerB.type))

      this.props.form.fields.passengers.onChange(newPassengers)
    }
  }

  handleChangeAdult (value) {
    this.handlePassengerChange(value, PASSENGER_TYPE_ADULT)
  }

  handleChangeChild (value) {
    this.handlePassengerChange(value, PASSENGER_TYPE_CHILD)
  }

  handleChangeStudent (value) {
    this.handlePassengerChange(value, PASSENGER_TYPE_STUDENT)
  }

  _getPassengerCount () {
    return (this.props.form.fields.passengers && countBy(this.props.form.fields.passengers.value, 'type')) || {}
  }

  getOriginStations () {
    const {stations, form: {fields: {destination}}} = this.props
    if (stations &&
      stations.some(station => station.connectedStations && station.connectedStations.length)
    ) {
      if (destination.valid &&
        destination.value &&
        destination.value.connectedStations &&
        destination.value.connectedStations.length
      ) {
        return stations.filter(
          station => station.connectedStations.includes(destination.value.UICStationCode)
        )
      }

      return stations.filter(station => station.connectedStations.length)
    }

    return stations
  }

  getDestinationStations () {
    const {stations, form: {fields: {origin}}} = this.props
    if (stations &&
      origin.valid &&
      origin.value &&
      origin.value.connectedStations &&
      origin.value.connectedStations.length
    ) {
      return stations.filter(
        station => origin.value.connectedStations.includes(station.UICStationCode)
      )
    } else {
      return stations
    }
  }

  render () {
    const passengersCount = this._getPassengerCount()
    const props = {...this.props}
    delete props.stations

    return (
      <ProductSearch
        {...props}
        valueAdult={passengersCount[PASSENGER_TYPE_ADULT] || 0}
        valueChild={passengersCount[PASSENGER_TYPE_CHILD] || 0}
        valueStudent={passengersCount[PASSENGER_TYPE_STUDENT] || 0}
        handleChangeAdult={this.handleChangeAdult}
        handleChangeChild={this.handleChangeChild}
        handleChangeStudent={this.handleChangeStudent}
        getOriginStations={this.getOriginStations}
        getDestinationStations={this.getDestinationStations}
      />
    )
  }
}

const mapStateToProps = state => ({
  disabled: modalIsActiveSelector(state) || ticketsApiLoadingSelector(state),
  isLoadingProductsSearch: state.api.v2.loading.getProducts,
  disableRepeatLastBooking: disableRepeatLastBookingSelector(state),
  passengersDisabled: Boolean(bookingSelector(state)),
  maxPerPassengerType: maxPerPassengerTypeSelector(state)
})

const mapDispatchToProps = {
  handleRepeatLastBooking: repeatLastBooking
}

export default connect(mapStateToProps, mapDispatchToProps)(
  connectFormContainer({formName: FORM_PRODUCT_SEARCH})(
    ProductSearchContainer
  )
)
