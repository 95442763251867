import {
  API_CHANGE_SEATS_INITIATE_REQUEST,
  API_CHANGE_SEATS_INITIATE_SUCCESS,
  API_CHANGE_SEATS_INITIATE_FAILURE
} from '../../../types'
import Client from '../../../../../api/client'
import ensureToken from '../../../../../api/ensure-token'

const changeSeatsInitiateRequest = bookingNumber => ({type: API_CHANGE_SEATS_INITIATE_REQUEST, bookingNumber})
const changeSeatsInitiateSuccess = response => ({type: API_CHANGE_SEATS_INITIATE_SUCCESS, response})
const changeSeatsInitiateFailure = error => ({type: API_CHANGE_SEATS_INITIATE_FAILURE, error})

export const changeSeatsInitiate = bookingNumber => ensureToken(async function (token, dispatch) {
  try {
    dispatch(changeSeatsInitiateRequest(bookingNumber))
    const response = await Client.changeSeatsInitiate(token, bookingNumber)
    dispatch(changeSeatsInitiateSuccess(response))
  } catch (error) {
    dispatch(changeSeatsInitiateFailure(error))
    throw error
  }
})
