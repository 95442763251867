import { salesChannelPropertySelector } from 's3p-js-lib/src/redux/selectors/api/user/sales-channel-properties'
import { createSelectorWithoutMemoize } from '../../../../misc/selector'
import {
  PRODUCT_TYPE_TICKET_ONLY,
  SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_FULFILLMENT_CHANGE
} from '../../../../constants'
import {
  canOverrideAftersalesRulesSelector,
  canOverrideValidationRulesFullOrIssueSelector
} from '../../api/auth/auth'
import {
  additionalProductsAllowedSelector,
  cancellationAllowedSelector,
  changeFulfillmentAllowedSelector,
  changeSeatsAllowedSelector,
  rebookingAllowedSelector,
  updatePassengersAllowedSelector
} from '../../api/booking/after-sales'
import {
  hasProductWithValidationIssuedSelector,
  hasSeatProductsSelector
} from '../../api/booking/products'
import { requiredProductsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/products'
import {
  hasAccessToAftersalesAdditionalProductsSelector,
  hasAccessToAftersalesCancellationSelector,
  hasAccessToAftersalesChangeSeatsSelector,
  hasAccessToAftersalesRebookingSelector,
  hasAccessToAftersalesUpdatePassengersSelector
} from 's3p-js-lib/src/redux/selectors/api/auth/auth'
import {
  isConfirmedSelector,
  isProvisionalSelector,
  pendingTotalPriceToBePaidSelector
} from '../../api/booking/booking'
import { fulfillmentMethodSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { isMagstripeOrTVMFulfillment } from '../../../../misc/utils'

export const isOverrideAftersalesRulesSelector = bookingSelector => createSelectorWithoutMemoize(
  [
    canOverrideAftersalesRulesSelector(bookingSelector),
    isConfirmedSelector(bookingSelector)
  ],
  (canOverrideAftersalesRules, isConfirmed) => canOverrideAftersalesRules && isConfirmed
)

export const isOverrideValidationRulesSelector = bookingSelector => createSelectorWithoutMemoize(
  [
    hasProductWithValidationIssuedSelector(bookingSelector),
    canOverrideValidationRulesFullOrIssueSelector(bookingSelector)
  ],
  (hasValidationIssue, overrideValidationRules) => hasValidationIssue && overrideValidationRules
)

export const isChangeFulfillmentAvailableSelector = bookingSelector => createSelectorWithoutMemoize(
  [
    bookingSelector,
    changeFulfillmentAllowedSelector(bookingSelector),
    salesChannelPropertySelector(SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_FULFILLMENT_CHANGE),
    fulfillmentMethodSelector(bookingSelector),
    hasProductWithValidationIssuedSelector(bookingSelector),
    canOverrideValidationRulesFullOrIssueSelector(bookingSelector)
  ],
  (booking, isAllowed, salesChannelProperty, fulfillmentMethod, hasValidationIssue, overrideValidationRules) => !booking || (
    isAllowed &&
    salesChannelProperty &&
    (isMagstripeOrTVMFulfillment(fulfillmentMethod) || !hasValidationIssue || overrideValidationRules)
  )
)

export const isAdditionalProductsAvailableSelector = bookingSelector => createSelectorWithoutMemoize(
  [
    requiredProductsSelector(bookingSelector),
    hasAccessToAftersalesAdditionalProductsSelector(bookingSelector),
    additionalProductsAllowedSelector(bookingSelector),
    isOverrideAftersalesRulesSelector(bookingSelector)
  ],
  (requiredProducts, salesChannelAndAgentAllowed, aftersalesRulesAllowed, overrideAftersalesRules) => Boolean(
    requiredProducts.length &&
    !requiredProducts.some(product => product.type === PRODUCT_TYPE_TICKET_ONLY) &&
    salesChannelAndAgentAllowed &&
    (aftersalesRulesAllowed || overrideAftersalesRules)
  )
)

export const isCancellationAvailableSelector = bookingSelector => createSelectorWithoutMemoize(
  [
    hasAccessToAftersalesCancellationSelector(bookingSelector),
    cancellationAllowedSelector(bookingSelector),
    isOverrideAftersalesRulesSelector(bookingSelector)
  ],
  (salesChannelAndAgentAllowed, aftersalesRulesAllowed, overrideAftersalesRules) => (
    salesChannelAndAgentAllowed &&
    (aftersalesRulesAllowed || overrideAftersalesRules)
  )
)

export const isRebookAvailableSelector = bookingSelector => createSelectorWithoutMemoize(
  [
    hasSeatProductsSelector(bookingSelector),
    hasAccessToAftersalesRebookingSelector(bookingSelector),
    rebookingAllowedSelector(bookingSelector),
    isOverrideAftersalesRulesSelector(bookingSelector)
  ],
  (hasSeatProducts, salesChannelAndAgentAllowed, aftersalesRulesAllowed, overrideAftersalesRules) => (
    hasSeatProducts &&
    salesChannelAndAgentAllowed &&
    (aftersalesRulesAllowed || overrideAftersalesRules)
  )
)

export const isChangeSeatsAvailableSelector = bookingSelector => createSelectorWithoutMemoize(
  [
    hasSeatProductsSelector(bookingSelector),
    hasAccessToAftersalesChangeSeatsSelector(bookingSelector),
    changeSeatsAllowedSelector(bookingSelector),
    isOverrideAftersalesRulesSelector(bookingSelector)
  ],
  (hasSeatProducts, salesChannelAndAgentAllowed, aftersalesRulesAllowed, overrideAftersalesRules) => (
    hasSeatProducts &&
    salesChannelAndAgentAllowed &&
    (aftersalesRulesAllowed || overrideAftersalesRules)
  )
)

export const isUpdatePassengersAvailableSelector = bookingSelector => createSelectorWithoutMemoize(
  [
    hasAccessToAftersalesUpdatePassengersSelector(bookingSelector),
    updatePassengersAllowedSelector(bookingSelector),
    isOverrideAftersalesRulesSelector(bookingSelector)
  ],
  (salesChannelAndAgentAllowed, aftersalesRulesAllowed, overrideAftersalesRules) => (
    salesChannelAndAgentAllowed &&
    (aftersalesRulesAllowed || overrideAftersalesRules)
  )
)

export const isManualConfirmationAvailableSelector = bookingSelector => createSelectorWithoutMemoize(
  [
    isProvisionalSelector(bookingSelector),
    pendingTotalPriceToBePaidSelector(bookingSelector)
  ],
  (isConfirmed, pendingTotalPriceToBePaid) => (
    isConfirmed && pendingTotalPriceToBePaid <= 0
  )
)
