import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Icon from '../../../../elements/icon/icon'
import TextLabel from '../../../../elements/text-label/text-label'

export default class RefundSummary extends Component {
  static propTypes = {
    hasProvisionalBooking: PropTypes.bool.isRequired,
    ticketValue: PropTypes.number.isRequired,
    fee: PropTypes.number,
    currency: PropTypes.string
  }

  render () {
    return (
      <div className='segment-notification'>
        <div className='refund-summary'>
          <div className='cancelled-ticket-value'>
            <TextLabel name='cancelled-ticket-value-description'>
              <Icon name='information' className='align-left' />
              <TextLabel.Text text={_t.message('aftersales.cancellation.refund-summary.ticket-value')} />
            </TextLabel>
            <TextLabel
              name='cancelled-ticket-value'
              text={_t.formatCurrency(this.props.ticketValue, this.props.currency)}
            />
          </div>
          {this.props.hasProvisionalBooking && this.props.fee
            ? (
              <div className='cancellation-fee'>
                <TextLabel
                  name='cancellation-fee-description'
                  text={_t.message('aftersales.cancellation.refund-summary.fee')} />
                <TextLabel name='cancellation-fee' text={_t.formatCurrency(this.props.fee, this.props.currency)} />
              </div>
            ) : null}
          {!this.props.hasProvisionalBooking
            ? (
              <div className='cancellation-fee'>
                <TextLabel
                  name='cancellation-fee-placeholder'
                  text={_t.message('aftersales.cancellation.refund-summary.fee-placeholder')} />
              </div>
            ) : null}
        </div>
      </div>
    )
  }
}
