import React, { Component } from 'react'
import GenericLayout from '../../generic-layout'
import ComponentContainer from '../../../elements/component-container/component-container'
import JourneySearch from '../../../containers/aftersales/rebooking/journey-search/journey-search'
import JourneyResultSet from '../../../containers/base/journey-search/journey-result-set/journey-result-set'
import MainMenu from '../../../containers/base/main-menu/main-menu'
import StickyFooter from '../../../elements/sticky-footer/sticky-footer'
import OrientationSummary from '../../../components/reservations/orientation-summary'
import NextButton from '../../../containers/aftersales/rebooking/journey-search/next-button'
import Taskbar from '../../../containers/base/taskbar/taskbar'
import RebookingOperation from '../../../components/aftersales/rebooking/operation'
import { TRAVEL_DIRECTION_OUTBOUND, TRAVEL_DIRECTION_INBOUND } from 's3p-js-lib/src/constants'
import { FORM_TYPE_REBOOK_JOURNEY_SEARCH } from 's3p-js-lib/src/constants-form'
import WaiveFees from '../../../containers/aftersales/rebooking/journey-search/waive-fees'

export default class RebookingLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <MainMenu />
        <RebookingOperation showSelection />
        <WaiveFees />
        <JourneySearch formName={FORM_TYPE_REBOOK_JOURNEY_SEARCH} />
        <ComponentContainer name='journey-result-set'>
          <JourneyResultSet direction={TRAVEL_DIRECTION_OUTBOUND} rebooking />
          <JourneyResultSet direction={TRAVEL_DIRECTION_INBOUND} rebooking />
        </ComponentContainer>
        <StickyFooter>
          <OrientationSummary nextButton={<NextButton />} />
          <Taskbar />
        </StickyFooter>
      </GenericLayout>
    )
  }
}
