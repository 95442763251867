import {routerActions} from 'react-router-redux'
import {
  UI_LAST_SELECTED_PRODUCT,
  USER_INPUT_PENDING_ADD_PRODUCT
} from '../../types'
import {
  addProduct as bookingAddProduct,
  UPDATE_BOOKING_HANDLED_ERRORS
} from 's3p-js-lib/src/redux/actions/api/v2/booking'
import {productsSelector} from 's3p-js-lib/src/redux/selectors/api/booking/products'
import {bookingSelector, fulfillmentMethodSelector} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import {sendMachineDisplayLines, timeoutClearDisplay} from '../../machine/display'
import {resetTickets} from './products'
import uniqueId from 'lodash/uniqueId'
import {showElement} from '../base/visible-element'
import {
  CLEAR_DISPLAY_AFTER_LONG,
  EXTERNAL_REFERENCE_NAME_IRISH,
  SIDEBAR_CART
} from '../../../../constants'
import {ONE_LEAP_PRODUCT_BOOKING_MODAL} from '../../../../components/tickets/modals/one-leap-product-booking-modal'
import {
  displayFormatAmount,
  isAnyLeapFulfillment,
  findMetaDataByKey
} from '../../../../misc/utils'
import {hasLeapFulfillmentSelector} from '../../../selectors/containers/base/tiles/fulfillment-methods'
import {leapStatus} from '../../machine/leap'

const pendingAddProduct = product => ({type: USER_INPUT_PENDING_ADD_PRODUCT, product})
export const setLastSelectedProduct = product => ({type: UI_LAST_SELECTED_PRODUCT, product})

const _fulfillmentMethodSelector = fulfillmentMethodSelector(bookingSelector)
const _productsSelector = productsSelector(bookingSelector)
const _hasLeapFulfillmentSelector = hasLeapFulfillmentSelector(bookingSelector)

export const redirectToTicketErrorPage = (dispatch, error) => {
  if (error && UPDATE_BOOKING_HANDLED_ERRORS.includes(error.code)) {
    dispatch([resetTickets(), routerActions.push('/error/tickets')])
  }
}

export const addProduct = product => async (dispatch, getState) => {
  const products = _productsSelector(getState())
  const fulfillmentMethod = _fulfillmentMethodSelector(getState())

  if (products.length >= 1 && isAnyLeapFulfillment(fulfillmentMethod)) {
    dispatch(showElement(ONE_LEAP_PRODUCT_BOOKING_MODAL))
  } else {
    dispatch(pendingAddProduct(product))

    const response = await dispatch(bookingAddProduct(product))
    if (response) {
      if (_hasLeapFulfillmentSelector(getState())) {
        dispatch(leapStatus())
      }

      dispatch([showElement(SIDEBAR_CART), setLastSelectedProduct(product)])
      const requestId = uniqueId('product_')

      let nameIrish
      if (product.metaData && product.metaData.length) {
        const metaNameIrish = findMetaDataByKey(product.metaData, EXTERNAL_REFERENCE_NAME_IRISH)
        nameIrish = metaNameIrish && metaNameIrish.value
      }

      await dispatch(sendMachineDisplayLines([displayFormatAmount(product.name, product.price), nameIrish || ''], requestId))
      dispatch(timeoutClearDisplay(CLEAR_DISPLAY_AFTER_LONG, requestId))
    } else {
      redirectToTicketErrorPage(dispatch, getState().api.base.lastApiErrors.v2.updateSegments)
    }
  }
}
