import PropTypes from 'prop-types'
import React, { Component } from 'react'
import GridLayout from '../../elements/grid/grid-layout'
import GridColumn from '../../elements/grid/grid-column'
import ProductSearch from '../../containers/tickets/product-search/product-search'
import ProductFamily from '../../containers/tickets/product-family/product-family'
import ProductProcess from '../tickets/product-process/product-process'
import Taskbar from '../../containers/base/taskbar/taskbar'
import Loader, { TYPE_BLOCKING, TYPE_COMPONENT } from '../../elements/loader/loader'
import MaxPassengerModal from '../base/max-passengers-modal'
import DifferentPassengerNumberModal from './modals/different-passengers-number-modal'
import BeforeDayCurrentMonth from './modals/before-day-current-month-modal'
import CartValueZeroModalContainer from '../../containers/tickets/cart-value-zero-modal'
import OneLeapProductBookingModal from './modals/one-leap-product-booking-modal'
import LeapOptionCheckBalanceModal from '../../containers/tickets/leap-option-check-balance-modal'

export default class Products extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    showMaxPassengersModal: PropTypes.bool,
    showDifferentPassengersModal: PropTypes.bool,
    showBeforeDayCurrentMonth: PropTypes.bool,
    beforeDayCurrentMonth: PropTypes.string,
    isLoadingRepeatLastBooking: PropTypes.bool,
    showCartValueZero: PropTypes.bool,
    showOneLeapProductBooking: PropTypes.bool,
    showLeapOptionCheckBalance: PropTypes.bool
  }

  render () {
    const {
      showMaxPassengersModal,
      showDifferentPassengersModal,
      showBeforeDayCurrentMonth,
      showCartValueZero,
      isLoading,
      beforeDayCurrentMonth,
      isLoadingRepeatLastBooking,
      showOneLeapProductBooking,
      showLeapOptionCheckBalance,
      ...props
    } = this.props
    return isLoading ? <Loader type={TYPE_BLOCKING} />
      : isLoadingRepeatLastBooking ? <Loader type={TYPE_COMPONENT} />
        : (
          <GridLayout name='ticket-left-content'>
            <GridColumn name='left'>
              <ProductFamily {...props} />
            </GridColumn>
            <GridColumn name='content'>
              <ProductSearch {...props} />
              <ProductProcess />
              <Taskbar />
            </GridColumn>
            {showMaxPassengersModal ? <MaxPassengerModal maxPassengers={props.maxPassengers} /> : null}
            {showDifferentPassengersModal ? <DifferentPassengerNumberModal /> : null}
            {showBeforeDayCurrentMonth ? <BeforeDayCurrentMonth beforeDayCurrentMonth={beforeDayCurrentMonth} /> : null}
            {showCartValueZero ? <CartValueZeroModalContainer /> : null}
            {showOneLeapProductBooking ? <OneLeapProductBookingModal /> : null}
            {showLeapOptionCheckBalance ? <LeapOptionCheckBalanceModal /> : null}
          </GridLayout>
        )
  }
}
