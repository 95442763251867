import React, { Component } from 'react'
import PropTypes from '../../../../misc/prop-types'
import { connect } from 'react-redux'
import { switchDate } from '../../../../redux/actions/containers/base/journey-search/journey-search'
import DateSwitcherItem from '../../../../components/base/journey-search/date-switcher/date-switcher-item'

class DateSwitcherItemContainer extends Component {
  static propTypes = {
    direction: PropTypes.string.isRequired,
    date: PropTypes.moment.isRequired,
    available: PropTypes.bool.isRequired,
    onSwitchDate: PropTypes.func.isRequired
  }

  constructor (...args) {
    super(...args)
    this.handleSwitchDate = this.handleSwitchDate.bind(this)
  }

  handleSwitchDate () {
    if (this.props.available) {
      this.props.onSwitchDate(this.props.direction, this.props.date)
    }
  }

  render () {
    return <DateSwitcherItem {...this.props} onSwitchDate={this.handleSwitchDate} />
  }
}

const mapDispatchToProps = (_, ownProps) => dispatch => ({
  onSwitchDate: (...args) => dispatch(switchDate(ownProps.rebooking, ...args))
})

export default connect(null, mapDispatchToProps)(DateSwitcherItemContainer)
