import { API_AFTERSALES_CONFIRM_SUCCESS } from 's3p-js-lib/src/redux/actions/types'
import { initialState } from 's3p-js-lib/src/redux/reducers/api/booking/aftersales-original-booking'

export default (state = initialState, action) => {
  switch (action.type) {
    case API_AFTERSALES_CONFIRM_SUCCESS:
      return initialState
    default:
      return state
  }
}
