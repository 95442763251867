import React, { Component } from 'react'
import PropTypes from '../../misc/prop-types'
import _t from 's3p-js-lib/src/translate'
import Icon from '../icon/icon'
import TextLabel from '../text-label/text-label'
import Segment from '../../components/base/additional-products/segments/segment'

class Header extends Component {
  static propTypes = {
    originName: PropTypes.string,
    destinationName: PropTypes.string,
    departureMoment: PropTypes.moment,
    arrivalMoment: PropTypes.moment,
    serviceName: PropTypes.string,
    className: PropTypes.string
  }

  render () {
    const {originName, destinationName, departureMoment, arrivalMoment, serviceName} = this.props
    return <div className='segment__header'>
      <div className='segment__header-origin'>
        <TextLabel name='origin' text={originName} />
        {departureMoment ? <TextLabel
          name='departure-date'
          text={_t.message('segment-block.departure-date', {
            date: _t.formatMomentDate(departureMoment, 'dayOfTheWeekWithDate')
          })}
        /> : null}
        {departureMoment ? <TextLabel
          name='departure-time'
          text={_t.message('segment-block.departure-time', {
            time: _t.formatMomentTime(departureMoment, 'localeTime')
          })}
        /> : null}
      </div>
      <div className='segment__header-destination'>
        {destinationName && <TextLabel name='destination'>
          <Icon name='long-arrow-right' className='align-left' />
          <TextLabel.Text text={destinationName} />
        </TextLabel> }
        {((departureMoment && arrivalMoment && departureMoment.isSame(arrivalMoment, 'day')) || !arrivalMoment)
          ? null
          : <TextLabel
            name='arrival-date'
            text={_t.message('segment-block.arrival-date', {
              date: _t.formatMomentDate(arrivalMoment, 'dayOfTheWeekWithDate')
            })}
          />
        }
        {arrivalMoment ? <TextLabel
          name='arrival-time'
          text={_t.message('segment-block.arrival-time', {
            time: _t.formatMomentTime(arrivalMoment, 'localeTime')
          })}
        /> : null}
        {serviceName ? (
          <TextLabel name='service-name'>
            <TextLabel.Text text={serviceName} />
            <Icon name='train' className='small align-left' />
          </TextLabel>
        ) : null}
      </div>
    </div>
  }
}

export default class OriginDestinationBlock extends Component {
  render () {
    return (
      <Segment header={<Header {...this.props} />}>{this.props.children}</Segment>
    )
  }
}
