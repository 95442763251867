import { connect } from 'react-redux'
import { templateSelector } from 's3p-js-lib/src/redux/selectors/user-input/base/seat-selector'
import { seatsSelector } from '../../../redux/selectors/user-input/base/seat-selector'
import CarriageFloorplan from '../../../components/base/seat-selector/carriage-floorplan'

const mapStateToProps = state => ({
  loading: (
    state.api.loading.updateSelectBundle ||
    state.api.loading.getSeatSelectionOptions ||
    state.api.loading.getCarriageLayoutsOrientation ||
    state.api.loading.getCarriageLayoutsAftersales ||
    state.api.loading.createProvisionalBooking
  ),
  seats: seatsSelector(state),
  template: templateSelector(state)
})

export default connect(mapStateToProps)(CarriageFloorplan)
