import { connect } from 'react-redux'
import { startNewBooking } from '../../../../redux/actions/containers/aftersales/book-again/book-again'
import {
  journeySearchFromBookingSelector,
  isLoadingToolbarActionsSelector
} from '../../../../redux/selectors/containers/aftersales/booking/toolbar'
import ToolbarItem from '../../../../components/aftersales/booking/toolbar/toolbar-item'

const mapStateToProps = state => ({
  disabled: isLoadingToolbarActionsSelector(state),
  available: Boolean(journeySearchFromBookingSelector(state))
})

const mapDispatchToProps = {
  onClick: startNewBooking
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarItem)
