import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import MagstripeData from '../../../components/aftersales/read-ticket/magstripe-data'
import { magneticDataSelector } from '../../../redux/selectors/machine/magnetic-ticket-printer'
import { MACHINE_MAGNETIC_TICKET_PRINTER } from '../../../constants'

class MagstripeDataContainer extends Component {
  static propTypes = {
    handleClearState: PropTypes.func.isRequired
  }

  componentWillUnmount () {
    this.props.handleClearState()
  }

  render () {
    return <MagstripeData {...this.props} />
  }
}

const mapStateToProps = state => ({
  magstripeData: magneticDataSelector(state)
})

const mapDispatchToProps = {
  handleClearState: () => clearState(MACHINE_MAGNETIC_TICKET_PRINTER)
}

export default connect(mapStateToProps, mapDispatchToProps)(MagstripeDataContainer)
