import DOMPurify from 'dompurify'
import { createSelector } from 'reselect'
import Validator from 's3p-js-lib/src/forms/validator'
import baseCustomerDetailsForm from 's3p-js-lib/src/forms/customer-details'
import { noCommaValidator } from './validators'

const customerDetailsFields = {
  firstName: {
    normalizers: [
      DOMPurify.sanitize
    ],
    validators: [
      Validator.isFirstName,
      noCommaValidator
    ]
  },
  lastName: {
    normalizers: [
      DOMPurify.sanitize
    ],
    validators: [
      Validator.isLastName,
      noCommaValidator
    ]
  },
  emailAddress: {
    normalizers: [
      DOMPurify.sanitize
    ],
    validators: [
      Validator.isEmail
    ]
  },
  phoneNumber: {
    normalizers: [
      DOMPurify.sanitize
    ],
    validators: [
      Validator.isPhoneNumber
    ]
  }
}

export default createSelector(
  [baseCustomerDetailsForm],
  baseForm => ({
    fields: {...baseForm.fields, ...customerDetailsFields}
  })
)
