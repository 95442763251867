import {
  UI_CLEAR_LAST_API_ERROR,
  API_UNHANDLED_ERROR,
  API_OAUTH_TOKEN_ERROR,
  API_OAUTH_REFRESH_TOKEN_FAILURE,
  API_AGENT_LOGIN_REQUEST,
  API_AGENT_LOGIN_SUCCESS,
  API_AGENT_LOGIN_FAILURE,
  API_BOOKING_LOGIN_REQUEST,
  API_BOOKING_LOGIN_SUCCESS,
  API_BOOKING_LOGIN_FAILURE,
  API_PUBLIC_ACCESS_REQUEST,
  API_PUBLIC_ACCESS_SUCCESS,
  API_PUBLIC_ACCESS_FAILURE,
  API_LOAD_USER_INFORMATION_REQUEST,
  API_LOAD_USER_INFORMATION_SUCCESS,
  API_LOAD_USER_INFORMATION_FAILURE,
  API_LOAD_AGENT_INFORMATION_REQUEST,
  API_LOAD_AGENT_INFORMATION_SUCCESS,
  API_LOAD_AGENT_INFORMATION_FAILURE,
  API_AGENT_CHANGE_PASSWORD_REQUEST,
  API_AGENT_CHANGE_PASSWORD_SUCCESS,
  API_AGENT_CHANGE_PASSWORD_FAILURE,
  API_CMS_LOAD_BLOCKS_REQUEST,
  API_CMS_LOAD_BLOCKS_SUCCESS,
  API_CMS_LOAD_BLOCKS_FAILURE,
  API_JOURNEY_SEARCH_REQUEST,
  API_JOURNEY_SEARCH_SUCCESS,
  API_JOURNEY_SEARCH_FAILURE,
  API_REBOOKING_JOURNEY_SEARCH_REQUEST,
  API_REBOOKING_JOURNEY_SEARCH_SUCCESS,
  API_REBOOKING_JOURNEY_SEARCH_FAILURE,
  API_REBOOKING_CREATE_BOOKING_REQUEST,
  API_REBOOKING_CREATE_BOOKING_SUCCESS,
  API_REBOOKING_CREATE_BOOKING_FAILURE,
  API_SELECT_BUNDLE_REQUEST,
  API_SELECT_BUNDLE_SUCCESS,
  API_SELECT_BUNDLE_FAILURE,
  API_GET_STATIONS_REQUEST,
  API_GET_STATIONS_SUCCESS,
  API_GET_STATIONS_FAILURE,
  API_CREATE_PROVISIONAL_BOOKING_REQUEST,
  API_CREATE_PROVISIONAL_BOOKING_SUCCESS,
  API_CREATE_PROVISIONAL_BOOKING_FAILURE,
  API_GET_BOOKING_REQUEST,
  API_GET_BOOKING_SUCCESS,
  API_GET_BOOKING_FAILURE,
  API_GET_BOOKINGS_REQUEST,
  API_GET_BOOKINGS_SUCCESS,
  API_GET_BOOKINGS_FAILURE,
  API_LINK_TO_CUSTOMER_EMAIL_REQUEST,
  API_LINK_TO_CUSTOMER_EMAIL_SUCCESS,
  API_LINK_TO_CUSTOMER_EMAIL_FAILURE,
  API_UPDATE_BOOKING_REQUEST,
  API_UPDATE_BOOKING_SUCCESS,
  API_UPDATE_BOOKING_FAILURE,
  API_ADD_VOUCHER_REQUEST,
  API_ADD_VOUCHER_SUCCESS,
  API_ADD_VOUCHER_FAILURE,
  API_REQUEST_REFUND_REQUEST,
  API_REQUEST_REFUND_SUCCESS,
  API_REQUEST_REFUND_FAILURE,
  API_REMOVE_VOUCHER_REQUEST,
  API_REMOVE_VOUCHER_SUCCESS,
  API_REMOVE_VOUCHER_FAILURE,
  API_GET_PAYMENT_METHODS_REQUEST,
  API_GET_PAYMENT_METHODS_SUCCESS,
  API_GET_PAYMENT_METHODS_FAILURE,
  API_GET_SEAT_SELECTION_OPTIONS_REQUEST,
  API_GET_SEAT_SELECTION_OPTIONS_SUCCESS,
  API_GET_SEAT_SELECTION_OPTIONS_FAILURE,
  API_GET_CARRIAGE_LAYOUTS_ORIENTATION_REQUEST,
  API_GET_CARRIAGE_LAYOUTS_ORIENTATION_SUCCESS,
  API_GET_CARRIAGE_LAYOUTS_ORIENTATION_FAILURE,
  API_GET_CARRIAGE_LAYOUTS_AFTERSALES_REQUEST,
  API_GET_CARRIAGE_LAYOUTS_AFTERSALES_SUCCESS,
  API_GET_CARRIAGE_LAYOUTS_AFTERSALES_FAILURE,
  API_GET_ADDITIONAL_PRODUCTS_REQUEST,
  API_GET_ADDITIONAL_PRODUCTS_SUCCESS,
  API_GET_ADDITIONAL_PRODUCTS_FAILURE,
  API_UPDATE_SELECT_BUNDLE_REQUEST,
  API_UPDATE_SELECT_BUNDLE_SUCCESS,
  API_UPDATE_SELECT_BUNDLE_FAILURE,
  API_RESET_SEAT_SELECTION_REQUEST,
  API_RESET_SEAT_SELECTION_SUCCESS,
  API_RESET_SEAT_SELECTION_FAILURE,
  API_CONTINUE_PROVISIONAL_BOOKING_REQUEST,
  API_CONTINUE_PROVISIONAL_BOOKING_SUCCESS,
  API_CONTINUE_PROVISIONAL_BOOKING_FAILURE,
  API_CHANGE_SEATS_INITIATE_REQUEST,
  API_CHANGE_SEATS_INITIATE_SUCCESS,
  API_CHANGE_SEATS_INITIATE_FAILURE,
  API_CHANGE_SEATS_SELECT_SEATS_REQUEST,
  API_CHANGE_SEATS_SELECT_SEATS_SUCCESS,
  API_CHANGE_SEATS_SELECT_SEATS_FAILURE,
  API_CANCELLATION_INITIATE_REQUEST,
  API_CANCELLATION_INITIATE_SUCCESS,
  API_CANCELLATION_INITIATE_FAILURE,
  API_CANCELLATION_CANCEL_REQUEST,
  API_CANCELLATION_CANCEL_SUCCESS,
  API_CANCELLATION_CANCEL_FAILURE,
  API_REBOOKING_INITIATE_REQUEST,
  API_REBOOKING_INITIATE_SUCCESS,
  API_REBOOKING_INITIATE_FAILURE,
  API_UPDATE_PASSENGERS_INITIATE_REQUEST,
  API_UPDATE_PASSENGERS_INITIATE_SUCCESS,
  API_UPDATE_PASSENGERS_INITIATE_FAILURE,
  API_UPDATE_PASSENGERS_UPDATE_REQUEST,
  API_UPDATE_PASSENGERS_UPDATE_SUCCESS,
  API_UPDATE_PASSENGERS_UPDATE_FAILURE,
  API_ADD_BOOKING_NOTE_REQUEST,
  API_ADD_BOOKING_NOTE_SUCCESS,
  API_ADD_BOOKING_NOTE_FAILURE,
  API_AFTERSALES_CONFIRM_REQUEST,
  API_AFTERSALES_CONFIRM_SUCCESS,
  API_AFTERSALES_CONFIRM_FAILURE,
  API_ADDITIONAL_PRODUCTS_INITIATE_REQUEST,
  API_ADDITIONAL_PRODUCTS_INITIATE_SUCCESS,
  API_ADDITIONAL_PRODUCTS_INITIATE_FAILURE,
  API_ADDITIONAL_PRODUCTS_UPDATE_REQUEST,
  API_ADDITIONAL_PRODUCTS_UPDATE_SUCCESS,
  API_ADDITIONAL_PRODUCTS_UPDATE_FAILURE,
  API_RESEND_TICKETS_REQUEST,
  API_RESEND_TICKETS_SUCCESS,
  API_RESEND_TICKETS_FAILURE,
  API_FIND_CUSTOMERS_REQUEST,
  API_FIND_CUSTOMERS_SUCCESS,
  API_FIND_CUSTOMERS_FAILURE,
  API_GET_JOURNEY_DETAILS_REQUEST,
  API_GET_JOURNEY_DETAILS_SUCCESS,
  API_GET_JOURNEY_DETAILS_FAILURE,
  API_AGENT_UPDATE_REQUEST,
  API_AGENT_UPDATE_SUCCESS,
  API_AGENT_UPDATE_FAILURE,
  API_PING_REQUEST,
  API_PING_SUCCESS,
  API_PING_FAILURE,
  API_UPDATE_AGENT_REQUEST,
  API_UPDATE_AGENT_SUCCESS,
  API_UPDATE_AGENT_FAILURE,
  API_GET_DOCUMENT_TEMPLATES_REQUEST,
  API_GET_DOCUMENT_TEMPLATES_SUCCESS,
  API_GET_DOCUMENT_TEMPLATES_FAILURE,
  API_START_AGENT_SHIFT_REQUEST,
  API_START_AGENT_SHIFT_SUCCESS,
  API_START_AGENT_SHIFT_FAILURE,
  API_GET_AGENT_SHIFT_REQUEST,
  API_GET_AGENT_SHIFT_SUCCESS,
  API_GET_AGENT_SHIFT_FAILURE,
  API_ADD_NOTES_AGENT_SHIFT_REQUEST,
  API_ADD_NOTES_AGENT_SHIFT_SUCCESS,
  API_ADD_NOTES_AGENT_SHIFT_FAILURE,
  API_END_AGENT_SHIFT_REQUEST,
  API_END_AGENT_SHIFT_SUCCESS,
  API_END_AGENT_SHIFT_FAILURE,
  API_GET_VOUCHERS_REQUEST,
  API_GET_VOUCHERS_SUCCESS,
  API_GET_VOUCHERS_FAILURE,
  API_TICKET_VOID_VALIDATION_REQUEST,
  API_TICKET_VOID_VALIDATION_SUCCESS,
  API_TICKET_VOID_VALIDATION_FAILURE,
  API_GET_RECEIPT_DOCUMENT_REQUEST,
  API_GET_RECEIPT_DOCUMENT_SUCCESS,
  API_GET_RECEIPT_DOCUMENT_FAILURE
} from '../../../../actions/types'
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_WARNING } from '../../../../../constants'
import {
  API_V2_UPDATE_FULFILLMENT_METHOD_FAILURE,
  API_V2_UPDATE_PASSENGERS_FAILURE,
  API_V2_UPDATE_PASSENGERS_REQUEST,
  API_V2_UPDATE_PASSENGERS_SUCCESS
} from 's3p-js-lib/src/redux/actions/types-v2'

export const createNewState = (namespace, state, action) => {
  return {
    ...state,
    [namespace]: action.error ? createError(action.error) : null
  }
}

const createError = actionError => actionError
  ? {
    code: actionError.errorCode,
    severity: MESSAGE_SEVERITY_ERROR,
    message: actionError.message
  }
  : null

export const clearErrors = (state, namespaces) => Array.isArray(namespaces)
  ? Object.keys(state).reduce(
    (newState, namespace) => {
      newState[namespace] = namespaces.includes(namespace) ? null : state[namespace]
      return newState
    }, {})
  : {}

export const initialState = {}

export default function lastApiError (state = initialState, action) {
  switch (action.type) {
    case UI_CLEAR_LAST_API_ERROR:
      return clearErrors(state, action.namespaces)
    case API_UNHANDLED_ERROR:
    case API_OAUTH_TOKEN_ERROR:
      return createNewState('unhandled', state, action)
    case API_GET_PAYMENT_METHODS_REQUEST:
    case API_GET_PAYMENT_METHODS_SUCCESS:
    case API_GET_PAYMENT_METHODS_FAILURE:
      return createNewState('getPaymentMethods', state, action)
    case API_UPDATE_SELECT_BUNDLE_REQUEST:
    case API_UPDATE_SELECT_BUNDLE_SUCCESS:
    case API_UPDATE_SELECT_BUNDLE_FAILURE:
      return createNewState('updateSelectBundle', state, action)
    case API_ADDITIONAL_PRODUCTS_UPDATE_REQUEST:
    case API_ADDITIONAL_PRODUCTS_UPDATE_FAILURE:
    case API_ADDITIONAL_PRODUCTS_UPDATE_SUCCESS:
      return createNewState('updateAdditionalProducts', state, action)
    case API_CREATE_PROVISIONAL_BOOKING_REQUEST:
    case API_CREATE_PROVISIONAL_BOOKING_SUCCESS:
    case API_CREATE_PROVISIONAL_BOOKING_FAILURE:
      return createNewState('createProvisionalBooking', state, action)
    case API_REBOOKING_CREATE_BOOKING_FAILURE:
    case API_REBOOKING_CREATE_BOOKING_SUCCESS:
    case API_REBOOKING_CREATE_BOOKING_REQUEST:
      return createNewState('rebookingCreateBooking', state, action)
    case API_CHANGE_SEATS_SELECT_SEATS_REQUEST:
    case API_CHANGE_SEATS_SELECT_SEATS_SUCCESS:
    case API_CHANGE_SEATS_SELECT_SEATS_FAILURE:
      return createNewState('changeSeatsSelectSeats', state, action)
    case API_PUBLIC_ACCESS_REQUEST:
    case API_PUBLIC_ACCESS_SUCCESS:
    case API_PUBLIC_ACCESS_FAILURE:
      return createNewState('publicLogin', state, action)
    case API_AGENT_LOGIN_REQUEST:
    case API_AGENT_LOGIN_SUCCESS:
    case API_AGENT_LOGIN_FAILURE:
      return createNewState('agentLogin', state, action)
    case API_BOOKING_LOGIN_REQUEST:
    case API_BOOKING_LOGIN_SUCCESS:
    case API_BOOKING_LOGIN_FAILURE:
      return createNewState('bookingLogin', state, action)
    case API_LOAD_USER_INFORMATION_REQUEST:
    case API_LOAD_USER_INFORMATION_SUCCESS:
    case API_LOAD_USER_INFORMATION_FAILURE:
      return createNewState('loadUserInformation', state, action)
    case API_LOAD_AGENT_INFORMATION_REQUEST:
    case API_LOAD_AGENT_INFORMATION_SUCCESS:
    case API_LOAD_AGENT_INFORMATION_FAILURE:
      return createNewState('loadAgentInformation', state, action)
    case API_RESEND_TICKETS_REQUEST:
    case API_RESEND_TICKETS_SUCCESS:
    case API_RESEND_TICKETS_FAILURE:
      return createNewState('resendTickets', state, action)
    case API_REQUEST_REFUND_FAILURE:
    case API_REQUEST_REFUND_REQUEST:
    case API_REQUEST_REFUND_SUCCESS:
      return createNewState('requestRefund', state, action)
    case API_OAUTH_REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        refreshToken: {
          code: action.error.code,
          severity: action.error.code ? MESSAGE_SEVERITY_ERROR : MESSAGE_SEVERITY_WARNING,
          message: action.error.message,
          defaultMessage: 'messages.session-expired'
        }
      }
    case API_AGENT_CHANGE_PASSWORD_REQUEST:
    case API_AGENT_CHANGE_PASSWORD_SUCCESS:
    case API_AGENT_CHANGE_PASSWORD_FAILURE:
      return createNewState('agentChangePassword', state, action)
    case API_AGENT_UPDATE_REQUEST:
    case API_AGENT_UPDATE_SUCCESS:
    case API_AGENT_UPDATE_FAILURE:
      return createNewState('agentUpdate', state, action)
    case API_UPDATE_AGENT_REQUEST:
    case API_UPDATE_AGENT_SUCCESS:
    case API_UPDATE_AGENT_FAILURE:
      return createNewState('updateAgent', state, action)
    case API_GET_RECEIPT_DOCUMENT_REQUEST:
    case API_GET_RECEIPT_DOCUMENT_SUCCESS:
    case API_GET_RECEIPT_DOCUMENT_FAILURE:
      return createNewState('getReceiptDocument', state, action)
    case API_CMS_LOAD_BLOCKS_REQUEST:
    case API_CMS_LOAD_BLOCKS_SUCCESS:
    case API_CMS_LOAD_BLOCKS_FAILURE:
      return createNewState('cmsLoadBlocks', state, action)
    case API_JOURNEY_SEARCH_REQUEST:
    case API_JOURNEY_SEARCH_SUCCESS:
    case API_JOURNEY_SEARCH_FAILURE:
      return createNewState('journeySearch', state, action)
    case API_REBOOKING_JOURNEY_SEARCH_REQUEST:
    case API_REBOOKING_JOURNEY_SEARCH_SUCCESS:
    case API_REBOOKING_JOURNEY_SEARCH_FAILURE:
      return createNewState('rebookJourneySearch', state, action)
    case API_SELECT_BUNDLE_REQUEST:
    case API_SELECT_BUNDLE_SUCCESS:
    case API_SELECT_BUNDLE_FAILURE:
      return createNewState('selectBundle', state, action)
    case API_GET_STATIONS_REQUEST:
    case API_GET_STATIONS_SUCCESS:
    case API_GET_STATIONS_FAILURE:
      return createNewState('getStations', state, action)
    case API_GET_BOOKING_REQUEST:
    case API_GET_BOOKING_SUCCESS:
    case API_GET_BOOKING_FAILURE:
      return createNewState('getBooking', state, action)
    case API_GET_BOOKINGS_REQUEST:
    case API_GET_BOOKINGS_SUCCESS:
    case API_GET_BOOKINGS_FAILURE:
      return createNewState('getBookings', state, action)
    case API_REMOVE_VOUCHER_REQUEST:
    case API_REMOVE_VOUCHER_SUCCESS:
    case API_REMOVE_VOUCHER_FAILURE:
      return createNewState('removeVoucher', state, action)
    case API_LINK_TO_CUSTOMER_EMAIL_REQUEST:
    case API_LINK_TO_CUSTOMER_EMAIL_SUCCESS:
    case API_LINK_TO_CUSTOMER_EMAIL_FAILURE:
      return createNewState('linkToCustomerEmail', state, action)
    case API_UPDATE_BOOKING_REQUEST:
    case API_UPDATE_BOOKING_SUCCESS:
    case API_UPDATE_BOOKING_FAILURE:
      return createNewState('updateBooking', state, action)
    case API_ADD_VOUCHER_REQUEST:
    case API_ADD_VOUCHER_SUCCESS:
    case API_ADD_VOUCHER_FAILURE:
      return createNewState('addVoucher', state, action)
    case API_PING_REQUEST:
    case API_PING_SUCCESS:
    case API_PING_FAILURE:
      return createNewState('ping', state, action)
    case API_GET_SEAT_SELECTION_OPTIONS_REQUEST:
    case API_GET_SEAT_SELECTION_OPTIONS_SUCCESS:
    case API_GET_SEAT_SELECTION_OPTIONS_FAILURE:
      return createNewState('getSeatSelectionOptions', state, action)
    case API_GET_CARRIAGE_LAYOUTS_ORIENTATION_REQUEST:
    case API_GET_CARRIAGE_LAYOUTS_ORIENTATION_SUCCESS:
    case API_GET_CARRIAGE_LAYOUTS_ORIENTATION_FAILURE:
      return createNewState('getCarriageLayoutsOrientation', state, action)
    case API_GET_CARRIAGE_LAYOUTS_AFTERSALES_REQUEST:
    case API_GET_CARRIAGE_LAYOUTS_AFTERSALES_SUCCESS:
    case API_GET_CARRIAGE_LAYOUTS_AFTERSALES_FAILURE:
      return createNewState('getCarriageLayoutsAftersales', state, action)
    case API_GET_ADDITIONAL_PRODUCTS_REQUEST:
    case API_GET_ADDITIONAL_PRODUCTS_SUCCESS:
    case API_GET_ADDITIONAL_PRODUCTS_FAILURE:
      return createNewState('getAdditionalProducts', state, action)
    case API_CANCELLATION_INITIATE_REQUEST:
    case API_CANCELLATION_INITIATE_SUCCESS:
    case API_CANCELLATION_INITIATE_FAILURE:
      return createNewState('cancellationInitiate', state, action)
    case API_CANCELLATION_CANCEL_REQUEST:
    case API_CANCELLATION_CANCEL_SUCCESS:
    case API_CANCELLATION_CANCEL_FAILURE:
      return createNewState('cancellation', state, action)
    case API_REBOOKING_INITIATE_REQUEST:
    case API_REBOOKING_INITIATE_SUCCESS:
    case API_REBOOKING_INITIATE_FAILURE:
      return createNewState('rebookingInitiate', state, action)
    case API_UPDATE_PASSENGERS_INITIATE_REQUEST:
    case API_UPDATE_PASSENGERS_INITIATE_SUCCESS:
    case API_UPDATE_PASSENGERS_INITIATE_FAILURE:
      return createNewState('updatePassengerInitiate', state, action)
    case API_UPDATE_PASSENGERS_UPDATE_REQUEST:
    case API_UPDATE_PASSENGERS_UPDATE_SUCCESS:
    case API_UPDATE_PASSENGERS_UPDATE_FAILURE:
      return createNewState('updatePassenger', state, action)
    case API_ADD_BOOKING_NOTE_REQUEST:
    case API_ADD_BOOKING_NOTE_SUCCESS:
    case API_ADD_BOOKING_NOTE_FAILURE:
      return createNewState('addBookingNote', state, action)
    case API_AFTERSALES_CONFIRM_REQUEST:
    case API_AFTERSALES_CONFIRM_SUCCESS:
    case API_AFTERSALES_CONFIRM_FAILURE:
      return createNewState('aftersalesConfirm', state, action)
    case API_ADDITIONAL_PRODUCTS_INITIATE_REQUEST:
    case API_ADDITIONAL_PRODUCTS_INITIATE_SUCCESS:
    case API_ADDITIONAL_PRODUCTS_INITIATE_FAILURE:
      return createNewState('additionalProductsInitiate', state, action)
    case API_RESET_SEAT_SELECTION_REQUEST:
    case API_RESET_SEAT_SELECTION_SUCCESS:
    case API_RESET_SEAT_SELECTION_FAILURE:
      return createNewState('resetSeatSelection', state, action)
    case API_CONTINUE_PROVISIONAL_BOOKING_REQUEST:
    case API_CONTINUE_PROVISIONAL_BOOKING_SUCCESS:
    case API_CONTINUE_PROVISIONAL_BOOKING_FAILURE:
      return createNewState('continueProvisionalBooking', state, action)
    case API_CHANGE_SEATS_INITIATE_REQUEST:
    case API_CHANGE_SEATS_INITIATE_SUCCESS:
    case API_CHANGE_SEATS_INITIATE_FAILURE:
      return createNewState('changeSeatsInitiate', state, action)
    case API_FIND_CUSTOMERS_REQUEST:
    case API_FIND_CUSTOMERS_SUCCESS:
    case API_FIND_CUSTOMERS_FAILURE:
      return createNewState('findCustomers', state, action)
    case API_GET_JOURNEY_DETAILS_REQUEST:
    case API_GET_JOURNEY_DETAILS_SUCCESS:
    case API_GET_JOURNEY_DETAILS_FAILURE:
      return createNewState('getJourneyDetails', state, action)
    case API_GET_DOCUMENT_TEMPLATES_REQUEST:
    case API_GET_DOCUMENT_TEMPLATES_SUCCESS:
    case API_GET_DOCUMENT_TEMPLATES_FAILURE:
      return createNewState('getDocumentTemplates', state, action)
    case API_START_AGENT_SHIFT_REQUEST:
    case API_START_AGENT_SHIFT_SUCCESS:
    case API_START_AGENT_SHIFT_FAILURE:
      return createNewState('startAgentShift', state, action)
    case API_GET_AGENT_SHIFT_REQUEST:
    case API_GET_AGENT_SHIFT_SUCCESS:
    case API_GET_AGENT_SHIFT_FAILURE:
      return createNewState('getAgentShift', state, action)
    case API_ADD_NOTES_AGENT_SHIFT_REQUEST:
    case API_ADD_NOTES_AGENT_SHIFT_SUCCESS:
    case API_ADD_NOTES_AGENT_SHIFT_FAILURE:
      return createNewState('addNotesAgentShift', state, action)
    case API_END_AGENT_SHIFT_REQUEST:
    case API_END_AGENT_SHIFT_SUCCESS:
    case API_END_AGENT_SHIFT_FAILURE:
      return createNewState('endAgentShift', state, action)
    case API_GET_VOUCHERS_REQUEST:
    case API_GET_VOUCHERS_SUCCESS:
    case API_GET_VOUCHERS_FAILURE:
      return createNewState('getVouchers', state, action)
    case API_V2_UPDATE_FULFILLMENT_METHOD_FAILURE:
      return {
        ...state,
        agentLogin: {
          code: action.error.code,
          severity: MESSAGE_SEVERITY_WARNING,
          message: action.error.message,
          defaultMessage: 'messages.session-expired'
        }
      }
    case API_TICKET_VOID_VALIDATION_REQUEST:
    case API_TICKET_VOID_VALIDATION_SUCCESS:
    case API_TICKET_VOID_VALIDATION_FAILURE:
      return createNewState('ticketVoidValidation', state, action)
    case API_V2_UPDATE_PASSENGERS_REQUEST:
    case API_V2_UPDATE_PASSENGERS_SUCCESS:
    case API_V2_UPDATE_PASSENGERS_FAILURE:
      return createNewState('updatePassengers', state, action)
    default:
      return state
  }
}
