import { connect } from 'react-redux'
import { additionalProductsSelector } from 's3p-js-lib/src/redux/selectors/api/orientation/specification/products'
import containRebookingFlow from '../../../aftersales/rebooking-flow'
import NextButton, { MODE_NEXT, MODE_SKIP } from '../../../../components/base/additional-products/next-button'

const mapStateToProps = state => ({
  isLoading: state.api.loading.updateSelectBundle || state.api.loading.rebookingCreateBooking,
  mode: additionalProductsSelector(state).length ? MODE_NEXT : MODE_SKIP
})

export default connect(mapStateToProps)(containRebookingFlow(NextButton))
