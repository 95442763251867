import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { carriageSelector } from 's3p-js-lib/src/redux/selectors/user-input/base/seat-selector'
import { selectCarriage } from '../../../../redux/actions/user-input/base/seat-selector'
import Carriage from '../../../../components/base/seat-selector/carriage-selector/carriage'
import { EMPTY_OBJECT } from 's3p-js-lib/src/constants'

class CarriageContainer extends Component {
  static propTypes = {
    selectCarriage: PropTypes.func.isRequired,
    carriageNumber: PropTypes.string.isRequired,
    aftersales: PropTypes.bool
  }

  constructor (...args) {
    super(...args)
    this.handleSelect = this.handleSelect.bind(this)
  }

  handleSelect () {
    this.props.selectCarriage(this.props.carriageNumber)
  }

  render () {
    return <Carriage {...this.props} handleSelect={this.handleSelect} />
  }
}

const mapStateToProps = () => {
  const _carriageSelector = carriageSelector()

  return (state, ownProps) => _carriageSelector(state, ownProps) || EMPTY_OBJECT
}

const mapDispatchToProps = {
  selectCarriage
}

export default connect(mapStateToProps, mapDispatchToProps)(CarriageContainer)
