import { compose } from 'redux'
import { connect } from 'react-redux'
import { overviewBookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import containVisibleElement from '../../../../containers/base/visible-element'
import { isLoadingToolbarActionsSelector } from '../../../../redux/selectors/containers/aftersales/booking/toolbar'
import ToolbarItem from '../../../../components/aftersales/booking/toolbar/toolbar-item'
import { isManualConfirmationAvailableSelector } from '../../../../redux/selectors/containers/base/aftersales'
import { confirmBooking } from 's3p-js-lib/src/redux/actions/api/v2/confirm-booking'
import {reprintResendBooking} from '../../../../redux/actions/containers/aftersales/booking/reprint-resend'

const _isManualConfirmationAvailableSelector = isManualConfirmationAvailableSelector(overviewBookingSelector)

const mapStateToProps = state => ({
  loading: state.api.loading.cancellationInitiate,
  disabled: isLoadingToolbarActionsSelector(state),
  available: _isManualConfirmationAvailableSelector(state)
})

const mapDispatchToProps = (_, ownProps) => dispatch => ({
  onClick: async () => {
    if (await dispatch(confirmBooking(ownProps.bookingNumber))) {
      dispatch(reprintResendBooking())
    }
  }
})

const container = compose(
  connect(mapStateToProps, mapDispatchToProps),
  containVisibleElement()
)

export default container(ToolbarItem)
