import pick from 'lodash/pick'
import {
  API_REQUEST_REFUND_REQUEST,
  API_REQUEST_REFUND_SUCCESS,
  API_REQUEST_REFUND_FAILURE,
  CLEAR_STATE
} from '../../../actions/types'

export const initialState = null

export default function refund (state = initialState, action) {
  switch (action.type) {
    case API_REQUEST_REFUND_REQUEST:
    case API_REQUEST_REFUND_FAILURE:
      return initialState
    case API_REQUEST_REFUND_SUCCESS:
      return (
        action.response.data.voucher && pick(action.response.data.voucher, ['code', 'description', 'amount', 'currency'])
      ) || initialState
    case CLEAR_STATE:
      return action.namespaces.includes('api.payment.refundVoucher') ? initialState : state
    default:
      return state
  }
}
