import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { pendingTotalPriceToBePaidSelector } from '../../../../selectors/api/booking/booking'
import { displayFormatAmount } from '../../../../../misc/utils'
import _t from 's3p-js-lib/src/translate'
import { addVoucher as addVoucherApi } from 's3p-js-lib/src/redux/actions/api/payment/vouchers'
import { sendMachineDisplayLines } from '../../../machine/display'
import { pendingPaymentOrConfirmBooking, showFinalizeBookingModal } from '../tiles/payment-methods'
import { showElement } from '../visible-element'
import { SIDEBAR_CART } from '../../../../../constants'
import { paymentsWithoutFailedSelector } from '../../../../selectors/api/booking/payments'

export const addVoucher = voucherCode => async (dispatch, getState) => {
  await dispatch(addVoucherApi(voucherCode))

  const state = getState()
  if (state.containers.base.paymentModal.voucherForm.success) {
    const payments = paymentsWithoutFailedSelector(bookingSelector)(state)
    const amountToBePaid = pendingTotalPriceToBePaidSelector(bookingSelector)(state)

    const voucherPayment = payments.find(payment => payment.voucher && payment.voucher.code === voucherCode)
    const amount = voucherPayment ? voucherPayment.voucher.amount : 0.0

    const displayAmountText = `machine.customer-display.lines.payment-display.voucher.lower.${amountToBePaid > 0 ? 'amount-left' : 'change'}`

    dispatch([
      showElement(SIDEBAR_CART),
      sendMachineDisplayLines([
        displayFormatAmount(_t.message('machine.customer-display.lines.payment-display.voucher.upper'), amount),
        displayFormatAmount(_t.message(displayAmountText), amountToBePaid)
      ])
    ])

    const result = await dispatch(pendingPaymentOrConfirmBooking())
    result && dispatch(showFinalizeBookingModal())
  }
}
