import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import './toolbar.scss'

export default class Toolbar extends Component {
  static propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    ariaLabel: PropTypes.string.isRequired
  }

  render () {
    const {className, name, ariaLabel, children, ...rest} = this.props
    return (
      <div
        {...rest}
        className={classNames('toolbar', name ? `toolbar-${name}` : '', className)}
        role='toolbar'
        aria-label={ariaLabel}
      >
        {children}
      </div>
    )
  }
}
