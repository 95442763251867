import { connect } from 'react-redux'
import {
  fulfillmentMethodSelector, overviewBookingSelector, bookingNumberSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import ProgressNavigation from '../../../../components/aftersales/change-fulfillment/progress-navigation'
import { canOverrideValidationRulesFullOrIssueSelector } from '../../../../redux/selectors/api/auth/auth'
import { isMagstripeOrTVMFulfillment, isLeapTopUpFulfillment } from '../../../../misc/utils'
import { goToAftersalesRebookingJourneySearch } from '../../../../redux/actions/containers/base/routing'
import { rebookingInitiate } from 's3p-js-lib/src/redux/actions/api/aftersales/rebooking/initiate'

const _fulfillmentMethodSelector = fulfillmentMethodSelector(overviewBookingSelector)
const _canOverrideValidationRulesFullOrIssueSelector = canOverrideValidationRulesFullOrIssueSelector(overviewBookingSelector)
const _bookingNumberSelector = bookingNumberSelector(overviewBookingSelector)

const mapStateToProps = state => {
  const fulfillmentMethod = _fulfillmentMethodSelector(state)

  let nextButtonDisabled = !_canOverrideValidationRulesFullOrIssueSelector(state)

  if (isMagstripeOrTVMFulfillment(fulfillmentMethod)) {
    nextButtonDisabled = (nextButtonDisabled && !state.containers.aftersales.voidTickets.success)
  } else if (isLeapTopUpFulfillment(fulfillmentMethod)) {
    nextButtonDisabled = (nextButtonDisabled && !state.containers.aftersales.cancellation.reverseLeap.success)
  }
  return ({
    loading: state.containers.aftersales.voidTickets.isRunning,
    disabled: nextButtonDisabled
  })
}
const mapDispatchToProps = {
  onClickNext: () => async (dispatch, getState) => {
    const bookingNumber = _bookingNumberSelector(getState())
    await dispatch(rebookingInitiate(bookingNumber))
    dispatch(goToAftersalesRebookingJourneySearch())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressNavigation)

