import {
  API_GET_BOOKING_REQUEST,
  API_GET_BOOKING_SUCCESS,
  API_GET_BOOKING_FAILURE
} from '../../types'
import ResponseCodes from '../../../../api/response-codes'
import Client from '../../../../../../src/api/client'
import ensureToken from '../../../../api/ensure-token'

const getBookingRequest = bookingNumber => ({type: API_GET_BOOKING_REQUEST, bookingNumber})
const getBookingSuccess = response => ({type: API_GET_BOOKING_SUCCESS, response})
const getBookingFailure = error => ({type: API_GET_BOOKING_FAILURE, error})

export const getBooking = bookingNumber => ensureToken(async (token, dispatch) => {
  try {
    dispatch(getBookingRequest(bookingNumber))
    const response = await Client.getBooking(token, bookingNumber)
    dispatch(getBookingSuccess(response))
  } catch (error) {
    dispatch(getBookingFailure(error))
    const handledErrors = [
      ResponseCodes.BOOKING_NOT_FOUND_FOR_BOOKING_NUMBER,
      ResponseCodes.BOOKING_NOT_ACCESSIBLE_FOR_CURRENT_USER
    ]
    if (handledErrors.includes(error.errorCode)) {
      return false
    }
    throw error
  }
})
