import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import Label from '../../../../elements/label/label'
import Indicator from '../../../../elements/indicator/indicator'
import TextLabel from '../../../../elements/text-label/text-label'
import _t from 's3p-js-lib/src/translate'

export default class SelectableItem extends Component {
  static propTypes = {
    onToggleItem: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
    confirm: PropTypes.bool.isRequired,
    item: PropTypes.shape({
      itemId: PropTypes.string.isRequired,
      productName: PropTypes.string.isRequired,
      passenger: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        cancelled: PropTypes.bool
      }).isRequired,
      price: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      cancelled: PropTypes.bool.isRequired,
      canBeCancelled: PropTypes.bool.isRequired
    }).isRequired
  }

  constructor (...args) {
    super(...args)
    this.onToggleItem = this.onToggleItem.bind(this)
  }

  onToggleItem () {
    this.props.onToggleItem(this.props.item)
  }

  render () {
    const {item, selected, disabled, required, confirm} = this.props
    const available = !disabled && !confirm && !item.cancelled
    const className = classNames(
      'segment-selectable-item',
      {'state--is-available': available},
      {'state--is-disabled': !available},
      {'state--is-selected': selected && !confirm}
    )
    const id = `ticket-${item.itemId}`
    const onChange = available ? this.onToggleItem : null
    const passengerName = item.passenger.firstName
      ? `${item.passenger.firstName} ${item.passenger.lastName}`
      : _t.message('aftersales.cancellation.passenger-unknown-name', {number: item.passenger.number})
    const productName = required && item.productFamily ? item.productFamily.name : item.productName

    return !confirm || item.provisional
      ? (
        <div className={className}>
          <div className='segment-selectable-item__type'>
            {confirm
              ? <TextLabel name='product' text={productName} />
              : (
                <div className='check-radio-wrapper'>
                  <input
                    disabled={!available}
                    type='checkbox'
                    name={id}
                    id={id}
                    checked={selected}
                    onChange={onChange}
                  />
                  <Label name='product' htmlFor={id} text={productName} />
                  {item.cancelled
                    ? (
                      <Label name='cancelled' htmlFor={id} text={_t.message('aftersales.cancellation.individual-items.cancelled')} />
                    ) : null}
                </div>
              )}
          </div>
          <div className='segment-selectable-item__passenger'>
            <TextLabel name='passenger-name' text={passengerName} />
            {confirm
              ? <Indicator
                name='status'
                className='cancelled'
                text={_t.message('aftersales.cancellation.individual-items.status-indicator-confirm')}
              />
              : selected
                ? <Indicator
                  name='status'
                  className='provisional'
                  text={_t.message('aftersales.cancellation.individual-items.status-indicator-operation')}
                /> : null}
          </div>
          <div className='segment-selectable-item__price'>
            <TextLabel name='passenger-price' text={_t.formatCurrency(item.price, item.currency)} />
          </div>
        </div>
      ) : null
  }
}
