import {
  API_V2_GET_PRODUCTS_REQUEST,
  API_V2_GET_PRODUCTS_SUCCESS,
  API_V2_GET_PRODUCTS_FAILURE
} from 's3p-js-lib/src/redux/actions/types-v2'
import Client from '../../../../../api/client'
import ensureToken from 's3p-js-lib/src/api/ensure-token'

const getProductsRequest = data => ({type: API_V2_GET_PRODUCTS_REQUEST, data})
const getProductsSuccess = response => ({type: API_V2_GET_PRODUCTS_SUCCESS, response})
const getProductsFailure = error => ({type: API_V2_GET_PRODUCTS_FAILURE, error})

export const getProducts = data => ensureToken(async (token, dispatch) => {
  try {
    dispatch(getProductsRequest(data))
    const response = await Client.productSearchForTickets(token, data)
    dispatch(getProductsSuccess(response))
  } catch (error) {
    dispatch(getProductsFailure(error))
    throw error
  }
})
