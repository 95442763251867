import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Modal from '../../../elements/modal/modal'

export const BEFORE_DAY_CURRENT_MONTH_MODAL = 'before-day-current-month'

export default class BeforeDayCurrentMonth extends Component {
  static propTypes = {
    beforeDayCurrentMonth: PropTypes.string.isRequired
  }

  render () {
    return (
      <Modal
        name={BEFORE_DAY_CURRENT_MONTH_MODAL}
        header={<Modal.Header
          title={_t.message(`product-search.before-day-current-month-warning.title`)}
        />}
      >
        <p>{_t.message(`product-search.before-day-current-month-warning.information`, this.props)}</p>
      </Modal>
    )
  }
}
