import { createStructuredSelector } from 'reselect'
import { PASSENGER_TYPE_ADULT, PASSENGER_TYPE_STUDENT, PASSENGER_TYPE_CHILD } from 's3p-js-lib/src/constants'
import { salesChannelPropertySelector } from 's3p-js-lib/src/redux/selectors/api/user/sales-channel-properties'

const maxAdultsSelector = salesChannelPropertySelector('booking_max_adults')
const maxChildrenSelector = salesChannelPropertySelector('booking_max_children')

export const maxPerPassengerTypeSelector = createStructuredSelector({
  [PASSENGER_TYPE_ADULT]: maxAdultsSelector,
  [PASSENGER_TYPE_STUDENT]: maxAdultsSelector,
  [PASSENGER_TYPE_CHILD]: maxChildrenSelector
})
