import { createSelector } from 'reselect'
import {
  selectedSeatsSelector as baseSelectedSeatsSelector,
  selectedPassengerIdSelector,
  selectedCarriageNumberSelector
} from 's3p-js-lib/src/redux/selectors/user-input/base/seat-selector'
import uniq from 'lodash/uniq'

export const selectedSeatsSelector = seatSelectionOptionsSelector => createSelector(
  [
    (state, ownProps) => ownProps.segmentId,
    seatSelectionOptionsSelector,
    baseSelectedSeatsSelector
  ],
  (segmentId, seatSelectionOptions, selectedSeats) => {
    const seatSelectionOption = seatSelectionOptions && seatSelectionOptions.find(
      option => option.leg.id === segmentId
    )
    if (!seatSelectionOption) {
      return selectedSeats
    }
    const additionalSelectedSeats = seatSelectionOption.selectedSeats.filter(
      seat => selectedSeats.every(selectedSeat => selectedSeat.passengerId !== seat.passengerId)
    )

    return selectedSeats.concat(additionalSelectedSeats)
  }
)

export const hasSelectedSeatsSelector = seatSelectionOptionsSelector => createSelector(
  [
    seatSelectionOptionsSelector,
    baseSelectedSeatsSelector
  ],
  (seatSelectionOptions, selectedSeats) => Boolean(
    selectedSeats.length > 0 ||
    (seatSelectionOptions && seatSelectionOptions.some(option => option.seatsSelected))
  )
)

export const passengersSelector = (basePassengersSelector, seatSelectionOptionsSelector) => createSelector(
  [
    basePassengersSelector,
    selectedSeatsSelector(seatSelectionOptionsSelector),
    selectedPassengerIdSelector
  ],
  (passengers, selectedSeats, selectedPassengerId) => {
    if (!passengers) {
      return null
    }

    return passengers.map((passenger, index) => ({
      ...passenger,
      number: index + 1,
      seat: selectedSeats && selectedSeats.find(selectedSeat => selectedSeat.passengerId === passenger.id),
      selected: passenger.id === selectedPassengerId
    }))
  }
)

export const isSeatSelectionValidSelector = (basePassengersSelector, seatSelectionOptionsSelector) => createSelector(
  [
    selectedSeatsSelector(seatSelectionOptionsSelector),
    passengersSelector(basePassengersSelector, seatSelectionOptionsSelector)
  ],
  (selectedSeats, passengers) => selectedSeats.length === passengers.length
)

export const hasPendingSeatSelectionSelector = state => baseSelectedSeatsSelector(state).length > 0

export const carriagesSelector = createSelector(
  [
    state => state.api.base.seatSelector.carriageLayouts && state.api.base.seatSelector.carriageLayouts.carriages,
    selectedCarriageNumberSelector,
    selectedPassengerIdSelector
  ],
  (carriages, selectedCarriageNumber, selectedPassengerId) => {
    if (!carriages) {
      return null
    }

    return carriages
      .sort((carriageA, carriageB) => carriageA.sequence_number - carriageB.sequence_number)
      .map(carriage => {
        const seatsAvailable = carriage.seats.reduce(
          (sum, seat) =>
            seat.available && !seat.blocked_passengers.includes(selectedPassengerId) ? sum + 1 : sum,
          0
        )

        return {
          template: carriage.template,
          number: carriage.carriage_number,
          available: !carriage.blocked && seatsAvailable > 0,
          selected: carriage.carriage_number === selectedCarriageNumber,
          seatsAvailable,
          properties: uniq(carriage.seats.reduce(
            (properties, seat) => {
              properties.push(...seat.property_codes)
              return properties
            }, []
          ))
        }
      })
  }
)

export const previousAvailableCarriageSelector = createSelector(
  [carriagesSelector],
  carriages => {
    if (!carriages) {
      return null
    }
    const selectedIndex = carriages.findIndex(({selected}) => selected)
    if (selectedIndex < 0) {
      return null
    }
    const previousCarriages = carriages.slice(0, selectedIndex)

    return previousCarriages.reverse().find(({available}) => available) || null
  }
)

export const nextAvailableCarriageSelector = createSelector(
  [carriagesSelector],
  carriages => {
    if (!carriages) {
      return null
    }
    const selectedIndex = carriages.findIndex(({selected}) => selected)
    if (selectedIndex < 0) {
      return null
    }
    const nextCarriages = carriages.slice(selectedIndex + 1)

    return nextCarriages.find(({available}) => available) || null
  }
)

export const activeCarriageSelector = createSelector(
  [carriagesSelector],
  carriages => (carriages || []).find(carriage => carriage.selected)
)

export const seatsSelector = (basePassengersSelector, seatSelectionOptionsSelector) => createSelector(
  [
    state => state.api.base.seatSelector.carriageLayouts && state.api.base.seatSelector.carriageLayouts.carriages,
    basePassengersSelector,
    selectedPassengerIdSelector,
    selectedCarriageNumberSelector,
    selectedSeatsSelector(seatSelectionOptionsSelector)
  ],
  (carriages, passengers, selectedPassengerId, selectedCarriageNumber, selectedSeats) => {
    const carriage = carriages && carriages.find(carriage => carriage.carriage_number === selectedCarriageNumber)
    if (!carriage) {
      return null
    }

    return carriage.seats.map(seat => {
      const result = {
        number: seat.seat_number,
        available: seat.available && !seat.blocked_passengers.includes(selectedPassengerId),
        propertyCodes: seat.property_codes,
        position: {
          x: seat.x_pos,
          y: seat.y_pos,
          reversed: (carriage.reversed && !seat.reversed) || (!carriage.reversed && seat.reversed)
        }
      }

      const selectedSeat = selectedSeats.find(selectedSeat =>
        selectedSeat.carriageNumber === carriage.carriage_number &&
        selectedSeat.seatNumber === seat.seat_number
      )
      if (selectedSeat) {
        const passengerIndex = passengers.findIndex(passenger => passenger.id === selectedSeat.passengerId)
        result.selectedByPassenger = {
          id: passengers[passengerIndex].id,
          number: passengerIndex + 1
        }
      }

      return result
    })
  }
)

export const segmentsSelector = seatSelectionOptionsSelector => createSelector(
  [
    (state, ownProps) => ownProps.segmentId,
    seatSelectionOptionsSelector
  ],
  (segmentId, seatSelectionOptions) => (seatSelectionOptions || []).map(seatSelectionOption => ({
    ...seatSelectionOption,
    active: seatSelectionOption.leg.id === segmentId
  }))
)

export const nextPassengerSelector = basePassengersSelector => createSelector(
  [
    selectedPassengerIdSelector,
    basePassengersSelector
  ],
  (currentPassengerId, passengers) => {
    const nextPassengerIndex = passengers.findIndex(passenger => passenger.id === currentPassengerId) + 1

    return passengers[nextPassengerIndex] ? passengers[nextPassengerIndex].id : null
  }
)
