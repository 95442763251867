import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../elements/text-label/text-label'

export default class PriceSummary extends Component {
  static propTypes = {
    passengersCount: PropTypes.number,
    price: PropTypes.number,
    priceLabel: PropTypes.string
  }

  static defaultProps = {
    priceLabel: 'booking-summary.price-summary.total-price'
  }

  render () {
    return (
      <div className='price-summary'>
        <div className='total'>
          {this.props.passengersCount
            ? (
              <div className='total__passengers'>
                <TextLabel
                  name='total-passenger-amount'
                  text={_t.message(
                    'booking-summary.price-summary.passenger-amount',
                    {passengersCount: this.props.passengersCount}
                  )}
                />
              </div>
            ) : null}
          {typeof this.props.price !== 'undefined'
            ? (
              <div className='total__price'>
                <TextLabel
                  name='total-price-description'
                  text={_t.message(this.props.priceLabel)}
                />
                <TextLabel
                  name='total-price-amount'
                  text={_t.formatCurrency(this.props.price)}
                />
              </div>
            ) : null}
        </div>
      </div>
    )
  }
}
