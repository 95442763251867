import React, { Component } from 'react'
import PropTypes from '../../../../misc/prop-types'
import _t from 's3p-js-lib/src/translate'
import BaseSegmentSet from '../../../../elements/segment-set/segment-set'
import Segment from './segment'

export default class SegmentSet extends Component {
  static propTypes = {
    onToggleItem: PropTypes.any,
    disabled: PropTypes.bool.isRequired,
    confirm: PropTypes.bool.isRequired,
    travelInfo: PropTypes.shape({
      direction: PropTypes.string.isRequired,
      departureDate: PropTypes.moment,
      departureStation: PropTypes.shape({
        name: PropTypes.string
      }),
      arrivalStation: PropTypes.shape({
        name: PropTypes.string
      })
    }),
    segments: PropTypes.arrayOf(PropTypes.shape({
      hasProvisionalItems: PropTypes.bool.isRequired
    })).isRequired
  }

  _renderSegments () {
    return this.props.segments.map(segment =>
      <Segment
        key={segment.id}
        segment={segment}
        onToggleItem={this.props.onToggleItem}
        disabled={this.props.disabled}
        confirm={this.props.confirm}
      />
    )
  }

  render () {
    const {segments, travelInfo, confirm} = this.props

    if (confirm && !segments.some(({hasProvisionalItems}) => hasProvisionalItems)) {
      return null
    }

    return travelInfo
      ? (
        <BaseSegmentSet
          direction={travelInfo.direction}
          summary={<BaseSegmentSet.JourneySummary
            travelDate={_t.message('aftersales.cancellation.individual-items.segment-set-header.date', {
              date: travelInfo.departureDate.format('ddd, D MMM')
            })}
            label={travelInfo.departureStation && travelInfo.arrivalStation ? _t.message('aftersales.cancellation.individual-items.segment-set-header.direction', {
              departureStation: travelInfo.departureStation.name,
              arrivalStation: travelInfo.arrivalStation.name
            }) : ''}
            direction={travelInfo.direction}
          />}
        >
          {this._renderSegments()}
        </BaseSegmentSet>
      ) : (
        <react-wrapper>
          {this._renderSegments()}
        </react-wrapper>
      )
  }
}
