import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Loader, { TYPE_BLOCKING } from '../../../elements/loader/loader'
import ComponentContainer from '../../../elements/component-container/component-container'
import Start from '../../../containers/agent/shift/start'
import Resume from '../../../containers/agent/shift/resume'
import End from '../../../containers/agent/shift/end'
import './shift.scss'

export const SHOW_SHIFT_START = 'SHOW_SHIFT_START'
export const SHOW_SHIFT_RESUME = 'SHOW_SHIFT_RESUME'
export const SHOW_SHIFT_END = 'SHOW_SHIFT_END'

export default class Shift extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    showShiftType: PropTypes.oneOf([SHOW_SHIFT_START, SHOW_SHIFT_RESUME, SHOW_SHIFT_END]).isRequired
  }

  render () {
    return this.props.loading
      ? <Loader type={TYPE_BLOCKING} />
      : (
        <ComponentContainer name='shift'>
          {this.props.showShiftType === SHOW_SHIFT_START ? <Start /> : null}
          {this.props.showShiftType === SHOW_SHIFT_RESUME ? <Resume /> : null}
          {this.props.showShiftType === SHOW_SHIFT_END ? <End /> : null}
        </ComponentContainer>
      )
  }
}
