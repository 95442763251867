import { createSelector } from 'reselect'
import { FULFILLMENT_METHOD_CODE_BARCODE, FULFILLMENT_METHOD_CODE_MAGSTRIPE } from '../../../../constants'

export const selectedReadTicketSelector = state => state.containers.aftersales.readTicket.selected

export const readTicketSelector = createSelector(
  [selectedReadTicketSelector],
  selectedReadTicket => ({
    magstripe: {
      code: FULFILLMENT_METHOD_CODE_MAGSTRIPE,
      selected: selectedReadTicket === FULFILLMENT_METHOD_CODE_MAGSTRIPE,
      available: true
    },
    barcode: {
      code: FULFILLMENT_METHOD_CODE_BARCODE,
      selected: selectedReadTicket === FULFILLMENT_METHOD_CODE_BARCODE,
      available: true
    }
  })
)
