import {
  API_AGENT_UPDATE_REQUEST,
  API_AGENT_UPDATE_SUCCESS,
  API_AGENT_UPDATE_FAILURE,
  CLEAR_STATE
} from '../../../../actions/types'
import { snakeCaseKeys } from '../../../../../misc/utils'

const updateAgent = (agents, newAgent) => {
  const index = agents && agents.findIndex(agent => agent.username === newAgent.username)
  if (agents && index >= 0) {
    const newAgents = [...agents]
    newAgents[index] = snakeCaseKeys(newAgent)
    return newAgents
  } else {
    return agents
  }
}

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_AGENT_UPDATE_FAILURE:
      return initialState
    case API_AGENT_UPDATE_REQUEST:
      return updateAgent(state, action.agent)
    case API_AGENT_UPDATE_SUCCESS:
      return updateAgent(state, action.response)
    case CLEAR_STATE:
      return action.namespaces.includes('api.user.agent.agents') ? initialState : state
    default:
      return state
  }
}
