import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ComponentContainer from '../../../elements/component-container/component-container'
import TextLabel from '../../../elements/text-label/text-label'
import ButtonGroup from '../../../elements/button-group/button-group'
import Button from '../../../elements/button/button'
import Panel from '../../../elements/panel/panel'
import Icon from '../../../elements/icon/icon'
import Feedback, { FEEDBACK_INFORMATION, FEEDBACK_WARNING } from '../../../elements/feedback/feedback'
import MagstripeData from '../../../containers/aftersales/read-ticket/magstripe-data'
import BarcodeData from '../../../containers/aftersales/read-ticket/barcode-data'
import { FULFILLMENT_METHOD_CODE_MAGSTRIPE, FULFILLMENT_METHOD_CODE_BARCODE } from '../../../constants'
import _t from 's3p-js-lib/src/translate'
import './read-ticket-output.scss'

export default class ReadTicketOutput extends Component {
  static propTypes = {
    selectedFulfillmentCode: PropTypes.string,
    isLoadingReadTicket: PropTypes.bool,
    showReadTicketButton: PropTypes.bool,
    showSelectFulfillment: PropTypes.bool,
    onReadTicket: PropTypes.func.isRequired,
    hasReadTicketError: PropTypes.bool,
    showGoToBookingButton: PropTypes.bool,
    onGoToBooking: PropTypes.func
  }

  renderReadTicketButton () {
    return (
      <Button
        name='read-ticket'
        className='primary'
        onClick={this.props.onReadTicket}
      >
        <TextLabel text={_t.message('aftersales.read-ticket.button.read-ticket')} />
      </Button>
    )
  }

  renderReadTicketStatus () {
    return (
      <div className='read-status'>
        <TextLabel name='status-indicator' className='animation-blink'>
          <Icon name='tickets' className='status-indicator align-left' />
          <TextLabel.Text text={_t.message('aftersales.read-ticket.please-present-ticket')} />
        </TextLabel>
      </div>
    )
  }

  renderGoToBookingButton () {
    return (
      <Button
        name='go-to-booking'
        className='primary'
        onClick={this.props.onGoToBooking}
      >
        <TextLabel text={_t.message('aftersales.read-ticket.button.go-to-booking')} />
      </Button>
    )
  }

  render () {
    const {
      selectedFulfillmentCode,
      isLoadingReadTicket,
      showReadTicketButton,
      showSelectFulfillment,
      hasReadTicketError,
      showGoToBookingButton
    } = this.props

    return (
      <ComponentContainer name='read-ticket-output'>
        <Panel
          header={
            <Panel.Header
              name='read-ticket'
              title={_t.message('aftersales.read-ticket.output.title', {fulfillment: selectedFulfillmentCode ? _t.message(`fulfillment-methods.${selectedFulfillmentCode}`) : null})}
              description={_t.message('aftersales.read-ticket.output.description')}
            />
          }
        >
          {showSelectFulfillment
            ? <Feedback
              status={FEEDBACK_INFORMATION}
              text={_t.message('aftersales.read-ticket.select-fulfillment-first')}
              className='read-ticket-select-fulfillment-first'
            />
            : null
          }

          <ButtonGroup>
            {showReadTicketButton ? this.renderReadTicketButton() : null}
            {showGoToBookingButton ? this.renderGoToBookingButton() : null}
          </ButtonGroup>

          {isLoadingReadTicket ? this.renderReadTicketStatus() : null}
          {hasReadTicketError
            ? <Feedback
              status={FEEDBACK_WARNING}
              text={_t.message('aftersales.read-ticket.default-error')}
            />
            : null
          }

          {selectedFulfillmentCode === FULFILLMENT_METHOD_CODE_MAGSTRIPE ? <MagstripeData isLoadingReadTicket={isLoadingReadTicket} /> : null}
          {selectedFulfillmentCode === FULFILLMENT_METHOD_CODE_BARCODE ? <BarcodeData isLoadingReadTicket={isLoadingReadTicket} /> : null}
        </Panel>
      </ComponentContainer>
    )
  }
}
