import React, { Component } from 'react'
import Fulfillment from '../../../containers/aftersales/booking/fulfillment'
import PaymentDetails from '../../../containers/aftersales/booking/payment-details/payment-details'
import ComponentContainer from '../../../elements/component-container/component-container'
import './fulfillment-payment-details.scss'

export default class FulfillmentPaymentDetails extends Component {
  render () {
    return (
      <ComponentContainer name='booking-fulfillment-payment-details'>
        <Fulfillment />
        <PaymentDetails />
      </ComponentContainer>
    )
  }
}
