import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ComponentContainer from '../../../elements/component-container/component-container'
import Panel from '../../../elements/panel/panel'
import Feedback, { FEEDBACK_SUCCESS } from '../../../elements/feedback/feedback'
import ButtonGroup from '../../../elements/button-group/button-group'
import Button from '../../../elements/button/button'
import Icon from '../../../elements/icon/icon'
import TextLabel from '../../../elements/text-label/text-label'
import Table from '../../../elements/table/table'
import NotesModal from '../../../containers/aftersales/booking/notes/notes-modal'
import NotesHeader from './notes/notes-header'
import NotesRow from './notes/notes-row'
import _t from 's3p-js-lib/src/translate'
import './notes.scss'

export default class Notes extends Component {
  static propTypes = {
    notes: PropTypes.array.isRequired,
    handleShowModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool,
    handleHideModal: PropTypes.func.isRequired,
    noteSaved: PropTypes.bool
  }

  render () {
    return (
      <ComponentContainer name='booking-notes'>
        <Panel
          header={
            <Panel.Header
              name='booking-notes'
              title={_t.message('aftersales.booking.notes.title')}
              description={_t.message('aftersales.booking.notes.description')}
            />
          }
          footer={
            <Panel.Footer>
              <ButtonGroup>
                <Button onClick={this.props.handleShowModal} name='add-note' className='tertiary'>
                  <Icon name='add' className='align-left' />
                  <TextLabel.Text text='Add note' />
                </Button>
              </ButtonGroup>
            </Panel.Footer>
          }
        >
          {this.props.noteSaved
            ? (
              <Feedback
                status={FEEDBACK_SUCCESS}
                text={_t.message('aftersales.booking.notes.saved-note')}
              />
            ) : null}
          {this.props.notes.length
            ? (
              <Table name='agent-notes' numColumns={4} zebraRows>
                <NotesHeader />
                <tbody>
                  {this.props.notes.map(
                    (note, index) => <NotesRow key={`note_${index}`} note={note} />
                  )}
                </tbody>
              </Table>
            ) : (
              <TextLabel name='information'>
                <Icon name='information' className='align-left' />
                <TextLabel.Text text={_t.message('aftersales.booking.notes.no-notes-found')} />
              </TextLabel>
            )
          }
          {this.props.showModal
            ? (
              <NotesModal
                handleHideModal={this.props.handleHideModal}
              />
            ) : null
          }
        </Panel>
      </ComponentContainer>
    )
  }
}
