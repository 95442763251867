import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import { toUtcDateMoment } from 's3p-js-lib/src/misc/date'
import Form from '../../../elements/form/form'
import Icon from '../../../elements/icon/icon'
import Button from '../../../elements/button/button'
import TextLabel from '../../../elements/text-label/text-label'
import AmountInputField from '../../../elements/amount-input/amount-input'
import ComponentContainer from '../../../elements/component-container/component-container'
import PassengerDetails from '../../../containers/base/passenger-details-search/passenger-details'
import PassengerPreferences from '../../../containers/base/journey-search/journey-search-form/passenger-preferences'
import MaxPassengerModal from '../max-passengers-modal'
import DateInputField from '../date-input-field/date-input-field'
import TimeSelector from './journey-search-form/time-selector'
import { EMPTY_OBJECT } from 's3p-js-lib/src/constants'
import { PASSENGER_TYPE_ADULT, PASSENGER_TYPE_STUDENT, PASSENGER_TYPE_CHILD } from '../../../constants'
import './journey-search.scss'

const FIELD_ID_ADULTS = 'adults'
const FIELD_ID_DESTINATION = 'destination'
const FIELD_ID_OUTBOUND_DATE = 'outbound-date'
const FIELD_ID_OUTBOUND_TIME = 'outbound-time'
const FIELD_ID_INBOUND_DATE = 'inbound-date'
const BUTTON_ID_SEARCH = 'search-button'
const ROUTE_BUNDLE_CLASS_NAME = 'product-family state--is-available'

export default class JourneySearchForm extends Component {
  static propTypes = {
    maxPerPassengerType: PropTypes.object,
    maxPassengers: PropTypes.number,
    showMaxPassengersModal: PropTypes.bool,
    disabled: PropTypes.bool,
    defaultOriginStation: PropTypes.object,
    valueAdult: PropTypes.number,
    valueChild: PropTypes.number,
    valueStudent: PropTypes.number,
    handleChangeAdult: PropTypes.func.isRequired,
    handleChangeChild: PropTypes.func.isRequired,
    handleChangeStudent: PropTypes.func.isRequired,
    handleChangeOutboundDate: PropTypes.func.isRequired,
    handleChangeInboundDate: PropTypes.func.isRequired,
    handleJourneySearch: PropTypes.func.isRequired,
    getOriginStations: PropTypes.func.isRequired,
    getDestinationStations: PropTypes.func.isRequired,
    isLoadingJourneySearch: PropTypes.bool.isRequired,
    focusSearchButton: PropTypes.bool,
    formName: PropTypes.string.isRequired,
    form: PropTypes.shape({
      valid: PropTypes.bool,
      fields: PropTypes.shape({
        outboundDate: PropTypes.object.isRequired,
        outboundTimeEarliest: PropTypes.object.isRequired,
        outboundTimeLatest: PropTypes.object.isRequired,
        inboundDate: PropTypes.object.isRequired,
        inboundTimeEarliest: PropTypes.object.isRequired,
        inboundTimeLatest: PropTypes.object.isRequired,
        origin: PropTypes.shape({
          onChange: PropTypes.func.isRequired
        }),
        destination: PropTypes.shape({
          onChange: PropTypes.func.isRequired
        }),
        passengers: PropTypes.shape({
          disabled: PropTypes.bool
        })
      })
    })
  }

  static defaultProps = {
    maxPerPassengerType: EMPTY_OBJECT
  }

  constructor (...args) {
    super(...args)
    this.today = toUtcDateMoment()
    this.handleJourneySearch = this.handleJourneySearch.bind(this)
  }

  componentDidMount () {
    if (this.props.focusSearchButton) {
      const element = document.getElementById(BUTTON_ID_SEARCH)
      element && setTimeout(() => element.focus(), 200)
    }
  }

  onCloseOutboundDate () {
    const element = document.getElementById(FIELD_ID_INBOUND_DATE)
    element && setTimeout(() => element.focus(), 200)
  }

  onCloseInboundDate () {
    const element = document.getElementById(FIELD_ID_OUTBOUND_TIME)
    element && setTimeout(() => element.focus(), 200)
  }

  async handleJourneySearch (values) {
    await this.props.handleJourneySearch(values)
    const elements = document.getElementsByClassName(ROUTE_BUNDLE_CLASS_NAME)
    elements && elements.length && setTimeout(() => elements[0].focus(), 200)
  }

  render () {
    return (
      <ComponentContainer name='journey-search'>
        {this.props.showMaxPassengersModal ? <MaxPassengerModal maxPassengers={this.props.maxPassengers} /> : null}
        <Form formContext='journey-search'>
          <Form.InputGroup name='journey-selector'>
            {this._renderStationSelectorField(this._getPropsOriginField())}
            {this._renderStationSelectorField(this._getPropsDestinationField())}
          </Form.InputGroup>
          <Form.InputGroup name='date-selector'>
            <Form.InputGroup name='date-selector-outbound'>
              <Form.InputWrapper name='label' hotkey='focusOutboundDate'>
                <Form.Label htmlFor='outbound-date' name='outbound-date' />
              </Form.InputWrapper>
              <Form.InputWrapper name='input'>
                <div className='date-time-selector'>
                  <DateInputField
                    {...this.props.form.fields.outboundDate}
                    hotkey='focusValidFrom'
                    disabled={this.props.disabled || this.props.form.fields.outboundDate.disabled}
                    id={FIELD_ID_OUTBOUND_DATE}
                    name='outbound-date'
                    minDate={this.today}
                    title={_t.message('journey-search.form.outbound-date.title')}
                    placeholder={_t.message('journey-search.form.outbound-date.placeholder')}
                    onCloseModal={this.onCloseOutboundDate}
                    onChange={this.props.handleChangeOutboundDate}
                  />
                  <TimeSelector
                    id='outbound-time'
                    name='outbound'
                    title={_t.message('journey-search.form.outbound-date.title')}
                    placeholder={_t.message('journey-search.form.outbound-date.placeholder')}
                    earliestTimeField={this.props.form.fields.outboundTimeEarliest}
                    latestTimeField={this.props.form.fields.outboundTimeLatest}
                    disabled={this.props.disabled || this.props.form.fields.outboundDate.disabled}
                  />
                </div>
              </Form.InputWrapper>
            </Form.InputGroup>
            <Form.InputGroup name='date-selector-inbound'>
              <Form.InputWrapper name='label'>
                <Form.Label htmlFor='inbound-date' name='inbound-date' />
              </Form.InputWrapper>
              <Form.InputWrapper name='input'>
                <div className='date-time-selector'>
                  <DateInputField
                    {...this.props.form.fields.inboundDate}
                    hotkey='focusInboundDate'
                    disabled={this.props.disabled || this.props.form.fields.inboundDate.disabled}
                    id={FIELD_ID_INBOUND_DATE}
                    name='inbound-date'
                    required={false}
                    title={_t.message('journey-search.form.inbound-date.title')}
                    placeholder={_t.message('journey-search.form.inbound-date.placeholder')}
                    minDate={this.props.form.fields.outboundDate.value}
                    onCloseModal={this.onCloseInboundDate}
                    onChange={this.props.handleChangeInboundDate}
                  />
                  <TimeSelector
                    id='inbound-time'
                    name='inbound'
                    earliestTimeField={this.props.form.fields.inboundTimeEarliest}
                    latestTimeField={this.props.form.fields.inboundTimeLatest}
                    disabled={this.props.disabled || this.props.form.fields.inboundDate.disabled}
                  />
                </div>
              </Form.InputWrapper>
            </Form.InputGroup>
          </Form.InputGroup>
          <Form.InputGroup name='passenger-selector'>
            <Form.InputGroup name='adults'>
              <Form.InputWrapper name='label'>
                <Form.Label htmlFor='adults' name='adults' />
              </Form.InputWrapper>
              <Form.InputWrapper name='input'>
                <AmountInputField
                  name='adults'
                  id={FIELD_ID_ADULTS}
                  onChange={this.props.handleChangeAdult}
                  disabled={this.props.disabled || this.props.form.fields.passengers.disabled}
                  value={this.props.valueAdult}
                  placeholder={_t.message('journey-search.form.adults.placeholder')}
                  max={this.props.maxPerPassengerType[PASSENGER_TYPE_ADULT]}
                  hotkey={{
                    increment: 'incrementAdult',
                    decrement: 'decrementAdult'
                  }}
                />
              </Form.InputWrapper>
            </Form.InputGroup>
            <Form.InputGroup name='children'>
              <Form.InputWrapper name='label'>
                <Form.Label htmlFor='children' name='children' />
              </Form.InputWrapper>
              <Form.InputWrapper name='input'>
                <AmountInputField
                  name='children'
                  id='children'
                  onChange={this.props.handleChangeChild}
                  disabled={this.props.disabled || this.props.form.fields.passengers.disabled}
                  value={this.props.valueChild}
                  placeholder={_t.message('journey-search.form.children.placeholder')}
                  max={this.props.maxPerPassengerType[PASSENGER_TYPE_CHILD]}
                  hotkey={{
                    increment: 'cancel',
                    decrement: 'decrementChild'
                  }}
                />
              </Form.InputWrapper>
            </Form.InputGroup>
            <Form.InputGroup name='students'>
              <Form.InputWrapper name='label'>
                <Form.Label htmlFor='students' name='students' />
              </Form.InputWrapper>
              <Form.InputWrapper name='input'>
                <AmountInputField
                  name='students'
                  id='students'
                  onChange={this.props.handleChangeStudent}
                  disabled={this.props.disabled || this.props.form.fields.passengers.disabled}
                  value={this.props.valueStudent}
                  placeholder={_t.message('journey-search.form.students.placeholder')}
                  max={this.props.maxPerPassengerType[PASSENGER_TYPE_STUDENT]}
                  hotkey={{
                    increment: 'incrementStudent',
                    decrement: 'decrementStudent'
                  }}
                />
              </Form.InputWrapper>
            </Form.InputGroup>
          </Form.InputGroup>
          <Form.InputGroup name='controls'>
            <Form.InputWrapper name='bike-availability'>
              <PassengerPreferences />
            </Form.InputWrapper>
            <PassengerDetails formName={this.props.formName} />
            <Form.InputWrapper name='search'>
              <Button
                id={BUTTON_ID_SEARCH}
                type='button'
                name='search'
                className='primary'
                onClick={this.handleJourneySearch}
                loading={this.props.isLoadingJourneySearch}
                disabled={!this.props.form.valid || this.props.disabled}
                hotkey='proceed'
              >
                <Icon name='search' className='align-left' />
                <TextLabel text={_t.message('journey-search.search-button')} name='search' />
              </Button>
            </Form.InputWrapper>
          </Form.InputGroup>
        </Form>
      </ComponentContainer>
    )
  }

  handleStationChange (id, onChange, value) {
    onChange(value)
    const element = document.getElementById(id)
    element && setTimeout(() => element.focus(), 200)
  }

  _renderStationSelectorField (props) {
    return (
      <Form.InputGroup name={props.name}>
        <Form.InputWrapper
          name='label'
          className={!props.valid && props.touched ? 'state--is-invalid' : ''}
        >
          <Form.Label htmlFor={props.name} name={props.name} />
        </Form.InputWrapper>
        <Form.InputWrapper
          name='input'
          hotkey={props.hotkey}
        >
          <Form.StationSelect
            {...props}
            disabled={props.disabled || this.props.disabled}
            autoComplete='off'
          />
        </Form.InputWrapper>
      </Form.InputGroup>
    )
  }

  _getPropsOriginField () {
    const {onChange, ...field} = this.props.form.fields.origin
    return {
      ...field,
      fieldName: 'origin',
      id: 'origin',
      autoFocus: !this.props.defaultOriginStation && !this.props.focusSearchButton,
      hotkey: 'focusOrigin',
      onChange: this.handleStationChange.bind(null, `${FIELD_ID_DESTINATION}_input`, onChange),
      stations: this.props.getOriginStations() || []
    }
  }

  _getPropsDestinationField () {
    const {onChange, ...field} = this.props.form.fields.destination
    return {
      ...field,
      fieldName: 'destination',
      id: FIELD_ID_DESTINATION,
      autoFocus: Boolean(this.props.defaultOriginStation) && !this.props.focusSearchButton,
      hotkey: 'focusDestination',
      onChange: this.handleStationChange.bind(null, FIELD_ID_OUTBOUND_DATE, onChange),
      stations: this.props.getDestinationStations() || []
    }
  }
}
