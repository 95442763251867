import PropTypes from 'prop-types'
import React, { Component } from 'react'
import GenericLayout from '../../generic-layout'
import GridLayout from '../../../elements/grid/grid-layout'
import GridColumn from '../../../elements/grid/grid-column'
import MainMenu from '../../../containers/base/main-menu/main-menu'
import SeatSelector from '../../../components/aftersales/rebooking/seat-selection/seat-selector'

export default class reservationsSeatSelectorLayout extends Component {
  static propTypes = {
    params: PropTypes.shape({
      segmentId: PropTypes.string.isRequired
    }).isRequired
  }

  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <MainMenu />
            <SeatSelector segmentId={this.props.params.segmentId} />
          </GridColumn>
        </GridLayout>
      </GenericLayout>
    )
  }
}
