import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Modal from '../../../elements/modal/modal'
import Button from '../../../elements/button/button'
import TextLabel from '../../../elements/text-label/text-label'

const CREDIT_DEBIT_RETRY_BUTTON_ID = 'credit-debit-retry'

export default class CreditDebitFooter extends Component {
  static propTypes = {
    handlePaymentReceived: PropTypes.func,
    handleRetry: PropTypes.func,
    handleValidate: PropTypes.func,
    handleDecline: PropTypes.func,
    handleRetryPrint: PropTypes.func,
    handleRetrySignaturePrint: PropTypes.func,
    handleRetryPedFailedReceipt: PropTypes.func,
    handleAbandonOperation: PropTypes.func,
    isLoadingPaymentReceived: PropTypes.bool,
    isLoadingValidate: PropTypes.bool,
    isLoadingDecline: PropTypes.bool,
    isLoadingRetryPrint: PropTypes.bool,
    disablePaymentReceived: PropTypes.bool,
    disableRetry: PropTypes.bool,
    disableValidate: PropTypes.bool,
    disableDecline: PropTypes.bool,
    showValidationButtons: PropTypes.bool,
    showRetryPrintButton: PropTypes.bool,
    showRetryPedFailedButton: PropTypes.bool,
    showAbandonOperation: PropTypes.bool
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.disableRetry && !nextProps.disableRetry && !nextProps.showValidationButtons) {
      const element = document.getElementById(CREDIT_DEBIT_RETRY_BUTTON_ID)
      element && setTimeout(() => element.focus(), 200)
    }
  }

  render () {
    return (
      <Modal.CustomFooter>
        {this.props.showValidationButtons ? (
          <Button
            name='retry-signature-print'
            className='tertiary'
            disabled={this.props.isLoadingDecline || this.props.isLoadingValidate}
            loading={this.props.isLoadingRetryPrint}
            onClick={this.props.handleRetrySignaturePrint}
          >
            <TextLabel name='retry-print' text={_t.message('credit-debit-payment.retry-signature-print')} />
          </Button>
        ) : null}
        {this.props.showRetryPedFailedButton ? (
          <Button
            name='retry-failed-ped-print'
            className='tertiary'
            disabled={this.props.disableRetry}
            loading={this.props.isLoadingRetryPrint}
            onClick={this.props.handleRetryPedFailedReceipt}
            hotkey={this.props.showValidationButtons && !this.props.showRetryPrintButton ? 'refresh' : null}
          >
            <TextLabel name='retry-print' text={_t.message('credit-debit-payment.retry-ped-failed-print')} />
          </Button>
        ) : null}
        {this.props.showRetryPrintButton ? (
          <Button
            name='retry-print'
            className='tertiary'
            loading={this.props.isLoadingRetryPrint}
            onClick={this.props.handleRetryPrint}
            hotkey={this.props.showValidationButtons && !this.props.showRetryPedFailedButton ? 'refresh' : null}
          >
            <TextLabel name='retry-print' text={_t.message('credit-debit-payment.retry-print')} />
          </Button>
        ) : null}
        {this.props.showValidationButtons ? (
          <Button
            name='decline'
            className='secondary'
            disabled={this.props.disableDecline}
            loading={this.props.isLoadingDecline}
            onClick={this.props.handleDecline}
          >
            <TextLabel name='decline' text={_t.message('credit-debit-payment.decline')} />
          </Button>
        ) : (
          <Button
            id={CREDIT_DEBIT_RETRY_BUTTON_ID}
            name='retry'
            className='secondary'
            disabled={this.props.disableRetry}
            onClick={this.props.handleRetry}
            hotkey='refresh'
          >
            <TextLabel name='retry' text={_t.message('credit-debit-payment.retry')} />
          </Button>
        )}
        {this.props.showAbandonOperation ? (
          <Button
            name='abandon-operation'
            className='tertiary'
            disabled={this.props.isLoadingPaymentReceived}
            onClick={this.props.handleAbandonOperation}
          >
            <TextLabel name='retry-print' text={_t.message('credit-debit-payment.abandon-operation')} />
          </Button>
        ) : null}
        {this.props.showValidationButtons ? (
          <Button
            name='validate'
            className='primary'
            disabled={this.props.disableValidate}
            loading={this.props.isLoadingValidate}
            onClick={this.props.handleValidate}
            hotkey='focusValidFrom'
          >
            <TextLabel name='validate' text={_t.message('credit-debit-payment.validate')} />
          </Button>
        ) : (
          <Button
            name='payment-received'
            className='primary'
            disabled={this.props.disablePaymentReceived}
            loading={this.props.isLoadingPaymentReceived}
            onClick={this.props.handlePaymentReceived}
            hotkey='proceed'
          >
            <TextLabel name='payment-received' text={_t.message('credit-debit-payment.payment-received')} />
          </Button>
        )}
      </Modal.CustomFooter>
    )
  }
}
