module.exports = {
  loadLocaleDataFile (locale, file) {
    try {
      delete require.cache[require.resolve(`../../../locale-data/${locale}/${file}.js`)]
      return require(`../../../locale-data/${locale}/${file}.js`).default
    } catch (e) {
      return {}
    }
  }
}
