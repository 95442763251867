import {
  MACHINE_START_PIN_PAYMENT_REQUEST,
  MACHINE_START_PIN_PAYMENT_SUCCESS,
  MACHINE_START_PIN_PAYMENT_FAILURE,
  MACHINE_COMPLETE_PIN_PAYMENT_REQUEST,
  MACHINE_COMPLETE_PIN_PAYMENT_SUCCESS,
  MACHINE_COMPLETE_PIN_PAYMENT_FAILURE,
  MACHINE_REFUND_PIN_PAYMENT_FAILURE,
  MACHINE_REFUND_PIN_PAYMENT_REQUEST,
  MACHINE_REFUND_PIN_PAYMENT_SUCCESS
} from '../types'
import DeviceManager from '../../../machine/device-manager'

const startPinPaymentRequest = data => ({type: MACHINE_START_PIN_PAYMENT_REQUEST, data})
const startPinPaymentSuccess = response => ({type: MACHINE_START_PIN_PAYMENT_SUCCESS, response})
const startPinPaymentFailure = machineError => ({type: MACHINE_START_PIN_PAYMENT_FAILURE, machineError})

const completePinPaymentRequest = data => ({type: MACHINE_COMPLETE_PIN_PAYMENT_REQUEST, data})
const completePinPaymentSuccess = response => ({type: MACHINE_COMPLETE_PIN_PAYMENT_SUCCESS, response})
const completePinPaymentFailure = machineError => ({type: MACHINE_COMPLETE_PIN_PAYMENT_FAILURE, machineError})

const refundPinPaymentRequest = () => ({type: MACHINE_REFUND_PIN_PAYMENT_REQUEST})
const refundPinPaymentSuccess = response => ({type: MACHINE_REFUND_PIN_PAYMENT_SUCCESS, response})
const refundPinPaymentFailure = machineError => ({type: MACHINE_REFUND_PIN_PAYMENT_FAILURE, machineError})

export const startPinPayment = data => async dispatch => {
  try {
    dispatch(startPinPaymentRequest(data))
    const response = await DeviceManager.startPinPayment(data)
    dispatch(startPinPaymentSuccess(response))
  } catch (error) {
    dispatch(startPinPaymentFailure(error))
  }
}

export const completePinPayment = data => async dispatch => {
  try {
    dispatch(completePinPaymentRequest(data))
    const response = await DeviceManager.completePinPayment(data)
    dispatch(completePinPaymentSuccess(response))
  } catch (error) {
    dispatch(completePinPaymentFailure(error))
  }
}

export const refundPinPayment = data => async dispatch => {
  try {
    dispatch(refundPinPaymentRequest())
    const response = await DeviceManager.refundPinPayment(data)
    dispatch(refundPinPaymentSuccess(response))
  } catch (error) {
    dispatch(refundPinPaymentFailure(error))
  }
}
