import { createSelector } from 'reselect'
import { camelCaseKeys } from '../../../../misc/utils'
import { toTimezoneMoment } from '../../../../misc/date'

export const bookingsSelector = createSelector(
  [state => state.api.booking.bookingList],
  bookings => bookings && bookings.map(booking => {
    const outbound = booking.outbound_route || booking.inbound_route
    const inbound = booking.outbound_route && booking.inbound_route ? booking.inbound_route : null

    return {
      ...camelCaseKeys(booking),
      bookingDateTime: toTimezoneMoment(booking.booking_date_time),
      outboundRoute: outbound
        ? {
          origin: outbound.origin,
          originUIC: outbound.origin_uic,
          destination: outbound.destination,
          destinationUIC: outbound.destination_uic,
          dateTime: toTimezoneMoment(outbound.date_time, outbound.timezone)
        }
        : null,
      inboundRoute: inbound
        ? {dateTime: toTimezoneMoment(inbound.date_time, inbound.timezone)}
        : null
    }
  })
)
