import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'
import {goToAftersalesBooking} from '../../../redux/actions/containers/base/routing'
import {elementVisibleSelector} from '../../../redux/selectors/containers/base/visible-element'
import {FINALIZE_BOOKING_MODAL} from '../../../constants'
import {
  completedBookingSelector,
  bookingNumberSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import {isMagstripeOrTVMFulfillment} from '../../../misc/utils'
import {
  fulfillmentMethodSelector,
  paymentRequiredSelector
} from '../../../../s3p-js-lib/src/redux/selectors/api/booking/booking'
import {bookingSelector} from '../../../../s3p-js-lib/src/redux/selectors/api/aftersales/booking'
import {hideElement, showModal} from '../../../redux/actions/containers/base/visible-element'
import {confirmBooking} from '../../../../s3p-js-lib/src/redux/actions/api/v2/confirm-booking'
import {goToChangeFulfillment} from '../../../redux/actions/containers/base/void-tickets-routing'
import FinalizeBooking from '../../base/finalize-booking/finalize-booking'
import {displayNextCustomer} from '../../../redux/actions/containers/base/finalize-booking/next-customer'

class FinalizeBookingContainer extends Component {
  static propTypes = {
    bookingNumber: PropTypes.string,
    finalizeBooking: PropTypes.bool,
    goToBooking: PropTypes.func.isRequired,
    isMagstripe: PropTypes.bool,
    onNextSale: PropTypes.func,
    onChangeFulfillment: PropTypes.func,
    onFinalizeBooking: PropTypes.func.isRequired
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.isMagstripe) {
      this.props.onFinalizeBooking()
    } else if (nextProps.finalizeBooking && nextProps.bookingNumber) {
      this.props.goToBooking()
    }
  }

  render () {
    return <FinalizeBooking
      aftersales
      onNextSale={this.props.onNextSale}
      onChangeFulfillment={this.props.onChangeFulfillment}
    />
  }
}

const _fulfillmentMethodSelector = fulfillmentMethodSelector(completedBookingSelector)
const finalizeBookingSelector = elementVisibleSelector(FINALIZE_BOOKING_MODAL)
const _bookingNumberSelector = bookingNumberSelector(completedBookingSelector)

const mapStateToProps = state => ({
  finalizeBooking: finalizeBookingSelector(state),
  bookingNumber: _bookingNumberSelector(state),
  isMagstripe: isMagstripeOrTVMFulfillment(_fulfillmentMethodSelector(state))
})

const mapDispatchToProps = {
  goToBooking: () => (dispatch, getState) => {
    dispatch([goToAftersalesBooking(_bookingNumberSelector(getState())), displayNextCustomer()])
  },

  onFinalizeBooking: () => async (dispatch, getState) => {
    if (!paymentRequiredSelector(bookingSelector)(getState()) && fulfillmentMethodSelector(bookingSelector)(getState())) {
      if (!_bookingNumberSelector(getState())) {
        await dispatch(confirmBooking())
      }
      dispatch(showModal(FINALIZE_BOOKING_MODAL))
    }
  },
  onChangeFulfillment: () => (dispatch, getState) => {
    dispatch([
      hideElement(FINALIZE_BOOKING_MODAL),
      goToChangeFulfillment(_bookingNumberSelector(getState()))
    ])
  },
  onNextSale: () => (dispatch, getState) => {
    dispatch([
      hideElement(FINALIZE_BOOKING_MODAL),
      goToAftersalesBooking(_bookingNumberSelector(getState())),
      displayNextCustomer()
    ])
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinalizeBookingContainer)
