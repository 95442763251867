import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import TextLabel from '../text-label/text-label'
import './panel.scss'

class PanelHeader extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    className: PropTypes.string
  }

  render () {
    const {name, title, type, description, className, children, ...props} = this.props
    return (
      <div {...props} className={classNames('panel__header', className)}>
        <TextLabel
          name={type || 'heading'}
          id={`${name}-heading`}
          text={title}
        />
        <TextLabel
          name='aria-invisible'
          id={`${name}-description`}
          text={description}
          aria-hidden='true'
        />
        {children}
      </div>
    )
  }
}

class PanelFooter extends Component {
  static propTypes = {
    className: PropTypes.string
  }

  render () {
    const {children, className, ...props} = this.props

    return (
      <div {...props} className={classNames('panel__footer', className)}>
        {children}
      </div>
    )
  }
}

class Panel extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    header: PropTypes.element,
    footer: PropTypes.element,
    className: PropTypes.string
  }

  static defaultProps = {
    loading: false
  }

  render () {
    return (
      <div className={classNames('panel', {'state--is-loading': this.props.loading}, this.props.className)}>
        {this.props.header}
        <div className='panel__content'>
          {this.props.children}
        </div>
        {this.props.footer}
      </div>
    )
  }
}

Panel.Header = PanelHeader
Panel.Footer = PanelFooter

export default Panel
