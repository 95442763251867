import {
  API_GET_LEAP_CONFIGURATION_DATA_REQUEST,
  API_GET_LEAP_CONFIGURATION_DATA_SUCCESS,
  API_GET_LEAP_CONFIGURATION_DATA_FAILURE,
  API_GET_LEAP_CONFIGURATION_DATA_SET_REQUEST,
  API_GET_LEAP_CONFIGURATION_DATA_SET_SUCCESS,
  API_GET_LEAP_CONFIGURATION_DATA_SET_FAILURE
} from '../../../../actions/types'
import { API_END_AGENT_SHIFT_SUCCESS, API_OAUTH_LOGOUT, CLEAR_STATE } from 's3p-js-lib/src/redux/actions/types'

const initialState = {
  data: null,
  dataSet: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case API_GET_LEAP_CONFIGURATION_DATA_REQUEST:
    case API_GET_LEAP_CONFIGURATION_DATA_FAILURE:
      return {
        ...state,
        data: initialState.data
      }
    case API_GET_LEAP_CONFIGURATION_DATA_SUCCESS:
      return {
        ...state,
        data: action.response.data
      }
    case API_GET_LEAP_CONFIGURATION_DATA_SET_REQUEST:
    case API_GET_LEAP_CONFIGURATION_DATA_SET_FAILURE:
      return {
        ...state,
        dataSet: initialState.dataSet
      }
    case API_GET_LEAP_CONFIGURATION_DATA_SET_SUCCESS:
      return {
        ...state,
        dataSet: action.response.data || null
      }
    case API_END_AGENT_SHIFT_SUCCESS:
    case API_OAUTH_LOGOUT:
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes('api.modules.leap.configuration') ? initialState : state
    default:
      return state
  }
}
