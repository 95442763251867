import Validator from './validator'
import moment from 'moment'
import {createSelector} from 'reselect'
import {formFieldValueSelectorCreator} from '../redux/selectors/user-input/base/form'
import {JOURNEY_SINGLE} from '../constants'

const inboundCannotBeBeforeOutbound = (value, {values}) => {
  return value && moment(value).isBefore(values.outboundDate) ? 'inbound-before-outbound' : null
}

const shouldHaveOnePassenger = value => {
  return !Array.isArray(value) ||
    value.length === 0 ? 'invalid-value' : null
}

const cannotBeSameStation = (value, {values}) =>
  value &&
    values.origin && values.destination &&
    values.origin.UICStationCode === values.destination.UICStationCode ? 'stations-are-equal' : null

export default createSelector([
  formFieldValueSelectorCreator('journeySearch', 'singleReturnSelector')
], singleReturnValue => {
  const isSingle = singleReturnValue === JOURNEY_SINGLE
  const returnRequiredValidators = !isSingle ? [Validator.isRequired] : []
  return {
    fields: {
      origin: {
        validators: [
          Validator.isRequired,
          Validator.isValidStationObject
        ]
      },
      destination: {
        validators: [
          Validator.isRequired,
          Validator.isValidStationObject,
          cannotBeSameStation
        ]
      },
      outboundDate: {
        validators: [
          Validator.isRequired,
          Validator.isValidDate,
          Validator.cannotBeInThePast
        ]
      },
      outboundTimeEarliest: {
        validators: [
          Validator.isTime
        ]
      },
      outboundTimeLatest: {
        validators: [
          Validator.isTime
        ]
      },
      inboundDate: {
        disabled: isSingle,
        validators: [
          Validator.isValidDate,
          inboundCannotBeBeforeOutbound,
          Validator.cannotBeInThePast
        ].concat(returnRequiredValidators)
      },
      inboundTimeEarliest: {
        disabled: isSingle,
        validators: [
          Validator.isTime
        ]
      },
      inboundTimeLatest: {
        disabled: isSingle,
        validators: [
          Validator.isTime
        ]
      },
      passengers: {
        validators: [
          shouldHaveOnePassenger,
          Validator.isValidPassenger
        ]
      },
      singleReturnSelector: {
        validators: [
          Validator.isRequired
        ]
      }
    }
  }
})
