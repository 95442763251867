import { createSelector } from 'reselect'

const combineSegments = segments => Object.values(segments.reduce(
  (groupedSegments, segment) => {
    if (segment.requiredProducts.some(({cancelled}) => !cancelled) ||
      segment.additionalProducts.some(({cancelled}) => !cancelled)
    ) {
      if (segment.uniqueIdentifier in groupedSegments) {
        groupedSegments[segment.uniqueIdentifier].requiredProducts.push(...segment.requiredProducts)
        groupedSegments[segment.uniqueIdentifier].additionalProducts.push(...segment.additionalProducts)
      } else {
        groupedSegments[segment.uniqueIdentifier] = {...segment}
      }
    }

    return groupedSegments
  },
  {}
))

const combineProducts = products => Object.values(products.reduce(
  (groupedProducts, product) => {
    if (!product.cancelled) {
      const key = [
        product.tariffCode,
        product.price,
        product.passenger && product.passenger.type
      ].join('|')

      if (key in groupedProducts) {
        groupedProducts[key].count++
      } else {
        groupedProducts[key] = {...product, count: 1}
      }
    }

    return groupedProducts
  },
  {}
))

export const segmentsSelector = baseSegmentsSelector => createSelector(
  [baseSegmentsSelector],
  segments => combineSegments(segments).map(segment => ({
    ...segment,
    requiredProducts: combineProducts(segment.requiredProducts),
    additionalProducts: combineProducts(segment.additionalProducts)
  }))
)

export const priceToBePaidSelector = bookingSelector => createSelector(
  [
    bookingSelector
  ],
  booking => booking ? booking.total_price_to_be_paid : null
)
