import { combineReducers } from 'redux'

export default function buildReducers (reducers) {
  if (typeof reducers === 'function') {
    return reducers
  } else if (typeof reducers === 'object' && reducers !== null) {
    return combineReducers(
      Object.keys(reducers).reduce(
        (finalReducers, reducerName) => {
          finalReducers[reducerName] = buildReducers(reducers[reducerName])
          return finalReducers
        },
        {}
      )
    )
  }
}
