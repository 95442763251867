import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TaskBar from '../../containers/base/taskbar/taskbar'
import StickyFooter from '../../elements/sticky-footer/sticky-footer'
import '../../elements/sticky-footer/sticky-footer-taskbar.scss'

export default class StickyFooterWithTaskbar extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired
  }

  render () {
    return (
      <StickyFooter>
        {this.props.children}
        <TaskBar />
      </StickyFooter>
    )
  }
}
