/* globals sessionStorage */

let sessionStorageExists

module.exports = {

  set (key, value) {
    if (typeof value === 'undefined' || value === null) {
      this.clear(key)
      return
    }

    if (this.sessionStorageExists()) {
      sessionStorage.setItem(key, JSON.stringify(this._serialize(value)))
    }
  },

  clear (key) {
    if (this.sessionStorageExists()) {
      sessionStorage.removeItem(key)
    }
  },

  has (key) {
    return this.sessionStorageExists() && sessionStorage.getItem(key) !== null
  },

  get (key, defaultValue) {
    if (this.sessionStorageExists()) {
      var value = sessionStorage.getItem(key)
      if (value !== null) {
        try {
          value = this._deserialize(JSON.parse(value))
        } catch (error) {
          value = null
        }
      }

      return value !== null ? value : defaultValue
    }
  },

  sessionStorageExists () {
    if (typeof sessionStorageExists !== 'boolean') {
      try {
        if (typeof sessionStorage !== 'object') {
          sessionStorageExists = false
        } else {
          // local storage exists, but can we write to it?
          try {
            sessionStorage.setItem('test', 'test')
            sessionStorage.removeItem('test')
            sessionStorageExists = true
          } catch (error) {
            sessionStorageExists = false
          }
        }
      } catch (error) {
        sessionStorageExists = false
      }
    }

    return sessionStorageExists
  },

  _serialize (data) {
    if (Array.isArray(data)) {
      return data.map(this._serialize.bind(this))
    } else if (data === null) {
      return null
    } else if (data instanceof Date) {
      return '[date]' + data
    } else if (typeof data === 'object') {
      return Object.keys(data).reduce(
        (result, key) => ({...result, [key]: this._serialize(data[key])}),
        {}
      )
    } else {
      return data
    }
  },

  _deserialize (data) {
    if (Array.isArray(data)) {
      return data.map(this._deserialize.bind(this))
    } else if (data === null) {
      return null
    } else if (typeof data === 'object') {
      return Object.keys(data).reduce(
        (result, key) => ({...result, [key]: this._deserialize(data[key])}),
        {}
      )
    } else if (typeof data === 'string' && data.indexOf('[date]') === 0) {
      return new Date(data.substring(6))
    } else {
      return data
    }
  }

}
