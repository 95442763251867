import { connect } from 'react-redux'
import { abandonPedOperationSelector } from '../../../redux/selectors/containers/base/payment-modal/payment-modal'
import { paymentResultSelector } from '../../../redux/selectors/machine/pin-payment'
import connectFormContainer from '../../base/form'
import CreditDebit from '../../../components/base/payment-modal/credit-debit'
import {
  FEEDBACK_ALERT,
  FEEDBACK_INFORMATION,
  FEEDBACK_SUCCESS,
  FEEDBACK_WARNING
} from '../../../elements/feedback/feedback'
import {
  PED_STATUS_AUTHORIZATION_REQUIRED,
  FORM_CREDIT_DEBIT,
  PED_STATUS_AWAITING_PAYMENT,
  PED_STATUS_DECLINED,
  PED_STATUS_NO_RESPONSE,
  PED_STATUS_FAILURE,
  PED_STATUS_SUCCESS,
  MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_PAYMENT_SUCCESS,
  MESSAGE_CREDIT_DEBIT_PAYMENT_PROCEED_PED_DEVICE,
  MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_AUTHORIZATION_DECLINED,
  MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_NO_RESPONSE_PED_DEVICE,
  MESSAGE_CREDIT_DEBIT_PAYMENT_RECEIPT_ERROR,
  MESSAGE_CREDIT_DEBIT_PAYMENT_RECEIPT_SIGNATURE_ERROR,
  MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_FAILURE_CODE,
  MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_API_ERROR,
  MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_MANUAL_REFUND
} from '../../../constants'

const MAP_PED_STATUS_FEEDBACK_OBJECT = {
  [PED_STATUS_SUCCESS]: {
    status: FEEDBACK_SUCCESS,
    text: MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_PAYMENT_SUCCESS
  },
  [PED_STATUS_AWAITING_PAYMENT]: {
    status: FEEDBACK_INFORMATION,
    text: MESSAGE_CREDIT_DEBIT_PAYMENT_PROCEED_PED_DEVICE
  },
  [PED_STATUS_DECLINED]: {
    status: FEEDBACK_WARNING,
    text: MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_AUTHORIZATION_DECLINED
  },
  [PED_STATUS_NO_RESPONSE]: {
    status: FEEDBACK_ALERT,
    text: MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_NO_RESPONSE_PED_DEVICE
  },
  [PED_STATUS_FAILURE]: {
    status: FEEDBACK_ALERT,
    text: MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_FAILURE_CODE
  }
}

const mapStateToProps = state => {
  const feedbackMessages = []
  const paymentResult = paymentResultSelector(state)
  const resultCode = paymentResult.result
  const creditDebit = state.containers.base.paymentModal.creditDebit
  const status = creditDebit.status
  const authorizationMessage = status === PED_STATUS_AUTHORIZATION_REQUIRED ? paymentResult.userMessage : null
  const receiptPrintError = state.containers.base.paymentModal.creditDebit.receiptError
  const receiptSignaturePrintError = status === PED_STATUS_AUTHORIZATION_REQUIRED
    ? state.containers.base.paymentModal.creditDebit.receiptSignatureError
    : null

  if (abandonPedOperationSelector(state)) {
    feedbackMessages.push({
      status: FEEDBACK_ALERT,
      text: MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_MANUAL_REFUND
    })
  } else if (state.api.base.lastApiErrors.v2.addPayments || state.api.base.lastApiErrors.v2.updatePayments) {
    feedbackMessages.push({
      status: FEEDBACK_ALERT,
      text: MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_API_ERROR
    })
  } else if (MAP_PED_STATUS_FEEDBACK_OBJECT[status]) {
    feedbackMessages.push(MAP_PED_STATUS_FEEDBACK_OBJECT[status])
  }

  if (receiptPrintError) {
    feedbackMessages.push({
      status: FEEDBACK_ALERT,
      text: MESSAGE_CREDIT_DEBIT_PAYMENT_RECEIPT_ERROR
    })
  }
  if (receiptSignaturePrintError) {
    feedbackMessages.push({
      status: FEEDBACK_ALERT,
      text: MESSAGE_CREDIT_DEBIT_PAYMENT_RECEIPT_SIGNATURE_ERROR
    })
  }

  return {
    amountToBePaid: state.containers.base.tiles.paymentMethods.amountToBePaid,
    status,
    resultCode,
    showForm: creditDebit.showForm,
    authorizationMessage,
    receiptPrintError,
    receiptSignaturePrintError,
    disableForm: (
      creditDebit.loadingValidate ||
      creditDebit.loadingDecline
    ),
    feedbackMessages
  }
}

export default connectFormContainer({formName: FORM_CREDIT_DEBIT})(
  connect(mapStateToProps)(CreditDebit)
)
