import ReactPropTypes from 'prop-types'
import originalMoment from 'moment'
import {
  TRAVEL_DIRECTION_OUTBOUND,
  TRAVEL_DIRECTION_INBOUND,
  TRAVEL_DIRECTION_BOTH
} from 's3p-js-lib/src/constants'

// use shim for production
let momentPropType = ReactPropTypes.any
let momentDurationPropType = ReactPropTypes.any

if (process.env.NODE_ENV !== 'production') {
  class Moment {}
  class MomentDuration {}

  const checkMomentType = (isRequired, props, propName, ...rest) => {
    if (originalMoment.isMoment(props[propName])) {
      return null
    }
    const checkInstanceOf = ReactPropTypes.instanceOf(Moment)
    const validate = isRequired ? checkInstanceOf.isRequired : checkInstanceOf
    return validate(props, propName, ...rest)
  }

  const checkMomentDurationType = (isRequired, props, propName, ...rest) => {
    if (originalMoment.isDuration(props[propName])) {
      return null
    }
    const checkInstanceOf = ReactPropTypes.instanceOf(MomentDuration)
    const validate = isRequired ? checkInstanceOf.isRequired : checkInstanceOf
    return validate(props, propName, ...rest)
  }

  momentPropType = checkMomentType.bind(null, false)
  momentPropType.isRequired = checkMomentType.bind(null, true)

  momentDurationPropType = checkMomentDurationType.bind(null, false)
  momentDurationPropType.isRequired = checkMomentDurationType.bind(null, true)
}

const PropTypes = {
  ...ReactPropTypes,
  direction: ReactPropTypes.oneOf([TRAVEL_DIRECTION_OUTBOUND, TRAVEL_DIRECTION_INBOUND]),
  allDirections: ReactPropTypes.oneOf([TRAVEL_DIRECTION_OUTBOUND, TRAVEL_DIRECTION_INBOUND, TRAVEL_DIRECTION_BOTH]),
  moment: momentPropType,
  momentDuration: momentDurationPropType
}

export default PropTypes
