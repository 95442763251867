import { FORM_JOURNEY_SEARCH } from 's3p-js-lib/src/constants-form'
import { switchDate as orientationSwitchDate } from 's3p-js-lib/src/redux/actions/api/orientation/date-switcher'
import { switchDate as rebookingSwitchDate } from 's3p-js-lib/src/redux/actions/api/aftersales/rebooking/date-switcher'
import { loadValues } from 's3p-js-lib/src/redux/actions/user-input/base/form'
import { selectBundle } from 's3p-js-lib/src/redux/actions/api/orientation/select-bundle'
import {
  persistSelectedUniqueBundleIds,
  resetSelectedUniqueBundleIds
} from 's3p-js-lib/src/redux/actions/user-input/orientation/selected-unique-bundle-ids'
import {
  bundlesBySelectedUniqueBundleIdsSelector
} from 's3p-js-lib/src/redux/selectors/user-input/orientation/selected-unique-bundle-ids'
import { journeySearchFormValuesSelector } from '../../../../selectors/containers/base/journey-search/journey-search'
import { loadBestPrices } from '../../reservations/journey-search/best-prices'

export const baseJourneySearch = (isAftersales = false) => _performJourneySearch => values => {
  return async dispatch => {
    const response = await dispatch(_performJourneySearch(values))
    if (response) {
      dispatch(loadBestPrices(isAftersales))
    }
  }
}

export const switchDate = (rebooking, ...args) => async (dispatch, getState) => {
  dispatch(persistSelectedUniqueBundleIds())

  const switchDateAction = rebooking ? rebookingSwitchDate : orientationSwitchDate
  if (!await dispatch(switchDateAction(...args))) {
    return
  }

  dispatch(loadValues(
    FORM_JOURNEY_SEARCH,
    journeySearchFormValuesSelector(getState())
  ))

  const journeyId = getState().api.orientation.journeySearch ? getState().api.orientation.journeySearch.id : null
  const bundles = bundlesBySelectedUniqueBundleIdsSelector(getState())
  if (journeyId && bundles) {
    for (const bundle of bundles) {
      await dispatch(selectBundle(journeyId, bundle.travelId, bundle.routeId, bundle.id))
    }
  }

  dispatch(resetSelectedUniqueBundleIds())
  if (journeyId) {
    dispatch(loadBestPrices(rebooking))
  }
}
