import { createSelector } from 'reselect'
import { SEAT_SELECTION_AVAILABLE } from 's3p-js-lib/src/constants'
import {
  outboundSeatSelectionOptionsSelector as baseOutboundSeatSelectionOptionsSelector,
  inboundSeatSelectionOptionsSelector as baseInboundSeatSelectionOptionsSelector
} from 's3p-js-lib/src/redux/selectors/api/orientation/seat-selection-options'

/** @internal Also used by the aftersales seat selection selectors */
export const seatSelectionOptionsSelector = baseSeatSelectionOptionsSelector => createSelector(
  [baseSeatSelectionOptionsSelector],
  options => options
    ? options.map(({selectedSeats, ...option}) => ({
      ...option,
      selectedSeats: selectedSeats,
      hasSelectedSeats: selectedSeats.length > 0,
      available: option.status === SEAT_SELECTION_AVAILABLE
    }))
    : null
)

export const outboundSeatSelectionOptionsSelector =
  seatSelectionOptionsSelector(baseOutboundSeatSelectionOptionsSelector)

export const inboundSeatSelectionOptionsSelector =
  seatSelectionOptionsSelector(baseInboundSeatSelectionOptionsSelector)

export const allSegmentsSeatSelectionOptionsSelector = createSelector(
  [
    outboundSeatSelectionOptionsSelector,
    inboundSeatSelectionOptionsSelector
  ], (outboundSegments, inboundSegments) => (outboundSegments || []).concat(inboundSegments || [])
)
