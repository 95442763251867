import { connect } from 'react-redux'
import { additionalProductsSelector } from '../../../redux/selectors/containers/aftersales/additional-products/additional-products'
import { isReturnJourneySelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { bookingSelector, originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import { additionalProductsUpdate } from 's3p-js-lib/src/redux/actions/api/aftersales/additional-products/update'
import AdditionalProducts from '../../../components/base/additional-products/additional-products'

const _isReturnJourneySelector = isReturnJourneySelector(originalBookingSelector)
const onUpdateProduct = (priceSpecification, uniqueId, quantity) => additionalProductsUpdate(uniqueId, quantity)

const mapStateToProps = state => {
  const booking = bookingSelector(state)
  return {
    additionalProducts: additionalProductsSelector(state),
    isLoadingAdditionalProducts: state.api.loading.additionalProductsUpdate,
    isReturnJourney: _isReturnJourneySelector(state),
    hasValidBookingFulfillmentMethod: Boolean(booking && booking.booking_fulfillment_method)
  }
}

const mapDispatchToProps = {
  onUpdateProduct
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalProducts)
