import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Icon from '../../../elements/icon/icon'
import TextLabel from '../../../elements/text-label/text-label'

export default class PassengerSummary extends Component {
  render () {
    return (
      <div className='passenger-rebook-amount'>
        <TextLabel name='passenger-summary'>
          <Icon name='passengers' className='align-left' />
          <TextLabel.Text text={_t.message('aftersales.rebooking.passengers-summary', this.props)} />
        </TextLabel>
      </div>
    )
  }
}
