import { createSelector } from 'reselect'
import {
  productsSelector,
  withoutCancelledSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/products'
import {
  API_PRODUCT_VALIDATION_TYPE_ISSUE,
  API_PRODUCT_VALIDATION_TYPE_FULL,
  PRODUCT_TYPE_SEAT_TICKET
} from '../../../../constants'

export const hasSeatProductsSelector = bookingSelector => createSelector(
  [withoutCancelledSelector(productsSelector(bookingSelector))],
  products => products.some(product => product.seat)
)

export const hasSeatTicketProductsSelector = bookingSelector => createSelector(
  [withoutCancelledSelector(productsSelector(bookingSelector))],
  products => products.some(product => product.type === PRODUCT_TYPE_SEAT_TICKET)
)

export const hasProductWithValidationIssuedSelector = bookingSelector => createSelector(
  [productsSelector(bookingSelector)],
  products => products.some(product => product.validations.some(
    validation => validation.validation_type === API_PRODUCT_VALIDATION_TYPE_ISSUE)
  )
)

export const productsWithActiveValidationSelector = bookingSelector => createSelector(
  [withoutCancelledSelector(productsSelector(bookingSelector))],
  products => products.filter(
    product => product.validations.some(
      validation => (
        !validation.void_date_time &&
        [API_PRODUCT_VALIDATION_TYPE_ISSUE, API_PRODUCT_VALIDATION_TYPE_FULL].includes(validation.validation_type)
      )
    )
  )
)

export const hasRebookFeeSelector = bookingSelector => createSelector(
  [withoutCancelledSelector(productsSelector(bookingSelector))],
  products => (products.length > 0 && products.some(product => product.hasRebookFee))
)
