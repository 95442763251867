import { createSelector } from 'reselect'
import { productsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/products'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'

export const selectedItemIdsSelector = createSelector(
  [
    productsSelector(bookingSelector),
    state => state.userInput.aftersales.cancellation.selectedItemIds
  ],
  (products, selectedItemIds) => {
    if (products.length === 0) {
      return selectedItemIds
    }

    return products.reduce(
      (itemIds, product) => selectedItemIds.some(item => item === product.itemId) ? itemIds.concat(product.itemId) : itemIds,
      []
    )
  }
)

export const selectedItemRefsSelector = createSelector(
  productsSelector(bookingSelector),
  state => state.userInput.aftersales.cancellation.selectedItemIds,
  (products, selectedItemIds) => products.reduce(
    (itemIds, product) => selectedItemIds.some(item => item === product.itemId) ? itemIds.concat(product.itemRef) : itemIds,
    []
  )
)
