/* globals S3P_SETTINGS: true */

import { routerActions } from 'react-router-redux'
import _t from 's3p-js-lib/src/translate'
import {UI_ERROR_BOOKING_CONFIRMATION_PAGE_REDIRECT, UI_ERROR_PAGE_REDIRECT} from '../actions/types'

export default store => next => action => {
  if (action.type === UI_ERROR_BOOKING_CONFIRMATION_PAGE_REDIRECT) {
    store.dispatch(routerActions.push(`/${_t.getLocales()}/error/booking-confirmation/${action.bookingNumber}`))
  } else if (action.type === UI_ERROR_PAGE_REDIRECT) {
    store.dispatch(routerActions.push(`/${_t.getLocales()}${S3P_SETTINGS.s3Passenger.features.errorPage}`))
  }

  return next(action)
}
