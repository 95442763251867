import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import PropTypes from '../../../misc/prop-types'
import Panel from '../../../elements/panel/panel'
import Button from '../../../elements/button/button'
import TextLabel from '../../../elements/text-label/text-label'
import FeedbackMachine from '../../../containers/base/last-machine-error-feedback'
import Feedback, { FEEDBACK_WARNING } from '../../../elements/feedback/feedback'
import { MESSAGE_SEVERITY_WARNING } from 's3p-js-lib/src/constants'

export default class EndShift extends Component {
  static propTypes = {
    onEndShift: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    deviceId: PropTypes.string,
    startDateTime: PropTypes.moment,
    hasValidLeapConfiguration: PropTypes.bool,
    isOfflineReceiptPrinter: PropTypes.bool,
    willPasswordExpireSoon: PropTypes.object
  }

  render () {
    return (
      <Panel
        header={
          <Panel.Header
            name='end-shift'
            title={_t.message('agent.shift.end.title')}
            description={_t.message('agent.shift.end.description')}
          />
        }
      >
        <FeedbackMachine namespaces={['cashDrawer']} />
        <FeedbackMachine namespaces={['receiptPrinter']} severities={[MESSAGE_SEVERITY_WARNING]} />
        {this.props.isOfflineReceiptPrinter
          ? <Feedback status={FEEDBACK_WARNING} text={_t.message('agent.shift.end.receipt-printer-offline')} />
          : null
        }
        {this.props.willPasswordExpireSoon.willExpireSoon
          ? <Feedback status={FEEDBACK_WARNING} text={_t.message('agent.shift.password-will-expire-soon', {days: this.props.willPasswordExpireSoon.expiresInDays})} />
          : null
        }
        {!this.props.hasValidLeapConfiguration
          ? (
            <Feedback
              status={FEEDBACK_WARNING}
              text={_t.message('leap.data.no-leap-configuration')}
            />
          ) : null
        }
        {this.props.startDateTime
          ? (
            <TextLabel name='information' text={_t.message(
              'agent.shift.end.information',
              {
                deviceId: this.props.deviceId || _t.message('agent.shift.end.device-unknown'),
                date: _t.formatMomentDate(this.props.startDateTime, 'dayOfTheWeekWithDate'),
                time: _t.formatMomentTime(this.props.startDateTime, 'short')
              }
            )}
            />
          ) : null}
        <div className='button-group'>
          <Button
            type='button'
            className='secondary'
            name='end-shift'
            onClick={this.props.onEndShift}
            tabIndex='1'
            autoFocus
            hotkey='focusEuro'
          >
            <TextLabel text={_t.message('agent.shift.end.button-end')} />
          </Button>
          <Button
            type='button'
            className='tertiary'
            name='logout'
            onClick={this.props.onLogout}
            tabIndex='2'
            hotkey='logout'
          >
            <TextLabel text={_t.message('agent.shift.resume.button-logout')} />
          </Button>
        </div>
      </Panel>
    )
  }
}
