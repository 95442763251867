import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import _t from 's3p-js-lib/src/translate'
import {
  bookingSelector,
  completedBookingSelector,
  bookingNumberSelector,
  paymentRequiredSelector,
  fulfillmentMethodSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { confirmBooking } from 's3p-js-lib/src/redux/actions/api/v2/confirm-booking'
import { showModal, hideElement } from '../../../../redux/actions/containers/base/visible-element'
import { resetReservations } from '../../../../redux/actions/containers/reservations/finalize-booking'
import { FINALIZE_BOOKING_MODAL } from '../../../../constants'
import FinalizeBooking from '../../../base/finalize-booking/finalize-booking'
import { goToChangeFulfillment } from '../../../../redux/actions/containers/base/void-tickets-routing'
import {displayNextCustomer} from '../../../../redux/actions/containers/base/finalize-booking/next-customer'

class FinalizeBookingContainer extends Component {
  static propTypes = {
    finalizeBooking: PropTypes.func.isRequired,
    onNextSale: PropTypes.func.isRequired,
    onChangeFulfillment: PropTypes.func.isRequired
  }

  UNSAFE_componentWillMount () {
    this.props.finalizeBooking()
  }

  render () {
    return <FinalizeBooking
      aftersales
      onNextSale={this.props.onNextSale}
      onChangeFulfillment={this.props.onChangeFulfillment}
    />
  }
}

const _bookingNumberSelector = bookingNumberSelector(completedBookingSelector)

const mapDispatchToProps = {
  finalizeBooking: () => async (dispatch, getState) => {
    if (!paymentRequiredSelector(bookingSelector)(getState()) && fulfillmentMethodSelector(bookingSelector)(getState())) {
      if (!_bookingNumberSelector(getState())) {
        await dispatch(confirmBooking())
      }
      dispatch(showModal(FINALIZE_BOOKING_MODAL))
    }
  },
  onChangeFulfillment: () => (dispatch, getState) => {
    dispatch([
      hideElement(FINALIZE_BOOKING_MODAL),
      resetReservations(),
      goToChangeFulfillment(_bookingNumberSelector(getState()))
    ])
  },
  onNextSale: () => (dispatch, getState) => {
    dispatch([
      hideElement(FINALIZE_BOOKING_MODAL),
      resetReservations(),
      routerActions.push(`/${_t.getLocales()}/aftersales/booking/${_bookingNumberSelector(getState())}`),
      displayNextCustomer()
    ])
  }
}

export default connect(null, mapDispatchToProps)(FinalizeBookingContainer)
