import { unhandledApiError } from '../actions/api/base/api'

const awaitPromise = async (result, store) => {
  try {
    result = await result
    return result !== false
  } catch (error) {
    if (error instanceof TypeError) {
      throw error
    }
    store.dispatch(unhandledApiError(error))
    return false
  }
}

export default store => next => action => {
  let result = next(action)

  if (result instanceof Promise) {
    result = awaitPromise(result, store)
  }

  return result
}
