import { createSelector } from 'reselect'
import { camelCaseKeys } from '../../../../../misc/utils'
import { toTimezoneMoment } from '../../../../../misc/date'

export const agentShiftSelector = createSelector(
  [state => state.api.user.agent.shift],
  shift => {
    if (!shift) {
      return null
    }

    const data = camelCaseKeys(shift)
    data.notes = shift.notes.map(camelCaseKeys)
    if (data.startDateTime) {
      data.startDateTime = toTimezoneMoment(data.startDateTime)
    }
    if (data.endDateTime) {
      data.endDateTime = toTimezoneMoment(data.endDateTime)
    }
    return data
  }
)
