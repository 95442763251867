import React, { Component } from 'react'
import GridLayout from '../elements/grid/grid-layout'
import GridColumn from '../elements/grid/grid-column'
import MainMenu from '../containers/base/main-menu/main-menu'
import Products from '../containers/tickets/products'
import Sidebar from '../containers/tickets/sidebar/sidebar'
import GenericLayout from './generic-layout'

export default class TicketsLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <GridLayout name='ticket-content-right'>
          <GridColumn name='content'>
            <MainMenu />
            <Products />
          </GridColumn>
          <GridColumn name='right'>
            <Sidebar />
          </GridColumn>
        </GridLayout>
      </GenericLayout>
    )
  }
}
