import { connect } from 'react-redux'
import {
  productFamiliesSelector,
  sortKeySelector,
  KEY_DEPARTURE,
  KEY_ARRIVAL,
  KEY_TRAVEL_TIME
} from '../../../../redux/selectors/containers/base/journey-search/journey-result-set'
import { sortByKey } from '../../../../redux/actions/containers/base/journey-search/journey-result-set'
import Sorting from '../../../../components/base/journey-search/journey-result-set/sorting'

const mapStateToProps = (state, ownProps) => {
  const _sortKeySelector = sortKeySelector(ownProps.direction)

  return state => {
    const sortKey = _sortKeySelector(state)

    return {
      sortedByDeparture: KEY_DEPARTURE === sortKey,
      sortedByArrival: KEY_ARRIVAL === sortKey,
      sortedByTravelTime: KEY_TRAVEL_TIME === sortKey,
      productFamilies: productFamiliesSelector(state)
    }
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSortByDeparture: () => dispatch(sortByKey(ownProps.direction, KEY_DEPARTURE)),
  onSortByArrival: () => dispatch(sortByKey(ownProps.direction, KEY_ARRIVAL)),
  onSortByTravelTime: () => dispatch(sortByKey(ownProps.direction, KEY_TRAVEL_TIME))
})

export default connect(mapStateToProps, mapDispatchToProps)(Sorting)
