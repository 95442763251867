import React from 'react'
import { Route } from 'react-router'
import ChangeSeatsLayout from '../../layouts/aftersales/change-seats'
import { isChangeSeatsAvailableSelector } from '../../redux/selectors/containers/base/aftersales'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import container from '../../containers/aftersales/auth'

export default (
  <Route
    key='aftersales-change-seats'
    path='/:lang/aftersales/change-seats'
    component={container(
      isChangeSeatsAvailableSelector(originalBookingSelector),
      ChangeSeatsLayout
    )}
  />
)
