import { connect } from 'react-redux'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { progressBarSelectorCreator } from '../../redux/selectors/containers/base/progress-bar'
import ProgressBar from '../../components/base/booking-progress-bar'
import { paymentsWithoutFailedSelector } from '../../redux/selectors/api/booking/payments'

const progressBarSelector = progressBarSelectorCreator('/reservations')
const paymentsSelector = paymentsWithoutFailedSelector(bookingSelector)

const mapStateToProps = state => ({
  ...progressBarSelector(state),
  hasPayments: Boolean(paymentsSelector(state).length)
})

export default connect(mapStateToProps)(ProgressBar)
