import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ComponentContainer from '../../../../elements/component-container/component-container'
import Panel from '../../../../elements/panel/panel'
import Icon from '../../../../elements/icon/icon'
import TextLabel from '../../../../elements/text-label/text-label'
import Table from '../../../../elements/table/table'
import Feedback, { FEEDBACK_INFORMATION, FEEDBACK_SUCCESS } from '../../../../elements/feedback/feedback'
import Loader, { TYPE_COMPONENT } from '../../../../elements/loader/loader'
import PaymentDetailsHeader from './payment-details-header'
import PaymentDetailsRow from './payment-details-row'
import PaymentDetailsFooter from '../../../../containers/aftersales/booking/payment-details/payment-details-footer'
import LastApiErrorFeedback from '../../../../containers/base/last-api-error-feedback'
import RefundModal from '../../../../containers/aftersales/booking/payment-details/refund-modal'
import PrintRefundVoucherModal from '../../../../containers/aftersales/booking/payment-details/refund/print-refund-voucher-modal'
import _t from 's3p-js-lib/src/translate'
import './payment-details.scss'

export default class PaymentDetails extends Component {
  static propTypes = {
    payments: PropTypes.arrayOf(PropTypes.shape({
      refId: PropTypes.number.isRequired
    })),
    isLoadingRefund: PropTypes.bool,
    canRequestRefund: PropTypes.bool,
    showRefundModal: PropTypes.bool,
    refundSuccess: PropTypes.bool,
    refundedVoucher: PropTypes.shape({
      code: PropTypes.string
    }),
    requestRefundHasErrors: PropTypes.shape({
      message: PropTypes.string
    }),
    showRefundVoucherModal: PropTypes.bool
  }

  render () {
    return (
      <ComponentContainer name='booking-payment-details'>
        {this.props.showRefundModal ? <RefundModal /> : null}
        {this.props.showRefundVoucherModal ? <PrintRefundVoucherModal /> : null}
        {this.props.isLoadingRefund ? <Loader type={TYPE_COMPONENT} /> : (
          <Panel
            header={
              <Panel.Header
                name='booking-details-payment-details'
                title={_t.message('aftersales.booking.payment-details.title')}
                description={_t.message('aftersales.booking.payment-details.description')}
              />
            }
            footer={this.props.canRequestRefund ? <PaymentDetailsFooter /> : null}
          >
            {this.props.refundSuccess
              ? (
                <Feedback
                  key='success'
                  status={FEEDBACK_SUCCESS}
                  text={this.props.refundedVoucher
                    ? _t.message('aftersales.booking.payment-details.refund-requested-voucher',
                      {voucherCode: this.props.refundedVoucher.code}
                    )
                    : _t.message('aftersales.booking.payment-details.refund-requested')}
                />
              ) : null}
            <LastApiErrorFeedback namespaces={['requestRefund']} />
            {this.props.canRequestRefund
              ? (
                <Feedback
                  key='feedback'
                  status={FEEDBACK_INFORMATION}
                  text={_t.message('aftersales.booking.payment-details.choose-refund')}
                />
              ) : null}
            {this.props.payments && this.props.payments.length
              ? (
                <Table key='payment-details-table' name='booking-payment-details' numColumns={4} zebraRows>
                  <PaymentDetailsHeader />
                  <tbody>
                    {this.props.payments.map(payment => (
                      <PaymentDetailsRow key={payment.refId} {...payment} />
                    ))}
                  </tbody>
                </Table>
              ) : (
                <TextLabel name='information'>
                  <Icon name='information' />
                  <TextLabel.Text text={_t.message('aftersales.booking.payment-details.no-payment-details-found')} />
                </TextLabel>
              )}
          </Panel>
        )}
      </ComponentContainer>
    )
  }
}
