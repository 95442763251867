import {
  API_LINK_TO_CUSTOMER_EMAIL_REQUEST,
  API_LINK_TO_CUSTOMER_EMAIL_SUCCESS,
  API_LINK_TO_CUSTOMER_EMAIL_FAILURE,
  API_FIND_CUSTOMERS_REQUEST,
  API_FIND_CUSTOMERS_SUCCESS,
  API_FIND_CUSTOMERS_FAILURE,
  API_OAUTH_LOGOUT
} from 's3p-js-lib/src/redux/actions/types'

const initialState = {
  isLoadingLinkToCustomer: false,
  isLoadingFindCustomers: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case API_LINK_TO_CUSTOMER_EMAIL_REQUEST:
      return {...state, isLoadingLinkToCustomer: true}
    case API_LINK_TO_CUSTOMER_EMAIL_SUCCESS:
    case API_LINK_TO_CUSTOMER_EMAIL_FAILURE:
      return {...state, isLoadingLinkToCustomer: false}
    case API_FIND_CUSTOMERS_REQUEST:
      return {...state, isLoadingFindCustomers: true}
    case API_FIND_CUSTOMERS_SUCCESS:
    case API_FIND_CUSTOMERS_FAILURE:
      return {...state, isLoadingFindCustomers: false}
    case API_OAUTH_LOGOUT:
      return initialState
    default:
      return state
  }
}
