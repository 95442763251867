import { connect } from 'react-redux'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import { FORM_PASSENGER_DETAILS_AFTERSALES, AFTERSALES_NAMECHANGE } from 's3p-js-lib/src/constants'
import ProgressNavigation from '../../../components/aftersales/update-passengers/progress-navigation'
import { formIsValidSelectorCreator } from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import {
  hasChangedPassengersSelector,
  hasChangedGroupNameSelector
} from '../../../redux/selectors/containers/aftersales/update-passengers/progress-navigation'
import {
  productsSelector,
  aftersalesRulesPaymentRequiredSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/products'
import { handleUpdatePassengers } from '../../../redux/actions/containers/aftersales/update-passengers/progress-navigation'
import { waiveFeesSelector } from 's3p-js-lib/src/redux/selectors/user-input/aftersales/waive-fees'

const formIsValidSelector = formIsValidSelectorCreator(FORM_PASSENGER_DETAILS_AFTERSALES)
const _aftersalesRulesPaymentRequiredSelector =
  aftersalesRulesPaymentRequiredSelector(AFTERSALES_NAMECHANGE)(productsSelector(originalBookingSelector))

const mapStateToProps = state => ({
  loading: state.api.loading.updatePassengersUpdate || state.api.loading.aftersalesConfirm,
  disabled: !(formIsValidSelector(state) && (hasChangedPassengersSelector(state) || hasChangedGroupNameSelector(state))),
  paymentRequired: _aftersalesRulesPaymentRequiredSelector(state) && !waiveFeesSelector(state)
})

const mapDispatchToProps = {
  onClickNext: handleUpdatePassengers
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressNavigation)
