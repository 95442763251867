import {
  USER_INPUT_USE_SHORTCUT,
  USER_INPUT_ENABLE_SHORTCUT_EDIT_MODE,
  USER_INPUT_DISABLE_SHORTCUT_EDIT_MODE,
  USER_INPUT_EDIT_SHORTCUT,
  UI_SHORTCUT_SAVED,
  USER_INPUT_SELECT_PRODUCT
} from '../../types'
import { getProducts } from '../../../actions/api/v2/orientation/search'
import { productFamiliesSelector } from '../../../selectors/api/v2/meta/product-families'
import { toUtcDateMoment } from 's3p-js-lib/src/misc/date'
import { loadValues } from 's3p-js-lib/src/redux/actions/user-input/base/form'
import { addProduct } from './product-search-result'
import { itemsSelector } from 's3p-js-lib/src/redux/selectors/api/v2/search'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import {
  FORM_EDIT_SHORTCUT,
  META_DATA_SHORTCUTS,
  DISABILITY_TYPE_NONE,
  TRAVEL_DIRECTION_OUTWARD
} from '../../../../constants'
import {
  PASSENGER_TYPE_ADULT,
  PASSENGER_TYPE_CHILD,
  PASSENGER_TYPE_STUDENT
} from 's3p-js-lib/src/constants'
import { FORM_PRODUCT_SEARCH } from 's3p-js-lib/src/constants-form'
import { shortcutsSelector } from '../../../selectors/containers/tickets/sidebar-shortcuts'
import {
  formFieldValuesSelectorCreator,
  formIsValidSelectorCreator
} from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { updateAgent, updateAgentMetaData } from 's3p-js-lib/src/redux/actions/api/user/agent/update-agent'
import { MAX_PASSENGER_MODAL } from '../../../../components/base/max-passengers-modal'
import { showModal } from '../../../actions/containers/base/visible-element'
import { DIFFERENT_PASSENGERS_NUMBER_MODAL } from '../../../../components/tickets/modals/different-passengers-number-modal'
import { maxPersonsSelector } from '../../../selectors/containers/base/max-passengers'
import countBy from 'lodash/countBy'
import { PASSENGER_DETAILS_MODAL } from '../../../../components/base/passenger-details-search/passenger-details-modal'

const useShortcutRequest = shortcut => ({type: USER_INPUT_USE_SHORTCUT, shortcut})
const shortcutSaved = sequenceNumber => ({type: UI_SHORTCUT_SAVED, sequenceNumber})

export const enableEditMode = () => ({type: USER_INPUT_ENABLE_SHORTCUT_EDIT_MODE})
export const disableEditMode = () => ({type: USER_INPUT_DISABLE_SHORTCUT_EDIT_MODE})
export const editShortcutRequest = sequenceNumber => ({type: USER_INPUT_EDIT_SHORTCUT, sequenceNumber})
const formValues = formFieldValuesSelectorCreator(FORM_EDIT_SHORTCUT)

const prepareGetProducts = shortcut => ({
  segments: [{
    origin: shortcut.origin && shortcut.origin.UICStationCode,
    destination: shortcut.destination && shortcut.destination.UICStationCode,
    validFrom: shortcut.validFrom || toUtcDateMoment().add(shortcut.dateInterval, 'd'),
    direction: TRAVEL_DIRECTION_OUTWARD
  }],
  passengers: shortcut.passengers.map((passenger, key) => ({
    ...passenger,
    id: `passenger_${key + 1}`,
    disabilityType: passenger.disabilityType || DISABILITY_TYPE_NONE
  })),
  productFamily: shortcut.productFamily.code
})

export const useShortcut = originalShortcut => async (dispatch, getState) => {
  const state = getState()

  if (originalShortcut.passengers.length > maxPersonsSelector(state)) {
    dispatch(showModal(MAX_PASSENGER_MODAL))
    return
  }

  const booking = bookingSelector(state)
  if (booking) {
    let showPassengerModal = booking.passengers.length !== originalShortcut.passengers.length
    if (!showPassengerModal) {
      const countPassengers = countBy(booking.passengers, 'type')
      const countShortcutPassengers = countBy(originalShortcut.passengers, 'type')
      showPassengerModal = [PASSENGER_TYPE_ADULT, PASSENGER_TYPE_CHILD, PASSENGER_TYPE_STUDENT]
        .some(type => countPassengers[type] !== countShortcutPassengers[type])
    }

    if (showPassengerModal) {
      dispatch(showModal(DIFFERENT_PASSENGERS_NUMBER_MODAL))
      return
    }
  }

  const shortcut = {
    ...originalShortcut,
    productFamily: productFamiliesSelector(getState())
      .find(family => family.code === originalShortcut.productFamilyCode),
    validFrom: toUtcDateMoment().add(originalShortcut.dateInterval, 'd')
  }

  if (shortcut.discountCardCode) {
    dispatch([useShortcutRequest(shortcut), loadValues(FORM_PRODUCT_SEARCH, shortcut), showModal(PASSENGER_DETAILS_MODAL)])
  } else {
    dispatch([useShortcutRequest(shortcut), loadValues(FORM_PRODUCT_SEARCH, shortcut)])

    await dispatch(getProducts(prepareGetProducts(shortcut)))

    if (shortcut.productId) {
      const products = itemsSelector(getState())
      const matchedProduct = products.find(product => product.id === shortcut.productId)
      if (matchedProduct) {
        await dispatch(addProduct(matchedProduct))
      }
    }
  }
}

export const editShortcut = sequenceNumber => async (dispatch, getState) => {
  const shortcut = shortcutsSelector(getState())
    .find(shortcut => shortcut.sequenceNumber === sequenceNumber)

  const productFamilies = productFamiliesSelector(getState())

  const values = {
    ...shortcut,
    passengers: shortcut.passengers || [],
    dateInterval: shortcut.dateInterval || 0,
    productFamily: shortcut && productFamilies
      .find(family => family.code === shortcut.productFamilyCode),
    product: shortcut.productId,
    productFilter: '',
    discountCard: shortcut.discountCardCode
  }

  await dispatch([
    editShortcutRequest(sequenceNumber),
    clearState('api.v2.search'),
    loadValues(FORM_EDIT_SHORTCUT, values)
  ])
  dispatch(searchProducts())
}

const formValuesSelector = formFieldValuesSelectorCreator(FORM_EDIT_SHORTCUT)
const formIsValidSelector = formIsValidSelectorCreator(FORM_EDIT_SHORTCUT)

export const searchProducts = () => (dispatch, getState) => {
  if (formIsValidSelector(getState())) {
    const values = formValuesSelector(getState())
    dispatch(getProducts(prepareGetProducts(values)))
  }
}

const prepareShortcut = (sequenceNumber, values) => {
  if (values.isEmpty) {
    return values
  } else {
    const passengers = values.passengers.map((passenger, key) => ({
      ...passenger,
      id: `passenger_${key + 1}`,
      disabilityType: passenger.disabilityType || DISABILITY_TYPE_NONE
    }))

    return ({
      id: `shortcut-${sequenceNumber}`,
      origin: values.origin && values.origin.UICStationCode,
      destination: values.destination && values.destination.UICStationCode,
      passengers,
      dateInterval: values.dateInterval,
      productFamilyCode: values.productFamily.code,
      productId: values.product || values.productId,
      discountCardCode: (values.discountCard && values.discountCard.code) || values.discountCardCode,
      sequenceNumber
    })
  }
}

export const saveShortcut = () => async (dispatch, getState) => {
  const values = formValues(getState())
  const sequenceNumber = getState().containers.tickets.shortcuts.selectedShortcutNr

  const shortcuts = shortcutsSelector(getState())
    .filter(shortcut => shortcut.sequenceNumber !== sequenceNumber)
    .map(item => prepareShortcut(item.sequenceNumber, item))
    .concat(prepareShortcut(sequenceNumber, values))
    .sort((a, b) => a.sequenceNumber - b.sequenceNumber)

  const metaData = updateAgentMetaData({[META_DATA_SHORTCUTS]: shortcuts}, getState)
  await dispatch(updateAgent({metaData}))
  dispatch(shortcutSaved(sequenceNumber))
}

export const selectProduct = productId => ({
  type: USER_INPUT_SELECT_PRODUCT,
  productId
})

export const validateShortcuts = () => async (dispatch, getState) => {
  const shortcuts = shortcutsSelector(getState())
  const productFamilies = productFamiliesSelector(getState())

  const emptyShortcutValues = sequenceNumber => ({
    isEmpty: true,
    id: `empty-shortcut-${sequenceNumber}`,
    sequenceNumber
  })

  const validShortcuts = shortcuts.map(
    shortcut => !shortcut.isEmpty && !productFamilies.some(family => family.code === shortcut.productFamilyCode)
      ? emptyShortcutValues(shortcut.sequenceNumber)
      : shortcut
  )
  if (!validShortcuts.every(value => shortcuts.indexOf(value) >= 0)) {
    const metaData = updateAgentMetaData({[META_DATA_SHORTCUTS]: validShortcuts}, getState)
    await dispatch(updateAgent({metaData}))
  }
}
