import request from './request'
import { STATUS_ERROR } from './device-manager'
import { createError } from './response-handler'

export const translateResponse = response => {
  const messages = []

  if ((response.status || response.Status) === STATUS_ERROR && response.Details) {
    messages.push(createError(response.Details.message, {translated: true}))
  }

  if ((response.status || response.Status) === STATUS_ERROR && response.results && response.results.message) {
    messages.push(createError(response.results.message, {translated: true}))
  }

  if (response.Details && response.Details.cover_open === 'True') {
    messages.push(createError('receipt-printer.cover-is-open'))
  } else if (response.Details && response.Details.paper_status === 'EMPTY') {
    messages.push(createError('receipt-printer.paper-is-empty'))
  }

  return messages
}

export default {
  async print (data) {
    return request({
      translateResponse,
      method: 'post',
      endpoint: '/vtm/devices/rest/ReceiptPrinter/print',
      data
    })
  },

  async testPrint () {
    return request({
      translateResponse,
      method: 'post',
      endpoint: '/vtm/devices/rest/ReceiptPrinter/testprint'
    })
  }
}
