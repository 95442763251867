import { createSelector } from 'reselect'
import {
  PAYMENT_METHOD_CODE_CASH,
  PAYMENT_METHOD_CODE_VOUCHER,
  PAYMENT_METHOD_CODE_CREDIT_DEBIT_CARD
} from '../../../../../constants'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { pendingPaymentsSelector } from '../../../api/booking/payments'

const availablePaymentMethodCodesSelector = createSelector(
  [
    state => state.api.payment.paymentMethods,
    pendingPaymentsSelector(bookingSelector)
  ],
  (paymentMethods, pendingPayments) => {
    const paymentCodes = (paymentMethods || []).map(paymentMethod => paymentMethod.code)
    if (!pendingPayments.some(payment => payment.method !== PAYMENT_METHOD_CODE_VOUCHER)) {
      paymentCodes.push(PAYMENT_METHOD_CODE_VOUCHER)
    }
    return paymentCodes
  }
)
export const selectedPaymentMethodSelector = state => state.containers.base.tiles.paymentMethods.paymentMethodCode

export const paymentMethodsSelector = createSelector(
  [
    availablePaymentMethodCodesSelector,
    selectedPaymentMethodSelector
  ],
  (availablePaymentMethods, paymentMethodCode) => {
    const createPaymentMethod = code => ({
      code,
      available: availablePaymentMethods.includes(code),
      selected: paymentMethodCode === code
    })

    return [
      createPaymentMethod(PAYMENT_METHOD_CODE_CASH),
      createPaymentMethod(PAYMENT_METHOD_CODE_VOUCHER),
      createPaymentMethod(PAYMENT_METHOD_CODE_CREDIT_DEBIT_CARD)
    ]
  }
)
