import { CLEAR_STATE, API_ADD_VOUCHER_FAILURE } from 's3p-js-lib/src/redux/actions/types'
import { API_V2_CREATE_BOOKING_FAILURE, API_V2_ADD_PAYMENTS_FAILURE } from 's3p-js-lib/src/redux/actions/types-v2'
import { EMPTY_OBJECT } from 's3p-js-lib/src/constants'
import {
  UI_PAYOUT_VOUCHER_REQUEST,
  UI_PAYOUT_VOUCHER_SUCCESS,
  UI_SELECT_VOUCHER
} from '../../../actions/types'
import { CONTAINERS_AFTERSALES_VOUCHER_TO_CASH } from '../../../../constants'

const API_ADD_VOUCHER_SUCCESS = 'API_ADD_VOUCHER_SUCCESS'

export const initialState = {
  selected: EMPTY_OBJECT,
  error: null,
  loading: false,
  added: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UI_SELECT_VOUCHER:
      return {...state, selected: action.voucher}
    case UI_PAYOUT_VOUCHER_REQUEST:
      return {...state, loading: true}
    case API_ADD_VOUCHER_SUCCESS:
      return {...state, success: true, loading: false, error: action.response.messages[0]}
    case UI_PAYOUT_VOUCHER_SUCCESS:
      return {...state, added: action.response, loading: false}
    case API_V2_CREATE_BOOKING_FAILURE:
    case API_V2_ADD_PAYMENTS_FAILURE:
    case API_ADD_VOUCHER_FAILURE:
      return {...state, loading: false}
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_AFTERSALES_VOUCHER_TO_CASH) ? initialState : state
    default:
      return state
  }
}
