import { USER_INPUT_SET_AFFILIATE_CODE, CLEAR_STATE } from '../../../actions/types'
import storage from '../../../../storage'

export const initialState = null

const defaultState = storage.get('affiliate_code') || initialState

// eslint-disable-next-line func-style
export default function affiliateCode (state = defaultState, action) {
  switch (action.type) {
    case USER_INPUT_SET_AFFILIATE_CODE:
      storage.set('affiliate_code', action.affiliateCode)
      return action.affiliateCode
    case CLEAR_STATE:
      return action.namespaces.includes('api.orientation.affiliateCode') ? initialState : state
    default:
      return state
  }
}
