import _t from 's3p-js-lib/src/translate'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { currencyRateValueSelector } from 's3p-js-lib/src/redux/selectors/api/v2/meta/currency-rates'
import { pendingTotalPriceToBePaidSelector } from '../../../../selectors/api/booking/booking'
import { displayFormatAmount } from '../../../../../misc/utils'
import { sendMachineDisplayLines } from '../../../machine/display'
import { USER_INPUT_PAYMENT_CASH_AMOUNT } from '../../../types'
import { addPayments, showFinalizeBookingModal } from '../tiles/payment-methods'
import { PAYMENT_STATUS_P } from 's3p-js-lib/src/constants'
import { CURRENCY_BRITISH_POUND, PAYMENT_METHOD_CASH, CURRENCY_EURO } from '../../../../../constants'

const totalPriceToBePaidSelector = pendingTotalPriceToBePaidSelector(bookingSelector)
const currencyRateGBPSelector = currencyRateValueSelector(CURRENCY_BRITISH_POUND)

const cashAmount = (received, currency) => (dispatch, getState) => {
  const state = getState()
  received = Math.min(received, 1000000000)
  const amountToBePaid = totalPriceToBePaidSelector(state)
  let change = received - amountToBePaid
  let adjustedCurrencyRate = 1
  if (currency === CURRENCY_BRITISH_POUND) {
    const currencyRate = currencyRateGBPSelector(state) || 1
    const receivedEUR = Math.round(100 * received / currencyRate) / 100
    adjustedCurrencyRate = (received / receivedEUR)
    change = Math.round(100 * (receivedEUR - amountToBePaid)) / 100
  }

  dispatch({
    type: USER_INPUT_PAYMENT_CASH_AMOUNT,
    received,
    change,
    adjustedCurrencyRate,
    currency
  })
}

export const currencySwitch = currency => (dispatch, getState) => {
  dispatch(cashAmount(getState().containers.base.paymentModal.cashForm.received, currency))
}

export const cashReceived = received => (dispatch, getState) => {
  dispatch(cashAmount(received, getState().containers.base.paymentModal.cashForm.currency))
}

export const addCashPayment = () => async (dispatch, getState) => {
  const state = getState()
  const amountChange = state.containers.base.paymentModal.cashForm.change || 0
  const amountReceived = state.containers.base.paymentModal.cashForm.received
  const currency = state.containers.base.paymentModal.cashForm.currency
  const payments = [{
    amount: amountReceived,
    currency,
    method: PAYMENT_METHOD_CASH,
    status: PAYMENT_STATUS_P,
    currencyRate: state.containers.base.paymentModal.cashForm.currencyRate
  }]

  if (amountChange > 0) {
    payments.push({
      amount: -amountChange,
      currency: CURRENCY_EURO,
      method: PAYMENT_METHOD_CASH,
      status: PAYMENT_STATUS_P
    })
  }

  dispatch(sendMachineDisplayLines([
    displayFormatAmount(_t.message('machine.customer-display.lines.payment-display.cash.upper'), amountReceived, currency),
    amountChange >= 0
      ? displayFormatAmount(_t.message('machine.customer-display.lines.payment-display.cash.lower'), amountChange)
      : ''
  ]))

  const result = await dispatch(addPayments(payments))
  result && dispatch(showFinalizeBookingModal())
}
