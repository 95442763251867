import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Form from '../../../elements/form/form'
import Label from '../../../elements/label/label'
import TextLabel from '../../../elements/text-label/text-label'
import FeedbackApi from '../../../elements/feedback/feedback-api'

export default class VoucherForm extends Component {
  static propTypes = {
    amountToBePaid: PropTypes.number.isRequired,
    messages: PropTypes.array.isRequired,
    form: PropTypes.shape({
      fields: PropTypes.shape({
        voucherCode: PropTypes.shape({
          value: PropTypes.string
        }).isRequired
      }).isRequired,
      valid: PropTypes.bool.isRequired
    }).isRequired,
    isLoading: PropTypes.bool
  }

  render () {
    return (
      <Form formContext='voucher-payment'>
        <FeedbackApi
          messages={this.props.messages}
          defaultMessage={_t.message('voucher-payment.default-error')}
        />
        <fieldset>
          <Form.InputGroup name='amount-to-be-paid'>
            <Form.InputWrapper name='label'>
              <TextLabel
                name='amount-to-be-paid-description'
                text={_t.message('voucher-payment.form.amount-to-be-paid.label')}
              />
            </Form.InputWrapper>
            <Form.InputWrapper name='value'>
              <TextLabel
                id='amount-to-be-paid-value'
                name='amount-to-be-paid-value'
                text={_t.formatCurrency(this.props.amountToBePaid)}
              />
            </Form.InputWrapper>
          </Form.InputGroup>
          <Form.InputGroup name='voucher-code'>
            <Form.InputWrapper name='label'>
              <Label
                htmlFor='voucher-code'
                name='voucher-code'
                text={_t.message('voucher-payment.form.voucher-code.label')}
              />
            </Form.InputWrapper>
            <Form.InputWrapper name='voucher-code'>
              <Form.TextField
                {...this.props.form.fields.voucherCode}
                value={(this.props.form.fields.voucherCode.value || '').toUpperCase()}
                name='voucher-code'
                id='voucher-code'
                autoFocus
                disabled={this.props.isLoading}
                errorText={null}
                autoComplete='off'
              />
            </Form.InputWrapper>
          </Form.InputGroup>
        </fieldset>
      </Form>
    )
  }
}
