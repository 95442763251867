import {
  bookingSelector,
  paymentRequiredSelector,
  fulfillmentMethodSelector, bookingNumberSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import {clearState} from 's3p-js-lib/src/redux/actions/clear-state'
import {removeItems as baseRemoveItems} from 's3p-js-lib/src/redux/actions/api/v2/remove-items'
import {sendMachineDisplayLines, timeoutClearDisplay} from '../../machine/display'
import {redirectToTicketErrorPage} from './product-search-result'
import {showElement} from '../../containers/base/visible-element'
import {CART_VALUE_ZERO_MODAL} from '../../../../components/tickets/modals/cart-value-zero-modal'
import {itemsSelector} from '../../../selectors/containers/tickets/sidebar-cart'
import {resetTickets} from './products'
import {showReturnPaymentModal} from '../base/payment-modal/return-payment-modal'
import {hasPendingCashOrVoucherPaymentsSelector} from '../../../selectors/api/booking/payments'
import {provisionalOrCompletedBookingSelector} from '../../../selectors/api/booking/booking'
import {CLEAR_DISPLAY_AFTER_LONG} from '../../../../constants'
import _t from 's3p-js-lib/src/translate'
import uniqueId from 'lodash/uniqueId'
import {cancelBooking} from '../../api/v2/booking/cancel-booking'

const _hasPendingCashPaymentsSelector = hasPendingCashOrVoucherPaymentsSelector(provisionalOrCompletedBookingSelector)

export const removeItems = itemRefs => async (dispatch, getState) => {
  const items = itemsSelector(getState())
  const bookingNumber = bookingNumberSelector(bookingSelector)(getState())
  if (items.length === 1) {
    dispatch(cancelCart())
    bookingNumber && await dispatch(cancelBooking(bookingNumber))
  } else {
    const response = await dispatch(baseRemoveItems(itemRefs))
    dispatch(sendTransactionCancelled())

    if (!response) {
      redirectToTicketErrorPage(dispatch, getState().api.base.lastApiErrors.v2.removeItems)
    }
  }
  if (!items.length) {
    dispatch(clearState('api.booking.provisionalBooking'))
  }

  const state = getState()
  if (
    bookingSelector(state) &&
    !paymentRequiredSelector(bookingSelector)(state) &&
    fulfillmentMethodSelector(bookingSelector)(state)
  ) {
    dispatch(showElement(CART_VALUE_ZERO_MODAL))
  }
}

export const cancelCart = () => (dispatch, getState) => {
  if (_hasPendingCashPaymentsSelector(getState())) {
    dispatch(showReturnPaymentModal())
  } else {
    dispatch([resetTickets(), sendTransactionCancelled()])
  }
}

export const sendTransactionCancelled = () => async dispatch => {
  const requestId = uniqueId('transaction_cancelled_')
  await dispatch(sendMachineDisplayLines([
    _t.message('machine.customer-display.lines.transaction-cancelled.upper'),
    _t.message('machine.customer-display.lines.transaction-cancelled.lower')
  ], requestId))
  dispatch(timeoutClearDisplay(CLEAR_DISPLAY_AFTER_LONG, requestId))
}
