import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Table from '../../../../src/elements/table/table'
import BookingHeader from './table/booking-header'
import BookingRow from '../../../containers/aftersales/bookings/table/booking-row'

export default class SearchBookingForm extends Component {
  static propTypes = {
    bookings: PropTypes.array
  }

  render () {
    return (
      <Table name='bookings' numColumns={6} zebraRows hoverRows>
        <BookingHeader />
        <tbody>
          {this.props.bookings.map(booking => <BookingRow key={booking.bookingReference} {...booking} />)}
        </tbody>
      </Table>
    )
  }
}
