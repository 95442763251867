import {connect} from 'react-redux'
import {completedBookingSelector, bookingNumberSelector} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import BaseFinalizeBooking from '../base/finalize-booking/finalize-booking'
import {FINALIZE_BOOKING_MODAL} from '../../constants'
import {resetTickets} from '../../redux/actions/containers/tickets/products'
import {hideElement} from '../../redux/actions/containers/base/visible-element'
import {goToChangeFulfillment} from '../../redux/actions/containers/base/void-tickets-routing'
import {displayNextCustomer} from '../../redux/actions/containers/base/finalize-booking/next-customer'

const mapDispatchToProps = {
  onChangeFulfillment: () => (dispatch, getState) => {
    const bookingNumber = bookingNumberSelector(completedBookingSelector)(getState())
    dispatch([
      hideElement(FINALIZE_BOOKING_MODAL),
      resetTickets(),
      goToChangeFulfillment(bookingNumber)
    ])
  },
  onNextSale: () => [hideElement(FINALIZE_BOOKING_MODAL), resetTickets(), displayNextCustomer()]
}

export default connect(null, mapDispatchToProps)(BaseFinalizeBooking)
