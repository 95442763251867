import {
  API_GET_BARCODE_DOCUMENT_REQUEST,
  API_GET_BARCODE_DOCUMENT_SUCCESS,
  API_GET_BARCODE_DOCUMENT_FAILURE,
  API_GET_GIFT_VOUCHER_DOCUMENT_REQUEST,
  API_GET_GIFT_VOUCHER_DOCUMENT_SUCCESS,
  API_GET_GIFT_VOUCHER_DOCUMENT_FAILURE,
  API_GET_REFUND_VOUCHER_DOCUMENT_REQUEST,
  API_GET_REFUND_VOUCHER_DOCUMENT_SUCCESS,
  API_GET_REFUND_VOUCHER_DOCUMENT_FAILURE
} from '../../types'
import Client from '../../../../api/client'

const getBarcodeDocumentRequest = ticketUrl => ({type: API_GET_BARCODE_DOCUMENT_REQUEST, ticketUrl})
const getBarcodeDocumentSuccess = response => ({type: API_GET_BARCODE_DOCUMENT_SUCCESS, response})
const getBarcodeDocumentFailure = error => ({type: API_GET_BARCODE_DOCUMENT_FAILURE, error})

const getGiftVoucherDocumentRequest = ticketUrl => ({type: API_GET_GIFT_VOUCHER_DOCUMENT_REQUEST, ticketUrl})
const getGiftVoucherDocumentSuccess = response => ({type: API_GET_GIFT_VOUCHER_DOCUMENT_SUCCESS, response})
const getGiftVoucherDocumentFailure = error => ({type: API_GET_GIFT_VOUCHER_DOCUMENT_FAILURE, error})

const getRefundVoucherDocumentRequest = ticketUrl => ({type: API_GET_REFUND_VOUCHER_DOCUMENT_REQUEST, ticketUrl})
const getRefundVoucherDocumentSuccess = response => ({type: API_GET_REFUND_VOUCHER_DOCUMENT_SUCCESS, response})
const getRefundVoucherDocumentFailure = error => ({type: API_GET_REFUND_VOUCHER_DOCUMENT_FAILURE, error})

export const getBarcodeDocument = ticketUrl => async dispatch => {
  try {
    dispatch(getBarcodeDocumentRequest(ticketUrl))
    const response = await Client.getTextFile(ticketUrl)
    dispatch(getBarcodeDocumentSuccess(response))
  } catch (error) {
    dispatch(getBarcodeDocumentFailure(error))
    return false
  }
}

export const getGiftVoucherDocument = ticketUrl => async dispatch => {
  try {
    dispatch(getGiftVoucherDocumentRequest(ticketUrl))
    const response = await Client.getTextFile(ticketUrl)
    dispatch(getGiftVoucherDocumentSuccess(response))
  } catch (error) {
    dispatch(getGiftVoucherDocumentFailure(error))
    return false
  }
}

export const getRefundVoucherDocument = ticketUrl => async dispatch => {
  try {
    dispatch(getRefundVoucherDocumentRequest(ticketUrl))
    const response = await Client.getTextFile(ticketUrl)
    dispatch(getRefundVoucherDocumentSuccess(response))
  } catch (error) {
    dispatch(getRefundVoucherDocumentFailure(error))
    return false
  }
}
