import OAuth from './oauth'
import moment from 'moment'
import {API_OAUTH_TOKEN_ERROR} from '../redux/actions/types'
import {oauthAccessTokenSelector} from '../redux/selectors/api/auth/oauth-token'
import {
  oauthRefreshTokenRequest,
  oauthRefreshTokenSuccess,
  oauthRefreshTokenFailure
} from '../redux/actions/api/auth/refresh'

let pendingRequest

export default callback => {
  return async (dispatch, getState) => {
    // eslint-disable-next-line func-style
    async function checkToken () {
      let oauthToken = getState().api.auth.oauthToken
      if (oauthToken === null) {
        return false
      }

      if (moment().isAfter(oauthToken.refresh_after)) {
        if (!pendingRequest) {
          dispatch(oauthRefreshTokenRequest())
          try {
            pendingRequest = OAuth.refreshToken(oauthToken.refresh_token)
            oauthToken = await pendingRequest
            pendingRequest = null
            dispatch(oauthRefreshTokenSuccess(oauthToken))
          } catch (error) {
            pendingRequest = null
            dispatch(oauthRefreshTokenFailure(error))
            throw error
          }
        } else {
          await pendingRequest
        }
      }
    }

    try {
      await checkToken()
    } catch (error) {
      dispatch({type: API_OAUTH_TOKEN_ERROR, error})
      return false
    }

    return callback(oauthAccessTokenSelector(getState()), dispatch, getState)
  }
}
