export const MIN_8_CHARACTERS = 'MIN_8_CHARACTERS'
export const ONE_UPPERCASE = 'ONE_UPPERCASE'
export const ONE_DIGIT = 'ONE_DIGIT'
export const ONE_SYMBOL = 'ONE_SYMBOL'
export const PASSWORD_IS_EQUAL = 'PASSWORD_IS_EQUAL'

export const getStrengthStatus = (password, passwordRepeated) => {
  const results = []
  if (password.length < 8) {
    results.push(MIN_8_CHARACTERS)
  }
  if (!password.match(/[A-Z]/)) {
    results.push(ONE_UPPERCASE)
  }
  if (!password.match(/[0-9]/)) {
    results.push(ONE_DIGIT)
  }
  if (!password.match(/[^A-Za-z0-9]/)) {
    results.push(ONE_SYMBOL)
  }
  if (passwordRepeated != null && !(password === passwordRepeated)) {
    results.push(PASSWORD_IS_EQUAL)
  }
  return results
}
