import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CancellationVoid from '../../../components/aftersales/cancellation/cancellation-void'
import { CONTAINERS_AFTERSALES_VOID_TICKETS, CONTAINERS_AFTERSALES_CANCELLATION_REVERSE_LEAP } from '../../../constants'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'

class CancellationVoidContainer extends Component {
  static propTypes = {
    clearState: PropTypes.func.isRequired,
    hasBooking: PropTypes.bool.isRequired
  }

  componentWillUnmount () {
    this.props.clearState([CONTAINERS_AFTERSALES_VOID_TICKETS, CONTAINERS_AFTERSALES_CANCELLATION_REVERSE_LEAP])
  }

  render () {
    return this.props.hasBooking ? <CancellationVoid /> : null
  }
}

const mapStateToProps = state => ({
  hasBooking: Boolean(originalBookingSelector(state))
})

const mapDispatchToProps = {
  clearState
}

export default connect(mapStateToProps, mapDispatchToProps)(CancellationVoidContainer)
