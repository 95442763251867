import {
  API_AGENT_LOGIN_REQUEST,
  API_AGENT_LOGIN_TOKEN,
  API_AGENT_LOGIN_SUCCESS,
  API_AGENT_LOGIN_FAILURE
} from 's3p-js-lib/src/redux/actions/types'
import { loadAgentInformation } from 's3p-js-lib/src/redux/actions/api/auth/agent-information'
import { loadUserInformation } from 's3p-js-lib/src/redux/actions/api/user/user'
import OAuth from 's3p-js-lib/src/api/oauth'
import ResponseCodes from 's3p-js-lib/src/api/response-codes'

const agentLoginRequest = (username, password) => ({type: API_AGENT_LOGIN_REQUEST, username, password})
const agentLoginToken = response => ({type: API_AGENT_LOGIN_TOKEN, response})
const agentLoginSuccess = () => ({type: API_AGENT_LOGIN_SUCCESS})
const agentLoginFailure = error => ({type: API_AGENT_LOGIN_FAILURE, error})

export const agentLogin = (username, password) => async dispatch => {
  try {
    dispatch(agentLoginRequest(username, password))
    const response = await OAuth.agentLogin(username, password)
    dispatch(agentLoginToken(response))
    const userInfoSuccess = await dispatch(loadUserInformation())
    const loginSuccess = userInfoSuccess && await dispatch(loadAgentInformation())
    dispatch(loginSuccess ? agentLoginSuccess() : agentLoginFailure())
    return loginSuccess
  } catch (error) {
    dispatch(agentLoginFailure(error))

    const handledErrors = [
      ResponseCodes.USER_OAUTH_AGENT_BAD_CREDENTIALS,
      ResponseCodes.USER_OAUTH_AGENT_INACTIVE,
      ResponseCodes.USER_OAUTH_AGENT_PASSWORD_BLOCKED,
      ResponseCodes.USER_OAUTH_AGENT_PASSWORD_EXPIRED,
      ResponseCodes.USER_AGENT_NOT_AUTHORIZED_MULTIPLE_DEVICES,
      ResponseCodes.USER_OAUTH_STEWARD_BAD_CREDENTIALS
    ]
    if (!handledErrors.includes(error.errorCode)) {
      throw error
    }
    return false
  }
}
