import React, { Component } from 'react'
import LastApiErrorFeedback from '../../../containers/base/last-api-error-feedback'
import ComponentContainer from '../../../elements/component-container/component-container'
import './booking-error.scss'

export default class BookingError extends Component {
  render () {
    return (
      <ComponentContainer name='booking-error'>
        <LastApiErrorFeedback namespaces={['getBooking']} />
      </ComponentContainer>
    )
  }
}
