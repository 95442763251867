import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import TextLabel from '../text-label/text-label'
import Link from '../link/link'
import './tab.scss'

class TabGroup extends Component {
  static propTypes = {
    name: PropTypes.string,
    className: PropTypes.string
  }

  render () {
    const {name, className, children} = this.props
    return (
      <div className={classNames('tab-interface', name ? `tab-interface-${name}` : '', className)} role='presentation'>
        {children}
      </div>
    )
  }
}

class TabList extends Component {
  render () {
    return (
      <div className='tab-interface__tablist' role='presentation'>
        <ul role='tablist'>
          {this.props.children}
        </ul>
      </div>
    )
  }
}

class TabPanel extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired
  }

  render () {
    return (
      <div className='tab-interface__tabpanel' role='presentation'>
        <div id={this.props.id} className='tabpanel' role='tabpanel'>
          {this.props.children}
        </div>
      </div>
    )
  }
}

class Tab extends Component {
  static propTypes = {
    text: PropTypes.string,
    id: PropTypes.string,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
  }

  constructor (...args) {
    super(...args)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (event) {
    event && event.preventDefault()
    if (this.props.onClick && !this.props.selected && !this.props.disabled) {
      this.props.onClick()
    }
  }

  render () {
    const {selected, disabled, text, id, children} = this.props
    const className = classNames({
      'state--is-selected': !disabled && selected,
      'state--is-available': !disabled && !selected,
      'state--is-disabled': disabled
    })

    return (
      <li role='tab'>
        <Link
          ref={this.setRef}
          href={`#${id}`}
          className={className}
          tabIndex={disabled || selected ? '-1' : '0'}
          onClick={this.handleClick}
          aria-controls={id}
          aria-selected={selected}
        >
          {children || <TextLabel name='tablist-description' text={text} />}
        </Link>
      </li>
    )
  }
}

TabGroup.TabPanel = TabPanel
TabGroup.TabList = TabList
TabGroup.Tab = Tab

export default TabGroup
