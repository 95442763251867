import { CLEAR_STATE } from 's3p-js-lib/src/redux/actions/types'
import { CURRENCY_EURO, CONTAINERS_BASE_PAYMENT_MODAL_CASH_FORM } from '../../../../../constants'
import {
  USER_INPUT_PAYMENT_METHOD_SELECT,
  USER_INPUT_PAYMENT_METHOD_DESELECT,
  USER_INPUT_PAYMENT_CASH_AMOUNT
} from '../../../../actions/types'

const initialState = {
  received: null,
  change: null,
  currencyRate: 1.0,
  currency: CURRENCY_EURO
}

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_INPUT_PAYMENT_METHOD_SELECT:
    case USER_INPUT_PAYMENT_METHOD_DESELECT:
      return initialState
    case USER_INPUT_PAYMENT_CASH_AMOUNT:
      return {
        received: action.received,
        change: action.change,
        currency: action.currency,
        currencyRate: action.adjustedCurrencyRate
      }
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_BASE_PAYMENT_MODAL_CASH_FORM) ? initialState : state
    default:
      return state
  }
}
