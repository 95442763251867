import { updateFulfillmentMethod } from 's3p-js-lib/src/redux/actions/api/v2/booking'
import { redirectToTicketErrorPage } from './product-search-result'
import {
  bookingSelector,
  paymentRequiredSelector,
  bookingNumberSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { confirmBooking } from 's3p-js-lib/src/redux/actions/api/v2/confirm-booking'
import {
  printReceipt,
  getPaymentMethods
} from '../base/tiles/payment-methods'
import { pendingSelectFulfillmentMethod } from '../base/tiles/fulfillment-methods'
import { showModal } from '../base/visible-element'
import { FINALIZE_BOOKING_MODAL } from '../../../../constants'

const _bookingNumberSelector = bookingNumberSelector(bookingSelector)

export const selectFulfillmentMethod = fulfillmentMethod => async (dispatch, getState) => {
  dispatch(pendingSelectFulfillmentMethod(fulfillmentMethod))
  const response = await dispatch(updateFulfillmentMethod(_bookingNumberSelector(getState()), fulfillmentMethod.code))
  if (!response) {
    redirectToTicketErrorPage(dispatch, getState().api.base.lastApiErrors.v2.updateFulfillmentMethod)
  } else {
    dispatch(getPaymentMethods())
    if (!paymentRequiredSelector(bookingSelector)(getState())) {
      await dispatch(confirmBooking())
      dispatch([printReceipt(), showModal(FINALIZE_BOOKING_MODAL)])
    }
  }
}
