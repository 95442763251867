import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Form from '../../../../elements/form/form'
import Checkbox from './checkbox'

export default class CancelEntireBooking extends Component {
  static propTypes = {
    onToggle: PropTypes.func.isRequired,
    bookingCancellationData: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired
  }

  render () {
    return (
      <fieldset>
        <Form.Legend name='entire-journey' />
        <Form.InputGroup name='entire-journey'>
          <Form.InputWrapper name='entire-journey'>
            <Checkbox
              id='entire-booking'
              name='entire-booking'
              cancellationData={this.props.bookingCancellationData}
              onToggle={this.props.onToggle}
              showIndeterminateAsUnchecked
              disabled={this.props.disabled}
            />
          </Form.InputWrapper>
        </Form.InputGroup>
      </fieldset>
    )
  }
}
