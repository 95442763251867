import PropTypes from 'prop-types'
import React, { Component } from 'react'
import StickyFooterWithTaskBar from '../../../components/base/sticky-footer-with-taskbar'
import ComponentContainer from '../../../elements/component-container/component-container'
import JourneySummary from '../../../containers/base/booking-summary/journey-summary'
import PriceSummary from '../../../containers/base/booking-summary/price-summary'
import '../../../elements/sticky-footer/sticky-footer.scss'

export default class ProgressNavigation extends Component {
  static propTypes = {
    isAdditionalProducts: PropTypes.bool
  }

  render () {
    return (
      <StickyFooterWithTaskBar>
        <ComponentContainer name='sticky-footer'>
          <div className='sticky-footer-content'>
            <JourneySummary />
            <PriceSummary priceLabel={this.props.isAdditionalProducts
              ? 'booking-summary.price-summary.total-price-additional-products' : undefined} />
            <div className='segment-navigation'>
              {this.props.children}
            </div>
          </div>
        </ComponentContainer>
      </StickyFooterWithTaskBar>
    )
  }
}
