import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../elements/text-label/text-label'
import Icon from '../../../elements/icon/icon'
import TileSet from '../../../elements/tile-set/tile-set'
import {
  FULFILLMENT_METHOD_CODE_MAGSTRIPE,
  FULFILLMENT_METHOD_CODE_BARCODE,
  FULFILLMENT_METHOD_CODE_LEAP,
  FULFILLMENT_METHOD_CODE_LEAP_TOP_UP,
  FULFILLMENT_METHOD_ICON_MAP
} from '../../../constants'

const HOTKEY_MAP = {
  [FULFILLMENT_METHOD_CODE_LEAP]: 'selectLeap',
  [FULFILLMENT_METHOD_CODE_LEAP_TOP_UP]: 'selectLeap',
  [FULFILLMENT_METHOD_CODE_MAGSTRIPE]: 'selectMagstripe',
  [FULFILLMENT_METHOD_CODE_BARCODE]: 'selectBarcode'
}

export default class FulfillmentMethodsTile extends Component {
  static propTypes = {
    object: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string.isRequired,
      available: PropTypes.bool,
      selected: PropTypes.bool,
      icon: PropTypes.element,
      loading: PropTypes.bool
    }),
    disabled: PropTypes.bool,
    handleSelect: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool
  }

  constructor (props, ...args) {
    super(props, ...args)
    this.handleClick = props.handleSelect.bind(null, props.object)
  }

  render () {
    return (
      <TileSet.Tile
        autoFocus={this.props.autoFocus}
        onClick={this.handleClick}
        selected={this.props.object.selected}
        disabled={!this.props.object.available || this.props.disabled}
        icon={<Icon name={FULFILLMENT_METHOD_ICON_MAP[this.props.object.code] || FULFILLMENT_METHOD_ICON_MAP['default']} />}
        hotkey={HOTKEY_MAP[this.props.object.code]}
      >
        <TextLabel
          name='fulfillment-method'
          text={this.props.object.name || _t.message(`fulfillment-methods.${this.props.object.code}`)}
        />
      </TileSet.Tile>
    )
  }
}
