import PropTypes from 'prop-types'
import React, { Component, PureComponent } from 'react'
import classNames from 'classnames'
import './text-label.scss'

class Text extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired
  }

  render () {
    return <span className='text'>{this.props.text}</span>
  }
}

/**
 * TextLabel
 *
 * You cannot use text property and children at the same time
 * <TextLabel text={`XYZ`} />
 * OR
 * <TextLabel>XYZ</TextLabel>
 */
class TextLabel extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string
  }

  render () {
    const {text, className, name, children, ...rest} = this.props
    return (
      <span {...rest} className={classNames('text-label', name ? `text-label-${name}` : '', className)}>
        {text ? <Text text={text} /> : children}
      </span>
    )
  }
}

TextLabel.Text = Text

export default TextLabel
