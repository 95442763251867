import PropTypes from 'prop-types'
import React from 'react'
import _t from 's3p-js-lib/src/translate'
import kebabCase from 'lodash/kebabCase'

export default Component => (class FormContextAwareInput extends React.Component {
  static contextTypes = {
    formContext: PropTypes.string
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string
  }

  render () {
    let {placeholder, ...rest} = this.props
    if (typeof placeholder === 'undefined') {
      placeholder = _t.message(`${this.context.formContext}.form.${kebabCase(rest.name)}.placeholder`)
    }

    return <Component placeholder={placeholder} {...rest} />
  }
})
