import { createSelector } from 'reselect'
import { DOCUMENT_TYPE_TIC, DOCUMENT_TYPE_VCR } from '../../../../constants'

export const barcodeDocumentSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking && booking.ticket_documents && booking.ticket_documents.find(
    ticketDocument => ticketDocument.document_type === DOCUMENT_TYPE_TIC
  )
)

export const giftVoucherTicketDocumentsSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking && booking.ticket_documents && booking.ticket_documents.filter(
    ticketDocument => ticketDocument.document_type === DOCUMENT_TYPE_VCR
  )
)
