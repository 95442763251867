import { updateFulfillmentMethod } from 's3p-js-lib/src/redux/actions/api/v2/booking'
import {
  bookingSelector,
  bookingNumberSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { pendingSelectFulfillmentMethod } from '../../base/tiles/fulfillment-methods'

const _bookingNumberSelector = bookingNumberSelector(bookingSelector)

export const selectFulfillmentMethod = fulfillmentMethod => async (dispatch, getState) => {
  dispatch(pendingSelectFulfillmentMethod(fulfillmentMethod))
  await dispatch(updateFulfillmentMethod(_bookingNumberSelector(getState()), fulfillmentMethod.code))
}
