import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'
import PaymentMethods from '../../../components/base/tiles/payment-methods'
import {
  totalPriceSelector,
  fulfillmentMethodSelector,
  bookingSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import {provisionalOrCompletedBookingSelector} from '../../../redux/selectors/api/booking/booking'
import {modalIsActiveSelector} from '../../../redux/selectors/containers/base/visible-element'
import {handleSelectPaymentMethod, PAYMENT_MODAL} from '../../../redux/actions/containers/base/tiles/payment-methods'
import {
  paymentMethodsSelector,
  selectedPaymentMethodSelector
} from '../../../redux/selectors/containers/base/tiles/payment-methods'
import {availableFulfillmentMethodsSelector} from 's3p-js-lib/src/redux/selectors/api/booking/fulfillment-methods'
import {clearState} from 's3p-js-lib/src/redux/actions/clear-state'
import {
  PAYMENT_METHOD_CODE_CASH,
  PAYMENT_METHOD_CODE_VOUCHER,
  PAYMENT_METHOD_CODE_CREDIT_DEBIT_CARD,
  CONTAINERS_BASE_TILES_PAYMENT_METHODS
} from '../../../constants'
import {hasValidFulfillmentMethodSelector} from '../../../redux/selectors/api/booking/fulfillment-methods'

class PaymentMethodsContainer extends Component {
  static propTypes = {
    clearState: PropTypes.func.isRequired
  }

  componentWillUnmount () {
    this.props.clearState(CONTAINERS_BASE_TILES_PAYMENT_METHODS)
  }

  render () {
    const props = {...this.props}
    delete props.clearState

    return <PaymentMethods {...props} />
  }
}

const _fulfillmentMethodSelector = fulfillmentMethodSelector(provisionalOrCompletedBookingSelector)
const _totalPriceSelector = totalPriceSelector(provisionalOrCompletedBookingSelector)
const _availableFulfillmentMethodsSelector = availableFulfillmentMethodsSelector(bookingSelector)
const _hasValidFulfillmentMethodSelector = hasValidFulfillmentMethodSelector(provisionalOrCompletedBookingSelector)

const mapStateToProps = state => {
  const selectedPaymentMethodCode = selectedPaymentMethodSelector(state)
  const showModal = state.containers.base.elementVisibility[PAYMENT_MODAL]
  const modalIsActive = modalIsActiveSelector(state)

  return {
    paymentMethods: paymentMethodsSelector(state),
    hideTiles: (
      _totalPriceSelector(state) === 0 ||
      !_fulfillmentMethodSelector(state) ||
      !_hasValidFulfillmentMethodSelector(state) ||
      state.api.v2.loading.updateSegments
    ),
    loading: (
      state.api.loading.getPaymentMethods ||
      state.api.v2.loading.updateFulfillmentMethod ||
      (!modalIsActive && state.api.v2.loading.confirmBooking)
    ),
    disabled: (
      modalIsActive ||
      state.api.v2.loading.getProducts ||
      !(_availableFulfillmentMethodsSelector(state) || []).length
    ),
    showCashModal: showModal && selectedPaymentMethodCode === PAYMENT_METHOD_CODE_CASH,
    showVoucherModal: showModal && selectedPaymentMethodCode === PAYMENT_METHOD_CODE_VOUCHER,
    showCreditDebitModal: showModal && selectedPaymentMethodCode === PAYMENT_METHOD_CODE_CREDIT_DEBIT_CARD
  }
}

const mapDispatchToProps = {
  handleSelectPaymentMethod,
  clearState
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodsContainer)
