import ResponseCodes from 's3p-js-lib/src/api/response-codes'
import { API_UNHANDLED_ERROR } from 's3p-js-lib/src/redux/actions/types'
import { showModal } from '../../redux/actions/containers/base/visible-element'
import { SHIFT_MODAL } from '../../constants'
import { shiftEndedRemotely } from '../actions/containers/agent/shift/modal'

export default () => next => action => {
  if (action.error && action.error.errorCode === ResponseCodes.USER_AGENT_HAS_NO_ACTIVE_SHIFT) {
    if (action.type === API_UNHANDLED_ERROR) {
      return
    }
    next(shiftEndedRemotely())
    next(showModal(SHIFT_MODAL))
  }
  return next(action)
}
