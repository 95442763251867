import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Loader, { TYPE_COMPONENT } from '../../../elements/loader/loader'
import TilesWithModal from '../../../containers/base/tiles/tiles-with-modal'
import FulfillmentMethodsTile from './fulfillment-method-tile'

const MAX_METHODS = 5

export default class FulfillmentMethods extends Component {
  static propTypes = {
    fulfillmentMethods: PropTypes.array,
    handleSelect: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingFulfillmentMethod: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired
  }

  render () {
    return this.props.loading || this.props.fulfillmentMethods
      ? (
        <div className='fulfillment-methods'>
          {this.props.loading
            ? <Loader type={TYPE_COMPONENT} />
            : (
              <TilesWithModal
                data={this.props.fulfillmentMethods}
                maxTiles={MAX_METHODS}
                name='fulfillment-method'
                loading={this.props.loadingFulfillmentMethod}
                translatePrefix='fulfillment-methods'
                tile={FulfillmentMethodsTile}
                handleSelect={this.props.handleSelect}
                disabled={this.props.disabled}
              />
            )}
        </div>
      ) : null
  }
}
