import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { hasAccessToWaiveFeesSelector } from 's3p-js-lib/src/redux/selectors/api/auth/auth'
import { toggleWaiveFees } from 's3p-js-lib/src/redux/actions/user-input/aftersales/waive-fees'
import WaiveFees from '../../../components/aftersales/waive-fees/waive-fees'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { aftersalesRulesPaymentRequiredSelector, productsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/products'
import { AFTERSALES_NAMECHANGE } from 's3p-js-lib/src/constants'

class WaiveFeesContainer extends Component {
  static propTypes = {
    isAllowedWaiveFees: PropTypes.bool
  }

  render () {
    return this.props.isAllowedWaiveFees
      ? (
        <WaiveFees {...this.props} />
      ) : null
  }
}
const _hasAccessToWaiveFeesSelector = hasAccessToWaiveFeesSelector(originalBookingSelector)
const _hasNameChangefeeSelector = aftersalesRulesPaymentRequiredSelector(AFTERSALES_NAMECHANGE)(productsSelector(originalBookingSelector))

const mapStateToProps = state => ({
  selected: state.userInput.aftersales.waiveFees,
  isAllowedWaiveFees: _hasNameChangefeeSelector(state) && _hasAccessToWaiveFeesSelector(state),
  disabled: Boolean(bookingSelector(state))
})

const mapDispatchToProps = {
  onToggleWaiveFees: toggleWaiveFees
}

export default connect(mapStateToProps, mapDispatchToProps)(WaiveFeesContainer)
