import { getBooking } from 's3p-js-lib/src/redux/actions/api/booking/booking'
import { overviewBookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import {
  goToAftersalesChangeFulfillmentVoidTickets,
  goToAftersalesChangeFulfillment,
  goToAftersalesUpdatePassengers,
  goToAftersalesUpdatePassengersVoidTickets,
  goToAftersalesChangeSeatsVoidTickets,
  goToAftersalesChangeSeats
} from './routing'
import { shouldVoidMagstripeTicketsSelector } from '../../../selectors/containers/aftersales/void-tickets'

export const goToChangeFulfillment = bookingNumber => async (dispatch, getState) => {
  await dispatch(getBooking(bookingNumber))

  if (shouldVoidMagstripeTicketsSelector(overviewBookingSelector)(getState())) {
    dispatch(goToAftersalesChangeFulfillmentVoidTickets(bookingNumber))
  } else {
    dispatch(goToAftersalesChangeFulfillment(bookingNumber))
  }
}

export const goToUpdatePassengers = bookingNumber => async (dispatch, getState) => {
  await dispatch(getBooking(bookingNumber))

  if (shouldVoidMagstripeTicketsSelector(overviewBookingSelector)(getState())) {
    dispatch(goToAftersalesUpdatePassengersVoidTickets())
  } else {
    dispatch(goToAftersalesUpdatePassengers())
  }
}

export const goToChangeSeats = bookingNumber => async (dispatch, getState) => {
  await dispatch(getBooking(bookingNumber))

  if (shouldVoidMagstripeTicketsSelector(overviewBookingSelector)(getState())) {
    dispatch(goToAftersalesChangeSeatsVoidTickets())
  } else {
    dispatch(goToAftersalesChangeSeats())
  }
}
