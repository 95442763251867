import { createSelector } from 'reselect'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import { stationsSelector, stationMapper } from 's3p-js-lib/src/redux/selectors/api/orientation/stations'
import { TRAVEL_DIRECTION_OUTBOUND, TRAVEL_DIRECTION_INBOUND, PRODUCT_TYPE_BIKE } from 's3p-js-lib/src/constants'
import {
  camelCaseKeys,
  serviceIdentifierCreator,
  mapDirectionToTravelDirection,
  mapTravelDirectionToDirection
} from 's3p-js-lib/src/misc/utils'
import { toTimezoneMoment } from 's3p-js-lib/src/misc/date'
import { enrichedJourneySearchSelector } from 's3p-js-lib/src/redux/selectors/api/orientation/journey-search'

export const KEY_DEPARTURE = 'KEY_DEPARTURE'
export const KEY_ARRIVAL = 'KEY_ARRIVAL'
export const KEY_TRAVEL_TIME = 'KEY_TRAVEL_TIME'

export const travelSelector = direction => {
  const travelDirection = mapDirectionToTravelDirection(direction)

  return createSelector(
    [
      state => state.api.orientation.offer,
      enrichedJourneySearchSelector,
      stationsSelector,
      state => state.api.loading.journeySearch || state.api.loading.rebookingJourneySearch
    ],
    (offer, journeySearch, stations, isJourneySearchLoading) => {
      const travel = offer && offer.travels.find(travel => travel.direction === travelDirection)
      const findStation = code => stations.find(station => station.UICStationCode === code) || {}

      let result = null
      if (travel) {
        result = {
          originStation: findStation(travel.origin),
          destinationStation: findStation(travel.destination)
        }
      } else if (isJourneySearchLoading && journeySearch) {
        if (direction === TRAVEL_DIRECTION_OUTBOUND && journeySearch.departureDate) {
          result = {
            originStation: journeySearch.originStation,
            destinationStation: journeySearch.destinationStation
          }
        } else if (direction === TRAVEL_DIRECTION_INBOUND && journeySearch.returnDate) {
          result = {
            originStation: journeySearch.destinationStation,
            destinationStation: journeySearch.originStation
          }
        }
      }

      return result
    }
  )
}

const routesByDirectionSelector = createSelector(
  [
    enrichedJourneySearchSelector,
    state => state.api.orientation.offer
  ],
  (journeySearch, offer) => {
    const travels = offer && offer.travels
    const journeySearchId = journeySearch && journeySearch.id

    return travels && travels.length ? travels.reduce(
      (_routes, travel) => {
        if (travel.routes && travel.routes.length) {
          const direction = mapTravelDirectionToDirection(travel.direction)
          const travelId = travel.id

          _routes[direction] = travel.routes.reduce(
            (_result, route) => {
              const departureStation = route.legs[0].departure_station
              const arrivalStation = route.legs[route.legs.length - 1].arrival_station
              const departureTime = toTimezoneMoment(departureStation.departure_timestamp, departureStation.timezone)
              const arrivalTime = toTimezoneMoment(arrivalStation.arrival_timestamp, arrivalStation.timezone)

              _result[route.id] = {
                id: route.id,
                journeySearchId,
                travelId,
                departureStation,
                arrivalStation,
                departureTime,
                arrivalTime,
                bundles: route.bundles.map(camelCaseKeys),
                legs: route.legs.map(leg => {
                  const formattedLeg = {
                    ...camelCaseKeys(leg),
                    departureStation: stationMapper(leg.departure_station),
                    arrivalStation: stationMapper(leg.arrival_station),
                    serviceScheduleDate: leg.service_schedule_date
                  }
                  formattedLeg.serviceIdentifier = serviceIdentifierCreator(formattedLeg)
                  return formattedLeg
                }),
                transfers: route.transfers.map(transfer => ({
                  ...camelCaseKeys(transfer),
                  arrivalTime: toTimezoneMoment(transfer.arrival_date_time),
                  departureTime: toTimezoneMoment(transfer.departure_date_time),
                  transferTime: moment.duration(transfer.transfer_time)
                })),
                travelTime: moment.duration(route.travel_time)
              }

              return _result
            },
            {}
          )
        }

        return _routes
      },
      {}
    ) : null
  }
)

const routesSelector = createSelector(
  [routesByDirectionSelector],
  routesByDirection => routesByDirection ? Object.values(routesByDirection).reduce(
    (routes, routesDirection) => ({
      ...routes,
      ...routesDirection
    }),
    {}
  ) : null
)

export const sortKeySelector = direction => state => state.containers.base.journeySearch.journeyResultSet.sortKey[direction]

const sortMap = {
  [KEY_DEPARTURE]: route => route.departureTime.unix(),
  [KEY_ARRIVAL]: route => route.arrivalTime.unix(),
  [KEY_TRAVEL_TIME]: route => route.travelTime.asSeconds()
}

export const routeIdsSelector = direction => createSelector(
  [
    routesByDirectionSelector,
    sortKeySelector(direction)
  ],
  (routes, sortKey) => routes && routes[direction]
    ? sortBy(Object.values(routes[direction]), sortMap[sortKey]).map(route => route.id)
    : []
)

export const productFamiliesSelector = createSelector(
  [
    state => state.api.orientation.offer && state.api.orientation.offer.product_families,
    routesSelector
  ],
  (productFamiliesOffer, routes) => {
    if (productFamiliesOffer && routes) {
      const productFamiliesIds = []
      Object.values(routes).forEach(route =>
        route.bundles.forEach(bundle => {
          if (!productFamiliesIds.includes(bundle.productFamilyId)) {
            productFamiliesIds.push(bundle.productFamilyId)
          }
        })
      )

      const productFamilies = productFamiliesOffer
        .filter(productFamily => productFamiliesIds.includes(productFamily.id))
        .map(camelCaseKeys)

      return sortBy(Object.values(productFamilies), 'sequenceNumber')
    }

    return []
  }
)

const selectedBundleRoutes = createSelector(
  [state => state.api.orientation.selectedBundles],
  bundles => (bundles || []).reduce(
    (_bundles, bundle) => {
      const id = bundle.route_id || bundle.routeId
      _bundles[id] = camelCaseKeys(bundle)
      return _bundles
    },
    {}
  )
)

const selectedBundleIdRouteSelector = routeId => createSelector(
  [selectedBundleRoutes],
  bundles => bundles[routeId] ? bundles[routeId].id || bundles[routeId].bundleId : null
)

const showJourneyDetailsRouteSelector = routeId => createSelector(
  [state => state.containers.base.journeySearch.journeyResultSet.showDetailsRouteId],
  showDetailsRouteId => routeId === showDetailsRouteId
)

export const routeSelector = routeId => createSelector(
  [
    routesSelector,
    productFamiliesSelector,
    selectedBundleIdRouteSelector(routeId),
    showJourneyDetailsRouteSelector(routeId),
    state => state.containers.reservations.passengerPreferences.isEnabledBikeFilter
  ],
  (routes, productFamilies, selectedBundleId, showJourneyDetails, isEnabledBikeFilter) => {
    if (routes) {
      const route = routes[routeId]
      const hasTravelInfo = route.legs.some(leg => leg.travelInfo)
      const _bundles = routes[routeId].bundles
      const bundles = productFamilies.map(productFamily => {
        const bundle = _bundles.find(bundle => bundle.productFamilyId === productFamily.id)
        const isAvailable = Boolean(bundle)

        return {
          ...bundle,
          routeId,
          selected: isAvailable && bundle.id === selectedBundleId,
          isAvailable,
          productFamily,
          isFilteredOut: isAvailable && isEnabledBikeFilter && !bundle.additionalProductsSummaries.some(
            product => product.product_type === PRODUCT_TYPE_BIKE
          )
        }
      })

      return {
        ...route,
        bundles,
        showJourneyDetails,
        hasTravelInfo
      }
    }

    return null
  }
)
