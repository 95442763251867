import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getSeatSelectionOptions } from 's3p-js-lib/src/redux/actions/api/base/seat-selector/seat-selection-options'
import {
  getCarriageLayoutsOrientation
} from '../../../redux/actions/containers/base/seat-selector/carriage-layouts'
import {
  selectFirstPassenger,
  selectFirstCarriage
} from '../../../redux/actions/containers/base/seat-selector/seat-selector'
import { selectSegment } from '../../../redux/actions/user-input/base/seat-selector'
import { carriagesSelector } from 's3p-js-lib/src/redux/selectors/api/base/seat-selector/carriage-layouts'
import { destroySeatSelector } from 's3p-js-lib/src/redux/actions/user-input/base/seat-selector'
import SeatSelectorWrapper from '../../../components/base/seat-selector/seat-selector-wrapper'

class SeatSelectorWrapperContainer extends Component {
  static propTypes = {
    segmentId: PropTypes.string.isRequired,
    hasCarriages: PropTypes.bool.isRequired,
    loadSegment: PropTypes.func.isRequired,
    selectFirstCarriage: PropTypes.func.isRequired,
    destroySeatSelector: PropTypes.func.isRequired
  }

  UNSAFE_componentWillMount () {
    this.props.loadSegment(this.props.segmentId)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!this.props.hasCarriages && nextProps.hasCarriages) {
      nextProps.selectFirstCarriage()
    } else if (this.props.segmentId !== nextProps.segmentId) {
      nextProps.loadSegment(nextProps.segmentId)
    }
  }

  componentWillUnmount () {
    this.props.destroySeatSelector()
  }

  render () {
    return <SeatSelectorWrapper {...this.props} />
  }
}

const mapStateToProps = state => ({
  isLoadingSeatSelectionOptions: state.api.loading.getSeatSelectionOptions,
  hasCarriages: carriagesSelector(state).length > 0
})

const getSeatSelectionOptionsWrapper = () => (dispatch, getState) => getState().api.orientation.journeySearch
  ? dispatch(getSeatSelectionOptions(getState().api.orientation.journeySearch.id))
  : null

const mapDispatchToProps = {
  loadSegment: segmentId => [
    destroySeatSelector(),
    selectSegment(segmentId),
    getSeatSelectionOptionsWrapper(),
    getCarriageLayoutsOrientation(),
    selectFirstPassenger()
  ],
  selectFirstCarriage,
  destroySeatSelector
}

export default connect(mapStateToProps, mapDispatchToProps)(SeatSelectorWrapperContainer)
