import React, { Component } from 'react'
import PaymentModal from './payment-modal'
import CreditDebitFooter from '../../../containers/base/payment-modal/credit-debit-footer'
import CreditDebit from '../../../containers/base/payment-modal/credit-debit'

export default class CreditDebitModal extends Component {
  render () {
    return (
      <PaymentModal
        {...this.props}
        footer={<CreditDebitFooter />}
      >
        <CreditDebit />
      </PaymentModal>
    )
  }
}
