import {maskData} from './mask-data'

const addRequestAttributes = (interaction, requestMethod, requestEndpoint, fetchOptions, data = {}) => {
  let requestData = {}
  try {
    requestData = maskData(fetchOptions.body ? JSON.parse(fetchOptions.body.toString()) : data)
  } catch (e) {
    console.error('Newrelic http request data capture error:', e)
  }
  try {
    interaction.setAttribute('requestMethod', requestMethod)
    interaction.setAttribute('requestEndpoint', requestEndpoint)
    interaction.setAttribute('requestHeaders', fetchOptions.headers)
    interaction.setAttribute('requestData', requestData)
  } catch (e) {
    console.error('Newrelic http request attributes assignment error: ', e)
  }
}

/**
 *
 * @param interaction
 * @param response
 * @returns {boolean} true if correlation id was added
 */
const addResponseStatusAndHeaderAttributes = (interaction, response) => {
  try {
    interaction.setAttribute('responseStatus', response.status)
    interaction.setAttribute('responseHeaders', JSON.stringify(Object.fromEntries(response.headers.entries())))

    if (response.headers.has('x-s3-correlation-id')) {
      interaction.setAttribute('correlationId', response.headers.get('x-s3-correlation-id'))
      return true
    }
  } catch (e) {
    console.error('Newrelic http response attributes setting error: ', e)
  }
  return false
}

const addResponseAttributes = async (interaction, response) => {
  if (!addResponseStatusAndHeaderAttributes(interaction, response)) {
    const clonedResponse = response.clone()
    const responseCapture = {
      string: '',
      maskedData: undefined,
      error: undefined
    }
    try {
      responseCapture.string = await unchunkReadableTextStream(clonedResponse.body)
      responseCapture.maskedData = responseCapture.string ? maskData(JSON.parse(responseCapture.string)) : ''
    } catch (e) {
      console.error('Http response read error:', e)
      responseCapture.error = e
    }

    try {
      interaction.setAttribute('responseError', responseCapture.error)
      const maxResponseDataChunks = 2048
      const responseChunkSize = 1500
      const responseDataString = responseCapture.maskedData ? JSON.stringify(responseCapture.maskedData) : responseCapture.string

      for (const [i, chunk] of [...chunks(responseDataString, responseChunkSize)].slice(0, maxResponseDataChunks).entries()) {
        interaction.setAttribute(`responseData${i || ''}`, chunk)
      }
    } catch (e) {
      console.error('Newrelic http response attributes setting error: ', e)
    }
  }
}

/**
 * Wraps a fetch promise to log request and request data with New Relic browser agent
 * @param newRelicBrowserInteractionName
 * @param requestMethod
 * @param requestEndpoint
 * @param fetchOptions
 * @param data
 * @param fetchHandler
 * @param errorHandler
 * @returns {Promise<*>}
 */
export const newRelicFetchRequestWrapper = async (
  newRelicBrowserInteractionName,
  requestMethod,
  requestEndpoint,
  fetchOptions,
  data,
  fetchHandler,
  errorHandler
) => {
  const newRelicBrowserInteraction = window?.newrelic?.interaction()?.setName(newRelicBrowserInteractionName)

  if (newRelicBrowserInteraction) {
    addRequestAttributes(newRelicBrowserInteraction, requestMethod, requestEndpoint, fetchOptions, data)
  }

  const response = await fetchHandler(
    requestEndpoint,
    fetchOptions
  ).catch(error => {
    window?.newrelic?.noticeError(error)
    errorHandler(error)
  })

  if (newRelicBrowserInteraction) {
    if (response) {
      await addResponseAttributes(newRelicBrowserInteraction, response)
    }
    try {
      newRelicBrowserInteraction.save()
    } catch (e) {
      console.error('Newrelic interaction save error: ', e)
    }
  }

  return response
}

export const newRelicTracerWrapper = (newRelicTracerName, callback) => window?.newrelic
  ? window?.newrelic?.interaction()?.createTracer(newRelicTracerName, callback)
  : callback

const utf8Decoder = new TextDecoder()
/**
 * @param {ReadableStream} stream
 * @param {number} maxLength
 * @param {TextDecoder} decoder
 * @returns {Promise<string>}
 */
const unchunkReadableTextStream = async (stream, maxLength = 0, decoder = utf8Decoder) => {
  const reader = stream.getReader()

  if (maxLength <= 0) {
    let str = ''
    while (true) {
      const {done, value} = await reader.read()
      if (done) {
        break
      }
      str += decoder.decode(value, {stream: true})
    }
    return str + decoder.decode()
  }

  let str = ''
  while (true) {
    const {done, value} = await reader.read()
    if (done) {
      break
    }
    const decodedChunk = decoder.decode(value, {stream: true})
    const overflow = (str.length + decodedChunk.length) - maxLength
    if (overflow > 0) {
      return str + decodedChunk.substring(0, decodedChunk.length - overflow) + decoder.decode()
    }
    str += decodedChunk
  }
  return str + decoder.decode()
}

/**
 * @param {string} string
 * @param {int} chunkSize
 * @param {int} maxChunks
 * @returns {Generator<string, void, *>}
 */
const chunks = function * (string, chunkSize = 1500, maxChunks = 2048) {
  const nrOfChunks = Math.max(0, Math.min(maxChunks, Math.ceil(string.length / chunkSize)))
  for (let i = 0; i < nrOfChunks; i++) {
    yield string.slice(i * chunkSize, (i + 1) * chunkSize)
  }
}

export const exportedForTesting = {
  unchunkReadableTextStream,
  chunks
}
