import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fulfillmentMethodSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import PrintMagstripeModal from './print-magstripe-modal'
import PrintBarcodeModal from './print-barcode-modal'
import PrintGiftVoucherModal from './print-gift-voucher-modal'
import LeapTopUpModal from './leap-top-up-modal'
import LeapModal from './leap-add-product-modal'
import AdditionalFulfillmentMethodModal from '../../../components/base/finalize-booking/additional-fulfillmentmethod-modal'
import {
  FULFILLMENT_METHOD_CODE_MAGSTRIPE,
  FULFILLMENT_METHOD_CODE_BARCODE,
  FULFILLMENT_METHOD_CODE_LEAP_TOP_UP,
  FULFILLMENT_METHOD_CODE_LEAP,
  FINALIZE_BOOKING_MODAL,
  FULFILLMENT_METHOD_GIFT_VOUCHER,
  BOM_FULFILLMENT_METHODS
} from '../../../constants'
import { completedOrOverviewBookingSelector } from '../../../redux/selectors/api/booking/booking'

class FinalizeBookingContainer extends Component {
  static propTypes = {
    fulfillmentMethod: PropTypes.object,
    onChangeFulfillment: PropTypes.func.isRequired,
    onNextSale: PropTypes.func.isRequired,
    showPrintMagstripeModal: PropTypes.bool,
    showPrintBarcodeModal: PropTypes.bool,
    showLeapTopUpModal: PropTypes.bool,
    showLeapModal: PropTypes.bool,
    aftersales: PropTypes.bool,
    showAdditionalFulfillmentMethodModal: PropTypes.bool,
    showGiftVoucherModal: PropTypes.bool,
    loadingNextSale: PropTypes.bool
  }

  render () {
    const modalProps = {
      aftersales: this.props.aftersales,
      onChangeFulfillment: this.props.onChangeFulfillment,
      onNextSale: this.props.onNextSale,
      loadingNextSale: this.props.loadingNextSale
    }

    return (
      <react-wrapper>
        {this.props.showPrintMagstripeModal ? <PrintMagstripeModal {...modalProps} /> : null}
        {this.props.showPrintBarcodeModal ? <PrintBarcodeModal {...modalProps} /> : null}
        {this.props.showLeapTopUpModal ? <LeapTopUpModal {...modalProps} /> : null}
        {this.props.showLeapModal ? <LeapModal {...modalProps} /> : null}
        {this.props.showGiftVoucherModal ? <PrintGiftVoucherModal {...modalProps} /> : null}
        {this.props.showAdditionalFulfillmentMethodModal
          ? <AdditionalFulfillmentMethodModal
            {...modalProps}
            fulfillmentMethod={this.props.fulfillmentMethod}
          /> : null}
      </react-wrapper>
    )
  }
}

const _fulfillmentMethodSelector = fulfillmentMethodSelector(completedOrOverviewBookingSelector)

const mapStateToProps = state => {
  const fulfillmentMethod = (_fulfillmentMethodSelector(state) || {})
  const fulfillmentMethodCode = fulfillmentMethod.code
  const showModal = state.containers.base.elementVisibility[FINALIZE_BOOKING_MODAL]
  const additionalFulfillmentMethod = !BOM_FULFILLMENT_METHODS.includes(fulfillmentMethodCode)

  const props = {fulfillmentMethod}
  if (showModal && fulfillmentMethodCode) {
    props.showPrintMagstripeModal = FULFILLMENT_METHOD_CODE_MAGSTRIPE === fulfillmentMethodCode
    props.showPrintBarcodeModal = FULFILLMENT_METHOD_CODE_BARCODE === fulfillmentMethodCode
    props.showLeapTopUpModal = FULFILLMENT_METHOD_CODE_LEAP_TOP_UP === fulfillmentMethodCode
    props.showLeapModal = FULFILLMENT_METHOD_CODE_LEAP === fulfillmentMethodCode
    props.showGiftVoucherModal = FULFILLMENT_METHOD_GIFT_VOUCHER === fulfillmentMethodCode
    props.showAdditionalFulfillmentMethodModal = additionalFulfillmentMethod
  }

  return props
}

export default connect(mapStateToProps)(FinalizeBookingContainer)
