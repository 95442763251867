import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  overviewBookingSelector,
  fulfillmentMethodSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import containVisibleElement from '../../../../containers/base/visible-element'
import { isLoadingToolbarActionsSelector } from '../../../../redux/selectors/containers/aftersales/booking/toolbar'
import { FULFILLMENT_METHOD_ICON_MAP } from '../../../../constants'
import {
  isChangeFulfillmentAvailableSelector,
  isOverrideValidationRulesSelector
} from '../../../../redux/selectors/containers/base/aftersales'
import ToolbarItem from '../../../../components/aftersales/booking/toolbar/toolbar-item'
import { goToChangeFulfillment } from '../../../../redux/actions/containers/base/void-tickets-routing'

const _fulfillmentMethodSelector = fulfillmentMethodSelector(overviewBookingSelector)
const _isAvailableSelector = isChangeFulfillmentAvailableSelector(overviewBookingSelector)
const _isOverrideValidationRulesSelector = isOverrideValidationRulesSelector(overviewBookingSelector)

const mapStateToProps = state => {
  const fulfillmentMethod = _fulfillmentMethodSelector(state)
  const fulfillmentMethodCode = fulfillmentMethod && fulfillmentMethod.code

  return {
    disabled: isLoadingToolbarActionsSelector(state),
    available: _isAvailableSelector(state),
    overrideValidationRules: _isOverrideValidationRulesSelector(state),
    iconName: FULFILLMENT_METHOD_ICON_MAP[fulfillmentMethodCode] || FULFILLMENT_METHOD_ICON_MAP['default']
  }
}

const mapDispatchToProps = (_, ownProps) => dispatch => ({
  onClick: () => dispatch(goToChangeFulfillment(ownProps.bookingNumber))
})

const container = compose(
  connect(mapStateToProps, mapDispatchToProps),
  containVisibleElement()
)

export default container(ToolbarItem)
