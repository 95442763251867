import {
  API_ADD_BOOKING_NOTE_REQUEST,
  API_ADD_BOOKING_NOTE_SUCCESS,
  API_ADD_BOOKING_NOTE_FAILURE,
  CLEAR_STATE
} from 's3p-js-lib/src/redux/actions/types'
import { CONTAINERS_AFTERSALES_BOOKING_NOTES } from '../../../../../constants'

const initialState = {saved: false}

export default function (state = initialState, action) {
  switch (action.type) {
    case API_ADD_BOOKING_NOTE_REQUEST:
    case API_ADD_BOOKING_NOTE_FAILURE:
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_AFTERSALES_BOOKING_NOTES) ? initialState : state
    case API_ADD_BOOKING_NOTE_SUCCESS:
      return {saved: true}
    default:
      return state
  }
}
