import { connect } from 'react-redux'
import NextButton from '../../../../components/base/seat-selection/next-button'
import containRebookingFlow from '../../../../containers/aftersales/rebooking-flow'
import { hasSelectedSeatsSelector } from 's3p-js-lib/src/redux/selectors/user-input/base/seat-selection'
import { allSegmentsSeatSelectionOptionsSelector } from '../../../../redux/selectors/containers/base/seat-selection/seat-selection'

const aftersalesHasSelectedSeatsSelector = hasSelectedSeatsSelector(allSegmentsSeatSelectionOptionsSelector)

const mapStateToProps = state => ({
  hasSelectedSeats: aftersalesHasSelectedSeatsSelector(state),
  isLoading: state.api.loading.createProvisionalBooking
})

export default connect(mapStateToProps)(containRebookingFlow(NextButton))
