import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import {
  bookingNumberSelector,
  completedBookingSelector,
  fulfillmentMethodSelector,
  totalPriceBookingSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { goToAftersalesBooking } from '../../../redux/actions/containers/base/routing'
import LeapModal from '../../../components/base/finalize-booking/leap-modal'
import { isOfflineSelectorCreator } from '../../../redux/selectors/machine/offline'
import {
  leapCardIsValidSelector,
  leapDataSelector,
  purseAmountTooHighSelector,
  purseAmountTooLowSelector
} from '../../../redux/selectors/machine/leap'
import {
  CONTAINERS_LEAP_ADD_PRODUCT,
  CONTAINERS_LEAP_DOUBTFUL_TRANSACTION,
  CONTAINERS_LEAP_READ,
  CONTAINERS_LEAP_TOP_UP,
  DEVICE_ID_LEAP,
  DEVICE_ID_RECEIPT_PRINTER,
  FINALIZE_BOOKING_MODAL,
  MACHINE_LEAP
} from '../../../constants'
import { lastMachineErrorsSelector } from '../../../redux/selectors/machine/last-machine-errors'
import {
  maximumTransactionValueSelector,
  minimumTransactionValueSelector
} from '../../../redux/selectors/containers/base/leap'
import { hideElement } from '../../../redux/actions/containers/base/visible-element'
import { resetReservations } from '../../../redux/actions/containers/reservations/finalize-booking'
import { resetTickets } from '../../../redux/actions/containers/tickets/products'

class LeapModalContainer extends Component {
  static propTypes = {
    clearState: PropTypes.func.isRequired,
    handleLeapAction: PropTypes.func.isRequired,
    isOfflineLeapDevice: PropTypes.bool
  }

  UNSAFE_componentWillMount () {
    if (!this.props.isOfflineLeapDevice) {
      this.props.handleLeapAction()
    }
  }

  componentWillUnmount () {
    this.props.clearState()
  }

  render () {
    return (
      <LeapModal
        {...this.props}
        handleRetry={this.props.handleLeapAction}
      />
    )
  }
}

const leapDeviceOfflineSelector = isOfflineSelectorCreator(DEVICE_ID_LEAP)
const ticketPrinterOfflineSelector = isOfflineSelectorCreator(DEVICE_ID_RECEIPT_PRINTER)
const _totalPriceBookingSelector = totalPriceBookingSelector(completedBookingSelector)
const _lastMachineErrorsSelector = lastMachineErrorsSelector([DEVICE_ID_LEAP])
const _fulfillmentMethodSelector = fulfillmentMethodSelector(completedBookingSelector)

const mapStateToProps = (state, ownProps) => {
  const incorrectPurseAmount = purseAmountTooLowSelector(state) || purseAmountTooHighSelector(state)
  const leapCardData = leapDataSelector(state)
  const isOfflineLeapDevice = leapDeviceOfflineSelector(state)

  const disableSaleButtons =
    ownProps.isLoading || (
      !isOfflineLeapDevice &&
      !incorrectPurseAmount &&
      !ownProps.leapActionError &&
      !ownProps.leapActionSuccess
    )

  let props = {}
  if (ownProps.leapActionError) {
    props = {
      minimumTransactionValue: minimumTransactionValueSelector(state),
      maximumTransactionValue: maximumTransactionValueSelector(state),
      expiryDate: leapCardData && leapCardData.expiryDate
    }
  }

  return {
    ...props,
    leapCardData,
    isOfflineLeapDevice,
    fulfillmentMethod: _fulfillmentMethodSelector(state),
    isOfflineTicketPrinter: ticketPrinterOfflineSelector(state),
    isLoadingLeapRead: state.containers.leap.read.loading,
    leapActionSuccess: ownProps.leapActionSuccess && !_lastMachineErrorsSelector(state).length,
    totalBookingPrice: _totalPriceBookingSelector(state),
    disableNextSale: disableSaleButtons,
    disableCancelSale: disableSaleButtons,
    disableRetry: ownProps.isLoading || (
      !isOfflineLeapDevice &&
      leapCardData &&
      (incorrectPurseAmount || !leapCardIsValidSelector(state) || ownProps.leapActionSuccess)
    )
  }
}

const mapDispatchToProps = {
  clearState: () => clearState([
    'api.modules.leap.confirmAction',
    'api.modules.leap.getActions',
    CONTAINERS_LEAP_ADD_PRODUCT,
    CONTAINERS_LEAP_DOUBTFUL_TRANSACTION,
    CONTAINERS_LEAP_READ,
    CONTAINERS_LEAP_TOP_UP,
    MACHINE_LEAP
  ]),
  onClickOpenAftersales: () => (dispatch, getState) => dispatch([
    hideElement(FINALIZE_BOOKING_MODAL),
    resetReservations(),
    resetTickets(),
    goToAftersalesBooking(bookingNumberSelector(completedBookingSelector)(getState()))
  ])
}

export default connect(mapStateToProps, mapDispatchToProps)(LeapModalContainer)
