import React, { Component } from 'react'
import PropTypes from '../../../../misc/prop-types'
import classNames from 'classnames'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../../elements/text-label/text-label'
import Icon from '../../../../elements/icon/icon'
import Link from '../../../../elements/link/link'
import Bundle from './bundle'
import JourneyDetails from '../../../../containers/base/journey-search/journey-details/journey-details'
import ServiceProperties from '../../../../containers/base/journey-search/journey-result-set/service-properties'
import './route-set.scss'

export default class Route extends Component {
  static propTypes = {
    departureTime: PropTypes.moment,
    arrivalTime: PropTypes.moment,
    handleSelectBundle: PropTypes.func.isRequired,
    transfers: PropTypes.array,
    departureStation: PropTypes.shape({
      name: PropTypes.string.isRequired
    }),
    arrivalStation: PropTypes.shape({
      name: PropTypes.string.isRequired
    }),
    travelTime: PropTypes.object,
    bundles: PropTypes.array,
    routeId: PropTypes.string,
    showJourneyDetails: PropTypes.bool,
    onShowJourneyDetails: PropTypes.func.isRequired,
    serviceProperties: PropTypes.array,
    hasTravelInfo: PropTypes.bool
  }

  render () {
    const {
      departureTime,
      arrivalTime,
      transfers,
      departureStation,
      arrivalStation,
      travelTime,
      bundles,
      serviceProperties,
      hasTravelInfo,
      showJourneyDetails
    } = this.props

    const isExpanded = bundles.every(bundle => !bundle.isFilteredOut) && bundles.some(bundle => bundle.selected)
    const routeClasses = classNames('route', {
      'state--is-available': bundles.length > 0 && !showJourneyDetails,
      'state--is-unavailable': bundles.every(bundle => !bundle.isAvailable),
      'state--is-selected': bundles.some(bundle => bundle.selected)
    })

    return departureTime
      ? (
        <div
          className={routeClasses}
          aria-selected={isExpanded ? 'true' : 'false'}
          aria-expanded={isExpanded ? 'true' : 'false'}
        >
          <div className='route__journey-overview'>
            <div className='route__journey-information-meta'>
              <div className='journey-time-segment'>
                <div className='journey-time-segment__departure-time'>
                  <TextLabel name='departure-time' text={_t.formatMomentTime(departureTime, 'short')} />
                </div>
                <div className='journey-time-segment__journey-segments'>
                  <div className='journey-segments'>
                    <div className='journey-section origin'>
                      <div className='journey-section__marker' />
                    </div>
                    {transfers && transfers.map((_, index) => (
                      <div key={index} className='journey-section transfer'>
                        <div className='journey-section__marker' />
                      </div>
                    ))}
                    <div className='journey-section destination'>
                      <div className='journey-section__marker' />
                    </div>
                  </div>
                </div>
                <div className='journey-time-segment__arrival-time'>
                  <TextLabel name='arrival-time' text={_t.formatMomentTime(arrivalTime, 'short')} />
                </div>
              </div>
              <div className='journey-information'>
                <div className='journey-information__origin'>
                  <TextLabel name='origin' text={departureStation.name} />
                </div>
                <div className='journey-information__transfer-information'>
                  <TextLabel name='travel-time' text={_t.message(
                    'journey-result-set.route.travel-time', {
                      hours: `${Math.floor(travelTime.hours())}`,
                      minutes: travelTime.minutes() < 10 ? `0${travelTime.minutes()}` : travelTime.minutes()
                    })} />
                  {transfers.length ? <TextLabel
                    name='transfers'
                    text={_t.message('journey-result-set.route.transfers', {transferCount: transfers.length})}
                  /> : null}
                </div>
                <div className='journey-information__destination'>
                  <TextLabel name='destination' text={arrivalStation.name} />
                </div>
              </div>
              <div className='journey-meta'>
                <div className='journey-meta__service-features'>
                  {serviceProperties
                    ? (
                      <ServiceProperties
                        showDefaultIcons
                        showAlert={hasTravelInfo}
                        serviceProperties={serviceProperties}
                      />
                    ) : null
                  }
                </div>
                <div className='journey-meta__show-details'>
                  {!showJourneyDetails ? (
                    <Link name='show-details' tabIndex='-1' onClick={this.props.onShowJourneyDetails}>
                      <TextLabel name='show-details' text={_t.message('journey-result-set.route.show-details')} />
                      {hasTravelInfo
                        ? <Icon name='warning' className='medium align-right warning-show-details' /> : null
                      }
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='route__product-family-set'>
              <div className='product-family-set'>
                {bundles.length
                  ? bundles.map((bundle, index) => (
                    <Bundle
                      key={index}
                      bundle={bundle}
                      handleSelectBundle={this.props.handleSelectBundle}
                    />
                  )) : (
                    <div className='no-product-families-available'>
                      <TextLabel name='description'>
                        <Icon name='tickets' className='medium align-left' />
                        <span dangerouslySetInnerHTML={
                          { __html: _t.message('journey-result-set.route.no-bundles') }
                        } />
                      </TextLabel>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          {showJourneyDetails ? <JourneyDetails routeId={this.props.routeId} /> : null}
        </div>
      ) : null
  }
}
