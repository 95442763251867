import { connect } from 'react-redux'
import {
  loadDefaultFormValues,
  rebookingJourneySearch
} from '../../../../redux/actions/containers/aftersales/rebooking/journey-search'
import JourneySearch from '../../../base/journey-search/journey-search'

const mapStateToProps = state => ({
  isLoading: state.api.loading.rebookingInitiate,
  isLoadingJourneySearch: state.api.loading.rebookingJourneySearch
})

const mapDispatchToProps = {
  handleJourneySearch: rebookingJourneySearch,
  loadDefaultFormValues
}

export default connect(mapStateToProps, mapDispatchToProps)(JourneySearch)
