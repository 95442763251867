import { compose } from 'redux'
import { connect } from 'react-redux'
import { cancellationInitiate } from 's3p-js-lib/src/redux/actions/api/aftersales/cancellation/initiate'
import { overviewBookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import containVisibleElement from '../../../../containers/base/visible-element'
import { routerActions } from '../../../../redux/actions/containers/base/routing'
import {
  isLoadingToolbarActionsSelector,
  isOverrideAftersalesRulesSelector
} from '../../../../redux/selectors/containers/aftersales/booking/toolbar'
import { cancellationAllowedSelector } from '../../../../redux/selectors/api/booking/after-sales'
import ToolbarItem from '../../../../components/aftersales/booking/toolbar/toolbar-item'
import { isCancellationAvailableSelector } from '../../../../redux/selectors/containers/base/aftersales'

const _isCancellationAvailableSelector = isCancellationAvailableSelector(overviewBookingSelector)
const _isOverrideAftersalesRulesSelector = isOverrideAftersalesRulesSelector(cancellationAllowedSelector)

const mapStateToProps = state => ({
  loading: state.api.loading.cancellationInitiate,
  disabled: isLoadingToolbarActionsSelector(state),
  available: _isCancellationAvailableSelector(state),
  overrideAftersalesRules: _isOverrideAftersalesRulesSelector(state)
})

const mapDispatchToProps = (_, ownProps) => dispatch => ({
  onClick: async () => {
    if (await dispatch(cancellationInitiate(ownProps.bookingNumber))) {
      dispatch(routerActions.push('/aftersales/cancellation'))
    }
  }
})

const container = compose(
  connect(mapStateToProps, mapDispatchToProps),
  containVisibleElement()
)

export default container(ToolbarItem)
