import {
  API_V2_REMOVE_ITEMS_REQUEST,
  API_V2_REMOVE_ITEMS_SUCCESS,
  API_V2_REMOVE_ITEMS_FAILURE
} from '../../types-v2'
import Client from '../../../../api/client'
import ensureToken from '../../../../api/ensure-token'
import { bookingSelector, bookingNumberSelector } from '../../../selectors/api/booking/booking'

const currentBookingNumberSelector = bookingNumberSelector(bookingSelector)

const removeItemsBookingRequest = (bookingNumber, itemRefs) => ({
  type: API_V2_REMOVE_ITEMS_REQUEST,
  bookingNumber,
  itemRefs
})
const removeItemsBookingSuccess = response => ({type: API_V2_REMOVE_ITEMS_SUCCESS, response})
const removeItemsBookingFailure = error => ({type: API_V2_REMOVE_ITEMS_FAILURE, error})

export const removeItems = itemRefs => ensureToken(async (token, dispatch, getState) => {
  const bookingNumber = currentBookingNumberSelector(getState())
  try {
    dispatch(removeItemsBookingRequest(bookingNumber, itemRefs))
    const response = await Client.removeItems(token, bookingNumber, itemRefs)
    dispatch(removeItemsBookingSuccess(response))
  } catch (error) {
    dispatch(removeItemsBookingFailure(error))
    throw error
  }
})
