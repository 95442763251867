import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ProgressBar from '../../../../elements/progress-bar/progress-bar'
import Segment from './segment'

export default class SeatSelectorSegmentNavigation extends Component {
  static propTypes = {
    segmentId: PropTypes.string.isRequired,
    onSelectSegment: PropTypes.func.isRequired,
    outboundSegments: PropTypes.arrayOf(PropTypes.shape({
      leg: PropTypes.shape({
        id: PropTypes.string.isRequired
      }),
      available: PropTypes.bool.isRequired
    })).isRequired,
    inboundSegments: PropTypes.arrayOf(PropTypes.shape({
      leg: PropTypes.shape({
        id: PropTypes.string.isRequired
      }),
      available: PropTypes.bool.isRequired
    })).isRequired
  }

  render () {
    const segments = this.props.outboundSegments.concat(this.props.inboundSegments)
    return segments.length > 1 ? (
      <ProgressBar>
        {segments.map(segment => segment.available ? (
          <Segment
            key={segment.leg.id}
            segmentId={segment.leg.id}
            onClick={this.props.onSelectSegment}
            {...segment}
          />
        ) : null)}
      </ProgressBar>
    ) : null
  }
}
