import {
  API_GET_DOCUMENT_TEMPLATES_REQUEST,
  API_GET_DOCUMENT_TEMPLATES_SUCCESS,
  API_GET_DOCUMENT_TEMPLATES_FAILURE,
  CLEAR_STATE
} from '../../../actions/types'

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_GET_DOCUMENT_TEMPLATES_REQUEST:
    case API_GET_DOCUMENT_TEMPLATES_FAILURE:
      return initialState
    case API_GET_DOCUMENT_TEMPLATES_SUCCESS:
      return action.response.data.document_templates
    case CLEAR_STATE:
      return action.namespaces.includes('api.meta.documentTemplates') ? initialState : state
    default:
      return state
  }
}
