import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Feedback, {FEEDBACK_INFORMATION} from '../../../../../elements/feedback/feedback'
import RefundCancel from '../../../../../containers/aftersales/booking/payment-details/refund/refund-cancel'
import Modal from '../../../../../elements/modal/modal'
import TextLabel from '../../../../../elements/text-label/text-label'
import Button from '../../../../../elements/button/button'
import _t from 's3p-js-lib/src/translate'

export default class RefundCash extends Component {
  static propTypes = {
    amount: PropTypes.number,
    isLoading: PropTypes.bool,
    confirmRefundCash: PropTypes.func,
    cancelRefund: PropTypes.func
  }

  render () {
    return (
      <div>
        <Feedback status={FEEDBACK_INFORMATION} text={_t.message('aftersales.refund-modal.cash.refund-description', {
          amount: _t.formatCurrency(this.props.amount)
        })} />
        <Modal.CustomFooter>
          <RefundCancel />
          <Button
            name='confirm'
            hotkey='proceed'
            className='primary'
            onClick={this.props.confirmRefundCash}
            loading={this.props.isLoading}
          >
            <TextLabel name='next-step' text={_t.message('aftersales.refund-modal.cash.confirm-payout')} />
          </Button>
        </Modal.CustomFooter>
      </div>
    )
  }
}
