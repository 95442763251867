import { CLEAR_STATE } from '../../../../../actions/types'
import {
  API_V2_GET_AGENT_SHIFT_REPORT_REQUEST,
  API_V2_GET_AGENT_SHIFT_REPORT_SUCCESS,
  API_V2_GET_AGENT_SHIFT_REPORT_FAILURE
} from '../../../../../actions/types-v2'

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_V2_GET_AGENT_SHIFT_REPORT_REQUEST:
    case API_V2_GET_AGENT_SHIFT_REPORT_FAILURE:
      return initialState
    case API_V2_GET_AGENT_SHIFT_REPORT_SUCCESS:
      return action.response.data?.shift_report || initialState
    case CLEAR_STATE:
      return action.namespaces.includes('api.v2.user.agent.shiftReport') ? initialState : state
    default:
      return state
  }
}
