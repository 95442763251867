import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Icon from '../../../../elements/icon/icon'

const productIcons = {
  ST: 'train',
  TO: 'train',
  SO: 'train',
  BK: 'bicycle',
  FB: 'meal',
  ET: 'car-park',
  DG: 'pets',
  VC: 'add-voucher',
  DEFAULT: 'add'
}

export default class ProductIcon extends Component {
  static propTypes = {
    productType: PropTypes.oneOf(Object.keys(productIcons)).isRequired,
    name: PropTypes.string,
    className: PropTypes.string
  }

  render () {
    return (
      <Icon name={productIcons[this.props.productType] || productIcons.DEFAULT} className={this.props.className} />
    )
  }
}
