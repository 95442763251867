import { createSelector } from 'reselect'
import {
  PAYMENT_METHOD_CODE_VOUCHER,
  PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD,
  PAYMENT_METHOD_PD_CREDIT_DEBIT_CARD,
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_CODE_REFUND,
  PAYMENT_METHODS_REFUND_ALLOWED
} from '../../../../../constants'
import { PAYMENT_STATUS_S } from 's3p-js-lib/src/constants'
import { overviewBookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { paymentsSelector } from '../../../api/booking/payments'
import { isPedPaymentMethod } from '../../../../../misc/utils'

export const getPaymentsMadeByMethod = payments => Object.values(payments
  .reduce((types, payment) => {
    const paymentMethod = payment.method === PAYMENT_METHOD_PD_CREDIT_DEBIT_CARD ? PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD : payment.method
    if (!types[paymentMethod]) {
      types[paymentMethod] = {
        amount: 0,
        method: paymentMethod,
        payments: []
      }
    }
    types[paymentMethod].amount += Math.round(100 * payment.amount / (payment.currencyRate || 1)) / 100
    types[paymentMethod].payments.push(payment)

    return types
  }, {}))
  .sort((a, b) => a.amount < b.amount ? 1 : -1)

export const payoutRecordsSelector = bookingSelector => createSelector(
  [paymentsSelector(bookingSelector)],
  payments => {
    const bookingPayments = payments.filter(payment => payment.paymentStatus === PAYMENT_STATUS_S)
    const refundPayments = bookingPayments.filter(payment =>
      payment.amount < 0 &&
      payment.refundAvailable &&
      payment.method === PAYMENT_METHOD_CODE_REFUND
    )
    const sumTotalPayments = refundPayments.reduce((amount, payment) => amount + payment.amount, 0)

    const payoutRecords = []
    const paymentsByMethods = getPaymentsMadeByMethod(bookingPayments).filter(
      payment => payment.method !== PAYMENT_METHOD_CODE_REFUND
    ).sort((a, _) => a.method === PAYMENT_METHOD_CODE_VOUCHER ? 1 : 0)

    let methodCounter = 0
    let currentTotal = Math.abs(sumTotalPayments)

    while (currentTotal > 0 && paymentsByMethods[methodCounter]) {
      const activeMethod = paymentsByMethods[methodCounter]
      payoutRecords.push({
        method: activeMethod.method,
        amount: Math.min(activeMethod.amount, currentTotal)
      })
      currentTotal -= activeMethod.amount
      methodCounter++
    }

    if (sumTotalPayments > 0) {
      payoutRecords.push({
        method: PAYMENT_METHOD_CODE_VOUCHER,
        amount: sumTotalPayments
      })
    }

    return payoutRecords.filter(payout => payout.amount > 0)
  }
)

export const payoutOrderSelector = bookingSelector => createSelector(
  [payoutRecordsSelector(bookingSelector)],
  records => {
    const list = []
    if (records.some(record => isPedPaymentMethod(record.method))) {
      list.push(PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD)
    }

    if (records.some(record => record.method === PAYMENT_METHOD_CASH)) {
      list.push(PAYMENT_METHOD_CASH)
    }

    if (records.some(record => record.method === PAYMENT_METHOD_CODE_VOUCHER)) {
      list.push(PAYMENT_METHOD_CODE_VOUCHER)
    }

    return list
  }
)

export const isRefundPaymentAvailableSelector = createSelector(
  [payoutRecordsSelector(overviewBookingSelector)],
  payoutRecords => payoutRecords.some(record => PAYMENT_METHODS_REFUND_ALLOWED.includes(record.method))
)
