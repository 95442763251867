import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Modal from '../../../elements/modal/modal'
import FinalizeBookingFooter from './finalize-booking-footer'
import TextLabel from '../../../elements/text-label/text-label'
import Icon from '../../../elements/icon/icon'
import FeedbackMachine from '../../../containers/base/last-machine-error-feedback'
import Feedback, { FEEDBACK_WARNING } from '../../../elements/feedback/feedback'
import { FINALIZE_BOOKING_MODAL } from '../../../constants'
import { MESSAGE_SEVERITY_WARNING, MESSAGE_SEVERITY_ERROR } from 's3p-js-lib/src/constants'

export default class FinalizeBookingModal extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    messages: PropTypes.array,
    totalTickets: PropTypes.number,
    ticketNumber: PropTypes.number,
    isOfflineTicketPrinter: PropTypes.bool,
    isOfflineReceiptPrinter: PropTypes.bool,
    isPrinting: PropTypes.bool,
    isVoucher: PropTypes.bool,
    available: PropTypes.bool,
    overrideAftersalesRules: PropTypes.bool
  }

  render () {
    return (
      <Modal
        name={FINALIZE_BOOKING_MODAL}
        header={<Modal.Title title={this.props.title} />}
        isCloseable={false}
        footer={
          <FinalizeBookingFooter
            {...this.props}
            allTicketsPrinted={this.props.ticketNumber >= this.props.totalTickets && this.props.totalTickets > 0}
            changeFulfillmentAvailable={this.props.available || this.props.overrideAftersalesRules}
          />
        }
      >
        {this.props.isOfflineReceiptPrinter
          ? <Feedback status={FEEDBACK_WARNING} text={_t.message('finalize-booking-modal.receipt-printer.offline')} />
          : null
        }
        {this.props.isOfflineTicketPrinter
          ? <Feedback status={FEEDBACK_WARNING} text={_t.message('finalize-booking-modal.ticket-printer.offline')} />
          : null
        }
        {!this.props.isOfflineTicketPrinter && this.props.isPrinting
          ? (
            <div className='read-status'>
              <TextLabel name='status-indicator' className='animation-blink'>
                <Icon name='print-ticket' className='status-indicator align-left' />
                {this.props.isVoucher
                  ? <TextLabel.Text text={_t.message(
                    'finalize-booking-modal.printing-voucher',
                    {number: this.props.ticketNumber, total: this.props.totalTickets}
                  )} />
                  : this.props.ticketNumber && this.props.totalTickets
                    ? <TextLabel.Text text={_t.message(
                      'finalize-booking-modal.printing-tickets',
                      {number: this.props.ticketNumber, total: this.props.totalTickets}
                    )} />
                    : null
                }
              </TextLabel>
            </div>
          ) : null}
        {(this.props.messages || []).map(message => (
          <Feedback
            key={message.text.join('-')}
            status={message.status}
            text={message.text.join(', ')}
          />
        ))}
        <FeedbackMachine namespaces={['ticketPrinter']} severities={[MESSAGE_SEVERITY_WARNING]} />
        <FeedbackMachine
          namespaces={['receiptPrinter']}
          severities={[MESSAGE_SEVERITY_WARNING, MESSAGE_SEVERITY_ERROR]}
        />
        {this.props.children}
      </Modal>
    )
  }
}
