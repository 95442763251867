import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import PropTypes from '../../../misc/prop-types'
import SegmentSet from '../../../elements/segment-set/segment-set'
import Segment from '../../../containers/aftersales/booking/segment-set/segment'
import Form from '../../../../src/elements/form/form'

const PREFIX = 'aftersales.booking.journey-overview.segment-set-header'

class ShowCancelledItemsSwitcher extends Component {
  static propTypes = {
    hasCancelledItems: PropTypes.bool,
    direction: PropTypes.oneOf(['inbound', 'outbound']).isRequired,
    onChange: PropTypes.func,
    checked: PropTypes.bool
  }

  render () {
    return this.props.hasCancelledItems ? (
      <Form>
        <fieldset>
          <Form.InputGroup name={`show-cancelled-items-${this.props.direction}`}>
            <Form.InputWrapper name={`show-cancelled-items-${this.props.direction}`}>
              <Form.Checkbox
                id={`show-cancelled-items-${this.props.direction}`}
                name={`show-cancelled-items-${this.props.direction}`}
                checked={this.props.checked}
                placeholder=''
                onChange={this.props.onChange}
              >
                <Form.Label
                  htmlFor={`show-cancelled-items-${this.props.direction}`}
                  name={`show-cancelled-items-${this.props.direction}`}
                  text={_t.message('aftersales.booking.journey-overview.segment-set-header.check-box-label')}
                />
              </Form.Checkbox>
            </Form.InputWrapper>
          </Form.InputGroup>
        </fieldset>
      </Form>
    ) : null
  }
}
export default class SegmentSetDirection extends Component {
  static propTypes = {
    travelDate: PropTypes.moment,
    direction: PropTypes.oneOf(['inbound', 'outbound']).isRequired, // todo constanten
    segments: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired
    })).isRequired,
    hasCancelledItems: PropTypes.bool.isRequired,
    onToggleElement: PropTypes.func.isRequired,
    elementVisibility: PropTypes.object.isRequired,
    isElementVisible: PropTypes.func
  }

  constructor (...args) {
    super(...args)
    this.toggleSegmentSet = this.toggleSegmentSet.bind(this)
  }

  toggleSegmentSet () {
    this.props.onToggleElement(this._getToggleName())
  }

  render () {
    const {segments, direction, travelDate, hasCancelledItems} = this.props
    return (
      <SegmentSet
        direction={direction}
        summary={<SegmentSet.JourneySummary
          travelDate={travelDate ? (_t.message(`${PREFIX}.date`, {date: travelDate.format('ddd, D MMM')})) : ''}
          label={_t.message(`${PREFIX}.direction`, {direction})}
          direction={direction}
        />}
        actions={
          <ShowCancelledItemsSwitcher
            hasCancelledItems={hasCancelledItems}
            checked={this._showCancelledItems()}
            direction={direction}
            onChange={this.toggleSegmentSet}
          />
        }
      >
        {segments.map(segment =>
          <Segment key={`${segment.uniqueIdentifier}-${segment.id}`} id={segment.id} showCancelledItems={this._showCancelledItems()} />
        )}
      </SegmentSet>
    )
  }

  _showCancelledItems () {
    return this.props.isElementVisible(this._getToggleName())
  }

  _getToggleName () {
    return `segment-set-${this.props.direction}`
  }
}
