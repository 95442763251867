import React, { Component } from 'react'

export default class FocusTrap extends Component {
  constructor (...args) {
    super(...args)
    this.elements = new Map()
    this.refsForChilds = new Map()
    this.onFocusFirst = this.onFocusFirst.bind(this)
    this.onFocusLast = this.onFocusLast.bind(this)
    this.getRefForChild = this.getRefForChild.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.previouslyFocusedElement = document.activeElement
  }

  componentWillUnmount () {
    this.previouslyFocusedElement && this.previouslyFocusedElement.focus()
  }

  getRefForChild (child) {
    if (!this.refsForChilds.has(child)) {
      this.refsForChilds.set(child, element => {
        if (element) {
          this.elements.set(child, element)
        } else {
          this.elements.delete(child)
        }
      })
    }
    return this.refsForChilds.get(child)
  }

  onFocusFirst () {
    for (let element of Array.from(this.elements.values()).reverse()) {
      const tabbables = this._getTabbables(element)
      if (tabbables.length > 0) {
        tabbables[tabbables.length - 1].focus()
        break
      }
    }
  }

  onFocusLast () {
    for (let element of this.elements.values()) {
      const tabbables = this._getTabbables(element)
      if (tabbables.length > 0) {
        tabbables[0].focus()
        break
      }
    }
  }

  _getTabbables (element) {
    return Array.from(element.querySelectorAll('select, input, button, a'))
  }

  render () {
    return (
      <react-wrapper>
        <div tabIndex='0' onFocus={this.onFocusFirst} />
        {React.Children.map(this.props.children, child => React.cloneElement(child, {
          ref: this.getRefForChild(child)
        }))}
        <div tabIndex='0' onFocus={this.onFocusLast} />
      </react-wrapper>
    )
  }
}
