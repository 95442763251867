import React, { Component } from 'react'
import PropTypes from '../../../misc/prop-types'
import TextLabel from '../../../elements/text-label/text-label'
import Icon from '../../../elements/icon/icon'
import moment from 'moment'
import _t from 's3p-js-lib/src/translate'

export default class ProvisionalTimer extends Component {
  static propTypes = {
    expiryTimestamp: PropTypes.moment,
    isProvisional: PropTypes.bool.isRequired
  }

  constructor (...args) {
    super(...args)

    this._updateTime = this._updateTime.bind(this)
    this.state = {
      currentDate: null
    }
  }

  componentDidMount () {
    if (this.props.isProvisional) {
      this._updateTime()
      this.interval = setInterval(this._updateTime, 1000)
    }
  }

  componentWillUnmount () {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  _updateTime () {
    const duration = moment.duration(this.props.expiryTimestamp.diff(moment(), 'seconds'), 'seconds')
    this.setState({
      currentDate: duration
    })

    if (this._isExpired()) {
      clearInterval(this.interval)
    }
  }

  _padNumber (nr) {
    return `${nr < 10 ? `0` : ''}${nr}`
  }

  _isExpired () {
    return this.props.expiryTimestamp.isBefore(moment())
  }

  render () {
    if (!this.props.isProvisional || !this.state.currentDate) {
      return null
    }

    return (
      <div className='booking-details-status__provisional-timer'>
        {!this._isExpired()
          ? (
            <TextLabel name='provisional-timer'>
              <Icon name='clock' className='align-left' />
              <TextLabel.Text
                text={_t.message('booking.provisional-timer.expire-in', {
                  days: this.state.currentDate.days(),
                  hours: this._padNumber(this.state.currentDate.hours()),
                  minutes: this._padNumber(this.state.currentDate.minutes()),
                  seconds: this._padNumber(this.state.currentDate.seconds())
                })}
              />
            </TextLabel>
          ) : (
            <TextLabel name='provisional-timer-expired'>
              <Icon name='clock' className='medium align-left' />
              <TextLabel.Text text={_t.message('booking.provisional-timer.is-expired')} />
            </TextLabel>
          )}
      </div>
    )
  }
}
