import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Modal from '../../../../elements/modal/modal'
import TextLabel from '../../../../elements/text-label/text-label'
import Button from '../../../../elements/button/button'
import { VOID_TICKETS_MODAL } from '../../../../constants'
import Feedback, { FEEDBACK_SUCCESS, FEEDBACK_ALERT } from '../../../../elements/feedback/feedback'
import Icon from '../../../../elements/icon/icon'
import LastApiErrorFeedback from '../../../../containers/base/last-api-error-feedback'

const START_BUTTON_ID = 'start'
const REPRINT_BUTTON_ID = 'reprint'

export default class LeapModal extends Component {
  static propTypes = {
    ticketNumber: PropTypes.number.isRequired,
    totalTickets: PropTypes.number.isRequired,
    isRunning: PropTypes.bool.isRequired,
    success: PropTypes.bool,
    isLoadingRead: PropTypes.bool,
    isLoadingVoid: PropTypes.bool,
    isLoadingEject: PropTypes.bool,
    isLoadingReprint: PropTypes.bool,
    processVoidTickets: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    disableNext: PropTypes.bool,
    reprintResendBooking: PropTypes.func,
    onHide: PropTypes.func,
    hasResendError: PropTypes.bool
  }

  renderFooter () {
    return (
      <Modal.CustomFooter>
        <Button
          name='cancel'
          className='tertiary'
          onClick={this.props.onHide}
          disabled={!this.props.hasResendError && (this.props.success || this.props.isRunning)}
          hotkey='cancel'
        >
          <TextLabel name='cancel' text={_t.message('aftersales.booking.reprint-resend.void-tickets.cancel')} />
        </Button>
        <Button
          id={START_BUTTON_ID}
          name={START_BUTTON_ID}
          className='secondary'
          disabled={this.props.isRunning || this.props.success}
          onClick={this.props.processVoidTickets}
          hotkey='retry'
        >
          <TextLabel name='start' text={_t.message('aftersales.booking.reprint-resend.void-tickets.start')} />
        </Button>
        <Button
          id={REPRINT_BUTTON_ID}
          name={REPRINT_BUTTON_ID}
          className='primary'
          onClick={this.props.reprintResendBooking}
          disabled={this.props.disableNext}
          loading={this.props.isLoadingReprint}
          hotkey='proceed'
        >
          <TextLabel name='reprint' text={_t.message('aftersales.booking.reprint-resend.void-tickets.reprint-tickets')}
          />
        </Button>
      </Modal.CustomFooter>
    )
  }

  render () {
    return (
      <Modal
        name={VOID_TICKETS_MODAL}
        header={<Modal.Title title={_t.message('aftersales.booking.reprint-resend.void-tickets.title')} />}
        footer={this.renderFooter()}
        isCloseable={false}
      >
        <TextLabel name='information'>
          <Icon name='information' className='align-left' />
          <TextLabel.Text text={_t.message('aftersales.booking.reprint-resend.void-tickets.information')} />
        </TextLabel>
        <LastApiErrorFeedback namespaces={['resendTickets']} />
        {this.props.isRunning || this.props.errorMessage || this.props.success
          ? (
            <div className='read-status'>
              <TextLabel name='sub-heading' text={_t.message(
                'aftersales.booking.reprint-resend.void-tickets.present',
                {number: this.props.ticketNumber, total: this.props.totalTickets}
              )} />
              {this.props.isLoadingRead
                ? <TextLabel name='tickets' className='animation-blink'>
                  <Icon name='tickets' className='align-left' />
                  <TextLabel.Text text={_t.message('aftersales.booking.reprint-resend.void-tickets.read')} />
                </TextLabel> : null
              }
              {this.props.isLoadingVoid
                ? <TextLabel name='tickets'>
                  <Icon name='tickets' className='align-left' />
                  <TextLabel.Text text={_t.message('aftersales.booking.reprint-resend.void-tickets.void')} />
                </TextLabel> : null
              }
              {this.props.isLoadingEject
                ? <TextLabel name='tickets'>
                  <Icon name='tickets' className='align-left' />
                  <TextLabel.Text text={_t.message('aftersales.booking.reprint-resend.void-tickets.eject')} />
                </TextLabel> : null
              }
              {this.props.errorMessage ? <Feedback status={FEEDBACK_ALERT} text={this.props.errorMessage} /> : null}
              {this.props.success
                ? <Feedback
                  status={FEEDBACK_SUCCESS}
                  text={_t.message('aftersales.booking.reprint-resend.void-tickets.success')}
                />
                : null}
            </div>
          ) : null}
      </Modal>
    )
  }
}
