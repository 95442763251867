export const mapDevice = (device, translater) => {
  const messages = translater
    ? translater(device.Device)
    : device.Device && device.Details && device.Details.message
      ? [`${device.Device.Id} - ${device.Details.message}`]
      : []

  return ({
    id: device.Device.Id,
    name: device.Device.Name,
    status: device.Device.Status,
    messages,
    details: device.Device.Details
  })
}
