import { createSelector } from 'reselect'

export const lastMachineErrorsSelector = namespaces => createSelector(
  [
    state => state.machine.lastMachineErrors
  ],
  lastMachineErrors => {
    const messages = namespaces === undefined
      ? Object.values(lastMachineErrors)
      : namespaces.map(namespace => lastMachineErrors[namespace])

    return messages.reduce(
      (messages, message) => message ? messages.concat(message) : messages,
      []
    )
  }
)
