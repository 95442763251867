import { connect } from 'react-redux'
import {
  completedBookingSelector,
  totalPriceSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import {
  hasPendingCashOrVoucherPaymentsSelector,
  paymentsWithoutFailedSelector
} from '../../../redux/selectors/api/booking/payments'
import {
  provisionalOrCompletedBookingSelector,
  pendingTotalPriceToBePaidSelector
} from '../../../redux/selectors/api/booking/booking'
import { ticketsApiLoadingSelector } from '../../../redux/selectors/containers/tickets/loading'
import { itemsSelector } from '../../../redux/selectors/containers/tickets/sidebar-cart'
import { returnPaymentModalVisibleSelector } from '../../../redux/selectors/containers/base/payment-modal/return-payment-modal'
import { showReturnPaymentModal } from '../../../redux/actions/containers/base/payment-modal/return-payment-modal'
import { removeVoucher } from 's3p-js-lib/src/redux/actions/api/payment/vouchers'
import {
  removeItems,
  cancelCart
} from '../../../redux/actions/containers/tickets/sidebar-cart'
import SidebarCart from '../../../components/tickets/sidebar/sidebar-cart'

const paymentsSelector = paymentsWithoutFailedSelector(provisionalOrCompletedBookingSelector)
const _hasPendingCashPaymentsSelector = hasPendingCashOrVoucherPaymentsSelector(provisionalOrCompletedBookingSelector)
const _totalPriceSelector = totalPriceSelector(provisionalOrCompletedBookingSelector)
const totalPriceToBePaidSelector = pendingTotalPriceToBePaidSelector(provisionalOrCompletedBookingSelector)

const mapStateToProps = state => ({
  items: itemsSelector(state),
  isLoading: (
    state.api.v2.loading.removeItems ||
    state.containers.tickets.repeatLastBooking.isLoading ||
    ticketsApiLoadingSelector(state)
  ),
  totalPrice: _totalPriceSelector(state),
  totalPriceToBePaid: totalPriceToBePaidSelector(state),
  payments: paymentsSelector(state),
  hasCompletedBooking: Boolean(completedBookingSelector(state)),
  showReturnPaymentModal: returnPaymentModalVisibleSelector(state),
  hasPayments: Boolean(paymentsSelector(state).length)
})

const mapDispatchToProps = {
  handleCancelCart: cancelCart,
  removeVoucher,
  removeItems: item => (dispatch, getState) => {
    if (_hasPendingCashPaymentsSelector(getState())) {
      dispatch(showReturnPaymentModal())
    } else {
      dispatch(removeItems(item))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarCart)
