import { clearLastApiErrors } from 's3p-js-lib/src/redux/actions/api/base/last-api-errors'
import { getProducts } from '../../../actions/api/v2/orientation/search'
import {
  formFieldValuesSelectorCreator,
  formIsValidSelectorCreator
} from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import { validate } from 's3p-js-lib/src/redux/actions/user-input/base/form'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import { FORM_PRODUCT_SEARCH } from 's3p-js-lib/src/constants-form'
import { UI_RESET_TICKETS } from '../../types'
import { machineStationSelector } from '../../../selectors/containers/base/stations'
import { clearMachineDisplay } from '../../machine/display'
import { showModal } from '../base/visible-element'
import { hideReturnPaymentModal } from '../base/payment-modal/return-payment-modal'
import { maxPersonsSelector } from '../../../selectors/containers/base/max-passengers'
import {
  DISABILITY_TYPE_NONE,
  EXTERNAL_REFERENCE_ONLY_BOOKABLE_BEFORE_DAY_OF_CURRENT_MONTH,
  TRAVEL_DIRECTION_OUTWARD,
  CONTAINERS_BASE_TILES_PAYMENT_METHODS,
  CONTAINERS_BASE_FINALIZE_BOOKING_PRINT_TICKETS_MODAL,
  CONTAINERS_BASE_PAYMENT_MODAL_CASH_FORM,
  CONTAINERS_BASE_PAYMENT_MODAL_CREDIT_DEBIT,
  CONTAINERS_TICKETS_PRODUCT_SEARCH_RESULT,
  CONTAINERS_TICKETS_SHORTCUT,
  USERINPUT_AFTERSALES_CANCELLATION,
  FULFILLMENT_METHOD_CODE_LEAP_TOP_UP,
  FULFILLMENT_METHOD_CODE_LEAP
} from '../../../../constants'
import { MAX_PASSENGER_MODAL } from '../../../../components/base/max-passengers-modal'
import { BEFORE_DAY_CURRENT_MONTH_MODAL } from '../../../../components/tickets/modals/before-day-current-month-modal'
import { LEAP_OPTION_CHECK_BALANCE_MODAL } from '../../../../components/tickets/modals/leap-option-check-balance-modal'
import moment from 'moment'

const formFieldValuesSelector = formFieldValuesSelectorCreator(FORM_PRODUCT_SEARCH)
const formIsValidSelector = formIsValidSelectorCreator(FORM_PRODUCT_SEARCH)

export const handleShowLeapModal = () => (dispatch, getState) => {
  const state = getState()
  const isFormValid = formIsValidSelector(state)
  const values = formFieldValuesSelector(state)
  if (
    isFormValid &&
    (
      values.productFamily.code === FULFILLMENT_METHOD_CODE_LEAP_TOP_UP ||
      values.productFamily.code === FULFILLMENT_METHOD_CODE_LEAP
    )
  ) {
    dispatch(showModal(LEAP_OPTION_CHECK_BALANCE_MODAL))
  }
}

export const handleSearchProducts = () => (dispatch, getState) => {
  const state = getState()

  if (formIsValidSelector(state)) {
    const values = formFieldValuesSelector(state)
    const beforeDayOfMonthValue = values.productFamily.attributes.find(
      attribute => attribute.key === EXTERNAL_REFERENCE_ONLY_BOOKABLE_BEFORE_DAY_OF_CURRENT_MONTH
    )

    if (values.passengers.length > maxPersonsSelector(state)) {
      dispatch(showModal(MAX_PASSENGER_MODAL))
    } else if (beforeDayOfMonthValue && values.validFrom.month() === moment().month() && values.validFrom.date() >= beforeDayOfMonthValue.value) {
      dispatch(showModal(BEFORE_DAY_CURRENT_MONTH_MODAL))
    } else {
      const passengers = values.passengers.map((passenger, key) => ({
        ...passenger,
        id: `passenger_${key + 1}`,
        disabilityType: passenger.disabilityType || DISABILITY_TYPE_NONE
      }))

      const segments = [{
        origin: values.origin && values.origin.UICStationCode,
        destination: values.destination && values.destination.UICStationCode,
        validFrom: values.validFrom,
        direction: TRAVEL_DIRECTION_OUTWARD
      }]

      dispatch(getProducts({productFamily: values.productFamily.code, segments, passengers}))
    }
  } else {
    dispatch(clearState(['api.v2.search', CONTAINERS_TICKETS_PRODUCT_SEARCH_RESULT, CONTAINERS_TICKETS_SHORTCUT]))
    dispatch(validate(FORM_PRODUCT_SEARCH))
  }
}

export const resetTickets = () => (dispatch, getState) => {
  const state = getState()
  const station = machineStationSelector(state)

  dispatch([
    clearMachineDisplay(),
    hideReturnPaymentModal(),
    clearState([
      'api.booking.barcodeDocument',
      'api.booking.receiptDocument',
      'api.booking.additionalDetailsBooking',
      'api.booking.aftersalesOriginalBooking',
      'api.booking.completedBooking',
      'api.booking.provisionalBooking',
      'api.modules.magstripe',
      'api.v2.search',
      CONTAINERS_BASE_TILES_PAYMENT_METHODS,
      CONTAINERS_BASE_FINALIZE_BOOKING_PRINT_TICKETS_MODAL,
      CONTAINERS_BASE_PAYMENT_MODAL_CASH_FORM,
      CONTAINERS_BASE_PAYMENT_MODAL_CREDIT_DEBIT,
      CONTAINERS_TICKETS_SHORTCUT,
      USERINPUT_AFTERSALES_CANCELLATION
    ]),
    clearLastApiErrors(['addPayments', 'updatePayments']),
    {type: UI_RESET_TICKETS, station}
  ])
}
