import { routerActions } from 'react-router-redux'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import { journeySearchFromBookingSelector } from '../../../../../redux/selectors/containers/aftersales/booking/toolbar'
import { getPreviousNextPageForBookingFlow } from '../../../../../constants'
import { currentPathnameSelector } from '../../../../../redux/selectors/containers/base/routing'
import { bookingFlowSelector } from '../../../../../redux/selectors/containers/base/booking-flow'
import _t from 's3p-js-lib/src/translate'

export const startNewBooking = () => async (dispatch, getState) => {
  const queryParams = journeySearchFromBookingSelector(getState())
  const result = getPreviousNextPageForBookingFlow(currentPathnameSelector(getState()), bookingFlowSelector(getState()))
  dispatch([clearState('journeySearch.offer'), routerActions.push(`/${_t.getLocales()}${result.start(queryParams)}`)])
}
