import ensureToken from 's3p-js-lib/src/api/ensure-token'
import ResponseCodes from 's3p-js-lib/src/api/response-codes'
import Client from '../../../../api/client'
import {
  API_RESEND_TICKETS_FAILURE,
  API_RESEND_TICKETS_REQUEST,
  API_RESEND_TICKETS_SUCCESS
} from 's3p-js-lib/src/redux/actions/types'

const resendTicketsRequest = data => ({type: API_RESEND_TICKETS_REQUEST, ...data})
const resendTicketsSuccess = response => ({type: API_RESEND_TICKETS_SUCCESS, response})
const resendTicketsFailure = error => ({type: API_RESEND_TICKETS_FAILURE, error})

export const resendTicketsByBookingNumber = (bookingNumber, overrideValidationRules) => ensureToken(async (token, dispatch) => {
  try {
    dispatch(resendTicketsRequest({bookingNumber, overrideValidationRules}))
    const response = await Client.resendTicketsByBookingNumber(token, bookingNumber, overrideValidationRules)
    dispatch(resendTicketsSuccess(response))
  } catch (error) {
    dispatch(resendTicketsFailure(error))
    const handledErrors = [ResponseCodes.BOOKING_REACHED_RESEND_LIMIT]
    if (handledErrors.includes(error.errorCode)) {
      return false
    }
    throw error
  }
})
