import { connect } from 'react-redux'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import {
  bookingSelector,
  fulfillmentMethodSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { selectedItemIdsSelector } from '../../../../redux/selectors/user-input/aftersales/cancellation'
import {
  isCancellationVoidPageSelector,
  refundDetailsSelector
} from '../../../../redux/selectors/containers/aftersales/cancellation'
import {
  isLeapFulfillment,
  isLeapTopUpFulfillment,
  isMagstripeOrTVMFulfillment
} from '../../../../misc/utils'
import Navigation from '../../../../components/aftersales/cancellation/progress-navigation/navigation'
import { canOverrideValidationRulesFullOrIssueSelector } from '../../../../redux/selectors/api/auth/auth'
import { showModal } from '../../../../redux/actions/containers/base/visible-element'
import { CANCELLATION_VOID_MODAL } from '../../../../constants'
import { elementVisibleSelector } from '../../../../redux/selectors/containers/base/visible-element'
import {
  handleNavigationNext,
  handleNavigationPrevious
} from '../../../../redux/actions/containers/aftersales/cancellation/navigation'
import { additionalDetailValueSelector } from 's3p-js-lib/src/redux/selectors/api/booking/additional-details'
import {
  LEAP_STATUS_OK,
  LEAP_ADD_PRODUCT_KEY,
  LEAP_STATUS_KEY
} from '../../../../redux/actions/containers/leap/leap'

const _fulfillmentMethodSelector = fulfillmentMethodSelector(originalBookingSelector)
const _canOverrideValidationRulesFullOrIssueSelector = canOverrideValidationRulesFullOrIssueSelector(originalBookingSelector)

const isVoidModalVisibleSelector = elementVisibleSelector(CANCELLATION_VOID_MODAL)
const _isLeapAddProductSuccesSelector = additionalDetailValueSelector(originalBookingSelector, `${LEAP_ADD_PRODUCT_KEY}_${LEAP_STATUS_KEY}`)

const mapStateToProps = state => {
  const hasProvisionalBooking = Boolean(bookingSelector(state))
  const fulfillmentMethod = _fulfillmentMethodSelector(state)

  const isCancellationVoidPage = isCancellationVoidPageSelector(state)
  const isMagstripe = isMagstripeOrTVMFulfillment(fulfillmentMethod)
  const isLeapTopUp = isLeapTopUpFulfillment(fulfillmentMethod)
  const isleapAddProduct = isLeapFulfillment(fulfillmentMethod)

  let nextButtonDisabled = (
    !_canOverrideValidationRulesFullOrIssueSelector(state) &&
    isCancellationVoidPage
  )

  const aftersales = state.containers.aftersales
  if (isMagstripe) {
    nextButtonDisabled = (
      nextButtonDisabled &&
      !aftersales.voidTickets.success
    )
  } else if (isLeapTopUp) {
    nextButtonDisabled = (
      nextButtonDisabled &&
      !aftersales.cancellation.reverseLeap.success
    )
  } else if (isleapAddProduct) {
    nextButtonDisabled = (
      nextButtonDisabled &&
      _isLeapAddProductSuccesSelector(state) === LEAP_STATUS_OK
    )
  }

  return ({
    ...refundDetailsSelector(state),
    isLoadingConfirm: state.api.loading.aftersalesConfirm,
    isLoadingRevert: state.api.loading.aftersalesRevert,
    isLoadingCancel: state.api.loading.cancellationCancel,
    showPreviousButton: hasProvisionalBooking && !isMagstripe && !isLeapTopUp,
    showConfirmButton: hasProvisionalBooking && !isCancellationVoidPage,
    showNextSubtext: !isMagstripe || !isLeapTopUp || isCancellationVoidPage,
    nextButtonDisabled,
    hasSelectedItems: selectedItemIdsSelector(state).length > 0,
    showVoidModal: isVoidModalVisibleSelector(state),
    isMagstripe: isMagstripe,
    isLeap: isLeapTopUp || isleapAddProduct
  })
}

const mapDispatchToProps = {
  onClickNext: handleNavigationNext,
  onClickPrevious: handleNavigationPrevious,
  showModal
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
