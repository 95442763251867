import {
  API_GET_MAGSTRIPE_TICKETS_REQUEST,
  API_GET_MAGSTRIPE_TICKETS_SUCCESS,
  API_GET_MAGSTRIPE_TICKETS_FAILURE,
  API_CONFIRM_MAGSTRIPE_REQUEST,
  API_CONFIRM_MAGSTRIPE_SUCCESS,
  API_CONFIRM_MAGSTRIPE_FAILURE
} from '../../types'
import Client from '../../../../api/client'
import ensureToken from 's3p-js-lib/src/api/ensure-token'
import {handleTicketFulfillmentApiErrorIfPinPaymentSucceeded} from '../../containers/base/finalize-booking/error'

const getMagstripeTicketsRequest = (bookingNumber, fulfillmentMethodCode, pinCode) => ({
  type: API_GET_MAGSTRIPE_TICKETS_REQUEST,
  bookingNumber,
  fulfillmentMethodCode,
  pinCode
})
const getMagstripeTicketsSuccess = response => ({type: API_GET_MAGSTRIPE_TICKETS_SUCCESS, response})
const getMagstripeTicketsFailure = error => ({type: API_GET_MAGSTRIPE_TICKETS_FAILURE, error})

const confirmMagstripeRequest = (bookingNumber, fulfillmentDetail) => ({type: API_CONFIRM_MAGSTRIPE_REQUEST, bookingNumber, fulfillmentDetail})
const confirmMagstripeSuccess = response => ({type: API_CONFIRM_MAGSTRIPE_SUCCESS, response})
const confirmMagstripeFailure = error => ({type: API_CONFIRM_MAGSTRIPE_FAILURE, error})

export const getMagstripeTickets = (bookingNumber, fulfillmentMethodCode, pinCode) => ensureToken(
  async (token, dispatch) => {
    try {
      dispatch(getMagstripeTicketsRequest(bookingNumber, fulfillmentMethodCode, pinCode))
      const response = await Client.getMagstripeTickets(token, bookingNumber, fulfillmentMethodCode, pinCode)
      dispatch(getMagstripeTicketsSuccess(response))
    } catch (error) {
      dispatch(handleTicketFulfillmentApiErrorIfPinPaymentSucceeded(`TOD magstripe ticket API ${error.errorCode || error.statusCode}`))
      dispatch(getMagstripeTicketsFailure(error))
      throw error
    }
  }
)

export const confirmMagstripe = (bookingNumber, data) => ensureToken(
  async (token, dispatch) => {
    try {
      dispatch(confirmMagstripeRequest(bookingNumber, data))
      const response = await Client.confirmMagstripe(token, bookingNumber, data)
      dispatch(confirmMagstripeSuccess(response))
    } catch (error) {
      dispatch(confirmMagstripeFailure(error))
      throw error
    }
  }
)
