import { createSelector } from 'reselect'
import {withoutCancelledSelector, productsSelector, requiredProductsSelector} from './products'
import { voucherSelector } from './vouchers'
import { salesChannelPropertySelector } from '../user/sales-channel-properties'
import { camelCaseKeys } from '../../../../misc/utils'
import {
  BOOKING_CONFIRMED,
  BOOKING_PROVISIONAL,
  BOOKING_CANCELLED
} from '../../../../constants'
import {PRODUCT_TYPE_TICKET_ONLY} from '../../../../../../src/constants'

export const bookingSelector = state => state.api.booking.provisionalBooking || null

export const completedBookingSelector = state => state.api.booking.completedBooking || null

export const overviewBookingSelector = state => state.api.booking.overviewBooking || null

export const bookingTotalFeeSelector = bookingSelector => createSelector(
  [bookingFeesSelector(bookingSelector)],
  fees => fees.reduce((total, fee) => total + fee.price, 0)
)

export const bookingFeesSelector = bookingSelector => createSelector(
  [tariffSegmentsSelector(bookingSelector)],
  tariffSegments => tariffSegments.reduce((fees, tariffSegment) => fees.concat(tariffSegment.fees), [])
)

export const bookingStatusSelector = bookingSelector => createSelector(
  [bookingSelector, withoutCancelledSelector(productsSelector(bookingSelector))],
  (booking, products) => {
    if (booking) {
      if (products.length > 0) {
        return BOOKING_CONFIRMED
      } else if (booking.expiry_timestamp) {
        return BOOKING_PROVISIONAL
      } else if (booking.cancelled_timestamp) {
        return BOOKING_CANCELLED
      } else if (booking.confirmed_timestamp) {
        return BOOKING_CONFIRMED
      }
    }
    return null
  }
)

export const bookingTicketUrlSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking && booking.ticket_url
)

export const bookingNumberSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking ? booking.booking_number : null
)

export const inboundTariffSegmentsSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking
    ? booking.inbound_booking_tariff_segments.map(segment => ({
      ...segment,
      direction: 'inbound'
    }))
    : []
)

export const outboundTariffSegmentsSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking
    ? booking.outbound_booking_tariff_segments.map(segment => ({
      ...segment,
      direction: 'outbound'
    }))
    : []
)

export const tariffSegmentsSelector = bookingSelector => createSelector(
  [outboundTariffSegmentsSelector(bookingSelector), inboundTariffSegmentsSelector(bookingSelector)],
  (outboundTariffSegments, inboundTariffSegments) => outboundTariffSegments.concat(inboundTariffSegments)
)

export const withRequiredProductsSelector = _tariffSegmentsSelector => createSelector(
  [_tariffSegmentsSelector],
  tariffSegments => tariffSegments.filter(segment => segment.required_products.some(product => !product.cancelled))
)

export const paymentRequiredSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking ? booking.total_price_to_be_paid > 0 : false
)

export const paymentAmountSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking ? booking.total_price_to_be_paid : 0
)

export const paymentAmountWithoutVouchersSelector = bookingSelector => createSelector(
  [paymentAmountSelector(bookingSelector), voucherSelector(bookingSelector)],
  (priceToBePaid, vouchers) => (vouchers || []).reduce(
    (total, voucher) => voucher.isPending ? total + voucher.amount : total,
    priceToBePaid
  )
)

export const fulfillmentMethodSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking && booking.booking_fulfillment_method && camelCaseKeys(booking.booking_fulfillment_method)
)

export const isReturnJourneySelector = bookingSelector => createSelector(
  [
    withRequiredProductsSelector(inboundTariffSegmentsSelector(bookingSelector)),
    withRequiredProductsSelector(outboundTariffSegmentsSelector(bookingSelector))
  ],
  (inboundTariffSegments, outboundTariffSegments) =>
    inboundTariffSegments.length > 0 && outboundTariffSegments.length > 0
)

export const isGroupBookingSelector = bookingSelector => createSelector(
  [
    bookingSelector,
    salesChannelPropertySelector('booking_group_start')
  ],
  (booking, groupBookingThreshold) =>
    booking && Object.keys(booking.passengers || {}).length >= groupBookingThreshold
)

export const isTicketOnlyBookingSelector = bookingSelector => createSelector(
  requiredProductsSelector(bookingSelector),
  requiredProducts => Boolean(
    requiredProducts.length && requiredProducts.some(product => product.type === PRODUCT_TYPE_TICKET_ONLY)
  )
)

export const totalPriceBookingSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking ? booking.total_price : 0
)

export const totalPriceSelector = bookingSelector => createSelector(
  [
    totalPriceBookingSelector(bookingSelector),
    voucherSelector(bookingSelector)
  ],
  (totalPrice, vouchers) => (vouchers || []).reduce(
    (total, voucher) => voucher.payment.amount === 0 ? total + voucher.amount : total,
    totalPrice
  )
)

export const additionalDetailsBookingSelector = state => state.api.booking.additionalDetailsBooking || null

export const receiptDocumentSelector = state => state.api.booking.receiptDocument || null

export const inventoryClassBySegmentIdAndPassengerIdSelector = (bookingSelector, segmentId, passengerId) => createSelector(
  withRequiredProductsSelector(tariffSegmentsSelector(bookingSelector)),
  tariffSegments => tariffSegments
    .find(tariffSegment => tariffSegment.id === segmentId)
    ?.required_products
    .find(requiredProduct => requiredProduct.passenger_id === passengerId)
    ?.inventory_class
)
