import { API_GET_JOURNEY_DETAILS_SUCCESS } from 's3p-js-lib/src/redux/actions/types'
import baseJourneyDetails, { initialState } from 's3p-js-lib/src/redux/reducers/api/orientation/journey-details'

export default (state = initialState, action) => {
  switch (action.type) {
    case API_GET_JOURNEY_DETAILS_SUCCESS:
      return state.concat(action.legs.map(leg => ({
        ...action.response.data.services.find(service => service.name === leg.serviceName),
        service_identifier: leg.serviceIdentifier
      })))
    default:
      return baseJourneyDetails(state, action)
  }
}
