import { passengersSelector } from '../../booking/passengers'
import { toUtcDateMoment } from '../../../../../misc/date'
import {
  outboundTariffSegmentsSelector,
  inboundTariffSegmentsSelector,
  withoutCancelledSelector
} from '../../../api/booking/tariff-segments'

export const journeySearchSelector = booking => {
  const outboundSegments = withoutCancelledSelector(outboundTariffSegmentsSelector(booking => booking))(booking)
  const inboundSegments = withoutCancelledSelector(inboundTariffSegmentsSelector(booking => booking))(booking)
  const passengers = passengersSelector(booking => booking)(booking)

  const firstOutboundSegment = outboundSegments.length ? outboundSegments[0] : null
  const lastOutboundSegment = outboundSegments.length ? outboundSegments[outboundSegments.length - 1] : null
  const firstInboundSegment = inboundSegments.length ? inboundSegments[0] : null
  const lastInboundSegment = inboundSegments.length ? inboundSegments[inboundSegments.length - 1] : null

  let originStation, destinationStation
  if (firstOutboundSegment && firstOutboundSegment.departureStation) {
    originStation = firstOutboundSegment.departureStation.UICStationCode
  } else if (lastInboundSegment && lastInboundSegment.arrivalStation) {
    originStation = lastInboundSegment.arrivalStation.UICStationCode
  }
  if (lastOutboundSegment && lastOutboundSegment.arrivalStation) {
    destinationStation = lastOutboundSegment.arrivalStation.UICStationCode
  } else if (firstInboundSegment && firstInboundSegment.departureStation) {
    destinationStation = firstInboundSegment.departureStation.UICStationCode
  }

  return {
    origin_station: originStation,
    destination_station: destinationStation,
    currency: booking.currency,
    contract_code: booking.agent && booking.agent.contract_code,
    departure_date: firstOutboundSegment && firstOutboundSegment.departureDate && toUtcDateMoment(
      firstOutboundSegment.departureDate
    ),
    return_date: firstInboundSegment && firstInboundSegment.departureDate
      ? toUtcDateMoment(firstInboundSegment.departureDate) : null,
    passengers: passengers.reduce(
      (passengers, passenger) => {
        if (!passenger.cancelled) {
          passengers.push({
            id: passenger.id,
            type: passenger.type,
            disability_type: passenger.disabilityType,
            discount_cards: passenger.discountCards || []
          })
        }
        return passengers
      },
      []
    )
  }
}
