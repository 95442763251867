import { connect } from 'react-redux'
import { FEEDBACK_ALERT } from '../../../elements/feedback/feedback'
import { isOfflineSelectorCreator } from '../../../redux/selectors/machine/offline'
import FinalizeBookingModal from '../../../components/base/finalize-booking/finalize-booking-modal'
import {
  isChangeFulfillmentAvailableSelector,
  isOverrideValidationRulesSelector
} from '../../../redux/selectors/containers/base/aftersales'
import { completedBookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { DEVICE_ID_RECEIPT_PRINTER } from '../../../constants'

const receiptPrinterOfflineSelector = isOfflineSelectorCreator(DEVICE_ID_RECEIPT_PRINTER)

const _isAvailableSelector = isChangeFulfillmentAvailableSelector(completedBookingSelector)
const _isOverrideValidationRulesSelector = isOverrideValidationRulesSelector(completedBookingSelector)

const mapStateToProps = (state, ownProps) => {
  let messages = ownProps.messages || []
  const printTicketsModal = state.containers.base.finalizeBooking.printTicketsModal
  if (printTicketsModal.receiptErrorMessage) {
    messages = messages.concat({
      status: FEEDBACK_ALERT,
      text: [printTicketsModal.receiptErrorMessage]
    })
  }

  if (!printTicketsModal.totalTickets && !printTicketsModal.ticketErrorMessage && !ownProps.additionalFulfillmentMethod) {
    messages = []
  }

  return {
    available: _isAvailableSelector(state),
    overrideAftersalesRules: _isOverrideValidationRulesSelector(state),
    isOfflineReceiptPrinter: receiptPrinterOfflineSelector(state),
    totalTickets: printTicketsModal.totalTickets,
    ticketNumber: printTicketsModal.ticketNumber,
    messages
  }
}

export default connect(mapStateToProps)(FinalizeBookingModal)
