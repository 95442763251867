import {
  USER_INPUT_REPEAT_LAST_BOOKING_REQUEST,
  USER_INPUT_REPEAT_LAST_BOOKING_SUCCESS,
  USER_INPUT_REPEAT_LAST_BOOKING_FAILURE,
  UI_PAYOUT_VOUCHER_REQUEST,
  UI_PAYOUT_VOUCHER_SUCCESS
} from '../../../actions/types'
import {CLEAR_STATE} from 's3p-js-lib/src/redux/actions/types'
import { API_V2_CONFIRM_BOOKING_SUCCESS } from 's3p-js-lib/src/redux/actions/types-v2'

const initialState = {
  lastBooking: null,
  isLoading: false,
  loadingVoucherToCash: false
}
export default (state = initialState, action) => {
  switch (action.type) {
    case API_V2_CONFIRM_BOOKING_SUCCESS:
      return !state.loadingVoucherToCash
        ? {
          ...state,
          lastBooking: action.response.data.booking || null
        } : state
    case USER_INPUT_REPEAT_LAST_BOOKING_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case USER_INPUT_REPEAT_LAST_BOOKING_SUCCESS:
    case USER_INPUT_REPEAT_LAST_BOOKING_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case UI_PAYOUT_VOUCHER_REQUEST:
      return {
        ...state,
        loadingVoucherToCash: true
      }
    case UI_PAYOUT_VOUCHER_SUCCESS:
      return {
        ...state,
        loadingVoucherToCash: false
      }
    case CLEAR_STATE:
      return action.namespaces.includes('containers.tickets.repeatLastBooking') ? initialState : state
    default:
      return state
  }
}
