import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../text-label/text-label'
import Icon from '../icon/icon'
import Link from '../link/link'

export const STATUS_PASSED = 'passed'
export const STATUS_AVAILABLE = 'available'
export const STATUS_SELECTED = 'selected'
export const STATUS_DISABLED = 'disabled'

const statusClassNameMap = {
  [STATUS_PASSED]: 'state--is-available',
  [STATUS_AVAILABLE]: 'state--is-available',
  [STATUS_SELECTED]: 'state--is-selected',
  [STATUS_DISABLED]: 'state--is-disabled'
}

export default class ProgressBarItem extends Component {
  static propTypes = {
    status: PropTypes.oneOf([
      STATUS_AVAILABLE,
      STATUS_DISABLED,
      STATUS_PASSED,
      STATUS_SELECTED
    ]),
    text: PropTypes.string,
    onClick: PropTypes.func
  }

  render () {
    const {onClick, text, status} = this.props
    const label = (
      <TextLabel name='progress-bar'>
        {status === STATUS_PASSED ? <Icon name='tick' className='small align-left' /> : null}
        <span className='text'>{text}</span>
      </TextLabel>
    )
    return (
      <li className={statusClassNameMap[status]}>
        {status === STATUS_PASSED || status === STATUS_AVAILABLE
          ? <Link name='progress-bar' onClick={onClick}>{label}</Link>
          : label
        }
      </li>
    )
  }
}
