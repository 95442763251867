import formReducer from 's3p-js-lib/src/redux/lib/form-reducer'
import composeReducers from 's3p-js-lib/src/redux/lib/compose-reducers'
import passengerDetails from 's3p-js-lib/src/redux/reducers/user-input/forms/orientation/passenger-details'
import {
  FORM_AGENT_LOGIN,
  FORM_TYPE_REDEEM_VOUCHER,
  FORM_PRODUCT_SEARCH,
  FORM_JOURNEY_SEARCH,
  FORM_CUSTOMER_DETAILS,
  FORM_FIND_CUSTOMERS,
  FORM_BOOKINGS_FILTER,
  FORM_TYPE_AGENT_SHIFT_START,
  FORM_TYPE_AGENT_SHIFT_NOTE,
  FORM_PASSENGER_DETAILS_AFTERSALES,
  FORM_TYPE_REBOOK_JOURNEY_SEARCH,
  FORM_PASSENGER_DETAILS,
  FORM_TYPE_VOUCHER_SEARCH,
  FORM_BOOKING_NOTE,
  FORM_AGENT_CHANGE_PASSWORD
} from 's3p-js-lib/src/constants-form'
import {
  FORM_EDIT_SHORTCUT,
  FORM_CREDIT_DEBIT,
  FORM_PRODUCT_SEARCH_PASSENGERS_DETAILS,
  FORM_JOURNEY_SEARCH_PASSENGERS_DETAILS,
  FORM_REBOOK_SEARCH_PASSENGERS_DETAILS
} from '../../../../constants'
import productSearch from './product-search'
import editShortcut from './edit-shortcut'

export default {
  [FORM_AGENT_LOGIN]: formReducer(FORM_AGENT_LOGIN),
  [FORM_TYPE_REDEEM_VOUCHER]: formReducer(FORM_TYPE_REDEEM_VOUCHER),
  [FORM_PRODUCT_SEARCH]: composeReducers(productSearch, formReducer(FORM_PRODUCT_SEARCH)),
  [FORM_PRODUCT_SEARCH_PASSENGERS_DETAILS]: formReducer(FORM_PRODUCT_SEARCH_PASSENGERS_DETAILS),
  [FORM_EDIT_SHORTCUT]: composeReducers(editShortcut, formReducer(FORM_EDIT_SHORTCUT)),
  [FORM_TYPE_AGENT_SHIFT_START]: formReducer(FORM_TYPE_AGENT_SHIFT_START),
  [FORM_TYPE_AGENT_SHIFT_NOTE]: formReducer(FORM_TYPE_AGENT_SHIFT_NOTE),
  [FORM_JOURNEY_SEARCH]: formReducer(FORM_JOURNEY_SEARCH),
  [FORM_JOURNEY_SEARCH_PASSENGERS_DETAILS]: formReducer(FORM_JOURNEY_SEARCH_PASSENGERS_DETAILS),
  [FORM_REBOOK_SEARCH_PASSENGERS_DETAILS]: formReducer(FORM_REBOOK_SEARCH_PASSENGERS_DETAILS),
  [FORM_CUSTOMER_DETAILS]: formReducer(FORM_CUSTOMER_DETAILS),
  [FORM_PASSENGER_DETAILS]: composeReducers(passengerDetails, formReducer(FORM_PASSENGER_DETAILS)),
  [FORM_PASSENGER_DETAILS_AFTERSALES]: composeReducers(passengerDetails, formReducer(FORM_PASSENGER_DETAILS_AFTERSALES)),
  [FORM_TYPE_REBOOK_JOURNEY_SEARCH]: formReducer(FORM_TYPE_REBOOK_JOURNEY_SEARCH),
  [FORM_BOOKINGS_FILTER]: formReducer(FORM_BOOKINGS_FILTER),
  [FORM_FIND_CUSTOMERS]: formReducer(FORM_FIND_CUSTOMERS),
  [FORM_BOOKING_NOTE]: formReducer(FORM_BOOKING_NOTE),
  [FORM_TYPE_VOUCHER_SEARCH]: formReducer(FORM_TYPE_VOUCHER_SEARCH),
  [FORM_CREDIT_DEBIT]: formReducer(FORM_CREDIT_DEBIT),
  [FORM_AGENT_CHANGE_PASSWORD]: formReducer(FORM_AGENT_CHANGE_PASSWORD)
}
