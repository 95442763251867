import React, { Component } from 'react'
import PropTypes from '../../../misc/prop-types'
import scroller from 's3p-js-lib/src/misc/scroller'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../elements/text-label/text-label'
import Icon from '../../../elements/icon/icon'
import Link from '../../../elements/link/link'

export default class TravelCompact extends Component {
  static propTypes = {
    direction: PropTypes.string.isRequired,
    departureStation: PropTypes.shape({
      name: PropTypes.string.isRequired
    }),
    arrivalStation: PropTypes.shape({
      name: PropTypes.string.isRequired
    }),
    departureDate: PropTypes.moment,
    arrivalDate: PropTypes.moment
  }

  constructor (...args) {
    super(...args)
    this.onGoToJourneyResultSet = this.onGoToJourneyResultSet.bind(this)
  }

  onGoToJourneyResultSet () {
    scroller(`journey-result-set-${this.props.direction}`)
  }

  _hasTravelDetails () {
    return Boolean(this.props.departureDate && this.props.arrivalDate)
  }

  render () {
    return (
      <div className={`journey-summary__${this.props.direction}`}>
        <div className='travel'>
          <div className='travel__origin'>
            {this.props.departureStation
              ? <TextLabel name='origin' text={this.props.departureStation.name} />
              : null
            }
            {this.props.departureDate
              ? [
                <TextLabel key='date' name='departure-date' text={this.props.departureDate.format('ddd, D MMM')} />,
                <TextLabel key='time' name='departure-time' text={this.props.departureDate.format('LT')} />
              ] : null }
          </div>
          <div className='travel__destination'>
            {this.props.arrivalStation
              ? (
                <TextLabel name='destination'>
                  <Icon name='long-arrow-right' className='medium align-left' />
                  <TextLabel.Text text={this.props.arrivalStation.name} />
                </TextLabel>
              ) : null
            }
            {this.props.arrivalDate
              ? (
                <TextLabel name='arrival-time' text={this.props.arrivalDate.format('LT')} />
              ) : null }
          </div>
        </div>
        {!this._hasTravelDetails()
          ? (
            <div className='select-journey'>
              <Link name='select-journey' onClick={this.onGoToJourneyResultSet}>
                <TextLabel name='select-journey'>
                  <Icon name={this.props.direction} className='medium align-left' />
                  <TextLabel.Text text={_t.message('booking-summary.travel-compact.select-journey',
                    {direction: this.props.direction})}
                  />
                </TextLabel>
              </Link>
            </div>
          ) : null }
      </div>
    )
  }
}
