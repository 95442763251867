import React from 'react'
import { Route } from 'react-router'
import containAuth from '../../containers/base/auth'
import SeatSelectionLayout from '../../layouts/reservations/seat-selection'
import { AGENT_ROLE_OPERATOR } from '../../constants'

export default (
  <Route
    key='reservations-seat-selection'
    path='/:lang/reservations/seat-selection'
    component={containAuth(SeatSelectionLayout, {roles: [AGENT_ROLE_OPERATOR]})}
  />
)
