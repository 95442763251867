import {
  UI_SHIFT_ENDED_REMOTELY,
  UI_MANUALLY_SAVE_NOTE_SHIFT
} from '../../../actions/types'
import { CLEAR_STATE, API_ADD_NOTES_AGENT_SHIFT_SUCCESS } from 's3p-js-lib/src/redux/actions/types'
import { CONTAINERS_SHIFT_MODAL } from '../../../../constants'

const initialState = {
  noteSaved: false,
  shiftEndedRemotely: false,
  manuallySavedShiftNote: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case API_ADD_NOTES_AGENT_SHIFT_SUCCESS:
      return {...state, noteSaved: true}
    case UI_SHIFT_ENDED_REMOTELY:
      return {...state, shiftEndedRemotely: true}
    case UI_MANUALLY_SAVE_NOTE_SHIFT:
      return {...state, manuallySavedShiftNote: true}
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_SHIFT_MODAL) ? initialState : state
    default:
      return state
  }
}
