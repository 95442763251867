import { connect } from 'react-redux'
import {
  outboundTariffSegmentsSelector,
  inboundTariffSegmentsSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/tariff-segments'
import {
  overviewBookingSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { travelSelector } from 's3p-js-lib/src/redux/selectors/api/booking/travel'
import JourneyOverview from '../../../components/aftersales/booking/journey-overview'

const outboundTravelSelector = travelSelector(outboundTariffSegmentsSelector(overviewBookingSelector))
const inboundTravelSelector = travelSelector(inboundTariffSegmentsSelector(overviewBookingSelector))
const _outboundTariffSegmentsSelector = outboundTariffSegmentsSelector(overviewBookingSelector)

const mapStateToProps = state => ({
  outboundTravel: outboundTravelSelector(state),
  inboundTravel: inboundTravelSelector(state),
  outboundSegments: _outboundTariffSegmentsSelector(state)
})

export default connect(mapStateToProps)(JourneyOverview)
