import React, { Component } from 'react'
import TableHeader from '../../../../elements/table/table-header'
import _t from 's3p-js-lib/src/translate'

export default class RelatedVouchersHeader extends Component {
  render () {
    return (
      <thead>
        <tr>
          <TableHeader text={_t.message('aftersales.booking.related-vouchers.header.type')} />
          <TableHeader text={_t.message('aftersales.booking.related-vouchers.header.code')} />
          <TableHeader text={_t.message('aftersales.booking.related-vouchers.header.amount')} />
        </tr>
      </thead>
    )
  }
}
