import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../../../elements/text-label/text-label'
import Modal from '../../../../../elements/modal/modal'
import Table from '../../../../../elements/table/table'
import Button from '../../../../../elements/button/button'
import Feedback, { FEEDBACK_WARNING } from '../../../../../elements/feedback/feedback'
import RefundCancel from '../../../../../containers/aftersales/booking/payment-details/refund/refund-cancel'
import { isPedPaymentMethod } from '../../../../../misc/utils'
import { PAYMENT_METHOD_CASH } from '../../../../../constants'

export default class RefundRecords extends Component {
  static propTypes = {
    initiateRefund: PropTypes.func.isRequired,
    refundRecords: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number,
      method: PropTypes.string
    })),
    isLoading: PropTypes.bool
  }

  render () {
    const cash = this.props.refundRecords.find(record => record.method === PAYMENT_METHOD_CASH)
    const ped = this.props.refundRecords.find(record => isPedPaymentMethod(record.method))

    return (
      <div>
        {cash && ped && ped.amount < cash.amount ? (
          <Feedback
            status={FEEDBACK_WARNING}
            text={_t.message('aftersales.refund-modal.overview.ped-before-cash')}
          />
        ) : null}
        <Table name='refund-records' numColumns={2} zebraRows>
          <thead>
            <tr>
              <th><TextLabel name='thead' text={_t.message('aftersales.refund-modal.table.method')} /></th>
              <th><TextLabel name='thead' text={_t.message('aftersales.refund-modal.table.amount')} /></th>
            </tr>
          </thead>
          <tbody>
            {this.props.refundRecords.map(record => (
              <tr key={record.method}>
                <td>{record.method}</td>
                <td>{_t.formatCurrency(record.amount)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Modal.CustomFooter>
          <RefundCancel />
          <Button
            hotkey='proceed'
            name='confirm'
            className='primary'
            loading={this.props.isLoading}
            onClick={this.props.initiateRefund}
          >
            <TextLabel name='next-step' text={_t.message('aftersales.refund-modal.overview.start')} />
          </Button>
        </Modal.CustomFooter>
      </div>
    )
  }
}
