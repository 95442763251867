import { connect } from 'react-redux'
import FulfillmentMethods from '../../containers/base/tiles/fulfillment-methods'
import { selectFulfillmentMethod } from '../../redux/actions/containers/tickets/fulfillment-methods'
import { provisionalOrCompletedBookingSelector } from '../../redux/selectors/api/booking/booking'
import { fulfillmentMethodsSelector } from '../../redux/selectors/containers/base/tiles/fulfillment-methods'
import { modalIsActiveSelector } from '../../redux/selectors/containers/base/visible-element'
import { ticketsApiLoadingSelector } from '../../redux/selectors/containers/tickets/loading'

const _fulfillmentMethodsSelector = fulfillmentMethodsSelector(provisionalOrCompletedBookingSelector)

const mapStateToProps = state => ({
  fulfillmentMethods: _fulfillmentMethodsSelector(state),
  loading:
    state.api.v2.loading.createBooking ||
    state.api.v2.loading.updateSegments ||
    state.machine.loading.statusLeap,
  loadingFulfillmentMethod: state.api.v2.loading.updateFulfillmentMethod,
  disabled: (
    modalIsActiveSelector(state) ||
    state.machine.loading.getMachineStatusDevices ||
    ticketsApiLoadingSelector(state)
  )
})

const mapDispatchToProps = {
  handleSelect: selectFulfillmentMethod
}

export default connect(mapStateToProps, mapDispatchToProps)(FulfillmentMethods)
