import {
  API_GET_GIFT_VOUCHER_DOCUMENT_REQUEST,
  API_GET_GIFT_VOUCHER_DOCUMENT_SUCCESS,
  API_GET_GIFT_VOUCHER_DOCUMENT_FAILURE
} from '../../../actions/types'
import { CLEAR_STATE } from 's3p-js-lib/src/redux/actions/types'

export default (state = null, action) => {
  switch (action.type) {
    case API_GET_GIFT_VOUCHER_DOCUMENT_REQUEST:
    case API_GET_GIFT_VOUCHER_DOCUMENT_FAILURE:
      return null
    case API_GET_GIFT_VOUCHER_DOCUMENT_SUCCESS:
      return action.response
    case CLEAR_STATE:
      return action.namespaces.includes('api.booking.giftVoucherDocument') ? null : state
    default:
      return state
  }
}
