import uniqueId from 'lodash/uniqueId'
import {
  MACHINE_DISPLAY_LINES_REQUEST,
  MACHINE_DISPLAY_LINES_SUCCESS,
  MACHINE_DISPLAY_LINES_FAILURE,
  MACHINE_CLEAR_DISPLAY_REQUEST,
  MACHINE_CLEAR_DISPLAY_SUCCESS,
  MACHINE_CLEAR_DISPLAY_FAILURE,
  MACHINE_TEST_DISPLAY_REQUEST,
  MACHINE_TEST_DISPLAY_SUCCESS,
  MACHINE_TEST_DISPLAY_FAILURE
} from '../types'
import DeviceManager from '../../../machine/device-manager'

const displayLinesRequest = (lines, requestId) => ({type: MACHINE_DISPLAY_LINES_REQUEST, lines, requestId})
const displayLinesSuccess = response => ({type: MACHINE_DISPLAY_LINES_SUCCESS, response})
const displayLinesFailure = machineError => ({type: MACHINE_DISPLAY_LINES_FAILURE, machineError})

const clearDisplayRequest = () => ({type: MACHINE_CLEAR_DISPLAY_REQUEST})
const clearDisplaySuccess = response => ({type: MACHINE_CLEAR_DISPLAY_SUCCESS, response})
const clearDisplayFailure = machineError => ({type: MACHINE_CLEAR_DISPLAY_FAILURE, machineError})

const testDisplayRequest = () => ({type: MACHINE_TEST_DISPLAY_REQUEST})
const testDisplaySuccess = response => ({type: MACHINE_TEST_DISPLAY_SUCCESS, response})
const testDisplayFailure = machineError => ({type: MACHINE_TEST_DISPLAY_FAILURE, machineError})

export const sendMachineDisplayLines = (lines, requestId = null) => async dispatch => {
  try {
    dispatch(displayLinesRequest(lines, requestId || uniqueId('id_')))
    const response = await DeviceManager.displayLines(lines)
    dispatch(displayLinesSuccess(response))
  } catch (error) {
    dispatch(displayLinesFailure(error))
  }
}

export const clearMachineDisplay = () => async dispatch => {
  try {
    dispatch(clearDisplayRequest())
    const response = await DeviceManager.clearDisplay()
    dispatch(clearDisplaySuccess(response))
  } catch (error) {
    dispatch(clearDisplayFailure(error))
  }
}

export const testMachineDisplay = () => async dispatch => {
  try {
    dispatch(testDisplayRequest())
    const response = await DeviceManager.testDisplay()
    dispatch(testDisplaySuccess(response))
  } catch (error) {
    dispatch(testDisplayFailure(error))
  }
}

export const timeoutClearDisplay = (milliseconds, requestId) => (dispatch, getState) => {
  setTimeout(() => {
    const currentId = getState().machine.display.requestId
    if (currentId === requestId) {
      dispatch(clearMachineDisplay())
    }
  }, milliseconds)
}
