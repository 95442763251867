import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FORM_AGENT_LOGIN } from 's3p-js-lib/src/constants-form'
import { connect } from 'react-redux'
import connectFormContainer from '../../base/form'
import AgentLogin from '../../../components/agent/login/login'
import { agentLogin, agentLogout } from '../../../redux/actions/containers/agent/login'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import { CONTAINERS_AGENT_LOGIN } from '../../../constants'

class AgentLoginContainer extends Component {
  static propTypes = {
    form: PropTypes.shape({
      fields: PropTypes.object.isRequired,
      valid: PropTypes.bool.isRequired,
      validateForm: PropTypes.func.isRequired
    }).isRequired,
    agentLogin: PropTypes.func.isRequired,
    agentLogout: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    invalidAgentRoles: PropTypes.bool,
    incorrectAgentConfiguration: PropTypes.bool,
    clearState: PropTypes.func.isRequired
  }

  constructor (...args) {
    super(...args)

    this.handleLogin = this.handleLogin.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.props.agentLogout()
  }

  componentWillUnmount () {
    this.props.clearState(CONTAINERS_AGENT_LOGIN)
  }

  handleLogin () {
    if (this.props.form.valid) {
      this.props.agentLogin(this.props.form.fields.username.value, this.props.form.fields.password.value)
    } else {
      this.props.form.validateForm()
    }
  }

  render () {
    return (
      <AgentLogin
        form={this.props.form}
        handleLogin={this.handleLogin}
        isLoading={this.props.isLoading}
        invalidAgentRoles={this.props.invalidAgentRoles}
        incorrectAgentConfiguration={this.props.incorrectAgentConfiguration}
      />
    )
  }
}

const mapStateToProps = state => ({
  isLoading: (
    state.api.loading.agentLogin ||
    state.api.loading.getAgentShift ||
    state.api.v2.loading.currencyRates
  ),
  invalidAgentRoles: state.containers.agent.login.invalidRoles,
  incorrectAgentConfiguration: state.containers.agent.login.incorrectConfiguration
})

const mapDispatchToProps = {
  agentLogin,
  agentLogout,
  clearState
}

export default connect(mapStateToProps, mapDispatchToProps)(
  connectFormContainer({formName: FORM_AGENT_LOGIN})(
    AgentLoginContainer
  )
)
