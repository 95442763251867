import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ComponentContainer from '../component-container/component-container'
import Panel from '../panel/panel'
import './sidebar.scss'

export class Sidebar extends Component {
  static propTypes = {
    className: PropTypes.any,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    header: PropTypes.element
  }

  render () {
    const {children, className, header, ...props} = this.props
    return (
      <ComponentContainer name='sidebar' className={className}>
        <Panel header={header ? React.cloneElement(header, {...props}) : <Panel.Header {...props} />}>
          {children}
        </Panel>
      </ComponentContainer>
    )
  }
}

class Header extends Component {
  static propTypes = {
    className: PropTypes.any,
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string
  }

  render () {
    return (
      <Panel.Header {...this.props} />
    )
  }
}

Sidebar.Header = Header

export default Sidebar
