import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import range from 'lodash/range'
import './table.scss'

export default class Table extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    numColumns: PropTypes.number.isRequired,
    compact: PropTypes.bool,
    hoverRows: PropTypes.bool,
    zebraRows: PropTypes.bool,
    className: PropTypes.string
  }

  static defaultProps = {
    compact: false,
    hoverRows: false,
    zebraRows: false
  }

  render () {
    const className = classNames(`table-${this.props.name}`, this.props.className, {
      'compact-table': this.props.compact,
      'hover-rows': this.props.hoverRows,
      'zebra-rows': this.props.zebraRows
    })

    return (
      <table className={className}>
        <colgroup>
          {range(1, this.props.numColumns + 1).map(number => <col key={`col${number}`} className={`col-${number}`} />)}
        </colgroup>
        {this.props.children}
      </table>
    )
  }
}
