import { createSelector } from 'reselect'
import { TRAVEL_DIRECTION_OUTBOUND } from 's3p-js-lib/src/constants'
import { journeySearchSelector } from 's3p-js-lib/src/redux/selectors/api/orientation/journey-search'
import {
  inboundTravelsSelector,
  outboundTravelsSelector
} from 's3p-js-lib/src/redux/selectors/api/orientation/cheapest-price'

const compareJourneySearchDateWithCheapestPriceTravels = compareCallback => createSelector(
  [
    journeySearchSelector,
    (state, ownProps) => ownProps.direction === TRAVEL_DIRECTION_OUTBOUND
      ? 'departure_date'
      : 'return_date',
    (state, ownProps) => ownProps.direction === TRAVEL_DIRECTION_OUTBOUND
      ? outboundTravelsSelector(state)
      : inboundTravelsSelector(state)
  ],
  (journeySearch, journeySearchParameter, travels) => {
    const date = journeySearch && journeySearch[journeySearchParameter]
    return Boolean(date && travels.length > 0 && compareCallback(date, travels))
  }
)

export const journeySearchDateBeforeCheapestPrices = () => compareJourneySearchDateWithCheapestPriceTravels(
  (date, travels) => date.isBefore(travels[0].date)
)

export const journeySearchDateAftersCheapestPrices = () => compareJourneySearchDateWithCheapestPriceTravels(
  (date, travels) => date.isAfter(travels[travels.length - 1].date)
)
