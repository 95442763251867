import React, {Component} from 'react'
import Error from '../../containers/error/error'
import BasicLayout from '../../components/base/basic-layout'

export default class ErrorLayout extends Component {
  render () {
    return (
      <BasicLayout>
        <Error />
      </BasicLayout>
    )
  }
}
