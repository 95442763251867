import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TabGroup from '../../../elements/tab/tab'
import TextLabel from '../../../elements/text-label/text-label'
import _t from 's3p-js-lib/src/translate'

export default class ProductTab extends Component {
  static propTypes = {
    product: PropTypes.object.isRequired,
    selectLeapProduct: PropTypes.func.isRequired,
    isSelected: PropTypes.bool,
    number: PropTypes.number.isRequired
  }

  constructor (...args) {
    super(...args)
    this.handleSelectLeapProduct = this.handleSelectLeapProduct.bind(this)
  }

  handleSelectLeapProduct () {
    this.props.selectLeapProduct(this.props.product)
  }

  render () {
    const {product} = this.props
    return (
      <TabGroup.Tab
        key={product.key}
        id={product.key}
        selected={this.props.isSelected}
        text={product.key}
        onClick={this.handleSelectLeapProduct}
      >
        <TextLabel name='product-name' text={_t.message('leap.products.product-name', {number: this.props.number})} />
        <TextLabel name='product-price' text={_t.formatCurrency(product.price)} />
      </TabGroup.Tab>
    )
  }
}
