import React, { Component } from 'react'
import AgentDetails from '../../../containers/aftersales/booking/agent-details'
import CustomerDetails from '../../../containers/aftersales/booking/customer-details'
import ComponentContainer from '../../../elements/component-container/component-container'
import './customer-agent-details.scss'

export default class CustomerAgentDetails extends Component {
  render () {
    return (
      <ComponentContainer name='booking-customer-agent-details'>
        <AgentDetails />
        <CustomerDetails />
      </ComponentContainer>
    )
  }
}
