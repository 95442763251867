import { connect } from 'react-redux'
import countBy from 'lodash/countBy'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import { passengersSelector } from 's3p-js-lib/src/redux/selectors/api/booking/passengers'
import {
  EMPTY_OBJECT,
  PASSENGER_TYPE_ADULT,
  PASSENGER_TYPE_CHILD,
  PASSENGER_TYPE_STUDENT
} from 's3p-js-lib/src/constants'
import PassengersSummary from '../../../components/aftersales/operation/passengers-summary'

const _passengersSelector = passengersSelector(originalBookingSelector)

const mapStateToProps = state => {
  const passengers = _passengersSelector(state)
  const passengersCount = (passengers && countBy(passengers, 'type')) || EMPTY_OBJECT

  return {
    valueAdult: passengersCount[PASSENGER_TYPE_ADULT] || 0,
    valueChild: passengersCount[PASSENGER_TYPE_CHILD] || 0,
    valueStudent: passengersCount[PASSENGER_TYPE_STUDENT] || 0
  }
}

export default connect(mapStateToProps)(PassengersSummary)
