import moment from 'moment'
import sortBy from 'lodash/sortBy'
import { createSelector } from 'reselect'
import { camelCaseKeys } from 's3p-js-lib/src/misc/utils'
import {
  PAYMENT_STATUS_F,
  PAYMENT_STATUS_P
} from 's3p-js-lib/src/constants'
import {
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_CODE_VOUCHER
} from '../../../../constants'

export const paymentsSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking && booking.payments
    ? sortBy(
      booking.payments.map(payment => {
        const {voucherId, refundProcessTimestamp, transactionTimestamp, ...result} = camelCaseKeys(payment)
        if (voucherId) {
          const voucher = (booking.vouchers || []).find(voucher => voucher.id === voucherId)
          result.voucher = voucher ? camelCaseKeys(voucher) : null
        }
        if (refundProcessTimestamp) {
          result.refundProcessTimestamp = moment(refundProcessTimestamp)
        }
        if (transactionTimestamp) {
          result.transactionTimestamp = moment(transactionTimestamp)
        }

        return result
      }),
      'refId'
    ) : []
)

export const paymentsWithoutFailedSelector = bookingSelector => createSelector(
  [paymentsSelector(bookingSelector)],
  payments => payments.filter(payment => payment.paymentStatus !== PAYMENT_STATUS_F)
)

export const pendingPaymentsSelector = bookingSelector => createSelector(
  [paymentsSelector(bookingSelector)],
  payments => payments.filter(payment => payment.paymentStatus === PAYMENT_STATUS_P)
)

export const hasPendingCashPaymentsSelector = bookingSelector => createSelector(
  [pendingPaymentsSelector(bookingSelector)],
  payments => payments.some(payment => payment.method === PAYMENT_METHOD_CASH)
)

export const hasPendingCashOrVoucherPaymentsSelector = bookingSelector => createSelector(
  [pendingPaymentsSelector(bookingSelector)],
  payments => payments.some(payment => payment.method === PAYMENT_METHOD_CASH || payment.method === PAYMENT_METHOD_CODE_VOUCHER)
)
