import _t from 's3p-js-lib/src/translate'
import { bookingNumberSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import {
  readMachineMagneticTicket,
  voidMachineMagneticTicket,
  ejectMachineMagneticTicket
} from '../../machine/magnetic-ticket-printer'
import { ticketVoidValidation } from 's3p-js-lib/src/redux/actions/api/operations/ticket'
import { magneticDataSelector } from '../../../selectors/machine/magnetic-ticket-printer'
import {
  UI_VOID_TICKET_START,
  UI_VOID_TICKET_NUMBER,
  UI_VOID_TICKET_SUCCESS,
  UI_VOID_TICKET_STOP,
  UI_VOID_TICKET_ERROR
} from '../../types'
import { PASSENGER_TYPE_ADULT, PASSENGER_TYPE_STUDENT, PASSENGER_TYPE_CHILD } from 's3p-js-lib/src/constants'
import { voidedTicketCountersSelector } from '../../../selectors/containers/aftersales/void-tickets'
import { originalOrOverviewBookingSelector } from '../../../selectors/api/booking/booking'
import { ticketsSelector } from '../../../selectors/containers/aftersales/cancellation'

const startVoidTickets = (total, voidedTicketCounters) => ({type: UI_VOID_TICKET_START, total, voidedTicketCounters})
const startVoidTicket = number => ({type: UI_VOID_TICKET_NUMBER, number})
const voidedTicket = counter => ({type: UI_VOID_TICKET_SUCCESS, counter})
const stopVoidTickets = () => ({type: UI_VOID_TICKET_STOP})
const errorVoidTickets = errorMessage => ({type: UI_VOID_TICKET_ERROR, errorMessage})

const isValidPassengerType = (passengerCode, passengerType) => {
  return passengerCode === 1
    ? passengerType === PASSENGER_TYPE_ADULT || passengerType === PASSENGER_TYPE_STUDENT
    : passengerType === PASSENGER_TYPE_CHILD
}

const ejectAndErrorVoidTickets = message => async dispatch => {
  await dispatch(ejectMachineMagneticTicket())
  dispatch(errorVoidTickets(message))
}

export const processVoidTickets = () => async (dispatch, getState) => {
  let state = getState()
  let voidedTicketCounters = voidedTicketCountersSelector(state)
  const tickets = ticketsSelector(state)
  const ticketsLength = Object.keys(tickets).length
  const bookingNumber = bookingNumberSelector(originalOrOverviewBookingSelector)(state)

  dispatch(startVoidTickets(ticketsLength, voidedTicketCounters))

  while (voidedTicketCounters.length < ticketsLength) {
    dispatch(startVoidTicket(voidedTicketCounters.length + 1))
    const result = await dispatch(readMachineMagneticTicket())

    if (result) {
      const bookingSerial = (parseInt(bookingNumber) % 262124) + 20
      const magneticData = magneticDataSelector(getState())
      const ticketCounter = magneticData.ticketSerial === bookingSerial
        ? Object.keys(tickets).find(counter =>
          isValidPassengerType(magneticData.passengerCode, tickets[counter][0].passenger.type) && !voidedTicketCounters.includes(counter)
        )
        : null

      if (ticketCounter) {
        const voidResult = await dispatch(voidMachineMagneticTicket())
        if (voidResult) {
          for (const ticket of tickets[ticketCounter]) {
            await dispatch(ticketVoidValidation(ticket.ticketNumber))
          }

          await dispatch(voidedTicket(ticketCounter))
          voidedTicketCounters = getState().containers.aftersales.voidTickets.voidedTicketCounters
        } else {
          await dispatch(ejectAndErrorVoidTickets(_t.message('aftersales.void-tickets.error.could-not-void')))
          break
        }
      } else {
        await dispatch(ejectAndErrorVoidTickets(_t.message('aftersales.void-tickets.error.wrong-ticket')))
        break
      }
    } else {
      await dispatch(ejectAndErrorVoidTickets(_t.message('aftersales.void-tickets.error.could-not-read')))
      break
    }
  }
  dispatch(stopVoidTickets())
}
