import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FORM_TYPE_REDEEM_VOUCHER } from 's3p-js-lib/src/constants-form'
import { connect } from 'react-redux'
import { addVoucher } from '../../../redux/actions/containers/base/payment-modal/voucher-form'
import { loadingSelector } from '../../../redux/selectors/containers/base/payment-modal/payment-modal'
import connectFormContainer from '../form'
import VoucherFooter from '../../../components/base/payment-modal/voucher-footer'

class VoucherFooterContainer extends Component {
  static propTypes = {
    addVoucher: PropTypes.func.isRequired,
    form: PropTypes.shape({
      fields: PropTypes.shape({
        voucherCode: PropTypes.shape({
          value: PropTypes.string
        }).isRequired
      }).isRequired,
      valid: PropTypes.bool.isRequired
    }).isRequired
  }

  constructor (...args) {
    super(...args)

    this.handleAddVoucher = this.handleAddVoucher.bind(this)
  }

  handleAddVoucher () {
    if (this.props.form.valid) {
      this.props.addVoucher(this.props.form.fields.voucherCode.value.replace(/\s/g, '').toUpperCase())
    }
  }

  render () {
    const props = {...this.props}
    delete props.addVoucher

    return (
      <VoucherFooter
        {...props}
        handleAddVoucher={this.handleAddVoucher}
      />
    )
  }
}

const mapStateToProps = state => ({
  amountToBePaid: state.containers.base.tiles.paymentMethods.amountToBePaid,
  isLoading: loadingSelector(state)
})

const mapDispatchToProps = {
  addVoucher
}

export default connect(mapStateToProps, mapDispatchToProps)(
  connectFormContainer({formName: FORM_TYPE_REDEEM_VOUCHER})(VoucherFooterContainer)
)
