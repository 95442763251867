import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'
import Fulfillment from '../../../components/aftersales/booking/fulfillment'
import {reprintResendBooking} from '../../../redux/actions/containers/aftersales/booking/reprint-resend'
import {
  overviewBookingSelector,
  bookingNumberSelector,
  fulfillmentMethodSelector,
  tariffSegmentsSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import {availableFulfillmentMethodsSelector} from 's3p-js-lib/src/redux/selectors/api/booking/fulfillment-methods'
import {hideElement, showModal} from '../../../redux/actions/containers/base/visible-element'
import {clearState} from 's3p-js-lib/src/redux/actions/clear-state'
import {isAnyLeapFulfillment} from '../../../misc/utils'
import {
  FINALIZE_BOOKING_MODAL,
  CONTAINERS_BOOKING_REPRINT_RESEND,
  VOID_TICKETS_MODAL
} from '../../../constants'
import moment from 'moment'
import {isConfirmedSelector} from '../../../redux/selectors/api/booking/booking'
import {getBooking} from 's3p-js-lib/src/redux/actions/api/booking/booking'
import {goToChangeFulfillment} from '../../../redux/actions/containers/base/void-tickets-routing'
import {elementVisibleSelector} from '../../../redux/selectors/containers/base/visible-element'
import {shouldVoidMagstripeTicketsSelector} from '../../../redux/selectors/containers/aftersales/void-tickets'
import {displayNextCustomer} from '../../../redux/actions/containers/base/finalize-booking/next-customer'

const _fulfillmentMethodSelector = fulfillmentMethodSelector(overviewBookingSelector)
const _availableFulfillmentMethodsSelector = availableFulfillmentMethodsSelector(overviewBookingSelector)
const isBookingConfirmedSelector = isConfirmedSelector(overviewBookingSelector)
const _tariffSegmentsSelector = tariffSegmentsSelector(overviewBookingSelector)

class FulfillmentContainer extends Component {
  static propTypes = {
    clearState: PropTypes.func.isRequired
  }

  componentWillUnmount () {
    this.props.clearState()
  }

  render () {
    return <Fulfillment {...this.props} />
  }
}

const showVoidTicketsSelector = elementVisibleSelector(VOID_TICKETS_MODAL)

const mapStateToProps = state => {
  const tarrifSegments = _tariffSegmentsSelector(state)
  const fulfillmentMethod = _fulfillmentMethodSelector(state)
  const lastSegment = tarrifSegments[tarrifSegments.length - 1]
  const showVoidTicketsModal = showVoidTicketsSelector(state)

  const allowReprintResend = Boolean(
    fulfillmentMethod && !isAnyLeapFulfillment(fulfillmentMethod) &&
    lastSegment &&
    moment(lastSegment.validity_end_date).isSameOrAfter(moment().startOf('day')) &&
    isBookingConfirmedSelector(state) &&
    (_availableFulfillmentMethodsSelector(state) || []).some(method => method.code === fulfillmentMethod.code)
  )

  return {
    fulfillmentMethod,
    allowReprintResend,
    loading: state.containers.aftersales.booking.reprintResend.loading,
    loadingNextSale: state.api.loading.getBooking,
    showVoidTicketsModal
  }
}

const mapDispatchToProps = {
  onClickReprintResend: () => (dispatch, getState) => {
    if (shouldVoidMagstripeTicketsSelector(overviewBookingSelector)(getState())) {
      dispatch(showModal(VOID_TICKETS_MODAL))
    } else {
      dispatch(reprintResendBooking())
    }
  },
  onChangeFulfillment: () => (dispatch, getState) => {
    const bookingNumber = bookingNumberSelector(overviewBookingSelector)(getState())
    dispatch([
      hideElement(FINALIZE_BOOKING_MODAL),
      goToChangeFulfillment(bookingNumber)
    ])
  },
  onNextSale: () => async (dispatch, getState) => {
    const bookingNumber = bookingNumberSelector(overviewBookingSelector)(getState())
    await dispatch(getBooking(bookingNumber))
    dispatch(hideElement(FINALIZE_BOOKING_MODAL))
    dispatch(displayNextCustomer())
  },
  clearState: () => clearState([CONTAINERS_BOOKING_REPRINT_RESEND])
}

export default connect(mapStateToProps, mapDispatchToProps)(FulfillmentContainer)
