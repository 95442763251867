import { connect } from 'react-redux'
import { lastApiErrorsSelector } from 's3p-js-lib/src/redux/selectors/api/base/last-api-errors'
import { clearLastApiErrors } from 's3p-js-lib/src/redux/actions/api/base/last-api-errors'

const mapStateToProps = () => {
  const _lastApiErrorsSelector = lastApiErrorsSelector()
  return (state, ownProps) => ({
    lastApiErrors: _lastApiErrorsSelector(state, ownProps)
  })
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  clearApiErrors: () => dispatch(clearLastApiErrors(ownProps.namespaces))
})

export default connect(mapStateToProps, mapDispatchToProps)
