import request from './request'
import { createError, createWarning } from './response-handler'
import { STATUS_ERROR } from '../machine/device-manager'

export const translateResponse = response => {
  const messages = []

  if ((response.status || response.Status) === STATUS_ERROR && response.results) {
    messages.push(createWarning(response.results.message, {translated: true}))
  } else if ((response.status || response.Status) === STATUS_ERROR && response.Details) {
    messages.push(createError(response.Details.message, {translated: true}))
  }

  return messages
}

export default {
  async readBarcode (timeout) {
    return request({
      method: 'post',
      endpoint: '/vtm/devices/rest/BarcodeScannerDevice/GetBarcodeData',
      data: {timeout: timeout},
      translateResponse
    })
  }
}
