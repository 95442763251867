import {
  UI_READ_LEAP_CARD_START,
  UI_READ_LEAP_CARD_END,
  UI_SELECT_LEAP_PRODUCT
} from '../../../actions/types'
import { CONTAINERS_LEAP_READ } from '../../../../constants'
import { CLEAR_STATE } from 's3p-js-lib/src/redux/actions/types'

const initialState = {
  loading: false,
  selectedProduct: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UI_READ_LEAP_CARD_START:
      return {loading: true, selectedProduct: null}
    case UI_READ_LEAP_CARD_END:
      return initialState
    case UI_SELECT_LEAP_PRODUCT:
      return {...state, selectedProduct: action.product}
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_LEAP_READ) ? initialState : state
    default:
      return state
  }
}
