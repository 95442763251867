import { createSelector } from 'reselect'
import { itemsSelector } from 's3p-js-lib/src/redux/selectors/api/v2/search'
import { PRODUCT_TYPE_VOUCHER } from '../../../../constants'
import { provisionalOrCompletedBookingSelector } from '../../api/booking/booking'
import { formFieldValuesSelectorCreator } from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import groupBy from 'lodash/groupBy'
import { FORM_PRODUCT_SEARCH } from 's3p-js-lib/src/constants-form'
import { toUtcDateMoment } from 's3p-js-lib/src/misc/date'

const formFieldValuesSelector = formFieldValuesSelectorCreator(FORM_PRODUCT_SEARCH)

export const selectedItemsFromCurrentBookingSelector = createSelector(
  [provisionalOrCompletedBookingSelector],
  booking => {
    const items = []
    if (booking) {
      booking.outbound_booking_tariff_segments.forEach(tariffSegment => {
        const nonCancelledProducts = tariffSegment.required_products.concat(tariffSegment.additional_products)
          .filter(product => !product.cancelled)
        const groupedProducts = groupBy(nonCancelledProducts, 'code')
        Object.keys(groupedProducts).forEach(productCode => {
          const segmentData = {
            departureStation: tariffSegment.departure_station,
            arrivalStation: tariffSegment.arrival_station,
            travelDate: tariffSegment.travel_date,
            productCode
          }
          items.push(segmentData)
        })
      })
    }
    return items
  }
)

export const productsSelector = createSelector(
  [
    itemsSelector,
    state => state.containers.tickets.productSearchResult.pendingProduct,
    state => state.containers.tickets.productSearchResult.lastSelectedProduct,
    selectedItemsFromCurrentBookingSelector,
    formFieldValuesSelector
  ],
  (products, pendingProduct, lastSelectedProduct, selectedItems, values) => (products || []).map(product => ({
    ...product,
    selected: product.type !== PRODUCT_TYPE_VOUCHER && (pendingProduct || lastSelectedProduct || {}).id === product.id,
    disabled: (!values.origin && !values.destination)
      ? false
      : selectedItems.some(
        item => (
          item.departureStation &&
          item.arrivalStation &&
          values.origin &&
          values.destination &&
          item.departureStation._u_i_c_station_code === values.origin._u_i_c_station_code &&
          item.arrivalStation._u_i_c_station_code === values.destination._u_i_c_station_code &&
          toUtcDateMoment(item.travelDate).isSame(toUtcDateMoment(values.validFrom)) &&
          item.productCode === product.productCode
        )
      )
  }))
)
