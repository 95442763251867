import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
  startPedPayment,
  hideCreditDebitModal
} from '../../../redux/actions/containers/base/payment-modal/credit-debit-modal'
import {loadingSelector} from '../../../redux/selectors/containers/base/payment-modal/payment-modal'
import {PED_STATUS_FAILURE, PED_STATUS_AUTHORIZATION_REQUIRED, PED_STATUS_DECLINED} from '../../../constants'
import CreditDebitModal from '../../../components/base/payment-modal/credit-debit-modal'
import {selectedPaymentMethodSelector} from '../../../redux/selectors/containers/base/tiles/payment-methods'

class CreditDebitModalContainer extends Component {
  static propTypes = {
    initiatePayment: PropTypes.func.isRequired
  }

  componentDidMount () {
    this.props.initiatePayment()
  }

  render () {
    const props = {...this.props}
    delete props.initiatePayment

    return <CreditDebitModal {...props} />
  }
}

const modalDisabledSelector = state => (
  ![PED_STATUS_FAILURE, PED_STATUS_AUTHORIZATION_REQUIRED, PED_STATUS_DECLINED]
    .includes(state.containers.base.paymentModal.creditDebit.status)
)

const mapStateToProps = state => ({
  paymentMethodCode: selectedPaymentMethodSelector(state),
  disabled: modalDisabledSelector(state) || loadingSelector(state)
})

const mapDispatchToProps = {
  initiatePayment: startPedPayment,
  handleHideModal: hideCreditDebitModal
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditDebitModalContainer)
