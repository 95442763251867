import {
  API_V2_GET_CURRENCY_RATES_REQUEST,
  API_V2_GET_CURRENCY_RATES_SUCCESS,
  API_V2_GET_CURRENCY_RATES_FAILURE
} from '../../../types-v2'
import Client from '../../../../../api/client'
import ensureToken from '../../../../../api/ensure-token'

const getCurrencyRatesRequest = () => ({type: API_V2_GET_CURRENCY_RATES_REQUEST})
const getCurrencyRatesSuccess = response => ({type: API_V2_GET_CURRENCY_RATES_SUCCESS, response})
const getCurrencyRatesFailure = error => ({type: API_V2_GET_CURRENCY_RATES_FAILURE, error})

export const getCurrencyRates = () => ensureToken(async (token, dispatch) => {
  try {
    dispatch(getCurrencyRatesRequest())
    const response = await Client.getCurrencyRates(token)
    dispatch(getCurrencyRatesSuccess(response))
  } catch (error) {
    dispatch(getCurrencyRatesFailure(error))
    throw error
  }
})
