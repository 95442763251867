import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { hasAccessToWaiveFeesSelector } from 's3p-js-lib/src/redux/selectors/api/auth/auth'
import { toggleWaiveFees } from 's3p-js-lib/src/redux/actions/user-input/aftersales/waive-fees'
import WaiveFees from '../../../../components/aftersales/waive-fees/waive-fees'
import { hasRebookFeeSelector } from '../../../../redux/selectors/api/booking/products'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import { waiveFeesSelector } from 's3p-js-lib/src/redux/selectors/user-input/aftersales/waive-fees'

class WaiveFeesContainer extends Component {
  static propTypes = {
    isAllowedWaiveFees: PropTypes.bool
  }

  render () {
    return this.props.isAllowedWaiveFees
      ? (
        <WaiveFees {...this.props} />
      ) : null
  }
}
const _hasAccessToWaiveFeesSelector = hasAccessToWaiveFeesSelector(originalBookingSelector)
const _hasRebookfeeSelector = hasRebookFeeSelector(originalBookingSelector)

const mapStateToProps = state => ({
  selected: waiveFeesSelector(state),
  isAllowedWaiveFees: _hasRebookfeeSelector(state) && _hasAccessToWaiveFeesSelector(state)
})

const mapDispatchToProps = {
  onToggleWaiveFees: toggleWaiveFees
}

export default connect(mapStateToProps, mapDispatchToProps)(WaiveFeesContainer)
