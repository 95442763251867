import uniqueId from 'lodash/uniqueId'

/* eslint-disable no-extend-native */
Promise.prototype.finally = function (callback) {
  let p = this.constructor
  return this.then(
    value => p.resolve(callback()).then(() => value),
    reason => p.resolve(callback()).then(() => { throw reason })
  )
}
/* eslint-enable no-extend-native */

/**
 * A queue that ensures promises to be executed in order, but still returns promise result of that promise
 * Example usage:
 *  const q = new PromiseQueue();
 *  q.add(() => request('endpoint 1'))
 *
 *  This request is executed if the 'endpoint 1' is finished
 *  q.add(() => request('endpoint 2'))
 *      .then(cb of endpoint success)
 *      .catch(cb of endpoint failure)
 *
 *  Or
 *
 *  try {
 *    const result = await q.add(() => request('endpoint 3'));
 *  } catch(error) {}
 *
 * @constructor
 */
// eslint-disable-next-line func-style
function PromiseQueue () {
  this.queue = []
  this.promiseMap = {}
  this.running = false

  this._publish = (id, method, response) => {
    if (this.promiseMap[id]) {
      this.promiseMap[id].forEach(item => item[method](response))
    }
  }

  this._cleanup = id => {
    delete this.promiseMap[id]
  }

  this._runItem = config => {
    return new Promise(resolve => {
      return config.promiseCreator(config.id)
        .then(response => {
          this.resolve(config.id, response)
        })
        .catch(response => {
          this.reject(config.id, response)
        })
        .finally(() => {
          this._cleanup(config.id)
          resolve()
        })
    })
  }

  this.run = () => {
    const tick = () => {
      this.running = true
      const queueItem = this.queue.shift()
      this._runItem(queueItem).then(() => {
        if (this.queue.length > 0) {
          tick()
        } else {
          this.running = false
        }
      })
    }

    if (!this.running) {
      tick()
    }
  }

  this.reject = (id, response) => {
    this._publish(id, 'reject', response)
  }

  this.resolve = (id, response) => {
    this._publish(id, 'resolve', response)
  }

  this.subscribe = id => {
    return new Promise((resolve, reject) => {
      if (!this.promiseMap[id]) {
        this.promiseMap[id] = []
      }
      this.promiseMap[id].push({
        resolve,
        reject
      })
    })
  }

  this.add = promiseCreator => {
    return new Promise((resolve, reject) => {
      const id = uniqueId('promiseQueue')
      this.queue.push({id, promiseCreator})
      this.subscribe(id).then(resolve, reject)
      this.run()
    })
  }
}

export default PromiseQueue
