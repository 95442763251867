import React, { Component } from 'react'
import GridLayout from '../../../elements/grid/grid-layout'
import GridColumn from '../../../elements/grid/grid-column'
import CancelBy from '../../../containers/aftersales/cancellation/cancel-by'
import VoidFulfillment from '../../../containers/aftersales/cancellation/void-fulfillment'

export default class CancellationVoid extends Component {
  render () {
    return (
      <GridLayout name='left-content'>
        <GridColumn name='left'>
          <CancelBy />
        </GridColumn>
        <GridColumn name='content'>
          <VoidFulfillment />
        </GridColumn>
      </GridLayout>
    )
  }
}
