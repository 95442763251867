import React from 'react'
import { Route } from 'react-router'
import CancellationLayout from '../../layouts/aftersales/cancellation'
import { isCancellationAvailableSelector } from '../../redux/selectors/containers/base/aftersales'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import container from '../../containers/aftersales/auth'

export default (
  <Route
    key='aftersales-cancellation'
    path='/:lang/aftersales/cancellation'
    component={container(
      isCancellationAvailableSelector(originalBookingSelector),
      CancellationLayout
    )}
  />
)
