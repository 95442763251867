import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getPaymentMethods } from '../../../redux/actions/containers/base/tiles/payment-methods'
import PaymentMethods from '../tiles/payment-methods'

class PaymentMethodsContainer extends Component {
  static propTypes = {
    getPaymentMethods: PropTypes.func.isRequired
  }

  UNSAFE_componentWillMount () {
    this.props.getPaymentMethods()
  }

  render () {
    return <PaymentMethods />
  }
}

const mapDispatchToProps = {
  getPaymentMethods
}

export default connect(null, mapDispatchToProps)(PaymentMethodsContainer)
