import {
  API_GET_STATIONS_REQUEST,
  API_GET_STATIONS_SUCCESS,
  API_GET_STATIONS_FAILURE
} from '../../types'
import Client from '../../../../api/client'
import ensureToken from '../../../../api/ensure-token'

const getStationsRequest = () => ({type: API_GET_STATIONS_REQUEST})
const getStationsSuccess = response => ({type: API_GET_STATIONS_SUCCESS, response})
const getStationsFailure = error => ({type: API_GET_STATIONS_FAILURE, error})

export const getStations = () => ensureToken(async (token, dispatch) => {
  try {
    dispatch(getStationsRequest())
    const response = await Client.loadStations(token)
    dispatch(getStationsSuccess(response))
  } catch (error) {
    dispatch(getStationsFailure(error))
    throw error
  }
})
