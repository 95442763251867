import Agent from './agent/index'
import Tickets from './tickets'
import Reservations from './reservations/index'
import AfterSales from './aftersales/index'
import Maintenance from './maintenance/index'
import Leap from './leap'
import Error from './error/index'

const routes = [
  ...Agent,
  Tickets,
  ...Reservations,
  ...AfterSales,
  ...Maintenance,
  Leap,
  ...Error // should always be the last in the array
]

export default routes
