import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Icon from '../../../../elements/icon/icon'

export default class ServiceProperty extends Component {
  static propTypes = {
    code: PropTypes.string.isRequired,
    description: PropTypes.string
  }

  static defaultProps = {
    description: ''
  }

  render () {
    return (
      <li>
        <Icon name={this.props.code} className='medium' title={this.props.description} />
      </li>
    )
  }
}
