import Error from './error'
import Tickets from './tickets'
import PageNotFound from './page-not-found'
import BookingConfirmation from './booking-confirmation'

export default [
  Error,
  Tickets,
  BookingConfirmation,
  PageNotFound // should always be the last in the array
]
