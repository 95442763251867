import {
  API_GET_RECEIPT_DOCUMENT_REQUEST,
  API_GET_RECEIPT_DOCUMENT_SUCCESS,
  API_GET_RECEIPT_DOCUMENT_FAILURE,
  CLEAR_STATE
} from '../../../actions/types'

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_GET_RECEIPT_DOCUMENT_REQUEST:
    case API_GET_RECEIPT_DOCUMENT_FAILURE:
      return initialState
    case API_GET_RECEIPT_DOCUMENT_SUCCESS:
      return action.response
    case CLEAR_STATE:
      return action.namespaces.includes('api.booking.receiptDocument') ? initialState : state
    default:
      return state
  }
}
