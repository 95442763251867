import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isInboundBeforeOutboundSelector } from 's3p-js-lib/src/redux/selectors/api/orientation/specification/travel'
import NextButton, { INBOUND_BEFORE_OUTBOUND_NAME } from '../../../../components/reservations/journey-search/next-button'
import containRebookingFlow from '../../../aftersales/rebooking-flow'
import containVisibleElement from '../../../base/visible-element'

class NextButtonContainer extends Component {
  static propTypes = {
    isInboundBeforeOutbound: PropTypes.bool.isRequired,
    onShowModal: PropTypes.func.isRequired,
    goForward: PropTypes.func.isRequired
  }

  constructor (...args) {
    super(...args)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick () {
    if (this.props.isInboundBeforeOutbound) {
      this.props.onShowModal(INBOUND_BEFORE_OUTBOUND_NAME)
    } else {
      this.props.goForward()
    }
  }

  render () {
    return (
      <NextButton
        {...this.props}
        onClick={this.handleClick}
      />
    )
  }
}

const mapStateToProps = state => ({
  isValid: Boolean(state.api.orientation.offer &&
    state.api.orientation.selectedBundles.length === state.api.orientation.offer.travels.length),
  isLoadingBundle: state.api.loading.selectBundle || state.api.loading.updateSelectBundle,
  isInboundBeforeOutbound: isInboundBeforeOutboundSelector(state)
})

export default containRebookingFlow(connect(mapStateToProps)(
  containVisibleElement(INBOUND_BEFORE_OUTBOUND_NAME)(NextButtonContainer)))
