import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'

export default class Segment extends Component {
  static propTypes = {
    header: PropTypes.node,
    className: PropTypes.string
  }

  render () {
    const {header, children, className} = this.props
    return (
      <div className={classNames('segment', className)}>
        {header}
        <div className='segment__content'>
          {children}
        </div>
      </div>
    )
  }
}
