import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'
import { stringifyQuery } from './utils'

/** @deprecated use stringifyQuery from ./utils.js */
export const createQueryFromValues = stringifyQuery

export const createQueryFromBooking = booking => {
  const passengers = booking.passengerTypesCount.reduce(
    (passengers, passengerCount) => {
      for (let i = 0; i < passengerCount.count; i++) {
        passengers.push({type: passengerCount.type})
      }

      return passengers
    },
    []
  )

  const values = omitBy({
    origin: booking.outboundRoute.originUIC,
    destination: booking.outboundRoute.destinationUIC,
    outboundDate: booking.outboundRoute && booking.outboundRoute.dateTime &&
    booking.outboundRoute.dateTime.format('YYYY-MM-DD'),
    inboundDate: booking.inboundRoute && booking.inboundRoute.dateTime &&
    booking.inboundRoute.dateTime.format('YYYY-MM-DD'),
    passengers
  }, isEmpty)

  return stringifyQuery(values)
}
