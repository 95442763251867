export const createSelectorWithoutMemoize = (dependencies, resultFunc) => function () {
  const params = []
  const length = dependencies.length

  for (let i = 0; i < length; i++) {
    params.push(dependencies[i].apply(null, arguments))
  }

  return resultFunc.apply(null, params)
}

