import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Button from '../../../elements/button/button'
import Icon from '../../../elements/icon/icon'
import TextLabel from '../../../elements/text-label/text-label'

class NextButton extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired
  }

  render () {
    return (
      <react-wrapper class='react-wrapper step-navigation-wrapper'>
        <Button
          name='next-step'
          className='tertiary'
          onClick={this.props.onClick}
          loading={this.props.isLoading}
          disabled={this.props.isDisabled}
        >
          <TextLabel>
            <TextLabel.Text text={_t.message('booking-summary.action.next')} />
            <Icon name='chevron-right' className='align-right' />
          </TextLabel>
        </Button>
      </react-wrapper>
    )
  }
}

export default NextButton
