import React, { Component } from 'react'
import GridLayout from '../../elements/grid/grid-layout'
import GridColumn from '../../elements/grid/grid-column'
import MainMenu from '../../containers/base/main-menu/main-menu'
import GenericLayout from '../generic-layout'
import SeatSelection from '../../containers/reservations/seat-selection/seat-selection'
import StickyFooter from '../../elements/sticky-footer/sticky-footer'
import OrientationSummary from '../../containers/reservations/orientation-summary'
import Taskbar from '../../containers/base/taskbar/taskbar'
import BookingProgressBar from '../../containers/reservations/progress-bar'
import NextButton from '../../containers/reservations/seat-selection/next-button'

export default class SeatSelectionLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <MainMenu />
            <BookingProgressBar />
            <SeatSelection />
            <StickyFooter>
              <OrientationSummary nextButton={<NextButton />} />
              <Taskbar />
            </StickyFooter>
          </GridColumn>
        </GridLayout>
      </GenericLayout>
    )
  }
}
