import {
  USER_INPUT_SET_SELECTED_UNIQUE_BUNDLE_IDS,
  USER_INPUT_RESET_SELECTED_UNIQUE_BUNDLE_IDS,
  CLEAR_STATE
} from '../../../actions/types'

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_INPUT_SET_SELECTED_UNIQUE_BUNDLE_IDS:
      return action.uniqueBundleIds
    case USER_INPUT_RESET_SELECTED_UNIQUE_BUNDLE_IDS:
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes('userInput.orientation.selectedUniqueBundleIds') ? initialState : state
    default:
      return state
  }
}
