import { connect } from 'react-redux'
import RelatedVouchers from '../../../components/aftersales/booking/related-vouchers'
import { relatedVouchersSelector } from 's3p-js-lib/src/redux/selectors/api/booking/related-vouchers'
import { overviewBookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'

const _relatedVouchersSelector = relatedVouchersSelector(overviewBookingSelector)

const mapStateToProps = state => ({
  vouchers: _relatedVouchersSelector(state)
})

export default connect(mapStateToProps)(RelatedVouchers)
