import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ComponentContainer from '../../elements/component-container/component-container'
import JourneySummary from '../../containers/base/booking-summary/journey-summary'
import PriceSummary from '../../containers/base/booking-summary/price-summary'

export default class BookingSummary extends Component {
  static propTypes = {
    nextButton: PropTypes.element
  }

  render () {
    return (
      <ComponentContainer name='sticky-footer'>
        <div className='sticky-footer-content'>
          <JourneySummary />
          <PriceSummary />
          <div className='step-navigation'>
            {this.props.nextButton}
          </div>
        </div>
      </ComponentContainer>
    )
  }
}
