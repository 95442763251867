import React, { Component } from 'react'
import Offline from './components/base/offline'
import HotKeys from './elements/utils/hotkeys'
import GlobalHotKeys from './global-hotkeys'
import { HOTKEY_MAP } from './constants'
import './forms/index'
import './assets/scss/app.scss'

export default class AppContainer extends Component {
  render () {
    return (
      <HotKeys keyMap={HOTKEY_MAP}>
        <div className='container'>
          <GlobalHotKeys />
          {this.props.children}
          <Offline />
        </div>
      </HotKeys>
    )
  }
}
