import {
  API_CANCELLATION_INITIATE_REQUEST,
  API_CANCELLATION_INITIATE_SUCCESS,
  API_CANCELLATION_INITIATE_FAILURE
} from '../../../types'
import Client from '../../../../../api/client'
import ensureToken from '../../../../../api/ensure-token'

const cancellationInitiateRequest = bookingNumber => ({type: API_CANCELLATION_INITIATE_REQUEST, bookingNumber})
const cancellationInitiateSuccess = response => ({type: API_CANCELLATION_INITIATE_SUCCESS, response})
const cancellationInitiateFailure = error => ({type: API_CANCELLATION_INITIATE_FAILURE, error})

export const cancellationInitiate = bookingNumber => ensureToken(async function (token, dispatch) {
  try {
    dispatch(cancellationInitiateRequest(bookingNumber))
    const response = await Client.cancellationInitiate(token, bookingNumber)

    dispatch(cancellationInitiateSuccess(response))
  } catch (error) {
    dispatch(cancellationInitiateFailure(error))
    throw error
  }
})
