import {
  API_AGENT_UPDATE_REQUEST,
  API_AGENT_UPDATE_SUCCESS,
  API_AGENT_UPDATE_FAILURE
} from '../../../types'
import { agentsSelector } from '../../../../selectors/api/user/agent/agents'
import Client from '../../../../../api/client'
import ensureToken from '../../../../../api/ensure-token'
import ResponseCodes from '../../../../../api/response-codes'
import { agentMetaDataSelector } from '../../../../selectors/api/user/agent'

const getAgent = (username, state) => agentsSelector(state).find(agent => agent.username === username)

const updateAgentRequest = agent => ({type: API_AGENT_UPDATE_REQUEST, agent})
const updateAgentsSuccess = response => ({type: API_AGENT_UPDATE_SUCCESS, response})
const updateAgentFailure = error => ({type: API_AGENT_UPDATE_FAILURE, error})

export const updateAgentMetaData = (data, getState) => {
  const agentMetaData = agentMetaDataSelector(getState())
  const metaDataKeys = Object.keys(data)
  return agentMetaData
    .filter(data => !metaDataKeys.includes(data.key))
    .concat(metaDataKeys.map(key => ({
      key,
      value: JSON.stringify(data[key])
    })))
}

export const updateAgent = agent => ensureToken(async (token, dispatch, getState) => {
  try {
    const username = agent.username || getState().api.user.user.username
    const originalAgent = getAgent(username, getState()) || getState().api.user.agent.user
    let active = typeof agent.active === 'boolean' ? agent.active : undefined
    if (typeof active === 'undefined') {
      active = typeof originalAgent.active === 'boolean' ? originalAgent.active : true
    }

    dispatch(updateAgentRequest(agent))
    const response = await Client.updateAgent(token, {
      active,
      agentRoles: originalAgent.agent_roles,
      contractCodes: (agent.contracts || originalAgent.contracts).map(contract => contract.code),
      offices: (agent.offices || originalAgent.offices).map(office => office.name),
      firstName: originalAgent.first_name,
      lastName: originalAgent.last_name,
      email: originalAgent.email,
      username: username,
      ...agent
    })
    dispatch(updateAgentsSuccess(response))
  } catch (error) {
    dispatch(updateAgentFailure(error))
    if (ResponseCodes.isSystemError(error)) {
      throw error
    }

    return false
  }
})
