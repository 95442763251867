import {createSelector} from 'reselect'

export const baseSelectedBundlesSelector = state => state.api.orientation.selectedBundles

export const selectedBundlesByJourneyIdSelector = journeyId => createSelector(
  baseSelectedBundlesSelector,
  selectedBundles => selectedBundles.filter(
    selectedBundle => selectedBundle.journey_id === journeyId
  )
)

