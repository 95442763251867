import {
  API_CMS_LOAD_BLOCKS_REQUEST,
  API_CMS_LOAD_BLOCKS_SUCCESS,
  API_CMS_LOAD_BLOCKS_FAILURE,
  API_OAUTH_LOGOUT,
  CLEAR_STATE
} from '../../../actions/types'

export const emptyCmsBlock = {title: '', content: ''}

export const initialState = {
  isLoading: false,
  loadingCmsBlocks: [],
  cmsBlocks: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case API_OAUTH_LOGOUT:
      return initialState
    case API_CMS_LOAD_BLOCKS_REQUEST:
      return {
        ...state,
        loadingCmsBlocks: state.loadingCmsBlocks.concat(action.blockNames),
        isLoading: true
      }
    case API_CMS_LOAD_BLOCKS_SUCCESS: {
      const cmsBlocks = action.blockNames.reduce(
        (cmsBlocks, blockName) => {
          const block = action.response.data.find(cmsBlock => cmsBlock.name === blockName)
          cmsBlocks[blockName] = {
            title: block && block.title ? block.title : '',
            content: block && block.content ? block.content : '',
            locale: action.locale
          }
          return cmsBlocks
        },
        state.cmsBlocks ? {...state.cmsBlocks} : {}
      )
      return {
        isLoading: false,
        loadingCmsBlocks: state.loadingCmsBlocks.filter(blockName => !cmsBlocks[blockName]),
        cmsBlocks
      }
    }
    case API_CMS_LOAD_BLOCKS_FAILURE:
      return {
        isLoading: false,
        loadingCmsBlocks: state.loadingCmsBlocks.filter(blockName => !action.blockNames.includes(blockName)),
        cmsBlocks: action.blockNames.reduce(
          (cmsBlocks, blockName) => {
            cmsBlocks[blockName] = {
              title: '',
              content: '',
              locale: action.locale
            }
            return cmsBlocks
          },
          state.cmsBlocks ? {...state.cmsBlocks} : {}
        )
      }
    case CLEAR_STATE:
      return action.namespaces.includes('api.base.cms') ? initialState : state
    default:
      return state
  }
}
