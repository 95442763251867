import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  overviewBookingSelector,
  fulfillmentMethodSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { provisionalOrOverviewBookingSelector } from '../../../redux/selectors/api/booking/booking'
import ChangeFulfillment from '../../../components/aftersales/change-fulfillment/change-fulfillment'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'

class ChangeFulfillmentContainer extends Component {
  static propTypes = {
    hasOverviewBooking: PropTypes.bool.isRequired,
    clearState: PropTypes.func.isRequired,
    currentFulfillmentMethod: PropTypes.any
  }

  componentWillUnmount () {
    this.props.clearState()
  }

  render () {
    return <ChangeFulfillment
      hasOverviewBooking={this.props.hasOverviewBooking}
      currentFulfillmentMethod={this.props.currentFulfillmentMethod}
    />
  }
}

const _fulfillmentMethodSelector = fulfillmentMethodSelector(provisionalOrOverviewBookingSelector)

const mapStateToProps = state => ({
  hasOverviewBooking: Boolean(overviewBookingSelector(state)),
  currentFulfillmentMethod: _fulfillmentMethodSelector(state)
})

const mapDispatchToProps = (_, ownProps) => dispatch => ({
  clearState: () => dispatch(clearState([
    'api.booking.overviewBooking',
    'api.booking.completedBooking',
    'api.booking.additionalDetailsBooking'
  ]))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeFulfillmentContainer)
