import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import SeatSelection from '../../../components/base/seat-selection/seat-selection'
import AllocatedSeats from '../../../components/base/seat-selection/allocated-seats'
import Loader, { TYPE_COMPONENT } from '../../../elements/loader/loader'
import { getSeatSelectionOptions } from 's3p-js-lib/src/redux/actions/api/base/seat-selector/seat-selection-options'
import {
  seatSelectionAvailableOptionsSelector,
  hasSelectedSeatsSelector
} from 's3p-js-lib/src/redux/selectors/user-input/base/seat-selection'
import {
  allSegmentsSeatSelectionOptionsSelector,
  outboundSeatSelectionOptionsSelector,
  inboundSeatSelectionOptionsSelector
} from '../../../redux/selectors/containers/base/seat-selection/seat-selection'
import {
  outboundTravelInfoSelector,
  inboundTravelInfoSelector
} from 's3p-js-lib/src/redux/selectors/api/orientation/specification'
import { segmentsSelector } from '../../../redux/selectors/containers/base/seat-selector/segment-navigation'

const orientationHasSelectedSeatsSelector = hasSelectedSeatsSelector(allSegmentsSeatSelectionOptionsSelector)

const availableSegmentsSelector =
  segmentsSelector(seatSelectionAvailableOptionsSelector(allSegmentsSeatSelectionOptionsSelector))

class SeatSelectionContainer extends Component {
  static propTypes = {
    journeyId: PropTypes.string.isRequired,
    getSeatSelectionOptions: PropTypes.func.isRequired,
    hasSelectedSeats: PropTypes.bool,
    outboundTravelInfo: PropTypes.object,
    inboundTravelInfo: PropTypes.object,
    isLoadingSeatSelectionOptions: PropTypes.bool
  }

  UNSAFE_componentWillMount () {
    this.props.getSeatSelectionOptions()
  }

  render () {
    return (
      (this.props.isLoadingSeatSelectionOptions
        ? <Loader type={TYPE_COMPONENT} />
        : (this.props.hasSelectedSeats
          ? <AllocatedSeats {...this.props} />
          : <SeatSelection {...this.props} />
        )
      )
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  journeyId: state.api.orientation.journeySearch && state.api.orientation.journeySearch.id,
  outboundSeatSelectionOptions: outboundSeatSelectionOptionsSelector(state),
  inboundSeatSelectionOptions: inboundSeatSelectionOptionsSelector(state),
  availableSegments: availableSegmentsSelector(state, ownProps),
  outboundTravelInfo: outboundTravelInfoSelector(state),
  inboundTravelInfo: inboundTravelInfoSelector(state),
  hasSelectedSeats: orientationHasSelectedSeatsSelector(state),
  isLoadingCreateBooking: state.api.loading.createProvisionalBooking || state.api.loading.rebookingCreateBooking,
  isLoadingSeatSelectionOptions: state.api.loading.getSeatSelectionOptions
})

const mapDispatchToProps = {
  getSeatSelectionOptions: () => (dispatch, getState) => getState().api.orientation.journeySearch
    ? dispatch(getSeatSelectionOptions(getState().api.orientation.journeySearch.id))
    : null
}

export default connect(mapStateToProps, mapDispatchToProps)(SeatSelectionContainer)
