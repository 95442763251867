import { connect } from 'react-redux'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import ReturnPaymentModal from '../../../components/base/payment-modal/return-payment-modal'
import { openMachineCashDrawer } from '../../../redux/actions/machine/cash-drawer'
import { pendingPaymentsSelector } from '../../../redux/selectors/api/booking/payments'

const _paymentsSelector = pendingPaymentsSelector(bookingSelector)

const mapStateToProps = state => ({
  payments: _paymentsSelector(state),
  loading: Boolean(state.api.v2.loading.updatePayments || state.api.loading.removeVoucher)
})

const mapDispatchToProps = {
  openMachineCashDrawer
}

export default connect(mapStateToProps, mapDispatchToProps)(ReturnPaymentModal)
