import { connect } from 'react-redux'
import PaymentDetailsFooter from '../../../../components/aftersales/booking/payment-details/payment-details-footer'
import { requestRefundVoucher } from 's3p-js-lib/src/redux/actions/api/payment/refund'
import { handleRefundPayment } from '../../../../redux/actions/containers/aftersales/booking/refund'
import { isRefundPaymentAvailableSelector } from '../../../../redux/selectors/containers/aftersales/booking/refund'
import { showModal } from '../../../../redux/actions/containers/base/visible-element'
import { REFUND_VOUCHER_MODAL } from '../../../../constants'

const mapStateToProps = state => ({
  showRefundPayment: isRefundPaymentAvailableSelector(state)
})

const mapDispatchToProps = {
  onRefundPayment: handleRefundPayment,
  onRefundVoucher: () => async dispatch => {
    const result = await dispatch(requestRefundVoucher())
    if (result) {
      dispatch(showModal(REFUND_VOUCHER_MODAL))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetailsFooter)
