import DOMPurify from 'dompurify'
import Validator from './validator'

export default {
  fields: {
    username: {
      normalizers: [
        DOMPurify.sanitize
      ],
      validators: [
        Validator.isRequired
      ]
    },
    password: {
      validators: [
        Validator.isRequired
      ]
    }
  }
}
