import {
  MACHINE_GET_STATION_INFO_REQUEST,
  MACHINE_GET_STATION_INFO_SUCCESS,
  MACHINE_GET_STATION_INFO_FAILURE
} from '../types'
import DeviceManager from '../../../machine/device-manager'

const getStationInfoRequest = () => ({type: MACHINE_GET_STATION_INFO_REQUEST})
const getStationInfoSuccess = response => ({type: MACHINE_GET_STATION_INFO_SUCCESS, response})
const getStationInfoFailure = machineError => ({type: MACHINE_GET_STATION_INFO_FAILURE, machineError})

export const getMachineStationInformation = () => async dispatch => {
  try {
    dispatch(getStationInfoRequest())
    const response = await DeviceManager.getStationInformation()
    dispatch(getStationInfoSuccess(response))
  } catch (error) {
    dispatch(getStationInfoFailure(error))
  }
}
