import { bookingFlowSelector } from './booking-flow'
import { currentPathnameSelector } from './routing'
import { isOnHoldConfirmedSelector } from '../../api/booking/booking'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'

export const progressBarSelectorCreator = (prefix, skipDetails = false) => state => {
  const {skipAdditionalProducts, skipSeatSelection} = bookingFlowSelector(state)
  const steps = []

  if (!skipSeatSelection) {
    steps.push({
      path: `${prefix}/seat-selection`,
      additionalPaths: [`${prefix}/seat-selector`],
      label: 'progress-bar.seat-selection'
    })
  }

  if (!skipAdditionalProducts) {
    steps.push({
      path: `${prefix}/additional-products`,
      label: 'progress-bar.additional-products'
    })
  }

  if (!skipDetails && !isOnHoldConfirmedSelector(bookingSelector)(state)) {
    steps.push({path: `${prefix}/details`, label: 'progress-bar.details'})
  }

  steps.push({path: `${prefix}/review-payment`, label: 'progress-bar.payment'})

  return {
    pathname: currentPathnameSelector(state),
    steps
  }
}
