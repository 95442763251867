import {
  API_V2_ADD_CUSTOMER_REQUEST,
  API_V2_ADD_CUSTOMER_SUCCESS,
  API_V2_ADD_CUSTOMER_FAILURE
} from 's3p-js-lib/src/redux/actions/types-v2'
import Client from '../../../../../api/client'
import ensureToken from 's3p-js-lib/src/api/ensure-token'
import {UPDATE_BOOKING_HANDLED_ERRORS} from 's3p-js-lib/src/redux/actions/api/v2/booking'

const addCustomerBookingRequest = (bookingNumber, customer) => ({
  type: API_V2_ADD_CUSTOMER_REQUEST,
  bookingNumber,
  customer
})

const addCustomerBookingSuccess = response => ({
  type: API_V2_ADD_CUSTOMER_SUCCESS,
  response
})

const addCustomerBookingFailure = error => ({
  type: API_V2_ADD_CUSTOMER_FAILURE,
  error
})

export const addCustomer = (bookingNumber, customer) => ensureToken(async (token, dispatch) => {
  try {
    dispatch(addCustomerBookingRequest(bookingNumber, customer))
    const response = await Client.addCustomer(token, bookingNumber, customer)
    dispatch(addCustomerBookingSuccess(response))
  } catch (error) {
    dispatch(addCustomerBookingFailure(error))
    if (UPDATE_BOOKING_HANDLED_ERRORS.includes(error.errorCode)) {
      return false
    }
    throw error
  }
})
