import { createSelector } from 'reselect'
import { camelCaseKeys } from '../../../../misc/utils'
import { toUtcDateMoment } from '../../../../misc/date'
import { TRAVEL_DIRECTION_OUTBOUND } from '../../../../constants'
import { stationsSelector } from './stations'

export const journeySearchSelector = createSelector(
  [state => state.api.orientation.journeySearch],
  journeySearch => journeySearch && {
    ...journeySearch,
    departure_date: journeySearch.departure_date && toUtcDateMoment(journeySearch.departure_date),
    return_date: journeySearch.return_date && toUtcDateMoment(journeySearch.return_date)
  }
)

export const enrichedJourneySearchSelector = createSelector(
  [
    journeySearchSelector,
    stationsSelector
  ],
  (journeySearch, stations) => {
    const findStation = code => stations.find(station => station.UICStationCode === code)

    return journeySearch
      ? {
        ...camelCaseKeys(journeySearch),
        originStation: findStation(journeySearch.origin_station),
        destinationStation: findStation(journeySearch.destination_station),
        passengers: journeySearch.passengers.map(camelCaseKeys)
      } : null
  }
)

export const isReturnJourneySelector = createSelector(
  [state => state.api.orientation.journeySearch],
  journeySearch => Boolean(journeySearch && journeySearch.departure_date && journeySearch.return_date)
)

export const travelSelector = createSelector(
  [
    (state, ownProps) => ownProps && ownProps.direction,
    state => state.api.orientation.offer
  ],
  (direction, offer) => {
    const travelDirection = direction === TRAVEL_DIRECTION_OUTBOUND ? 'outward' : 'return'
    return offer && offer.travels.find(travel => travel.direction === travelDirection)
  }
)

export const nextCursorSelector = createSelector(
  [travelSelector],
  travel => travel && travel.next_cursor
)

export const prevCursorSelector = createSelector(
  [travelSelector],
  travel => travel && travel.prev_cursor
)
