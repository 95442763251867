import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _t from 's3p-js-lib/src/translate'
import FinalizeBookingModal from './finalize-booking-modal'
import { isOfflineSelectorCreator } from '../../../redux/selectors/machine/offline'
import { DEVICE_ID_RECEIPT_PRINTER, LAST_MACHINE_ERROR_NAMESPACE_RECEIPT_PRINTER } from '../../../constants'
import { FEEDBACK_ALERT, FEEDBACK_SUCCESS } from '../../../elements/feedback/feedback'
import {
  printBarcodeTickets,
  continueBarcodeTickets
} from '../../../redux/actions/containers/base/finalize-booking/print-barcode-modal'
import { lastMachineErrorsSelector } from '../../../redux/selectors/machine/last-machine-errors'
import { printReceipt } from '../../../redux/actions/containers/base/tiles/payment-methods'

class PrintBarcodeModalContainer extends Component {
  static propTypes = {
    onChangeFulfillment: PropTypes.func.isRequired,
    onNextSale: PropTypes.func.isRequired,
    isPrinting: PropTypes.bool,
    barcodeErrorMessage: PropTypes.any,
    printBarcodeTickets: PropTypes.func.isRequired,
    continueBarcodeTickets: PropTypes.func.isRequired,
    isOfflineTicketPrinter: PropTypes.bool,
    receiptPrinterMachineErrors: PropTypes.array,
    aftersales: PropTypes.bool,
    retrieveAndPrintCycle: PropTypes.bool,
    receiptPrinterErrorMessage: PropTypes.object,
    showContinuePrinting: PropTypes.bool,
    showRetryPrinting: PropTypes.bool
  }

  UNSAFE_componentWillMount () {
    if (!this.props.isOfflineTicketPrinter) {
      this.props.printBarcodeTickets()
    }
  }

  render () {
    let messages = this.props.isPrinting || this.props.retrieveAndPrintCycle
      ? []
      : this.props.barcodeErrorMessage
        ? [{
          status: FEEDBACK_ALERT,
          text: [_t.message('finalize-booking-modal.barcode.status-error', {error: this.props.barcodeErrorMessage})]
        }]
        : this.props.receiptPrinterErrorMessage
          ? [{status: FEEDBACK_ALERT, text: [_t.message('finalize-booking-modal.receipt.default-error')]}]
          : this.props.receiptPrinterMachineErrors && this.props.receiptPrinterMachineErrors.length
            ? []
            : [{status: FEEDBACK_SUCCESS, text: [_t.message('finalize-booking-modal.barcode.status-success')]}]

    return (
      <FinalizeBookingModal
        {...this.props}
        title={_t.message('finalize-booking-modal.barcode.title')}
        messages={messages}
        disableNextSale={this.props.isPrinting}
        showContinuePrinting={this.props.showContinuePrinting}
        onContinuePrinting={this.props.continueBarcodeTickets}
        showRetryPrinting={this.props.showRetryPrinting}
        onRetryPrinting={this.props.printBarcodeTickets}
      />
    )
  }
}

const _lastMachineErrorsSelector = lastMachineErrorsSelector([LAST_MACHINE_ERROR_NAMESPACE_RECEIPT_PRINTER])
const receiptPrinterOfflineSelector = isOfflineSelectorCreator(DEVICE_ID_RECEIPT_PRINTER)

const mapStateToProps = state => ({
  isPrinting: state.containers.base.finalizeBooking.printTicketsModal.isPrinting,
  barcodeErrorMessage: state.containers.base.finalizeBooking.printTicketsModal.ticketErrorMessage,
  receiptPrinterErrorMessage: state.machine.receiptPrinter.error,
  retrieveAndPrintCycle: state.containers.base.finalizeBooking.printTicketsModal.retrievingAndPrintingTickets,
  receiptPrinterMachineErrors: _lastMachineErrorsSelector(state),
  hasPrintErrors: Boolean(
    _lastMachineErrorsSelector(state).length ||
    receiptPrinterOfflineSelector(state) ||
    state.containers.base.finalizeBooking.printTicketsModal.ticketErrorMessage
  ),
  showRetryPrinting: Boolean(state.containers.base.finalizeBooking.printTicketsModal.ticketErrorMessage),
  showRetryReceiptPrinter: !state.containers.base.finalizeBooking.printTicketsModal.ticketErrorMessage,
  showContinuePrinting: !state.containers.base.finalizeBooking.printTicketsModal.ticketErrorMessage
})

const mapDispatchToProps = {
  printBarcodeTickets,
  continueBarcodeTickets: () => [continueBarcodeTickets(), printReceipt()]
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintBarcodeModalContainer)
