import PropTypes from 'prop-types'
import React, { Component } from 'react'
import localStorage from 's3p-js-lib/src/local-storage'
import {LOCAL_STORAGE_CMS_BLOCKS_OFFLINE} from '../../../constants'

export default class CmsBlockOffline extends Component {
  static propTypes = {
    defaultContent: PropTypes.any,
    name: PropTypes.string.isRequired
  }

  getBlock (name) {
    const blocks = localStorage.get(LOCAL_STORAGE_CMS_BLOCKS_OFFLINE, {})
    return blocks[name] || {}
  }

  render () {
    const {defaultContent} = this.props
    const cmsBlock = this.getBlock(this.props.name)
    return (
      <div className='cms-block' dangerouslySetInnerHTML={{__html: cmsBlock.content || defaultContent || ''}} />
    )
  }
}
