import React, {Component} from 'react'
import _t from 's3p-js-lib/src/translate'
import Modal from '../../../elements/modal/modal'

export const DIFFERENT_PASSENGERS_NUMBER_MODAL = 'different-passengers-number'

export default class DifferentPassengersNumberModal extends Component {
  render () {
    return (
      <Modal
        name={DIFFERENT_PASSENGERS_NUMBER_MODAL}
        header={
          <Modal.Header
            title={_t.message(`product-search.different-passengers-number-warning.title`)}
          />
        }
      >
        <p>{_t.message(`product-search.different-passengers-number-warning.information`)}</p>
      </Modal>
    )
  }
}
