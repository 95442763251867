import { connect } from 'react-redux'
import {
  travelSelector as specificationTravelSelector
} from 's3p-js-lib/src/redux/selectors/api/orientation/specification/travel'
import { travelSelector as offerTravelSelector } from 's3p-js-lib/src/redux/selectors/api/orientation/offer/travel'
import {
  outboundLegsSelector,
  inboundLegsSelector
} from 's3p-js-lib/src/redux/selectors/api/orientation/specification/legs'
import { TRAVEL_DIRECTION_OUTBOUND, TRAVEL_DIRECTION_INBOUND } from 's3p-js-lib/src/constants'
import JourneySummary from '../../../components/base/booking-summary/journey-summary'

const outboundSpecificationTravelSelector = specificationTravelSelector(outboundLegsSelector)
const inboundSpecificationTravelSelector = specificationTravelSelector(inboundLegsSelector)

const outboundOfferTravelSelector = offerTravelSelector(TRAVEL_DIRECTION_OUTBOUND)
const inboundOfferTravelSelector = offerTravelSelector(TRAVEL_DIRECTION_INBOUND)

const mapStateToProps = state => ({
  outboundTravel: outboundSpecificationTravelSelector(state) || outboundOfferTravelSelector(state),
  inboundTravel: inboundSpecificationTravelSelector(state) || inboundOfferTravelSelector(state)
})

export default connect(mapStateToProps)(JourneySummary)
