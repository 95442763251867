/* globals DEBUG_MODE */

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import classNames from 'classnames'
import './sprite.scss'

class Sprite extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string
  }

  render () {
    const {name, className, ...props} = this.props
    try {
      const spritePath = require(`../../assets/img/sprite/${name}.svg`).default
      return (
        <svg
          {...props}
          className={classNames('sprite', `sprite-${name}`, className)}
          aria-labelledby={`sprite-${name}-title`}
          role='img'
          key={name}
        >
          <title id={`sprite-${name}-title`}>{name}</title>
          <use pointerEvents='none' xlinkHref={spritePath} />
        </svg>
      )
    } catch (e) {
      if (DEBUG_MODE) {
        console.error(`sprite ${name}.svg could not be found`)
      }
      return null
    }
  }
}

export default Sprite
