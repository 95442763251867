import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DropdownList from '../../elements/dropdown-list/dropdown-list'

export default class DiscountCardSelect extends Component {
  static propTypes = {
    discountCardTypes: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string,
      text: PropTypes.string
    })),
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    autoFocus: PropTypes.bool
  }

  UNSAFE_componentWillMount () {
    if (typeof this.props.value === 'string') {
      this.props.onChange(this.props.discountCardTypes.find(card => card.code === this.props.value))
    }
  }

  render () {
    return (
      <DropdownList
        {...this.props}
        valueField='code'
        textField='text'
        data={this.props.discountCardTypes}
        defaultValue=''
      />
    )
  }
}
