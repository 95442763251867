import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Feedback, { FEEDBACK_WARNING, FEEDBACK_ALERT } from './feedback'
import { MESSAGE_SEVERITY_WARNING, MESSAGE_SEVERITY_ERROR } from 's3p-js-lib/src/constants'

export default class FeedbackMachine extends Component {
  static propTypes = {
    namespaces: PropTypes.arrayOf(PropTypes.string),
    messages: PropTypes.arrayOf(PropTypes.shape({
      severity: PropTypes.string,
      message: PropTypes.string.isRequired,
      translated: PropTypes.bool
    })).isRequired,
    severities: PropTypes.arrayOf(PropTypes.string),
    defaultMessage: PropTypes.string
  }

  static defaultProps = {
    severities: [MESSAGE_SEVERITY_WARNING, MESSAGE_SEVERITY_ERROR]
  }

  shouldComponentUpdate (nextProps) {
    return this.props.messages !== nextProps.messages || this.props.defaultMessage !== nextProps.defaultMessage
  }

  render () {
    const feedback = this.props.messages.reduce((messages, message, index) => {
      const text = message.translated ? message.message : _t.message(`machine.${message.message}`)

      if (this.props.severities.includes(message.severity)) {
        messages.push(<Feedback
          key={index}
          status={MESSAGE_SEVERITY_WARNING === message.severity ? FEEDBACK_WARNING : FEEDBACK_ALERT}
          text={text}
        />)
      }

      return messages
    }, [])

    return feedback.length ? <react-wrapper class='react-wrapper'>{feedback}</react-wrapper> : (
      this.props.defaultMessage ? <Feedback text={this.props.defaultMessage} /> : null
    )
  }
}
