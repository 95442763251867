import JourneySearch from './journey-search'
import SeatSelector from './seat-selector'
import Details from './booking-details'
import AdditionalProducts from './additional-products'
import SeatSelection from './seat-selection'
import Payment from './review-payment'

export default [
  JourneySearch,
  AdditionalProducts,
  SeatSelection,
  Details,
  Payment,
  SeatSelector
]
