import {
  API_TICKET_VOID_VALIDATION_REQUEST,
  API_TICKET_VOID_VALIDATION_SUCCESS,
  API_TICKET_VOID_VALIDATION_FAILURE
} from '../../types'
import Client from '../../../../api/client'
import ensureToken from '../../../../api/ensure-token'

const ticketVoidValidationRequest = ticketNumber => ({type: API_TICKET_VOID_VALIDATION_REQUEST, ticketNumber})
const ticketVoidValidationSuccess = response => ({type: API_TICKET_VOID_VALIDATION_SUCCESS, response})
const ticketVoidValidationFailure = error => ({type: API_TICKET_VOID_VALIDATION_FAILURE, error})

export const ticketVoidValidation = ticketNumber => ensureToken(async (token, dispatch) => {
  try {
    dispatch(ticketVoidValidationRequest(ticketNumber))
    const response = await Client.ticketVoidValidation(token, ticketNumber)
    dispatch(ticketVoidValidationSuccess(response))
  } catch (error) {
    dispatch(ticketVoidValidationFailure(error))
    throw error
  }
})
