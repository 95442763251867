import { CLEAR_STATE } from 's3p-js-lib/src/redux/actions/types'
import {
  UI_PRINTING_TICKET_START,
  UI_PRINTING_TICKET_NUMBER,
  UI_PRINTING_TICKET_STOP,
  UI_PRINTING_TICKET_ERROR,
  UI_PRINTING_RECEIPT_ERROR,
  UI_RETRIEVE_AND_PRINT_CYCLE_START,
  UI_RETRIEVE_AND_PRINT_CYCLE_END
} from '../../../../actions/types'
import { CONTAINERS_BASE_FINALIZE_BOOKING_PRINT_TICKETS_MODAL } from '../../../../../constants'

const initialState = {
  isPrinting: false,
  totalTickets: 0,
  ticketNumber: 0,
  error: null,
  ticketErrorMessage: null,
  receiptErrorMessage: null,
  retrievingAndPrintingTickets: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UI_PRINTING_TICKET_START:
      return {...state, isPrinting: true, totalTickets: action.total, ticketErrorMessage: null}
    case UI_PRINTING_TICKET_NUMBER:
      return {...state, ticketNumber: action.number}
    case UI_PRINTING_TICKET_STOP:
      return {...state, isPrinting: false}
    case UI_PRINTING_TICKET_ERROR:
      return {...state, ticketErrorMessage: action.errorMessage}
    case UI_PRINTING_RECEIPT_ERROR:
      return {...state, receiptErrorMessage: action.errorMessage}
    case UI_RETRIEVE_AND_PRINT_CYCLE_START:
      return {...state, retrievingAndPrintingTickets: true}
    case UI_RETRIEVE_AND_PRINT_CYCLE_END:
      return {...state, retrievingAndPrintingTickets: false}
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_BASE_FINALIZE_BOOKING_PRINT_TICKETS_MODAL) ? initialState : state
    default:
      return state
  }
}
