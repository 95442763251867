import React, { Component } from 'react'
import Row from '../elements/row/row'
import ShiftModal from '../containers/agent/shift/modal'
import ChangePassword from '../containers/agent/change-password/change-password'

class GenericLayout extends Component {
  render () {
    return (
      <main>
        <Row>
          {this.props.children}
        </Row>
        <ShiftModal />
        <ChangePassword />
      </main>
    )
  }
}

export default GenericLayout
