/* globals S3P_SETTINGS: true */
import {
  formFieldValueSelectorCreator,
  formIsValidSelectorCreator
} from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import { updatePassengersUpdate } from '../../../api/aftersales/update-passengers/update'
import { validate } from 's3p-js-lib/src/redux/actions/user-input/base/form'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import {
  bookingSelector,
  bookingNumberSelector,
  paymentRequiredSelector,
  fulfillmentMethodSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { passengersSelector } from 's3p-js-lib/src/redux/selectors/api/booking/passengers'
import { aftersalesConfirm } from 's3p-js-lib/src/redux/actions/api/aftersales/confirm'
import { FORM_PASSENGER_DETAILS_AFTERSALES } from 's3p-js-lib/src/constants'
import { changedPassengersSelector } from '../../../../selectors/containers/aftersales/update-passengers/progress-navigation'
import { waiveFeesSelector } from 's3p-js-lib/src/redux/selectors/user-input/aftersales/waive-fees'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import { canOverrideAftersalesRulesSelector } from 's3p-js-lib/src/redux/selectors/api/auth/auth'
import { isMagstripeOrTVMFulfillment } from '../../../../../misc/utils'
import { reprintResendBooking } from '../booking/reprint-resend'
import { goToAftersalesUpdatePassengersReviewPayment, goToAftersalesBooking } from '../../base/routing'
import { updatePassengersAllowedSelector } from '../../../../selectors/api/booking/after-sales'
import { overrideValidationRulesSelector } from '../../../../selectors/api/auth/auth'
import {displayNextCustomer} from '../../base/finalize-booking/next-customer'

const passengersWithGroupName = (passengers, groupName) => passengers.map(
  (passenger, index) => ({
    ...passenger,
    firstName: S3P_SETTINGS.s3Passenger.features.passengerDetails.groupBookingFirstName,
    lastName: `${groupName} ${index + 1}`
  })
)

export const handleUpdatePassengers = () => async (dispatch, getState) => {
  const state = getState()
  const waiveFees = waiveFeesSelector(state)
  const overrideAftersalesRules = !updatePassengersAllowedSelector(originalBookingSelector)(state) && canOverrideAftersalesRulesSelector(originalBookingSelector)(state)
  const overrideValidationsRules = overrideValidationRulesSelector(originalBookingSelector)(state)
  const fulfillmentMethod = fulfillmentMethodSelector(originalBookingSelector)(getState())
  if (formIsValidSelectorCreator(FORM_PASSENGER_DETAILS_AFTERSALES)(state)) {
    const bookingNumber = bookingNumberSelector(originalBookingSelector)(state)
    const groupName = formFieldValueSelectorCreator(FORM_PASSENGER_DETAILS_AFTERSALES, 'groupName')(state)
    const passengersDetails = groupName
      ? passengersWithGroupName(passengersSelector(originalBookingSelector)(state), groupName)
      : changedPassengersSelector(state)
    const response = await dispatch(updatePassengersUpdate(bookingNumber, passengersDetails, waiveFees, overrideAftersalesRules, overrideValidationsRules))

    if (response) {
      if (paymentRequiredSelector(bookingSelector)(getState())) {
        dispatch(goToAftersalesUpdatePassengersReviewPayment())
      } else {
        if (isMagstripeOrTVMFulfillment(fulfillmentMethod)) {
          dispatch(reprintResendBooking())
        } else {
          await dispatch(aftersalesConfirm(bookingNumber))
        }
        dispatch(clearState('userInput.aftersales.waiveFees'))
        dispatch(goToAftersalesBooking(bookingNumber))
        dispatch(displayNextCustomer())
      }
    }
  } else {
    dispatch(validate(FORM_PASSENGER_DETAILS_AFTERSALES))
  }
}
