import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import classNames from 'classnames'
import Icon from '../../../elements/icon/icon'
import TextLabel from '../../../elements/text-label/text-label'
import {
  MIN_8_CHARACTERS,
  ONE_SYMBOL,
  ONE_DIGIT,
  ONE_UPPERCASE,
  getStrengthStatus, PASSWORD_IS_EQUAL
} from 's3p-js-lib/src/misc/password-strength'

export default class PasswordStrengthIndicator extends Component {
  static propTypes = {
    password: PropTypes.string,
    passwordRepeated: PropTypes.string
  }

  _renderItem (valid, text) {
    return (
      <TextLabel
        className={classNames({'state--is-valid': valid})}
        name='password-requirement'
      >
        <Icon name='tick' className='small align-left' />
        <TextLabel.Text text={text} />
      </TextLabel>

    )
  }

  render () {
    const result = getStrengthStatus(this.props.password, this.props.passwordRepeated)
    return (
      <div className='password-requirements-list'>
        <ul>
          <li>
            <TextLabel
              name='password-requirement-description'
              text={_t.message('change-password.form.password-strength-indicator.requirements')}
            />
          </li>
          <li>
            {this._renderItem(
              !result.includes(MIN_8_CHARACTERS),
              _t.message('change-password.form.password-strength-indicator.min-8-characters')
            )}
          </li>
          <li>
            {this._renderItem(
              !result.includes(ONE_DIGIT),
              _t.message('change-password.form.password-strength-indicator.one-digit')
            )}
          </li>
          <li>
            {this._renderItem(
              !result.includes(ONE_UPPERCASE),
              _t.message('change-password.form.password-strength-indicator.one-uppercase')
            )}
          </li>
          <li>
            {this._renderItem(
              !result.includes(ONE_SYMBOL),
              _t.message('change-password.form.password-strength-indicator.one-symbol')
            )}
          </li>
          <li>
            {this._renderItem(
              !result.includes(PASSWORD_IS_EQUAL),
              _t.message('change-password.form.password-strength-indicator.password-is-equal')
            )}
          </li>
        </ul>
      </div>
    )
  }
}
