import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import VoucherToCash from '../../../components/aftersales/voucher-to-cash/voucher-to-cash'
import { voucherListSelector, hasResultsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/voucher-list'
import { elementVisibleSelector } from '../../../redux/selectors/containers/base/visible-element'
import { VOUCHER_TO_CASH_MODAL, CONTAINERS_AFTERSALES_VOUCHER_TO_CASH } from '../../../constants'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'

class VoucherToCashContainer extends Component {
  static propTypes = {
    clearState: PropTypes.func.isRequired
  }

  componentWillUnmount () {
    this.props.clearState(CONTAINERS_AFTERSALES_VOUCHER_TO_CASH, ['api.booking.voucherList'])
  }

  render () {
    return <VoucherToCash {...this.props} />
  }
}

const showVoucherToCashModal = elementVisibleSelector(VOUCHER_TO_CASH_MODAL)

const mapStateToProps = state => ({
  vouchers: voucherListSelector(state),
  hasResults: hasResultsSelector(state),
  showModal: showVoucherToCashModal(state)
})

const mapDispatchToProps = {
  clearState
}

export default connect(mapStateToProps, mapDispatchToProps)(VoucherToCashContainer)
