import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Panel from '../../elements/panel/panel'
import Feedback, { FEEDBACK_ALERT } from '../../elements/feedback/feedback'
import ComponentContainer from '../../elements/component-container/component-container'
import ProgressNavigation from '../../elements/progress-navigation/progress-navigation'
import './error.scss'

export default class ErrorBookingConfirmation extends Component {
  static propTypes = {
    bookingNumber: PropTypes.string.isRequired,
    handleGoToAftersales: PropTypes.func.isRequired
  }

  render () {
    return (
      <ComponentContainer name='application-error'>
        <Panel
          header={<Panel.Header
            name='application-error'
            title={_t.message('application-error.header')}
            description={_t.message('application-error.description')}
          />}
        >
          <Feedback status={FEEDBACK_ALERT} text={_t.message(
            'booking-confirmation-error.message',
            {bookingNumber: this.props.bookingNumber}
          )} />
          <ProgressNavigation
            previous={<ProgressNavigation.PreviousButton
              text={_t.message('booking-confirmation-error.go-back-button')}
              onClick={this.props.handleGoToAftersales}
            />}
          />
        </Panel>
      </ComponentContainer>
    )
  }
}
