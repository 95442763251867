import { selectRebookingBundle as baseRebookSelectBundle } from 's3p-js-lib/src/redux/actions/api/orientation/select-bundle'
import { routeSelector } from '../../../../selectors/containers/base/journey-search/journey-result-set'

export const handleRebookingSelectBundle = bundle => async (dispatch, getState) => {
  const route = routeSelector(bundle.routeId)(getState())

  await dispatch(baseRebookSelectBundle(
    route.journeySearchId,
    route.travelId,
    bundle.routeId,
    bundle.id
  ))
}
