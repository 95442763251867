import { createSelector } from 'reselect'
import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'
import { camelCaseKeys } from '../../../../misc/utils'
import countries from 'countries-list'

export const customerSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking && booking.customer
    ? omitBy(
        camelCaseKeys({
          ...booking.customer,
          ...booking.customer.address,
          fullName: booking.customer.first_name && booking.customer.last_name
            ? `${booking.customer.first_name} ${booking.customer.last_name}`
            : null,
          address: undefined,
          emailAddress: booking.customer.email,
          country: booking.customer.country_code &&
          countries[booking.customer.country_code] &&
          countries[booking.customer.country_code].name
        }),
        isEmpty
      ) : null
)
