import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import OrientationSummary from '../../components/reservations/orientation-summary'

class OrientationSummaryContainer extends Component {
  static propTypes = {
    hasOffer: PropTypes.bool.isRequired
  }

  render () {
    const {hasOffer, ...props} = this.props

    return hasOffer ? <OrientationSummary {...props} /> : null
  }
}

const mapStateToProps = state => ({
  hasOffer: Boolean(state.api.orientation.offer)
})

export default connect(mapStateToProps)(OrientationSummaryContainer)
