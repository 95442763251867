import { UI_TABLES_SORT, UI_TABLES_CLEAR } from 's3p-js-lib/src/redux/actions/types'
import { tableSortSelector } from '../../../selectors/containers/base/table'
import { UI_TABLE_SORT_ASCENDING, UI_TABLE_SORT_DESCENDING } from 's3p-js-lib/src/constants'

export const sortColumn = (tableName, column, order) => ({type: UI_TABLES_SORT, tableName, column, order})

export const toggleSortColumn = (tableName, column) => {
  const _tableSortSelector = tableSortSelector(tableName)

  return () => (dispatch, getState) => {
    const sort = _tableSortSelector(getState())

    dispatch(sortColumn(
      tableName,
      column,
      sort.column === column && sort.order === UI_TABLE_SORT_ASCENDING ? UI_TABLE_SORT_DESCENDING : UI_TABLE_SORT_ASCENDING
    ))
  }
}

export const clearTable = tableName => () => ({type: UI_TABLES_CLEAR, tableName})
