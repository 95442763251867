import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import PropTypes from '../../../misc/prop-types'
import Panel from '../../../elements/panel/panel'
import Button from '../../../elements/button/button'
import TextLabel from '../../../elements/text-label/text-label'
import Feedback, { FEEDBACK_WARNING } from '../../../elements/feedback/feedback'

export default class ResumeShift extends Component {
  static propTypes = {
    onEndShift: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    onResumeShift: PropTypes.func.isRequired,
    startDateTime: PropTypes.moment,
    loading: PropTypes.bool,
    willPasswordExpireSoon: PropTypes.object
  }

  render () {
    return (
      <Panel
        header={
          <Panel.Header
            name='resume-shift'
            title={_t.message('agent.shift.resume.title')}
            description={_t.message('agent.shift.resume.description')} />
        }
      >
        {this.props.willPasswordExpireSoon.willExpireSoon
          ? <Feedback status={FEEDBACK_WARNING} text={_t.message('agent.shift.password-will-expire-soon', {days: this.props.willPasswordExpireSoon.expiresInDays})} />
          : null
        }
        {this.props.startDateTime
          ? (
            <TextLabel name='information' text={_t.message(
              'agent.shift.resume.information',
              {
                date: _t.formatMomentDate(this.props.startDateTime, 'dayOfTheWeekWithDate'),
                time: _t.formatMomentTime(this.props.startDateTime, 'short')
              }
            )}
            />
          ) : null}
        <div className='button-group'>
          <Button
            type='button'
            className='tertiary'
            name='logout'
            onClick={this.props.onLogout}
            disabled={this.props.loading}
            hotkey='logout'
          >
            <TextLabel text={_t.message('agent.shift.resume.button-logout')} />
          </Button>
          <Button
            type='button'
            className='secondary'
            name='end-shift'
            onClick={this.props.onEndShift}
            disabled={this.props.loading}
            hotkey='focusEuro'
          >
            <TextLabel text={_t.message('agent.shift.resume.button-end')} />
          </Button>
          <Button
            type='button'
            className='primary'
            name='resume-shift'
            onClick={this.props.onResumeShift}
            loading={this.props.loading}
            autoFocus
            hotkey='refresh'
          >
            <TextLabel text={_t.message('agent.shift.resume.button-resume')} />
          </Button>
        </div>
      </Panel>
    )
  }
}
