import {
  API_SELECT_BUNDLE_REQUEST,
  API_SELECT_BUNDLE_SUCCESS,
  API_SELECT_BUNDLE_FAILURE
} from '../../types'
import {createV1SelectBundleResponseSelector} from 's3p-js-lib/src/redux/actions/api/orientation/select-bundle-mapper'

const selectBundleRequest = bundle => ({type: API_SELECT_BUNDLE_REQUEST, bundle})
const selectBundleSuccess = response => ({type: API_SELECT_BUNDLE_SUCCESS, response})
const selectBundleFailure = error => ({type: API_SELECT_BUNDLE_FAILURE, error})

const _selectBundle = (journeyId, travelId, routeId, bundleId) => (dispatch, getState) => {
  try {
    dispatch(selectBundleRequest({
      journeyId: journeyId,
      travelId: travelId,
      routeId: routeId,
      bundleId: bundleId
    }))
    dispatch(selectBundleSuccess(createV1SelectBundleResponseSelector(journeyId, travelId, routeId, bundleId)(getState())))
  } catch (error) {
    dispatch(selectBundleFailure(error))
    throw error
  }
}

export const selectBundle = (journeyId, travelId, routeId, bundleId) => {
  return _selectBundle(journeyId, travelId, routeId, bundleId)
}
export const selectRebookingBundle = (journeyId, travelId, routeId, bundleId) => {
  return _selectBundle(journeyId, travelId, routeId, bundleId)
}
