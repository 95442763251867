import {
  DISABILITY_TYPE_NONE,
  CURRENCY_EURO,
  FULFILLMENT_METHOD_CODE_BARCODE,
  VOUCHER_TO_CASH_MODAL,
  DOCUMENT_TEMPLATE_NAME_VOUCHER_TO_CASH_CUSTOMER_RECEIPT,
  DOCUMENT_TEMPLATE_NAME_VOUCHER_TO_CASH_MERCHANT_RECEIPT
} from '../../../../../constants'
import {
  UI_PAYOUT_VOUCHER_REQUEST,
  UI_PAYOUT_VOUCHER_SUCCESS,
  UI_SELECT_VOUCHER
} from '../../../types'
import _t from 's3p-js-lib/src/translate'
import { PASSENGER_TYPE_ADULT, PAYMENT_STATUS_S } from 's3p-js-lib/src/constants'
import { confirmBooking } from 's3p-js-lib/src/redux/actions/api/v2/confirm-booking'
import { createBooking, updateFulfillmentMethod } from 's3p-js-lib/src/redux/actions/api/v2/booking'
import { addPayments } from 's3p-js-lib/src/redux/actions/api/v2/payment/add-payments'
import { bookingNumberSelector, bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { addVoucher } from 's3p-js-lib/src/redux/actions/api/payment/vouchers'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import { openMachineCashDrawer } from '../../../machine/cash-drawer'
import { showModal } from '../../base/visible-element'
import moment from 'moment'
import { sendMachineReceiptPrint } from '../../../machine/receipt-printer'
import { setPrintReceiptError } from '../../base/tiles/payment-methods'
import { completedBookingSelector } from '../../../../../../s3p-js-lib/src/redux/selectors/api/booking/booking'
import localStorage from 's3p-js-lib/src/local-storage'
import { agentShiftSelector } from 's3p-js-lib/src/redux/selectors/api/user/agent/shift'
import { machineStationSelector } from '../../../../selectors/containers/base/stations'

const payoutVoucherRequest = code => ({type: UI_PAYOUT_VOUCHER_REQUEST, code})
const payoutVoucherSuccess = response => ({type: UI_PAYOUT_VOUCHER_SUCCESS, response})

const clearStates = () => clearState(['api.booking.provisionalBooking', 'api.booking.voucherList'])

const _bookingNumberSelector = bookingNumberSelector(bookingSelector)

const SEGMENT_ID = 'segment_1'
const PASSENGER_ID = 'passenger_1'

const selectVoucher = voucher => ({type: UI_SELECT_VOUCHER, voucher})

export const refundVoucher = voucher => [
  selectVoucher(voucher),
  openMachineCashDrawer(),
  showModal(VOUCHER_TO_CASH_MODAL)
]

export const payOutVoucher = (voucher, tariffCode, paymentMethod) => async (dispatch, getState) => {
  dispatch(payoutVoucherRequest(voucher.code))

  const resultCreateBooking = await dispatch(createBooking(getSegmentValues(), productValues(tariffCode), passengerValues))
  if (!resultCreateBooking) {
    dispatch(clearStates())
    return
  }

  const bookingNumber = _bookingNumberSelector(getState())

  const resultAddPayment = await dispatch(addPayments(addPaymentValues(voucher, paymentMethod), bookingNumber))
  if (!resultAddPayment) {
    dispatch(clearStates())
    return
  }

  const resultAddVoucher = await dispatch(addVoucher(voucher.code))
  if (!resultAddVoucher) {
    dispatch(clearStates())
    return
  }

  const state = getState()
  if (!state.containers.base.paymentModal.voucherForm.success) {
    dispatch(clearStates())
  } else {
    const resultUpdateFulfillmentMethod = await dispatch(updateFulfillmentMethod(bookingNumber, FULFILLMENT_METHOD_CODE_BARCODE))
    if (!resultUpdateFulfillmentMethod) {
      dispatch(clearStates())
      return
    }

    const resultConfirmBooking = await dispatch(confirmBooking())
    if (!resultConfirmBooking) {
      dispatch(clearStates())
      return
    }
    dispatch(payoutVoucherSuccess(voucher.code))
    dispatch(printReceipt())
    dispatch(clearState('api.booking.voucherList'))
  }
}

const getSegmentValues = () => ([{
  departureDate: moment().format('YYYY-MM-DD'),
  id: SEGMENT_ID
}])

const productValues = tarrifCode => [{
  passengerId: PASSENGER_ID,
  tariffCode: tarrifCode
}]

const passengerValues = [{
  disabilityType: DISABILITY_TYPE_NONE,
  discountCards: [],
  id: PASSENGER_ID,
  type: PASSENGER_TYPE_ADULT
}]

const addPaymentValues = (voucher, paymentMethod) => [{
  amount: ((voucher.amount - voucher.amountUsed) * -1),
  currency: CURRENCY_EURO,
  method: paymentMethod,
  status: PAYMENT_STATUS_S
}]

export const printReceipt = () => async (dispatch, getState) => {
  const state = getState()
  const bookingNumber = completedBookingSelector(state).booking_number
  const selectedVoucher = state.containers.aftersales.voucherToCash.selected
  const shift = agentShiftSelector(getState())
  const deviceId = getState().machine.terminalInformation.data.terminalId || ''
  try {
    await dispatch(sendMachineReceiptPrint(createReceipt(
      bookingNumber,
      selectedVoucher,
      DOCUMENT_TEMPLATE_NAME_VOUCHER_TO_CASH_CUSTOMER_RECEIPT,
      machineStationSelector(state),
      deviceId,
      shift.shiftId
    )))
    return dispatch(sendMachineReceiptPrint(createReceipt(
      bookingNumber,
      selectedVoucher,
      DOCUMENT_TEMPLATE_NAME_VOUCHER_TO_CASH_MERCHANT_RECEIPT,
      machineStationSelector(state),
      deviceId,
      shift.shiftId
    )))
  } catch (error) {
    dispatch(setPrintReceiptError(_t.message('finalize-booking-modal.receipt.default-error')))
    return false
  }
}

const createReceipt = (bookingNumber, selectedVoucher, templateName, station, deviceId, shiftId) => {
  let template = localStorage.get(templateName)
  const price = selectedVoucher.amount
    ? selectedVoucher.amountUsed
        ? selectedVoucher.amount - selectedVoucher.amountUsed
        : selectedVoucher.amount
    : null
  template = template.replace(/{{price}}/g, price ? _t.formatCurrency(price) : '')
  template = template.replace(/{{voucher_code}}/g, selectedVoucher.code || '')
  template = template.replace(/{{booking_number}}/g, bookingNumber || '')
  template = template.replace(/{{current_date}}/g, moment().format('L'))
  template = template.replace(/{{current_time}}/g, moment().format('LTS'))
  template = template.replace(/{{station_name}}/g, station ? station.name : '')
  template = template.replace(/{{device_id}}/g, deviceId || '')
  template = template.replace(/{{shift_id}}/g, shiftId || '')

  return JSON.parse(template)
}
