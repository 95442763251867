import {
  API_AGENT_CHANGE_PASSWORD_REQUEST,
  API_AGENT_CHANGE_PASSWORD_SUCCESS,
  API_AGENT_CHANGE_PASSWORD_FAILURE,
  CLEAR_STATE
} from 's3p-js-lib/src/redux/actions/types'
import ResponseCodes from 's3p-js-lib/src/api/response-codes'
import _t from 's3p-js-lib/src/translate'
import { CONTAINERS_USER_AGENT_CHANGE_PASSWORD } from '../../../../constants'

const initialState = {
  isLoading: false,
  success: false,
  serviceError: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case API_AGENT_CHANGE_PASSWORD_REQUEST:
      return {
        isLoading: true
      }
    case API_AGENT_CHANGE_PASSWORD_SUCCESS:
      return {
        success: true,
        isLoading: false
      }
    case API_AGENT_CHANGE_PASSWORD_FAILURE:
      return {
        isLoading: false,
        serviceError: ResponseCodes.hasMessage(action.error.errorCode)
          ? ResponseCodes.translate(action.error.errorCode)
          : _t.getIntlMessage('agent-login.error')
      }
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_USER_AGENT_CHANGE_PASSWORD) ? initialState : state
  }

  return state
}
