import { useRouterHistory } from 'react-router'
import createBrowserHistory from 'history/lib/createBrowserHistory'
import qs from 'qs'

export default () => {
  const createAppHistory = useRouterHistory(createBrowserHistory)
  const history = createAppHistory({
    parseQueryString: qs.parse,
    stringifyQuery: query => qs.stringify(query, {encode: false, skipNulls: true})
  })

  window.reacthistory = history

  return history
}
