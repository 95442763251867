import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _t from 's3p-js-lib/src/translate'
import PrintRefundVoucherModal from '../../../../../components/aftersales/booking/payment-details/refund/print-refund-voucher-modal'
import { isOfflineSelectorCreator } from '../../../../../redux/selectors/machine/offline'
import {
  DEVICE_ID_RECEIPT_PRINTER,
  LAST_MACHINE_ERROR_NAMESPACE_RECEIPT_PRINTER,
  PRINT_VOUCHER_DEFAULT_ERROR_MESSAGE,
  REFUND_VOUCHER_MODAL
} from '../../../../../constants'
import { FEEDBACK_ALERT, FEEDBACK_SUCCESS } from '../../../../../elements/feedback/feedback'
import { lastMachineErrorsSelector } from '../../../../../redux/selectors/machine/last-machine-errors'
import { hideElement } from '../../../../../redux/actions/containers/base/visible-element'
import { printRefundVoucher } from '../../../../../redux/actions/containers/aftersales/booking/refund'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'

const ERROR_MESSAGES_MAP = {
  [PRINT_VOUCHER_DEFAULT_ERROR_MESSAGE]: 'aftersales.booking.related-vouchers.refund-voucher-modal.default-error'
}

class PrintRefundVoucherModalContainer extends Component {
  static propTypes = {
    isPrinting: PropTypes.bool,
    receiptPrinterMachineErrors: PropTypes.array,
    retrieveAndPrintCycle: PropTypes.bool,
    receiptPrinterErrorMessage: PropTypes.object,
    isOfflineReceiptPrinter: PropTypes.bool,
    printRefundVoucher: PropTypes.func.isRequired,
    ticketErrorMessage: PropTypes.string
  }

  UNSAFE_componentWillMount () {
    if (!this.props.isOfflineReceiptPrinter) {
      this.props.printRefundVoucher()
    }
  }

  render () {
    let messages = this.props.isPrinting || this.props.retrieveAndPrintCycle
      ? []
      : this.props.ticketErrorMessage
        ? [{
          status: FEEDBACK_ALERT,
          text: [_t.message(ERROR_MESSAGES_MAP[this.props.ticketErrorMessage])]
        }]
        : this.props.receiptPrinterErrorMessage
          ? [{
            status: FEEDBACK_ALERT,
            text: [_t.message('aftersales.booking.related-vouchers.refund-voucher-modal.default-error')]
          }]
          : this.props.receiptPrinterMachineErrors.length
            ? []
            : [{
              status: FEEDBACK_SUCCESS,
              text: [_t.message('aftersales.booking.related-vouchers.refund-voucher-modal.success')]
            }]

    return (
      <PrintRefundVoucherModal
        {...this.props}
        messages={messages}
      />
    )
  }
}

const _lastMachineErrorsSelector = lastMachineErrorsSelector([LAST_MACHINE_ERROR_NAMESPACE_RECEIPT_PRINTER])
const receiptPrinterOfflineSelector = isOfflineSelectorCreator(DEVICE_ID_RECEIPT_PRINTER)

const mapStateToProps = state => ({
  isPrinting: state.containers.aftersales.booking.printRefundVoucherModal.isPrinting,
  receiptPrinterErrorMessage: state.machine.receiptPrinter.error,
  retrieveAndPrintCycle: state.containers.aftersales.booking.printRefundVoucherModal.retrievingAndPrintingTickets,
  receiptPrinterMachineErrors: _lastMachineErrorsSelector(state),
  hasPrintErrors: Boolean(_lastMachineErrorsSelector(state).length || receiptPrinterOfflineSelector(state)),
  ticketErrorMessage: state.containers.aftersales.booking.printRefundVoucherModal.ticketErrorMessage,
  isOfflineReceiptPrinter: receiptPrinterOfflineSelector(state)
})

const mapDispatchToProps = {
  onHide: () => [hideElement(REFUND_VOUCHER_MODAL), clearState(REFUND_VOUCHER_MODAL)],
  printRefundVoucher,
  onRetry: () => printRefundVoucher()
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintRefundVoucherModalContainer)
