import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { hideElement } from '../../../../redux/actions/containers/base/visible-element'
import { processVoidTickets } from '../../../../redux/actions/containers/aftersales/void-tickets'
import {
  VOID_TICKETS_MODAL,
  CONTAINERS_AFTERSALES_VOID_TICKETS
} from '../../../../constants'
import VoidTicketsModal from '../../../../components/aftersales/booking/fulfillment/void-tickets-modal'
import { reprintResendBooking } from '../../../../redux/actions/containers/aftersales/booking/reprint-resend'
import { overviewBookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { canOverrideValidationRulesFullOrIssueSelector } from '../../../../redux/selectors/api/auth/auth'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'

const _canOverrideValidationRulesFullOrIssueSelector = canOverrideValidationRulesFullOrIssueSelector(overviewBookingSelector)

class VoidTicketsModalContainer extends Component {
  static propTypes = {
    clearState: PropTypes.func.isRequired
  }

  componentWillUnmount () {
    this.props.clearState()
  }

  render () {
    return <VoidTicketsModal {...this.props} />
  }
}

const mapStateToProps = state => ({
  ...state.containers.aftersales.voidTickets,
  isLoadingRead: state.machine.loading.readMagneticTicket,
  isLoadingVoid: state.machine.loading.voidMagneticTicket || state.api.loading.ticketVoidValidation,
  isLoadingEject: state.machine.loading.ejectMagneticTicket,
  isLoadingReprint: state.containers.aftersales.booking.reprintResend.loading,
  hasResendError: Boolean(state.api.base.lastApiErrors.resendTickets),
  disableNext: !_canOverrideValidationRulesFullOrIssueSelector(state) && !state.containers.aftersales.voidTickets.success
})

const mapDispatchToProps = {
  reprintResendBooking,
  processVoidTickets,
  onHide: () => hideElement(VOID_TICKETS_MODAL),
  clearState: () => clearState(CONTAINERS_AFTERSALES_VOID_TICKETS)
}

export default connect(mapStateToProps, mapDispatchToProps)(VoidTicketsModalContainer)
