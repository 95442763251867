import {
  API_GET_ADDITIONAL_PRODUCTS_REQUEST,
  API_GET_ADDITIONAL_PRODUCTS_SUCCESS,
  API_PROCESS_DEEP_LINK_FAILURE,
  API_JOURNEY_SEARCH_REQUEST,
  API_REBOOKING_JOURNEY_SEARCH_REQUEST,
  API_REBOOKING_INITIATE_REQUEST,
  API_CANCELLATION_INITIATE_REQUEST,
  API_CHANGE_SEATS_INITIATE_REQUEST,
  API_UPDATE_PASSENGERS_INITIATE_REQUEST,
  API_ADDITIONAL_PRODUCTS_INITIATE_REQUEST,
  API_AFTERSALES_CONFIRM_REQUEST,
  API_CREATE_PROVISIONAL_BOOKING_FAILURE,
  API_OAUTH_LOGOUT,
  API_SELECT_BUNDLE_REQUEST,
  CLEAR_STATE
} from '../../../actions/types'

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_GET_ADDITIONAL_PRODUCTS_REQUEST:
    case API_JOURNEY_SEARCH_REQUEST:
    case API_REBOOKING_JOURNEY_SEARCH_REQUEST:
    case API_PROCESS_DEEP_LINK_FAILURE:
    case API_REBOOKING_INITIATE_REQUEST:
    case API_CANCELLATION_INITIATE_REQUEST:
    case API_CHANGE_SEATS_INITIATE_REQUEST:
    case API_UPDATE_PASSENGERS_INITIATE_REQUEST:
    case API_ADDITIONAL_PRODUCTS_INITIATE_REQUEST:
    case API_AFTERSALES_CONFIRM_REQUEST:
    case API_CREATE_PROVISIONAL_BOOKING_FAILURE:
    case API_OAUTH_LOGOUT:
    case API_SELECT_BUNDLE_REQUEST:
      return initialState
    case API_GET_ADDITIONAL_PRODUCTS_SUCCESS:
      return action.response
    case CLEAR_STATE:
      return action.namespaces.includes('api.orientation.additionalProducts') ? initialState : state
    default:
      return state
  }
}
