import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { seatSelector } from 's3p-js-lib/src/redux/selectors/user-input/base/seat-selector'
import { selectSeat } from '../../../../redux/actions/user-input/base/seat-selector'
import { selectNextPassenger } from '../../../../redux/actions/containers/base/seat-selector/seat-selector'
import Seat from '../../../../components/base/seat-selector/carriage-floorplan/seat'

class SeatContainer extends Component {
  static propTypes = {
    aftersales: PropTypes.bool,
    selectSeat: PropTypes.func.isRequired,
    selectNextPassenger: PropTypes.func.isRequired,
    seatNumber: PropTypes.string.isRequired
  }

  constructor (...args) {
    super(...args)
    this.handleSelect = this.handleSelect.bind(this)
  }

  handleSelect () {
    this.props.selectSeat(this.props.seatNumber)
    this.props.selectNextPassenger(this.props.aftersales)
  }

  render () {
    return <Seat {...this.props} handleSelect={this.handleSelect} />
  }
}

const mapStateToProps = () => {
  const _seatSelector = seatSelector()

  return (state, ownProps) => _seatSelector(state, ownProps) || {}
}

const mapDispatchToProps = {
  selectSeat,
  selectNextPassenger
}

export default connect(mapStateToProps, mapDispatchToProps)(SeatContainer)
