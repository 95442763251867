import React, { Component } from 'react'
import GenericLayout from '../generic-layout'
import MainMenu from '../../containers/base/main-menu/main-menu'
import GridLayout from '../../elements/grid/grid-layout'
import GridColumn from '../../elements/grid/grid-column'
import ReadTicket from '../../containers/aftersales/read-ticket/read-ticket'
import ReadTicketOutput from '../../containers/aftersales/read-ticket/read-ticket-output'
import StickyFooter from '../../elements/sticky-footer/sticky-footer'
import Taskbar from '../../containers/base/taskbar/taskbar'

export default class BookingLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <MainMenu />
            <GridLayout name='left-content'>
              <GridColumn name='left'>
                <ReadTicket />
              </GridColumn>
              <GridColumn name='right'>
                <ReadTicketOutput />
              </GridColumn>
            </GridLayout>
            <StickyFooter>
              <Taskbar />
            </StickyFooter>
          </GridColumn>
        </GridLayout>
      </GenericLayout>
    )
  }
}
