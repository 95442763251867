import React, { Component } from 'react'
import ComponentContainer from '../component-container/component-container'
import './progress-bar.scss'

export default class ProgressBar extends Component {
  render () {
    return (
      <ComponentContainer name='progress-bar'>
        <nav>
          <ol>
            {this.props.children}
          </ol>
        </nav>
      </ComponentContainer>
    )
  }
}
