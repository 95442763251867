import { initialState } from 's3p-js-lib/src/redux/reducers/api/v2/search'
import { USER_INPUT_FORM_CHANGE_FIELD } from 's3p-js-lib/src/redux/actions/types'
import { FORM_PRODUCT_SEARCH } from 's3p-js-lib/src/constants-form'
import { FORM_EDIT_SHORTCUT } from '../../../../constants'

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_INPUT_FORM_CHANGE_FIELD:
      return action.formName === FORM_PRODUCT_SEARCH || (
        action.formName === FORM_EDIT_SHORTCUT &&
        action.fieldName !== 'product' &&
        action.fieldName !== 'productFilter'
      ) ? initialState : state
    default:
      return state
  }
}
