import {
  API_GET_LEAP_CONFIGURATION_DATA_REQUEST,
  API_GET_LEAP_CONFIGURATION_DATA_SUCCESS,
  API_GET_LEAP_CONFIGURATION_DATA_FAILURE,
  API_GET_LEAP_CONFIGURATION_DATA_SET_REQUEST,
  API_GET_LEAP_CONFIGURATION_DATA_SET_SUCCESS,
  API_GET_LEAP_CONFIGURATION_DATA_SET_FAILURE,
  API_CONFIRM_LEAP_CONFIGURATION_DATA_SET_REQUEST,
  API_CONFIRM_LEAP_CONFIGURATION_DATA_SET_SUCCESS,
  API_CONFIRM_LEAP_CONFIGURATION_DATA_SET_FAILURE,
  API_GET_LEAP_SCHEME_WIDE_CODE_REQUEST,
  API_GET_LEAP_SCHEME_WIDE_CODE_SUCCESS,
  API_GET_LEAP_SCHEME_WIDE_CODE_FAILURE,
  API_GET_LEAP_ACTIONS_REQUEST,
  API_GET_LEAP_ACTIONS_SUCCESS,
  API_GET_LEAP_ACTIONS_FAILURE,
  API_CONFIRM_LEAP_ACTION_REQUEST,
  API_CONFIRM_LEAP_ACTION_SUCCESS,
  API_CONFIRM_LEAP_ACTION_FAILURE
} from '../../types'
import Client from '../../../../api/client'
import ensureToken from 's3p-js-lib/src/api/ensure-token'

const getLeapConfigurationDataRequest = () => ({type: API_GET_LEAP_CONFIGURATION_DATA_REQUEST})
const getLeapConfigurationDataSuccess = response => ({type: API_GET_LEAP_CONFIGURATION_DATA_SUCCESS, response})
const getLeapConfigurationDataFailure = error => ({type: API_GET_LEAP_CONFIGURATION_DATA_FAILURE, error})

const getLeapConfigurationDataSetRequest = (deviceId, scimId, samId) => ({
  type: API_GET_LEAP_CONFIGURATION_DATA_SET_REQUEST,
  deviceId,
  scimId,
  samId
})
const getLeapConfigurationDataSetSuccess = response => ({type: API_GET_LEAP_CONFIGURATION_DATA_SET_SUCCESS, response})
const getLeapConfigurationDataSetFailure = error => ({type: API_GET_LEAP_CONFIGURATION_DATA_SET_FAILURE, error})

const confirmLeapConfigurationDataSetRequest = (deviceId, scimId, samId, data) => ({
  type: API_CONFIRM_LEAP_CONFIGURATION_DATA_SET_REQUEST,
  deviceId,
  scimId,
  samId,
  data
})
const confirmLeapConfigurationDataSetSuccess = response => ({
  type: API_CONFIRM_LEAP_CONFIGURATION_DATA_SET_SUCCESS,
  response
})
const confirmLeapConfigurationDataSetFailure = error => ({type: API_CONFIRM_LEAP_CONFIGURATION_DATA_SET_FAILURE, error})

const leapSchemeWideCodeRequest = () => ({type: API_GET_LEAP_SCHEME_WIDE_CODE_REQUEST})
const leapSchemeWideCodeSuccess = response => ({type: API_GET_LEAP_SCHEME_WIDE_CODE_SUCCESS, response})
const leapSchemeWideCodeFailure = error => ({type: API_GET_LEAP_SCHEME_WIDE_CODE_FAILURE, error})

const getLeapActionsRequest = (cardNumber, samId) => ({type: API_GET_LEAP_ACTIONS_REQUEST, cardNumber, samId})
const getLeapActionsSuccess = response => ({type: API_GET_LEAP_ACTIONS_SUCCESS, response})
const getLeapActionsFailure = error => ({type: API_GET_LEAP_ACTIONS_FAILURE, error})

const confirmLeapActionRequest = (cardNumber, actionId, response, status) => ({
  type: API_CONFIRM_LEAP_ACTION_REQUEST,
  cardNumber,
  actionId,
  response,
  status
})
const confirmLeapActionSuccess = response => ({type: API_CONFIRM_LEAP_ACTION_SUCCESS, response})
const confirmLeapActionFailure = error => ({type: API_CONFIRM_LEAP_ACTION_FAILURE, error})

export const getLeapConfigurationData = () => ensureToken(async (token, dispatch) => {
  try {
    dispatch(getLeapConfigurationDataRequest())
    const response = await Client.leapConfigurationData(token)
    dispatch(getLeapConfigurationDataSuccess(response))
  } catch (error) {
    dispatch(getLeapConfigurationDataFailure(error))
    throw error
  }
})

export const getLeapConfigurationDataSet = (deviceId, scimId, samId) => ensureToken(async (token, dispatch) => {
  try {
    dispatch(getLeapConfigurationDataSetRequest(deviceId, scimId, samId))
    const response = await Client.leapConfigurationDataSet(token, deviceId, scimId, samId)
    dispatch(getLeapConfigurationDataSetSuccess(response))
  } catch (error) {
    dispatch(getLeapConfigurationDataSetFailure(error))
    throw error
  }
})

export const confirmLeapConfigurationDataSet = (deviceId, scimId, samId, data) => ensureToken(async (token, dispatch) => {
  try {
    dispatch(confirmLeapConfigurationDataSetRequest(deviceId, scimId, samId, data))
    const response = await Client.leapConfigurationDataSetConfirm(token, deviceId, scimId, samId, data)
    dispatch(confirmLeapConfigurationDataSetSuccess(response))
  } catch (error) {
    dispatch(confirmLeapConfigurationDataSetFailure(error))
    throw error
  }
})

export const getLeapSchemeWideCode = () => ensureToken(async (token, dispatch) => {
  try {
    dispatch(leapSchemeWideCodeRequest())
    const response = await Client.leapSchemeWideCode(token)
    dispatch(leapSchemeWideCodeSuccess(response))
  } catch (error) {
    dispatch(leapSchemeWideCodeFailure(error))
    throw error
  }
})

export const getLeapActions = (cardNumber, samId) => ensureToken(async (token, dispatch) => {
  try {
    dispatch(getLeapActionsRequest(cardNumber, samId))
    const response = await Client.getLeapActionList(token, cardNumber, samId)
    dispatch(getLeapActionsSuccess(response))
  } catch (error) {
    dispatch(getLeapActionsFailure(error))
    return false
  }
})

export const confirmLeapAction = (cardNumber, actionId, topUpResponse, topUpStatus) => ensureToken(async (token, dispatch) => {
  try {
    dispatch(confirmLeapActionRequest(cardNumber, actionId, topUpResponse, topUpStatus))
    const response = await Client.confirmLeapAction(token, cardNumber, actionId, topUpResponse, topUpStatus)
    dispatch(confirmLeapActionSuccess(response))
  } catch (error) {
    dispatch(confirmLeapActionFailure(error))
    return false
  }
})
