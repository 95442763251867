import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { routerActions } from '../../../redux/actions/containers/base/routing'
import { hideElement } from '../../../redux/actions/containers/base/visible-element'
import { elementVisibleSelector, modalExtraPropsSelector } from '../../../redux/selectors/containers/base/visible-element'
import { START_SHIFT_ERROR_MODAL } from '../../../constants'
import StartShiftErrorModal from '../../../components/agent/shift/start-shift-error-modal'

class StartShiftErrorModalContainer extends Component {
  static propTypes = {
    showModal: PropTypes.bool
  }

  render () {
    return this.props.showModal ? <StartShiftErrorModal {...this.props} /> : null
  }
}

const showModalSelector = elementVisibleSelector(START_SHIFT_ERROR_MODAL)
const errorModalExtraPropsSelector = modalExtraPropsSelector(START_SHIFT_ERROR_MODAL)

const mapStateToProps = state => ({
  showModal: showModalSelector(state),
  ...errorModalExtraPropsSelector(state)
})

const mapDispatchToProps = {
  onLogout: () => [
    hideElement(START_SHIFT_ERROR_MODAL),
    routerActions.push('/agent/login')
  ]
}

export default connect(mapStateToProps, mapDispatchToProps)(StartShiftErrorModalContainer)
