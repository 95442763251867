import {createSelector} from 'reselect'

export const bookingFlowSelector = createSelector(
  [
    state => state.api.orientation.specification
      ? state.api.orientation.specification.seat_selection_available
      : null,
    state => state.api.orientation.specification
      ? state.api.orientation.specification.additional_products_available
      : null
  ],
  (seatSelectionAvailable, additionalProductsAvailable) => ({
    skipSeatSelection: !seatSelectionAvailable,
    skipAdditionalProducts: !additionalProductsAvailable
  })
)
