import { createSelector } from 'reselect'
import { selectedSeatsSelector } from './seat-selector'

export const seatSelectionAvailableOptionsSelector = seatSelectionOptionsSelector => createSelector(
  [seatSelectionOptionsSelector],
  seatSelectionOptions =>
    seatSelectionOptions && seatSelectionOptions.filter(option => option.available)
)

export const seatSelectionUnavailableOptionsSelector = seatSelectionOptionsSelector => createSelector(
  [seatSelectionOptionsSelector],
  seatSelectionOptions =>
    seatSelectionOptions && seatSelectionOptions.filter(option => !option.available)
)

export const hasSelectedSeatsSelector = seatSelectionOptionsSelector => createSelector(
  [
    seatSelectionOptionsSelector,
    selectedSeatsSelector
  ],
  (seatSelectionOptions, selectedSeats) => Boolean(
    selectedSeats.length > 0 || (seatSelectionOptions && seatSelectionOptions.some(option => option.hasSelectedSeats))
  )
)
