import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Button from '../../../../elements/button/button'
import Icon from '../../../../elements/icon/icon'
import OriginDestinationBlock from '../../../../elements/origin-destination-block/origin-destination-block'
import SegmentStatus from './segment-status'
import TextLabel from '../../../../elements/text-label/text-label'

export default class Segment extends Component {
  static propTypes = {
    onSelectSegment: PropTypes.func.isRequired,
    option: PropTypes.shape({
      selectedSeats: PropTypes.array.isRequired,
      hasSelectedSeats: PropTypes.bool.isRequired,
      status: PropTypes.string.isRequired,
      updated: PropTypes.bool,
      leg: PropTypes.shape({
        id: PropTypes.string
      })
    }).isRequired
  }

  constructor (...args) {
    super(...args)

    this.onSelectSegment = this.onSelectSegment.bind(this)
  }

  componentDidMount () {
    const element = document.getElementById('button-change-seats')
    element && setTimeout(() => element.focus(), 200)
  }

  onSelectSegment (event) {
    event.preventDefault()
    this.props.onSelectSegment(this.props.option.leg.id)
  }

  render () {
    const {option} = this.props
    return (
      <OriginDestinationBlock
        originName={option.leg.departureStation.name}
        destinationName={option.leg.arrivalStation.name}
        departureMoment={option.leg.departureStation.departureTime}
        arrivalMoment={option.leg.arrivalStation.arrivalTime}
      >
        <div className='segment-seat-selection'>
          <div className='segment-seat-selection__status'>
            <SegmentStatus selectedSeats={option.selectedSeats} status={option.status} updated={option.updated} />
          </div>
          {option.available ? (
            <div className='segment-seat-selection__actions'>
              <Button id='button-change-seats' name='change-seats' className='secondary' onClick={this.onSelectSegment}>
                <TextLabel name='swap-origin-destination' text={_t.message(option.selectedSeats
                  ? 'allocated-seats.leg.change'
                  : 'allocated-seats.leg.select'
                )} />
                <Icon name='chevron-right' />
              </Button>
            </div>
          ) : null}
        </div>
      </OriginDestinationBlock>
    )
  }
}
