import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Modal from '../../elements/modal/modal'

export const MAX_PASSENGER_MODAL = 'max-passengers'

export default class MaxPassengersModal extends Component {
  static propTypes = {
    maxPassengers: PropTypes.number.isRequired
  }

  render () {
    return (
      <Modal
        name={MAX_PASSENGER_MODAL}
        header={<Modal.Header
          title={_t.message(`product-search.max-passengers-warning.title`)}
        />}
      >
        <p>{_t.message(`product-search.max-passengers-warning.information`, this.props)}</p>
      </Modal>
    )
  }
}
