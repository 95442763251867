import {
  API_UPDATE_SELECT_BUNDLE_REQUEST,
  API_UPDATE_SELECT_BUNDLE_SUCCESS,
  API_UPDATE_SELECT_BUNDLE_FAILURE
} from '../../types'
import ResponseCodes from '../../../../api/response-codes'
import {createV1SelectBundleResponseSelector} from 's3p-js-lib/src/redux/actions/api/orientation/update-select-bundle-mapper'

const updateSelectBundleRequest = (journeyId, bundle, additionalProducts, selectedSeats, seatPreferences) => ({
  type: API_UPDATE_SELECT_BUNDLE_REQUEST,
  journeyId,
  bundle,
  additionalProducts,
  selectedSeats,
  seatPreferences
})
const updateSelectBundleSuccess = response => ({type: API_UPDATE_SELECT_BUNDLE_SUCCESS, response})
const updateSelectBundleFailure = error => ({type: API_UPDATE_SELECT_BUNDLE_FAILURE, error})

export const updateSelectBundleWithSeatSelection = (legId, selectedSeats) => (dispatch, getState) => {
  const state = getState()
  try {
    const journeyId = getJourneyId(state)
    const route = getRouteByLegId(state, legId)
    const bundle = getSelectedBundleByRoute(state, route)
    const additionalProducts = getAdditionalProducts(state)
    const allSelectedSeats = selectedSeats
      ? updateSelectedSeats(getSelectedSeats(state, route), selectedSeats, legId) : []
    const seatPreferences = getSeatPreferences(state, route)

    dispatch(
      updateSelectBundleSuccess(
        createV1SelectBundleResponseSelector(
          journeyId,
          bundle.travel_id,
          bundle.route_id,
          bundle.id,
          allSelectedSeats,
          seatPreferences,
          additionalProducts
        )(getState())
      )
    )
    return true
  } catch (error) {
    dispatch(updateSelectBundleFailure(error))
    if (ResponseCodes.isSystemError(error)) {
      throw error
    }
    return false
  }
}

export const updateSelectBundleWithAdditionalProducts = (legId, selectedAdditionalProducts) => (dispatch, getState) => {
  const state = getState()
  try {
    const journeyId = getJourneyId(state)
    const route = getRouteByLegId(state, legId)
    const bundle = getSelectedBundleByRoute(state, route)
    const additionalProducts = updateAdditionalProducts(
      getAdditionalProducts(state),
      selectedAdditionalProducts
    )
    const allSelectedSeats = getSelectedSeats(state, route)
    const seatPreferences = getSeatPreferences(state, route)

    dispatch(updateSelectBundleRequest(
      journeyId,
      bundle,
      additionalProducts,
      allSelectedSeats,
      seatPreferences
    ))
    const response = createV1SelectBundleResponseSelector(
      journeyId,
      bundle.travel_id,
      bundle.route_id,
      bundle.id,
      allSelectedSeats,
      seatPreferences,
      additionalProducts
    )(getState())
    dispatch(updateSelectBundleSuccess(response))
  } catch (error) {
    dispatch(updateSelectBundleFailure(error))
    if (ResponseCodes.isSystemError(error)) {
      throw error
    }
    return false
  }
}

export const updateSelectBundleWithSeatPreferences = (route, seatPreferences) => (dispatch, getState) => {
  const state = getState()
  try {
    const journeyId = getJourneyId(state)
    const bundle = getSelectedBundleByRoute(state, route)
    const additionalProducts = getAdditionalProducts(state)
    const allSelectedSeats = getSelectedSeats(state, route)

    dispatch(updateSelectBundleRequest(
      journeyId,
      bundle,
      additionalProducts,
      allSelectedSeats,
      seatPreferences
    ))
    const response = createV1SelectBundleResponseSelector(
      journeyId,
      bundle.travel_id,
      bundle.route_id,
      bundle.id,
      allSelectedSeats,
      seatPreferences,
      additionalProducts
    )(getState())
    dispatch(updateSelectBundleSuccess(response))
  } catch (error) {
    dispatch(updateSelectBundleFailure(error))
    if (ResponseCodes.isSystemError(error)) {
      throw error
    }
    return false
  }
}

const getJourneyId = state => {
  if (!state.api.orientation.journeySearch ||
    !state.api.orientation.journeySearch.id
  ) {
    throw new Error('Unable to get the journey id from the state')
  }

  return state.api.orientation.journeySearch.id
}

const getRouteByLegId = (state, legId) => {
  return getRouteByPredicate(
    state,
    route => route.legs.some(leg => leg.id === legId),
    `Unable to get the route for leg id ${legId} from the state`
  )
}

const getRouteByPredicate = (state, callback, errorMessage) => {
  if (!state.api.orientation ||
    !state.api.orientation.offer ||
    !state.api.orientation.offer.travels) {
    throw new Error('Unable to get the offer from the state')
  }
  const routes = state.api.orientation.offer.travels.reduce(
    (routes, travel) => routes.concat(travel.routes),
    []
  )
  const route = routes.find(callback)
  if (!route) {
    throw new Error(errorMessage)
  }

  return route
}

const getSelectedBundleByRoute = (state, route) => {
  return getSelectedBundleByPredicate(
    state,
    bundle => bundle.route_id === route.id,
    `Unable to get selected bundle for route id ${route.id} from the state`
  )
}

const getSelectedBundleByPredicate = (state, callback, errorMessage) => {
  const bundle = state.api.orientation.selectedBundles.find(callback)
  if (!bundle) {
    throw new Error(errorMessage)
  }

  return bundle
}

const getAdditionalProducts = state => {
  if (!state.api.orientation.specification) {
    throw new Error('Unable to get orientation specification from the state')
  }

  return (state.api.orientation.specification.additional_products || [])
    .map(product => ({
      item_id: product.item_id,
      passenger_id: product.passenger_id,
      quantity: product.quantity
    }))
}

const getSelectedSeats = (state, route) => {
  return state.api.orientation.specification &&
    state.api.orientation.specification.selected_seats
      .filter(selectedSeat => route.legs.some(leg => leg.id === selectedSeat.leg_id))
}

const getSeatPreferences = (state, route) => {
  return (state.api.orientation.specification.seat_preferences || [])
    .filter(seatPreference => route.legs.some(leg => leg.id === seatPreference.leg_id))
}

const updateSelectedSeats = (selectedSeats, updatedSelectedSeats, legId) => {
  return selectedSeats
    .filter(selectedSeat =>
      selectedSeat.leg_id !== legId ||
      !updatedSelectedSeats.some(seat => seat.passengerId === selectedSeat.passenger_id)
    )
    .concat(updatedSelectedSeats.map(seat => ({
      passenger_id: seat.passengerId,
      leg_id: legId,
      carriage_number: seat.carriageNumber,
      seat_number: seat.seatNumber
    })))
}

const updateAdditionalProducts = (selectedAdditionalProducts, updatedAdditionalProducts) => {
  return selectedAdditionalProducts
    .filter(product => !updatedAdditionalProducts.some(updatedProduct =>
      updatedProduct.itemId === product.item_id &&
      updatedProduct.passengerId === product.passenger_id)
    )
    .concat(updatedAdditionalProducts.map(product => ({
      item_id: product.itemId,
      passenger_id: product.passengerId,
      quantity: product.quantity
    })))
    .filter(product => product.quantity > 0)
}
