import {
  API_UNHANDLED_ERROR,
  API_OAUTH_REFRESH_TOKEN_FAILURE,
  API_PUBLIC_ACCESS_FAILURE,
  API_OAUTH_TOKEN_ERROR
} from 's3p-js-lib/src/redux/actions/types'
import responseCodes from 's3p-js-lib/src/api/response-codes'
import { oauthLogout } from 's3p-js-lib/src/redux/actions/api/auth/logout'
import {redirectToBookingConfirmationErrorPage, redirectToErrorPage} from '../actions/error-page'
import {API_V2_CONFIRM_BOOKING_FAILURE} from 's3p-js-lib/src/redux/actions/types-v2'

export default store => next => action => {
  action.error instanceof Error && window?.newrelic?.noticeError(action.error)
  if ([API_OAUTH_REFRESH_TOKEN_FAILURE, API_OAUTH_TOKEN_ERROR].includes(action.type) ||
    (API_UNHANDLED_ERROR === action.type && [401, 403].includes(action.error.statusCode))
  ) {
    // only logout, redirect is handled by auth container based on route settings
    store.dispatch(oauthLogout())
  } else if (action.type === API_V2_CONFIRM_BOOKING_FAILURE) {
    store.dispatch(redirectToBookingConfirmationErrorPage(action.bookingNumber))
  } else if ([API_UNHANDLED_ERROR, API_PUBLIC_ACCESS_FAILURE].includes(action.type) &&
    action.error.errorCode !== responseCodes.USER_AGENT_HAS_NO_ACTIVE_SHIFT &&
    action.error.name !== API_V2_CONFIRM_BOOKING_FAILURE
  ) {
    store.dispatch(redirectToErrorPage())
  } else if (window.console && action.error && action.error instanceof Error) {
    console.error(action.type, action.error)
  }

  return next(action)
}
