import React, {Component} from 'react'
import moment from 'moment'
import TextLabel from '../../../elements/text-label/text-label'

class CurrentDateTime extends Component {
  constructor (...args) {
    super(...args)
    this.state = {dateTime: this._getCurrentDateTime()}
    this._handleTick = this._handleTick.bind(this)
  }

  componentDidMount () {
    this._start()
  }

  componentWillUnmount () {
    this._stop()
  }

  render () {
    return (
      <div className='date'>
        <TextLabel name='date' text={this.state.dateTime} />
      </div>
    )
  }

  _getCurrentDateTime () {
    return moment().format('l LT')
  }

  _start () {
    !this.timer && (this.timer = setInterval(this._handleTick, 1000))
  }

  _stop () {
    this.timer && clearInterval(this.timer)
    delete this.timer
  }

  _handleTick () {
    if (this.state.dateTime !== this._getCurrentDateTime()) {
      this.timer && this.setState({dateTime: this._getCurrentDateTime()})
    }
  }
}

export default CurrentDateTime
