import { connect } from 'react-redux'
import { confirmBooking } from 's3p-js-lib/src/redux/actions/api/v2/confirm-booking'
import CartValueZeroModal, { CART_VALUE_ZERO_MODAL } from '../../components/tickets/modals/cart-value-zero-modal'
import { hideElement } from '../../redux/actions/containers/base/visible-element'

const mapStateToProps = state => ({
  isLoadingProceed: state.api.v2.loading.confirmBooking
})

const mapDispatchToProps = {
  proceedFulfillmentWithCartValueZero: () => async dispatch => {
    await dispatch(confirmBooking())
    dispatch(hideElement(CART_VALUE_ZERO_MODAL))
  },
  abortFulfillmentWithCartValueZero: () => hideElement(CART_VALUE_ZERO_MODAL)
}

export default connect(mapStateToProps, mapDispatchToProps)(CartValueZeroModal)
