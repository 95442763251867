import PropTypes from 'prop-types'
import React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {selectBundle, selectRebookingBundle} from 's3p-js-lib/src/redux/actions/api/orientation/select-bundle'
import {
  bundlesBySelectedUniqueBundleIdsSelector
} from 's3p-js-lib/src/redux/selectors/user-input/orientation/selected-unique-bundle-ids'
import {
  persistSelectedUniqueBundleIds,
  resetSelectedUniqueBundleIds
} from 's3p-js-lib/src/redux/actions/user-input/orientation/selected-unique-bundle-ids'

const containKeepBundleSelection = Component =>
  (class KeepBundleSelection extends React.Component {
    static propTypes = {
      reselectBundles: PropTypes.func.isRequired,
      persistSelectedUniqueBundleIds: PropTypes.func.isRequired
    }

    render () {
      const props = {...this.props}
      delete props.hasOffer
      delete props.persistSelectedUniqueBundleIds

      return (
        <Component
          {...props}
          startJourneySearch={this.props.persistSelectedUniqueBundleIds}
          reselectBundles={this.props.reselectBundles}
        />
      )
    }
  })

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  reselectBundles: () => async (dispatch, getState) => {
    const selectBundleCall = ownProps.aftersales ? selectRebookingBundle : selectBundle
    const journeyId = getState().api.orientation.journeySearch ? getState().api.orientation.journeySearch.id : null

    const bundles = bundlesBySelectedUniqueBundleIdsSelector(getState())
    if (bundles && journeyId) {
      for (const bundle of bundles) {
        await dispatch(selectBundleCall(journeyId, bundle.travelId, bundle.routeId, bundle.id))
      }
    }
    dispatch(resetSelectedUniqueBundleIds())
  },
  persistSelectedUniqueBundleIds
}, dispatch)

export default Component => connect(null, mapDispatchToProps)(containKeepBundleSelection(Component))
