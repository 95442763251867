import { connect } from 'react-redux'
import VoucherToCashModal from '../../../components/aftersales/voucher-to-cash/voucher-to-cash-modal'
import {
  VOUCHER_TO_CASH_MODAL,
  PRODUCT_CODE_VOUCHER_TO_CASH,
  PAYMENT_METHOD_CASH,
  CONTAINERS_AFTERSALES_VOUCHER_TO_CASH,
  DEVICE_ID_RECEIPT_PRINTER
} from '../../../constants'
import { hideElement } from '../../../redux/actions/containers/base/visible-element'
import {
  payOutVoucher,
  printReceipt
} from '../../../redux/actions/containers/aftersales/vouchers-to-cash/vouchers-to-cash'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import { isOfflineSelectorCreator } from '../../../redux/selectors/machine/offline'
import {displayNextCustomer} from '../../../redux/actions/containers/base/finalize-booking/next-customer'
import { deviceMessagesSelector } from '../../../redux/selectors/containers/base/diagnostics'

const receiptPrinterOfflineSelector = isOfflineSelectorCreator(DEVICE_ID_RECEIPT_PRINTER)

const mapStateToProps = state => {
  const deviceMessages = deviceMessagesSelector(state)
  return {
    ...state.containers.aftersales.voucherToCash,
    deviceMessages,
    hasDeviceMessages: deviceMessages.some(device => device.messages.length > 0),
    disabledReceipt: receiptPrinterOfflineSelector(state),
    loadingReceipt: state.machine.loading.receiptMachinePrint
  }
}

const mapDispatchToProps = {
  onRetry: printReceipt,
  onHideModal: () => [hideElement(VOUCHER_TO_CASH_MODAL), clearState(CONTAINERS_AFTERSALES_VOUCHER_TO_CASH)],
  onNextSale: () => [hideElement(VOUCHER_TO_CASH_MODAL), clearState(CONTAINERS_AFTERSALES_VOUCHER_TO_CASH), displayNextCustomer()],
  onPayOutVoucher: () => (dispatch, getState) => {
    dispatch(payOutVoucher(
      getState().containers.aftersales.voucherToCash.selected,
      PRODUCT_CODE_VOUCHER_TO_CASH,
      PAYMENT_METHOD_CASH
    ))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VoucherToCashModal)
