import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../../elements/text-label/text-label'
import Icon from '../../../../elements/icon/icon'

class SortLabel extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    sorted: PropTypes.bool.isRequired,
    onSort: PropTypes.func.isRequired
  }

  render () {
    return (
      <div
        className={`${this.props.name} state--is-sortable ${this.props.sorted ? 'state--is-sorted' : ''}`}
        onClick={this.props.onSort}
      >
        <TextLabel name={this.props.name}>
          <TextLabel.Text text={_t.message(`journey-result-set.header.${this.props.name}`)} />
          {this.props.sorted ? <Icon name='chevron-down' className='align-right' /> : null}
        </TextLabel>
      </div>
    )
  }
}

export default class Sorting extends Component {
  static propTypes = {
    sortedByDeparture: PropTypes.bool.isRequired,
    sortedByArrival: PropTypes.bool.isRequired,
    sortedByTravelTime: PropTypes.bool.isRequired,
    onSortByDeparture: PropTypes.func.isRequired,
    onSortByArrival: PropTypes.func.isRequired,
    onSortByTravelTime: PropTypes.func.isRequired,
    productFamilies: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }))
  }

  render () {
    return (
      <div className='route-set-header__sort-route-set'>
        <div className='sort-route-set'>
          <div className='sort-route-set__information'>
            <SortLabel
              name='departure'
              sorted={this.props.sortedByDeparture}
              onSort={this.props.onSortByDeparture}
            />
            <SortLabel
              name='arrival'
              sorted={this.props.sortedByArrival}
              onSort={this.props.onSortByArrival}
            />
            <SortLabel
              name='travel-time'
              sorted={this.props.sortedByTravelTime}
              onSort={this.props.onSortByTravelTime}
            />
          </div>
          <div className='sort-route-set__product-family-set'>
            {this.props.productFamilies.map(productFamily =>
              <div
                key={productFamily.id}
                className='product-family-type-information'
              >
                <TextLabel
                  name='product-family-type-information'
                  text={productFamily.name}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
