import { createSelector } from 'reselect'
import { camelCaseKeys } from '../../../../misc/utils'

export const additionalDetailsSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking && booking.additional_details ? booking.additional_details.map(camelCaseKeys) : []
)

export const additionalDetailValueSelector = (bookingSelector, key) => createSelector(
  [additionalDetailsSelector(bookingSelector)],
  additionalDetails => {
    const detail = additionalDetails.find(item => item.key === key)
    return detail ? detail.value : null
  }
)
