import React from 'react'
import { Route } from 'react-router'
import BookingLayout from '../../layouts/aftersales/booking'
import containAuth from '../../containers/base/auth'
import { AGENT_ROLE_OPERATOR } from '../../constants'

export default (
  <Route
    key='aftersales-booking'
    path='/:lang/aftersales/booking/:bookingNumber'
    component={containAuth(BookingLayout, {roles: [AGENT_ROLE_OPERATOR]})}
  />
)
