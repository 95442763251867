import request from './request'
import {translateResponseMap} from './translate-response'
import {mapDevice} from './device'

let cacheStatus

export default {
  async getStatusDevices () {
    const status = await request({
      method: 'get',
      endpoint: `/vtm/devices/rest/all/status`,
      ignoreErrors: true
    })

    return Array.isArray(status) ? status.map(device => {
      const translator = device && device.Device && translateResponseMap[device.Device.Id]
      return mapDevice(device, translator)
    }) : []
  },

  async getControlStatus () {
    if (!cacheStatus) {
      cacheStatus = await request({
        method: 'get',
        endpoint: '/vtm/devices/rest/NADManControl/status'
      })
    }

    return cacheStatus
  },

  clearStatusCache () {
    cacheStatus = undefined
  }
}
