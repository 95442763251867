import React from 'react'
import { Route } from 'react-router'
import containAuth from '../../containers/base/auth'
import PaymentLayout from '../../layouts/reservations/review-payment'
import { AGENT_ROLE_OPERATOR } from '../../constants'

export default (
  <Route
    key='reservations-review-payment'
    path='/:lang/reservations/review-payment'
    component={containAuth(PaymentLayout, {roles: [AGENT_ROLE_OPERATOR]})}
  />
)
