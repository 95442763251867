import React, { Component } from 'react'
import GenericLayout from '../generic-layout'
import AdditionalProducts from '../../containers/aftersales/additional-products/additional-products'
import MainMenu from '../../containers/base/main-menu/main-menu'
import ProgressNavigation from '../../containers/aftersales/additional-products/progress-navigation'
import GridLayout from '../../elements/grid/grid-layout'
import GridColumn from '../../elements/grid/grid-column'
import Operation from '../../containers/aftersales/operation/operation'

export default class AdditionalProductsLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <MainMenu />
            <Operation
              labelTitle='aftersales.additional-products.header.title'
              labelInformation='aftersales.additional-products.header.information'
            />
            <AdditionalProducts />
            <ProgressNavigation />
          </GridColumn>
        </GridLayout>
      </GenericLayout>
    )
  }
}
