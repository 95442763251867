import {
  UI_LEAP_REVERSE_START,
  UI_LEAP_REVERSE_END
} from '../../../../actions/types'
import {
  API_AFTERSALES_CONFIRM_SUCCESS,
  API_AFTERSALES_REVERT_SUCCESS,
  CLEAR_STATE
} from 's3p-js-lib/src/redux/actions/types'
import { CONTAINERS_AFTERSALES_CANCELLATION_REVERSE_LEAP } from '../../../../../constants'

const initialState = {
  isRunning: false,
  success: false,
  errorMessage: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UI_LEAP_REVERSE_START:
      return {isRunning: true, success: false, errorMessage: null}
    case UI_LEAP_REVERSE_END:
      return {isRunning: false, success: !action.errorMessage, errorMessage: action.errorMessage}
    case API_AFTERSALES_CONFIRM_SUCCESS:
    case API_AFTERSALES_REVERT_SUCCESS:
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_AFTERSALES_CANCELLATION_REVERSE_LEAP) ? initialState : state
    default:
      return state
  }
}
