import React, { Component } from 'react'
import ComponentContainer from '../../../elements/component-container/component-container'
import BaseFulfillmentMethods from '../../../containers/base/tiles/fulfillment-methods'
import './fulfillment-methods.scss'

export default class FulfillmentMethods extends Component {
  render () {
    return (
      <ComponentContainer name='fulfillment-methods'>
        <BaseFulfillmentMethods {...this.props} />
      </ComponentContainer>
    )
  }
}
