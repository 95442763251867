import React, { Component } from 'react'
import ReviewPaymentLayout from '../review-payment'

export default class ReviewPaymentChangeSeats extends Component {
  render () {
    return (
      <ReviewPaymentLayout
        labelTitle='aftersales.change-seats.header.title'
        labelInformation='aftersales.change-seats.header.information'
      />
    )
  }
}
