import { CLEAR_STATE } from '../../../../actions/types'
import {
  API_V2_GET_CURRENCY_RATES_REQUEST,
  API_V2_GET_CURRENCY_RATES_SUCCESS,
  API_V2_GET_CURRENCY_RATES_FAILURE
} from '../../../../actions/types-v2'

export const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case API_V2_GET_CURRENCY_RATES_REQUEST:
    case API_V2_GET_CURRENCY_RATES_FAILURE:
      return initialState
    case API_V2_GET_CURRENCY_RATES_SUCCESS:
      return action.response.data.currency_rates
    case CLEAR_STATE:
      return action.namespaces.includes('api.v2.meta.currencyRates') ? initialState : state
    default:
      return state
  }
}
