import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Icon from '../../../../elements/icon/icon'
import TextLabel from '../../../../elements/text-label/text-label'
import {
  SEAT_SELECTION_AVAILABLE,
  SEAT_SELECTION_NOT_INCLUDED,
  SEAT_SELECTION_NOT_AVAILABLE,
  SEAT_SELECTION_NOT_AVAILABLE_ANYMORE,
  SEAT_SELECTION_NOT_AUTHORIZED,
  SEAT_SELECTION_READONLY,
  MESSAGE_YES,
  MESSAGE_NO
} from 's3p-js-lib/src/constants'
import { requireSeatSeparator } from '../../../../misc/utils'

class SegmentStatusNoSeatSelected extends Component {
  render () {
    return (
      <TextLabel name='seat-selection-none-selected' className='warning'>
        <Icon name='warning' />
        <TextLabel.Text
          text={_t.message('allocated-seats.warning-no-seat-selected')} />
      </TextLabel>
    )
  }
}

class SegmentStatusSeatSelectionNotAvailable extends Component {
  render () {
    return (
      <TextLabel name='seat-selection-not-available' className='state--is-unavailable'>
        <Icon name='not-available' />
        <TextLabel.Text
          text={_t.message('allocated-seats.warning-seat-selection-not-available')} />
      </TextLabel>
    )
  }
}

class SegmentStatusSelectionOk extends Component {
  static propTypes = {
    seats: PropTypes.array.isRequired
  }

  render () {
    const seatList = this.props.seats.map(seat => _t.message('allocated-seats.leg.seat', {
      carriage: seat.carriageNumber,
      seat: seat.seatNumber,
      requireSeparator: requireSeatSeparator(seat) ? MESSAGE_YES : MESSAGE_NO
    })).join(_t.message('allocated-seats.leg.seat-separator'))
    return (
      <TextLabel name='seat-selection-is-selected' className='state--is-selected'>
        <Icon name='tick' />
        <TextLabel.Text
          text={_t.message('allocated-seats.leg.seat-line', {count: this.props.seats.length, seats: seatList})} />
      </TextLabel>
    )
  }
}

class SegmentStatusSelectionChanged extends Component {
  static propTypes = {
    seats: PropTypes.arrayOf(PropTypes.shape({
      carriageNumber: PropTypes.string.isRequired,
      seatNumber: PropTypes.string.isRequired
    }))
  }

  render () {
    const seatList = this.props.seats.map(seat => _t.message('allocated-seats.leg.seat', {
      carriage: seat.carriageNumber,
      seat: seat.seatNumber,
      requireSeparator: requireSeatSeparator(seat) ? MESSAGE_YES : MESSAGE_NO
    })).join(_t.message('allocated-seats.leg.seat-separator'))

    return (
      <TextLabel name='seat-selection-is-changed' className='state--is-selected'>
        <Icon name='tick-circle' />
        <TextLabel.Text
          text={_t.message('allocated-seats.leg.seat-line', {count: this.props.seats.length, seats: seatList})} />
      </TextLabel>
    )
  }
}

export default class SegmentStatus extends Component {
  static propTypes = {
    selectedSeats: PropTypes.arrayOf(PropTypes.shape({
      carriageNumber: PropTypes.string.isRequired,
      seatNumber: PropTypes.string.isRequired
    })),
    updated: PropTypes.bool,
    status: PropTypes.oneOf([
      SEAT_SELECTION_AVAILABLE,
      SEAT_SELECTION_NOT_INCLUDED,
      SEAT_SELECTION_NOT_AVAILABLE,
      SEAT_SELECTION_NOT_AVAILABLE_ANYMORE,
      SEAT_SELECTION_NOT_AUTHORIZED,
      SEAT_SELECTION_READONLY
    ])
  }

  render () {
    if (this.props.updated) {
      return <SegmentStatusSelectionChanged seats={this.props.selectedSeats} />
    } else if (this.props.status === SEAT_SELECTION_AVAILABLE || this.props.status === SEAT_SELECTION_READONLY) {
      return this.props.selectedSeats && this.props.selectedSeats.length
        ? <SegmentStatusSelectionOk seats={this.props.selectedSeats} />
        : <SegmentStatusNoSeatSelected />
    } else {
      return <SegmentStatusSeatSelectionNotAvailable />
    }
  }
}
