import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import _t from 's3p-js-lib/src/translate'
import {loadCmsBlocks} from 's3p-js-lib/src/redux/actions/api/base/cms'
import {oauthAccessTokenSelector} from 's3p-js-lib/src/redux/selectors/api/auth/oauth-token'

const mapStateToProps = state => ({
  cmsBlocks: state.api.base.cms.cmsBlocks,
  loadingCmsBlocks: state.api.base.cms.loadingCmsBlocks,
  hasOAuthToken: Boolean(oauthAccessTokenSelector(state)),
  locale: _t.getLocales()
})
const mapDispatchToProps = {loadCmsBlocks}

export default (...blockNames) => Component => {
  class CmsContainer extends React.Component {
    static propTypes = {
      loadCmsBlocks: PropTypes.func.isRequired,
      cmsBlocks: PropTypes.object,
      locale: PropTypes.string,
      hasOAuthToken: PropTypes.bool,
      loadingCmsBlocks: PropTypes.arrayOf(PropTypes.string).isRequired
    }

    componentDidMount () {
      if (this.props.hasOAuthToken) {
        this._loadCmsBlocks(this.props)
      }
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
      if (nextProps.hasOAuthToken && (!this.props.hasOAuthToken || this.locale !== nextProps.locale)) {
        this._loadCmsBlocks(nextProps)
      }
    }

    _loadCmsBlocks ({cmsBlocks, loadCmsBlocks, loadingCmsBlocks, locale}) {
      this.locale = locale

      const blockNamesToLoad = blockNames.filter(blockName =>
        (!loadingCmsBlocks.includes(blockName) && !(cmsBlocks && blockName in cmsBlocks)) ||
        (cmsBlocks && cmsBlocks[blockName] && cmsBlocks[blockName].locale !== this.locale)
      )

      if (blockNamesToLoad.length > 0) {
        loadCmsBlocks(blockNamesToLoad, this.locale)
      }
    }

    render () {
      return <Component {...this.props} />
    }
  }

  CmsContainer.displayName = `CmsContainer(${Component.displayName || Component.name || 'Component'})`

  return connect(mapStateToProps, mapDispatchToProps)(CmsContainer)
}
