import React, { Component } from 'react'
import GridLayout from '../../elements/grid/grid-layout'
import GridColumn from '../../elements/grid/grid-column'
import MainMenu from '../../containers/base/main-menu/main-menu'
import TestThermalPrinter from '../../containers/maintenance/test-thermal-printer'
import StickyFooter from '../../elements/sticky-footer/sticky-footer'
import Taskbar from '../../containers/base/taskbar/taskbar'
import GenericLayout from '../generic-layout'

export default class TestCfdLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <MainMenu />
            <TestThermalPrinter />
            <StickyFooter>
              <Taskbar />
            </StickyFooter>
          </GridColumn>
        </GridLayout>
      </GenericLayout>
    )
  }
}
