import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import './description-list.scss'

const DescriptionList = ({className, name, children, ...rest}) => (
  <dl {...rest} className={classNames('description-list', name ? `description-list-${name}` : '', className)}>
    {children}
  </dl>
)

DescriptionList.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string
}

export default DescriptionList
