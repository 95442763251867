import {
  USER_INPUT_SEAT_SELECTOR_SELECT_PASSENGER,
  USER_INPUT_SEAT_SELECTOR_SELECT_CARRIAGE,
  USER_INPUT_SEAT_SELECTOR_SELECT_SEAT,
  USER_INPUT_SEAT_SELECTOR_DESTROY
} from '../../types'

// eslint-disable-next-line func-style
export function selectPassenger (passengerId) {
  return {type: USER_INPUT_SEAT_SELECTOR_SELECT_PASSENGER, passengerId}
}

// eslint-disable-next-line func-style
export function selectCarriage (carriageNumber) {
  return {type: USER_INPUT_SEAT_SELECTOR_SELECT_CARRIAGE, carriageNumber}
}

// eslint-disable-next-line func-style
export function selectSeat (passengerId, carriageNumber, seatNumber) {
  return {type: USER_INPUT_SEAT_SELECTOR_SELECT_SEAT, passengerId, carriageNumber, seatNumber}
}

// eslint-disable-next-line func-style
export function destroySeatSelector () {
  return {type: USER_INPUT_SEAT_SELECTOR_DESTROY}
}
