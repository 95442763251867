import { STATUS_UNKNOWN, STATUS_OK, STATUS_ERROR } from '../machine/device-manager'

export const initialState = STATUS_UNKNOWN

export const statusReducer = (requestActionType, successActionType, failureActionType) => (state = initialState, action) => {
  switch (action.type) {
    case requestActionType:
      return initialState
    case successActionType:
      return STATUS_OK === action.response.status ? STATUS_OK : initialState
    case failureActionType:
      return STATUS_ERROR
    default:
      return state
  }
}
