import { compose } from 'redux'
import { connect } from 'react-redux'
import { changeSeatsInitiate } from 's3p-js-lib/src/redux/actions/api/aftersales/change-seats/initiate'
import { overviewBookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import containVisibleElement from '../../../../containers/base/visible-element'
import {
  isLoadingToolbarActionsSelector,
  isOverrideAftersalesRulesSelector
} from '../../../../redux/selectors/containers/aftersales/booking/toolbar'
import { changeSeatsAllowedSelector } from '../../../../redux/selectors/api/booking/after-sales'
import ToolbarItem from '../../../../components/aftersales/booking/toolbar/toolbar-item'
import { isChangeSeatsAvailableSelector, isOverrideValidationRulesSelector } from '../../../../redux/selectors/containers/base/aftersales'
import { goToChangeSeats } from '../../../../redux/actions/containers/base/void-tickets-routing'

const _isChangeSeatsAvailableSelector = isChangeSeatsAvailableSelector(overviewBookingSelector)
const _isOverrideAftersalesRulesSelector = isOverrideAftersalesRulesSelector(changeSeatsAllowedSelector)
const _isOverrideValidationRulesSelector = isOverrideValidationRulesSelector(overviewBookingSelector)

const mapStateToProps = state => ({
  loading: state.api.loading.changeSeatsInitiate,
  disabled: isLoadingToolbarActionsSelector(state),
  available: _isChangeSeatsAvailableSelector(state),
  overrideAftersalesRules: _isOverrideAftersalesRulesSelector(state),
  overrideValidationRules: _isOverrideValidationRulesSelector(state)

})

const mapDispatchToProps = (_, ownProps) => (dispatch, getState) => ({
  onClick: async () => {
    if (await dispatch(changeSeatsInitiate(ownProps.bookingNumber))) {
      dispatch(goToChangeSeats(ownProps.bookingNumber))
    }
  }
})

const container = compose(
  connect(mapStateToProps, mapDispatchToProps),
  containVisibleElement()
)

export default container(ToolbarItem)
