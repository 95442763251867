import { createSelector } from 'reselect'
import { camelCaseKeys } from '../../../../../misc/utils'

export const passengersSelector = createSelector(
  [state => state.api.orientation.offer],
  offer => offer
    ? offer.passengers.map((passenger, index) => ({
      ...camelCaseKeys(passenger),
      number: index + 1
    }))
    : []
)

export const passengersWithDisabilityTypeSelector = type => createSelector(
  [passengersSelector],
  passengers => passengers.filter(passenger => passenger.disabilityType === type)
)

export const passengersWithoutDisabilityTypeSelector = type => createSelector(
  [passengersSelector],
  passengers => passengers.filter(passenger => passenger.disabilityType !== type)
)
