import React, { Component } from 'react'
import GenericLayout from '../generic-layout'
import GridLayout from '../../elements/grid/grid-layout'
import GridColumn from '../../elements/grid/grid-column'
import MainMenu from '../../containers/base/main-menu/main-menu'
import UpdatePassengers from '../../containers/aftersales/update-passengers/update-passengers'
import ProgressNavigation from '../../containers/aftersales/update-passengers/progress-navigation'
import Operation from '../../containers/aftersales/operation/operation'
import WaiveFees from '../../containers/aftersales/update-passengers/waive-fees'

export default class UpdatePassengersLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <MainMenu />
            <Operation
              labelTitle='aftersales.update-passengers.header.title'
              labelInformation='aftersales.update-passengers.header.information'
            />
            <WaiveFees />
            <UpdatePassengers />
            <ProgressNavigation />
          </GridColumn>
        </GridLayout>
      </GenericLayout>
    )
  }
}
