import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../../elements/text-label/text-label'
import Icon from '../../../../elements/icon/icon'
import Button from '../../../../elements/button/button'
import FindCustomersDialog from './find-customers-dialog'
import FindCustomers from '../../../../containers/reservations/booking-details/find-customers'
import FindCustomersTable from './find-customers-table'

export default class SelectedCustomer extends Component {
  static propTypes = {
    isLoadingLinkToCustomer: PropTypes.bool.isRequired,
    isLoadingFindCustomers: PropTypes.bool.isRequired,
    foundCustomers: PropTypes.array,
    isAgent: PropTypes.bool.isRequired,
    hasAccessToPublicBookings: PropTypes.bool.isRequired,
    selectedCustomer: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      emailAddress: PropTypes.string.isRequired
    }),
    findCustomers: PropTypes.func.isRequired,
    resetCustomers: PropTypes.func.isRequired,
    onShowElement: PropTypes.func.isRequired,
    onHideElement: PropTypes.func.isRequired,
    onLinkCrmCustomer: PropTypes.func.isRequired,
    onUnlinkCrmCustomer: PropTypes.func.isRequired,
    onOpenFindCustomersDialog: PropTypes.func.isRequired,
    onCloseFindCustomersDialog: PropTypes.func.isRequired,
    showSearchCustomerDialog: PropTypes.bool,
    form: PropTypes.shape({
      loadFormValues: PropTypes.func.isRequired,
      fields: PropTypes.shape({
        isPassenger: PropTypes.object
      }).isRequired
    }).isRequired
  }

  componentDidMount () {
    const element = document.getElementById('search-customer')
    element && setTimeout(() => element.focus(), 200)
  }

  renderLinkCustomer () {
    return (
      <div className='customer-selector'>
        <TextLabel
          name='select-customer'
          text={_t.message('booking-details.selected-customer.find-customer-placeholder')} />
        <Button
          id='search-customer'
          name='search-customer'
          loading={this.props.isLoadingLinkToCustomer}
          className='secondary'
          onClick={this.props.onOpenFindCustomersDialog}
        >
          <TextLabel>
            <Icon name='search' className='align-left' />
            <TextLabel.Text text={_t.message('booking-details.selected-customer.find-customer-button')} />
          </TextLabel>
        </Button>
        {this.props.showSearchCustomerDialog ? (
          <FindCustomersDialog
            loading={this.props.isLoadingLinkToCustomer}
            onClose={this.props.onCloseFindCustomersDialog}>
            <FindCustomers
              cntResult={this.props.foundCustomers ? this.props.foundCustomers.length : 0}
              findCustomers={this.props.findCustomers}
              resetCustomers={this.props.resetCustomers}
              isLoadingFindCustomers={this.props.isLoadingFindCustomers}
              isLoadingLinkToCustomer={this.props.isLoadingLinkToCustomer}
              isResultFiltered={this.props.foundCustomers !== null}
            />
            {this.props.foundCustomers && this.props.foundCustomers.length && !this.props.isLoadingFindCustomers ? (
              <FindCustomersTable
                customers={this.props.foundCustomers}
                onTableRowClick={this.props.onLinkCrmCustomer} />
            ) : null}
          </FindCustomersDialog>
        ) : null}
      </div>
    )
  }

  renderUnlinkCustomer () {
    return (
      <div className='customer-selector'>
        <TextLabel name='selected-customer'>
          <Icon name='tick' className='align-left' />
          <TextLabel.Text
            text={_t.message('booking-details.selected-customer.selected-customer-name', this.props.selectedCustomer)}
          />
        </TextLabel>
        <Button
          name='remove-customer'
          loading={this.props.isLoadingLinkToCustomer}
          className='alert'
          onClick={this.props.onUnlinkCrmCustomer}
        >
          <TextLabel>
            <Icon name='unlink' className='align-left' />
            <TextLabel.Text text={
              _t.message('booking-details.selected-customer.unlink-customer-button')}
            />
          </TextLabel>
        </Button>
      </div>
    )
  }

  render () {
    return (
      this.props.selectedCustomer
        ? this.renderUnlinkCustomer()
        : this.renderLinkCustomer()
    )
  }
}
