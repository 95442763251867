import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Form from '../../../../elements/form/form'
import Feedback, {FEEDBACK_WARNING} from '../../../../elements/feedback/feedback'
import _t from 's3p-js-lib/src/translate'
import CancelByPassenger from '../../../../containers/aftersales/cancellation/cancel-by/cancel-by-passenger'

export default class CancelByPassengers extends Component {
  static propTypes = {
    bookingCancellationData: PropTypes.shape({
      allItemsSelected: PropTypes.bool.isRequired
    }).isRequired,
    passengersCancellationData: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      allItemsSelected: PropTypes.bool.isRequired,
      noItemsSelected: PropTypes.bool.isRequired
    })).isRequired,
    outboundCancellationData: PropTypes.object,
    inboundCancellationData: PropTypes.object
  }

  render () {
    const {
      bookingCancellationData,
      passengersCancellationData,
      outboundCancellationData,
      inboundCancellationData,
      ...props
    } = this.props
    if (passengersCancellationData.length < 2) {
      return null
    }

    let message
    if (bookingCancellationData.someItemsSelected && !bookingCancellationData.allItemsSelected &&
      outboundCancellationData && inboundCancellationData &&
      passengersCancellationData.every(({allItemsSelected, noItemsSelected}) => allItemsSelected || noItemsSelected)
    ) {
      message = _t.message('aftersales.cancellation.cancel-by.passenger-warning')
    }

    return (
      <fieldset>
        <Form.Legend name='passenger' />
        {message ? <Feedback status={FEEDBACK_WARNING} text={message} /> : null}
        {passengersCancellationData.map(passengerCancellationData => (
          <CancelByPassenger
            key={passengerCancellationData.id}
            bookingCancellationData={bookingCancellationData}
            passengerCancellationData={passengerCancellationData}
            outboundCancellationData={outboundCancellationData}
            inboundCancellationData={inboundCancellationData}
            {...props}
          />
        ))}
      </fieldset>
    )
  }
}
