import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { routerActions } from '../../../redux/actions/containers/base/routing'
import {
  hideElement,
  showModal
} from '../../../redux/actions/containers/base/visible-element'
import { elementVisibleSelector } from '../../../redux/selectors/containers/base/visible-element'
import ShiftModal from '../../../components/agent/shift/modal'
import {
  SHIFT_MODAL,
  CONTAINERS_SHIFT_MODAL,
  CHANGE_PASSWORD_MODAL
} from '../../../constants'
import { FORM_TYPE_AGENT_SHIFT_NOTE } from 's3p-js-lib/src/constants-form'
import {
  endShift,
  saveNoteShift,
  printShiftReport
} from '../../../redux/actions/containers/agent/shift/modal'
import connectFormContainer from '../../base/form'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import { agentShiftSelector } from 's3p-js-lib/src/redux/selectors/api/user/agent/shift'
import { compose } from 'redux'

class ShiftModalContainer extends Component {
  static propTypes = {
    showShiftModal: PropTypes.bool,
    clearState: PropTypes.func.isRequired
  }

  componentWillUnmount () {
    this.props.clearState()
  }

  render () {
    return this.props.showShiftModal ? <ShiftModal {...this.props} /> : null
  }
}

const showShiftModalSelector = elementVisibleSelector(SHIFT_MODAL)
const showChangePasswordModalSelector = elementVisibleSelector(CHANGE_PASSWORD_MODAL)

const mapStateToProps = state => ({
  showShiftModal: showShiftModalSelector(state),
  showChangePasswordModal: showChangePasswordModalSelector(state),
  username: state.api.user.user && state.api.user.user.username,
  shift: agentShiftSelector(state),
  noteSavedSuccessfully: state.containers.shift.modal.manuallySavedShiftNote && state.containers.shift.modal.noteSaved,
  shiftEndedRemotely: state.containers.shift.modal.shiftEndedRemotely,
  loading: state.api.loading.addNotesAgentShift || state.api.loading.endAgentShift || state.api.v2.loading.getShiftReport || state.machine.loading.receiptMachinePrint || state.machine.loading.sendMachineDisplayLines,
  isLoadingPrintShiftReport: state.api.v2.loading.getShiftReport || state.machine.loading.receiptMachinePrint
})

const mapDispatchToProps = {
  onHideElement: () => hideElement(SHIFT_MODAL),
  onLogout: () => [
    hideElement(SHIFT_MODAL),
    routerActions.push('/agent/login')
  ],
  onSaveNoteShift: saveNoteShift,
  onEndShift: endShift,
  onPrintShiftReport: printShiftReport,
  clearState: () => clearState(CONTAINERS_SHIFT_MODAL),
  onChangePassword: () => showModal(CHANGE_PASSWORD_MODAL)
}

const container = compose(
  connect(mapStateToProps, mapDispatchToProps),
  connectFormContainer({formName: FORM_TYPE_AGENT_SHIFT_NOTE})
)

export default container(ShiftModalContainer)
