import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAdditionalProducts } from 's3p-js-lib/src/redux/actions/api/orientation/additional-products'
import { getSeatSelectionOptions } from 's3p-js-lib/src/redux/actions/api/base/seat-selector/seat-selection-options'
import { travelSelector } from 's3p-js-lib/src/redux/selectors/api/orientation/specification/travel'
import {
  outboundLegsSelector,
  inboundLegsSelector
} from 's3p-js-lib/src/redux/selectors/api/orientation/specification/legs'
import { additionalProductsSelector } from '../../../redux/selectors/containers/reservations/additional-products'
import { onUpdateProduct } from '../../../redux/actions/containers/reservations/additional-product'
import AdditionalProducts from '../../../components/base/additional-products/additional-products'
import { isReturnJourneySelector } from 's3p-js-lib/src/redux/selectors/api/orientation/journey-search'
import containCmsComponent from '../../base/cms/cms-container'
import { CMS_BLOCK_ORIENTATION_ADDITIONAL_PRODUCTS } from '../../../constants'

class AdditionalProductsContainer extends Component {
  static propTypes = {
    getAdditionalProducts: PropTypes.func.isRequired,
    getSeatSelectionOptions: PropTypes.func.isRequired
  }

  UNSAFE_componentWillMount () {
    this.props.getAdditionalProducts()
    this.props.getSeatSelectionOptions()
  }

  render () {
    return (
      <AdditionalProducts {...this.props} />
    )
  }
}

const outboundTravelSelector = travelSelector(outboundLegsSelector)
const inboundTravelSelector = travelSelector(inboundLegsSelector)

const mapStateToProps = state => ({
  isLoadingAdditionalProducts: state.api.loading.updateSelectBundle,
  outboundTravel: outboundTravelSelector(state),
  inboundTravel: inboundTravelSelector(state),
  additionalProducts: additionalProductsSelector(state),
  isReturnJourney: isReturnJourneySelector(state)
})

const mapDispatchToProps = {
  onUpdateProduct,
  getAdditionalProducts: () => (dispatch, getState) => getState().api.orientation.journeySearch
    ? dispatch(getAdditionalProducts(getState().api.orientation.journeySearch.id))
    : null,
  getSeatSelectionOptions: () => (dispatch, getState) => getState().api.orientation.journeySearch
    ? dispatch(getSeatSelectionOptions(getState().api.orientation.journeySearch.id))
    : null
}

export default containCmsComponent(CMS_BLOCK_ORIENTATION_ADDITIONAL_PRODUCTS)(connect(mapStateToProps, mapDispatchToProps)(AdditionalProductsContainer))
