import request from './request'
import { STATUS_ERROR } from './device-manager'
import { createWarning, createError } from './response-handler'

const TIMEOUT = 30

export const translateResponse = response => {
  const messages = []

  if ((response.status || response.Status) === STATUS_ERROR && response.Details) {
    messages.push(createError(response.Details.message, {translated: true}))
  }

  if ((response.status || response.Status) === STATUS_ERROR && response.results && response.results.message) {
    if (response.results.message === 'No ticket was found') {
      messages.push(createWarning(response.results.message, {translated: true}))
    } else {
      messages.push(createError(response.results.message, {translated: true}))
    }
  }

  if (response.Details && response.Details.internal_hopper_low === 'True') {
    messages.push(createWarning('ticket-printer.running-low'))
  }

  if (response.Details && response.Details.access_cover_open === 'True') {
    messages.push(createWarning('ticket-printer.cover-is-open'))
  }

  return messages
}

export default {
  async printTicket (magneticData, template, data) {
    return request({
      translateResponse,
      method: 'post',
      endpoint: '/vtm/devices/rest/MagneticTicketPrinter/print',
      data: {
        TimeOut: TIMEOUT,
        MagneticData: magneticData.replace(/\|/g, ''),
        Template: template,
        Data: data
      }
    })
  },

  async readTicket () {
    return request({
      translateResponse,
      method: 'post',
      endpoint: '/vtm/devices/rest/MagneticTicketPrinter/read'
    })
  },

  async voidTicket () {
    return request({
      translateResponse,
      method: 'post',
      endpoint: '/vtm/devices/rest/MagneticTicketPrinter/void'
    })
  },

  async ejectTicket () {
    return request({
      translateResponse,
      method: 'post',
      endpoint: '/vtm/devices/rest/MagneticTicketPrinter/eject'
    })
  },

  async setDarkness (setting) {
    return request({
      translateResponse,
      method: 'post',
      endpoint: '/vtm/devices/rest/MagneticTicketPrinter/set_darkness',
      data: {Darken: setting}
    })
  },

  async updateTemplate (templateId, content) {
    return request({
      translateResponse,
      method: 'post',
      endpoint: '/vtm/devices/rest/MagneticTicketPrinter/updatetemplate',
      data: {
        templateId: templateId,
        content: content
      }
    })
  },

  async listTemplates () {
    return request({
      translateResponse,
      method: 'post',
      endpoint: '/vtm/devices/rest/MagneticTicketPrinter/listtemplates'
    })
  },

  async removeTemplate (templateId) {
    return request({
      translateResponse,
      method: 'post',
      endpoint: '/vtm/devices/rest/MagneticTicketPrinter/removetemplate',
      data: {templateId: templateId}
    })
  }
}
