import React, {Component} from 'react'
import Select from './select'
import countries from 'countries-list'

const getOptions = () => Object.keys(countries)
  .reduce(
    (options, countryCode) => options.concat({
      text: countries[countryCode].name,
      value: countryCode
    }),
    []
  )
  .sort((optionA, optionB) => optionA.text.localeCompare(optionB.text))

class CountrySelect extends Component {
  constructor (...args) {
    super(...args)

    this.options = getOptions()
  }

  render () {
    return <Select {...this.props} data={this.options} />
  }
}

export default CountrySelect
