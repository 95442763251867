export const API_V2_GET_PRODUCTS_REQUEST = 'API_V2_GET_PRODUCTS_REQUEST'
export const API_V2_GET_PRODUCTS_SUCCESS = 'API_V2_GET_PRODUCTS_SUCCESS'
export const API_V2_GET_PRODUCTS_FAILURE = 'API_V2_GET_PRODUCTS_FAILURE'

export const API_V2_GET_PRODUCT_FAMILIES_REQUEST = 'API_V2_GET_PRODUCT_FAMILIES_REQUEST'
export const API_V2_GET_PRODUCT_FAMILIES_SUCCESS = 'API_V2_GET_PRODUCT_FAMILIES_SUCCESS'
export const API_V2_GET_PRODUCT_FAMILIES_FAILURE = 'API_V2_GET_PRODUCT_FAMILIES_FAILURE'

export const API_V2_GET_CURRENCY_RATES_REQUEST = 'API_V2_GET_CURRENCY_RATES_REQUEST'
export const API_V2_GET_CURRENCY_RATES_SUCCESS = 'API_V2_GET_CURRENCY_RATES_SUCCESS'
export const API_V2_GET_CURRENCY_RATES_FAILURE = 'API_V2_GET_CURRENCY_RATES_FAILURE'

export const API_V2_CREATE_BOOKING_REQUEST = 'API_V2_CREATE_BOOKING_REQUEST'
export const API_V2_CREATE_BOOKING_SUCCESS = 'API_V2_CREATE_BOOKING_SUCCESS'
export const API_V2_CREATE_BOOKING_FAILURE = 'API_V2_CREATE_BOOKING_FAILURE'

export const API_V2_UPDATE_FULFILLMENT_METHOD_REQUEST = 'API_V2_UPDATE_FULFILLMENT_METHOD_REQUEST'
export const API_V2_UPDATE_FULFILLMENT_METHOD_SUCCESS = 'API_V2_UPDATE_FULFILLMENT_METHOD_SUCCESS'
export const API_V2_UPDATE_FULFILLMENT_METHOD_FAILURE = 'API_V2_UPDATE_FULFILLMENT_METHOD_FAILURE'

export const API_V2_UPDATE_SEGMENTS_REQUEST = 'API_V2_UPDATE_SEGMENTS_REQUEST'
export const API_V2_UPDATE_SEGMENTS_SUCCESS = 'API_V2_UPDATE_SEGMENTS_SUCCESS'
export const API_V2_UPDATE_SEGMENTS_FAILURE = 'API_V2_UPDATE_SEGMENTS_FAILURE'

export const API_V2_CONFIRM_BOOKING_REQUEST = 'API_V2_CONFIRM_BOOKING_REQUEST'
export const API_V2_CONFIRM_BOOKING_SUCCESS = 'API_V2_CONFIRM_BOOKING_SUCCESS'
export const API_V2_CONFIRM_BOOKING_FAILURE = 'API_V2_CONFIRM_BOOKING_FAILURE'

export const API_V2_ADD_ADDITIONAL_DETAILS_REQUEST = 'API_V2_ADD_ADDITIONAL_DETAILS_REQUEST'
export const API_V2_ADD_ADDITIONAL_DETAILS_SUCCESS = 'API_V2_ADD_ADDITIONAL_DETAILS_SUCCESS'
export const API_V2_ADD_ADDITIONAL_DETAILS_FAILURE = 'API_V2_ADD_ADDITIONAL_DETAILS_FAILURE'

export const API_V2_UPDATE_ADDITIONAL_DETAILS_REQUEST = 'API_V2_UPDATE_ADDITIONAL_DETAILS_REQUEST'
export const API_V2_UPDATE_ADDITIONAL_DETAILS_SUCCESS = 'API_V2_UPDATE_ADDITIONAL_DETAILS_SUCCESS'
export const API_V2_UPDATE_ADDITIONAL_DETAILS_FAILURE = 'API_V2_UPDATE_ADDITIONAL_DETAILS_FAILURE'

export const API_V2_ADD_PAYMENTS_REQUEST = 'API_V2_ADD_PAYMENTS_REQUEST'
export const API_V2_ADD_PAYMENTS_SUCCESS = 'API_V2_ADD_PAYMENTS_SUCCESS'
export const API_V2_ADD_PAYMENTS_FAILURE = 'API_V2_ADD_PAYMENTS_FAILURE'

export const API_V2_UPDATE_PAYMENTS_REQUEST = 'API_V2_UPDATE_PAYMENTS_REQUEST'
export const API_V2_UPDATE_PAYMENTS_SUCCESS = 'API_V2_UPDATE_PAYMENTS_SUCCESS'
export const API_V2_UPDATE_PAYMENTS_FAILURE = 'API_V2_UPDATE_PAYMENTS_FAILURE'

export const API_V2_REMOVE_ITEMS_REQUEST = 'API_V2_REMOVE_ITEMS_REQUEST'
export const API_V2_REMOVE_ITEMS_SUCCESS = 'API_V2_REMOVE_ITEMS_SUCCESS'
export const API_V2_REMOVE_ITEMS_FAILURE = 'API_V2_REMOVE_ITEMS_FAILURE'

export const API_V2_GET_AGENT_SHIFT_REPORT_REQUEST = 'API_V2_GET_AGENT_SHIFT_REPORT_REQUEST'
export const API_V2_GET_AGENT_SHIFT_REPORT_SUCCESS = 'API_V2_GET_AGENT_SHIFT_REPORT_SUCCESS'
export const API_V2_GET_AGENT_SHIFT_REPORT_FAILURE = 'API_V2_GET_AGENT_SHIFT_REPORT_FAILURE'

export const API_V2_UPDATE_CUSTOMER_REQUEST = 'API_V2_UPDATE_CUSTOMER_REQUEST'
export const API_V2_UPDATE_CUSTOMER_SUCCESS = 'API_V2_UPDATE_CUSTOMER_SUCCESS'
export const API_V2_UPDATE_CUSTOMER_FAILURE = 'API_V2_UPDATE_CUSTOMER_FAILURE'

export const API_V2_ADD_CUSTOMER_REQUEST = 'API_V2_ADD_CUSTOMER_REQUEST'
export const API_V2_ADD_CUSTOMER_SUCCESS = 'API_V2_ADD_CUSTOMER_SUCCESS'
export const API_V2_ADD_CUSTOMER_FAILURE = 'API_V2_ADD_CUSTOMER_FAILURE'

export const API_V2_GET_TICKET_DETAILS_REQUEST = 'API_V2_GET_TICKET_DETAILS_REQUEST'
export const API_V2_GET_TICKET_DETAILS_SUCCESS = 'API_V2_GET_TICKET_DETAILS_SUCCESS'
export const API_V2_GET_TICKET_DETAILS_FAILURE = 'API_V2_GET_TICKET_DETAILS_FAILURE'

export const API_V2_UPDATE_PASSENGERS_REQUEST = 'API_V2_UPDATE_PASSENGERS_REQUEST'
export const API_V2_UPDATE_PASSENGERS_SUCCESS = 'API_V2_UPDATE_PASSENGERS_SUCCESS'
export const API_V2_UPDATE_PASSENGERS_FAILURE = 'API_V2_UPDATE_PASSENGERS_FAILURE'

export const API_V2_CREATE_CRM_USER_REQUEST = 'API_V2_CREATE_CRM_USER_REQUEST'
export const API_V2_CREATE_CRM_USER_SUCCESS = 'API_V2_CREATE_CRM_USER_SUCCESS'
export const API_V2_CREATE_CRM_USER_FAILURE = 'API_V2_CREATE_CRM_USER_FAILURE'

export const API_V2_CANCEL_BOOKING_REQUEST = 'API_V2_CANCEL_BOOKING_REQUEST'
export const API_V2_CANCEL_BOOKING_SUCCESS = 'API_V2_CANCEL_BOOKING_SUCCESS'
export const API_V2_CANCEL_BOOKING_FAILURE = 'API_V2_CANCEL_BOOKING_FAILURE'
