import { CMS_BLOCK_OFFLINE, LOCAL_STORAGE_CMS_BLOCKS_OFFLINE } from '../../../../constants'
import {
  API_IS_BACK_ONLINE,
  API_IS_OFFLINE,
  API_RETRY_FINISHED,
  API_RETRY_ATTEMPT,
  API_CMS_LOAD_BLOCKS_SUCCESS
} from 's3p-js-lib/src/redux/actions/types'
import localStorage from 's3p-js-lib/src/local-storage'

const defaultState = {
  nextRetry: null,
  attempt: 0,
  isRetry: false,
  isOffline: false,
  feedbackMessage: 'offline.title'
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case API_CMS_LOAD_BLOCKS_SUCCESS:
      if (action.blockNames.includes(CMS_BLOCK_OFFLINE)) {
        const block = action.response.data.find(cmsBlock => cmsBlock.name === CMS_BLOCK_OFFLINE)
        localStorage.set(LOCAL_STORAGE_CMS_BLOCKS_OFFLINE, {
          [CMS_BLOCK_OFFLINE]: block
        })
      }
      return state
    case API_RETRY_ATTEMPT:
      return {
        ...state,
        isOffline: true,
        isRetry: true,
        attempt: action.attempt,
        nextTry: action.nextTry,
        feedbackMessage: action.feedbackMessage
      }
    case API_RETRY_FINISHED:
      return {
        ...state,
        isRetry: false,
        isOffline: !action.isSuccess
      }
    case API_IS_OFFLINE:
      return {...state, isOffline: true}
    case API_IS_BACK_ONLINE:
      return defaultState
    default:
      return state
  }
}
