import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { confirmCash } from '../../../../../redux/actions/containers/aftersales/booking/refund'
import RefundCash from '../../../../../components/aftersales/booking/payment-details/refund/refund-cash'
import { openMachineCashDrawer } from '../../../../../redux/actions/machine/cash-drawer'

class RefundCashContainer extends Component {
  static propTypes = {
    nextStep: PropTypes.func.isRequired,
    confirmCash: PropTypes.func.isRequired,
    openMachineCashDrawer: PropTypes.func.isRequired

  }

  constructor (...args) {
    super(...args)

    this.handleConfirmRefundCash = this.handleConfirmRefundCash.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.props.openMachineCashDrawer()
  }

  async handleConfirmRefundCash () {
    const result = await this.props.confirmCash()
    if (result) {
      this.props.nextStep()
    }
  }

  render () {
    return <RefundCash {...this.props} confirmRefundCash={this.handleConfirmRefundCash} />
  }
}

const mapDispatchToProps = {
  confirmCash,
  openMachineCashDrawer
}

const mapStateToProps = state => ({
  isLoading: !state.containers.aftersales.booking.refund.isAborting &&
    (state.api.v2.loading.addPayments || state.api.v2.loading.updatePayments || state.api.loading.requestRefund)
})

export default connect(mapStateToProps, mapDispatchToProps)(RefundCashContainer)
