import { connect } from 'react-redux'
import containVisibleElement from '../../base/visible-element'
import {
  outboundTariffSegmentsSelector,
  inboundTariffSegmentsSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/tariff-segments'
import {
  overviewBookingSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import SegmentSetDirection from '../../../components/aftersales/booking/segment-set'

const _outboundTariffSegmentsSelector = outboundTariffSegmentsSelector(overviewBookingSelector)
const _inboundTariffSegmentsSelector = inboundTariffSegmentsSelector(overviewBookingSelector)

const mapStateToProps = (state, ownProps) => {
  const tariffSegments = ownProps.direction === 'inbound'
    ? _inboundTariffSegmentsSelector(state)
    : _outboundTariffSegmentsSelector(state)

  let firstTariffSegment = tariffSegments.find(segment => segment.requiredProducts.every(product => !product.cancelled))
  if (!firstTariffSegment && tariffSegments.length) {
    firstTariffSegment = tariffSegments[0]
  }

  return {
    segments: tariffSegments || [],
    hasCancelledItems: tariffSegments ? tariffSegments.some(segment =>
      segment.requiredProducts.some(product => product.cancelled) ||
      segment.additionalProducts.some(product => product.cancelled)
    ) : false,
    travelDate: firstTariffSegment && (firstTariffSegment.departureDate || firstTariffSegment.travelDate)
  }
}

export default connect(mapStateToProps)(containVisibleElement(
  'segment-set-inbound',
  'segment-set-outbound'
)(SegmentSetDirection))
