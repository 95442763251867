import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import isString from 'lodash/isString'
import {
  formFieldValuesSelectorCreator,
  formFieldValueSelectorCreator,
  formIsValidSelectorCreator
} from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import {
  fulfillmentMethodSelector,
  bookingNumberSelector,
  bookingSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { validate } from 's3p-js-lib/src/redux/actions/user-input/base/form'
import {
  SALES_CHANNEL_PROPERTY_REQUEST_CUSTOMER,
  FORM_CUSTOMER_DETAILS,
  FORM_PASSENGER_DETAILS
} from 's3p-js-lib/src/constants'
import { salesChannelPropertySelector } from 's3p-js-lib/src/redux/selectors/api/user/sales-channel-properties'
import scroller from 's3p-js-lib/src/misc/scroller'
import { provisionalOrCompletedBookingSelector } from '../../../redux/selectors/api/booking/booking'
import containBookingFlow from '../../../containers/reservations/booking-flow'
import { passengerDetailsSelector } from '../../../redux/selectors/containers/reservations/passengers-details'
import NextButton from '../../../components/reservations/booking-details/next-button'
import { showFeedback } from '../../../redux/actions/containers/reservations/booking-details/customer-details'
import { SCROLLER_BOOKING_DETAILS_CUSTOMER_DETAILS_FORM } from '../../../constants'
import { addCustomer } from '../../../redux/actions/api/v2/booking/add-customer'
import { updateCustomer } from '../../../redux/actions/api/v2/booking/update-customer'
import { updatePassengers } from 's3p-js-lib/src/redux/actions/api/v2/booking/update-passengers'
import {goToReservationsJourneySearch} from '../../../redux/actions/containers/base/routing'
import {resetReservations} from '../../../redux/actions/containers/reservations/finalize-booking'

class NextButtonContainer extends Component {
  static propTypes = {
    isLoadingUpdatePassengers: PropTypes.bool.isRequired,
    isLoadingAddCustomer: PropTypes.bool.isRequired,
    isLoadingUpdateCustomer: PropTypes.bool.isRequired,
    isCustomerDetailsFormValid: PropTypes.bool.isRequired,
    isPassengerDetailsFormValid: PropTypes.bool.isRequired,
    hasFulfillmentMethod: PropTypes.bool,
    validate: PropTypes.func.isRequired,
    process: PropTypes.func.isRequired,
    goForward: PropTypes.func.isRequired,
    showFeedback: PropTypes.func.isRequired,
    requireEmailOrMobile: PropTypes.bool.isRequired,
    hasEmailOrMobile: PropTypes.bool.isRequired,
    scrollToTopOfCustomerDetailsForm: PropTypes.func.isRequired,
    clearStateAndGoToReservationsJourneySearch: PropTypes.func.isRequired
  }

  constructor (...args) {
    super(...args)
    this.handleGoNext = this.handleGoNext.bind(this)
  }

  handleGoNext () {
    const {
      isCustomerDetailsFormValid,
      isPassengerDetailsFormValid,
      hasFulfillmentMethod,
      process,
      goForward,
      validate,
      showFeedback,
      requireEmailOrMobile,
      hasEmailOrMobile,
      scrollToTopOfCustomerDetailsForm,
      clearStateAndGoToReservationsJourneySearch
    } = this.props

    if (isCustomerDetailsFormValid && isPassengerDetailsFormValid && hasFulfillmentMethod) {
      if (requireEmailOrMobile && !hasEmailOrMobile) {
        scrollToTopOfCustomerDetailsForm()
        showFeedback()
      } else {
        Promise.all(process()).then(successes => {
          if (successes.every(success => success)) {
            goForward()
          } else {
            clearStateAndGoToReservationsJourneySearch()
          }
        })
      }
    } else {
      validate()
    }
  }

  render () {
    const {
      isLoadingUpdatePassengers,
      isLoadingAddCustomer,
      isLoadingUpdateCustomer,
      isCustomerDetailsFormValid,
      isPassengerDetailsFormValid,
      hasFulfillmentMethod,
      ...props
    } = this.props

    return (
      <NextButton
        onClick={this.handleGoNext}
        isLoading={
          isLoadingUpdatePassengers ||
          isLoadingAddCustomer ||
          isLoadingUpdateCustomer
        }
        isDisabled={!isCustomerDetailsFormValid || !isPassengerDetailsFormValid || !hasFulfillmentMethod}
        {...props}
      />
    )
  }
}

const emailAddressValueSelector = formFieldValueSelectorCreator(FORM_CUSTOMER_DETAILS, 'emailAddress')
const phoneNumberValueSelector = formFieldValueSelectorCreator(FORM_CUSTOMER_DETAILS, 'phoneNumber')

const customerDetailsFormIsValidSelector = formIsValidSelectorCreator(FORM_CUSTOMER_DETAILS)
const passengerDetailsFormIsValidSelector = formIsValidSelectorCreator(FORM_PASSENGER_DETAILS)
const customerDetailsFormFieldValuesSelector = formFieldValuesSelectorCreator(FORM_CUSTOMER_DETAILS)
const _passengerDetailsSelector = passengerDetailsSelector(FORM_PASSENGER_DETAILS)
const _fulfillmentMethodSelector = fulfillmentMethodSelector(provisionalOrCompletedBookingSelector)
const requestCustomerSelector = state => Boolean(
  salesChannelPropertySelector(SALES_CHANNEL_PROPERTY_REQUEST_CUSTOMER)(state)
)
const hasCustomerDetailsSelector = state => Object.values(customerDetailsFormFieldValuesSelector(state))
  .some(value => isString(value) && value !== '')

const hasEmailOrMobilePhoneSelector = state => Boolean(
  emailAddressValueSelector(state) || phoneNumberValueSelector(state)
)
const requireEmailOrMobile = state => requestCustomerSelector(state) || hasCustomerDetailsSelector(state)

const mapStateToProps = state => ({
  isLoadingUpdatePassengers: state.api.loading.updatePassengers,
  isLoadingAddCustomer: state.api.v2.loading.addCustomer,
  isLoadingUpdateCustomer: state.api.v2.loading.updateCustomer,
  isCustomerDetailsFormValid: customerDetailsFormIsValidSelector(state),
  isPassengerDetailsFormValid: passengerDetailsFormIsValidSelector(state),
  hasFulfillmentMethod: Boolean(_fulfillmentMethodSelector(state)),
  requireEmailOrMobile: requireEmailOrMobile(state),
  hasEmailOrMobile: hasEmailOrMobilePhoneSelector(state),
  scrollToTopOfCustomerDetailsForm: () => scroller(SCROLLER_BOOKING_DETAILS_CUSTOMER_DETAILS_FORM)
})

const mapDispatchToProps = {
  process: () => (dispatch, getState) => {
    const state = getState()
    const bookingNumber = bookingNumberSelector(bookingSelector)(state)
    const actions = [updatePassengers(bookingNumber, _passengerDetailsSelector(state))]

    if (hasCustomerDetailsSelector(state)) {
      const booking = bookingSelector(state)
      const customerDetails = {
        ...customerDetailsFormFieldValuesSelector(state),
        id: booking?.customer?.crm_customer_id
      }

      if (booking.customer) {
        actions.push(updateCustomer(bookingNumber, customerDetails))
      } else {
        actions.push(addCustomer(bookingNumber, customerDetails))
      }
    }

    return dispatch(actions)
  },
  validate: () => [
    validate(FORM_CUSTOMER_DETAILS),
    validate(FORM_PASSENGER_DETAILS)
  ],
  showFeedback,
  clearStateAndGoToReservationsJourneySearch: () => dispatch => {
    dispatch(resetReservations())
    dispatch(goToReservationsJourneySearch())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(containBookingFlow(NextButtonContainer))
