import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DescriptionList from '../../../elements/description-list/description-list'
import TextLabel from '../../../elements/text-label/text-label'
import FeedbackMachine from '../../../containers/base/last-machine-error-feedback'
import _t from 's3p-js-lib/src/translate'
import './read-ticket.scss'

const MD = 'aftersales.read-ticket.magstripe-data'

export default class MagstripeData extends Component {
  static propTypes = {
    magstripeData: PropTypes.object,
    isLoadingReadTicket: PropTypes.bool
  }

  renderFeedbackMachine () {
    return !this.props.isLoadingReadTicket
      ? <FeedbackMachine namespaces={['magneticTicketPrinterRead', 'magneticTicketPrinterEject']} />
      : null
  }

  render () {
    const {magstripeData} = this.props

    if (!magstripeData) {
      return this.renderFeedbackMachine()
    }
    const restrictions = magstripeData.modalRestrictions
      ? _t.message(`read-ticket.magstripe.modal-restrictions.${magstripeData.modalRestrictions}`)
      : ''

    const previousModeOfTravel = magstripeData.previousModeOfTravel
      ? _t.message(`read-ticket.magstripe.mode-of-travel.${magstripeData.previousModeOfTravel}`)
      : ''

    const daysOfWeek = magstripeData.daysOfWeekAvailable.map(day => {
      return _t.message(`read-ticket.days-of-week.${day}`)
    })

    const suburbanAccessAndTransfer = magstripeData.suburbanAccessAndTransfer.map(suburban => {
      return _t.message(`read-ticket.magstripe.suburban.${suburban}`)
    })

    return (
      <div>
        <div className='description-list-block'>
          <div className='description-list-block__content'>
            {this.renderFeedbackMachine()}
            <TextLabel text={_t.message(`${MD}.ticket-header`)} className='text-label-sub-heading' />
            <div className='description-list-group'>
              <div className='description-list-group__column'>
                <DescriptionList>
                  <dt><TextLabel text={_t.message(`${MD}.machine-of-issue`)} /></dt>
                  <dd><TextLabel text={`${magstripeData.machineOfIssue}`} /></dd>
                  <dt><TextLabel text={_t.message(`${MD}.boarding-station`)} /></dt>
                  <dd><TextLabel text={magstripeData.origin ? magstripeData.origin.name : magstripeData.originCode} /></dd>
                  <dt><TextLabel text={_t.message(`${MD}.suburban-access-and-transfer`)} /></dt>
                  <dd><TextLabel text={suburbanAccessAndTransfer.join(', ')} /></dd>
                  <dt><TextLabel text={_t.message(`${MD}.ticket-serial-number`)} /></dt>
                  <dd><TextLabel text={`${magstripeData.ticketSerial}`} /></dd>
                </DescriptionList>
              </div>
              <div className='description-list-group__column'>
                <DescriptionList>
                  <dt><TextLabel text={_t.message(`${MD}.ticket-type`)} /></dt>
                  <dd><TextLabel text={`${magstripeData.ticketType}`} /></dd>
                  <dt><TextLabel text={_t.message(`${MD}.destination-station`)} /></dt>
                  <dd><TextLabel text={magstripeData.destination ? magstripeData.destination.name : magstripeData.destinationCode} /></dd>
                  <dt><TextLabel text={_t.message(`${MD}.passenger-status`)} /></dt>
                  <dd><TextLabel text={`${magstripeData.passengerCode}`} /></dd>
                  <dt><TextLabel text={_t.message(`${MD}.days-of-week-available`)} /></dt>
                  <dd><TextLabel text={daysOfWeek.join(', ')} /></dd>
                </DescriptionList>
              </div>
            </div>
          </div>
        </div>
        <div className='description-list-block'>
          <div className='description-list-block__content'>
            <TextLabel text={_t.message(`${MD}.validation-header`)} className='text-label-sub-heading' />
            <div className='description-list-group'>
              <div className='description-list-group__column'>
                <DescriptionList>
                  <dt><TextLabel text={_t.message(`${MD}.start-of-validity-validation-day`)} /></dt>
                  <dd><TextLabel text={`${magstripeData.startOfValidityValidationDay}/${magstripeData.startOfValidityValidationMonth}`} /></dd>
                  <dt><TextLabel text={_t.message(`${MD}.start-of-validity-validation-year`)} /></dt>
                  <dd><TextLabel text={`${magstripeData.startOfValidityValidationYear}`} /></dd>
                  <dt><TextLabel text={_t.message(`${MD}.validation-time`)} /></dt>
                  <dd><TextLabel text={`${magstripeData.validationTimeHours}`} /></dd>
                  <dt><TextLabel text={_t.message(`${MD}.number-of-ptm-validations`)} /></dt>
                  <dd><TextLabel text={`${magstripeData.numberOfPtmValidations}`} /></dd>
                  <dt><TextLabel text={_t.message(`${MD}.number-of-journeys-allowed`)} /></dt>
                  <dd><TextLabel text={`${magstripeData.numberOfJourneysAllowed}`} /></dd>
                </DescriptionList>
              </div>
              <div className='description-list-group__column'>
                <DescriptionList>
                  <dt><TextLabel text={_t.message(`${MD}.last-day-of-validity`)} /></dt>
                  <dd><TextLabel text={`${magstripeData.lastDayOfValidity}/${magstripeData.lastMonthOfValidity}`} /></dd>
                  <dt><TextLabel text={_t.message(`${MD}.last-year-of-validity`)} /></dt>
                  <dd><TextLabel text={`${magstripeData.lastYearOfValidity}`} /></dd>
                  <dt><TextLabel text={_t.message(`${MD}.previous-mode-of-travel`)} /></dt>
                  <dd><TextLabel text={previousModeOfTravel} /></dd>
                  <dt><TextLabel text={_t.message(`${MD}.number-of-road-validations`)} /></dt>
                  <dd><TextLabel text={`${magstripeData.numberOfRoadValidations}`} /></dd>
                  <dt><TextLabel text={_t.message(`${MD}.number-of-journeys-taken`)} /></dt>
                  <dd><TextLabel text={`${magstripeData.numberOfJourneysTaken}`} /></dd>
                </DescriptionList>
              </div>
            </div>
          </div>
        </div>
        <div className='description-list-block'>
          <div className='description-list-block__content'>
            <TextLabel text={_t.message(`${MD}.technical-header`)} className='text-label-sub-heading' />
            <div className='description-list-group'>
              <div className='description-list-group__column'>
                <DescriptionList>
                  <dt><TextLabel text={_t.message(`${MD}.feeder-trips-taken`)} /></dt>
                  <dd><TextLabel text={`${magstripeData.feederTripsTaken}`} /></dd>
                  <dt><TextLabel text={_t.message(`${MD}.modal-restrictions`)} /></dt>
                  <dd><TextLabel text={restrictions} /></dd>
                </DescriptionList>
              </div>
              <div className='description-list-group__column'>
                <DescriptionList>
                  <dt><TextLabel text={_t.message(`${MD}.checksum`)} /></dt>
                  <dd><TextLabel text={`${magstripeData.checksum}`} /></dd>
                  <dt><TextLabel text={_t.message(`${MD}.checksum-primary-data`)} /></dt>
                  <dd><TextLabel text={`${magstripeData.checksumPrimaryData}`} /></dd>
                  <dt><TextLabel text={_t.message(`${MD}.time-of-day-valid-table`)} /></dt>
                  <dd><TextLabel text={`${magstripeData.timeOfDayValidTable}`} /></dd>
                </DescriptionList>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
