import React, { Component } from 'react'
import PropTypes from '../../../../misc/prop-types'
import TextLabel from '../../../../elements/text-label/text-label'
import Indicator from '../../../../elements/indicator/indicator'
import {
  PAYMENT_STATUS_S,
  PAYMENT_STATUS_P,
  PAYMENT_STATUS_F
} from 's3p-js-lib/src/constants'
import _t from 's3p-js-lib/src/translate'
import { PAYMENT_METHOD_CODE_REFUND, PAYMENT_METHOD_CODE_VOUCHER } from '../../../../constants'
import Icon from '../../../../elements/icon/icon'
import Dropdown from '../../../../elements/dropdown/dropdown'

export default class PaymentDetailsRow extends Component {
  static propTypes = {
    amount: PropTypes.number.isRequired,
    method: PropTypes.string.isRequired,
    paymentStatus: PropTypes.string.isRequired,
    reference: PropTypes.string,
    currency: PropTypes.string,
    refundProcessTimestamp: PropTypes.moment
  }

  _getStatus () {
    if (this.props.method === PAYMENT_METHOD_CODE_REFUND && !this.props.refundProcessTimestamp) {
      return 'not-yet-processed'
    } else {
      switch (this.props.paymentStatus) {
        case PAYMENT_STATUS_S:
          return 'confirmed'
        case PAYMENT_STATUS_P:
          return 'provisional'
        case PAYMENT_STATUS_F:
          return 'failed'
        default:
          return 'unknown'
      }
    }
  }

  render () {
    const status = this._getStatus()
    return (
      <tr>
        <td data-th-label={_t.message('aftersales.booking.payment-details.payment-method')}>
          <TextLabel name='payment-method' text={this.props.method} />
        </td>
        <td data-th-label={_t.message('aftersales.booking.payment-details.reference')}>
          {this.props.method === PAYMENT_METHOD_CODE_VOUCHER && this.props.amount === 0
            ? <TextLabel name='reference'>
              <TextLabel.Text text={this.props.reference} />
              <Dropdown
                name={`payment-details-tooltip_${this.props.method}-${this.props.reference}`}
                view={
                  <Dropdown.View name={`payment-details-tooltip_${this.props.method}-${this.props.reference}`} hideHeader>
                    <TextLabel name='tooltip' text={_t.message('aftersales.booking.payment-details.discount-voucher-used')} />
                  </Dropdown.View>
                }
              >
                <Icon name='information' className='align-right has--tooltip' />
              </Dropdown>
            </TextLabel>
            : <TextLabel name='reference' text={this.props.reference} /> }

        </td>
        <td data-th-label={_t.message('aftersales.booking.payment-details.payment-status')}>
          <Indicator
            name='status'
            className={status}
            text={_t.message(`aftersales.booking.payment-details.status.${status}`)}
          />
        </td>
        <td data-th-label={_t.message('aftersales.booking.payment-details.amount')}>
          <TextLabel name='price' text={_t.formatCurrency(this.props.amount, this.props.currency)} />
        </td>
      </tr>
    )
  }
}
