/* globals S3P_SETTINGS: true */

import { USER_INPUT_SET_CURRENCY, CLEAR_STATE } from '../../../actions/types'
import storage from '../../../../storage'

export const initialState = 'EUR'

const defaultState = storage.get('currency') || S3P_SETTINGS.s3Passenger.features.defaultCurrency || initialState

export default function currency (state = defaultState, action) {
  switch (action.type) {
    case USER_INPUT_SET_CURRENCY:
      storage.set('currency', action.currency)
      return action.currency
    case CLEAR_STATE:
      return action.namespaces.includes('api.orientation.currency') ? initialState : state
    default:
      return state
  }
}
