import { connect } from 'react-redux'
import { agentShiftSelector } from 's3p-js-lib/src/redux/selectors/api/user/agent/shift'
import Shift, {
  SHOW_SHIFT_START,
  SHOW_SHIFT_RESUME,
  SHOW_SHIFT_END
} from '../../../components/agent/shift/shift'
import { hasValidLeapConfiguration } from '../../../misc/storage'

const mapStateToProps = state => {
  const shift = agentShiftSelector(state)

  let showShiftType = SHOW_SHIFT_START
  if (!state.containers.agent.shift.loadingStartShift && shift && !shift.endDateTime) {
    if (state.machine.terminalInformation.data.terminalId === shift.deviceId && hasValidLeapConfiguration()) {
      showShiftType = SHOW_SHIFT_RESUME
    } else {
      showShiftType = SHOW_SHIFT_END
    }
  }

  return ({
    loading: state.api.loading.getAgentShift,
    showShiftType
  })
}

export default connect(mapStateToProps)(Shift)
