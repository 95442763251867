import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Modal from '../../../../elements/modal/modal'
import LastApiErrorFeedback from '../../../../containers/base/last-api-error-feedback'

export default class FindCustomersDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired
  }

  render () {
    const {onClose, children, ...props} = this.props
    return (

      <Modal name='search-customer'
        onHideElement={onClose}
        header={<Modal.Header title={_t.message('booking-details.find-customers-dialog.title')} />}
        {...props}
      >
        <LastApiErrorFeedback
          namespaces={['findCustomers', 'unhandled']}
          defaultMessage='application-error.known-error'
        />
        {children}
      </Modal>
    )
  }
}
