import {
  API_GET_JOURNEY_DETAILS_REQUEST,
  API_GET_JOURNEY_DETAILS_SUCCESS,
  API_GET_JOURNEY_DETAILS_FAILURE
} from 's3p-js-lib/src/redux/actions/types'
import Client from 's3p-js-lib/src/api/client'
import ensureToken from 's3p-js-lib/src/api/ensure-token'

const journeyDetailsRequest = services => ({type: API_GET_JOURNEY_DETAILS_REQUEST, services})
const journeyDetailsSuccess = (services, legs, response) =>
  ({type: API_GET_JOURNEY_DETAILS_SUCCESS, services, legs, response})
const journeyDetailsFailure = (services, error) => ({type: API_GET_JOURNEY_DETAILS_FAILURE, services, error})

export const getJourneyDetails = legs => ensureToken(async (token, dispatch) => {
  const services = legs.map(leg => leg.serviceIdentifier)

  try {
    dispatch(journeyDetailsRequest(services))
    const response = await Client.getJourneyDetails(token, services)
    dispatch(journeyDetailsSuccess(services, legs, response))
  } catch (error) {
    dispatch(journeyDetailsFailure(services, error))
    throw error
  }
})
