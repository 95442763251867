import {
  API_UPDATE_PASSENGERS_INITIATE_REQUEST,
  API_UPDATE_PASSENGERS_INITIATE_SUCCESS,
  API_UPDATE_PASSENGERS_INITIATE_FAILURE
} from '../../../types'
import Client from '../../../../../api/client'
import ensureToken from '../../../../../api/ensure-token'

const updatePassengersInitiateRequest = bookingNumber => ({type: API_UPDATE_PASSENGERS_INITIATE_REQUEST, bookingNumber})
const updatePassengersInitiateSuccess = response => ({type: API_UPDATE_PASSENGERS_INITIATE_SUCCESS, response})
const updatePassengersInitiateFailure = error => ({type: API_UPDATE_PASSENGERS_INITIATE_FAILURE, error})

export const updatePassengersInitiate = bookingNumber => ensureToken(async function (token, dispatch) {
  try {
    dispatch(updatePassengersInitiateRequest(bookingNumber))
    const response = await Client.updatePassengersInitiate(token, bookingNumber)
    dispatch(updatePassengersInitiateSuccess(response))
  } catch (error) {
    dispatch(updatePassengersInitiateFailure(error))
    throw error
  }
})
