import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'
import { toUtcDateMoment } from 's3p-js-lib/src/misc/date'
import { stationsSelector } from 's3p-js-lib/src/redux/selectors/api/orientation/stations'
import { loadValues } from 's3p-js-lib/src/redux/actions/user-input/base/form'
import { executeJourneySearch } from 's3p-js-lib/src/redux/actions/api/orientation/journey-search'
import { FORM_JOURNEY_SEARCH } from 's3p-js-lib/src/constants-form'
import { maxPersonsSelector } from '../../../../selectors/containers/base/max-passengers'
import { currentQuerySelector } from '../../../../selectors/containers/base/routing'
import { journeySearchFormValuesSelector } from '../../../../selectors/containers/base/journey-search/journey-search'
import { showModal } from '../../base/visible-element'
import { baseJourneySearch } from '../../base/journey-search/journey-search'
import { MAX_PASSENGER_MODAL } from '../../../../../components/base/max-passengers-modal'
import { DISABILITY_TYPE_NONE, JOURNEY_SEARCH_PRODUCT_FAMILIES } from '../../../../../constants'

const performJourneySearch = values => (dispatch, getState) =>
  dispatch(executeJourneySearch({
    origin: values.origin.UICStationCode,
    destination: values.destination.UICStationCode,
    outboundDate: values.outboundDate,
    outboundTimeEarliest: values.outboundTimeEarliest,
    outboundTimeLatest: values.outboundTimeLatest,
    inboundDate: values.inboundDate,
    inboundTimeEarliest: values.inboundTimeEarliest,
    inboundTimeLatest: values.inboundTimeLatest,
    currency: getState().api.orientation.currency,
    passengers: values.passengers.map(passenger => ({
      type: passenger.type,
      disability_type: passenger.disabilityType,
      discount_cards: passenger.discountCards
    })),
    productFamilies: values.productFamilies
  }))

export const journeySearch = values => async (dispatch, getState) => {
  if (values.passengers.length > maxPersonsSelector(getState())) {
    dispatch(showModal(MAX_PASSENGER_MODAL))
  } else {
    const newValues = {
      ...values,
      productFamilies: JOURNEY_SEARCH_PRODUCT_FAMILIES,
      passengers: values.passengers.map(passenger => ({
        ...passenger,
        disabilityType: passenger.disabilityType || DISABILITY_TYPE_NONE
      }))
    }
    await dispatch(baseJourneySearch()(performJourneySearch)(newValues))
  }
}

const convertQueryToJourneySearch = (values, getState) => {
  const stations = stationsSelector(getState())
  const getStation = code => stations && stations.find(station => station.UICStationCode === code)

  return omitBy({
    origin: values.origin && getStation(values.origin),
    destination: values.destination && getStation(values.destination),
    outboundDate: values.outboundDate && toUtcDateMoment(values.outboundDate),
    inboundDate: values.inboundDate && toUtcDateMoment(values.inboundDate),
    passengers: values.passengers
  }, isEmpty)
}

export const loadDefaultFormValues = () => (dispatch, getState) => {
  const initialValues = journeySearchFormValuesSelector(getState())
  const updatedDefaultValues = convertQueryToJourneySearch(
    currentQuerySelector(getState()),
    getState
  )

  dispatch(loadValues(
    FORM_JOURNEY_SEARCH,
    {...initialValues, ...updatedDefaultValues}
  ))
}
