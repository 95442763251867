import { addPayments } from 's3p-js-lib/src/redux/actions/api/v2/payment/add-payments'
import { updatePayments } from 's3p-js-lib/src/redux/actions/api/v2/payment/update-payments'
import {
  overviewBookingSelector,
  bookingNumberSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { provisionalOrOverviewBookingSelector } from '../../../selectors/api/booking/booking'
import {
  getPaymentsMadeByMethod,
  payoutRecordsSelector
} from '../../../selectors/containers/aftersales/booking/refund'
import {
  PAYMENT_STATUS_S,
  PAYMENT_STATUS_P,
  PAYMENT_STATUS_F
} from 's3p-js-lib/src/constants'
import {
  PAYMENT_METHOD_CODE_REFUND,
  DOCUMENT_TEMPLATE_NAME_PED_REFUND_CUSTOMER_RECEIPT,
  DOCUMENT_TEMPLATE_NAME_PED_REFUND_MERCHANT_RECEIPT,
  CURRENCY_EURO,
  PAYMENT_METHOD_PD_CREDIT_DEBIT_CARD,
  PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD,
  DOCUMENT_TEMPLATE_NAME_CASH_REFUND_CUSTOMER_RECEIPT,
  DOCUMENT_TEMPLATE_NAME_CASH_REFUND_MERCHANT_RECEIPT, PAYMENT_METHOD_CASH
} from '../../../../constants'
import { sendMachineReceiptPrint } from '../../machine/receipt-printer'
import { createReceipt } from '../../../../misc/receipt-template-parser'
import { refundResultSelector } from '../../../selectors/machine/pin-payment'
import { paymentsWithoutFailedSelector } from '../../../selectors/api/booking/payments'
import { isPedPaymentMethod } from '../../../../misc/utils'
import { machineStationSelector } from '../../../selectors/containers/base/stations'
import { agentShiftSelector } from 's3p-js-lib/src/redux/selectors/api/user/agent/shift'
import { requiredProductsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/products'

const payoutPrefix = 'PAYOUT_'
const balancePrefix = 'BALANCE_'

const _payoutRecordsSelector = payoutRecordsSelector(overviewBookingSelector)

const preparePayments = (originalPayment, refundPayment, amount) => {
  const newReferenceId = Math.floor(Math.random() * 1000000)
  const paymentRef = `${payoutPrefix}${newReferenceId}`
  const balanceRef = `${balancePrefix}${newReferenceId}`
  const method = originalPayment.method === PAYMENT_METHOD_PD_CREDIT_DEBIT_CARD
    ? PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD
    : originalPayment.method

  return [{
    reference: paymentRef,
    amount: -amount,
    currency: CURRENCY_EURO,
    method,
    status: PAYMENT_STATUS_P,
    originalPaymentId: originalPayment.refId
  }, {
    reference: balanceRef,
    amount: amount,
    currency: CURRENCY_EURO,
    method: PAYMENT_METHOD_CODE_REFUND,
    status: PAYMENT_STATUS_S,
    originalPaymentId: refundPayment.refId
  }]
}

const isRefundRecord = payment => payment.refundAvailable && payment.amount < 0 && payment.method === PAYMENT_METHOD_CODE_REFUND

const findBalanceRecordByPayout = (payoutPayment, payments) => {
  const identifier = payoutPayment.reference.substring(payoutPrefix.length)
  return payments.find(
    record => record.method === PAYMENT_METHOD_CODE_REFUND && record.reference === `${balancePrefix}${identifier}`
  )
}

const getUpdatedPayments = (bookingPayments, paymentMethod) => {
  const refundRecords = bookingPayments.filter(isRefundRecord)

  const payoutRecords = bookingPayments
    .filter(payment =>
      payment.reference &&
      payment.reference.indexOf(payoutPrefix) === 0 &&
      payment.method === paymentMethod &&
      payment.paymentStatus === PAYMENT_STATUS_P
    )

  const updatedPayments = [].concat(
    payoutRecords
      .map(payoutPayment => ({
        ref: bookingPayments.find(payment => payment.reference === payoutPayment.reference).refId,
        status: PAYMENT_STATUS_S,
        refundable: true
      })),
    payoutRecords
      .map(payoutRecord => {
        const balancePayment = findBalanceRecordByPayout(payoutRecord, bookingPayments)
        return {
          ref: bookingPayments.find(payment => payment.reference === balancePayment.reference).refId,
          status: PAYMENT_STATUS_S,
          refundProcessed: true,
          refundable: false
        }
      })
  )

  const payoutAmount = payoutRecords.reduce((amount, payment) => amount + payment.amount, 0)

  refundRecords.forEach(refundRecord => {
    const refundedAmount = bookingPayments
      .filter(payment => payment.originalPaymentId === refundRecord.refId && payment.refundProcessTimestamp)
      .reduce((amount, payment) => amount + payment.amount, 0)

    const balanceAmount = Math.round(100 * (refundRecord.amount + refundedAmount - payoutAmount))
    if (balanceAmount === 0) {
      updatedPayments.push({
        refundProcessed: true,
        status: PAYMENT_STATUS_S,
        ref: refundRecord.refId
      })
    }
  })

  return updatedPayments
}

export const initiateRefund = localPaymentMethods => async (dispatch, getState) => {
  const bookingNumber = bookingNumberSelector(overviewBookingSelector)(getState())
  const bookingPayments = paymentsWithoutFailedSelector(overviewBookingSelector)(getState())
  const refundRecords = bookingPayments.filter(isRefundRecord)

  if (!refundRecords.length) {
    return false
  }

  const payments = []
  const paymentMethods = getPaymentsMadeByMethod(bookingPayments)
  const payoutMethods = paymentMethods.filter(payment => localPaymentMethods.includes(payment.method) && payment.amount > 0)

  refundRecords.forEach(refundRecord => {
    let outstandingAmount = Math.abs(refundRecord.amount)
    let paymentMethodCounter = 0

    while (outstandingAmount > 0 && payoutMethods[paymentMethodCounter]) {
      const payoutMethod = payoutMethods[paymentMethodCounter]

      const payoutAmount = Math.min(outstandingAmount, payoutMethod.amount)

      const originalPayment = payoutMethod.payments.sort((a, b) => a.amount < b.amount ? -1 : 1)[0]

      payments.push(...preparePayments(originalPayment, refundRecord, payoutAmount))

      payoutMethod.amount -= payoutAmount
      outstandingAmount -= payoutAmount
      paymentMethodCounter++
    }
  })

  if (payments.length) {
    return dispatch(addPayments(payments, bookingNumber))
  }
  return true
}

export const confirmRefunds = paymentMethod => async (dispatch, getState) => {
  const bookingNumber = bookingNumberSelector(provisionalOrOverviewBookingSelector)(getState())
  const bookingPayments = paymentsWithoutFailedSelector(provisionalOrOverviewBookingSelector)(getState())
  const updatedPayments = getUpdatedPayments(bookingPayments, paymentMethod)

  await dispatch(updatePayments(updatedPayments, bookingNumber))
  if (isPedPaymentMethod(paymentMethod)) {
    dispatch(printPedRefundReceipt())
  } else if (paymentMethod === PAYMENT_METHOD_CASH) {
    dispatch(printCashRefundReceipt())
  }
  return true
}

export const printPedRefundReceipt = () => async (dispatch, getState) => {
  const state = getState()
  const pedPayout = (_payoutRecordsSelector(state) || []).find(payout => isPedPaymentMethod(payout.method))
  const refundResult = refundResultSelector(state)
  const bookingNumber = bookingNumberSelector(provisionalOrOverviewBookingSelector)(state)

  if (pedPayout &&
    await dispatch(sendMachineReceiptPrint(createReceipt(
      DOCUMENT_TEMPLATE_NAME_PED_REFUND_CUSTOMER_RECEIPT,
      refundResult,
      pedPayout.amount,
      machineStationSelector(state),
      bookingNumber
    )))
  ) {
    return dispatch(sendMachineReceiptPrint(
      createReceipt(
        DOCUMENT_TEMPLATE_NAME_PED_REFUND_MERCHANT_RECEIPT,
        refundResult,
        pedPayout.amount,
        machineStationSelector(state),
        bookingNumber
      )
    ))
  }
}

export const printCashRefundReceipt = () => async (dispatch, getState) => {
  const state = getState()
  const cashPayout = (_payoutRecordsSelector(state) || []).find(payout => payout.method === PAYMENT_METHOD_CASH)
  const bookingNumber = bookingNumberSelector(provisionalOrOverviewBookingSelector)(state)
  const requiredProducts = requiredProductsSelector(provisionalOrOverviewBookingSelector)(state)
  const ticketNumbers = requiredProducts
    .reduce((tickets, {cancelled, ticketNumber}) => cancelled ? [...tickets, ticketNumber] : tickets, [])
    .join(', ')
  const shift = agentShiftSelector(state)
  const deviceId = state.machine.terminalInformation.data.terminalId || ''

  if (cashPayout &&
    await dispatch(sendMachineReceiptPrint(createReceipt(
      DOCUMENT_TEMPLATE_NAME_CASH_REFUND_CUSTOMER_RECEIPT,
      {
        deviceId,
        shiftId: shift.shiftId,
        ticketNumbers,
        paymentMethod: PAYMENT_METHOD_CASH
      },
      cashPayout.amount,
      machineStationSelector(state),
      bookingNumber
    )))
  ) {
    return dispatch(sendMachineReceiptPrint(createReceipt(
      DOCUMENT_TEMPLATE_NAME_CASH_REFUND_MERCHANT_RECEIPT,
      {
        deviceId,
        shiftId: shift.shiftId,
        ticketNumbers,
        paymentMethod: PAYMENT_METHOD_CASH
      },
      cashPayout.amount,
      machineStationSelector(state),
      bookingNumber
    )))
  }
}

export const cancelPendingRefunds = () => async (dispatch, getState) => {
  const bookingNumber = bookingNumberSelector(provisionalOrOverviewBookingSelector)(getState())
  const bookingPayments = paymentsWithoutFailedSelector(provisionalOrOverviewBookingSelector)(getState())

  const updatedPayments = bookingPayments
    .filter(payment =>
      payment.paymentStatus === PAYMENT_STATUS_P &&
      payment.reference.indexOf(payoutPrefix) === 0
    )
    .map(payoutPayment => [payoutPayment, findBalanceRecordByPayout(payoutPayment, bookingPayments)])
    .reduce((payments, payment) => payments.concat(payment), [])
    .map(payment => ({
      ref: payment.refId,
      status: PAYMENT_STATUS_F
    }))

  if (updatedPayments.length) {
    return dispatch(updatePayments(updatedPayments, bookingNumber))
  }
  return true
}
