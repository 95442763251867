import {
  API_CREATE_PROVISIONAL_BOOKING_SUCCESS,
  API_UPDATE_BOOKING_SUCCESS,
  API_PROCESS_DEEP_LINK_SUCCESS,
  API_UPDATE_PASSENGERS_INITIATE_SUCCESS,
  API_CONTINUE_PROVISIONAL_BOOKING_SUCCESS,
  USER_INPUT_FORM_LOAD_VALUES,
  USER_INPUT_FORM_CHANGE_FIELD,
  USER_INPUT_FORM_DESTROY,
  CLEAR_STATE
} from '../../../../actions/types'
import { FORM_CUSTOMER_DETAILS } from '../../../../../constants-form'

export const initialState = {
  passengerIds: []
}

const updateStateByBooking = (state, booking) => booking && booking.passengers ? {
  ...state,
  passengerIds: booking.passengers.map(passenger => passenger.id)
} : state

const updateFirstPassengerStateByCustomerData = (
  state,
  customerIsPassenger,
  titleValue,
  firstNameValue,
  lastNameValue,
  phoneNumberValue,
  emailAddressValue
) => {
  const subForms = state.subForms || {}
  const fields = subForms.passenger_1 ? subForms.passenger_1.fields || {} : {}
  return {
    ...state,
    subForms: {
      ...subForms,
      passenger_1: {
        disabled: customerIsPassenger,
        fields: {
          ...fields,
          title: {
            ...fields.title,
            value: titleValue
          },
          firstName: {
            ...fields.firstName,
            value: firstNameValue
          },
          lastName: {
            ...fields.lastName,
            value: lastNameValue
          },
          phoneNumber: {
            ...fields.phoneNumber,
            value: phoneNumberValue
          },
          emailAddress: {
            ...fields.emailAddress,
            value: emailAddressValue
          }
        }
      }
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_INPUT_FORM_DESTROY:
      return action.formName === 'passengerDetails' ? initialState : state
    case USER_INPUT_FORM_CHANGE_FIELD:
      if (
        action.formName === FORM_CUSTOMER_DETAILS &&
        (action.otherValues.isPassenger || action.fieldName === 'isPassenger') &&
        ['isPassenger', 'firstName', 'lastName', 'title', 'phoneNumber', 'emailAddress'].includes(action.fieldName)
      ) {
        const subForm = state.subForms ? state.subForms.passenger_1 || {} : {}
        const customerIsPassenger = action.fieldName === 'isPassenger'
          ? action.value
          : action.otherValues.isPassenger ? action.otherValues.isPassenger : subForm.disabled
        const titleValue = customerIsPassenger ? action.fieldName === 'title'
          ? action.value
          : action.otherValues.title : undefined
        const firstNameValue = customerIsPassenger ? action.fieldName === 'firstName'
          ? action.value
          : action.otherValues.firstName : undefined
        const lastNameValue = customerIsPassenger ? action.fieldName === 'lastName'
          ? action.value
          : action.otherValues.lastName : undefined
        const phoneNumberValue = customerIsPassenger ? action.fieldName === 'phoneNumber'
          ? action.value
          : action.otherValues.phoneNumber : undefined
        const emailAddressValue = customerIsPassenger
          ? action.fieldName === 'emailAddress'
            ? action.value
            : action.otherValues.emailAddress
          : undefined
        return updateFirstPassengerStateByCustomerData(
          state,
          customerIsPassenger,
          titleValue,
          firstNameValue,
          lastNameValue,
          phoneNumberValue,
          emailAddressValue
        )
      }
      return state
    case USER_INPUT_FORM_LOAD_VALUES:
      if (action.formName === FORM_CUSTOMER_DETAILS && action.values.isPassenger) {
        return updateFirstPassengerStateByCustomerData(
          state,
          true,
          action.values.title,
          action.values.firstName || undefined,
          action.values.lastName || undefined,
          action.values.phoneNumber || undefined,
          action.values.emailAddress || undefined
        )
      }
      return state
    case API_CREATE_PROVISIONAL_BOOKING_SUCCESS:
    case API_UPDATE_BOOKING_SUCCESS:
      return updateStateByBooking(state, action.response.data.booking)
    case API_CONTINUE_PROVISIONAL_BOOKING_SUCCESS:
      return updateStateByBooking(state, action.response.data)
    case API_PROCESS_DEEP_LINK_SUCCESS:
      return updateStateByBooking(state, action.response.data.session.booking)
    case API_UPDATE_PASSENGERS_INITIATE_SUCCESS:
      return updateStateByBooking(state, action.response.original_booking)
    case CLEAR_STATE:
      return action.namespaces.includes(`userInput.form.${FORM_CUSTOMER_DETAILS}`) ? initialState : state
    default:
      return state
  }
}
