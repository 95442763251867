import Validator from './validator'

export default {
  fields: {
    currentPassword: {
      disabled: false,
      isRequired: true,
      formatters: [],
      validators: [
        Validator.isRequired
      ]
    },
    newPassword: {
      disabled: false,
      isRequired: true,
      formatters: [],
      validators: [
        Validator.isRequired,
        Validator.passwordStrength
      ]
    },
    newPasswordRepeated: {
      disabled: false,
      isRequired: true,
      formatters: [],
      validators: [
        Validator.isRequired,
        Validator.isRepeated('newPassword')
      ]
    }
  }
}
