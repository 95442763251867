import React from 'react'
import { Route } from 'react-router'
import AdditionalProductsLayout from '../../layouts/aftersales/additional-products'
import { isAdditionalProductsAvailableSelector } from '../../redux/selectors/containers/base/aftersales'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import container from '../../containers/aftersales/auth'

export default (
  <Route
    key='aftersales-additional-products'
    path='/:lang/aftersales/additional-products'
    component={container(
      isAdditionalProductsAvailableSelector(originalBookingSelector),
      AdditionalProductsLayout
    )}
  />
)
