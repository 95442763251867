import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import SideBar from '../../../elements/sidebar/sidebar'
import TextLabel from '../../../elements/text-label/text-label'
import Icon from '../../../elements/icon/icon'
import Button from '../../../elements/button/button'
import SideBarCartItem from './sidebar-cart-item'
import SideBarCartPayment from './sidebar-cart-payment'
import ReturnPaymentModal from '../../../containers/tickets/return-payment-modal'
import './cart.scss'

export default class SidebarCart extends Component {
  static propTypes = {
    handleShowShortcuts: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      itemRefs: PropTypes.array.isRequired
    })).isRequired,
    totalPrice: PropTypes.number.isRequired,
    totalPriceToBePaid: PropTypes.number,
    handleCancelCart: PropTypes.func.isRequired,
    removeItems: PropTypes.func.isRequired,
    removeVoucher: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    hasCompletedBooking: PropTypes.bool,
    payments: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired
    })).isRequired,
    showReturnPaymentModal: PropTypes.bool,
    hasPayments: PropTypes.bool
  }

  render () {
    return (
      <SideBar
        name='cart'
        title={_t.message('cart.title')}
        description={_t.message('cart.description')}
        header={this._renderHeaderWithButtons()}
      >
        <div className='cart is-scrollable'>
          <div className='cart__content'>
            {this.props.items.map(item => (
              <SideBarCartItem
                key={item.itemRefs[0]}
                item={item}
                removeItems={this.props.removeItems}
                disabled={this.props.isLoading || this.props.hasCompletedBooking || this.props.hasPayments}
              />
            ))}
          </div>
          <div className='cart__total'>
            <div className='cart-total'>
              <div className='cart-total__total-price'>
                <TextLabel name='total-price-description' text={_t.message('cart.total-price')} />
                <TextLabel
                  name='total-price-value'
                  text={_t.formatCurrency(this.props.totalPrice)}
                />
              </div>
              {this.props.payments.map(payment => (
                <SideBarCartPayment
                  key={payment.id}
                  payment={payment}
                  removeVoucher={this.props.removeVoucher}
                  disabled={this.props.isLoading || this.props.hasCompletedBooking}
                />
              ))}
              {this.props.totalPriceToBePaid >= 0
                ? (
                  <div className='cart-total__to-be-paid'>
                    <TextLabel name='to-be-paid-description' text={_t.message('cart.to-be-paid')} />
                    <TextLabel
                      name='to-be-paid-value'
                      text={_t.formatCurrency(this.props.totalPriceToBePaid)}
                    />
                  </div>
                ) : null}
            </div>
          </div>
          <div className='cart__reset'>
            <Button
              type='button'
              name='abort'
              className='alert'
              tabIndex='-1'
              disabled={this.props.isLoading}
              onClick={this.props.handleCancelCart}
            >
              <TextLabel name='abort'>
                <Icon name='cross' className='align-left' />
                <TextLabel.Text name='abort' text={_t.message('cart.abort')} />
              </TextLabel>
            </Button>
          </div>
        </div>
        {this.props.showReturnPaymentModal ? <ReturnPaymentModal /> : null}
      </SideBar>
    )
  }

  _renderHeaderWithButtons () {
    return (
      <SideBar.Header>
        <Button
          type='button'
          name='show-shortcuts'
          tabIndex='-1'
          onClick={this.props.handleShowShortcuts}
        >
          <TextLabel name='show-shortcuts'>
            <Icon name='arrow-right' className='align-left' />
            <TextLabel.Text name='show-shortcuts' text={_t.message('shortcuts.title')} />
          </TextLabel>
        </Button>
      </SideBar.Header>
    )
  }
}
