import { createSelector } from 'reselect'
import {
  currentAgentSelector,
  hasAgentRoleSelector
} from 's3p-js-lib/src/redux/selectors/api/user/agent'
import { agentSelector as bookingAgentSelector } from 's3p-js-lib/src/redux/selectors/api/booking/agent'
import {
  AGENT_ROLE_OPERATOR,
  AGENT_ROLE_TECHNICIAN,
  API_PRODUCT_VALIDATION_TYPE_ISSUE,
  API_PRODUCT_VALIDATION_TYPE_FULL,
  SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_OVERRIDE_RULES,
  SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_OVERRIDE_VALIDATIONS
} from '../../../../constants'
import {
  AGENT_PERMISSION_AFTERSALES_CONTRACT_OVERRIDE_AFTERSALES_RULES,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_OVERRIDE_VALIDATION_RULES_FULL,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_OVERRIDE_VALIDATION_RULES_ISSUE,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_OVERRIDE_AFTERSALES_RULES,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_OVERRIDE_VALIDATION_RULES_FULL,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_OVERRIDE_VALIDATION_RULES_ISSUE,
  AGENT_PERMISSION_BOOKING_ACCESS_AGENT_RESTRICTION,
  AGENT_PERMISSION_BOOKING_ACCESS_OFFICE_RESTRICTION,
  AGENT_PERMISSION_BOOKING_ACCESS_OUTSIDE_CONTRACT
} from 's3p-js-lib/src/constants'
import { createSelectorWithoutMemoize } from '../../../../misc/selector'
import { salesChannelPropertySelector } from 's3p-js-lib/src/redux/selectors/api/user/sales-channel-properties'

export const createAgentRoleAuthSelector = options => createSelector(
  [
    hasAgentRoleSelector(AGENT_ROLE_OPERATOR),
    hasAgentRoleSelector(AGENT_ROLE_TECHNICIAN)
  ],
  (isOperator, isTechnician) => options.roles && !(
    (options.roles.some(role => role === AGENT_ROLE_OPERATOR) && isOperator) ||
    (options.roles.some(role => role === AGENT_ROLE_TECHNICIAN) && isTechnician)
  )
)

export const overrideValidationRulesSelector = bookingSelector => createSelector(
  [
    canOverrideValidationRulesFullSelector(bookingSelector),
    canOverrideValidationRulesIssueSelector(bookingSelector)
  ],
  (canOverrideValidationRulesFull, canOverrideValidationRulesIssue) => {
    return canOverrideValidationRulesFull && canOverrideValidationRulesIssue
      ? [API_PRODUCT_VALIDATION_TYPE_FULL, API_PRODUCT_VALIDATION_TYPE_ISSUE]
      : canOverrideValidationRulesFull
        ? [API_PRODUCT_VALIDATION_TYPE_FULL]
        : canOverrideValidationRulesIssue
          ? [API_PRODUCT_VALIDATION_TYPE_ISSUE]
          : []
  }
)

const isInternalBookingSelector = bookingSelector => createSelector(
  [
    bookingAgentSelector(bookingSelector),
    currentAgentSelector
  ],
  (bookingAgent, currentAgent) => {
    if (!bookingAgent || !currentAgent) {
      return false
    }

    let internal = currentAgent.permissions.includes(AGENT_PERMISSION_BOOKING_ACCESS_OUTSIDE_CONTRACT)
      ? Boolean(currentAgent.organization && currentAgent.organization.code === bookingAgent.organization)
      : currentAgent.contracts.some(contract => contract.code === bookingAgent.contractCode)

    if (internal && currentAgent.permissions.includes(AGENT_PERMISSION_BOOKING_ACCESS_AGENT_RESTRICTION)) {
      internal = currentAgent.username === bookingAgent.username
    }

    if (internal && currentAgent.permissions.includes(AGENT_PERMISSION_BOOKING_ACCESS_OFFICE_RESTRICTION)) {
      internal = currentAgent.offices.some(office => office.name === bookingAgent.office)
    }

    return internal
  }
)

const canOverrideRulesSelector = (bookingSelector, internalPermission, externalPermission, salesChannelProperty) => {
  const _isInternalBookingSelector = isInternalBookingSelector(bookingSelector)
  const _salesChannelPropertySelector = salesChannelPropertySelector(salesChannelProperty)

  return state => {
    const salesChannelProperty = _salesChannelPropertySelector(state)
    const currentAgent = salesChannelProperty && currentAgentSelector(state)

    return Boolean(
      currentAgent &&
      currentAgent.permissions.includes(_isInternalBookingSelector(state) ? internalPermission : externalPermission)
    )
  }
}

export const canOverrideAftersalesRulesSelector = bookingSelector => canOverrideRulesSelector(
  bookingSelector,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_OVERRIDE_AFTERSALES_RULES,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_OVERRIDE_AFTERSALES_RULES,
  SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_OVERRIDE_RULES
)

export const canOverrideValidationRulesFullSelector = bookingSelector => canOverrideRulesSelector(
  bookingSelector,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_OVERRIDE_VALIDATION_RULES_FULL,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_OVERRIDE_VALIDATION_RULES_FULL,
  SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_OVERRIDE_VALIDATIONS
)

export const canOverrideValidationRulesIssueSelector = bookingSelector => canOverrideRulesSelector(
  bookingSelector,
  AGENT_PERMISSION_AFTERSALES_CONTRACT_OVERRIDE_VALIDATION_RULES_ISSUE,
  AGENT_PERMISSION_AFTERSALES_EXTERNAL_OVERRIDE_VALIDATION_RULES_ISSUE,
  SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_OVERRIDE_VALIDATIONS
)

export const canOverrideValidationRulesFullOrIssueSelector = bookingSelector => createSelectorWithoutMemoize(
  [
    canOverrideValidationRulesFullSelector(bookingSelector),
    canOverrideValidationRulesIssueSelector(bookingSelector)
  ],
  (overrideFull, overrideIssue) => overrideFull || overrideIssue
)
