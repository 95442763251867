import PropTypes from 'prop-types'
import React, { Component } from 'react'
import connectFormContainer from '../../base/form'
import PassengerSubForm from '../../../components/base/passenger-details-search/passenger-details-modal/passenger-sub-form'

class PassengerSubFormContainer extends Component {
  static propTypes = {
    form: PropTypes.shape({
      loadFormValues: PropTypes.func.isRequired,
      fields: PropTypes.shape({
        discountCard: PropTypes.object
      })
    })
  }

  constructor (...args) {
    super(...args)
    this.handleDiscountCardChange = this.handleDiscountCardChange.bind(this)
  }

  handleDiscountCardChange (value) {
    this.props.form.fields.discountCard.onChange(value)
    if (!value.code) {
      this.props.form.loadFormValues({discountCardNumber: ''})
    }
  }

  render () {
    return (
      <PassengerSubForm {...this.props} handleDiscountCardChange={this.handleDiscountCardChange} />
    )
  }
}

export default connectFormContainer()(PassengerSubFormContainer)
