import { createSelector } from 'reselect'
import { camelCaseKeys } from '../../../../misc/utils'

export const mapAndEnrichCustomer = ({email, ...customer}) => ({
  ...camelCaseKeys(customer),
  fullName: `${customer.title || ''} ${customer.first_name} ${customer.last_name}`.trim(),
  emailAddress: email
})

export const customerSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking && booking?.customer?.crm_customer_id && mapAndEnrichCustomer(booking.customer)
)
