import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Modal from '../../../elements/modal/modal'
import Button from '../../../elements/button/button'
import TextLabel from '../../../elements/text-label/text-label'
import Icon from '../../../elements/icon/icon'

export const LEAP_OPTION_CHECK_BALANCE_MODAL = 'leap-option-check-balance-modal'

export default class LeapOptionCheckBalanceModal extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    continueToCheckBalance: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  }

  render () {
    return (
      <Modal
        name={LEAP_OPTION_CHECK_BALANCE_MODAL}
        isCloseable={false}
        header={<LeapOptionCheckBalanceHeader {...this.props} />}
        footer={<LeapOptionCheckBalanceFooter {...this.props} />}
      >
        <p>{_t.message('product-search.leap-option-check-balance.information')}</p>
      </Modal>
    )
  }
}

class LeapOptionCheckBalanceFooter extends Component {
  static propTypes = {
    continueToCheckBalance: PropTypes.func.isRequired
  }

  render () {
    return (
      <Modal.CustomFooter>
        <Button
          name='modal-continue'
          className='primary'
          onClick={this.props.continueToCheckBalance}
          autoFocus
        >
          <TextLabel>
            <TextLabel.Text text={_t.message('product-search.leap-option-check-balance.button.continue')} />
          </TextLabel>
        </Button>
      </Modal.CustomFooter>
    )
  }
}

class LeapOptionCheckBalanceHeader extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  render () {
    return (
      <Modal.Title title={_t.message(`product-search.leap-option-check-balance.title.${this.props.type}`)}>
        <Button
          name='modal-close'
          className='secondary'
          title={_t.message('modal.close')}
          aria-label={_t.message('modal.close')}
          onClick={this.props.onClose}
        >
          <TextLabel aria-hidden='true'>
            <Icon name='cross' className='align-left' />
            <TextLabel.Text text={_t.message('modal.close')} />
          </TextLabel>
        </Button>
      </Modal.Title>
    )
  }
}
