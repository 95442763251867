import request from './request'
import { STATUS_ERROR } from './device-manager'
import { createError, createWarning } from './response-handler'
import {mapDevice} from './device'

export const translateResponse = response => {
  const messages = []

  if ((response.status || response.Status) === STATUS_ERROR) {
    if (response.results && response.results.message === 'Timeout waiting for card presentation') {
      messages.push(createWarning(response.results.message, {translated: true}))
    } else if (response.results) {
      messages.push(createError(response.results.message, {translated: true}))
    } else if (response.Details) {
      messages.push(createError(response.Details.message, {translated: true}))
    }
  }

  return messages
}

export default {
  async addProduct (timeout, cardIdentifier, product) {
    return request({
      translateResponse,
      method: 'post',
      endpoint: '/vtm/devices/rest/LeapScim/add_product',
      data: {
        Command: {
          Timeout: timeout,
          CardIdentifier: cardIdentifier
        },
        product
      }
    })
  },

  async applyCd (name, data) {
    return request({
      method: 'post',
      endpoint: '/vtm/devices/rest/LeapScim/apply_cd',
      data: {
        'Name': name,
        'DataAsBase64': data
      }
    })
  },

  async processActionList (timeout, cardIdentifier, actions) {
    return request({
      translateResponse,
      method: 'post',
      endpoint: '/vtm/devices/rest/LeapScim/process_action_list',
      data: {
        Command: {
          Timeout: timeout,
          CardIdentifier: cardIdentifier
        },
        actions: actions.map(action => ({id: action.id, type: action.type, ActualData: action.action}))
      }
    })
  },

  async read () {
    return request({
      translateResponse,
      method: 'post',
      endpoint: '/vtm/devices/rest/LeapScim/read'
    })
  },

  async reset () {
    return request({
      method: 'post',
      endpoint: '/vtm/devices/rest/LeapScim/reset'
    })
  },

  async reverse (timeout, cardIdentifier) {
    return request({
      translateResponse,
      method: 'post',
      endpoint: '/vtm/devices/rest/LeapScim/reverse',
      data: {
        Timeout: timeout,
        CardIdentifier: cardIdentifier
      }
    })
  },

  async setConfiguration (options) {
    const {antennaType, cardExpiryDuration, ...data} = options
    return request({
      translateResponse,
      method: 'post',
      endpoint: '/vtm/devices/rest/LeapScim/set_configuration',
      data: {
        ...data,
        AntennaType: antennaType,
        CardExpiryDuration: cardExpiryDuration
      }
    })
  },

  async status () {
    const response = await request({
      method: 'get',
      endpoint: '/vtm/devices/rest/LeapScim/status',
      ignoreErrors: true
    })

    return Array.isArray(response) ? response.map(item => mapDevice(item, translateResponse)) : []
  },

  async topUp (cardIdentifier, value, paymentMethod) {
    return request({
      translateResponse,
      method: 'post',
      endpoint: '/vtm/devices/rest/LeapScim/top_up',
      data: {
        Command: {
          CardIdentifier: cardIdentifier
        },
        Value: value,
        PaymentMethod: paymentMethod
      }
    })
  },

  async validateProduct (command, product) {
    return request({
      method: 'post',
      endpoint: '/vtm/devices/rest/LeapScim/validate_product',
      data: {
        command,
        product
      }
    })
  }
}
