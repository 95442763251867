import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import './grid.scss'

export default class GridLayout extends Component {
  static propTypes = {
    name: PropTypes.string,
    className: PropTypes.string
  }

  render () {
    const {className, name, children, ...rest} = this.props
    return (
      <div {...rest} className={classNames('grid-layout', name ? `grid-layout-${name}` : '', className)}>
        {children}
      </div>
    )
  }
}
