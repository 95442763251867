import {
  USER_INPUT_JOURNEY_SEARCH_SWAP_ORIGIN_DESTINATION,
  API_JOURNEY_SEARCH_SUCCESS,
  API_GET_STATIONS_SUCCESS,
  API_OAUTH_LOGOUT,
  CLEAR_STATE
} from '../../../../actions/types'
import { FORM_JOURNEY_SEARCH } from '../../../../../constants-form'
import { toUtcDateMoment } from '../../../../../misc/date'
import { stationMapper } from '../../../../selectors/api/orientation/stations'

export const initialState = {}

const updateStationField = (stations, field) => {
  if (!field || !field.value || !field.value.UICStationCode) {
    return field || {}
  }
  const station = stations.find(station => station._u_i_c_station_code === field.value.UICStationCode)

  return {
    ...field,
    value: station ? stationMapper(station) : null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case API_OAUTH_LOGOUT:
      return initialState
    case USER_INPUT_JOURNEY_SEARCH_SWAP_ORIGIN_DESTINATION:
      return {
        ...state,
        fields: {
          ...state.fields,
          origin: state.fields.destination,
          destination: state.fields.origin
        }
      }
    case API_JOURNEY_SEARCH_SUCCESS: {
      const journeySearch = action.response.data.journey_search
      return {
        ...state,
        fields: {
          ...(state.fields || initialState),
          inboundDate: {
            ...state.fields.inboundDate,
            value: journeySearch.return_date ? toUtcDateMoment(journeySearch.return_date) : null
          },
          outboundDate: {
            ...state.fields.outboundDate,
            value: journeySearch.departure_date ? toUtcDateMoment(journeySearch.departure_date) : null
          }
        }
      }
    }
    case API_GET_STATIONS_SUCCESS:
      return {
        ...state,
        fields: {
          ...(state.fields || initialState),
          origin: updateStationField(action.response, state.fields && state.fields.origin),
          destination: updateStationField(action.response, state.fields && state.fields.destination)
        }
      }
    case CLEAR_STATE:
      return action.namespaces.includes(`userInput.form.${FORM_JOURNEY_SEARCH}`) ? initialState : state
    default:
      return state
  }
}
