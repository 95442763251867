import { showModal, hideElement } from '../../../containers/base/visible-element'
import { overviewBookingSelector, bookingNumberSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { getBooking } from 's3p-js-lib/src/redux/actions/api/booking/booking'
import { payoutRecordsSelector } from '../../../../selectors/containers/aftersales/booking/refund'
import {
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD,
  PRINT_VOUCHER_DEFAULT_ERROR_MESSAGE,
  PED_STATUS_NO_RESPONSE,
  PAYMENT_METHOD_CODE_REFUND,
  CURRENCY_EURO,
  PED_STATUS_FAILURE,
  PED_STATUS_SUCCESS,
  REFUND_MODAL,
  PAYMENT_METHODS_LOCAL_PAYMENTS,
  PAYMENT_METHODS_PSP_REFUND_ALLOWED
} from '../../../../../constants'
import {
  USER_INPUT_SET_REFUND_STEP,
  USER_INPUT_REFUND_DONE,
  USER_INPUT_REFUND_ABORT
} from '../../../types'
import { confirmRefunds, cancelPendingRefunds, initiateRefund } from '../../../api/payment/refund'
import { refundPinPayment } from '../../../machine/pin-payment'
import {
  provisionalOrOverviewBookingSelector,
  refundVoucherDocumentSelector
} from '../../../../selectors/api/booking/booking'
import {
  retrieveAndPrintCycleStart,
  retrieveAndPrintCycleEnd,
  errorPrintingTickets,
  startPrintingTickets,
  stopPrintingTickets
} from '../../base/finalize-booking/print-ticket-modal'
import { giftVoucherTicketDocumentsSelector as refundVoucherTicketDocumentSelector } from '../../../../selectors/api/booking/ticket-documents'
import { getRefundVoucherDocument } from '../../../api/booking/ticket-documents'
import { sendMachineReceiptPrint } from '../../../machine/receipt-printer'
import { PAYMENT_STATUS_F } from 's3p-js-lib/src/constants'
import { pedStatus } from '../../base/payment-modal/credit-debit-modal'
import { refundResultSelector } from '../../../../selectors/machine/pin-payment'
import { addPayments } from '../../base/tiles/payment-methods'
import { isPedPaymentMethod } from '../../../../../misc/utils'
import { paymentsSelector } from '../../../../selectors/api/booking/payments'
import { requestRefundPsp } from 's3p-js-lib/src/redux/actions/api/payment/refund'

const _bookingNumberSelector = bookingNumberSelector(provisionalOrOverviewBookingSelector)
const _paymentsSelector = paymentsSelector(overviewBookingSelector)

export const initiateLocalRefund = () => initiateRefund(PAYMENT_METHODS_LOCAL_PAYMENTS)
export const confirmCash = () => confirmRefunds(PAYMENT_METHOD_CASH)
export const confirmCreditCard = () => confirmRefunds(PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD)

const validateResponse = (response, status, payoutRecord) => (dispatch, getState) => {
  if (!response) {
    dispatch(pedStatus(PED_STATUS_NO_RESPONSE))
    return
  }
  const refundResult = refundResultSelector(getState())
  if (refundResult.refundCompleted) {
    dispatch(pedStatus(status))
  } else {
    const payments = [{
      amount: -payoutRecord.amount,
      currency: CURRENCY_EURO,
      method: PAYMENT_METHOD_CODE_REFUND,
      status: PAYMENT_STATUS_F,
      externalReference: refundResult.transactionId
    }]
    dispatch([addPayments(payments), pedStatus(PED_STATUS_FAILURE)])
  }
}

export const startRefundCreditCardPayment = () => async (dispatch, getState) => {
  const payoutRecord = payoutRecordsSelector(overviewBookingSelector)(getState())
    .find(record => isPedPaymentMethod(record.method))
  const response = await dispatch(refundPinPayment({
    TransactionId: '',
    Value: Math.round(payoutRecord.amount * 100)
  }))

  dispatch(validateResponse(response, PED_STATUS_SUCCESS, payoutRecord))
}

export const setRefundStep = step => ({type: USER_INPUT_SET_REFUND_STEP, step})
export const refundDone = () => ({type: USER_INPUT_REFUND_DONE})
export const refundAbort = () => ({type: USER_INPUT_REFUND_ABORT})

const showRefundModal = () => showModal(REFUND_MODAL)
const hideRefundModal = () => hideElement(REFUND_MODAL)

export const handleRefundPayment = () => async (dispatch, getState) => {
  const payments = _paymentsSelector(getState()).filter(payment => payment.method !== PAYMENT_METHOD_CODE_REFUND)
  if (payments.every(payment => PAYMENT_METHODS_PSP_REFUND_ALLOWED.includes(payment.method))) {
    await dispatch(requestRefundPsp())
  } else {
    dispatch(showRefundModal())
  }
}

const refundPspPayments = () => async (dispatch, getState) => {
  if (_paymentsSelector(getState()).some(payment => PAYMENT_METHODS_PSP_REFUND_ALLOWED.includes(payment.method))) {
    await dispatch(requestRefundPsp())
  }
}

export const finalizeRefund = (refundPsp = true) => async (dispatch, getState) => {
  refundPsp && await dispatch(refundPspPayments())
  dispatch([hideRefundModal(), refundDone(), getBooking(_bookingNumberSelector(getState()))])
}

export const cancelRefund = () => async dispatch => {
  dispatch(refundAbort())
  await dispatch(cancelPendingRefunds())
  dispatch(finalizeRefund(false))
}

export const printRefundVoucher = () => async (dispatch, getState) => {
  await dispatch(retrieveAndPrintCycleStart())

  const refundVoucherBooking = refundVoucherTicketDocumentSelector(overviewBookingSelector)(getState())

  if (refundVoucherBooking && refundVoucherBooking[0] && refundVoucherBooking[0].url) {
    if (await dispatch(getRefundVoucherDocument(refundVoucherBooking[0].url))) {
      await dispatch(printTickets())
    }
  }
  await dispatch(retrieveAndPrintCycleEnd())
}

const printTickets = () => async (dispatch, getState) => {
  const refundVoucherDocumentText = refundVoucherDocumentSelector(getState())
  if (refundVoucherDocumentText) {
    try {
      const refundVoucherDocument = JSON.parse(refundVoucherDocumentText)
      if (!refundVoucherDocument) {
        dispatch(errorPrintingTickets(PRINT_VOUCHER_DEFAULT_ERROR_MESSAGE))
      } else {
        dispatch(startPrintingTickets(1))
        await dispatch(sendMachineReceiptPrint(refundVoucherDocument))
        dispatch(stopPrintingTickets())
      }
    } catch (error) {
      dispatch(errorPrintingTickets(PRINT_VOUCHER_DEFAULT_ERROR_MESSAGE))
    }
  }
}
