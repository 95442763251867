import {
  API_GET_RECEIPT_DOCUMENT_REQUEST,
  API_GET_RECEIPT_DOCUMENT_SUCCESS,
  API_GET_RECEIPT_DOCUMENT_FAILURE
} from '../../types'
import Client from '../../../../../../src/api/client'

const getReceiptDocumentRequest = ticketUrl => ({type: API_GET_RECEIPT_DOCUMENT_REQUEST, ticketUrl})
const getReceiptDocumentSuccess = response => ({type: API_GET_RECEIPT_DOCUMENT_SUCCESS, response})
const getReceiptDocumentFailure = error => ({type: API_GET_RECEIPT_DOCUMENT_FAILURE, error})

export const getReceiptDocument = ticketUrl => async dispatch => {
  try {
    dispatch(getReceiptDocumentRequest(ticketUrl))
    const response = await Client.getTextFile(ticketUrl)
    dispatch(getReceiptDocumentSuccess(response))
  } catch (error) {
    dispatch(getReceiptDocumentFailure(error))
    return false
  }
}
