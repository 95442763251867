import { createSelector } from 'reselect'
import sortBy from 'lodash/sortBy'
import { camelCaseKeys, indexBy } from '../../../../misc/utils'
import { PASSENGER_TYPE_ADULT, PASSENGER_TYPE_CHILD } from '../../../../constants'

export const generateItemId = (productCode, passengerFares) => {
  const passengerFaresId = passengerFares
    .sort((a, b) => a.type === b.type ? 0
      : a.type === PASSENGER_TYPE_ADULT ? -1
        : a.type === PASSENGER_TYPE_CHILD ? 1 : -1)
    .map(passengerFare => `${passengerFare.type}-${passengerFare.tariffCode}`)
    .join('-')

  return `${productCode}-${passengerFaresId}`
}

export const segmentsSelector = createSelector(
  [state => state.api.v2.search && state.api.v2.search.segments],
  segments => indexBy(segments, 'id')
)

export const passengersSelector = createSelector(
  [state => state.api.v2.search && state.api.v2.search.passengers],
  passengers => indexBy(passengers, 'id')
)

export const tariffsSelector = createSelector(
  [state => state.api.v2.search && state.api.v2.search.tariffs],
  tariffs => indexBy(tariffs, 'id')
)

export const faresSelector = createSelector(
  [state => state.api.v2.search && state.api.v2.search.fares],
  fares => indexBy(fares, 'id')
)

export const productsSelector = createSelector(
  [state => state.api.v2.search && state.api.v2.search.products],
  products => indexBy(products, 'code')
)

export const itemsSelector = createSelector(
  [
    state => state.api.v2.search && state.api.v2.search.items,
    faresSelector,
    productsSelector,
    tariffsSelector,
    passengersSelector
  ],
  (items, fares, products, tariffs, passengers) => {
    const result = (items || []).map(item => {
      const fare = fares[item.fare_id]
      if (!fare) {
        throw new Error(`Could not find Fare ${item.fare_id}`)
      }
      const product = products[item.product_code]
      if (!product) {
        throw new Error(`Could not find Product ${item.product_code}`)
      }

      const passengerFares = fare.passengerFares.map(passengerFare => {
        const tariff = tariffs[passengerFare.tariff_ref]
        if (!tariff) {
          throw new Error(`Could not find Tariff ${passengerFare.tariff_ref}`)
        }
        const passenger = passengers[passengerFare.passenger_ref]
        if (!passenger) {
          throw new Error(`Could not find Passenger ${passengerFare.passenger_ref}`)
        }

        return {
          id: passenger.id,
          type: passenger.type,
          tariffCode: tariff.code,
          validityOriginBound: tariff.validityOriginBound,
          validityDestinationBound: tariff.validityDestinationBound
        }
      })

      const newProduct = {...product}
      delete newProduct.code
      return {
        ...camelCaseKeys(item),
        ...newProduct,
        id: generateItemId(item.product_code, passengerFares),
        price: fare.price,
        passengerFares
      }
    })

    return sortBy(result, 'price')
  }
)
