import {
  API_GET_ADDITIONAL_PRODUCTS_REQUEST,
  API_GET_ADDITIONAL_PRODUCTS_SUCCESS,
  API_GET_ADDITIONAL_PRODUCTS_FAILURE
} from '../../types'
import {createV1AdditionalProductsResponseSelector} from './additional-products-mapper'

const getAdditionalProductsRequest = journeyId => ({type: API_GET_ADDITIONAL_PRODUCTS_REQUEST, journeyId})
const getAdditionalProductsSuccess = response => ({type: API_GET_ADDITIONAL_PRODUCTS_SUCCESS, response})
const getAdditionalProductsFailure = error => ({type: API_GET_ADDITIONAL_PRODUCTS_FAILURE, error})

export const getAdditionalProducts = journeyId => (dispatch, getState) => {
  try {
    dispatch(getAdditionalProductsRequest(journeyId))
    const response = createV1AdditionalProductsResponseSelector(journeyId)(getState())
    dispatch(getAdditionalProductsSuccess(response))
  } catch (error) {
    dispatch(getAdditionalProductsFailure(error))
    throw error
  }
}
