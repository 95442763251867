import React, { Component } from 'react'
import PropTypes from '../../../misc/prop-types'
import _t from 's3p-js-lib/src/translate'
import ComponentContainer from '../../../elements/component-container/component-container'
import {
  PASSENGER_TYPE_ADULT,
  PASSENGER_TYPE_STUDENT,
  PASSENGER_TYPE_CHILD
} from 's3p-js-lib/src/constants'
import TextLabel from '../../../elements/text-label/text-label'
import Form from '../../../elements/form/form'
import Icon from '../../../elements/icon/icon'
import Button from '../../../elements/button/button'
import AmountInputField from '../../../elements/amount-input/amount-input'
import DateInputField from '../../base/date-input-field/date-input-field'
import { toUtcDateMoment } from 's3p-js-lib/src/misc/date'
import PassengerDetails from '../../../containers/base/passenger-details-search/passenger-details'
import './product-search.scss'

const FIELD_ID_DESTINATION = 'destination'
const FIELD_ID_ADULTS = 'adults'
const FIELD_ID_VALID_FROM = 'valid-from'

export default class ProductSearch extends Component {
  static propTypes = {
    maxPerPassengerType: PropTypes.object.isRequired,
    valueAdult: PropTypes.number,
    valueChild: PropTypes.number,
    valueStudent: PropTypes.number,
    handleChangeAdult: PropTypes.func.isRequired,
    handleChangeChild: PropTypes.func.isRequired,
    handleChangeStudent: PropTypes.func.isRequired,
    isLoadingProductsSearch: PropTypes.bool.isRequired,
    disableRepeatLastBooking: PropTypes.bool,
    form: PropTypes.shape({
      fields: PropTypes.shape({
        origin: PropTypes.shape({
          onChange: PropTypes.func
        }),
        destination: PropTypes.shape({
          onChange: PropTypes.func
        }),
        validFrom: PropTypes.shape({
          onChange: PropTypes.func,
          value: PropTypes.moment
        }).isRequired
      }),
      valid: PropTypes.bool.isRequired

    }),
    defaultOriginStation: PropTypes.object,
    handleSearchProducts: PropTypes.func.isRequired,
    handleRepeatLastBooking: PropTypes.func.isRequired,
    passengersDisabled: PropTypes.bool,
    formName: PropTypes.string,
    disabled: PropTypes.bool,
    getOriginStations: PropTypes.func.isRequired,
    getDestinationStations: PropTypes.func.isRequired
  }

  constructor (...args) {
    super(...args)

    this.today = toUtcDateMoment()
    this.onCloseDateField = this.onCloseDateField.bind(this)
    this.handleStationChange = this.handleStationChange.bind(this)
  }

  onCloseDateField () {
    this._focusField(FIELD_ID_ADULTS)
  }

  render () {
    const {disabled, form} = this.props
    return (
      <ComponentContainer name='product-search'>
        <Form formContext='product-search'>
          <Form.InputGroup name='journey-selector'>
            {this._renderStationSelectorField(this._getPropsOriginField())}
            {this._renderStationSelectorField(this._getPropsDestinationField())}
            <Form.InputGroup name='journey-date'>
              <Form.InputWrapper name='label'>
                <Form.Label htmlFor='valid-from' name='valid-from' />
              </Form.InputWrapper>
              <Form.InputWrapper name='input'>
                <DateInputField
                  {...form.fields.validFrom}
                  hotkey='focusValidFrom'
                  disabled={form.fields.validFrom.disabled || disabled}
                  required
                  id={FIELD_ID_VALID_FROM}
                  name='valid-from'
                  title={_t.message('product-search.form.valid-from.title')}
                  placeholder={_t.message('product-search.form.valid-from.placeholder')}
                  minDate={this.today}
                  onCloseModal={this.onCloseDateField}
                />
              </Form.InputWrapper>
            </Form.InputGroup>
          </Form.InputGroup>
          <Form.InputGroup name='passenger-selector'>
            <Form.InputGroup name='adults'>
              <Form.InputWrapper name='label'>
                <Form.Label htmlFor='adults' name='adults' />
              </Form.InputWrapper>
              <Form.InputWrapper name='input'>
                <AmountInputField
                  name='adults'
                  id={FIELD_ID_ADULTS}
                  onChange={this.props.handleChangeAdult}
                  disabled={this.props.passengersDisabled || disabled}
                  value={this.props.valueAdult}
                  placeholder={_t.message('product-search.form.adults.placeholder')}
                  max={this.props.maxPerPassengerType[PASSENGER_TYPE_ADULT]}
                  hotkey={{
                    increment: 'incrementAdult',
                    decrement: 'decrementAdult'
                  }}
                />
              </Form.InputWrapper>
            </Form.InputGroup>
            <Form.InputGroup name='children'>
              <Form.InputWrapper name='label'>
                <Form.Label htmlFor='children' name='children' />
              </Form.InputWrapper>
              <Form.InputWrapper name='input'>
                <AmountInputField
                  name='children'
                  id='children'
                  onChange={this.props.handleChangeChild}
                  disabled={this.props.passengersDisabled || disabled}
                  value={this.props.valueChild}
                  placeholder={_t.message('product-search.form.children.placeholder')}
                  max={this.props.maxPerPassengerType[PASSENGER_TYPE_CHILD]}
                  hotkey={{
                    increment: 'cancel',
                    decrement: 'decrementChild'
                  }}
                />
              </Form.InputWrapper>
            </Form.InputGroup>
            <Form.InputGroup name='students'>
              <Form.InputWrapper name='label'>
                <Form.Label htmlFor='students' name='students' />
              </Form.InputWrapper>
              <Form.InputWrapper name='input'>
                <AmountInputField
                  name='students'
                  id='students'
                  onChange={this.props.handleChangeStudent}
                  disabled={this.props.passengersDisabled || disabled}
                  value={this.props.valueStudent}
                  placeholder={_t.message('product-search.form.students.placeholder')}
                  max={this.props.maxPerPassengerType[PASSENGER_TYPE_STUDENT]}
                  hotkey={{
                    increment: 'incrementStudent',
                    decrement: 'decrementStudent'
                  }}
                />
              </Form.InputWrapper>
            </Form.InputGroup>
          </Form.InputGroup>
          <Form.InputGroup name='controls'>
            <PassengerDetails disabled={this.props.passengersDisabled || disabled} formName={this.props.formName} />
            <Form.InputWrapper name='search'>
              <Button
                type='button'
                name='search'
                className='primary'
                onClick={this.props.handleSearchProducts}
                loading={this.props.isLoadingProductsSearch}
                disabled={!form.valid || disabled}
                hotkey='proceed'
              >
                <TextLabel>
                  <Icon name='search' className='align-left' />
                  <TextLabel.Text text={_t.message('product-search.search-button')} />
                </TextLabel>
              </Button>
            </Form.InputWrapper>
            <Form.InputWrapper name='repeat-previous'>
              <Button
                type='button'
                name='repeat-previous'
                className='tertiary'
                onClick={this.props.handleRepeatLastBooking}
                disabled={this.props.disableRepeatLastBooking || disabled}
                hotkey='refresh'
              >
                <TextLabel>
                  <Icon name='return' className='align-left' />
                  <TextLabel.Text text={_t.message('product-search.repeat-previous-button')} />
                </TextLabel>
              </Button>
            </Form.InputWrapper>
          </Form.InputGroup>
        </Form>
      </ComponentContainer>
    )
  }

  handleStationChange (id, onChange, value) {
    onChange(value)
    this._focusField(id)
  }

  _focusField (id) {
    const element = document.getElementById(id)
    element && setTimeout(() => element.focus(), 200)
  }

  _renderStationSelectorField (props) {
    return (
      <Form.InputGroup name={props.name}>
        <Form.InputWrapper
          name='label'
          className={!props.valid && props.touched ? 'state--is-invalid' : ''}
        >
          <Form.Label htmlFor={props.name} name={props.name} />
        </Form.InputWrapper>
        <Form.InputWrapper
          name='input'
          hotkey={props.hotkey}
        >
          <Form.StationSelect
            {...props}
            disabled={props.disabled || this.props.disabled}
            autoComplete='off'
          />
        </Form.InputWrapper>
      </Form.InputGroup>
    )
  }

  _getPropsOriginField () {
    const {onChange, ...field} = this.props.form.fields.origin
    return {
      ...field,
      fieldName: 'origin',
      id: 'origin',
      autoFocus: !this.props.defaultOriginStation,
      hotkey: 'focusOrigin',
      onChange: this.handleStationChange.bind(null, `${FIELD_ID_DESTINATION}_input`, onChange),
      stations: this.props.getOriginStations() || []
    }
  }

  _getPropsDestinationField () {
    const {onChange, ...field} = this.props.form.fields.destination
    return {
      ...field,
      fieldName: 'destination',
      id: FIELD_ID_DESTINATION,
      autoFocus: Boolean(this.props.defaultOriginStation),
      hotkey: 'focusDestination',
      onChange: this.handleStationChange.bind(null, FIELD_ID_VALID_FROM, onChange),
      stations: this.props.getDestinationStations() || []
    }
  }
}
