import { connect } from 'react-redux'
import NextButton, { MODE_NEXT, MODE_SKIP } from '../../../components/base/additional-products/next-button'
import containBookingFlow from '../../reservations/booking-flow'
import { additionalProductsSelector } from 's3p-js-lib/src/redux/selectors/api/orientation/specification/products'

const mapStateToProps = state => ({
  isLoading: state.api.loading.updateSelectBundle || state.api.loading.createProvisionalBooking,
  mode: additionalProductsSelector(state).length ? MODE_NEXT : MODE_SKIP
})

export default connect(mapStateToProps)(containBookingFlow(NextButton))
