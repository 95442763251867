import loadingReducer from '../../lib/loading-reducer'
import {
  API_CONFIRMATION_POLL_START,
  API_CONFIRMATION_POLL_STOP,
  API_CONFIRMATION_POLL_TIMEOUT,
  API_AGENT_LOGIN_REQUEST,
  API_AGENT_LOGIN_SUCCESS,
  API_AGENT_LOGIN_FAILURE,
  API_BOOKING_LOGIN_REQUEST,
  API_BOOKING_LOGIN_SUCCESS,
  API_BOOKING_LOGIN_FAILURE,
  API_PUBLIC_ACCESS_REQUEST,
  API_PUBLIC_ACCESS_SUCCESS,
  API_PUBLIC_ACCESS_FAILURE,
  API_LOAD_USER_INFORMATION_REQUEST,
  API_LOAD_USER_INFORMATION_SUCCESS,
  API_LOAD_USER_INFORMATION_FAILURE,
  API_LOAD_AGENT_INFORMATION_REQUEST,
  API_LOAD_AGENT_INFORMATION_SUCCESS,
  API_LOAD_AGENT_INFORMATION_FAILURE,
  API_AGENT_CHANGE_PASSWORD_REQUEST,
  API_AGENT_CHANGE_PASSWORD_SUCCESS,
  API_AGENT_CHANGE_PASSWORD_FAILURE,
  API_CMS_LOAD_BLOCKS_REQUEST,
  API_CMS_LOAD_BLOCKS_SUCCESS,
  API_CMS_LOAD_BLOCKS_FAILURE,
  API_JOURNEY_SEARCH_REQUEST,
  API_JOURNEY_SEARCH_SUCCESS,
  API_JOURNEY_SEARCH_FAILURE,
  API_REBOOKING_JOURNEY_SEARCH_REQUEST,
  API_REBOOKING_JOURNEY_SEARCH_SUCCESS,
  API_REBOOKING_JOURNEY_SEARCH_FAILURE,
  API_REBOOKING_CREATE_BOOKING_REQUEST,
  API_REBOOKING_CREATE_BOOKING_SUCCESS,
  API_REBOOKING_CREATE_BOOKING_FAILURE,
  API_SELECT_BUNDLE_REQUEST,
  API_SELECT_BUNDLE_SUCCESS,
  API_SELECT_BUNDLE_FAILURE,
  API_GET_STATIONS_REQUEST,
  API_GET_STATIONS_SUCCESS,
  API_GET_STATIONS_FAILURE,
  API_CREATE_PROVISIONAL_BOOKING_REQUEST,
  API_CREATE_PROVISIONAL_BOOKING_SUCCESS,
  API_CREATE_PROVISIONAL_BOOKING_FAILURE,
  API_GET_BOOKING_REQUEST,
  API_GET_BOOKING_SUCCESS,
  API_GET_BOOKING_FAILURE,
  API_GET_BOOKINGS_REQUEST,
  API_GET_BOOKINGS_SUCCESS,
  API_GET_BOOKINGS_FAILURE,
  API_LINK_TO_CUSTOMER_EMAIL_REQUEST,
  API_LINK_TO_CUSTOMER_EMAIL_SUCCESS,
  API_LINK_TO_CUSTOMER_EMAIL_FAILURE,
  API_UPDATE_BOOKING_REQUEST,
  API_UPDATE_BOOKING_SUCCESS,
  API_UPDATE_BOOKING_FAILURE,
  API_ADD_VOUCHER_REQUEST,
  API_ADD_VOUCHER_SUCCESS,
  API_ADD_VOUCHER_FAILURE,
  API_REQUEST_REFUND_REQUEST,
  API_REQUEST_REFUND_SUCCESS,
  API_REQUEST_REFUND_FAILURE,
  API_REMOVE_VOUCHER_REQUEST,
  API_REMOVE_VOUCHER_SUCCESS,
  API_REMOVE_VOUCHER_FAILURE,
  API_GET_PAYMENT_METHODS_REQUEST,
  API_GET_PAYMENT_METHODS_SUCCESS,
  API_GET_PAYMENT_METHODS_FAILURE,
  API_GET_SEAT_SELECTION_OPTIONS_REQUEST,
  API_GET_SEAT_SELECTION_OPTIONS_SUCCESS,
  API_GET_SEAT_SELECTION_OPTIONS_FAILURE,
  API_GET_CARRIAGE_LAYOUTS_ORIENTATION_REQUEST,
  API_GET_CARRIAGE_LAYOUTS_ORIENTATION_SUCCESS,
  API_GET_CARRIAGE_LAYOUTS_ORIENTATION_FAILURE,
  API_GET_CARRIAGE_LAYOUTS_AFTERSALES_REQUEST,
  API_GET_CARRIAGE_LAYOUTS_AFTERSALES_SUCCESS,
  API_GET_CARRIAGE_LAYOUTS_AFTERSALES_FAILURE,
  API_GET_ADDITIONAL_PRODUCTS_REQUEST,
  API_GET_ADDITIONAL_PRODUCTS_SUCCESS,
  API_GET_ADDITIONAL_PRODUCTS_FAILURE,
  API_UPDATE_SELECT_BUNDLE_REQUEST,
  API_UPDATE_SELECT_BUNDLE_SUCCESS,
  API_UPDATE_SELECT_BUNDLE_FAILURE,
  API_RESET_SEAT_SELECTION_REQUEST,
  API_RESET_SEAT_SELECTION_SUCCESS,
  API_RESET_SEAT_SELECTION_FAILURE,
  API_CONTINUE_PROVISIONAL_BOOKING_REQUEST,
  API_CONTINUE_PROVISIONAL_BOOKING_SUCCESS,
  API_CONTINUE_PROVISIONAL_BOOKING_FAILURE,
  API_CHANGE_SEATS_INITIATE_REQUEST,
  API_CHANGE_SEATS_INITIATE_SUCCESS,
  API_CHANGE_SEATS_INITIATE_FAILURE,
  API_CHANGE_SEATS_SELECT_SEATS_REQUEST,
  API_CHANGE_SEATS_SELECT_SEATS_SUCCESS,
  API_CHANGE_SEATS_SELECT_SEATS_FAILURE,
  API_CANCELLATION_INITIATE_REQUEST,
  API_CANCELLATION_INITIATE_SUCCESS,
  API_CANCELLATION_INITIATE_FAILURE,
  API_CANCELLATION_CANCEL_REQUEST,
  API_CANCELLATION_CANCEL_SUCCESS,
  API_CANCELLATION_CANCEL_FAILURE,
  API_REBOOKING_INITIATE_REQUEST,
  API_REBOOKING_INITIATE_SUCCESS,
  API_REBOOKING_INITIATE_FAILURE,
  API_UPDATE_PASSENGERS_INITIATE_REQUEST,
  API_UPDATE_PASSENGERS_INITIATE_SUCCESS,
  API_UPDATE_PASSENGERS_INITIATE_FAILURE,
  API_UPDATE_PASSENGERS_UPDATE_REQUEST,
  API_UPDATE_PASSENGERS_UPDATE_SUCCESS,
  API_UPDATE_PASSENGERS_UPDATE_FAILURE,
  API_ADD_BOOKING_NOTE_REQUEST,
  API_ADD_BOOKING_NOTE_SUCCESS,
  API_ADD_BOOKING_NOTE_FAILURE,
  API_AFTERSALES_CONFIRM_REQUEST,
  API_AFTERSALES_CONFIRM_SUCCESS,
  API_AFTERSALES_CONFIRM_FAILURE,
  API_ADDITIONAL_PRODUCTS_INITIATE_REQUEST,
  API_ADDITIONAL_PRODUCTS_INITIATE_SUCCESS,
  API_ADDITIONAL_PRODUCTS_INITIATE_FAILURE,
  API_ADDITIONAL_PRODUCTS_UPDATE_REQUEST,
  API_ADDITIONAL_PRODUCTS_UPDATE_SUCCESS,
  API_ADDITIONAL_PRODUCTS_UPDATE_FAILURE,
  API_RESEND_TICKETS_REQUEST,
  API_RESEND_TICKETS_SUCCESS,
  API_RESEND_TICKETS_FAILURE,
  API_FIND_CUSTOMERS_REQUEST,
  API_FIND_CUSTOMERS_SUCCESS,
  API_FIND_CUSTOMERS_FAILURE,
  API_GET_JOURNEY_DETAILS_REQUEST,
  API_GET_JOURNEY_DETAILS_SUCCESS,
  API_GET_JOURNEY_DETAILS_FAILURE,
  API_AGENT_UPDATE_REQUEST,
  API_AGENT_UPDATE_SUCCESS,
  API_AGENT_UPDATE_FAILURE,
  API_PING_REQUEST,
  API_PING_SUCCESS,
  API_PING_FAILURE,
  API_UPDATE_AGENT_REQUEST,
  API_UPDATE_AGENT_SUCCESS,
  API_UPDATE_AGENT_FAILURE,
  API_GET_DOCUMENT_TEMPLATES_REQUEST,
  API_GET_DOCUMENT_TEMPLATES_SUCCESS,
  API_GET_DOCUMENT_TEMPLATES_FAILURE,
  API_START_AGENT_SHIFT_REQUEST,
  API_START_AGENT_SHIFT_SUCCESS,
  API_START_AGENT_SHIFT_FAILURE,
  API_GET_AGENT_SHIFT_REQUEST,
  API_GET_AGENT_SHIFT_SUCCESS,
  API_GET_AGENT_SHIFT_FAILURE,
  API_ADD_NOTES_AGENT_SHIFT_REQUEST,
  API_ADD_NOTES_AGENT_SHIFT_SUCCESS,
  API_ADD_NOTES_AGENT_SHIFT_FAILURE,
  API_END_AGENT_SHIFT_REQUEST,
  API_END_AGENT_SHIFT_SUCCESS,
  API_END_AGENT_SHIFT_FAILURE,
  API_GET_VOUCHERS_REQUEST,
  API_GET_VOUCHERS_SUCCESS,
  API_GET_VOUCHERS_FAILURE,
  API_TICKET_VOID_VALIDATION_REQUEST,
  API_TICKET_VOID_VALIDATION_SUCCESS,
  API_TICKET_VOID_VALIDATION_FAILURE,
  API_GET_RECEIPT_DOCUMENT_REQUEST,
  API_GET_RECEIPT_DOCUMENT_SUCCESS,
  API_GET_RECEIPT_DOCUMENT_FAILURE
} from '../../actions/types'

export default {
  getStations: loadingReducer(
    API_GET_STATIONS_REQUEST,
    API_GET_STATIONS_SUCCESS,
    API_GET_STATIONS_FAILURE
  ),
  journeySearch: loadingReducer(
    API_JOURNEY_SEARCH_REQUEST,
    API_JOURNEY_SEARCH_SUCCESS,
    API_JOURNEY_SEARCH_FAILURE
  ),
  getJourneyDetails: (state = [], action) => {
    switch (action.type) {
      case API_GET_JOURNEY_DETAILS_REQUEST:
        return [
          ...state.filter(service => !action.services.includes(service)),
          ...action.services
        ]
      case API_GET_JOURNEY_DETAILS_SUCCESS:
      case API_GET_JOURNEY_DETAILS_FAILURE:
        return state.filter(service => !action.services.includes(service))
      default:
        return state
    }
  },
  selectBundle: loadingReducer(
    API_SELECT_BUNDLE_REQUEST,
    API_SELECT_BUNDLE_SUCCESS,
    API_SELECT_BUNDLE_FAILURE
  ),
  updateSelectBundle: loadingReducer(
    API_UPDATE_SELECT_BUNDLE_REQUEST,
    API_UPDATE_SELECT_BUNDLE_SUCCESS,
    API_UPDATE_SELECT_BUNDLE_FAILURE
  ),
  getAdditionalProducts: loadingReducer(
    API_GET_ADDITIONAL_PRODUCTS_REQUEST,
    API_GET_ADDITIONAL_PRODUCTS_SUCCESS,
    API_GET_ADDITIONAL_PRODUCTS_FAILURE
  ),
  getSeatSelectionOptions: loadingReducer(
    API_GET_SEAT_SELECTION_OPTIONS_REQUEST,
    API_GET_SEAT_SELECTION_OPTIONS_FAILURE,
    API_GET_SEAT_SELECTION_OPTIONS_SUCCESS
  ),
  getCarriageLayoutsOrientation: loadingReducer(
    API_GET_CARRIAGE_LAYOUTS_ORIENTATION_REQUEST,
    API_GET_CARRIAGE_LAYOUTS_ORIENTATION_SUCCESS,
    API_GET_CARRIAGE_LAYOUTS_ORIENTATION_FAILURE
  ),
  getCarriageLayoutsAftersales: loadingReducer(
    API_GET_CARRIAGE_LAYOUTS_AFTERSALES_REQUEST,
    API_GET_CARRIAGE_LAYOUTS_AFTERSALES_SUCCESS,
    API_GET_CARRIAGE_LAYOUTS_AFTERSALES_FAILURE
  ),
  createProvisionalBooking: loadingReducer(
    API_CREATE_PROVISIONAL_BOOKING_REQUEST,
    API_CREATE_PROVISIONAL_BOOKING_SUCCESS,
    API_CREATE_PROVISIONAL_BOOKING_FAILURE
  ),
  updateBooking: loadingReducer(
    API_UPDATE_BOOKING_REQUEST,
    API_UPDATE_BOOKING_SUCCESS,
    API_UPDATE_BOOKING_FAILURE
  ),
  getPaymentMethods: loadingReducer(
    API_GET_PAYMENT_METHODS_REQUEST,
    API_GET_PAYMENT_METHODS_SUCCESS,
    API_GET_PAYMENT_METHODS_FAILURE
  ),
  addVoucher: loadingReducer(
    API_ADD_VOUCHER_REQUEST,
    API_ADD_VOUCHER_SUCCESS,
    API_ADD_VOUCHER_FAILURE
  ),
  removeVoucher: (state = null, action) => {
    switch (action.type) {
      case API_REMOVE_VOUCHER_REQUEST:
        return action.voucherCode
      case API_REMOVE_VOUCHER_SUCCESS:
      case API_REMOVE_VOUCHER_FAILURE:
        return false
      default:
        return state
    }
  },
  confirmation: loadingReducer(
    API_CONFIRMATION_POLL_START,
    API_CONFIRMATION_POLL_STOP,
    API_CONFIRMATION_POLL_TIMEOUT
  ),
  getBooking: loadingReducer(
    API_GET_BOOKING_REQUEST,
    API_GET_BOOKING_SUCCESS,
    API_GET_BOOKING_FAILURE
  ),
  getBookings: loadingReducer(
    API_GET_BOOKINGS_REQUEST,
    API_GET_BOOKINGS_SUCCESS,
    API_GET_BOOKINGS_FAILURE
  ),
  cancellationInitiate: loadingReducer(
    API_CANCELLATION_INITIATE_REQUEST,
    API_CANCELLATION_INITIATE_SUCCESS,
    API_CANCELLATION_INITIATE_FAILURE
  ),
  cancellationCancel: loadingReducer(
    API_CANCELLATION_CANCEL_REQUEST,
    API_CANCELLATION_CANCEL_SUCCESS,
    API_CANCELLATION_CANCEL_FAILURE
  ),
  updatePassengersInitiate: loadingReducer(
    API_UPDATE_PASSENGERS_INITIATE_REQUEST,
    API_UPDATE_PASSENGERS_INITIATE_SUCCESS,
    API_UPDATE_PASSENGERS_INITIATE_FAILURE
  ),
  updatePassengersUpdate: loadingReducer(
    API_UPDATE_PASSENGERS_UPDATE_REQUEST,
    API_UPDATE_PASSENGERS_UPDATE_SUCCESS,
    API_UPDATE_PASSENGERS_UPDATE_FAILURE
  ),
  aftersalesConfirm: loadingReducer(
    API_AFTERSALES_CONFIRM_REQUEST,
    API_AFTERSALES_CONFIRM_SUCCESS,
    API_AFTERSALES_CONFIRM_FAILURE
  ),
  agentLogin: loadingReducer(
    API_AGENT_LOGIN_REQUEST,
    API_AGENT_LOGIN_SUCCESS,
    API_AGENT_LOGIN_FAILURE
  ),
  bookingLogin: loadingReducer(
    API_BOOKING_LOGIN_REQUEST,
    API_BOOKING_LOGIN_SUCCESS,
    API_BOOKING_LOGIN_FAILURE
  ),
  publicAccess: loadingReducer(
    API_PUBLIC_ACCESS_REQUEST,
    API_PUBLIC_ACCESS_SUCCESS,
    API_PUBLIC_ACCESS_FAILURE
  ),
  agentChangePassword: loadingReducer(
    API_AGENT_CHANGE_PASSWORD_REQUEST,
    API_AGENT_CHANGE_PASSWORD_SUCCESS,
    API_AGENT_CHANGE_PASSWORD_FAILURE
  ),
  cmsBlocks: (state = [], action) => {
    switch (action.type) {
      case API_CMS_LOAD_BLOCKS_REQUEST:
        return [
          ...state.filter(blockName => !action.blockNames.includes(blockName)),
          ...action.blockNames
        ]
      case API_CMS_LOAD_BLOCKS_SUCCESS:
      case API_CMS_LOAD_BLOCKS_FAILURE:
        return state.filter(blockName => !action.blockNames.includes(blockName))
      default:
        return state
    }
  },
  rebookingInitiate: loadingReducer(
    API_REBOOKING_INITIATE_REQUEST,
    API_REBOOKING_INITIATE_SUCCESS,
    API_REBOOKING_INITIATE_FAILURE
  ),
  rebookingJourneySearch: loadingReducer(
    API_REBOOKING_JOURNEY_SEARCH_REQUEST,
    API_REBOOKING_JOURNEY_SEARCH_SUCCESS,
    API_REBOOKING_JOURNEY_SEARCH_FAILURE
  ),
  rebookingCreateBooking: loadingReducer(
    API_REBOOKING_CREATE_BOOKING_REQUEST,
    API_REBOOKING_CREATE_BOOKING_SUCCESS,
    API_REBOOKING_CREATE_BOOKING_FAILURE
  ),
  changeSeatsInitiate: loadingReducer(
    API_CHANGE_SEATS_INITIATE_REQUEST,
    API_CHANGE_SEATS_INITIATE_SUCCESS,
    API_CHANGE_SEATS_INITIATE_FAILURE
  ),
  changeSeatsSelectSeats: loadingReducer(
    API_CHANGE_SEATS_SELECT_SEATS_REQUEST,
    API_CHANGE_SEATS_SELECT_SEATS_SUCCESS,
    API_CHANGE_SEATS_SELECT_SEATS_FAILURE
  ),
  additionalProductsUpdate: loadingReducer(
    API_ADDITIONAL_PRODUCTS_UPDATE_REQUEST,
    API_ADDITIONAL_PRODUCTS_UPDATE_SUCCESS,
    API_ADDITIONAL_PRODUCTS_UPDATE_FAILURE
  ),
  agentUpdate: loadingReducer(
    API_AGENT_UPDATE_REQUEST,
    API_AGENT_UPDATE_SUCCESS,
    API_AGENT_UPDATE_FAILURE
  ),
  continueProvisionalBooking: loadingReducer(
    API_CONTINUE_PROVISIONAL_BOOKING_REQUEST,
    API_CONTINUE_PROVISIONAL_BOOKING_SUCCESS,
    API_CONTINUE_PROVISIONAL_BOOKING_FAILURE
  ),
  additionalProductsInitiate: loadingReducer(
    API_ADDITIONAL_PRODUCTS_INITIATE_REQUEST,
    API_ADDITIONAL_PRODUCTS_INITIATE_SUCCESS,
    API_ADDITIONAL_PRODUCTS_INITIATE_FAILURE
  ),
  addBookingNote: loadingReducer(
    API_ADD_BOOKING_NOTE_REQUEST,
    API_ADD_BOOKING_NOTE_SUCCESS,
    API_ADD_BOOKING_NOTE_FAILURE
  ),
  requestRefund: loadingReducer(
    API_REQUEST_REFUND_REQUEST,
    API_REQUEST_REFUND_SUCCESS,
    API_REQUEST_REFUND_FAILURE
  ),
  getVouchers: loadingReducer(
    API_GET_VOUCHERS_REQUEST,
    API_GET_VOUCHERS_SUCCESS,
    API_GET_VOUCHERS_FAILURE
  ),
  updateAgent: loadingReducer(
    API_UPDATE_AGENT_REQUEST,
    API_UPDATE_AGENT_SUCCESS,
    API_UPDATE_AGENT_FAILURE
  ),
  ping: loadingReducer(
    API_PING_REQUEST,
    API_PING_SUCCESS,
    API_PING_FAILURE
  ),
  startAgentShift: loadingReducer(
    API_START_AGENT_SHIFT_REQUEST,
    API_START_AGENT_SHIFT_SUCCESS,
    API_START_AGENT_SHIFT_FAILURE
  ),
  getAgentShift: loadingReducer(
    API_GET_AGENT_SHIFT_REQUEST,
    API_GET_AGENT_SHIFT_SUCCESS,
    API_GET_AGENT_SHIFT_FAILURE
  ),
  addNotesAgentShift: loadingReducer(
    API_ADD_NOTES_AGENT_SHIFT_REQUEST,
    API_ADD_NOTES_AGENT_SHIFT_SUCCESS,
    API_ADD_NOTES_AGENT_SHIFT_FAILURE
  ),
  endAgentShift: loadingReducer(
    API_END_AGENT_SHIFT_REQUEST,
    API_END_AGENT_SHIFT_SUCCESS,
    API_END_AGENT_SHIFT_FAILURE
  ),
  ticketVoidValidation: loadingReducer(
    API_TICKET_VOID_VALIDATION_REQUEST,
    API_TICKET_VOID_VALIDATION_SUCCESS,
    API_TICKET_VOID_VALIDATION_FAILURE
  ),
  loadUserInformation: loadingReducer(
    API_LOAD_USER_INFORMATION_REQUEST,
    API_LOAD_USER_INFORMATION_SUCCESS,
    API_LOAD_USER_INFORMATION_FAILURE
  ),
  loadAgentInformation: loadingReducer(
    API_LOAD_AGENT_INFORMATION_REQUEST,
    API_LOAD_AGENT_INFORMATION_SUCCESS,
    API_LOAD_AGENT_INFORMATION_FAILURE
  ),
  linkToCustomerEmail: loadingReducer(
    API_LINK_TO_CUSTOMER_EMAIL_REQUEST,
    API_LINK_TO_CUSTOMER_EMAIL_SUCCESS,
    API_LINK_TO_CUSTOMER_EMAIL_FAILURE
  ),
  resetSeatSelection: loadingReducer(
    API_RESET_SEAT_SELECTION_REQUEST,
    API_RESET_SEAT_SELECTION_SUCCESS,
    API_RESET_SEAT_SELECTION_FAILURE
  ),
  resendTickets: loadingReducer(
    API_RESEND_TICKETS_REQUEST,
    API_RESEND_TICKETS_SUCCESS,
    API_RESEND_TICKETS_FAILURE
  ),
  findCustomers: loadingReducer(
    API_FIND_CUSTOMERS_REQUEST,
    API_FIND_CUSTOMERS_SUCCESS,
    API_FIND_CUSTOMERS_FAILURE
  ),
  getDocumentTemplates: loadingReducer(
    API_GET_DOCUMENT_TEMPLATES_REQUEST,
    API_GET_DOCUMENT_TEMPLATES_SUCCESS,
    API_GET_DOCUMENT_TEMPLATES_FAILURE
  ),
  getReceiptDocument: loadingReducer(
    API_GET_RECEIPT_DOCUMENT_REQUEST,
    API_GET_RECEIPT_DOCUMENT_SUCCESS,
    API_GET_RECEIPT_DOCUMENT_FAILURE
  )
}
