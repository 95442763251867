import { connect } from 'react-redux'
import { bookingSelector as aftersalesBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import {
  outboundTariffSegmentsSelector,
  inboundTariffSegmentsSelector,
  groupByUniqueIdentifier
} from 's3p-js-lib/src/redux/selectors/api/booking/tariff-segments'
import { travelSelector } from 's3p-js-lib/src/redux/selectors/api/booking/travel'
import { selectedItemIdsSelector } from '../../../redux/selectors/user-input/aftersales/cancellation'
import {
  selectItemIds,
  deselectItemIds
} from 's3p-js-lib/src/redux/actions/user-input/aftersales/cancellation'
import {
  outboundTariffSegmentsSelector as _outboundTariffSegmentsSelector,
  inboundTariffSegmentsSelector as _inboundTariffSegmentsSelector
} from '../../../redux/selectors/containers/aftersales/cancellation'
import CancelItems from '../../../components/aftersales/cancellation/cancel-items'
import { hasAccessToAftersalesPartialCancellationSelector } from 's3p-js-lib/src/redux/selectors/api/auth/auth'

const _outboundSegments = groupByUniqueIdentifier(outboundTariffSegmentsSelector(aftersalesBookingSelector))
const _inboundSegments = groupByUniqueIdentifier(inboundTariffSegmentsSelector(aftersalesBookingSelector))

const _outboundTravelSelector = travelSelector(_outboundSegments)
const _inboundTravelSelector = travelSelector(_inboundSegments)

const partialCancellationAllowedSelector = hasAccessToAftersalesPartialCancellationSelector(aftersalesBookingSelector)

const mapStateToProps = state => ({
  hasProvisionalBooking: Boolean(bookingSelector(state)),
  disabled: !partialCancellationAllowedSelector(state),
  selectedItemIds: selectedItemIdsSelector(state),
  outboundTravelInfo: _outboundTravelSelector(state),
  outboundSegments: _outboundTariffSegmentsSelector(state),
  inboundTravelInfo: _inboundTravelSelector(state),
  inboundSegments: _inboundTariffSegmentsSelector(state)
})

const mapDispatchToProps = {
  onToggleItem: item => dispatch => {
    if (item.selected) {
      dispatch(deselectItemIds(item.cancelItemIds))
    } else {
      dispatch(selectItemIds(item.cancelItemIds))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelItems)
