import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import classNames from 'classnames'
import TextLabel from '../../../../elements/text-label/text-label'
import Button from '../../../../elements/button/button'
import Icon from '../../../../elements/icon/icon'

export default class PreviousDateSet extends Component {
  static propTypes = {
    handlePreviousDateSet: PropTypes.func.isRequired,
    hasSelectedDate: PropTypes.bool.isRequired,
    disabled: PropTypes.bool
  }

  render () {
    const buttonClassName = classNames('secondary', {'has-selected-date': this.props.hasSelectedDate})

    return (
      <li className='date-switcher__item previous-date-set'>
        <Button
          name='previous-date-set'
          disabled={this.props.disabled}
          className={buttonClassName}
          onClick={this.props.handlePreviousDateSet}
          tabIndex='-1'
        >
          <Icon name='chevron-left' />
          <TextLabel
            name='previous-date-set'
            text={_t.message('journey-result-set.date-switcher.previous-date-set')}
          />
        </Button>
      </li>
    )
  }
}
