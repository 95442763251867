import {createSelector} from 'reselect'

export const segmentsSelector = seatSelectionOptionsSelector => createSelector(
  [
    (state, ownProps) => ownProps.segmentId,
    seatSelectionOptionsSelector
  ],
  (segmentId, seatSelectionOptions) => (seatSelectionOptions || []).map(seatSelectionOption => ({
    ...seatSelectionOption,
    active: seatSelectionOption.leg.id === segmentId
  }))
)
