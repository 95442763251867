import {
  USER_INPUT_REPEAT_LAST_BOOKING_REQUEST,
  USER_INPUT_REPEAT_LAST_BOOKING_SUCCESS,
  USER_INPUT_REPEAT_LAST_BOOKING_FAILURE
} from '../../types'
import { getProducts } from '../../../actions/api/v2/orientation/search'
import { addProduct } from './product-search-result'
import { resetTickets } from './products'
import { itemsSelector } from 's3p-js-lib/src/redux/selectors/api/v2/search'
import { loadValues } from 's3p-js-lib/src/redux/actions/user-input/base/form'
import { FORM_PRODUCT_SEARCH } from 's3p-js-lib/src/constants-form'
import { productFamiliesSelector } from '../../../selectors/api/v2/meta/product-families'
import {
  productsFromLastBookingSelector,
  passengerSelector
} from '../../../selectors/containers/tickets/repeat-last-booking'
import { stationsSelector } from 's3p-js-lib/src/redux/selectors/api/base/stations'

const repeatLastBookingRequest = products => ({type: USER_INPUT_REPEAT_LAST_BOOKING_REQUEST, products})
const repeatLastBookingSuccess = () => ({type: USER_INPUT_REPEAT_LAST_BOOKING_SUCCESS})
const repeatLastBookingFailure = () => ({type: USER_INPUT_REPEAT_LAST_BOOKING_FAILURE})

export const repeatLastBooking = () => async (dispatch, getState) => {
  const passengers = passengerSelector(getState())
  const products = productsFromLastBookingSelector(getState())
  dispatch([resetTickets(), repeatLastBookingRequest(products)])

  let success = true
  let product
  while (products.length) {
    product = products.shift()
    await dispatch(getProducts(product))

    const items = itemsSelector(getState())

    const matchedProduct = items.find(item => item.id === product.productId)
    if (matchedProduct) {
      await dispatch(addProduct(matchedProduct))
    } else {
      success = false
      break
    }
  }

  if (success && product) {
    const stations = stationsSelector(getState())
    const formValues = {
      productFamily: productFamiliesSelector(getState())
        .find(family => family.code === product.productFamily),
      ...product.segments[0],
      origin: product.segments[0].origin ? stations.find(station => station.UICStationCode === product.segments[0].origin) : null,
      destination: product.segments[0].destination ? stations.find(station => station.UICStationCode === product.segments[0].destination) : null,
      passengers
    }
    dispatch([loadValues(FORM_PRODUCT_SEARCH, formValues), repeatLastBookingSuccess()])
  } else {
    dispatch([resetTickets(), repeatLastBookingFailure()])
  }
}
