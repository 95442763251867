import {
  API_CHANGE_SEATS_SELECT_SEATS_REQUEST,
  API_CHANGE_SEATS_SELECT_SEATS_SUCCESS,
  API_CHANGE_SEATS_SELECT_SEATS_FAILURE
} from 's3p-js-lib/src/redux/actions/types'
import Client from '../../../../../api/client'
import ensureToken from 's3p-js-lib/src/api/ensure-token'
import ResponseCodes from 's3p-js-lib/src/api/response-codes'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import { bookingNumberSelector, tariffSegmentsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'

const changeSeatsSelectSeatsRequest = (bookingNumber, selectedSeats, waiveFees, overrideAftersalesRules, overrideValidationsRules) => ({
  type: API_CHANGE_SEATS_SELECT_SEATS_REQUEST,
  bookingNumber,
  selectedSeats,
  waiveFees,
  overrideAftersalesRules,
  overrideValidationsRules
})
const changeSeatsSelectSeatsSuccess = response => ({type: API_CHANGE_SEATS_SELECT_SEATS_SUCCESS, response})
const changeSeatsSelectSeatsFailure = error => ({type: API_CHANGE_SEATS_SELECT_SEATS_FAILURE, error})

export const changeSeatsSelectSeats = (segmentId, selectedSeats, waiveFees, overrideAftersalesRules, overrideValidationsRules) => ensureToken(async (token, dispatch, getState) => {
  try {
    const state = getState()
    const bookingNumber = bookingNumberSelector(bookingSelector)(state)
    const tariffSegments = tariffSegmentsSelector(bookingSelector)(state)
    const tariffSegment = tariffSegments.find(({id}) => id === segmentId)
    const seats = selectedSeats.map(seat => ({
      ...seat,
      product: tariffSegment.required_products.find(
        product => !product.cancelled && product.passenger_id === seat.passengerId
      )
    }))

    dispatch(changeSeatsSelectSeatsRequest(bookingNumber, seats, waiveFees, overrideAftersalesRules, overrideValidationsRules))
    const response = await Client.changeSeatsSelectSeats(token, bookingNumber, seats, waiveFees, overrideAftersalesRules, overrideValidationsRules)
    dispatch(changeSeatsSelectSeatsSuccess(response))
  } catch (error) {
    dispatch(changeSeatsSelectSeatsFailure(error))
    if (ResponseCodes.isSystemError(error)) {
      throw error
    }
    return false
  }
})
