import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Panel from '../../../elements/panel/panel'
import Form from '../../../elements/form/form'
import Button from '../../../elements/button/button'
import TextLabel from '../../../elements/text-label/text-label'
import Feedback, { FEEDBACK_WARNING } from '../../../elements/feedback/feedback'

export default class StartShift extends Component {
  static propTypes = {
    onStartShift: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    form: PropTypes.shape({
      fields: PropTypes.shape({
        amount: PropTypes.object
      }),
      valid: PropTypes.bool
    }),
    willPasswordExpireSoon: PropTypes.object
  }

  handleFocus (event) {
    event.target.select()
  }

  render () {
    return (
      <Panel
        header={
          <Panel.Header
            name='start-shift'
            title={_t.message('agent.shift.start.title')}
            description={_t.message('agent.shift.start.description')} />
        }
      >
        {this.props.willPasswordExpireSoon.willExpireSoon
          ? <Feedback status={FEEDBACK_WARNING} text={_t.message('agent.shift.password-will-expire-soon', {days: this.props.willPasswordExpireSoon.expiresInDays})} />
          : null
        }
        <TextLabel name='information' text={_t.message('agent.shift.start.information')} />
        <Form formContext='agent.shift.start'>
          <fieldset>
            <Form.InputGroup name='start-amount'>
              <Form.InputWrapper name='label'>
                <Form.Label
                  htmlFor='start-amount'
                  name='amount'
                />
              </Form.InputWrapper>
              <Form.InputWrapper name='value'>
                <Form.CurrencyField
                  {...this.props.form.fields.amount}
                  onFocus={this.handleFocus}
                  id='start-amount'
                  name='amount'
                  autoComplete='off'
                  autoFocus
                  disabled={this.props.loading}
                />
              </Form.InputWrapper>
            </Form.InputGroup>
            <Form.InputGroup name='actions'>
              <Form.InputWrapper name='actions'>
                <div className='button-group'>
                  <Button
                    className='tertiary'
                    name='logout'
                    onClick={this.props.onLogout}
                    hotkey='logout'
                    disabled={this.props.loading}
                  >
                    <TextLabel text={_t.message('agent.shift.start.logout')} />
                  </Button>
                  <Button
                    type='submit'
                    className='primary'
                    name='start-shift'
                    disabled={!this.props.form.valid}
                    onClick={this.props.onStartShift}
                    loading={this.props.loading}
                    hotkey='incrementStudent'
                  >
                    <TextLabel text={_t.message('agent.shift.start.form.button')} />
                  </Button>
                </div>
              </Form.InputWrapper>
            </Form.InputGroup>
          </fieldset>
        </Form>
      </Panel>
    )
  }
}
