import {
  MACHINE_PRINT_MAGNETIC_TICKET_REQUEST,
  MACHINE_PRINT_MAGNETIC_TICKET_SUCCESS,
  MACHINE_PRINT_MAGNETIC_TICKET_FAILURE,
  MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_REQUEST,
  MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_SUCCESS,
  MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_FAILURE,
  MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_REQUEST,
  MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_SUCCESS,
  MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_FAILURE,
  MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_REQUEST,
  MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_SUCCESS,
  MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_FAILURE,
  MACHINE_READ_MAGNETIC_TICKET_REQUEST,
  MACHINE_READ_MAGNETIC_TICKET_SUCCESS,
  MACHINE_READ_MAGNETIC_TICKET_FAILURE,
  MACHINE_SET_DARKNESS_REQUEST,
  MACHINE_SET_DARKNESS_SUCCESS,
  MACHINE_SET_DARKNESS_FAILURE
} from '../../actions/types'
import { MACHINE_MAGNETIC_TICKET_PRINTER } from '../../../constants'
import { CLEAR_STATE } from 's3p-js-lib/src/redux/actions/types'
import { statusReducer, initialState as initialStateTestStatus } from '../../../misc/reducers'

const initialState = {
  counter: null,
  serialNumber: null,
  error: null,
  magneticData: null,
  lastStatus: null,
  testStatus: initialStateTestStatus,
  printerDarkness: null,
  templates: null
}

const _statusReducer = statusReducer(
  MACHINE_SET_DARKNESS_REQUEST,
  MACHINE_SET_DARKNESS_SUCCESS,
  MACHINE_SET_DARKNESS_FAILURE
)

export default (state = initialState, action) => {
  switch (action.type) {
    case MACHINE_PRINT_MAGNETIC_TICKET_REQUEST:
      return {...state, lastStatus: null, error: null}
    case MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_REQUEST:
    case MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_SUCCESS:
    case MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_REQUEST:
    case MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_SUCCESS:
    case MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_REQUEST:
      return {...state, error: null}
    case MACHINE_PRINT_MAGNETIC_TICKET_SUCCESS:
      return {
        ...state,
        lastStatus: action.response.status,
        counter: action.response.results.counter,
        serialNumber: action.response.results.serial_number
      }
    case MACHINE_PRINT_MAGNETIC_TICKET_FAILURE:
      return {
        ...state,
        lastStatus: action.machineError.response && (action.machineError.response.status || initialState.lastStatus),
        error: action.machineError,
        counter: action.machineError.response && action.machineError.response.results && action.machineError.response.results.counter
          ? action.machineError.response.results.counter
          : null,
        serialNumber: action.machineError.response && action.machineError.response.results && action.machineError.response.results.serial_number
          ? action.machineError.response.results.serial_number
          : null
      }
    case MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_FAILURE:
    case MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_FAILURE:
      return {...state, error: action.machineError}
    case MACHINE_READ_MAGNETIC_TICKET_REQUEST:
    case MACHINE_READ_MAGNETIC_TICKET_FAILURE:
      return {...state, magneticData: null}
    case MACHINE_READ_MAGNETIC_TICKET_SUCCESS:
      return {...state, magneticData: action.response.results.magnetic_data}
    case MACHINE_SET_DARKNESS_REQUEST:
    case MACHINE_SET_DARKNESS_FAILURE:
      return {
        ...state, testStatus: _statusReducer(state, action)
      }
    case MACHINE_SET_DARKNESS_SUCCESS:
      return {
        ...state,
        testStatus: _statusReducer(state, action),
        printerDarkness: action.response.results.printer_darkness
      }
    case MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_SUCCESS:
      return {
        ...state,
        error: null,
        templates: action.response ? action.response.results : []
      }
    case MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_FAILURE:
      return {
        ...state,
        error: action.machineError,
        templates: null
      }
    case CLEAR_STATE:
      return action.namespaces.includes(MACHINE_MAGNETIC_TICKET_PRINTER) ? initialState : state
    default:
      return state
  }
}
