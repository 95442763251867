import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formFieldValueSelectorCreator } from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import ProductFamily from '../../../components/tickets/product-family/product-family'
import { modalIsActiveSelector } from '../../../redux/selectors/containers/base/visible-element'
import { FORM_PRODUCT_SEARCH } from 's3p-js-lib/src/constants-form'
import { ticketsApiLoadingSelector } from '../../../redux/selectors/containers/tickets/loading'

const FIELD_ID_DESTINATION = 'destination_input'

class ProductFamilyContainer extends Component {
  static propTypes = {
    handleSearchProducts: PropTypes.func.isRequired,
    productFamilies: PropTypes.array.isRequired,
    handleSelectTile: PropTypes.func.isRequired,
    handleLoadFormValues: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    selectedProductFamily: PropTypes.shape({
      code: PropTypes.string
    }),
    paramProductFamily: PropTypes.string
  }

  constructor (...args) {
    super(...args)

    this.handleSelect = this.handleSelect.bind(this)
  }

  UNSAFE_componentWillMount () {
    if (this._checkIfDefaultShouldBeLoaded(this.props)) {
      this._loadDefaultProductFamily(this.props)
    }
    if (this.props.paramProductFamily) {
      this.props.handleLoadFormValues({
        productFamily: this.props.productFamilies.find(productFamily => productFamily.code === this.props.paramProductFamily)
      })
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const productFamiliesAreNowAvailable = !this.props.productFamilies.length && nextProps.productFamilies.length
    if (productFamiliesAreNowAvailable || this._checkIfDefaultShouldBeLoaded(nextProps)) {
      this._loadDefaultProductFamily(nextProps)
    }
  }

  _checkIfDefaultShouldBeLoaded (nextProps) {
    return nextProps.productFamilies.length > 0 && !nextProps.selectedProductFamily
  }

  _loadDefaultProductFamily (nextProps) {
    nextProps.handleLoadFormValues({productFamily: nextProps.productFamilies[0]})
  }

  handleSelect (productFamily) {
    this.props.handleSelectTile({productFamily})
    const element = document.getElementById(FIELD_ID_DESTINATION)
    element && setTimeout(() => element.focus(), 200)
  }

  render () {
    return (
      <ProductFamily
        disabled={this.props.disabled}
        productFamilies={this.props.productFamilies}
        handleSelect={this.handleSelect}
      />
    )
  }
}

const formFieldValueSelector = formFieldValueSelectorCreator(FORM_PRODUCT_SEARCH, 'productFamily')
const mapStateToProps = state => ({
  disabled: modalIsActiveSelector(state) || ticketsApiLoadingSelector(state),
  selectedProductFamily: formFieldValueSelector(state)
})

export default connect(mapStateToProps)(ProductFamilyContainer)
