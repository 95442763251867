import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  getCarriageLayoutsAftersales
} from '../../../../redux/actions/containers/aftersales/seat-selector/carriage-layouts'
import {
  selectFirstPassenger,
  selectFirstCarriage
} from '../../../../redux/actions/containers/base/seat-selector/seat-selector'
import { selectSegment } from '../../../../redux/actions/user-input/base/seat-selector'
import { carriagesSelector } from 's3p-js-lib/src/redux/selectors/api/base/seat-selector/carriage-layouts'
import { destroySeatSelector } from 's3p-js-lib/src/redux/actions/user-input/base/seat-selector'
import SeatSelectorWrapper from '../../../../components/base/seat-selector/seat-selector-wrapper'
import { tariffSegmentSelector } from '../../../../redux/selectors/containers/aftersales/seat-change'

class SeatSelectorWrapperContainer extends Component {
  static propTypes = {
    segmentId: PropTypes.string.isRequired,
    hasCarriages: PropTypes.bool.isRequired,
    loadSegment: PropTypes.func.isRequired,
    selectFirstCarriage: PropTypes.func.isRequired,
    destroySeatSelector: PropTypes.func.isRequired,
    tariffSegment: PropTypes.object
  }

  UNSAFE_componentWillMount () {
    this.props.loadSegment(this.props.segmentId, this.props.tariffSegment)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!this.props.hasCarriages && nextProps.hasCarriages) {
      nextProps.selectFirstCarriage(true)
    } else if (this.props.segmentId !== nextProps.segmentId) {
      nextProps.loadSegment(nextProps.segmentId, nextProps.tariffSegment)
    }
  }

  render () {
    return <SeatSelectorWrapper {...this.props} />
  }
}

const mapStateToProps = (state, ownProps) => ({
  isLoadingSeatSelectionOptions: state.api.loading.getSeatSelectionOptions,
  hasCarriages: carriagesSelector(state).length > 0,
  tariffSegment: tariffSegmentSelector(state, ownProps)
})

const mapDispatchToProps = {
  loadSegment: (segmentId, tariffSegment) => [
    destroySeatSelector(),
    selectSegment(segmentId),
    getCarriageLayoutsAftersales(tariffSegment),
    selectFirstPassenger(true)
  ],
  selectFirstCarriage,
  destroySeatSelector
}

export default connect(mapStateToProps, mapDispatchToProps)(SeatSelectorWrapperContainer)
