import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Icon from '../../../elements/icon/icon'
import Button from '../../../elements/button/button'
import TextLabel from '../../../elements/text-label/text-label'
import BaseProgressNavigation from '../../../components/aftersales/progress-navigation/progress-navigation'
import '../../../elements/sticky-footer/sticky-footer.scss'

export default class ProgressNavigation extends Component {
  static propTypes = {
    disableConfirm: PropTypes.bool.isRequired,
    handleClickNext: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isPaymentRequired: PropTypes.bool
  }

  render () {
    return (
      <BaseProgressNavigation isAdditionalProducts>
        <Button
          name='next-step'
          className='tertiary'
          disabled={this.props.disableConfirm}
          onClick={this.props.handleClickNext}
          loading={this.props.isLoading}
        >
          <TextLabel name='confirm'>
            {this.props.isPaymentRequired
              ? <TextLabel
                name='next-additional-products-step-description'
                text={_t.message('aftersales.additional-products.confirm-sub-pay')} />
              : <TextLabel
                text={_t.message('aftersales.additional-products.confirm')} />
            }
            <Icon name='chevron-right' className='align-right' />
          </TextLabel>
        </Button>
      </BaseProgressNavigation>
    )
  }
}
