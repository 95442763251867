import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import TextLabel from '../../../../elements/text-label/text-label'
import Sprite from '../../../../elements/sprite/sprite'

export default class Seat extends Component {
  static propTypes = {
    available: PropTypes.bool.isRequired,
    selected: PropTypes.bool.isRequired,
    selectedByPassenger: PropTypes.bool.isRequired,
    reversed: PropTypes.bool.isRequired,
    seatNumber: PropTypes.string.isRequired,
    xPos: PropTypes.number.isRequired,
    yPos: PropTypes.number.isRequired,
    handleSelect: PropTypes.func.isRequired,
    firstAvailable: PropTypes.bool
  }

  _getSpriteName () {
    const selected = Boolean(this.props.selectedByPassenger)
    const available = this.props.available && !selected
    const unavailable = !this.props.available && !selected
    const reversed = this.props.reversed

    return classNames(
      {'carriage-floorplan-seat-selected': !reversed && selected},
      {'carriage-floorplan-seat': !reversed && available},
      {'carriage-floorplan-seat-unavailable': !reversed && unavailable},
      {'carriage-floorplan-seat-reversed-selected': reversed && selected},
      {'carriage-floorplan-seat-reversed': reversed && available},
      {'carriage-floorplan-seat-reversed-unavailable': reversed && unavailable}
    )
  }

  componentDidMount () {
    const elements = document.getElementsByClassName('first')
    elements.length > 0 && elements[0].focus()
  }

  render () {
    const props = {
      className: classNames('carriage-floorplan-seat',
        {reversed: this.props.reversed},
        {'state--is-available': this.props.available},
        {'state--is-selected': this.props.selected},
        {'state--is-highlighted': this.props.selectedByPassenger},
        {'state--is-disabled': !this.props.available && !this.props.selected},
        {'first': this.props.firstAvailable}
      ),
      style: {
        top: this.props.yPos,
        left: this.props.xPos
      }
    }
    if (this.props.available) {
      props.tabIndex = '0'
      props.role = 'option'
      props.onClick = this.props.handleSelect
    } else if (this.props.selected) {
      props['aria-selected'] = 'true'
    } else {
      props['aria-disabled'] = 'true'
    }

    return (
      <div {...props}>
        <TextLabel name='seat-number' text={this.props.seatNumber} />
        {!this.props.selected && !this.props.selectedByPassenger ? <Sprite name={this._getSpriteName()} /> : null}
      </div>
    )
  }
}
