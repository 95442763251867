import {
  API_ADD_VOUCHER_REQUEST,
  API_ADD_VOUCHER_SUCCESS,
  API_ADD_VOUCHER_FAILURE,
  API_REMOVE_VOUCHER_REQUEST,
  API_REMOVE_VOUCHER_SUCCESS,
  API_REMOVE_VOUCHER_FAILURE
} from '../../types'
import Client from '../../../../../../src/api/client'
import ResponseCodes from '../../../../api/response-codes'
import { bookingSelector, bookingNumberSelector } from '../../../selectors/api/booking/booking'
import ensureToken from '../../../../api/ensure-token'

const addVoucherRequest = voucherCode => ({type: API_ADD_VOUCHER_REQUEST, voucherCode})
const addVoucherSuccess = response => ({type: API_ADD_VOUCHER_SUCCESS, response})
const addVoucherFailure = error => ({type: API_ADD_VOUCHER_FAILURE, error})

const removeVoucherRequest = voucherCode => ({type: API_REMOVE_VOUCHER_REQUEST, voucherCode})
const removeVoucherSuccess = response => ({type: API_REMOVE_VOUCHER_SUCCESS, response})
const removeVoucherFailure = error => ({type: API_REMOVE_VOUCHER_FAILURE, error})

export const addVoucher = voucherCode => ensureToken(async (token, dispatch, getState) => {
  try {
    dispatch(addVoucherRequest(voucherCode))
    const bookingNumber = bookingNumberSelector(bookingSelector)(getState())
    const response = await Client.addVoucher(token, bookingNumber, voucherCode)

    dispatch(addVoucherSuccess(response))
  } catch (error) {
    dispatch(addVoucherFailure(error))
    if (ResponseCodes.isSystemError(error)) {
      throw error
    }
    return false
  }
})

export const removeVoucher = voucherCode => ensureToken(async (token, dispatch, getState) => {
  try {
    dispatch(removeVoucherRequest(voucherCode))
    const bookingNumber = bookingNumberSelector(bookingSelector)(getState())
    const response = await Client.removeVoucher(token, bookingNumber, voucherCode)

    dispatch(removeVoucherSuccess(response))
  } catch (error) {
    dispatch(removeVoucherFailure(error))
    if (ResponseCodes.isSystemError(error)) {
      throw error
    }
    return false
  }
})
