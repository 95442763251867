import moment from 'moment-timezone'
import {toUtcDateMoment} from 's3p-js-lib/src/misc/date'
import {snakeCaseKeys} from 's3p-js-lib/src/misc/utils'
import {TRAVEL_DIRECTION_OUTBOUND} from 's3p-js-lib/src/constants'
import sortBy from 'lodash/sortBy'

const v2ToV1Bundle = (v2comfortZones, v2legs) => v2Bundle => {
  const v1Bundle = {
    ...v2Bundle,
    product_family_id: `product-family-${v2Bundle.product_family_id}`,
    required_products: v2Bundle.items.filter(item => item.required).map(item => ({
      name: item.name,
      description: item.description,
      id: `product-${item.product_code}`,
      type: item.product_type,
      code: item.product_code,
      meta_data: item.meta_data,
      product_family_id: `product-family-${item.product_family_id}`,
      after_sales_rule_sets: item.after_sales_rule_sets,
      leg_refs: item.leg_ids,
      valid_days: item.valid_days,
      valid_trips: item.valid_trips,
      comfort_zone: v2comfortZones.find(v2comfortZone => v2comfortZone.code === item.comfort_zone_code)
    })),
    additional_products_summaries: v2Bundle.items.filter(item => !item.required).reduce(
      (addons, item) => {
        const existingAddon = addons.find(addon => addon.product_type === item.product_type)
        const itemLowestPrice = item.passenger_fares.reduce((lowestPrice, passengerFare) => {
          if (lowestPrice === null || passengerFare.price < lowestPrice) {
            lowestPrice = passengerFare.price
          }
          return lowestPrice
        }, null)
        if (!existingAddon) {
          addons.push({
            product_type: item.product_type,
            lowest_price: itemLowestPrice
          })
        } else if (existingAddon.lowest_price > itemLowestPrice) {
          existingAddon.lowest_price = itemLowestPrice
        }
        return addons
      }, []
    )
  }
  const bundleLegs = v2legs.filter(v2leg => v2Bundle.items.flatMap(v2BundleItem => v2BundleItem.leg_ids).includes(v2leg.id))
  const bundleLowestLogicalAvailabilities = bundleLegs.flatMap(bundleLeg => bundleLeg.logical_availability).reduce(
    (bundleLowestLogicalAvailabilities, logicalAvailability) => {
      const existingBundleLowestLogicalAvailability = bundleLowestLogicalAvailabilities.find(
        bundleLowestLogicalAvailability => bundleLowestLogicalAvailability.bucket_code === logicalAvailability.bucket_code
      )
      if (!existingBundleLowestLogicalAvailability) {
        bundleLowestLogicalAvailabilities.push(logicalAvailability)
      }
      return bundleLowestLogicalAvailabilities
    }, []
  )

  v1Bundle.passenger_prices = v2Bundle.items.filter(item => item.required).reduce(
    (passengerPrices, item) => {
      item.passenger_fares.reduce((passengerPrices, itemPassengerFare) => {
        const existingPassengerPrice = passengerPrices.find(passengerPrice => passengerPrice.id === itemPassengerFare.passenger_id)
        if (!existingPassengerPrice) {
          passengerPrices.push({
            id: itemPassengerFare.passenger_id,
            price: itemPassengerFare.price,
            bucket_code: itemPassengerFare.bucket_code,
            inventory_class: itemPassengerFare.inventory_class
          })
        } else {
          existingPassengerPrice.price = Math.round((existingPassengerPrice.price + itemPassengerFare.price + Number.EPSILON) * 100) / 100
        }
        return passengerPrices
      }, passengerPrices)
      return passengerPrices
    }, []
  )
  bundleLowestLogicalAvailabilities.forEach(logicalAvailability => {
    if (logicalAvailability.bucket_code === v1Bundle.passenger_prices[0].bucket_code) {
      v1Bundle.availability = v1Bundle.availability !== undefined ? Math.min(v1Bundle.availability, logicalAvailability.logical) : logicalAvailability.logical
    }
  })

  // v1Bundle
  return v1Bundle
}

const filterRouteWithEarliestDepartingLegInRange = (routes, timeEarliest, timeLatest) =>
  routes.reduce((routesWithEarliestDepartingLegInRange, route) => {
    const sortedLegs = sortBy(route.legs, ['departure_station.departure_timestamp'])
    const earliestDepartingLegTimestamp = sortedLegs[0].departure_station.departure_timestamp
    const earliestDepartingLegMoment = moment(earliestDepartingLegTimestamp).utc()
    const earliest = earliestDepartingLegMoment.clone()
      .set('hour', parseInt(timeEarliest.split(':')[0]))
      .set('minute', parseInt(timeEarliest.split(':')[1]))
      .set('second', parseInt(timeEarliest.split(':')[2]))
    const latest = earliestDepartingLegMoment.clone()
      .set('hour', parseInt(timeLatest.split(':')[0]))
      .set('minute', parseInt(timeLatest.split(':')[1]))
      .set('second', parseInt(timeLatest.split(':')[2]))
    const isInRange = earliestDepartingLegMoment.isBetween(earliest, latest)
    if (isInRange) {
      routesWithEarliestDepartingLegInRange.push(route)
    }
    return routesWithEarliestDepartingLegInRange
  }, [])

export const v2ToV1JourneySearchResponse = (data, journeySearchRequest) => {
  data.data.offer.product_families.forEach(productFamily => {
    productFamily.id = `product-family-${productFamily.id}`
  })
  data.data.offer.travels.forEach(travel => {
    if (travel.direction === TRAVEL_DIRECTION_OUTBOUND) {
      travel.direction = 'outward'
      travel.origin = journeySearchRequest.origin
      travel.destination = journeySearchRequest.destination
    } else {
      travel.direction = 'return'
      travel.origin = journeySearchRequest.destination
      travel.destination = journeySearchRequest.origin
    }
    travel.departure_date = moment(travel.departure_date).utc().format('YYYY-MM-DDTHH:mm:ss+0000')

    // handle missing v2 time filters
    if (travel.direction === 'outward' &&
      journeySearchRequest.outboundTimeEarliest &&
      journeySearchRequest.outboundTimeLatest
    ) {
      travel.routes = filterRouteWithEarliestDepartingLegInRange(
        travel.routes,
        journeySearchRequest.outboundTimeEarliest,
        journeySearchRequest.outboundTimeLatest
      )
    }
    if (travel.direction === 'return' &&
      journeySearchRequest.inboundTimeEarliest &&
      journeySearchRequest.inboundTimeLatest
    ) {
      travel.routes = filterRouteWithEarliestDepartingLegInRange(
        travel.routes,
        journeySearchRequest.inboundTimeEarliest,
        journeySearchRequest.inboundTimeLatest
      )
    }

    travel.routes.forEach(route => {
      route.travel_time = route.travel_duration
      route.best_bundle_ids = route.best_bundle_ids || []
      route.legs.forEach(leg => {
        leg.service_schedule_date = toUtcDateMoment(leg.service_schedule_date).format('YYYY-MM-DD')
        leg.arrival_station.arrival_timestamp = moment(leg.arrival_station.arrival_timestamp).utc().format('YYYY-MM-DDTHH:mm:ssZZ')
        leg.departure_station.departure_timestamp = moment(leg.departure_station.departure_timestamp).utc().format('YYYY-MM-DDTHH:mm:ssZZ')
      })
      route.transfers.forEach(transfer => {
        const arrivalDateTime = transfer.arrival_date_time
        transfer.arrival_date_time = moment(transfer.departure_date_time).utc().format('YYYY-MM-DDTHH:mm:ssZZ')
        transfer.departure_date_time = moment(arrivalDateTime).utc().format('YYYY-MM-DDTHH:mm:ssZZ')
        transfer.transfer_description = transfer.description
      })
      route.bundles = route.bundles.map(v2ToV1Bundle(data.data.offer.comfort_zones, route.legs))
    })
  })
  const result = Object.assign({
    journey_search: {
      id: window.btoa(JSON.stringify(journeySearchRequest)),
      origin_station: journeySearchRequest.origin,
      destination_station: journeySearchRequest.destination,
      departure_date: journeySearchRequest.outboundDate && journeySearchRequest.outboundDate.utc().format(),
      return_date: journeySearchRequest.inboundDate && journeySearchRequest.inboundDate.utc().format(),
      passengers: journeySearchRequest.passengers,
      currency: journeySearchRequest.currency,
      product_families: journeySearchRequest.productFamilies,
      product_types: journeySearchRequest.productTypes || [],
      outbound_time_earliest: journeySearchRequest.outboundTimeEarliest,
      outbound_time_latest: journeySearchRequest.outboundTimeLatest,
      inbound_time_earliest: journeySearchRequest.inboundTimeEarliest,
      inbound_time_latest: journeySearchRequest.inboundTimeLatest,
      // TODO
      outbound_cursor: journeySearchRequest.outboundCursor,
      inbound_cursor: journeySearchRequest.inboundCursor
    }
  }, data.data)
  return {data: result}
}

const mapToDate = date => {
  const momentObject = date && toUtcDateMoment(date)
  return momentObject && momentObject.isValid() ? momentObject : null
}

export const prepareGetOfferPostData = (data, isAftersales = false) => {
  const result = {}
  const outboundDate = mapToDate(data.outboundDate)
  const travels = data.travels || []
  if (outboundDate) {
    const travel = {
      origin: data.origin,
      destination: data.destination,
      direction: 'outbound',
      id: 'travel_1',
      departure: toUtcDateMoment(outboundDate).format('YYYY-MM-DD')
    }
    if (data.productFamilies) {
      travel.product_families = data.productFamilies
    }
    travels.push(travel)
  }

  // TODO Deprecated in v2
  if (data.outboundTimeEarliest && data.outboundTimeLatest) {
    result.departure_time_filter = {
      earliest: data.outboundTimeEarliest,
      latest: data.outboundTimeLatest
    }
  }

  const inboundDate = mapToDate(data.inboundDate)
  if (inboundDate) {
    const travel = {
      origin: data.inboundOrigin || data.destination,
      destination: data.inboundDestination || data.origin,
      direction: 'inbound',
      id: 'travel_2',
      departure: toUtcDateMoment(inboundDate).format('YYYY-MM-DD')
    }
    if (data.productFamilies) {
      travel.product_families = data.productFamilies
    }
    travels.push(travel)
  }

  // TODO Deprecated in v2
  if (data.inboundTimeEarliest && data.inboundTimeLatest) {
    result.return_time_filter = {
      earliest: data.inboundTimeEarliest,
      latest: data.inboundTimeLatest
    }
  }

  if (data.currency) {
    result.currency = data.currency
  }

  // TODO Deprecated in v2
  if (data.outboundCursor) {
    result.outbound_cursor = data.outboundCursor
  }
  // TODO Deprecated in v2
  if (data.inboundCursor) {
    result.inbound_cursor = data.inboundCursor
  }

  return {
    ...result,
    is_aftersales: isAftersales,
    currency: data.currency,
    passengers: data.passengers.map(snakeCaseKeys),
    travels
  }
}
