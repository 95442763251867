import request from './request'
import {STATUS_ERROR} from './device-manager'
import {createError, createWarning} from './response-handler'

export const translateResponse = response => {
  const messages = []

  if ((response.status || response.Status) === STATUS_ERROR && response.results) {
    messages.push(createWarning(response.results.message, {translated: true}))
  }

  if ((response.status || response.Status) === STATUS_ERROR && response.Details) {
    messages.push(createError('cash-drawer.not-responding'))
  }

  return messages
}

export default {
  async openDrawer () {
    return request({
      method: 'post',
      endpoint: '/vtm/devices/rest/CashDrawer/release',
      translateResponse
    })
  }
}
