import {
  API_OAUTH_REFRESH_TOKEN_REQUEST,
  API_OAUTH_REFRESH_TOKEN_SUCCESS,
  API_OAUTH_REFRESH_TOKEN_FAILURE
} from '../../types'

// eslint-disable-next-line func-style
export function oauthRefreshTokenRequest () {
  return {type: API_OAUTH_REFRESH_TOKEN_REQUEST}
}

// eslint-disable-next-line func-style
export function oauthRefreshTokenSuccess (response) {
  return {type: API_OAUTH_REFRESH_TOKEN_SUCCESS, response}
}

// eslint-disable-next-line func-style
export function oauthRefreshTokenFailure (error) {
  return {type: API_OAUTH_REFRESH_TOKEN_FAILURE, error}
}
