import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Feedback, { FEEDBACK_ALERT } from '../../../elements/feedback/feedback'
import Form from '../../../elements/form/form'
import Label from '../../../elements/label/label'
import TextLabel from '../../../elements/text-label/text-label'
import {
  CURRENCY_EURO,
  CURRENCY_BRITISH_POUND
} from '../../../constants'
import FeedbackMachine from '../../../containers/base/last-machine-error-feedback'

export default class CashForm extends Component {
  static propTypes = {
    amountToBePaid: PropTypes.number.isRequired,
    amountToBePaidGBP: PropTypes.number.isRequired,
    amountChange: PropTypes.number.isRequired,
    apiError: PropTypes.bool,
    paymentCurrency: PropTypes.string.isRequired,
    amountReceived: PropTypes.number.isRequired,
    handleChangeAmountReceived: PropTypes.func.isRequired,
    handleChangeCurrency: PropTypes.func.isRequired
  }

  constructor (...args) {
    super(...args)

    this._handleChangeCurrency = this._handleChangeCurrency.bind(this)
  }

  handleFocus (event) {
    event.target.select()
  }

  _handleChangeCurrency (event) {
    this.props.handleChangeCurrency(event)
    const amountReceived = document.getElementById('amount-received')
    amountReceived && amountReceived.focus()
  }

  render () {
    return (
      <Form formContext='cash-payment'>
        <FeedbackMachine namespaces={['cashDrawer']} />
        {this.props.apiError && <Feedback status={FEEDBACK_ALERT} text={_t.message('cash-payment.feedback.api-error')} />}
        <fieldset>
          <Form.InputGroup name='amount-to-be-paid'>
            <Form.InputWrapper name='label'>
              <TextLabel
                name='amount-to-be-paid-description'
                text={_t.message('cash-payment.form.amount-to-be-paid.label')}
              />
            </Form.InputWrapper>
            <Form.InputWrapper name='value'>
              <TextLabel
                id='amount-to-be-paid-value'
                name='amount-to-be-paid-value'
                text={_t.formatCurrency(this.props.amountToBePaid)}
              />
              {this.props.paymentCurrency === CURRENCY_BRITISH_POUND
                ? <TextLabel
                  id='amount-to-be-paid-gbp-value'
                  name='amount-to-be-paid-gbp-value'
                  text={_t.message('cash-payment.form.amount-to-be-paid.price-GBP', {price: this.props.amountToBePaidGBP})}
                /> : null}
            </Form.InputWrapper>
          </Form.InputGroup>
          <Form.InputGroup name='amount-received'>
            <Form.InputWrapper name='label'>
              <Label
                htmlFor='amount-received'
                name='amount-received'
                text={_t.message('cash-payment.form.amount-received.label')}
              />
            </Form.InputWrapper>
            <Form.InputWrapper name='amount-received'>
              <Form.CurrencyField
                name='amount-received'
                id='amount-received'
                autoFocus
                value={this.props.amountReceived}
                onChange={this.props.handleChangeAmountReceived}
                placeholder={_t.message('cash-payment.form.amount-received.placeholder')}
                autoComplete='off'
                onFocus={this.handleFocus}
              />
            </Form.InputWrapper>
            <Form.InputWrapper name='currency-selector'>
              <Form.RadioGroup name='currency-selector' value={this.props.paymentCurrency}>
                <Form.RadioGroup.RadioItem
                  id='currency-eur'
                  name='currency-eur'
                  groupName='currency'
                  hotkey='focusEuro'
                  value={CURRENCY_EURO}
                  onChange={this._handleChangeCurrency}
                />
                <Form.RadioGroup.RadioItem
                  id='currency-gbp'
                  name='currency-gbp'
                  groupName='currency'
                  hotkey='focusGbp'
                  value={CURRENCY_BRITISH_POUND}
                  onChange={this._handleChangeCurrency}
                />
              </Form.RadioGroup>
            </Form.InputWrapper>
          </Form.InputGroup>
          <Form.InputGroup name='change'>
            <Form.InputWrapper name='label'>
              <TextLabel
                text={_t.message('cash-payment.form.amount-change.label')}
                name='change-description'
              />
            </Form.InputWrapper>
            <Form.InputWrapper name='value'>
              <TextLabel
                id='change-value'
                text={_t.formatCurrency(this.props.amountChange)}
                name='change-value'
              />
            </Form.InputWrapper>
          </Form.InputGroup>
        </fieldset>
      </Form>
    )
  }
}
