import { createSelector } from 'reselect'
import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'
import { stationsSelector } from 's3p-js-lib/src/redux/selectors/api/base/stations'
import { machineStationSelector } from '../stations'
import { toUtcDateMoment } from 's3p-js-lib/src/misc/date'
import { JOURNEY_SINGLE, JOURNEY_RETURN, PASSENGER_TYPE_ADULT } from 's3p-js-lib/src/constants'

const getMoment = timestamp => timestamp && toUtcDateMoment(timestamp)

export const journeySearchFormValuesSelector = createSelector(
  [
    state => state.api.orientation.journeySearch || {},
    machineStationSelector,
    stationsSelector
  ],
  (journeySearch, defaultOriginStation, stations) => {
    const getStation = code => stations
      ? stations.find(station => station.UICStationCode === code)
      : (code ? {UICStationCode: code, name: ''} : undefined)

    const inboundDate = getMoment(journeySearch.return_date)

    return omitBy({
      origin: getStation(journeySearch.origin_station) || defaultOriginStation,
      destination: getStation(journeySearch.destination_station),
      outboundDate: getMoment(journeySearch.departure_date) || toUtcDateMoment(),
      outboundTimeEarliest: journeySearch.departure_time_filter && journeySearch.departure_time_filter.earliest,
      outboundTimeLatest: journeySearch.departure_time_filter && journeySearch.departure_time_filter.latest,
      inboundDate,
      inboundTimeEarliest: journeySearch.return_time_filter && journeySearch.return_time_filter.earliest,
      inboundTimeLatest: journeySearch.return_time_filter && journeySearch.return_time_filter.latest,
      passengers: journeySearch.passengers ? journeySearch.passengers.map(passenger => ({
        id: passenger.id,
        type: passenger.type,
        disabilityType: passenger.disability_type,
        discountCards: passenger.discount_cards || []
      })) : [{type: PASSENGER_TYPE_ADULT}],
      singleReturnSelector: inboundDate ? JOURNEY_RETURN : JOURNEY_SINGLE
    }, isEmpty)
  }
)
