import { connect } from 'react-redux'
import EditModal from '../../../../components/tickets/sidebar/shortcuts/edit-modal'
import { FORM_EDIT_SHORTCUT } from '../../../../constants'
import {
  formIsValidSelectorCreator
} from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import { saveShortcut } from '../../../../redux/actions/containers/tickets/sidebar-shortcuts'

const formValidSelector = formIsValidSelectorCreator(FORM_EDIT_SHORTCUT)

const mapStateToProps = state => ({
  isLoadingUpdateAgent: state.api.loading.agentUpdate,
  sequenceNumber: state.containers.tickets.shortcuts.selectedShortcutNr,
  validForm: formValidSelector(state)
})

const mapDispatchToProps = {
  handleSaveShortcut: saveShortcut
}

export default connect(mapStateToProps, mapDispatchToProps)(EditModal)
