import {
  API_GET_BOOKINGS_REQUEST,
  API_GET_BOOKINGS_SUCCESS,
  API_GET_BOOKINGS_FAILURE,
  CLEAR_STATE
} from '../../../actions/types'

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_GET_BOOKINGS_REQUEST:
      return initialState
    case API_GET_BOOKINGS_SUCCESS:
      return action.response.length > 0 ? action.response : initialState
    case API_GET_BOOKINGS_FAILURE:
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes('api.booking.bookingList') ? initialState : state
    default:
      return state
  }
}
