import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import ComponentContainer from '../../../elements/component-container/component-container'
import Panel from '../../../elements/panel/panel'
import Icon from '../../../elements/icon/icon'
import Button from '../../../elements/button/button'
import TextLabel from '../../../elements/text-label/text-label'
import Feedback, {
  FEEDBACK_SUCCESS,
  FEEDBACK_ALERT
} from '../../../elements/feedback/feedback'
import './void-fulfillment.scss'

export default class VoidTickets extends Component {
  static propTypes = {
    ticketNumber: PropTypes.number.isRequired,
    totalTickets: PropTypes.number.isRequired,
    isRunning: PropTypes.bool.isRequired,
    success: PropTypes.bool,
    voidingNotPossibleWithinTime: PropTypes.bool,
    isLoadingRead: PropTypes.bool,
    isLoadingVoid: PropTypes.bool,
    isLoadingEject: PropTypes.bool,
    processVoidTickets: PropTypes.func.isRequired,
    errorMessage: PropTypes.string
  }

  render () {
    return (
      <ComponentContainer name='cancellation-void-ticket'>
        <Panel
          header={
            <Panel.Header
              name='cancellation-void-ticket'
              title={_t.message('aftersales.void-tickets.title')}
              description={_t.message('aftersales.void-tickets.description')}
            />
          }
        >
          <TextLabel name='information'>
            <Icon name='information' className='align-left' />
            {this.props.voidingNotPossibleWithinTime
              ? <TextLabel.Text text={_t.message('aftersales.void-tickets.voiding-not-possible-within-time')} />
              : <TextLabel.Text text={_t.message('aftersales.void-tickets.information')} />
            }
          </TextLabel>
          {this.props.isRunning || this.props.errorMessage || this.props.success
            ? (
              <div className='read-status'>
                <TextLabel name='sub-heading' text={_t.message(
                  'aftersales.void-tickets.present',
                  {number: this.props.ticketNumber, total: this.props.totalTickets}
                )} />
                {this.props.isLoadingRead
                  ? <TextLabel name='tickets' className='animation-blink'>
                    <Icon name='tickets' className='align-left' />
                    <TextLabel.Text text={_t.message('aftersales.void-tickets.read')} />
                  </TextLabel> : null
                }
                {this.props.isLoadingVoid
                  ? <TextLabel name='tickets'>
                    <Icon name='tickets' className='align-left' />
                    <TextLabel.Text text={_t.message('aftersales.void-tickets.void')} />
                  </TextLabel> : null
                }
                {this.props.isLoadingEject
                  ? <TextLabel name='tickets'>
                    <Icon name='tickets' className='align-left' />
                    <TextLabel.Text text={_t.message('aftersales.void-tickets.eject')} />
                  </TextLabel> : null
                }
                {this.props.errorMessage ? <Feedback status={FEEDBACK_ALERT} text={this.props.errorMessage} /> : null}
                {this.props.success
                  ? <Feedback
                    status={FEEDBACK_SUCCESS}
                    text={_t.message('aftersales.void-tickets.success')}
                  />
                  : null}
              </div>
            ) : null}
          {!this.props.voidingNotPossibleWithinTime && !this.props.isRunning && !this.props.success
            ? (
              <Button
                name='start-void-tickets'
                className={`primary${this.props.ticketNumber > 0 ? ' tertiary' : ''}`}
                onClick={this.props.processVoidTickets}
              >
                <TextLabel
                  text={_t.message(
                    `aftersales.void-tickets.${this.props.ticketNumber > 0 ? 'retry' : 'start'}`
                  )}
                />
              </Button>
            ) : null}
        </Panel>
      </ComponentContainer>
    )
  }
}
