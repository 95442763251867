import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import ComponentContainer from '../../../elements/component-container/component-container'
import Panel from '../../../elements/panel/panel'
import Form from '../../../elements/form/form'
import PassengerFieldSet from '../../../containers/base/passenger-details-booking/passenger-field-set'
import './passenger-details.scss'

export default class PassengerDetails extends Component {
  static propTypes = {
    isGroupBooking: PropTypes.bool,
    protectPrivacy: PropTypes.bool,
    passengers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired
    })),
    form: PropTypes.shape({
      subFormNames: PropTypes.array,
      fields: PropTypes.shape({
        groupName: PropTypes.object,
        protectPrivacy: PropTypes.object
      })
    }).isRequired
  }

  render () {
    return (
      <ComponentContainer name='passenger-details'>
        <Panel
          header={
            <Panel.Header
              name='passenger-details'
              title={_t.message(`passenger-details.title${this.props.isGroupBooking ? '-group' : ''}`)}
              description={_t.message(`passenger-details.description${this.props.isGroupBooking ? '-group' : ''}`)}
            />
          }
        >
          <Form formContext='passenger-details'>
            {this.props.isGroupBooking
              ? (
                <fieldset>
                  <Form.InputGroup name='group-name'>
                    <Form.InputWrapper name='group-name'>
                      <Form.Label
                        name='group-name-description'
                        text={_t.message('passenger-details.group-name-description')}
                        htmlFor='group-name'
                      />
                      <Form.TextField
                        {...this.props.form.fields.groupName}
                        name='group-name'
                        id='group-name'
                      />
                    </Form.InputWrapper>
                  </Form.InputGroup>
                </fieldset>
              ) : this.props.form.subFormNames.map(
                (subFormName, index) => (
                  <PassengerFieldSet
                    key={subFormName}
                    passenger={this.props.passengers[index]}
                    formName={subFormName}
                  />
                )
              )}
            <fieldset>
              <Form.InputGroup name='protect-privacy'>
                <Form.Checkbox
                  {...this.props.form.fields.protectPrivacy}
                  name='protect-privacy'
                  id='protect-privacy'
                >
                  <Form.Label htmlFor='protect-privacy' name='protect-privacy' />
                </Form.Checkbox>
              </Form.InputGroup>
            </fieldset>
          </Form>
        </Panel>
      </ComponentContainer>
    )
  }
}
