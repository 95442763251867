import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Form from '../../../elements/form/form'
import Icon from '../../../elements/icon/icon'
import TextLabel from '../../../elements/text-label/text-label'
import _t from 's3p-js-lib/src/translate'
import {
  TRAVEL_DIRECTION_OUTBOUND,
  TRAVEL_DIRECTION_INBOUND,
  TRAVEL_DIRECTION_BOTH
} from 's3p-js-lib/src/constants'

export default class RebookingSelection extends Component {
  static propTypes = {
    selection: PropTypes.string,
    rebookingSelection: PropTypes.func.isRequired,
    showSelection: PropTypes.bool.isRequired,
    inboundOriginDestination: PropTypes.shape({
      originName: PropTypes.string.isRequired,
      destinationName: PropTypes.string.isRequired
    }),
    outboundOriginDestination: PropTypes.shape({
      originName: PropTypes.string.isRequired,
      destinationName: PropTypes.string.isRequired
    })
  }

  static defaultProps = {
    selection: 'both'
  }

  componentWillUnmount () {
    this.props.rebookingSelection(this.props.selection)
  }

  render () {
    if (!this.props.showSelection) {
      return null
    }

    return (
      <div className='operation-rebook'>
        <div className='operation-rebook__description'>
          <TextLabel name='rebook'>
            <Icon name='rebook' className='align-left' />
            <TextLabel.Text text={_t.message('aftersales.rebooking.selection-description')} />
          </TextLabel>
        </div>
        <div className='operation-rebook__scope'>
          <Form.InputGroup name='rebook-scope'>
            <Form.RadioGroup name='rebook-scope' value={this.props.selection} noLabel>
              <Form.RadioGroup.RadioItem
                id='rebook-scope-outbound'
                name='rebook-scope-outbound'
                groupName='rebook-scope'
                onChange={this.props.rebookingSelection}
                value={TRAVEL_DIRECTION_OUTBOUND}>
                <Form.Label
                  htmlFor='rebook-scope-outbound'
                  name='rebook-scope-outbound'
                  text={_t.message('aftersales.rebooking.outbound', this.props.outboundOriginDestination)}
                />
              </Form.RadioGroup.RadioItem>
              <Form.RadioGroup.RadioItem
                id='rebook-scope-inbound'
                name='rebook-scope-inbound'
                groupName='rebook-scope'
                onChange={this.props.rebookingSelection}
                value={TRAVEL_DIRECTION_INBOUND}
              >
                <Form.Label
                  htmlFor='rebook-scope-inbound'
                  name='rebook-scope-inbound'
                  text={_t.message('aftersales.rebooking.inbound', this.props.inboundOriginDestination)}
                />
              </Form.RadioGroup.RadioItem>
              <Form.RadioGroup.RadioItem
                id='rebook-scope-outbound-inbound'
                name='rebook-scope-outbound-inbound'
                groupName='rebook-scope'
                onChange={this.props.rebookingSelection}
                value={TRAVEL_DIRECTION_BOTH}
              >
                <Form.Label
                  htmlFor='rebook-scope-outbound-inbound'
                  name='rebook-scope-outbound-inbound'
                  text={_t.message('aftersales.rebooking.both')}
                />
              </Form.RadioGroup.RadioItem>
            </Form.RadioGroup>
          </Form.InputGroup>
        </div>
      </div>
    )
  }
}
