import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Panel from '../../../elements/panel/panel'
import ComponentContainer from '../../../elements/component-container/component-container'
import VoucherToCashForm from '../../../containers/aftersales/voucher-to-cash/form/voucher-search-form'
import VoucherToCashTable from '../../../components/aftersales/voucher-to-cash/voucher-to-cash-table'
import VoucherToCashModal from '../../../containers/aftersales/voucher-to-cash/voucher-to-cash-modal'
import Feedback, { FEEDBACK_INFORMATION } from '../../../elements/feedback/feedback'
import _t from 's3p-js-lib/src/translate'
import './voucher-to-cash.scss'

export default class VoucherToCash extends Component {
  static propTypes = {
    vouchers: PropTypes.array.isRequired,
    showModal: PropTypes.bool.isRequired,
    hasResults: PropTypes.bool.isRequired
  }

  render () {
    return (
      <ComponentContainer name='voucher-to-cash'>
        <Panel
          header={
            <Panel.Header
              name='voucher-to-cash'
              title={_t.message('voucher-to-cash.title')}
              description={_t.message('voucher-to-cash.description')} />
          }
        >
          <VoucherToCashForm />
          {this.props.hasResults
            ? this.props.vouchers.length
              ? <VoucherToCashTable vouchers={this.props.vouchers} />
              : <Feedback
                className='no-vouchers'
                status={FEEDBACK_INFORMATION}
                text={_t.message('voucher-to-cash.no-vouchers')} />
            : null
          }
          {this.props.showModal ? <VoucherToCashModal /> : null}
        </Panel>
      </ComponentContainer>

    )
  }
}
