import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Button from '../../../elements/button/button'
import TextLabel from '../../../elements/text-label/text-label'
import Form from '../../../elements/form/form'
import LastApiErrorFeedback from '../../../containers/base/last-api-error-feedback'
import ComponentContainer from '../../../elements/component-container/component-container'
import Panel from '../../../elements/panel/panel'
import Feedback, { FEEDBACK_WARNING } from '../../../elements/feedback/feedback'
import './login.scss'

export default class AgentLogin extends Component {
  static propTypes = {
    form: PropTypes.shape({
      fields: PropTypes.object.isRequired
    }).isRequired,
    handleLogin: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    invalidAgentRoles: PropTypes.bool,
    incorrectAgentConfiguration: PropTypes.bool
  }

  render () {
    return (
      <ComponentContainer name='agent-login'>
        <Panel>
          <LastApiErrorFeedback
            namespaces={['agentLogin', 'refreshToken', 'unhandled']}
            defaultMessage='agent.login.error'
          />
          {this.props.invalidAgentRoles
            ? <Feedback status={FEEDBACK_WARNING} text={_t.message('agent.login.invalid-roles')} />
            : null
          }
          {this.props.incorrectAgentConfiguration
            ? <Feedback status={FEEDBACK_WARNING} text={_t.message('agent.login.configured-incorrect')} />
            : null
          }
          <Form formContext='agent.login'>
            <fieldset>
              <Form.InputGroup name='username'>
                <Form.InputWrapper name='label'>
                  <Form.Label
                    htmlFor='agent-login-username'
                    name='username'
                  />
                </Form.InputWrapper>
                <Form.InputWrapper name='input'>
                  <Form.TextField
                    {...this.props.form.fields.username}
                    name='username'
                    id='agent-login-username'
                    autoComplete='off'
                    autoFocus
                  />
                  <Form.InputFeedback {...this.props.form.fields.username} />
                </Form.InputWrapper>
              </Form.InputGroup>
              <Form.InputGroup name='password'>
                <Form.InputWrapper name='label'>
                  <Form.Label
                    htmlFor='agent-login-password'
                    name='password'
                  />
                </Form.InputWrapper>
                <Form.InputWrapper name='input'>
                  <Form.PasswordField
                    {...this.props.form.fields.password}
                    name='password'
                    id='agent-login-password'
                    autoComplete='off'
                  />
                  <Form.InputFeedback {...this.props.form.fields.password} />
                </Form.InputWrapper>
              </Form.InputGroup>
              <Form.InputGroup name='submit'>
                <Form.InputWrapper name='login'>
                  <Button
                    loading={this.props.isLoading}
                    type='submit'
                    className='primary'
                    name='login'
                    onClick={this.props.handleLogin}
                  >
                    <TextLabel text={_t.message('agent.login.form.button')} />
                  </Button>
                </Form.InputWrapper>
              </Form.InputGroup>
            </fieldset>
          </Form>
        </Panel>
      </ComponentContainer>
    )
  }
}
