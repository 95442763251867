import {
  formFieldValueSelectorCreator,
  subFormFieldValuesSelectorCreator
} from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import { loadValues } from 's3p-js-lib/src/redux/actions/user-input/base/form'
import {
  FORM_PRODUCT_SEARCH_PASSENGERS_DETAILS,
  FORM_JOURNEY_SEARCH_PASSENGERS_DETAILS,
  FORM_REBOOK_SEARCH_PASSENGERS_DETAILS,
  DISABILITY_TYPE_WHEELCHAIR,
  DISABILITY_TYPE_NONE,
  DISCOUNT_CARD_SEAT_ONLY
} from '../../../../../constants'
import {
  FORM_PRODUCT_SEARCH,
  FORM_JOURNEY_SEARCH,
  FORM_TYPE_REBOOK_JOURNEY_SEARCH
} from 's3p-js-lib/src/constants-form'
import { handleSearchProducts } from '../../tickets/products'

export const loadPassengerDetailsTicketsFlow = () => (dispatch, getState) => {
  const passengersSelector = formFieldValueSelectorCreator(FORM_PRODUCT_SEARCH, 'passengers')
  const passengers = passengersSelector(getState())
  const discountCardCode = getState().containers.tickets.shortcut.usedShortcut.discountCardCode

  passengers.forEach(
    (passenger, index) => {
      const values = {
        wheelchair: passenger.disabilityType === DISABILITY_TYPE_WHEELCHAIR,
        passengerType: passenger.type
      }
      if (discountCardCode && !passenger.discountCards) {
        values['discountCard'] = discountCardCode
      } else if (passenger.discountCards) {
        (passenger.discountCards).forEach(discountCard => {
          values['discountCard'] = discountCard.code
          values['discountCardNumber'] = discountCard.identifier
        })
      }
      dispatch(loadValues(`${FORM_PRODUCT_SEARCH_PASSENGERS_DETAILS}.passenger_${index + 1}`, values))
    }
  )
}

export const loadPassengerDetailsReservationFlow = () => loadPassengerDetails(FORM_JOURNEY_SEARCH, FORM_JOURNEY_SEARCH_PASSENGERS_DETAILS)
export const loadPassengerDetailsRebookFlow = () => loadPassengerDetails(FORM_TYPE_REBOOK_JOURNEY_SEARCH, FORM_REBOOK_SEARCH_PASSENGERS_DETAILS)

export const loadPassengerDetails = (formSearch, formPassengerDetails) => (dispatch, getState) => {
  const passengersSelector = formFieldValueSelectorCreator(formSearch, 'passengers')
  const passengers = passengersSelector(getState())

  passengers.forEach(
    (passenger, index) => {
      const values = {
        wheelchair: passenger.disabilityType === DISABILITY_TYPE_WHEELCHAIR,
        passengerType: passenger.type
      }
      if (passenger.discountCards) {
        (passenger.discountCards.filter(cards => cards.code !== DISCOUNT_CARD_SEAT_ONLY)).forEach(discountCard => {
          if (discountCard) {
            values['discountCard'] = discountCard.code
            values['discountCardNumber'] = discountCard.identifier
          }
        })
        values['seatOnly'] = passenger.discountCards.some(card => card.code === DISCOUNT_CARD_SEAT_ONLY)
      }
      dispatch(loadValues(`${formPassengerDetails}.passenger_${index + 1}`, values))
    }
  )
}

export const savePassengerDetailsTicketsFlow = () => (dispatch, getState) => {
  const subFormFieldValuesSelector = subFormFieldValuesSelectorCreator(FORM_PRODUCT_SEARCH_PASSENGERS_DETAILS)
  const subFormValues = subFormFieldValuesSelector(getState())
  const isDiscountCardShortcut = (
    Object.keys(getState().containers.tickets.shortcut.usedShortcut).length &&
    getState().containers.tickets.shortcut.usedShortcut.discountCardCode
  )

  const passengers = Object.keys(subFormValues).map(passengerId => {
    const values = subFormValues[passengerId]
    const discountCards = []
    if (values.discountCard && values.discountCard.code.length > 0) {
      discountCards.push({
        code: values.discountCard.code,
        identifier: values.discountCardNumber
      })
    }
    return {
      type: values.passengerType,
      disabilityType: values.wheelchair ? DISABILITY_TYPE_WHEELCHAIR : DISABILITY_TYPE_NONE,
      discountCards
    }
  })
  isDiscountCardShortcut
    ? dispatch([loadValues(FORM_PRODUCT_SEARCH, {passengers}), handleSearchProducts()])
    : dispatch(loadValues(FORM_PRODUCT_SEARCH, {passengers}))
}

export const savePassengerDetailsReservationFlow = () => (dispatch, getState) => {
  const subFormFieldValuesSelector = subFormFieldValuesSelectorCreator(FORM_JOURNEY_SEARCH_PASSENGERS_DETAILS)
  const subFormValues = subFormFieldValuesSelector(getState())

  const passengers = Object.keys(subFormValues).map(passengerId => {
    const values = subFormValues[passengerId]
    const discountCards = []
    if (values.discountCard && values.discountCard.code.length > 0) {
      discountCards.push({
        code: values.discountCard.code,
        identifier: values.discountCardNumber
      })
    }
    if (values.seatOnly) {
      discountCards.push({
        code: DISCOUNT_CARD_SEAT_ONLY
      })
    }
    return {
      type: values.passengerType,
      disabilityType: values.wheelchair ? DISABILITY_TYPE_WHEELCHAIR : DISABILITY_TYPE_NONE,
      discountCards
    }
  })

  dispatch(loadValues(FORM_JOURNEY_SEARCH, {passengers}))
}
