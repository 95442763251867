import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Modal from '../../../../../elements/modal/modal'
import Icon from '../../../../../elements/icon/icon'
import TextLabel from '../../../../../elements/text-label/text-label'
import Button from '../../../../../elements/button/button'
import { REFUND_VOUCHER_MODAL } from '../../../../../constants'
import Feedback, { FEEDBACK_WARNING } from '../../../../../elements/feedback/feedback'
import { MESSAGE_SEVERITY_WARNING, MESSAGE_SEVERITY_ERROR } from 's3p-js-lib/src/constants'
import FeedbackMachine from '../../../../../containers/base/last-machine-error-feedback'

const RETRY_BUTTON_ID = 'retry'
const FINISH_BUTTON_ID = 'finish'

export default class PrintRefundVoucherModal extends Component {
  static propTypes = {
    onHide: PropTypes.func.isRequired,
    onRetry: PropTypes.func.isRequired,
    disableFinish: PropTypes.bool,
    disableCancel: PropTypes.bool,
    disableRetry: PropTypes.bool,
    messages: PropTypes.array,
    ticketErrorMessage: PropTypes.string,
    isOfflineReceiptPrinter: PropTypes.bool,
    isPrinting: PropTypes.bool,
    hasPrintErrors: PropTypes.bool
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!nextProps.disableRetry && nextProps.hasPrintErrors) {
      const element = document.getElementById(RETRY_BUTTON_ID)
      element && setTimeout(() => element.focus(), 200)
    } else {
      const element = document.getElementById(FINISH_BUTTON_ID)
      element && setTimeout(() => element.focus(), 200)
    }
  }

  renderFooter () {
    return (
      <Modal.CustomFooter>
        <Button
          name='cancel'
          className='tertiary'
          onClick={this.props.onHide}
          disabled={this.props.disableCancel || this.props.isPrinting || (!this.props.hasPrintErrors && !this.props.ticketErrorMessage)}
        >
          <TextLabel name='cancel' text={_t.message('aftersales.booking.related-vouchers.refund-voucher-modal.cancel')} />
        </Button>
        <Button
          id={RETRY_BUTTON_ID}
          name={RETRY_BUTTON_ID}
          className='secondary'
          disabled={this.props.disableRetry || this.props.isPrinting || this.props.isOfflineReceiptPrinter}
          onClick={this.props.onRetry}
        >
          <TextLabel name='retry' text={_t.message('aftersales.booking.related-vouchers.refund-voucher-modal.retry')} />
        </Button>
        <Button
          id={FINISH_BUTTON_ID}
          name='finish'
          className='primary'
          onClick={this.props.onHide}
          disabled={this.props.disableFinish || this.props.isPrinting || this.props.hasPrintErrors || Boolean(this.props.ticketErrorMessage)}
          hotkey='proceed'
        >
          <TextLabel name='finish' text={_t.message('aftersales.booking.related-vouchers.refund-voucher-modal.finish')}
          />
        </Button>
      </Modal.CustomFooter>
    )
  }

  render () {
    return (
      <Modal
        name={REFUND_VOUCHER_MODAL}
        header={<Modal.Title title={_t.message('aftersales.booking.related-vouchers.refund-voucher-modal.title')} />}
        footer={this.renderFooter()}
        isCloseable
      >
        {this.props.isOfflineReceiptPrinter
          ? <Feedback status={FEEDBACK_WARNING} text={_t.message('finalize-booking-modal.receipt-printer.offline')} />
          : null
        }

        {!this.props.isOfflineReceiptPrinter && this.props.isPrinting
          ? (
            <div className='read-status'>
              <TextLabel name='status-indicator' className='animation-blink'>
                <Icon name='print-ticket' className='status-indicator align-left' />
                <TextLabel.Text
                  text={_t.message('aftersales.booking.related-vouchers.refund-voucher-modal.printing')} />
              </TextLabel>
            </div>
          ) : null}

        {!this.props.isOfflineReceiptPrinter
          ? (this.props.messages || []).map(message => (
            <Feedback
              key={message.text.join('-')}
              status={message.status}
              text={message.text.join(', ')}
            />
          )) : null}

        <FeedbackMachine
          namespaces={['receiptPrinter']}
          severities={[MESSAGE_SEVERITY_WARNING, MESSAGE_SEVERITY_ERROR]}
        />
        {this.props.children}
      </Modal>
    )
  }
}
