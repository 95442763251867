import { createSelector } from 'reselect'
import {
  tariffSegmentsSelector as baseTariffSegmentsSelector,
  overviewBookingSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import {
  tariffSegmentsSelector as enrichedTariffSegmentsSelector
} from '../../../api/booking/segments'
import sumBy from 'lodash/sumBy'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import {
  AFTERSALES_REBOOK,
  AFTERSALES_CANCELLATION,
  AFTERSALES_SEATCHANGE,
  AFTERSALES_NAMECHANGE
} from 's3p-js-lib/src/constants'

const tariffSegmentsSelector = enrichedTariffSegmentsSelector(
  overviewBookingSelector,
  baseTariffSegmentsSelector(overviewBookingSelector)
)

const mapPassenger = (passenger, id, products, cancelled) => {
  const discounts = products.reduce((discounts, product) => discounts.concat(product.discounts || []), [])
  const groupedDiscounts = Object.values(groupBy(discounts, 'name')).map(discounts => ({
    ...discounts[0],
    amount: sumBy(discounts, 'amount')
  }))
  const totalPrice = sumBy(products, 'price')
  const totalDiscountPrice = sumBy(groupedDiscounts, 'amount')

  return {
    ...passenger,
    id,
    cancelled,
    products,
    additionalProductsString: products.filter(product => !product.required).map(product => product.name).join(', '),
    fullName: `${passenger.title || ''} ${passenger.firstName} ${passenger.lastName}`.trim(),
    currency: products[0] && products[0].currency,
    seat: cancelled ? undefined : passenger.seat,
    discounts: groupedDiscounts,
    totalPrice,
    totalDiscountPrice
  }
}

const mapProduct = required => product => ({
  ...product,
  required,
  description: product.name || product.code || (product.productFamily
    ? product.productFamily.name || product.productFamily.code || '' : '')
})

export const tariffSegmentSelector = createSelector(
  [
    tariffSegmentsSelector,
    (state, ownProps) => ownProps.id
  ],
  (tariffSegments, segmentId) => tariffSegments && segmentId && tariffSegments.find(
    segment => segment.id === segmentId
  )
)

const sortKeys = {
  passenger: passenger => `${passenger.firstName}_${passenger.lastName}`,
  seat: passenger => passenger.seat ? `${passenger.seat.carriage}_${passenger.seat.number}` : '',
  products: passenger => passenger.additionalProductsString,
  price: passenger => passenger.totalPrice
}

export const passengersSelector = createSelector(
  [
    tariffSegmentSelector,
    (state, ownProps) => ownProps.showCancelledItems
  ],
  (tariffSegment, showCancelledItems) => {
    let passengers = []
    if (tariffSegment && tariffSegment.passengers) {
      passengers = tariffSegment.passengers.reduce(
        (enrichedPassengers, passenger) => {
          const products = tariffSegment.requiredProducts.map(mapProduct(true)).concat(
            tariffSegment.additionalProducts.map(mapProduct(false))
          ).filter(product => product.passenger.id === passenger.id)

          const cancelledProducts = products.filter(product => product.cancelled)
          const nonCanceledProducts = products.filter(product => !product.cancelled)

          if (nonCanceledProducts.length || !cancelledProducts.length) {
            enrichedPassengers.push(mapPassenger(
              passenger,
              [tariffSegment.id, passenger.id, 'not-cancelled'].join('|'),
              nonCanceledProducts,
              false
            ))
          }

          if (showCancelledItems && cancelledProducts.length) {
            enrichedPassengers.push(mapPassenger(
              passenger,
              [tariffSegment.id, passenger.id, 'cancelled'].join('|'),
              cancelledProducts,
              true
            ))
          }

          return enrichedPassengers
        },
        []
      )
    }

    return sortBy(passengers, sortKeys)
  }
)

const afterSalesOperationTypes = [
  AFTERSALES_REBOOK,
  AFTERSALES_CANCELLATION,
  AFTERSALES_SEATCHANGE,
  AFTERSALES_NAMECHANGE
]

const selectAfterSalesRules = afterSalesRules => afterSalesOperationTypes.map(operationType => {
  const afterSalesRule = afterSalesRules.find(afterSalesRule => afterSalesRule.type === operationType)

  return {
    ...afterSalesRule,
    type: operationType,
    active: afterSalesRule ? afterSalesRule.active : false,
    price: (afterSalesRule && afterSalesRule.price) || 0
  }
})

const generateAftersalesRuleSignature = rule => [
  rule.active ? '+' : '',
  rule.type,
  rule.validUntil.time(),
  rule.vat,
  rule.price,
  rule.feeCalculationType,
  rule.downsellOnly ? '*' : ''
].join('.')

const generateAftersalesRulesSignature = aftersalesRules =>
  sortBy(aftersalesRules, rule => rule.type).reduce(
    (sig, rule) => sig + generateAftersalesRuleSignature(rule) + '|',
    '|'
  )

const getUniqueId = product => [
  product.code,
  product.cancelled,
  generateAftersalesRulesSignature(product.aftersalesRules)
].join('|')

const groupedProducts = (products, showCancelledItems, required) => {
  let grouped = Object.values(groupBy(products, getUniqueId))

  if (!showCancelledItems) {
    grouped = grouped.filter(productList => !productList[0].cancelled)
  }

  let result = grouped.map(productList => ({
    id: getUniqueId(productList[0]),
    code: productList[0].code,
    description: required && productList[0].productFamily
      ? productList[0].productFamily.name || productList[0].productFamily.code
      : productList[0].name || productList[0].code,
    type: productList[0].type,
    currency: productList[0].currency,
    cancelled: productList[0].cancelled,
    aftersalesRules: !productList[0].cancelled ? selectAfterSalesRules(productList[0].afterSalesRules) : [],
    count: productList.length
  }))

  if (showCancelledItems) {
    result = result.filter(
      product => !(product.cancelled && result.some(pr => pr.code === product.code && !pr.cancelled))
    )
  }

  return result
}

export const productsSelector = createSelector(
  [
    tariffSegmentSelector,
    (state, ownProps) => ownProps.showCancelledItems
  ],
  (tariffSegment, showCancelledItems) => {
    if (!tariffSegment) {
      return []
    }

    return groupedProducts(tariffSegment.requiredProducts, showCancelledItems, true).concat(
      groupedProducts(tariffSegment.additionalProducts, showCancelledItems, false)
    )
  }
)

export const feeSummarySelector = createSelector(
  [tariffSegmentSelector],
  tariffSegment => {
    if (!tariffSegment || !tariffSegment.fees || !tariffSegment.fees.length) {
      return null
    }

    return {
      fees: tariffSegment.fees,
      currency: tariffSegment.fees[0].currency,
      totalPrice: sumBy(tariffSegment.fees, 'price')
    }
  }
)
