import {
  API_OAUTH_LOGOUT,
  API_OAUTH_REFRESH_TOKEN_SUCCESS,
  API_OAUTH_REFRESH_TOKEN_FAILURE,
  API_AGENT_LOGIN_TOKEN,
  API_BOOKING_LOGIN_TOKEN,
  API_PUBLIC_ACCESS_TOKEN,
  CLEAR_STATE
} from '../../../actions/types'
import storage from '../../../../storage'
import moment from 'moment'

export const initialState = null

export const STATE_API_AUTH_OAUTH_TOKEN = 'OAUTH_TOKEN'

const defaultState = storage.get(STATE_API_AUTH_OAUTH_TOKEN) || initialState

export default function oauthToken (state = defaultState, action) {
  switch (action.type) {
    case API_OAUTH_REFRESH_TOKEN_SUCCESS:
    case API_AGENT_LOGIN_TOKEN:
    case API_BOOKING_LOGIN_TOKEN:
    case API_PUBLIC_ACCESS_TOKEN: {
      const refreshAfter = moment().add(action.response.expires_in, 'seconds').subtract(300, 'seconds')
      const tokenResponse = {
        ...action.response,
        refresh_after: refreshAfter.toISOString()
      }
      storage.set(STATE_API_AUTH_OAUTH_TOKEN, tokenResponse)
      return tokenResponse
    }
    case API_OAUTH_REFRESH_TOKEN_FAILURE:
    case API_OAUTH_LOGOUT:
      storage.clear(STATE_API_AUTH_OAUTH_TOKEN)
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes('api.auth.oauthToken') ? initialState : state
    default:
      return state
  }
}
