import Validator from './validator'
import {createSelector} from 'reselect'
import {salesChannelPropertySelector} from '../redux/selectors/api/user/sales-channel-properties'
import {isGroupBookingSelector, bookingSelector} from '../redux/selectors/api/booking/booking'
import {isAgentSelector, isCrmUserSelector} from '../redux/selectors/api/auth/auth'

const nonAgentFields = {
  postalCode: {
    validators: [
      Validator.isPostalCode
    ]
  },
  street: {
    validators: [
      Validator.isStreet
    ]
  },
  houseNumber: {
    validators: [
      Validator.isHouseNumber
    ]
  },
  city: {
    validators: [
      Validator.isCity
    ]
  },
  country: {
    validators: [
      Validator.isCountryCode
    ]
  },
  phoneNumber: {
    validators: [
      Validator.isRequired,
      Validator.isPhoneNumber
    ]
  }
}
const allUsersFields = {
  title: {},
  firstName: {
    validators: [
      Validator.isFirstName,
      Validator.isRequired
    ]
  },
  lastName: {
    validators: [
      Validator.isLastName,
      Validator.isRequired
    ]
  },
  emailAddress: {
    validators: [
      Validator.isRequired,
      Validator.isEmail
    ]
  },
  emailAddressRepeated: {
    validators: [
      Validator.isRepeated('emailAddress')
    ]
  },
  isPassenger: {}
}
const createAccount = {}

const agentFormType = {...allUsersFields}
const crmUserFormType = {...allUsersFields, ...nonAgentFields}

export default createSelector(
  [
    salesChannelPropertySelector('request_customer'),
    isAgentSelector,
    isCrmUserSelector,
    isGroupBookingSelector(bookingSelector)
  ],
  (requestCustomer, isAgent, isCrm, isGroupBooking) => {
    let fields = crmUserFormType
    if (isAgent && !requestCustomer) {
      fields = agentFormType
    } else if (!isCrm && !isAgent) {
      fields = {...fields, createAccount}
    }

    if (isGroupBooking) {
      delete fields.isPassenger
    }

    return {fields}
  }
)
