import {bundleByIdSelector, travelByIdSelector, baseOfferSelector, routeByIdSelector} from 's3p-js-lib/src/redux/selectors/api/orientation/offer'
import {baseSelectedBundlesSelector} from 's3p-js-lib/src/redux/selectors/api/orientation/selected-bundles'
import {SEAT_SELECTION_AVAILABLE} from 's3p-js-lib/src/constants'

const mapBundleLegsToJourneySegments = (bundleWithIdentifiers, state) => bundleWithIdentifiers?.identifiers?.route_id
  ? (routeByIdSelector(bundleWithIdentifiers?.identifiers?.route_id)(state)?.legs || []).map(
      leg => ({
        leg_id: leg.id,
        departure_station: leg.departure_station,
        arrival_station: leg.arrival_station,
        service_identifier: leg.service_identifier,
        service_name: leg.service_name,
        service_type: leg.service_type,
        departure_date_time: leg.departure_station.departure_timestamp,
        arrival_date_time: leg.arrival_station.arrival_timestamp,
        departure_platform: '',
        arrival_platform: ''
      }))
  : []

export const mapTravelBundleItemPassengerFareToProduct = (travelId, bundle, bundleItem, passengerFare) => ({
  bundle_id: bundle.id,
  discounts: bundle.discounts,
  item_id: bundleItem.id,
  legs: bundleItem.leg_ids,
  original_price: passengerFare.original_price,
  passenger_id: passengerFare.passenger_id,
  price: passengerFare.price,
  product_code: bundleItem.product_code,
  product_family_id: bundle.productFamilyId,
  product_id: `product-${bundleItem.product_code}`,
  product_name: bundleItem.name,
  quantity: 1,
  tariff_code: passengerFare.tariff_code,
  tariff_id: `tariff-${passengerFare.tariff_code}`,
  tariff_name: 'NOT_MIGRATED',
  travel_id: travelId
})

const mapBundleItemsToProducts = (bundlesWithIdentifiers, requiredProductsOnly = true) =>
  Object.values(bundlesWithIdentifiers).reduce((products, bundleWithIdentifiers) => {
    return [
      ...products,
      ...bundleWithIdentifiers.bundle.items.reduce((_products, item) => {
        if (item.required === requiredProductsOnly) {
          return [
            ..._products,
            ...item.passenger_fares.map(
              fare => mapTravelBundleItemPassengerFareToProduct(
                bundleWithIdentifiers.identifiers.travel_id,
                bundleWithIdentifiers.bundle,
                item,
                fare
              )
            )
          ]
        }
        return _products
      }, [])
    ]
  }, [])

const mapBundleItemsToRequiredProducts = bundlesWithIdentifiers => mapBundleItemsToProducts(bundlesWithIdentifiers, true)

export const createV1SelectBundleResponseSelector = (journeyId, travelId, routeId, bundleId) => state => {
  const bundle = bundleByIdSelector(bundleId)(state)
  if (!bundle) {
    // Bundle id cant be found in the journey search.
    return false
  }
  const travel = travelByIdSelector(travelId)(state)
  const oldSelectedBundleIdentifiersForOppositeTravel = baseSelectedBundlesSelector(state).find(({direction}) => direction !== travel.direction)
  const oldBundle = oldSelectedBundleIdentifiersForOppositeTravel && bundleByIdSelector(oldSelectedBundleIdentifiersForOppositeTravel.id)(state)
  const _bundlesWithIdentifiersByDirection = {
    ...(oldBundle ? {
      [travel.direction === 'outward' ? 'return' : 'outward']: {
        bundle: oldBundle,
        identifiers: oldSelectedBundleIdentifiersForOppositeTravel
      }
    } : {}),
    [travel.direction]: {
      bundle,
      identifiers: {
        direction: travel.direction,
        journey_id: journeyId,
        travel_id: travelId,
        route_id: routeId,
        id: bundleId
      }
    }
  }
  const outboundBundleWithIdentifiers = _bundlesWithIdentifiersByDirection['outward']
  const inboundBundleWithIdentifiers = _bundlesWithIdentifiersByDirection['return']

  // Map the bundle selection to a response similar to api/v1/select-bundles
  const bundleSelection = {
    messages: [],
    data: {
      selected_bundles: [
        ...baseSelectedBundlesSelector(state).filter(({direction}) => direction !== travel.direction), // Keep previous bundle from opposite direction
        _bundlesWithIdentifiersByDirection[travel.direction].identifiers
      ],
      orientation_booking_specification: {
        additional_products: [], // Added during 'Add additional products', initially empty
        additional_products_available: Object.values(_bundlesWithIdentifiersByDirection).some(
          _bundleWithIdentifiers => _bundleWithIdentifiers.bundle.additionalProductsSummaries.length
        ),
        inbound_booking_journey_segments: inboundBundleWithIdentifiers ? mapBundleLegsToJourneySegments(inboundBundleWithIdentifiers, state) : [],
        outbound_booking_journey_segments: outboundBundleWithIdentifiers ? mapBundleLegsToJourneySegments(outboundBundleWithIdentifiers, state) : [],
        outbound_product_family_id: outboundBundleWithIdentifiers?.bundle?.productFamilyId,
        passengers: baseOfferSelector(state).passengers,
        product_families: baseOfferSelector(state).product_families,
        required_products: mapBundleItemsToRequiredProducts(_bundlesWithIdentifiersByDirection),
        seat_preferences: [], // TODO
        seat_selection_available: Object.values(_bundlesWithIdentifiersByDirection).flatMap(
          _bundleWithIdentifiers => _bundleWithIdentifiers.bundle.items
        ).some(item => item.seat_selection_status === SEAT_SELECTION_AVAILABLE), // Assume if some of our items in the bundle have this status, we can select a seat?
        selected_seats: [], // Added during 'Select seats', initially empty
        total_price: (outboundBundleWithIdentifiers?.bundle?.price || 0) + (inboundBundleWithIdentifiers?.bundle?.price || 0)
      }
    }
  }

  return bundleSelection
}
