import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../../../elements/text-label/text-label'
import Panel from '../../../../elements/panel/panel'
import ButtonGroup from '../../../../elements/button-group/button-group'
import Button from '../../../../elements/button/button'
import Icon from '../../../../elements/icon/icon'
import _t from 's3p-js-lib/src/translate'

export default class PaymentDetailsFooter extends Component {
  static propTypes = {
    showRefundPayment: PropTypes.bool,
    onRefundPayment: PropTypes.func.isRequired,
    onRefundVoucher: PropTypes.func.isRequired
  }

  render () {
    return (
      <Panel.Footer>
        <ButtonGroup>
          {this.props.showRefundPayment ? (
            <Button onClick={this.props.onRefundPayment} name='refund-payment' className='tertiary'>
              <Icon name='refund' className='align-left' />
              <TextLabel.Text text={_t.message('aftersales.booking.payment-details.footer.refund-payment')} />
            </Button>
          ) : null}
          <Button onClick={this.props.onRefundVoucher} name='refund-by-voucher' className='primary'>
            <Icon name='redeem-voucher' className='align-left' />
            <TextLabel.Text text={_t.message('aftersales.booking.payment-details.footer.refund-by-voucher')} />
          </Button>
        </ButtonGroup>
      </Panel.Footer>
    )
  }
}
