import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../elements/text-label/text-label'
import TileSet from '../../../elements/tile-set/tile-set'
import countBy from 'lodash/countBy'
import { PASSENGER_TYPE_ADULT, PASSENGER_TYPE_CHILD, PASSENGER_TYPE_STUDENT } from 's3p-js-lib/src/constants'

export default class ProductSearchResultTile extends Component {
  static propTypes = {
    object: PropTypes.shape({
      price: PropTypes.number.isRequired,
      selected: PropTypes.bool,
      loading: PropTypes.bool,
      passengerFares: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string
      }))
    }),
    hotkey: PropTypes.string,
    handleSelect: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool
  }

  constructor (props, ...args) {
    super(props, ...args)
    this.handleClick = props.handleSelect.bind(null, props.object)
  }

  render () {
    const {object, autoFocus, hotkey, disabled} = this.props
    const count = countBy(object.passengerFares, 'type')
    const passengerCount = {
      adults: count[PASSENGER_TYPE_ADULT] || 0,
      students: count[PASSENGER_TYPE_STUDENT] || 0,
      children: count[PASSENGER_TYPE_CHILD] || 0
    }
    return (
      <TileSet.Tile
        autoFocus={autoFocus}
        onClick={this.handleClick}
        selected={object.selected}
        disabled={disabled}
        hotkey={hotkey}
      >
        <div className='tickets-product-family'>
          <TextLabel name='product-family' text={object.name} />
        </div>
        <div className='tickets-product-price-passenger'>
          <TextLabel
            name='number-of-passenger-type'
            text={_t.message('products-result.passenger-count', passengerCount)}
          />
          <TextLabel name='product-price' text={_t.formatCurrency(object.price)} />
        </div>
      </TileSet.Tile>
    )
  }
}
