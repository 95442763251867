import { createSelector } from 'reselect'
import { camelCaseKeys } from 's3p-js-lib/src/misc/utils'
import {
  PED_RESULT_CODE_APPROVED_ONLINE,
  PED_RESULT_CODE_APPROVED_OFFLINE,
  PED_RESULT_CODE_APPROVED_MANUAL
} from '../../../constants'
import padStart from 'lodash/padStart'
import toUpper from 'lodash/toUpper'

const manipulatePaymentData = ({maskedPan, issueNumber, emvApplicationLabel, ...pinPayment}) => {
  if (maskedPan) {
    pinPayment.maskedPan = `XXXX XXXX XXXX ${maskedPan.substr(maskedPan.length - 4)}`
  }
  if (issueNumber) {
    pinPayment.issueNumber = padStart(issueNumber, 2, '0')
  }
  if (emvApplicationLabel) {
    pinPayment.emvApplicationLabel = toUpper(emvApplicationLabel)
  }

  return pinPayment
}

export const paymentResultSelector = createSelector(
  [state => state.machine.pinPayment],
  pinPayment => pinPayment ? ({
    ...manipulatePaymentData(camelCaseKeys(pinPayment)),
    result: parseInt(pinPayment.result)
  }) : {}
)

export const refundResultSelector = createSelector(
  [paymentResultSelector],
  pinPayment => {
    const successCodes = [
      PED_RESULT_CODE_APPROVED_ONLINE,
      PED_RESULT_CODE_APPROVED_OFFLINE,
      PED_RESULT_CODE_APPROVED_MANUAL
    ]
    return {
      ...pinPayment,
      refundCompleted: successCodes.includes(pinPayment.result)
    }
  }
)
