import {
  API_ADD_BOOKING_NOTE_REQUEST,
  API_ADD_BOOKING_NOTE_SUCCESS,
  API_ADD_BOOKING_NOTE_FAILURE
} from '../../types'
import Client from '../../../../api/client'
import ensureToken from '../../../../api/ensure-token'
import ResponseCodes from '../../../../api/response-codes'

const addBookingNoteRequest = note => ({type: API_ADD_BOOKING_NOTE_REQUEST, note})
const addBookingNoteSuccess = response => ({type: API_ADD_BOOKING_NOTE_SUCCESS, response})
const addBookingNoteFailure = error => ({type: API_ADD_BOOKING_NOTE_FAILURE, error})

export const addBookingNote = (bookingNumber, note) => ensureToken(async function (token, dispatch) {
  try {
    dispatch(addBookingNoteRequest(note))
    const response = await Client.addBookingNote(token, bookingNumber, note)
    dispatch(addBookingNoteSuccess(response))
  } catch (error) {
    dispatch(addBookingNoteFailure(error))
    if (ResponseCodes.isSystemError(error)) {
      throw error
    }
    return false
  }
})
