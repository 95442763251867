import {
  API_OAUTH_LOGOUT,
  API_OAUTH_REFRESH_TOKEN_FAILURE,
  API_LOAD_AGENT_INFORMATION_SUCCESS,
  API_LOAD_AGENT_INFORMATION_FAILURE,
  API_AGENT_CHANGE_PASSWORD_SUCCESS,
  API_AGENT_UPDATE_SUCCESS,
  CLEAR_STATE
} from '../../../../actions/types'
import storage from '../../../../../storage'

export const initialState = null

const defaultState = storage.get('agent_information') || initialState

export default function agentInformation (state = defaultState, action) {
  switch (action.type) {
    case API_AGENT_UPDATE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newAgent = {
        ...state,
        ...action.response
      }
      storage.set('agent_information', newAgent)
      return newAgent
    case API_LOAD_AGENT_INFORMATION_SUCCESS:
      storage.set('agent_information', action.response)
      return action.response

    case API_AGENT_CHANGE_PASSWORD_SUCCESS: {
      const agentInformation = {
        ...storage.get('agent_information'),
        password_expiry_date_time: null
      }
      storage.set('agent_information', agentInformation)
      return agentInformation
    }
    case API_OAUTH_REFRESH_TOKEN_FAILURE:
    case API_LOAD_AGENT_INFORMATION_FAILURE:
    case API_OAUTH_LOGOUT:
      storage.clear('agent_information')
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes('api.user.agent.user') ? initialState : state
    default:
      return state
  }
}
