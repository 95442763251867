import React, { Component } from 'react'
import GenericLayout from '../../layouts/generic-layout'
import VoucherToCash from '../../containers/aftersales/voucher-to-cash/voucher-to-cash'
import MainMenu from '../../containers/base/main-menu/main-menu'
import TaskBar from '../../containers/base/taskbar/taskbar'
import GridLayout from '../../elements/grid/grid-layout'
import GridColumn from '../../elements/grid/grid-column'
import StickyFooter from '../../elements/sticky-footer/sticky-footer'

export default class VoucherToCashLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <MainMenu />
            <VoucherToCash />
            <StickyFooter>
              <TaskBar />
            </StickyFooter>
          </GridColumn>
        </GridLayout>
      </GenericLayout>
    )
  }
}
