import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import countBy from 'lodash/countBy'
import connectFormContainer from '../form'
import { currentQuerySelector } from '../../../redux/selectors/containers/base/routing'
import { maxPersonsSelector } from '../../../redux/selectors/containers/base/max-passengers'
import {
  modalIsActiveSelector,
  elementVisibleSelector
} from '../../../redux/selectors/containers/base/visible-element'
import { maxPerPassengerTypeSelector } from '../../../redux/selectors/containers/tickets/product-search'
import { machineStationSelector } from '../../../redux/selectors/containers/base/stations'
import { stationsSelector } from 's3p-js-lib/src/redux/selectors/api/base/stations'
import { MAX_PASSENGER_MODAL } from '../../../components/base/max-passengers-modal'
import JourneySearchForm from '../../../components/base/journey-search/journey-search-form'
import {
  PASSENGER_TYPE_ADULT,
  PASSENGER_TYPE_STUDENT,
  PASSENGER_TYPE_CHILD,
  JOURNEY_SINGLE,
  JOURNEY_RETURN
} from 's3p-js-lib/src/constants'

class JourneySearchFormContainer extends Component {
  static propTypes = {
    showMaxPassengersModal: PropTypes.bool,
    maxPassengers: PropTypes.number.isRequired,
    defaultOriginStation: PropTypes.object,
    maxPerPassengerType: PropTypes.object.isRequired,
    handleJourneySearch: PropTypes.func.isRequired,
    loadDefaultFormValues: PropTypes.func.isRequired,
    stations: PropTypes.array,
    form: PropTypes.shape({
      fields: PropTypes.shape({
        outboundDate: PropTypes.shape({
          onChange: PropTypes.func,
          value: PropTypes.object
        }).isRequired,
        inboundDate: PropTypes.shape({
          onChange: PropTypes.func,
          value: PropTypes.object
        }).isRequired,
        singleReturnSelector: PropTypes.shape({
          onChange: PropTypes.func,
          value: PropTypes.string
        }).isRequired,
        passengers: PropTypes.shape({
          onChange: PropTypes.func,
          value: PropTypes.array
        }).isRequired
      }),
      loadFormValues: PropTypes.func.isRequired,
      getValues: PropTypes.func.isRequired,
      validateForm: PropTypes.func.isRequired,
      valid: PropTypes.bool.isRequired
    })
  }

  constructor (...args) {
    super(...args)

    this.handleChangeAdult = this.handleChangeAdult.bind(this)
    this.handleChangeChild = this.handleChangeChild.bind(this)
    this.handleChangeStudent = this.handleChangeStudent.bind(this)
    this.handleChangeOutboundDate = this.handleChangeOutboundDate.bind(this)
    this.handleChangeInboundDate = this.handleChangeInboundDate.bind(this)
    this.handleJourneySearch = this.handleJourneySearch.bind(this)
    this.getOriginStations = this.getOriginStations.bind(this)
    this.getDestinationStations = this.getDestinationStations.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.props.loadDefaultFormValues && this.props.loadDefaultFormValues()
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.defaultOriginStation && !this.props.defaultOriginStation) {
      nextProps.form.loadFormValues({origin: nextProps.defaultOriginStation})
    }
  }

  _getPassengersForType (type) {
    return (this.props.form.fields.passengers.value || []).filter(passenger => passenger.type === type)
  }

  _getPassengersWithoutType (type) {
    return (this.props.form.fields.passengers.value || []).filter(passenger => passenger.type !== type)
  }

  handlePassengerChange (value, type) {
    const removedPassengers = this._getPassengersForType(type)
    const newPassengers = this._getPassengersWithoutType(type)

    for (let i = 0; i < parseInt(value) || 0; i++) {
      if (removedPassengers[i]) {
        newPassengers.push(removedPassengers[i])
      } else {
        newPassengers.push({type})
      }
    }
    newPassengers.sort((passengerA, passengerB) => passengerA.type.localeCompare(passengerB.type))
    this.props.form.fields.passengers.onChange(newPassengers)
  }

  handleChangeAdult (value) {
    this.handlePassengerChange(value, PASSENGER_TYPE_ADULT)
  }

  handleChangeChild (value) {
    this.handlePassengerChange(value, PASSENGER_TYPE_CHILD)
  }

  handleChangeStudent (value) {
    this.handlePassengerChange(value, PASSENGER_TYPE_STUDENT)
  }

  _getPassengerCount () {
    return (this.props.form.fields.passengers && countBy(this.props.form.fields.passengers.value, 'type')) || {}
  }

  handleChangeOutboundDate (outboundDate) {
    if (this._isInboundBeforeOutbound(outboundDate, this.props.form.fields.inboundDate.value)) {
      this.props.form.fields.inboundDate.onChange(outboundDate)
    }
    this.props.form.fields.outboundDate.onChange(outboundDate)
  }

  handleChangeInboundDate (inboundDate) {
    if (this._isInboundBeforeOutbound(this.props.form.fields.outboundDate.value, inboundDate)) {
      this.props.form.fields.outboundDate.onChange(inboundDate)
    }
    this.props.form.fields.inboundDate.onChange(inboundDate)
    const singleReturn = inboundDate ? JOURNEY_RETURN : JOURNEY_SINGLE
    if (singleReturn !== this.props.form.fields.singleReturnSelector.value) {
      this.props.form.fields.singleReturnSelector.onChange(singleReturn)
    }
  }

  _isInboundBeforeOutbound (outboundDate, inboundDate) {
    return outboundDate instanceof moment &&
      inboundDate instanceof moment &&
      inboundDate.isBefore(outboundDate)
  }

  getOriginStations () {
    const {stations, form: {fields: {destination}}} = this.props
    if (stations &&
      stations.some(station => station.connectedStations && station.connectedStations.length)
    ) {
      if (destination.valid &&
        destination.value &&
        destination.value.connectedStations &&
        destination.value.connectedStations.length
      ) {
        return stations.filter(
          station => station.connectedStations.includes(destination.value.UICStationCode)
        )
      }

      return stations.filter(station => station.connectedStations.length)
    }

    return stations
  }

  getDestinationStations () {
    const {stations, form: {fields: {origin}}} = this.props
    if (stations &&
      origin.valid &&
      origin.value &&
      origin.value.connectedStations &&
      origin.value.connectedStations.length
    ) {
      return stations.filter(
        station => origin.value.connectedStations.includes(station.UICStationCode)
      )
    } else {
      return stations
    }
  }

  async handleJourneySearch () {
    if (this.props.form.valid) {
      await this.props.handleJourneySearch(this.props.form.getValues())
    }
  }

  render () {
    const passengersCount = this._getPassengerCount()
    const props = {...this.props}
    delete props.stations

    return (
      <JourneySearchForm
        {...props}
        valueAdult={passengersCount[PASSENGER_TYPE_ADULT] || 0}
        valueChild={passengersCount[PASSENGER_TYPE_CHILD] || 0}
        valueStudent={passengersCount[PASSENGER_TYPE_STUDENT] || 0}
        handleChangeAdult={this.handleChangeAdult}
        handleChangeChild={this.handleChangeChild}
        handleChangeStudent={this.handleChangeStudent}
        handleChangeOutboundDate={this.handleChangeOutboundDate}
        handleChangeInboundDate={this.handleChangeInboundDate}
        handleJourneySearch={this.handleJourneySearch}
        getOriginStations={this.getOriginStations}
        getDestinationStations={this.getDestinationStations}
      />
    )
  }
}

const showMaxPassengersModalSelector = elementVisibleSelector(MAX_PASSENGER_MODAL)

const mapStateToProps = state => ({
  showMaxPassengersModal: showMaxPassengersModalSelector(state),
  maxPassengers: maxPersonsSelector(state),
  defaultOriginStation: machineStationSelector(state),
  stations: stationsSelector(state),
  disabled: modalIsActiveSelector(state),
  maxPerPassengerType: maxPerPassengerTypeSelector(state),
  focusSearchButton: currentQuerySelector(state).focusSearchButton === '1'
})

export default connect(mapStateToProps)(
  connectFormContainer()(
    JourneySearchFormContainer
  )
)
