import {
  API_UPDATE_BOOKING_REQUEST,
  API_UPDATE_BOOKING_SUCCESS,
  API_UPDATE_BOOKING_FAILURE,
  API_LINK_TO_CUSTOMER_EMAIL_REQUEST,
  API_LINK_TO_CUSTOMER_EMAIL_SUCCESS,
  API_LINK_TO_CUSTOMER_EMAIL_FAILURE
} from '../../types'
import Client from '../../../../../../src/api/client'
import ensureToken from '../../../../api/ensure-token'
import { bookingSelector } from '../../../selectors/api/booking/booking'
import cloneDeep from 'lodash/cloneDeep'
import {UPDATE_BOOKING_HANDLED_ERRORS} from 's3p-js-lib/src/redux/actions/api/v2/booking'

const updateBookingRequest = crmCustomer => ({type: API_UPDATE_BOOKING_REQUEST, crmCustomer})
const updateBookingSuccess = response => ({type: API_UPDATE_BOOKING_SUCCESS, response})
const updateBookingFailure = error => ({type: API_UPDATE_BOOKING_FAILURE, error})

const linkToCustomerEmailRequest = linkToCustomerEmail => ({type: API_LINK_TO_CUSTOMER_EMAIL_REQUEST, linkToCustomerEmail})
const linkToCustomerEmailSuccess = response => ({type: API_LINK_TO_CUSTOMER_EMAIL_SUCCESS, response})
const linkToCustomerEmailFailure = error => ({type: API_LINK_TO_CUSTOMER_EMAIL_FAILURE, error})

export const updateBookingWithCrmCustomer = ({crmCustomer}) =>
  ensureToken(async (token, dispatch, getState) => {
    try {
      dispatch(updateBookingRequest(crmCustomer))
      let lastResponse
      const booking = bookingSelector(getState())

      if (booking && crmCustomer !== undefined) {
        try {
          dispatch(linkToCustomerEmailRequest(crmCustomer))
          if (crmCustomer) {
            const customerDetailsData = prepareCustomerDetailsData(crmCustomer)
            lastResponse = booking?.customer
              ? await Client.updateBookingCustomer(token, booking.booking_number, customerDetailsData)
              : await Client.addCustomerToBooking(token, booking.booking_number, customerDetailsData)
          } else if (booking.customer && crmCustomer === null) {
            const customerDetailsData = cloneDeep(booking.customer)
            delete customerDetailsData.crm_customer_id
            delete customerDetailsData.crm_external_reference
            customerDetailsData.crm_reference = ''
            lastResponse = await Client.updateBookingCustomer(token, booking.booking_number, customerDetailsData)
          }

          dispatch(linkToCustomerEmailSuccess(lastResponse))
        } catch (error) {
          dispatch(linkToCustomerEmailFailure(error))
          if (UPDATE_BOOKING_HANDLED_ERRORS.includes(error.errorCode)) {
            return false
          }
          throw error
        }
      }

      lastResponse && dispatch(updateBookingSuccess(lastResponse))
      return true
    } catch (error) {
      dispatch(updateBookingFailure(error))
      throw error
    }
  })

const prepareCustomerDetailsData = customerDetails => {
  const customer = {
    title: customerDetails.title === '' ? undefined : customerDetails.title,
    first_name: customerDetails.firstName,
    last_name: customerDetails.lastName,
    address: {
      postal_code: customerDetails.postalCode,
      street: customerDetails.street,
      house_number: customerDetails.houseNumber,
      city: customerDetails.city,
      country_code: customerDetails.country
    },
    email: customerDetails.emailAddress,
    phone_number: customerDetails.phoneNumber
  }
  if (customerDetails.customerNumber) {
    customer.crm_customer_id = customerDetails.customerNumber
    customer.crm_reference = `${customerDetails.customerNumber}`
  }
  delete customer.crm_external_reference
  return customer
}
