import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ComponentContainer from '../../../elements/component-container/component-container'
import Button from '../../../elements/button/button'
import Panel from '../../../elements/panel/panel'
import TextLabel from '../../../elements/text-label/text-label'
import ButtonGroup from '../../../elements/button-group/button-group'
import _t from 's3p-js-lib/src/translate'
import './seat-selection.scss'

export default class SeatSelection extends Component {
  static propTypes = {
    handleManualSeatSelection: PropTypes.func.isRequired,
    availableSegments: PropTypes.array,
    goForward: PropTypes.func.isRequired,
    isLoadingCreateBooking: PropTypes.bool
  }

  constructor (...args) {
    super(...args)

    this.handleManualSeatSelection = this.handleManualSeatSelection.bind(this)
  }

  handleManualSeatSelection () {
    const firstAvailableSegment = this.props.availableSegments && this.props.availableSegments.length
      ? this.props.availableSegments[0]
      : null

    firstAvailableSegment
      ? this.props.handleManualSeatSelection(firstAvailableSegment.leg.id)
      : this.props.goForward()
  }

  render () {
    return (
      <ComponentContainer name='seat-selection'>
        <Panel
          header={
            <Panel.Header
              name='seat-selection'
              title={_t.message('seat-selection.title')}
              description={_t.message('seat-selection.description')}
            />
          }
        >
          <TextLabel
            name='information'
            text={_t.message('seat-selection.description')} />
          <ButtonGroup>
            <Button
              name='automatic-seat-selection'
              className='tertiary'
              onClick={this.props.goForward}
              loading={this.props.isLoadingCreateBooking}
              autoFocus
            >
              <TextLabel text={_t.message('seat-selection.buttons.automatic-seat-selection')} />
            </Button>
            <Button name='manual-seat-selection' className='tertiary' onClick={this.handleManualSeatSelection}>
              <TextLabel text={_t.message('seat-selection.buttons.manual-seat-selection')} />
            </Button>
          </ButtonGroup>
        </Panel>
      </ComponentContainer>
    )
  }
}
