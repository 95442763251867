import React, { Component } from 'react'
import StickyFooterWithTaskbar from '../../base/sticky-footer-with-taskbar'
import ComponentContainer from '../../../../src/elements/component-container/component-container'
import RefundSummary from '../../../containers/aftersales/cancellation/progress-navigation/refund-summary'
import Navigation from '../../../containers/aftersales/cancellation/progress-navigation/navigation'

export default class ProgressNavigation extends Component {
  render () {
    return (
      <StickyFooterWithTaskbar>
        <ComponentContainer name='sticky-footer'>
          <div className='sticky-footer-content'>
            <RefundSummary />
            <Navigation />
          </div>
        </ComponentContainer>
      </StickyFooterWithTaskbar>
    )
  }
}
