import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TilesWithModal from '../../../containers/base/tiles/tiles-with-modal'
import PaymentMethodTile from './payment-method-tile'
import PaymentCashModal from '../payment-modal/cash-modal'
import PaymentVoucherModal from '../payment-modal/voucher-modal'
import PaymentCreditDebitModal from '../../../containers/base/payment-modal/credit-debit-modal'
import Loader, { TYPE_COMPONENT } from '../../../elements/loader/loader'

const MAX_METHODS = 5

export default class PaymentMethods extends Component {
  static propTypes = {
    paymentMethods: PropTypes.array.isRequired,
    showCashModal: PropTypes.bool,
    showVoucherModal: PropTypes.bool,
    showCreditDebitModal: PropTypes.bool,
    handleSelectPaymentMethod: PropTypes.func.isRequired,
    hideTiles: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired
  }

  render () {
    return this.props.loading || (this.props.paymentMethods.length && !this.props.hideTiles)
      ? (
        <div className='payment-methods'>
          {this.props.loading ? <Loader type={TYPE_COMPONENT} />
            : this.props.paymentMethods.length
              ? (
                <TilesWithModal
                  data={this.props.paymentMethods}
                  maxTiles={MAX_METHODS}
                  name='payment-method'
                  translatePrefix='payment-methods'
                  tile={PaymentMethodTile}
                  handleSelect={this.props.handleSelectPaymentMethod}
                  disabled={this.props.disabled}
                  hideDivider
                />
              ) : null}
          {this.props.showCashModal ? <PaymentCashModal /> : null}
          {this.props.showVoucherModal ? <PaymentVoucherModal /> : null}
          {this.props.showCreditDebitModal ? <PaymentCreditDebitModal /> : null}
        </div>
      ) : null
  }
}
