import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../../../elements/text-label/text-label'
import Icon from '../../../../elements/icon/icon'
import TileSet from '../../../../elements/tile-set/tile-set'
import _t from 's3p-js-lib/src/translate'

const propertyCodeIconMap = {
  WH: 'carriage-feature-wheelchair-access',
  BAR: 'carriage-feature-bar',
  BIKE: 'carriage-feature-bicycle',
  FIRST: 'carriage-feature-first-class',
  QUIET: 'carriage-feature-quiet'
}

export default class Carriage extends Component {
  static propTypes = {
    available: PropTypes.bool.isRequired,
    selected: PropTypes.bool.isRequired,
    carriageNumber: PropTypes.string.isRequired,
    carriageName: PropTypes.string.isRequired,
    handleSelect: PropTypes.func.isRequired,
    propertyCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
    availableSeats: PropTypes.number
  }

  render () {
    return (
      <TileSet.Tile
        selected={this.props.selected}
        onClick={this.props.handleSelect}
        disabled={!this.props.available}
      >
        <TextLabel name='carriage-number' text={_t.message('seat-selector.carriage-selector.carriage-number',
          {carriageNumber: this.props.carriageNumber}
        )} />
        {this.props.availableSeats > 0 ? (
          <TextLabel name='seats-available' text={_t.message(
            'seat-selector.carriage-selector.seats-available',
            {seatsAvailable: this.props.availableSeats}
          )} />
        ) : (
          <TextLabel name='seats-available' text={_t.message(
            'seat-selector.carriage-selector.no-seats-available'
          )} />
        )}
        <div className='feature-icons'>
          <ul>
            {this.props.propertyCodes.map(code => propertyCodeIconMap[code]
              ? (
                <li key={code}>
                  <Icon name={propertyCodeIconMap[code]} className='medium' />
                </li>
              )
              : null
            )}
          </ul>
        </div>
      </TileSet.Tile>
    )
  }
}
