import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _t from 's3p-js-lib/src/translate'
import FinalizeBookingModal from './finalize-booking-modal'
import { isOfflineSelectorCreator } from '../../../redux/selectors/machine/offline'
import {
  DEVICE_ID_RECEIPT_PRINTER,
  LAST_MACHINE_ERROR_NAMESPACE_RECEIPT_PRINTER,
  PRINT_VOUCHER_DEFAULT_ERROR_MESSAGE
} from '../../../constants'
import { FEEDBACK_ALERT, FEEDBACK_SUCCESS } from '../../../elements/feedback/feedback'
import {
  printGiftVoucher
} from '../../../redux/actions/containers/base/finalize-booking/print-gift-voucher-modal'
import { lastMachineErrorsSelector } from '../../../redux/selectors/machine/last-machine-errors'

const ERROR_MESSAGES_MAP = {
  [PRINT_VOUCHER_DEFAULT_ERROR_MESSAGE]: 'finalize-booking-modal.gift-voucher.default-error'
}

class PrintGiftVoucherModalContainer extends Component {
  static propTypes = {
    onChangeFulfillment: PropTypes.func.isRequired,
    onNextSale: PropTypes.func.isRequired,
    isPrinting: PropTypes.bool,
    receiptPrinterMachineErrors: PropTypes.array,
    aftersales: PropTypes.bool,
    retrieveAndPrintCycle: PropTypes.bool,
    receiptPrinterErrorMessage: PropTypes.object,
    isOfflineReceiptPrinter: PropTypes.bool,
    printGiftVoucher: PropTypes.func.isRequired,
    ticketErrorMessage: PropTypes.string
  }

  UNSAFE_componentWillMount () {
    if (!this.props.isOfflineReceiptPrinter) {
      this.props.printGiftVoucher()
    }
  }

  render () {
    let messages = this.props.isPrinting || this.props.retrieveAndPrintCycle
      ? []
      : this.props.ticketErrorMessage
        ? [{
          status: FEEDBACK_ALERT,
          text: [_t.message(ERROR_MESSAGES_MAP[this.props.ticketErrorMessage])]
        }]
        : this.props.receiptPrinterErrorMessage
          ? [{status: FEEDBACK_ALERT, text: [_t.message('finalize-booking-modal.receipt.default-error')]}]
          : this.props.receiptPrinterMachineErrors.length
            ? []
            : [{status: FEEDBACK_SUCCESS, text: [_t.message('finalize-booking-modal.gift-voucher.status-success')]}]

    return (
      <FinalizeBookingModal
        {...this.props}
        title={_t.message('finalize-booking-modal.gift-voucher.title')}
        messages={messages}
        disableNextSale={this.props.isPrinting}
        showChangeFulfillment={false}
        showContinuePrinting
        onContinuePrinting={this.props.printGiftVoucher}
        isVoucher
      />
    )
  }
}

const _lastMachineErrorsSelector = lastMachineErrorsSelector([LAST_MACHINE_ERROR_NAMESPACE_RECEIPT_PRINTER])
const receiptPrinterOfflineSelector = isOfflineSelectorCreator(DEVICE_ID_RECEIPT_PRINTER)

const mapStateToProps = state => ({
  isPrinting: state.containers.base.finalizeBooking.printTicketsModal.isPrinting,
  receiptPrinterErrorMessage: state.machine.receiptPrinter.error,
  retrieveAndPrintCycle: state.containers.base.finalizeBooking.printTicketsModal.retrievingAndPrintingTickets,
  receiptPrinterMachineErrors: _lastMachineErrorsSelector(state),
  hasPrintErrors: Boolean(_lastMachineErrorsSelector(state).length || receiptPrinterOfflineSelector(state)),
  ticketErrorMessage: state.containers.base.finalizeBooking.printTicketsModal.ticketErrorMessage
})

const mapDispatchToProps = {
  printGiftVoucher
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintGiftVoucherModalContainer)
