import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import ComponentContainer from '../../../elements/component-container/component-container'
import SegmentSet from './allocated-seats/segment-set'
import Panel from '../../../elements/panel/panel'
import './allocated-seats.scss'

export default class AllocatedSeats extends Component {
  static propTypes = {
    journeyId: PropTypes.string,
    outboundSeatSelectionOptions: PropTypes.array,
    inboundSeatSelectionOptions: PropTypes.array,
    outboundTravelInfo: PropTypes.object,
    inboundTravelInfo: PropTypes.object,
    handleManualSeatSelection: PropTypes.func.isRequired,
    availableSegments: PropTypes.array,
    goForward: PropTypes.func
  }

  render () {
    const {
      outboundSeatSelectionOptions,
      inboundSeatSelectionOptions,
      outboundTravelInfo,
      inboundTravelInfo,
      handleManualSeatSelection
    } = this.props

    return (
      <ComponentContainer name='allocated-seats'>
        <Panel
          header={<Panel.Header
            name='allocated-seats'
            title={_t.message('allocated-seats.title')}
            description={_t.message('allocated-seats.description')}
          />}
        >
          <div className='travel-details'>
            {outboundSeatSelectionOptions && outboundTravelInfo
              ? (
                <SegmentSet
                  options={outboundSeatSelectionOptions}
                  onSelectSegment={handleManualSeatSelection}
                  travelInfo={outboundTravelInfo}
                />
              ) : null}
            {inboundSeatSelectionOptions && inboundTravelInfo
              ? (
                <SegmentSet
                  options={inboundSeatSelectionOptions}
                  onSelectSegment={handleManualSeatSelection}
                  travelInfo={inboundTravelInfo}
                />
              ) : null}
          </div>
        </Panel>
      </ComponentContainer>
    )
  }
}
