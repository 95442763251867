import notes from './notes'
import refund from './refund'
import reprintResend from './reprint-resend'
import printRefundVoucherModal from './print-refund-voucher-modal'
import booking from './booking'

export default {
  booking,
  notes,
  refund,
  reprintResend,
  printRefundVoucherModal
}
