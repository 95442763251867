import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import ComponentContainer from '../../../elements/component-container/component-container'
import Feedback, { FEEDBACK_WARNING } from '../../../elements/feedback/feedback'
import Panel from '../../../elements/panel/panel'
import Form from '../../../elements/form/form'
import CustomerFieldSet from './customer-details/customer-field-set'
import TravelingPassengerFieldSet from './customer-details/traveling-passenger-field-set'
import SelectedCustomer from '../../../containers/reservations/booking-details/selected-customer'
import { SCROLLER_BOOKING_DETAILS_CUSTOMER_DETAILS_FORM } from '../../../constants'
import './customer-details.scss'

export default class CustomerDetails extends Component {
  static propTypes = {
    form: PropTypes.shape({
      fields: PropTypes.shape({
        isPassenger: PropTypes.object
      }).isRequired
    }).isRequired,
    showFeedback: PropTypes.bool.isRequired
  }

  render () {
    return (
      <ComponentContainer name='customer-details'>
        <Panel
          header={
            <Panel.Header
              name='customer-details'
              title={_t.message('booking-details.customer-details.title')}
              description={_t.message('booking-details.customer-details.title')}
            />
          }
        >
          <SelectedCustomer />
          <Form id={SCROLLER_BOOKING_DETAILS_CUSTOMER_DETAILS_FORM} formContext='booking-details.customer-details'>
            {this.props.showFeedback
              ? (
                <Feedback
                  status={FEEDBACK_WARNING}
                  text={_t.message('booking-details.customer-details.form.invalid-form')}
                />
              )
              : null}
            <CustomerFieldSet form={this.props.form} />
            {this.props.form.fields.isPassenger
              ? <TravelingPassengerFieldSet field={this.props.form.fields.isPassenger} />
              : null
            }
          </Form>
        </Panel>
      </ComponentContainer>
    )
  }
}
