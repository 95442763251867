import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  DISCOUNT_CARD_PRIVILEGE_CARD,
  DISCOUNT_CARD_STUDENT_CARD,
  DISCOUNT_CARD_WELFARE_CARD
} from '../../../constants'
import TextLabel from '../../../elements/text-label/text-label'
import classNames from 'classnames'
import Link from '../../../elements/link/link'
import _t from 's3p-js-lib/src/translate'
import './shortcuts.scss'
import {
  JOURNEY_RETURN,
  JOURNEY_SINGLE
} from 's3p-js-lib/src/constants'
import countBy from 'lodash/countBy'

const getClassName = selected => classNames('shortcut', selected ? 'state--is-selected' : '')

class ShortcutEmpty extends Component {
  static propTypes = {
    selected: PropTypes.bool,
    sequenceNumber: PropTypes.number
  }

  render () {
    return (
      <div className={getClassName(this.props.selected)}>
        <TextLabel name='empty-shortcut' text={_t.message('shortcuts.empty')} />
      </div>
    )
  }
}

export default class Shortcut extends Component {
  static propTypes = {
    id: PropTypes.string,
    isEmpty: PropTypes.bool,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    sequenceNumber: PropTypes.number,
    origin: PropTypes.shape({
      name: PropTypes.string
    }),
    destination: PropTypes.shape({
      name: PropTypes.string
    }),
    passengers: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string
    })),
    type: PropTypes.oneOf([JOURNEY_SINGLE, JOURNEY_RETURN]),
    productFamily: PropTypes.shape({
      description: PropTypes.string
    }),
    dateInterval: PropTypes.number,
    product: PropTypes.string,
    discountCardCode: PropTypes.string,
    useShortcut: PropTypes.func,
    hotkey: PropTypes.string
  }

  constructor (props, ...args) {
    super(props, ...args)

    this.useShortcut = this.useShortcut.bind(this)
  }

  useShortcut () {
    const data = {...this.props}
    delete data.useShortcut
    this.props.useShortcut(data)
  }

  getDiscountCardLabel (discountCardCode) {
    switch (discountCardCode) {
      case DISCOUNT_CARD_PRIVILEGE_CARD:
        return 'privilege'
      case DISCOUNT_CARD_STUDENT_CARD:
        return 'student'
      case DISCOUNT_CARD_WELFARE_CARD:
        return 'welfare'
      default:
        return null
    }
  }

  render () {
    const {
      isEmpty,
      selected,
      hotkey,
      destination,
      productFamily,
      passengers,
      sequenceNumber,
      disabled,
      discountCardCode
    } = this.props
    const passengerCounts = countBy(passengers, 'type')
    const discountCardLabel = this.getDiscountCardLabel(discountCardCode)

    return (
      <Link name='shortcut' onClick={this.useShortcut} tabIndex='-1' hotkey={hotkey} disabled={disabled}>
        {!isEmpty
          ? (
            <div className={getClassName(selected)}>
              <div className='shortcut__journey-product-family'>
                {destination
                  ? (
                    <TextLabel
                      name='destination'
                      text={_t.message('shortcuts.destination', {
                        destinationName: destination.name
                      })}
                    />
                  ) : (
                    <TextLabel name='origin-destination' text={_t.message('shortcuts.all-origin-destination')} />
                  )}
                <TextLabel
                  name='product-family'
                  text={_t.message('shortcuts.product-family', {
                    description: productFamily && productFamily.description
                  })}
                />
              </div>
              <div className='shortcut__number-of-passenger-type-discount-card'>
                <div className='number-of-passenger-type'>
                  {Object.keys(passengerCounts).map(passengerType => (
                    <TextLabel
                      key={passengerType}
                      name='number-of-passenger-type'
                      text={_t.message('shortcuts.passenger-count', {
                        type: passengerType,
                        count: passengerCounts[passengerType]
                      })}
                    />
                  ))}
                </div>
                {discountCardLabel
                  ? (
                    <div className='discount-card'>
                      <TextLabel
                        name='discount-card'
                        text={_t.message(`shortcuts.discount-card-${discountCardLabel}`)}
                      />
                    </div>
                  ) : null}
              </div>
            </div>
          ) : <ShortcutEmpty selected={selected} sequenceNumber={sequenceNumber} />}
      </Link>
    )
  }
}
