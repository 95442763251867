/* globals S3P_SETTINGS: true */

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getMachineStatusDevices } from '../../redux/actions/machine/status'
import { deviceMessagesSelector, deviceIdsWithErrorsSelector } from '../../redux/selectors/containers/base/diagnostics'
import Diagnostics, { MESSAGE_MODAL } from '../../components/base/taskbar/diagnostics'
import { showElement } from '../../redux/actions/containers/base/visible-element'

const isDevelopment = S3P_SETTINGS.environment === 'development'
const STATUS_INTERVAL = 30000 // milliseconds

class DiagnosticsContainer extends Component {
  static propTypes = {
    getMachineStatusDevices: PropTypes.func.isRequired,
    deviceMessages: PropTypes.array,
    devicesIdsWithErrors: PropTypes.array,
    failure: PropTypes.bool,
    showElement: PropTypes.func.isRequired,
    hasDeviceMessages: PropTypes.bool
  }

  constructor (...args) {
    super(...args)

    this.state = {failureIsOld: false}
  }

  componentDidMount () {
    this.props.getMachineStatusDevices()
    if (!isDevelopment) {
      this.statusPollId = setInterval(this.props.getMachineStatusDevices, STATUS_INTERVAL)
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const devicesWithErrors = this.props.devicesIdsWithErrors
    const nextDevicesWithErrors = nextProps.devicesIdsWithErrors
    const hasNewErrors = !(nextDevicesWithErrors.every(value => devicesWithErrors.indexOf(value) >= 0))

    if (hasNewErrors || (this.props.failure && !this.state.failureIsOld)) {
      nextProps.showElement(MESSAGE_MODAL)
      if (this.props.failure) {
        this.setState({failureIsOld: true})
      }
    } else if (!this.props.failure && this.state.failureIsOld) {
      this.setState({failureIsOld: false})
    }
  }

  componentWillUnmount () {
    this.statusPollId && clearInterval(this.statusPollId)
  }

  render () {
    return <Diagnostics
      hasDeviceMessages={this.props.hasDeviceMessages}
      deviceMessages={this.props.deviceMessages}
      failure={this.props.failure}
    />
  }
}

const mapStateToProps = state => {
  const deviceMessages = deviceMessagesSelector(state)
  const devicesIdsWithErrors = deviceIdsWithErrorsSelector(state)
  return {
    deviceMessages,
    devicesIdsWithErrors,
    hasDeviceMessages: deviceMessages.some(device => device.messages.length > 0),
    failure: state.machine.status.failure
  }
}

const mapDispatchToProps = {
  getMachineStatusDevices: () => (dispatch, getState) => {
    if (!getState().machine.loading.getMachineStatusDevices) {
      dispatch(getMachineStatusDevices())
    }
  },
  showElement
}

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosticsContainer)
