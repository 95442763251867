import {
  API_V2_GET_TICKET_DETAILS_REQUEST,
  API_V2_GET_TICKET_DETAILS_SUCCESS,
  API_V2_GET_TICKET_DETAILS_FAILURE
} from 's3p-js-lib/src/redux/actions/types-v2'
import Client from 's3p-js-lib/src/api/client'
import ensureToken from 's3p-js-lib/src/api/ensure-token'
import ResponseCodes from 's3p-js-lib/src/api/response-codes'

const getTicketDetailsRequest = (ticketKey, data) => ({type: API_V2_GET_TICKET_DETAILS_REQUEST, ticketKey, data})
const getTicketDetailsSuccess = response => ({type: API_V2_GET_TICKET_DETAILS_SUCCESS, response})
const getTicketDetailsFailure = error => ({type: API_V2_GET_TICKET_DETAILS_FAILURE, error})

export const getTicketDetails = (ticketKey, data) => ensureToken(async (token, dispatch) => {
  try {
    dispatch(getTicketDetailsRequest(ticketKey, data))
    const response = await Client.getTicketDetails(token, ticketKey, data)
    dispatch(getTicketDetailsSuccess(response))
  } catch (error) {
    dispatch(getTicketDetailsFailure(error))
    if (error.errorCode !== ResponseCodes.BOOKING_TICKET_NOT_FOUND) {
      throw error
    }
    return false
  }
})
