import { connect } from 'react-redux'
import { completedOrProvisionalOrOverviewBookingSelector } from '../../../redux/selectors/api/booking/booking'
import { bookingPassengerSelector } from 's3p-js-lib/src/redux/selectors/api/booking/passengers'
import { priceToBePaidSelector } from '../../../redux/selectors/containers/base/booking-summary'
import PriceSummary from '../../../components/base/booking-summary/price-summary'

const _priceToBePaidSelector = priceToBePaidSelector(completedOrProvisionalOrOverviewBookingSelector)
const _bookingPassengerSelector = bookingPassengerSelector(completedOrProvisionalOrOverviewBookingSelector)

const mapStateToProps = state => {
  const passengers = _bookingPassengerSelector(state)
  return {
    passengersCount: passengers && passengers.length,
    price: _priceToBePaidSelector(state)
  }
}

export default connect(mapStateToProps)(PriceSummary)
