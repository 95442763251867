import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Modal from '../../../../elements/modal/modal'
import TextLabel from '../../../../elements/text-label/text-label'
import _t from 's3p-js-lib/src/translate'
import EditModalForm from '../../../../containers/tickets/sidebar/shortcuts/edit-modal-form'

export default class EditModal extends Component {
  static propTypes = {
    sequenceNumber: PropTypes.number.isRequired,
    handleSaveShortcut: PropTypes.func.isRequired,
    onHideElement: PropTypes.func.isRequired,
    isLoadingUpdateAgent: PropTypes.bool.isRequired,
    validForm: PropTypes.bool
  }

  render () {
    return (
      <Modal
        onHideElement={this.props.onHideElement}
        name='edit-shortcut'
        header={<Modal.Header
          title={_t.message('edit-shortcut.title', {hotkey: `Ctrl+Shift+${this.props.sequenceNumber}`})}
        />}
        footer={<Modal.FooterSingle
          loading={this.props.isLoadingUpdateAgent}
          disabled={!this.props.validForm}
          onClick={this.props.handleSaveShortcut}
          hotkey='proceed'
        >
          <TextLabel text={_t.message('edit-shortcut.save')} />
        </Modal.FooterSingle>}
      >
        <EditModalForm sequenceNumber={this.props.sequenceNumber} />
      </Modal>
    )
  }
}
