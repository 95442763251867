import {connect} from 'react-redux'
import {cmsBlockSelectorCreator} from 's3p-js-lib/src/redux/selectors/api/base/cms'

const mapStateToProps = () => {
  const cmsBlockSelector = cmsBlockSelectorCreator()

  return (state, ownProps) => ({
    isLoadingCmsBlock: state.api.base.cms.isLoading,
    cmsBlock: cmsBlockSelector(state, ownProps)
  })
}

export default connect(mapStateToProps)
