import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Modal from '../../../../elements/modal/modal'
import TextLabel from '../../../../elements/text-label/text-label'
import Button from '../../../../elements/button/button'
import Form from '../../../../elements/form/form'
import DropdownList from '../../../../elements/dropdown-list/dropdown-list'
import kebabCase from 'lodash/kebabCase'
import { FORM_BOOKING_NOTE } from 's3p-js-lib/src/constants-form'
import _t from 's3p-js-lib/src/translate'

export default class NotesModal extends Component {
  static propTypes = {
    handleHideModal: PropTypes.func.isRequired,
    handleSaveNote: PropTypes.func.isRequired,
    form: PropTypes.shape({
      getValues: PropTypes.func.isRequired,
      valid: PropTypes.bool,
      fields: PropTypes.shape({
        queue: PropTypes.object,
        content: PropTypes.object
      })
    }),
    queues: PropTypes.any,
    isLoadingAddBookingNote: PropTypes.bool
  }

  getQueueLabel (value) {
    return value && _t.message(`aftersales.booking.notes.modal.queue.${kebabCase(value.name)}`)
  }

  render () {
    return (
      <Modal
        name={FORM_BOOKING_NOTE}
        header={<Modal.Header title={_t.message('aftersales.booking.notes.modal.title')} />}
        footer={
          <Modal.CustomFooter>
            <Button
              name='cancel'
              className='tertriary'
              onClick={this.props.handleHideModal}
            >
              <TextLabel name='cancel' text={_t.message('aftersales.booking.notes.modal.cancel')} />
            </Button>
            <Button
              name='save-note'
              className='primary'
              onClick={this.props.handleSaveNote}
              loading={this.props.isLoadingAddBookingNote}
              disabled={!this.props.form.valid}
            >
              <TextLabel name='save-note' text={_t.message('aftersales.booking.notes.modal.save')} />
            </Button>
          </Modal.CustomFooter>
        }
      >
        <Form formContext={FORM_BOOKING_NOTE}>
          <Form.Label text={_t.message('aftersales.booking.notes.modal.queue.label')} htmlFor='queue' name='content' />
          <DropdownList
            {...this.props.form.fields.queue}
            textField={this.getQueueLabel}
            data={this.props.queues}
            placeholder={_t.message('aftersales.booking.notes.modal.queue.placeholder')}
          />
          <Form.Label text={_t.message('aftersales.booking.notes.modal.content.label')} htmlFor='content' name='content' />
          <Form.TextField
            {...this.props.form.fields.content}
            name='content'
            id='content'
            placeholder={_t.message('aftersales.booking.notes.modal.content.placeholder')}
          />
        </Form>
      </Modal>
    )
  }
}
