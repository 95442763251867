import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import Mousetrap from 'mousetrap'
import Hotkeys from '../utils/hotkeys'
import HotkeyLabel from '../hotkey-label/hotkey-label'
import './tile-set.scss'

class Tile extends Component {
  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.element,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    autoFocus: PropTypes.bool,
    hotkey: PropTypes.string,
    loading: PropTypes.bool,
    tabIndex: PropTypes.number
  }

  static defaultProps = {
    disabled: false,
    selected: false,
    autoFocus: false,
    tabIndex: 0
  }

  constructor (props, ...args) {
    super(props, ...args)

    this.handleClick = this.handleClick.bind(this)

    this.keyHandlers = {}
    if (props.hotkey) {
      this.keyHandlers[props.hotkey] = this.handleClick
    }
  }

  componentDidMount () {
    const mousetrap = new Mousetrap(this._element)
    mousetrap.bind('enter', this.handleClick)
    mousetrap.bind('space', this.handleClick)
    if (this.props.autoFocus) {
      this._element.focus()
    }
  }

  handleClick (event) {
    event.preventDefault()
    if (!this.props.disabled && !this.props.selected && this.props.onClick) {
      this.props.onClick(event)
    }

    return false
  }

  render () {
    const {className, selected, disabled, children, icon, hotkey} = this.props
    const tabIndex = selected || disabled ? -1 : this.props.tabIndex

    const fullClassName = classNames('tile', className, {
      'state--is-selected': selected,
      'state--is-available': !disabled && !selected,
      'state--is-unavailable': disabled && !selected
    })

    const tile = (
      <div
        ref={element => { this._element = element }}
        className={fullClassName}
        tabIndex={tabIndex}
        aria-selected={selected}
        role='option'
        onClick={this.handleClick}
      >
        {icon && <div className='tile__icon'>{icon}</div>}
        <div className='tile__content'>
          {children}
        </div>
        {hotkey && <HotkeyLabel hotkey={hotkey} />}
      </div>
    )

    return hotkey ? <Hotkeys handlers={this.keyHandlers} enabled={!this.props.disabled}>{tile}</Hotkeys> : tile
  }
}

class TileSet extends Component {
  static propTypes = {
    className: PropTypes.string,
    rowCount: PropTypes.number,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    className: 'tile-set',
    disabled: false
  }

  constructor (...args) {
    super(...args)

    this.handleMove = this.handleMove.bind(this)

    this.keyHandlers = {
      up: this.handleMove('up'),
      down: this.handleMove('down'),
      left: this.handleMove('left'),
      right: this.handleMove('right')
    }
  }

  handleMove (to) {
    return () => {
      if (document.activeElement.parentNode === this.tileSet) {
        let nextElement
        const activeChildIndex = Array.from(this.tileSet.children)
          .findIndex(child => child === document.activeElement)
        switch (to) {
          case 'right':
            nextElement = document.activeElement.nextSibling
            break
          case 'left':
            nextElement = document.activeElement.previousSibling
            break
          case 'up':
            nextElement = this.tileSet.children[activeChildIndex - this.props.rowCount]
            break
          case 'down':
            nextElement = this.tileSet.children[activeChildIndex + this.props.rowCount]
            break
        }

        if (nextElement && !nextElement.classList.contains('state--is-unavailable')) {
          nextElement.focus()
        }
      }
    }
  }

  render () {
    return (
      <Hotkeys handlers={this.keyHandlers} enabled={!this.props.disabled}>
        <div
          className={this.props.className}
          ref={tileSet => { this.tileSet = tileSet }}
        >{this.props.children}</div>
      </Hotkeys>
    )
  }
}

TileSet.Tile = Tile

export default TileSet
