/* globals S3P_SETTINGS: true */

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadValues } from 's3p-js-lib/src/redux/actions/user-input/base/form'
import {
  handleSearchProducts,
  handleShowLeapModal
} from '../../redux/actions/containers/tickets/products'
import { elementVisibleSelector } from '../../redux/selectors/containers/base/visible-element'
import { getStations } from 's3p-js-lib/src/redux/actions/api/orientation/stations'
import { getMachineStationInformation } from '../../redux/actions/machine/station-information'
import { getProductFamilies } from 's3p-js-lib/src/redux/actions/api/v2/meta/product-families'
import { productFamiliesSelector } from '../../redux/selectors/containers/tickets/products'
import Products from '../../components/tickets/products'
import { MAX_PASSENGER_MODAL } from '../../components/base/max-passengers-modal'
import { DIFFERENT_PASSENGERS_NUMBER_MODAL } from '../../components/tickets/modals/different-passengers-number-modal'
import { CART_VALUE_ZERO_MODAL } from '../../components/tickets/modals/cart-value-zero-modal'
import { BEFORE_DAY_CURRENT_MONTH_MODAL } from '../../components/tickets/modals/before-day-current-month-modal'
import { machineStationSelector } from '../../redux/selectors/containers/base/stations'
import { maxPersonsSelector } from '../../redux/selectors/containers/base/max-passengers'
import { stationsSelector } from 's3p-js-lib/src/redux/selectors/api/base/stations'
import { onlyBookableBeforeDayOfCurrentMonthSelector } from '../../redux/selectors/api/v2/meta/product-families'
import { FORM_PRODUCT_SEARCH } from 's3p-js-lib/src/constants-form'
import { currentQuerySelector } from '../../redux/selectors/containers/base/routing'
import { ONE_LEAP_PRODUCT_BOOKING_MODAL } from '../../components/tickets/modals/one-leap-product-booking-modal'
import { LEAP_OPTION_CHECK_BALANCE_MODAL } from '../../components/tickets/modals/leap-option-check-balance-modal'
import { validateShortcuts } from '../../redux/actions/containers/tickets/sidebar-shortcuts'

class ProductsContainer extends Component {
  static propTypes = {
    getMachineStationInformation: PropTypes.func.isRequired,
    getProductFamilies: PropTypes.func.isRequired,
    getStations: PropTypes.func.isRequired,
    handleSearchProducts: PropTypes.func.isRequired,
    handleShowLeapModal: PropTypes.func.isRequired,
    defaultOriginStation: PropTypes.object,
    productFamilies: PropTypes.array,
    stations: PropTypes.array,
    maxPassengers: PropTypes.number,
    beforeDayCurrentMonth: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    showMaxPassengersModal: PropTypes.bool,
    showBeforeDayCurrentMonth: PropTypes.bool,
    showCartValueZero: PropTypes.bool,
    isLoadingRepeatLastBooking: PropTypes.bool,
    showDifferentPassengersModal: PropTypes.bool,
    paramProductFamily: PropTypes.string,
    loadFormValues: PropTypes.func,
    validateShortcuts: PropTypes.func
  }

  constructor (args) {
    super(args)
    this.handleLoadFormValues = this.handleLoadFormValues.bind(this)
    this.handleSelectTile = this.handleSelectTile.bind(this)
  }

  UNSAFE_componentWillMount () {
    if (!this.props.isLoading) {
      if (this.props.stations === null) {
        this.props.getStations(S3P_SETTINGS.s3Passenger.features.stations)
      }

      if (!this.props.defaultOriginStation) {
        this.props.getMachineStationInformation()
      }

      if (!this.props.productFamilies.length) {
        this.props.getProductFamilies().then(
          success => {
            if (success) {
              this.props.validateShortcuts()
            }
          })
      } else {
        this.props.validateShortcuts()
      }
    }
  }

  handleLoadFormValues (values) {
    this.props.loadFormValues(values)
    this.props.handleSearchProducts()
  }

  handleSelectTile (values) {
    this.handleLoadFormValues(values)
    this.props.handleShowLeapModal()
  }

  render () {
    return (
      <Products
        {...this.props}
        handleSelectTile={this.handleSelectTile}
        handleLoadFormValues={this.handleLoadFormValues}
      />
    )
  }
}

const showMaxPassengersModalSelector = elementVisibleSelector(MAX_PASSENGER_MODAL)
const showDifferentPassengersNumberModalSelector = elementVisibleSelector(DIFFERENT_PASSENGERS_NUMBER_MODAL)
const showCartValueZeroModalSelector = elementVisibleSelector(CART_VALUE_ZERO_MODAL)
const showBeforeDayCurrentMonthSelector = elementVisibleSelector(BEFORE_DAY_CURRENT_MONTH_MODAL)
const showOneLeapProductBookingModalSelector = elementVisibleSelector(ONE_LEAP_PRODUCT_BOOKING_MODAL)
const showLeapOptionCheckBalanceModalSelector = elementVisibleSelector(LEAP_OPTION_CHECK_BALANCE_MODAL)

const mapStateToProps = state => ({
  defaultOriginStation: machineStationSelector(state),
  stations: stationsSelector(state),
  productFamilies: productFamiliesSelector(state),
  isLoading: (
    state.api.v2.loading.getProductFamilies ||
    state.api.loading.getStations ||
    state.machine.stationInformation.isLoading
  ),
  isLoadingRepeatLastBooking: state.containers.tickets.repeatLastBooking.isLoading,
  showMaxPassengersModal: showMaxPassengersModalSelector(state),
  maxPassengers: maxPersonsSelector(state),
  showDifferentPassengersModal: showDifferentPassengersNumberModalSelector(state),
  showBeforeDayCurrentMonth: showBeforeDayCurrentMonthSelector(state),
  showCartValueZero: showCartValueZeroModalSelector(state),
  showOneLeapProductBooking: showOneLeapProductBookingModalSelector(state),
  showLeapOptionCheckBalance: showLeapOptionCheckBalanceModalSelector(state),
  beforeDayCurrentMonth: onlyBookableBeforeDayOfCurrentMonthSelector(state),
  paramProductFamily: currentQuerySelector(state).productFamily || currentQuerySelector(state).product_family
})

const mapDispatchToProps = {
  getProductFamilies,
  handleShowLeapModal,
  handleSearchProducts,
  getStations,
  getMachineStationInformation,
  loadFormValues: values => loadValues(FORM_PRODUCT_SEARCH, values),
  validateShortcuts
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsContainer)
