import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../text-label/text-label'
import './legend.scss'

export default class Legend extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }

  render () {
    return (
      <legend>
        <TextLabel name={`${this.props.name}`} text={this.props.text} />
      </legend>
    )
  }
}
