import PropTypes from 'prop-types'
import React, { Component } from 'react'
import connectFormContainer from '../../base/form'
import { FORM_FIND_CUSTOMERS } from 's3p-js-lib/src/constants-form'
import { CATEGORY_LAST_NAME, CATEGORY_EMAIL_ADDRESS } from 's3p-js-lib/src/forms/find-customers'
import kebabCase from 'lodash/kebabCase'
import FindCustomersForm from '../../../components/reservations/booking-details/find-customers/find-customers-form'

const SEARCH_CATEGORIES = [
  {
    value: CATEGORY_LAST_NAME,
    text: `booking-details.find-customers-dialog.form.category.options.${kebabCase(CATEGORY_LAST_NAME)}`
  },
  {
    value: CATEGORY_EMAIL_ADDRESS,
    text: `booking-details.find-customers-dialog.form.category.options.${kebabCase(CATEGORY_EMAIL_ADDRESS)}`
  }
]

class FindCustomersContainer extends Component {
  static propTypes = {
    form: PropTypes.shape({
      loadFormValues: PropTypes.func.isRequired,
      fields: PropTypes.shape({
        term: PropTypes.object.isRequired,
        category: PropTypes.object.isRequired
      }).isRequired,
      valid: PropTypes.bool.isRequired
    }).isRequired,
    findCustomers: PropTypes.func.isRequired,
    resetCustomers: PropTypes.func.isRequired
  }

  constructor (...args) {
    super(...args)
    this.handleFindCustomers = this.handleFindCustomers.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.handleClearTerms = this.handleClearTerms.bind(this)
  }

  handleFindCustomers () {
    const {form, findCustomers} = this.props
    if (form.valid) {
      findCustomers({[form.fields.category.value]: form.fields.term.value})
    }
  }

  handleReset () {
    this.props.form.fields.term.onChange('')
    this.props.form.fields.category.onChange(CATEGORY_LAST_NAME)
    this.props.resetCustomers()
  }

  handleClearTerms () {
    this.props.form.fields.term.onChange('')
  }

  render () {
    return <FindCustomersForm
      onReset={this.handleReset}
      onFindCustomers={this.handleFindCustomers}
      onClearTerms={this.handleClearTerms}
      searchCategories={SEARCH_CATEGORIES}
      {...this.props}
    />
  }
}

export default connectFormContainer({formName: FORM_FIND_CUSTOMERS})(FindCustomersContainer)
