import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ServiceProperty from './service-property'

export default class ServiceProperties extends Component {
  static propTypes = {
    serviceProperties: PropTypes.array
  }

  render () {
    if (!this.props.serviceProperties || !this.props.serviceProperties.length) {
      return null
    }

    return (
      <div className='journey-meta--service-features'>
        <div className='feature-icons'>
          <ul>
            {this.props.serviceProperties.map(icon => <ServiceProperty key={icon.code} code={icon.code} description={icon.description} />)}
          </ul>
        </div>
      </div>
    )
  }
}
