import { API_ADD_VOUCHER_SUCCESS, CLEAR_STATE } from '../../../../actions/types'
import { FORM_TYPE_REDEEM_VOUCHER } from '../../../../../constants-form'

export const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case API_ADD_VOUCHER_SUCCESS:
      return {
        ...state,
        fields: {
          ...state.fields,
          voucherCode: {
            ...state.fields.voucherCode,
            value: '',
            touched: false
          }
        }
      }
    case CLEAR_STATE:
      return action.namespaces.includes(`userInput.form.${FORM_TYPE_REDEEM_VOUCHER}`) ? initialState : state
    default:
      return state
  }
}
