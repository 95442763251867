import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../text-label/text-label'
import '../feedback/feedback.scss'

export default class InputFeedback extends Component {
  static propTypes = {
    name: PropTypes.string,
    valid: PropTypes.bool,
    active: PropTypes.bool,
    touched: PropTypes.bool,
    text: PropTypes.string
  }

  render () {
    return !this.props.active && !this.props.valid && this.props.text && this.props.touched ? (
      <div className='input-feedback state--is-invalid'>
        <TextLabel className='text-label-input-feedback-message' text={this.props.text} />
      </div>
    ) : null
  }
}
