import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { goToErrorPage, routerActions } from '../../../../redux/actions/containers/base/routing'
import { showModal, hideElement } from '../../../../redux/actions/containers/base/visible-element'
import {
  isSeatSelectionValidSelector,
  segmentsSelector,
  selectedSeatsSelector,
  hasSelectedSeatsSelector,
  hasPendingSeatSelectionSelector
} from '../../../../redux/selectors/containers/base/seat-selector/seat-selector'
import {
  passengersSelector,
  seatSelectionOptionsSelector
} from '../../../../redux/selectors/containers/aftersales/seat-change'
import { elementVisibleSelector } from '../../../../redux/selectors/containers/base/visible-element'
import { seatSelectionAvailableOptionsSelector } from '../../../../redux/selectors/containers/base/seat-selector/seat-selection-options'
import ProgressNavigation from '../../../../components/aftersales/change-seats/seat-selector/progress-navigation'
import { changeSeatsSelectSeats } from '../../../../redux/actions/api/aftersales/change-seats/select-seats'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import {
  canOverrideAftersalesRulesSelector,
  overrideValidationRulesSelector
} from '../../../../redux/selectors/api/auth/auth'
import { changeSeatsAllowedSelector } from '../../../../redux/selectors/api/booking/after-sales'

const ABORT_DIALOG_NAME = 'abort-seat-selection'

const _elementVisibleSelector = elementVisibleSelector(ABORT_DIALOG_NAME)

const _isSeatSelectionValidSelector = isSeatSelectionValidSelector(passengersSelector, seatSelectionOptionsSelector)
const availableSegmentsSelector = segmentsSelector(seatSelectionAvailableOptionsSelector(seatSelectionOptionsSelector))
const _selectedSeatsSelector = selectedSeatsSelector(seatSelectionOptionsSelector)
const _hasSelectedSeatsSelector = hasSelectedSeatsSelector(seatSelectionOptionsSelector)
const _changeSeatsAllowedSelector = changeSeatsAllowedSelector(bookingSelector)
const _canOverrideAftersalesRulesSelector = canOverrideAftersalesRulesSelector(bookingSelector)
const _overrideValidationRulesSelector = overrideValidationRulesSelector(bookingSelector)

class ProgressNavigationContainer extends Component {
  static propTypes = {
    segment: PropTypes.shape({
      leg: PropTypes.shape({
        id: PropTypes.any.isRequired
      })
    }),
    previousSegment: PropTypes.object,
    nextSegment: PropTypes.object,
    handleHideAbortDialog: PropTypes.func.isRequired,
    handleShowAbortDialog: PropTypes.func.isRequired,
    handleGoToSegment: PropTypes.func.isRequired,
    handleAbortContinue: PropTypes.func.isRequired,
    handleGoToErrorPage: PropTypes.func.isRequired,
    hasSelectedSeats: PropTypes.bool,
    hasPendingChanges: PropTypes.bool,
    selectedSeats: PropTypes.array.isRequired,
    goForward: PropTypes.func.isRequired,
    confirmSeatSelection: PropTypes.func.isRequired,
    isLoadingUpdateSegment: PropTypes.bool,
    waiveFees: PropTypes.bool,
    overrideAftersalesRules: PropTypes.bool,
    overrideValidationsRules: PropTypes.array
  }

  constructor (...args) {
    super(...args)

    this.abortOrShowDialog = this.abortOrShowDialog.bind(this)
    this.abortAndGoToMainFlow = this.abortAndGoToMainFlow.bind(this)
    this.confirmAndGoTo = this.confirmAndGoTo.bind(this)
    this.goToSegmentOrMainFlow = this.goToSegmentOrMainFlow.bind(this)
    this.proceed = this.proceed.bind(this)
    this.goBack = this.goBack.bind(this)
  }

  abortOrShowDialog () {
    if (this.props.hasSelectedSeats) {
      this.props.handleShowAbortDialog()
    } else {
      this.abortAndGoToMainFlow()
    }
  }

  abortAndGoToMainFlow () {
    this.props.handleHideAbortDialog()
    this.goToSegmentOrMainFlow()
  }

  confirmAndGoTo (segment) {
    this.props.confirmSeatSelection(
      this.props.segment.leg.id,
      this.props.selectedSeats,
      this.props.waiveFees,
      this.props.overrideAftersalesRules,
      this.props.overrideValidationsRules
    ).then(success => {
      if (success) {
        this.goToSegmentOrMainFlow(segment)
      } else {
        this.props.handleGoToErrorPage()
      }
    })
  }

  goToSegmentOrMainFlow (segment) {
    if (segment) {
      this.props.handleGoToSegment(segment.leg.id)
    } else {
      this.props.goForward()
    }
  }

  proceed () {
    if (this.props.hasPendingChanges) {
      this.confirmAndGoTo(this.props.nextSegment)
    } else {
      this.goToSegmentOrMainFlow(this.props.nextSegment)
    }
  }

  goBack () {
    if (this.props.hasPendingChanges) {
      this.confirmAndGoTo(this.props.previousSegment)
    } else {
      this.goToSegmentOrMainFlow(this.props.previousSegment)
    }
  }

  render () {
    return (
      <ProgressNavigation
        {...this.props}
        proceed={this.proceed}
        abortOrShowDialog={this.abortOrShowDialog}
        cancelAbort={this.props.handleHideAbortDialog}
        abortAndGoToMainFlow={this.abortAndGoToMainFlow}
        confirmAndGoTo={this.confirmAndGoTo}
        goToSegmentOrMainFlow={this.goToSegmentOrMainFlow}
        goBack={this.goBack}
        onAbort={this.props.handleShowAbortDialog}
        onAbortContinue={this.props.handleAbortContinue}
        isLoadingUpdateSegment={this.props.isLoadingUpdateSegment}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const passengers = passengersSelector(state, ownProps)
  const selectedSeats = _selectedSeatsSelector(state, ownProps)
  const segments = availableSegmentsSelector(state, ownProps)
  const segmentIndex = segments.findIndex(segment => segment.leg.id === ownProps.segmentId)
  const segment = segmentIndex >= 0 ? segments[segmentIndex] : null
  const nextSegment = segmentIndex >= 0 && (segmentIndex + 1) < segments.length ? segments[segmentIndex + 1] : null
  const previousSegment = segmentIndex >= 1 ? segments[segmentIndex - 1] : null

  return {
    segment,
    previousSegment,
    nextSegment,
    selectedSeats,
    selectionsLeft: passengers.length - selectedSeats.length,
    isValid: _isSeatSelectionValidSelector(state, ownProps),
    isLoading: state.api.loading.changeSeatsSelectSeats,
    hasPendingChanges: hasPendingSeatSelectionSelector(state, ownProps),
    hasSelectedSeats: _hasSelectedSeatsSelector(state, ownProps),
    isLoadingUpdateSegment: state.api.loading.updateSegment,
    showAbortDialog: _elementVisibleSelector(state),
    waiveFees: state.userInput.aftersales.waiveFees,
    overrideAftersalesRules: !_changeSeatsAllowedSelector(state) && _canOverrideAftersalesRulesSelector(state),
    overrideValidationsRules: _overrideValidationRulesSelector(state)
  }
}

const mapDispatchToProps = (_, ownProps) => dispatch => ({
  confirmSeatSelection: (segmentId, selectedSeats, waiveFees, overrideAftersalesRules, overrideValidationsRules) =>
    dispatch(changeSeatsSelectSeats(segmentId, selectedSeats, waiveFees, overrideAftersalesRules, overrideValidationsRules)),
  handleGoToSegment: legId => dispatch(routerActions.push(ownProps.segmentUrl.replace(/:segmentId\b/gi, legId))),
  handleAbortContinue: () => dispatch([routerActions.push('/aftersales/change-seats'), hideElement(ABORT_DIALOG_NAME)]),
  handleShowAbortDialog: () => dispatch(showModal(ABORT_DIALOG_NAME)),
  handleHideAbortDialog: () => dispatch(hideElement(ABORT_DIALOG_NAME)),
  handleGoToErrorPage: () => dispatch(goToErrorPage())
})

export default connect(mapStateToProps, mapDispatchToProps)(ProgressNavigationContainer)
