import { connect } from 'react-redux'
import LeapModal from './leap-modal'
import { leapAddProduct } from '../../../redux/actions/containers/leap/leap-add-product'

const mapStateToProps = state => {
  const addProduct = state.containers.leap.addProduct
  const isLoading = addProduct.loading
  const addProductError = addProduct.error
  const addProductSuccess = addProduct.success

  return {
    isLoading,
    leapActionError: addProductError,
    leapActionSuccess: addProductSuccess
  }
}

const mapDispatchToProps = {
  handleLeapAction: leapAddProduct
}

export default connect(mapStateToProps, mapDispatchToProps)(LeapModal)
