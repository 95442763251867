import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import sortBy from 'lodash/sortBy'
import classNames from 'classnames'
import OriginDestinationBlock from '../../../../elements/origin-destination-block/origin-destination-block'
import TextLabel from '../../../../elements/text-label/text-label'
import Label from '../../../../elements/label/label'
import Segment from './segment'
import { PASSENGER_TYPE_ADULT, SALES_PRESENTATION_ONCE_PER_JOURNEY } from 's3p-js-lib/src/constants'

const passengerLines = ({passengers, priceSpecification, disabled, onUpdateProduct, isQuantityMaximumAvailable}) =>
  passengers.map((passengerPrice, index) =>
    <PassengerPriceLine
      passengerPrice={passengerPrice}
      onChange={() => onUpdateProduct(
        priceSpecification,
        passengerPrice.uniqueIds || passengerPrice.uniqueId,
        passengerPrice.quantity > 0 ? 0 : 1
      )}
      disabled={(isQuantityMaximumAvailable && passengerPrice.quantity === 0) || disabled}
      key={index} />
  )

class PriceSpecificationBox extends Component {
  static propTypes = {
    salesPresentation: PropTypes.string,
    priceSpecification: PropTypes.shape({
      passengers: PropTypes.arrayOf(PropTypes.shape({
        uniqueId: PropTypes.string,
        uniqueIds: PropTypes.array,
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
        passengerNumber: PropTypes.number.isRequired,
        passenger: PropTypes.object
      })).isRequired
    }).isRequired,
    disabled: PropTypes.bool.isRequired,
    onUpdateProduct: PropTypes.func.isRequired,
    isQuantityMaximumAvailable: PropTypes.bool
  }

  render () {
    const {salesPresentation, priceSpecification, disabled, onUpdateProduct, isQuantityMaximumAvailable} = this.props
    const origin = priceSpecification.origin
    const destination = priceSpecification.destination
    const passengers = sortBy(priceSpecification.passengers, 'passengerNumber')
    const props = {passengers, priceSpecification, disabled, onUpdateProduct, isQuantityMaximumAvailable}
    return (salesPresentation === SALES_PRESENTATION_ONCE_PER_JOURNEY
      ? <Segment>{passengerLines(props)}</Segment>
      : <OriginDestinationBlock
        originName={origin.name}
        destinationName={destination.name}
        departureMoment={origin.departureTime}
        arrivalMoment={destination.arrivalTime}
      >
        {passengerLines(props)}
      </OriginDestinationBlock>
    )
  }
}

class PassengerPriceLine extends Component {
  static propTypes = {
    passengerPrice: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func
  }

  render () {
    const {passengerPrice, disabled, onChange} = this.props
    const checked = passengerPrice.quantity > 0
    const className = classNames('segment-selectable-item', {
      'state--is-available': !disabled,
      'state--is-unavailable': disabled,
      'state--is-selected': checked
    })
    const passengerName = passengerPrice.lastName && passengerPrice.firstName
      ? _t.message('additional-products.passenger-name', passengerPrice)
      : _t.message('additional-products.passenger', {number: passengerPrice.passengerNumber})

    return <div className={className}>
      <div className='segment-selectable-item__type'>
        {passengerPrice.disabled ? (
          <TextLabel name='passenger-number' text={passengerName} />
        ) : (
          <div className='check-radio-wrapper'>
            <input
              type='checkbox'
              name={passengerPrice.uniqueId}
              id={passengerPrice.uniqueId}
              onChange={disabled ? null : onChange}
              disabled={disabled}
              checked={checked}
            />
            <Label
              name='passenger-number' htmlFor={passengerPrice.uniqueId}
              text={passengerName}
            />
          </div>
        )}
        {passengerPrice.type !== PASSENGER_TYPE_ADULT ? <TextLabel
          name='passenger-type'
          text={_t.message('additional-products.passenger-type', passengerPrice)}
        /> : null}
      </div>
      <div className='segment-selectable-item__price'>
        <TextLabel
          name='passenger-price'
          text={
            _t.message(`additional-products.${passengerPrice.disabled ? 'already-booked' : 'passenger-price'}`,
              passengerPrice
            )} />
      </div>
    </div>
  }
}

export default PriceSpecificationBox
