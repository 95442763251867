import {
  leapDataSelector,
  leapProductSelector
} from '../../../selectors/machine/leap'
import { addLeapProduct } from '../../machine/leap'
import {
  addOrUpdateAdditionalDetails,
  LEAP_ADD_PRODUCT_KEY,
  LEAP_TRANSACTION_KEY,
  leapDoubtfulTransaction
} from './leap'
import {
  leapDoubtfulTransactionOccurredSelector,
  checkDoubtfulTransactionMessageSelector,
  readLeapStepsBeforeActionSelector
} from '../../../selectors/containers/base/leap'
import {
  UI_LEAP_ADD_PRODUCT_END,
  UI_LEAP_ADD_PRODUCT_START,
  UI_LEAP_DOUBTFUL_TRANSACTION_CARD_IDENTIFIER
} from '../../types'
import { readLeapCard } from './leap-read'
import {
  LEAP_ERROR_MESSAGE_ADD_PRODUCT_FAILED,
  LEAP_ERROR_MESSAGE_INVALID_PRODUCT_CONFIGURATION,
  LEAP_ERROR_MESSAGE_LEAP_DOUBTFUL_TRANSACTION,
  LEAP_ERROR_MESSAGE_INVALID_PROFILE_RESTRICTIONS,
  LEAP_ERROR_MESSAGE_PURSE_IS_BLOCKED,
  LEAP_TIMEOUT
} from '../../../../constants'
import {
  addAdditionalDetails,
  updateAdditionalDetails
} from 's3p-js-lib/src/redux/actions/api/v2/additional-details'
import {
  bookingNumberSelector,
  completedBookingSelector,
  overviewBookingSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { getBooking } from 's3p-js-lib/src/redux/actions/api/booking/booking'
import { productsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/products'
import { additionalDetailValueSelector } from 's3p-js-lib/src/redux/selectors/api/booking/additional-details'

export const addProductLeapStart = () => ({type: UI_LEAP_ADD_PRODUCT_START})
export const addProductLeapEnd = error => ({type: UI_LEAP_ADD_PRODUCT_END, error})
export const cardUsedForDoubtfulTransaction = cardIdentifier => ({
  type: UI_LEAP_DOUBTFUL_TRANSACTION_CARD_IDENTIFIER,
  cardIdentifier
})

const LEAP_PASN_KEY = 'PASN'

export const leapAddProduct = () => async (dispatch, getState) => {
  dispatch(addProductLeapStart())
  await dispatch(readLeapCard())

  let state = getState()
  let errorMessage = readLeapStepsBeforeActionSelector(state)
  if (!errorMessage || errorMessage === LEAP_ERROR_MESSAGE_PURSE_IS_BLOCKED) {
    const data = leapDataSelector(state)
    const product = leapProductSelector(state)
    if (product.Type && product.Class) {
      const noSelectionCardProfileRestriction = (
        product.CardProfileRestrictions &&
        product.CardProfileRestrictions.length === 1 &&
        product.CardProfileRestrictions.includes('0')
      )

      if (data.cardProfile.code && (
        !product.CardProfileRestrictions ||
          !product.CardProfileRestrictions.length ||
          noSelectionCardProfileRestriction ||
          product.CardProfileRestrictions.includes(data.cardProfile.code.toString())
      )
      ) {
        errorMessage = leapDoubtfulTransactionOccurredSelector(state)
        if (!errorMessage) {
          let result
          if (
            state.containers.leap.doubtFulTransaction.cardIdentifier === data.cardIdentifier &&
            data.products.some(leapProduct => leapProduct.typeCode === product.Type)
          ) {
            result = true
          } else {
            result = await dispatch(addLeapProduct(LEAP_TIMEOUT, data.cardIdentifier, product))
          }

          state = getState()
          const addProduct = state.machine.leap.addProduct
          const transaction = addProduct && addProduct[LEAP_TRANSACTION_KEY]
          if (transaction) {
            const transactionData = transaction.split('|')

            const bookingNumber = bookingNumberSelector(completedBookingSelector)(state)
            if (await dispatch(getBooking(bookingNumber))) {
              const products = productsSelector(completedBookingSelector)(state)
              const additionalDetail = [{
                key: LEAP_PASN_KEY,
                value: transactionData.length > 24 ? transactionData[25] : '',
                itemRefId: products[0].itemRef
              }]

              if (additionalDetailValueSelector(overviewBookingSelector, LEAP_PASN_KEY)(getState())) {
                await dispatch(updateAdditionalDetails(bookingNumber, additionalDetail))
              } else {
                await dispatch(addAdditionalDetails(bookingNumber, additionalDetail))
              }
            }
            await dispatch(addOrUpdateAdditionalDetails(addProduct, result, LEAP_ADD_PRODUCT_KEY))
          }
          if (checkDoubtfulTransactionMessageSelector(getState())) {
            await dispatch(leapDoubtfulTransaction())
            errorMessage = LEAP_ERROR_MESSAGE_LEAP_DOUBTFUL_TRANSACTION
          } else if (!result) {
            errorMessage = LEAP_ERROR_MESSAGE_ADD_PRODUCT_FAILED
          }
        }
      } else {
        errorMessage = LEAP_ERROR_MESSAGE_INVALID_PROFILE_RESTRICTIONS
      }
    } else {
      errorMessage = LEAP_ERROR_MESSAGE_INVALID_PRODUCT_CONFIGURATION
    }
  }

  dispatch(addProductLeapEnd(errorMessage))
}
