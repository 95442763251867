import {deepConvertValues} from '@s3p-js-deep-purple/utils'
import {camelCase, isNumber, isString} from 'lodash'

const MASK_PROPERTY_KEY_LIST = [
  'address',
  'addresses',
  'agentUserName',
  'bankAccount',
  'birthDate',
  'city',
  'code', // https://confluence.sqills.com/S3PDOC/latest/post-voucher-create-v2-101429877.html
  'creditCard',
  'crmCustomerId',
  'currentPassword',
  'cvc',
  'data', // https://confluence.sqills.com/S3PDOC/latest/put-booking-ticket-data-115349266.html
  'dateOfBirth',
  'discountCard',
  'discountCards',
  'email',
  'encryptedCardNumber',
  'encryptedData',
  'encryptedExpiryMonth',
  'encryptedExpiryYear',
  'encryptedSecurityCode',
  'externalCardReference',
  'firstName',
  'holder', // https://confluence.sqills.com/S3PDOC/latest/entity-bank-account-69298611.html
  'houseNumber',
  'identifier',
  'lastName',
  'nationality',
  'number', // https://confluence.sqills.com/S3PDOC/latest/entity-bank-account-69298611.html
  'passengerCards',
  'password',
  'paymentRefId',
  'paymentReference',
  'paymentSession',
  'paymentTokenReference',
  'phoneNumber',
  'phoneNumbers',
  'postalCode',
  'pspPaymentDetails',
  'reference',
  'street',
  'ticketNumber',
  'ticketNumbers',
  'title',
  'upgradeToken',
  'username',
  'value', // https://confluence.sqills.com/S3PDOC/latest/put-user-crm-69301921.html
  'voucherCode',
  'voucherCodes', // https://confluence.sqills.com/S3PDOC/latest/post-user-crm-favorite-vouchers-72713577.html
  'accessToken',
  'refreshToken'
]

const maskValue = privatePropertiesList => (key, value) => {
  const _key = camelCase(key)
  // Fully mask passwords
  if (/password/i.test(_key)) {
    return '[Filtered]'
  }
  // Don't mask if not in list
  if (!privatePropertiesList.includes(_key)) {
    return value
  }
  // Show first and last letter of strings.
  // Always convert to an 8 letter string, no matter the length.
  if (value !== '' && (isString(value) || isNumber(value))) {
    const _value = value.toString()
    const first = _value[0]
    const last = _value[_value.length - 1]
    return `${first.padEnd(7, 'X')}${last}`
  }
  // Others (booleans, objects, arrays) are completely filtered
  return '[Filtered]'
}

export const maskData = deepConvertValues(
  maskValue(MASK_PROPERTY_KEY_LIST)
)
