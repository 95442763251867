import Validator from './validator'

export default {
  fields: {
    username: {
      validators: [
        Validator.isRequired
      ]
    },
    firstName: {
      validators: [
        Validator.isRequired
      ]
    },
    lastName: {
      validators: [
        Validator.isRequired
      ]
    },
    email: {
      validators: [
        Validator.isRequired,
        Validator.isEmail
      ]
    },
    contracts: {},
    offices: {},
    agentRoles: {
      validators: [
        Validator.isNonEmptyArray
      ]
    }
  }
}
