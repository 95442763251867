import { createSelector } from 'reselect'
import { camelCaseKeys } from '../../../../misc/utils'

export const productFamiliesSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking && booking.product_families && booking.product_families.map(camelCaseKeys)
)

export const getProductFamily = (productFamilies, id) => Array.isArray(productFamilies) && id && camelCaseKeys(
  productFamilies.find(productFamily => productFamily.id === id)
)

export const outboundProductFamilySelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking && getProductFamily(booking.product_families, booking.outbound_product_family_id)
)

export const inboundProductFamilySelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking && getProductFamily(booking.product_families, booking.inbound_product_family_id)
)
