/* globals localStorage */

module.exports = {

  set (key, value) {
    if (typeof value === 'undefined' || value === null) {
      this.clear(key)
      return
    }

    if (this._localStorageExists()) {
      localStorage.setItem(key, JSON.stringify(value))
    }
  },

  clear (key) {
    if (this._localStorageExists()) {
      localStorage.removeItem(key)
    }
  },

  has (key) {
    return this._localStorageExists() && localStorage.getItem(key) !== null
  },

  get (key, defaultValue) {
    if (this._localStorageExists()) {
      let value = localStorage.getItem(key)
      if (value !== null) {
        try {
          value = JSON.parse(value)
        } catch (error) {
          value = null
        }
      }

      return value !== null ? value : defaultValue
    }
  },

  _localStorageExists () {
    return typeof localStorage === 'object' && localStorage !== null
  }
}
