import { connect } from 'react-redux'
import { DEVICE_ID_RECEIPT_PRINTER, SHIFT_MODAL } from '../../../constants'
import { showModal } from '../../../redux/actions/containers/base/visible-element'
import { routerActions } from '../../../redux/actions/containers/base/routing'
import { agentShiftSelector } from 's3p-js-lib/src/redux/selectors/api/user/agent/shift'
import { isOfflineSelectorCreator } from '../../../redux/selectors/machine/offline'
import { willPasswordExpireSoon } from '../../../redux/selectors/containers/agent/shift/shift'
import End from '../../../components/agent/shift/end'
import { hasValidLeapConfiguration } from '../../../misc/storage'

const receiptPrinterOfflineSelector = isOfflineSelectorCreator(DEVICE_ID_RECEIPT_PRINTER)

const mapStateToProps = state => {
  const shift = agentShiftSelector(state)
  return {
    isOfflineReceiptPrinter: receiptPrinterOfflineSelector(state),
    deviceId: shift && shift.deviceId ? shift.deviceId : null,
    startDateTime: shift ? shift.startDateTime : null,
    hasValidLeapConfiguration: hasValidLeapConfiguration(),
    willPasswordExpireSoon: willPasswordExpireSoon(state)
  }
}

const mapDispatchToProps = {
  onEndShift: () => showModal(SHIFT_MODAL),
  onLogout: () => routerActions.push('/agent/login')
}

export default connect(mapStateToProps, mapDispatchToProps)(End)
