import {
  STORAGE_ITEM_NAME_LEAP_CONFIGURATION_DATA,
  STORAGE_ITEM_NAME_LEAP_SCHEME_WIDE_CODE
} from '../constants'
import localStorage from 's3p-js-lib/src/local-storage'

export const hasValidLeapConfiguration = () => (
  localStorage.has(STORAGE_ITEM_NAME_LEAP_CONFIGURATION_DATA) &&
  localStorage.has(STORAGE_ITEM_NAME_LEAP_SCHEME_WIDE_CODE)
)

