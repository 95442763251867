export const UI_PAYMENT_ERROR = 'UI_PAYMENT_ERROR'
export const UI_TABLES_SORT = 'UI_TABLES_SORT'
export const UI_TABLES_CLEAR = 'UI_TABLES_CLEAR'
export const UI_ACCORDION_GROUP_CLOSE_ITEM = 'UI_ACCORDION_GROUP_CLOSE_ITEM'
export const UI_ACCORDION_GROUP_OPEN_ITEM = 'UI_ACCORDION_GROUP_OPEN_ITEM'
export const UI_ELEMENT_SHOW = 'UI_ELEMENT_SHOW'
export const UI_ELEMENT_HIDE = 'UI_ELEMENT_HIDE'
export const UI_ELEMENT_TOGGLE = 'UI_ELEMENT_TOGGLE'
export const UI_ELEMENTS_CLEAR = 'UI_ELEMENTS_CLEAR'
export const UI_SET_BOOKING_DETAILS_FEEDBACK = 'UI_SET_BOOKING_DETAILS_FEEDBACK'
export const UI_AFTERSALES_INITIATE = 'UI_AFTERSALES_INITIATE'
export const UI_AFTERSALES_CONTINUE = 'UI_AFTERSALES_CONTINUE'
export const UI_CLEAR_LAST_API_ERROR = 'UI_CLEAR_LAST_API_ERROR'
