/* globals S3P_SETTINGS: true */
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { routerActions } from '../../redux/actions/containers/base/routing'
import { createAuthSelector } from 's3p-js-lib/src/redux/selectors/api/auth/auth'
import { createAgentRoleAuthSelector } from '../../redux/selectors/api/auth/auth'
import { compose } from 'redux'

const container = WrappedComponent => (class AuthComponent extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    pendingTokenRequest: PropTypes.bool.isRequired,
    handleGoToUnauthorizedPage: PropTypes.func.isRequired
  }

  UNSAFE_componentWillMount () {
    this.checkAuth(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.isAuthenticated !== nextProps.isAuthenticated ||
      this.props.pendingTokenRequest !== nextProps.pendingTokenRequest
    ) {
      this.checkAuth(nextProps)
    }
  }

  checkAuth (props) {
    if ((!props.isAuthenticated && !props.pendingTokenRequest) || props.hasInvalidRoles) {
      this.props.handleGoToUnauthorizedPage()
    }
  }

  render () {
    return this.props.isAuthenticated ? <WrappedComponent {...this.props} /> : null
  }
})

const connectState = options => {
  const isAuthenticatedSelector = createAuthSelector(options)
  const roleAuthSelector = createAgentRoleAuthSelector(options)

  const mapStateToProps = state => ({
    isAuthenticated: isAuthenticatedSelector(state),
    pendingTokenRequest: state.api.loading.agentLogin,
    hasInvalidRoles: roleAuthSelector(state)
  })

  const mapDispatchToProps = {
    handleGoToUnauthorizedPage: () => routerActions.push(S3P_SETTINGS.s3Passenger.features.unauthorizedPages.default)
  }

  return connect(mapStateToProps, mapDispatchToProps)
}

export const authContainer = (options = {}) => compose(
  connectState(options),
  container
)

export default (Component, options = {}) => authContainer(options)(Component)
