import {
  API_GET_MAGSTRIPE_TICKETS_REQUEST,
  API_GET_MAGSTRIPE_TICKETS_SUCCESS,
  API_GET_MAGSTRIPE_TICKETS_FAILURE,
  API_GET_BARCODE_DOCUMENT_REQUEST,
  API_GET_BARCODE_DOCUMENT_SUCCESS,
  API_GET_BARCODE_DOCUMENT_FAILURE,
  API_CONFIRM_MAGSTRIPE_REQUEST,
  API_CONFIRM_MAGSTRIPE_SUCCESS,
  API_CONFIRM_MAGSTRIPE_FAILURE
} from '../../../actions/types'
import { createNewState } from 's3p-js-lib/src/redux/reducers/api/base/last-api-errors/last-api-errors'

export default function lastApiError (state = {}, action) {
  switch (action.type) {
    case API_GET_MAGSTRIPE_TICKETS_REQUEST:
    case API_GET_MAGSTRIPE_TICKETS_SUCCESS:
    case API_GET_MAGSTRIPE_TICKETS_FAILURE:
      return createNewState('getMagstripeTickets', state, action)
    case API_CONFIRM_MAGSTRIPE_REQUEST:
    case API_CONFIRM_MAGSTRIPE_SUCCESS:
    case API_CONFIRM_MAGSTRIPE_FAILURE:
      return createNewState('confirmMagstripe', state, action)
    case API_GET_BARCODE_DOCUMENT_REQUEST:
    case API_GET_BARCODE_DOCUMENT_SUCCESS:
    case API_GET_BARCODE_DOCUMENT_FAILURE:
      return createNewState('getBarcodeDocument', state, action)
    default:
      return state
  }
}
