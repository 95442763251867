import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../../elements/text-label/text-label'
import DescriptionList from '../../../elements/description-list/description-list'
import _t from 's3p-js-lib/src/translate'

const LEAP_PRODUCT_DATA = 'leap.products.data'

export default class LeapProductData extends Component {
  static propTypes = {
    product: PropTypes.object.isRequired
  }

  render () {
    const {product} = this.props
    return (
      <div className='description-list-group'>
        <div className='description-list-group__column'>
          <div className='description-list-block'>
            <div className='description-list-block__content'>
              <DescriptionList>
                <dt><TextLabel text={_t.message(`${LEAP_PRODUCT_DATA}.class`)} /></dt>
                <dd><TextLabel text={`${product.class}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_PRODUCT_DATA}.type`)} /></dt>
                <dd><TextLabel text={`${product.type}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_PRODUCT_DATA}.usn`)} /></dt>
                <dd><TextLabel text={`${product.usn}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_PRODUCT_DATA}.owner-id`)} /></dt>
                <dd><TextLabel text={`${product.ownerId}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_PRODUCT_DATA}.soac`)} /></dt>
                <dd><TextLabel text={`${product.soac}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_PRODUCT_DATA}.issue-date-time`)} /></dt>
                <dd><TextLabel text={product.issueDateTime && product.issueDateTime.format('L LT')} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_PRODUCT_DATA}.expiry-date-time`)} /></dt>
                <dd><TextLabel text={product.expiryDateTime && product.expiryDateTime.format('L LT')} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_PRODUCT_DATA}.start-date-time`)} /></dt>
                <dd><TextLabel text={product.startDateTime && product.startDateTime.format('L LT')} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_PRODUCT_DATA}.status`)} /></dt>
                <dd><TextLabel text={`${product.status}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_PRODUCT_DATA}.block-date-time`)} /></dt>
                <dd><TextLabel text={product.blockDateTime && product.blockDateTime.format('L LT')} /></dd>
              </DescriptionList>
            </div>
          </div>
        </div>
        <div className='description-list-group__column'>
          <div className='description-list-block'>
            <div className='description-list-block__content'>
              <DescriptionList>
                <dt><TextLabel text={_t.message(`${LEAP_PRODUCT_DATA}.activation-date-time`)} /></dt>
                <dd><TextLabel text={product.activationDateTime && product.activationDateTime.format('L LT')} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_PRODUCT_DATA}.journey-balance`)} /></dt>
                <dd><TextLabel text={`${product.journeyBalance}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_PRODUCT_DATA}.issue-participant-id`)} /></dt>
                <dd><TextLabel text={`${product.issueParticipantId}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_PRODUCT_DATA}.price`)} /></dt>
                <dd><TextLabel text={`${_t.formatCurrency(product.price)}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_PRODUCT_DATA}.payment-method`)} /></dt>
                <dd><TextLabel text={`${product.paymentMethod}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_PRODUCT_DATA}.origin`)} /></dt>
                <dd><TextLabel text={`${product.origin}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_PRODUCT_DATA}.destination`)} /></dt>
                <dd><TextLabel text={`${product.destination}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_PRODUCT_DATA}.route`)} /></dt>
                <dd><TextLabel text={`${product.route}`} /></dd>
                <dt><TextLabel text={_t.message(`${LEAP_PRODUCT_DATA}.service`)} /></dt>
                <dd><TextLabel text={`${product.service}`} /></dd>
              </DescriptionList>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
