import React from 'react'
import { Route } from 'react-router'
import { authContainer } from '../../containers/base/auth'
import ChangeFulfillmentLayout from '../../layouts/aftersales/change-fulfillment'
import { AGENT_ROLE_OPERATOR } from '../../constants'
import checkAccessBySelector from '../../containers/base/check-access-by-selector'
import loadOverviewBooking from '../../containers/base/load-overview-booking'
import { compose } from 'redux'
import { isChangeFulfillmentAvailableSelector } from '../../redux/selectors/containers/base/aftersales'
import { overviewBookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { goToCurrentAftersalesBooking } from '../../redux/actions/containers/base/aftersales'

const container = compose(
  authContainer({roles: [AGENT_ROLE_OPERATOR]}),
  loadOverviewBooking,
  checkAccessBySelector({
    selector: isChangeFulfillmentAvailableSelector(overviewBookingSelector),
    onNotAllowed: goToCurrentAftersalesBooking
  })
)

export default (
  <Route
    key='aftersales-change-fulfillment'
    path='/:lang/aftersales/change-fulfillment/:bookingNumber'
    component={container(ChangeFulfillmentLayout)}
  />
)
