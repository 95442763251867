import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TileSet from '../../../elements/tile-set/tile-set'
import Passenger from '../../../containers/base/seat-selector/passenger-manifest/passenger'

export default class PassengerManifest extends Component {
  static propTypes = {
    passengers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired
    })),
    aftersales: PropTypes.bool
  }

  render () {
    return (
      <div className='passenger-selector-container'>
        <div className='passenger-selector'>
          <TileSet>
            {this.props.passengers.map(passenger =>
              <Passenger key={passenger.id} aftersales={this.props.aftersales} id={passenger.id} />
            )}
          </TileSet>
        </div>
      </div>
    )
  }
}
