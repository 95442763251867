import { carriagesSelector } from 's3p-js-lib/src/redux/selectors/api/base/seat-selector/carriage-layouts'
import {
  passengersSelector,
  selectedPassengerSelector,
  carriageSelector,
  selectedPassengerIdSelector
} from 's3p-js-lib/src/redux/selectors/user-input/base/seat-selector'
import { selectPassenger } from 's3p-js-lib/src/redux/actions/user-input/base/seat-selector'
import { selectCarriage } from '../../../user-input/base/seat-selector'

const _carriageSelector = carriageSelector()

export const selectFirstPassenger = (aftersales = false) => (dispatch, getState) => {
  const passengers = passengersSelector(getState(), {aftersales})
  if (passengers.length > 0) {
    dispatch(selectPassenger(passengers[0].id))
  }
}

export const selectNextPassenger = (aftersales = false) => (dispatch, getState) => {
  const passengers = passengersSelector(getState(), {aftersales})
  const currentPassengerId = selectedPassengerIdSelector(getState())

  const nextPassengerIndex = passengers.findIndex(passenger => passenger.id === currentPassengerId) + 1

  if (passengers[nextPassengerIndex]) {
    dispatch(selectPassenger(passengers[nextPassengerIndex].id))
  }
}

export const selectFirstCarriage = (aftersales = false) => (dispatch, getState) => {
  const carriages = carriagesSelector(getState())
  const passenger = selectedPassengerSelector(getState(), {aftersales})

  let carriageNumber = passenger && passenger.seat && passenger.seat.carriageNumber
  if (!carriageNumber || !isCarriageAvailable(getState(), carriageNumber, aftersales)) {
    const firstAvailableCarriage = carriages.find(carriage => {
      if (carriage.blocked || carriage.seats.length === 0) {
        return false
      }

      return isCarriageAvailable(getState(), carriage.carriageNumber)
    })
    carriageNumber = firstAvailableCarriage && firstAvailableCarriage.carriageNumber
  }

  carriageNumber && dispatch(selectCarriage(carriageNumber))
}

const isCarriageAvailable = (state, carriageNumber, aftersales = false) => {
  const carriage = _carriageSelector(state, {carriageNumber, aftersales})
  return carriage && carriage.available
}
