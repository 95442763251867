import {
  API_REBOOKING_JOURNEY_SEARCH_SUCCESS,
  API_OAUTH_LOGOUT,
  CLEAR_STATE
} from '../../../../actions/types'
import { FORM_TYPE_REBOOK_JOURNEY_SEARCH } from '../../../../../constants-form'
import { toUtcDateMoment } from '../../../../../misc/date'

export const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case API_OAUTH_LOGOUT:
      return initialState
    case API_REBOOKING_JOURNEY_SEARCH_SUCCESS: {
      const journeySearch = action.response.data.journey_search
      const newJourneySearch = {
        ...state,
        fields: {
          ...state.fields
        }
      }
      if (journeySearch.return_date) {
        newJourneySearch.fields.inboundDate = {
          ...state.fields.inboundDate,
          value: toUtcDateMoment(journeySearch.return_date)
        }
      }
      if (journeySearch.departure_date) {
        newJourneySearch.fields.outboundDate = {
          ...state.fields.outboundDate,
          value: toUtcDateMoment(journeySearch.departure_date)
        }
      }
      return newJourneySearch
    }
    case CLEAR_STATE:
      return action.namespaces.includes(`userInput.form.${FORM_TYPE_REBOOK_JOURNEY_SEARCH}`) ? initialState : state
    default:
      return state
  }
}
