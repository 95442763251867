import {
  API_OAUTH_LOGOUT,
  API_OAUTH_REFRESH_TOKEN_FAILURE,
  API_LOAD_USER_INFORMATION_SUCCESS,
  API_LOAD_USER_INFORMATION_FAILURE,
  CLEAR_STATE
} from '../../../actions/types'
import storage from '../../../../storage'

export const initialState = null

const defaultState = storage.get('user_information') || initialState

export default function userInformation (state = defaultState, action) {
  switch (action.type) {
    case API_LOAD_USER_INFORMATION_SUCCESS:
      storage.set('user_information', action.response)
      return action.response
    case API_OAUTH_REFRESH_TOKEN_FAILURE:
    case API_LOAD_USER_INFORMATION_FAILURE:
    case API_OAUTH_LOGOUT:
      storage.clear('user_information')
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes('api.user.user') ? initialState : state
    default:
      return state
  }
}
