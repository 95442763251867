import { createSelector } from 'reselect'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import map from 'lodash/map'
import {
  additionalProductsSelector as baseAdditionalProductsSelector
} from 's3p-js-lib/src/redux/selectors/api/orientation/additional-products'
import { SALES_PRESENTATION_ONCE_PER_JOURNEY } from 's3p-js-lib/src/constants'

const combinePriceSpecifications = priceSpecifications => Object.values(map(
  groupBy(priceSpecifications, 'travelId'),
  (priceSpecifications, travelId) => ({
    travelDirection: priceSpecifications[0].direction,
    travelDate: priceSpecifications[0].departureDate,
    priceSpecifications: priceSpecifications.map(
      ({
        departureStation,
        departureDate,
        arrivalStation,
        arrivalDate,
        passengers,
        legIds,
        ...priceSpecifications
      }) => ({
        ...priceSpecifications,
        legs: legIds,
        origin: departureStation && departureDate
          ? {
            name: departureStation.name,
            departureTime: departureDate
          } : null,
        destination: arrivalStation && arrivalDate
          ? {
            name: arrivalStation.name,
            arrivalTime: arrivalDate
          } : null,
        passengers: passengers.map(passenger => ({
          ...passenger,
          passengerNumber: passenger.number
        }))
      })
    )
  })
))

const salesPresentationProcessor = (additionalProduct, priceSpecifications) => {
  if (additionalProduct.salesPresentation === SALES_PRESENTATION_ONCE_PER_JOURNEY) {
    return priceSpecifications.reduce(
      (groupedList, priceSpec) => {
        if (!groupedList) {
          groupedList.push(priceSpec)
        } else {
          const existingPriceSpec = groupedList.find(
            _priceSpec => _priceSpec.direction === priceSpec.direction
          )
          if (!existingPriceSpec) {
            priceSpec.itemIds = [priceSpec.itemId]
            groupedList.push(priceSpec)

            return groupedList
          }

          existingPriceSpec.itemIds.push(priceSpec.itemId)
          existingPriceSpec.passengers = existingPriceSpec.passengers.reduce(
            (passengers, passenger) => {
              const newPricePassenger = priceSpec.passengers.find(
                _passenger => _passenger.id === passenger.id
              )
              if (newPricePassenger) {
                passengers.push({
                  ...passenger,
                  price: passenger.price + newPricePassenger.price,
                  totalPrice: passenger.totalPrice + newPricePassenger.totalPrice
                })
              }
              return passengers
            },
            []
          )

          return groupedList
        }
      }, []
    )
  }

  return priceSpecifications
}

const calculateLowestPrice = priceSpecifications => Math.min(
  ...priceSpecifications.map(priceSpecification => Math.min(
    ...priceSpecification.passengers.map(({price}) => price)
  ))
)

export const additionalProductsSelector = createSelector(
  [
    baseAdditionalProductsSelector
  ],
  additionalProducts => {
    if (additionalProducts) {
      const products = Object.values(additionalProducts.reduce(
        (groupedAdditionalProducts, {priceSpecifications, ...additionalProduct}) => {
          priceSpecifications = salesPresentationProcessor(additionalProduct, priceSpecifications)
          additionalProduct.lowestPrice = calculateLowestPrice(priceSpecifications)
          const travelPriceSpecifications = combinePriceSpecifications(priceSpecifications)

          if (additionalProduct.id in groupedAdditionalProducts) {
            groupedAdditionalProducts[additionalProduct.id]
              .travelPriceSpecifications.push(...travelPriceSpecifications)
          } else {
            groupedAdditionalProducts[additionalProduct.id] = {
              ...additionalProduct,
              travelPriceSpecifications
            }
          }

          return groupedAdditionalProducts
        },
        {}
      ))
      return sortBy(products, 'name')
    } else { return null }
  }
)
