import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Button from '../../../../elements/button/button'
import Icon from '../../../../elements/icon/icon'
import _t from 's3p-js-lib/src/translate'

export default class Pagination extends Component {
  static propTypes = {
    hasNextResults: PropTypes.bool.isRequired,
    hasPreviousResults: PropTypes.bool.isRequired,
    startJourneySearch: PropTypes.func.isRequired,
    reselectBundles: PropTypes.func.isRequired,
    aftersales: PropTypes.bool.isRequired,
    getNextResults: PropTypes.func.isRequired,
    getPreviousResults: PropTypes.func.isRequired
  }

  constructor (...args) {
    super(...args)
    this.onGetPreviousResults = this.onGetPreviousResults.bind(this)
    this.onGetNextResults = this.onGetNextResults.bind(this)
  }

  async onGetPreviousResults () {
    this.props.startJourneySearch()
    await this.props.getPreviousResults()
    await this.props.reselectBundles()
  }

  async onGetNextResults () {
    this.props.startJourneySearch()
    await this.props.getNextResults()
    await this.props.reselectBundles()
  }

  render () {
    const {hasNextResults, hasPreviousResults} = this.props
    return hasNextResults || hasPreviousResults ? (
      <div className='route-set-results'>
        <div className='button-group'>
          <Button
            name='earlier-results'
            disabled={!hasPreviousResults}
            onClick={this.onGetPreviousResults}
            className='secondary small'
          >
            <Icon name='chevron-up' className='medium align-left' />
            <span className='text'>{_t.message('journey-result-set.pagination.earlier')}</span>
          </Button>
          <Button
            name='later-results'
            disabled={!hasNextResults}
            onClick={this.onGetNextResults}
            className='secondary small'
          >
            <span className='text'>{_t.message('journey-result-set.pagination.later')}</span>
            <Icon name='chevron-down' className='medium align-right' />
          </Button>
        </div>
      </div>
    ) : null
  }
}
