import _t from '../translate'

const responseCodes = {
  INTERNAL_SERVER_ERROR: 0,
  SOAP_SERVICE_UNAVAILABLE: 6,
  UNEXPECTED_SOAP_ERROR: 7,
  REDIS_SERVER_ERROR: 10,
  INVALID_JSON_RESPONSE: 15,
  CMS_SERVICE_UNAVAILABLE: 17,
  UNAUTHORIZED_FOR_OPERATION: 18,

  CRM_REGISTER_EMAIL_EXISTS: 1000,
  CRM_REGISTER_EMAIL_INVALID: 1001,
  USER_OAUTH_AGENT_BAD_CREDENTIALS: 1002,
  USER_OAUTH_BOOKING_BAD_CREDENTIALS: 1003,
  USER_OAUTH_CRM_BAD_CREDENTIALS: 1004,
  USER_OAUTH_AGENT_HAS_NO_CONTRACTS: 1005,
  SALES_CHANNEL_PROPERTIES_INVALID_FORMAT: 1006,
  USER_INVALID_SESSION_ID_FOR_AGENT: 1007,
  USER_AGENT_INVALID_CURRENT_USER: 1008,
  USER_AGENT_INVALID_OFFICE_NAMES: 1009,
  USER_AGENT_INVALID_CONTRACT_CODES: 1010,
  USER_AGENT_INVALID_AGENT_ROLES: 1011,
  USER_AGENT_MISSING_REQUIRED_FIELDS: 1012,
  USER_AGENT_USERNAME_ALREADY_EXISTS: 1013,
  USER_AGENT_USERNAME_NOT_FOUND: 1014,
  USER_AGENT_RESET_PASSWORD_NOT_FOUND: 1015,
  USER_AGENT_CHANGE_PASSWORD_WEAK: 1016,
  USER_OAUTH_AGENT_PASSWORD_BLOCKED: 1017,
  USER_OAUTH_AGENT_INACTIVE: 1018,
  USER_AGENT_CHANGE_PASSWORD_REQUIRES_CURRENT_PASSWORD: 1019,
  USER_OAUTH_STEWARD_BAD_CREDENTIALS: 1020,
  USER_OAUTH_AGENT_HAS_NO_OFFICES: 1021,
  USER_AGENT_NON_EXISTING_CONTRACT: 1022,
  USER_AGENT_NON_EXISTING_OFFICE: 1023,
  USER_AGENT_CHANGE_PASSWORD_WRONG_CURRENT_PASSWORD: 1024,
  USER_AGENT_CHANGE_PASSWORD_PERMISSION_DENIED: 1025,
  CRM_USER_NOT_FOUND: 1026,
  CRM_SECURITY_CODE_INVALID: 1027,
  CRM_UPDATE_EMAIL_ADDRESS_ALREADY_EXISTS: 1030,
  USER_OAUTH_CRM_PASSWORD_BLOCKED: 1032,
  CRM_UPDATE_WRONG_CURRENT_PASSWORD: 1033,
  USER_AGENT_INCORRECT_AGENT_PERMISSIONS: 1036,
  USER_AGENT_SHIFT_ALREADY_STARTED: 1037,
  USER_AGENT_SHIFT_NOT_FOUND: 1038,
  USER_AGENT_HAS_NO_ACTIVE_SHIFT: 1039,
  USER_OAUTH_INVALID_REFRESH_TOKEN: 1049,
  USER_OAUTH_AGENT_PASSWORD_EXPIRED: 1053,
  USER_OAUTH_AGENT_SESSION_EXPIRED: 3180,
  BOOKING_VOUCHER_SALES_CHANNEL_NOT_AUTHORIZED: 3046,

  ORIENTATION_DEEP_LINK_NO_BUNDLE_FOUND: 2007,
  ORIENTATION_DEEP_LINK_NO_OUTBOUND_TRAVEL_FOUND: 2008,
  ORIENTATION_DEEP_LINK_NO_INBOUND_TRAVEL_FOUND: 2009,
  ORIENTATION_DEEP_LINK_NO_ROUTE_FOUND: 2010,
  ORIENTATION_SALES_CHANNEL_REQUIRES_AGENT_AUTHORIZATION: 2012,
  ORIENTATION_SALES_CHANNEL_UNKNOWN: 2013,

  BOOKING_VOUCHER_NOT_FOUND: 3003,
  BOOKING_VOUCHER_NOT_AVAILABLE_TODAY: 3004,
  BOOKING_VOUCHER_NOT_AVAILABLE_FOR_TRAVELLING: 3005,
  BOOKING_VOUCHER_NOT_AVAILABLE_FOR_ANY_SERVICE: 3006,
  BOOKING_VOUCHER_NOT_AVAILABLE_FOR_TOTAL: 3007,
  BOOKING_VOUCHER_IS_USED: 3008,
  BOOKING_VOUCHER_NOT_ALLOWED: 3009,
  BOOKING_VOUCHER_ALREADY_USED: 3010,
  BOOKING_VOUCHER_RESTRICTED: 3011,
  BOOKING_VOUCHER_AMOUNT_NOT_SUFFICIENT: 3012,
  BOOKING_VOUCHER_NOT_AVAILABLE_FOR_TRAVEL_DAY: 3014,
  BOOKING_NOT_FOUND: 3015,
  BOOKING_NOT_FOUND_FOR_BOOKING_NUMBER: 3016,
  BOOKING_VOUCHER_DOES_NOT_EXISTS: 3017,
  BOOKING_NOT_ACCESSIBLE_FOR_CURRENT_USER: 3018,
  BOOKING_FULFILLMENT_METHODS_MISSING: 3021,
  BOOKING_VOUCHER_FULL_AMOUNT_USED: 3025,
  BOOKING_CREATE_UNKNOWN_ERROR: 3026,
  BOOKING_CREATE_NO_AVAILABLE_SEAT_COMBINATION: 3027,
  BOOKING_CREATE_NO_LOGICAL_AVAILABILITY: 3028,
  BOOKING_RESEND_TICKETS_BOOKING_NOT_FOUND: 3029,
  BOOKING_CREATE_INVALID_REQUEST_FOR_SERVICE: 3031,
  BOOKING_CREATE_COULD_NOT_ALLOCATE_SEATS: 3032,
  BOOKING_CREATE_NO_AVAILABLE_CARRIAGES: 3033,
  BOOKING_CREATE_NO_LOGICAL_OR_PHYSICAL_AVAILABILITY: 3034,
  BOOKING_IS_PENDING_IN_ANOTHER_QUEUE: 3035,
  BOOKING_IS_CANCELLED: 3036,
  BOOKING_IS_EXPIRED: 3037,
  BOOKING_VOUCHER_NOT_AVAILABLE_FOR_BOOKING: 3040,
  BOOKING_VOUCHER_NOT_AVAILABLE_IN_BOOKING_CURRENCY: 3041,
  BOOKING_VOUCHER_EMAIL_VALIDATION_FAILED: 3044,
  BOOKING_TICKET_NOT_FOUND: 3048,
  BOOKING_UNABLE_TO_REALLOCATE_SEATS: 3053,
  BOOKING_CREATE_BOOKING_HORIZON_BREACHED: 3054,
  BOOKING_VOUCHER_SALES_TIME_RESTRICTION: 3056,
  BOOKING_VOUCHER_ONLY_FOR_ONE_WAY_TRIP: 3060,
  BOOKING_PROVISION_OF_A_SERVICE_NAME_IS_REQUIRED: 3080,
  BOOKING_TARIFF_REQUIRES_DEPARTURE_STATION: 3086,
  BOOKING_REACHED_RESEND_LIMIT: 3100,
  BOOKING_SEARCH_NOT_SPECIFIC_CUSTOMER: 3163,
  BOOKING_SEARCH_NOT_SPECIFIC_PASSENGER: 3164,
  PAYMENT_NO_REFUND_PAYMENT_FOR_REFUND_VOUCHER: 4003,
  PAYMENT_NO_CUSTOMER_INFORMATION_FOR_REFUND_VOUCHER: 4004,
  PAYMENT_INVALID_ENCRYPTED_DATA: 4008,
  PAYMENT_UNKNOWN_INITIATE_PAYMENT_ERROR: 4009,
  PAYMENT_CARD_TYPE_NOT_SUPPORTED: '4009.7',
  PAYMENT_NOT_FOUND_FOR_REFERENCE: 4014,
  PAYMENT_IS_REFUSED: 4016,
  PAYMENT_IS_CANCELLED: 4017,
  PAYMENT_INVALID_BANK_ACCOUNT_INFORMATION: 4023,
  PAYMENT_NO_ELIGIBLE_PAYMENTS_EXISTS: 4025,

  AFTER_SALES_OPERATION_HAS_INVALID_ITEMS: 5022,

  CMS_INVALID_JSON_RESPONSE: 6001,

  API_DOCUMENT_TEMPLATES_NOT_FOUND: 8001,

  AGENT_CRM_ACCESS_REQUIRED_TO_VIEW_CUSTOMERS: 'agent_crm_access_required_to_view_customers'
}

const messages = {
  [responseCodes.USER_OAUTH_AGENT_BAD_CREDENTIALS]: 'messages.agent-bad-credentials',
  [responseCodes.USER_OAUTH_STEWARD_BAD_CREDENTIALS]: 'messages.agent-bad-credentials',
  [responseCodes.USER_OAUTH_BOOKING_BAD_CREDENTIALS]: 'messages.booking-login-invalid-booking-details',
  [responseCodes.USER_OAUTH_CRM_BAD_CREDENTIALS]: 'messages.crm-bad-credentials',
  [responseCodes.USER_AGENT_USERNAME_ALREADY_EXISTS]: 'messages.agent-with-given-username-already-exists',
  [responseCodes.USER_AGENT_RESET_PASSWORD_NOT_FOUND]: 'messages.agent-reset-password-not-found',
  [responseCodes.USER_AGENT_CHANGE_PASSWORD_WEAK]: 'messages.agent-change-password-weak',
  [responseCodes.USER_AGENT_INCORRECT_AGENT_PERMISSIONS]: 'messages.incorrect-permissions',
  [responseCodes.USER_OAUTH_AGENT_PASSWORD_BLOCKED]: 'messages.agent-blocked-password',
  [responseCodes.USER_OAUTH_AGENT_PASSWORD_EXPIRED]: 'messages.agent-expired-password',
  [responseCodes.USER_OAUTH_AGENT_SESSION_EXPIRED]: 'messages.session-expired',
  [responseCodes.USER_OAUTH_AGENT_INACTIVE]: 'messages.agent-inactive',
  [responseCodes.USER_AGENT_CHANGE_PASSWORD_WRONG_CURRENT_PASSWORD]: 'messages.agent-change-password-wrong-current-password',
  [responseCodes.USER_AGENT_CHANGE_PASSWORD_PERMISSION_DENIED]: 'messages.agent-change-password-permission-denied',
  [responseCodes.CRM_USER_NOT_FOUND]: 'messages.crm-user-not-found',
  [responseCodes.CRM_SECURITY_CODE_INVALID]: 'messages.crm-security-code-invalid',
  [responseCodes.CRM_UPDATE_EMAIL_ADDRESS_ALREADY_EXISTS]: 'messages.crm-update-email-address-already-exists',
  [responseCodes.USER_OAUTH_CRM_PASSWORD_BLOCKED]: 'messages.crm-blocked-password',
  [responseCodes.CRM_UPDATE_WRONG_CURRENT_PASSWORD]: 'messages.crm-change-password-wrong-current-password',
  [responseCodes.BOOKING_VOUCHER_SALES_CHANNEL_NOT_AUTHORIZED]: 'messages.voucher-not-authorised-for-sales-channel',
  [responseCodes.USER_INVALID_SESSION_ID_FOR_AGENT]: 'messages.session-expired',
  [responseCodes.BOOKING_VOUCHER_NOT_FOUND]: 'messages.voucher-not-found',
  [responseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_TODAY]: 'messages.voucher-not-for-sale-today',
  [responseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_FOR_TRAVELLING]: 'messages.voucher-not-available-for-travelling-on-give-date',
  [responseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_FOR_ANY_SERVICE]: 'messages.voucher-not-available-for-any-service',
  [responseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_FOR_TOTAL]: 'messages.voucher-not-available-for-total',
  [responseCodes.BOOKING_VOUCHER_IS_USED]: 'messages.voucher-used-by-customer',
  [responseCodes.BOOKING_VOUCHER_NOT_ALLOWED]: 'messages.voucher-not-allowed',
  [responseCodes.BOOKING_VOUCHER_ALREADY_USED]: 'messages.voucher-already-used',
  [responseCodes.BOOKING_VOUCHER_RESTRICTED]: 'messages.voucher-restricted',
  [responseCodes.BOOKING_VOUCHER_AMOUNT_NOT_SUFFICIENT]: 'messages.voucher-amount-not-sufficient',
  [responseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_FOR_TRAVEL_DAY]: 'messages.voucher-not-available-for-travel-day',
  [responseCodes.BOOKING_NOT_FOUND_FOR_BOOKING_NUMBER]: 'messages.booking-is-not-found',
  [responseCodes.BOOKING_VOUCHER_DOES_NOT_EXISTS]: 'messages.voucher-to-remove-does-not-exists',
  [responseCodes.BOOKING_NOT_ACCESSIBLE_FOR_CURRENT_USER]: 'messages.booking-not-accessible-for-current-user',
  [responseCodes.BOOKING_FULFILLMENT_METHODS_MISSING]: 'messages.booking-create-not-available',
  [responseCodes.BOOKING_VOUCHER_FULL_AMOUNT_USED]: 'messages.voucher-full-amount-used',
  [responseCodes.BOOKING_CREATE_UNKNOWN_ERROR]: 'messages.booking-create-unknown-error',
  [responseCodes.BOOKING_CREATE_NO_AVAILABLE_SEAT_COMBINATION]: 'messages.booking-create-not-available',
  [responseCodes.BOOKING_CREATE_NO_LOGICAL_AVAILABILITY]: 'messages.booking-create-not-available',
  [responseCodes.BOOKING_RESEND_TICKETS_BOOKING_NOT_FOUND]: 'messages.booking-resend-tickets-booking-not-found',
  [responseCodes.BOOKING_CREATE_INVALID_REQUEST_FOR_SERVICE]: 'messages.booking-create-not-available',
  [responseCodes.BOOKING_CREATE_COULD_NOT_ALLOCATE_SEATS]: 'messages.booking-create-not-available',
  [responseCodes.BOOKING_CREATE_NO_AVAILABLE_CARRIAGES]: 'messages.booking-create-not-available',
  [responseCodes.BOOKING_CREATE_NO_LOGICAL_OR_PHYSICAL_AVAILABILITY]: 'messages.booking-create-out-of-stock',
  [responseCodes.BOOKING_IS_PENDING_IN_ANOTHER_QUEUE]: 'messages.booking-is-pending-an-another-queue',
  [responseCodes.BOOKING_IS_CANCELLED]: 'messages.booking-is-cancelled',
  [responseCodes.BOOKING_IS_EXPIRED]: 'messages.booking-is-expired',
  [responseCodes.BOOKING_NOT_FOUND]: 'messages.booking-is-not-found',
  [responseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_FOR_BOOKING]: 'messages.voucher-not-available-for-booking',
  [responseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_IN_BOOKING_CURRENCY]: 'messages.voucher-not-available-in-booking-currency',
  [responseCodes.BOOKING_VOUCHER_EMAIL_VALIDATION_FAILED]: 'messages.voucher-email-validation-failed',
  [responseCodes.BOOKING_UNABLE_TO_REALLOCATE_SEATS]: 'messages.booking-unable-to-reallocate-seats',
  [responseCodes.BOOKING_CREATE_BOOKING_HORIZON_BREACHED]: 'messages.booking-horizon-breached',
  [responseCodes.BOOKING_VOUCHER_SALES_TIME_RESTRICTION]: 'messages.voucher-sales-time-restriction',
  [responseCodes.BOOKING_VOUCHER_ONLY_FOR_ONE_WAY_TRIP]: 'messages.voucher-restricted-to-one-way-trip',
  [responseCodes.BOOKING_REACHED_RESEND_LIMIT]: 'messages.booking-reached-resend-limit',
  [responseCodes.BOOKING_SEARCH_NOT_SPECIFIC_CUSTOMER]: 'messages.booking-search-not-specific',
  [responseCodes.BOOKING_SEARCH_NOT_SPECIFIC_PASSENGER]: 'messages.booking-search-not-specific',
  [responseCodes.PAYMENT_NO_REFUND_PAYMENT_FOR_REFUND_VOUCHER]: 'messages.voucher-no-refund-payment-for-refund-voucher',
  [responseCodes.PAYMENT_NO_CUSTOMER_INFORMATION_FOR_REFUND_VOUCHER]: 'messages.voucher-no-customer-information-for-refund-voucher',
  [responseCodes.PAYMENT_INVALID_ENCRYPTED_DATA]: 'messages.payment-configuration-not-valid',
  [responseCodes.PAYMENT_UNKNOWN_INITIATE_PAYMENT_ERROR]: 'messages.payment-unknown-initiate-payment-error',
  [responseCodes.PAYMENT_CARD_TYPE_NOT_SUPPORTED]: 'messages.payment-card-type-not-supported',
  [responseCodes.PAYMENT_IS_REFUSED]: 'messages.payment-is-refused',
  [responseCodes.PAYMENT_IS_CANCELLED]: 'messages.payment-is-cancelled',
  [responseCodes.PAYMENT_INVALID_BANK_ACCOUNT_INFORMATION]: 'messages.payment-invalid-bank-account-information',
  [responseCodes.PAYMENT_NO_ELIGIBLE_PAYMENTS_EXISTS]: 'messages.payment-no-eligible-payments-exists',
  [responseCodes.AFTER_SALES_OPERATION_HAS_INVALID_ITEMS]: 'messages.aftersales-operation-has-invalid-items',
  [responseCodes.AGENT_CRM_ACCESS_REQUIRED_TO_VIEW_CUSTOMERS]: 'messages.agent-crm-access-required-to-view-customers'
}

const severity = {
  info: 'info',
  warning: 'warning',
  error: 'error'
}

const severityMap = {
  [responseCodes.USER_OAUTH_AGENT_BAD_CREDENTIALS]: severity.info,
  [responseCodes.USER_OAUTH_BOOKING_BAD_CREDENTIALS]: severity.info,
  [responseCodes.USER_OAUTH_CRM_BAD_CREDENTIALS]: severity.info,
  [responseCodes.USER_AGENT_USERNAME_ALREADY_EXISTS]: severity.info,
  [responseCodes.USER_AGENT_RESET_PASSWORD_NOT_FOUND]: severity.info,
  [responseCodes.USER_AGENT_CHANGE_PASSWORD_WEAK]: severity.warning,
  [responseCodes.USER_AGENT_INCORRECT_AGENT_PERMISSIONS]: severity.info,
  [responseCodes.USER_OAUTH_AGENT_PASSWORD_BLOCKED]: severity.info,
  [responseCodes.USER_OAUTH_AGENT_PASSWORD_EXPIRED]: severity.info,
  [responseCodes.USER_OAUTH_AGENT_INACTIVE]: severity.info,
  [responseCodes.USER_AGENT_CHANGE_PASSWORD_WRONG_CURRENT_PASSWORD]: severity.info,
  [responseCodes.USER_AGENT_CHANGE_PASSWORD_PERMISSION_DENIED]: severity.warning,
  [responseCodes.CRM_USER_NOT_FOUND]: severity.info,
  [responseCodes.CRM_SECURITY_CODE_INVALID]: severity.info,
  [responseCodes.CRM_UPDATE_EMAIL_ADDRESS_ALREADY_EXISTS]: severity.info,
  [responseCodes.USER_OAUTH_CRM_PASSWORD_BLOCKED]: severity.info,
  [responseCodes.CRM_UPDATE_WRONG_CURRENT_PASSWORD]: severity.info,
  [responseCodes.BOOKING_VOUCHER_NOT_FOUND]: severity.info,
  [responseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_TODAY]: severity.info,
  [responseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_FOR_TRAVELLING]: severity.info,
  [responseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_FOR_ANY_SERVICE]: severity.info,
  [responseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_FOR_TOTAL]: severity.info,
  [responseCodes.BOOKING_VOUCHER_IS_USED]: severity.info,
  [responseCodes.BOOKING_VOUCHER_NOT_ALLOWED]: severity.info,
  [responseCodes.BOOKING_VOUCHER_ALREADY_USED]: severity.info,
  [responseCodes.BOOKING_VOUCHER_RESTRICTED]: severity.info,
  [responseCodes.BOOKING_VOUCHER_AMOUNT_NOT_SUFFICIENT]: severity.info,
  [responseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_FOR_TRAVEL_DAY]: severity.info,
  [responseCodes.BOOKING_NOT_FOUND_FOR_BOOKING_NUMBER]: severity.info,
  [responseCodes.BOOKING_VOUCHER_DOES_NOT_EXISTS]: severity.info,
  [responseCodes.BOOKING_NOT_ACCESSIBLE_FOR_CURRENT_USER]: severity.info,
  [responseCodes.BOOKING_FULFILLMENT_METHODS_MISSING]: severity.warning,
  [responseCodes.BOOKING_VOUCHER_FULL_AMOUNT_USED]: severity.info,
  [responseCodes.BOOKING_CREATE_UNKNOWN_ERROR]: severity.info,
  [responseCodes.BOOKING_CREATE_NO_AVAILABLE_SEAT_COMBINATION]: severity.info,
  [responseCodes.BOOKING_CREATE_NO_LOGICAL_AVAILABILITY]: severity.info,
  [responseCodes.BOOKING_RESEND_TICKETS_BOOKING_NOT_FOUND]: severity.info,
  [responseCodes.BOOKING_CREATE_INVALID_REQUEST_FOR_SERVICE]: severity.error,
  [responseCodes.BOOKING_CREATE_COULD_NOT_ALLOCATE_SEATS]: severity.info,
  [responseCodes.BOOKING_CREATE_NO_AVAILABLE_CARRIAGES]: severity.info,
  [responseCodes.BOOKING_CREATE_NO_LOGICAL_OR_PHYSICAL_AVAILABILITY]: severity.info,
  [responseCodes.BOOKING_IS_PENDING_IN_ANOTHER_QUEUE]: severity.info,
  [responseCodes.BOOKING_IS_CANCELLED]: severity.error,
  [responseCodes.BOOKING_IS_EXPIRED]: severity.info,
  [responseCodes.BOOKING_NOT_FOUND]: severity.error,
  [responseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_FOR_BOOKING]: severity.info,
  [responseCodes.BOOKING_VOUCHER_NOT_AVAILABLE_IN_BOOKING_CURRENCY]: severity.info,
  [responseCodes.BOOKING_VOUCHER_EMAIL_VALIDATION_FAILED]: severity.info,
  [responseCodes.BOOKING_UNABLE_TO_REALLOCATE_SEATS]: severity.info,
  [responseCodes.BOOKING_CREATE_BOOKING_HORIZON_BREACHED]: severity.warning,
  [responseCodes.BOOKING_VOUCHER_SALES_TIME_RESTRICTION]: severity.info,
  [responseCodes.BOOKING_VOUCHER_ONLY_FOR_ONE_WAY_TRIP]: severity.info,
  [responseCodes.BOOKING_REACHED_RESEND_LIMIT]: severity.warning,
  [responseCodes.PAYMENT_NO_REFUND_PAYMENT_FOR_REFUND_VOUCHER]: severity.error,
  [responseCodes.PAYMENT_NO_CUSTOMER_INFORMATION_FOR_REFUND_VOUCHER]: severity.error,
  [responseCodes.PAYMENT_INVALID_ENCRYPTED_DATA]: severity.error,
  [responseCodes.PAYMENT_UNKNOWN_INITIATE_PAYMENT_ERROR]: severity.error,
  [responseCodes.PAYMENT_CARD_TYPE_NOT_SUPPORTED]: severity.error,
  [responseCodes.PAYMENT_IS_REFUSED]: severity.info,
  [responseCodes.PAYMENT_IS_CANCELLED]: severity.info,
  [responseCodes.PAYMENT_INVALID_BANK_ACCOUNT_INFORMATION]: severity.info,
  [responseCodes.USER_AGENT_SHIFT_NOT_FOUND]: severity.info,
  [responseCodes.USER_AGENT_HAS_NO_ACTIVE_SHIFT]: severity.info,
  [responseCodes.BOOKING_PROVISION_OF_A_SERVICE_NAME_IS_REQUIRED]: severity.warning,
  [responseCodes.BOOKING_TARIFF_REQUIRES_DEPARTURE_STATION]: severity.warning,
  [responseCodes.AFTER_SALES_OPERATION_HAS_INVALID_ITEMS]: severity.error,
  [responseCodes.BOOKING_TICKET_NOT_FOUND]: severity.info,
  [responseCodes.USER_OAUTH_INVALID_REFRESH_TOKEN]: severity.info,
  [responseCodes.USER_INVALID_SESSION_ID_FOR_AGENT]: severity.info,
  [responseCodes.CMS_INVALID_JSON_RESPONSE]: severity.info,
  [responseCodes.INTERNAL_SERVER_ERROR]: severity.info,
  [responseCodes.SOAP_SERVICE_UNAVAILABLE]: severity.info,
  [responseCodes.UNEXPECTED_SOAP_ERROR]: severity.info,
  [responseCodes.REDIS_SERVER_ERROR]: severity.info,
  [responseCodes.INVALID_JSON_RESPONSE]: severity.info,
  [responseCodes.CMS_SERVICE_UNAVAILABLE]: severity.info,
  [responseCodes.UNAUTHORIZED_FOR_OPERATION]: severity.info,
  [responseCodes.BOOKING_SEARCH_NOT_SPECIFIC_CUSTOMER]: severity.warning,
  [responseCodes.BOOKING_SEARCH_NOT_SPECIFIC_PASSENGER]: severity.warning,
  [responseCodes.USER_OAUTH_STEWARD_BAD_CREDENTIALS]: severity.info
}

export default {
  ...responseCodes,
  messages,
  severity,
  severityMap,

  _callbackSystemError () {
    return false
  },

  hasMessage (code) {
    return code in this.messages
  },

  getMessage (code) {
    return this.messages[code]
  },

  getSeverity (code) {
    return code && code in this.severityMap ? this.severityMap[code] : undefined
  },

  translate (code, defaultValue) {
    const message = this.getMessage(code)
    if (this.hasMessage(code) && _t.hasMessage(message)) {
      return _t.message(message)
    } else {
      return defaultValue || message || `error: ${code}`
    }
  },

  isSystemError (error) {
    return ('errorCode' in error && error.errorCode < 1000) || this._callbackSystemError(error)
  },

  addResponseCodes (responseCodes) {
    Object.keys(responseCodes).forEach(responseCodeKey => {
      this[responseCodeKey] = responseCodes[responseCodeKey]
    })
  },

  addMessages (messages) {
    this.messages = {
      ...this.messages,
      ...messages
    }
  },

  addSeverityMap (severityMap) {
    this.severityMap = {
      ...this.severityMap,
      ...severityMap
    }
  },

  setCallbackSystemError (callback) {
    this._callbackSystemError = callback
  }
}
