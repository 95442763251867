import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Modal from '../../../elements/modal/modal'

export const TERMS_AND_CONDITIONS_MODAL = 'terms-and-conditions'

export default class TermsAndConditions extends Component {
  static propTypes = {
    link: PropTypes.string
  }

  render () {
    return (
      <Modal
        name='terms-and-conditions'
        header={<Modal.Header
          title={_t.message('terms-and-conditions.title')}
        />}
      >
        <iframe src={this.props.link} />
      </Modal>
    )
  }
}
