import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Icon from '../../../elements/icon/icon'
import Button from '../../../elements/button/button'
import TextLabel from '../../../elements/text-label/text-label'
import BaseProgressNavigation from '../progress-navigation/progress-navigation'
import '../../../../src/elements/sticky-footer/sticky-footer.scss'
import VoidTicketsModal from '../void-tickets/void-tickets-modal'

export default class ProgressNavigation extends Component {
  static propTypes = {
    onClickNext: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    paymentRequired: PropTypes.bool,
    showVoidModal: PropTypes.bool
  }

  render () {
    return (
      <BaseProgressNavigation>
        <Button
          name='next-step'
          className='tertiary'
          disabled={this.props.disabled}
          loading={this.props.loading}
          onClick={this.props.onClickNext}
        >
          <TextLabel>
            <TextLabel.Text
              text={_t.message(`aftersales.update-passengers.confirm${
                this.props.paymentRequired ? '-sub-pay' : ''
              }`)}
            />
            <Icon name='chevron-right' className='medium align-right' />
          </TextLabel>
        </Button>
        {this._renderModal()}
      </BaseProgressNavigation>
    )
  }

  _renderModal () {
    return this.props.showVoidModal
      ? <VoidTicketsModal onClickNext={this.props.onClickNext} /> : null
  }
}
