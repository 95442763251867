import React, { Component } from 'react'
import PropTypes from '../../misc/prop-types'
import _t from 's3p-js-lib/src/translate'
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_WARNING } from 's3p-js-lib/src/constants'
import FeedbackMachine from '../../containers/base/last-machine-error-feedback'
import ComponentContainer from '../../elements/component-container/component-container'
import Panel from '../../elements/panel/panel'
import Icon from '../../elements/icon/icon'
import ButtonGroup from '../../elements/button-group/button-group'
import Button from '../../elements/button/button'
import TextLabel from '../../elements/text-label/text-label'
import LeapReadList from './output/leap-read-list'
import Feedback, { FEEDBACK_WARNING } from '../../elements/feedback/feedback'
import './leap-read-card.scss'
import LeapReverseModal from '../../containers/leap/leap-reverse-modal'

export default class LeapReadCard extends Component {
  static propTypes = {
    readLeapCard: PropTypes.func.isRequired,
    isLoadingReadLeapCard: PropTypes.bool,
    leapCardData: PropTypes.shape({
      cardIdentifier: PropTypes.number.isRequired,
      expiryDate: PropTypes.moment
    }),
    addProductIsAllowed: PropTypes.bool,
    cardIsExpired: PropTypes.bool,
    cardIsBlocked: PropTypes.bool,
    purseIsBlocked: PropTypes.bool,
    cardIsValid: PropTypes.bool,
    hasValidLeapConfiguration: PropTypes.bool,
    clearState: PropTypes.func,
    reverseTransationIsAllowed: PropTypes.bool,
    onShowModal: PropTypes.func,
    showReverseModal: PropTypes.bool,
    onAddProduct: PropTypes.func,
    onTopUp: PropTypes.func
  }

  UNSAFE_componentWillMount () {
    this.props.readLeapCard()
  }

  componentWillUnmount () {
    this.props.clearState()
  }

  renderReadingLeapCard () {
    return (
      <div className='read-status'>
        <TextLabel name='status-indicator' className='animation-blink'>
          <Icon name='tickets' className='status-indicator align-left' />
          <TextLabel.Text text={_t.message('leap.read.please-present-ticket')} />
        </TextLabel>
      </div>
    )
  }

  renderLeapDataAndMessages () {
    return [
      (this.props.cardIsExpired
        ? <Feedback
          key='expired-since'
          status={FEEDBACK_WARNING}
          text={_t.message('leap.data.card-expired-since', {date: this.props.leapCardData.expiryDate && this.props.leapCardData.expiryDate.format('L LT')})}
        />
        : null),
      (this.props.cardIsBlocked
        ? <Feedback
          key='card-is-blocked'
          status={FEEDBACK_WARNING}
          text={_t.message('leap.data.leap-card-is-blocked')}
        />
        : null),
      (this.props.purseIsBlocked
        ? <Feedback
          key='purse-is-blocked'
          status={FEEDBACK_WARNING}
          text={_t.message('leap.data.leap-purse-is-blocked')}
        />
        : null),
      <LeapReadList key='leap-card-data' leapCard={this.props.leapCardData} />
    ]
  }

  render () {
    return (
      <ComponentContainer name='leap-read-card'>
        <Panel
          header={
            <Panel.Header
              name='leap-read'
              title={this.props.leapCardData
                ? _t.message('leap.read.title-card-number', {cardNumber: this.props.leapCardData.cardIdentifier})
                : _t.message('leap.read.title')
              }
              description={_t.message('leap.read.description')}
            />
          }
        >
          {this.props.isLoadingReadLeapCard
            ? this.renderReadingLeapCard()
            : null
          }
          {!this.props.isLoadingReadLeapCard && this.props.hasValidLeapConfiguration
            ? (
              <ButtonGroup>
                <Button
                  name='read-leap-card'
                  className='primary'
                  onClick={this.props.readLeapCard}
                  loading={this.props.isLoadingReadLeapCard}
                >
                  <TextLabel text={this.props.leapCardData
                    ? _t.message('leap.read.read-new-leap-card')
                    : _t.message('leap.read.read-leap-card')
                  } />
                </Button>
                {this.props.leapCardData && this.props.reverseTransationIsAllowed
                  ? (
                    <Button
                      name='reverse-leap-card'
                      className='primary'
                      onClick={this.props.onShowModal}
                      disabled={this.props.showReverseModal}
                    >
                      <TextLabel text={_t.message('leap.read.reverse-leap-card')} />
                    </Button>
                  )
                  : null
                }
                { this.props.leapCardData
                  ? <Button
                    name='add-product'
                    className='primary'
                    onClick={this.props.onAddProduct}
                    disabled={!this.props.addProductIsAllowed}
                  >
                    <TextLabel text={_t.message('leap.read.add-product')} />
                  </Button> : null }
                { this.props.leapCardData
                  ? <Button
                    name='read-leap-card'
                    className='primary'
                    onClick={this.props.onTopUp}
                  >
                    <TextLabel text={_t.message('leap.read.top-up')} />
                  </Button> : null }
              </ButtonGroup>
            ) : null }
          {!this.props.hasValidLeapConfiguration
            ? (
              <Feedback
                status={FEEDBACK_WARNING}
                text={_t.message('leap.data.no-leap-configuration')}
              />
            ) : null
          }
          {!this.props.showReverseModal
            ? <FeedbackMachine
              namespaces={['leapDevice']}
              messages={[MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_WARNING]}
            />
            : null}
          {this.props.leapCardData && !this.props.isLoadingReadLeapCard
            ? this.renderLeapDataAndMessages()
            : null
          }
        </Panel>
        {this.props.showReverseModal ? <LeapReverseModal /> : null}
      </ComponentContainer>
    )
  }
}
