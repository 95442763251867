import {
  UI_ELEMENT_SHOW,
  UI_ELEMENT_HIDE,
  UI_ELEMENT_TOGGLE,
  UI_ELEMENTS_CLEAR,
  CLEAR_STATE,
  API_OAUTH_LOGOUT
} from 's3p-js-lib/src/redux/actions/types'
import {
  FINALIZE_BOOKING_MODAL,
  CONTAINERS_BASE_TILES_PAYMENT_METHODS,
  CONTAINERS_BASE_ELEMENT_VISIBILITY
} from '../../../../constants'
import omit from 'lodash/omit'
import { UI_ERROR_PAGE_REDIRECT } from '../../../actions/types'

const getVisibleModals = state => Object.keys(state)
  .filter(name => typeof state[name] === 'object' && state[name].isModal && state[name].visible)

const hideFinalizeBookingModal = state => getVisibleModals(state).includes(FINALIZE_BOOKING_MODAL)
  ? {...state, [FINALIZE_BOOKING_MODAL]: false}
  : state

export default function visibleElement (state = {}, action) {
  switch (action.type) {
    case API_OAUTH_LOGOUT:
    case UI_ERROR_PAGE_REDIRECT:
      return {}
    case UI_ELEMENTS_CLEAR:
      return omit(state, action.names)
    case UI_ELEMENT_SHOW:
      if (action.isModal) {
        const visibleModals = getVisibleModals(state).reduce((states, name) => {
          states[name] = false
          return states
        }, {})

        return {
          ...state,
          ...visibleModals,
          [action.name]: {isModal: true, visible: true, extraProps: action.extraProps}
        }
      } else {
        return {...state, [action.name]: true}
      }
    case UI_ELEMENT_HIDE:
      return {...state, [action.name]: false}
    case UI_ELEMENT_TOGGLE:
      return {
        ...state,
        [action.name]: typeof state[action.name] === 'object'
          ? {...state[action.name], visible: !state[action.name].visible}
          : !state[action.name]
      }
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_BASE_TILES_PAYMENT_METHODS) ||
      action.namespaces.includes(CONTAINERS_BASE_ELEMENT_VISIBILITY) ? hideFinalizeBookingModal(state) : state
    default:
      return state
  }
}
