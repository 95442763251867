import React, { Component } from 'react'
import GridLayout from '../../elements/grid/grid-layout'
import GridColumn from '../../elements/grid/grid-column'
import MainMenu from '../../containers/base/main-menu/main-menu'
import GenericLayout from '../generic-layout'
import ReviewPayment from '../../containers/base/review-payment/review-payment'
import FinalizeBooking from '../../containers/reservations/review-payment/finalize-booking'
import ReturnPaymentModal from '../../containers/reservations/review-payment/return-payment-modal'
import StickyFooter from '../../elements/sticky-footer/sticky-footer'
import BookingSummary from '../../components/reservations/booking-summary'
import Taskbar from '../../containers/base/taskbar/taskbar'
import BookingProgressBar from '../../containers/reservations/progress-bar'

export default class PaymentLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <MainMenu />
            <BookingProgressBar />
            <ReviewPayment />
            <ReturnPaymentModal />
            <FinalizeBooking />
            <StickyFooter>
              <BookingSummary />
              <Taskbar />
            </StickyFooter>
          </GridColumn>
        </GridLayout>
      </GenericLayout>
    )
  }
}
