import {
  API_ADD_VOUCHER_REQUEST,
  API_ADD_VOUCHER_SUCCESS,
  API_ADD_VOUCHER_FAILURE,
  USER_INPUT_FORM_DESTROY
} from 's3p-js-lib/src/redux/actions/types'
import {MESSAGE_SEVERITY_WARNING} from 's3p-js-lib/src/constants'

const defaultState = {
  success: false,
  messages: []
}

const getErrorMessage = error => ({severity: MESSAGE_SEVERITY_WARNING, code: error ? error.errorCode : null})

export default (state = defaultState, action) => {
  switch (action.type) {
    case API_ADD_VOUCHER_REQUEST:
      return defaultState
    case API_ADD_VOUCHER_SUCCESS: {
      const messages = action.response.messages || []
      return {
        success: messages.length === 0,
        messages
      }
    }
    case API_ADD_VOUCHER_FAILURE:
      return {
        success: false,
        messages: [getErrorMessage(action.error)]
      }
    case USER_INPUT_FORM_DESTROY:
      return action.formName === 'redeemVoucher' ? {
        ...state,
        messages: []
      } : state
    default:
      return state
  }
}
