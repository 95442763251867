import baseReducers from 's3p-js-lib/src/redux/reducers/api'
import loading from './loading'
import v2 from './v2'
import booking from './booking'
import orientation from './orientation'
import modules from './modules'
import base from './base'

export default {
  ...baseReducers,
  orientation,
  booking,
  loading,
  v2,
  modules,
  base
}
