import { compose } from 'redux'
import { connect } from 'react-redux'
import { updatePassengersInitiate } from 's3p-js-lib/src/redux/actions/api/aftersales/update-passengers/initiate'
import { overviewBookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import containVisibleElement from '../../../../containers/base/visible-element'
import {
  isLoadingToolbarActionsSelector,
  isOverrideAftersalesRulesSelector
} from '../../../../redux/selectors/containers/aftersales/booking/toolbar'
import { updatePassengersAllowedSelector } from '../../../../redux/selectors/api/booking/after-sales'
import ToolbarItem from '../../../../components/aftersales/booking/toolbar/toolbar-item'
import {
  isUpdatePassengersAvailableSelector,
  isOverrideValidationRulesSelector
} from '../../../../redux/selectors/containers/base/aftersales'
import { goToUpdatePassengers } from '../../../../redux/actions/containers/base/void-tickets-routing'

const _isUpdatePassengersAvailableSelector = isUpdatePassengersAvailableSelector(overviewBookingSelector)
const _isOverrideAftersalesRulesSelector = isOverrideAftersalesRulesSelector(updatePassengersAllowedSelector)
const _isOverrideValidationRulesSelector = isOverrideValidationRulesSelector(overviewBookingSelector)

const mapStateToProps = state => ({
  loading: state.api.loading.updatePassengersInitiate,
  disabled: isLoadingToolbarActionsSelector(state),
  available: _isUpdatePassengersAvailableSelector(state),
  overrideAftersalesRules: _isOverrideAftersalesRulesSelector(state),
  overrideValidationRules: _isOverrideValidationRulesSelector(state)
})

const mapDispatchToProps = (_, ownProps) => dispatch => ({
  onClick: async () => {
    if (await dispatch(updatePassengersInitiate(ownProps.bookingNumber))) {
      dispatch(goToUpdatePassengers(ownProps.bookingNumber))
    }
  }
})

const container = compose(
  connect(mapStateToProps, mapDispatchToProps),
  containVisibleElement()
)

export default container(ToolbarItem)
