import aftersales from './aftersales'
import base from './base'
import forms from './forms'
import orientation from './orientation'

export default {
  aftersales,
  base,
  forms,
  orientation
}
