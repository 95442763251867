import { selectBundle as baseSelectBundle } from 's3p-js-lib/src/redux/actions/api/orientation/select-bundle'
import { routeSelector } from '../../../../selectors/containers/base/journey-search/journey-result-set'
import { sendMachineDisplayLines, timeoutClearDisplay } from '../../../machine/display'
import uniqueId from 'lodash/uniqueId'
import { CLEAR_DISPLAY_AFTER_LONG, EXTERNAL_REFERENCE_NAME_IRISH } from '../../../../../constants'
import { displayFormatAmount, findMetaDataByKey } from '../../../../../misc/utils'

export const handleSelectBundle = bundle => async (dispatch, getState) => {
  const route = routeSelector(bundle.routeId)(getState())

  await dispatch(baseSelectBundle(
    route.journeySearchId,
    route.travelId,
    bundle.routeId,
    bundle.id
  ))

  const product = bundle.requiredProducts[0]
  const metaData = product.meta_data

  let nameIrish = ''
  if (metaData && metaData.length) {
    const metaNameIrish = findMetaDataByKey(metaData, EXTERNAL_REFERENCE_NAME_IRISH)
    nameIrish = metaNameIrish && metaNameIrish.value
  }

  const requestId = uniqueId('productName_')
  await dispatch(sendMachineDisplayLines([displayFormatAmount(product.name, bundle.price), nameIrish], requestId))
  dispatch(timeoutClearDisplay(CLEAR_DISPLAY_AFTER_LONG, requestId))
}
