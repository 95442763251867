import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import { MESSAGE_SEVERITY_WARNING, MESSAGE_SEVERITY_ERROR } from 's3p-js-lib/src/constants'
import Modal from '../../../elements/modal/modal'
import TextLabel from '../../../elements/text-label/text-label'
import Icon from '../../../elements/icon/icon'
import Button from '../../../elements/button/button'
import RetryReceiptButton from '../../../containers/base/finalize-booking/footer/retry-receipt'
import FeedbackMachine from '../../../containers/base/last-machine-error-feedback'
import Feedback, { FEEDBACK_SUCCESS, FEEDBACK_WARNING } from '../../../elements/feedback/feedback'
import {
  FINALIZE_BOOKING_MODAL,
  FULFILLMENT_METHOD_CODE_LEAP,
  LAST_MACHINE_ERROR_NAMESPACE_LEAP_DEVICE,
  LAST_MACHINE_ERROR_NAMESPACE_RECEIPT_PRINTER,
  LEAP_ERROR_MESSAGE_AMOUNT_EXCEEDED,
  LEAP_ERROR_MESSAGE_AMOUNT_TOO_LOW,
  LEAP_ERROR_MESSAGE_CARD_EXPIRED_SINCE,
  LEAP_ERROR_MESSAGE_CARD_IS_BLOCKED,
  LEAP_ERROR_MESSAGE_NO_CONFIGURATION,
  LEAP_ERROR_MESSAGE_PURSE_IS_BLOCKED,
  LEAP_ERROR_MESSAGE_TOP_UP_FAILED,
  LEAP_ERROR_MESSAGE_ADD_PRODUCT_FAILED,
  LEAP_ERROR_MESSAGE_INVALID_PRODUCT_CONFIGURATION,
  LEAP_ERROR_MESSAGE_LEAP_CARD_NOT_READ,
  LEAP_ERROR_MESSAGE_LEAP_CONFIGURATION_DATA_CORRUPT,
  LEAP_ERROR_MESSAGE_LEAP_DOUBTFUL_TRANSACTION,
  LEAP_ERROR_MESSAGE_LEAP_PRESENT_CORRECT_CARD,
  LEAP_ERROR_MESSAGE_LEAP_TRANSACTION_PERIOD_EXPIRED,
  LEAP_ERROR_MESSAGE_INVALID_PROFILE_RESTRICTIONS
} from '../../../constants'

const LEAP_MAPPING_ERROR_MESSAGES = {
  [LEAP_ERROR_MESSAGE_NO_CONFIGURATION]: 'leap.data.no-leap-configuration',
  [LEAP_ERROR_MESSAGE_AMOUNT_TOO_LOW]: 'finalize-booking-modal.leap.error.amount-too-low',
  [LEAP_ERROR_MESSAGE_AMOUNT_EXCEEDED]: 'finalize-booking-modal.leap.error.amount-exceeded',
  [LEAP_ERROR_MESSAGE_TOP_UP_FAILED]: 'finalize-booking-modal.leap.error.top-up-failed',
  [LEAP_ERROR_MESSAGE_ADD_PRODUCT_FAILED]: 'finalize-booking-modal.leap.error.add-product-failed',
  [LEAP_ERROR_MESSAGE_CARD_EXPIRED_SINCE]: 'leap.data.card-expired-since',
  [LEAP_ERROR_MESSAGE_CARD_IS_BLOCKED]: 'leap.data.leap-card-is-blocked',
  [LEAP_ERROR_MESSAGE_PURSE_IS_BLOCKED]: 'leap.data.leap-purse-is-blocked',
  [LEAP_ERROR_MESSAGE_INVALID_PRODUCT_CONFIGURATION]: 'leap.data.leap-invalid-product-configuration',
  [LEAP_ERROR_MESSAGE_LEAP_CARD_NOT_READ]: 'leap.data.leap-card-not-read',
  [LEAP_ERROR_MESSAGE_LEAP_CONFIGURATION_DATA_CORRUPT]: 'leap.data.leap-configuration-data-corrupt',
  [LEAP_ERROR_MESSAGE_LEAP_DOUBTFUL_TRANSACTION]: 'leap.data.leap-doubtful-transaction',
  [LEAP_ERROR_MESSAGE_INVALID_PROFILE_RESTRICTIONS]: 'leap.data.leap-invalid-profile-restrictions',
  [LEAP_ERROR_MESSAGE_LEAP_PRESENT_CORRECT_CARD]: 'leap.data.leap-present-correct-card',
  [LEAP_ERROR_MESSAGE_LEAP_TRANSACTION_PERIOD_EXPIRED]: 'leap.data.leap-transaction-period-expired'
}

const RETRY_BUTTON_ID = 'retry'
const NEXT_SALE_BUTTON_ID = 'next-sale'

export default class LeapModal extends Component {
  static propTypes = {
    fulfillmentMethod: PropTypes.shape({
      code: PropTypes.string.isRequired
    }).isRequired,
    isLoading: PropTypes.bool,
    isLoadingLeapRead: PropTypes.bool,
    isOfflineLeapDevice: PropTypes.bool,
    isOfflineTicketPrinter: PropTypes.bool,
    leapCardData: PropTypes.object,
    onNextSale: PropTypes.func.isRequired,
    handleRetry: PropTypes.func.isRequired,
    onClickOpenAftersales: PropTypes.func.isRequired,
    disableNextSale: PropTypes.bool,
    disableCancelSale: PropTypes.bool,
    disableRetry: PropTypes.bool,
    leapActionError: PropTypes.string,
    leapActionSuccess: PropTypes.bool,
    success: PropTypes.bool,
    purseBalance: PropTypes.number,
    totalBookingPrice: PropTypes.number,
    minimumTransactionValue: PropTypes.number,
    maximumTransactionValue: PropTypes.number,
    expiryDate: PropTypes.object,
    purseAmountTooLow: PropTypes.bool,
    purseAmountTooHigh: PropTypes.bool
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!nextProps.disableRetry) {
      const element = document.getElementById(RETRY_BUTTON_ID)
      element && setTimeout(() => element.focus(), 200)
    } else {
      const element = document.getElementById(NEXT_SALE_BUTTON_ID)
      element && setTimeout(() => element.focus(), 200)
    }
  }

  renderFooter () {
    return (
      <Modal.CustomFooter>
        <Button
          name='cancel-sale'
          className='tertiary'
          onClick={this.props.onClickOpenAftersales}
          disabled={this.props.disableCancelSale}
          hotkey='cancel'
        >
          <TextLabel name='change-fulfillment' text={_t.message('finalize-booking-modal.open-aftersales')} />
        </Button>
        <RetryReceiptButton disabled={this.props.isLoading} />
        <Button
          id={RETRY_BUTTON_ID}
          name={RETRY_BUTTON_ID}
          className='secondary'
          disabled={this.props.disableRetry}
          onClick={this.props.handleRetry}
          hotkey='refresh'
        >
          <TextLabel name='retry-printing' text={_t.message('finalize-booking-modal.retry')} />
        </Button>
        <Button
          id={NEXT_SALE_BUTTON_ID}
          name='finish-booking'
          className='primary'
          onClick={this.props.onNextSale}
          disabled={this.props.disableNextSale}
          hotkey='proceed'
        >
          <TextLabel name='finish-booking' text={_t.message('finalize-booking-modal.next-sale')} />
        </Button>
      </Modal.CustomFooter>
    )
  }

  renderLeapReading () {
    const executeActionLabel = this.props.fulfillmentMethod.code === FULFILLMENT_METHOD_CODE_LEAP
      ? 'finalize-booking-modal.leap.add-product-executing-leap'
      : 'finalize-booking-modal.leap.top-up-executing-leap'
    return (
      <div className='read-status'>
        <TextLabel name='status-indicator' className='animation-blink'>
          <Icon name='print-ticket' className='status-indicator align-left' />
          <TextLabel.Text text={_t.message(this.props.isLoadingLeapRead
            ? 'finalize-booking-modal.leap.reading'
            : executeActionLabel)}
          />
        </TextLabel>
      </div>
    )
  }

  renderLeapActionError () {
    return <Feedback status={FEEDBACK_WARNING} text={
      _t.message(LEAP_MAPPING_ERROR_MESSAGES[this.props.leapActionError], {
        amount: _t.formatCurrency(this.props.totalBookingPrice),
        purseBalance: this.props.leapCardData && _t.formatCurrency(this.props.leapCardData.purseBalance),
        minimumTransactionValue: _t.formatCurrency(this.props.minimumTransactionValue),
        maximumTransactionValue: _t.formatCurrency(this.props.maximumTransactionValue),
        date: this.props.expiryDate && this.props.expiryDate.format('L LT')
      })
    } />
  }

  render () {
    return (
      <Modal
        name={FINALIZE_BOOKING_MODAL}
        header={<Modal.Title title={_t.message('finalize-booking-modal.leap.title')} />}
        footer={this.renderFooter()}
        isCloseable={false}
      >
        {this.props.isOfflineTicketPrinter
          ? <Feedback status={FEEDBACK_WARNING} text={_t.message('finalize-booking-modal.receipt-printer.offline')} />
          : null
        }
        {this.props.isOfflineLeapDevice
          ? <Feedback status={FEEDBACK_WARNING} text={_t.message('finalize-booking-modal.leap-device.offline')} />
          : null
        }

        {this.props.isLoading && this.renderLeapReading()}

        <FeedbackMachine
          namespaces={[LAST_MACHINE_ERROR_NAMESPACE_RECEIPT_PRINTER, LAST_MACHINE_ERROR_NAMESPACE_LEAP_DEVICE]}
          severities={[MESSAGE_SEVERITY_WARNING, MESSAGE_SEVERITY_ERROR]}
        />

        {this.props.leapActionError ? this.renderLeapActionError() : null}

        {this.props.leapActionSuccess && !this.props.isLoading
          ? <Feedback status={FEEDBACK_SUCCESS} text={_t.message(this.props.fulfillmentMethod.code === FULFILLMENT_METHOD_CODE_LEAP
            ? 'finalize-booking-modal.leap.add-product-success'
            : 'finalize-booking-modal.leap.top-up-success'
          )} />
          : null
        }
        {this.props.children}
      </Modal>
    )
  }
}
