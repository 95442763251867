import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ComponentContainer from '../../../elements/component-container/component-container'
import Panel from '../../../elements/panel/panel'
import RelatedVouchersHeader from './related-vouchers/related-vouchers-header'
import RelatedVouchersRow from './related-vouchers/related-vouchers-row'
import Table from '../../../elements/table/table'
import _t from 's3p-js-lib/src/translate'
import './related-vouchers.scss'
import TextLabel from '../../../elements/text-label/text-label'
import Icon from '../../../elements/icon/icon'

export default class Notes extends Component {
  static propTypes = {
    vouchers: PropTypes.array.isRequired
  }

  render () {
    return (
      <ComponentContainer name='booking-related-vouchers'>
        <Panel
          header={
            <Panel.Header
              name='related-vouchers'
              title={_t.message('aftersales.booking.related-vouchers.title')}
              description={_t.message('aftersales.booking.related-vouchers.description')}
            />
          }
        >
          {this.props.vouchers.length
            ? (
              <Table name='related-vouchers' numColumns={4} zebraRows>
                <RelatedVouchersHeader />
                <tbody>
                  {this.props.vouchers.map(
                    (voucher, index) =>
                      <RelatedVouchersRow voucher={voucher} key={`related-voucher-${index}`} />
                  )}
                </tbody>
              </Table>
            ) : (
              <TextLabel name='information'>
                <Icon name='information' className='align-left' />
                <TextLabel.Text text={_t.message('aftersales.booking.related-vouchers.no-related-vouchers-found')} />
              </TextLabel>
            )
          }
        </Panel>
      </ComponentContainer>
    )
  }
}
