import PropTypes from 'prop-types'
import React, { Component } from 'react'
import defer from 'lodash/defer'

const KEY_CODE_ESC = 27

export const DOM_IGNORE_CLASS = 'closable-ignore'

export default class Closable extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    handleHideElement: PropTypes.func.isRequired
  }

  constructor (...args) {
    super(...args)

    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  UNSAFE_componentWillMount () {
    defer(() => {
      window.addEventListener('keydown', this.handleKeyDown, false)
      window.addEventListener('click', this.handleClickOutside, false)
      window.addEventListener('touchstart', this.handleClickOutside, false) // iOS bug
    })
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleKeyDown, false)
    window.removeEventListener('click', this.handleClickOutside, false)
    window.removeEventListener('touchstart', this.handleClickOutside, false)
  }

  handleKeyDown (event) {
    if (event.keyCode === KEY_CODE_ESC) {
      this.props.handleHideElement()
    }
  }

  handleClickOutside (event) {
    const className = this._getUniqueClassName()
    const hasClass = (element, className) =>
      typeof element.className === 'string' ? element.className.indexOf(className) >= 0 : false

    let node = event.target
    let found = false
    while (!found && node !== null && node !== document) {
      found = hasClass(node, className) || hasClass(node, DOM_IGNORE_CLASS)
      node = node.parentNode
    }

    if (!found && node !== null) {
      this.props.handleHideElement()
    }
  }

  _getUniqueClassName () {
    return `closable-${this.props.name}`
  }

  render () {
    return (
      <react-wrapper class={`react-wrapper ${this._getUniqueClassName()}`}>
        {this.props.children}
      </react-wrapper>
    )
  }
}
