import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ProductSearchResult, { PRODUCTS_TILES_NAME } from '../../../components/tickets/product-process/product-search-result'
import { provisionalOrCompletedBookingSelector } from '../../../redux/selectors/api/booking/booking'
import { addProduct } from '../../../redux/actions/containers/tickets/product-search-result'
import { ticketsApiLoadingSelector } from '../../../redux/selectors/containers/tickets/loading'
import { productsSelector } from '../../../redux/selectors/containers/tickets/product-search-result'
import { modalIsActiveSelector } from '../../../redux/selectors/containers/base/visible-element'
import { showElement } from '../../../redux/actions/containers/base/visible-element'
import { getModalName } from '../../base/tiles/tiles-with-modal'

class ProductSearchResultContainer extends Component {
  static propTypes = {
    products: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      nameIrish: PropTypes.string
    })).isRequired,
    showElement: PropTypes.func.isRequired,
    addProduct: PropTypes.func.isRequired,
    showTiles: PropTypes.bool.isRequired,
    isLoadingProductSearch: PropTypes.bool,
    isLoadingAddProduct: PropTypes.bool,
    noProductsFound: PropTypes.bool,
    hasSearch: PropTypes.bool,
    disabled: PropTypes.bool
  }

  constructor (props, ...args) {
    super(props, ...args)
    this.handleShowModal = props.showElement.bind(null, getModalName(PRODUCTS_TILES_NAME))
  }

  render () {
    return (
      <ProductSearchResult
        disabled={this.props.disabled}
        products={this.props.products}
        isLoadingProductSearch={this.props.isLoadingProductSearch}
        isLoadingAddProduct={this.props.isLoadingAddProduct}
        handleSelect={this.props.addProduct}
        handleShowModal={this.handleShowModal}
        showTiles={this.props.showTiles}
        noProductsFound={this.props.noProductsFound}
      />
    )
  }
}

const mapStateToProps = state => {
  const products = productsSelector(state)
  const isLoadingAddProduct = state.api.v2.loading.createBooking || state.api.v2.loading.updateSegments
  return {
    disabled: modalIsActiveSelector(state) || isLoadingAddProduct || ticketsApiLoadingSelector(state),
    showTiles: Boolean(provisionalOrCompletedBookingSelector(state) || products.length),
    products,
    isLoadingProductSearch: state.api.v2.loading.getProducts,
    isLoadingAddProduct,
    noProductsFound: state.api.v2.search !== null && !products.length
  }
}

const mapDispatchToProps = {
  addProduct,
  showElement
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearchResultContainer)
