import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Modal from '../../../elements/modal/modal'
import Table from '../../../elements/table/table'
import TextLabel from '../../../elements/text-label/text-label'
import { PAYMENT_METHOD_CODE_VOUCHER, RETURN_PAYMENT_MODAL } from '../../../constants'

export default class ReturnPaymentModal extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    payments: PropTypes.array.isRequired,
    handleClick: PropTypes.func.isRequired,
    openMachineCashDrawer: PropTypes.func.isRequired
  }

  UNSAFE_componentWillMount () {
    this.props.openMachineCashDrawer()
  }

  renderRow (payment) {
    const isVoucher = PAYMENT_METHOD_CODE_VOUCHER === payment.method
    const value = isVoucher ? payment.voucher : payment
    return (
      <tr key={payment.refId} className='payment'>
        <td>
          <TextLabel
            name='payment-description'
            text={_t.message('return-payment.payment-description', {method: payment.method, code: value.code})}
          />
        </td>
        <td><TextLabel name='payment-value' text={_t.formatCurrency(value.amount, value.currency)} /></td>
      </tr>
    )
  }

  render () {
    return (
      <Modal
        name={RETURN_PAYMENT_MODAL}
        header={<Modal.Header title={_t.message('return-payment.title')} />}
        footer={<Modal.Footer
          labelContinue='return-payment.confirm-button'
          onContinue={this.props.handleClick}
          isLoadingContinue={this.props.loading}
        />}
      >
        <TextLabel text={_t.message('return-payment.already-paid')} />
        <Table name='payment-overview' numColumns={2}>
          <tbody>
            {this.props.payments.map(this.renderRow)}
          </tbody>
        </Table>
        <TextLabel text={_t.message('return-payment.confirm-return')} />
      </Modal>
    )
  }
}
