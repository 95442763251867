import { connect } from 'react-redux'
import { bookingNumberSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { goToAftersalesBooking } from '../../../redux/actions/containers/base/routing'
import { completedOrOverviewBookingSelector } from '../../../redux/selectors/api/booking/booking'
import BaseFinalizeBooking from '../../base/finalize-booking/finalize-booking'
import { hideElement } from '../../../redux/actions/containers/base/visible-element'
import { clearMachineDisplay } from '../../../redux/actions/machine/display'
import { FINALIZE_BOOKING_MODAL } from '../../../constants'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import { getBooking } from 's3p-js-lib/src/redux/actions/api/booking/booking'
import {displayNextCustomer} from '../../../redux/actions/containers/base/finalize-booking/next-customer'

const mapDispatchToProps = {
  onChangeFulfillment: () => (dispatch, getState) => {
    const bookingNumber = bookingNumberSelector(completedOrOverviewBookingSelector)(getState())
    dispatch([
      clearState([
        'api.booking.provisionalBooking',
        'api.booking.completedBooking',
        'api.booking.overviewBooking',
        'api.booking.additionalDetailsBooking'
      ]),
      getBooking(bookingNumber),
      hideElement(FINALIZE_BOOKING_MODAL)
    ])
  },
  onNextSale: () => (dispatch, getState) => {
    dispatch([
      hideElement(FINALIZE_BOOKING_MODAL),
      clearMachineDisplay(),
      goToAftersalesBooking(bookingNumberSelector(completedOrOverviewBookingSelector)(getState())),
      displayNextCustomer()
    ])
  }
}

export default connect(null, mapDispatchToProps)(BaseFinalizeBooking)
