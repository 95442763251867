import { createSelector } from 'reselect'
import { journeySearchSelector } from 's3p-js-lib/src/redux/selectors/api/orientation/journey-search'
import {
  TRAVEL_DIRECTION_BOTH,
  TRAVEL_DIRECTION_INBOUND,
  TRAVEL_DIRECTION_OUTBOUND
} from 's3p-js-lib/src/constants'
import { getSelectionFromJourneySearch } from 's3p-js-lib/src/redux/selectors/user-input/aftersales/rebooking/selection'
import {
  canRebookProductsSelector,
  requiredInboundProductsSelector,
  requiredOutboundProductsSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/products'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import { canOverrideAftersalesRulesSelector } from '../../../api/auth/auth'

const _requiredOutboundProductsSelector = requiredOutboundProductsSelector(originalBookingSelector)
const _requiredInboundProductsSelector = requiredInboundProductsSelector(originalBookingSelector)

export const rebookingDirectionSelector = createSelector(
  [
    _requiredOutboundProductsSelector,
    _requiredInboundProductsSelector,
    canRebookProductsSelector(_requiredOutboundProductsSelector),
    canRebookProductsSelector(_requiredInboundProductsSelector),
    canOverrideAftersalesRulesSelector(originalBookingSelector)
  ],
  (outboundProducts, inboundProducts, canRebookOutboundProducts, canRebookInboundProducts, canOverrideAftersales) => {
    const canRebookOutbound = Boolean(outboundProducts.length && (canRebookOutboundProducts || canOverrideAftersales))
    const canRebookInbound = Boolean(inboundProducts.length && (canRebookInboundProducts || canOverrideAftersales))

    return canRebookOutbound && canRebookInbound
      ? TRAVEL_DIRECTION_BOTH
      : canRebookOutbound ? TRAVEL_DIRECTION_OUTBOUND
        : canRebookInbound ? TRAVEL_DIRECTION_INBOUND
          : null
  }
)

export const rebookingSelectionSelector = createSelector(
  [
    state => state.userInput.aftersales.rebooking.selection,
    journeySearchSelector,
    rebookingDirectionSelector
  ],
  (currentSelection, journeySearch, rebookingDirection) =>
    rebookingDirection === TRAVEL_DIRECTION_BOTH
      ? currentSelection || getSelectionFromJourneySearch(journeySearch) || rebookingDirection
      : rebookingDirection
)

export const rebookingShowSelector = state => rebookingDirectionSelector(state) === TRAVEL_DIRECTION_BOTH
