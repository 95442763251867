import { API_UNHANDLED_ERROR } from 's3p-js-lib/src/redux/actions/types'
import { MACHINE_FAILURES_ACTIONS } from '../actions/types'
import { apiIsOffline } from 's3p-js-lib/src/redux/actions/api/offline'
import { machineIsOffline } from '../actions/machine/machine'
import { HTTP_CODE_ORIGIN_UNREACHABLE } from '../../constants'

export const isOriginUnreachable = error => error instanceof Error && error.statusCode === HTTP_CODE_ORIGIN_UNREACHABLE

export default () => next => action => {
  if (action.type === API_UNHANDLED_ERROR && isOriginUnreachable(action.error)) {
    return next(apiIsOffline())
  } else if (MACHINE_FAILURES_ACTIONS.includes(action.type) && isOriginUnreachable(action.error)) {
    next(machineIsOffline())
    return next(action)
  } else {
    return next(action)
  }
}
