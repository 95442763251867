import { createSelector } from 'reselect'
import { itemsSelector } from 's3p-js-lib/src/redux/selectors/api/v2/search'
import { formFieldValueSelectorCreator } from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import { FORM_EDIT_SHORTCUT } from '../../../../constants'

const formFieldValueSelector = formFieldValueSelectorCreator(FORM_EDIT_SHORTCUT, 'productFilter')

export const productSelector = createSelector(
  [formFieldValueSelector, itemsSelector],
  (value, items) => {
    if (!value) {
      return items
    } else {
      const upperCase = value.toUpperCase()
      return items.filter(item => item.name.toUpperCase().includes(upperCase))
    }
  }
)
