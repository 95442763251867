import { connect } from 'react-redux'
import NextButton from '../../../components/base/seat-selection/next-button'
import containBookingFlow from '../../../containers/reservations/booking-flow'
import { hasSelectedSeatsSelector } from 's3p-js-lib/src/redux/selectors/user-input/base/seat-selection'
import { allSegmentsSeatSelectionOptionsSelector } from '../../../redux/selectors/containers/base/seat-selection/seat-selection'

const reservationsHasSelectedSeatsSelector = hasSelectedSeatsSelector(allSegmentsSeatSelectionOptionsSelector)

const mapStateToProps = state => ({
  hasSelectedSeats: reservationsHasSelectedSeatsSelector(state),
  isLoading: state.api.loading.createProvisionalBooking
})

export default connect(mapStateToProps)(containBookingFlow(NextButton))
