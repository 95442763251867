import {
  MACHINE_RECEIPT_PRINT_REQUEST,
  MACHINE_RECEIPT_PRINT_SUCCESS,
  MACHINE_RECEIPT_PRINT_FAILURE,
  MACHINE_TEST_RECEIPT_PRINT_REQUEST,
  MACHINE_TEST_RECEIPT_PRINT_SUCCESS,
  MACHINE_TEST_RECEIPT_PRINT_FAILURE
} from '../types'
import DeviceManager from '../../../machine/device-manager'

const receiptPrintRequest = data => ({type: MACHINE_RECEIPT_PRINT_REQUEST, data})
const receiptPrintSuccess = response => ({type: MACHINE_RECEIPT_PRINT_SUCCESS, response})
const receiptPrintFailure = machineError => ({type: MACHINE_RECEIPT_PRINT_FAILURE, machineError})

const testReceiptPrint = () => ({type: MACHINE_TEST_RECEIPT_PRINT_REQUEST})
const testPrintSuccess = response => ({type: MACHINE_TEST_RECEIPT_PRINT_SUCCESS, response})
const testPrintFailure = machineError => ({type: MACHINE_TEST_RECEIPT_PRINT_FAILURE, machineError})

export const sendMachineReceiptPrint = data => async dispatch => {
  try {
    dispatch(receiptPrintRequest(data))
    const response = await DeviceManager.printReceipt(data)
    dispatch(receiptPrintSuccess(response))
  } catch (error) {
    dispatch(receiptPrintFailure(error))
    return false
  }
}

export const sendMachineTestReceiptPrint = () => async dispatch => {
  try {
    dispatch(testReceiptPrint())
    const response = await DeviceManager.testPrintReceipt()
    dispatch(testPrintSuccess(response))
  } catch (error) {
    dispatch(testPrintFailure(error))
  }
}
