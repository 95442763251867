import { createSelector } from 'reselect'
import { TRAVEL_DIRECTION_OUTBOUND, TRAVEL_DIRECTION_INBOUND } from '../../../../constants'
import { camelCaseKeys } from '../../../../misc/utils'
import { legMapper, legsSelector, travelsSelector } from './offer'

export const seatSelectionOptionsSelector = createSelector(
  [
    state => state.api.orientation.seatSelectionOptions
  ],
  seatSelectionOptions => seatSelectionOptions ? seatSelectionOptions.map(option => ({
    leg: legMapper(option.leg),
    selectedSeats: option.selected_seats.map(camelCaseKeys),
    status: option.status
  })) : null
)

export const seatSelectionOptionsByLegIdSelector = legId => createSelector(
  seatSelectionOptionsSelector,
  seatSelectionOptions => seatSelectionOptions.find(seatSelectionOption => seatSelectionOption.leg.id === legId)
)

const seatSelectionOptionsForDirection = direction => {
  const travelDirection = direction === TRAVEL_DIRECTION_OUTBOUND ? 'outward' : 'return'

  return createSelector(
    [
      seatSelectionOptionsSelector,
      legsSelector,
      travelsSelector
    ],
    (seatSelectionOptions, legs, travels) => seatSelectionOptions && legs && travels ? seatSelectionOptions.filter(
      option => {
        const leg = legs.find(leg => leg.uniqueIdentifier === option.leg.uniqueIdentifier)
        const travel = leg && travels.find(travel => travel.id === leg.travelId)

        return leg && travel && travel.direction === travelDirection
      }
    ) : null
  )
}

export const productsAvailabilitySelector = createSelector(
  [seatSelectionOptionsSelector],
  seatSelectionOptions => seatSelectionOptions
    ? seatSelectionOptions.map(options => ({
      availability: options.leg.availability,
      logicalAvailability: options.leg.logicalAvailability,
      id: options.leg.id
    }))
    : null
)

export const outboundSeatSelectionOptionsSelector = seatSelectionOptionsForDirection(TRAVEL_DIRECTION_OUTBOUND)

export const inboundSeatSelectionOptionsSelector = seatSelectionOptionsForDirection(TRAVEL_DIRECTION_INBOUND)
