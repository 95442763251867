import React, { Component } from 'react'
import PaymentModal from '../../../containers/base/payment-modal/payment-modal'
import VoucherFooter from '../../../containers/base/payment-modal/voucher-footer'
import VoucherForm from '../../../containers/base/payment-modal/voucher-form'

export default class VoucherModal extends Component {
  render () {
    return (
      <PaymentModal footer={<VoucherFooter />}>
        <VoucherForm />
      </PaymentModal>
    )
  }
}
