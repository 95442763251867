import { getBooking } from 's3p-js-lib/src/redux/actions/api/booking/booking'
import {
  bookingNumberSelector,
  completedBookingSelector,
  totalPriceBookingSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import {
  UI_LEAP_TOP_UP_START,
  UI_LEAP_TOP_UP_END,
  UI_LEAP_DOUBTFUL_TRANSACTION_TOP_UP_VALUE
} from '../../types'
import { leapDataSelector, leapTopUpPaymentCode } from '../../../selectors/machine/leap'
import { readLeapCard } from './leap-read'
import { topUpLeap } from '../../machine/leap'
import { addOrUpdateAdditionalDetails, LEAP_TOP_UP_KEY, leapDoubtfulTransaction } from './leap'
import {
  checkDoubtfulTransactionMessageSelector,
  leapDoubtfulTransactionOccurredSelector,
  readLeapStepsBeforeActionSelector
} from '../../../selectors/containers/base/leap'
import { LEAP_ERROR_MESSAGE_TOP_UP_FAILED, LEAP_ERROR_MESSAGE_LEAP_DOUBTFUL_TRANSACTION } from '../../../../constants'

const topUpLeapStart = () => ({type: UI_LEAP_TOP_UP_START})
const topUpLeapEnd = error => ({type: UI_LEAP_TOP_UP_END, error})
const leapDoubtfulTransactionCurrentPurseBalance = currentPurseBalance => ({type: UI_LEAP_DOUBTFUL_TRANSACTION_TOP_UP_VALUE, currentPurseBalance})

const _bookingNumberSelector = bookingNumberSelector(completedBookingSelector)
const _totalPriceBookingSelector = totalPriceBookingSelector(completedBookingSelector)

export const leapTopUp = () => async (dispatch, getState) => {
  dispatch(topUpLeapStart())
  await dispatch(readLeapCard())

  const state = getState()
  let errorMessage = readLeapStepsBeforeActionSelector(state)
  if (!errorMessage) {
    const data = leapDataSelector(state)

    errorMessage = leapDoubtfulTransactionOccurredSelector(state)
    if (!errorMessage) {
      let result
      const doubtfulTransactionCurrentPurseBalance = state.containers.leap.doubtFulTransaction.currentPurseBalance
      const totalPrice = _totalPriceBookingSelector(state)
      if (doubtfulTransactionCurrentPurseBalance && (doubtfulTransactionCurrentPurseBalance + totalPrice === data.purseBalance)) {
        result = true
      } else {
        result = await dispatch(topUpLeap(data.cardIdentifier, totalPrice * 100, leapTopUpPaymentCode(state)))
      }

      if (result) {
        await dispatch(getBooking(_bookingNumberSelector(state)))
        await dispatch(addOrUpdateAdditionalDetails(getState().machine.leap.topUp, result, LEAP_TOP_UP_KEY))
      } else if (checkDoubtfulTransactionMessageSelector(getState())) {
        await dispatch(leapDoubtfulTransaction())
        if (!doubtfulTransactionCurrentPurseBalance) {
          dispatch(leapDoubtfulTransactionCurrentPurseBalance(data.purseBalance))
        }
        errorMessage = LEAP_ERROR_MESSAGE_LEAP_DOUBTFUL_TRANSACTION
      } else {
        errorMessage = LEAP_ERROR_MESSAGE_TOP_UP_FAILED
      }
    }
  }

  dispatch(topUpLeapEnd(errorMessage))
}
