import { createSelector } from 'reselect'
import { stationMapper } from '../../orientation/stations'
import { TRAVEL_DIRECTION_OUTBOUND, TRAVEL_DIRECTION_INBOUND } from '../../../../../constants'

export const travelSelector = direction => {
  const travelDirection = direction === TRAVEL_DIRECTION_OUTBOUND ? 'outward' : 'return'
  return createSelector(
    [state => state.api.orientation.offer],
    offer => {
      if (!offer || !offer.travels.some(travel => travel.direction === direction || travel.direction === travelDirection)) {
        return null
      }

      const outboundTravel = offer.travels.find(travel => travel.direction === TRAVEL_DIRECTION_OUTBOUND || travel.direction === 'outward')
      const inboundTravel = offer.travels.find(travel => travel.direction === TRAVEL_DIRECTION_INBOUND || travel.direction === 'return')

      const departureStation = (outboundTravel || inboundTravel).origin_station
      const arrivalStation = (outboundTravel || inboundTravel).destination_station

      return {
        direction,
        departureStation: departureStation && typeof departureStation === 'object' ? stationMapper(departureStation) : departureStation,
        arrivalStation: arrivalStation && typeof arrivalStation === 'object' ? stationMapper(arrivalStation) : arrivalStation
      }
    }
  )
}
