import React, {Component} from 'react'
import PageNotFound from '../../components/error/page-not-found'
import BasicLayout from '../../components/base/basic-layout'

export default class ErrorLayout extends Component {
  render () {
    return (
      <BasicLayout>
        <PageNotFound />
      </BasicLayout>
    )
  }
}
