import DOMPurify from 'dompurify'
import Validator from './validator'
import {createSelector} from 'reselect'

import {isBookingQueueEnabled} from '../redux/selectors/api/booking/notes'
import {overviewBookingSelector} from '../redux/selectors/api/booking/booking'

export default createSelector(
  [isBookingQueueEnabled(overviewBookingSelector)],
  bookingQueueEnabled => ({
    fields: {
      queue: {
        validators: [],
        disabled: !bookingQueueEnabled
      },
      content: {
        normalizers: [
          DOMPurify.sanitize
        ],
        validators: [
          Validator.isRequired,
          Validator.minLength(3)
        ]
      }
    }
  })
)
