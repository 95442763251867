import { connect } from 'react-redux'
import {
  openAccordionGroupItem,
  closeAccordionGroupItem
} from '../../redux/actions/containers/base/accordion-group'
import AccordionGroup from '../../components/base/accordion-group'

const mapStateToProps = (state, ownProps) => state.containers.base.accordionGroup[ownProps.name] || {}

const mapDispatchToProps = {
  openAccordionGroupItem,
  closeAccordionGroupItem
}

export default connect(mapStateToProps, mapDispatchToProps)(AccordionGroup)
