import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Mousetrap from 'mousetrap'

export default class ClickableRow extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired
  }

  componentDidMount () {
    this.mousetrap = new Mousetrap(this._element)
    this.mousetrap.bind('enter', this.props.onClick)
    this.mousetrap.bind('space', this.props.onClick)
  }

  componentWillUnmount () {
    this.mousetrap.reset()
    this.mousetrap = null
  }

  render () {
    return <tr role='link' tabIndex='0' ref={element => { this._element = element }} {...this.props} />
  }
}
