import PropTypes from 'prop-types'
import React from 'react'
import Mousetrap from 'mousetrap'
import 'mousetrap/plugins/global-bind/mousetrap-global-bind'
import {connect} from 'react-redux'

// eslint-disable-next-line func-style
function ChannelFactory () {
  this.subscribers = {}

  this.publish = (channel, data) => {
    return (this.subscribers[channel] || []).reduce(
      (_, handler) => handler(channel, data) !== false,
      true
    )
  }

  this.subscribe = (channel, callback) => {
    if (this.subscribers[channel]) {
      this.subscribers[channel].push(callback)
    } else {
      this.subscribers[channel] = [callback]
    }
  }

  this.unsubscribe = (channel, callback) => {
    this.subscribers[channel] = this.subscribers[channel].filter(handler => handler !== callback)
  }
}

const channels = new ChannelFactory()

class HotKeys extends React.Component {
  static propTypes = {
    keyMap: PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        sequence: PropTypes.string.isRequired,
        global: PropTypes.bool,
        action: PropTypes.string
      })
    ])),
    handlers: PropTypes.objectOf(PropTypes.func),
    enabled: PropTypes.bool,
    loading: PropTypes.bool
  }

  static defaultProps = {
    enabled: true
  }

  constructor (...args) {
    super(...args)

    this.handleEvent = this.handleEvent.bind(this)
  }

  componentDidMount () {
    if (this.props.keyMap) {
      Object.keys(this.props.keyMap).forEach(channelName => {
        const config = this.props.keyMap[channelName]
        if (typeof config === 'string') {
          Mousetrap.bind(config, event => channels.publish(channelName, event))
        } else {
          const bindFunction = config.global ? Mousetrap.bindGlobal : Mousetrap.bind
          return bindFunction(
            config.sequence,
            event => channels.publish(channelName, event),
            config.action
          )
        }
      })
    }

    if (this.props.handlers) {
      Object.keys(this.props.handlers).forEach(channelName => {
        channels.subscribe(channelName, this.handleEvent)
      })
    }
  }

  componentWillUnmount () {
    if (this.props.keyMap) {
      Object.keys(this.props.keyMap).forEach(channelName => {
        const config = this.props.keyMap[channelName]
        const sequence = typeof config === 'string' ? config : config.sequence
        Mousetrap.unbind(sequence)
      })
    }

    if (this.props.handlers) {
      Object.keys(this.props.handlers).forEach(channelName => {
        channels.unsubscribe(channelName, this.handleEvent)
      })
    }
  }

  handleEvent (channelName, data) {
    if (this.props.enabled && this.props.handlers[channelName] && !this.props.loading) {
      return this.props.handlers[channelName](data)
    }
  }

  render () {
    return Array.isArray(this.props.children) ? (
      <react-wrapper>{this.props.children}</react-wrapper>
    ) : this.props.children || null
  }
}

const isLoading = loadingState => Object.values(loadingState).some(loading => loading === true)

const mapStateToProps = state => ({
  loading: [state.api.v2.loading, state.api.loading, state.machine.loading].some(isLoading)
})

export default connect(mapStateToProps)(HotKeys)
