import React from 'react'
import { Route } from 'react-router'
import containAuth from '../../containers/base/auth'
import DetailsLayout from '../../layouts/reservations/booking-details'
import { AGENT_ROLE_OPERATOR } from '../../constants'

export default (
  <Route
    key='reservations-booking-details'
    path='/:lang/reservations/details'
    component={containAuth(DetailsLayout, {roles: [AGENT_ROLE_OPERATOR]})}
  />
)
