import { createSelector } from 'reselect'
import moment from 'moment'
import { currentAgentSelector } from 's3p-js-lib/src/redux/selectors/api/user/agent'

export const willPasswordExpireSoon = createSelector(
  [currentAgentSelector],
  currentAgent => {
    const expireDateTime = moment(currentAgent.passwordExpiryDateTime)
    const expiresInDays = Math.ceil(expireDateTime.diff(moment(), 'days', true))
    const willExpireSoon = expiresInDays >= 0 && expiresInDays <= 15

    return {
      willExpireSoon,
      expiresInDays
    }
  }
)
