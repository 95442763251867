import {
  API_OAUTH_LOGOUT,
  API_OAUTH_REFRESH_TOKEN_FAILURE,
  CLEAR_STATE
} from '../../../../actions/types'
import storage from '../../../../../storage'

export const initialState = null

const defaultState = storage.get('crm_user_information') || initialState

export default (state = defaultState, action) => {
  switch (action.type) {
    case API_OAUTH_REFRESH_TOKEN_FAILURE:
    case API_OAUTH_LOGOUT:
      storage.clear('crm_user_information')
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes('api.user.crm.user') ? initialState : state
    default:
      return state
  }
}
