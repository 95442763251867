import agents from './agents'
import user from './user'
import foundCustomers from './found-customers'
import shift from './shift'

export default {
  agents,
  user,
  foundCustomers,
  shift
}
