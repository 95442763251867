import {
  USER_INPUT_PENDING_SELECT_FULFILLMENT_METHOD,
  API_CREATE_PROVISIONAL_BOOKING_REQUEST,
  CLEAR_STATE
} from '../../../actions/types'
import { API_V2_CREATE_BOOKING_REQUEST } from '../../../actions/types-v2'

export const initialState = {
  pendingFulfillmentMethod: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case API_V2_CREATE_BOOKING_REQUEST:
    case API_CREATE_PROVISIONAL_BOOKING_REQUEST:
      return initialState
    case USER_INPUT_PENDING_SELECT_FULFILLMENT_METHOD:
      return {...state, pendingFulfillmentMethod: action.fulfillmentMethod}
    case CLEAR_STATE:
      return action.namespaces.includes('userInput.base.fulfillmentMethods') ? initialState : state
    default:
      return state
  }
}
