import {
  USER_INPUT_DISABLE_SHORTCUT_EDIT_MODE,
  USER_INPUT_ENABLE_SHORTCUT_EDIT_MODE,
  USER_INPUT_EDIT_SHORTCUT,
  UI_SHORTCUT_SAVED
} from '../../../actions/types'
import {USER_INPUT_FORM_CHANGE_FIELD} from 's3p-js-lib/src/redux/actions/types'
import {FORM_EDIT_SHORTCUT} from '../../../../constants'

const initialState = {
  editModeEnabled: false,
  selectedShortcutNr: 1,
  lastSavedShortcut: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_INPUT_EDIT_SHORTCUT:
      return {...state, selectedShortcutNr: action.sequenceNumber, lastSavedShortcut: null}
    case USER_INPUT_ENABLE_SHORTCUT_EDIT_MODE:
      return {editModeEnabled: true}
    case USER_INPUT_DISABLE_SHORTCUT_EDIT_MODE:
      return {editModeEnabled: false}
    case UI_SHORTCUT_SAVED:
      return {...state, lastSavedShortcut: action.sequenceNumber}
    case USER_INPUT_FORM_CHANGE_FIELD:
      return action.formName === FORM_EDIT_SHORTCUT && state.lastSavedShortcut !== null
        ? {...state, lastSavedShortcut: null}
        : state
    default:
      return state
  }
}
