import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../../../../elements/text-label/text-label'
import _t from 's3p-js-lib/src/translate'

export default class DiscountDetailsRow extends Component {
  static propTypes = {
    totalDiscountPrice: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    discounts: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired
    })).isRequired
  }

  render () {
    const {totalDiscountPrice, currency, discounts} = {...this.props}
    return (
      <tr>
        <td>&nbsp;</td>
        <td data-th-label={_t.message('aftersales.booking.journey-overview.price-details-tooltip.discount')}>
          <TextLabel
            name='total-discount'
            text={_t.message('aftersales.booking.journey-overview.price-details-tooltip.total-discount')} />
          <TextLabel name='discount-type' text={`(${discounts.map(discount => discount.name).join(', ')})`} />
        </td>
        <td data-th-label={_t.message('aftersales.booking.journey-overview.price-details-tooltip.price')}>
          <TextLabel name='total-discount-price' text={`-${_t.formatCurrency(totalDiscountPrice, currency)}`} />
        </td>
      </tr>
    )
  }
}
