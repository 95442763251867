import {
  createV1SelectBundleResponseSelector as baseCreateV1SelectBundleResponseSelector,
  mapTravelBundleItemPassengerFareToProduct
} from './select-bundle-mapper'
import {bundleAndBundleItemByItemIdSelector} from 's3p-js-lib/src/redux/selectors/api/orientation/offer'

export const createV1SelectBundleResponseSelector = (
  journeyId,
  travelId,
  routeId,
  bundleId,
  selectedSeats,
  seatPrefereneces,
  additionalProducts
) => state => {
  let addonProductTotalPriceAdjustment = 0
  const response = baseCreateV1SelectBundleResponseSelector(journeyId, travelId, routeId, bundleId)(state)
  delete response.data.selected_bundles
  response.data.orientation_booking_specification.selected_seats = selectedSeats
  response.data.orientation_booking_specification.seat_preferences = seatPrefereneces
  response.data.orientation_booking_specification.additional_products = additionalProducts.map(additionalProduct => {
    const {bundle, bundleItem} = bundleAndBundleItemByItemIdSelector(additionalProduct.item_id)(state)
    const product = mapTravelBundleItemPassengerFareToProduct(
      travelId,
      bundle,
      bundleItem,
      bundleItem.passenger_fares.find(passengerFare => passengerFare.passenger_id === additionalProduct.passenger_id)
    )
    addonProductTotalPriceAdjustment += product.price
    return product
  })
  response.data.orientation_booking_specification.total_price += addonProductTotalPriceAdjustment

  return response
}
