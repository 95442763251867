import {
  getMagstripeTickets,
  confirmMagstripe
} from '../../../api/module/magstripe'
import { printMachineMagneticTicket } from '../../../machine/magnetic-ticket-printer'
import {
  addAdditionalDetails,
  updateAdditionalDetails
} from 's3p-js-lib/src/redux/actions/api/v2/additional-details'
import {
  fulfillmentMethodSelector,
  bookingNumberSelector,
  additionalDetailsBookingSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { completedOrOverviewBookingSelector } from '../../../../selectors/api/booking/booking'
import { additionalDetailsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/additional-details'
import {
  counterSelector,
  serialSelector
} from '../../../../selectors/machine/magnetic-ticket-printer'
import { isOfflineSelectorCreator } from '../../../../../redux/selectors/machine/offline'
import { pinDocumentSelector } from 's3p-js-lib/src/redux/selectors/api/booking/ticket-documents'
import { productsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/products'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import {
  ADDITIONAL_DETAILS_MAGSTRIPE_COUNTER,
  DOCUMENT_TEMPLATE_NAME_MAGSTRIPE_TICKET,
  ADDITIONAL_DETAILS_MAGSTRIPE_STATUS,
  TICKET_PRINTER_DEVICE_SERIAL,
  EXTERNAL_REFERENCE_MAGSTRIPE_TEMPLATE_NAME,
  DEVICE_ID_TICKET_PRINTER,
  PRODUCT_TYPE_TICKET_ONLY
} from '../../../../../constants'
import {
  startPrintingTickets,
  printingTicketNumber,
  stopPrintingTickets,
  errorPrintingTickets,
  retrieveAndPrintCycleStart,
  retrieveAndPrintCycleEnd
} from './print-ticket-modal'
import { findMetaDataByKey } from '../../../../../misc/utils'
import _t from 's3p-js-lib/src/translate'
import moment from 'moment'

const ticketPrinterOfflineSelector = isOfflineSelectorCreator(DEVICE_ID_TICKET_PRINTER)
const _fulfillmentMethodSelector = fulfillmentMethodSelector(completedOrOverviewBookingSelector)
const _bookingNumberSelector = bookingNumberSelector(completedOrOverviewBookingSelector)
const _additionalDetailsBookingNumberSelector = bookingNumberSelector(additionalDetailsBookingSelector)
const _pinDocumentSelector = pinDocumentSelector(completedOrOverviewBookingSelector)
const _productsSelector = productsSelector(completedOrOverviewBookingSelector)
const _additionalDetailsSelector = additionalDetailsSelector(completedOrOverviewBookingSelector)
const _additionalDetailsAdditionalDetailsBookingSelector = additionalDetailsSelector(additionalDetailsBookingSelector)

const persistAdditionalDetails = ticketNumbers => async (dispatch, getState) => {
  const state = getState()
  const status = state.machine.magneticTicketPrinter.lastStatus
  const bookingNumber = _bookingNumberSelector(state)
  const additionalDetailsBookingNumber = _additionalDetailsBookingNumberSelector(state)
  const products = _productsSelector(state)
  const additionalDetailsBooking = additionalDetailsBookingNumber && additionalDetailsBookingNumber === bookingNumber
    ? _additionalDetailsAdditionalDetailsBookingSelector(state)
    : _additionalDetailsSelector(state)
  const counter = counterSelector(state)
  const serial = serialSelector(state)

  const newAdditionalDetails = []
  const updatedAdditionalDetails = []

  const processAdditionalDetail = (itemId, itemRefId, key, value) => {
    const additionalDetail = additionalDetailsBooking.find(additionalDetail =>
      additionalDetail.itemRef === itemId &&
      additionalDetail.key === key
    )
    const additionalDetails = additionalDetail ? updatedAdditionalDetails : newAdditionalDetails
    additionalDetails.push({
      itemRefId,
      key,
      value: additionalDetail ? `${additionalDetail.value},${value}` : value.toString()
    })
  }

  for (const ticketNumber of ticketNumbers) {
    const product = products.find(product => product.ticketNumber === ticketNumber)
    if (status) {
      processAdditionalDetail(product.itemId, product.itemRef, ADDITIONAL_DETAILS_MAGSTRIPE_STATUS, status)
    }
    if (counter) {
      processAdditionalDetail(product.itemId, product.itemRef, ADDITIONAL_DETAILS_MAGSTRIPE_COUNTER, counter)
    }
    if (serial) {
      processAdditionalDetail(product.itemId, product.itemRef, TICKET_PRINTER_DEVICE_SERIAL, serial)
    }
  }

  if (updatedAdditionalDetails.length) {
    await dispatch(updateAdditionalDetails(bookingNumber, updatedAdditionalDetails))
  }
  if (newAdditionalDetails.length) {
    await dispatch(addAdditionalDetails(bookingNumber, newAdditionalDetails))
  }
}

export const printMagstripeTickets = () => async (dispatch, getState) => {
  await dispatch(retrieveAndPrintCycleStart())

  const state = getState()
  const bookingNumber = _bookingNumberSelector(state)
  const fulfillmentMethod = _fulfillmentMethodSelector(state)
  const pinDocument = _pinDocumentSelector(state)

  if (pinDocument) {
    await dispatch(getMagstripeTickets(bookingNumber, fulfillmentMethod.code, pinDocument.pin))
    if (!ticketPrinterOfflineSelector(getState())) {
      await dispatch(printTickets(bookingNumber))
    } else {
      dispatch(errorPrintingTickets('Failed to print ticket(s) due to offline ticket printer'))
    }
  } else {
    dispatch(errorPrintingTickets('No pin document found in booking'))
  }
  await dispatch(retrieveAndPrintCycleEnd())
}

export const continueMagstripeTickets = () => async (dispatch, getState) => {
  const state = getState()

  await dispatch(printTickets(
    _bookingNumberSelector(state),
    state.containers.base.finalizeBooking.printTicketsModal.ticketNumber
      ? state.containers.base.finalizeBooking.printTicketsModal.ticketNumber - 1
      : 0
  ))
}

const convertDate = date => moment(date).format('DD-MM-YYYY')
const convertTime = time => moment(time).format('HH:mm')

const prepareMagstripeObject = data => {
  return {
    confirmed_date_time: data.confirmed_date_time || '',
    customer_first_name: data.customer_first_name || '',
    customer_last_name: data.customer_last_name || '',
    destination: data.destination || '',
    destination_irish: data.destination_irish || '',
    origin: data.origin || '',
    origin_irish: data.origin_irish || '',
    magstripe: data.magstripe || '',
    customer_title: data.customer_title || '',
    outward_arrival_date: data.outward_arrival ? convertDate(data.outward_arrival) : '',
    outward_arrival_time: data.outward_arrival ? convertTime(data.outward_arrival) : '',
    outward_departure_date: data.outward_departure ? convertDate(data.outward_departure) : '',
    outward_departure_time: data.outward_departure ? convertTime(data.outward_departure) : '',
    outward_product_family_code: data.outward_product_family_code || '',
    outward_comfort_class: data.outward_comfort_class || '',
    outward_seats_reserved: data.outward_seats_reserved || '',
    return_arrival_date: data.return_arrival ? convertDate(data.return_arrival) : '',
    return_arrival_time: data.return_arrival ? convertTime(data.return_arrival) : '',
    return_departure_date: data.return_departure ? convertDate(data.return_departure) : '',
    return_departure_time: data.return_departure ? convertTime(data.return_departure) : '',
    return_product_family_code: data.return_product_family_code || '',
    return_comfort_class: data.return_comfort_class || '',
    return_seats_reserved: data.return_seats_reserved || '',
    passenger_first_name: data.passenger_first_name || '',
    passenger_last_name: data.passenger_last_name || '',
    passenger_title: data.passenger_title || '',
    passenger_type: data.passenger_type || '',
    passenger_card_code: data.passenger_card_code || '',
    passenger_card_id: (data.passenger_card_id || '').replace(/.(?=.{5})/g, 'X'),
    price: _t.formatNumber(data.price || 0.0, 'decimal'),
    tariff_name: data.tariff_name || '',
    tariff_name_irish: data.tariff_name_irish || ''
  }
}

const printTickets = (bookingNumber, start = 0) => async (dispatch, getState) => {
  const state = getState()
  const products = _productsSelector(state)
  if (state.api.modules.magstripe.tickets && bookingNumber === state.api.modules.magstripe.bookingNumber) {
    const tickets = state.api.modules.magstripe.tickets.map(ticket => {
      const {magstripe: magneticData, ticket_numbers: ticketNumbers, ...data} = ticket

      const templateName = products.reduce(
        (name, product) => {
          if (!name && ticketNumbers.includes(product.ticketNumber)) {
            const metaData = findMetaDataByKey(product.metaData || [], EXTERNAL_REFERENCE_MAGSTRIPE_TEMPLATE_NAME)
            name = (metaData && metaData.value) || null
          }

          return name
        },
        null
      )

      const ticketProduct = products.find(product => product.ticketNumber)
      data.isOpenTicket = ticketProduct && ticketProduct.type === PRODUCT_TYPE_TICKET_ONLY

      return {
        template: templateName || DOCUMENT_TEMPLATE_NAME_MAGSTRIPE_TICKET,
        data: {...prepareMagstripeObject(data), ticket_number: ticketNumbers[0]},
        magneticData,
        ticketNumbers
      }
    })

    dispatch(startPrintingTickets(tickets.length))

    const fulfillmentDetail = [{
      key: 'Fulfillment', value: 'Magstripe'
    }, {
      key: 'Printed on location',
      value: state.machine.status.data.find(data => data.id === 'NADManControl').details.machine_name
    }]

    let index
    for (index = start; index < tickets.length; index++) {
      dispatch(printingTicketNumber(index + 1))
      const result = await dispatch(printMachineMagneticTicket(tickets[index]))
      await dispatch(persistAdditionalDetails(tickets[index].ticketNumbers))

      if (result) {
        await dispatch(confirmMagstripe(bookingNumber, {
          fulfillmentDetail,
          ticketNumbers: tickets[index].ticketNumbers
        }))
      } else {
        break
      }
    }

    if (index === tickets.length) {
      dispatch(clearState('api.modules.magstripe'))
    }

    dispatch(stopPrintingTickets())
  } else {
    dispatch(errorPrintingTickets('No tickets available or doesn\'t match with completed booking'))
  }
}
