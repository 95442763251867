import React, { Component } from 'react'
import GenericLayout from '../../generic-layout'
import MainMenu from '../../../containers/base/main-menu/main-menu'
import VoidTickets from '../../../containers/aftersales/change-seats/void-tickets/void-tickets'
import ProgressNavigation from '../../../containers/aftersales/change-seats/void-tickets/progress-navigation'
import Operation from '../../../containers/aftersales/operation/operation'

export default class VoidTicketsLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <MainMenu />
        <Operation
          labelTitle='aftersales.void-tickets.header.title'
          labelInformation='aftersales.void-tickets.header.information'
        />
        <VoidTickets />
        <ProgressNavigation />
      </GenericLayout>
    )
  }
}
