import { connect } from 'react-redux'
import AgentDetails from '../../../components/aftersales/booking/agent-details'
import { agentSelector } from 's3p-js-lib/src/redux/selectors/api/booking/agent'
import { overviewBookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'

const _agentSelector = agentSelector(overviewBookingSelector)

const mapStateToProps = state => {
  const booking = overviewBookingSelector(state)

  return {
    affiliateCode: booking && booking.affiliate_code ? booking.affiliate_code : '',
    salesChannelCode: booking && booking.sales_channel_code ? booking.sales_channel_code : null,
    agent: _agentSelector(state)
  }
}
export default connect(mapStateToProps)(AgentDetails)
