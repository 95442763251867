import { connect } from 'react-redux'
import { compose } from 'redux'
import { overviewBookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import containVisibleElement from '../../../../containers/base/visible-element'
import {
  isLoadingToolbarActionsSelector,
  isOverrideAftersalesRulesSelector
} from '../../../../redux/selectors/containers/aftersales/booking/toolbar'
import { rebookingAllowedSelector } from '../../../../redux/selectors/api/booking/after-sales'
import ToolbarItem from '../../../../components/aftersales/booking/toolbar/toolbar-item'
import { isRebookAvailableSelector } from '../../../../redux/selectors/containers/base/aftersales'
import { goToRebooking } from '../../../../redux/actions/containers/aftersales/rebooking/rebooking'

const _isRebookAvailableSelector = isRebookAvailableSelector(overviewBookingSelector)
const _isOverrideAftersalesRulesSelector = isOverrideAftersalesRulesSelector(rebookingAllowedSelector)

const mapStateToProps = state => ({
  loading: state.api.loading.rebookingInitiate,
  disabled: isLoadingToolbarActionsSelector(state),
  available: _isRebookAvailableSelector(state),
  overrideAftersalesRules: _isOverrideAftersalesRulesSelector(state)
})

const mapDispatchToProps = {
  onClick: goToRebooking
}

const container = compose(
  connect(mapStateToProps, mapDispatchToProps),
  containVisibleElement()
)

export default container(ToolbarItem)
