import { createSelector } from 'reselect'
import { formFieldValueSelectorCreator } from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import {
  EXTERNAL_REFERENCE_SHOW_ON_BOM_OPEN_TICKET_SALE,
  EXTERNAL_REFERENCE_ONLY_BOOKABLE_BEFORE_DAY_OF_CURRENT_MONTH
} from '../../../../../constants'
import { FORM_PRODUCT_SEARCH } from 's3p-js-lib/src/constants-form'
import {
  productFamiliesSelector as baseProductFamiliesSelector
} from 's3p-js-lib/src/redux/selectors/api/v2/meta/product-families'
import isString from 'lodash/isString'

const formFieldValueSelector = formFieldValueSelectorCreator(FORM_PRODUCT_SEARCH, 'productFamily')

const showOnTickets = attributes => attributes.some(
  attribute => attribute.key === EXTERNAL_REFERENCE_SHOW_ON_BOM_OPEN_TICKET_SALE &&
    isString(attribute.value) &&
    (attribute.value.toLowerCase() === 'true' || attribute.value === '1')
)

export const productFamiliesSelector = createSelector(
  [baseProductFamiliesSelector],
  productFamilies => productFamilies.filter(productFamily => showOnTickets(productFamily.attributes))
)

export const onlyBookableBeforeDayOfCurrentMonthSelector = createSelector(
  [formFieldValueSelector],
  productFamily => {
    const attribute = (productFamily
      ? productFamily.attributes.find(
        attribute => attribute.key === EXTERNAL_REFERENCE_ONLY_BOOKABLE_BEFORE_DAY_OF_CURRENT_MONTH
      )
      : null
    )
    return (attribute ? attribute.value : null)
  }
)
