import { connect } from 'react-redux'
import _t from 's3p-js-lib/src/translate'
import { routerActions } from 'react-router-redux'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import {
  outboundSeatSelectionOptionsSelector,
  inboundSeatSelectionOptionsSelector
} from '../../../redux/selectors/containers/base/seat-selector/seat-selection-options'
import {
  outboundTravelInfoSelector,
  inboundTravelInfoSelector,
  seatSelectionOptionsSelector
} from '../../../redux/selectors/containers/aftersales/seat-change'
import AllocatedSeats from '../../../components/base/seat-selection/allocated-seats'

const _outboundSeatSelectionOptionsSelector = outboundSeatSelectionOptionsSelector(seatSelectionOptionsSelector)
const _inboundSeatSelectionOptionsSelector = inboundSeatSelectionOptionsSelector(seatSelectionOptionsSelector)
const _outboundTravelInfoSelector = outboundTravelInfoSelector(bookingSelector)
const _inboundTravelInfoSelector = inboundTravelInfoSelector(bookingSelector)

const mapStateToProps = state => ({
  outboundSeatSelectionOptions: _outboundSeatSelectionOptionsSelector(state),
  inboundSeatSelectionOptions: _inboundSeatSelectionOptionsSelector(state),
  outboundTravelInfo: _outboundTravelInfoSelector(state),
  inboundTravelInfo: _inboundTravelInfoSelector(state)
})

const mapDispatchToProps = {
  handleManualSeatSelection: firstAvailableLegId => routerActions.push(`/${_t.getLocales()}/aftersales/change-seats/seat-selector/${firstAvailableLegId}`)
}

export default connect(mapStateToProps, mapDispatchToProps)(AllocatedSeats)
