import React, { Component } from 'react'
import PropTypes from '../../../misc/prop-types'
import TextLabel from '../../../elements/text-label/text-label'
import Loader, { TYPE_INLINE } from '../../../elements/loader/loader'
import _t from 's3p-js-lib/src/translate'
import moment from 'moment'

class NextTry extends Component {
  static propTypes = {
    nextTry: PropTypes.moment.isRequired,
    attempt: PropTypes.number.isRequired
  }

  constructor (...args) {
    super(...args)

    this.tick = this.tick.bind(this)

    this.state = {
      nextTryInS: 0
    }
  }

  tick () {
    this.setState({
      nextTryInS: this.props.nextTry.diff(moment(), 's')
    })
  }

  componentDidMount () {
    this.timer = setInterval(this.tick, 1000)
  }

  componentWillUnmount () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  render () {
    return this.state.nextTryInS <= 0
      ? <Loader type={TYPE_INLINE} />
      : <TextLabel text={_t.message('offline.retry-action', {seconds: this.state.nextTryInS})} />
  }
}

export default NextTry
