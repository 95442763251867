import {
  API_GET_PAYMENT_METHODS_REQUEST,
  API_GET_PAYMENT_METHODS_SUCCESS,
  API_GET_PAYMENT_METHODS_FAILURE
} from '../../types'
import Client from '../../../../../../src/api/client'
import ensureToken from '../../../../api/ensure-token'

const getPaymentMethodsRequest = bookingNumber => ({type: API_GET_PAYMENT_METHODS_REQUEST, bookingNumber})
const getPaymentMethodsSuccess = response => ({type: API_GET_PAYMENT_METHODS_SUCCESS, response})
const getPaymentMethodsFailure = error => ({type: API_GET_PAYMENT_METHODS_FAILURE, error})

export const getPaymentMethods = bookingNumber => ensureToken(async (token, dispatch) => {
  try {
    dispatch(getPaymentMethodsRequest(bookingNumber))
    const response = await Client.getPaymentMethods(token, bookingNumber)
    dispatch(getPaymentMethodsSuccess(response))
  } catch (error) {
    dispatch(getPaymentMethodsFailure(error))
    throw error
  }
})
