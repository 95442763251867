import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ComponentContainer from '../../../elements/component-container/component-container'
import Panel from '../../../elements/panel/panel'
import Icon from '../../../elements/icon/icon'
import TextLabel from '../../../elements/text-label/text-label'
import LastApiErrorFeedback from '../../../containers/base/last-api-error-feedback'
import Button from '../../../elements/button/button'
import ButtonGroup from '../../../elements/button-group/button-group'
import _t from 's3p-js-lib/src/translate'
import FinalizeBooking from '../../../containers/base/finalize-booking/finalize-booking'
import VoidTicketsModal from '../../../containers/aftersales/booking/fulfillment/void-tickets-modal'

export default class Fulfillment extends Component {
  static propTypes = {
    onNextSale: PropTypes.func.isRequired,
    onChangeFulfillment: PropTypes.func.isRequired,
    onClickReprintResend: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    loadingNextSale: PropTypes.bool,
    fulfillmentMethod: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    allowReprintResend: PropTypes.bool,
    showVoidTicketsModal: PropTypes.bool
  }

  render () {
    return this.props.fulfillmentMethod
      ? (
        <ComponentContainer name='booking-fulfillment'>
          <Panel
            header={
              <Panel.Header
                name='booking-notes'
                title={_t.message('aftersales.booking.reprint-resend.title', {fulfillment: this.props.fulfillmentMethod.name})}
                description={_t.message('aftersales.booking.reprint-resend.description')}
              />
            }
            footer={
              this.props.allowReprintResend ? (
                <Panel.Footer>
                  <ButtonGroup>
                    <Button
                      name='reprint-resend'
                      className='primary'
                      onClick={this.props.onClickReprintResend}
                      loading={this.props.loading}
                    >
                      <Icon name='add' className='align-left' />
                      <TextLabel.Text text={_t.message('aftersales.booking.reprint-resend.button.reprint-resend')} />
                    </Button>
                  </ButtonGroup>
                </Panel.Footer>
              ) : null
            }
          >
            <LastApiErrorFeedback namespaces={['resendTickets']} />
            {!this.props.allowReprintResend
              ? (
                <TextLabel name='information'>
                  <Icon name='information' className='align-left' />
                  <TextLabel.Text text={_t.message('aftersales.booking.reprint-resend.no-reprint-resend-available')} />
                </TextLabel>
              ) : null
            }
            <FinalizeBooking
              onNextSale={this.props.onNextSale}
              loadingNextSale={this.props.loadingNextSale}
              onChangeFulfillment={this.props.onChangeFulfillment}
              aftersales
            />
          </Panel>
          {this.props.showVoidTicketsModal ? <VoidTicketsModal /> : null}
        </ComponentContainer>
      ) : null
  }
}
