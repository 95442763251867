import { UI_SHOW_FEEDBACK } from '../../../types'
import { CONTAINERS_RESERVATION_BOOKING_DETAILS_CUSTOMER_DETAILS } from '../../../../../constants'

export const showFeedback = () => (dispatch, getState) => {
  if (!getState().containers.reservations.bookingDetails.customerDetails.showFeedback) {
    dispatch({
      type: UI_SHOW_FEEDBACK,
      name: CONTAINERS_RESERVATION_BOOKING_DETAILS_CUSTOMER_DETAILS
    })
  }
}
