import {
  API_FIND_CUSTOMERS_SUCCESS,
  API_FIND_CUSTOMERS_FAILURE,
  API_OAUTH_LOGOUT,
  CLEAR_STATE
} from '../../../../actions/types'

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_FIND_CUSTOMERS_SUCCESS:
      return action.response.customers
    case API_OAUTH_LOGOUT:
    case API_FIND_CUSTOMERS_FAILURE:
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes('api.user.agent.foundCustomers') ? initialState : state
    default:
      return state
  }
}
