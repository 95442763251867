import {
  UI_READ_LEAP_CARD_START,
  UI_READ_LEAP_CARD_END
} from '../../types'
import { processLeapActionList, readLeap } from '../../machine/leap'
import {
  leapDataSelector,
  leapProcessActionListSelector
} from '../../../selectors/machine/leap'
import { confirmLeapAction, getLeapActions } from '../../api/module/leap'
import { LEAP_TIMEOUT } from '../../../../constants'

const readLeapCardStart = () => ({type: UI_READ_LEAP_CARD_START})
const readLeapCardEnd = () => ({type: UI_READ_LEAP_CARD_END})

const processAndConfirmLeapActions = leapData => async (dispatch, getState) => {
  const leapMachineActions = leapProcessActionListSelector(getState())
  for (const index in leapMachineActions) {
    // eslint-disable-next-line no-prototype-builtins
    if (leapMachineActions.hasOwnProperty(index)) {
      await dispatch(confirmLeapAction(
        leapData.cardNumber,
        leapMachineActions[index].id,
        leapMachineActions[index].response,
        leapMachineActions[index].status
      ))
    }
  }
}

export const readLeapCard = () => async (dispatch, getState) => {
  dispatch(readLeapCardStart())
  const response = await dispatch(readLeap())
  if (response) {
    const leapData = leapDataSelector(getState())

    const samIds = leapData.products.reduce(
      (_samIds, product) => {
        if (product.issueSamId && !_samIds.includes(product.issueSamId)) {
          _samIds.push(product.issueSamId)
        }

        return _samIds
      },
      leapData.lastTransaction ? [leapData.lastTransaction.samId] : [null]
    )

    for (const samId of samIds) {
      const response = await dispatch(getLeapActions(leapData.cardNumber, samId || undefined))
      if (response) {
        let actions = getState().api.modules.leap.getActions
        let response = false
        if (actions.length) {
          response = await dispatch(processLeapActionList(LEAP_TIMEOUT, leapData.cardIdentifier, actions))
        }

        if (response) {
          dispatch(processAndConfirmLeapActions(leapData))
        }
      }
    }
  }
  dispatch(readLeapCardEnd())
}
