import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import ComponentContainer from '../../../elements/component-container/component-container'
import Panel from '../../../elements/panel/panel'
import TextLabel from '../../../elements/text-label/text-label'
import './refund-details.scss'

export default class RefundDetails extends Component {
  static propTypes = {
    hasProvisionalBooking: PropTypes.bool.isRequired,
    ticketValue: PropTypes.number.isRequired,
    fee: PropTypes.number,
    total: PropTypes.number.isRequired
  }

  render () {
    return this.props.hasProvisionalBooking
      ? (
        <ComponentContainer name='cancellation-refund-details'>
          <Panel
            header={
              <Panel.Header
                name='cancellation-refund-details'
                title={_t.message('aftersales.cancellation.refund-details.title')}
                description={_t.message('aftersales.cancellation.refund-details.description')}
              />
            }
          >
            <div className='refund-details-item'>
              <div className='refund-details-item__type'>
                <TextLabel
                  name='refund-type'
                  text={_t.message('aftersales.cancellation.refund-details.ticket-value')}
                />
              </div>
              <div className='refund-details-item__price'>
                <TextLabel
                  name='refund-price'
                  text={_t.formatCurrency(this.props.ticketValue)}
                />
              </div>
            </div>
            {this.props.fee
              ? (
                <div className='refund-details-item'>
                  <div className='refund-details-item__type'>
                    <TextLabel
                      name='refund-type'
                      text={_t.message('aftersales.cancellation.refund-details.fee')}
                    />
                  </div>
                  <div className='refund-details-item__price'>
                    <TextLabel
                      name='refund-price'
                      text={_t.formatCurrency(this.props.fee)}
                    />
                  </div>
                </div>
              ) : null}
            <div className='refund-details-item'>
              <div className='refund-details-item__type'>
                <TextLabel
                  name='total-refund-type'
                  text={_t.message('aftersales.cancellation.refund-details.total')}
                />
              </div>
              <div className='refund-details-item__price'>
                <TextLabel
                  name='total-refund-price'
                  text={_t.formatCurrency(this.props.total)}
                />
              </div>
            </div>
          </Panel>
        </ComponentContainer>
      ) : null
  }
}
