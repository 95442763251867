import { createSelector } from 'reselect'
import {
  CATEGORY_BOOKING_NUMBER,
  CATEGORY_CUSTOMER_NAME,
  CATEGORY_CUSTOMER_EMAIL,
  CATEGORY_CUSTOMER_COMPANY,
  CATEGORY_PASSENGER_NAME,
  CATEGORY_PASSENGER_EMAIL
} from '../../../../forms/bookings-filter'
import { AGENT_PERMISSION_BOOKING_ACCESS_RESTRICT_SEARCH_TO_BOOKING_REFERENCE } from '../../../../constants'
import { agentPermissionsSelector } from '../../api/user/agent/agent-permissions'

export const availableCategoriesSelector = createSelector(
  agentPermissionsSelector,
  permissions => {
    const searchCategories = [CATEGORY_BOOKING_NUMBER]
    if (!permissions.includes(AGENT_PERMISSION_BOOKING_ACCESS_RESTRICT_SEARCH_TO_BOOKING_REFERENCE)) {
      searchCategories.push(
        CATEGORY_CUSTOMER_NAME,
        CATEGORY_CUSTOMER_EMAIL,
        CATEGORY_CUSTOMER_COMPANY,
        CATEGORY_PASSENGER_NAME,
        CATEGORY_PASSENGER_EMAIL
      )
    }

    return searchCategories
  }
)
