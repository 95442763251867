import { createSelector } from 'reselect'
import Validator from 's3p-js-lib/src/forms/validator'
import { FORM_EDIT_SHORTCUT } from '../constants'
import { cannotBeSameStation } from 's3p-js-lib/src/forms/product-search'
import { disabledOriginDestination } from './product-search'
import { formFieldValueSelectorCreator } from 's3p-js-lib/src/redux/selectors/user-input/base/form'

export default createSelector(
  [formFieldValueSelectorCreator(FORM_EDIT_SHORTCUT, 'productFamily')],
  productFamilyValue => {
    const disableStations = productFamilyValue && disabledOriginDestination(productFamilyValue.attributes)
    return {
      fields: {
        origin: {
          validators: [
            Validator.isRequired,
            Validator.isValidStationObject
          ],
          disabled: disableStations
        },
        destination: {
          validators: [
            Validator.isRequired,
            Validator.isValidStationObject,
            cannotBeSameStation
          ],
          disabled: disableStations
        },
        dateInterval: {},
        passengers: {
          validators: [
            Validator.arrayAtLeastOneItem
          ]
        },
        productFamily: {
          validators: [
            Validator.isRequired
          ]
        },
        product: {},
        productFilter: {},
        discountCard: {}
      }
    }
  }
)
