import {ADDITIONAL_PRODUCT_TYPES} from '../../../../../constants'
import moment from 'moment-timezone'

export const createProductSearchRequest = getBookingResponseBooking => {
  const productSearchRequest = {
    currency: getBookingResponseBooking.currency,
    is_aftersales: true,
    passengers: getBookingResponseBooking.passengers.map(passenger => ({
      id: passenger.id,
      type: passenger.type,
      discount_cards: passenger.discount_cards,
      disability_type: passenger.disability_type
    })),
    product_family: getBookingResponseBooking.product_families[0].code,
    product_types: ADDITIONAL_PRODUCT_TYPES,
    segments: [
      ...mapBookingResponseTariffSegmentsToProductSearchRequestSegments(
        'outward',
        getBookingResponseBooking.outbound_booking_tariff_segments
      ),
      ...mapBookingResponseTariffSegmentsToProductSearchRequestSegments(
        'return',
        getBookingResponseBooking.inbound_booking_tariff_segments
      )
    ]
  }
  if (getBookingResponseBooking?.agent?.contract_code) {
    productSearchRequest.contract_code = getBookingResponseBooking.agent.contract_code
  }
  return productSearchRequest
}

const mapBookingResponseTariffSegmentsToProductSearchRequestSegments = (direction, bookingResponseTariffSegments) => {
  return bookingResponseTariffSegments
    .filter(tariffSegment => tariffSegment.required_products.every(
      requiredProduct => !requiredProduct.cancelled && !requiredProduct.provisional
    ))
    .flatMap(
      tariffSegment => tariffSegment.booking_journey_segments.map(journeySegment => ({
        origin_station: journeySegment.departure_station._u_i_c_station_code,
        destination_station: journeySegment.arrival_station._u_i_c_station_code,
        direction,
        start_validity_date: moment.tz(journeySegment.departure_date_time, journeySegment.timezone).utc().format('YYYY-MM-DD'),
        start_validity_time: moment.tz(journeySegment.departure_date_time, journeySegment.timezone).utc().format('HH:mm:ss'),
        service_type: journeySegment.service_type.code,
        service_name: journeySegment.service_name,
        comfort_zone_code: tariffSegment.comfort_zone_code,
        id: tariffSegment.id
      }))
    )
}

export const mapAdditionalProducts = (responseProductSearchProducts, responseBooking) => {
  const additionalProducts = responseProductSearchProducts.segments.reduce((segmentAdditionalProducts, segment) => {
    const tariffSegment = responseBooking[
      segment.direction === 'outward' ? 'outbound_booking_tariff_segments' : 'inbound_booking_tariff_segments'
    ].find(tariffSegment => tariffSegment.id === segment.id)
    const tariffJourneySegment = tariffSegment.booking_journey_segments.find(
      journeySegment => journeySegment.departure_station._u_i_c_station_code === segment.origin &&
        journeySegment.arrival_station._u_i_c_station_code === segment.destination &&
        moment(journeySegment.departure_date_time).format('YYYY-MM-DD') === segment.departure_date
    )
    const items = responseProductSearchProducts.items.filter(item => item.segment_ids.includes(segment.id))
    if (items.length) {
      const itemFareIds = items.map(item => item.fare_id)
      segmentAdditionalProducts.push(...responseProductSearchProducts.fares
        .filter(fare => itemFareIds.includes(fare.id))
        .flatMap(fare => fare.passenger_fares.map(passengerFare => {
            const tariff = responseProductSearchProducts.tariffs.find(tariff => tariff.id === passengerFare.tariff_ref)
            const passenger = responseProductSearchProducts.passengers.find(passenger => passenger.id === passengerFare.passenger_ref)
            const product = responseProductSearchProducts.products.find(product => product.code === tariff.product_code)
            return createAdditionalProductItem(
              passengerFare.price,
              tariff.code,
              passenger,
              product,
              segment,
              tariffJourneySegment,
              tariffSegment
            )
          })
        )
      )
    }
    // segment does not have mapped items in product search, but still need to check for existing addons that match
    // tariff, passenger and product references
    segmentAdditionalProducts.push(...tariffSegment.additional_products.reduce(
      (existingMatchedAddonProducts, tariffSegmentAddonProduct) => {
        const tariffCode = tariffSegmentAddonProduct.tariff_code
        const passenger = responseBooking.passengers.find(passenger => passenger.id === tariffSegmentAddonProduct.passenger_id)

        // check if not cancelled and has tariffCode and passenger
        if (!tariffSegmentAddonProduct.cancelled && tariffCode && passenger &&
          // check we haven't already added this product as part of product search mapped items
          !segmentAdditionalProducts.find(
            segmentAddon => segmentAddon.segment.id === tariffSegment.id &&
              segmentAddon.tariff_code === tariffCode &&
              segmentAddon.passenger.id === passenger.id &&
              segmentAddon.product.code === tariffSegmentAddonProduct.code
          ) &&
          // and check we haven't already added this existing product below
          !existingMatchedAddonProducts.find(
            addon => addon.tariff_code === tariffCode &&
              addon.passenger.id === passenger.id &&
              addon.product.code === tariffSegmentAddonProduct.code
          )
        ) {
          existingMatchedAddonProducts.push(createAdditionalProductItem(
            tariffSegmentAddonProduct.price,
            tariffCode,
            passenger,
            tariffSegmentAddonProduct,
            segment,
            tariffJourneySegment,
            tariffSegment
          ))
        }
        return existingMatchedAddonProducts
      }, [])
    )
    return segmentAdditionalProducts
  }, [])

  return additionalProducts
}

const createAdditionalProductItem = (price, tariffCode, passenger, product, segment, tariffJourneySegment, tariffSegment) => {
  const productItem = {
    price,
    tariff_code: tariffCode,
    passenger,
    product,
    segment: {
      id: segment.id,
      direction: segment.direction,
      origin: {
        code: tariffJourneySegment.departure_station._u_i_c_station_code,
        name: tariffJourneySegment.departure_station.name,
        departure_date_time: moment.tz(
          tariffJourneySegment.departure_date_time,
          tariffJourneySegment.departure_station.timezone
        ).utc().format('YYYY-MM-DDTHH:mm:ss+0000'),
        timezone: tariffJourneySegment.departure_station.timezone
      },
      destination: {
        code: tariffJourneySegment.arrival_station._u_i_c_station_code,
        name: tariffJourneySegment.arrival_station.name,
        arrival_date_time: moment.tz(
          tariffJourneySegment.arrival_date_time,
          tariffJourneySegment.arrival_station.timezone
        ).utc().format('YYYY-MM-DDTHH:mm:ss+0000'),
        timezone: tariffJourneySegment.arrival_station.timezone
      },
      departure_date: moment(tariffJourneySegment.departure_date_time).set({
        hour: 0,
        minute: 0,
        second: 0
      }).format('YYYY-MM-DDTHH:mm:ss+0000'),
      service_name: tariffJourneySegment.service_name,
      comfort_zone_code: tariffSegment.comfort_zone_code,
      service_identifier: tariffSegment.service_identifier ||
        [tariffJourneySegment.service_name, tariffJourneySegment.service_type.code, moment(tariffJourneySegment.departure_date_time).format('YYYY-MM-DD')].join('|'),
      validity_start_date: tariffSegment.validity_start_date
    },
    original_quantity: tariffSegment.additional_products.filter(existingAdditionalProduct => {
      return existingAdditionalProduct.code === product.code &&
        existingAdditionalProduct.type === product.type &&
        existingAdditionalProduct.tariff_code === tariffCode &&
        existingAdditionalProduct.passenger_id === passenger.id &&
        !existingAdditionalProduct.cancelled &&
        !existingAdditionalProduct.provisional
    }).length
  }
  const existingProvisionalAddonProduct = tariffSegment.additional_products.find(
    addon =>
      addon.tariff_code === tariffCode &&
      addon.passenger_id === passenger.id &&
      addon.code === product.code &&
      addon.provisional
  )
  if (existingProvisionalAddonProduct) {
    product.item_ref = existingProvisionalAddonProduct.item_ref
    productItem.new_quantity = 1
  }
  return productItem
}
