export const loadingSelector = state => (
  state.api.loading.addVoucher ||
  state.api.loading.getReceiptDocument ||
  state.api.v2.loading.addPayments ||
  state.api.v2.loading.updatePayments ||
  state.api.v2.loading.confirmBooking ||
  state.machine.loading.receiptMachinePrint
)

export const pedPaymentStatusSelector = state =>
  state.containers.base.paymentModal.creditDebit.status

export const abandonPedOperationSelector = state =>
  state.containers.base.paymentModal.creditDebit.addPedPaymentCount > 1
