import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReadTicket from '../../../components/aftersales/read-ticket/read-ticket'
import { selectReadTicket } from '../../../redux/actions/containers/aftersales/read-ticket/read-ticket'
import { readTicketSelector } from '../../../redux/selectors/containers/aftersales/read-ticket'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import { CONTAINERS_AFTERSALES_READTICKET } from '../../../constants'

class ReadTicketContainer extends Component {
  static propTypes = {
    handleClearState: PropTypes.func.isRequired
  }

  componentWillUnmount () {
    this.props.handleClearState()
  }

  render () {
    return <ReadTicket {...this.props} />
  }
}

const mapStateToProps = state => ({
  fulfillmentMethods: readTicketSelector(state),
  isLoading: state.machine.loading.readMagneticTicket || state.containers.aftersales.readTicket.loadingReadBarcode
})

const mapDispatchToProps = {
  handleSelect: selectReadTicket,
  handleClearState: () => clearState(CONTAINERS_AFTERSALES_READTICKET)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReadTicketContainer)
