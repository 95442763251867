import {
  API_GET_SEAT_SELECTION_OPTIONS_REQUEST,
  API_GET_SEAT_SELECTION_OPTIONS_SUCCESS,
  API_GET_SEAT_SELECTION_OPTIONS_FAILURE
} from '../../../types'
import {
  createV1SeatSelectionOptionsResponseSelector
} from 's3p-js-lib/src/redux/actions/api/base/seat-selector/seat-selection-options-mapper'

const getSeatSelectionOptionsRequest = journeyId => ({type: API_GET_SEAT_SELECTION_OPTIONS_REQUEST, journeyId})
const getSeatSelectionOptionsSuccess = response => ({type: API_GET_SEAT_SELECTION_OPTIONS_SUCCESS, response})
const getSeatSelectionOptionsFailure = error => ({type: API_GET_SEAT_SELECTION_OPTIONS_FAILURE, error})

export const getSeatSelectionOptions = journeyId => (dispatch, getState) => {
  try {
    dispatch(getSeatSelectionOptionsRequest(journeyId))
    dispatch(getSeatSelectionOptionsSuccess(createV1SeatSelectionOptionsResponseSelector(journeyId)(getState())))
  } catch (error) {
    dispatch(getSeatSelectionOptionsFailure(error))
    throw error
  }
}
