import React, { Component } from 'react'
import PropTypes from '../../../../misc/prop-types'
import { connect } from 'react-redux'
import { switchDate } from '../../../../redux/actions/containers/base/journey-search/journey-search'
import { journeySearchDateAftersCheapestPrices } from '../../../../redux/selectors/containers/base/journey-search/date-switcher'
import NextDateSet from '../../../../components/base/journey-search/date-switcher/next-date-set'

class NextDateSetContainer extends Component {
  static propTypes = {
    direction: PropTypes.string.isRequired,
    date: PropTypes.moment,
    onSwitchDate: PropTypes.func.isRequired
  }

  constructor (...args) {
    super(...args)
    this.handleNextDateSet = this.handleNextDateSet.bind(this)
  }

  handleNextDateSet () {
    this.props.onSwitchDate(this.props.direction, this.props.date)
  }

  render () {
    return <NextDateSet {...this.props} handleNextDateSet={this.handleNextDateSet} />
  }
}

const mapStateToProps = () => {
  const _journeySearchDateAftersCheapestPrices = journeySearchDateAftersCheapestPrices()

  return (state, ownProps) => ({
    hasSelectedDate: _journeySearchDateAftersCheapestPrices(state, ownProps)
  })
}

const mapDispatchToProps = (_, ownProps) => dispatch => ({
  onSwitchDate: (...args) => dispatch(switchDate(ownProps.rebooking, ...args))
})

export default connect(mapStateToProps, mapDispatchToProps)(NextDateSetContainer)
