import { connect } from 'react-redux'
import { progressBarSelectorCreator } from '../../../redux/selectors/containers/base/progress-bar'
import ProgressBar from '../../../components/base/booking-progress-bar'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { pendingPaymentsSelector } from '../../../redux/selectors/api/booking/payments'

const progressBarSelector = progressBarSelectorCreator('/aftersales/rebooking', true)
const _paymentsSelector = pendingPaymentsSelector(bookingSelector)

const mapStateToProps = state => ({
  ...progressBarSelector(state),
  hasPayments: Boolean(_paymentsSelector(state).length)
})

export default connect(mapStateToProps)(ProgressBar)
