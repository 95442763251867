import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TabGroup from '../../../elements/tab/tab'
import LeapProductData from './leap-product-data'
import ProductTab from './product-tab'
import Feedback, { FEEDBACK_INFORMATION } from '../../../elements/feedback/feedback'
import _t from 's3p-js-lib/src/translate'

export default class LeapProductTabs extends Component {
  static propTypes = {
    selectLeapProduct: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    selectedProduct: PropTypes.object
  }

  render () {
    return this.props.products.length ? (
      <TabGroup>
        <TabGroup.TabList>
          {this.props.products.map((product, index) => (
            <ProductTab
              number={index + 1}
              key={product.key}
              product={product}
              selectLeapProduct={this.props.selectLeapProduct}
              isSelected={this.props.selectedProduct
                ? this.props.selectedProduct.key === product.key
                : this.props.products[0].key === product.key
              }
            />
          ))}
        </TabGroup.TabList>
        <TabGroup.TabPanel id='leap-products-data'>
          {this.props.selectedProduct
            ? <LeapProductData product={this.props.selectedProduct} />
            : this.props.products.length
              ? <LeapProductData product={this.props.products[0]} />
              : null
          }
        </TabGroup.TabPanel>

      </TabGroup>
    ) : <Feedback status={FEEDBACK_INFORMATION} text={_t.message('leap.products.no-products-found')} />
  }
}
