import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selectPassenger } from 's3p-js-lib/src/redux/actions/user-input/base/seat-selector'
import { passengerSelector } from 's3p-js-lib/src/redux/selectors/user-input/base/seat-selector'
import Passenger from '../../../../components/base/seat-selector/passenger-manifest/passenger'
import { EMPTY_OBJECT } from 's3p-js-lib/src/constants'

class PassengerContainer extends Component {
  static propTypes = {
    selectPassenger: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired
  }

  constructor (...args) {
    super(...args)
    this.handleSelect = this.handleSelect.bind(this)
  }

  handleSelect () {
    this.props.selectPassenger(this.props.id)
  }

  render () {
    return <Passenger {...this.props} handleSelect={this.handleSelect} />
  }
}

const mapStateToProps = () => {
  const _passengerSelector = passengerSelector()

  return (state, ownProps) => _passengerSelector(state, ownProps) || EMPTY_OBJECT
}

const mapDispatchToProps = {
  selectPassenger
}

export default connect(mapStateToProps, mapDispatchToProps)(PassengerContainer)
