import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { startShift } from '../../../redux/actions/containers/agent/shift/start'
import { routerActions } from '../../../redux/actions/containers/base/routing'
import { willPasswordExpireSoon } from '../../../redux/selectors/containers/agent/shift/shift'
import connectFormContainer from '../../base/form'
import { FORM_TYPE_AGENT_SHIFT_START } from 's3p-js-lib/src/constants-form'
import Start from '../../../components/agent/shift/start'
import { CONTAINERS_AGENT_SHIFT } from '../../../constants'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'

class StartContainer extends Component {
  static propTypes = {
    clearState: PropTypes.func.isRequired
  }

  UNSAFE_componentWillMount () {
    this.props.clearState(CONTAINERS_AGENT_SHIFT)
  }

  render () {
    return <Start {...this.props} />
  }
}

const mapStateToProps = state => ({
  loading: state.containers.agent.shift.loadingStartShift,
  willPasswordExpireSoon: willPasswordExpireSoon(state)
})

const mapDispatchToProps = {
  onStartShift: startShift,
  onLogout: () => routerActions.push('/agent/login'),
  clearState
}

const container = compose(
  connect(mapStateToProps, mapDispatchToProps),
  connectFormContainer({formName: FORM_TYPE_AGENT_SHIFT_START})
)

export default container(StartContainer)
