import { connect } from 'react-redux'
import SetDarkness from '../../components/maintenance/set-darkness'
import { adjustDarkness } from '../../redux/actions/machine/magnetic-ticket-printer'

const mapStateToProps = state => ({
  isLoadingSetDarkness: state.machine.loading.setDarkness,
  testStatus: state.machine.magneticTicketPrinter.testStatus,
  printerDarkness: state.machine.magneticTicketPrinter.printerDarkness
})

const mapDisplayToProps = {
  increaseDarkness: () => adjustDarkness(true),
  decreaseDarkness: () => adjustDarkness(false)
}

export default connect(mapStateToProps, mapDisplayToProps)(SetDarkness)
