import {
  USER_INPUT_FORM_TOUCH_FIELD,
  USER_INPUT_FORM_TOUCH_FIELDS,
  USER_INPUT_FORM_FOCUS_FIELD,
  USER_INPUT_FORM_BLUR_FIELD,
  USER_INPUT_FORM_CHANGE_FIELD,
  USER_INPUT_FORM_DESTROY,
  USER_INPUT_FORM_VALIDATE,
  USER_INPUT_FORM_LOAD_VALUES,
  USER_INPUT_FORM_RESET,
  USER_INPUT_FORM_UNTOUCH_FIELDS
} from '../actions/types'
import omit from 'lodash/omit'

const _getSubForms = formState => formState.subForms || {}
const _getSubForm = (formState, subFormName) => _getSubForms(formState)[subFormName] || {}
const _getFields = formState => formState.fields || {}
const _getField = (formState, fieldName) => _getFields(formState)[fieldName] || {}

const _updateFields = (formState, object) => ({
  ...formState,
  fields: Object.keys(_getFields(formState)).reduce(
    (nextState, fieldName) => {
      nextState[fieldName] = {
        ..._getField(formState, fieldName),
        ...object
      }
      return nextState
    },
    {}
  ),
  subForms: Object.keys(_getSubForms(formState)).reduce(
    (nextState, subFormName) => {
      nextState[subFormName] = _updateFields(_getSubForm(formState, subFormName), object)
      return nextState
    },
    {}
  )
})

const reset = state => omit(state, ['fields', 'subForms'])
const unTouchFields = state => _updateFields(state, {touched: false})

const updateField = (state, formName, fieldName, object) => {
  let subFormNames = formName.split('.')
  subFormNames.shift()

  return _updateField(state, subFormNames, fieldName, object)
}

const _updateField = (state, subFormNames, fieldName, object) => {
  if (subFormNames.length === 0) {
    return {
      ...state,
      fields: {
        ..._getFields(state),
        [fieldName]: {
          ..._getField(state, fieldName),
          ...object
        }
      }
    }
  } else {
    const subFormName = subFormNames.shift()
    return {
      ...state,
      subForms: {
        ..._getSubForms(state),
        [subFormName]: _updateField(_getSubForm(state, subFormName), subFormNames, fieldName, object)
      }
    }
  }
}

// eslint-disable-next-line func-style
function _updateFieldValue (state, formName, fieldName, value, extraFieldState = {}) {
  const fieldObject = _getField(state, fieldName)

  return updateField(state, formName, fieldName, {
    value: value,
    dirty: Boolean(fieldObject.dirty || fieldObject.value !== value),
    touched: true,
    ...extraFieldState
  })
}

// eslint-disable-next-line func-style
function destroy (state, {formName}) {
  const subFormNames = formName.split('.')
  subFormNames.shift()
  const nextState = {...state}

  if (subFormNames.length) {
    const subFormName = subFormNames.shift()
    nextState.subForms = {..._getSubForms(state)}
    delete nextState.subForms[subFormName]
  } else {
    delete nextState.fields
    delete nextState.subForms
    delete nextState.disabled
  }

  return nextState
}

// eslint-disable-next-line func-style
function focusField (state, action) {
  return updateField(state, action.formName, action.fieldName, {active: true})
}

// eslint-disable-next-line func-style
function blurField (state, action) {
  if ('value' in action) {
    return _updateFieldValue(state, action.formName, action.fieldName, action.value, {active: false})
  } else {
    return updateField(state, action.formName, action.fieldName, {
      touched: true,
      active: false
    })
  }
}

// eslint-disable-next-line func-style
function changeField (state, action) {
  return _updateFieldValue(state, action.formName, action.fieldName, action.value)
}

// eslint-disable-next-line func-style
function touchField (state, action) {
  return updateField(state, action.formName, action.fieldName, {touched: true})
}

// eslint-disable-next-line func-style
function touchFields (state, action) {
  return action.fieldNames.reduce(
    (state, fieldName) => updateField(state, action.formName, fieldName, {touched: true}),
    state
  )
}

// eslint-disable-next-line func-style
function validate (state, action) {
  return _validateForm(action.formType, state, action.formName)
}

// eslint-disable-next-line func-style
function _validateForm (formType, formState, formName) {
  let newFormState = Object.keys(formType.fields || {}).reduce(
    (formState, fieldName) => updateField(formState, formName, fieldName, {touched: true}),
    formState
  )

  if (formType.subForms) {
    newFormState = Object.keys(formType.subForms).reduce(
      (newFormState, subFormName) => _validateForm(
        formType.subForms[subFormName],
        newFormState,
        `${formName}.${subFormName}`
      ),
      newFormState
    )
  }

  return newFormState
}

// eslint-disable-next-line func-style
function loadValues (state, action) {
  const formFields = Object.keys(action.formType.fields || {})
  return Object.keys(action.values).reduce(
    (newState, fieldName) => formFields.indexOf(fieldName) !== -1
      ? updateField(newState, action.formName, fieldName, {value: action.values[fieldName]})
      : newState,
    state
  )
}

const actionMap = {
  [USER_INPUT_FORM_DESTROY]: destroy,
  [USER_INPUT_FORM_FOCUS_FIELD]: focusField,
  [USER_INPUT_FORM_BLUR_FIELD]: blurField,
  [USER_INPUT_FORM_CHANGE_FIELD]: changeField,
  [USER_INPUT_FORM_TOUCH_FIELD]: touchField,
  [USER_INPUT_FORM_TOUCH_FIELDS]: touchFields,
  [USER_INPUT_FORM_VALIDATE]: validate,
  [USER_INPUT_FORM_LOAD_VALUES]: loadValues,
  [USER_INPUT_FORM_RESET]: reset,
  [USER_INPUT_FORM_UNTOUCH_FIELDS]: unTouchFields
}

export default formName => (state = {}, action) => {
  if (!(action.type in actionMap)) {
    return state
  }
  // If this action is not for this form then return state
  const [baseFormName] = action.formName.split('.')
  if (baseFormName !== formName) {
    return state
  }

  return actionMap[action.type](state, action)
}
