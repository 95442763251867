export * from './constants-form'

export const EMPTY_ARRAY = Object.freeze([])
export const EMPTY_OBJECT = Object.freeze({})

export const API_REQUEST_REFUND_TYPE_VOUCHER = 'voucher'
export const API_REQUEST_REFUND_TYPE_PSP = 'psp'

export const SALES_PRESENTATION_ONCE_PER_PASSENGER_PER_SEGMENT = 'PS'
export const SALES_PRESENTATION_ONCE_PER_PASSENGER_PER_DAY = 'PD'
export const SALES_PRESENTATION_ONCE_PER_PASSENGER_PER_BOOKING = 'PB'
export const SALES_PRESENTATION_ONCE_PER_PASSENGER_PER_JOURNEY = 'PT'
export const SALES_PRESENTATION_ONCE_PER_SEGMENT = 'OS'
export const SALES_PRESENTATION_ONCE_PER_DAY = 'OD'
export const SALES_PRESENTATION_ONCE_PER_BOOKING = 'OB'
export const SALES_PRESENTATION_ONCE_PER_JOURNEY = 'OT'

export const HTTP_CODE_UNAUTHORIZED = 401
export const HTTP_CODE_NOT_FOUND = 404
export const HTTP_CODE_INTERNAL_SERVER_ERROR = 500
export const HTTP_CODE_BAD_GATEWAY = 502
export const HTTP_CODE_SERVICE_UNAVAILABLE = 503
export const HTTP_CODE_GATEWAY_TIMEOUT = 504
export const HTTP_CODE_ORIGIN_UNREACHABLE = 523

export const RETRYABLE_ERROR_HTTP_CODES = [
  HTTP_CODE_INTERNAL_SERVER_ERROR,
  HTTP_CODE_BAD_GATEWAY,
  HTTP_CODE_SERVICE_UNAVAILABLE,
  HTTP_CODE_GATEWAY_TIMEOUT,
  HTTP_CODE_ORIGIN_UNREACHABLE
]

export const PASSENGER_TYPE_ADULT = 'A'
export const PASSENGER_TYPE_CHILD = 'C'
export const PASSENGER_TYPE_STUDENT = 'S'

export const PAYMENT_STATUS_S = 'S'
export const PAYMENT_STATUS_P = 'P'
export const PAYMENT_STATUS_F = 'F'

export const PAYMENT_METHOD_FLOW_TYPE_CREDIT_CARD_ENCRYPTED = 'CREDITCARD_ENCRYPTED'

export const BOOKING_AGENT_NOTE = 'AGENT_NOTE'
export const BOOKING_NOTE = 'NOTE'
export const AGENT_PERMISSION_PAYMENT_VIRTUAL_ENABLED = 'agent_payment.virtualEnabled'
export const AGENT_PERMISSION_PAYMENT_EXTERNAL_ENABLED = 'agent_payment.externalEnabled'
export const AGENT_PERMISSION_ADMINISTRATION_CREATE = 'agent_administration.create'
export const AGENT_PERMISSION_ADMINISTRATION_UPDATE = 'agent_administration.update'
export const AGENT_PERMISSION_ADMINISTRATION_LIST = 'agent_administration.list'
export const AGENT_PERMISSION_ADMINISTRATION_RESET_PASSWORD = 'agent_administration.resetPassword'
export const AGENT_PERMISSION_AFTERSALES_EXTERNAL_ADDONS = 'agent_aftersales_external.addons'
export const AGENT_PERMISSION_AFTERSALES_EXTERNAL_REBOOKING = 'agent_aftersales_external.rebooking'
export const AGENT_PERMISSION_AFTERSALES_EXTERNAL_NAME_CHANGE = 'agent_aftersales_external.nameChange'
export const AGENT_PERMISSION_AFTERSALES_EXTERNAL_SEAT_CHANGE = 'agent_aftersales_external.seatChange'
export const AGENT_PERMISSION_AFTERSALES_EXTERNAL_CANCELLATION = 'agent_aftersales_external.cancellation'
export const AGENT_PERMISSION_AFTERSALES_EXTERNAL_PARTIAL_CANCELLATION =
    'agent_aftersales_external.partial.cancellation'
export const AGENT_PERMISSION_AFTERSALES_EXTERNAL_WAIVE_FEES = 'agent_aftersales_external.waiveFees'
export const AGENT_PERMISSION_AFTERSALES_EXTERNAL_REFUND_PSP = 'agent_aftersales_external.refundPsp'
export const AGENT_PERMISSION_AFTERSALES_EXTERNAL_REFUND_VOUCHER = 'agent_aftersales_external.refundVoucher'
export const AGENT_PERMISSION_AFTERSALES_EXTERNAL_FEE_CANCELLATION = 'agent_aftersales_external.fee.cancellation'
export const AGENT_PERMISSION_AFTERSALES_EXTERNAL_OVERRIDE_AFTERSALES_RULES =
  'agent_aftersales_external.overrideAftersalesRules'
export const AGENT_PERMISSION_AFTERSALES_EXTERNAL_OVERRIDE_VALIDATION_RULES_FULL =
  'agent_aftersales_external.overrideValidationRulesFull'
export const AGENT_PERMISSION_AFTERSALES_EXTERNAL_OVERRIDE_VALIDATION_RULES_ISSUE =
  'agent_aftersales_external.overrideValidationRulesIssue'
export const AGENT_PERMISSION_AFTERSALES_CONTRACT_ADDONS = 'agent_aftersales_contract.addons'
export const AGENT_PERMISSION_AFTERSALES_CONTRACT_REBOOKING = 'agent_aftersales_contract.rebooking'
export const AGENT_PERMISSION_AFTERSALES_CONTRACT_NAME_CHANGE = 'agent_aftersales_contract.nameChange'
export const AGENT_PERMISSION_AFTERSALES_CONTRACT_SEAT_CHANGE = 'agent_aftersales_contract.seatChange'
export const AGENT_PERMISSION_AFTERSALES_CONTRACT_CANCELLATION = 'agent_aftersales_contract.cancellation'
export const AGENT_PERMISSION_AFTERSALES_CONTRACT_PARTIAL_CANCELLATION =
    'agent_aftersales_contract.partial.cancellation'
export const AGENT_PERMISSION_AFTERSALES_CONTRACT_WAIVE_FEES = 'agent_aftersales_contract.waiveFees'
export const AGENT_PERMISSION_AFTERSALES_CONTRACT_REFUND_PSP = 'agent_aftersales_contract.refundPsp'
export const AGENT_PERMISSION_AFTERSALES_CONTRACT_REFUND_VOUCHER = 'agent_aftersales_contract.refundVoucher'
export const AGENT_PERMISSION_AFTERSALES_CONTRACT_FEE_CANCELLATION = 'agent_aftersales_contract.fee.cancellation'
export const AGENT_PERMISSION_AFTERSALES_CONTRACT_OVERRIDE_AFTERSALES_RULES =
  'agent_aftersales_contract.overrideAftersalesRules'
export const AGENT_PERMISSION_AFTERSALES_CONTRACT_OVERRIDE_VALIDATION_RULES_FULL =
  'agent_aftersales_contract.overrideValidationRulesFull'
export const AGENT_PERMISSION_AFTERSALES_CONTRACT_OVERRIDE_VALIDATION_RULES_ISSUE =
  'agent_aftersales_contract.overrideValidationRulesIssue'
export const AGENT_PERMISSION_BOOKING_ACCESS_PUBLIC = 'agent_booking_access.public'
export const AGENT_PERMISSION_BOOKING_ACCESS_RESTRICT_SEARCH_TO_BOOKING_REFERENCE =
    'agent_booking_access.restrictSearchToBookingReference'
export const AGENT_PERMISSION_BOOKING_ACCESS_PROVISIONAL = 'agent_booking_access.provisional'
export const AGENT_PERMISSION_BOOKING_ACCESS_AGENT_RESTRICTION = 'agent_booking_access.agentRestriction'
export const AGENT_PERMISSION_BOOKING_ACCESS_OUTSIDE_CONTRACT = 'agent_booking_access.outsideContract'
export const AGENT_PERMISSION_BOOKING_ACCESS_OFFICE_RESTRICTION = 'agent_booking_access.officeRestriction'
export const AGENT_PERMISSION_BOOKING_ACCESS_VIEW_VOUCHER_DETAILS = 'agent_booking_access.viewVoucherDetails'
export const AGENT_PERMISSION_BOOKING_FUNCTIONS_VOUCHER = 'agent_booking_functions.voucher'
export const AGENT_PERMISSION_BOOKING_FUNCTIONS_CREATE = 'agent_booking_functions.create'
export const AGENT_PERMISSION_BOOKING_FUNCTIONS_SEAT_SELECTOR = 'agent_booking_functions.seatSelector'

export const SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_CANCEL = 'allow_aftersales_cancel'
export const SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_REBOOK = 'allow_aftersales_rebook'
export const SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_REFUND = 'allow_aftersales_refund'
export const SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_OVERRIDE_FEES = 'allow_aftersales_override_fees'
export const SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_VOUCHER_CREATION = 'allow_refund_voucher_creation'
export const SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_OVERRIDE_RULES = 'allow_aftersales_override_rules'
export const SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_OVERRIDE_VALIDATIONS = 'allow_aftersales_override_validations'
export const SALES_CHANNEL_PROPERTY_ALLOW_VOUCHER_PAYMENT = 'allow_voucher_payment'

export const SALES_CHANNEL_PROPERTY_REQUEST_CUSTOMER = 'request_customer'

export const SALES_CHANNEL_PROPERTY_GROUP_BOOKINGS = 'groupBookings'
export const SALES_CHANNEL_PROPERTY_NORMAL_BOOKINGS = 'normalBookings'

export const SALES_CHANNEL_PROPERTY_FIELD_PRESENCE_HIDDEN = 'hidden'
export const SALES_CHANNEL_PROPERTY_FIELD_PRESENCE_OPTIONAL = 'optional'
export const SALES_CHANNEL_PROPERTY_FIELD_PRESENCE_REQUIRED = 'required'

export const BOOKING_CONFIRMED = 'BOOKING_CONFIRMED'
export const BOOKING_PROVISIONAL = 'BOOKING_PROVISIONAL'
export const BOOKING_CANCELLED = 'BOOKING_CANCELLED'

export const SEAT_SELECTION_AVAILABLE = 'SEAT_SELECTION_AVAILABLE'
export const SEAT_SELECTION_NOT_INCLUDED = 'SEAT_SELECTION_NOT_INCLUDED'
export const SEAT_SELECTION_NOT_AVAILABLE = 'SEAT_SELECTION_NOT_AVAILABLE'
export const SEAT_SELECTION_NOT_AVAILABLE_ANYMORE = 'SEAT_SELECTION_NOT_AVAILABLE_ANYMORE'
export const SEAT_SELECTION_NOT_AUTHORIZED = 'SEAT_SELECTION_NOT_AUTHORIZED'
export const SEAT_SELECTION_READONLY = 'SEAT_SELECTION_READONLY'

export const AFTERSALES_REBOOK = 'REBOOK'
export const AFTERSALES_SEATCHANGE = 'SEATCHANGE'
export const AFTERSALES_NAMECHANGE = 'NAMECHANGE'
export const AFTERSALES_CANCELLATION = 'CANCEL'
export const AFTERSALES_ADDITIONAL_PRODUCTS = 'ADDITIONAL_PRODUCTS'

export const PENDING_AFTERSALES_REBOOK = 'AFTERSALES_REBOOK'
export const PENDING_AFTERSALES_CANCELLATION = 'AFTERSALES_CANCEL'
export const PENDING_AFTERSALES_ADDITIONAL_PRODUCTS = 'AFTERSALES_MODIFY'
export const PENDING_AFTERSALES_NAMECHANGE = 'AFTERSALES_NAMECHANGE'
export const PENDING_AFTERSALES_SEATCHANGE = 'AFTERSALES_SEATCHANGE'

export const BOOKING_LOGIN_AFTERSALES_REBOOK = 'rebooking'
export const BOOKING_LOGIN_AFTERSALES_SEATCHANGE = 'change-seats'
export const BOOKING_LOGIN_AFTERSALES_NAMECHANGE = 'update-passengers'
export const BOOKING_LOGIN_AFTERSALES_CANCELLATION = 'cancellation'
export const BOOKING_LOGIN_AFTERSALES_ADDITIONAL_PRODUCTS = 'additional-products'
export const BOOKING_LOGIN_AFTERSALES_UPGRADE_PRODUCTS = 'upgrade-products'

export const ROLE_USER = 'ROLE_USER'
export const ROLE_PUBLIC = 'ROLE_PUBLIC'
export const ROLE_ORIENTATION = 'ROLE_ORIENTATION'
export const ROLE_AGENT = 'ROLE_AGENT'
export const ROLE_BOOKING = 'ROLE_BOOKING'
export const ROLE_CRM = 'ROLE_CRM'

export const TRAVEL_DIRECTION_OUTBOUND = 'outbound'
export const TRAVEL_DIRECTION_INBOUND = 'inbound'
export const TRAVEL_DIRECTION_BOTH = 'both'

export const DEEP_LINK_PENDING = 'DEEP_LINK_PENDING'
export const DEEP_LINK_SUCCESS = 'DEEP_LINK_SUCCESS'
export const DEEP_LINK_FAILURE = 'DEEP_LINK_FAILURE'
export const DEEP_LINK_INVALID = 'DEEP_LINK_INVALID'
export const DEEP_LINK_REQUIRES_AUTHENTICATION = 'DEEP_LINK_REQUIRES_AUTHENTICATION'

export const UI_TABLE_SORT_ASCENDING = 'asc'
export const UI_TABLE_SORT_DESCENDING = 'desc'

export const PASSENGER_ONE_ID = 'passenger_1'
export const PASSENGER_TWO_ID = 'passenger_2'

export const MESSAGE_SEVERITY_WARNING = 'warn'
export const MESSAGE_SEVERITY_ERROR = 'error'
export const MESSAGE_SEVERITY_SUCCESS = 'success'

export const PRODUCT_TYPE_BIKE = 'BK'

export const CONFIRMATION_PAYMENT_SUCCESS = 'success'
export const CONFIRMATION_PAYMENT_CANCELLED = 'cancelled'
export const CONFIRMATION_PAYMENT_TIMEOUT = 'timeout'
export const CONFIRMATION_PAYMENT_FAILED = 'failed'
export const CONFIRMATION_PAYMENT_PENDING = 'pending'

export const MESSAGE_YES = 'yes'
export const MESSAGE_NO = 'no'

export const PHONENUMBER_TYPE_PRIVATEFIXED = 'privateFixed'
export const PHONENUMBER_TYPE_PRIVATEMOBILE = 'privateMobile'
export const PHONENUMBER_TYPE_BUSINESSFIXED = 'businessFixed'
export const PHONENUMBER_TYPE_BUSINESSMOBILE = 'businessMobile'

export const JOURNEY_SINGLE = 'single'
export const JOURNEY_RETURN = 'return'

export const DOCUMENT_TYPE_PIN = 'PIN'
export const DOCUMENT_TYPE_RECEIPT = 'RCP'
export const DOCUMENT_TYPE_INVOICE = 'INV'
