import PropTypes from 'prop-types'
import React, { Component } from 'react'

export const STATE_UNCHECKED = 'unchecked'
export const STATE_CHECKED = 'checked'
export const STATE_INDETERMINATE = 'indeterminate'

class TriStateCheckbox extends Component {
  constructor (...args) {
    super(...args)
    this.onChange = this.onChange.bind(this)
  }

  componentDidMount () {
    this._handleCheckboxState(this.props.state)
  }

  onChange () {
    this.props.onChange()
    this._handleCheckboxState(this.props.state)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.state !== nextProps.state) {
      this._handleCheckboxState(nextProps.state)
    }
  }

  shouldComponentUpdate (nextProps) {
    return nextProps.state !== this.props.state ||
      nextProps.className !== this.props.className ||
      nextProps.disabled !== this.props.disabled
  }

  _handleCheckboxState (state) {
    switch (state) {
      case STATE_CHECKED:
        this._input.indeterminate = false
        this._input.checked = true
        break
      case STATE_INDETERMINATE:
        this._input.indeterminate = true
        this._input.checked = false
        break
      default:
        this._input.indeterminate = false
        this._input.checked = false
    }
  }

  render () {
    return (
      <input
        type='checkbox'
        id={this.props.id}
        ref={input => { this._input = input }}
        onClick={this.onChange}
        disabled={this.props.disabled}
      />
    )
  }
}

TriStateCheckbox.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  state: PropTypes.oneOf([STATE_CHECKED, STATE_INDETERMINATE, STATE_UNCHECKED])
}

TriStateCheckbox.defaultProps = {
  disabled: false
}

export default TriStateCheckbox
