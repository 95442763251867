import React from 'react'
import { Route } from 'react-router'
import containAuth from '../../containers/base/auth'
import TestThermalPrinter from '../../layouts/maintenance/test-thermal-printer'
import { AGENT_ROLE_OPERATOR, AGENT_ROLE_TECHNICIAN } from '../../constants'

export default (
  <Route
    key='maintenance-test-thermal-printer'
    path='/:lang/maintenance/test-thermal-printer'
    component={containAuth(TestThermalPrinter, {roles: [AGENT_ROLE_OPERATOR, AGENT_ROLE_TECHNICIAN]})}
  />
)
