import React from 'react'
import { Route } from 'react-router'
import containAuth from '../../containers/base/auth'
import AdditionalProductsLayout from '../../layouts/reservations/additional-products'
import { AGENT_ROLE_OPERATOR } from '../../constants'

export default (
  <Route
    key='reservations-additional-products'
    path='/:lang/reservations/additional-products'
    component={containAuth(AdditionalProductsLayout, {roles: [AGENT_ROLE_OPERATOR]})}
  />
)
