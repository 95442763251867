import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import Error from '../../components/error/error'
import containLastApiErrors from '../base/last-api-errors'

class ErrorContainer extends Component {
  static propTypes = {
    clearApiErrors: PropTypes.func.isRequired,
    handleGoBack: PropTypes.func.isRequired,
    lastApiErrors: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })).isRequired,
    hideIfNoErrorsFound: PropTypes.bool
  }

  componentWillUnmount () {
    this.props.clearApiErrors()
  }

  render () {
    const errorCodes = this.props.lastApiErrors.reduce(
      (codes, error) => {
        if (error.code) {
          codes.push(error.code)
        }
        return codes
      },
      []
    )

    return (
      this.props.hideIfNoErrorsFound && !errorCodes.length ? null : <Error
        handleGoBack={this.props.handleGoBack}
        errorCodes={errorCodes.length ? errorCodes : null}
      />
    )
  }
}

const mapDispatchToProps = {
  handleGoBack: routerActions.goBack
}

export default connect(null, mapDispatchToProps)(containLastApiErrors(ErrorContainer))
