import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../elements/text-label/text-label'
import Icon from '../../../elements/icon/icon'
import Button from '../../../elements/button/button'
import { PAYMENT_METHOD_CODE_VOUCHER } from '../../../constants'

export default class SidebarCartItem extends Component {
  static propTypes = {
    payment: PropTypes.shape({
      id: PropTypes.string.isRequired,
      method: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      voucher: PropTypes.shape({
        code: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired
      })
    }),
    removeVoucher: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    disabled: false
  }

  constructor (...args) {
    super(...args)
    this.handleRemoveVoucher = this.handleRemoveVoucher.bind(this)
  }

  handleRemoveVoucher () {
    if (this.props.payment.voucher) {
      this.props.removeVoucher(this.props.payment.voucher.code)
    }
  }

  render () {
    const isVoucher = PAYMENT_METHOD_CODE_VOUCHER === this.props.payment.method
    const value = isVoucher ? this.props.payment.voucher : this.props.payment
    return (
      <div className='cart-total__paid-per-voucher'>
        {isVoucher
          ? <Button
            tabIndex='-1'
            type='button'
            name='remove-cart-voucher'
            className='alert'
            onClick={this.handleRemoveVoucher}
            disabled={this.props.disabled}
          >
            <TextLabel name='remove-cart-voucher'>
              <Icon name='subtract' />
              <TextLabel.Text name='remove-cart-item' text={_t.message('cart.remove-voucher')} />
            </TextLabel>
          </Button> : null}
        <TextLabel
          name='paid-per-voucher-description'
          text={_t.message('cart.payment-description', {method: this.props.payment.method})}
        />
        <TextLabel
          name='paid-per-voucher-value'
          text={_t.formatCurrency(value.amount, value.currency)}
        />
      </div>
    )
  }
}
