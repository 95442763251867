import memoize from 'lodash/memoize'
import {createQueryFromValues} from 's3p-js-lib/src/misc/journey-search-url-query'

export const MAX_PASSENGERS = 75

export const HTTP_CODE_SERVICE_UNAVAILABLE = 503
export const HTTP_CODE_ORIGIN_UNREACHABLE = 523

export const LANGUAGE_ENGLISH = 'en-IE'
export const LANGUAGE_IRISH = 'ga-IE'

export const PASSENGER_TYPE_ADULT = 'A'
export const PASSENGER_TYPE_CHILD = 'C'
export const PASSENGER_TYPE_STUDENT = 'S'

export const PRODUCT_TYPE_SEAT_TICKET = 'ST'
export const PRODUCT_TYPE_SEAT_ONLY = 'SO'
export const PRODUCT_TYPE_TICKET_ONLY = 'TO'

export const PRODUCT_TYPE_BIKE = 'BK'
export const PRODUCT_TYPE_PET = 'DG'
export const PRODUCT_TYPE_FOOD_AND_BEVERAGES = 'FB'
export const PRODUCT_TYPE_LUGGAGE = 'LU'
export const PRODUCT_TYPE_FEE = 'FE'
export const PRODUCT_TYPE_PRAM = 'PR'
export const PRODUCT_TYPE_VOUCHER = 'VC'
export const PRODUCT_TYPE_INSURANCE = 'IN'
export const PRODUCT_TYPE_EXTERNAL_TICKET = 'ET'
export const PRODUCT_TYPE_DISABILITY_SUPPORT = 'DS'
export const PRODUCT_TYPE_PENALTY_FARE = 'PE'
export const PRODUCT_TYPE_HOTEL = 'HO'
export const PRODUCT_TYPE_WIFI = 'WI'
export const PRODUCT_TYPE_MERCHANDISE = 'MD'
export const PRODUCT_TYPE_MEALS = 'ME'
export const PRODUCT_TYPE_TAXI = 'TA'
export const PRODUCT_TYPE_PARKING = 'PA'
export const PRODUCT_TYPE_ENTERTAINMENT = 'EN'
export const PRODUCT_TYPE_LOCAL_TRANSPORT = 'LT'

export const MAIN_PRODUCT_TYPES = [PRODUCT_TYPE_SEAT_TICKET, PRODUCT_TYPE_SEAT_ONLY, PRODUCT_TYPE_TICKET_ONLY]
export const ADDITIONAL_PRODUCT_TYPES = [
  PRODUCT_TYPE_BIKE,
  PRODUCT_TYPE_PET,
  PRODUCT_TYPE_FOOD_AND_BEVERAGES,
  PRODUCT_TYPE_LUGGAGE,
  PRODUCT_TYPE_PRAM,
  PRODUCT_TYPE_VOUCHER,
  PRODUCT_TYPE_INSURANCE,
  PRODUCT_TYPE_EXTERNAL_TICKET,
  PRODUCT_TYPE_DISABILITY_SUPPORT,
  PRODUCT_TYPE_PENALTY_FARE,
  PRODUCT_TYPE_HOTEL,
  PRODUCT_TYPE_WIFI,
  PRODUCT_TYPE_MERCHANDISE,
  PRODUCT_TYPE_MEALS,
  PRODUCT_TYPE_TAXI,
  PRODUCT_TYPE_PARKING,
  PRODUCT_TYPE_ENTERTAINMENT,
  PRODUCT_TYPE_LOCAL_TRANSPORT
]
export const ALL_PRODUCT_TYPES = [...MAIN_PRODUCT_TYPES, ...ADDITIONAL_PRODUCT_TYPES]

export const DISABILITY_TYPE_NONE = 'NH'
export const DISABILITY_TYPE_WHEELCHAIR = 'WH'
export const DISCOUNT_CARD_SEAT_ONLY = 'SO'
export const DISCOUNT_CARD_STUDENT_CARD = 'SID'
export const DISCOUNT_CARD_WELFARE_CARD = 'DRDSP'
export const DISCOUNT_CARD_PRIVILEGE_CARD = 'PTI'

export const SEAT_PROPERTY_TRAVEL_DIRECTION_UNKNOWN = 'TDU'
export const SEAT_PROPERTY_NO_DISABILITY = 'DAC'

export const PAYMENT_PROVIDER_NONE = 'NONE'
export const PAYMENT_METHOD_CODE_CASH = 'NONE_CASH'
export const PAYMENT_METHOD_CODE_VOUCHER = 'voucher'
export const PAYMENT_METHOD_CODE_REFUND = 'refund'
export const PAYMENT_METHOD_CODE_CREDIT_DEBIT_CARD = 'NONE_BOM_PED'
export const PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD = 'BOM_PED'
export const PAYMENT_METHOD_PD_CREDIT_DEBIT_CARD = 'PD_PED'
export const PAYMENT_METHOD_CASH = 'CASH'
export const PAYMENT_METHOD_PSP_CC_3DS = 'CC3DS'
export const PAYMENT_METHOD_PSP_CC_B2B = 'CC_B2B'
export const PAYMENT_METHOD_PSP_CC_TELE = 'CC_TELE'

export const PAYMENT_METHOD_INVOICE = 'INV'
export const PAYMENT_METHOD_DEFERRED_PAYMENT = 'POST_PAYMENT'

export const PAYMENT_METHODS_LOCAL_PAYMENTS = [
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD,
  PAYMENT_METHOD_PD_CREDIT_DEBIT_CARD
]

export const PAYMENT_METHODS_PSP_REFUND_ALLOWED = [
  PAYMENT_METHOD_PSP_CC_3DS,
  PAYMENT_METHOD_PSP_CC_B2B,
  PAYMENT_METHOD_PSP_CC_TELE,
  PAYMENT_METHOD_INVOICE
]

export const PAYMENT_METHODS_REFUND_ALLOWED = PAYMENT_METHODS_LOCAL_PAYMENTS.concat(PAYMENT_METHODS_PSP_REFUND_ALLOWED)

export const VOUCHER_DISCOUNT = 'DISCOUNT'

export const FULFILLMENT_METHOD_CODE_MAGSTRIPE = 'BM_MS'
export const FULFILLMENT_METHOD_CODE_BARCODE = 'BM_B'
export const FULFILLMENT_METHOD_CODE_LEAP = 'BM_L'
export const FULFILLMENT_METHOD_CODE_LEAP_TOP_UP = 'BM_LT'
export const FULFILLMENT_METHOD_CODE_EMAIL = 'BM_E'
export const FULFILLMENT_METHOD_CODE_HOMEPRINT = 'HP_E'
export const FULFILLMENT_METHOD_CODE_PICKUP = 'HP_T'
export const FULFILLMENT_METHOD_CODE_MOBILE = 'HP_M'
export const FULFILLMENT_METHOD_CODE_POST = 'HP_P'
export const FULFILLMENT_METHOD_CODE_PDF = 'HP_D'
export const FULFILLMENT_METHOD_CODE_SMS = 'HP_S'
export const FULFILLMENT_METHOD_CODE_TVM = 'ST_MS'
export const FULFILLMENT_METHOD_GIFT_VOUCHER = 'BM_VC'

export const CURRENCY_EURO = 'EUR'
export const CURRENCY_BRITISH_POUND = 'GBP'

export const SIDEBAR_CART = 'SIDEBAR_CART'
export const SHIFT_MODAL = 'SHIFT_MODAL'
export const START_SHIFT_ERROR_MODAL = 'START_SHIFT_ERROR_MODAL'
export const FINALIZE_BOOKING_MODAL = 'fulfillment-method'
export const RETURN_PAYMENT_MODAL = 'return-payment'
export const VOUCHER_TO_CASH_MODAL = 'voucherToCashModal'
export const LEAP_REVERSE_MODAL = 'LeapReverseModal'
export const REFUND_VOUCHER_MODAL = 'RefundVoucherModal'
export const CANCELLATION_VOID_MODAL = 'CancellationVoidModal'
export const REFUND_MODAL = 'refund'
export const VOID_TICKETS_MODAL = 'VoidTicketsModal'
export const CHANGE_PASSWORD_MODAL = 'ChangePasswordModal'

export const FORM_EDIT_SHORTCUT = 'editShortcut'
export const FORM_CREDIT_DEBIT = 'creditDebit'
export const FORM_PRODUCT_SEARCH_PASSENGERS_DETAILS = 'productSearchPassengerDetails'
export const FORM_JOURNEY_SEARCH_PASSENGERS_DETAILS = 'journeySearchPassengerDetails'
export const FORM_REBOOK_SEARCH_PASSENGERS_DETAILS = 'rebookSearchPassengerDetails'

export const CONTAINERS_RESERVATION_BOOKING_DETAILS_CUSTOMER_DETAILS = 'containers.reservations.bookingDetails.customerDetails'
export const CONTAINERS_RESERVATIONS_PASSENGER_PREFERENCES = 'containers.reservations.passengerPreferences'
export const CONTAINERS_BASE_TILES_PAYMENT_METHODS = 'containers.base.tiles.paymentMethods'
export const CONTAINERS_BASE_ELEMENT_VISIBILITY = 'containers.base.elementVisibility'
export const CONTAINERS_BASE_FINALIZE_BOOKING_PRINT_TICKETS_MODAL = 'containers.base.finalizeBooking.printTicketsModal'
export const CONTAINERS_BASE_FINALIZE_BOOKING_PRINT_BARCODES = 'containers.base.finalizeBooking.printBarcodes'
export const CONTAINERS_BASE_PAYMENT_MODAL_CASH_FORM = 'containers.base.paymentModal.cashForm'
export const CONTAINERS_BASE_PAYMENT_MODAL_CREDIT_DEBIT = 'containers.base.paymentModal.creditDebit'
export const CONTAINERS_TICKETS_PRODUCT_SEARCH_RESULT = 'containers.tickets.productSearchResult'
export const CONTAINERS_TICKETS_SHORTCUT = 'containers.tickets.shortcut'
export const CONTAINERS_AGENT_LOGIN = 'containers.agent.login'
export const CONTAINERS_AGENT_SHIFT = 'containers.agent.shift'
export const CONTAINERS_USER_AGENT_CHANGE_PASSWORD = 'containers.agent.changePassword'
export const CONTAINERS_AFTERSALES_BOOKING_BOOKING = 'containers.aftersales.booking.booking'
export const CONTAINERS_AFTERSALES_VOUCHER_TO_CASH = 'containers.aftersales.voucherToCash'
export const CONTAINERS_AFTERSALES_BOOKING_NOTES = 'containers.aftersales.booking.notes'
export const CONTAINERS_AFTERSALES_OPERATION = 'containers.aftersales.operation'
export const CONTAINERS_AFTERSALES_VOID_TICKETS = 'containers.aftersales.cancellation.voidTickets'
export const CONTAINERS_AFTERSALES_CANCELLATION_REVERSE_LEAP = 'containers.aftersales.cancellation.reverseLeap'
export const CONTAINERS_AFTERSALES_READTICKET = 'containers.aftersales.readTicket'
export const CONTAINERS_BOOKING_REPRINT_RESEND = 'containers.booking.reprint-resend'
export const CONTAINERS_SHIFT_MODAL = 'containers.shift.modal'
export const CONTAINERS_LEAP_DOUBTFUL_TRANSACTION = 'containers.leap.doubtfulTransaction'
export const CONTAINERS_LEAP_ADD_PRODUCT = 'containers.leap.addProduct'
export const CONTAINERS_LEAP_READ = 'containers.leap.read'
export const CONTAINERS_LEAP_TOP_UP = 'containers.leap.topUp'

export const USERINPUT_AFTERSALES_CANCELLATION = 'userInput.aftersales.cancellation'
export const MACHINE_LEAP_READ = 'machine.leap.read'
export const MACHINE_LEAP = 'machine.leap'

export const MACHINE_MAGNETIC_TICKET_PRINTER = 'machine.magneticTicketPrinter'

export const TICKET_PRINTER_DEVICE_SERIAL = 'newbury_serial'
export const TICKET_PRINTER_DEVICE_COUNTER = 'newbury_counter'
export const TICKET_PRINTER_DEVICE_START_AMOUNT = 'start_amount'

export const LEAP_DEVICE_SERIAL = 'scim_serial'

export const PRINT_VOUCHER_DEFAULT_ERROR_MESSAGE = 'PRINT_VOUCHER_DEFAULT_ERROR_MESSAGE'

export const SCROLLER_BOOKING_DETAILS_CUSTOMER_DETAILS_FORM = 'booking-details-customer-details-form'

export const CMS_BLOCK_OFFLINE = 'OFFLINE'
export const LOCAL_STORAGE_CMS_BLOCKS_OFFLINE = 'offline-cms-blocks'

export const EXTERNAL_REFERENCE_DISABLE_OD = 'disableOD'
export const EXTERNAL_REFERENCE_NAME_IRISH = 'irishName'
export const EXTERNAL_REFERENCE_SHOW_ON_BOM_OPEN_TICKET_SALE = 'showOnBOMOpenTicketSale'
export const EXTERNAL_REFERENCE_ONLY_BOOKABLE_BEFORE_DAY_OF_CURRENT_MONTH = 'onlyBookableBeforeDayOfCurrentMonth'
export const EXTERNAL_REFERENCE_MAGSTRIPE_TEMPLATE_NAME = 'magstripeTemplateName'
export const EXTERNAL_REFERENCE_LEAP_PRODUCT_CLASS_NAME = 'productClass'
export const EXTERNAL_REFERENCE_LEAP_PRODUCT_VERSION_NAME = 'productVersion'
export const EXTERNAL_REFERENCE_LEAP_PRODUCT_OWNER_ID_NAME = 'ownerID'
export const EXTERNAL_REFERENCE_LEAP_PRODUCT_SINGLE_OPERATOR_ACCESS_NAME = 'singleOperatorAccess'
export const EXTERNAL_REFERENCE_LEAP_PRODUCT_COUPONS_NAME = 'coupons'
export const EXTERNAL_REFERENCE_LEAP_PRODUCT_TICKET_SPECIFIC_DATA = 'ticketSpecificData'
export const EXTERNAL_REFERENCE_LEAP_PRODUCT_CARD_PROFILE_RESTRICTIONS = 'cardProfileRestrictions'
export const EXTERNAL_REFERENCE_LEAP_PRODUCT_SALE_REPORT_LIMIT = 'saleReportLimit'
export const EXTERNAL_REFERENCE_LEAP_PRODUCT_SALES_MAXIMUM_JOURNEYS = 'maximumJourneys'

export const DOCUMENT_TEMPLATE_TICKET = 'TICKET'
export const DOCUMENT_TEMPLATE_RECEIPT = 'RECEIPT'
export const DOCUMENT_TEMPLATE_VOUCHER = 'VOUCHER'
export const DOCUMENT_TEMPLATE_SHIFT_REPORT = 'SHIFT_REPORT'

export const DOCUMENT_TYPE_TIC = 'TIC'
export const DOCUMENT_TYPE_VCR = 'VCR'

export const DOCUMENT_TEMPLATE_NAME_MAGSTRIPE_TICKET = 'BOMMagstripeTemplate'
export const DOCUMENT_TEMPLATE_NAME_PED_CUSTOMER_RECEIPT = 'BOMPEDCustomerTemplate'
export const DOCUMENT_TEMPLATE_NAME_PED_MERCHANT_RECEIPT = 'BOMPEDMerchantTemplate'
export const DOCUMENT_TEMPLATE_NAME_PED_SIGNATURE_RECEIPT = 'BOMPEDSignatureTemplate'
export const DOCUMENT_TEMPLATE_NAME_PED_FAILED_CUSTOMER_RECEIPT = 'BOMPEDFailedCustomerTemplate'
export const DOCUMENT_TEMPLATE_NAME_PED_FAILED_MERCHANT_RECEIPT = 'BOMPEDFailedMerchantTemplate'
export const DOCUMENT_TEMPLATE_NAME_SHIFT_REPORT = 'BOMShiftTemplate'
export const DOCUMENT_TEMPLATE_NAME_PED_REFUND_CUSTOMER_RECEIPT = 'BOMPEDRefundCustomerTemplate'
export const DOCUMENT_TEMPLATE_NAME_PED_REFUND_MERCHANT_RECEIPT = 'BOMPEDRefundMerchantTemplate'
export const DOCUMENT_TEMPLATE_NAME_PED_SIGNATURE_CODE = 'BOMPEDSignatureCode'
export const DOCUMENT_TEMPLATE_NAME_VOUCHER_TO_CASH_CUSTOMER_RECEIPT = 'BOMVoucherToCashCustomerTemplate'
export const DOCUMENT_TEMPLATE_NAME_VOUCHER_TO_CASH_MERCHANT_RECEIPT = 'BOMVoucherToCashMerchantTemplate'
export const DOCUMENT_TEMPLATE_NAME_CASH_REFUND_CUSTOMER_RECEIPT = 'BOMCashRefundCustomerTemplate'
export const DOCUMENT_TEMPLATE_NAME_CASH_REFUND_MERCHANT_RECEIPT = 'BOMCashRefundMerchantTemplate'

export const JOURNEY_SEARCH_PRODUCT_FAMILIES = ['NF', 'MF', 'FF', '1C']

export const META_DATA_SHORTCUTS = 'shortcuts'

export const ADDITIONAL_DETAILS_MAGSTRIPE_COUNTER = 'magstripe_counter'
export const ADDITIONAL_DETAILS_MAGSTRIPE_STATUS = 'magstripe_status'
export const ADDITIONAL_DETAILS_BARCODE_STATUS = 'barcode_status'

export const CMS_BLOCK_ORIENTATION_ADDITIONAL_PRODUCTS = 'CMS_BLOCK_ORIENTATION_ADDITIONAL_PRODUCTS'

export const SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_FULFILLMENT_CHANGE = 'allow_aftersales_fulfillmentchange'
export const SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_OVERRIDE_RULES = 'allow_aftersales_override_rules'
export const SALES_CHANNEL_PROPERTY_ALLOW_AFTERSALES_OVERRIDE_VALIDATIONS = 'allow_aftersales_override_validations'
export const SALES_CHANNEL_EXTERNAL_REFERENCE_TERMS_AND_CONDITIONS_KEY_NAME = 'termsAndConditionsURL'

export const API_PRODUCT_VALIDATION_TYPE_ISSUE = 'ISSUE'
export const API_PRODUCT_VALIDATION_TYPE_FULL = 'FULL'

export const TRAVEL_DIRECTION_OUTWARD = 'outward'
export const TRAVEL_DIRECTION_RETURN = 'return'

export const KEYCODE_ENTER = 13
export const KEYCODE_TAB = 9

export const PRODUCT_CODE_VOUCHER_TO_CASH = 'VTC'
export const VOUCHER_TO_CASH_VOUCHER_TYPES = ['PREPAID', 'COMPENSATION', 'REFUND']

export const LAST_MACHINE_ERROR_NAMESPACE_RECEIPT_PRINTER = 'receiptPrinter'
export const LAST_MACHINE_ERROR_NAMESPACE_LEAP_DEVICE = 'leapDevice'

export const AGENT_ROLE_OPERATOR = 'bomoperator'
export const AGENT_ROLE_TECHNICIAN = 'bomtechnician'

export const STORAGE_ITEM_NAME_LEAP_CONFIGURATION_DATA = 'leapConfigurationData'
export const STORAGE_ITEM_NAME_LEAP_SCHEME_WIDE_CODE = 'leapSchemeWideCode'

export const LEAP_TIMEOUT = 30

export const LEAP_MAX_ACTIVE_PRODUCTS_ON_CARD = 5

export const ISSUER_LEAP_CARD_NUMBER = 937239
export const ISSUER_DSP_PSC_CARD_NUMBER = 600713

export const CLEAR_DISPLAY_AFTER_SHORT = 3000 // milliseconds
export const CLEAR_DISPLAY_AFTER_LONG = 30000 // milliseconds
export const CLEAR_DISPLAY_AFTER_MEDIUM = 5000 // milliseconds

export const LEAP_PAYMENT_CODE_CASH = 1
export const LEAP_PAYMENT_CODE_CREDIT_DEBIT_CARD = 3
export const LEAP_PAYMENT_CODE_VOUCHER = 14

export const LEAP_TOP_UP_TRANSACTION_CODE = 'Purse Top-Up'

export const LEAP_ADDITIONAL_DETAIL_KEY_CARD_IDENTIFIER = 'cardIdentifier'

export const LEAP_ERROR_MESSAGE_NO_CONFIGURATION = 'LEAP_ERROR_MESSAGE_NO_CONFIGURATION'
export const LEAP_ERROR_MESSAGE_AMOUNT_TOO_LOW = 'LEAP_ERROR_MESSAGE_AMOUNT_TOO_LOW'
export const LEAP_ERROR_MESSAGE_AMOUNT_EXCEEDED = 'LEAP_ERROR_MESSAGE_AMOUNT_EXCEEDED'
export const LEAP_ERROR_MESSAGE_TOP_UP_FAILED = 'LEAP_ERROR_MESSAGE_TOP_UP_FAILED'
export const LEAP_ERROR_MESSAGE_CARD_EXPIRED_SINCE = 'LEAP_ERROR_MESSAGE_CARD_EXPIRED_SINCE'
export const LEAP_ERROR_MESSAGE_CARD_IS_BLOCKED = 'LEAP_ERROR_MESSAGE_CARD_IS_BLOCKED'
export const LEAP_ERROR_MESSAGE_PURSE_IS_BLOCKED = 'LEAP_ERROR_MESSAGE_PURSE_IS_BLOCKED'
export const LEAP_ERROR_MESSAGE_ADD_PRODUCT_FAILED = 'LEAP_ERROR_MESSAGE_ADD_PRODUCT_FAILED'
export const LEAP_ERROR_MESSAGE_INVALID_PRODUCT_CONFIGURATION = 'LEAP_ERROR_MESSAGE_INVALID_PRODUCT_CONFIGURATION'
export const LEAP_ERROR_MESSAGE_LEAP_CARD_NOT_READ = 'LEAP_ERROR_MESSAGE_LEAP_CARD_NOT_READ'
export const LEAP_ERROR_MESSAGE_LEAP_CONFIGURATION_DATA_CORRUPT = 'LEAP_ERROR_MESSAGE_LEAP_CONFIGURATION_DATA_CORRUPT'
export const LEAP_ERROR_MESSAGE_REVERSE_TRANSACTION_FAILED = 'LEAP_ERROR_MESSAGE_REVERSE_TRANSACTION_FAILED'
export const LEAP_ERROR_MESSAGE_LEAP_DOUBTFUL_TRANSACTION = 'LEAP_ERROR_MESSAGE_LEAP_DOUBTFUL_TRANSACTION'
export const LEAP_ERROR_MESSAGE_INVALID_PROFILE_RESTRICTIONS = 'LEAP_ERROR_MESSAGE_INVALID_PROFILE_RESTRICTIONS'
export const LEAP_ERROR_MESSAGE_LEAP_PRESENT_CORRECT_CARD = 'LEAP_ERROR_MESSAGE_LEAP_PRESENT_CORRECT_CARD'
export const LEAP_ERROR_MESSAGE_LEAP_TRANSACTION_PERIOD_EXPIRED = 'LEAP_ERROR_MESSAGE_LEAP_TRANSACTION_PERIOD_EXPIRED'

export const LEAP_REVERSE_ERROR_MESSAGE_CANCEL_TIME_EXCEEDED = 'LEAP_REVERSE_ERROR_MESSAGE_CANCEL_TIME_EXCEEDED'
export const LEAP_REVERSE_ERROR_MESSAGE_WRONG_LAST_TRANSACTION_CODE = 'LEAP_REVERSE_ERROR_MESSAGE_INCORRECT_LAST_TRANSACTION_CODE'
export const LEAP_REVERSE_ERROR_MESSAGE_WRONG_CARD_PRESENTED = 'LEAP_REVERSE_ERROR_MESSAGE_INCORRECT_CARD_PRESENTED'
export const LEAP_REVERSE_ERROR_MESSAGE_WRONG_LAST_EVENT_VALUE = 'LEAP_REVERSE_ERROR_MESSAGE_WRONG_LAST_EVENT_VALUE'
export const LEAP_REVERSE_ERROR_MESSAGE_BOOKING_HAS_NO_INFORMATION = 'LEAP_REVERSE_ERROR_MESSAGE_BOOKING_HAS_NO_INFORMATION'
export const LEAP_REVERSE_ERROR_MESSAGE_REVERSE_FAILED = 'LEAP_REVERSE_ERROR_MESSAGE_REVERSE_FAILED'

export const BOOKING_ON_HOLD_STATUS_AVAILABLE = 'available'
export const BOOKING_ON_HOLD_STATUS_PROVISIONAL = 'provisional'
export const BOOKING_ON_HOLD_STATUS_ON_HOLD = 'on_hold'
export const BOOKING_EXPIRED = 'BOOKING_EXPIRED'

export const BOM_FULFILLMENT_METHODS = [
  FULFILLMENT_METHOD_CODE_MAGSTRIPE,
  FULFILLMENT_METHOD_CODE_BARCODE,
  FULFILLMENT_METHOD_CODE_LEAP,
  FULFILLMENT_METHOD_CODE_LEAP_TOP_UP,
  FULFILLMENT_METHOD_GIFT_VOUCHER
]

export const SORT_FIND_CUSTOMERS = {
  TABLE_NAME: 'booking-details.customer-details.customers',
  FULL_NAME: 'fullName',
  EMAIL_ADDRESS: 'emailAddress'
}

export const MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_PAYMENT_SUCCESS = 'MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_PAYMENT_SUCCESS'
export const MESSAGE_CREDIT_DEBIT_PAYMENT_PROCEED_PED_DEVICE = 'MESSAGE_CREDIT_DEBIT_PAYMENT_PROCEED_PED_DEVICE'
export const MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_AUTHORIZATION_DECLINED = 'MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_AUTHORIZATION_DECLINED'
export const MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_NO_RESPONSE_PED_DEVICE = 'MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_NO_RESPONSE_PED_DEVICE'
export const MESSAGE_CREDIT_DEBIT_PAYMENT_RECEIPT_ERROR = 'MESSAGE_CREDIT_DEBIT_PAYMENT_RECEIPT_ERROR'
export const MESSAGE_CREDIT_DEBIT_PAYMENT_RECEIPT_SIGNATURE_ERROR = 'MESSAGE_CREDIT_DEBIT_PAYMENT_RECEIPT_SIGNATURE_ERROR'
export const MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_FAILURE_UNKNOWN = 'MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_FAILURE_UNKNOWN'
export const MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_FAILURE_CODE = 'MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_FAILURE_CODE'
export const MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_API_ERROR = 'MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_API_ERROR'
export const MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_MANUAL_REFUND = 'MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_MANUAL_REFUND'

export const PED_STATUS_AWAITING_PAYMENT = 'PED_STATUS_AWAITING_PAYMENT'
export const PED_STATUS_SUCCESS = 'PED_STATUS_SUCCESS'
export const PED_STATUS_NO_RESPONSE = 'PED_STATUS_NO_RESPONSE'
export const PED_STATUS_DECLINED = 'PED_STATUS_DECLINED'
export const PED_STATUS_FAILURE = 'PED_STATUS_FAILURE'
export const PED_STATUS_AUTHORIZATION_REQUIRED = 'PED_STATUS_AUTHORIZATION_REQUIRED'

export const PED_RESULT_CODE_APPROVED_ONLINE = 1
export const PED_RESULT_CODE_APPROVED_OFFLINE = 2
export const PED_RESULT_CODE_APPROVED_MANUAL = 3
export const PED_RESULT_CODE_DECLINED_ONLINE = 4
export const PED_RESULT_CODE_DECLINED_OFFLINE = 5
export const PED_RESULT_CODE_CANCELLED = 9
export const PED_RESULT_CODE_TRANSACTION_PERFORMED = 10
export const PED_RESULT_CODE_CAPTURE_CARD_DECLINED_ONLINE = 16
export const PED_RESULT_CODE_TRANSACTION_ABORTED = 19
export const PED_RESULT_CODE_PRE_SALES_COMPLETED = 20
export const PED_RESULT_CODE_PRE_SALES_REJECTED = 21
export const PED_RESULT_CODE_CARD_NUMBER_NOT_MATCHED = 22
export const PED_RESULT_CODE_EXPIRY_DATE_NOT_MATCHED = 23
export const PED_RESULT_CODE_INVALID_TRANSACTION_STATE = 24
export const PED_RESULT_CODE_TRANSACTION_NOT_VALID_FOR_REQUESTED_OPERATION = 25
export const PED_RESULT_CODE_INVALID_PGTR = 26
export const PED_RESULT_CODE_INVALID_MERCHANT = 27
export const PED_RESULT_CODE_INVALID_TERMINAL = 28
export const PED_RESULT_CODE_MERCHANT_STATUS_IS_NOT_VALID = 29
export const PED_RESULT_CODE_INVALID_CARD_NUMBER = 30
export const PED_RESULT_CODE_EXPIRED_CARD = 31
export const PED_RESULT_CODE_PRE_VALID_CARD = 32
export const PED_RESULT_CODE_INVALID_ISSUE_NUMBER = 33
export const PED_RESULT_CODE_INVALID_CARD_EXPIRY_DATE = 34
export const PED_RESULT_CODE_INVALID_START_DATE = 35
export const PED_RESULT_CODE_CARD_NOT_ACCEPTED = 36
export const PED_RESULT_CODE_TRANSACTION_NOT_ALLOWED = 37
export const PED_RESULT_CODE_CASH_BACK_NOT_ALLOWED = 38
export const PED_RESULT_CODE_STATUS_BUSY = 42
export const PED_RESULT_CODE_STATUS_NOT_BUSY = 43
export const PED_RESULT_CODE_PINPAD_IS_NOT_CONNECTED = 44
export const PED_RESULT_CODE_PINPAD_IS_CONNECTED = 45
export const PED_RESULT_CODE_AVS_DETAILS_REQUIRED = 50
export const PED_RESULT_CODE_VOICE_AUTHORIZATION = 400
export const PED_RESULT_CODE_SIGNATURE_VERIFICATION = 401
export const PED_RESULT_CODE_FALLBACK_CONFIRMATION = 402

export const PED_PAYMENT_METHOD_CONTACTLESS = 7
export const PED_PAYMENT_METHOD_CONTACTLESS_VOUCHER = 91
export const PED_PAYMENT_METHOD_CONTACTLESS_92 = 92

export const PED_PAYMENT_METHODS_CONTACTLESS = [
  PED_PAYMENT_METHOD_CONTACTLESS,
  PED_PAYMENT_METHOD_CONTACTLESS_VOUCHER,
  PED_PAYMENT_METHOD_CONTACTLESS_92
]

export const PED_VERIFICATION_TYPE_SIGNATURE_VERIFIED = 1
export const PED_VERIFICATION_TYPE_PIN_VERIFIED = 2
export const PED_VERIFICATION_TYPE_CARDHOLDER_NOT_PRESENT = 7
export const PED_VERIFICATION_TYPE_NO_CVM = 8
export const PED_VERIFICATION_TYPE_UNKNOWN_CVM = 9
export const PED_VERIFICATION_TYPE_SIGNATURE_AND_PIN_VERIFIED = 10
export const PED_VERIFICATION_TYPE_CONSUMER_DEVICE_VERIFICATION = 14

export const LEAP_CONFIGURATION_DATA_MAP_TXNTYPES = 'TXNTYPES'
export const LEAP_CONFIGURATION_DATA_MAP_TIER1PP = 'TIER1PP'
export const LEAP_CONFIGURATION_DATA_MAP_CARD_EXPIRY_DURATION = 'Card Expiry Duration'
export const LEAP_CONFIGURATION_DATA_MAP_PPTU_MINIMUM_TRANSACTION_VALUE = 'PPTU.MinimumTransactionValue'
export const LEAP_CONFIGURATION_DATA_MAP_PPTU_MAXIMUM_TRANSACTION_VALUE = 'PPTU.MaximumTransactionValue'
export const LEAP_CONFIGURATION_DATA_MAP_TRANSACTION_REVERSAL_DURATION = 'Transaction Reversal Duration'

export const SCHEME_WIDE_CODE_MAP_BLOCKING_REASON = 'Blocking Reason'
export const SCHEME_WIDE_CODE_MAP_PRODUCT_TYPE_LONGNAMES = 'Product Type: Longnames'
export const SCHEME_WIDE_CODE_MAP_PARTICIPANT_ID_LONGNAME = 'Participant ID: Longname'
export const SCHEME_WIDE_CODE_MAP_PAYMENT_REFUND_METHOD = 'Payment/Refund Method'
export const SCHEME_WIDE_CODE_MAP_EVENT_CODE = 'Event Code'
export const SCHEME_WIDE_CODE_MAP_LOCATION_ID = 'Location ID'
export const SCHEME_WIDE_CODE_MAP_ROUTE_CODE = 'Route Code'
export const SCHEME_WIDE_CODE_MAP_SERVICE_CODE = 'Service Code'
export const SCHEME_WIDE_CODE_MAP_CARD_PROFILE = 'Card Profile'

export const LEAP_TRANSACTION_EVENT_CODE_CHECK_IN = 'Check-In'

export const SERVICE_ICON_CODE_DEFAULT = 'default'
export const SERVICE_ICON_CODE_N = 'N'
const SERVICE_ICON_CODE_BF = 'BF'
const SERVICE_ICON_CODE_BP = 'BP'
const SERVICE_ICON_CODE_BI = 'BI'
const SERVICE_ICON_CODE_B = 'B'
const SERVICE_ICON_CODE_TS = 'TS'
const SERVICE_ICON_CODE_1B = '1B'
const SERVICE_ICON_CODE_1S = '1S'
const SERVICE_ICON_CODE_1F = '1F'
const SERVICE_ICON_CODE_P = 'P'
const SERVICE_ICON_CODE_A = 'A'

const SERVICE_ICON_WHEELCHAIR = 'service-feature-wheelchair'
const SERVICE_ICON_PRIORITY = 'service-feature-priority'
const SERVICE_ICON_BIKE = 'service-feature-bike'
const SERVICE_ICON_BREAKFAST_1ST = 'service-feature-breakfast1st'
const SERVICE_ICON_BUFFET_BAR = 'service-feature-buffetbar'
const SERVICE_ICON_TROLLEY = 'service-feature-trolley'
const SERVICE_ICON_BUFFET = 'service-feature-buffet'
const SERVICE_ICON_BUFFET_1ST = 'service-feature-buffet1st'
const SERVICE_ICON_FIRST_CLASS = 'service-feature-firstclass'
const SERVICE_ICON_STANDARD = 'service-feature-standard'
const SERVICE_ICON_BIKE_PREBOOKED = 'service-feature-bikeprebooked'
const SERVICE_ICON_BIKE_AVAILABLE = 'service-feature-bikeavailable'
const SERVICE_ICON_ALERT = 'service-feature-alert'

export const SERVICE_ICONS_MAP = {
  [SERVICE_ICON_CODE_DEFAULT]: [
    SERVICE_ICON_WHEELCHAIR,
    SERVICE_ICON_PRIORITY,
    SERVICE_ICON_BIKE
  ],
  [SERVICE_ICON_CODE_BF]: [
    SERVICE_ICON_BREAKFAST_1ST,
    SERVICE_ICON_BUFFET_BAR,
    SERVICE_ICON_TROLLEY
  ],
  [SERVICE_ICON_CODE_BP]: [
    SERVICE_ICON_BUFFET,
    SERVICE_ICON_BUFFET_BAR,
    SERVICE_ICON_TROLLEY
  ],
  [SERVICE_ICON_CODE_BI]: [
    SERVICE_ICON_BUFFET_1ST,
    SERVICE_ICON_BUFFET_BAR,
    SERVICE_ICON_TROLLEY
  ],
  [SERVICE_ICON_CODE_B]: [
    SERVICE_ICON_BUFFET_BAR,
    SERVICE_ICON_TROLLEY
  ],
  [SERVICE_ICON_CODE_TS]: [
    SERVICE_ICON_TROLLEY
  ],
  [SERVICE_ICON_CODE_1B]: [
    SERVICE_ICON_FIRST_CLASS,
    SERVICE_ICON_STANDARD
  ],
  [SERVICE_ICON_CODE_1S]: [
    SERVICE_ICON_STANDARD
  ],
  [SERVICE_ICON_CODE_1F]: [
    SERVICE_ICON_FIRST_CLASS
  ],
  [SERVICE_ICON_CODE_P]: [
    SERVICE_ICON_BIKE_PREBOOKED
  ],
  [SERVICE_ICON_CODE_A]: [
    SERVICE_ICON_BIKE_AVAILABLE
  ],
  [SERVICE_ICON_CODE_N]: [
    SERVICE_ICON_ALERT
  ]
}

export const HOTKEY_MAP = {
  increase: 'plus',
  decrease: '-',
  up: 'up',
  down: 'down',
  left: 'left',
  right: 'right',
  space: 'space',
  showMore: 'alt+m',
  close: 'esc',
  selectMagstripe: 'ctrl+m',
  selectBarcode: 'ctrl+b',
  selectLeap: 'ctrl+l',
  selectCash: 'ctrl+c',
  selectVoucher: 'ctrl+v',
  selectCard: 'ctrl+x',
  focusNote: 'alt+n',
  proceed: {sequence: 'ctrl+enter', global: true},
  focusOrigin: {sequence: 'alt+o', action: 'keydown', global: true},
  focusDestination: {sequence: 'alt+d', action: 'keydown', global: true},
  focusValidFrom: {sequence: 'alt+v', action: 'keydown', global: true},
  focusInboundDate: {sequence: 'alt+t', action: 'keydown', global: true},
  incrementAdult: {sequence: 'alt+a', global: true},
  incrementStudent: {sequence: 'alt+s', global: true},
  decrementAdult: {sequence: 'ctrl+shift+a', global: true},
  decrementChild: {sequence: 'ctrl+shift+c', global: true},
  decrementStudent: {sequence: 'ctrl+shift+s', global: true},
  decrementDate: {sequence: 'ctrl+shift+v', global: true},
  toggleBikeAvailability: {sequence: 'alt+b', global: true},
  focusProductFamily: {sequence: 'alt+y', action: 'keydown', global: true},
  focusProduct: {sequence: 'alt+p', action: 'keydown', global: true},
  refresh: {sequence: 'alt+r', global: true},
  cancel: {sequence: 'alt+c', global: true},
  selectProductNr1: {sequence: 'ctrl+alt+1', action: 'keydown', global: true},
  selectProductNr2: {sequence: 'ctrl+alt+2', action: 'keydown', global: true},
  selectProductNr3: {sequence: 'ctrl+alt+3', action: 'keydown', global: true},
  selectProductNr4: {sequence: 'ctrl+alt+4', action: 'keydown', global: true},
  selectProductNr5: {sequence: 'ctrl+alt+5', action: 'keydown', global: true},
  selectProductNr6: {sequence: 'ctrl+alt+6', action: 'keydown', global: true},
  selectProductNr7: {sequence: 'ctrl+alt+7', action: 'keydown', global: true},
  selectProductNr8: {sequence: 'ctrl+alt+8', action: 'keydown', global: true},
  selectProductNr9: {sequence: 'ctrl+alt+9', action: 'keydown', global: true},
  selectLeftNr1: {sequence: 'alt+1', action: 'keydown', global: true},
  selectLeftNr2: {sequence: 'alt+2', action: 'keydown', global: true},
  selectLeftNr3: {sequence: 'alt+3', action: 'keydown', global: true},
  selectLeftNr4: {sequence: 'alt+4', action: 'keydown', global: true},
  selectLeftNr5: {sequence: 'alt+5', action: 'keydown', global: true},
  selectLeftNr6: {sequence: 'alt+6', action: 'keydown', global: true},
  selectLeftNr7: {sequence: 'alt+7', action: 'keydown', global: true},
  selectLeftNr8: {sequence: 'alt+8', action: 'keydown', global: true},
  selectLeftNr9: {sequence: 'alt+9', action: 'keydown', global: true},
  chooseNr1: {sequence: 'ctrl+shift+1', action: 'keydown', global: true},
  chooseNr2: {sequence: 'ctrl+shift+2', action: 'keydown', global: true},
  chooseNr3: {sequence: 'ctrl+shift+3', action: 'keydown', global: true},
  chooseNr4: {sequence: 'ctrl+shift+4', action: 'keydown', global: true},
  chooseNr5: {sequence: 'ctrl+shift+5', action: 'keydown', global: true},
  chooseNr6: {sequence: 'ctrl+shift+6', action: 'keydown', global: true},
  chooseNr7: {sequence: 'ctrl+shift+7', action: 'keydown', global: true},
  chooseNr8: {sequence: 'ctrl+shift+8', action: 'keydown', global: true},
  chooseNr9: {sequence: 'ctrl+shift+9', action: 'keydown', global: true},
  focusDiscountCardNumberNr1: {sequence: 'alt+shift+1', action: 'keydown', global: true},
  focusDiscountCardNumberNr2: {sequence: 'alt+shift+2', action: 'keydown', global: true},
  focusDiscountCardNumberNr3: {sequence: 'alt+shift+3', action: 'keydown', global: true},
  focusDiscountCardNumberNr4: {sequence: 'alt+shift+4', action: 'keydown', global: true},
  focusDiscountCardNumberNr5: {sequence: 'alt+shift+5', action: 'keydown', global: true},
  focusDiscountCardNumberNr6: {sequence: 'alt+shift+6', action: 'keydown', global: true},
  focusDiscountCardNumberNr7: {sequence: 'alt+shift+7', action: 'keydown', global: true},
  focusDiscountCardNumberNr8: {sequence: 'alt+shift+8', action: 'keydown', global: true},
  focusDiscountCardNumberNr9: {sequence: 'alt+shift+9', action: 'keydown', global: true},
  chooseSeatOnlyNr1: {sequence: 'ctrl+1', action: 'keydown', global: true},
  chooseSeatOnlyNr2: {sequence: 'ctrl+2', action: 'keydown', global: true},
  chooseSeatOnlyNr3: {sequence: 'ctrl+3', action: 'keydown', global: true},
  chooseSeatOnlyNr4: {sequence: 'ctrl+4', action: 'keydown', global: true},
  chooseSeatOnlyNr5: {sequence: 'ctrl+5', action: 'keydown', global: true},
  chooseSeatOnlyNr6: {sequence: 'ctrl+6', action: 'keydown', global: true},
  chooseSeatOnlyNr7: {sequence: 'ctrl+7', action: 'keydown', global: true},
  chooseSeatOnlyNr8: {sequence: 'ctrl+8', action: 'keydown', global: true},
  chooseSeatOnlyNr9: {sequence: 'ctrl+9', action: 'keydown', global: true},
  focusEuro: {sequence: 'alt+e', global: true},
  focusGbp: {sequence: 'alt+g', global: true},
  logout: {sequence: 'alt+l', global: true}
}

export const HOTKEY_MAP_GLOBAL = {
  menuOpenTickets: {sequence: 'ctrl+alt+t', global: true},
  menuReservations: {sequence: 'ctrl+alt+r', global: true},
  menuAftersales: {sequence: 'ctrl+alt+a', global: true},
  menuLeap: {sequence: 'ctrl+alt+l', global: true},
  menuShift: {sequence: 'ctrl+alt+s', global: true},
  menuMaintenance: {sequence: 'ctrl+alt+m', global: true}
}

export const FULFILLMENT_METHOD_ICON_MAP = {
  [FULFILLMENT_METHOD_CODE_LEAP]: 'fulfillment-method-leap',
  [FULFILLMENT_METHOD_CODE_LEAP_TOP_UP]: 'fulfillment-method-leap',
  [FULFILLMENT_METHOD_CODE_MAGSTRIPE]: 'fulfillment-method-magstripe',
  [FULFILLMENT_METHOD_CODE_BARCODE]: 'fulfillment-method-barcode',
  [FULFILLMENT_METHOD_CODE_EMAIL]: 'fulfillment-method-email',
  [FULFILLMENT_METHOD_CODE_HOMEPRINT]: 'fulfillment-method-homeprint',
  [FULFILLMENT_METHOD_CODE_MOBILE]: 'fulfillment-method-mobile',
  [FULFILLMENT_METHOD_CODE_PICKUP]: 'fulfillment-method-pickup',
  [FULFILLMENT_METHOD_CODE_POST]: 'fulfillment-method-post',
  [FULFILLMENT_METHOD_CODE_PDF]: 'fulfillment-method-pdf',
  [FULFILLMENT_METHOD_CODE_SMS]: 'fulfillment-method-sms',
  [FULFILLMENT_METHOD_CODE_TVM]: 'fulfillment-method-tvm',
  'default': 'tick'
}

export const getPreviousNextPageForBookingFlow = memoize(
  (currentPage, {skipSeatSelection, skipAdditionalProducts}) => {
    const pages = ['/reservations/journey-search']
    !skipSeatSelection && pages.push('/reservations/seat-selection')
    !skipAdditionalProducts && pages.push('/reservations/additional-products')
    pages.push('/reservations/details')
    pages.push('/reservations/review-payment')

    const currentPageIndex = !skipSeatSelection && currentPage.indexOf('/reservations/seat-selector') >= 0
      ? 1
      : pages.findIndex(path => currentPage.indexOf(path) >= 0)
    const nextPage = pages[currentPageIndex + 1]

    return {
      flow: pages,
      start: queryParams => queryParams ? `${pages[0]}?${createQueryFromValues(queryParams)}` : pages[0],
      next: nextPage || null,
      previous: pages[currentPageIndex - 1] ? pages[currentPageIndex - 1] : null,
      shouldCreateProvisionalBooking: nextPage === '/reservations/details',
      createProvisionalBookingFailurePage: pages[0],
      setSeatPreferences: currentPageIndex === 0
    }
  },
  (currentPage, data) => currentPage + JSON.stringify(data)
)

export const getPreviousNextPageForRebookingFlow = memoize(
  (currentPage, {skipSeatSelection, skipAdditionalProducts}) => {
    const pages = ['/aftersales/rebooking/journey-search']
    !skipSeatSelection && pages.push('/aftersales/rebooking/seat-selection')
    !skipAdditionalProducts && pages.push('/aftersales/rebooking/additional-products')
    pages.push('/aftersales/rebooking/review-payment')

    const currentPageIndex = !skipSeatSelection && currentPage.indexOf('/aftersales/rebooking/seat-selector') >= 0
      ? 1
      : pages.findIndex(path => currentPage.indexOf(path) >= 0)
    const nextPage = pages[currentPageIndex + 1]

    return {
      flow: pages,
      next: nextPage || null,
      previous: pages[currentPageIndex - 1] ? pages[currentPageIndex - 1] : null,
      shouldCreateBooking: ['/aftersales/rebooking/review-payment'].includes(nextPage),
      returnPage: bookingNumber => `/aftersales/booking/${bookingNumber}`,
      createBookingFailurePage: pages[0]
    }
  },
  (currentPage, data) => currentPage + JSON.stringify(data)
)

export const DEVICE_ID_PED = 'PED'
export const DEVICE_ID_LEAP = 'LeapScim'
export const DEVICE_ID_RECEIPT_PRINTER = 'ReceiptPrinter'
export const DEVICE_ID_TICKET_PRINTER = 'MagneticTicketPrinter'
export const DEVICE_ID_CASH_DRAWER = 'CashDrawer'
export const DEVICE_ID_CUSTOMER_DISPLAY = 'CustomerDisplay'
export const DEVICE_ID_BARCODE_SCANNER = 'BarcodeScannerDevice'
