import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import './row.scss'

export default class Row extends Component {
  static propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    wrap: PropTypes.bool
  }

  static defaultProps = {
    wrap: true
  }

  render () {
    const {className, name, children, wrap, ...rest} = this.props
    return (
      <div {...rest} className={classNames('row', name ? `row-${name}` : '', className)}>
        {wrap ? <div className='wrap'>{children}</div> : children}
      </div>
    )
  }
}
