import {
  API_CMS_LOAD_BLOCKS_REQUEST,
  API_CMS_LOAD_BLOCKS_SUCCESS,
  API_CMS_LOAD_BLOCKS_FAILURE
} from '../../types'
import Client from '../../../../api/client'
import ensureToken from '../../../../api/ensure-token'

const loadCmsBlocksRequest = (blockNames, locale, data = null) => ({type: API_CMS_LOAD_BLOCKS_REQUEST, blockNames, locale, data})
const loadCmsBlocksSuccess = (blockNames, response, locale, data = null) => ({type: API_CMS_LOAD_BLOCKS_SUCCESS, blockNames, response, locale, data})
const loadCmsBlocksFailure = (blockNames, error, locale, data = null) => ({type: API_CMS_LOAD_BLOCKS_FAILURE, blockNames, error, locale, data})

export const loadCmsBlocks = (blockNames, locale) => ensureToken(async (token, dispatch) => {
  try {
    dispatch(loadCmsBlocksRequest(blockNames, locale))
    const response = await Client.loadCmsBlocks(token, blockNames)
    dispatch(loadCmsBlocksSuccess(blockNames, response, locale))
  } catch (error) {
    dispatch(loadCmsBlocksFailure(blockNames, error, locale))
    return false
  }
})
