import PropTypes from 'prop-types'
import React, { Component } from 'react'
import SeatSelectorWrapper from '../../../containers/aftersales/change-seats/seat-selector/seat-selector-wrapper'
import CarriageSelector from '../../../containers/base/seat-selector/carriage-selector'
import CarriageFloorplan from '../../../containers/base/seat-selector/carriage-floorplan'
import PassengerManifest from '../../../containers/base/seat-selector/passenger-manifest'
import SegmentNavigation from '../../../containers/base/seat-selector/segment-navigation'
import ProgressNavigation from '../../../containers/aftersales/change-seats/seat-selector/progress-navigation'
import Taskbar from '../../../containers/base/taskbar/taskbar'

export default class SeatSelector extends Component {
  static propTypes = {
    handleGoForward: PropTypes.func.isRequired,
    params: PropTypes.shape({
      segmentId: PropTypes.string
    })
  }

  render () {
    return (
      <SeatSelectorWrapper segmentId={this.props.params.segmentId}>
        <SegmentNavigation segmentId={this.props.params.segmentId} />
        <div className='passenger-selector-seat-layout-carriage-selector-container'>
          <PassengerManifest aftersales />
          <CarriageFloorplan aftersales />
          <CarriageSelector aftersales />
        </div>
        <ProgressNavigation
          segmentId={this.props.params.segmentId}
          segmentUrl='/aftersales/change-seats/seat-selector/:segmentId'
          goForward={this.props.handleGoForward}
          messageBase='aftersales.change-seats.footer'
        >
          <Taskbar />
        </ProgressNavigation>
      </SeatSelectorWrapper>
    )
  }
}
