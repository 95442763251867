import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Form from '../../../../elements/form/form'
import {
  STATE_CHECKED,
  STATE_UNCHECKED,
  STATE_INDETERMINATE
} from '../../../../elements/checkbox-radiobutton/tri-state-checkbox-field'

export default class CancellationOptionsCheckbox extends Component {
  static propTypes = {
    onToggle: PropTypes.func.isRequired,
    labelText: PropTypes.string,
    cancellationData: PropTypes.shape({
      allItemsSelected: PropTypes.bool.isRequired,
      someItemsSelected: PropTypes.bool.isRequired
    }).isRequired,
    showIndeterminateAsUnchecked: PropTypes.bool
  }

  static defaultProps = {
    showIndeterminateAsUnchecked: false
  }

  _getState () {
    const {cancellationData, showIndeterminateAsUnchecked} = this.props

    let state = STATE_UNCHECKED
    if (cancellationData.allItemsSelected) {
      state = STATE_CHECKED
    } else if (cancellationData.someItemsSelected) {
      state = showIndeterminateAsUnchecked ? STATE_UNCHECKED : STATE_INDETERMINATE
    }

    return state
  }

  render () {
    const {onToggle, labelText, ...props} = this.props
    delete props.showIndeterminateAsUnchecked
    delete props.cancellationData

    return (
      <Form.TriStateCheckbox
        {...props}
        state={this._getState()}
        onChange={onToggle}
        placeholder=''
      >
        <Form.Label
          htmlFor={props.id}
          name={props.name}
          text={labelText}
        />
      </Form.TriStateCheckbox>
    )
  }
}
