import React, { Component } from 'react'
import Shift from '../../containers/agent/shift/shift'
import GenericLayout from '../generic-layout'
import GridLayout from '../../elements/grid/grid-layout'
import GridColumn from '../../elements/grid/grid-column'
import Header from '../../components/base/header/header'
import TaskBar from '../../containers/base/taskbar/taskbar'
import StartShiftErrorModal from '../../containers/agent/shift/start-shift-error-modal'

export default class ShiftLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <Header />
            <Shift />
            <TaskBar />
          </GridColumn>
        </GridLayout>
        <StartShiftErrorModal />
      </GenericLayout>
    )
  }
}
