import {
  MACHINE_OPEN_CASH_DRAWER_REQUEST,
  MACHINE_OPEN_CASH_DRAWER_SUCCESS,
  MACHINE_OPEN_CASH_DRAWER_FAILURE
} from '../types'
import DeviceManager from '../../../machine/device-manager'

const openCashDrawerRequest = () => ({type: MACHINE_OPEN_CASH_DRAWER_REQUEST})
const openCashDrawerSuccess = response => ({type: MACHINE_OPEN_CASH_DRAWER_SUCCESS, response})
const openCashDrawerFailure = machineError => ({type: MACHINE_OPEN_CASH_DRAWER_FAILURE, machineError})

export const openMachineCashDrawer = () => async dispatch => {
  try {
    dispatch(openCashDrawerRequest())
    const response = await DeviceManager.openCashDrawer()
    dispatch(openCashDrawerSuccess(response))
  } catch (error) {
    dispatch(openCashDrawerFailure(error))
  }
}
