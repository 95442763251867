import React from 'react'
import { Route } from 'react-router'
import VoucherToCashLayout from '../../layouts/aftersales/voucher-to-cash'
import containAuth from '../../containers/base/auth'
import { AGENT_ROLE_OPERATOR } from '../../constants'

export default (
  <Route
    key='aftersales-voucher-to-cash'
    path='/:lang/aftersales/voucher-to-cash'
    component={containAuth(VoucherToCashLayout, {roles: [AGENT_ROLE_OPERATOR]})}
  />
)
