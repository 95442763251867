import {
  bookingSelector,
  bookingNumberSelector,
  paymentRequiredSelector,
  fulfillmentMethodSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { aftersalesConfirm } from 's3p-js-lib/src/redux/actions/api/aftersales/confirm'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import {
  goToAftersalesBooking,
  goToAftersalesChangeSeatsReviewPayment
} from '../../base/routing'
import { isMagstripeOrTVMFulfillment } from '../../../../../misc/utils'
import { reprintResendBooking } from '../booking/reprint-resend'
import {displayNextCustomer} from '../../base/finalize-booking/next-customer'

export const handleUpdateChangeSeats = () => async (dispatch, getState) => {
  const fulfillmentMethod = fulfillmentMethodSelector(bookingSelector)(getState())
  if (paymentRequiredSelector(bookingSelector)(getState())) {
    dispatch(goToAftersalesChangeSeatsReviewPayment())
  } else {
    const bookingNumber = bookingNumberSelector(bookingSelector)(getState())
    if (isMagstripeOrTVMFulfillment(fulfillmentMethod)) {
      dispatch(reprintResendBooking())
    } else { await dispatch(aftersalesConfirm(bookingNumber)) }
    dispatch([
      clearState('userInput.aftersales.waiveFees'),
      goToAftersalesBooking(bookingNumber),
      displayNextCustomer()
    ])
  }
}
