import { createSelector } from 'reselect'
import { DOCUMENT_TYPE_PIN, DOCUMENT_TYPE_RECEIPT } from '../../../../constants'

export const pinDocumentSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking && booking.ticket_documents && booking.ticket_documents.find(
    doc => doc.document_type === DOCUMENT_TYPE_PIN
  )
)

export const receiptDocumentSelector = bookingSelector => createSelector(
  [bookingSelector],
  booking => booking && booking.ticket_documents && booking.ticket_documents.find(
    ticketDocument => ticketDocument.document_type === DOCUMENT_TYPE_RECEIPT
  )
)
