import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Modal from '../../../../elements/modal/modal'
import TextLabel from '../../../../elements/text-label/text-label'
import _t from 's3p-js-lib/src/translate'

export default class ConfirmDiscardPendingChanges extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onContinue: PropTypes.func.isRequired
  }

  render () {
    const {name, onContinue} = this.props
    return (
      <Modal
        name={name}
        header={<Modal.Header title={_t.message('seat-selector.confirm-discard-pending-changes.title')} />}
        footer={<Modal.Footer onContinue={onContinue} />}
      >
        <TextLabel name='information' text={_t.message('seat-selector.confirm-discard-pending-changes.information')} />
      </Modal>
    )
  }
}
