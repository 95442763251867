import { createSelector } from 'reselect'
import moment from 'moment-timezone'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'
import { camelCaseKeys } from 's3p-js-lib/src/misc/utils'
import {
  TRAVEL_DIRECTION_OUTBOUND,
  TRAVEL_DIRECTION_INBOUND,
  SALES_PRESENTATION_ONCE_PER_JOURNEY
} from 's3p-js-lib/src/constants'
import { toTimezoneMoment } from 's3p-js-lib/src/misc/date'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import { passengersSelector } from 's3p-js-lib/src/redux/selectors/api/booking/passengers'
import { afterSalesAdditionalProductsSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/additional-products'

const passengersAfterSalesSelector = passengersSelector(bookingSelector)

export const additionalProductsSelector = createSelector(
  [
    afterSalesAdditionalProductsSelector,
    passengersAfterSalesSelector
  ],
  (additionalProducts, passengers) => {
    const productsById = groupBy(
      additionalProducts,
      additionalProduct => `${additionalProduct.product.type}|${additionalProduct.product.code}`
    )

    passengers = keyBy(passengers, 'id')
    const selectedProducts = []
    Object.keys(productsById).forEach(key => {
      const products = productsById[key]
      let allPassengers = []
      const productSpecification = _getProductSpecifications(products, key, passengers, allPassengers)

      productSpecification.lowestPrice = _getLowestPrice(allPassengers)

      productSpecification.travelPriceSpecifications = _convertObjectsToArray(productSpecification.travelPriceSpecifications)

      selectedProducts.push(productSpecification)
    })
    return sortBy(selectedProducts, 'id')
  }
)

const _convertObjectsToArray = travelPriceSpecifications => {
  return Object.values(
    travelPriceSpecifications
  ).map(
    travelPriceSpecification => ({
      ...travelPriceSpecification,
      priceSpecifications: Object.values(travelPriceSpecification.priceSpecifications)
    })
  )
}

const _getLowestPrice = allPassengers => {
  return allPassengers.length ? allPassengers.reduce(
    (price, passenger) => Math.min(price, passenger.price),
    Number.MAX_VALUE
  ) : null
}

const _getProductSpecifications = (products, key, passengers, allPassengers) => {
  return products.reduce(
    (specification, product) => {
      const hasAddedNewProducts = product.new_quantity > product.original_quantity
      const quantity = product.new_quantity || product.original_quantity

      specification.totalPrice += quantity > 0 && hasAddedNewProducts
        ? product.price
        : 0

      const direction = _getDirection(product)
      const originDestination = _setOriginDestination(product)
      _setTravelPriceSpecificationForDirection(specification, direction, product)

      const priceSpecifications = specification.travelPriceSpecifications[direction].priceSpecifications
      _setPriceSpecifications(priceSpecifications, product, originDestination)

      const priceSpecification = priceSpecifications[originDestination]

      _updatePassengers(passengers, priceSpecification, product, allPassengers, quantity)

      specification.selected = specification.selected || hasAddedNewProducts

      return {
        ...specification,
        ...camelCaseKeys(product.product)
      }
    },
    {
      id: key,
      selected: false,
      totalPrice: 0,
      travelPriceSpecifications: {}
    }
  )
}

const _getDirection = product => {
  return product.segment.direction === 'outward'
    ? TRAVEL_DIRECTION_OUTBOUND
    : TRAVEL_DIRECTION_INBOUND
}

const _setTravelPriceSpecificationForDirection = (specification, direction, product) => {
  if (!specification.travelPriceSpecifications[direction]) {
    specification.travelPriceSpecifications[direction] = {
      travelDirection: direction,
      travelDate: moment(product.segment.departure_date),
      priceSpecifications: {}
    }
  }
}

const _setOriginDestination = product => {
  return product.product.sales_presentation === SALES_PRESENTATION_ONCE_PER_JOURNEY
    ? SALES_PRESENTATION_ONCE_PER_JOURNEY
    : `${product.segment.origin.code}|${product.segment.destination.code}`
}

const _setPriceSpecifications = (priceSpecifications, product, originDestination) => {
  if (!priceSpecifications[originDestination]) {
    priceSpecifications[originDestination] = {
      origin: {
        name: product.segment.origin.name,
        departureTime: toTimezoneMoment(
          product.segment.origin.departure_date_time,
          product.segment.origin.timezone
        )
      },
      destination: {
        name: product.segment.destination.name,
        arrivalTime: toTimezoneMoment(
          product.segment.destination.arrival_date_time,
          product.segment.destination.timezone
        )
      },
      passengers: []
    }
  }
}

const _updatePassengers = (passengers, priceSpecification, product, allPassengers, quantity) => {
  const passenger = passengers[product.passenger.id]
  if (passenger && !passenger.cancelled) {
    const existingPassenger = priceSpecification.passengers.find(
      _passenger => _passenger.id === product.passenger.id
    )

    if (existingPassenger) {
      existingPassenger.uniqueIds.push(product.id)
      existingPassenger.price = existingPassenger.price + product.price
    } else {
      const newPassenger = {
        ...passenger,
        id: product.passenger.id,
        passengerNumber: parseInt(passenger.number),
        type: product.passenger.type,
        price: product.price,
        uniqueId: product.id,
        uniqueIds: [product.id],
        disabled: product.original_quantity > 0,
        quantity: quantity
      }
      allPassengers.push(newPassenger)
      priceSpecification.passengers.push(newPassenger)
    }
  }
}
