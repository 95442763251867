import { UI_ELEMENT_HIDE, CLEAR_STATE } from 's3p-js-lib/src/redux/actions/types'
import { UI_ABORT_AFTERSALES_START } from '../../../actions/types'
import { CONTAINERS_AFTERSALES_OPERATION } from '../../../../constants'

const initialState = {
  isAbortOperation: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UI_ABORT_AFTERSALES_START:
      return {isAbortOperation: true}
    case UI_ELEMENT_HIDE:
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_AFTERSALES_OPERATION) ? initialState : state
    default:
      return state
  }
}
