import PropTypes from 'prop-types'
import React, { Component } from 'react'
import SidebarShortcuts from './sidebar-shortcuts'
import SidebarCart from './sidebar-cart'
import {connect} from 'react-redux'
import {provisionalOrCompletedBookingSelector} from '../../../redux/selectors/api/booking/booking'
import containVisibleElement from '../../base/visible-element'
import {SIDEBAR_CART} from '../../../constants'

class Sidebar extends Component {
  static propTypes = {
    hasBooking: PropTypes.bool.isRequired,
    onHideElement: PropTypes.func.isRequired,
    onShowElement: PropTypes.func.isRequired,
    isElementVisible: PropTypes.func.isRequired
  }

  constructor (...args) {
    super(...args)
    this.hideElement = this.hideElement.bind(this)
    this.showElement = this.showElement.bind(this)
  }

  hideElement () {
    this.props.onHideElement(SIDEBAR_CART)
  }

  showElement () {
    this.props.onShowElement(SIDEBAR_CART)
  }

  render () {
    return this.props.isElementVisible(SIDEBAR_CART) && this.props.hasBooking
      ? <SidebarCart handleShowShortcuts={this.hideElement} />
      : <SidebarShortcuts handleShowCart={this.showElement} />
  }
}

const mapStateToProps = state => ({
  hasBooking: Boolean(provisionalOrCompletedBookingSelector(state))
})

export default connect(mapStateToProps)(containVisibleElement(SIDEBAR_CART)(Sidebar))
