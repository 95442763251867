import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { routerActions } from '../../../redux/actions/containers/base/routing'
import SegmentNavigation, { MODAL_PENDING_CHANGES_ELEMENT } from '../../../components/base/seat-selector/segment-navigation'
import {
  inboundSeatSelectionOptionsSelector,
  outboundSeatSelectionOptionsSelector
} from '../../../redux/selectors/containers/base/seat-selection/seat-selection'
import {
  selectedSeatsSelector,
  isSeatSelectionValidSelector,
  hasPendingSeatSelectionSelector
} from 's3p-js-lib/src/redux/selectors/user-input/base/seat-selector'
import { selectSegment } from '../../../redux/actions/user-input/base/seat-selector'
import { segmentsSelector } from '../../../redux/selectors/containers/base/seat-selector/segment-navigation'
import { updateSelectBundleWithSeatSelection } from 's3p-js-lib/src/redux/actions/api/orientation/update-select-bundle'
import containVisibleElement from '../visible-element'

class SegmentNavigationContainer extends Component {
  static propTypes = {
    segmentId: PropTypes.string,
    nextSegmentId: PropTypes.string,
    hasPendingChanges: PropTypes.bool,
    isValid: PropTypes.bool,
    selectedSeats: PropTypes.array,
    updateSelectBundleWithSeatSelection: PropTypes.func.isRequired,
    selectSegment: PropTypes.func.isRequired,
    onShowModal: PropTypes.func.isRequired,
    onHideElement: PropTypes.func.isRequired,
    isElementVisible: PropTypes.func.isRequired,
    handleOpenSeatSelector: PropTypes.func.isRequired
  }

  constructor (...args) {
    super(...args)

    this.onSelectSegment = this.onSelectSegment.bind(this)
    this.onContinueChangeSegment = this.onContinueChangeSegment.bind(this)
  }

  onSelectSegment (newSegmentId) {
    if (this.props.isValid) {
      this.props.updateSelectBundleWithSeatSelection(this.props.segmentId, this.props.selectedSeats)
        .then(success => success && this.props.handleOpenSeatSelector(newSegmentId))
    } else if (this.props.hasPendingChanges) {
      this.props.selectSegment(newSegmentId)
      this.props.onShowModal(MODAL_PENDING_CHANGES_ELEMENT)
    } else {
      this.props.handleOpenSeatSelector(newSegmentId)
    }
  }

  onContinueChangeSegment () {
    this.props.onHideElement(MODAL_PENDING_CHANGES_ELEMENT)
    this.props.handleOpenSeatSelector(this.props.nextSegmentId)
  }

  render () {
    return (
      <SegmentNavigation
        onSelectSegment={this.onSelectSegment}
        onContinueChangeSegment={this.onContinueChangeSegment}
        showPendingChangesModal={this.props.isElementVisible(MODAL_PENDING_CHANGES_ELEMENT)}
        {...this.props}
      />
    )
  }
}

const mapStateToProps = () => {
  const outboundSegmentsSelector = segmentsSelector(outboundSeatSelectionOptionsSelector)
  const inboundSegmentsSelector = segmentsSelector(inboundSeatSelectionOptionsSelector)

  return (state, ownProps) => ({
    isValid: isSeatSelectionValidSelector(state),
    nextSegmentId: state.userInput.base.seatSelector.selectedSegmentId,
    hasPendingChanges: hasPendingSeatSelectionSelector(state),
    selectedSeats: selectedSeatsSelector(state),
    outboundSegments: outboundSegmentsSelector(state, ownProps),
    inboundSegments: inboundSegmentsSelector(state, ownProps)
  })
}

const mapDispatchToProps = {
  handleOpenSeatSelector: segmentId => routerActions.push(`/reservations/seat-selector/${segmentId}`),
  updateSelectBundleWithSeatSelection,
  selectSegment
}

export default connect(mapStateToProps, mapDispatchToProps)(
  containVisibleElement(MODAL_PENDING_CHANGES_ELEMENT)(SegmentNavigationContainer)
)
