import { connect } from 'react-redux'
import { overviewBookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { customerSelector } from 's3p-js-lib/src/redux/selectors/api/booking/customer'
import CustomerDetails from '../../../components/aftersales/booking/customer-details'

const _customerSelector = customerSelector(overviewBookingSelector)

const mapStateToProps = state => ({
  customer: _customerSelector(state)
})

export default connect(mapStateToProps)(CustomerDetails)
