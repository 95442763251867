import { createSelector } from 'reselect'
import { stationsSelector } from 's3p-js-lib/src/redux/selectors/api/base/stations'
import hex2bin from '../../../misc/hex2bin'

export const counterSelector = state => state.machine.magneticTicketPrinter.counter

export const serialSelector = state => state.machine.magneticTicketPrinter.serialNumber

const stationDigitCode = binaryCode => `00${parseInt(binaryCode, 2)}`.substr(-3)

export const isLoadingMagstripeActionsSelector = state =>
  state.machine.loading.readMagneticTicket ||
state.machine.loading.voidMagneticTicket ||
state.machine.loading.ejectMagneticTicket ||
state.machine.loading.printMagneticTicket

const daysOfWeekAvailable = data => {
  const availableDayBits = data.split('')
  let daysOfWeekAvailable = []
  if (availableDayBits[0] === '1') { daysOfWeekAvailable.push('sunday') }
  if (availableDayBits[1] === '1') { daysOfWeekAvailable.push('monday') }
  if (availableDayBits[2] === '1') { daysOfWeekAvailable.push('tuesday') }
  if (availableDayBits[3] === '1') { daysOfWeekAvailable.push('wednesday') }
  if (availableDayBits[4] === '1') { daysOfWeekAvailable.push('thursday') }
  if (availableDayBits[5] === '1') { daysOfWeekAvailable.push('friday') }
  if (availableDayBits[6] === '1') { daysOfWeekAvailable.push('saturday') }
  return daysOfWeekAvailable
}

const modalRestrictions = data => {
  const modalRestrictionsBits = data.split('')

  if (modalRestrictionsBits[0] === '1') { return 'bus-eireann' }
  if (modalRestrictionsBits[1] === '1') { return 'mainline-rail' }
  if (modalRestrictionsBits[2] === '1') { return 'suburban-rail' }
  if (modalRestrictionsBits[3] === '1') { return 'dublin-bus' }
  if (modalRestrictionsBits[4] === '1') { return 'feeder-bus' }
  return ''
}

const suburbanAccessAndTransfer = data => {
  const suburbanAccessAndTransferBits = data.split('')
  const access = suburbanAccessAndTransferBits[1] === '1'
    ? 'access.not-allowed'
    : 'access.allowed'

  const transfer = suburbanAccessAndTransferBits[2] === '1'
    ? 'transfer.not-allowed'
    : 'transfer.allowed'

  return [access, transfer]
}

const previousModeOfTravel = data => {
  switch (data) {
    case '000':
      return 'new'
    case '001':
      return 'local-link'
    case '010':
      return 'feeder-bus'
    case '011':
      return 'bus'
    case '100':
      return 'suburban-rail'
    case '111':
      return 'mainline-rail'
    default:
      return ''
  }
}

export const getTemplatesSelector = createSelector(
  [state => state.machine.magneticTicketPrinter.templates],
  templates => Object.keys(templates || {}).map(template => ({name: template, date: templates[template]}))
)

export const magneticDataSelector = createSelector(
  [
    state => state.machine.magneticTicketPrinter.magneticData,
    stationsSelector
  ],
  (magneticData, stations) => {
    if (!magneticData) {
      return null
    }
    const findStation = code => stations.find(station => station.synonyms.some(synonym => synonym === code))

    const magneticDataBinary = hex2bin(magneticData)
    const originCode = stationDigitCode(magneticDataBinary.substr(185, 8))
    const destinationCode = stationDigitCode(magneticDataBinary.substr(193, 9))
    const origin = findStation(originCode)
    const destination = findStation(destinationCode)

    return {
      headFlag: parseInt(magneticDataBinary.substr(16, 4), 2),
      machineOfIssue: parseInt(magneticDataBinary.substr(20, 12), 2),
      checksum: parseInt(magneticDataBinary.substr(32, 8), 2),
      lastYearOfValidity: parseInt(magneticDataBinary.substr(40, 2), 2),
      lastMonthOfValidity: parseInt(magneticDataBinary.substr(42, 4), 2),
      lastDayOfValidity: parseInt(magneticDataBinary.substr(46, 5), 2),
      modalRestrictions: modalRestrictions(magneticDataBinary.substr(51, 5)),
      ticketType: parseInt(magneticDataBinary.substr(56, 10), 2),
      daysOfWeekAvailable: daysOfWeekAvailable(magneticDataBinary.substr(66, 7)),
      numberOfJourneysAllowed: parseInt(magneticDataBinary.substr(73, 5), 2),
      numberOfJourneysTaken: parseInt(magneticDataBinary.substr(78, 5), 2),
      timeOfDayValidTable: parseInt(magneticDataBinary.substr(83, 4), 2),
      passengerCode: parseInt(magneticDataBinary.substr(87, 1), 2),
      checksumPrimaryData: parseInt(magneticDataBinary.substr(88, 8), 2),
      startOfValidityValidationYear: parseInt(magneticDataBinary.substr(144, 2), 2),
      startOfValidityValidationMonth: parseInt(magneticDataBinary.substr(146, 4), 2),
      startOfValidityValidationDay: parseInt(magneticDataBinary.substr(150, 5), 2),
      suburbanAccessAndTransfer: suburbanAccessAndTransfer(magneticDataBinary.substr(155, 4)),
      previousModeOfTravel: previousModeOfTravel(magneticDataBinary.substr(159, 3)),
      validationTimeHours: parseInt(magneticDataBinary.substr(162, 5), 2),
      validationTimeMinutes: parseInt(magneticDataBinary.substr(167, 6), 2),
      numberOfRoadValidations: parseInt(magneticDataBinary.substr(173, 5), 2),
      numberOfPtmValidations: parseInt(magneticDataBinary.substr(178, 5), 2),
      feederTripsTaken: parseInt(magneticDataBinary.substr(183, 2), 2),
      ticketSerial: parseInt(magneticDataBinary.substr(202, 18), 2),
      trailingFlags: parseInt(magneticDataBinary.substr(220, 4), 2),
      originCode,
      destinationCode,
      origin,
      destination
    }
  }
)
