import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import ComponentContainer from '../../../elements/component-container/component-container'
import TextLabel from '../../../elements/text-label/text-label'
import CurrentDateTime from './current-date-time'
import Diagnostics from '../../../containers/base/diagnostics'
import classNames from 'classnames'
import './taskbar.scss'
import TermsAndConditions from '../terms-and-conditions/terms-and-conditions'
import Link from '../../../elements/link/link'

export default class TaskBar extends Component {
  static propTypes = {
    username: PropTypes.string,
    terminalId: PropTypes.string,
    disableDiagnostics: PropTypes.bool,
    hasDeviceMessages: PropTypes.bool,
    handleOpenTermsAndConditions: PropTypes.func,
    showTermsAndConditionsModal: PropTypes.bool,
    showTermsAndConditionsLink: PropTypes.bool,
    termsAndConditionsLink: PropTypes.string
  }

  static defaultProps = {
    disableDiagnostics: false
  }

  render () {
    return (
      <ComponentContainer name='taskbar'>
        <div className={classNames('taskbar-content', {'alert': this.props.hasDeviceMessages && !this.props.disableDiagnostics})}>
          <div className='taskbar-content__information'>
            {this.props.username ? <TextLabel name='username' text={this.props.username} /> : null}
            {this.props.terminalId ? <TextLabel name='terminal-id' text={this.props.terminalId} /> : null}
            {this.props.showTermsAndConditionsLink
              ? <Link name='terms-and-conditions' onClick={this.props.handleOpenTermsAndConditions}>
                <TextLabel name='terms-and-conditions' text={_t.message('terms-and-conditions.link')} />
              </Link>
              : null
            }
          </div>
          <div className='taskbar-content__printer-status-date'>
            {!this.props.disableDiagnostics ? <Diagnostics /> : null}
            <CurrentDateTime />
          </div>
        </div>
        {this.props.showTermsAndConditionsModal ? <TermsAndConditions link={this.props.termsAndConditionsLink} /> : null}
      </ComponentContainer>
    )
  }
}
