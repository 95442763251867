import { connect } from 'react-redux'
import { provisionalOrOverviewBookingSelector } from '../../../redux/selectors/api/booking/booking'
import { fulfillmentMethodsSelector } from '../../../redux/selectors/containers/base/tiles/fulfillment-methods'
import { modalIsActiveSelector } from '../../../redux/selectors/containers/base/visible-element'
import FulfillmentMethods from '../../../containers/base/tiles/fulfillment-methods'
import { pendingSelectFulfillmentMethod } from '../../../redux/actions/containers/base/tiles/fulfillment-methods'

const _fulfillmentMethodsSelector = fulfillmentMethodsSelector(provisionalOrOverviewBookingSelector)
const mapStateToProps = state => {
  const modalIsActive = modalIsActiveSelector(state)

  return ({
    fulfillmentMethods: _fulfillmentMethodsSelector(state),
    disabled: (
      modalIsActive ||
      state.api.v2.loading.updateFulfillmentMethod ||
      state.api.v2.loading.confirmBooking ||
      state.machine.loading.getMachineStatusDevices ||
      state.containers.aftersales.booking.reprintResend.loading
    )
  })
}

const mapDispatchToProps = {
  handleSelect: pendingSelectFulfillmentMethod
}

export default connect(mapStateToProps, mapDispatchToProps)(FulfillmentMethods)
