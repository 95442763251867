import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../../../elements/text-label/text-label'
import TileSet from '../../../../elements/tile-set/tile-set'

class ProductTile extends Component {
  static propTypes = {
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired,
    handleSelectProduct: PropTypes.func.isRequired,
    selected: PropTypes.bool
  }

  constructor (...args) {
    super(...args)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick () {
    this.props.handleSelectProduct(this.props.product.id)
  }

  render () {
    return (
      <TileSet.Tile
        onClick={this.handleClick}
        selected={this.props.selected}
      >
        <TextLabel name={this.props.product.id} text={this.props.product.name} />
      </TileSet.Tile>
    )
  }
}

export default ProductTile
