import { connect } from 'react-redux'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { refundDetailsSelector } from '../../../redux/selectors/containers/aftersales/cancellation'

const mapStateToProps = state => ({
  hasProvisionalBooking: Boolean(bookingSelector(state)),
  ...refundDetailsSelector(state)
})

export default connect(mapStateToProps)
