import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ComponentContainer from '../../../elements/component-container/component-container'
import _t from 's3p-js-lib/src/translate'
import Panel from '../../../elements/panel/panel'
import SegmentSet from '../../../containers/aftersales/booking/segment-set'
import './journey-overview.scss'

export default class JourneyOverview extends Component {
  static propTypes = {
    outboundTravel: PropTypes.object,
    inboundTravel: PropTypes.object,
    outboundSegments: PropTypes.array
  }

  render () {
    return (this.props.outboundTravel || this.props.outboundSegments) ? (
      <ComponentContainer name='booking-details-journey-overview'>
        <Panel
          header={
            <Panel.Header
              name='booking-details-journey-overview'
              title={_t.message('aftersales.booking.journey-overview.header.title')}
              description={_t.message('aftersales.booking.journey-overview.header.description')}
            />
          }
        >
          <div className='travel-details'>
            <SegmentSet direction='outbound' />
            {this.props.inboundTravel ? <SegmentSet direction='inbound' /> : null}
          </div>
        </Panel>
      </ComponentContainer>
    ) : null
  }
}
