import React, {Component} from 'react'
import ErrorTickets from '../../containers/error/tickets'
import BasicLayout from '../../components/base/basic-layout'

export default class ErrorTicketsLayout extends Component {
  render () {
    return (
      <BasicLayout>
        <ErrorTickets />
      </BasicLayout>
    )
  }
}
