import {createSelector} from 'reselect'
import {originalBookingSelector} from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import {journeySegmentsSelector, productsSelector, selectedSeatsSelector} from 's3p-js-lib/src/redux/selectors/api/orientation/specification'
import moment from 'moment-timezone'

export const createRebookingV2RequestSelector = (waiveFees, overrideAftersalesRules, overrideValidationRules) => createSelector(
  originalBookingSelector,
  journeySegmentsSelector,
  productsSelector,
  selectedSeatsSelector,
  (originalBooking, journeySegments, products, selectedSeats) => {
    return {
      bookingNumber: originalBooking.booking_number,
      override_aftersales_fees: waiveFees,
      override_aftersales_rules: overrideAftersalesRules,
      override_validation_rules: overrideValidationRules,
      segments: journeySegments.map(journeySegment => ({
        origin: journeySegment.departure_station._u_i_c_station_code,
        destination: journeySegment.arrival_station._u_i_c_station_code,
        direction: journeySegment.direction,
        start_validity_date: moment(journeySegment.departure_date_time).format('YYYY-MM-DD'),
        service_name: journeySegment.service_name,
        service_identifier: journeySegment.service_identifier,
        items: products.reduce(
          (productItems, product) => {
            if (product.legs.includes(journeySegment.leg_id)) {
              const productItem = {
                passenger_id: product.passenger_id,
                tariff_code: product.tariff_code,
                price: product.price
              }
              const selectedSeat = product.isRequired && selectedSeats.find(
                selectedSeat => selectedSeat.passenger_id === product.passenger_id && product.legs.includes(selectedSeat.leg_id)
              )
              if (selectedSeat) {
                productItem.seat = {
                  number: selectedSeat.seat_number,
                  carriage: selectedSeat.carriage_number
                }
              }
              productItems.push(productItem)
            }
            return productItems
          }, []
        )
      }))
    }
  }
)
