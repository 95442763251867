import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fulfillmentMethodSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { isMagstripeOrTVMFulfillment } from '../../../misc/utils'
import VoidMagstripeTickets from './void-magstripe-tickets'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import { CONTAINERS_AFTERSALES_VOID_TICKETS } from '../../../constants'
import { originalOrOverviewBookingSelector } from '../../../redux/selectors/api/booking/booking'

class VoidFulfillmentContainer extends Component {
  static propTypes = {
    isMagstripeFulfillment: PropTypes.bool,
    clearState: PropTypes.func
  }

  componentWillUnmount () {
    this.props.clearState(CONTAINERS_AFTERSALES_VOID_TICKETS)
  }

  render () {
    const {isMagstripeFulfillment, ...props} = this.props
    delete props.clearState

    return isMagstripeFulfillment ? <VoidMagstripeTickets {...props} /> : null
  }
}

const _fulfillmentMethodSelector = fulfillmentMethodSelector(originalOrOverviewBookingSelector)

const mapStateToProps = state => ({
  isMagstripeFulfillment: isMagstripeOrTVMFulfillment(_fulfillmentMethodSelector(state))
})

const mapDispatchToProps = {
  clearState
}

export default connect(mapStateToProps, mapDispatchToProps)(VoidFulfillmentContainer)
