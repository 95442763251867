import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import TestThermalPrinter from '../../components/maintenance/test-thermal-printer'
import { sendMachineTestReceiptPrint } from '../../redux/actions/machine/receipt-printer'

class TestThermalPrinterContainer extends Component {
  static propTypes = {
    sendMachineTestReceiptPrint: PropTypes.func.isRequired
  }

  UNSAFE_componentWillMount () {
    this.props.sendMachineTestReceiptPrint()
  }

  render () {
    return <TestThermalPrinter {...this.props} />
  }
}

const mapStateToProps = state => ({
  isLoadingTestReceiptMachinePrint: state.machine.loading.testReceiptMachinePrint,
  testStatus: state.machine.receiptPrinter.testStatus
})

const mapDisplayToProps = {
  sendMachineTestReceiptPrint
}

export default connect(mapStateToProps, mapDisplayToProps)(TestThermalPrinterContainer)
