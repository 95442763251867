import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TravelCompact from './travel-compact'
import { TRAVEL_DIRECTION_OUTBOUND, TRAVEL_DIRECTION_INBOUND } from 's3p-js-lib/src/constants'

export default class JourneySummary extends Component {
  static propTypes = {
    outboundTravel: PropTypes.object,
    inboundTravel: PropTypes.object
  }

  render () {
    const {outboundTravel, inboundTravel} = this.props
    return (
      <div className='journey-summary'>
        {outboundTravel ? <TravelCompact {...outboundTravel} direction={TRAVEL_DIRECTION_OUTBOUND} /> : null}
        {inboundTravel ? <TravelCompact {...inboundTravel} direction={TRAVEL_DIRECTION_INBOUND} /> : null}
      </div>
    )
  }
}
