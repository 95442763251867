import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Feedback, { FEEDBACK_WARNING, FEEDBACK_ALERT } from './feedback'
import ResponseCodes from 's3p-js-lib/src/api/response-codes'
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_WARNING } from 's3p-js-lib/src/constants'

export default class FeedbackApi extends Component {
  static propTypes = {
    messages: PropTypes.array.isRequired,
    clearApiErrors: PropTypes.func,
    defaultMessage: PropTypes.string
  }

  componentWillUnmount () {
    this.props.clearApiErrors && this.props.clearApiErrors()
  }

  render () {
    const feedback = this.props.messages.reduce(
      (_feedback, message, index) => {
        if ([MESSAGE_SEVERITY_WARNING, MESSAGE_SEVERITY_ERROR].includes(message.severity)) {
          const _defaultMessage = message.defaultMessage || this.props.defaultMessage
          const text = ResponseCodes.hasMessage(message.code)
            ? ResponseCodes.translate(message.code, _defaultMessage)
            : _t.hasMessage(_defaultMessage || '')
              ? (_defaultMessage === 'application-error.known-error' && message.code
                ? _t.message(_defaultMessage, {errorCode: message.code}) : _t.message(_defaultMessage)
              ) : _defaultMessage

          if (text) {
            _feedback.push(
              <Feedback
                key={index}
                status={MESSAGE_SEVERITY_WARNING === message.severity ? FEEDBACK_WARNING : FEEDBACK_ALERT}
                text={text}
              />
            )
          }
        }
        return _feedback
      },
      []
    )

    return feedback.length ? <react-wrapper class='react-wrapper'>{feedback}</react-wrapper> : null
  }
}
