import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Modal from '../../../elements/modal/modal'
import { VOID_TICKETS_MODAL } from '../../../constants'
import Feedback, { FEEDBACK_INFORMATION } from '../../../elements/feedback/feedback'

export default class VoidTicketsModal extends Component {
  static propTypes = {
    onClickNext: PropTypes.func
  }

  render () {
    return (
      <Modal
        name={VOID_TICKETS_MODAL}
        isCloseable
        header={<Modal.Header title={_t.message('aftersales.void-tickets.header.title')} />}
        footer={<Modal.Footer onContinue={this.props.onClickNext} />}
      >
        <Feedback
          status={FEEDBACK_INFORMATION}
          text={_t.message('aftersales.void-tickets.can-override-void-tickets')}
        />
      </Modal>
    )
  }
}
