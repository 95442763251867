import Validator from './validator'

export const CATEGORY_BOOKING_NUMBER = 'bookingNumber'
export const CATEGORY_CUSTOMER_NAME = 'customerName'
export const CATEGORY_CUSTOMER_EMAIL = 'customerEmail'
export const CATEGORY_CUSTOMER_COMPANY = 'customerCompany'
export const CATEGORY_PASSENGER_NAME = 'passengerName'
export const CATEGORY_PASSENGER_EMAIL = 'passengerEmail'

export default {
  fields: {
    term: {
      validators: [
        Validator.isRequired
      ]
    },
    category: {
      validators: [
        Validator.isRequired
      ]
    }
  }
}
