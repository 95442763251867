import lastApiErrors from './last-api-errors'
import v2 from './v2'

export const initialState = {
  v2: {}
}

export default (state = initialState, action) => {
  const lastApiErrorsState = lastApiErrors(state, action)
  const v2State = v2(state.v2, action)
  return state.v2 !== v2State || state !== lastApiErrorsState
    ? {...lastApiErrorsState, v2: v2State}
    : state
}
