import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../../elements/text-label/text-label'
import Icon from '../../../elements/icon/icon'
import Link from '../../../elements/link/link'

export const STATUS_AVAILABLE = 'available'
export const STATUS_SELECTED = 'selected'
export const STATUS_DISABLED = 'disabled'

export default class MainMenuItem extends Component {
  static propTypes = {
    status: PropTypes.oneOf([STATUS_AVAILABLE, STATUS_DISABLED, STATUS_SELECTED]).isRequired,
    icon: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    hotkey: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
  }

  renderLabel () {
    return (
      <TextLabel name='navigation-item'>
        <Icon name={this.props.icon} className='align-left' />
        <TextLabel.Text text={this.props.text} />
      </TextLabel>
    )
  }

  render () {
    const {onClick, status, hotkey} = this.props

    return (
      <li className={`state--is-${status}`}>
        {status !== STATUS_DISABLED
          ? (
            <Link
              name='navigation-item'
              onClick={onClick}
              tabIndex='-1'
              hotkey={hotkey}
            >
              {this.renderLabel()}
            </Link>
          )
          : this.renderLabel()
        }
      </li>
    )
  }
}
