import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import FulfillmentMethods from '../../../components/base/tiles/fulfillment-methods'
import { hasLeapFulfillmentSelector } from '../../../redux/selectors/containers/base/tiles/fulfillment-methods'
import { leapStatus } from '../../../redux/actions/machine/leap'

class FulfillmentMethodsContainer extends Component {
  static propTypes = {
    hasLeapFulfillment: PropTypes.bool,
    updateLeapStatus: PropTypes.func.isRequired
  }

  UNSAFE_componentWillMount () {
    if (this.props.hasLeapFulfillment) {
      this.props.updateLeapStatus()
    }
  }

  render () {
    const props = {...this.props}
    delete props.updateLeapStatus
    delete props.hasLeapFulfillment

    return <FulfillmentMethods {...props} />
  }
}

const mapStateToProps = () => {
  const _hasLeapFulfillmentSelector = hasLeapFulfillmentSelector(bookingSelector)
  return state => ({
    hasLeapFulfillment: _hasLeapFulfillmentSelector(state)
  })
}

const mapDispatchToProps = {
  updateLeapStatus: leapStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(FulfillmentMethodsContainer)
