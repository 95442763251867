import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Modal from '../../../elements/modal/modal'
import Button from '../../../elements/button/button'
import TextLabel from '../../../elements/text-label/text-label'

export const CART_VALUE_ZERO_MODAL = 'cart-value-zero-modal'

export default class CartValueZeroModal extends Component {
  static propTypes = {
    proceedFulfillmentWithCartValueZero: PropTypes.func.isRequired,
    abortFulfillmentWithCartValueZero: PropTypes.func.isRequired,
    isLoadingProceed: PropTypes.bool
  }

  render () {
    return (
      <Modal
        name={CART_VALUE_ZERO_MODAL}
        isCloseable={false}
        header={
          <Modal.Header
            title={_t.message(`product-search.cart-zero-value-warning.title`)}
          />
        }
        footer={
          <CartValueZeroModalFooter
            {...this.props}
          />
        }
      >
        <p>{_t.message(`product-search.cart-zero-value-warning.information`)}</p>
      </Modal>
    )
  }
}

class CartValueZeroModalFooter extends Component {
  static propTypes = {
    abortFulfillmentWithCartValueZero: PropTypes.func.isRequired,
    proceedFulfillmentWithCartValueZero: PropTypes.func.isRequired,
    isLoadingProceed: PropTypes.bool.isRequired
  }

  render () {
    return (
      <Modal.CustomFooter>
        <Button
          name='modal-cancel'
          className='cancel'
          tabIndex='1'
          onClick={this.props.abortFulfillmentWithCartValueZero}
        >
          <TextLabel>
            <TextLabel.Text text={_t.message('product-search.cart-zero-value-warning.button.cancel')} />
          </TextLabel>
        </Button>
        <Button
          name='modal-continue'
          className='primary'
          disabled={this.props.isLoadingProceed}
          loading={this.props.isLoadingProceed}
          onClick={this.props.proceedFulfillmentWithCartValueZero}
          autoFocus
        >
          <TextLabel>
            <TextLabel.Text text={_t.message('product-search.cart-zero-value-warning.button.continue')} />
          </TextLabel>
        </Button>
      </Modal.CustomFooter>
    )
  }
}
