import { createSelector } from 'reselect'
import { STATUS_ERROR } from '../../../machine/device-manager'

export const isOfflineSelectorCreator = deviceType => createSelector(
  [
    state => state.machine.status.data
  ],
  machineStatus => {
    if (!machineStatus || !machineStatus.length) {
      return true
    }
    const device = Array.isArray(machineStatus) && machineStatus.find(machine => machine.id === deviceType)
    return device ? device.status === STATUS_ERROR : true
  }
)
