import { createSelector } from 'reselect'
import { camelCaseKeys } from '../../../../misc/utils'
import { toUtcDateMoment } from '../../../../misc/date'

export const ticketDetailsSelector = createSelector(
  [state => state.api.v2.ticketDetails],
  ticketDetails => ticketDetails
    ? {
      ...camelCaseKeys(ticketDetails),
      passenger: ticketDetails.passenger ? camelCaseKeys(ticketDetails.passenger) : null,
      connection: ticketDetails.connection ? camelCaseKeys(ticketDetails.connection) : null,
      createDate: toUtcDateMoment(ticketDetails.create_timestamp),
      updateDate: ticketDetails.update_timestamp ? toUtcDateMoment(ticketDetails.update_timestamp) : null,
      cancelDate: ticketDetails.cancel_timestamp ? toUtcDateMoment(ticketDetails.cancel_timestamp) : null
    }
    : null
)
