import { connect } from 'react-redux'
import {
  travelSelector,
  productFamiliesSelector,
  routeIdsSelector
} from '../../../../redux/selectors/containers/base/journey-search/journey-result-set'
import JourneyResultSet from '../../../../components/base/journey-search/journey-result-set/journey-result-set'

const mapStateToProps = (state, ownProps) => {
  const _travelSelector = travelSelector(ownProps.direction)
  const _routeIdsSelector = routeIdsSelector(ownProps.direction)

  return state => ({
    travel: _travelSelector(state),
    productFamilies: productFamiliesSelector(state),
    routeIds: _routeIdsSelector(state),
    isLoadingJourneySearch: state.api.loading.journeySearch
  })
}

export default connect(mapStateToProps)(JourneyResultSet)
