import AdditionalProducts from './additional-products'
import JourneySearch from './journey-search'
import ReviewPayment from './review-payment'
import SeatSelection from './seat-selection'
import SeatSelector from './seat-selector'
import VoidTickets from './void-tickets'

export default [
  AdditionalProducts,
  JourneySearch,
  ReviewPayment,
  SeatSelection,
  SeatSelector,
  VoidTickets
]
