import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Modal from '../../../elements/modal/modal'
import TextLabel from '../../../elements/text-label/text-label'
import _t from 's3p-js-lib/src/translate'
import ChangePasswordForm from './form'
import Feedback, { FEEDBACK_ALERT, FEEDBACK_SUCCESS } from '../../../elements/feedback/feedback'
import Button from '../../../elements/button/button'

export default class ShiftModal extends Component {
  static propTypes = {
    serviceError: PropTypes.string,
    success: PropTypes.bool,
    onHideElement: PropTypes.func,
    isLoading: PropTypes.bool,
    form: PropTypes.object,
    onAgentChangePassword: PropTypes.func
  }

  _renderMessage () {
    if (this.props.serviceError) {
      return <Feedback status={FEEDBACK_ALERT} text={this.props.serviceError} />
    } else if (this.props.success) {
      return <Feedback status={FEEDBACK_SUCCESS} text={_t.message('change-password.success')} />
    } else {
      return null
    }
  }

  _renderFooter () {
    return (
      <Modal.CustomFooter>
        <Button
          loading={this.props.isLoading}
          type='submit'
          name='change-password'
          className='primary'
          hotkey='proceed'
          onClick={this.props.onAgentChangePassword}
        >
          <TextLabel text={_t.message('change-password.form.submit')} />
        </Button>
        <Button
          loading={this.props.isLoading}
          name='return'
          className='secondary'
          hotkey='close'
          onClick={this.props.onHideElement}
        >
          <TextLabel text={_t.message('change-password.form.return')} />
        </Button>
      </Modal.CustomFooter>
    )
  }

  render () {
    return (
      <Modal
        onHideElement={this.props.onHideElement}
        name='change-password'
        isCloseable
        header={<Modal.Header title={_t.message('change-password.title')} />}
        footer={this._renderFooter()}
      >
        {this._renderMessage()}
        <ChangePasswordForm
          form={this.props.form}
          isLoading={this.props.isLoading}
        />
      </Modal>
    )
  }
}
