import React from 'react'
import { Route } from 'react-router'
import { authContainer } from '../../../containers/base/auth'
import { AGENT_ROLE_OPERATOR } from '../../../constants'
import VoidTicketsLayout from '../../../layouts/aftersales/update-passengers/void-tickets'
import loadOverviewBooking from '../../../containers/base/load-overview-booking'
import checkAccessBySelector from '../../../containers/base/check-access-by-selector'
import { compose } from 'redux'
import { isUpdatePassengersAvailableSelector } from '../../../redux/selectors/containers/base/aftersales'
import { goToCurrentAftersalesBooking } from '../../../redux/actions/containers/base/aftersales'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'

const container = compose(
  authContainer({roles: [AGENT_ROLE_OPERATOR]}),
  loadOverviewBooking,
  checkAccessBySelector({
    selector: isUpdatePassengersAvailableSelector(originalBookingSelector),
    onNotAllowed: goToCurrentAftersalesBooking
  })
)

export default (
  <Route
    key='aftersales-void-tickets'
    path='/:lang/aftersales/update-passengers/void-tickets'
    component={container(VoidTicketsLayout)}
  />
)
