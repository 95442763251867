import {
  API_OAUTH_LOGOUT,
  API_CHANGE_SEATS_INITIATE_REQUEST,
  API_CHANGE_SEATS_INITIATE_SUCCESS,
  API_CHANGE_SEATS_INITIATE_FAILURE,
  API_CANCELLATION_INITIATE_REQUEST,
  API_CANCELLATION_INITIATE_SUCCESS,
  API_CANCELLATION_INITIATE_FAILURE,
  API_REBOOKING_INITIATE_REQUEST,
  API_REBOOKING_INITIATE_SUCCESS,
  API_REBOOKING_INITIATE_FAILURE,
  API_ADDITIONAL_PRODUCTS_INITIATE_REQUEST,
  API_ADDITIONAL_PRODUCTS_INITIATE_SUCCESS,
  API_ADDITIONAL_PRODUCTS_INITIATE_FAILURE,
  API_UPDATE_PASSENGERS_INITIATE_REQUEST,
  API_UPDATE_PASSENGERS_INITIATE_SUCCESS,
  API_UPDATE_PASSENGERS_INITIATE_FAILURE,
  CLEAR_STATE
} from '../../../actions/types'

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_REBOOKING_INITIATE_SUCCESS:
    case API_CHANGE_SEATS_INITIATE_SUCCESS:
    case API_CANCELLATION_INITIATE_SUCCESS:
    case API_UPDATE_PASSENGERS_INITIATE_SUCCESS:
      return action.response.data.booking
    case API_ADDITIONAL_PRODUCTS_INITIATE_SUCCESS:
      return action.response.data.original_booking
    case API_REBOOKING_INITIATE_REQUEST:
    case API_REBOOKING_INITIATE_FAILURE:
    case API_CHANGE_SEATS_INITIATE_REQUEST:
    case API_CHANGE_SEATS_INITIATE_FAILURE:
    case API_CANCELLATION_INITIATE_REQUEST:
    case API_CANCELLATION_INITIATE_FAILURE:
    case API_UPDATE_PASSENGERS_INITIATE_REQUEST:
    case API_UPDATE_PASSENGERS_INITIATE_FAILURE:
    case API_ADDITIONAL_PRODUCTS_INITIATE_REQUEST:
    case API_ADDITIONAL_PRODUCTS_INITIATE_FAILURE:
    case API_OAUTH_LOGOUT:
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes('api.booking.aftersalesOriginalBooking') ? initialState : state
    default:
      return state
  }
}
