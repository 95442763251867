import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { initiateLocalRefund } from '../../../../../redux/actions/containers/aftersales/booking/refund'
import RefundRecords from '../../../../../components/aftersales/booking/payment-details/refund/refund-records'

class RefundRecordsContainer extends Component {
  static propTypes = {
    nextStep: PropTypes.func.isRequired,
    initiateRefund: PropTypes.func.isRequired
  }

  constructor (...args) {
    super(...args)

    this.handleInitiateRefund = this.handleInitiateRefund.bind(this)
  }

  async handleInitiateRefund () {
    const result = await this.props.initiateRefund()
    if (result) {
      this.props.nextStep()
    }
  }

  render () {
    return <RefundRecords {...this.props} initiateRefund={this.handleInitiateRefund} />
  }
}

const mapStateToProps = state => ({
  isLoading: state.api.v2.loading.addPayments || state.api.v2.loading.updatePayments || state.api.loading.requestRefund
})

const mapDispatchToProps = {
  initiateRefund: initiateLocalRefund
}

export default connect(mapStateToProps, mapDispatchToProps)(RefundRecordsContainer)
