import { createSelector } from 'reselect'

export const currencyRateSelector = currencyCode => createSelector(
  [state => state.api.v2.meta.currencyRates],
  currencyRates => (currencyRates || []).find(currencyRate => currencyRate.code === currencyCode) || null
)

export const currencyRateValueSelector = currencyCode => createSelector(
  [currencyRateSelector(currencyCode)],
  currencyRate => currencyRate ? currencyRate.rate : null
)
