import PropTypes from 'prop-types'
import React, { Component } from 'react'
import HotkeyLabel from '../hotkey-label/hotkey-label'
import classNames from 'classnames'

export default class InputWrapper extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    hotkey: PropTypes.string
  }

  static defaultProps = {
    disabled: false
  }

  render () {
    const fullClassName = classNames(
      'input-wrapper',
      `input-wrapper-${this.props.name}`,
      this.props.className,
      {'state--is-disabled': this.props.disabled}
    )

    return (
      <div className={fullClassName}>
        {this.props.children}
        {this.props.hotkey && <HotkeyLabel hotkey={this.props.hotkey} />}
      </div>
    )
  }
}
