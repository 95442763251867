import { connect } from 'react-redux'
import { hidePaymentModal } from '../../../redux/actions/containers/base/tiles/payment-methods'
import { loadingSelector } from '../../../redux/selectors/containers/base/payment-modal/payment-modal'
import PaymentModal from '../../../components/base/payment-modal/payment-modal'
import { selectedPaymentMethodSelector } from '../../../redux/selectors/containers/base/tiles/payment-methods'

const mapStateToProps = state => ({
  paymentMethodCode: selectedPaymentMethodSelector(state),
  disabled: loadingSelector(state)
})

const mapDispatchToProps = {
  handleHideModal: hidePaymentModal
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal)
