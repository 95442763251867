import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import RefundModal from '../../../../components/aftersales/booking/payment-details/refund-modal'
import {
  payoutRecordsSelector,
  payoutOrderSelector
} from '../../../../redux/selectors/containers/aftersales/booking/refund'
import { overviewBookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { setRefundStep, finalizeRefund } from '../../../../redux/actions/containers/aftersales/booking/refund'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'

class RefundModalContainer extends Component {
  static propTypes = {
    activeStep: PropTypes.string,
    refundOrder: PropTypes.arrayOf(PropTypes.string),
    finalizeRefund: PropTypes.func,
    setRefundStep: PropTypes.func,
    clearState: PropTypes.func
  }

  constructor (...args) {
    super(...args)

    this.handleNextStep = this.handleNextStep.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.props.clearState()
  }

  handleNextStep () {
    const nextStep = !this.props.activeStep
      ? this.props.refundOrder[0]
      : this.props.refundOrder[this.props.refundOrder.indexOf(this.props.activeStep) + 1]

    if (!nextStep) {
      this.props.finalizeRefund()
    } else {
      this.props.setRefundStep(nextStep)
    }
  }

  render () {
    return <RefundModal nextStep={this.handleNextStep} {...this.props} />
  }
}

const _payoutRecordsSelector = payoutRecordsSelector(overviewBookingSelector)
const _payoutOrderSelector = payoutOrderSelector(overviewBookingSelector)

const mapStateToProps = state => ({
  activeStep: state.containers.aftersales.booking.refund.activeStep,
  refundRecords: _payoutRecordsSelector(state),
  refundOrder: _payoutOrderSelector(state)
})

const mapDispatchToProps = {
  setRefundStep,
  finalizeRefund,
  clearState: () => clearState('machine.pinPayment')
}

export default connect(mapStateToProps, mapDispatchToProps)(RefundModalContainer)
