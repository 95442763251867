import {
  MACHINE_OPEN_CASH_DRAWER_REQUEST,
  MACHINE_OPEN_CASH_DRAWER_SUCCESS,
  MACHINE_OPEN_CASH_DRAWER_FAILURE
} from '../../actions/types'

export default (state = {isLoading: false}, action) => {
  switch (action.type) {
    case MACHINE_OPEN_CASH_DRAWER_REQUEST:
      return {isLoading: true}
    case MACHINE_OPEN_CASH_DRAWER_FAILURE:
    case MACHINE_OPEN_CASH_DRAWER_SUCCESS:
      return {isLoading: false}
    default:
      return state
  }
}
