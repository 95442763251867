import {
  API_REBOOKING_CREATE_BOOKING_REQUEST,
  API_REBOOKING_CREATE_BOOKING_SUCCESS,
  API_REBOOKING_CREATE_BOOKING_FAILURE,
  API_AFTERSALES_CONFIRM_REQUEST,
  API_AFTERSALES_CONFIRM_SUCCESS,
  API_AFTERSALES_CONFIRM_FAILURE
} from 's3p-js-lib/src/redux/actions/types'

const initialState = {
  isLoadingCreateBooking: false,
  isLoadingConfirmBooking: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case API_REBOOKING_CREATE_BOOKING_REQUEST:
      return {...state, isLoadingCreateBooking: true}
    case API_REBOOKING_CREATE_BOOKING_SUCCESS:
    case API_REBOOKING_CREATE_BOOKING_FAILURE:
      return {...state, isLoadingCreateBooking: false}
    case API_AFTERSALES_CONFIRM_REQUEST:
      return {...state, isLoadingConfirmBooking: true}
    case API_AFTERSALES_CONFIRM_FAILURE:
    case API_AFTERSALES_CONFIRM_SUCCESS:
      return {...state, isLoadingConfirmBooking: false}
    default:
      return state
  }
}
