import {
  API_FIND_CUSTOMERS_REQUEST,
  API_FIND_CUSTOMERS_SUCCESS,
  API_FIND_CUSTOMERS_FAILURE
} from '../../../types'
import Client from '../../../../../api/client'
import ensureToken from '../../../../../api/ensure-token'

const findCustomersRequest = customerSearchPredicate => ({type: API_FIND_CUSTOMERS_REQUEST, customerSearchPredicate})
const findCustomersSuccess = response => ({type: API_FIND_CUSTOMERS_SUCCESS, response})
const findCustomersFailure = error => ({type: API_FIND_CUSTOMERS_FAILURE, error})

export const findCustomers = customerSearchPredicate => ensureToken(async (token, dispatch) => {
  try {
    dispatch(findCustomersRequest(customerSearchPredicate))
    const response = await Client.findCustomers(token, {...customerSearchPredicate})
    dispatch(findCustomersSuccess(response))
  } catch (error) {
    dispatch(findCustomersFailure(error))

    const handledErrors = [
      'agent_crm_access_required_to_view_customers'
    ]
    if (!handledErrors.includes(error.errorCode)) {
      throw error
    }
  }
})
