import { createSelector } from 'reselect'
import { TRAVEL_DIRECTION_OUTBOUND, TRAVEL_DIRECTION_INBOUND } from 's3p-js-lib/src/constants'

export const outboundSeatSelectionOptionsSelector = seatSelectionOptionsSelector => createSelector(
  [seatSelectionOptionsSelector],
  seatSelectionOptions =>
    seatSelectionOptions && seatSelectionOptions.filter(option => option.direction === TRAVEL_DIRECTION_OUTBOUND)
)

export const inboundSeatSelectionOptionsSelector = seatSelectionOptionsSelector => createSelector(
  [seatSelectionOptionsSelector],
  seatSelectionOptions =>
    seatSelectionOptions && seatSelectionOptions.filter(option => option.direction === TRAVEL_DIRECTION_INBOUND)
)

export const seatSelectionAvailableOptionsSelector = seatSelectionOptionsSelector => createSelector(
  [seatSelectionOptionsSelector],
  seatSelectionOptions =>
    seatSelectionOptions && seatSelectionOptions.filter(option => option.available)
)

export const seatSelectionUnavailableOptionsSelector = seatSelectionOptionsSelector => createSelector(
  [seatSelectionOptionsSelector],
  seatSelectionOptions =>
    seatSelectionOptions && seatSelectionOptions.filter(option => !option.available)
)
