import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import connectFormContainer from '../../base/form'
import { agentChangePassword } from 's3p-js-lib/src/redux/actions/api/user/agent/change-password'
import { validate, reset } from 's3p-js-lib/src/redux/actions/user-input/base/form'
import {
  formSelectorCreator,
  formFieldValuesSelectorCreator
} from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import { showModal } from '../../../redux/actions/containers/base/visible-element'
import { CHANGE_PASSWORD_MODAL, CONTAINERS_USER_AGENT_CHANGE_PASSWORD, SHIFT_MODAL } from '../../../constants'
import { compose } from 'redux'
import { elementVisibleSelector } from '../../../redux/selectors/containers/base/visible-element'
import ChangePasswordModal from '../../../components/agent/change-password/modal'

class ChangePassword extends Component {
  static propTypes = {
    clearState: PropTypes.func.isRequired,
    showChangePasswordModal: PropTypes.bool
  }

  componentWillUnmount () {
    this.props.clearState(CONTAINERS_USER_AGENT_CHANGE_PASSWORD)
  }

  render () {
    return this.props.showChangePasswordModal ? <ChangePasswordModal {...this.props} /> : null
  }
}

const FORM_AGENT_CHANGE_PASSWORD = 'agentChangePassword'
const showChangePasswordModalSelector = elementVisibleSelector(CHANGE_PASSWORD_MODAL)

const mapStateToProps = state => ({
  showChangePasswordModal: showChangePasswordModalSelector(state),
  ...state.containers.agent.changePassword
})

const formSelector = formSelectorCreator(FORM_AGENT_CHANGE_PASSWORD)
const formFieldValuesSelector = formFieldValuesSelectorCreator(FORM_AGENT_CHANGE_PASSWORD)

const mapDispatchToProps = {
  onAgentChangePassword: () => async (dispatch, getState) => {
    const state = getState()
    if (formSelector(state).valid) {
      await dispatch(agentChangePassword(formFieldValuesSelector(state)))
      dispatch(reset(FORM_AGENT_CHANGE_PASSWORD))
    } else {
      dispatch(validate(FORM_AGENT_CHANGE_PASSWORD))
    }
  },
  onHideElement: () => [
    showModal(SHIFT_MODAL),
    reset(FORM_AGENT_CHANGE_PASSWORD),
    clearState(CONTAINERS_USER_AGENT_CHANGE_PASSWORD)
  ],
  clearState
}

const container = compose(
  connect(mapStateToProps, mapDispatchToProps),
  connectFormContainer({formName: FORM_AGENT_CHANGE_PASSWORD})
)
export default container(ChangePassword)

