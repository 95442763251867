import {
  MACHINE_GET_STATUS_DEVICES_REQUEST,
  MACHINE_GET_STATUS_DEVICES_SUCCESS,
  MACHINE_GET_STATUS_DEVICES_FAILURE
} from '../types'
import DeviceManager from '../../../machine/device-manager'

const getStatusDevicesRequest = () => ({type: MACHINE_GET_STATUS_DEVICES_REQUEST})
const getStatusDevicesSuccess = response => ({type: MACHINE_GET_STATUS_DEVICES_SUCCESS, response})
const getStatusDevicesFailure = machineError => ({type: MACHINE_GET_STATUS_DEVICES_FAILURE, machineError})

export const getMachineStatusDevices = () => async dispatch => {
  try {
    dispatch(getStatusDevicesRequest())
    const response = await DeviceManager.getStatusDevices()
    dispatch(getStatusDevicesSuccess(response))
  } catch (error) {
    dispatch(getStatusDevicesFailure(error))
  }
}
