import PropTypes from 'prop-types'
import React, { Component } from 'react'
import SeatSelectorWrapper from '../../../containers/base/seat-selector/seat-selector-wrapper'
import CarriageSelector from '../../../containers/base/seat-selector/carriage-selector'
import CarriageFloorplan from '../../../containers/base/seat-selector/carriage-floorplan'
import PassengerManifest from '../../../containers/base/seat-selector/passenger-manifest'
import SegmentNavigation from '../../../containers/base/seat-selector/segment-navigation'
import '../../base/seat-selector/seat-selector.scss'

export default class SeatSelector extends Component {
  static propTypes = {
    segmentId: PropTypes.string.isRequired
  }

  render () {
    return (
      <SeatSelectorWrapper segmentId={this.props.segmentId}>
        <SegmentNavigation segmentId={this.props.segmentId} />
        <div className='passenger-selector-seat-layout-carriage-selector-container'>
          <PassengerManifest />
          <CarriageFloorplan />
          <CarriageSelector />
        </div>
      </SeatSelectorWrapper>
    )
  }
}
