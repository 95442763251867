import {
  fulfillmentMethodSelector,
  bookingNumberSelector,
  bookingSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import {
  completedOrOverviewBookingSelector,
  provisionalOrOverviewBookingSelector
} from '../../../../selectors/api/booking/booking'
import {FINALIZE_BOOKING_MODAL} from '../../../../../constants'
import {pinDocumentSelector} from 's3p-js-lib/src/redux/selectors/api/booking/ticket-documents'
import {showModal} from '../../../../../redux/actions/containers/base/visible-element'
import {resendTicketsByBookingNumber} from '../../../api/booking/resend-tickets'
import {getBooking} from 's3p-js-lib/src/redux/actions/api/booking/booking'
import {confirmBooking} from 's3p-js-lib/src/redux/actions/api/v2/confirm-booking'
import {clearState} from 's3p-js-lib/src/redux/actions/clear-state'
import {
  UI_START_REPRINT_RESEND,
  UI_END_REPRINT_RESEND
} from '../../../types'
import {isMagstripeFulfillment} from '../../../../../misc/utils'
import {overrideValidationRulesSelector} from '../../../../selectors/api/auth/auth'

const startReprintResend = () => ({type: UI_START_REPRINT_RESEND})
const endReprintResend = () => ({type: UI_END_REPRINT_RESEND})

const _pinDocumentSelector = pinDocumentSelector(completedOrOverviewBookingSelector)
const hasPin = pinDocument => pinDocument && pinDocument.pin

export const reprintResendBooking = () => async (dispatch, getState) => {
  dispatch(startReprintResend())
  const bookingNumber = bookingNumberSelector(provisionalOrOverviewBookingSelector)(getState())

  let responseRetrieveBooking
  if (bookingSelector(getState())) {
    await dispatch(confirmBooking())
  } else {
    await dispatch(getBooking(bookingNumber))
    dispatch(clearState([
      'api.booking.completedBooking',
      'api.booking.additionalDetailsBooking'
    ]))
  }

  const isMagstripe = isMagstripeFulfillment(
    fulfillmentMethodSelector(completedOrOverviewBookingSelector)(getState())
  )

  let responseResendTickets = true
  if (!isMagstripe || !hasPin(_pinDocumentSelector(getState()))) {
    responseResendTickets = await dispatch(resendTicketsByBookingNumber(
      bookingNumber,
      overrideValidationRulesSelector(completedOrOverviewBookingSelector)(getState())
    ))
  }

  if (responseResendTickets) {
    responseRetrieveBooking = await dispatch(getBooking(bookingNumber))
    dispatch(clearState([
      'api.booking.completedBooking',
      'api.booking.additionalDetailsBooking'
    ]))

    if (responseRetrieveBooking && (!isMagstripe || hasPin(_pinDocumentSelector(getState())))) {
      dispatch(showModal(FINALIZE_BOOKING_MODAL))
    }
  }

  dispatch(endReprintResend())
}
