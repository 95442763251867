import React, {Component} from 'react'

export default class SystemErrorLayout extends Component {
  render () {
    return (
      <div style={{margin: 'auto'}}>
        An system error has occurred
      </div>
    )
  }
}
