import AdditionalProducts from './additional-products'
import Booking from './booking'
import Bookings from './bookings'
import Cancellation from './cancellation'
import CancellationVoid from './cancellation-void'
import ChangeFulfillment from './change-fulfillment'
import ChangeSeats from './change-seats'
import ReadTicket from './read-ticket'
import Rebooking from './rebooking/index'
import UpdatePassengers from './update-passengers'
import VoucherToCash from './voucher-to-cash'
import AdditionalProductsReviewPayment from './additional-products/index'
import ChangeSeatsReviewPayment from './change-seats/index'
import UpdatePassengersReviewPayment from './update-passengers/index'
import VoidTickets from './change-fulfillment/index'

export default [
  AdditionalProducts,
  Booking,
  Bookings,
  Cancellation,
  CancellationVoid,
  ChangeFulfillment,
  ChangeSeats,
  ReadTicket,
  ...Rebooking,
  UpdatePassengers,
  VoucherToCash,
  ...AdditionalProductsReviewPayment,
  ...ChangeSeatsReviewPayment,
  ...UpdatePassengersReviewPayment,
  ...VoidTickets
]
