import {
  MACHINE_GET_STATUS_DEVICES_SUCCESS,
  MACHINE_GET_STATUS_DEVICES_FAILURE,
  MACHINE_LEAP_STATUS_SUCCESS,
  MACHINE_LEAP_STATUS_FAILURE
} from '../../actions/types'
import isEmpty from 'lodash/isEmpty'
import { DEVICE_ID_LEAP } from '../../../constants'
const initialState = {
  data: [],
  failure: false
}

const setMachineLeapStatusState = (state, action) => {
  const data = state.data.filter(device => device.id !== DEVICE_ID_LEAP)
  const response = action.response || (action.machineError && action.machineError.response)
  if (!isEmpty(response)) {
    const newData = Array.isArray(response) ? response : [response]
    data.push(...newData)
  }

  return {
    ...state,
    data
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case MACHINE_GET_STATUS_DEVICES_FAILURE:
      return {
        data: [],
        failure: true
      }
    case MACHINE_GET_STATUS_DEVICES_SUCCESS:
      return {
        data: action.response,
        failure: false
      }
    case MACHINE_LEAP_STATUS_SUCCESS:
    case MACHINE_LEAP_STATUS_FAILURE:
      return setMachineLeapStatusState(state, action)
    default:
      return state
  }
}
