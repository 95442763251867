import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import TextLabel from '../text-label/text-label'
import { UI_TABLE_SORT_ASCENDING, UI_TABLE_SORT_DESCENDING } from 's3p-js-lib/src/constants'

export default class TableHeader extends Component {
  static propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    sortable: PropTypes.bool,
    sort: PropTypes.oneOf([UI_TABLE_SORT_ASCENDING, UI_TABLE_SORT_DESCENDING, null])
  }

  render () {
    const className = classNames(
      this.props.className,
      {
        'state--is-sortable': this.props.sortable && !this.props.sort,
        'state--is-sorted-ascending': this.props.sort === UI_TABLE_SORT_ASCENDING,
        'state--is-sorted-descending': this.props.sort === UI_TABLE_SORT_DESCENDING
      })

    return (
      <th scope='col' onClick={this.props.onClick} className={className}>
        {this.props.text ? <TextLabel name='thead' text={this.props.text} /> : this.props.children}
      </th>
    )
  }
}
