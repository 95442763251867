import { API_GET_BOOKINGS_SUCCESS } from 's3p-js-lib/src/redux/actions/types'

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_GET_BOOKINGS_SUCCESS:
      return action.response.length > 0 ? action.response : []
    default:
      return state
  }
}
