import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../../elements/text-label/text-label'
import Form from '../../../../elements/form/form'

const FieldPropType = PropTypes.shape({
  active: PropTypes.bool.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired
})

export default class CustomerFieldSet extends Component {
  static propTypes = {
    form: PropTypes.shape({
      fields: PropTypes.shape({
        firstName: FieldPropType,
        lastName: FieldPropType,
        emailAddress: FieldPropType,
        phoneNumber: FieldPropType
      })
    }),
    isLoading: PropTypes.bool
  }

  render () {
    const {form, isLoading} = this.props

    return (
      <fieldset>
        <TextLabel
          name='mandatory-fields'
          text={_t.message('booking-details.customer-details.required-fields')}
        />
        <Form.InputGroup name='customer-title-first-last-name'>
          <Form.InputWrapper name='customer-title'>
            <Form.Label htmlFor='customer-title' name='customer-title' />
            <Form.TitleSelect
              {...form.fields.title}
              name='customer-title'
              id='customer-title'
              disabled={form.fields.title.disabled || isLoading}
            />
          </Form.InputWrapper>
          <Form.InputWrapper name='customer-first-name'>
            <Form.Label htmlFor='customer-first-name' name='customer-first-name' />
            <Form.TextField
              {...form.fields.firstName}
              name='customer-first-name'
              id='customer-first-name'
              disabled={form.fields.firstName.disabled || isLoading}
            />
          </Form.InputWrapper>
          <Form.InputWrapper name='customer-last-name'>
            <Form.Label htmlFor='customer-last-name' name='customer-last-name' />
            <Form.TextField
              {...form.fields.lastName}
              name='customer-lastName'
              id='customer-last-name'
              disabled={form.fields.lastName.disabled || isLoading}
            />
          </Form.InputWrapper>
        </Form.InputGroup>
        <Form.InputGroup name='customer-email-mobile-phone'>
          <Form.InputWrapper name='customer-email-address'>
            <Form.Label htmlFor='customer-email-address' name='customer-email-address' />
            <Form.EmailField
              {...form.fields.emailAddress}
              name='customer-emailAddress'
              id='customer-email-address'
              disabled={form.fields.emailAddress.disabled || isLoading}
            />
          </Form.InputWrapper>
          {form.fields.phoneNumber ? (
            <Form.InputWrapper name='customer-mobile-phone'>
              <Form.Label htmlFor='customer-mobile-phone' name='customer-mobile-phone' />
              <Form.TextField
                {...form.fields.phoneNumber}
                name='customer-mobile-phone'
                id='customer-mobile-phone'
                disabled={form.fields.phoneNumber.disabled || isLoading}
              />
            </Form.InputWrapper>
          ) : null}
        </Form.InputGroup>
        <Form.InputGroup name='customer-email-confirmation'>
          <TextLabel name='support-label' text={_t.message('booking-details.customer-details.confirmation')} />
        </Form.InputGroup>
      </fieldset>

    )
  }
}
