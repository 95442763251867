import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import {
  STATUS_PASSED,
  STATUS_DISABLED,
  STATUS_SELECTED
} from '../../elements/progress-bar/progress-bar-item'
import ProgressBarItem from '../../containers/base/progress-bar/progress-bar-item'
import '../../elements/progress-bar/progress-bar.scss'

const matchPath = (currentPath, path) => currentPath.indexOf(path) >= 0
const findIndex = (location, progressItems) => progressItems.findIndex(item =>
  matchPath(location, item.path) ||
  (item.additionalPaths && item.additionalPaths.some(path => matchPath(location, path)))
)

const getStatus = (currentIndex, location, items) => {
  const locationIndex = findIndex(location, items)
  if (currentIndex === locationIndex) {
    return STATUS_SELECTED
  } else if (locationIndex > currentIndex) {
    return STATUS_DISABLED
  } else {
    return STATUS_PASSED
  }
}

export default class ProgressBar extends Component {
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    steps: PropTypes.array,
    hasPayments: PropTypes.bool
  }

  render () {
    const items = this.props.steps
    const currentIndex = findIndex(this.props.pathname, items)

    return <div className='progress-bar-container'>
      <div className='progress-bar' role='progressbar' aria-label='Progress bar'>
        <nav>
          <ol>
            {items.map(item => {
              return (
                <ProgressBarItem
                  key={item.path}
                  text={_t.message(item.label)}
                  status={getStatus(currentIndex, item.path, items)}
                  path={item.path}
                />
              )
            })}
          </ol>
        </nav>
      </div>
    </div>
  }
}
