import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import './component-container.scss'

export default class ComponentContainer extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string
  }

  render () {
    const {name, children, className, ...props} = this.props
    return (
      <div {...props}
        className={classNames('component-container', name ? `component-container-${name}` : '', className)}>
        <div className={name}
          role='region'
          aria-labelledby={`${name}-heading`}
          aria-describedby={`${name}-description`}
        >
          {children}
        </div>
      </div>
    )
  }
}
