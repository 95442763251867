import {
  API_V2_UPDATE_PASSENGERS_REQUEST,
  API_V2_UPDATE_PASSENGERS_SUCCESS,
  API_V2_UPDATE_PASSENGERS_FAILURE
} from '../../../types-v2'
import Client from '../../../../../api/client'
import ensureToken from '../../../../../api/ensure-token'
import {UPDATE_BOOKING_HANDLED_ERRORS} from 's3p-js-lib/src/redux/actions/api/v2/booking'

const updatePassengersBookingRequest = (bookingNumber, customer) => ({
  type: API_V2_UPDATE_PASSENGERS_REQUEST,
  bookingNumber,
  customer
})

const updatePassengersBookingSuccess = response => ({
  type: API_V2_UPDATE_PASSENGERS_SUCCESS,
  response
})

const updatePassengersBookingFailure = error => ({
  type: API_V2_UPDATE_PASSENGERS_FAILURE,
  error
})

export const updatePassengers = (bookingNumber, passengers) => ensureToken(async (token, dispatch) => {
  try {
    dispatch(updatePassengersBookingRequest(bookingNumber, passengers))
    const response = await Client.updatePassengers(token, bookingNumber, passengers)
    dispatch(updatePassengersBookingSuccess(response))
  } catch (error) {
    dispatch(updatePassengersBookingFailure(error))
    if (UPDATE_BOOKING_HANDLED_ERRORS.includes(error.errorCode)) {
      return false
    }
    throw error
  }
})
