import { createSelector } from 'reselect'
import get from 'lodash/get'

export const lastApiErrorsSelector = () => createSelector(
  [
    state => state.api.base.lastApiErrors,
    (state, ownProps) => ownProps.namespaces
  ],
  (lastApiErrors, namespaces) => {
    const messages = Array.isArray(namespaces)
      ? namespaces.map(namespace => get(lastApiErrors, namespace))
      : Object.values(lastApiErrors)

    return messages.filter(message => message)
  }
)
