import React, { Component } from 'react'
import TextLabel from '../../../../elements/text-label/text-label'
import _t from 's3p-js-lib/src/translate'
import PropTypes from '../../../../misc/prop-types'
export default class NotesRow extends Component {
  static propTypes = {
    note: PropTypes.shape({
      queueResolution: PropTypes.int,
      bookingQueue: PropTypes.string,
      content: PropTypes.string.isRequired,
      createDateTime: PropTypes.moment.isRequired,
      createdBy: PropTypes.string.isRequired
    }).isRequired
  }

  render () {
    const {queueResolution, bookingQueue, content, createDateTime, createdBy} = this.props.note
    return (
      <tr>
        <td data-th-label='Date'>
          <TextLabel
            name='agent-notes-date'
            text={_t.formatMomentDateTime(createDateTime, 'full')}
          />
        </td>
        <td data-th-label='Agent'>
          <TextLabel name='agent-notes-agent-name' text={createdBy} />
        </td>
        <td data-th-label='Status'>
          {bookingQueue ? <TextLabel name='agent-notes-agent-status' text={_t.message(
            'aftersales.booking.notes.modal.queue.status-format', {resolved: queueResolution ? 1 : 0, bookingQueue}
          )} /> : null}
        </td>
        <td data-th-label='Note'>
          <TextLabel
            name='agent-notes-agent-message'
            text={content} />
        </td>
      </tr>
    )
  }
}
