import {
  API_OAUTH_LOGOUT,
  API_OAUTH_TOKEN_REQUEST,
  API_PUBLIC_ACCESS_SUCCESS,
  API_AGENT_LOGIN_SUCCESS,
  API_BOOKING_LOGIN_SUCCESS,
  CLEAR_STATE
} from '../../../actions/types'

export const initialState = 0

export default function tokenRequestCount (state = initialState, action) {
  switch (action.type) {
    case API_OAUTH_TOKEN_REQUEST:
      return ++state
    case API_PUBLIC_ACCESS_SUCCESS:
    case API_AGENT_LOGIN_SUCCESS:
    case API_BOOKING_LOGIN_SUCCESS:
    case API_OAUTH_LOGOUT:
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes('api.auth.tokenRequestCount') ? initialState : state
    default:
      return state
  }
}
