export * from './types-ui'
export * from './types-user-input'

export const CLEAR_STATE = 'CLEAR_STATE'

export const API_UNHANDLED_ERROR = 'API_UNHANDLED_ERROR'
export const API_IS_OFFLINE = 'API_IS_OFFLINE'
export const API_IS_BACK_ONLINE = 'API_IS_BACK_ONLINE'

export const API_RETRY_ATTEMPT = 'API_RETRY_ATTEMPT'
export const API_RETRY_FINISHED = 'API_RETRY_FINISHED'

export const API_OAUTH_TOKEN_REQUEST = 'API_OAUTH_TOKEN_REQUEST'
export const API_OAUTH_TOKEN_ERROR = 'API_OAUTH_TOKEN_ERROR'
export const API_OAUTH_LOGOUT = 'API_OAUTH_LOGOUT'

export const API_CONFIRMATION_POLL_START = 'API_CONFIRMATION_POLL_START'
export const API_CONFIRMATION_POLL_STOP = 'API_CONFIRMATION_POLL_STOP'
export const API_CONFIRMATION_POLL_TIMEOUT = 'API_CONFIRMATION_POLL_TIMEOUT'

export const API_JOURNEY_SEARCH_SWITCH_DATE = 'API_JOURNEY_SEARCH_SWITCH_DATE'

export const API_OAUTH_REFRESH_TOKEN_REQUEST = 'API_OAUTH_REFRESH_TOKEN_REQUEST'
export const API_OAUTH_REFRESH_TOKEN_SUCCESS = 'API_OAUTH_REFRESH_TOKEN_SUCCESS'
export const API_OAUTH_REFRESH_TOKEN_FAILURE = 'API_OAUTH_REFRESH_TOKEN_FAILURE'

export const API_AGENT_LOGIN_REQUEST = 'API_AGENT_LOGIN_REQUEST'
export const API_AGENT_LOGIN_TOKEN = 'API_AGENT_LOGIN_TOKEN'
export const API_AGENT_LOGIN_SUCCESS = 'API_AGENT_LOGIN_SUCCESS'
export const API_AGENT_LOGIN_FAILURE = 'API_AGENT_LOGIN_FAILURE'

export const API_BOOKING_LOGIN_REQUEST = 'API_BOOKING_LOGIN_REQUEST'
export const API_BOOKING_LOGIN_TOKEN = 'API_BOOKING_LOGIN_TOKEN'
export const API_BOOKING_LOGIN_SUCCESS = 'API_BOOKING_LOGIN_SUCCESS'
export const API_BOOKING_LOGIN_FAILURE = 'API_BOOKING_LOGIN_FAILURE'

export const API_PUBLIC_ACCESS_REQUEST = 'API_PUBLIC_ACCESS_REQUEST'
export const API_PUBLIC_ACCESS_TOKEN = 'API_PUBLIC_ACCESS_TOKEN'
export const API_PUBLIC_ACCESS_SUCCESS = 'API_PUBLIC_ACCESS_SUCCESS'
export const API_PUBLIC_ACCESS_FAILURE = 'API_PUBLIC_ACCESS_FAILURE'

export const API_LOAD_USER_INFORMATION_REQUEST = 'API_LOAD_USER_INFORMATION_REQUEST'
export const API_LOAD_USER_INFORMATION_SUCCESS = 'API_LOAD_USER_INFORMATION_SUCCESS'
export const API_LOAD_USER_INFORMATION_FAILURE = 'API_LOAD_USER_INFORMATION_FAILURE'

export const API_LOAD_AGENT_INFORMATION_REQUEST = 'API_LOAD_AGENT_INFORMATION_REQUEST'
export const API_LOAD_AGENT_INFORMATION_SUCCESS = 'API_LOAD_AGENT_INFORMATION_SUCCESS'
export const API_LOAD_AGENT_INFORMATION_FAILURE = 'API_LOAD_AGENT_INFORMATION_FAILURE'

export const API_AGENT_CHANGE_PASSWORD_REQUEST = 'API_AGENT_CHANGE_PASSWORD_REQUEST'
export const API_AGENT_CHANGE_PASSWORD_SUCCESS = 'API_AGENT_CHANGE_PASSWORD_SUCCESS'
export const API_AGENT_CHANGE_PASSWORD_FAILURE = 'API_AGENT_CHANGE_PASSWORD_FAILURE'

export const API_CMS_LOAD_BLOCKS_REQUEST = 'API_CMS_LOAD_BLOCKS_REQUEST'
export const API_CMS_LOAD_BLOCKS_SUCCESS = 'API_CMS_LOAD_BLOCKS_SUCCESS'
export const API_CMS_LOAD_BLOCKS_FAILURE = 'API_CMS_LOAD_BLOCKS_FAILURE'

export const API_JOURNEY_SEARCH_REQUEST = 'API_JOURNEY_SEARCH_REQUEST'
export const API_JOURNEY_SEARCH_SUCCESS = 'API_JOURNEY_SEARCH_SUCCESS'
export const API_JOURNEY_SEARCH_FAILURE = 'API_JOURNEY_SEARCH_FAILURE'

export const API_REBOOKING_JOURNEY_SEARCH_REQUEST = 'API_REBOOKING_JOURNEY_SEARCH_REQUEST'
export const API_REBOOKING_JOURNEY_SEARCH_SUCCESS = 'API_REBOOKING_JOURNEY_SEARCH_SUCCESS'
export const API_REBOOKING_JOURNEY_SEARCH_FAILURE = 'API_REBOOKING_JOURNEY_SEARCH_FAILURE'

export const API_REBOOKING_CREATE_BOOKING_REQUEST = 'API_REBOOKING_CREATE_BOOKING_REQUEST'
export const API_REBOOKING_CREATE_BOOKING_SUCCESS = 'API_REBOOKING_CREATE_BOOKING_SUCCESS'
export const API_REBOOKING_CREATE_BOOKING_FAILURE = 'API_REBOOKING_CREATE_BOOKING_FAILURE'

export const API_SELECT_BUNDLE_REQUEST = 'API_SELECT_BUNDLE_REQUEST'
export const API_SELECT_BUNDLE_SUCCESS = 'API_SELECT_BUNDLE_SUCCESS'
export const API_SELECT_BUNDLE_FAILURE = 'API_SELECT_BUNDLE_FAILURE'

export const API_GET_STATIONS_REQUEST = 'API_GET_STATIONS_REQUEST'
export const API_GET_STATIONS_SUCCESS = 'API_GET_STATIONS_SUCCESS'
export const API_GET_STATIONS_FAILURE = 'API_GET_STATIONS_FAILURE'

export const API_PROCESS_DEEP_LINK_REQUEST = 'API_PROCESS_DEEP_LINK_REQUEST'
export const API_PROCESS_DEEP_LINK_SUCCESS = 'API_PROCESS_DEEP_LINK_SUCCESS'
export const API_PROCESS_DEEP_LINK_FAILURE = 'API_PROCESS_DEEP_LINK_FAILURE'
export const API_PROCESS_DEEP_LINK_INVALID = 'API_PROCESS_DEEP_LINK_INVALID'

export const API_CREATE_PROVISIONAL_BOOKING_REQUEST = 'API_CREATE_PROVISIONAL_BOOKING_REQUEST'
export const API_CREATE_PROVISIONAL_BOOKING_SUCCESS = 'API_CREATE_PROVISIONAL_BOOKING_SUCCESS'
export const API_CREATE_PROVISIONAL_BOOKING_FAILURE = 'API_CREATE_PROVISIONAL_BOOKING_FAILURE'

export const API_GET_BOOKING_REQUEST = 'API_GET_BOOKING_REQUEST'
export const API_GET_BOOKING_SUCCESS = 'API_GET_BOOKING_SUCCESS'
export const API_GET_BOOKING_FAILURE = 'API_GET_BOOKING_FAILURE'

export const API_GET_BOOKINGS_REQUEST = 'API_GET_BOOKINGS_REQUEST'
export const API_GET_BOOKINGS_SUCCESS = 'API_GET_BOOKINGS_SUCCESS'
export const API_GET_BOOKINGS_FAILURE = 'API_GET_BOOKINGS_FAILURE'

export const API_GET_DEBUG_REQUEST = 'API_GET_DEBUG_REQUEST'
export const API_GET_DEBUG_SUCCESS = 'API_GET_DEBUG_SUCCESS'
export const API_GET_DEBUG_FAILURE = 'API_GET_DEBUG_FAILURE'

export const API_LINK_TO_CUSTOMER_EMAIL_REQUEST = 'API_LINK_TO_CUSTOMER_EMAIL_REQUEST'
export const API_LINK_TO_CUSTOMER_EMAIL_SUCCESS = 'API_LINK_TO_CUSTOMER_EMAIL_SUCCESS'
export const API_LINK_TO_CUSTOMER_EMAIL_FAILURE = 'API_LINK_TO_CUSTOMER_EMAIL_FAILURE'

export const API_UPDATE_BOOKING_REQUEST = 'API_UPDATE_BOOKING_REQUEST'
export const API_UPDATE_BOOKING_SUCCESS = 'API_UPDATE_BOOKING_SUCCESS'
export const API_UPDATE_BOOKING_FAILURE = 'API_UPDATE_BOOKING_FAILURE'

export const API_ADD_VOUCHER_REQUEST = 'API_ADD_VOUCHER_REQUEST'
export const API_ADD_VOUCHER_SUCCESS = 'API_ADD_VOUCHER_SUCCESS'
export const API_ADD_VOUCHER_FAILURE = 'API_ADD_VOUCHER_FAILURE'

export const API_REQUEST_REFUND_REQUEST = 'API_REQUEST_REFUND_REQUEST'
export const API_REQUEST_REFUND_SUCCESS = 'API_REQUEST_REFUND_SUCCESS'
export const API_REQUEST_REFUND_FAILURE = 'API_REQUEST_REFUND_FAILURE'

export const API_REMOVE_VOUCHER_REQUEST = 'API_REMOVE_VOUCHER'
export const API_REMOVE_VOUCHER_SUCCESS = 'API_REMOVE_VOUCHER_SUCCESS'
export const API_REMOVE_VOUCHER_FAILURE = 'API_REMOVE_VOUCHER_FAILURE'

export const API_GET_PAYMENT_METHODS_REQUEST = 'API_GET_PAYMENT_METHODS_REQUEST'
export const API_GET_PAYMENT_METHODS_SUCCESS = 'API_GET_PAYMENT_METHODS_SUCCESS'
export const API_GET_PAYMENT_METHODS_FAILURE = 'API_GET_PAYMENT_METHODS_FAILURE'

export const API_GET_SEAT_SELECTION_OPTIONS_REQUEST = 'API_GET_SEAT_SELECTION_OPTIONS_REQUEST'
export const API_GET_SEAT_SELECTION_OPTIONS_SUCCESS = 'API_GET_SEAT_SELECTION_OPTIONS_SUCCESS'
export const API_GET_SEAT_SELECTION_OPTIONS_FAILURE = 'API_GET_SEAT_SELECTION_OPTIONS_FAILURE'

export const API_GET_CARRIAGE_LAYOUTS_ORIENTATION_REQUEST = 'API_GET_CARRIAGE_LAYOUTS_ORIENTATION_REQUEST'
export const API_GET_CARRIAGE_LAYOUTS_ORIENTATION_SUCCESS = 'API_GET_CARRIAGE_LAYOUTS_ORIENTATION_SUCCESS'
export const API_GET_CARRIAGE_LAYOUTS_ORIENTATION_FAILURE = 'API_GET_CARRIAGE_LAYOUTS_ORIENTATION_FAILURE'

export const API_GET_CARRIAGE_LAYOUTS_AFTERSALES_REQUEST = 'API_GET_CARRIAGE_LAYOUTS_AFTERSALES_REQUEST'
export const API_GET_CARRIAGE_LAYOUTS_AFTERSALES_SUCCESS = 'API_GET_CARRIAGE_LAYOUTS_AFTERSALES_SUCCESS'
export const API_GET_CARRIAGE_LAYOUTS_AFTERSALES_FAILURE = 'API_GET_CARRIAGE_LAYOUTS_AFTERSALES_FAILURE'

export const API_GET_ADDITIONAL_PRODUCTS_REQUEST = 'API_GET_ADDITIONAL_PRODUCTS_REQUEST'
export const API_GET_ADDITIONAL_PRODUCTS_SUCCESS = 'API_GET_ADDITIONAL_PRODUCTS_SUCCESS'
export const API_GET_ADDITIONAL_PRODUCTS_FAILURE = 'API_GET_ADDITIONAL_PRODUCTS_FAILURE'

export const API_UPDATE_SELECT_BUNDLE_REQUEST = 'API_UPDATE_SELECT_BUNDLE_REQUEST'
export const API_UPDATE_SELECT_BUNDLE_SUCCESS = 'API_UPDATE_SELECT_BUNDLE_SUCCESS'
export const API_UPDATE_SELECT_BUNDLE_FAILURE = 'API_UPDATE_SELECT_BUNDLE_FAILURE'

export const API_RESET_SEAT_SELECTION_REQUEST = 'API_RESET_SEAT_SELECTION_REQUEST'
export const API_RESET_SEAT_SELECTION_SUCCESS = 'API_RESET_SEAT_SELECTION_SUCCESS'
export const API_RESET_SEAT_SELECTION_FAILURE = 'API_RESET_SEAT_SELECTION_FAILURE'

export const API_CONTINUE_PROVISIONAL_BOOKING_REQUEST = 'API_CONTINUE_PROVISIONAL_BOOKING_REQUEST'
export const API_CONTINUE_PROVISIONAL_BOOKING_SUCCESS = 'API_CONTINUE_PROVISIONAL_BOOKING_SUCCESS'
export const API_CONTINUE_PROVISIONAL_BOOKING_FAILURE = 'API_CONTINUE_PROVISIONAL_BOOKING_FAILURE'

export const API_CHANGE_SEATS_INITIATE_REQUEST = 'API_CHANGE_SEATS_INITIATE_REQUEST'
export const API_CHANGE_SEATS_INITIATE_SUCCESS = 'API_CHANGE_SEATS_INITIATE_SUCCESS'
export const API_CHANGE_SEATS_INITIATE_FAILURE = 'API_CHANGE_SEATS_INITIATE_FAILURE'

export const API_CHANGE_SEATS_SELECT_SEATS_REQUEST = 'API_CHANGE_SEATS_SELECT_SEATS_REQUEST'
export const API_CHANGE_SEATS_SELECT_SEATS_SUCCESS = 'API_CHANGE_SEATS_SELECT_SEATS_SUCCESS'
export const API_CHANGE_SEATS_SELECT_SEATS_FAILURE = 'API_CHANGE_SEATS_SELECT_SEATS_FAILURE'

export const API_CANCELLATION_INITIATE_REQUEST = 'API_CANCELLATION_INITIATE_REQUEST'
export const API_CANCELLATION_INITIATE_SUCCESS = 'API_CANCELLATION_INITIATE_SUCCESS'
export const API_CANCELLATION_INITIATE_FAILURE = 'API_CANCELLATION_INITIATE_FAILURE'

export const API_CANCELLATION_CANCEL_REQUEST = 'API_CANCELLATION_CANCEL_REQUEST'
export const API_CANCELLATION_CANCEL_SUCCESS = 'API_CANCELLATION_CANCEL_SUCCESS'
export const API_CANCELLATION_CANCEL_FAILURE = 'API_CANCELLATION_CANCEL_FAILURE'

export const API_REBOOKING_INITIATE_REQUEST = 'API_REBOOKING_INITIATE_REQUEST'
export const API_REBOOKING_INITIATE_SUCCESS = 'API_REBOOKING_INITIATE_SUCCESS'
export const API_REBOOKING_INITIATE_FAILURE = 'API_REBOOKING_INITIATE_FAILURE'

export const API_UPDATE_PASSENGERS_INITIATE_REQUEST = 'API_UPDATE_PASSENGERS_INITIATE_REQUEST'
export const API_UPDATE_PASSENGERS_INITIATE_SUCCESS = 'API_UPDATE_PASSENGERS_INITIATE_SUCCESS'
export const API_UPDATE_PASSENGERS_INITIATE_FAILURE = 'API_UPDATE_PASSENGERS_INITIATE_FAILURE'

export const API_UPDATE_PASSENGERS_UPDATE_REQUEST = 'API_UPDATE_PASSENGERS_UPDATE_REQUEST'
export const API_UPDATE_PASSENGERS_UPDATE_SUCCESS = 'API_UPDATE_PASSENGERS_UPDATE_SUCCESS'
export const API_UPDATE_PASSENGERS_UPDATE_FAILURE = 'API_UPDATE_PASSENGERS_UPDATE_FAILURE'

export const API_ADD_BOOKING_NOTE_REQUEST = 'API_ADD_BOOKING_NOTE_REQUEST'
export const API_ADD_BOOKING_NOTE_SUCCESS = 'API_ADD_BOOKING_NOTE_SUCCESS'
export const API_ADD_BOOKING_NOTE_FAILURE = 'API_ADD_BOOKING_NOTE_FAILURE'

export const API_AFTERSALES_CONFIRM_REQUEST = 'API_AFTERSALES_CONFIRM_REQUEST'
export const API_AFTERSALES_CONFIRM_SUCCESS = 'API_AFTERSALES_CONFIRM_SUCCESS'
export const API_AFTERSALES_CONFIRM_FAILURE = 'API_AFTERSALES_CONFIRM_FAILURE'

export const API_AFTERSALES_REVERT_REQUEST = 'API_AFTERSALES_REVERT_REQUEST'
export const API_AFTERSALES_REVERT_SUCCESS = 'API_AFTERSALES_REVERT_SUCCESS'
export const API_AFTERSALES_REVERT_FAILURE = 'API_AFTERSALES_REVERT_FAILURE'

export const API_ADDITIONAL_PRODUCTS_INITIATE_REQUEST = 'API_ADDITIONAL_PRODUCTS_INITIATE_REQUEST'
export const API_ADDITIONAL_PRODUCTS_INITIATE_SUCCESS = 'API_ADDITIONAL_PRODUCTS_INITIATE_SUCCESS'
export const API_ADDITIONAL_PRODUCTS_INITIATE_FAILURE = 'API_ADDITIONAL_PRODUCTS_INITIATE_FAILURE'

export const API_ADDITIONAL_PRODUCTS_UPDATE_REQUEST = 'API_ADDITIONAL_PRODUCTS_UPDATE_REQUEST'
export const API_ADDITIONAL_PRODUCTS_UPDATE_SUCCESS = 'API_ADDITIONAL_PRODUCTS_UPDATE_SUCCESS'
export const API_ADDITIONAL_PRODUCTS_UPDATE_FAILURE = 'API_ADDITIONAL_PRODUCTS_UPDATE_FAILURE'

export const API_RESEND_TICKETS_REQUEST = 'API_RESEND_TICKETS_REQUEST'
export const API_RESEND_TICKETS_SUCCESS = 'API_RESEND_TICKETS_SUCCESS'
export const API_RESEND_TICKETS_FAILURE = 'API_RESEND_TICKETS_FAILURE'

export const API_FIND_CUSTOMERS_REQUEST = 'API_FIND_CUSTOMERS_REQUEST'
export const API_FIND_CUSTOMERS_SUCCESS = 'API_FIND_CUSTOMERS_SUCCESS'
export const API_FIND_CUSTOMERS_FAILURE = 'API_FIND_CUSTOMERS_FAILURE'

export const API_GET_JOURNEY_DETAILS_REQUEST = 'API_GET_JOURNEY_DETAILS_REQUEST'
export const API_GET_JOURNEY_DETAILS_SUCCESS = 'API_GET_JOURNEY_DETAILS_SUCCESS'
export const API_GET_JOURNEY_DETAILS_FAILURE = 'API_GET_JOURNEY_DETAILS_FAILURE'

export const API_AGENT_UPDATE_REQUEST = 'API_AGENT_UPDATE_REQUEST'
export const API_AGENT_UPDATE_SUCCESS = 'API_AGENT_UPDATE_SUCCESS'
export const API_AGENT_UPDATE_FAILURE = 'API_AGENT_UPDATE_FAILURE'

export const API_PING_REQUEST = 'API_PING_REQUEST'
export const API_PING_SUCCESS = 'API_PING_SUCCESS'
export const API_PING_FAILURE = 'API_PING_FAILURE'

export const API_UPDATE_AGENT_REQUEST = 'API_UPDATE_AGENT_REQUEST'
export const API_UPDATE_AGENT_SUCCESS = 'API_UPDATE_AGENT_SUCCESS'
export const API_UPDATE_AGENT_FAILURE = 'API_UPDATE_AGENT_FAILURE'

export const API_GET_DOCUMENT_TEMPLATES_REQUEST = 'API_GET_DOCUMENT_TEMPLATES_REQUEST'
export const API_GET_DOCUMENT_TEMPLATES_SUCCESS = 'API_GET_DOCUMENT_TEMPLATES_SUCCESS'
export const API_GET_DOCUMENT_TEMPLATES_FAILURE = 'API_GET_DOCUMENT_TEMPLATES_FAILURE'

export const API_START_AGENT_SHIFT_REQUEST = 'API_START_AGENT_SHIFT_REQUEST'
export const API_START_AGENT_SHIFT_SUCCESS = 'API_START_AGENT_SHIFT_SUCCESS'
export const API_START_AGENT_SHIFT_FAILURE = 'API_START_AGENT_SHIFT_FAILURE'

export const API_GET_AGENT_SHIFT_REQUEST = 'API_GET_AGENT_SHIFT_REQUEST'
export const API_GET_AGENT_SHIFT_SUCCESS = 'API_GET_AGENT_SHIFT_SUCCESS'
export const API_GET_AGENT_SHIFT_FAILURE = 'API_GET_AGENT_SHIFT_FAILURE'

export const API_ADD_NOTES_AGENT_SHIFT_REQUEST = 'API_ADD_NOTES_AGENT_SHIFT_REQUEST'
export const API_ADD_NOTES_AGENT_SHIFT_SUCCESS = 'API_ADD_NOTES_AGENT_SHIFT_SUCCESS'
export const API_ADD_NOTES_AGENT_SHIFT_FAILURE = 'API_ADD_NOTES_AGENT_SHIFT_FAILURE'

export const API_END_AGENT_SHIFT_REQUEST = 'API_END_AGENT_SHIFT_REQUEST'
export const API_END_AGENT_SHIFT_SUCCESS = 'API_END_AGENT_SHIFT_SUCCESS'
export const API_END_AGENT_SHIFT_FAILURE = 'API_END_AGENT_SHIFT_FAILURE'

export const API_HEAD_AGENT_SHIFT_REQUEST = 'API_HEAD_AGENT_SHIFT_REQUEST'
export const API_HEAD_AGENT_SHIFT_SUCCESS = 'API_HEAD_AGENT_SHIFT_SUCCESS'
export const API_HEAD_AGENT_SHIFT_FAILURE = 'API_HEAD_AGENT_SHIFT_FAILURE'

export const API_GET_VOUCHERS_REQUEST = 'API_GET_VOUCHERS_REQUEST'
export const API_GET_VOUCHERS_SUCCESS = 'API_GET_VOUCHERS_SUCCESS'
export const API_GET_VOUCHERS_FAILURE = 'API_GET_VOUCHERS_FAILURE'

export const API_TICKET_VOID_VALIDATION_REQUEST = 'API_TICKET_VOID_VALIDATION_REQUEST'
export const API_TICKET_VOID_VALIDATION_SUCCESS = 'API_TICKET_VOID_VALIDATION_SUCCESS'
export const API_TICKET_VOID_VALIDATION_FAILURE = 'API_TICKET_VOID_VALIDATION_FAILURE'

export const API_GET_RECEIPT_DOCUMENT_REQUEST = 'API_GET_RECEIPT_DOCUMENT_REQUEST'
export const API_GET_RECEIPT_DOCUMENT_SUCCESS = 'API_GET_RECEIPT_DOCUMENT_SUCCESS'
export const API_GET_RECEIPT_DOCUMENT_FAILURE = 'API_GET_RECEIPT_DOCUMENT_FAILURE'
