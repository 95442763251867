import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Tether from 'tether'
import RenderOutsideApp from './render-outside-app'
import WatchDOM from './watch-dom'

export default class TetherComponent extends Component {
  static propTypes = {
    target: PropTypes.any,
    attachment: PropTypes.string,
    targetAttachment: PropTypes.string,
    constraints: PropTypes.array,
    renderPlaceholder: PropTypes.bool,
    DOMWatcher: PropTypes.func
  }

  constructor (...args) {
    super(...args)
    this.handleUpdate = this.handleUpdate.bind(this)
  }

  componentDidMount () {
    if (this.props.target) {
      this.init(this.props.target)
    }
  }

  componentWillUnmount () {
    if (this.tether) {
      this.tether.destroy()
      this.tether = null
    }
    if (this.watchDOM) {
      this.watchDOM.stop()
      this.watchDOM = null
    }
  }

  init (target) {
    const options = {
      target: target,
      element: this.element.element,
      attachment: this.props.attachment || this.props.renderPlaceholder ? 'center center' : 'bottom center',
      targetAttachment:
                this.props.targetAttachment || this.props.renderPlaceholder ? 'center center' : 'top center',
      targetModifier: 'visible',
      optimizations: {
        gpu: false
      },
      constraints: this.props.constraints || [{
        to: 'window',
        attachment: 'together',
        pin: true
      }]
    }

    this.tether = new Tether(options)
    this.tether.position()

    if (this.props.DOMWatcher) {
      this.watchDOM = new WatchDOM(this.props.DOMWatcher)
      this.watchDOM.observe(this.tether.position)
    }
  }

  handleUpdate () {
    this.tether && this.tether.position()
  }

  render () {
    return (
      <RenderOutsideApp
        containerClass='tether'
        renderPlaceholder={this.props.renderPlaceholder}
        ref={element => { this.element = element }}
        handleUpdate={this.handleUpdate}
      >
        {this.props.children}
      </RenderOutsideApp>
    )
  }
}
