import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../../../elements/text-label/text-label'
import _t from 's3p-js-lib/src/translate'

export default class RelatedVouchersRow extends Component {
  static propTypes = {
    voucher: PropTypes.shape({
      type: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired
    }).isRequired
  }

  render () {
    return (
      <tr>
        <td data-th-label={_t.message('aftersales.booking.related-vouchers.header.type')}>
          <TextLabel name='related-voucher-type' text={this.props.voucher.type} />
        </td>
        <td data-th-label={_t.message('aftersales.booking.related-vouchers.header.code')}>
          <TextLabel name='related-voucher-code' text={this.props.voucher.code} />
        </td>
        <td data-th-label={_t.message('aftersales.booking.related-vouchers.header.amount')}>
          <TextLabel name='related-voucher-amount' text={_t.formatCurrency(this.props.voucher.amount)} />
        </td>
      </tr>
    )
  }
}
