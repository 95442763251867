import PropTypes from 'prop-types'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  showElement,
  showModal,
  hideElement,
  toggleElement,
  clearElements
} from '../../redux/actions/containers/base/visible-element'
import pick from 'lodash/pick'
import memoize from 'lodash/memoize'

const cachedActionCreators = memoize((fn, dispatch) => bindActionCreators(fn, dispatch))

const mapDispatchToProps = (dispatch, ownProps) => ({
  onShowElement: ownProps.onShowElement || cachedActionCreators(showElement, dispatch),
  onShowModal: ownProps.onShowModal || cachedActionCreators(showModal, dispatch),
  onHideElement: ownProps.onHideElement || cachedActionCreators(hideElement, dispatch),
  onToggleElement: ownProps.onToggleElement || cachedActionCreators(toggleElement, dispatch),
  onClearElements: ownProps.onClearElements || cachedActionCreators(clearElements, dispatch)
})

const container = Component => {
  class VisibleElementContainer extends React.Component {
    static propTypes = {
      elementVisibility: PropTypes.object.isRequired,
      onClearElements: PropTypes.func.isRequired
    }

    componentWillUnmount () {
      const elementNames = Object.keys(this.props.elementVisibility)
      if (elementNames.length > 0) {
        this.props.onClearElements(elementNames)
      }
    }

    render () {
      return <Component {...this.props} />
    }
  }

  return VisibleElementContainer
}

export default (...elements) => Component => {
  const mapStateToProps = (state, ownProps) => {
    let previousVisibilityState = {}

    const isElementVisible = name => (typeof previousVisibilityState[name] === 'object'
      ? previousVisibilityState[name].visible
      : previousVisibilityState[name]
    ) || false

    const elementNames = [...elements]
    if (ownProps.name && !elementNames.includes(ownProps.name)) {
      elementNames.push(ownProps.name)
    }

    return state => {
      const newVisibilities = state.containers.base.elementVisibility
      const hasDifferentKeys = elementNames.some(
        element => newVisibilities[element] !== previousVisibilityState[element]
      )

      if (hasDifferentKeys) {
        previousVisibilityState = pick(newVisibilities, elementNames)
      }

      return {
        elementVisibility: previousVisibilityState,
        isElementVisible
      }
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(container(Component))
}
