import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { resetTickets } from '../../redux/actions/containers/tickets/products'
import { returnPayments } from '../../redux/actions/containers/base/payment-modal/return-payment-modal'
import {
  returnPaymentModalVisibleSelector
} from '../../redux/selectors/containers/base/payment-modal/return-payment-modal'
import ReturnPaymentModal from '../base/payment-modal/return-payment-modal'

class ReturnPaymentModalContainer extends Component {
  static propTypes = {
    showReturnPaymentModal: PropTypes.bool
  }

  render () {
    return this.props.showReturnPaymentModal ? <ReturnPaymentModal {...this.props} /> : null
  }
}

const mapDispatchToProps = {
  handleClick: () => async dispatch => {
    await dispatch(returnPayments())
    dispatch(resetTickets())
  }
}

const mapStateToProps = state => ({
  showReturnPaymentModal: returnPaymentModalVisibleSelector(state)
})

export default connect(mapStateToProps, mapDispatchToProps)(ReturnPaymentModalContainer)
