import { createSelector } from 'reselect'
import { originalBookingSelector, bookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import { tariffSegmentsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { selectedPassengerIdSelector } from 's3p-js-lib/src/redux/selectors/user-input/base/seat-selector'
import { passengersSelector as bookingPassengersSelector } from 's3p-js-lib/src/redux/selectors/api/booking/passengers'
import {
  SEAT_SELECTION_NOT_AVAILABLE,
  SEAT_SELECTION_AVAILABLE,
  SEAT_SELECTION_READONLY,
  AFTERSALES_SEATCHANGE
} from 's3p-js-lib/src/constants'
import { getUniqueBookingTariffSegmentIdentifier } from 's3p-js-lib/src/misc/utils'
import { toTimezoneMoment } from 's3p-js-lib/src/misc/date'
import {
  outboundTariffSegmentsSelector,
  inboundTariffSegmentsSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/tariff-segments'
import { travelSelector } from 's3p-js-lib/src/redux/selectors/api/booking/travel'
import isEqual from 'lodash/isEqual'
import { canOverrideAftersalesRulesSelector } from 's3p-js-lib/src/redux/selectors/api/auth/auth'

const getSegmentInformation = tariffSegment => {
  // Multiple journey segments for a single tariff segment makes no sense in the context of seat selection.
  if (tariffSegment.booking_journey_segments.length !== 1) {
    return null
  }

  const seatProducts = tariffSegment.required_products.filter(product => Boolean(product.seat) && !product.cancelled)
  const journeySegment = tariffSegment.booking_journey_segments[0]

  return {
    key: getUniqueBookingTariffSegmentIdentifier(tariffSegment),
    seatProducts,
    leg: {
      id: tariffSegment.id,
      departureStation: {
        name: tariffSegment.departure_station.name || tariffSegment.departure_station._u_i_c_station_code,
        departureTimestamp: toTimezoneMoment(
          journeySegment.departure_date_time,
          journeySegment.departure_station.timezone
        )
      },
      arrivalStation: {
        name: tariffSegment.arrival_station.name || tariffSegment.arrival_station._u_i_c_station_code,
        arrivalTimestamp: toTimezoneMoment(
          journeySegment.arrival_date_time,
          journeySegment.arrival_station.timezone
        )
      }
    },
    cancelled: tariffSegment.required_products.every(({cancelled}) => cancelled),
    direction: tariffSegment.direction
  }
}

const getTariffSegments = tariffSegments => Object.values(tariffSegments.reduce(
  (segments, tariffSegment) => {
    const segment = getSegmentInformation(tariffSegment)
    if (segment && !segment.cancelled) {
      if (!(segment.key in segments)) {
        segments[segment.key] = segment
      } else {
        segments[segment.key].seatProducts = segments[segment.key].seatProducts.concat(segment.seatProducts)
      }
    }

    return segments
  },
  {}
))

export const seatSelectionOptionsSelector = createSelector(
  [
    tariffSegmentsSelector(originalBookingSelector),
    tariffSegmentsSelector(bookingSelector),
    canOverrideAftersalesRulesSelector(bookingSelector)
  ],
  (originalTariffSegments, updatedTariffSegments, canOverrideAftersalesRules) => {
    const originalSegments = getTariffSegments(originalTariffSegments)
    const seatMapper = seatProduct =>
      `${seatProduct.passenger_id}|${seatProduct.seat.carriage}|${seatProduct.seat.number}`

    return getTariffSegments(updatedTariffSegments).map(segment => {
      const selectedSeats = segment.seatProducts.map(product => ({
        passengerId: product.passenger_id,
        carriageNumber: product.seat.carriage,
        seatNumber: product.seat.number
      }))

      let status = SEAT_SELECTION_NOT_AVAILABLE
      if (segment.seatProducts.some(product => product.can_change_seat)) {
        status = SEAT_SELECTION_AVAILABLE
      } else if (selectedSeats.length > 0) {
        status = SEAT_SELECTION_READONLY
      }

      const originalSegment = originalSegments.find(
        originalSegment => originalSegment.key === segment.key
      )
      const originalSeats = originalSegment ? originalSegment.seatProducts.map(seatMapper) : null

      return {
        ...segment,
        available: status === SEAT_SELECTION_AVAILABLE || canOverrideAftersalesRules,
        updated: originalSeats && !isEqual(originalSeats, segment.seatProducts.map(seatMapper)),
        status,
        selectedSeats,
        hasSelectedSeats: selectedSeats.length > 0
      }
    })
  }
)

export const tariffSegmentSelector = createSelector(
  [
    (state, ownProps) => ownProps.segmentId,
    tariffSegmentsSelector(bookingSelector)
  ],
  (segmentId, tariffSegments) => tariffSegments.find(tariffSegment => tariffSegment.id === segmentId)
)

export const passengersSelector = createSelector(
  [
    tariffSegmentSelector,
    bookingPassengersSelector(bookingSelector),
    canOverrideAftersalesRulesSelector(bookingSelector)
  ],
  (tariffSegment, passengers, canOverrideAftersalesRules) => {
    const passengerIds = tariffSegment.required_products.reduce(
      (passengerIds, product) => {
        if (!product.cancelled && (product.can_change_seat || canOverrideAftersalesRules) && !passengerIds.includes(product.passenger_id)) {
          passengerIds.push(product.passenger_id)
        }

        return passengerIds
      },
      []
    )

    return passengerIds.map(passengerId => passengers.find(passenger => passenger.id === passengerId))
  }
)

export const feeSelector = createSelector(
  [
    tariffSegmentSelector,
    selectedPassengerIdSelector
  ],
  (tariffSegment, selectedPassengerId) => {
    const product = selectedPassengerId && tariffSegment && tariffSegment.required_products.find(
      product => !product.cancelled && product.passenger_id === selectedPassengerId
    )

    const aftersalesRule = product && product.after_sales_rules.find(
      aftersalesRule => aftersalesRule.active && aftersalesRule.type === AFTERSALES_SEATCHANGE
    )

    return aftersalesRule ? aftersalesRule.price : 0.0
  }
)

const travelInfoSelector = tariffSegmentsSelector => createSelector(
  [travelSelector(tariffSegmentsSelector)],
  travel => travel
    ? ({
      direction: travel.direction,
      departureTime: travel.departureDate
    }) : null
)

export const outboundTravelInfoSelector = bookingSelector => travelInfoSelector(outboundTariffSegmentsSelector(bookingSelector))
export const inboundTravelInfoSelector = bookingSelector => travelInfoSelector(inboundTariffSegmentsSelector(bookingSelector))
