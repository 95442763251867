import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Modal from '../../../elements/modal/modal'
import TextLabel from '../../../elements/text-label/text-label'

export default class CashFooter extends Component {
  render () {
    return (
      <Modal.FooterSingle {...this.props} hotkey='proceed'>
        <TextLabel name='payment-received' text={_t.message('cash-payment.form.payment-received.label')} />
      </Modal.FooterSingle>
    )
  }
}
