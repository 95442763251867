import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { hasAccessToWaiveFeesSelector } from 's3p-js-lib/src/redux/selectors/api/auth/auth'
import { selectWaiveFees, deselectWaiveFees } from 's3p-js-lib/src/redux/actions/user-input/aftersales/waive-fees'
import WaiveFees from '../../../components/aftersales/waive-fees/waive-fees'

const _hasAccessToWaiveFeesSelector = hasAccessToWaiveFeesSelector(bookingSelector)

class WaiveFeesContainer extends Component {
  static propTypes = {
    hasAccessToWaiveFees: PropTypes.bool.isRequired,
    selected: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    deselectWaiveFees: PropTypes.func.isRequired,
    selectWaiveFees: PropTypes.func.isRequired
  }

  constructor (...args) {
    super(...args)
    this.handleToggle = this.handleToggle.bind(this)
  }

  handleToggle () {
    if (this.props.selected) {
      this.props.deselectWaiveFees()
    } else {
      this.props.selectWaiveFees()
    }
  }

  render () {
    return this.props.hasAccessToWaiveFees
      ? (
        <WaiveFees
          selected={this.props.selected}
          disabled={this.props.disabled}
          onToggleWaiveFees={this.handleToggle}
        />
      ) : null
  }
}

const mapStateToProps = state => ({
  selected: state.userInput.aftersales.waiveFees,
  hasAccessToWaiveFees: _hasAccessToWaiveFeesSelector(state)
})

const mapDispatchToProps = {
  selectWaiveFees,
  deselectWaiveFees
}

export default connect(mapStateToProps, mapDispatchToProps)(WaiveFeesContainer)
