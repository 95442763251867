import React, { Component } from 'react'
import GridLayout from '../../../elements/grid/grid-layout'
import GridColumn from '../../../elements/grid/grid-column'
import MainMenu from '../../../containers/base/main-menu/main-menu'
import Taskbar from '../../../containers/base/taskbar/taskbar'
import GenericLayout from '../../generic-layout'
import ReviewPayment from '../../../containers/base/review-payment/review-payment'
import StickyFooter from '../../../elements/sticky-footer/sticky-footer'
import BookingSummary from '../../../components/aftersales/rebooking/booking-summary'
import FinalizeBooking from '../../../containers/aftersales/rebooking/review-payment/finalize-booking'
import RebookingOperation from '../../../components/aftersales/rebooking/operation'
import BookingProgressBar from '../../../containers/aftersales/rebooking/progress-bar'
import ReturnPaymentModal from '../../../containers/aftersales/review-payment/return-payment-modal'

export default class PaymentLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <MainMenu />
            <BookingProgressBar />
            <RebookingOperation />
            <ReviewPayment />
            <ReturnPaymentModal />
            <StickyFooter>
              <BookingSummary />
              <Taskbar />
            </StickyFooter>
          </GridColumn>
        </GridLayout>
        <FinalizeBooking />
      </GenericLayout>
    )
  }
}
