import {
  MACHINE_READ_BARCODE_REQUEST,
  MACHINE_READ_BARCODE_SUCCESS,
  MACHINE_READ_BARCODE_FAILURE
} from '../types'
import DeviceManager from '../../../machine/device-manager'

const readBarcodeRequest = () => ({type: MACHINE_READ_BARCODE_REQUEST})
const readBarcodeSuccess = response => ({type: MACHINE_READ_BARCODE_SUCCESS, response})
const readBarcodeFailure = machineError => ({type: MACHINE_READ_BARCODE_FAILURE, machineError})

export const readBarcode = timeout => async dispatch => {
  try {
    dispatch(readBarcodeRequest())
    const response = await DeviceManager.readBarcode(timeout)
    dispatch(readBarcodeSuccess(response))
  } catch (error) {
    dispatch(readBarcodeFailure(error))
    return false
  }
}
