import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import Link from '../link/link'
import Icon from '../icon/icon'
import TextLabel from '../text-label/text-label'
import './menu.scss'

class MenuItem extends Component {
  static propTypes = {
    text: PropTypes.string,
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    href: PropTypes.string,
    to: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    hotkey: PropTypes.string
  }

  static defaultProps = {
    disabled: false,
    active: false
  }

  render () {
    const content = this.props.text
      ? (
        <TextLabel name='menu-item'>
          {this.props.icon ? <Icon name={this.props.icon} className='align-left' /> : null}
          <TextLabel.Text text={this.props.text} />
        </TextLabel>
      ) : this.props.children

    const className = classNames(
      {
        'menu__item': true,
        'state--is-available': true,
        'state--is-selected': this.props.active,
        'state--is-disabled': this.props.disabled
      },
      this.props.className
    )

    return (
      <li className={className}>
        {this.props.disabled ? content
          : (
            <Link onClick={this.props.onClick} href={this.props.href} hotkey={this.props.hotkey}>
              {content}
            </Link>
          )}
      </li>
    )
  }
}

class Menu extends Component {
  render () {
    return (
      <div className='menu'>
        <nav>
          <ul>
            {this.props.children}
          </ul>
        </nav>
      </div>
    )
  }
}

Menu.Item = MenuItem

export default Menu
