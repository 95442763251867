import { statusReducer } from '../../../misc/reducers'
import {
  MACHINE_DISPLAY_LINES_REQUEST,
  MACHINE_DISPLAY_LINES_FAILURE,
  MACHINE_CLEAR_DISPLAY_REQUEST,
  MACHINE_CLEAR_DISPLAY_SUCCESS,
  MACHINE_CLEAR_DISPLAY_FAILURE,
  MACHINE_TEST_DISPLAY_REQUEST,
  MACHINE_TEST_DISPLAY_SUCCESS,
  MACHINE_TEST_DISPLAY_FAILURE
} from '../../actions/types'

const requestIdReducer = (state = null, action) => {
  switch (action.type) {
    case MACHINE_DISPLAY_LINES_REQUEST:
      return action.requestId
    case MACHINE_CLEAR_DISPLAY_REQUEST:
    case MACHINE_DISPLAY_LINES_FAILURE:
      return null
    default:
      return state
  }
}

export default {
  requestId: requestIdReducer,
  testStatus: statusReducer(
    MACHINE_TEST_DISPLAY_REQUEST,
    MACHINE_TEST_DISPLAY_SUCCESS,
    MACHINE_TEST_DISPLAY_FAILURE
  ),
  clearStatus: statusReducer(
    MACHINE_CLEAR_DISPLAY_REQUEST,
    MACHINE_CLEAR_DISPLAY_SUCCESS,
    MACHINE_CLEAR_DISPLAY_FAILURE
  )
}
