import get from 'lodash/get'
import displayLD220 from './HP-display-LD220'
import cashDrawerA798 from './HP-cash-drawer-A798'
import newburyND4800 from './Newbury-ND4800'
import receiptPrinterA799 from './HP-printer-A799'
import Leap from './leap'
import worldPayIPP350 from './WorldPay-IPP350'
import BarcodeScanner from './barcode-scanner'
import NADManager from './nad-manager'

export const STATUS_UNKNOWN = 'UNKNOWN'
export const STATUS_OK = 'OK'
export const STATUS_ERROR = 'ERROR'

export default {
  async getStationInformation () {
    const response = await NADManager.getControlStatus()

    return {
      stationCode: get(response, '[0].Device.Details.station_id', null)
    }
  },

  async getTerminalInformation () {
    const response = await NADManager.getControlStatus()

    return {
      terminalId: get(response, '[0].Device.Details.machine_name', null)
    }
  },

  getStatusDevices () {
    return NADManager.getStatusDevices()
  },

  displayLines (lines) {
    return displayLD220.displayLines(lines)
  },

  clearDisplay () {
    return displayLD220.clearDisplay()
  },

  testDisplay () {
    return displayLD220.testDisplay()
  },

  openCashDrawer () {
    return cashDrawerA798.openDrawer()
  },

  printReceipt (data) {
    return receiptPrinterA799.print(data)
  },

  testPrintReceipt () {
    return receiptPrinterA799.testPrint()
  },

  printMagneticTicket (ticket) {
    const {magneticData, template, data} = ticket
    return newburyND4800.printTicket(magneticData, template, data)
  },

  readMagneticTicket () {
    return newburyND4800.readTicket()
  },

  voidMagneticTicket () {
    return newburyND4800.voidTicket()
  },

  ejectMagneticTicket () {
    return newburyND4800.ejectTicket()
  },

  setDarkness (data) {
    return newburyND4800.setDarkness(data)
  },

  updateMagneticTicketTemplate (id, content) {
    return newburyND4800.updateTemplate(id, content)
  },

  getMagneticTicketTemplates () {
    return newburyND4800.listTemplates()
  },

  removeMagneticTicketTemplates (id) {
    return newburyND4800.removeTemplate(id)
  },

  leapStatus () {
    return Leap.status()
  },

  addLeapProduct (timeout, cardIdentifier, product) {
    return Leap.addProduct(timeout, cardIdentifier, product)
  },

  readLeap () {
    return Leap.read()
  },

  topUpLeap (cardIdentifier, value, paymentMethod) {
    return Leap.topUp(cardIdentifier, value, paymentMethod)
  },

  applyLeapCd (name, data) {
    return Leap.applyCd(name, data)
  },

  processLeapActionList (timeout, cardIdentifier, actions) {
    return Leap.processActionList(timeout, cardIdentifier, actions)
  },

  reverseLeap (timeout, cardIdentifier) {
    return Leap.reverse(timeout, cardIdentifier)
  },

  setLeapConfiguration (options) {
    return Leap.setConfiguration(options)
  },

  readBarcode (timeout) {
    return BarcodeScanner.readBarcode(timeout)
  },

  startPinPayment (data) {
    return worldPayIPP350.collect(data)
  },

  completePinPayment (data) {
    return worldPayIPP350.completeCollection(data)
  },

  cancelPinPayment (data) {
    return worldPayIPP350.cancelTransaction(data)
  },

  refundPinPayment (data) {
    return worldPayIPP350.refundPayment(data)
  }
}
