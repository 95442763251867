import {USER_INPUT_CANCELLATION_SELECT_ITEM_IDS, USER_INPUT_CANCELLATION_DESELECT_ITEM_IDS} from '../../types'

// eslint-disable-next-line func-style
export function selectItemIds (itemIds) {
  return {type: USER_INPUT_CANCELLATION_SELECT_ITEM_IDS, itemIds}
}

// eslint-disable-next-line func-style
export function deselectItemIds (itemIds) {
  return {type: USER_INPUT_CANCELLATION_DESELECT_ITEM_IDS, itemIds}
}
