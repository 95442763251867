import baseLoadingReducers from 's3p-js-lib/src/redux/reducers/api/v2/loading'
import loadingReducer from 's3p-js-lib/src/redux/lib/loading-reducer'

import {
  API_V2_USER_LOGOUT_REQUEST,
  API_V2_USER_LOGOUT_SUCCESS,
  API_V2_USER_LOGOUT_FAILURE,
  API_V2_GET_CALENDAR_REQUEST,
  API_V2_GET_CALENDAR_SUCCESS,
  API_V2_GET_CALENDAR_FAILURE
} from '../../../actions/types-v2'

export default {
  ...baseLoadingReducers,
  logoutUser: loadingReducer(
    API_V2_USER_LOGOUT_REQUEST,
    API_V2_USER_LOGOUT_SUCCESS,
    API_V2_USER_LOGOUT_FAILURE
  ),
  getCalendar: loadingReducer(
    API_V2_GET_CALENDAR_REQUEST,
    API_V2_GET_CALENDAR_SUCCESS,
    API_V2_GET_CALENDAR_FAILURE
  )
}
