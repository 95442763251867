import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ComponentContainer from '../../../elements/component-container/component-container'
import Panel from '../../../elements/panel/panel'
import TextLabel from '../../../elements/text-label/text-label'
import AccordionGroup from '../../../containers/base/accordion-group'
import _t from 's3p-js-lib/src/translate'
import TravelBox from './segments/travel-box'
import Icon from '../../../elements/icon/icon'
import CmsBlockContent from '../cms/cms-block-content'
import Feedback, { FEEDBACK_ALERT, FEEDBACK_WARNING } from '../../../elements/feedback/feedback'
import {
  SALES_PRESENTATION_ONCE_PER_SEGMENT,
  PRODUCT_TYPE_BIKE,
  TRAVEL_DIRECTION_OUTBOUND
} from 's3p-js-lib/src/constants'
import { CMS_BLOCK_ORIENTATION_ADDITIONAL_PRODUCTS } from '../../../constants'
import sortBy from 'lodash/sortBy'
import './additional-products.scss'

const PRODUCT_ICON_MAP = {
  'ST': 'train',
  'TO': 'train',
  'SO': 'train',
  'BK': 'bicycle',
  'FB': 'meal',
  'ET': 'car-park',
  'DG': 'pets',
  'DEFAULT': 'add'
}

export default class AdditionalProducts extends Component {
  static propTypes = {
    outboundAdditionalProductLegs: PropTypes.array,
    additionalProducts: PropTypes.array,
    onUpdateProduct: PropTypes.func.isRequired,
    isLoadingAdditionalProducts: PropTypes.bool.isRequired,
    isReturnJourney: PropTypes.bool.isRequired,
    hasValidBookingFulfillmentMethod: PropTypes.bool
  }

  static defaultProps = {
    hasValidBookingFulfillmentMethod: true
  }

  renderAdditionalProduct = (product, index, disabled, onUpdateProduct, isReturnJourney) => {
    const travelPriceSpecifications = sortBy(
      product.travelPriceSpecifications,
      specification => specification.travelDirection === TRAVEL_DIRECTION_OUTBOUND ? 0 : 1
    )

    const salesPresentation = product.salesPresentation || SALES_PRESENTATION_ONCE_PER_SEGMENT
    return (
      <AccordionGroup.Item
        id={product.id}
        key={product.id}
        name={product.id}
        autoFocus={index === 0}
        active={product.selected}
        selected={product.selected}
        icon={<Icon name={PRODUCT_ICON_MAP[product.type] || PRODUCT_ICON_MAP['DEFAULT']} />}
        header={product.name}
        description={product.description}
        price={_t.message(
          product.selected ? 'additional-products.price' : 'additional-products.cheapest-price',
          {price: product.selected ? product.totalPrice : product.lowestPrice}
        )}
      >
        <div className='travel-details'>
          {isReturnJourney && product.type === PRODUCT_TYPE_BIKE && travelPriceSpecifications.length === 1
            ? <Feedback status={FEEDBACK_WARNING} text={_t.message('additional-products.bike-warning')} />
            : null}
          {travelPriceSpecifications.map(travelPriceSpecification =>
            <TravelBox
              key={travelPriceSpecification.travelDirection}
              salesPresentation={salesPresentation}
              disabled={disabled}
              onUpdateProduct={onUpdateProduct}
              isQuantityMaximumAvailable={product.isQuantityMaximumAvailable}
              {...travelPriceSpecification}
            />
          )}
        </div>
      </AccordionGroup.Item>
    )
  }

  render () {
    return (
      <ComponentContainer name='additional-products'>
        <Panel
          header={<Panel.Header
            name='additional-products'
            title={_t.message('additional-products.title')}
            description={_t.message('additional-products.description')}
          />}
        >
          <CmsBlockContent.Named name={CMS_BLOCK_ORIENTATION_ADDITIONAL_PRODUCTS} />
          {!this.props.hasValidBookingFulfillmentMethod
            ? <Feedback text={_t.message('additional-products.no-booking-fulfillment-method')} status={FEEDBACK_ALERT} />
            : null
          }
          {this.props.additionalProducts && this.props.additionalProducts.length
            ? <AccordionGroup name='additional-product' focusOnOpen>
              {this.props.additionalProducts.map((product, index) =>
                this.renderAdditionalProduct(product, index, this.props.isLoadingAdditionalProducts, this.props.onUpdateProduct, this.props.isReturnJourney)
              )}
            </AccordionGroup>
            : <TextLabel
              name='information'
              text={_t.message('additional-products.no-products-found')}
            />
          }
        </Panel>
      </ComponentContainer>
    )
  }
}
