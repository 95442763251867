import {
  API_V2_CONFIRM_BOOKING_REQUEST,
  API_V2_CONFIRM_BOOKING_SUCCESS,
  API_V2_CONFIRM_BOOKING_FAILURE
} from '../../types-v2'
import Client from '../../../../../../src/api/client'
import ensureToken from '../../../../api/ensure-token'
import { bookingSelector, bookingNumberSelector as baseBookingNumberSelector } from '../../../selectors/api/booking/booking'
import {handleTicketFulfillmentApiErrorIfPinPaymentSucceeded} from '../../../../../../src/redux/actions/containers/base/finalize-booking/error'
import {headAgentShift} from 's3p-js-lib/src/redux/actions/api/user/agent/shift'
import {showModal} from '../../../../../../src/redux/actions/containers/base/visible-element'
import {SHIFT_MODAL} from '../../../../../../src/constants'
import {shiftEndedRemotely} from '../../../../../../src/redux/actions/containers/agent/shift/modal'

const bookingNumberSelector = baseBookingNumberSelector(bookingSelector)
const confirmBookingRequest = bookingNumber => ({type: API_V2_CONFIRM_BOOKING_REQUEST, bookingNumber})
const confirmBookingSuccess = response => ({type: API_V2_CONFIRM_BOOKING_SUCCESS, response})
const confirmBookingFailure = (error, bookingNumber) => ({type: API_V2_CONFIRM_BOOKING_FAILURE, error, bookingNumber})

export const confirmBooking = (explicitBookingNumber = null) => ensureToken(
  async (token, dispatch, getState) => {
    const bookingNumber = explicitBookingNumber || bookingNumberSelector(getState())
    try {
      const hasShift = await dispatch(headAgentShift())
      if (!hasShift) {
        dispatch(shiftEndedRemotely())
        dispatch(showModal(SHIFT_MODAL))
        return
      }
      dispatch(confirmBookingRequest(bookingNumber))
      const response = await Client.confirmBooking(token, bookingNumber)
      dispatch(confirmBookingSuccess(response))
    } catch (error) {
      dispatch(confirmBookingFailure(error, bookingNumber))
      dispatch(handleTicketFulfillmentApiErrorIfPinPaymentSucceeded(`Booking confirmation API ${error.errorCode || error.statusCode}`))
      error.name = API_V2_CONFIRM_BOOKING_FAILURE
      throw error
    }
  }
)
