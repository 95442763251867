import Validator from './validator'

export default {
  fields: {
    amount: {
      validators: [
        Validator.isRequired,
        Validator.minValue(0.01)
      ]
    }
  }
}
