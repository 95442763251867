import {
  API_GET_CARRIAGE_LAYOUTS_ORIENTATION_REQUEST,
  API_GET_CARRIAGE_LAYOUTS_ORIENTATION_SUCCESS,
  API_GET_CARRIAGE_LAYOUTS_ORIENTATION_FAILURE,
  API_GET_CARRIAGE_LAYOUTS_AFTERSALES_REQUEST,
  API_GET_CARRIAGE_LAYOUTS_AFTERSALES_SUCCESS,
  API_GET_CARRIAGE_LAYOUTS_AFTERSALES_FAILURE
} from '../../../types'
import Client from '../../../../../api/client'
import ensureToken from '../../../../../api/ensure-token'

const getCarriageLayoutsOrientationRequest = () => ({type: API_GET_CARRIAGE_LAYOUTS_ORIENTATION_REQUEST})
const getCarriageLayoutsOrientationSuccess = response => ({type: API_GET_CARRIAGE_LAYOUTS_ORIENTATION_SUCCESS, response})
const getCarriageLayoutsOrientationFailure = error => ({type: API_GET_CARRIAGE_LAYOUTS_ORIENTATION_FAILURE, error})

const getCarriageLayoutsAftersalesRequest = (serviceName, fromStationUIC, toStationUIC, travelDate) => ({type: API_GET_CARRIAGE_LAYOUTS_AFTERSALES_REQUEST, serviceName, fromStationUIC, toStationUIC, travelDate})
const getCarriageLayoutsAftersalesSuccess = response => ({type: API_GET_CARRIAGE_LAYOUTS_AFTERSALES_SUCCESS, response})
const getCarriageLayoutsAftersalesFailure = error => ({type: API_GET_CARRIAGE_LAYOUTS_AFTERSALES_FAILURE, error})

export const getCarriageLayoutsOrientation = (serviceName, fromStationUIC, toStationUIC, travelDate) => ensureToken(async (token, dispatch) => {
  try {
    dispatch(getCarriageLayoutsOrientationRequest())
    const response = await Client.getCarriageLayoutsOrientation(
      token,
      serviceName,
      fromStationUIC,
      toStationUIC,
      travelDate
    )
    dispatch(getCarriageLayoutsOrientationSuccess(response))
  } catch (error) {
    dispatch(getCarriageLayoutsOrientationFailure(error))
    throw error
  }
})

export const getCarriageLayoutsAftersales = (serviceName, fromStationUIC, toStationUIC, travelDate) => ensureToken(async (token, dispatch) => {
  try {
    dispatch(getCarriageLayoutsAftersalesRequest(serviceName, fromStationUIC, toStationUIC, travelDate))
    const response = await Client.getCarriageLayoutsAftersales(
      token,
      serviceName,
      fromStationUIC,
      toStationUIC,
      travelDate
    )
    dispatch(getCarriageLayoutsAftersalesSuccess(response))
  } catch (error) {
    dispatch(getCarriageLayoutsAftersalesFailure(error))
    throw error
  }
})

