import {
  API_JOURNEY_SEARCH_REQUEST,
  API_JOURNEY_SEARCH_SUCCESS,
  API_JOURNEY_SEARCH_FAILURE
} from '../../types'
import Client from '../../../../api/client'
import ensureToken from '../../../../api/ensure-token'
import {
  nextCursorSelector,
  prevCursorSelector,
  journeySearchSelector
} from '../../../selectors/api/orientation/journey-search'
import { TRAVEL_DIRECTION_OUTBOUND } from '../../../../constants'
import {v2ToV1JourneySearchResponse} from 's3p-js-lib/src/redux/actions/api/v2/journey-search-mapper'

const journeySearchRequest = offer => ({type: API_JOURNEY_SEARCH_REQUEST, offer})
const journeySearchSuccess = (response, stations) => ({type: API_JOURNEY_SEARCH_SUCCESS, response, stations})
const journeySearchFailure = error => ({type: API_JOURNEY_SEARCH_FAILURE, error})

export const executeJourneySearch = offer => ensureToken(async (token, dispatch, getState) => {
  try {
    dispatch(journeySearchRequest(offer))
    const response = await Client.getOffer(token, offer)
    dispatch(journeySearchSuccess(v2ToV1JourneySearchResponse(response, offer), getState().api.orientation.stations))
  } catch (error) {
    dispatch(journeySearchFailure(error))
    throw error
  }
})

export const buildJourneySearchFromState = state => {
  const journeySearch = journeySearchSelector(state)
  return {
    origin: journeySearch.origin_station,
    destination: journeySearch.destination_station,
    outboundDate: journeySearch.departure_date,
    outboundTimeEarliest: journeySearch.departure_time_filter && journeySearch.departure_time_filter.earliest,
    outboundTimeLatest: journeySearch.departure_time_filter && journeySearch.departure_time_filter.latest,
    inboundDate: journeySearch.return_date,
    inboundTimeEarliest: journeySearch.return_time_filter && journeySearch.return_time_filter.earliest,
    inboundTimeLatest: journeySearch.return_time_filter && journeySearch.return_time_filter.latest,
    currency: journeySearch.currency,
    passengers: journeySearch.passengers,
    outboundCursor: journeySearch.outbound_cursor,
    inboundCursor: journeySearch.inbound_cursor,
    productFamilies: journeySearch.product_families
  }
}

export const getUpdatedJourneySearchForPagination = (state, direction, later) => {
  const selector = later ? nextCursorSelector : prevCursorSelector
  const cursor = selector(state, {direction})
  if (!cursor) {
    throw new Error(`Unable to paginate ${later ? 'later' : 'earlier'} for direction ${direction}`)
  }

  const journeySearch = buildJourneySearchFromState(state)
  if (direction === TRAVEL_DIRECTION_OUTBOUND) {
    journeySearch.outboundCursor = cursor
  } else {
    journeySearch.inboundCursor = cursor
  }

  return journeySearch
}

export const paginateJourneySearch = (direction, later) => {
  return (dispatch, getState) => dispatch(
    executeJourneySearch(getUpdatedJourneySearchForPagination(getState(), direction, later))
  )
}

export const performJourneySearch = values => {
  return (dispatch, getState) => dispatch(executeJourneySearch({
    origin: values.origin.UICStationCode,
    destination: values.destination.UICStationCode,
    outboundDate: values.outboundDate,
    outboundTimeEarliest: values.outboundTimeEarliest,
    outboundTimeLatest: values.outboundTimeLatest,
    inboundDate: values.inboundDate,
    inboundTimeEarliest: values.inboundTimeEarliest,
    inboundTimeLatest: values.inboundTimeLatest,
    currency: getState().api.orientation.currency,
    passengers: values.passengers.map(passenger => ({
      type: passenger.type,
      disability_type: passenger.disabilityType,
      discount_cards: (passenger.discountCards || []).map(
        card => typeof card === 'string' ? {code: card} : card
      )
    })),
    productFamilies: values.productFamilies
  }))
}
