import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  startRefundCreditCardPayment,
  confirmCreditCard
} from '../../../../../redux/actions/containers/aftersales/booking/refund'
import RefundCreditCard from '../../../../../components/aftersales/booking/payment-details/refund/refund-credit-card'
import { lastMachineErrorsSelector } from '../../../../../redux/selectors/machine/last-machine-errors'
import { refundResultSelector } from '../../../../../redux/selectors/machine/pin-payment'
import { printPedRefundReceipt } from '../../../../../redux/actions/api/payment/refund'
import { openMachineCashDrawer } from '../../../../../redux/actions/machine/cash-drawer'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'

class RefundCreditCardContainer extends Component {
  static propTypes = {
    nextStep: PropTypes.func.isRequired,
    startRefundCreditCardPayment: PropTypes.func.isRequired,
    confirmCreditCard: PropTypes.func.isRequired,
    hasPinPaymentErrors: PropTypes.bool,
    openMachineCashDrawer: PropTypes.func.isRequired,
    clearState: PropTypes.func
  }

  constructor (...args) {
    super(...args)

    this.handleConfirmCreditCard = this.handleConfirmCreditCard.bind(this)
  }

  componentWillUnmount () {
    this.props.clearState()
  }

  componentDidMount () {
    this.props.startRefundCreditCardPayment()
  }

  async handleConfirmCreditCard () {
    if (!this.props.hasPinPaymentErrors) {
      const result = await this.props.confirmCreditCard()
      if (result) {
        this.props.openMachineCashDrawer()
        this.props.nextStep()
      }
    }
  }

  render () {
    return <RefundCreditCard {...this.props} confirmCreditCard={this.handleConfirmCreditCard} />
  }
}

const _pinPaymentErrors = lastMachineErrorsSelector(['pinPayment'])

const mapStateToProps = state => {
  const refundResult = refundResultSelector(state)
  const refundPinErrors = _pinPaymentErrors(state)
  const refundLoading = state.machine.loading.refundPinPayment
  const hasRefundErrors = !refundResult.refundCompleted || refundPinErrors.length > 0
  const refund = state.containers.aftersales.booking.refund
  const loading = state.api.v2.loading.addPayments || state.api.v2.loading.updatePayments
  return {
    refundLoading,
    refundSuccess: refundResult.refundCompleted,
    disabledNextButton: refundLoading || hasRefundErrors,
    showRetryButton: !refundLoading && hasRefundErrors,
    showRetryPrintButton: refund.refundReceiptPrintError && !loading,
    updatePaymentsLoading: !refund.isAborting && loading
  }
}

const mapDispatchToProps = {
  confirmCreditCard,
  startRefundCreditCardPayment,
  retryRefundCreditCard: startRefundCreditCardPayment,
  retryPrintRefundReceipt: printPedRefundReceipt,
  openMachineCashDrawer,
  clearState: () => clearState('machine.pinPayment')
}

export default connect(mapStateToProps, mapDispatchToProps)(RefundCreditCardContainer)
