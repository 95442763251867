import {
  giftVoucherDocumentSelector,
  completedOrOverviewBookingSelector
} from '../../../../selectors/api/booking/booking'
import {sendMachineReceiptPrint} from '../../../machine/receipt-printer'
import {getGiftVoucherDocument} from '../../../api/booking/ticket-documents'
import {giftVoucherTicketDocumentsSelector} from '../../../../selectors/api/booking/ticket-documents'
import {
  startPrintingTickets,
  stopPrintingTickets,
  errorPrintingTickets,
  retrieveAndPrintCycleStart,
  retrieveAndPrintCycleEnd, printingTicketNumber
} from './print-ticket-modal'
import {PRINT_VOUCHER_DEFAULT_ERROR_MESSAGE} from '../../../../../constants'

export const printGiftVoucher = () => async (dispatch, getState) => {
  await dispatch(retrieveAndPrintCycleStart())
  const giftVoucherBooking = giftVoucherTicketDocumentsSelector(completedOrOverviewBookingSelector)(getState())

  dispatch(startPrintingTickets(giftVoucherBooking.length))
  for (let index = 0; index < giftVoucherBooking.length; index++) {
    if (giftVoucherBooking[index]?.url) {
      await dispatch(getGiftVoucherDocument(giftVoucherBooking[index].url))
      await dispatch(printTickets(index + 1))
    }
  }
  dispatch(stopPrintingTickets())
  await dispatch(retrieveAndPrintCycleEnd())
}

const printTickets = (number = 0) => async (dispatch, getState) => {
  const giftVoucherDocumentText = giftVoucherDocumentSelector(getState())
  if (giftVoucherDocumentText) {
    try {
      const giftVoucherDocuments = JSON.parse(giftVoucherDocumentText)
      if (!giftVoucherDocuments) {
        dispatch(errorPrintingTickets(PRINT_VOUCHER_DEFAULT_ERROR_MESSAGE))
      } else {
        dispatch(printingTicketNumber(number))
        let index
        if (Array.isArray(giftVoucherDocuments)) {
          for (index = 0; index < giftVoucherDocuments.length; index++) {
            const result = await dispatch(sendMachineReceiptPrint(giftVoucherDocuments[index]))
            if (!result) {
              break
            }
          }
        } else {
          await dispatch(sendMachineReceiptPrint(giftVoucherDocuments))
        }
      }
    } catch (error) {
      dispatch(errorPrintingTickets(PRINT_VOUCHER_DEFAULT_ERROR_MESSAGE))
    }
  }
}
