import baseReducers from 's3p-js-lib/src/redux/reducers/user-input'
import forms from './forms'
import aftersales from './aftersales'
import base from './base'

export default {
  ...baseReducers,
  aftersales,
  forms,
  base
}
