import { connect } from 'react-redux'
import {
  completedBookingSelector,
  paymentAmountWithoutVouchersSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { removeVoucher } from 's3p-js-lib/src/redux/actions/api/payment/vouchers'
import {
  provisionalOrCompletedBookingSelector,
  pendingTotalPriceToBePaidSelector
} from '../../../redux/selectors/api/booking/booking'
import { showReturnPaymentModal } from '../../../redux/actions/containers/base/payment-modal/return-payment-modal'
import PaymentOverview from '../../../components/base/review-payment/payment-overview'
import { pendingPaymentsSelector } from '../../../redux/selectors/api/booking/payments'

const _paymentsSelector = pendingPaymentsSelector(provisionalOrCompletedBookingSelector)
const totalPriceSelector = paymentAmountWithoutVouchersSelector(provisionalOrCompletedBookingSelector)
const totalPriceToBePaidSelector = pendingTotalPriceToBePaidSelector(provisionalOrCompletedBookingSelector)

const mapStateToProps = state => ({
  isLoading: Boolean(state.api.loading.removeVoucher),
  totalPrice: totalPriceSelector(state),
  totalPriceToBePaid: totalPriceToBePaidSelector(state),
  payments: _paymentsSelector(state),
  hasCompletedBooking: Boolean(completedBookingSelector(state))
})

const mapDispatchToProps = {
  returnPayments: showReturnPaymentModal,
  removeVoucher
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOverview)
