import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../text-label/text-label'
import {HOTKEY_MAP, HOTKEY_MAP_GLOBAL} from '../../constants'
import './hotkey-label.scss'

export default class HotkeyLabel extends Component {
  static propTypes = {
    hotkey: PropTypes.string.isRequired
  }

  _getHotkeyLabel () {
    const label = HOTKEY_MAP[this.props.hotkey] || HOTKEY_MAP_GLOBAL[this.props.hotkey]
    return label && typeof label !== 'string' ? label.sequence : label
  }

  render () {
    // eslint-disable-next-line no-prototype-builtins
    return HOTKEY_MAP.hasOwnProperty(this.props.hotkey) || HOTKEY_MAP_GLOBAL.hasOwnProperty(this.props.hotkey)
      ? (
        <div className='hotkey-label'>
          <TextLabel text={this._getHotkeyLabel()} name='hotkey-label' />
        </div>
      ) : null
  }
}
