import search from './search'
import loading from './loading'
import meta from './meta'
import user from './user'
import ticketDetails from './ticket-details'

export default {
  search,
  loading,
  meta,
  user,
  ticketDetails
}
