import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../text-label/text-label'
import classNames from 'classnames'

class WizardListItem extends Component {
  static propTypes = {
    active: PropTypes.bool,
    text: PropTypes.string
  }

  render () {
    return (
      <li className={classNames({'state--is-disabled': !this.props.active, 'state--is-selected': this.props.active})}>
        <TextLabel name='step' text={this.props.text} />
      </li>
    )
  }
}

class WizardList extends Component {
  render () {
    return (
      <div className='wizard-list'>
        <ol>
          {this.props.children}
        </ol>
      </div>
    )
  }
}

WizardList.Item = WizardListItem
export default WizardList
