/* globals Image */

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import Loader, { TYPE_COMPONENT } from '../../../elements/loader/loader'
import Seat from '../../../containers/base/seat-selector/carriage-floorplan/seat'
import TextLabel from '../../../elements/text-label/text-label'
import Icon from '../../../elements/icon/icon'
import { SEAT_PROPERTY_TRAVEL_DIRECTION_UNKNOWN } from '../../../constants'
import _t from 's3p-js-lib/src/translate'

export default class CarriageFloorPlan extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    segmentId: PropTypes.string,
    template: PropTypes.string,
    rotate: PropTypes.bool,
    seats: PropTypes.arrayOf(PropTypes.shape({
      seatNumber: PropTypes.string.isRequired
    })),
    aftersales: PropTypes.bool
  }

  static defaultProps = {
    rotate: true
  }

  constructor (...args) {
    super(...args)
    this.state = {}
  }

  componentDidMount () {
    if (this.props.template) {
      this._loadCarriageTemplate(this.props.template)
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.template && nextProps.template !== this.props.template) {
      this._loadCarriageTemplate(nextProps.template)
    }
  }

  _loadCarriageTemplate (template) {
    const image = new Image()
    image.onload = () => {
      this.setState({
        height: image.height,
        width: image.width
      })
    }
    image.src = template
  }

  render () {
    const travelDirectionUnknown = this.props.seats &&
      this.props.seats.some(seat => seat.propertyCodes.indexOf(SEAT_PROPERTY_TRAVEL_DIRECTION_UNKNOWN) !== -1)

    const styleFloorPlan = {
      backgroundImage: this.props.template ? `url("${this.props.template}")` : undefined,
      backgroundRepeat: 'no-repeat',
      height: this.state.height
    }

    let styleContainer
    if (!this.props.rotate && this.state.width && this.state.height) {
      styleContainer = {
        height: this.state.width,
        width: this.state.height
      }
      styleFloorPlan.transform = `rotate(90deg) translateX(-${this.state.height}px)`
    }

    let firstAvailableSeatFound = false
    return (
      <div className='seat-layout-container'>
        <div className='seat-layout'>
          {this.props.loading
            ? <Loader type={TYPE_COMPONENT} />
            : (
              <div className='floorplan' style={styleContainer}>
                {!travelDirectionUnknown
                  ? (
                    <div className='floorplan__travel-direction'>
                      <Icon name='chevron-up' className='medium align-left' />
                      <TextLabel
                        name='travel-direction'
                        text={_t.message('seat-selector.travel-direction')} />
                    </div>
                  ) : null}
                <div className='floorplan__bumper' />
                <div
                  className='floorplan__content'
                  style={styleFloorPlan}
                >
                  {this.props.seats.map(seat => {
                    const seatComponent = <Seat
                      key={seat.seatNumber}
                      seatNumber={seat.seatNumber}
                      aftersales={this.props.aftersales}
                      firstAvailable={!firstAvailableSeatFound && seat.available}
                    />
                    if (seat.available) {
                      firstAvailableSeatFound = true
                    }
                    return seatComponent
                  })}
                </div>
                <div className='floorplan__footer' />
              </div>
            )}

        </div>
      </div>
    )
  }
}
