import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Modal from '../../../elements/modal/modal'
import { PAYMENT_MODAL } from '../../../redux/actions/containers/base/tiles/payment-methods'

export default class PaymentModal extends Component {
  static propTypes = {
    paymentMethodCode: PropTypes.string,
    handleHideModal: PropTypes.func,
    disabled: PropTypes.bool
  }

  render () {
    const {handleHideModal, paymentMethodCode, children, disabled, ...props} = this.props

    return (
      <Modal
        name={PAYMENT_MODAL}
        onHideElement={handleHideModal}
        header={<Modal.Header
          title={_t.message(`payment-methods.${paymentMethodCode}.modal-title`)}
          disabled={disabled}
        />}
        isCloseable={false}
        {...props}
      >
        {children}
      </Modal>
    )
  }
}
