import React, { Component } from 'react'
import GenericLayout from '../generic-layout'
import Bookings from '../../containers/aftersales/bookings/bookings'
import MainMenu from '../../containers/base/main-menu/main-menu'
import Taskbar from '../../containers/base/taskbar/taskbar'
import GridLayout from '../../elements/grid/grid-layout'
import GridColumn from '../../elements/grid/grid-column'
import StickyFooter from '../../elements/sticky-footer/sticky-footer'

export default class BookingsLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <MainMenu />
            <Bookings />
            <StickyFooter>
              <Taskbar />
            </StickyFooter>
          </GridColumn>
        </GridLayout>
      </GenericLayout>
    )
  }
}
