import { createSelector } from 'reselect'
import { indexBy } from 's3p-js-lib/src/misc/utils'
import { productsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/products'
import { fulfillmentMethodSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { availableFulfillmentMethodsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/fulfillment-methods'
import {
  FULFILLMENT_METHOD_CODE_MAGSTRIPE,
  FULFILLMENT_METHOD_CODE_BARCODE,
  FULFILLMENT_METHOD_CODE_LEAP,
  FULFILLMENT_METHOD_CODE_LEAP_TOP_UP,
  DEVICE_ID_LEAP
} from '../../../../../constants'
import { pendingFulfillmentMethodSelector } from '../../../user-input/base/fulfillment-methods'
import { isOfflineSelectorCreator } from '../../../machine/offline'
import { isAnyLeapFulfillment } from '../../../../../misc/utils'

const _availableFulfillmentMethodsSelector = _bookingSelector => createSelector(
  [availableFulfillmentMethodsSelector(_bookingSelector)],
  fulfillmentMethods => fulfillmentMethods ? indexBy(fulfillmentMethods, 'code') : null
)
export const fulfillmentMethodsSelector = _bookingSelector => createSelector(
  [
    _availableFulfillmentMethodsSelector(_bookingSelector),
    fulfillmentMethodSelector(_bookingSelector),
    pendingFulfillmentMethodSelector,
    productsSelector(_bookingSelector),
    isOfflineSelectorCreator(DEVICE_ID_LEAP)
  ],
  (availableFulfillmentMethods, selectedMethod, pendingFulfillmentMethod, products, leapOffline) => {
    if (availableFulfillmentMethods) {
      const oneProduct = products.length === 1
      const createFulfillmentMethod = code => ({
        code,
        name: (availableFulfillmentMethods[code] || {}).name || null,
        available: Boolean(
          availableFulfillmentMethods[code] &&
          !([FULFILLMENT_METHOD_CODE_LEAP, FULFILLMENT_METHOD_CODE_LEAP_TOP_UP].includes(code) && leapOffline) &&
          (oneProduct || ![FULFILLMENT_METHOD_CODE_LEAP, FULFILLMENT_METHOD_CODE_LEAP_TOP_UP].includes(code))
        ),
        selected: (pendingFulfillmentMethod || selectedMethod || {}).code === code,
        loading: pendingFulfillmentMethod && pendingFulfillmentMethod.code === code
      })

      return Object.keys(availableFulfillmentMethods).reduce(
        (codes, code) => {
          if (!codes.includes(code)) {
            codes.push(code)
          }
          return codes
        },
        [
          FULFILLMENT_METHOD_CODE_MAGSTRIPE,
          FULFILLMENT_METHOD_CODE_BARCODE,
          FULFILLMENT_METHOD_CODE_LEAP
        ]
      ).map(createFulfillmentMethod)
    } else {
      return null
    }
  }
)

export const hasLeapFulfillmentSelector = bookingSelector => createSelector(
  [availableFulfillmentMethodsSelector(bookingSelector)],
  fulfillmentMethods => fulfillmentMethods ? fulfillmentMethods.some(isAnyLeapFulfillment) : false
)
