import React, { Component } from 'react'
import PropTypes from '../../misc/prop-types'
import {getPropsForReactWidget} from 's3p-js-lib/src/misc/utils'
import DateTimePickerWidget from 'react-widgets/lib/DateTimePicker'
import ReactDOM from 'react-dom'
import moment from 'moment'
import {toUtcDateMoment} from 's3p-js-lib/src/misc/date'
import momentLocalizer from 'react-widgets/lib/localizers/moment'
import classNames from 'classnames'
import './date-time-picker.scss'
momentLocalizer(moment)

class DateTimePicker extends Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    min: PropTypes.moment,
    max: PropTypes.moment,
    value: PropTypes.moment,
    format: PropTypes.string,
    className: PropTypes.string
  }

  static defaultProps = {
    format: 'DD-MM-YYYY'
  }

  constructor (...args) {
    super(...args)

    this.onChange = this.onChange.bind(this)
    this.onClick = this.onClick.bind(this)
    this.onToggle = this.onToggle.bind(this)
  }

  _updateReadOnly (to) {
    // eslint-disable-next-line react/no-find-dom-node
    const element = ReactDOM.findDOMNode(this._element.refs.inner.refs.valueInput)
    if (element) {
      if (to) {
        element.setAttribute('readonly', to)
      } else if (element.attributes['readonly']) {
        element.removeAttribute('readonly')
      }
      element.setAttribute('aria-readonly', to)
    }
  }

  componentDidMount () {
    const readOnly = typeof this.props.readOnly === 'boolean'
      ? this.props.readOnly
      : false
    this._updateReadOnly(readOnly)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (typeof nextProps.readOnly === 'boolean') {
      this._updateReadOnly(nextProps.readOnly)
    }
  }

  onClick () {
    if (!this.props.active && !this.props.disabled && this.props.onFocus) {
      this.props.onFocus()
    }
  }

  onToggle (open) {
    if (!open) {
      this.props.onBlur()
    }
  }

  onChange (date) {
    this.props.onChange(date ? this._dateToMoment(date) : null)
  }

  _dateToMoment (date) {
    return toUtcDateMoment(date)
  }

  _toDate (date) {
    if (date && typeof date.toDate === 'function') {
      return date.toDate()
    } else {
      return date
    }
  }

  render () {
    const {value, min, max, className, ...props} = this.props
    const newProps = getPropsForReactWidget(props, DateTimePickerWidget, Object.keys(DateTimePicker.propTypes))
    delete newProps.onBlur
    delete newProps.onChange

    const fullClassName = classNames(className, 'input', props.name ? `input-${props.name}` : '')

    return (
      <DateTimePickerWidget
        ref={element => { this._element = element }}
        onChange={this.onChange}
        onClick={this.onClick}
        open={props.active ? 'calendar' : false}
        onToggle={this.onToggle}
        min={this._toDate(min) || undefined}
        max={this._toDate(max) || undefined}
        value={this._toDate(value)}
        className={fullClassName}
        {...newProps}
      />
    )
  }
}

export default DateTimePicker
