import { statusReducer, initialState as initialStateTestStatus } from '../../../misc/reducers'
import {
  MACHINE_TEST_RECEIPT_PRINT_REQUEST,
  MACHINE_TEST_RECEIPT_PRINT_SUCCESS,
  MACHINE_TEST_RECEIPT_PRINT_FAILURE,
  MACHINE_RECEIPT_PRINT_REQUEST,
  MACHINE_RECEIPT_PRINT_SUCCESS,
  MACHINE_RECEIPT_PRINT_FAILURE
} from '../../actions/types'

const initialState = {
  error: null,
  lastStatus: null,
  testStatus: initialStateTestStatus
}

const _statusReducer = statusReducer(
  MACHINE_TEST_RECEIPT_PRINT_REQUEST,
  MACHINE_TEST_RECEIPT_PRINT_SUCCESS,
  MACHINE_TEST_RECEIPT_PRINT_FAILURE
)

export default (state = initialState, action) => {
  switch (action.type) {
    case MACHINE_RECEIPT_PRINT_SUCCESS:
      return {
        ...initialState,
        lastStatus: action.response.status
      }
    case MACHINE_RECEIPT_PRINT_REQUEST:
      return initialState
    case MACHINE_RECEIPT_PRINT_FAILURE:
      return {
        ...state,
        error: action.machineError,
        lastStatus: action.machineError.response && (action.machineError.response.status || initialState.lastStatus)
      }
    case MACHINE_TEST_RECEIPT_PRINT_REQUEST:
    case MACHINE_TEST_RECEIPT_PRINT_SUCCESS:
    case MACHINE_TEST_RECEIPT_PRINT_FAILURE:
      return {
        ...state, testStatus: _statusReducer(state, action)
      }
    default:
      return state
  }
}
