/* globals S3P_SETTINGS: true */
/* globals DEBUG_MODE: true */

import moment from 'moment-timezone'
import Storage from './storage'
import {createIntl, createIntlCache} from '@formatjs/intl'

let currentLocale, intl, currentCurrency, messages, formats

const Translate = {

  defaultLocale: S3P_SETTINGS.i18n.supportedLocale || 'en-IE',
  defaultCurrency: S3P_SETTINGS.s3Passenger.features.defaultCurrency || 'EUR',

  initialize () {
    // eslint-disable-next-line no-console
    DEBUG_MODE && console.info('Translate initialize')
    this.setLocale(Storage.get('locale', this.defaultLocale))
    this.setCurrency(Storage.get('currency', this.defaultCurrency))
    intl = createIntl({
      locale: this.getLocale(),
      defaultLocale: this.getLocale(),
      messages: messages,
      defaultFormats: formats
    }, createIntlCache())
    moment.locale(Translate.getLocale())
  },

  _flattenMessages (nestedMessages, prefix = '') {
    return Object.keys(nestedMessages).reduce((messages, key) => {
      let value = nestedMessages[key]
      let prefixedKey = prefix ? `${prefix}.${key}` : key

      if (typeof value === 'string') {
        messages[prefixedKey] = value
      } else {
        Object.assign(messages, this._flattenMessages(value, prefixedKey))
      }

      return messages
    }, {})
  },

  setLocale (locale) {
    const messagesLoader = require('./translate/translate-loader')
    // eslint-disable-next-line no-console
    DEBUG_MODE && console.info('Translate setLocale', locale)
    if (!messagesLoader) {
      throw new Error('messagesLoader should be set before loading locale')
    }
    currentLocale = locale
    messages = this._flattenMessages(messagesLoader.loadLocaleDataFile(locale, 'messages'))
    formats = messagesLoader.loadLocaleDataFile(locale, 'formats')

    Storage.set('locale', currentLocale)
  },

  setCurrency (currency) {
    currentCurrency = currency
    Storage.set('currency', currentCurrency)
  },

  getRegion (locale) {
    return (locale || currentLocale).split('-')[1]
  },

  getDefaultRegion () {
    return this.getRegion(this.defaultLocale)
  },

  getLocale () {
    return currentLocale
  },

  /**
   * @deprecated use getLocale (since only en-IE is supported)
   * @returns {Translate.props.locale}
   */
  getLocales () {
    return this.getLocale()
  },

  formatNumber (value, style) {
    return intl.formatNumber(
      value,
      formats.number[style] ? formats.number[style] : {style}
    )
  },

  formatCurrency (value, currency) {
    return intl.formatNumber(value, {style: 'currency', currency: currency || currentCurrency})
  },

  getIntlMessage (path) {
    return this.message(path)
  },

  formatMomentTime (dateTime, options) {
    return this._formatMoment(dateTime, 'momentTime', options)
  },

  formatMomentDate (dateTime, options) {
    return this._formatMoment(dateTime, 'momentDate', options)
  },

  formatMomentDateTime (dateTime, options) {
    return this._formatMoment(dateTime, 'momentDateTime', options)
  },

  formatMomentDuration (duration, options) {
    if (!moment.isDuration(duration)) {
      throw new Error('Unable to format a non-moment duration')
    }
    const momentSinceEpoch = moment(duration.asMilliseconds()).tz('UTC')

    return this._formatMoment(momentSinceEpoch, 'momentDuration', options)
  },

  _formatMoment (dateTime, type, options) {
    const momentObject = moment(dateTime)
    if (!momentObject.isValid()) {
      throw new Error('Unable to format an invalid date to time')
    }
    if (!formats[type]) {
      throw new Error(`No translation format type "${type} is available."`)
    }
    return momentObject.format(
      formats[type][options] || formats[type].default || undefined
    )
  },

  message (message, context = null) {
    if (context === null && this.hasMessage(message) && messages[message] === '') {
      return ''
    }
    if (DEBUG_MODE && !this.hasMessage(message)) {
      console.error(`MISSING_TRANSLATION for key: "${message}"`)
    }
    return intl.formatMessage({id: message, defaultMessage: ''}, context)
  },

  hasMessage (message) {
    return messages && messages[message] !== undefined
  }
}

export default Translate
