import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { getPropsForReactWidget } from 's3p-js-lib/src/misc/utils'
import { DropdownList as DropdownListWidget } from 'react-widgets'
import Hotkeys from '../utils/hotkeys'
import './dropdown-list.scss'
import HotkeyLabel from '../hotkey-label/hotkey-label'

const emptyFunction = () => {}

export default class DropdownList extends Component {
  static propTypes = {
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onSelect: PropTypes.func,
    data: PropTypes.array,
    placeholder: PropTypes.string,
    id: PropTypes.string,
    textField: PropTypes.any,
    valueField: PropTypes.any,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    hotkey: PropTypes.string,
    hotkeyDisabled: PropTypes.bool,
    active: PropTypes.bool
  }

  constructor (props, ...args) {
    super(props, ...args)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onSelect = this.onSelect.bind(this)
    this.onClick = this.onClick.bind(this)

    this.state = {
      open: false
    }
  }

  UNSAFE_componentWillMount () {
    if (this.props.hotkey) {
      this._setHotkeyHandler(this.props.hotkey)
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.active !== nextProps.active) {
      this.setState({open: nextProps.active})
    }

    if (this.props.hotkey !== nextProps.hotkey) {
      this._setHotkeyHandler(nextProps.hotkey)
    }
  }

  _setHotkeyHandler (hotkey) {
    this.keyHandlers = {}
    this.keyHandlers[hotkey] = this.onFocus
  }

  onBlur () {
    this.setState({open: false})
  }

  onClick () {
    if (!this.state.open) {
      this.setState({open: true})
    }
  }

  onFocus () {
    this.setState({open: true})
    this.props.onFocus && this.props.onFocus()
    return false
  }

  onSelect () {
    this.setState({open: false})
    this.props.onSelect && this.props.onSelect()
  }

  render () {
    const {textField, valueField, data, value, id, defaultValue, placeholder, ...props} = this.props
    delete props.onClick
    delete props.onBlur

    return (
      <Hotkeys handlers={this.keyHandlers} enabled={!this.props.hotkeyDisabled}>
        <DropdownListWidget
          {...getPropsForReactWidget(props, DropdownListWidget)}
          onFocus={this.onFocus}
          onClick={this.onClick}
          onBlur={this.onBlur}
          open={this.state.open}
          onSelect={this.onSelect}
          onToggle={emptyFunction}
          id={id}
          duration={150}
          textField={textField}
          valueField={valueField}
          data={data || []}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
        />
        {this.props.hotkey ? <HotkeyLabel hotkey={this.props.hotkey} /> : null}
      </Hotkeys>
    )
  }
}
