import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import classNames from 'classnames'
import TextLabel from '../../../../elements/text-label/text-label'
import Button from '../../../../elements/button/button'
import Icon from '../../../../elements/icon/icon'

export default class NextDayItem extends Component {
  static propTypes = {
    handleNextDateSet: PropTypes.func.isRequired,
    hasSelectedDate: PropTypes.bool.isRequired
  }

  render () {
    const buttonClassName = classNames('secondary', {'has-selected-date': this.props.hasSelectedDate})

    return (
      <li className='date-switcher__item next-date-set'>
        <Button name='next-date-set' className={buttonClassName} onClick={this.props.handleNextDateSet} tabIndex='-1'>
          <TextLabel
            name='next-date-set'
            text={_t.message('journey-result-set.date-switcher.next-date-set')}
          />
          <Icon name='chevron-right' />
        </Button>
      </li>
    )
  }
}
