import {
  API_LOAD_USER_INFORMATION_REQUEST,
  API_LOAD_USER_INFORMATION_SUCCESS,
  API_LOAD_USER_INFORMATION_FAILURE
} from '../../types'
import Client from '../../../../api/client'
import ensureToken from '../../../../api/ensure-token'

const loadUserInformationRequest = () => ({type: API_LOAD_USER_INFORMATION_REQUEST})
const loadUserInformationSuccess = response => ({type: API_LOAD_USER_INFORMATION_SUCCESS, response})
const loadUserInformationFailure = error => ({type: API_LOAD_USER_INFORMATION_FAILURE, error})

export const loadUserInformation = () => ensureToken(async (token, dispatch) => {
  try {
    dispatch(loadUserInformationRequest())
    const response = await Client.loadUserInformation(token)
    dispatch(loadUserInformationSuccess(response))
  } catch (error) {
    dispatch(loadUserInformationFailure(error))
    throw error
  }
})
