import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../../../../elements/text-label/text-label'
import _t from 's3p-js-lib/src/translate'
import ProductIcon from './../product-icon'

export default class ProductDetailsRow extends Component {
  static propTypes = {
    description: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    tariffCode: PropTypes.string.isRequired,
    originalPrice: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired
  }

  render () {
    return (
      <tr>
        <td data-th-label={_t.message('aftersales.booking.journey-overview.price-details-tooltip.product')}>
          <TextLabel name='product-type'>
            <ProductIcon productType={this.props.type} className='align-left' />
            <TextLabel.Text text={this.props.description} />
          </TextLabel>
        </td>
        <td data-th-label={_t.message('aftersales.booking.journey-overview.price-details-tooltip.tariff')}>
          <TextLabel name='tariff' text={this.props.tariffCode} />
        </td>
        <td data-th-label={_t.message('aftersales.booking.journey-overview.price-details-tooltip.price')}>
          <TextLabel name='price' text={_t.formatCurrency(this.props.originalPrice, this.props.currency)} />
        </td>
      </tr>
    )
  }
}
