import {
  updateSelectBundleWithAdditionalProducts
} from 's3p-js-lib/src/redux/actions/api/orientation/update-select-bundle'

export const onUpdateProduct = (priceSpecification, uniqueId, quantity) => {
  const itemPassengerId = uniqueId.split('|', 2)
  const itemId = itemPassengerId[0]
  const passengerId = itemPassengerId[1]

  let selectedAdditionalProducts = []
  if (Array.isArray(priceSpecification.itemIds)) {
    selectedAdditionalProducts = priceSpecification.itemIds.reduce((selectedAdditionalProducts, itemId) => {
      selectedAdditionalProducts.push({itemId, passengerId, quantity, legs: priceSpecification.legs})

      return selectedAdditionalProducts
    }, selectedAdditionalProducts)
  } else {
    selectedAdditionalProducts.push({itemId, passengerId, quantity, legs: priceSpecification.legs})
  }

  return updateSelectBundleWithAdditionalProducts(priceSpecification.legs[0], selectedAdditionalProducts)
}
