import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ProgressItem, {STATUS_PASSED, STATUS_AVAILABLE, STATUS_SELECTED}
  from '../../../../elements/progress-bar/progress-bar-item'
import _t from 's3p-js-lib/src/translate'

export default class Segment extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    segmentId: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    hasSelectedSeats: PropTypes.bool.isRequired,
    leg: PropTypes.shape({
      departureStation: PropTypes.shape({
        name: PropTypes.string.isRequired
      }).isRequired,
      arrivalStation: PropTypes.shape({
        name: PropTypes.string.isRequired
      }).isRequired
    })
  }

  constructor (...args) {
    super(...args)
    this.onClick = this.onClick.bind(this)
  }

  onClick () {
    this.props.onClick(this.props.segmentId)
  }

  render () {
    const status = this.props.active
      ? STATUS_SELECTED
      : (this.props.hasSelectedSeats ? STATUS_PASSED : STATUS_AVAILABLE)

    return <ProgressItem
      status={status}
      onClick={this.onClick}
      text={_t.message('seat-selector.segment-navigation.od', {
        departureStation: this.props.leg.departureStation.name,
        arrivalStation: this.props.leg.arrivalStation.name
      })}
    />
  }
}
