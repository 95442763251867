import {
  UI_START_REPRINT_RESEND,
  UI_END_REPRINT_RESEND
} from '../../../../actions/types'
import { CONTAINERS_BOOKING_REPRINT_RESEND } from '../../../../../constants'
import { CLEAR_STATE, API_RESEND_TICKETS_REQUEST, API_RESEND_TICKETS_SUCCESS, API_RESEND_TICKETS_FAILURE } from 's3p-js-lib/src/redux/actions/types'

const initialState = {
  reprintResendFulfillment: false,
  loading: false,
  error: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case UI_START_REPRINT_RESEND:
      return {...state, loading: true}
    case UI_END_REPRINT_RESEND:
      return {...state, loading: false, reprintResendFulfillment: true}
    case API_RESEND_TICKETS_REQUEST:
    case API_RESEND_TICKETS_SUCCESS:
      return {...state, error: null}
    case API_RESEND_TICKETS_FAILURE:
      return {...state, error: {...action.error.response.error}}
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_BOOKING_REPRINT_RESEND) ? initialState : state
    default:
      return state
  }
}
