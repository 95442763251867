import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import { PAYMENT_STATUS_F, PAYMENT_STATUS_P } from 's3p-js-lib/src/constants'
import { showModal, hideElement } from '../visible-element'
import { updatePendingPayments } from '../tiles/payment-methods'
import { RETURN_PAYMENT_MODAL } from '../../../../../constants'
import { removeVoucher } from 's3p-js-lib/src/redux/actions/api/payment/vouchers'
import { sendTransactionCancelled } from '../../tickets/sidebar-cart'
import { paymentsWithoutFailedSelector } from '../../../../selectors/api/booking/payments'

const _paymentsWithoutFailedSelector = paymentsWithoutFailedSelector(bookingSelector)

export const returnPayments = () => async (dispatch, getState) => {
  let resultVouchers = true
  const payments = _paymentsWithoutFailedSelector(getState())
  for (const index in payments) {
    // eslint-disable-next-line no-prototype-builtins
    if (payments.hasOwnProperty(index) && payments[index].paymentStatus === PAYMENT_STATUS_P && payments[index].voucher) {
      resultVouchers = await dispatch(removeVoucher(payments[index].voucher.code))
    }
  }

  const response = await dispatch(updatePendingPayments(PAYMENT_STATUS_F))

  if (response && resultVouchers) {
    dispatch([sendTransactionCancelled(), hideReturnPaymentModal()])
  }
}

export const showReturnPaymentModal = () => showModal(RETURN_PAYMENT_MODAL)
export const hideReturnPaymentModal = () => hideElement(RETURN_PAYMENT_MODAL)
