import React from 'react'
import { Route } from 'react-router'
import RebookingAdditionalProductsLayout from '../../../layouts/aftersales/rebooking/additional-products'
import { isRebookAvailableSelector } from '../../../redux/selectors/containers/base/aftersales'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import container from '../../../containers/aftersales/auth'

export default (
  <Route
    key='aftersales-rebooking-additional-products'
    path='/:lang/aftersales/rebooking/additional-products'
    component={container(
      isRebookAvailableSelector(originalBookingSelector),
      RebookingAdditionalProductsLayout
    )}
  />
)
