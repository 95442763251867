import { createSelector } from 'reselect'
import moment from 'moment'

export const travelSelector = tariffSegmentsSelector => createSelector(
  [tariffSegmentsSelector],
  tariffSegments => {
    const mainTariffSegments = tariffSegments && tariffSegments.filter(
      segment => segment.requiredProducts.some(({cancelled, provisional}) => !cancelled || provisional)
    )

    if (!mainTariffSegments || mainTariffSegments.length === 0) {
      return null
    }

    const firstSegment = mainTariffSegments[0]
    const lastSegment = mainTariffSegments[mainTariffSegments.length - 1]
    const duration = firstSegment.departureDate &&
      lastSegment.arrivalDate &&
      moment.duration(lastSegment.arrivalDate.diff(firstSegment.departureDate))

    return {
      direction: firstSegment.direction,
      departureDate: firstSegment.departureDate,
      arrivalDate: lastSegment.arrivalDate,
      departureStation: firstSegment.departureStation,
      arrivalStation: lastSegment.arrivalStation,
      duration
    }
  }
)
