import { createSelector } from 'reselect'
import { camelCaseKeys } from 's3p-js-lib/src/misc/utils'
import {
  toTimezoneMoment,
  toUtcDateMoment
} from 's3p-js-lib/src/misc/date'
import { BOOKING_ON_HOLD_STATUS_ON_HOLD } from '../../../../constants'
import moment from 'moment'

export const bookingsSelector = createSelector(
  [state => state.api.booking.bookingList],
  bookings => bookings && bookings.map(_booking => {
    const {
      bookingDateTime,
      cancelled,
      provisional,
      onHoldStatus,
      outboundRoute,
      inboundRoute,
      outboundStartValidityDate,
      inboundStartValidityDate,
      ...booking
    } = camelCaseKeys(_booking)

    const _outboundRoute = outboundRoute || inboundRoute
    const _outboundStartValidityDate = outboundStartValidityDate || inboundStartValidityDate
    const isOnHold = Boolean(onHoldStatus && onHoldStatus.toLowerCase() === BOOKING_ON_HOLD_STATUS_ON_HOLD)

    booking.bookingDateTime = toTimezoneMoment(bookingDateTime)
    booking.isExpired = booking.expiryDateTime && moment(booking.expiryDateTime).isBefore()
    booking.isProvisional = provisional
    booking.isCancelled = cancelled
    booking.isConfirmed = !isOnHold && !cancelled && !provisional
    booking.isOnHoldProvisional = !cancelled && isOnHold
    booking.isOnHoldExpired = cancelled && isOnHold
    booking.outboundStartValidityDate = _outboundStartValidityDate ? toUtcDateMoment(_outboundStartValidityDate) : null
    booking.inboundStartValidityDate = outboundStartValidityDate && inboundStartValidityDate
      ? toUtcDateMoment(inboundStartValidityDate)
      : null
    booking.outboundRoute = _outboundRoute
      ? {
        origin: _outboundRoute.origin,
        originUIC: _outboundRoute.origin_uic,
        destination: _outboundRoute.destination,
        destinationUIC: _outboundRoute.destination_uic,
        dateTime: toTimezoneMoment(_outboundRoute.date_time, _outboundRoute.timezone)
      }
      : null
    booking.inboundRoute = outboundRoute && inboundRoute
      ? {dateTime: toTimezoneMoment(inboundRoute.date_time, inboundRoute.timezone)}
      : null

    return booking
  })
)
