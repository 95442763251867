import PropTypes from 'prop-types'
import React, { Component } from 'react'
import without from 'lodash/without'
import intersection from 'lodash/intersection'
import CancelByPassenger from '../../../../components/aftersales/cancellation/cancel-by/cancel-by-passenger'

export default class CancelByPassengerContainer extends Component {
  static propTypes = {
    bookingCancellationData: PropTypes.shape({
      allItemsSelected: PropTypes.bool.isRequired,
      selectedItemIds: PropTypes.arrayOf(PropTypes.string).isRequired
    }).isRequired,
    passengerCancellationData: PropTypes.shape({
      id: PropTypes.string.isRequired,
      someItemsSelected: PropTypes.bool.isRequired,
      itemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
      selectedItemIds: PropTypes.arrayOf(PropTypes.string).isRequired
    }).isRequired,
    outboundCancellationData: PropTypes.shape({
      allItemsSelected: PropTypes.bool.isRequired,
      someItemsSelected: PropTypes.bool.isRequired,
      noItemsSelected: PropTypes.bool.isRequired,
      itemIds: PropTypes.arrayOf(PropTypes.string).isRequired
    }),
    inboundCancellationData: PropTypes.shape({
      allItemsSelected: PropTypes.bool.isRequired,
      someItemsSelected: PropTypes.bool.isRequired,
      noItemsSelected: PropTypes.bool.isRequired,
      itemIds: PropTypes.arrayOf(PropTypes.string).isRequired
    }),
    onSelectItemIds: PropTypes.func.isRequired,
    onDeselectItemIds: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
  }

  constructor (...args) {
    super(...args)
    this.onToggle = this.onToggle.bind(this)
  }

  onToggle () {
    if (this.props.passengerCancellationData.someItemsSelected) {
      this._deselectItems()
    } else {
      this._selectItems()
    }
  }

  _selectItems () {
    const {
      passengerCancellationData,
      outboundCancellationData,
      inboundCancellationData,
      onSelectItemIds
    } = this.props

    if (!outboundCancellationData || !inboundCancellationData ||
      (outboundCancellationData.someItemsSelected && inboundCancellationData.someItemsSelected) ||
      (outboundCancellationData.noItemsSelected && inboundCancellationData.noItemsSelected)
    ) {
      onSelectItemIds(passengerCancellationData.itemIds)
    } else if (outboundCancellationData.someItemsSelected) {
      onSelectItemIds(intersection(passengerCancellationData.itemIds, outboundCancellationData.itemIds))
    } else {
      onSelectItemIds(intersection(passengerCancellationData.itemIds, inboundCancellationData.itemIds))
    }
  }

  _deselectItems () {
    const {
      passengerCancellationData,
      outboundCancellationData,
      inboundCancellationData,
      bookingCancellationData,
      onDeselectItemIds
    } = this.props

    if (!bookingCancellationData.allItemsSelected &&
      outboundCancellationData && inboundCancellationData &&
      (outboundCancellationData.allItemsSelected || outboundCancellationData.noItemsSelected) &&
      (inboundCancellationData.allItemsSelected || inboundCancellationData.noItemsSelected)
    ) {
      onDeselectItemIds(without(bookingCancellationData.selectedItemIds, ...passengerCancellationData.itemIds))
    } else {
      onDeselectItemIds(passengerCancellationData.selectedItemIds)
    }
  }

  render () {
    return <CancelByPassenger
      passengerCancellationData={this.props.passengerCancellationData}
      onToggle={this.onToggle}
      disabled={this.props.disabled}
    />
  }
}
