import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DropdownList from '../dropdown-list/dropdown-list'
import _t from 's3p-js-lib/src/translate'

const getOptions = () => {
  const optionValues = ['MR', 'MS', 'MRS']

  return optionValues.map(optionValue => ({
    text: _t.message(`form.title.options.${optionValue.toLowerCase()}`),
    value: optionValue
  }))
}

export default class TitleSelect extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired
  }

  constructor (...args) {
    super(...args)
    this.handleChange = this.handleChange.bind(this)
    this.options = getOptions()
  }

  handleChange (option) {
    if (option) {
      this.props.onChange(option.value)
    }
  }

  render () {
    return (
      <DropdownList
        {...this.props}
        onChange={this.handleChange}
        valueField='value'
        textField='text'
        data={this.options}
        defaultValue=''
      />
    )
  }
}
