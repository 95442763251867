import {
  API_REBOOKING_INITIATE_REQUEST,
  API_REBOOKING_INITIATE_SUCCESS,
  API_REBOOKING_INITIATE_FAILURE
} from '../../../types'
import Client from '../../../../../api/client'
import ensureToken from '../../../../../api/ensure-token'

const rebookingInitiateRequest = bookingNumber => ({type: API_REBOOKING_INITIATE_REQUEST, bookingNumber})
const rebookingInitiateSuccess = response => ({type: API_REBOOKING_INITIATE_SUCCESS, response})
const rebookingInitiateFailure = error => ({type: API_REBOOKING_INITIATE_FAILURE, error})

export const rebookingInitiate = bookingNumber => ensureToken(async function (token, dispatch) {
  try {
    dispatch(rebookingInitiateRequest(bookingNumber))
    const response = await Client.rebookInitiate(token, bookingNumber)
    dispatch(rebookingInitiateSuccess(response))
  } catch (error) {
    dispatch(rebookingInitiateFailure(error))
    throw error
  }
})
