import React, { Component } from 'react'
import PropTypes from '../../../../misc/prop-types'
import OriginDestinationBlock from '../../../../elements/origin-destination-block/origin-destination-block'
import SelectableItem from './selectable-item'

export default class Segment extends Component {
  static propTypes = {
    onToggleItem: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    confirm: PropTypes.bool.isRequired,
    segment: PropTypes.shape({
      departureStation: PropTypes.shape({
        name: PropTypes.string.isRequired
      }),
      arrivalStation: PropTypes.shape({
        name: PropTypes.string.isRequired
      }),
      departureDate: PropTypes.moment.isRequired,
      arrivalDate: PropTypes.moment.isRequired
    }).isRequired
  }

  render () {
    const {segment, onToggleItem, disabled, confirm} = this.props

    return !confirm || segment.hasProvisionalItems
      ? (
        <OriginDestinationBlock
          originName={segment.departureStation ? segment.departureStation.name : null}
          destinationName={segment.arrivalStation ? segment.arrivalStation.name : null}
          departureMoment={segment.departureDate}
          arrivalMoment={segment.arrivalDate}
        >
          {segment.products.map(item => (
            <SelectableItem
              key={item.itemId}
              item={item}
              required={item.required}
              onToggleItem={onToggleItem}
              selected={item.selected}
              disabled={disabled || item.disabled}
              confirm={confirm}
            />
          ))}
        </OriginDestinationBlock>
      ) : null
  }
}
