import React from 'react'
import './check-radio-wrapper.scss'

export default Component => class CheckRadioWrapper extends React.Component {
  render () {
    const {children, ...props} = this.props
    return (
      <div className='check-radio-wrapper'>
        <Component {...props} />
        {children}
      </div>
    )
  }
}
