import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {getPropsForReactWidget} from 's3p-js-lib/src/misc/utils'
import ComboBoxWidget from 'react-widgets/lib/Combobox'
import {dataText} from 'react-widgets/lib/util/dataHelpers'
import './combo-box.scss'

class ComboBox extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    active: PropTypes.bool,
    data: PropTypes.array,
    placeholder: PropTypes.string,
    id: PropTypes.string,
    textField: PropTypes.any,
    value: PropTypes.any,
    itemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(Component)])
  }

  constructor (...args) {
    super(...args)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSelect = this.onSelect.bind(this)
    this._itemComponent = this._itemComponent.bind(this)

    this.state = {value: ''}
  }

  onFocus () {
    this.setState({value: ''})
    this.props.onFocus && this.props.onFocus()
  }

  onBlur () {
    this.props.onBlur && this.props.onBlur(this.state.value)
    this.setState({value: dataText(this.props.value, this.props.textField)})
  }

  onChange (value) {
    if (typeof value === 'object') {
      this.setState({value: dataText(value, this.props.textField)})
    } else {
      this.setState({value})
    }
  }

  onSelect (value) {
    this.props.onChange && this.props.onChange(value)
  }

  _itemComponent (Component) {
    return props => <Component {...props} searchValue={this.state.value} />
  }

  UNSAFE_componentWillReceiveProps ({value, active, textField}) {
    if (!active) {
      this.setState({value: dataText(value, textField)})
    }
  }

  render () {
    const {itemComponent, textField, data, value, placeholder, id, ...props} = this.props
    delete props.onClick

    return (
      <ComboBoxWidget
        {...getPropsForReactWidget(props, ComboBoxWidget)}
        id={id}
        duration={150}
        placeholder={dataText(value, textField) || placeholder}
        textField={textField}
        itemComponent={itemComponent ? this._itemComponent(itemComponent) : null}
        data={data || []}
        value={this.state.value}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onSelect={this.onSelect}
      />
    )
  }
}

export default ComboBox
