import { createSelector } from 'reselect'
import { emptyCmsBlock } from '../../../reducers/api/base/cms'

export const cmsBocksSelectorCreator = (...blockNames) => createSelector(
  [state => state.api.base.cms.cmsBlocks],
  cmsBlocks => blockNames.reduce(
    (blocks, cmsBlockName) => {
      blocks[cmsBlockName] = (cmsBlocks && cmsBlocks[cmsBlockName]) || emptyCmsBlock
      return blocks
    },
    {}
  )
)

export const cmsBlockSelectorCreator = () => createSelector(
  [
    state => state.api.base.cms.cmsBlocks,
    (state, ownProps) => ownProps.name
  ],
  (cmsBlocks, cmsBlockName) => (cmsBlocks && cmsBlocks[cmsBlockName]) || emptyCmsBlock
)
