import React, {Component} from 'react'
import Select from './select'
import _t from 's3p-js-lib/src/translate'

const getOptions = () => ([
  {
    text: _t.message('form.gender.options.male'),
    value: 'M'
  },
  {
    text: _t.message('form.gender.options.female'),
    value: 'F'
  }
])

class GenderSelect extends Component {
  constructor (...args) {
    super(...args)
    this.options = getOptions()
  }

  render () {
    return <Select {...this.props} data={this.options} />
  }
}

GenderSelect.propTypes = Select.propTypes

export default GenderSelect
