import { createSelector } from 'reselect'
import {
  productsSelector,
  outboundProductsSelector,
  inboundProductsSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/products'
import { feesSelector } from 's3p-js-lib/src/redux/selectors/api/booking/fees'
import { passengersSelector } from 's3p-js-lib/src/redux/selectors/api/booking/passengers'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { additionalDetailsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/additional-details'
import { bookingSelector as aftersalesBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import {
  outboundTariffSegmentsSelector as baseOutboundTariffSegmentsSelector,
  inboundTariffSegmentsSelector as baseInboundTariffSegmentsSelector,
  groupByUniqueIdentifier
} from 's3p-js-lib/src/redux/selectors/api/booking/tariff-segments'
import { selectedItemIdsSelector } from '../../user-input/aftersales/cancellation'
import { canOverrideAftersalesRulesSelector } from '../../api/auth/auth'
import { CURRENCY_EURO } from '../../../../constants'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import { provisionalOrOriginalOrOverviewBookingSelector } from '../../api/booking/booking'
import {
  hasSeatTicketProductsSelector,
  productsWithActiveValidationSelector
} from '../../api/booking/products'
import { currentPathnameSelector } from '../base/routing'

const getCancellationData = (products, selectedItemIds, canOverrideAftersalesRules) => {
  const cancellationData = products.reduce(
    (data, product) => {
      if (product.cancelled && product.provisional) {
        data.itemIds.push(product.itemId)
        data.selectedItemIds.push(product.itemId)
      } else if (!product.cancelled && (product.canBeCancelled || canOverrideAftersalesRules)) {
        data.itemIds.push(product.itemId)
        if (selectedItemIds.includes(product.itemId)) {
          data.selectedItemIds.push(product.itemId)
        }
      }
      return data
    },
    {
      itemIds: [],
      selectedItemIds: []
    }
  )

  if (cancellationData.itemIds.length === 0) {
    return null
  }

  cancellationData.noItemsSelected = cancellationData.selectedItemIds.length === 0
  cancellationData.someItemsSelected = cancellationData.selectedItemIds.length > 0
  cancellationData.allItemsSelected = cancellationData.itemIds.length === cancellationData.selectedItemIds.length

  return cancellationData
}

const cancellationDataSelector = productsSelector => createSelector(
  [productsSelector, selectedItemIdsSelector, canOverrideAftersalesRulesSelector(aftersalesBookingSelector)],
  getCancellationData
)

export const bookingCancellationDataSelector = cancellationDataSelector(productsSelector(aftersalesBookingSelector))

export const outboundCancellationDataSelector =
  cancellationDataSelector(outboundProductsSelector(aftersalesBookingSelector))

export const inboundCancellationDataSelector =
  cancellationDataSelector(inboundProductsSelector(aftersalesBookingSelector))

export const passengersCancellationDataSelector = createSelector(
  [
    productsSelector(aftersalesBookingSelector),
    passengersSelector(aftersalesBookingSelector),
    selectedItemIdsSelector,
    canOverrideAftersalesRulesSelector(aftersalesBookingSelector)
  ],
  (products, passengers, selectedItemIds, canOverrideAftersalesRules) => {
    const cancellableProducts = products.filter(
      product => (!product.cancelled || product.provisional) && (product.canBeCancelled || canOverrideAftersalesRules)
    )

    return passengers ? passengers.reduce(
      (passengersCancellationData, passenger) => {
        const passengerProducts = cancellableProducts.filter(product => product.passenger.id === passenger.id)
        const cancellationData = getCancellationData(passengerProducts, selectedItemIds, canOverrideAftersalesRules)

        if (cancellationData) {
          passengersCancellationData.push({
            ...cancellationData,
            firstName: '',
            lastName: '',
            ...passenger
          })
        }

        return passengersCancellationData
      },
      []
    ) : []
  }
)

export const refundDetailsSelector = createSelector(
  [
    bookingSelector,
    aftersalesBookingSelector,
    productsSelector(aftersalesBookingSelector),
    selectedItemIdsSelector,
    feesSelector(bookingSelector)
  ],
  (provisionalBooking, aftersalesBooking, products, selectedItemIds, fees) => ({
    ticketValue: products.reduce(
      (value, product) => {
        const isSelected = (provisionalBooking && product.provisional && product.cancelled) ||
          (!provisionalBooking && selectedItemIds.includes(product.itemId))

        return isSelected ? value + product.price : value
      },
      0.0
    ),
    fee: fees.reduce(
      (value, fee) => fee.provisional ? value + fee.price : value,
      0.0
    ),
    currency: aftersalesBooking ? aftersalesBooking.currency : CURRENCY_EURO,
    total: -1 * ((provisionalBooking && provisionalBooking.total_price_to_be_paid) || 0.0)
  })
)

const segmentMapper = (segments, selectedItemIds, canOverrideAftersalesRules) => segments.map(segment => {
  const additionalProducts = segment.additionalProducts.map(product => ({
    selected: selectedItemIds.includes(product.itemId),
    disabled: product.cancelled || !(product.canBeCancelled || canOverrideAftersalesRules),
    cancelItemIds: [product.itemId],
    ...product
  }))

  const requiredProducts = segment.requiredProducts.map(product => {
    const passengerAdditionalProducts = additionalProducts.filter(
      ({passenger}) => passenger.id === product.passenger.id
    )
    const selected = selectedItemIds.includes(product.itemId)
    if (selected) {
      passengerAdditionalProducts.forEach(product => {
        product.disabled = true
      })
    }

    return {
      selected,
      disabled: false,
      cancelItemIds: [product.itemId].concat(
        passengerAdditionalProducts.reduce(
          (additionalProducts, {itemId, canBeCancelled}) => {
            if (canBeCancelled || canOverrideAftersalesRules) {
              additionalProducts.push(itemId)
            }
            return additionalProducts
          }, []
        )
      ),
      ...product
    }
  })

  const products = requiredProducts.concat(additionalProducts)

  return {
    id: segment.id,
    departureStation: segment.departureStation,
    arrivalStation: segment.arrivalStation,
    departureDate: segment.departureDate,
    arrivalDate: segment.arrivalDate,
    hasProvisionalItems: products.some(({provisional}) => provisional),
    products: sortBy(products, 'passenger.id')
  }
})

const canBeCancelled = ({canBeCancelled}) => canBeCancelled

const filterSegments = tariffSegments => tariffSegments.reduce((segments, segment) => {
  const newSegment = {...segment}
  const filteredRequiredProducts = segment.requiredProducts ? segment.requiredProducts.filter(canBeCancelled) : []
  const filteredAdditionalProducts = segment.additionalProducts ? segment.additionalProducts.filter(canBeCancelled) : []

  if (filteredRequiredProducts.length > 0 || filteredAdditionalProducts.length > 0) {
    if (filteredRequiredProducts.length > 0) {
      newSegment.requiredProducts = filteredRequiredProducts
    }
    if (filteredAdditionalProducts.length > 0) {
      newSegment.additionalProducts = filteredAdditionalProducts
    }
    segments.push(newSegment)
  }

  return segments
}, [])

const tariffSegmentsSelector = tariffSegmentsSelector => createSelector(
  [
    tariffSegmentsSelector,
    canOverrideAftersalesRulesSelector(aftersalesBookingSelector)
  ],
  (tariffSegments, canOverrideAftersalesRules) => canOverrideAftersalesRules
    ? tariffSegments
    : filterSegments(tariffSegments)
)

export const outboundTariffSegmentsSelector = createSelector(
  [
    groupByUniqueIdentifier(tariffSegmentsSelector(baseOutboundTariffSegmentsSelector(aftersalesBookingSelector))),
    selectedItemIdsSelector,
    canOverrideAftersalesRulesSelector(aftersalesBookingSelector)
  ],
  segmentMapper
)

export const inboundTariffSegmentsSelector = createSelector(
  [
    groupByUniqueIdentifier(tariffSegmentsSelector(baseInboundTariffSegmentsSelector(aftersalesBookingSelector))),
    selectedItemIdsSelector,
    canOverrideAftersalesRulesSelector(aftersalesBookingSelector)
  ],
  segmentMapper
)

export const ticketsSelector = createSelector(
  [
    productsWithActiveValidationSelector(provisionalOrOriginalOrOverviewBookingSelector),
    additionalDetailsSelector(provisionalOrOriginalOrOverviewBookingSelector),
    hasSeatTicketProductsSelector(provisionalOrOriginalOrOverviewBookingSelector),
    state => state.userInput.aftersales.cancellation.selectedItemIds
  ],
  (products, additionalDetails, hasSeatTicketProducts, itemsToBeCancelled) => {
    let productsWithCounter = products.reduce((counters, product) => {
      if (!itemsToBeCancelled.length || itemsToBeCancelled.includes(product.itemId)) {
        const detail = additionalDetails.find(detail => detail.itemRefId === product.itemRef)
        if (detail) {
          counters.push({...product, counter: detail.value})
        }
      }
      return counters
    }, [])

    if (!productsWithCounter.length && products.length) {
      if (hasSeatTicketProducts) {
        productsWithCounter = products.map(product => ({...product, counter: product.passenger.id}))
      } else {
        productsWithCounter = products.map(product => ({...product, counter: product.itemId}))
      }
    }

    return groupBy(productsWithCounter, 'counter')
  }
)

export const isCancellationVoidPageSelector = state => currentPathnameSelector(state).indexOf('/cancellation/void') >= 0
