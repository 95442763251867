import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Loader, { TYPE_BLOCKING } from '../../../elements/loader/loader'
import ComponentContainer from '../../../elements/component-container/component-container'
import FulfillmentMethods from '../../../containers/aftersales/change-fulfillment/fulfillment-methods'
import FinalizeBooking from '../../../containers/aftersales/change-fulfillment/finalize-booking'
import './change-fulfillment.scss'

export default class ChangeFulfillment extends Component {
  static propTypes = {
    hasOverviewBooking: PropTypes.bool.isRequired,
    currentFulfillmentMethod: PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  }

  render () {
    return this.props.hasOverviewBooking
      ? (
        <ComponentContainer name='change-fulfillment'>
          <FulfillmentMethods
            loading={false}
            loadingFulfillmentMethod={false}
          />
          <FinalizeBooking aftersales />
        </ComponentContainer>
      ) : <Loader type={TYPE_BLOCKING} />
  }
}
