import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _t from 's3p-js-lib/src/translate'
import { getMachineTerminalInformation } from '../../../redux/actions/machine/terminal-information'
import { hasDeviceMessages } from '../../../redux/selectors/containers/base/diagnostics'
import Taskbar from '../../../components/base/taskbar/taskbar'
import { TERMS_AND_CONDITIONS_MODAL } from '../../../components/base/terms-and-conditions/terms-and-conditions'
import { showModal } from '../../../redux/actions/containers/base/visible-element'
import { currentPathnameSelector } from '../../../redux/selectors/containers/base/routing'
import { termsAndConditionsURLSelector } from '../../../redux/selectors/containers/base/terms-and-condtions'

class TaskbarContainer extends Component {
  static propTypes = {
    hasDeviceMessages: PropTypes.bool,
    showTermsAndConditionsModal: PropTypes.bool,
    username: PropTypes.string,
    terminalId: PropTypes.string,
    getMachineTerminalInformation: PropTypes.func.isRequired,
    handleOpenTermsAndConditions: PropTypes.func
  }

  UNSAFE_componentWillMount () {
    if (!this.props.terminalId) {
      this.props.getMachineTerminalInformation()
    }
  }

  render () {
    return (
      <Taskbar
        {...this.props}
        hasDeviceMessages={this.props.hasDeviceMessages}
        username={this.props.username}
        terminalId={this.props.terminalId}
      />
    )
  }
}

const mapStateToProps = state => {
  const termsAndConditionsModal = state.containers.base.elementVisibility[TERMS_AND_CONDITIONS_MODAL]
  const currentPathname = currentPathnameSelector(state)
  const locales = _t.getLocales()
  return {
    hasDeviceMessages: hasDeviceMessages(state),
    username: state.api.user.user && state.api.user.user.username,
    terminalId: state.machine.terminalInformation.data.terminalId,
    showTermsAndConditionsModal: termsAndConditionsModal && termsAndConditionsModal.visible,
    showTermsAndConditionsLink: currentPathname.startsWith(`/${locales}/tickets`) || currentPathname.startsWith(`/${locales}/reservations`),
    termsAndConditionsLink: termsAndConditionsURLSelector(state)
  }
}

const mapDispatchToProps = {
  getMachineTerminalInformation,
  handleOpenTermsAndConditions: () => showModal(TERMS_AND_CONDITIONS_MODAL)
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskbarContainer)
