import { createSelector } from 'reselect'

export const afterSalesAdditionalProductsSelector = createSelector(
  [state => state.api.aftersales.additionalProducts],
  additionalProducts => (additionalProducts || []).map(
    product => {
      const id = [
        product.passenger.id,
        product.segment.origin.code,
        product.segment.destination.code,
        product.segment.service_name,
        product.product.code,
        product.product.type,
        product.tariff_code,
        product.segment.departure_date,
        product.segment.comfort_zone_code
      ].join('|')

      return {
        ...product,
        id
      }
    }
  )
)
