import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ComponentContainer from '../../../elements/component-container/component-container'
import Panel from '../../../elements/panel/panel'
import TextLabel from '../../../elements/text-label/text-label'
import _t from 's3p-js-lib/src/translate'
import './details-options.scss'
import Button from '../../../elements/button/button'
import Icon from '../../../elements/icon/icon'
import ProvisionalTimer from '../../../containers/aftersales/booking/provisional-timer'
import BookingStatus from '../../base/booking/status'

export default class DetailsOptions extends Component {
  static propTypes = {
    bookingNumber: PropTypes.string,
    ticketDocument: PropTypes.shape({
      pin: PropTypes.string
    }),
    isProvisional: PropTypes.bool,
    isOnHoldProvisional: PropTypes.bool,
    isLoadingContinueProvisionalBooking: PropTypes.bool,
    onContinueProvisionalBooking: PropTypes.func.isRequired
  }

  _renderProcessBooking () {
    return <div className='booking-details-status__actions'>
      {this.props.isProvisional || this.props.isOnHoldProvisional
        ? (
          <Button
            className='small secondary'
            name='process-booking'
            onClick={this.props.onContinueProvisionalBooking}
            loading={this.props.isLoadingContinueProvisionalBooking}
          >
            <Icon name='tick' className='align-left' />
            <TextLabel text={_t.message('booking.process-booking')} />
          </Button>
        ) : null
      }
    </div>
  }

  render () {
    const {bookingNumber, ticketDocument} = this.props
    return bookingNumber ? (
      <ComponentContainer name='booking-details-options'>
        <Panel
          header={
            <Panel.Header
              name='booking-details-options'
              title={_t.message('booking.title', {bookingNumber})}
              description={_t.message('booking.description', {bookingNumber})}
            />
          }
        >
          <div className='booking-details-status'>
            {ticketDocument && ticketDocument.pin
              ? (
                <div className='booking-details-status__ticket-pin'>
                  <TextLabel name='ticket-pin-description' text={_t.message('booking.ticket-collection-number')} />
                  <TextLabel
                    name='ticket-pin-value'
                    text={`${ticketDocument.pin} ${bookingNumber}`}
                  />
                </div>
              ) : null}
            <div className='booking-details-status__booking-number'>
              <TextLabel name='booking-number-description' text={_t.message('booking.booking-number-text')} />
              <TextLabel name='booking-number-value' text={bookingNumber} />
            </div>
            <div className='booking-details-status__information'>
              <TextLabel name='booking-status-description' text={_t.message('booking.booking-status')} />
              <BookingStatus {...this.props} />
            </div>
            <ProvisionalTimer />
            {this.props.isOnHoldProvisional && this._renderProcessBooking()}
          </div>
        </Panel>
      </ComponentContainer>
    ) : null
  }
}
