import PropTypes from 'prop-types'
import React from 'react'
import containCmsBlock from '../../../containers/base/cms/cms-block'
import Loader from '../../../elements/loader/loader'

const CmsBlockContent = ({isLoadingCmsBlocks, cmsBlock, defaultContent}) => isLoadingCmsBlocks ? (
  <Loader />
) : (
  <div className='cms-block' dangerouslySetInnerHTML={{__html: cmsBlock.content || defaultContent || ''}} />
)

CmsBlockContent.propTypes = {
  isLoadingCmsBlocks: PropTypes.bool,
  cmsBlock: PropTypes.shape({
    content: PropTypes.string.isRequired
  }).isRequired,
  defaultContent: PropTypes.string
}

CmsBlockContent.defaultProps = {
  isLoadingCmsBlocks: false
}

CmsBlockContent.Named = containCmsBlock(CmsBlockContent)

export default CmsBlockContent
