import { createSelector } from 'reselect'
import { isConfirmedSelector } from './booking'
import {
  productsSelector,
  requiredOutboundProductsSelector,
  requiredInboundProductsSelector,
  withoutCancelledSelector,
  canRebookProductsSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/products'
import { createSelectorWithoutMemoize } from '../../../../misc/selector'

export const changeFulfillmentAllowedSelector = bookingSelector => createSelectorWithoutMemoize(
  [isConfirmedSelector(bookingSelector)],
  isConfirmed => isConfirmed
)

export const rebookingAllowedSelector = bookingSelector => createSelectorWithoutMemoize(
  [
    isConfirmedSelector(bookingSelector),
    canRebookProductsSelector(requiredOutboundProductsSelector(bookingSelector)),
    canRebookProductsSelector(requiredInboundProductsSelector(bookingSelector))
  ],
  (isConfirmed, canRebookOutboundProducts, canRebookInboundProducts) =>
    isConfirmed && (canRebookOutboundProducts || canRebookInboundProducts)
)

export const cancellationAllowedSelector = bookingSelector => createSelector(
  [
    isConfirmedSelector(bookingSelector),
    withoutCancelledSelector(productsSelector(bookingSelector))
  ],
  (isConfirmed, products) => isConfirmed && products.some(product => product.canBeCancelled)
)

export const changeSeatsAllowedSelector = bookingSelector => createSelector(
  [
    isConfirmedSelector(bookingSelector),
    withoutCancelledSelector(productsSelector(bookingSelector))
  ],
  (isConfirmed, products) => isConfirmed && products.some(product => product.canChangeSeat)
)

export const updatePassengersAllowedSelector = bookingSelector => createSelector(
  [
    isConfirmedSelector(bookingSelector),
    withoutCancelledSelector(productsSelector(bookingSelector))
  ],
  (isConfirmed, products) => isConfirmed && products.every(product => product.canChangeName)
)

export const additionalProductsAllowedSelector = bookingSelector => createSelectorWithoutMemoize(
  [isConfirmedSelector(bookingSelector)],
  isConfirmed => isConfirmed
)
