/* globals S3P_SETTINGS: true */

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getStations } from 's3p-js-lib/src/redux/actions/api/orientation/stations'
import { getMachineStationInformation } from '../../../redux/actions/machine/station-information'
import { stationsSelector } from 's3p-js-lib/src/redux/selectors/api/base/stations'
import { machineStationSelector } from '../../../redux/selectors/containers/base/stations'
import Loader, { TYPE_BLOCKING } from '../../../elements/loader/loader'
import JourneySearchForm from './journey-search-form'
import { loadBestPrices } from '../../../redux/actions/containers/reservations/journey-search/best-prices'

class JourneySearchContainer extends Component {
  static propTypes = {
    getMachineStationInformation: PropTypes.func.isRequired,
    getStations: PropTypes.func.isRequired,
    hasStations: PropTypes.bool.isRequired,
    hasOffer: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoadingStations: PropTypes.bool.isRequired,
    formName: PropTypes.string,
    loadBestPrices: PropTypes.func.isRequired,
    handleJourneySearch: PropTypes.func.isRequired,
    isLoadingJourneySearch: PropTypes.bool.isRequired,
    loadDefaultFormValues: PropTypes.func
  }

  UNSAFE_componentWillMount () {
    if (!this.props.isLoading && !this.props.hasStations) {
      this.props.getStations(S3P_SETTINGS.s3Passenger.features.stations)
    }

    if (this.props.hasOffer) {
      this.props.loadBestPrices()
    }
  }

  render () {
    return this.props.isLoading || this.props.isLoadingStations
      ? <Loader type={TYPE_BLOCKING} />
      : (
        <JourneySearchForm
          formName={this.props.formName}
          handleJourneySearch={this.props.handleJourneySearch}
          isLoadingJourneySearch={this.props.isLoadingJourneySearch}
          loadDefaultFormValues={this.props.loadDefaultFormValues}
        />
      )
  }
}

const mapStateToProps = state => ({
  defaultOriginStation: machineStationSelector(state),
  hasStations: Boolean(stationsSelector(state)),
  isLoadingStations: state.api.loading.getStations,
  hasOffer: Boolean(state.api.orientation.offer)
})

const mapDispatchToProps = {
  getStations,
  getMachineStationInformation,
  loadBestPrices
}

export default connect(mapStateToProps, mapDispatchToProps)(JourneySearchContainer)
