import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import EditModalForm from '../../../../components/tickets/sidebar/shortcuts/edit-modal-form'
import connectFormContainer from '../../../base/form'
import { salesChannelPropertySelector } from 's3p-js-lib/src/redux/selectors/api/user/sales-channel-properties'
import { stationsSelector } from 's3p-js-lib/src/redux/selectors/api/base/stations'
import countBy from 'lodash/countBy'
import {
  DISCOUNT_CARD_STUDENT_CARD,
  FORM_EDIT_SHORTCUT
} from '../../../../constants'
import {
  PASSENGER_TYPE_ADULT,
  PASSENGER_TYPE_STUDENT,
  PASSENGER_TYPE_CHILD
} from 's3p-js-lib/src/constants'
import {
  searchProducts,
  selectProduct
} from '../../../../redux/actions/containers/tickets/sidebar-shortcuts'
import { productSelector } from '../../../../redux/selectors/containers/tickets/edit-modal'

const maxAdultsSelector = salesChannelPropertySelector('booking_max_adults')
const maxChildrenSelector = salesChannelPropertySelector('booking_max_children')

class EditModalFormContainer extends Component {
  static propTypes = {
    searchProducts: PropTypes.func.isRequired,
    selectProduct: PropTypes.func.isRequired,
    stations: PropTypes.array.isRequired,
    maxPerPassengerType: PropTypes.shape({
      [PASSENGER_TYPE_STUDENT]: PropTypes.number,
      [PASSENGER_TYPE_CHILD]: PropTypes.number,
      [PASSENGER_TYPE_ADULT]: PropTypes.number
    }),
    isLoadingProductsSearch: PropTypes.bool.isRequired,
    isLoadingUpdateAgent: PropTypes.bool.isRequired,
    shortcutSavedSuccessful: PropTypes.bool,
    products: PropTypes.arrayOf(PropTypes.object),
    searchedProducts: PropTypes.bool.isRequired,
    form: PropTypes.shape({
      getValues: PropTypes.func.isRequired,
      valid: PropTypes.bool,
      fields: PropTypes.shape({
        productFamily: PropTypes.object,
        origin: PropTypes.object,
        destination: PropTypes.object,
        dateInterval: PropTypes.shape({
          value: PropTypes.number
        }),
        passengers: PropTypes.shape({
          onChange: PropTypes.func.isRequired,
          value: PropTypes.array
        }),
        product: PropTypes.object,
        productFilter: PropTypes.object,
        discountCard: PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
          onChange: PropTypes.func
        })
      })
    })
  }

  constructor (...args) {
    super(...args)

    this.handleChangeAdult = this.handlePassengerChange.bind(this, PASSENGER_TYPE_ADULT)
    this.handleChangeChild = this.handlePassengerChange.bind(this, PASSENGER_TYPE_CHILD)
    this.handleChangeStudent = this.handlePassengerChange.bind(this, PASSENGER_TYPE_STUDENT)
  }

  handlePassengerChange (type, value) {
    const passengers = this.props.form.fields.passengers.value || []
    const existingPassengers = passengers.filter(passenger => passenger.type !== type)
    let passengersOfType = passengers.filter(passenger => passenger.type === type)

    if (value < passengersOfType.length) {
      passengersOfType = passengersOfType.slice(0, -(passengersOfType.length - value))
    } else {
      passengersOfType = passengersOfType.concat(
        Array(value - passengersOfType.length).fill(1).map(() => ({type}))
      )
    }

    const newPassengers = existingPassengers
      .concat(passengersOfType)
      .sort((passengerA, passengerB) => passengerA.type.localeCompare(passengerB.type))

    this.props.form.fields.passengers.onChange(newPassengers)
    const discountCardCode = get(
      this.props.form.fields.discountCard,
      'value.code',
      this.props.form.fields.discountCard.value
    )

    if (type !== PASSENGER_TYPE_STUDENT &&
      value > 0 &&
      discountCardCode === DISCOUNT_CARD_STUDENT_CARD
    ) {
      this.props.form.fields.discountCard.onChange('')
    }
  }

  render () {
    const passengersCount = countBy(this.props.form.fields.passengers.value, 'type')
    return (
      <EditModalForm
        {...this.props}
        valueAdult={passengersCount[PASSENGER_TYPE_ADULT] || 0}
        valueChild={passengersCount[PASSENGER_TYPE_CHILD] || 0}
        valueStudent={passengersCount[PASSENGER_TYPE_STUDENT] || 0}
        onChangeAdult={this.handleChangeAdult}
        onChangeChild={this.handleChangeChild}
        onChangeStudent={this.handleChangeStudent}
      />
    )
  }
}

const mapStateToProps = state => ({
  isLoadingUpdateAgent: state.api.loading.updateAgent,
  isLoadingProductsSearch: state.api.v2.loading.getProducts,
  stations: stationsSelector(state),
  products: productSelector(state),
  searchedProducts: state.api.v2.search !== null,
  shortcutSavedSuccessful: state.containers.tickets.shortcuts.selectedShortcutNr === state.containers.tickets.shortcuts.lastSavedShortcut,
  maxPerPassengerType: {
    [PASSENGER_TYPE_ADULT]: maxAdultsSelector(state),
    [PASSENGER_TYPE_STUDENT]: maxAdultsSelector(state),
    [PASSENGER_TYPE_CHILD]: maxChildrenSelector(state)
  }
})

const mapDispatchToProps = {
  searchProducts,
  selectProduct
}

export default connect(mapStateToProps, mapDispatchToProps)(
  connectFormContainer({formName: FORM_EDIT_SHORTCUT})(EditModalFormContainer)
)
