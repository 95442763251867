import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import classNames from 'classnames'
import Form from '../../../../elements/form/form'
import Label from '../../../../elements/label/label'
import Indicator from '../../../../elements/indicator/indicator'
import Hotkeys from '../../../../elements/utils/hotkeys'
import DiscountCardSelect from '../../../../elements/discount-card-select/discount-card-select'
import {
  DISCOUNT_CARD_STUDENT_CARD,
  DISCOUNT_CARD_WELFARE_CARD,
  DISCOUNT_CARD_PRIVILEGE_CARD
} from '../../../../constants'
import { PASSENGER_TYPE_STUDENT } from 's3p-js-lib/src/constants'
const FIELD_ID_DISCOUNT_CARD = 'discount-card'

export default class PassengerSubForm extends Component {
  static propTypes = {
    number: PropTypes.number.isRequired,
    form: PropTypes.shape({
      fields: PropTypes.shape({
        passengerType: PropTypes.shape({
          value: PropTypes.string.isRequired
        }).isRequired,
        wheelchair: PropTypes.object,
        discountCardNumber: PropTypes.object,
        discountCard: PropTypes.object,
        seatOnly: PropTypes.object
      })
    }),
    isRebookFlow: PropTypes.bool,
    isReservationsFlow: PropTypes.bool,
    handleDiscountCardChange: PropTypes.func.isRequired
  }

  constructor (props, ...args) {
    super(props, ...args)

    this.hotKeySelectWheelchair = `chooseNr${this.props.number}`
    this.hotKeySelectSeatOnly = `chooseSeatOnlyNr${this.props.number}`
    this.keyHandlers = {
      [this.hotKeySelectWheelchair]: this.toggleWheelchair.bind(this),
      [this.hotKeySelectSeatOnly]: this.toggleSeatOnly.bind(this)
    }
  }

  toggleWheelchair () {
    this.props.form.fields.wheelchair && this.props.form.fields.wheelchair.onChange(!this.props.form.fields.wheelchair.value)
  }

  toggleSeatOnly () {
    this.props.form.fields.seatOnly && this.props.form.fields.seatOnly.onChange(!this.props.form.fields.seatOnly.value)
  }

  discountCardTypes (passengerType) {
    const discountCardTypes = [
      {
        code: '',
        text: _t.message('product-search.passenger-details.discount-card-types.no-card')
      },
      {
        code: DISCOUNT_CARD_PRIVILEGE_CARD,
        text: _t.message('product-search.passenger-details.discount-card-types.privilege-card')
      },
      {
        code: DISCOUNT_CARD_WELFARE_CARD,
        text: _t.message('product-search.passenger-details.discount-card-types.welfare-card')
      }
    ]

    if (passengerType === PASSENGER_TYPE_STUDENT) {
      discountCardTypes.push({
        code: DISCOUNT_CARD_STUDENT_CARD,
        text: _t.message('product-search.passenger-details.discount-card-types.student-card')
      })
    }

    return discountCardTypes
  }

  componentDidMount () {
    const element = document.getElementById('1-wheelchair')
    element && setTimeout(() => element.focus(), 200)
  }

  render () {
    const selectedDiscountCard = this.props.form.fields.discountCard.value
    const discountNumber = this.props.form.fields.discountCardNumber
    return (
      <Hotkeys handlers={this.keyHandlers}>
        <Form.InputGroup
          name='passenger-details'
          tabIndex='-1'
          className={this.props.isRebookFlow ? 'state--is-disabled' : ''}
        >
          <Form.InputWrapper name='passenger'>
            <Indicator
              text={_t.message(
                'product-search.passenger-details.form.wheelchair.passenger-number',
                {number: this.props.number}
              )}
              name='passenger-number'
            />
            <Label
              htmlFor={`${this.props.number}-wheelchair`}
              text={_t.message(
                'product-search.passenger-details.form.wheelchair.passenger-type',
                {type: this.props.form.fields.passengerType.value}
              )}
              name='passenger-type'
            />
          </Form.InputWrapper>
          <Form.InputWrapper
            name='wheelchair'
            hotkey={this.props.isRebookFlow ? undefined : this.hotKeySelectWheelchair}
          >
            <Form.Checkbox
              {...this.props.form.fields.wheelchair}
              id={`${this.props.number}-wheelchair`}
              name='wheelchair'
              disabled={this.props.isRebookFlow}
            />
          </Form.InputWrapper>
          {this.props.isReservationsFlow || this.props.isRebookFlow
            ? (
              <Form.InputWrapper
                name='seat-only'
                hotkey={this.props.isRebookFlow ? undefined : this.hotKeySelectSeatOnly}
              >
                <Form.Checkbox
                  {...this.props.form.fields.seatOnly}
                  id={`${this.props.number}-seat-only`}
                  name='seatOnly'
                  disabled={this.props.isRebookFlow}
                />
              </Form.InputWrapper>
            )
            : (null)
          }
          <Form.InputWrapper
            name='discount-card'
            hotkey={this.props.isRebookFlow ? undefined : `selectProductNr${this.props.number}`}
          >
            <DiscountCardSelect
              name='discount-card'
              id={FIELD_ID_DISCOUNT_CARD}
              hotkey={`selectProductNr${this.props.number}`}
              placeholder={_t.message('product-search.passenger-details.form.discount-card.placeholder')}
              discountCardTypes={this.discountCardTypes(this.props.form.fields.passengerType.value)}
              value={selectedDiscountCard}
              onChange={this.props.handleDiscountCardChange}
              disabled={this.props.isRebookFlow}
            />
          </Form.InputWrapper>
          <Form.InputWrapper
            name='discount-card-number'
            hotkey={this.props.isRebookFlow ? undefined : `focusDiscountCardNumberNr${this.props.number}`}
          >
            <Form.TextField
              {...discountNumber}
              id={`passenger-${this.props.number}-discount-card-number`}
              name='discount-card-number'
              hotkey={this.props.isRebookFlow ? undefined : `focusDiscountCardNumberNr${this.props.number}`}
              autoComplete='off'
              disabled={this.props.isRebookFlow || !(selectedDiscountCard && selectedDiscountCard.code)}
              className={classNames({'state--is-invalid': Boolean(discountNumber.touched && discountNumber.error)})}
            />
          </Form.InputWrapper>
        </Form.InputGroup>
      </Hotkeys>
    )
  }
}
