import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../../elements/text-label/text-label'
import FinalizeBookingModal from '../../../containers/base/finalize-booking/finalize-booking-modal'

export default class AdditionalFulfillmentMethodModal extends Component {
  static propTypes = {
    fulfillmentMethod: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    })
  }

  render () {
    const {fulfillmentMethod: {name, description}, ...props} = this.props

    return (
      <FinalizeBookingModal
        title={name}
        disableNextSale={false}
        additionalFulfillmentMethod
        {...props}
      >
        <TextLabel name='finish-booking'>
          <TextLabel.Text text={description} />
        </TextLabel>
      </FinalizeBookingModal>
    )
  }
}
