import { createSelector } from 'reselect'
import { uniqBy } from 'lodash'
import { SERVICE_ICON_CODE_DEFAULT, SERVICE_ICON_CODE_N, SERVICE_ICONS_MAP } from '../../../../../../constants'

export const servicePropertiesSelector = createSelector(
  [
    (state, ownProps) => ownProps.serviceProperties,
    (state, ownProps) => ownProps.showDefaultIcons,
    (state, ownProps) => ownProps.showAlert
  ],
  (serviceProperties, showDefaultIcons, showAlert) => {
    let icons = []
    if (showAlert) {
      icons.push({code: SERVICE_ICON_CODE_N})
    }
    if (showDefaultIcons) {
      icons.push({code: SERVICE_ICON_CODE_DEFAULT})
    }
    icons = icons.concat(serviceProperties)

    return uniqBy(icons.reduce((codes, property) => {
      return codes.concat((SERVICE_ICONS_MAP[property.code] || [])
        .map(icon => ({code: icon, description: property.description}))
      )
    }, []), 'code')
  }
)
