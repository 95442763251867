import { createSelector } from 'reselect'
import { FORM_PRODUCT_SEARCH } from 's3p-js-lib/src/constants-form'
import { formFieldValueSelectorCreator } from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import baseProductSearchForm from 's3p-js-lib/src/forms/product-search'
import { EXTERNAL_REFERENCE_DISABLE_OD } from '../constants'
import isString from 'lodash/isString'

export const disabledOriginDestination = attributes => attributes.some(
  attribute => attribute.key === EXTERNAL_REFERENCE_DISABLE_OD &&
    isString(attribute.value) &&
    (attribute.value.toLowerCase() === 'true' || attribute.value === '1')
)

export default createSelector(
  [
    baseProductSearchForm,
    formFieldValueSelectorCreator(FORM_PRODUCT_SEARCH, 'productFamily')
  ],
  (baseForm, fieldValue) => {
    const disableStations = fieldValue && disabledOriginDestination(fieldValue.attributes)

    return {
      fields: {
        ...baseForm.fields,
        origin: {
          ...baseForm.fields.origin,
          disabled: disableStations
        },
        destination: {
          ...baseForm.fields.destination,
          disabled: disableStations
        }
      }
    }
  }
)
