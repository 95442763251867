import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fulfillmentMethodSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import CancellationVoidMagstripe from '../void-tickets/void-magstripe-tickets'
import CancellationVoidLeap from './reverse-leap'
import {
  isMagstripeOrTVMFulfillment,
  isLeapFulfillment
} from '../../../misc/utils'

const _fulfillmentMethodSelector = fulfillmentMethodSelector(originalBookingSelector)

class CancellationVoidFulfillmentContainer extends Component {
  static propTypes = {
    isMagstripeFulfillment: PropTypes.bool
  }

  render () {
    return this.props.isMagstripeFulfillment ? <CancellationVoidMagstripe /> : <CancellationVoidLeap {...this.props} />
  }
}

const mapStateToProps = state => ({
  isMagstripeFulfillment: isMagstripeOrTVMFulfillment(_fulfillmentMethodSelector(state)),
  isleapAddProduct: isLeapFulfillment(_fulfillmentMethodSelector(state))
})

export default connect(mapStateToProps)(CancellationVoidFulfillmentContainer)
