import {
  USER_INPUT_USE_SHORTCUT
} from '../../../actions/types'
import { CLEAR_STATE } from '../../../../../s3p-js-lib/src/redux/actions/types'
import { CONTAINERS_TICKETS_SHORTCUT } from '../../../../constants'

const initialState = {
  usedShortcut: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_INPUT_USE_SHORTCUT:
      return {...state, usedShortcut: action.shortcut}
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_TICKETS_SHORTCUT) ? initialState : state
    default:
      return state
  }
}
