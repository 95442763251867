import { createSelector } from 'reselect'
import Validator from 's3p-js-lib/src/forms/validator'
import { hasAgentRoleSelector } from 's3p-js-lib/src/redux/selectors/api/user/agent'
import { AGENT_ROLE_TECHNICIAN } from '../constants'

export default createSelector(
  [hasAgentRoleSelector(AGENT_ROLE_TECHNICIAN)],
  hasTechnicianRole => {
    const validators = hasTechnicianRole ? [] : [
      Validator.isRequired,
      Validator.minValue(0.01)
    ]
    return {
      fields: {
        amount: {
          validators
        }
      }
    }
  }
)
