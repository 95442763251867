import React from 'react'
import { Route } from 'react-router'
import containAuth from '../../containers/base/auth'
import SeatSelectorLayout from '../../layouts/reservations/seat-selector'
import { AGENT_ROLE_OPERATOR } from '../../constants'

export default (
  <Route
    key='reservations-seat-selector'
    path='/:lang/reservations/seat-selector/:segmentId'
    component={containAuth(SeatSelectorLayout, {roles: [AGENT_ROLE_OPERATOR]})}
  />
)
