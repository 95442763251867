import { connect } from 'react-redux'
import DetailsOptions from '../../../components/aftersales/booking/details-options'
import {
  overviewBookingSelector,
  bookingNumberSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { pinDocumentSelector } from 's3p-js-lib/src/redux/selectors/api/booking/ticket-documents'
import {
  isCancelledSelector,
  isConfirmedSelector,
  isOnHoldExpiredSelector,
  isOnHoldConfirmedSelector,
  isProvisionalSelector,
  isExpiredSelector
} from '../../../redux/selectors/api/booking/booking'
import { goToReservationsReviewPayment } from '../../../redux/actions/containers/base/routing'

const _bookingNumberSelector = bookingNumberSelector(overviewBookingSelector)
const _ticketDocument = pinDocumentSelector(overviewBookingSelector)
const _isProvisionalSelector = isProvisionalSelector(overviewBookingSelector)
const _isConfirmedSelector = isConfirmedSelector(overviewBookingSelector)
const _isCancelledSelector = isCancelledSelector(overviewBookingSelector)
const _isOnHoldExpiredSelector = isOnHoldExpiredSelector(overviewBookingSelector)
const _isOnHoldProvisionalSelector = isOnHoldConfirmedSelector(overviewBookingSelector)
const _isExpiredSelector = isExpiredSelector(overviewBookingSelector)

const mapStateToProps = state => ({
  bookingNumber: _bookingNumberSelector(state),
  ticketDocument: _ticketDocument(state),
  isProvisional: _isProvisionalSelector(state),
  isExpired: _isExpiredSelector(state),
  isConfirmed: _isConfirmedSelector(state),
  isCancelled: _isCancelledSelector(state),
  isOnHoldExpired: _isOnHoldExpiredSelector(state),
  isOnHoldProvisional: _isOnHoldProvisionalSelector(state),
  isLoadingContinueProvisionalBooking: state.api.loading.continueProvisionalBooking
})

const mapDispatchToProps = {
  onContinueProvisionalBooking: () => (dispatch, getState) => {
    if (_isOnHoldProvisionalSelector(getState())) {
      dispatch(goToReservationsReviewPayment())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsOptions)
