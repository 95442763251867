import { connect } from 'react-redux'
import { processVoidTickets } from '../../../redux/actions/containers/aftersales/void-tickets'
import VoidTickets from '../../../components/aftersales/cancellation/void-tickets'

const mapStateToProps = state => ({
  ...state.containers.aftersales.voidTickets,
  isLoadingRead: state.machine.loading.readMagneticTicket,
  isLoadingVoid: state.machine.loading.voidMagneticTicket || state.api.loading.ticketVoidValidation,
  isLoadingEject: state.machine.loading.ejectMagneticTicket
})

const mapDispatchToProps = {
  processVoidTickets
}

export default connect(mapStateToProps, mapDispatchToProps)(VoidTickets)
