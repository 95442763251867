import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ComponentContainer from '../../../elements/component-container/component-container'
import TilesWithModal from '../../../containers/base/tiles/tiles-with-modal'
import ProductFamilyTile from './product-family-tile'
import './product-family.scss'

const MAX_FAMILIES = 12

export default class ProductFamily extends Component {
  static propTypes = {
    productFamilies: PropTypes.array.isRequired,
    handleSelect: PropTypes.func.isRequired,
    selectedCode: PropTypes.string,
    disabled: PropTypes.bool
  }

  constructor (...args) {
    super(...args)

    this.hotkey = {
      tiles: [
        'selectLeftNr1',
        'selectLeftNr2',
        'selectLeftNr3',
        'selectLeftNr4',
        'selectLeftNr5',
        'selectLeftNr6',
        'selectLeftNr7',
        'selectLeftNr8',
        'selectLeftNr9'
      ]
    }
  }

  render () {
    return (
      <ComponentContainer name='tickets-product-family'>
        <TilesWithModal
          disabled={this.props.disabled}
          data={this.props.productFamilies}
          maxTiles={MAX_FAMILIES}
          name='product-family'
          translatePrefix='product-family'
          tile={ProductFamilyTile}
          handleSelect={this.props.handleSelect}
          hotkey={this.hotkey}
          hideDivider
        />
      </ComponentContainer>
    )
  }
}
