import localStorage from 's3p-js-lib/src/local-storage'
import {
  STORAGE_ITEM_NAME_LEAP_CONFIGURATION_DATA,
  LEAP_CONFIGURATION_DATA_MAP_TXNTYPES,
  LEAP_CONFIGURATION_DATA_MAP_PPTU_MINIMUM_TRANSACTION_VALUE,
  LEAP_CONFIGURATION_DATA_MAP_PPTU_MAXIMUM_TRANSACTION_VALUE,
  LEAP_CONFIGURATION_DATA_MAP_TIER1PP,
  LEAP_CONFIGURATION_DATA_MAP_CARD_EXPIRY_DURATION,
  LEAP_ERROR_MESSAGE_AMOUNT_EXCEEDED,
  LEAP_ERROR_MESSAGE_AMOUNT_TOO_LOW,
  LEAP_ERROR_MESSAGE_CARD_EXPIRED_SINCE,
  LEAP_ERROR_MESSAGE_CARD_IS_BLOCKED,
  LEAP_ERROR_MESSAGE_NO_CONFIGURATION,
  LEAP_ERROR_MESSAGE_PURSE_IS_BLOCKED,
  LEAP_ERROR_MESSAGE_LEAP_CARD_NOT_READ,
  LEAP_ERROR_MESSAGE_LEAP_CONFIGURATION_DATA_CORRUPT,
  LAST_MACHINE_ERROR_NAMESPACE_LEAP_DEVICE,
  LEAP_CONFIGURATION_DATA_MAP_TRANSACTION_REVERSAL_DURATION,
  LEAP_ERROR_MESSAGE_LEAP_TRANSACTION_PERIOD_EXPIRED,
  LEAP_ERROR_MESSAGE_LEAP_PRESENT_CORRECT_CARD
} from '../../../../constants'
import {
  leapDataSelector,
  leapCardIsValidSelector,
  leapCardIsExpiredSelector,
  leapCardIsBlockedSelector,
  leapPurseIsBlockedSelector,
  purseAmountTooLowSelector,
  purseAmountTooHighSelector
} from '../../../selectors/machine/leap'
import { lastMachineErrorsSelector } from '../../machine/last-machine-errors'
import moment from 'moment'

const lastLeapErrorsSelector = lastMachineErrorsSelector([LAST_MACHINE_ERROR_NAMESPACE_LEAP_DEVICE])

export const minimumTransactionValueSelector = () => {
  const leapConfiguration = localStorage.get(STORAGE_ITEM_NAME_LEAP_CONFIGURATION_DATA)
  return leapConfiguration &&
    leapConfiguration[LEAP_CONFIGURATION_DATA_MAP_TXNTYPES] &&
    parseInt(leapConfiguration[LEAP_CONFIGURATION_DATA_MAP_TXNTYPES][LEAP_CONFIGURATION_DATA_MAP_PPTU_MINIMUM_TRANSACTION_VALUE])
}

export const maximumTransactionValueSelector = () => {
  const leapConfiguration = localStorage.get(STORAGE_ITEM_NAME_LEAP_CONFIGURATION_DATA)
  return leapConfiguration &&
    leapConfiguration[LEAP_CONFIGURATION_DATA_MAP_TXNTYPES] &&
    parseInt(leapConfiguration[LEAP_CONFIGURATION_DATA_MAP_TXNTYPES][LEAP_CONFIGURATION_DATA_MAP_PPTU_MAXIMUM_TRANSACTION_VALUE])
}

export const configurationExpiryDurationSelector = () => {
  const leapConfiguration = localStorage.get(STORAGE_ITEM_NAME_LEAP_CONFIGURATION_DATA)
  return leapConfiguration &&
    leapConfiguration[LEAP_CONFIGURATION_DATA_MAP_TIER1PP] &&
    leapConfiguration[LEAP_CONFIGURATION_DATA_MAP_TIER1PP][LEAP_CONFIGURATION_DATA_MAP_CARD_EXPIRY_DURATION]
}

export const transactionReversalDurationSelector = () => {
  const leapConfiguration = localStorage.get(STORAGE_ITEM_NAME_LEAP_CONFIGURATION_DATA)
  return leapConfiguration &&
    leapConfiguration[LEAP_CONFIGURATION_DATA_MAP_TIER1PP] &&
    parseInt(leapConfiguration[LEAP_CONFIGURATION_DATA_MAP_TIER1PP][LEAP_CONFIGURATION_DATA_MAP_TRANSACTION_REVERSAL_DURATION])
}

export const checkDoubtfulTransactionMessageSelector = state => {
  const errors = lastLeapErrorsSelector(state)
  return errors && errors.some(error => error.message.match(/Leap Error: \(F24([0-9a-fA-F]{2})\)/g))
}

export const leapDoubtfulTransactionOccurredSelector = state => {
  let errorMessage = ''
  const cardIdentifier = state.containers.leap.doubtFulTransaction.cardIdentifier

  if (!cardIdentifier) {
    return errorMessage
  }

  const data = leapDataSelector(state)
  if (cardIdentifier === data.cardIdentifier) {
    if (cardIdentifier && data.lastTransaction && data.lastTransaction.dateTime.add(parseInt(transactionReversalDurationSelector()), 'seconds').isSameOrBefore(moment())) {
      errorMessage = LEAP_ERROR_MESSAGE_LEAP_TRANSACTION_PERIOD_EXPIRED
    }
  } else {
    errorMessage = LEAP_ERROR_MESSAGE_LEAP_PRESENT_CORRECT_CARD
  }
  return errorMessage
}

export const readLeapStepsBeforeActionSelector = state => {
  let errorMessage = null
  const leapConfiguration = localStorage.get(STORAGE_ITEM_NAME_LEAP_CONFIGURATION_DATA)
  if (!leapConfiguration || !leapConfiguration[LEAP_CONFIGURATION_DATA_MAP_TXNTYPES] || !leapConfiguration[LEAP_CONFIGURATION_DATA_MAP_TIER1PP]) {
    errorMessage = LEAP_ERROR_MESSAGE_LEAP_CONFIGURATION_DATA_CORRUPT
  } else if (!leapDataSelector(state)) {
    errorMessage = LEAP_ERROR_MESSAGE_LEAP_CARD_NOT_READ
  } else if (leapCardIsValidSelector(state)) {
    const leapConfiguration = localStorage.get(STORAGE_ITEM_NAME_LEAP_CONFIGURATION_DATA)
    if (!leapConfiguration) {
      errorMessage = LEAP_ERROR_MESSAGE_NO_CONFIGURATION
    } else {
      if (purseAmountTooLowSelector(state)) {
        errorMessage = LEAP_ERROR_MESSAGE_AMOUNT_TOO_LOW
      } else if (purseAmountTooHighSelector(state)) {
        errorMessage = LEAP_ERROR_MESSAGE_AMOUNT_EXCEEDED
      }
    }
  } else if (leapCardIsExpiredSelector(state)) {
    errorMessage = LEAP_ERROR_MESSAGE_CARD_EXPIRED_SINCE
  } else if (leapCardIsBlockedSelector(state)) {
    errorMessage = LEAP_ERROR_MESSAGE_CARD_IS_BLOCKED
  } else if (leapPurseIsBlockedSelector(state)) {
    errorMessage = LEAP_ERROR_MESSAGE_PURSE_IS_BLOCKED
  }

  return errorMessage
}
