import { createSelector } from 'reselect'
import { toTimezoneMoment } from '../../../../../misc/date'
import { stationMapper } from '../../orientation/stations'
import { TRAVEL_DIRECTION_OUTBOUND, TRAVEL_DIRECTION_INBOUND } from '../../../../../constants'
import { requiredProductsSelector, additionalProductsSelector } from './products'

const legMapper = (leg, direction, requiredProducts, additionalProducts) => ({
  id: leg.leg_id,
  uniqueIdentifier: leg.leg_id,
  departureDate: toTimezoneMoment(leg.departure_date_time, leg.departure_station.timezone),
  arrivalDate: toTimezoneMoment(leg.arrival_date_time, leg.arrival_station.timezone),
  departureStation: stationMapper(leg.departure_station),
  arrivalStation: stationMapper(leg.arrival_station),
  serviceName: leg.service_name,
  requiredProducts: requiredProducts.filter(product => product.legs.includes(leg.leg_id)),
  additionalProducts: additionalProducts.filter(product => product.legs.includes(leg.leg_id)),
  direction
})

const legsSelectorCreator = (specificationLegProperty, direction) => createSelector(
  [
    state => state.api.orientation.specification,
    requiredProductsSelector,
    additionalProductsSelector
  ],
  (specification, requiredProducts, additionalProducts) => specification
    ? specification[specificationLegProperty].map(
      leg => legMapper(leg, direction, requiredProducts, additionalProducts)
    ) : []
)

export const outboundLegsSelector = legsSelectorCreator(
  'outbound_booking_journey_segments',
  TRAVEL_DIRECTION_OUTBOUND
)
export const inboundLegsSelector = legsSelectorCreator(
  'inbound_booking_journey_segments',
  TRAVEL_DIRECTION_INBOUND
)
export const legsSelector = createSelector(
  [outboundLegsSelector, inboundLegsSelector],
  (outboundLegs, inboundLegs) => outboundLegs.concat(inboundLegs)
)
