import accordionGroup from './accordion-group'
import elementVisibility from './visible-element'
import finalizeBooking from './finalize-booking'
import offline from './offline'
import paymentModal from './payment-modal'
import table from './table'
import tiles from './tiles'
import journeySearch from './journey-search'

export default {
  accordionGroup,
  elementVisibility,
  finalizeBooking,
  journeySearch,
  offline,
  paymentModal,
  table,
  tiles
}
