import {
  USER_INPUT_REBOOKING_OFFER_REBOOK_SELECTION,
  CLEAR_STATE
} from '../../../../actions/types'

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_INPUT_REBOOKING_OFFER_REBOOK_SELECTION:
      return action.selection
    case CLEAR_STATE:
      return action.namespaces.includes('userInput.aftersales.rebooking.selection') ? initialState : state
    default:
      return state
  }
}
