import { connect } from 'react-redux'
import { overviewBookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { toTimezoneMoment } from 's3p-js-lib/src/misc/date'
import ProvisionalTimer from '../../../components/aftersales/booking/provisional-timer'

const mapStateToProps = state => {
  const booking = overviewBookingSelector(state)

  return {
    isProvisional: booking ? Boolean(booking.expiry_timestamp) : false,
    expiryTimestamp: toTimezoneMoment(booking.expiry_timestamp)
  }
}

export default connect(mapStateToProps)(ProvisionalTimer)
