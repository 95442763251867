import _t from 's3p-js-lib/src/translate'
import {clearState} from 's3p-js-lib/src/redux/actions/clear-state'
import {
  startAgentShift as baseStartAgentShift,
  getAgentShift, headAgentShift
} from 's3p-js-lib/src/redux/actions/api/user/agent/shift'
import {openMachineCashDrawer} from '../../../machine/cash-drawer'
import {formFieldValueSelectorCreator} from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import {getDocumentTemplates} from 's3p-js-lib/src/redux/actions/api/meta/document-templates'
import {documentTemplatesSelector} from 's3p-js-lib/src/redux/selectors/api/meta/document-templates'
import {getStations} from 's3p-js-lib/src/redux/actions/api/orientation/stations'
import {stationsSelector} from 's3p-js-lib/src/redux/selectors/api/base/stations'
import {FORM_TYPE_AGENT_SHIFT_START} from 's3p-js-lib/src/constants-form'
import localStorage from 's3p-js-lib/src/local-storage'
import {
  updateMagneticTicketTemplate,
  getMagneticTicketTemplates,
  removeMagneticTicketTemplate
} from '../../../machine/magnetic-ticket-printer'
import {
  sendMachineDisplayLines,
  timeoutClearDisplay
} from '../../../machine/display'
import {
  CURRENCY_EURO,
  DOCUMENT_TEMPLATE_TICKET,
  DOCUMENT_TEMPLATE_RECEIPT,
  DOCUMENT_TEMPLATE_NAME_PED_CUSTOMER_RECEIPT,
  DOCUMENT_TEMPLATE_NAME_PED_MERCHANT_RECEIPT,
  DOCUMENT_TEMPLATE_NAME_PED_SIGNATURE_RECEIPT,
  DOCUMENT_TEMPLATE_NAME_PED_FAILED_CUSTOMER_RECEIPT,
  DOCUMENT_TEMPLATE_NAME_PED_FAILED_MERCHANT_RECEIPT,
  DOCUMENT_TEMPLATE_NAME_PED_REFUND_CUSTOMER_RECEIPT,
  DOCUMENT_TEMPLATE_NAME_PED_REFUND_MERCHANT_RECEIPT,
  DOCUMENT_TEMPLATE_NAME_PED_SIGNATURE_CODE,
  DOCUMENT_TEMPLATE_NAME_CASH_REFUND_CUSTOMER_RECEIPT,
  DOCUMENT_TEMPLATE_NAME_CASH_REFUND_MERCHANT_RECEIPT,
  STORAGE_ITEM_NAME_LEAP_CONFIGURATION_DATA,
  STORAGE_ITEM_NAME_LEAP_SCHEME_WIDE_CODE,
  CLEAR_DISPLAY_AFTER_SHORT,
  START_SHIFT_ERROR_MODAL,
  TICKET_PRINTER_DEVICE_SERIAL,
  TICKET_PRINTER_DEVICE_COUNTER,
  TICKET_PRINTER_DEVICE_START_AMOUNT,
  LEAP_DEVICE_SERIAL,
  FULFILLMENT_METHOD_CODE_MAGSTRIPE,
  DOCUMENT_TEMPLATE_NAME_VOUCHER_TO_CASH_CUSTOMER_RECEIPT,
  DOCUMENT_TEMPLATE_NAME_VOUCHER_TO_CASH_MERCHANT_RECEIPT,
  DEVICE_ID_LEAP,
  DEVICE_ID_TICKET_PRINTER
} from '../../../../../constants'
import {
  getLeapConfigurationData,
  getLeapConfigurationDataSet,
  confirmLeapConfigurationDataSet,
  getLeapSchemeWideCode
} from '../../../api/module/leap'
import {getCurrencyRates} from 's3p-js-lib/src/redux/actions/api/v2/meta/currency-rates'
import {
  UI_END_START_SHIFT,
  UI_START_START_SHIFT
} from '../../../types'
import {applyLeapCd} from '../../../machine/leap'
import {setLeapConfiguration} from '../../leap/leap'
import {camelCaseKeys} from 's3p-js-lib/src/misc/utils'
import {showModal} from '../../base/visible-element'
import {routerActions} from '../../base/routing'
import {isOfflineSelectorCreator} from '../../../../selectors/machine/offline'
import {getMachineStationInformation} from '../../../machine/station-information'
import {getMachineStatusDevices} from '../../../machine/status'
import {getMachineTerminalInformation} from '../../../machine/terminal-information'
import {machineStationSelector} from '../../../../selectors/containers/base/stations'
import {getTemplatesSelector} from '../../../../selectors/machine/magnetic-ticket-printer'
import differenceWith from 'lodash/differenceWith'
import uniqBy from 'lodash/uniqBy'
import moment from 'moment'
import {displayNextCustomer} from '../../base/finalize-booking/next-customer'
import {captureMessage} from '@s3p-js-deep-purple/sentry'
import {
  disableNadmanSentryLogging,
  enableNadmanSentryLogging,
  isNadmanSentryLoggingEnabled
} from '../../../../../machine/request'
import NADManager from '../../../../../machine/nad-manager'
import isEmpty from 'lodash/isEmpty'

const fieldValueSelector = formFieldValueSelectorCreator(FORM_TYPE_AGENT_SHIFT_START, 'amount')

const localStorePedTemplate = (templates, templateName) => {
  const pedTemplate = templates.find(
    template => template.templateType === DOCUMENT_TEMPLATE_RECEIPT && template.name === templateName
  )
  if (pedTemplate) {
    localStorage.set(templateName, pedTemplate.templateContent)
  }
}

const startStartShift = () => ({type: UI_START_START_SHIFT})
const endStartShift = () => ({type: UI_END_START_SHIFT})

const loadDocumentTemplates = () => async (dispatch, getState) => {
  await dispatch(getMagneticTicketTemplates())
  await dispatch(getDocumentTemplates([DOCUMENT_TEMPLATE_TICKET, DOCUMENT_TEMPLATE_RECEIPT]))

  const state = getState()
  const newburyTemplates = getTemplatesSelector(state)
  const templates = documentTemplatesSelector(state)
  const magstripeTemplates = templates.filter(template => (
    template.templateType === DOCUMENT_TEMPLATE_TICKET &&
    template.fulfillmentMethods.includes(FULFILLMENT_METHOD_CODE_MAGSTRIPE)
  ))

  const hasIdenticalName = (template, otherTemplate) => template.name === otherTemplate.name

  const toDeleteTemplates = differenceWith(newburyTemplates, magstripeTemplates, hasIdenticalName)
  const toAddTemplates = differenceWith(magstripeTemplates, newburyTemplates, hasIdenticalName)
  const toUpdateTemplates = uniqBy(magstripeTemplates.filter(template => newburyTemplates.some(existing => {
    if (existing.name === template.name) {
      return !template.changeTimestamp || moment(template.changeTimestamp).isAfter(moment(existing.date))
    }
    return false
  })).concat(toAddTemplates), 'name')

  for (const template of toDeleteTemplates) {
    await dispatch(removeMagneticTicketTemplate(template.name))
  }

  for (const template of toUpdateTemplates) {
    await dispatch(updateMagneticTicketTemplate(template.name, template.templateContent))
  }

  localStorePedTemplate(templates, DOCUMENT_TEMPLATE_NAME_PED_REFUND_CUSTOMER_RECEIPT)
  localStorePedTemplate(templates, DOCUMENT_TEMPLATE_NAME_PED_REFUND_MERCHANT_RECEIPT)
  localStorePedTemplate(templates, DOCUMENT_TEMPLATE_NAME_PED_CUSTOMER_RECEIPT)
  localStorePedTemplate(templates, DOCUMENT_TEMPLATE_NAME_PED_MERCHANT_RECEIPT)
  localStorePedTemplate(templates, DOCUMENT_TEMPLATE_NAME_PED_SIGNATURE_RECEIPT)
  localStorePedTemplate(templates, DOCUMENT_TEMPLATE_NAME_PED_FAILED_CUSTOMER_RECEIPT)
  localStorePedTemplate(templates, DOCUMENT_TEMPLATE_NAME_PED_FAILED_MERCHANT_RECEIPT)
  localStorePedTemplate(templates, DOCUMENT_TEMPLATE_NAME_PED_SIGNATURE_CODE)
  localStorePedTemplate(templates, DOCUMENT_TEMPLATE_NAME_VOUCHER_TO_CASH_CUSTOMER_RECEIPT)
  localStorePedTemplate(templates, DOCUMENT_TEMPLATE_NAME_VOUCHER_TO_CASH_MERCHANT_RECEIPT)
  localStorePedTemplate(templates, DOCUMENT_TEMPLATE_NAME_CASH_REFUND_CUSTOMER_RECEIPT)
  localStorePedTemplate(templates, DOCUMENT_TEMPLATE_NAME_CASH_REFUND_MERCHANT_RECEIPT)
}

const loadLeapConfigurationDataSet = deviceId => async (dispatch, getState) => {
  const leapMachine = getState().machine.status.data.find(machine => machine.id === DEVICE_ID_LEAP) || {}
  const scimId = leapMachine.details && leapMachine.details.serial_number
  const samId = leapMachine.details && leapMachine.details.sam_id

  let applyCdResult = true
  if (scimId && samId && await dispatch(getLeapConfigurationDataSet(deviceId, scimId, samId))) {
    const dataSet = getState().api.modules.leap.configuration.dataSet || []
    for (const _data of dataSet) {
      const data = camelCaseKeys(_data)
      applyCdResult = await dispatch(applyLeapCd(data.filename.replace('.zip', ''), data.cdSetData))
      if (applyCdResult) {
        const applyCdResponse = getState().machine.leap.applyCd
        await dispatch(confirmLeapConfigurationDataSet(
          deviceId,
          scimId,
          samId,
          {
            source_node: data.sourceNode,
            destination_node: data.destinationNode,
            transmission_sequence: data.transmissionSequence,
            status: applyCdResponse.status,
            result: JSON.stringify(applyCdResponse.results || {})
          }
        ))
      } else {
        dispatch(showModal(START_SHIFT_ERROR_MODAL))
        break
      }
    }
  }

  return applyCdResult
}

const loadLeapConfigurationAndSchemeWideCode = () => async (dispatch, getState) => {
  const responseConfigurationData = await dispatch(getLeapConfigurationData())
  const responseSchemeWideCode = await dispatch(getLeapSchemeWideCode())
  const leapData = getState().api.modules.leap

  if (responseConfigurationData) {
    localStorage.set(STORAGE_ITEM_NAME_LEAP_CONFIGURATION_DATA, leapData.configuration.data)
  }

  if (responseSchemeWideCode) {
    localStorage.set(STORAGE_ITEM_NAME_LEAP_SCHEME_WIDE_CODE, leapData.schemeWideCode)
  }
}

const startAgentShift = (deviceId, stationCode) => async (dispatch, getState) => {
  await dispatch(getMachineStatusDevices())
  if (!stationsSelector(getState())) {
    await dispatch(getStations())
  }

  const value = fieldValueSelector(getState()) || 0

  let location = stationCode
  if (!isEmpty(stationCode)) {
    location = machineStationSelector(getState())?.UICStationCode || stationCode
  }

  const newburyMachine = getState().machine.status.data.find(machine => machine.id === DEVICE_ID_TICKET_PRINTER)
  const leapMachine = getState().machine.status.data.find(machine => machine.id === DEVICE_ID_LEAP) || {}

  const metaData = []
  if (newburyMachine) {
    metaData.push({key: TICKET_PRINTER_DEVICE_START_AMOUNT, value: value.toString()})

    if (newburyMachine.details.serial_number) {
      metaData.push({key: TICKET_PRINTER_DEVICE_SERIAL, value: newburyMachine.details.serial_number})
    }
    if (newburyMachine.details.tickets_printed) {
      metaData.push({key: TICKET_PRINTER_DEVICE_COUNTER, value: newburyMachine.details.tickets_printed})
    }
    if (leapMachine && leapMachine.details && leapMachine.details.serial_number) {
      metaData.push({key: LEAP_DEVICE_SERIAL, value: leapMachine.details.serial_number})
    }
  }

  return dispatch(baseStartAgentShift(
    value,
    CURRENCY_EURO,
    deviceId,
    null,
    metaData,
    location
  ))
}

const retryNadmanInformationRequest = nadmanInformationRequest => async dispatch => {
  const defaultLoggingEnabled = isNadmanSentryLoggingEnabled()
  !defaultLoggingEnabled && enableNadmanSentryLogging()
  await dispatch(nadmanInformationRequest())
  !defaultLoggingEnabled && disableNadmanSentryLogging()
}

const getMachineTerminalInformationWithLoggedRetry = () => async (dispatch, getState) => {
  await dispatch(getMachineTerminalInformation())
  if (!getState().machine.terminalInformation.data.terminalId) {
    NADManager.clearStatusCache()
    await dispatch(retryNadmanInformationRequest(getMachineTerminalInformation))
  }
}

const getMachineStationInformationWithLoggedRetry = () => async (dispatch, getState) => {
  await dispatch(getMachineStationInformation())
  if (!getState().machine.stationInformation.data.stationCode) {
    NADManager.clearStatusCache()
    await dispatch(retryNadmanInformationRequest(getMachineStationInformation))
  }
}

export const startShift = () => async (dispatch, getState) => {
  dispatch(startStartShift())
  await dispatch(getMachineTerminalInformationWithLoggedRetry())
  await dispatch(getMachineStationInformationWithLoggedRetry())
  const deviceId = getState().machine.terminalInformation.data.terminalId || ''
  const stationCode = getState().machine.stationInformation.data.stationCode

  if (!deviceId) {
    captureMessage(
      'Invalid NADMan terminal id.',
      {
        level: 'warning',
        extra: {
          data: {
            terminalInformation: getState().machine.terminalInformation
          }
        }
      }
    )
  }
  if (!stationCode) {
    captureMessage(
      'Invalid NADMan station code.',
      {
        level: 'warning',
        extra: {
          data: {
            stationInformation: getState().machine.stationInformation
          }
        }
      }
    )
  }

  if (await dispatch(getCurrencyRates())) {
    if (await dispatch(loadDocumentTemplates())) {
      let response = false
      if (deviceId) {
        response = await dispatch(loadLeapConfigurationDataSet(deviceId))
      }

      if (!deviceId || response) {
        await dispatch(loadLeapConfigurationAndSchemeWideCode())

        if (!isOfflineSelectorCreator(DEVICE_ID_LEAP)(getState()) && !await dispatch(setLeapConfiguration())) {
          dispatch(showModal(START_SHIFT_ERROR_MODAL))
          return
        }
        const result = await dispatch(startAgentShift(deviceId, stationCode))

        if (result) {
          const requestId = 'shift_start'
          await dispatch(sendMachineDisplayLines([
            _t.message('machine.customer-display.lines.welcome.upper'),
            _t.message('machine.customer-display.lines.welcome.lower')
          ], requestId))
          dispatch([
            timeoutClearDisplay(CLEAR_DISPLAY_AFTER_SHORT, requestId),
            openMachineCashDrawer(),
            routerActions.push('/tickets')
          ])
          dispatch(displayNextCustomer())
        } else {
          const response = await dispatch(headAgentShift())
          response && await dispatch(getAgentShift())
        }
      }
    }
  }

  dispatch([
    endStartShift(),
    clearState(['api.meta.documentTemplates', 'api.modules.leap.configuration', 'api.modules.leap.schemeWideCode'])
  ])
}
