import { registerFormType } from 's3p-js-lib/src/forms/form-registry'
import { formFieldValueSelectorCreator } from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { completedOrProvisionalOrOriginalOrOverviewBookingSelector } from '../redux/selectors/api/booking/booking'
import {
  FORM_BOOKINGS_FILTER,
  FORM_CUSTOMER_DETAILS,
  FORM_PASSENGER_DETAILS,
  FORM_PRODUCT_SEARCH,
  FORM_JOURNEY_SEARCH,
  FORM_TYPE_REBOOK_JOURNEY_SEARCH,
  FORM_TYPE_AGENT_SHIFT_START,
  FORM_PASSENGER_DETAILS_AFTERSALES
} from 's3p-js-lib/src/constants-form'
import {
  FORM_EDIT_SHORTCUT,
  FORM_CREDIT_DEBIT,
  FORM_PRODUCT_SEARCH_PASSENGERS_DETAILS,
  FORM_JOURNEY_SEARCH_PASSENGERS_DETAILS,
  FORM_REBOOK_SEARCH_PASSENGERS_DETAILS
} from '../constants'
import bookingsFilter from './bookings-filter'
import customerDetails from './customer-details'
import editShortcut from './edit-shortcut'
import passengerDetails from './passenger-details'
import passengerDetailsSearch from './passenger-details-search'
import productSearch from './product-search'
import journeySearch from './journey-search'
import rebookJourneySearch from './rebook-journey-search'
import agentShiftStart from './agent-shift-start'
import creditDebit from './credit-debit'

registerFormType(FORM_BOOKINGS_FILTER, bookingsFilter)
registerFormType(FORM_CUSTOMER_DETAILS, customerDetails)
registerFormType(FORM_EDIT_SHORTCUT, editShortcut)
registerFormType(FORM_PASSENGER_DETAILS, passengerDetails(bookingSelector))
registerFormType(FORM_PASSENGER_DETAILS_AFTERSALES, passengerDetails(completedOrProvisionalOrOriginalOrOverviewBookingSelector))
registerFormType(
  FORM_PRODUCT_SEARCH_PASSENGERS_DETAILS,
  passengerDetailsSearch(formFieldValueSelectorCreator(FORM_PRODUCT_SEARCH, 'passengers'))
)
registerFormType(
  FORM_JOURNEY_SEARCH_PASSENGERS_DETAILS,
  passengerDetailsSearch(formFieldValueSelectorCreator(FORM_JOURNEY_SEARCH, 'passengers'), true)
)
registerFormType(
  FORM_REBOOK_SEARCH_PASSENGERS_DETAILS,
  passengerDetailsSearch(formFieldValueSelectorCreator(FORM_TYPE_REBOOK_JOURNEY_SEARCH, 'passengers'), true)
)
registerFormType(FORM_PRODUCT_SEARCH, productSearch)
registerFormType(FORM_JOURNEY_SEARCH, journeySearch)
registerFormType(FORM_TYPE_REBOOK_JOURNEY_SEARCH, rebookJourneySearch)
registerFormType(FORM_TYPE_AGENT_SHIFT_START, agentShiftStart)
registerFormType(FORM_CREDIT_DEBIT, creditDebit)
