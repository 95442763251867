import { CLEAR_STATE } from 's3p-js-lib/src/redux/actions/types'
import { USER_INPUT_PAYMENT_METHOD_SELECT, USER_INPUT_PAYMENT_METHOD_DESELECT } from '../../../../actions/types'
import { CONTAINERS_BASE_TILES_PAYMENT_METHODS } from '../../../../../constants'

const initialState = {
  paymentMethodCode: null,
  amountToBePaid: null,
  amountToBePaidGBP: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_INPUT_PAYMENT_METHOD_SELECT:
      return {
        ...state,
        paymentMethodCode: action.code,
        amountToBePaid: action.amountToBePaid,
        amountToBePaidGBP: action.amountToBePaidGBP
      }
    case USER_INPUT_PAYMENT_METHOD_DESELECT:
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_BASE_TILES_PAYMENT_METHODS) ? initialState : state
    default:
      return state
  }
}
