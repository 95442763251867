import {
  API_V2_CANCEL_BOOKING_REQUEST,
  API_V2_CANCEL_BOOKING_SUCCESS,
  API_V2_CANCEL_BOOKING_FAILURE
} from 's3p-js-lib/src/redux/actions/types-v2'
import Client from '../../../../../api/client'
import ensureToken from 's3p-js-lib/src/api/ensure-token'

const cancelBookingRequest = (bookingNumber, customer) => ({
  type: API_V2_CANCEL_BOOKING_REQUEST,
  bookingNumber,
  customer
})

const cancelBookingSuccess = response => ({
  type: API_V2_CANCEL_BOOKING_SUCCESS,
  response
})

const cancelBookingFailure = error => ({
  type: API_V2_CANCEL_BOOKING_FAILURE,
  error
})

export const cancelBooking = bookingNumber => ensureToken(async (token, dispatch) => {
  try {
    dispatch(cancelBookingRequest(bookingNumber))
    const response = await Client.cancelBooking(token, bookingNumber)
    dispatch(cancelBookingSuccess(response))
  } catch (error) {
    dispatch(cancelBookingFailure(error))
    throw error
  }
})
