import {
  USER_INPUT_FORM_TOUCH_FIELD,
  USER_INPUT_FORM_FOCUS_FIELD,
  USER_INPUT_FORM_BLUR_FIELD,
  USER_INPUT_FORM_CHANGE_FIELD,
  USER_INPUT_FORM_DESTROY,
  USER_INPUT_FORM_VALIDATE,
  USER_INPUT_FORM_RESET,
  USER_INPUT_FORM_LOAD_VALUES,
  USER_INPUT_FORM_UNTOUCH_FIELDS
} from '../../types'

import {
  formTypeSelectorCreator,
  formFieldValuesSelectorCreator,
  formFieldNormalizersSelectorCreator,
  formFieldContextSelectorCreator
} from '../../../selectors/user-input/base/form'
import storage from '../../../../storage'

// eslint-disable-next-line func-style
function getValueFromEventOrValue (eventOrValue) {
  let value = eventOrValue
  if (eventOrValue && eventOrValue.target) {
    if (eventOrValue.target.type && eventOrValue.target.type.toLowerCase() === 'checkbox') {
      value = eventOrValue.target.checked
    } else {
      value = eventOrValue.target.value
    }
  }

  return value
}

const normalizeValue = (value, fieldName, formName, state) => {
  const normalizers = formFieldNormalizersSelectorCreator(formName, fieldName)(state)
  const context = formFieldContextSelectorCreator(formName, fieldName)(state)

  return normalizers.reduce((value, normalizer) => normalizer(value, context), value)
}

// eslint-disable-next-line func-style
export function touchField (formName, fieldNames) {
  return {type: USER_INPUT_FORM_TOUCH_FIELD, formName, fieldNames}
}

// eslint-disable-next-line func-style
export function focusField (formName, fieldName) {
  return {type: USER_INPUT_FORM_FOCUS_FIELD, formName, fieldName}
}

// eslint-disable-next-line func-style
export function blurField (formName, fieldName, eventOrValue) {
  const action = {type: USER_INPUT_FORM_BLUR_FIELD, formName, fieldName}
  if (typeof eventOrValue !== 'undefined') {
    action.value = getValueFromEventOrValue(eventOrValue)
  }

  return action
}

// eslint-disable-next-line func-style
export function changeField (formName, fieldName, eventOrValue, otherValues) {
  const value = getValueFromEventOrValue(eventOrValue)
  return (dispatch, getState) => {
    dispatch({
      type: USER_INPUT_FORM_CHANGE_FIELD,
      formName,
      fieldName,
      value: normalizeValue(value, fieldName, formName, getState()),
      otherValues
    })
  }
}

// eslint-disable-next-line func-style
export function updateField (formName, fieldName, eventOrValue, otherValues) {
  return async (dispatch, getState) => {
    const formType = formTypeSelectorCreator(formName)(getState())
    await dispatch(changeField(formName, fieldName, eventOrValue, otherValues))

    if (formType.persistent) {
      persistForm(formName, formFieldValuesSelectorCreator(formName)(getState()))
    }
  }
}

// eslint-disable-next-line func-style
function persistForm (formName, values) {
  storage.set(`FORM[${formName}]`, values)
}

// eslint-disable-next-line func-style
export function destroy (formName) {
  return {type: USER_INPUT_FORM_DESTROY, formName}
}

// eslint-disable-next-line func-style
export function validate (formName) {
  const formTypeSelector = formTypeSelectorCreator(formName)
  return (dispatch, getState) => {
    dispatch({type: USER_INPUT_FORM_VALIDATE, formName, formType: formTypeSelector(getState())})
  }
}

// eslint-disable-next-line func-style
export function reset (formName) {
  return {type: USER_INPUT_FORM_RESET, formName}
}

// eslint-disable-next-line func-style
export function loadValues (formName, values) {
  const formTypeSelector = formTypeSelectorCreator(formName)
  return (dispatch, getState) => {
    dispatch({type: USER_INPUT_FORM_LOAD_VALUES, formName, values, formType: formTypeSelector(getState())})
  }
}

// eslint-disable-next-line func-style
export function unTouchFields (formName) {
  return {type: USER_INPUT_FORM_UNTOUCH_FIELDS, formName}
}
