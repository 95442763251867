import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import { MESSAGE_SEVERITY_WARNING, MESSAGE_SEVERITY_ERROR } from '../../../../s3p-js-lib/src/constants'
import { VOUCHER_TO_CASH_MODAL } from '../../../constants'
import Modal from '../../../elements/modal/modal'
import Button from '../../../elements/button/button'
import TextLabel from '../../../elements/text-label/text-label'
import DescriptionList from '../../../elements/description-list/description-list'
import Feedback, { FEEDBACK_SUCCESS, FEEDBACK_WARNING } from '../../../elements/feedback/feedback'
import LastApiErrorFeedback from '../../../containers/base/last-api-error-feedback'
import FeedbackMachine from '../../../containers/base/last-machine-error-feedback'
import './voucher-to-cash.scss'

const VOUCHER_RETRY_BUTTON_ID = 'voucher-retry'

export default class VoucherToCashModal extends Component {
  static propTypes = {
    selected: PropTypes.shape({
      code: PropTypes.string.isRequired,
      voucherType: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      amountUsed: PropTypes.number.isRequired
    }),
    error: PropTypes.shape({
      content: PropTypes.string
    }),
    deviceMessages: PropTypes.array.isRequired,
    hasDeviceMessages: PropTypes.bool,
    loading: PropTypes.bool,
    added: PropTypes.string,
    disabledReceipt: PropTypes.bool,
    onHideModal: PropTypes.func.isRequired,
    onPayOutVoucher: PropTypes.func.isRequired,
    onRetry: PropTypes.func.isRequired,
    onNextSale: PropTypes.func.isRequired
  }

  componentDidMount () {
    const element = document.getElementById(VOUCHER_RETRY_BUTTON_ID)
    element && setTimeout(() => element.focus(), 200)
  }

  render () {
    const {
      selected,
      error,
      deviceMessages,
      hasDeviceMessages,
      loading,
      added,
      disabledReceipt,
      onHideModal,
      onPayOutVoucher,
      onRetry,
      onNextSale
    } = this.props
    const voucherIsPayedOut = selected && selected.code === added

    return (
      <Modal
        name={VOUCHER_TO_CASH_MODAL}
        isCloseable={!loading}
        onHideElement={onHideModal}
        header={<Modal.Header disabled={loading} title={_t.message('voucher-to-cash.modal.title')} />}
        footer={
          <Modal.CustomFooter>
            <Button
              name='modal-cancel'
              className='cancel'
              onClick={onHideModal}
              disabled={disabledReceipt || loading || voucherIsPayedOut}
            >
              <TextLabel text={_t.message('voucher-to-cash.modal.button.cancel')} />
            </Button>
            <Button
              name='modal-retry-print-receipt'
              className='secondary'
              onClick={onRetry}
              disabled={loading || !voucherIsPayedOut}
            >
              <TextLabel name='retry-printing' text={_t.message('finalize-booking-modal.retry-receipt-printing')} />
            </Button>
            {added
              ? (
                <Button
                  name='modal-pay-out'
                  className='primary'
                  onClick={onNextSale}
                  disabled={Boolean(error)}
                >
                  <TextLabel text={_t.message('voucher-to-cash.modal.button.next-sale')} />
                </Button>
              ) : (
                <Button
                  id={VOUCHER_RETRY_BUTTON_ID}
                  name='modal-pay-out'
                  className='primary'
                  onClick={onPayOutVoucher}
                  loading={loading}
                  disabled={voucherIsPayedOut || Boolean(error)}
                >
                  <TextLabel text={_t.message('voucher-to-cash.modal.button.pay-out')} />
                </Button>
              )}
          </Modal.CustomFooter>
        }
      >
        <FeedbackMachine
          namespaces={['receiptPrinter']}
          severities={[MESSAGE_SEVERITY_WARNING, MESSAGE_SEVERITY_ERROR]}
        />
        <LastApiErrorFeedback
          defaultMessage={_t.message('voucher-to-cash.modal.default-error')}
          namespaces={['v2.createBooking', 'v2.addPayments', 'addVoucher']}
        />
        {hasDeviceMessages
          ? deviceMessages.map(device => device.messages.map((message, index) => (
            <Feedback
              key={`${device.id}|${index}`}
              status={FEEDBACK_WARNING}
              text={_t.message(
                'taskbar.diagnostics.message',
                {
                  name: device.name,
                  severity: message.severity,
                  message: message.translated ? message.message : _t.message(`machine.${message.message}`)
                }
              )}
            />
          )))
          : null}
        {error
          ? <Feedback status={FEEDBACK_WARNING} text={error.content} />
          : added
            ? <Feedback status={FEEDBACK_SUCCESS} text={_t.message('voucher-to-cash.modal.voucher-refunded')} />
            : null
        }
        <TextLabel name='information' text={_t.message('voucher-to-cash.modal.information')} />
        <DescriptionList>
          <dt><TextLabel text={_t.message('voucher-to-cash.modal.voucher-code')} /></dt>
          <dd><TextLabel text={selected.code} /></dd>
          <dt><TextLabel text={_t.message('voucher-to-cash.modal.type')} /></dt>
          <dd><TextLabel text={selected.voucherType} /></dd>
          <dt><TextLabel text={_t.message('voucher-to-cash.modal.amount-to-pay')} /></dt>
          <dd><TextLabel text={_t.formatCurrency(selected.amount - selected.amountUsed)} /></dd>
        </DescriptionList>
      </Modal>
    )
  }
}
