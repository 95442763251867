import Validator from './validator'
import { createSelector } from 'reselect'
import { formFieldValueSelectorCreator } from '../redux/selectors/user-input/base/form'
import { bookingSelector, paymentRequiredSelector } from '../redux/selectors/api/booking/booking'
import { PAYMENT_METHOD_FLOW_TYPE_CREDIT_CARD_ENCRYPTED } from '../constants'
import { FORM_PAYMENT_METHOD } from '../constants-form'

export default createSelector(
  [
    formFieldValueSelectorCreator(FORM_PAYMENT_METHOD, 'method'),
    paymentRequiredSelector(bookingSelector)
  ],
  (method, paymentRequired) => {
    const fields = {
      termsConditions: {
        validators: [Validator.isRequired]
      }
    }
    const subForms = {}

    if (paymentRequired) {
      fields.method = {
        validators: [Validator.isRequired]
      }

      if (method && method.flow_type === PAYMENT_METHOD_FLOW_TYPE_CREDIT_CARD_ENCRYPTED) {
        subForms.creditCard = {
          fields: {
            holderName: {
              validators: [Validator.isRequired]
            },
            cardNumber: {
              validators: [
                Validator.isRequired
              ]
            },
            cvvNumber: {
              validators: [
                Validator.isRequired,
                value => !value.match(/^\d{3}$/) ? 'invalid-value' : null
              ]
            },
            expiryMonth: {
              validators: [
                Validator.isRequired,
                value => value < 1 || value > 12 ? 'invalid-value' : null
              ]
            },
            expiryYear: {
              validators: [Validator.isRequired]
            }
          }
        }
      }
    }

    return {fields, subForms}
  }
)
