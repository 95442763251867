import { createSelector } from 'reselect'
import groupBy from 'lodash/groupBy'
import unzip from 'lodash/unzip'
import { toUtcDateMoment } from 's3p-js-lib/src/misc/date'
import { provisionalOrCompletedBookingSelector } from '../../api/booking/booking'
import { bookingPassengerSelector } from 's3p-js-lib/src/redux/selectors/api/booking/passengers'
import { stationsSelector } from 's3p-js-lib/src/redux/selectors/api/base/stations'

const _passengerSelector = bookingPassengerSelector(provisionalOrCompletedBookingSelector)

const findStation = (stations, UICStationCode) => stations.find(station => station.UICStationCode === UICStationCode)

export const itemsSelector = createSelector(
  [
    provisionalOrCompletedBookingSelector,
    _passengerSelector,
    stationsSelector
  ],
  (booking, passengers, stations) => {
    const items = []
    if (booking) {
      booking.outbound_booking_tariff_segments.forEach(tariffSegment => {
        const nonCancelledProducts = tariffSegment.required_products.concat(tariffSegment.additional_products)
          .filter(product => !product.cancelled)
        const groupedProducts = groupBy(nonCancelledProducts, 'code')
        Object.values(groupedProducts).forEach(productsByCode => {
          const groupedByPassengerGroup = unzip(Object.values(groupBy(productsByCode, 'passenger_id')))
          Object.values(groupedByPassengerGroup).forEach(products => {
            const productName = products[0].name
            const productSummary = products.reduce(
              (_productSummary, product) => {
                if (product) {
                  _productSummary.price = _productSummary.price + product.price
                  _productSummary.passengers.push(
                    passengers.find(passenger => passenger.id === product.passenger_id)
                  )
                  _productSummary.itemRefs.push(product.item_ref)
                }
                return _productSummary
              },
              {
                origin: tariffSegment.departure_station &&
                findStation(stations, tariffSegment.departure_station._u_i_c_station_code),
                destination: tariffSegment.arrival_station &&
                findStation(stations, tariffSegment.arrival_station._u_i_c_station_code),
                productName,
                validFrom: toUtcDateMoment(tariffSegment.validity_start_date),
                price: 0.0,
                passengers: [],
                itemRefs: []
              }
            )
            items.push(productSummary)
          })
        })
      })
    }

    return items
  }
)
