import { createSelector } from 'reselect'
import { SALES_CHANNEL_EXTERNAL_REFERENCE_TERMS_AND_CONDITIONS_KEY_NAME } from '../../../../constants'

export const termsAndConditionsURLSelector = createSelector(
  [state => state.api.user.user.sales_channel_meta_data],
  metaData => {
    const url = metaData && metaData.find(data => data.key === SALES_CHANNEL_EXTERNAL_REFERENCE_TERMS_AND_CONDITIONS_KEY_NAME)
    return url && url.value
  }
)
