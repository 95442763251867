import {
  API_GET_PAYMENT_METHODS_REQUEST,
  API_GET_PAYMENT_METHODS_SUCCESS,
  API_GET_PAYMENT_METHODS_FAILURE,
  CLEAR_STATE
} from '../../../actions/types'

export const initialState = null

export default function (state = initialState, action) {
  switch (action.type) {
    case API_GET_PAYMENT_METHODS_REQUEST:
    case API_GET_PAYMENT_METHODS_FAILURE:
      return initialState
    case API_GET_PAYMENT_METHODS_SUCCESS:
      return action.response
    case CLEAR_STATE:
      return action.namespaces.includes('api.payment.paymentMethods') ? initialState : state
    default:
      return state
  }
}
