import PropTypes from 'prop-types'
import React, { Component } from 'react'
import formContextAwareLabel from '../label/form-context-aware-label'
import formContextAwareLegend from '../legend/form-context-aware-legend'
import formContextAwareInputFeedback from '../input-feedback/form-context-aware-input-feedback'
import formContextAwareInput from '../input/form-context-aware-input'
import attachHotKey from '../input/attach-hotkey'
import Label from '../label/label'
import Legend from '../legend/legend'
import InputFeedback from '../input-feedback/input-feedback'
import Select from '../select/select'
import Input from '../input/input'
import CountrySelect from '../select/country-select-field'
import TitleSelect from '../select/title-select-field'
import DateTimePicker from '../date-time-picker/date-time-picker'
import Calendar from '../calendar/calendar'
import InputGroup from '../input/input-group'
import InputWrapper from '../input/input-wrapper'
import RadioGroup from '../checkbox-radiobutton/check-radio-group'
import StationSelect from '../station-select/station-select'
import GenderSelect from '../select/gender-select-field'
import CurrencyInput from '../currency-input/currency-input'
import './form.scss'

class Form extends Component {
  static propTypes = {
    formContext: PropTypes.string
  }

  static defaultProps = {
    onSubmit: event => {
      event.preventDefault()
      return false
    }
  }

  static childContextTypes = {
    formContext: PropTypes.string
  }

  getChildContext () {
    return {
      formContext: this.props.formContext
    }
  }

  render () {
    const {children, ...props} = this.props
    delete props.formContext

    return <form action='#' noValidate {...props}>{children}</form>
  }
}

Form.Label = formContextAwareLabel(Label)
Form.InputFeedback = formContextAwareInputFeedback(InputFeedback)
Form.Legend = formContextAwareLegend(Legend)
Form.TextField = attachHotKey(formContextAwareInput(Input.Text))
Form.EmailField = attachHotKey(formContextAwareInput(Input.Email))
Form.PasswordField = attachHotKey(formContextAwareInput(Input.Password))
Form.CurrencyField = formContextAwareInput(CurrencyInput)
Form.TextArea = attachHotKey(formContextAwareInput(Input.TextArea))
Form.Checkbox = attachHotKey(formContextAwareInput(Input.Checkbox))
Form.Select = attachHotKey(formContextAwareInput(Select))
Form.StationSelect = attachHotKey(formContextAwareInput(StationSelect))
Form.CountrySelect = formContextAwareInput(CountrySelect)
Form.TitleSelect = formContextAwareInput(TitleSelect)
Form.GenderSelect = formContextAwareInput(GenderSelect)
Form.Calendar = Calendar
Form.DatePicker = attachHotKey(formContextAwareInput(DateTimePicker))
Form.TriStateCheckbox = attachHotKey(Input.TriStateCheckbox)
Form.InputGroup = InputGroup
Form.InputWrapper = InputWrapper
Form.RadioGroup = RadioGroup

export default Form
