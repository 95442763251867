import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import HotkeyLabel from '../hotkey-label/hotkey-label'
import Hotkeys from '../utils/hotkeys'
import './link.scss'

export default class Link extends Component {
  static propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    hotkey: PropTypes.string
  }

  static defaultProps = {
    disabled: false
  }

  constructor (props, ...args) {
    super(props, ...args)
    this.onClick = this.onClick.bind(this)

    this.keyHandlers = {}
    if (props.hotkey) {
      this.keyHandlers[props.hotkey] = this.onClick
    }
  }

  onClick (event) {
    event.preventDefault()
    event.stopPropagation()
    if (!this.props.disabled) {
      if (this.props.onClick) {
        this.props.onClick(event)
      } else if (this.props.href) {
        window.open(this.props.href)
      }
    }
  }

  render () {
    const {className, href, name, children, hotkey, ...rest} = this.props
    const fullClassName = classNames('link', name ? `link-${name}` : '', className)

    const link = (
      <a {...rest} href={href || '#'} className={fullClassName} onClick={this.onClick}>
        {children}
        {hotkey ? <HotkeyLabel hotkey={hotkey} /> : null}
      </a>
    )

    return hotkey ? <Hotkeys handlers={this.keyHandlers} enabled={!this.props.disabled}>{link}</Hotkeys> : link
  }
}
