import {
  getCarriageLayoutsAftersales as baseGetCarriageLayoutsAftersales
} from 's3p-js-lib/src/redux/actions/api/base/seat-selector/carriage-layouts'

export const getCarriageLayoutsAftersales = tariffSegment => async dispatch => {
  if (!tariffSegment) {
    return
  }

  return dispatch(
    baseGetCarriageLayoutsAftersales(
      tariffSegment.validity_service,
      tariffSegment.departure_station._u_i_c_station_code,
      tariffSegment.arrival_station._u_i_c_station_code,
      tariffSegment.travel_date
    )
  )
}
