import React, { Component } from 'react'
import GridLayout from '../../../elements/grid/grid-layout'
import GridColumn from '../../../elements/grid/grid-column'
import MainMenu from '../../../containers/base/main-menu/main-menu'
import Taskbar from '../../../containers/base/taskbar/taskbar'
import GenericLayout from '../../generic-layout'
import AdditionalProductsContainer from '../../../containers/reservations/additional-products/additional-products'
import RebookingOperation from '../../../components/aftersales/rebooking/operation'
import StickyFooter from '../../../elements/sticky-footer/sticky-footer'
import OrientationSummary from '../../../components/reservations/orientation-summary'
import NextButton from '../../../containers/aftersales/rebooking/additional-products/next-button'
import BookingProgressBar from '../../../containers/aftersales/rebooking/progress-bar'

export default class AdditionalProductsLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <MainMenu />
            <BookingProgressBar />
            <RebookingOperation />
            <AdditionalProductsContainer />
            <StickyFooter>
              <OrientationSummary nextButton={<NextButton />} />
              <Taskbar />
            </StickyFooter>
          </GridColumn>
        </GridLayout>
      </GenericLayout>
    )
  }
}
