import { createSelector } from 'reselect'
import { ticketDetailsSelector as baseTicketDetailsSelector } from 's3p-js-lib/src/redux/selectors/api/v2/ticket-details'
import { validityWeekdaysToFullWeekdays } from '../../../../misc/utils'
import { camelCaseKeys } from 's3p-js-lib/src/misc/utils'

export const ticketDetailsSelector = createSelector(
  [baseTicketDetailsSelector],
  ticketDetails => {
    if (!ticketDetails) {
      return null
    }

    return {
      ...ticketDetails,
      validityFullWeekdays: validityWeekdaysToFullWeekdays(ticketDetails.validityWeekdays),
      validations: ticketDetails.validations.map(camelCaseKeys)
    }
  }
)
