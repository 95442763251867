import {
  MACHINE_PRINT_MAGNETIC_TICKET_REQUEST,
  MACHINE_PRINT_MAGNETIC_TICKET_SUCCESS,
  MACHINE_PRINT_MAGNETIC_TICKET_FAILURE,
  MACHINE_READ_MAGNETIC_TICKET_REQUEST,
  MACHINE_READ_MAGNETIC_TICKET_SUCCESS,
  MACHINE_READ_MAGNETIC_TICKET_FAILURE,
  MACHINE_EJECT_MAGNETIC_TICKET_REQUEST,
  MACHINE_EJECT_MAGNETIC_TICKET_SUCCESS,
  MACHINE_EJECT_MAGNETIC_TICKET_FAILURE,
  MACHINE_VOID_MAGNETIC_TICKET_REQUEST,
  MACHINE_VOID_MAGNETIC_TICKET_SUCCESS,
  MACHINE_VOID_MAGNETIC_TICKET_FAILURE,
  MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_REQUEST,
  MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_SUCCESS,
  MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_FAILURE,
  MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_REQUEST,
  MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_SUCCESS,
  MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_FAILURE,
  MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_REQUEST,
  MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_SUCCESS,
  MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_FAILURE,
  MACHINE_SET_DARKNESS_REQUEST,
  MACHINE_SET_DARKNESS_SUCCESS,
  MACHINE_SET_DARKNESS_FAILURE
} from '../types'
import DeviceManager from '../../../machine/device-manager'

const printMagneticTicketRequest = ticket => ({type: MACHINE_PRINT_MAGNETIC_TICKET_REQUEST, ticket})
const printMagneticTicketSuccess = response => ({type: MACHINE_PRINT_MAGNETIC_TICKET_SUCCESS, response})
const printMagneticTicketFailure = machineError => ({type: MACHINE_PRINT_MAGNETIC_TICKET_FAILURE, machineError})

const readMagneticTicketRequest = () => ({type: MACHINE_READ_MAGNETIC_TICKET_REQUEST})
const readMagneticTicketSuccess = response => ({type: MACHINE_READ_MAGNETIC_TICKET_SUCCESS, response})
const readMagneticTicketFailure = machineError => ({type: MACHINE_READ_MAGNETIC_TICKET_FAILURE, machineError})

const voidMagneticTicketRequest = () => ({type: MACHINE_VOID_MAGNETIC_TICKET_REQUEST})
const voidMagneticTicketSuccess = response => ({type: MACHINE_VOID_MAGNETIC_TICKET_SUCCESS, response})
const voidMagneticTicketFailure = machineError => ({type: MACHINE_VOID_MAGNETIC_TICKET_FAILURE, machineError})

const ejectMagneticTicketRequest = () => ({type: MACHINE_EJECT_MAGNETIC_TICKET_REQUEST})
const ejectMagneticTicketSuccess = response => ({type: MACHINE_EJECT_MAGNETIC_TICKET_SUCCESS, response})
const ejectMagneticTicketFailure = machineError => ({type: MACHINE_EJECT_MAGNETIC_TICKET_FAILURE, machineError})

const updateTemplateRequest = (id, content) => ({type: MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_REQUEST, id, content})
const updateTemplateSuccess = response => ({type: MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_SUCCESS, response})
const updateTemplateFailure = machineError => ({type: MACHINE_UPDATE_MAGNETIC_TICKET_TEMPLATE_FAILURE, machineError})

const getTemplatesRequest = () => ({type: MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_REQUEST})
const getTemplatesSuccess = response => ({type: MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_SUCCESS, response})
const getTemplatesFailure = machineError => ({type: MACHINE_GET_MAGNETIC_TICKET_TEMPLATES_FAILURE, machineError})

const removeTemplateRequest = id => ({type: MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_REQUEST, id})
const removeTemplateSuccess = response => ({type: MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_SUCCESS, response})
const removeTemplateFailure = machineError => ({type: MACHINE_REMOVE_MAGNETIC_TICKET_TEMPLATE_FAILURE, machineError})

const setDarknessRequest = data => ({type: MACHINE_SET_DARKNESS_REQUEST, data})
const setDarknessSuccess = response => ({type: MACHINE_SET_DARKNESS_SUCCESS, response})
const setDarknessFailure = machineError => ({type: MACHINE_SET_DARKNESS_FAILURE, machineError})

export const printMachineMagneticTicket = ticket => async dispatch => {
  try {
    dispatch(printMagneticTicketRequest(ticket))
    const response = await DeviceManager.printMagneticTicket(ticket)
    dispatch(printMagneticTicketSuccess(response))
  } catch (error) {
    dispatch(printMagneticTicketFailure(error))
    return false
  }
}

export const readMachineMagneticTicket = () => async dispatch => {
  try {
    dispatch(readMagneticTicketRequest())
    const response = await DeviceManager.readMagneticTicket()
    dispatch(readMagneticTicketSuccess(response))
  } catch (error) {
    dispatch(readMagneticTicketFailure(error))
    return false
  }
}

export const voidMachineMagneticTicket = () => async dispatch => {
  try {
    dispatch(voidMagneticTicketRequest())
    const response = await DeviceManager.voidMagneticTicket()
    dispatch(voidMagneticTicketSuccess(response))
  } catch (error) {
    dispatch(voidMagneticTicketFailure(error))
    return false
  }
}

export const ejectMachineMagneticTicket = () => async dispatch => {
  try {
    dispatch(ejectMagneticTicketRequest())
    const response = await DeviceManager.ejectMagneticTicket()
    dispatch(ejectMagneticTicketSuccess(response))
  } catch (error) {
    dispatch(ejectMagneticTicketFailure(error))
    return false
  }
}

export const updateMagneticTicketTemplate = (id, content) => async dispatch => {
  try {
    dispatch(updateTemplateRequest(id, content))
    const response = await DeviceManager.updateMagneticTicketTemplate(id, content)
    dispatch(updateTemplateSuccess(response))
  } catch (error) {
    dispatch(updateTemplateFailure(error))
    return false
  }
}

export const adjustDarkness = data => async dispatch => {
  try {
    dispatch(setDarknessRequest(data))
    const response = await DeviceManager.setDarkness(data)
    dispatch(setDarknessSuccess(response))
  } catch (error) {
    dispatch(setDarknessFailure(error))
    return false
  }
}

export const getMagneticTicketTemplates = () => async dispatch => {
  try {
    dispatch(getTemplatesRequest())
    const response = await DeviceManager.getMagneticTicketTemplates()
    dispatch(getTemplatesSuccess(response))
  } catch (error) {
    dispatch(getTemplatesFailure(error))
    return false
  }
}
export const removeMagneticTicketTemplate = id => async dispatch => {
  try {
    dispatch(removeTemplateRequest(id))
    const response = await DeviceManager.removeMagneticTicketTemplates(id)
    dispatch(removeTemplateSuccess(response))
  } catch (error) {
    dispatch(removeTemplateFailure(error))
    return false
  }
}
