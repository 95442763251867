import {USER_INPUT_WAIVE_FEES_SELECT, USER_INPUT_WAIVE_FEES_DESELECT} from '../../types'
import {waiveFeesSelector} from '../../../selectors/user-input/aftersales/waive-fees'

// eslint-disable-next-line func-style
export function selectWaiveFees () {
  return {type: USER_INPUT_WAIVE_FEES_SELECT}
}

// eslint-disable-next-line func-style
export function deselectWaiveFees () {
  return {type: USER_INPUT_WAIVE_FEES_DESELECT}
}

// eslint-disable-next-line func-style
export function toggleWaiveFees () {
  return (dispatch, getState) => dispatch(waiveFeesSelector(getState()) ? deselectWaiveFees() : selectWaiveFees())
}
