import PropTypes from 'prop-types'
import React from 'react'
import kebabCase from 'lodash/kebabCase'
import _t from 's3p-js-lib/src/translate'

export default Component => (class FormContextAwareLabel extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    text: PropTypes.string
  }

  static contextTypes = {
    formContext: PropTypes.string
  }

  render () {
    let {text, ...rest} = this.props
    if (typeof text === 'undefined') {
      text = _t.message(`${this.context.formContext}.form.${kebabCase(rest.name)}.label`)
    }

    return <Component text={text} {...rest} />
  }
})
