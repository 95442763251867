import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Form from '../../../../elements/form/form'
import Feedback, { FEEDBACK_WARNING } from '../../../../elements/feedback/feedback'
import Checkbox from './checkbox'

export default class CancelByDirection extends Component {
  static propTypes = {
    outboundCancellationData: PropTypes.object,
    inboundCancellationData: PropTypes.object,
    onToggleOutbound: PropTypes.func.isRequired,
    onToggleInbound: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    message: PropTypes.string
  }

  render () {
    const {outboundCancellationData, inboundCancellationData, disabled, message} = this.props

    return outboundCancellationData && inboundCancellationData
      ? (
        <fieldset>
          <Form.Legend name='direction' />
          {message ? <Feedback status={FEEDBACK_WARNING} text={message} /> : null}
          <Form.InputGroup name='outbound'>
            <Form.InputWrapper name='outbound'>
              <Checkbox
                id='outbound'
                name='outbound'
                onToggle={this.props.onToggleOutbound}
                cancellationData={outboundCancellationData}
                disabled={disabled}
              />
            </Form.InputWrapper>
          </Form.InputGroup>
          <Form.InputGroup name='inbound'>
            <Form.InputWrapper name='inbound'>
              <Checkbox
                id='inbound'
                name='inbound'
                onToggle={this.props.onToggleInbound}
                cancellationData={inboundCancellationData}
                disabled={disabled}
              />
            </Form.InputWrapper>
          </Form.InputGroup>
        </fieldset>
      ) : null
  }
}
