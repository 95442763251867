import {connect} from 'react-redux'
import TilesWithModal, {MORE_MODAL} from '../../../components/base/tiles/tiles-with-modal'
import {showModal, hideElement} from '../../../redux/actions/containers/base/visible-element'

export const getModalName = name => `${MORE_MODAL}-${name}`

const mapStateToProps = (state, ownProps) => {
  const modalState = state.containers.base.elementVisibility[getModalName(ownProps.name)]
  return {
    showMoreModal: Boolean(modalState && modalState.visible)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleShowModal: () => dispatch(showModal(getModalName(ownProps.name))),
  handleHideModal: () => dispatch(hideElement(getModalName(ownProps.name)))
})

export default connect(mapStateToProps, mapDispatchToProps)(TilesWithModal)
