import React, {Component} from 'react'
import Login from '../../containers/agent/login/login'
import BasicLayout from '../../components/base/basic-layout'

export default class AgentLoginLayout extends Component {
  render () {
    return (
      <BasicLayout>
        <Login />
      </BasicLayout>
    )
  }
}
