import {
  USER_INPUT_ENABLE_JOURNEY_SEARCH_BIKE_FILTER,
  USER_INPUT_DISABLE_JOURNEY_SEARCH_BIKE_FILTER
} from '../../../../redux/actions/types'
import {
  API_REBOOKING_INITIATE_SUCCESS,
  CLEAR_STATE
} from 's3p-js-lib/src/redux/actions/types'
import { PRODUCT_TYPE_BIKE } from 's3p-js-lib/src/constants'
import { CONTAINERS_RESERVATIONS_PASSENGER_PREFERENCES } from '../../../../constants'

const defaultState = {
  isEnabledBikeFilter: false
}

const hasSegmentBikeProducts = segment => segment.additional_products.some(
  ({type, cancelled}) => !cancelled && type === PRODUCT_TYPE_BIKE
)

const hasBookingBikeProducts = booking =>
  booking.outbound_booking_tariff_segments.some(hasSegmentBikeProducts) ||
booking.inbound_booking_tariff_segments.some(hasSegmentBikeProducts)

export default function passengerPreferences (state = defaultState, action) {
  switch (action.type) {
    case USER_INPUT_ENABLE_JOURNEY_SEARCH_BIKE_FILTER:
      return {...state, isEnabledBikeFilter: true}
    case USER_INPUT_DISABLE_JOURNEY_SEARCH_BIKE_FILTER:
      return {...state, isEnabledBikeFilter: false}
    case API_REBOOKING_INITIATE_SUCCESS:
      return {...state, isEnabledBikeFilter: hasBookingBikeProducts(action.response.data.booking)}
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_RESERVATIONS_PASSENGER_PREFERENCES) ? defaultState : state
    default:
      return state
  }
}
