import React, { Component } from 'react'
import GenericLayout from './generic-layout'
import MainMenu from '../containers/base/main-menu/main-menu'
import GridLayout from '../elements/grid/grid-layout'
import GridColumn from '../elements/grid/grid-column'
import LeapReadCard from '../containers/leap/leap-read-card'
import LeapProducts from '../containers/leap/leap-products'
import StickyFooter from '../elements/sticky-footer/sticky-footer'
import Taskbar from '../containers/base/taskbar/taskbar'

export default class LeapLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <MainMenu />
            <GridLayout name='left-right'>
              <GridColumn name='left'>
                <LeapReadCard />
              </GridColumn>
              <GridColumn name='right'>
                <LeapProducts />
              </GridColumn>
            </GridLayout>
            <StickyFooter>
              <Taskbar />
            </StickyFooter>
          </GridColumn>
        </GridLayout>
      </GenericLayout>
    )
  }
}
