import React, { Component } from 'react'
import Notes from '../../../containers/aftersales/booking/notes'
import RelatedVouchers from '../../../containers/aftersales/booking/related-vouchers'
import ComponentContainer from '../../../elements/component-container/component-container'
import './notes-related-vouchers.scss'

export default class NotesRelatedVouchers extends Component {
  render () {
    return (
      <ComponentContainer name='booking-notes-related-vouchers'>
        <Notes />
        <RelatedVouchers />
      </ComponentContainer>
    )
  }
}
