import { createSelector } from 'reselect'
import { camelCaseKeys, getUniqueLegIdentifier } from '../../../../misc/utils'
import { stationMapper } from './stations'
import sortBy from 'lodash/sortBy'

export const baseOfferSelector = state => state.api.orientation.offer

export const travelsSelector = createSelector(
  baseOfferSelector,
  offer => offer ? offer.travels : []
)

export const travelByIdSelector = _id => createSelector(
  travelsSelector,
  travels => travels.find(({id}) => id === _id)
)

export const routesSelector = createSelector(
  [travelsSelector],
  travels => travels && travels.reduce(
    (routes, travel) => routes.concat(travel.routes.map(route => ({
      ...camelCaseKeys(route),
      travelId: travel.id
    }))),
    []
  )
)

export const routeByIdSelector = _id => createSelector(
  routesSelector,
  routes => routes.find(({id}) => id === _id)
)

export const bundlesSelector = createSelector(
  [routesSelector],
  routes => routes && routes.reduce(
    (bundles, route) => bundles.concat(route.bundles.map(bundle => ({
      ...camelCaseKeys(bundle),
      travelId: route.travelId,
      routeId: route.id
    }))),
    []
  )
)

export const bundleByIdSelector = _id => createSelector(
  bundlesSelector,
  bundles => bundles.find(({id}) => id === _id)
)

export const bundleAndBundleItemByItemIdSelector = itemId => createSelector(
  bundlesSelector,
  bundles => bundles.reduce(
    (result, bundle) => {
      if (result.bundleItem) {
        return result
      }
      const matchedItem = bundle.items.find(item => item.id === itemId)
      if (matchedItem) {
        return {bundle, bundleItem: matchedItem}
      }
      return result
    },
    {bundle: undefined, bundleItem: undefined}
  )
)

export const requiredBundleItemByLegIdSelector = legId => createSelector(
  bundlesSelector,
  bundles => bundles.flatMap(bundle => bundle.items).find(item => item.leg_ids.includes(legId) && item.required)
)

export const passengersSelector = createSelector(
  [state => state.api.orientation.offer && state.api.orientation.offer.passengers],
  passengers => passengers ? passengers.reduce(
    (passengers, passenger, index) => {
      passengers[passenger.id] = {
        ...passenger,
        number: index + 1
      }
      return passengers
    },
    {}
  ) : null
)

export const legMapper = leg => {
  const camelCaseLeg = camelCaseKeys(leg)
  camelCaseLeg.departureStation = stationMapper(camelCaseLeg.departureStation)
  camelCaseLeg.arrivalStation = stationMapper(camelCaseLeg.arrivalStation)
  camelCaseLeg.availability = camelCaseLeg.availability.map(camelCaseKeys)
  camelCaseLeg.uniqueIdentifier = getUniqueLegIdentifier(camelCaseLeg)
  return camelCaseLeg
}

export const legsSelector = createSelector(
  [routesSelector],
  routes => routes && routes.reduce(
    (legs, route) => {
      legs.push(...route.legs.map(leg => ({
        ...legMapper(leg),
        routeId: route.id,
        travelId: route.travelId
      })))
      return legs
    }, [])
)

export const legsByRouteIdSelector = routeId => createSelector(
  routeByIdSelector(routeId),
  route => route?.legs || []
)

export const firstAndLastLegsByIdsSelector = legIds => createSelector(
  legsSelector,
  legs => {
    const sortedLegs = sortBy(legs.filter(leg => legIds.includes(leg.id)), ['departure_station.departure_timestamp'])
    return sortedLegs.length ? [sortedLegs[0], sortedLegs[sortedLegs.length - 1]] : []
  }
)

export const passengerByIdSelector = passengerId => createSelector(
  baseOfferSelector,
  offer => offer.passengers.find(passenger => passenger.id === passengerId)
)

export const inventoryClassBySegmentIdAndPassengerIdSelector = (legId, passengerId) => createSelector(
  requiredBundleItemByLegIdSelector(legId),
  bundleItem => bundleItem?.passenger_fares.find(fare => fare.passenger_id === passengerId)?.inventory_class
)
