import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../../../../elements/text-label/text-label'
import Icon from '../../../../../elements/icon/icon'
import _t from 's3p-js-lib/src/translate'
import Table from '../../../../../elements/table/table'
import TableHeader from '../../../../../elements/table/table-header'
import Dropdown from '../../../../../elements/dropdown/dropdown'
import ProductDetailsRow from './product-details-row'
import DiscountDetailsRow from './discount-details-row'

export default class SegmentPassengerRow extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    additionalProductsString: PropTypes.string,
    cancelled: PropTypes.bool.isRequired,
    seat: PropTypes.shape({
      number: PropTypes.string.isRequired,
      carriage: PropTypes.string.isRequired
    }),
    products: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      required: PropTypes.bool.isRequired
    })).isRequired,
    discounts: PropTypes.array.isRequired,
    totalDiscountPrice: PropTypes.number.isRequired,
    totalPrice: PropTypes.number.isRequired,
    currency: PropTypes.string
  }

  render () {
    return (
      <tr className={this.props.cancelled ? 'state--is-cancelled' : ''}>
        <td data-th-label={_t.message(`aftersales.booking.journey-overview.passenger`)}>
          <TextLabel name='passenger-full-name' className='has-tooltip'>
            <Icon name='adult' className='align-left' />
            <TextLabel text={this.props.firstName} />
          </TextLabel>
          <TextLabel
            name='passenger-type'
            text={_t.message(`aftersales.booking.journey-overview.passenger-type`, {type: this.props.type})}
          />
        </td>
        {this.props.seat
          ? (
            <td data-th-label='Seat'>
              <TextLabel
                name='coach-number'
                text={_t.message(`aftersales.booking.journey-overview.coach`, this.props.seat)}
              />
              <TextLabel
                name='seat-number'
                text={_t.message(`aftersales.booking.journey-overview.seat`, this.props.seat)}
              />
            </td>
          ) : <td data-th-label='Seat'>&nbsp;</td>}
        <td data-th-label='Add-ons'>
          <TextLabel name='additional-products' text={this.props.additionalProductsString} />
        </td>
        <td data-th-label='Price'>
          <TextLabel name='price'>
            <Dropdown
              name={`booking-details.product-details-tooltip_${this.props.id}`}
              view={
                <Dropdown.View name={`booking-details.product-details-tooltip_${this.props.id}`} hideHeader>
                  <Table name='product-details' numColumns={3} lastCellsAlignRight>
                    <thead>
                      <tr>
                        <TableHeader
                          text={_t.message('aftersales.booking.journey-overview.price-details-tooltip.product')}
                        />
                        <TableHeader
                          text={_t.message('aftersales.booking.journey-overview.price-details-tooltip.tariff')}
                        />
                        <TableHeader
                          text={_t.message('aftersales.booking.journey-overview.price-details-tooltip.price')}
                        />
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.products.map(
                        product => <ProductDetailsRow key={`${this.props.id}-${product.code}`} {...product} />
                      )}
                    </tbody>
                    <tfoot>
                      {this.props.discounts.length
                        ? (
                          <DiscountDetailsRow
                            discounts={this.props.discounts}
                            totalDiscountPrice={this.props.totalDiscountPrice}
                            currency={this.props.currency}
                          />
                        ) : null}
                      <tr>
                        <td>&nbsp;</td>
                        <td data-th-label={_t.message(
                          'aftersales.booking.journey-overview.price-details-tooltip.total-passenger',
                          {name: this.props.firstName}
                        )}>
                          <TextLabel
                            name='segment-total-description'
                            text={_t.message(
                              'aftersales.booking.journey-overview.price-details-tooltip.total-passenger',
                              {name: this.props.firstName}
                            )}
                          />
                        </td>
                        <td data-th-label='Price'>
                          <TextLabel
                            name='segment-total-price'
                            text={_t.formatCurrency(this.props.totalPrice, this.props.currency)}
                          />
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </Dropdown.View>
              }
            >
              <Icon name='information' className='small align-left has--tooltip' />
            </Dropdown>
            <TextLabel text={_t.formatCurrency(this.props.totalPrice, this.props.currency)} />
          </TextLabel>
        </td>
      </tr>
    )
  }
}
