import Client from '../../../api/client'
import {
  API_IS_BACK_ONLINE,
  API_IS_OFFLINE,
  API_PING_FAILURE,
  API_PING_REQUEST,
  API_PING_SUCCESS,
  API_RETRY_ATTEMPT,
  API_RETRY_FINISHED
} from '../types'

const pingRequest = () => ({type: API_PING_REQUEST})
const pingSuccess = response => ({type: API_PING_SUCCESS, response})
const pingFailure = error => ({type: API_PING_FAILURE, error})

export const apiRetryFinished = resolution => ({type: API_RETRY_FINISHED, isSuccess: !(resolution instanceof Error)})
export const apiRetryAttempt = (attempt, nextTry, feedbackMessage) => (
  {type: API_RETRY_ATTEMPT, attempt, nextTry, feedbackMessage}
)
export const apiIsOffline = () => ({type: API_IS_OFFLINE})
export const apiIsBackOnline = () => ({type: API_IS_BACK_ONLINE})

export const checkIsOnline = () => async dispatch => {
  try {
    dispatch(pingRequest())
    const response = await Client.ping()
    dispatch([
      pingSuccess(response),
      apiIsBackOnline()
    ])
  } catch (error) {
    dispatch(pingFailure(error))
    throw error
  }
}
