import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _t from 's3p-js-lib/src/translate'
import classNames from 'classnames'
import { hideElement } from '../../redux/actions/containers/base/visible-element'
import Closable from '../utils/closable'
import RenderOutsideApp from '../utils/render-outside-app'
import FocusTrap from '../utils/focus-trap'
import TextLabel from '../text-label/text-label'
import Button from '../button/button'
import Icon from '../icon/icon'
import './modal.scss'

/**
 * Usage
 *
 * import containVisibleElement from 'containers/base/visible-element';
 * Use this component to wrap the component where you need a modal
 * or use customMapStateToProps & mapDispatchToProps for this
 *
 * {props.elementVisibility['hello-modal'] ? <Modal
 *    name="modal"
 *    header={<Modal.Header title="Hello" />}
 *
 *   <p>Children</p>
 * </Modal> : null}
 *
 * <Button onClick={event => props.onShowElement('hello-modal')} text="Open" />
 */

class Title extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired
  }

  render () {
    return (
      <div className='modal__header'>
        <TextLabel
          name='heading'
          text={this.props.title}
        />
        {this.props.children}
      </div>
    )
  }
}

class Header extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    onHideElement: PropTypes.func,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    disabled: false
  }

  render () {
    return (
      <Title title={this.props.title}>
        <Button
          name='modal-close'
          className='secondary'
          title={_t.message('modal.close')}
          aria-label={_t.message('modal.close')}
          onClick={this.props.onHideElement}
          disabled={this.props.disabled}
        >
          <TextLabel aria-hidden='true'>
            <Icon name='cross' className='align-left' />
            <TextLabel.Text text={_t.message('modal.close')} />
          </TextLabel>
        </Button>
      </Title>
    )
  }
}

class ButtonGroup extends Component {
  render () {
    return (
      <div className='modal__footer'>
        <div className='button-group'>
          {this.props.children}
        </div>
      </div>
    )
  }
}

class Footer extends Component {
  static propTypes = {
    onHideElement: PropTypes.func,
    onContinue: PropTypes.func.isRequired,
    isLoadingContinue: PropTypes.bool,
    isLoadingCancel: PropTypes.bool,
    labelCancel: PropTypes.string,
    labelContinue: PropTypes.string
  }

  render () {
    return (
      <ButtonGroup>
        <Button
          name='modal-cancel'
          className='cancel'
          disabled={this.props.isLoadingContinue}
          loading={this.props.isLoadingCancel}
          onClick={this.props.onHideElement}
        >
          <TextLabel text={_t.message(this.props.labelCancel || 'modal.cancel')} />
        </Button>
        <Button
          name='modal-continue'
          className='primary'
          disabled={this.props.isLoadingCancel}
          loading={this.props.isLoadingContinue}
          onClick={this.props.onContinue}
        >
          <TextLabel text={_t.message(this.props.labelContinue || 'modal.continue')} />
        </Button>
      </ButtonGroup>
    )
  }
}

class FooterSingle extends Component {
  static propTypes = {
    onHideElement: PropTypes.func,
    onClick: PropTypes.func
  }

  render () {
    const props = {...this.props}
    delete props.onHideElement
    return (
      <ButtonGroup>
        <Button
          {...props}
          name='modal-continue'
          className='primary'
          onClick={this.props.onClick || this.props.onHideElement}
        >
          {this.props.children}
        </Button>
      </ButtonGroup>
    )
  }
}

export class ModalElement extends Component {
  constructor (props, ...args) {
    super(props, ...args)

    this.onHideElement = props.onHideElement.bind(null, props.name, {isModal: true})
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    onHideElement: PropTypes.func.isRequired,
    header: PropTypes.element.isRequired,
    footer: PropTypes.element,
    className: PropTypes.string,
    style: PropTypes.object,
    isCloseable: PropTypes.bool
  }

  static defaultProps = {
    isCloseable: true
  }

  componentDidMount () {
    document.body.classList.add('state--is-locked')
  }

  componentWillUnmount () {
    document.body.classList.remove('state--is-locked')
  }

  render () {
    const {header, footer, children, name, className, style, isCloseable} = this.props
    const classes = classNames('modal', `modal-${name}`, className)
    const content = (
      <react-wrapper>
        {header ? React.cloneElement(header, {onHideElement: this.onHideElement}) : null}
        <div className='modal__content'>{children}</div>
        {footer ? React.cloneElement(footer, {onHideElement: this.onHideElement}) : null}
      </react-wrapper>
    )

    return (
      <RenderOutsideApp>
        <FocusTrap>
          <div
            className={classes}
            role='dialog'
            aria-labelledby='modal-heading'
            aria-live='assertive'
            style={style}
          >
            <div className='modal__panel'>
              {isCloseable
                ? <Closable handleHideElement={this.onHideElement} name={name}>{content}</Closable>
                : content
              }
            </div>
            <div className='modal__overlay' />
          </div>
        </FocusTrap>
      </RenderOutsideApp>
    )
  }
}

const onHideElement = dispatch => (name, options) => dispatch(hideElement(name, options))
const mapDispatchToProps = (dispatch, ownProps) => ({
  onHideElement: ownProps.onHideElement || onHideElement(dispatch)
})

const Modal = connect(null, mapDispatchToProps)(ModalElement)

Modal.Title = Title
Modal.Header = Header
Modal.Footer = Footer
Modal.FooterSingle = FooterSingle
Modal.CustomFooter = ButtonGroup

export default Modal
