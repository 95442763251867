import { CLEAR_STATE } from 's3p-js-lib/src/redux/actions/types'
import {
  API_GET_MAGSTRIPE_TICKETS_REQUEST,
  API_GET_MAGSTRIPE_TICKETS_SUCCESS,
  API_GET_MAGSTRIPE_TICKETS_FAILURE
} from '../../../actions/types'

const initialState = {bookingNumber: null, tickets: []}

export default (state = initialState, action) => {
  switch (action.type) {
    case API_GET_MAGSTRIPE_TICKETS_REQUEST:
    case API_GET_MAGSTRIPE_TICKETS_FAILURE:
      return initialState
    case API_GET_MAGSTRIPE_TICKETS_SUCCESS:
      return {bookingNumber: action.response.booking_number, tickets: action.response.tickets}
    case CLEAR_STATE:
      return action.namespaces.includes('api.modules.magstripe') ? initialState : state
    default:
      return state
  }
}
