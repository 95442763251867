import {
  API_ADDITIONAL_PRODUCTS_UPDATE_REQUEST,
  API_ADDITIONAL_PRODUCTS_UPDATE_SUCCESS,
  API_ADDITIONAL_PRODUCTS_UPDATE_FAILURE
} from '../../../types'
import Client from '../../../../../api/client'
import ensureToken from '../../../../../api/ensure-token'
import { afterSalesAdditionalProductsSelector } from '../../../../selectors/api/aftersales/additional-products'
import { bookingSelector } from '../../../../selectors/api/aftersales/booking'
import ResponseCodes from '../../../../../api/response-codes'

const additionalProductsInitiateRequest = (productIds, quantity) => ({
  type: API_ADDITIONAL_PRODUCTS_UPDATE_REQUEST,
  productIds,
  quantity
})
const additionalProductsInitiateSuccess = response => ({type: API_ADDITIONAL_PRODUCTS_UPDATE_SUCCESS, response})
const additionalProductsInitiateFailure = error => ({type: API_ADDITIONAL_PRODUCTS_UPDATE_FAILURE, error})

export const additionalProductsUpdate = (productIds, quantity) => ensureToken(async (token, dispatch, getState) => {
  try {
    dispatch(additionalProductsInitiateRequest(productIds, quantity))
    const booking = bookingSelector(getState())
    const afterSalesSegmentsForUpdateAndAdditionalProducts = reduceSegmentsAndUpdateAdditionalProducts(
      booking,
      afterSalesAdditionalProductsSelector(getState()),
      productIds,
      quantity
    )
    let response
    if (afterSalesSegmentsForUpdateAndAdditionalProducts.existingProvisionalAddonProductRefsToRemove.length) {
      response = await Client.removeItems(
        token,
        booking.booking_number,
        afterSalesSegmentsForUpdateAndAdditionalProducts.existingProvisionalAddonProductRefsToRemove
      )
    }
    if (afterSalesSegmentsForUpdateAndAdditionalProducts.segments.length) {
      response = await Client.additionalProductsUpdate(
        token,
        booking.booking_number,
        afterSalesSegmentsForUpdateAndAdditionalProducts.segments
      )
    }

    const result = {
      data: {
        new_booking: response ? response.data.booking : booking,
        additional_products: afterSalesSegmentsForUpdateAndAdditionalProducts.additionalProducts
      }
    }

    dispatch(additionalProductsInitiateSuccess(result))
  } catch (error) {
    dispatch(additionalProductsInitiateFailure(error))
    if (ResponseCodes.isSystemError(error)) {
      throw error
    }
    return false
  }
})

const reduceSegmentsAndUpdateAdditionalProducts = (booking, additionalProducts, productIds, quantity) => additionalProducts.reduce(
  (result, additionalProduct) => {
    if (productIds.includes(additionalProduct.id)) {
      if (quantity === 0) {
        result.existingProvisionalAddonProductRefsToRemove = getExistingProvisionalAddonProductRefIdsToRemove(
          additionalProduct,
          booking
        )
      } else if (quantity === 1) {
        const existingSegment = result.segments.find(segment => segment.id === additionalProduct.segment.id)
        if (existingSegment) {
          existingSegment.items.push({
            passenger_id: additionalProduct.passenger.id,
            tariff_code: additionalProduct.tariff_code
          })
        } else {
          result.segments.push(mapSegment(additionalProduct))
        }
      }
      additionalProduct.new_quantity = quantity
    }
    result.additionalProducts.push(additionalProduct)

    return result
  },
  {additionalProducts: [], segments: [], existingProvisionalAddonProductRefsToRemove: []}
)

const getExistingProvisionalAddonProductRefIdsToRemove = (additionalProduct, booking) =>
  booking[
    additionalProduct.segment.direction === 'outward'
      ? 'outbound_booking_tariff_segments' : 'inbound_booking_tariff_segments'
  ]
    .find(tariffSegment => tariffSegment.id === additionalProduct.segment.id)
    ?.additional_products.filter(
      existingProvisionalAddon =>
        existingProvisionalAddon.tariff_code === additionalProduct.tariff_code &&
        existingProvisionalAddon.passenger_id === additionalProduct.passenger.id &&
        existingProvisionalAddon.code === additionalProduct.product.code &&
        existingProvisionalAddon.provisional
    ).map(existingProvisionalAddon => existingProvisionalAddon.item_ref) || []

const mapSegment = additionalProduct => ({
  id: additionalProduct.segment.id,
  destination: additionalProduct.segment.destination.code,
  direction: ['outward', 'outbound'].includes(additionalProduct.segment.direction) ? 'outbound' : 'inbound',
  origin: additionalProduct.segment.origin.code,
  start_validity_date: additionalProduct.segment.validity_start_date,
  items: [{
    passenger_id: additionalProduct.passenger.id,
    tariff_code: additionalProduct.tariff_code
  }],
  ...(additionalProduct.segment.service_name && additionalProduct.segment.service_identifier)
    ? {
      service_name: additionalProduct.segment.service_name,
      service_identifier: additionalProduct.segment.service_identifier
    } : {}
})
