/* globals S3P_SETTINGS: true */

import { createSelector } from 'reselect'
import { bookingPassengerSelector } from '../booking/passengers'

const hasGroupFirstName = passengers => passengers && passengers.every(passenger =>
  passenger.first_name === S3P_SETTINGS.s3Passenger.features.passengerDetails.groupBookingFirstName
)

export const groupNameSelector = bookingSelector => createSelector(
  [bookingPassengerSelector(bookingSelector)],
  passengers => {
    if (!passengers || !hasGroupFirstName(passengers)) {
      return null
    }

    const lastNames = passengers.reduce(
      (names, passenger) => {
        const lastName = passenger.last_name.match(/(.+)(?=\s[0-9]{1,2})/g)

        return lastName && names.indexOf(lastName[0]) === -1 ? names.concat([lastName[0]]) : names
      },
      []
    )

    return lastNames.length === 1 ? lastNames[0] : null
  }
)
