import {routerActions} from 'react-router-redux'
import _t from 's3p-js-lib/src/translate'
import {agentLogin as baseAgentLogin} from '../../api/auth/agent-login'
import {hasAgentRoleSelector} from 's3p-js-lib/src/redux/selectors/api/user/agent'
import {oauthLogout} from 's3p-js-lib/src/redux/actions/api/auth/logout'
import {
  UI_AGENT_HAS_INVALID_ROLES,
  UI_AGENT_INCORRECT_CONFIGURATION
} from '../../types'
import {AGENT_ROLE_OPERATOR, AGENT_ROLE_TECHNICIAN} from '../../../../constants'
import {userLogout} from '../../api/v2/user/logout'
import {getAgentShift, headAgentShift} from 's3p-js-lib/src/redux/actions/api/user/agent/shift'
import { clearLastApiErrors } from '../../../../../s3p-js-lib/src/redux/actions/api/base/last-api-errors'

const _hasAgentRoleOperatorSelector = hasAgentRoleSelector(AGENT_ROLE_OPERATOR)
const _hasAgentRoleTechnicianSelector = hasAgentRoleSelector(AGENT_ROLE_TECHNICIAN)

const agentInvalidRoles = () => ({type: UI_AGENT_HAS_INVALID_ROLES})
const agentIncorrectConfiguration = () => ({type: UI_AGENT_INCORRECT_CONFIGURATION})

export const agentLogin = (username, password) => async (dispatch, getState) => {
  const result = await dispatch(baseAgentLogin(username, password))
  if (result) {
    dispatch(clearLastApiErrors())
    const isOperator = _hasAgentRoleOperatorSelector(getState())
    const isTechnician = _hasAgentRoleTechnicianSelector(getState())

    if (!isOperator && !isTechnician) {
      dispatch(agentInvalidRoles())
      dispatch(agentLogout())
    } else if (isOperator && isTechnician) {
      dispatch(agentIncorrectConfiguration())
    } else {
      const response = await dispatch(headAgentShift())
      response && await dispatch(getAgentShift())
      dispatch(routerActions.push(`/${_t.getLocales()}/agent/shift`))
    }
  }
}

export const agentLogout = () => async dispatch => {
  await dispatch(userLogout())
  dispatch(oauthLogout())
}
