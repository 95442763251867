import {fulfillmentMethodSelector} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import {availableFulfillmentMethodsSelector} from 's3p-js-lib/src/redux/selectors/api/booking/fulfillment-methods'
import { createSelector } from 'reselect'

export const hasValidFulfillmentMethodSelector = bookingSelector => createSelector(
  [
    availableFulfillmentMethodsSelector(bookingSelector),
    fulfillmentMethodSelector(bookingSelector)
  ],
  (availableFulfillmentMethods, bookingFulfillmentMethod) => {
    if (!(availableFulfillmentMethods && bookingFulfillmentMethod)) {
      return false
    }
    return availableFulfillmentMethods.some(fulfillmentMethod => fulfillmentMethod.code === bookingFulfillmentMethod.code)
  }
)
