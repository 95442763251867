import composeReducers from '../../../lib/compose-reducers'
import formReducer from '../../../lib/form-reducer'
import journeySearchFormReducer from './orientation/journey-search'
import rebookJourneySearchFormReducer from './aftersales/rebook-journey-search'
import passengerDetailsForm from './orientation/passenger-details'
import vouchersFormReducer from './booking/vouchers'
import {
  FORM_AGENT_CHANGE_PASSWORD,
  FORM_AGENT_LOGIN,
  FORM_TYPE_AGENT_MODIFY,
  FORM_TYPE_AGENT_SHIFT_NOTE,
  FORM_TYPE_AGENT_SHIFT_START,
  FORM_BOOKING_NOTE,
  FORM_BOOKINGS_FILTER,
  FORM_CUSTOMER_DETAILS,
  FORM_FIND_CUSTOMERS,
  FORM_JOURNEY_SEARCH,
  FORM_PASSENGER_DETAILS,
  FORM_PASSENGER_DETAILS_AFTERSALES,
  FORM_PAYMENT_METHOD,
  FORM_PRODUCT_SEARCH,
  FORM_TYPE_REBOOK_JOURNEY_SEARCH,
  FORM_TYPE_REDEEM_VOUCHER,
  FORM_TYPE_VOUCHER_SEARCH
} from '../../../../constants-form'

export default {
  [FORM_AGENT_CHANGE_PASSWORD]: formReducer(FORM_AGENT_CHANGE_PASSWORD),
  [FORM_AGENT_LOGIN]: formReducer(FORM_AGENT_LOGIN),
  [FORM_TYPE_AGENT_MODIFY]: formReducer(FORM_TYPE_AGENT_MODIFY),
  [FORM_TYPE_AGENT_SHIFT_NOTE]: formReducer(FORM_TYPE_AGENT_SHIFT_NOTE),
  [FORM_TYPE_AGENT_SHIFT_START]: formReducer(FORM_TYPE_AGENT_SHIFT_START),
  [FORM_BOOKING_NOTE]: formReducer(FORM_BOOKING_NOTE),
  [FORM_BOOKINGS_FILTER]: formReducer(FORM_BOOKINGS_FILTER),
  [FORM_CUSTOMER_DETAILS]: formReducer(FORM_CUSTOMER_DETAILS),
  [FORM_FIND_CUSTOMERS]: formReducer(FORM_FIND_CUSTOMERS),
  [FORM_JOURNEY_SEARCH]: composeReducers(journeySearchFormReducer, formReducer(FORM_JOURNEY_SEARCH)),
  [FORM_PASSENGER_DETAILS]: composeReducers(passengerDetailsForm, formReducer(FORM_PASSENGER_DETAILS)),
  [FORM_PASSENGER_DETAILS_AFTERSALES]: composeReducers(
    passengerDetailsForm,
    formReducer(FORM_PASSENGER_DETAILS_AFTERSALES)
  ),
  [FORM_PAYMENT_METHOD]: formReducer(FORM_PAYMENT_METHOD),
  [FORM_PRODUCT_SEARCH]: formReducer(FORM_PRODUCT_SEARCH),
  [FORM_TYPE_REBOOK_JOURNEY_SEARCH]: composeReducers(
    rebookJourneySearchFormReducer,
    formReducer(FORM_TYPE_REBOOK_JOURNEY_SEARCH)
  ),
  [FORM_TYPE_REDEEM_VOUCHER]: composeReducers(vouchersFormReducer, formReducer(FORM_TYPE_REDEEM_VOUCHER)),
  [FORM_TYPE_VOUCHER_SEARCH]: formReducer(FORM_TYPE_VOUCHER_SEARCH)
}
