import {connect} from 'react-redux'
import SidebarShortcuts from '../../../components/tickets/sidebar/sidebar-shortcuts'
import {shortcutsSelector} from '../../../redux/selectors/containers/tickets/sidebar-shortcuts'
import {bookingSelector} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import {modalIsActiveSelector} from '../../../redux/selectors/containers/base/visible-element'
import {
  useShortcut,
  enableEditMode,
  disableEditMode,
  editShortcut
} from '../../../redux/actions/containers/tickets/sidebar-shortcuts'

const mapStateToProps = state => ({
  disabled: modalIsActiveSelector(state) && !state.containers.tickets.shortcuts.editModeEnabled,
  hasBooking: Boolean(bookingSelector(state)),
  shortcuts: shortcutsSelector(state),
  selectedSequenceNumber: state.containers.tickets.shortcuts.selectedShortcutNr,
  editModeEnabled: state.containers.tickets.shortcuts.editModeEnabled
})

const mapDispatchToProps = {
  useShortcut,
  enableEditMode: sequenceNumber => [enableEditMode(), editShortcut(sequenceNumber)],
  disableEditMode,
  editShortcut
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarShortcuts)
