import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import DropdownList from '../../../../elements/dropdown-list/dropdown-list'

const TIME_FILTER_ONE = 'TIME_FILTER_ONE'
const TIME_FILTER_TWO = 'TIME_FILTER_TWO'
const TIME_FILTER_THREE = 'TIME_FILTER_THREE'
const TIME_FILTER_FOUR = 'TIME_FILTER_FOUR'

export default class TimeSelector extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    earliestTimeField: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func.isRequired,
      disabled: PropTypes.bool
    }),
    latestTimeField: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      disabled: PropTypes.bool
    })
  }

  constructor (props, ...args) {
    super(props, ...args)

    this.handleChange = this.handleChange.bind(this)

    this.placeholder = _t.message(`journey-search.form.${props.name}-time.placeholder`)
    this.times = [
      {value: null, text: this.placeholder},
      {value: TIME_FILTER_ONE, text: _t.message('journey-search.form.times.filter-one')},
      {value: TIME_FILTER_TWO, text: _t.message('journey-search.form.times.filter-two')},
      {value: TIME_FILTER_THREE, text: _t.message('journey-search.form.times.filter-three')},
      {value: TIME_FILTER_FOUR, text: _t.message('journey-search.form.times.filter-four')}
    ]
  }

  handleChange (selected) {
    let earliest, latest
    switch (selected.value) {
      case TIME_FILTER_ONE:
        earliest = '00:00:00'
        latest = '10:00:00'
        break
      case TIME_FILTER_TWO:
        earliest = '09:00:00'
        latest = '15:00:00'
        break
      case TIME_FILTER_THREE:
        earliest = '14:00:00'
        latest = '20:00:00'
        break
      case TIME_FILTER_FOUR:
        earliest = '18:00:00'
        latest = '23:59:59'
        break
      default:
        earliest = null
        latest = null
    }
    this.props.earliestTimeField.onChange(earliest)
    this.props.latestTimeField.onChange(latest)
  }

  _getSelectedTimeFromEarliestTime (time) {
    switch (time) {
      case '00:00:00':
        return TIME_FILTER_ONE
      case '09:00:00':
        return TIME_FILTER_TWO
      case '14:00:00':
        return TIME_FILTER_THREE
      case '18:00:00':
        return TIME_FILTER_FOUR
      default:
        return null
    }
  }

  render () {
    const disableComponent = this.props.disabled || this.props.earliestTimeField.disabled || this.props.latestTimeField.disabled
    return (
      <div className='time-selector'>
        <DropdownList
          {...this.props}
          onChange={this.handleChange}
          name={`${this.props.name}-time`}
          value={this._getSelectedTimeFromEarliestTime(this.props.earliestTimeField.value)}
          textField='text'
          valueField='value'
          data={this.times}
          placeholder={this.placeholder}
          disabled={disableComponent}
        />
      </div>
    )
  }
}
