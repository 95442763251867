import React, {Component} from 'react'
import GridLayout from '../../elements/grid/grid-layout'
import GridColumn from '../../elements/grid/grid-column'
import Row from '../../elements/row/row'
import Header from './header/header'
import TaskBar from './taskbar/taskbar'

export default class BasicLayout extends Component {
  render () {
    return (
      <main>
        <Row>
          <GridLayout name='content'>
            <GridColumn name='content'>
              <Header />
              {this.props.children}
              <TaskBar disableDiagnostics />
            </GridColumn>
          </GridLayout>
        </Row>
      </main>
    )
  }
}
