import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TableHeader from '../../../../elements/table/table-header'
import _t from 's3p-js-lib/src/translate'

export default class NotesHeader extends Component {
  static propTypes = {
    date: PropTypes.any,
    agent: PropTypes.string,
    status: PropTypes.any,
    note: PropTypes.any
  }

  render () {
    return (
      <thead>
        <tr>
          <TableHeader text={_t.message('aftersales.booking.notes.header.date')} />
          <TableHeader text={_t.message('aftersales.booking.notes.header.agent')} />
          <TableHeader text={_t.message('aftersales.booking.notes.header.status')} />
          <TableHeader text={_t.message('aftersales.booking.notes.header.note')} />
        </tr>
      </thead>
    )
  }
}
