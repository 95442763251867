import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import './button-group.scss'

const ButtonGroup = ({className, children, ...rest}) => (
  <div {...rest} className={classNames('button-group', className)}>
    {children}
  </div>
)

ButtonGroup.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string
}

export default ButtonGroup
