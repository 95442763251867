import React, { Component } from 'react'
import PropTypes from '../../misc/prop-types'
import CalendarWidget from 'react-widgets/lib/Calendar'
import moment from 'moment'
import {toUtcDateMoment} from 's3p-js-lib/src/misc/date'
import momentLocalizer from 'react-widgets/lib/localizers/moment'
import '../table/table.scss'
import './calendar.scss'
momentLocalizer(moment)

class Calendar extends Component {
  static propTypes = {
    minDate: PropTypes.moment,
    maxDate: PropTypes.moment,
    value: PropTypes.moment,
    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func
  }

  constructor (...args) {
    super(...args)

    this.onChange = this.onChange.bind(this)
  }

  onChange (date) {
    this.props.onChange(date ? toUtcDateMoment(date) : null)
  }

  render () {
    return (
      <CalendarWidget
        onChange={this.onChange}
        onKeyDown={this.props.onKeyDown}
        value={this.props.value && this.props.value.toDate()}
        min={this.props.minDate && this.props.minDate.toDate()}
        max={this.props.maxDate && this.props.maxDate.toDate()}
      />
    )
  }
}

export default Calendar
