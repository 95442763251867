import {
  API_V2_GET_PRODUCT_FAMILIES_REQUEST,
  API_V2_GET_PRODUCT_FAMILIES_SUCCESS,
  API_V2_GET_PRODUCT_FAMILIES_FAILURE
} from '../../../types-v2'
import Client from '../../../../../api/client'
import ensureToken from '../../../../../api/ensure-token'

const getProductFamiliesRequest = salesChannel => ({type: API_V2_GET_PRODUCT_FAMILIES_REQUEST, salesChannel})
const getProductFamiliesSuccess = response => ({type: API_V2_GET_PRODUCT_FAMILIES_SUCCESS, response})
const getProductFamiliesFailure = error => ({type: API_V2_GET_PRODUCT_FAMILIES_FAILURE, error})

export const getProductFamilies = salesChannel => ensureToken(async (token, dispatch) => {
  try {
    dispatch(getProductFamiliesRequest(salesChannel))
    const response = await Client.getProductFamilies(token, salesChannel)
    dispatch(getProductFamiliesSuccess(response))
  } catch (error) {
    dispatch(getProductFamiliesFailure(error))
    throw error
  }
})
