import {
  API_V2_UPDATE_CUSTOMER_REQUEST,
  API_V2_UPDATE_CUSTOMER_SUCCESS,
  API_V2_UPDATE_CUSTOMER_FAILURE
} from 's3p-js-lib/src/redux/actions/types-v2'
import Client from '../../../../../api/client'
import ensureToken from 's3p-js-lib/src/api/ensure-token'
import {UPDATE_BOOKING_HANDLED_ERRORS} from 's3p-js-lib/src/redux/actions/api/v2/booking'

const updateCustomerBookingRequest = (bookingNumber, customer) => ({
  type: API_V2_UPDATE_CUSTOMER_REQUEST,
  bookingNumber,
  customer
})

const updateCustomerBookingSuccess = response => ({
  type: API_V2_UPDATE_CUSTOMER_SUCCESS,
  response
})

const updateCustomerBookingFailure = error => ({
  type: API_V2_UPDATE_CUSTOMER_FAILURE,
  error
})

export const updateCustomer = (bookingNumber, customer) => ensureToken(async (token, dispatch) => {
  try {
    dispatch(updateCustomerBookingRequest(bookingNumber, customer))
    const response = await Client.updateCustomer(token, bookingNumber, customer)
    dispatch(updateCustomerBookingSuccess(response))
  } catch (error) {
    dispatch(updateCustomerBookingFailure(error))
    if (UPDATE_BOOKING_HANDLED_ERRORS.includes(error.errorCode)) {
      return false
    }
    throw error
  }
})
