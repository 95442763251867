import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ComponentContainer from '../component-container/component-container'
import ButtonGroup from '../button-group/button-group'
import Button from '../button/button'
import TextLabel from '../text-label/text-label'
import Icon from '../icon/icon'
import './progress-navigation.scss'
import _t from 's3p-js-lib/src/translate'

class ProgressNavigation extends Component {
  static propTypes = {
    previous: PropTypes.element,
    next: PropTypes.element
  }

  render () {
    return (
      <ComponentContainer name='progress-navigation'>
        <ButtonGroup>
          {this.props.previous}
          {this.props.next}
        </ButtonGroup>
      </ComponentContainer>
    )
  }
}

class NextButton extends Component {
  static propTypes = {
    text: PropTypes.string
  }

  render () {
    const {text, ...props} = this.props

    return (
      <Button name='next-step' className='primary' {...props}>
        <TextLabel text={text || _t.message('progress-navigation.next')} />
        <Icon name='chevron-right' className='align-right' />
      </Button>
    )
  }
}

class PreviousButton extends Component {
  static propTypes = {
    text: PropTypes.string
  }

  render () {
    const {text, ...props} = this.props

    return (
      <Button name='previous-step' className='tertiary' {...props}>
        <Icon name='chevron-left' className='align-left' />
        <TextLabel text={text || _t.message('progress-navigation.previous')} />
      </Button>
    )
  }
}

ProgressNavigation.NextButton = NextButton
ProgressNavigation.PreviousButton = PreviousButton

export default ProgressNavigation
