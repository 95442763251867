/* globals sessionStorage, localStorage */

const storageExistsChecker = storage => {
  let doesStorageExists = false
  if (typeof storage !== 'boolean') {
    try {
      if (typeof storage !== 'object') {
        doesStorageExists = false
      } else {
        try {
          storage.setItem('test', 'test')
          storage.removeItem('test')
          doesStorageExists = true
        } catch (error) {
          doesStorageExists = false
        }
      }
    } catch (error) {
      doesStorageExists = false
    }
  }
  return doesStorageExists
}

const defaultSerializer = {
  serialize (data) {
    if (Array.isArray(data)) {
      return data.map(this.serialize.bind(this))
    } else if (data === null) {
      return null
    } else if (data instanceof Date) {
      return '[date]' + data
    } else if (typeof data === 'object') {
      return Object.keys(data).reduce(
        (result, key) => ({...result, [key]: this.serialize(data[key])}),
        {}
      )
    } else {
      return data
    }
  },
  deserialize (data) {
    if (Array.isArray(data)) {
      return data.map(this.deserialize.bind(this))
    } else if (data === null) {
      return null
    } else if (typeof data === 'object') {
      return Object.keys(data).reduce(
        (result, key) => ({...result, [key]: this.deserialize(data[key])}),
        {}
      )
    } else if (typeof data === 'string' && data.indexOf('[date]') === 0) {
      return new Date(data.substring(6))
    } else {
      return data
    }
  }
}

export const storageCreator = (storage, serializer) => {
  const storageExists = storageExistsChecker(storage)
  return {
    available: storageExists,
    set (key, value) {
      if (typeof value === 'undefined' || value === null) {
        this.clear(key)
        return
      }

      if (storageExists) {
        storage.setItem(key, JSON.stringify(serializer.serialize(value)))
      }
    },

    clear (key) {
      if (storageExists) {
        storage.removeItem(key)
      }
    },

    has (key) {
      return storageExists && storage.getItem(key) !== null
    },

    get (key, defaultValue) {
      if (storageExists) {
        let value = storage.getItem(key)
        if (value !== null) {
          try {
            value = serializer.deserialize(JSON.parse(value))
          } catch (error) {
            value = null
          }
        }

        return value !== null ? value : defaultValue
      } else {
        return defaultValue
      }
    }
  }
}

let sessionStore
let localStore

try { sessionStore = storageCreator(sessionStorage, defaultSerializer) } catch (error) {
  sessionStore = {available: false}
}
try { localStore = storageCreator(localStorage, defaultSerializer) } catch (error) {
  localStore = {available: false}
}

export {
  sessionStore,
  localStore
}
