import React, { Component } from 'react'
import GridLayout from '../../elements/grid/grid-layout'
import GridColumn from '../../elements/grid/grid-column'
import ComponentContainer from '../../elements/component-container/component-container'
import MainMenu from '../../containers/base/main-menu/main-menu'
import JourneySearch from '../../containers/reservations/journey-search/journey-search'
import StickyFooter from '../../elements/sticky-footer/sticky-footer'
import OrientationSummary from '../../containers/reservations/orientation-summary'
import NextButton from '../../containers/reservations/journey-search/next-button'
import Taskbar from '../../containers/base/taskbar/taskbar'
import JourneyResultSet from '../../containers/base/journey-search/journey-result-set/journey-result-set'
import GenericLayout from '../generic-layout'
import {
  TRAVEL_DIRECTION_OUTBOUND,
  TRAVEL_DIRECTION_INBOUND
} from 's3p-js-lib/src/constants'
import LastApiErrorFeedback from '../../containers/base/last-api-error-feedback'
import { TYPE_MODAL } from '../../components/base/last-api-error-feedback'

export default class JourneySearchLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <MainMenu />
            <JourneySearch />
            <ComponentContainer name='journey-result-set'>
              <JourneyResultSet direction={TRAVEL_DIRECTION_OUTBOUND} />
              <JourneyResultSet direction={TRAVEL_DIRECTION_INBOUND} />
            </ComponentContainer>
            <StickyFooter>
              <OrientationSummary nextButton={<NextButton />} />
              <Taskbar />
            </StickyFooter>
            <LastApiErrorFeedback namespaces={['createProvisionalBooking', 'linkToCustomerEmail', 'updatePassengers']} type={TYPE_MODAL} />
          </GridColumn>
        </GridLayout>
      </GenericLayout>
    )
  }
}
