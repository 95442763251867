import { connect } from 'react-redux'
import { paginateJourneySearch } from 's3p-js-lib/src/redux/actions/api/orientation/journey-search'
import {
  paginateJourneySearch as paginateRebookingJourneySearch
} from 's3p-js-lib/src/redux/actions/api/aftersales/rebooking/journey-search'
import {
  nextCursorSelector,
  prevCursorSelector
} from 's3p-js-lib/src/redux/selectors/api/orientation/journey-search'
import Pagination from '../../../../components/base/journey-search/journey-result-set/pagination'
import containKeepBundleSelection from '../keep-bundle-selection'
import { loadBestPrices } from '../../../../redux/actions/containers/reservations/journey-search/best-prices'

const mapStateToProps = (state, ownProps) => ({
  hasNextResults: Boolean(nextCursorSelector(state, ownProps)),
  hasPreviousResults: Boolean(prevCursorSelector(state, ownProps))
})

const mapDispatchToProps = (_, ownProps) => {
  const paginate = ownProps.aftersales ? paginateRebookingJourneySearch : paginateJourneySearch
  return dispatch => ({
    getNextResults: () => dispatch(async dispatch => {
      await dispatch(paginate(ownProps.direction, true))
      dispatch(loadBestPrices(Boolean(ownProps.aftersales)))
    }),
    getPreviousResults: () => dispatch(async dispatch => {
      await dispatch(paginate(ownProps.direction, false))
      dispatch(loadBestPrices(Boolean(ownProps.aftersales)))
    })
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(containKeepBundleSelection(Pagination))
