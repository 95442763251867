import PropTypes from 'prop-types'
import React, { Component } from 'react'
import FeedbackApi from '../../elements/feedback/feedback-api'
import FeedbackApiModal from './feedback-api-modal'

export const TYPE_MODAL = 'modal'
export const TYPE_FEEDBACK = 'feedback'

export default class LastApiErrorFeedback extends Component {
  static propTypes = {
    type: PropTypes.oneOf([TYPE_FEEDBACK, TYPE_MODAL]),
    lastApiErrors: PropTypes.arrayOf(PropTypes.object),
    clearApiErrors: PropTypes.func.isRequired
  }

  static defaultProps = {
    type: TYPE_FEEDBACK
  }

  render () {
    const {type, lastApiErrors, clearApiErrors, ...props} = this.props

    return (type === TYPE_MODAL)
      ? (
        <FeedbackApiModal
          open={lastApiErrors.length > 0}
          onClose={clearApiErrors}
          messages={lastApiErrors}
          {...props}
        />
      ) : (
        <FeedbackApi
          messages={lastApiErrors}
          clearApiErrors={clearApiErrors}
          {...props}
        />
      )
  }
}
