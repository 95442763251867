import {
  UI_SELECT_READ_TICKET,
  UI_READ_BARCODE_REQUEST,
  UI_READ_BARCODE_SUCCESS,
  UI_READ_BARCODE_FAILURE
} from '../../../types'
import { readBarcode } from '../../../../actions/machine/barcode-scanner'
import { getTicketDetails } from '../../../api/v2/ticket-details'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import Base64 from 'Base64'
import pako from 'pako'

const BARCODE_READ_TIMEOUT = 20

export const selectReadTicket = fulfillmentMethod => ({
  type: UI_SELECT_READ_TICKET,
  fulfillmentMethod: fulfillmentMethod
})

const readBarcodeRequest = () => ({type: UI_READ_BARCODE_REQUEST})
const readBarcodeSuccess = () => ({type: UI_READ_BARCODE_SUCCESS})
const readBarcodeFailure = error => ({type: UI_READ_BARCODE_FAILURE, error})

export const readBarcodeData = () => async (dispatch, getState) => {
  dispatch(readBarcodeRequest())

  await dispatch(clearState('api.v2.ticketDetails'))
  await dispatch(readBarcode(BARCODE_READ_TIMEOUT))

  const barcode = getState().machine.barcodeScanner

  if (!barcode) {
    dispatch(readBarcodeFailure('no barcode provided'))
    return null
  }
  const ticketKey = getTicketKeyFromBarcode(barcode)
  if (!ticketKey) {
    dispatch(readBarcodeFailure('no good barcode found'))
    return null
  }

  const data = {
    includeValidationStatus: true,
    includeAddonCountByType: true,
    includeAddonCountByProduct: true
  }
  const response = await dispatch(getTicketDetails(ticketKey, data))
  if (response) {
    dispatch(readBarcodeSuccess())
  } else {
    dispatch(readBarcodeFailure('Barcode failed to read'))
  }
}

const getTicketKeyFromBarcode = barcode => {
  try {
    const barcodeDecoded = Base64.atob(barcode)
    const messageLength = barcodeDecoded.substr(64, 4)
    const decodedString = pako.inflate(barcodeDecoded.substr(68, Number(messageLength)), { to: 'string' })
    const uHead = decodedString.substr(0, 53)
    const ticketKey = uHead.substr(16, 20)
    return ticketKey.trim()
  } catch (error) {
    return null
  }
}
