import { CLEAR_STATE } from '../../../actions/types'
import {
  API_V2_ADD_ADDITIONAL_DETAILS_SUCCESS,
  API_V2_UPDATE_ADDITIONAL_DETAILS_SUCCESS
} from '../../../actions/types-v2'

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_V2_ADD_ADDITIONAL_DETAILS_SUCCESS:
    case API_V2_UPDATE_ADDITIONAL_DETAILS_SUCCESS:
      return action.response.data.booking
    case CLEAR_STATE:
      return action.namespaces.includes('api.booking.additionalDetailsBooking') ? initialState : state
    default:
      return state
  }
}
