import {selectedUniqueBundleIdsSelector} from '../../../selectors/user-input/orientation/selected-unique-bundle-ids'
import {
  USER_INPUT_SET_SELECTED_UNIQUE_BUNDLE_IDS,
  USER_INPUT_RESET_SELECTED_UNIQUE_BUNDLE_IDS
} from '../../types'

// eslint-disable-next-line func-style
export function setSelectedUniqueBundleIds (uniqueBundleIds) {
  return {type: USER_INPUT_SET_SELECTED_UNIQUE_BUNDLE_IDS, uniqueBundleIds}
}

// eslint-disable-next-line func-style
export function persistSelectedUniqueBundleIds () {
  return (dispatch, getState) => {
    dispatch(setSelectedUniqueBundleIds(selectedUniqueBundleIdsSelector(getState())))
  }
}

// eslint-disable-next-line func-style
export function resetSelectedUniqueBundleIds (uniqueBundleIds) {
  return {type: USER_INPUT_RESET_SELECTED_UNIQUE_BUNDLE_IDS, uniqueBundleIds}
}
