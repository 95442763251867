import {
  API_CONFIRM_LEAP_ACTION_REQUEST,
  API_CONFIRM_LEAP_ACTION_SUCCESS,
  API_CONFIRM_LEAP_ACTION_FAILURE
} from '../../../../actions/types'
import { CLEAR_STATE } from 's3p-js-lib/src/redux/actions/types'

const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_CONFIRM_LEAP_ACTION_REQUEST:
    case API_CONFIRM_LEAP_ACTION_SUCCESS:
      return initialState
    case API_CONFIRM_LEAP_ACTION_FAILURE:
      return action.error.response.message || initialState
    case CLEAR_STATE:
      return action.namespaces.includes('api.modules.leap.confirmAction') ? initialState : state
    default:
      return state
  }
}
