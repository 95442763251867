import PropTypes from 'prop-types'
import React from 'react'
import Table from '../../../../elements/table/table'
import FindCustomersHeader from '../../../../containers/reservations/booking-details/find-customer/find-customers-header'
import FindCustomersRow from './find-customers-row'

export default class FindCustomersTable extends React.Component {
  static propTypes = {
    customers: PropTypes.arrayOf(PropTypes.shape({
      emailAddress: PropTypes.string.isRequired
    })).isRequired,
    onTableRowClick: PropTypes.func.isRequired
  }

  render () {
    return (
      <Table name='search-customer' numColumns={2} hoverRows zebraRows>
        <FindCustomersHeader />
        <tbody>
          {this.props.customers.map(customer =>
            <FindCustomersRow key={customer.emailAddress} customer={customer} onClick={this.props.onTableRowClick} />
          )}
        </tbody>
      </Table>
    )
  }
}
