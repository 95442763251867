import PropTypes from 'prop-types'
import React, { Component } from 'react'
import CancelEntireBooking from '../../../../components/aftersales/cancellation/cancel-by/cancel-entire-booking'

export default class CancelEntireBookingContainer extends Component {
  static propTypes = {
    onSelectItemIds: PropTypes.func.isRequired,
    onDeselectItemIds: PropTypes.func.isRequired,
    bookingCancellationData: PropTypes.shape({
      allItemsSelected: PropTypes.bool.isRequired,
      itemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
      selectedItemIds: PropTypes.array
    }).isRequired,
    disabled: PropTypes.bool.isRequired
  }

  constructor (...args) {
    super(...args)
    this.onToggle = this.onToggle.bind(this)
  }

  UNSAFE_componentWillMount () {
    if (this.props.bookingCancellationData.itemIds.length === 1) {
      this.props.onSelectItemIds(this.props.bookingCancellationData.itemIds.filter(
        itemId => !this.props.bookingCancellationData.selectedItemIds.includes(itemId)))
    }
  }

  onToggle () {
    const {bookingCancellationData, onDeselectItemIds, onSelectItemIds} = this.props
    if (bookingCancellationData.allItemsSelected) {
      onDeselectItemIds(bookingCancellationData.itemIds)
    } else {
      onSelectItemIds(bookingCancellationData.itemIds.filter(
        itemId => !bookingCancellationData.selectedItemIds.includes(itemId)
      ))
    }
  }

  render () {
    return <CancelEntireBooking
      onToggle={this.onToggle}
      bookingCancellationData={this.props.bookingCancellationData}
      disabled={this.props.disabled}
    />
  }
}
