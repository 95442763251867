import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../text-label/text-label'
import kebabCase from 'lodash/kebabCase'
import _t from 's3p-js-lib/src/translate'
import Form from '../form/form'
import IncrementalInputWrapper from '../incremental-input-wrapper/incremental-input-wrapper'
import Icon from '../icon/icon'
import Button from '../button/button'
import HotkeyLabel from '../hotkey-label/hotkey-label'
import { findDOMNode } from 's3p-js-lib/src/misc/utils'
import Mousetrap from 'mousetrap'

export default class AmountInputField extends Component {
  static contextTypes = {
    formContext: PropTypes.string
  }

  static propTypes = {
    value: PropTypes.number.isRequired,
    defaultValue: PropTypes.number,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    disabled: PropTypes.bool,
    max: PropTypes.number,
    min: PropTypes.number,
    hotkey: PropTypes.shape({
      increment: PropTypes.string,
      decrement: PropTypes.string,
      focus: PropTypes.string
    })
  }

  static defaultProps = {
    defaultValue: 0,
    hotkey: {}
  }

  constructor (props, ...args) {
    super(props, ...args)

    this.minimal = props.min || Number.MIN_SAFE_INTEGER
    this.maximal = props.max || Number.MAX_SAFE_INTEGER

    this.onChange = this.onChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onIncrement = this.onIncrement.bind(this)
    this.onDecrement = this.onDecrement.bind(this)
  }

  componentDidMount () {
    // eslint-disable-next-line react/no-find-dom-node
    this.mousetrap = new Mousetrap(findDOMNode(this.input))
    this.mousetrap.bind('plus', this.onIncrement)
    this.mousetrap.bind('-', this.onDecrement)
    this.mousetrap.bind('up', this.onIncrement)
    this.mousetrap.bind('down', this.onDecrement)
  }

  componentWillUnmount () {
    this.mousetrap.reset()
    this.mousetrap = null
  }

  onIncrement () {
    const value = this.props.value || this.props.defaultValue
    this.handleChange(value + 1)
  }

  onDecrement () {
    const value = this.props.value || this.props.defaultValue
    this.handleChange(value - 1)
  }

  onChange (event) {
    this.handleChange(event.target.value)
  }

  handleChange (value) {
    this.props.onChange && this.props.onChange(this.parseValue(value))
  }

  onFocus () {
    // eslint-disable-next-line react/no-find-dom-node
    const htmlElement = findDOMNode(this.input)
    if (htmlElement) {
      htmlElement.select()
    }
    this.props.onFocus && this.props.onFocus()
  }

  onBlur (event) {
    if (this.props.onBlur) {
      this.props.onBlur(this.parseValue(event.target.value))
    }
  }

  parseValue (value) {
    const checkValue = value ? parseInt(value, 10) : this.props.defaultValue

    return Math.min(Math.max(checkValue, this.minimal), this.maximal)
  }

  render () {
    const {value, disabled, defaultValue, min, max, name, id, ...props} = this.props
    delete props.hotkey

    return (
      <IncrementalInputWrapper>
        <IncrementalInputWrapper.Modifier>
          <Button
            type='button'
            tabIndex='-1'
            name='modifier'
            disabled={disabled || value <= min}
            onClick={this.onDecrement}
            hotkey={this.props.hotkey.decrement}
          >
            <TextLabel name='modifier'>
              <Icon name='subtract' />
              <TextLabel.Text text={_t.message(`${this.context.formContext}.form.${kebabCase(name)}.subtract`)} />
            </TextLabel>
          </Button>
        </IncrementalInputWrapper.Modifier>
        <IncrementalInputWrapper.Input>
          <Form.TextField
            {...props}
            autoComplete='off'
            ref={input => { this.input = input }}
            name={name}
            id={id}
            value={value || defaultValue}
            onChange={this.onChange}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            disabled={disabled}
            hotkey={this.props.hotkey.focus}
          />
          {this.props.hotkey.focus && <HotkeyLabel hotkey={this.props.hotkey.focus} />}
        </IncrementalInputWrapper.Input>
        <IncrementalInputWrapper.Modifier>
          <Button
            type='button'
            tabIndex='-1'
            name='modifier'
            disabled={disabled || value >= max}
            onClick={this.onIncrement}
            hotkey={this.props.hotkey.increment}
          >
            <TextLabel name='modifier'>
              <Icon name='add' />
              <TextLabel.Text text={_t.message(`${this.context.formContext}.form.${kebabCase(name)}.add`)} />
            </TextLabel>
          </Button>
        </IncrementalInputWrapper.Modifier>
      </IncrementalInputWrapper>
    )
  }
}
