import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import classNames from 'classnames'
import TextLabel from '../text-label/text-label'
import Icon from '../icon/icon'
import Button from '../button/button'
import './feedback.scss'

export const FEEDBACK_SUCCESS = 'FEEDBACK_SUCCESS'
export const FEEDBACK_ALERT = 'FEEDBACK_ALERT'
export const FEEDBACK_WARNING = 'FEEDBACK_WARNING'
export const FEEDBACK_INFORMATION = 'FEEDBACK_INFORMATION'

export default class Feedback extends Component {
  static propTypes = {
    status: PropTypes.oneOf([FEEDBACK_SUCCESS, FEEDBACK_ALERT, FEEDBACK_WARNING, FEEDBACK_INFORMATION]),
    text: PropTypes.string,
    className: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object
    ]),
    onCloseButtonClick: PropTypes.func,
    showCloseButton: PropTypes.bool
  }

  static defaultProps = {
    showCloseButton: false
  }

  static propsMap = {
    FEEDBACK_SUCCESS: {
      icon: 'tick',
      className: 'success'
    },
    FEEDBACK_ALERT: {
      icon: 'alert',
      className: 'alert'
    },
    FEEDBACK_WARNING: {
      icon: 'warning',
      className: 'warning'
    },
    FEEDBACK_INFORMATION: {
      icon: 'information',
      className: 'information'
    }
  }

  render () {
    const {status, text, className, showCloseButton, onCloseButtonClick, children, ...other} = this.props

    return (
      <div {...other}
        className={classNames('feedback', Feedback.propsMap[status].className, className)}
        role='alert'
        aria-live='assertive'
      >
        <TextLabel name='feedback-message'>
          <Icon name={Feedback.propsMap[status].icon} className='medium align-left' />
          {text ? <TextLabel.Text text={text} /> : children}
        </TextLabel>
        {showCloseButton ? (
          <Button
            name='close'
            title={_t.message('feedback.close-button')}
            aria-label={_t.message('feedback.close-button')}
            onClick={onCloseButtonClick}
          >
            <Icon name='cross' className='medium align-left' />
            <TextLabel aria-hidden='true' text={_t.message('feedback.close-button')} />
          </Button>
        ) : null}
      </div>
    )
  }
}
