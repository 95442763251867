import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import connectFormContainer from '../../base/form'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { customerSelector } from 's3p-js-lib/src/redux/selectors/api/booking/customer'
import { passengersSelector } from 's3p-js-lib/src/redux/selectors/api/booking/passengers'
import CustomerDetails from '../../../components/reservations/booking-details/customer-details'
import { FORM_CUSTOMER_DETAILS } from 's3p-js-lib/src/constants-form'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import { CONTAINERS_RESERVATION_BOOKING_DETAILS_CUSTOMER_DETAILS } from '../../../constants'

class CustomerDetailsContainer extends Component {
  static propTypes = {
    createAccount: PropTypes.bool,
    form: PropTypes.shape({
      loadFormValues: PropTypes.func.isRequired
    }).isRequired,
    customer: PropTypes.object,
    isPassenger: PropTypes.bool.isRequired,
    clearState: PropTypes.func.isRequired
  }

  UNSAFE_componentWillMount () {
    const {...customer} = this.props.customer || {}

    this.props.form.loadFormValues({
      isPassenger: this.props.isPassenger,
      firstName: '',
      lastName: '',
      emailAddress: '',
      ...customer
    })
  }

  componentWillUnmount () {
    this.props.clearState()
  }

  render () {
    return <CustomerDetails {...this.props} />
  }
}

const bookingCustomerSelector = customerSelector(bookingSelector)
const _passengersSelector = passengersSelector(bookingSelector)

const mapStateToProps = state => {
  const customer = bookingCustomerSelector(state)
  const passengers = _passengersSelector(state)
  const firstPassenger = passengers && passengers.length && passengers[0]

  return {
    customer: customer,
    isPassenger: Boolean(!customer || !firstPassenger || (
      customer.firstName && customer.firstName === firstPassenger.firstName &&
        customer.lastName && customer.lastName === firstPassenger.lastName &&
        customer.emailAddress && customer.emailAddress === firstPassenger.emailAddress && (
        (!customer.phoneNumber && !firstPassenger.phoneNumber) ||
          customer.phoneNumber === firstPassenger.phoneNumber
      )
    )
    ),
    showFeedback: state.containers.reservations.bookingDetails.customerDetails.showFeedback
  }
}

const mapDispatchToProps = {
  clearState: () => clearState(CONTAINERS_RESERVATION_BOOKING_DETAILS_CUSTOMER_DETAILS)
}

export default connect(mapStateToProps, mapDispatchToProps)(
  connectFormContainer({formName: FORM_CUSTOMER_DETAILS})(CustomerDetailsContainer)
)
