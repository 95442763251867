import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { isAgentSelector } from 's3p-js-lib/src/redux/selectors/api/auth/auth'
import { findCustomers } from 's3p-js-lib/src/redux/actions/api/user/agent/find-customers'
import { updateBookingWithCrmCustomer } from 's3p-js-lib/src/redux/actions/api/booking/update'
import { customerSelector } from 's3p-js-lib/src/redux/selectors/api/my-s3/customer'
import { salesChannelPropertySelector } from 's3p-js-lib/src/redux/selectors/api/user/sales-channel-properties'
import { foundCustomersSelector } from '../../../redux/selectors/containers/reservations/booking-details/find-customers/selected-customer'
import { sortColumn } from '../../../redux/actions/containers/base/table'
import containVisibleElement from '../../base/visible-element'
import SelectedCustomer from '../../../components/reservations/booking-details/find-customers/selected-customer'
import connectFormContainer from '../../base/form'
import {
  FORM_CUSTOMER_DETAILS,
  UI_TABLE_SORT_ASCENDING
} from 's3p-js-lib/src/constants'
import { SORT_FIND_CUSTOMERS } from '../../../constants'
import {goToReservationsJourneySearch} from '../../../redux/actions/containers/base/routing'

const bookingSelectedCrmCustomerSelector = customerSelector(bookingSelector)
const hasAccessToPublicBookingsSelector = salesChannelPropertySelector('access_public_bookings')
const SEARCH_CUSTOMER_DIALOG_NAME = 'customer-details.selected-customer.modal'

class SelectedCustomerContainer extends Component {
  static propTypes = {
    updateBooking: PropTypes.func.isRequired,
    onShowElement: PropTypes.func.isRequired,
    onHideElement: PropTypes.func.isRequired,
    selectedCustomer: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      emailAddress: PropTypes.string.isRequired
    }),
    elementVisibility: PropTypes.shape({
      [SEARCH_CUSTOMER_DIALOG_NAME]: PropTypes.bool
    }).isRequired,
    sortColumn: PropTypes.func.isRequired,
    form: PropTypes.shape({
      loadFormValues: PropTypes.func.isRequired,
      fields: PropTypes.shape({
        isPassenger: PropTypes.object
      }).isRequired
    }).isRequired,
    goToReservationsJourneySearch: PropTypes.func.isRequired
  }

  constructor (...args) {
    super(...args)
    this.handleLinkCrmCustomer = this.handleLinkCrmCustomer.bind(this)
    this.handleUnlinkCrmCustomer = this.handleUnlinkCrmCustomer.bind(this)
    this.handleOpenFindCustomersDialog = this.handleOpenFindCustomersDialog.bind(this)
    this.handleCloseFindCustomersDialog = this.handleCloseFindCustomersDialog.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.props.sortColumn(SORT_FIND_CUSTOMERS.TABLE_NAME, SORT_FIND_CUSTOMERS.FULL_NAME, UI_TABLE_SORT_ASCENDING)
  }

  handleUnlinkCrmCustomer () {
    if (!this.props.updateBooking({crmCustomer: null})) {
      this.props.goToReservationsJourneySearch()
    }
  }

  handleLinkCrmCustomer (customer) {
    this.props.updateBooking({crmCustomer: customer}).then(
      success => {
        if (success) {
          this.prefillCustomerDetailsForm()
          this.handleCloseFindCustomersDialog()
        }
      }
    )
  }

  handleOpenFindCustomersDialog () {
    this.props.onShowElement(SEARCH_CUSTOMER_DIALOG_NAME)
  }

  handleCloseFindCustomersDialog () {
    this.props.onHideElement(SEARCH_CUSTOMER_DIALOG_NAME)
  }

  prefillCustomerDetailsForm () {
    const {form, selectedCustomer} = this.props
    if (selectedCustomer) {
      const isPassenger = form.fields.isPassenger ? form.fields.isPassenger.value : undefined

      form.reset()
      form.loadFormValues({
        title: selectedCustomer.title,
        firstName: selectedCustomer.firstName,
        lastName: selectedCustomer.lastName,
        emailAddress: selectedCustomer.emailAddress,
        phoneNumber: selectedCustomer.phoneNumber,
        isPassenger
      })
    }
  }

  render () {
    return (
      <SelectedCustomer
        onLinkCrmCustomer={this.handleLinkCrmCustomer}
        onUnlinkCrmCustomer={this.handleUnlinkCrmCustomer}
        onOpenFindCustomersDialog={this.handleOpenFindCustomersDialog}
        onCloseFindCustomersDialog={this.handleCloseFindCustomersDialog}
        showSearchCustomerDialog={this.props.elementVisibility[SEARCH_CUSTOMER_DIALOG_NAME]}
        {...this.props}
      />
    )
  }
}

const mapStateToProps = state => ({
  ...state.containers.reservations.bookingDetails.selectedCustomer,
  foundCustomers: foundCustomersSelector(state),
  isAgent: isAgentSelector(state),
  selectedCustomer: bookingSelectedCrmCustomerSelector(state),
  hasAccessToPublicBookings: hasAccessToPublicBookingsSelector(state)
})

const mapDispatchToProps = {
  findCustomers,
  resetCustomers: () => clearState('api.user.agent.foundCustomers'),
  updateBooking: updateBookingWithCrmCustomer,
  sortColumn,
  goToReservationsJourneySearch
}

export default connect(mapStateToProps, mapDispatchToProps)(
  containVisibleElement(SEARCH_CUSTOMER_DIALOG_NAME)(
    connectFormContainer({formName: FORM_CUSTOMER_DETAILS})(SelectedCustomerContainer)))
