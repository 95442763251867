import { createSelector } from 'reselect'
import { overviewBookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import {
  journeySearchFromBookingSelector as baseJourneySearchFromBookingSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/journey-search'
import { isOverrideAftersalesRulesSelector as baseIsOverrideAftersalesRulesSelector } from '../../base/aftersales'

export const journeySearchFromBookingSelector = createSelector(
  [baseJourneySearchFromBookingSelector(overviewBookingSelector)],
  journeySearch => journeySearch ? {...journeySearch, focusSearchButton: 1} : null
)

export const isLoadingToolbarActionsSelector = state => (
  state.api.loading.changeSeatsInitiate ||
  state.api.loading.cancellationInitiate ||
  state.api.loading.rebookingInitiate ||
  state.api.loading.updatePassengersInitiate ||
  state.api.loading.additionalProductsInitiate
)

const overrideAftersalesRulesSelector = baseIsOverrideAftersalesRulesSelector(overviewBookingSelector)

export const isOverrideAftersalesRulesSelector = aftersalesAllowedSelector => {
  const _aftersalesAllowedSelector = aftersalesAllowedSelector(overviewBookingSelector)

  return state => !_aftersalesAllowedSelector(state) && overrideAftersalesRulesSelector(state)
}
