import React, { Component } from 'react'
import PropTypes from '../../../misc/prop-types'
import moment from 'moment'
import Mousetrap from 'mousetrap'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../elements/text-label/text-label'
import Form from '../../../elements/form/form'
import IncrementalInputWrapper from '../../../elements/incremental-input-wrapper/incremental-input-wrapper'
import Icon from '../../../elements/icon/icon'
import Button from '../../../elements/button/button'
import DateInputModal from './modal'
import containVisibleElement from '../../../containers/base/visible-element'
import HotKeys from '../../../elements/utils/hotkeys'
import HotKeyLabel from '../../../elements/hotkey-label/hotkey-label'
import { findDOMNode } from 's3p-js-lib/src/misc/utils'

class DateInputField extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.object,
    hotkey: PropTypes.any,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    minDate: PropTypes.moment,
    onChange: PropTypes.func.isRequired,
    onShowModal: PropTypes.func.isRequired,
    onHideElement: PropTypes.func.isRequired,
    isElementVisible: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func
  }

  static defaultProps = {
    required: false
  }

  constructor (props, ...args) {
    super(props, ...args)

    this._openModal = this._openModal.bind(this)
    this._useHotkey = this._useHotkey.bind(this)

    this.handleDecrementClick = this.handleDecrementClick.bind(this)
    this.handleIncrementClick = this.handleIncrementClick.bind(this)
    this.onHideElement = this.onHideElement.bind(this)
    this.handleOpenModalEvent = this.handleOpenModalEvent.bind(this)

    this.keyHandlers = {}
    if (props.hotkey) {
      this.keyHandlers[props.hotkey] = this._openModal
    }
  }

  componentDidMount () {
    // eslint-disable-next-line react/no-find-dom-node
    this.mousetrap = new Mousetrap(findDOMNode(this.input))
    this.mousetrap.bind('plus', this._useHotkey(this.handleIncrementClick))
    this.mousetrap.bind('-', this._useHotkey(this.handleDecrementClick))
    this.mousetrap.bind('up', this._useHotkey(this.handleIncrementClick))
    this.mousetrap.bind('down', this._useHotkey(this.handleDecrementClick))
    this.mousetrap.bind('enter', this._openModal)
  }

  componentWillUnmount () {
    this.mousetrap.reset()
    this.mousetrap = null
  }

  _openModal () {
    this.props.onShowModal(this.props.name)

    return false
  }

  _useHotkey (callback) {
    return () => {
      if (!this.props.disabled) {
        callback()
      }
    }
  }

  handleIncrementClick () {
    const value = this.props.value || this.props.minDate
    this.props.onChange(moment(value).add(1, 'd'))
  }

  handleDecrementClick () {
    if (this.props.value && !this.props.value.isSameOrBefore(moment(), 'day')) {
      this.props.onChange(moment(this.props.value).subtract(1, 'd'))
    }
  }

  handleOpenModalEvent (event) {
    if (!this.props.disabled) {
      event.preventDefault()
      event.stopPropagation()
      this._openModal()
    }
  }

  render () {
    return (
      <HotKeys handlers={this.keyHandlers} enabled={!this.props.disabled}>
        <IncrementalInputWrapper>
          <IncrementalInputWrapper.Modifier>
            <Button
              type='button'
              tabIndex='-1'
              name='modifier'
              disabled={this.props.disabled || (this.props.value && this.props.value.isSameOrBefore(moment(), 'day'))}
              onClick={this.handleDecrementClick}
            >
              <TextLabel name='modifier'>
                <Icon name='chevron-left' />
                <TextLabel.Text text={_t.message('date-input-field.back-button-title')} />
              </TextLabel>
            </Button>
          </IncrementalInputWrapper.Modifier>
          <IncrementalInputWrapper.Input>
            <Form.TextField
              ref={input => { this.input = input }}
              id={this.props.id}
              name={this.props.name}
              value={this.props.value && this.props.value.format('L')}
              readOnly
              required={this.props.required}
              autoComplete='off'
              placeholder={this.props.placeholder}
              onTouchEnd={this.handleOpenModalEvent}
              onFocus={this.handleFocus}
              disabled={this.props.disabled}
            />
            <HotKeyLabel hotkey={this.props.hotkey} />
          </IncrementalInputWrapper.Input>
          <IncrementalInputWrapper.Modifier>
            <Button
              type='button'
              tabIndex='-1'
              name='modifier'
              onClick={this.handleIncrementClick}
              disabled={this.props.disabled}
            >
              <TextLabel name='modifier'>
                <Icon name='chevron-right' />
                <TextLabel.Text text={_t.message('date-input-field.next-button-title')} />
              </TextLabel>
            </Button>
          </IncrementalInputWrapper.Modifier>
          {this.renderModal()}
        </IncrementalInputWrapper>
      </HotKeys>
    )
  }

  onHideElement () {
    this.props.onHideElement(this.props.name)
    this.props.onCloseModal && this.props.onCloseModal()
  }

  renderModal () {
    return this.props.isElementVisible(this.props.name)
      ? <DateInputModal {...this.props} onClose={this.onHideElement} />
      : null
  }
}

export default containVisibleElement()(DateInputField)
