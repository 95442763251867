import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _t from 's3p-js-lib/src/translate'
import { FEEDBACK_SUCCESS, FEEDBACK_ALERT } from '../../../elements/feedback/feedback'
import FinalizeBookingModal from './finalize-booking-modal'
import { isOfflineSelectorCreator } from '../../../redux/selectors/machine/offline'
import { lastMachineErrorsSelector } from '../../../redux/selectors/machine/last-machine-errors'
import {
  printMagstripeTickets,
  continueMagstripeTickets
} from '../../../redux/actions/containers/base/finalize-booking/print-magstripe-modal'
import { printReceipt } from '../../../redux/actions/containers/base/tiles/payment-methods'
import { printTicketsModalSelector } from '../../../redux/selectors/containers/base/finalize-booking-modal'
import { DEVICE_ID_RECEIPT_PRINTER, DEVICE_ID_TICKET_PRINTER } from '../../../constants'

const NAMESPACE_MAGNETIC_TICKET_PRINTER_PRINT = 'magneticTicketPrinterPrint'
const NAMESPACE_RECEIPT_PRINTER = 'receiptPrinter'

class PrintMagstripeModalContainer extends Component {
  static propTypes = {
    printMagstripeTickets: PropTypes.func.isRequired,
    continueMagstripeTickets: PropTypes.func.isRequired,
    onNextSale: PropTypes.func.isRequired,
    isPrinting: PropTypes.bool,
    isOfflineTicketPrinter: PropTypes.bool,
    aftersales: PropTypes.bool,
    ticketErrorMessage: PropTypes.string,
    retrieveAndPrintCycle: PropTypes.bool,
    isOfflineReceiptPrinter: PropTypes.bool
  }

  UNSAFE_componentWillMount () {
    this.props.printMagstripeTickets()
  }

  render () {
    let message = this.props.isPrinting || this.props.retrieveAndPrintCycle
      ? null
      : this.props.ticketErrorMessage
        ? {
          status: FEEDBACK_ALERT,
          text: [_t.message('finalize-booking-modal.magstripe.status-error', {error: this.props.ticketErrorMessage})]
        }
        : {status: FEEDBACK_SUCCESS, text: [_t.message('finalize-booking-modal.magstripe.status-success')]}

    return (
      <FinalizeBookingModal
        {...this.props}
        title={_t.message('finalize-booking-modal.magstripe.title')}
        messages={message ? [message] : []}
        disableNextSale={this.props.isPrinting}
        showContinuePrinting
        onContinuePrinting={this.props.continueMagstripeTickets}
        isMagstripeFulfillment
      />
    )
  }
}

const ticketPrinterOfflineSelector = isOfflineSelectorCreator(DEVICE_ID_TICKET_PRINTER)
const receiptPrinterOfflineSelector = isOfflineSelectorCreator(DEVICE_ID_RECEIPT_PRINTER)
const _receiptLastMachineErrorsSelector = lastMachineErrorsSelector([NAMESPACE_RECEIPT_PRINTER])
const _ticketLastMachineErrorsSelector = lastMachineErrorsSelector([NAMESPACE_MAGNETIC_TICKET_PRINTER_PRINT])
let previousReceiptPrinterOffline = false
let showOfflineContinueButton = false

const mapStateToProps = state => {
  let ticketErrorMessage
  const error = state.machine.magneticTicketPrinter.error
  if (error) {
    ticketErrorMessage = error.response && error.response.results && error.response.results.message
      ? error.response.results.message
      : _t.message('finalize-booking-modal.magstripe.default-error')
  } else if (printTicketsModalSelector(state).ticketErrorMessage) {
    ticketErrorMessage = printTicketsModalSelector(state).ticketErrorMessage
  }
  const receiptPrinterIsOffline = receiptPrinterOfflineSelector(state)
  showOfflineContinueButton = showOfflineContinueButton || (!receiptPrinterIsOffline && previousReceiptPrinterOffline)
  previousReceiptPrinterOffline = receiptPrinterIsOffline

  return {
    isOfflineTicketPrinter: ticketPrinterOfflineSelector(state),
    isOfflineReceiptPrinter: receiptPrinterOfflineSelector(state),
    isPrinting: (
      state.api.loading.getMagstripeTickets ||
      state.machine.loading.printMagneticTicket ||
      printTicketsModalSelector(state).isPrinting
    ),
    retrieveAndPrintCycle: printTicketsModalSelector(state).retrievingAndPrintingTickets,
    ticketErrorMessage,
    hasPrintErrors: Boolean(
      _ticketLastMachineErrorsSelector(state).length ||
      _receiptLastMachineErrorsSelector(state).length ||
      receiptPrinterOfflineSelector(state) ||
      showOfflineContinueButton)
  }
}

const mapDispatchToProps = {
  printMagstripeTickets,
  continueMagstripeTickets: () => (dispatch, getState) => {
    let reprintActions = []
    if (_ticketLastMachineErrorsSelector(getState()).length ||
      printTicketsModalSelector(getState()).ticketErrorMessage) {
      reprintActions.push(continueMagstripeTickets())
    }
    if (_receiptLastMachineErrorsSelector(getState()).length ||
      receiptPrinterOfflineSelector(getState()) ||
      showOfflineContinueButton) {
      reprintActions.push(printReceipt())
    }
    dispatch(reprintActions)
    showOfflineContinueButton = false
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintMagstripeModalContainer)
