import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DropdownList from '../../elements/dropdown-list/dropdown-list'
import { productFamiliesSelector } from '../../redux/selectors/api/v2/meta/product-families'
import _t from 's3p-js-lib/src/translate'

class ProductFamilySelect extends Component {
  static propTypes = {
    productFamilies: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string
    })),
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool
  }

  render () {
    return (
      <DropdownList
        {...this.props}
        textField='name'
        valueField='code'
        data={this.props.productFamilies}
        value={this.props.value}
        placeholder={_t.message('edit-shortcut.form.product-family.placeholder')}
      />
    )
  }
}
const mapStateToProps = state => ({
  productFamilies: productFamiliesSelector(state)
})

export default connect(mapStateToProps)(ProductFamilySelect)
