import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../../../../elements/text-label/text-label'
import Button from '../../../../../elements/button/button'
import _t from 's3p-js-lib/src/translate'

export default class RefundCancel extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isAborting: PropTypes.bool,
    cancelRefund: PropTypes.func
  }

  render () {
    return (
      <Button
        name='cancel'
        className='secondary'
        disabled={this.props.isLoading || this.props.disabled}
        onClick={this.props.cancelRefund}
        loading={this.props.isAborting && this.props.isLoading}
      >
        <TextLabel name='cancel' text={_t.message('aftersales.refund-modal.cancel')} />
      </Button>
    )
  }
}
