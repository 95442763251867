import composeReducers from 's3p-js-lib/src/redux/lib/compose-reducers'
import baseReducers from 's3p-js-lib/src/redux/reducers/api/booking'
import baseAftersalesOriginalBooking from 's3p-js-lib/src/redux/reducers/api/booking/aftersales-original-booking'
import aftersalesOriginalBooking from './aftersales-original-booking'
import barcodeDocument from './barcode-document'
import basebookingList from 's3p-js-lib/src/redux/reducers/api/booking/booking-list'
import bookingList from './booking-list'
import giftVoucherDocument from './gift-voucher-document'
import refundVoucherDocument from './refund-voucher-document'

export default {
  ...baseReducers,
  aftersalesOriginalBooking: composeReducers(baseAftersalesOriginalBooking, aftersalesOriginalBooking),
  barcodeDocument,
  bookingList: composeReducers(basebookingList, bookingList),
  giftVoucherDocument,
  refundVoucherDocument
}
