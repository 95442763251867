import { createSelector } from 'reselect'
import { productFamiliesSelector as baseProductFamiliesSelector } from '../../api/v2/meta/product-families'
import { formFieldValueSelectorCreator } from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import { FORM_PRODUCT_SEARCH } from 's3p-js-lib/src/constants-form'

export const productFamiliesSelector = createSelector(
  [
    baseProductFamiliesSelector,
    formFieldValueSelectorCreator(FORM_PRODUCT_SEARCH, 'productFamily')
  ],
  (productFamilies, selectedProductFamily) => {
    return productFamilies.map(productFamily =>
      selectedProductFamily && productFamily.code === selectedProductFamily.code
        ? {...productFamily, selected: true}
        : productFamily
    )
  }
)

export const ticketsApiLoadingSelector = state => (
  state.api.v2.loading.createBooking ||
  state.api.v2.loading.updateFulfillmentMethod ||
  state.api.loading.getPaymentMethods ||
  state.api.v2.loading.updateSegments ||
  state.api.v2.loading.getProducts ||
  state.api.v2.loading.removeItems ||
  state.api.v2.loading.confirmBooking ||
  state.api.loading.getReceiptDocument
)
