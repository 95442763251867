import { createSelector } from 'reselect'
import { routeSelector } from './journey-result-set'
import { journeyDetailsSelector } from 's3p-js-lib/src/redux/selectors/api/orientation/offer/journey-details'

const getAllStationsOfService = service =>
  [service.departureStation].concat(service.viaStations, service.arrivalStation)

const findStation = stationToBeFound => station => station.UICStationCode === stationToBeFound.UICStationCode

export const allServiceStationsSelectorCreator = routeId => createSelector(
  [
    journeyDetailsSelector,
    routeSelector(routeId)
  ],
  (services, route) => {
    const stations = route.legs.reduce(
      (_stations, leg) => {
        const serviceStations =
          services[leg.serviceIdentifier] ? getAllStationsOfService(services[leg.serviceIdentifier]) : []
        const startStationIndex = serviceStations.findIndex(findStation(leg.departureStation))
        const endStationIndex = serviceStations.findIndex(findStation(leg.arrivalStation))
        const legStations = serviceStations
          .slice(startStationIndex + (_stations.length > 0 ? 1 : 0), endStationIndex + 1)
          .map(station => ({
            ...station,
            transfer: route.transfers.find(transfer => transfer.departureStationName === station.name),
            arrivalTime: leg.departureStation.name === station.name ? undefined : station.arrivalTime,
            departureTime: leg.arrivalStation.name === station.name ? undefined : station.departureTime
          }))
        _stations.push(...legStations)
        return _stations
      },
      []
    )

    return stations
  }
)

export const himMessagesSelector = routeSelector => createSelector(
  [
    routeSelector
  ],
  route => {
    return route ? route.legs.reduce(
      (info, leg) => {
        if (leg.travelInfo) {
          info.push(...leg.travelInfo)
        }
        return info
      },
      []
    ) : []
  }
)

export const servicePropertySelector = routeSelector => createSelector(
  [
    routeSelector
  ],
  route => {
    return route ? route.legs.reduce(
      (serviceProperties, leg) => {
        if (leg.serviceProperties) {
          leg.serviceProperties.forEach(property => {
            if (!serviceProperties.some(serviceProperty => serviceProperty.code === property.code)) {
              serviceProperties.push(property)
            }
          })
        }
        return serviceProperties
      },
      []
    ) : []
  }
)
