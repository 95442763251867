/* globals DEBUG_MODE */

import PropTypes from 'prop-types'

import React from 'react'
import classNames from 'classnames'
import './icon.scss'

const Icon = ({name, className, ...props}) => {
  try {
    const iconPath = require(`../../assets/img/icon/${name}.svg`).default
    return (
      <svg
        {...props}
        className={classNames('icon', `icon-${name}`, className)}
        aria-labelledby={`icon-${name}-title`}
        role='img'
        key={name}
      >
        <title id={`icon-${name}-title`}>{name}</title>
        <use pointerEvents='none' xlinkHref={iconPath} />
      </svg>
    )
  } catch (e) {
    if (DEBUG_MODE) {
      console.error(`icon ${name}.svg could not be found`)
    }
    return null
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default Icon
