import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { getPropsForComponent } from '../../misc/utils'
import TriStateCheckbox from '../checkbox-radiobutton/tri-state-checkbox-field'
import classNames from 'classnames'
import pick from 'lodash/pick'
import attachCheckRadioWrapper from '../checkbox-radiobutton/check-radio-wrapper'
import './input.scss'

class InputField extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    required: PropTypes.bool,
    active: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    className: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    autoComplete: PropTypes.string,
    readOnly: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    checked: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onTouchEnd: PropTypes.func,
    type: PropTypes.oneOf([
      'checkbox',
      'color',
      'date',
      'datetime',
      'email',
      'file',
      'hidden',
      'image',
      'month',
      'number',
      'password',
      'radio',
      'range',
      'reset',
      'search',
      'submit',
      'tel',
      'text',
      'time',
      'url',
      'week'
    ]).isRequired
  }

  static defaultProps = {
    value: ''
  }

  shouldComponentUpdate (nextProps) {
    return nextProps.required !== this.props.required ||
      nextProps.active !== this.props.active ||
      nextProps.touched !== this.props.touched ||
      nextProps.valid !== this.props.valid ||
      nextProps.disabled !== this.props.disabled ||
      nextProps.value !== this.props.value ||
      nextProps.checked !== this.props.checked ||
      nextProps.name !== this.props.name ||
      nextProps.placeholder !== this.props.placeholder ||
      nextProps.type !== this.props.type ||
      nextProps.className !== this.props.className
  }

  render () {
    const {
      defaultValue,
      name,
      className,
      required,
      active,
      valid,
      touched,
      ...rest
    } = pick(this.props, Object.keys(InputField.propTypes))
    const isEmptyAndNotRequired = !rest.value && !required
    const fullClassName = classNames(
      className,
      'input',
      name ? `input-${name}` : '',
      {'state--is-invalid': !isEmptyAndNotRequired && !active && !valid && touched},
      {'state--is-valid': !isEmptyAndNotRequired && !active && valid && touched}
    )

    return <input value={defaultValue} className={fullClassName} name={name} {...rest} />
  }
}

const InputFieldTextArea = props => {
  const {defaultValue, ...rest} = getPropsForComponent(props, InputFieldTextArea)
  return <textarea value={defaultValue} {...rest} />
}

InputFieldTextArea.propTypes = {
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  tabIndex: PropTypes.string
}

InputFieldTextArea.defaultProps = {
  value: ''
}

const InputFieldText = props => <InputField type='text' {...props} />
const InputFieldPassword = props => <InputField type='password' {...props} />
const InputFieldEmail = props => <InputField type='email' {...props} />
const InputFieldHidden = props => <InputField type='hidden' {...props} />
const InputFieldTelephone = props => <InputField type='tel' {...props} />
const InputFieldTriStateCheckbox = attachCheckRadioWrapper(TriStateCheckbox)
const InputFieldCheckbox = attachCheckRadioWrapper(props => <InputField type='checkbox' {...props} />)
const InputFieldRadio = attachCheckRadioWrapper(props => <InputField type='radio' {...props} />)

InputField.Text = InputFieldText
InputField.Password = InputFieldPassword
InputField.Email = InputFieldEmail
InputField.Hidden = InputFieldHidden
InputField.Telephone = InputFieldTelephone
InputField.TriStateCheckbox = InputFieldTriStateCheckbox
InputField.Checkbox = InputFieldCheckbox
InputField.Radio = InputFieldRadio
InputField.TextArea = InputFieldTextArea

export default InputField
