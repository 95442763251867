import { connect } from 'react-redux'
import { fulfillmentMethodSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import ProgressNavigation from '../../../../components/aftersales/update-passengers/progress-navigation'
import { canOverrideValidationRulesFullOrIssueSelector } from '../../../../redux/selectors/api/auth/auth'
import { isMagstripeOrTVMFulfillment, isLeapTopUpFulfillment } from '../../../../misc/utils'
import { goToAftersalesUpdatePassengers } from '../../../../redux/actions/containers/base/routing'
import { showModal, hideElement } from '../../../../redux/actions/containers/base/visible-element'
import { elementVisibleSelector } from '../../../../redux/selectors/containers/base/visible-element'
import { VOID_TICKETS_MODAL } from '../../../../constants'
import { originalOrOverviewBookingSelector } from '../../../../redux/selectors/api/booking/booking'

const _fulfillmentMethodSelector = fulfillmentMethodSelector(originalOrOverviewBookingSelector)
const _canOverrideValidationRulesFullOrIssueSelector = canOverrideValidationRulesFullOrIssueSelector(originalOrOverviewBookingSelector)
const isVoidModalVisibleSelector = elementVisibleSelector(VOID_TICKETS_MODAL)

const mapStateToProps = state => {
  const fulfillmentMethod = _fulfillmentMethodSelector(state)
  const voidTickets = state.containers.aftersales.voidTickets

  let nextButtonDisabled = !_canOverrideValidationRulesFullOrIssueSelector(state) || voidTickets.isRunning

  if (isMagstripeOrTVMFulfillment(fulfillmentMethod)) {
    nextButtonDisabled = (nextButtonDisabled && !voidTickets.success)
  } else if (isLeapTopUpFulfillment(fulfillmentMethod)) {
    nextButtonDisabled = (nextButtonDisabled && !state.containers.aftersales.cancellation.reverseLeap.success)
  }
  return ({
    loading: voidTickets.isRunning,
    disabled: nextButtonDisabled,
    showVoidModal: isVoidModalVisibleSelector(state)
  })
}

const mapDispatchToProps = {
  onClickNext: () => (dispatch, getState) => {
    const fulfillmentMethod = _fulfillmentMethodSelector(getState())
    if (isMagstripeOrTVMFulfillment(fulfillmentMethod) && !getState().containers.aftersales.voidTickets.success && !isVoidModalVisibleSelector(getState())) {
      return dispatch(showModal(VOID_TICKETS_MODAL))
    }
    dispatch(hideElement(VOID_TICKETS_MODAL))
    dispatch(goToAftersalesUpdatePassengers())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressNavigation)

