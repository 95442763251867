import { connect } from 'react-redux'
import {
  enableBikeFilter,
  disableBikeFilter
} from '../../../../redux/actions/containers/base/journey-search/passenger-preferences'
import PassengerPreferences from '../../../../components/base/journey-search/journey-search-form/passenger-preferences'

const mapStateToProps = state => state.containers.reservations.passengerPreferences

const mapDispatchToProps = {
  enableBikeFilter,
  disableBikeFilter
}

export default connect(mapStateToProps, mapDispatchToProps)(PassengerPreferences)
