import {
  API_START_AGENT_SHIFT_REQUEST,
  API_START_AGENT_SHIFT_SUCCESS,
  API_START_AGENT_SHIFT_FAILURE,
  API_GET_AGENT_SHIFT_SUCCESS,
  API_GET_AGENT_SHIFT_FAILURE,
  API_ADD_NOTES_AGENT_SHIFT_SUCCESS,
  API_END_AGENT_SHIFT_SUCCESS,
  CLEAR_STATE
} from '../../../../actions/types'
import {
  API_END_AGENT_SHIFT_FAILURE,
  API_HEAD_AGENT_SHIFT_FAILURE
} from 's3p-js-lib/src/redux/actions/types'

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_START_AGENT_SHIFT_SUCCESS:
    case API_GET_AGENT_SHIFT_SUCCESS:
    case API_ADD_NOTES_AGENT_SHIFT_SUCCESS:
    case API_END_AGENT_SHIFT_SUCCESS:
      return action.response.data?.shift
    case API_START_AGENT_SHIFT_REQUEST:
    case API_START_AGENT_SHIFT_FAILURE:
    case API_GET_AGENT_SHIFT_FAILURE:
    case API_END_AGENT_SHIFT_FAILURE:
    case API_HEAD_AGENT_SHIFT_FAILURE:
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes('api.user.agent.shift') ? initialState : state
    default:
      return state
  }
}
