/* globals S3P_SETTINGS */

import { createSelector } from 'reselect'
import {
  subFormFieldValuesSelectorCreator,
  formFieldValueSelectorCreator,
  formFieldValuesSelectorCreator
} from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import {
  passengersCompareSelector,
  passengerCompareString,
  passengersSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/passengers'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { useAutomaticPassengerDetailsSelector } from 's3p-js-lib/src/redux/selectors/api/user/sales-channel-properties'
import { FORM_CUSTOMER_DETAILS } from 's3p-js-lib/src/constants-form'

const customerDetailsFormFieldValuesSelector = formFieldValuesSelectorCreator(FORM_CUSTOMER_DETAILS)

const automaticPassengerDetailsSelector =
  (bookingSelector, passengerDetailsFormFieldValuesSelector) => createSelector(
    [
      passengersSelector(bookingSelector),
      passengerDetailsFormFieldValuesSelector,
      customerDetailsFormFieldValuesSelector
    ],
    (passengers, passengerDetails, customerDetails) => passengersWithGroupName(
      passengers,
      passengerDetails.groupName || customerDetails.lastName
    )
  )

export const passengersWithGroupName = (passengers, groupName) => (passengers || []).map(
  (passenger, index) => ({
    ...passenger,
    firstName: S3P_SETTINGS.s3Passenger.features.passengerDetails.groupBookingFirstName,
    lastName: `${groupName} ${index + 1}`
  })
)

export const manualPassengerDetailsSelector =
  (bookingSelector, passengerDetailsSubFormFieldValuesSelector) => createSelector(
    [
      passengersSelector(bookingSelector),
      passengerDetailsSubFormFieldValuesSelector
    ],
    (passengers, passengerDetails) => passengers.map(
      passenger => ({
        ...passenger,
        ...passengerDetails[passenger.id]
      })
    )
  )

export const passengerDetailsSelector = formName => createSelector(
  [
    useAutomaticPassengerDetailsSelector(bookingSelector),
    automaticPassengerDetailsSelector(bookingSelector, formFieldValuesSelectorCreator(formName)),
    manualPassengerDetailsSelector(bookingSelector, subFormFieldValuesSelectorCreator(formName)),
    formFieldValueSelectorCreator(formName, 'protectPrivacy')
  ],
  (useAutomaticDetails, automaticDetails, manualDetails, protectPrivacy) =>
    Object.values(useAutomaticDetails ? automaticDetails : manualDetails).map(passenger => ({
      ...passenger,
      protectPrivacy,
      firstName: passenger.firstName || '',
      lastName: passenger.lastName || ''
    }))
)

export const updatedPassengerDetailsSelector = formName => createSelector(
  [
    passengerDetailsSelector(formName),
    passengersCompareSelector(originalBookingSelector)
  ],
  (passengers, originalPassengersCompare) => passengers.reduce(
    (updatedPassengers, passenger) => {
      if (passengerCompareString(passenger) !== originalPassengersCompare[passenger.id]) {
        updatedPassengers.push(passenger)
      }

      return updatedPassengers
    },
    []
  )
)
