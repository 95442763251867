import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ComponentContainer from '../../../elements/component-container/component-container'
import _t from 's3p-js-lib/src/translate'
import Panel from '../../../elements/panel/panel'
import TextLabel from '../../../elements/text-label/text-label'
import DescriptionList from '../../../elements/description-list/description-list'
import countries from 'countries-list'
import './customer-details.scss'

export default class CustomerDetails extends Component {
  static propTypes = {
    customer: PropTypes.object
  }

  render () {
    const customer = this.props.customer
    return customer
      ? (
        <ComponentContainer name='booking-customer-details'>
          <Panel
            header={
              <Panel.Header
                name='booking-overview-customer-details'
                title={_t.message('aftersales.booking.customer.header.title')}
                description={_t.message('aftersales.booking.customer.header.description')}
              />
            }
          >
            <DescriptionList>
              <dt><TextLabel name='term' text={_t.message('aftersales.booking.customer.name')} /></dt>
              <dd><TextLabel
                name='description'
                text={`${customer.title || ''} ${customer.firstName || ''} ${customer.lastName || ''}`} />
              </dd>
              <dt><TextLabel name='term' text={_t.message('aftersales.booking.customer.email')} /></dt>
              <dd><TextLabel name='description' text={customer.email || ''} /></dd>
              <dt><TextLabel name='term' text={_t.message('aftersales.booking.customer.address')} /></dt>
              <dd><TextLabel
                name='description'
                text={`${customer.street || ''} ${customer.houseNumber || ''}`} />
              </dd>
              <dt><TextLabel name='term' text={_t.message('aftersales.booking.customer.postal-code')} /></dt>
              <dd><TextLabel name='description' text={`${customer.postalCode || ''}`} /></dd>
              <dt><TextLabel name='term' text={_t.message('aftersales.booking.customer.city')} /></dt>
              <dd><TextLabel name='description' text={customer.city || ''} /></dd>
              <dt><TextLabel name='term' text={_t.message('aftersales.booking.customer.country')} /></dt>
              <dd><TextLabel
                name='description'
                text={customer.countryCode ? countries[customer.countryCode].name : ''} /></dd>
              <dt><TextLabel name='term' text={_t.message('aftersales.booking.customer.phone')} /></dt>
              <dd><TextLabel name='description' text={customer.phoneNumber || ''} /></dd>
            </DescriptionList>
          </Panel>
        </ComponentContainer>) : null
  }
}
