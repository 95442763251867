import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { rebookingSelectionSelector, rebookingShowSelector } from '../../../../redux/selectors/user-input/aftersales/rebooking/selection'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import { rebookingSelection } from '../../../../redux/actions/containers/aftersales/rebooking/journey-search'
import {
  inboundTariffSegmentsSelector,
  outboundTariffSegmentsSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/tariff-segments'
import {
  travelSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/travel'
import RebookingSelection from '../../../../components/aftersales/rebooking/selection'

class RebookingSelectionContainer extends Component {
  static propTypes = {
    rebookingSelection: PropTypes.func,
    selection: PropTypes.string
  }

  constructor (...args) {
    super(...args)

    this.handleRebookingSelection = this.handleRebookingSelection.bind(this)
  }

  handleRebookingSelection () {
    this.props.rebookingSelection(this.props.selection)
  }

  render () {
    return (
      <RebookingSelection {...this.props} />
    )
  }
}

const _originInboundSelector = travelSelector(inboundTariffSegmentsSelector(originalBookingSelector))
const _destinationInboundSelector = travelSelector(inboundTariffSegmentsSelector(originalBookingSelector))
const _originOutboundSelector = travelSelector(outboundTariffSegmentsSelector(originalBookingSelector))
const _destinationOutboundSelector =
  travelSelector(outboundTariffSegmentsSelector(originalBookingSelector))

const mapStateToProps = state => {
  const showSelection = rebookingShowSelector(state)
  return {
    inboundOriginDestination: showSelection ? {
      originName: _originInboundSelector(state).departureStation.name,
      destinationName: _destinationInboundSelector(state).arrivalStation.name
    } : null,
    outboundOriginDestination: showSelection ? {
      originName: _originOutboundSelector(state).departureStation.name,
      destinationName: _destinationOutboundSelector(state).arrivalStation.name
    } : null,
    selection: rebookingSelectionSelector(state),
    showSelection
  }
}

const mapDispatchToProps = {
  rebookingSelection
}

export default connect(mapStateToProps, mapDispatchToProps)(RebookingSelectionContainer)
