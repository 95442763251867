import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import ComponentContainer from '../../../elements/component-container/component-container'
import Panel from '../../../elements/panel/panel'
import PaymentOverview from '../../../containers/base/review-payment/payment-overview'
import PaymentMethods from '../../../containers/base/review-payment/payment-methods'
import Loader, { TYPE_COMPONENT } from '../../../elements/loader/loader'
import './review-payment.scss'
import Feedback, { FEEDBACK_ALERT } from '../../../elements/feedback/feedback'

export default class ReviewPayment extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    showPaymentMethods: PropTypes.bool.isRequired,
    showFulfillmentMethodError: PropTypes.bool.isRequired
  }

  render () {
    return (
      <ComponentContainer name='review-payment'>
        <PaymentOverview />
        <div className='payment-methods'>
          {this.props.loading ? <Loader type={TYPE_COMPONENT} /> : null}
          {this.props.showPaymentMethods || this.props.showFulfillmentMethodError
            ? (
              <Panel
                header={<Panel.Header
                  name='payment-methods'
                  title={_t.message('review-payment.payment-methods-title')}
                  description={_t.message('review-payment.payment-methods-description')}
                />}
              >
                {this.props.showPaymentMethods ? <PaymentMethods /> : null}
                {this.props.showFulfillmentMethodError
                  ? <Feedback
                    status={FEEDBACK_ALERT}
                    text={_t.message('aftersales.rebooking.no-booking-fulfillment-method')}
                  /> : null}
              </Panel>
            ) : null}
        </div>
      </ComponentContainer>
    )
  }
}
