import {
  API_LOAD_AGENT_INFORMATION_REQUEST,
  API_LOAD_AGENT_INFORMATION_SUCCESS,
  API_LOAD_AGENT_INFORMATION_FAILURE
} from '../../types'
import Client from '../../../../api/client'
import ensureToken from '../../../../api/ensure-token'

const loadAgentInformationRequest = () => ({type: API_LOAD_AGENT_INFORMATION_REQUEST})
const loadAgentInformationSuccess = response => ({type: API_LOAD_AGENT_INFORMATION_SUCCESS, response})
const loadAgentInformationFailure = error => ({type: API_LOAD_AGENT_INFORMATION_FAILURE, error})

export const loadAgentInformation = () => ensureToken(async (token, dispatch) => {
  try {
    dispatch(loadAgentInformationRequest())
    const response = await Client.loadAgentUserInformation(token)
    dispatch(loadAgentInformationSuccess(response))
  } catch (error) {
    dispatch(loadAgentInformationFailure(error))
  }
})
