import { getBooking } from 's3p-js-lib/src/redux/actions/api/booking/booking'
import { shouldVoidMagstripeTicketsSelector } from '../../../../selectors/containers/aftersales/void-tickets'
import { overviewBookingSelector, bookingNumberSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { rebookingInitiate } from 's3p-js-lib/src/redux/actions/api/aftersales/rebooking/initiate'
import { goToAftersalesRebookingVoidTickets, goToAftersalesRebookingJourneySearch } from '../../base/routing'

export const goToRebooking = () => async (dispatch, getState) => {
  const bookingNumber = bookingNumberSelector(overviewBookingSelector)(getState())
  await dispatch(getBooking(bookingNumber))

  if (shouldVoidMagstripeTicketsSelector(overviewBookingSelector)(getState())) {
    dispatch(goToAftersalesRebookingVoidTickets(bookingNumber))
  } else {
    await dispatch(rebookingInitiate(bookingNumber))
    dispatch(goToAftersalesRebookingJourneySearch())
  }
}
