import { createSelector } from 'reselect'
import { stationMapper } from '../orientation/stations'
import {
  requiredOutboundProductsSelector,
  requiredInboundProductsSelector,
  additionalOutboundProductsSelector,
  additionalInboundProductsSelector
} from './products'
import { camelCaseKeys, getUniqueBookingTariffSegmentIdentifier } from '../../../../misc/utils'
import { toUtcDateMoment, toTimezoneMoment } from '../../../../misc/date'
import { TRAVEL_DIRECTION_OUTBOUND, TRAVEL_DIRECTION_INBOUND } from '../../../../constants'

const tariffSegmentMapper = (segment, requiredProducts, additionalProducts, direction) => {
  const journeySegments = segment.booking_journey_segments
  let departureDate, arrivalDate
  if (journeySegments.length > 0) {
    const firstJourneySegment = journeySegments[0]
    const lastJourneySegment = journeySegments[journeySegments.length - 1]
    departureDate = toTimezoneMoment(
      firstJourneySegment.departure_date_time,
      firstJourneySegment.departure_station && firstJourneySegment.departure_station.timezone
    )
    arrivalDate = toTimezoneMoment(
      lastJourneySegment.arrival_date_time,
      lastJourneySegment.arrival_station && lastJourneySegment.arrival_station.timezone
    )
  } else {
    departureDate = toUtcDateMoment(segment.validity_start_date)
    arrivalDate = toUtcDateMoment(segment.validity_end_date)
  }

  return {
    ...camelCaseKeys(segment),
    travelDate: toUtcDateMoment(segment.travel_date),
    uniqueIdentifier: getUniqueBookingTariffSegmentIdentifier(segment),
    departureDate,
    arrivalDate,
    departureStation: segment.departure_station && stationMapper(segment.departure_station),
    arrivalStation: segment.arrival_station && stationMapper(segment.arrival_station),
    serviceName: segment.validity_service,
    requiredProducts: segment.required_products.map(
      ({item_id: itemId}) => requiredProducts.find(product => product.itemId === itemId)
    ),
    additionalProducts: segment.additional_products.map(
      ({item_id: itemId}) => additionalProducts.find(product => product.itemId === itemId)
    ),
    direction
  }
}

export const inboundTariffSegmentsSelector = bookingSelector => createSelector(
  [
    bookingSelector,
    requiredInboundProductsSelector(bookingSelector),
    additionalInboundProductsSelector(bookingSelector)
  ],
  (booking, requiredProducts, additionalProducts) => booking
    ? booking.inbound_booking_tariff_segments.map(
      segment => tariffSegmentMapper(segment, requiredProducts, additionalProducts, TRAVEL_DIRECTION_INBOUND)
    )
    : []
)

export const outboundTariffSegmentsSelector = bookingSelector => createSelector(
  [
    bookingSelector,
    requiredOutboundProductsSelector(bookingSelector),
    additionalOutboundProductsSelector(bookingSelector)
  ],
  (booking, requiredProducts, additionalProducts) => booking ? booking.outbound_booking_tariff_segments.map(
    segment => tariffSegmentMapper(segment, requiredProducts, additionalProducts, TRAVEL_DIRECTION_OUTBOUND)
  ) : []
)

export const tariffSegmentsSelector = bookingSelector => createSelector(
  [
    outboundTariffSegmentsSelector(bookingSelector),
    inboundTariffSegmentsSelector(bookingSelector)
  ],
  (outboundTariffSegments, inboundTariffSegments) => outboundTariffSegments.concat(inboundTariffSegments)
)

export const groupByUniqueIdentifier = tariffSegmentsSelector => createSelector(
  [tariffSegmentsSelector],
  tariffSegments => Object.values(tariffSegments.reduce(
    (groupedTariffSegments, tariffSegment) => {
      if (tariffSegment.uniqueIdentifier in groupedTariffSegments) {
        const groupedTariffSegment = groupedTariffSegments[tariffSegment.uniqueIdentifier]
        groupedTariffSegment.requiredProducts = groupedTariffSegment.requiredProducts.concat(
          tariffSegment.requiredProducts
        )
        groupedTariffSegment.additionalProducts = groupedTariffSegment.additionalProducts.concat(
          tariffSegment.additionalProducts
        )
      } else {
        groupedTariffSegments[tariffSegment.uniqueIdentifier] = {...tariffSegment}
      }

      return groupedTariffSegments
    },
    {}
  ))
)

export const filterSegmentsByProduct = productTest => tariffSegmentSelector => createSelector(
  [tariffSegmentSelector],
  tariffSegments => tariffSegments.reduce((segments, segment) => {
    const newSegment = {...segment}
    const filteredRequiredProducts =
      segment.requiredProducts ? segment.requiredProducts.filter(productTest) : []
    const filteredAdditionalProducts =
      segment.additionalProducts ? segment.additionalProducts.filter(productTest) : []

    if (filteredRequiredProducts.length > 0 || filteredAdditionalProducts.length > 0) {
      if (filteredRequiredProducts.length > 0) {
        newSegment.requiredProducts = filteredRequiredProducts
      }
      if (filteredAdditionalProducts.length > 0) {
        newSegment.additionalProducts = filteredAdditionalProducts
      }
      segments.push(newSegment)
    }

    return segments
  }, [])
)

export const withoutCancelledSelector = tariffSegmentsSelector => createSelector(
  [tariffSegmentsSelector],
  tariffSegments => tariffSegments.filter(({requiredProducts}) => !requiredProducts.every(({cancelled}) => cancelled))
)
