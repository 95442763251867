import { createSelector } from 'reselect'
import moment from 'moment'
import { camelCaseKeys } from 's3p-js-lib/src/misc/utils'
import { toTimezoneMoment } from 's3p-js-lib/src/misc/date'
import { passengersSelector } from 's3p-js-lib/src/redux/selectors/api/booking/passengers'
import { productFamiliesSelector } from 's3p-js-lib/src/redux/selectors/api/booking/product-families'
import { groupedTariffSegmentsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/segments'

const flatPick = (array, key) => array.reduce((returnArray, item) => returnArray.concat(item[key] || []), [])

const getStationName = station => station.name || station._u_i_c_station_code

const productMapper = (timezone, passengers, productFamilies) => ({passenger_id: passengerId, product_family_id: productFamilyId, ...product}) => ({
  ...camelCaseKeys(product),
  passenger: passengers.find(passenger => passenger.id === passengerId),
  productFamily: productFamilies.find(productFamily => productFamily.id === productFamilyId),
  afterSalesRules: product.after_sales_rules.map(rule => ({
    ...camelCaseKeys(rule),
    validUntil: toTimezoneMoment(rule.valid_until, timezone)
  }))
})

export const tariffSegmentsSelector = (bookingSelector, tariffSegmentSelector) => createSelector(
  [
    groupedTariffSegmentsSelector(tariffSegmentSelector),
    passengersSelector(bookingSelector),
    productFamiliesSelector(bookingSelector)
  ],
  (tariffSegments, passengers, productFamilies) => {
    return tariffSegments.length > 0
      ? tariffSegments.map(groupedSegments => {
        const journeySegment = flatPick(groupedSegments, 'booking_journey_segments')[0]
        const firstSegment = groupedSegments[0]
        const timezone = journeySegment && journeySegment.departure_station && journeySegment.departure_station.timezone
        const tariffSegmentProductMapper = productMapper(timezone, passengers, productFamilies)
        const requiredProducts = flatPick(groupedSegments, 'required_products').map(tariffSegmentProductMapper)

        const getProduct = passengerId => requiredProducts.find(product => passengerId === product.passenger.id && !product.cancelled)

        return {
          id: firstSegment.id,
          departureDate: journeySegment && journeySegment.departure_date_time && toTimezoneMoment(
            journeySegment.departure_date_time,
            timezone
          ),
          arrivalDate: journeySegment && journeySegment.arrival_date_time && toTimezoneMoment(
            journeySegment.arrival_date_time,
            journeySegment.arrival_station.timezone
          ),
          originName: firstSegment.departure_station && getStationName(firstSegment.departure_station),
          destinationName: firstSegment.arrival_station && getStationName(firstSegment.arrival_station),
          serviceName: firstSegment.validity_service,
          additionalProducts: flatPick(groupedSegments, 'additional_products').map(tariffSegmentProductMapper),
          fees: flatPick(groupedSegments, 'fees').filter(fee => !fee.cancelled).map(camelCaseKeys),
          requiredProducts,
          passengers: passengers.map(passenger => {
            const product = getProduct(passenger.id)
            return {
              ...passenger,
              seat: product ? product.seat : null,
              birthDate: product && product.passenger.birthDate ? moment(product.passenger.birthDate) : null
            }
          })
        }
      })
      : null
  }
)
