import {
  UI_TABLES_SORT,
  UI_TABLES_CLEAR
} from 's3p-js-lib/src/redux/actions/types'

const clearTableFromState = (tableName, state) => {
  const newState = {...state}
  delete newState[tableName]
  return newState
}

export default function (state = {}, action) {
  switch (action.type) {
    case UI_TABLES_SORT:
      return {
        ...state,
        [action.tableName]: {
          column: action.column,
          order: action.order
        }
      }
    case UI_TABLES_CLEAR:
      return clearTableFromState(action.tableName, state)
    default:
      return state
  }
}
