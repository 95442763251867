import PropTypes from 'prop-types'
import React from 'react'
import _t from 's3p-js-lib/src/translate'
import kebabCase from 'lodash/kebabCase'

export default Component => (class FormContextAwareInputFeedback extends React.Component {
  static contextTypes = {
    formContext: PropTypes.string
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    error: PropTypes.string
  }

  render () {
    const {error, ...rest} = this.props
    if (!error) {
      return null
    }

    return (
      <Component
        text={_t.message(`${this.context.formContext}.form.${kebabCase(rest.name)}.${error}`)}
        {...rest}
      />
    )
  }
})
