import Validator from 's3p-js-lib/src/forms/validator'
import baseForm from 's3p-js-lib/src/forms/bookings-filter'

export default {
  ...baseForm,
  fields: {
    ...baseForm.fields,
    term: {
      ...baseForm.fields.term,
      validators: (baseForm.fields.term.validators || []).filter(validator => validator !== Validator.isRequired)
    }
  }
}
