import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Modal from '../../../elements/modal/modal'
import TextLabel from '../../../elements/text-label/text-label'
import Button from '../../../elements/button/button'
import _t from 's3p-js-lib/src/translate'
import ShiftModalForm from './modal-form'
import Feedback, { FEEDBACK_SUCCESS, FEEDBACK_INFORMATION } from '../../../elements/feedback/feedback'
import './shift.scss'

export default class ShiftModal extends Component {
  static propTypes = {
    onHideElement: PropTypes.func.isRequired,
    noteSavedSuccessfully: PropTypes.bool.isRequired,
    onSaveNoteShift: PropTypes.func.isRequired,
    onEndShift: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    shiftEndedRemotely: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    onPrintShiftReport: PropTypes.func.isRequired,
    isLoadingPrintShiftReport: PropTypes.bool,
    shift: PropTypes.object,
    showChangePasswordModal: PropTypes.bool,
    onChangePassword: PropTypes.func
  }

  render () {
    const isDisabled = this.props.shiftEndedRemotely || !this.props.shift
    return (
      <Modal
        onHideElement={this.props.onHideElement}
        name='shift-information'
        isCloseable={!isDisabled}
        header={
          <Modal.Header
            title={_t.message('shift-modal.title')}
            disabled={isDisabled}
          />}
        footer={this._renderFooterWithFourButtons()}
      >
        {this.props.noteSavedSuccessfully
          ? (
            <Feedback
              status={FEEDBACK_SUCCESS}
              text={_t.message('shift-modal.saved-successful')}
            />
          )
          : null
        }
        {this.props.shiftEndedRemotely
          ? <Feedback
            status={FEEDBACK_INFORMATION}
            text={_t.message('shift-modal.shift-ended-remotely')}
          />
          : null}
        <ShiftModalForm {...this.props} />
      </Modal>
    )
  }

  _renderFooterWithFourButtons () {
    return (
      <Modal.CustomFooter>
        <Button
          name='logout'
          className='tertiary'
          onClick={this.props.onLogout}
          hotkey='logout'
        >
          <TextLabel name='logout' text={_t.message('shift-modal.form.logout')} />
        </Button>
        <Button
          name='change-password'
          className='secondary'
          onClick={this.props.onChangePassword}
          hotkey='cancel'
        >
          <TextLabel name='change-password' text={_t.message('shift-modal.form.change-password')} />
        </Button>
        {!this.props.shiftEndedRemotely && this.props.shift
          ? [
            <Button
              key='end-shift'
              name='end-shift'
              className='secondary'
              onClick={this.props.onEndShift}
              loading={this.props.loading}
              hotkey='focusEuro'>
              <TextLabel name='end-shift' text={_t.message('shift-modal.form.end-shift')} />
            </Button>,
            <Button
              key='save-notes'
              name='save-notes'
              className='primary'
              onClick={this.props.onSaveNoteShift}
              loading={this.props.loading}
              hotkey='incrementStudent'>
              <TextLabel name='save-notes' text={_t.message('shift-modal.form.save-note')} />
            </Button>]
          : <Button
            name='print-shift-report'
            className='secondary'
            onClick={this.props.onPrintShiftReport}
            loading={this.props.isLoadingPrintShiftReport}
            disabled={!this.props.shift}
            hotkey='focusProduct'
          >
            <TextLabel name='print-shift-report' text={_t.message('shift-modal.form.print-shift-report')} />
          </Button>
        }
      </Modal.CustomFooter>
    )
  }
}
