import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Icon from '../../../../../src/elements/icon/icon'
import Button from '../../../../elements/button/button'
import TextLabel from '../../../../elements/text-label/text-label'

export default class VouchersRow extends Component {
  static propTypes = {
    voucher: PropTypes.shape({
      code: PropTypes.string.isRequired,
      voucherType: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      amountUsed: PropTypes.number.isRequired
    }),
    onClick: PropTypes.func.isRequired
  }

  constructor (...args) {
    super(...args)
    this.handleOnClick = this.handleOnClick.bind(this)
  }

  handleOnClick () {
    this.props.onClick(this.props.voucher)
  }

  render () {
    const voucher = this.props.voucher
    return (
      <tr>
        <td><TextLabel name='voucher-code' text={voucher.code} /></td>
        <td><TextLabel name='type' text={voucher.voucherType} /></td>
        <td><TextLabel name='description' text={voucher.description} /></td>
        <td><TextLabel name='value' text={_t.formatCurrency(voucher.amount - voucher.amountUsed)} /></td>
        <td>
          <Button
            name='pay-out'
            className='small secondary'
            onClick={this.handleOnClick}
            disabled={voucher.amountUsed >= voucher.amount}
          >
            <TextLabel>
              <Icon name='refund' className='align-left' />
              <TextLabel.Text text={_t.message('voucher-to-cash.table.pay-out')} />
            </TextLabel>
          </Button>
        </td>
      </tr>
    )
  }
}
