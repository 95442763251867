import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../../elements/text-label/text-label'
import TileSet from '../../../elements/tile-set/tile-set'

export default class ProductFamilyTile extends Component {
  static propTypes = {
    object: PropTypes.shape({
      name: PropTypes.string.isRequired,
      available: PropTypes.bool,
      selected: PropTypes.bool
    }),
    handleSelect: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    hotkey: PropTypes.string
  }

  constructor (props, ...args) {
    super(props, ...args)
    this.handleClick = props.handleSelect.bind(null, props.object)
  }

  render () {
    return (
      <TileSet.Tile
        tabIndex={-1}
        autoFocus={this.props.autoFocus}
        onClick={this.handleClick}
        disabled={this.props.disabled}
        selected={this.props.object.selected}
        hotkey={this.props.hotkey}
      >
        <TextLabel name='product-family' text={this.props.object.name} />
      </TileSet.Tile>
    )
  }
}
