import React from 'react'
import { Route } from 'react-router'
import SeatSelectorLayout from '../../../layouts/aftersales/seat-selector'
import { isChangeSeatsAvailableSelector } from '../../../redux/selectors/containers/base/aftersales'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import container from '../../../containers/aftersales/auth'

export default (
  <Route
    key='aftersales-change-seats-seat-selector'
    path='/:lang/aftersales/change-seats/seat-selector/:segmentId'
    component={container(
      isChangeSeatsAvailableSelector(originalBookingSelector),
      SeatSelectorLayout
    )}
  />
)
