import {translateResponse as barcodeTranslateResponse} from './barcode-scanner'
import {translateResponse as pedTranslateResponse} from './WorldPay-IPP350'
import {translateResponse as leapTranslateResponse} from './leap'
import {translateResponse as ticketTranslateResponse} from './Newbury-ND4800'
import {translateResponse as printerTranslateResponse} from './HP-printer-A799'
import {translateResponse as cashDrawerTranslateResponse} from './HP-cash-drawer-A798'
import {translateResponse as displayTranslateResponse} from './HP-display-LD220'
import {
  DEVICE_ID_BARCODE_SCANNER,
  DEVICE_ID_CASH_DRAWER,
  DEVICE_ID_CUSTOMER_DISPLAY,
  DEVICE_ID_LEAP, DEVICE_ID_PED,
  DEVICE_ID_RECEIPT_PRINTER,
  DEVICE_ID_TICKET_PRINTER
} from '../constants'

export const translateResponseMap = {
  NADManControl: () => [],
  [DEVICE_ID_CASH_DRAWER]: cashDrawerTranslateResponse,
  [DEVICE_ID_CUSTOMER_DISPLAY]: displayTranslateResponse,
  [DEVICE_ID_BARCODE_SCANNER]: barcodeTranslateResponse,
  [DEVICE_ID_TICKET_PRINTER]: ticketTranslateResponse,
  [DEVICE_ID_RECEIPT_PRINTER]: printerTranslateResponse,
  [DEVICE_ID_LEAP]: leapTranslateResponse,
  [DEVICE_ID_PED]: pedTranslateResponse
}
