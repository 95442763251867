import PropTypes from 'prop-types'
import React from 'react'
import defer from 'lodash/defer'
import { findDOMNode } from 's3p-js-lib/src/misc/utils'
import HotKeys from '../utils/hotkeys'

export default Component => {
  class FormHotKey extends React.Component {
    static propTypes = {
      hotkey: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string
      ]),
      disabled: PropTypes.bool,
      hotkeyDisabled: PropTypes.bool
    }

    constructor (...args) {
      super(...args)

      this.focus = this.focus.bind(this)
      this.setElement = this.setElement.bind(this)
    }

    setElement (element) {
      this.element = element
    }

    _isSelectable (element) {
      return element.tagName.toLowerCase() === 'input'
    }

    _isInput (element) {
      return ['input', 'select', 'textarea'].includes(element.tagName.toLowerCase())
    }

    focus () {
      if (this.element) {
        // eslint-disable-next-line react/no-find-dom-node
        const element = findDOMNode(this.element)
        if (element) {
          const input = this._isInput(element) ? element : element.querySelector('input, select, textarea')
          input && input.focus()

          if (this._isSelectable(input)) {
            defer(() => input.select())
          }
        }
      }

      return false
    }

    render () {
      const component = <Component ref={this.setElement} {...this.props} />
      if (this.props.hotkey) {
        const handlers = {[this.props.hotkey]: this.focus}
        return (
          <HotKeys
            handlers={handlers}
            enabled={!this.props.disabled && !this.props.hotkeyDisabled}
          >
            {component}
          </HotKeys>
        )
      } else {
        return component
      }
    }
  }

  return FormHotKey
}
