import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import NotesModal from '../../../../components/aftersales/booking/notes/notes-modal'
import connectFormContainer from '../../../base/form'
import { FORM_BOOKING_NOTE } from 's3p-js-lib/src/constants-form'
import { overviewBookingSelector, bookingNumberSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { agentBookingQueuesSelector } from 's3p-js-lib/src/redux/selectors/api/user/agent'
import { hideElement } from '../../../../redux/actions/containers/base/visible-element'
import { addBookingNote } from 's3p-js-lib/src/redux/actions/api/booking/add-booking-note'

const _bookingNumberSelector = bookingNumberSelector(overviewBookingSelector)

class NotesModalContainer extends Component {
  static propTypes = {
    form: PropTypes.shape({
      getValues: PropTypes.func.isRequired,
      valid: PropTypes.bool,
      fields: PropTypes.shape({
        queue: PropTypes.object,
        content: PropTypes.object
      })
    }),
    addBookingNote: PropTypes.func.isRequired,
    bookingNumber: PropTypes.string.isRequired
  }

  constructor (...args) {
    super(...args)
    this.handleSaveNote = this.handleSaveNote.bind(this)
  }

  handleSaveNote () {
    this.props.addBookingNote(
      this.props.bookingNumber,
      {
        queue: this.props.form.fields.queue?.value?.id,
        content: this.props.form.fields.content.value
      }
    )
  }

  render () {
    return (
      <NotesModal {...this.props} handleSaveNote={this.handleSaveNote} />
    )
  }
}

const mapStateToProps = state => ({
  queues: agentBookingQueuesSelector(state).map(bookingQueue => ({
    id: bookingQueue.id,
    name: bookingQueue.name
  })),
  bookingNumber: _bookingNumberSelector(state),
  isLoadingAddBookingNote: state.api.loading.addBookingNote
})

const mapDispatchToProps = {
  addBookingNote: (bookingNumber, note) => async dispatch => {
    await dispatch(addBookingNote(bookingNumber, note))
    dispatch(hideElement(FORM_BOOKING_NOTE))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  connectFormContainer({formName: FORM_BOOKING_NOTE})(NotesModalContainer)
)
