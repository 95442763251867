import React from 'react'
import { Route } from 'react-router'
import CancellationVoidLayout from '../../layouts/aftersales/cancellation-void'
import { isCancellationAvailableSelector } from '../../redux/selectors/containers/base/aftersales'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import container from '../../containers/aftersales/auth'

export default (
  <Route
    key='aftersales-cancellation-void'
    path='/:lang/aftersales/cancellation/void'
    component={container(
      isCancellationAvailableSelector(originalBookingSelector),
      CancellationVoidLayout
    )}
  />
)
