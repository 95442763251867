import {
  API_CREATE_PROVISIONAL_BOOKING_REQUEST,
  API_JOURNEY_SEARCH_REQUEST,
  API_OAUTH_LOGOUT,
  CLEAR_STATE
} from '../../../actions/types'
import {
  API_V2_CREATE_BOOKING_REQUEST,
  API_V2_CONFIRM_BOOKING_SUCCESS
} from '../../../actions/types-v2'

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_V2_CONFIRM_BOOKING_SUCCESS:
      return action.response.data.booking
    case API_CREATE_PROVISIONAL_BOOKING_REQUEST:
    case API_JOURNEY_SEARCH_REQUEST:
    case API_OAUTH_LOGOUT:
    case API_V2_CREATE_BOOKING_REQUEST:
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes('api.booking.completedBooking') ? initialState : state
    default:
      return state
  }
}
