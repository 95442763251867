import request from './request'
import { STATUS_ERROR } from './device-manager'
import { createError } from './response-handler'

export const translateResponse = response => {
  const messages = []

  if ((response.status || response.Status) === STATUS_ERROR) {
    if (response.results) {
      messages.push(createError(response.results.message, {translated: true}))
    } else if (response.Details) {
      messages.push(createError(response.Details.message, {translated: true}))
    }
  }

  return messages
}

export default {
  async collect (data) {
    return request({
      method: 'post',
      endpoint: '/vtm/devices/rest/PED/collect',
      translateResponse,
      data
    })
  },

  async completeCollection (data) {
    return request({
      method: 'post',
      endpoint: '/vtm/devices/rest/PED/completecollection',
      translateResponse,
      data
    })
  },

  async cancelTransaction (data) {
    return request({
      method: 'post',
      endpoint: '/vtm/devices/rest/PED/canceltransaction',
      translateResponse,
      data
    })
  },

  async refundPayment (data) {
    return request({
      method: 'post',
      endpoint: '/vtm/devices/rest/PED/refund',
      translateResponse,
      data
    })
  }

}
