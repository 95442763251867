import {
  MACHINE_LEAP_ADD_PRODUCT_REQUEST,
  MACHINE_LEAP_ADD_PRODUCT_SUCCESS,
  MACHINE_LEAP_ADD_PRODUCT_FAILURE,
  MACHINE_LEAP_READ_REQUEST,
  MACHINE_LEAP_READ_SUCCESS,
  MACHINE_LEAP_READ_FAILURE,
  MACHINE_LEAP_TOP_UP_REQUEST,
  MACHINE_LEAP_TOP_UP_SUCCESS,
  MACHINE_LEAP_TOP_UP_FAILURE,
  MACHINE_LEAP_PROCESS_ACTION_LIST_REQUEST,
  MACHINE_LEAP_PROCESS_ACTION_LIST_SUCCESS,
  MACHINE_LEAP_PROCESS_ACTION_LIST_FAILURE,
  MACHINE_LEAP_APPLY_CD_REQUEST,
  MACHINE_LEAP_APPLY_CD_SUCCESS,
  MACHINE_LEAP_APPLY_CD_FAILURE,
  MACHINE_LEAP_REVERSE_REQUEST,
  MACHINE_LEAP_REVERSE_SUCCESS,
  MACHINE_LEAP_REVERSE_FAILURE,
  MACHINE_LEAP_SET_CONFIGURATION_FAILURE,
  MACHINE_LEAP_SET_CONFIGURATION_REQUEST,
  MACHINE_LEAP_SET_CONFIGURATION_SUCCESS,
  MACHINE_LEAP_STATUS_REQUEST,
  MACHINE_LEAP_STATUS_SUCCESS,
  MACHINE_LEAP_STATUS_FAILURE
} from '../types'
import DeviceManager from '../../../machine/device-manager'

const addLeapProductRequest = (timeout, cardIdentifier, product) => ({type: MACHINE_LEAP_ADD_PRODUCT_REQUEST, timeout, cardIdentifier, product})
const addLeapProductSuccess = response => ({type: MACHINE_LEAP_ADD_PRODUCT_SUCCESS, response})
const addLeapProductFailure = machineError => ({type: MACHINE_LEAP_ADD_PRODUCT_FAILURE, machineError})

const readLeapRequest = () => ({type: MACHINE_LEAP_READ_REQUEST})
const readLeapSuccess = response => ({type: MACHINE_LEAP_READ_SUCCESS, response})
const readLeapFailure = machineError => ({type: MACHINE_LEAP_READ_FAILURE, machineError})

const topUpLeapRequest = (cardIdentifier, value, paymentMethod) => ({
  type: MACHINE_LEAP_TOP_UP_REQUEST,
  cardIdentifier,
  value,
  paymentMethod
})
const topUpLeapSuccess = response => ({type: MACHINE_LEAP_TOP_UP_SUCCESS, response})
const topUpLeapFailure = machineError => ({type: MACHINE_LEAP_TOP_UP_FAILURE, machineError})

const processLeapActionListRequest = (timeout, cardIdentifier, actions) => ({
  type: MACHINE_LEAP_PROCESS_ACTION_LIST_REQUEST,
  timeout,
  cardIdentifier,
  actions
})
const processLeapActionListSuccess = response => ({type: MACHINE_LEAP_PROCESS_ACTION_LIST_SUCCESS, response})
const processLeapActionListFailure = machineError => ({type: MACHINE_LEAP_PROCESS_ACTION_LIST_FAILURE, machineError})

const applyLeapCdRequest = (name, data) => ({type: MACHINE_LEAP_APPLY_CD_REQUEST, name, data})
const applyLeapCdSuccess = response => ({type: MACHINE_LEAP_APPLY_CD_SUCCESS, response})
const applyLeapCdFailure = machineError => ({type: MACHINE_LEAP_APPLY_CD_FAILURE, machineError})

const reverseLeapRequest = (timeout, cardIdentifier) => ({type: MACHINE_LEAP_REVERSE_REQUEST, timeout, cardIdentifier})
const reverseLeapSuccess = response => ({type: MACHINE_LEAP_REVERSE_SUCCESS, response})
const reverseLeapFailure = machineError => ({type: MACHINE_LEAP_REVERSE_FAILURE, machineError})

const setConfigurationLeapRequest = data => ({type: MACHINE_LEAP_SET_CONFIGURATION_REQUEST, data})
const setConfigurationLeapSuccess = response => ({type: MACHINE_LEAP_SET_CONFIGURATION_SUCCESS, response})
const setConfigurationLeapFailure = machineError => ({type: MACHINE_LEAP_SET_CONFIGURATION_FAILURE, machineError})

const leapStatusRequest = () => ({type: MACHINE_LEAP_STATUS_REQUEST})
const leapStatusSuccess = response => ({type: MACHINE_LEAP_STATUS_SUCCESS, response})
const leapStatusFailure = machineError => ({type: MACHINE_LEAP_STATUS_FAILURE, machineError})

export const setConfigurationLeap = data => async dispatch => {
  try {
    dispatch(setConfigurationLeapRequest(data))
    const response = await DeviceManager.setLeapConfiguration(data)
    dispatch(setConfigurationLeapSuccess(response))
  } catch (error) {
    dispatch(setConfigurationLeapFailure(error))
    return false
  }
}

export const addLeapProduct = (timeout, cardIdentifier, product) => async dispatch => {
  try {
    dispatch(addLeapProductRequest(timeout, cardIdentifier, product))
    const response = await DeviceManager.addLeapProduct(timeout, cardIdentifier, product)
    dispatch(addLeapProductSuccess(response))
  } catch (error) {
    dispatch(addLeapProductFailure(error))
    return false
  }
}

export const readLeap = () => async dispatch => {
  try {
    dispatch(readLeapRequest())
    const response = await DeviceManager.readLeap()
    dispatch(readLeapSuccess(response))
  } catch (error) {
    dispatch(readLeapFailure(error))
    return false
  }
}

export const topUpLeap = (cardIdentifier, value, paymentMethod) => async dispatch => {
  try {
    dispatch(topUpLeapRequest(cardIdentifier, value, paymentMethod))
    const response = await DeviceManager.topUpLeap(cardIdentifier, value, paymentMethod)
    dispatch(topUpLeapSuccess(response))
  } catch (error) {
    dispatch(topUpLeapFailure(error))
    return false
  }
}

export const processLeapActionList = (timeout, cardIdentifier, actions) => async dispatch => {
  try {
    dispatch(processLeapActionListRequest(timeout, cardIdentifier, actions))
    const response = await DeviceManager.processLeapActionList(timeout, cardIdentifier, actions)
    dispatch(processLeapActionListSuccess(response))
  } catch (error) {
    dispatch(processLeapActionListFailure(error))
    return false
  }
}

export const applyLeapCd = (name, data) => async dispatch => {
  try {
    dispatch(applyLeapCdRequest(name, data))
    const response = await DeviceManager.applyLeapCd(name, data)
    dispatch(applyLeapCdSuccess(response))
  } catch (error) {
    dispatch(applyLeapCdFailure(error))
    return false
  }
}

export const reverseLeap = (timeout, cardIdentifier) => async dispatch => {
  try {
    dispatch(reverseLeapRequest(timeout, cardIdentifier))
    const response = await DeviceManager.reverseLeap(timeout, cardIdentifier)
    dispatch(reverseLeapSuccess(response))
  } catch (error) {
    dispatch(reverseLeapFailure(error))
    return false
  }
}

export const leapStatus = () => async dispatch => {
  try {
    dispatch(leapStatusRequest())
    const response = await DeviceManager.leapStatus()
    dispatch(leapStatusSuccess(response))
  } catch (error) {
    dispatch(leapStatusFailure(error))
    return false
  }
}
