import aftersales from './aftersales'
import base from './base'
import reservations from './reservations'
import shift from './shift'
import tickets from './tickets'
import leap from './leap'
import agent from './agent'

export default {
  aftersales,
  base,
  reservations,
  shift,
  tickets,
  leap,
  agent
}
