import { createSelector } from 'reselect'

export const travelSelector = tariffSegmentsSelector => createSelector(
  [tariffSegmentsSelector],
  tariffSegments => {
    const mainTariffSegments = tariffSegments.filter(
      segment => segment.requiredProducts.some(({cancelled, provisional}) => !cancelled && provisional)
    )

    if (!mainTariffSegments.length) {
      return null
    }

    const firstSegment = mainTariffSegments[0]
    const lastSegment = mainTariffSegments[mainTariffSegments.length - 1]

    return {
      direction: firstSegment && firstSegment.direction,
      departureDate: firstSegment && firstSegment.departureDate,
      arrivalDate: lastSegment && lastSegment.arrivalDate,
      departureStation: firstSegment.departureStation,
      arrivalStation: lastSegment.arrivalStation
    }
  }
)
