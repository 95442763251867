import { createSelector } from 'reselect'
import { stationMapper } from '../../orientation/stations'
import { toTimezoneMoment, toUtcDateMoment } from '../../../../../misc/date'

const serviceStationMapper = station => ({
  ...stationMapper(station),
  departureTime: station.departure_timestamp &&
  toTimezoneMoment(station.departure_timestamp, station.time_zone || station.timezone),
  arrivalTime: station.arrival_timestamp &&
  toTimezoneMoment(station.arrival_timestamp, station.time_zone || station.timezone)
})

export const journeyDetailsSelector = createSelector(
  [state => state.api.orientation.journeyDetails],
  journeyDetails => journeyDetails.reduce(
    (details, service) => {
      const formattedService = {
        name: service.name,
        arrivalStation: serviceStationMapper(service.arrival_station),
        departureStation: serviceStationMapper(service.departure_station),
        scheduleDate: toUtcDateMoment(service.schedule_date),
        serviceProperties: service.service_properties,
        serviceType: service.service_type,
        serviceIdentifier: service.service_identifier,
        viaStations: service.via_stations.map(serviceStationMapper)
      }

      details[service.service_identifier] = formattedService

      return details
    },
    {}
  )
)

export const journeyDetailsServicesSelector = createSelector(
  [journeyDetailsSelector],
  services => Object.values(services).map(service => service.serviceIdentifier)
)
