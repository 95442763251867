/* globals S3P_SETTINGS: true */

import moment from 'moment-timezone'

// eslint-disable-next-line func-style
export function toUtcDateMoment (date) {
  // Parse manually due to bug in moment::parseZone (moment v2.14.1)
  const matches = typeof date === 'string' && date.match(/^\d{4}-\d{1,2}-\d{1,2}/)
  if (matches) {
    return moment.utc(`${matches[0]}T00:00:00Z`)
  }

  if (!moment.isMoment(date)) {
    date = moment(date)
  }

  return date.isValid() ? moment.utc(`${date.format('YYYY-MM-DD')}T00:00:00Z`) : date
}

// eslint-disable-next-line func-style
export function toTimezoneMoment (dateTime, timezone = null) {
  if (!timezone || !moment.tz.zone(timezone)) {
    timezone = S3P_SETTINGS.s3Passenger.features.defaultTimezone
  }

  return moment(dateTime).tz(timezone)
}
