import Validator from './validator'

export default {
  fields: {
    code: {
      validators: [
        Validator.isRequired
      ]
    }
  }
}
