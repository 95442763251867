import { connect } from 'react-redux'
import {
  bookingNumberSelector,
  fulfillmentMethodSelector,
  overviewBookingSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { reprintResendBooking } from '../../../redux/actions/containers/aftersales/booking/reprint-resend'
import ProgressNavigation from '../../../components/aftersales/change-fulfillment/progress-navigation'
import { updateFulfillmentMethod } from 's3p-js-lib/src/redux/actions/api/v2/booking'
import { pendingFulfillmentMethodSelector } from '../../../redux/selectors/user-input/base/fulfillment-methods'

const _fulfillmentMethodSelector = fulfillmentMethodSelector(overviewBookingSelector)

const mapStateToProps = state => {
  const pendingFulfillmentMethod = pendingFulfillmentMethodSelector(state)

  return {
    loading: (
      state.api.v2.loading.updateFulfillmentMethod ||
      state.api.v2.loading.confirmBooking ||
      state.containers.aftersales.booking.reprintResend.loading
    ),
    disabled: !pendingFulfillmentMethod || pendingFulfillmentMethod.code === _fulfillmentMethodSelector(state).code
  }
}

const mapDispatchToProps = {
  onClickNext: () => async (dispatch, getState) => {
    const state = getState()
    const fulfillmentMethod = pendingFulfillmentMethodSelector(state)
    if (fulfillmentMethod) {
      await dispatch(updateFulfillmentMethod(bookingNumberSelector(overviewBookingSelector)(state), fulfillmentMethod.code))
      return dispatch(reprintResendBooking())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressNavigation)
