import moment from 'moment'
import { createSelector } from 'reselect'
import { canOverrideAftersalesRulesSelector } from '../../api/auth/auth'
import {
  originalOrOverviewBookingSelector,
  provisionalOrOriginalOrOverviewBookingSelector
} from '../../api/booking/booking'
import { ticketsSelector } from './cancellation'
import { fulfillmentMethodSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { productsWithActiveValidationSelector } from '../../api/booking/products'
import { isMagstripeOrTVMFulfillment } from '../../../../misc/utils'

export const voidedTicketCountersSelector = createSelector(
  [
    ticketsSelector,
    state => state.containers.aftersales.voidTickets.voidedTicketCounters
  ],
  (ticketMap, voidedTicketCounters) => Object.keys(ticketMap).reduce(
    (voidedTicketKeys, printCounter) => {
      const tickets = ticketMap[printCounter]
      if (tickets.some(ticket => ticket.voidDateTime) || voidedTicketCounters.some(counter => counter === printCounter)) {
        voidedTicketKeys.push(printCounter)
      }
      return voidedTicketKeys
    },
    []
  )
)

export const shouldVoidMagstripeTicketsSelector = bookingSelector => createSelector(
  [
    fulfillmentMethodSelector(bookingSelector),
    productsWithActiveValidationSelector(bookingSelector)
  ],
  (fulfillmentMethod, productsWithActiveValidation) => Boolean(
    isMagstripeOrTVMFulfillment(fulfillmentMethod) &&
    productsWithActiveValidation.length
  )
)

export const actionNotPossibleWithinTimeSelector = type => createSelector(
  canOverrideAftersalesRulesSelector(originalOrOverviewBookingSelector),
  productsWithActiveValidationSelector(provisionalOrOriginalOrOverviewBookingSelector),
  (canOverrideAftersalesRules, products) => {
    if (canOverrideAftersalesRules) {
      return false
    }

    // using 1 minute per ticket
    const approximateTimeFinished = moment().add(products.length, 'minutes')
    return products.some(
      product => {
        const afterSalesRule = (product.afterSalesRules || []).find(rule => rule.type === type)
        return (
          afterSalesRule &&
          afterSalesRule.active &&
          afterSalesRule.valid_until &&
          moment(afterSalesRule.valid_until).isBefore(approximateTimeFinished)
        )
      }
    )
  }
)
