import { connect } from 'react-redux'
import { cancelRefund } from '../../../../../redux/actions/containers/aftersales/booking/refund'
import RefundCancel from '../../../../../components/aftersales/booking/payment-details/refund/refund-cancel'
import { refundResultSelector } from '../../../../../redux/selectors/machine/pin-payment'

const mapStateToProps = (state, ownProps) => ({
  isAborting: state.containers.aftersales.booking.refund.isAborting,
  isLoading: state.api.v2.loading.addPayments || state.api.v2.loading.updatePayments,
  disabled: ownProps.disabled || refundResultSelector(state).refundCompleted
})

const mapDispatchToProps = {
  cancelRefund
}

export default connect(mapStateToProps, mapDispatchToProps)(RefundCancel)
