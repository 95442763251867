import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../elements/text-label/text-label'
import Icon from '../../../elements/icon/icon'
import Menu from '../../../elements/menu/menu'
import Dropdown from '../../../elements/dropdown/dropdown'
import {
  STATUS_AVAILABLE,
  STATUS_SELECTED,
  STATUS_DISABLED
} from './main-menu-item'
import HotKeys from '../../../elements/utils/hotkeys'
import HotkeyLabel from '../../../elements/hotkey-label/hotkey-label'

const statusPropType = PropTypes.oneOf([STATUS_AVAILABLE, STATUS_DISABLED, STATUS_SELECTED]).isRequired

export default class MaintenanceMenu extends Component {
  static propTypes = {
    status: statusPropType,
    statusTestCfd: statusPropType,
    statusTestThermalPrinter: statusPropType,
    statusSetDarkness: statusPropType,
    handleClickTestCfd: PropTypes.func.isRequired,
    handleClickTestThermalPrinter: PropTypes.func.isRequired,
    handleClickSetDarkness: PropTypes.func.isRequired
  }

  _getStatusProps (status) {
    return {
      disabled: status === STATUS_DISABLED,
      active: status === STATUS_SELECTED
    }
  }

  render () {
    return (
      <li className={`state--is-${this.props.status}`}>
        <HotKeys handlers={{menuMaintenance: this.props.handleClickTestCfd}}>
          <Dropdown
            name='maintenance-item'
            view={
              <Dropdown.View name='maintenance' hideHeader>
                <Menu>
                  <Menu.Item
                    icon='cogwheel'
                    text={_t.message('main-menu.maintenance.test-cfd')}
                    onClick={this.props.handleClickTestCfd}
                    {...this._getStatusProps(this.props.statusTestCfd)}
                  />
                  <Menu.Item
                    icon='fulfillment-method-homeprint'
                    text={_t.message('main-menu.maintenance.test-thermal-printer')}
                    onClick={this.props.handleClickTestThermalPrinter}
                    {...this._getStatusProps(this.props.statusTestThermalPrinter)}
                  />
                  <Menu.Item
                    icon='fulfillment-method-homeprint'
                    text={_t.message('main-menu.maintenance.set-darkness')}
                    onClick={this.props.handleClickSetDarkness}
                    {...this._getStatusProps(this.props.statusSetDarkness)}
                  />
                </Menu>
              </Dropdown.View>
            }
          >
            <TextLabel name='navigation-item'>
              <Icon name='wrench' className='align-left' />
              <TextLabel.Text text={_t.message('main-menu.maintenance.title')} />
              <Icon name='chevron-down' className='align-right' />
            </TextLabel>
          </Dropdown>
          <HotkeyLabel hotkey='menuMaintenance' />
        </HotKeys>
      </li>
    )
  }
}
