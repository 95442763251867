import React, { Component } from 'react'
import PropTypes from '../../../../misc/prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { switchDate } from '../../../../redux/actions/containers/base/journey-search/journey-search'
import { journeySearchDateBeforeCheapestPrices } from '../../../../redux/selectors/containers/base/journey-search/date-switcher'
import PreviousDateSet from '../../../../components/base/journey-search/date-switcher/previous-date-set'

class PreviousDateSetContainer extends Component {
  static propTypes = {
    direction: PropTypes.string.isRequired,
    date: PropTypes.moment,
    onSwitchDate: PropTypes.func.isRequired
  }

  constructor (...args) {
    super(...args)
    this.handlePreviousDateSet = this.handlePreviousDateSet.bind(this)
  }

  handlePreviousDateSet () {
    this.props.onSwitchDate(this.props.direction, this.props.date)
  }

  render () {
    return (
      <PreviousDateSet
        {...this.props}
        disabled={this.props.date.isBefore(moment(), 'day')}
        handlePreviousDateSet={this.handlePreviousDateSet}
      />
    )
  }
}

const mapStateToProps = () => {
  const _journeySearchDateBeforeCheapestPrices = journeySearchDateBeforeCheapestPrices()

  return (state, ownProps) => ({
    hasSelectedDate: _journeySearchDateBeforeCheapestPrices(state, ownProps)
  })
}

const mapDispatchToProps = (_, ownProps) => dispatch => ({
  onSwitchDate: (...args) => dispatch(switchDate(ownProps.rebooking, ...args))
})

export default connect(mapStateToProps, mapDispatchToProps)(PreviousDateSetContainer)
