import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Form from '../../../../elements/form/form'
import Icon from '../../../../elements/icon/icon'
import Button from '../../../../elements/button/button'
import TextLabel from '../../../../elements/text-label/text-label'
import { FORM_TYPE_VOUCHER_SEARCH } from 's3p-js-lib/src/constants-form'
import _t from 's3p-js-lib/src/translate'

export default class VouchersSearchForm extends Component {
  static propTypes = {
    handleGetVouchers: PropTypes.func.isRequired,
    loadingGetVouchers: PropTypes.bool,
    form: PropTypes.shape({
      valid: PropTypes.bool,
      fields: PropTypes.shape({
        code: PropTypes.object
      })
    })
  }

  render () {
    return (
      <Form formContext={FORM_TYPE_VOUCHER_SEARCH}>
        <div className='toolbar'>
          <div className='toolbar__item'>
            <Form.Label htmlFor='search-code' name='search-code' text={_t.message('voucher-to-cash.form.code.label')} />
          </div>
          <div className='toolbar__item'>
            <Form.TextField
              {...this.props.form.fields.code}
              name='search-code'
              id='search-code'
              placeholder={_t.message('voucher-to-cash.form.code.placeholder')}
              autoFocus
            />
          </div>
          <div className='toolbar__item'>
            <Button
              name='search-vouchers'
              className='primary'
              onClick={this.props.handleGetVouchers}
              disabled={!this.props.form.valid}
              loading={this.props.loadingGetVouchers}
            >
              <TextLabel>
                <Icon name='search' className='align-left' />
                <TextLabel.Text text={_t.message('voucher-to-cash.form.search')} />
              </TextLabel>
            </Button>
          </div>
        </div>
      </Form>
    )
  }
}
