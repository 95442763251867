import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FORM_TYPE_REDEEM_VOUCHER } from 's3p-js-lib/src/constants-form'
import { connect } from 'react-redux'
import VoucherForm from '../../../components/base/payment-modal/voucher-form'
import connectFormContainer from '../form'

class VoucherFormContainer extends Component {
  static propTypes = {
    form: PropTypes.shape({
      fields: PropTypes.shape({
        voucherCode: PropTypes.shape({
          value: PropTypes.string
        }).isRequired
      }).isRequired,
      valid: PropTypes.bool.isRequired
    }).isRequired
  }

  render () {
    return (
      <VoucherForm
        {...this.props}
      />
    )
  }
}

const mapStateToProps = state => ({
  amountToBePaid: state.containers.base.tiles.paymentMethods.amountToBePaid,
  isLoading: state.api.loading.addVoucher || state.api.v2.loading.confirmBooking,
  messages: state.containers.base.paymentModal.voucherForm.messages
})

export default connect(mapStateToProps)(
  connectFormContainer({formName: FORM_TYPE_REDEEM_VOUCHER})(VoucherFormContainer)
)
