import React, { Component } from 'react'
import PropTypes from '../../../misc/prop-types'
import Form from '../../../elements/form/form'
import Modal from '../../../elements/modal/modal'
import DateTextInputField from './input'
import { KEYCODE_TAB } from '../../../constants'

const FIELD_ID_INPUT = 'modal-date-field'

export default class DateInputModal extends Component {
  constructor (...args) {
    super(...args)

    this.onChangeCalendar = this.onChangeCalendar.bind(this)
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    minDate: PropTypes.moment,
    value: PropTypes.moment
  }

  onChangeCalendar (value) {
    this.props.onChange(value)
    this.props.onClose()
  }

  onKeyDownCalendar (event) {
    if (event.keyCode === KEYCODE_TAB) {
      event.preventDefault()
      event.stopPropagation()
      const element = document.getElementById(FIELD_ID_INPUT)
      element && element.focus()
    }
  }

  render () {
    return (
      <Modal
        name={this.props.name}
        header={<Modal.Header title={this.props.title} />}
      >
        <Form formContext='date-input-field'>
          <fieldset>
            <Form.InputGroup name='text'>
              <Form.InputWrapper name='text'>
                <DateTextInputField
                  name='text-field'
                  id={FIELD_ID_INPUT}
                  autoComplete='off'
                  autoFocus
                  required={this.props.required}
                  placeholder={this.props.placeholder}
                  value={this.props.value}
                  minDate={this.props.minDate}
                  onChange={this.props.onChange}
                  onEnter={this.props.onClose}
                />
              </Form.InputWrapper>
            </Form.InputGroup>
            <Form.InputGroup name='calendar'>
              <Form.Calendar
                name='calendar'
                onChange={this.onChangeCalendar}
                minDate={this.props.minDate}
                value={this.props.value}
                onKeyDown={this.onKeyDownCalendar}
              />
            </Form.InputGroup>
          </fieldset>
        </Form>
      </Modal>
    )
  }
}
