import { connect } from 'react-redux'
import { additionalProductsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/products'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import { routerActions } from '../../../redux/actions/containers/base/routing'
import ProgressNavigation from '../../../components/aftersales/additional-products/progress-navigation'
import {
  bookingSelector as provisionalBookingSelector,
  bookingNumberSelector,
  paymentRequiredSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { aftersalesConfirm } from 's3p-js-lib/src/redux/actions/api/aftersales/confirm'
import {displayNextCustomer} from '../../../redux/actions/containers/base/finalize-booking/next-customer'

const _additionalProductsSelector = additionalProductsSelector(bookingSelector)
const _paymentRequiredSelector = paymentRequiredSelector(bookingSelector)
const _bookingNumberSelector = bookingNumberSelector(provisionalBookingSelector)

const mapStateToProps = state => {
  const booking = bookingSelector(state)

  return {
    isLoading: state.api.loading.additionalProductsUpdate,
    isPaymentRequired: _paymentRequiredSelector(state),
    disableConfirm: Boolean(
      !_additionalProductsSelector(state).some(product => product.provisional) ||
      !booking ||
      !booking.booking_fulfillment_method
    )
  }
}

const mapDispatchToProps = {
  handleClickNext: () => async (dispatch, getState) => {
    if (_paymentRequiredSelector(getState())) {
      dispatch(routerActions.push('/aftersales/additional-products/review-payment'))
    } else {
      const bookingNumber = _bookingNumberSelector(getState())
      await dispatch(aftersalesConfirm(bookingNumber))
      dispatch(displayNextCustomer())
      dispatch(routerActions.push(`/aftersales/booking/${bookingNumber}`))
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProgressNavigation)
