import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {lastApiErrorsSelector} from 's3p-js-lib/src/redux/selectors/api/base/last-api-errors'
import {clearLastApiErrors} from 's3p-js-lib/src/redux/actions/api/base/last-api-errors'
import LastApiErrorFeedback from '../../components/base/last-api-error-feedback'

const mapStateToProps = () => {
  const _lastApiErrorsSelector = lastApiErrorsSelector()
  return (state, ownProps) => ({
    lastApiErrors: _lastApiErrorsSelector(state, ownProps)
  })
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  clearApiErrors: () => clearLastApiErrors(ownProps.namespaces)
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(LastApiErrorFeedback)
