import { createSelector } from 'reselect'
import _sumBy from 'lodash/sumBy'
import { legsSelector } from './specification/legs'
import { camelCaseKeys } from '../../../../misc/utils'
import { passengersSelector } from './offer/passengers'
import { additionalProductsSelector as selectedAdditionalProductsSelector } from './specification/products'
import { productsAvailabilitySelector } from './seat-selection-options'

const additionalProducts = state => state.api.orientation.additionalProducts

const passengerPriceMapper = (passengers, selectedAdditionalProducts, priceSpecification) => passengerPrice => {
  const passenger = passengers.find(({id}) => passengerPrice.id === id)
  const selected = selectedAdditionalProducts.find(selectedAdditionalProduct =>
    selectedAdditionalProduct.itemId === priceSpecification.item_id &&
    selectedAdditionalProduct.passenger.id === passenger.id
  )
  const quantity = selected ? selected.quantity : 0

  return {
    ...passenger,
    uniqueId: `${priceSpecification.item_id}|${passengerPrice.id}`,
    price: passengerPrice.price,
    quantity,
    totalPrice: quantity * passengerPrice.price
  }
}

const priceSpecificationMapper = (legs, passengers, selectedAdditionalProducts) => priceSpecification => {
  const originLeg = legs.find(leg => leg.id === priceSpecification.legs[0])
  const destinationLeg = legs.find(
    leg => leg.id === priceSpecification.legs[priceSpecification.legs.length - 1]
  )
  const mappedPassengers = priceSpecification.passengers.map(
    passengerPriceMapper(passengers, selectedAdditionalProducts, priceSpecification)
  )

  return {
    travelId: priceSpecification.travel_id,
    itemId: priceSpecification.item_id,
    legIds: priceSpecification.legs,
    passengers: mappedPassengers,
    selected: mappedPassengers.some(({quantity}) => quantity > 0),
    totalPrice: _sumBy(mappedPassengers, 'totalPrice'),
    departureStation: originLeg && originLeg.departureStation,
    departureDate: originLeg && originLeg.departureDate,
    arrivalStation: destinationLeg && destinationLeg.arrivalStation,
    arrivalDate: originLeg && originLeg.arrivalDate,
    direction: originLeg ? originLeg.direction : destinationLeg ? destinationLeg.direction : null
  }
}

const isQuantityMaximumAvailable = (additionalProduct, selectedAdditionalProducts, productsAvailability) => {
  const bucketCode = additionalProduct.tariff.bucket
    ? additionalProduct.tariff.bucket.code
    : null

  if (!bucketCode || !productsAvailability) {
    return [false, false]
  }

  const productsAvailabilityFiltered = productsAvailability
    .map(productAvailability => {
      const availability = productAvailability.logicalAvailability.find(availability => availability.bucket_code === bucketCode)
      return {
        ...availability,
        legId: productAvailability.id
      }
    })

  return productsAvailabilityFiltered.map(productAvailabilityFiltered => {
    const quantity = selectedAdditionalProducts.reduce((quantity, selectedProduct) => {
      if (
        selectedProduct.productId !== additionalProduct.id ||
        !selectedProduct.legs.find(leg => leg === productAvailabilityFiltered.legId)
      ) {
        return quantity
      }
      quantity += selectedProduct.quantity
      return quantity
    }, 0)

    return productAvailabilityFiltered.logical <= quantity
  })
}

export const additionalProductsSelector = createSelector(
  [
    additionalProducts,
    legsSelector,
    passengersSelector,
    selectedAdditionalProductsSelector,
    productsAvailabilitySelector
  ],
  (additionalProducts, legs, passengers, selectedAdditionalProducts, productsAvailability) => {
    if (!additionalProducts) {
      return null
    }

    const _priceSpecificationMapper = priceSpecificationMapper(legs, passengers, selectedAdditionalProducts)

    return additionalProducts.map(({price_specifications: priceSpecifications, ...additionalProduct}) => {
      const mappedPriceSpecifications = priceSpecifications.map(_priceSpecificationMapper)

      return {
        ...camelCaseKeys(additionalProduct),
        priceSpecifications: mappedPriceSpecifications,
        totalPrice: _sumBy(mappedPriceSpecifications, 'totalPrice'),
        selected: mappedPriceSpecifications.some(({selected}) => selected),
        isQuantityMaximumAvailable: isQuantityMaximumAvailable(additionalProduct, selectedAdditionalProducts, productsAvailability)
      }
    })
  }
)
