import {
  API_AFTERSALES_CONFIRM_REQUEST,
  API_AFTERSALES_CONFIRM_SUCCESS,
  API_AFTERSALES_CONFIRM_FAILURE
} from '../../types'
import Client from '../../../../api/client'
import ensureToken from '../../../../api/ensure-token'

const aftersalesConfirmRequest = bookingNumber => ({type: API_AFTERSALES_CONFIRM_REQUEST, bookingNumber})
const aftersalesConfirmSuccess = response => ({type: API_AFTERSALES_CONFIRM_SUCCESS, response})
const aftersalesConfirmFailure = error => ({type: API_AFTERSALES_CONFIRM_FAILURE, error})

export const aftersalesConfirm = bookingNumber => ensureToken(async function (token, dispatch) {
  try {
    dispatch(aftersalesConfirmRequest(bookingNumber))
    const response = await Client.aftersalesConfirm(token, bookingNumber)

    dispatch(aftersalesConfirmSuccess(response))
  } catch (error) {
    dispatch(aftersalesConfirmFailure(error))
    throw error
  }
})
