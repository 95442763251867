import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import _t from 's3p-js-lib/src/translate'
import Closable from '../utils/closable'
import Icon from '../icon/icon'
import TextLabel from '../text-label/text-label'
import Button from '../button/button'

export default class DropdownView extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onHideElement: PropTypes.func,
    title: PropTypes.string,
    className: PropTypes.string,
    hideHeader: PropTypes.bool
  }

  static defaultProps = {
    hideHeader: false
  }

  render () {
    const {name, title, className, children, onHideElement, hideHeader} = this.props
    return (
      <div
        className={classNames('dropdown', `dropdown-${name}`, className)}
        role='tooltip'
        aria-labelledby={title ? `dropdown-heading-${name}` : null}
      >
        <Closable name='dropdown-view' handleHideElement={onHideElement}>
          <div className='dropdown__panel'>
            {!hideHeader
              ? (
                <div className='dropdown__header'>
                  {title ? <TextLabel id={`dropdown-heading-${name}`} name='heading' text={title} /> : null}
                  <Button
                    name='dropdown-close'
                    className='secondary'
                    onClick={onHideElement}
                    title={_t.message('dropdown.close')}
                    aria-label={_t.message('dropdown.close')}
                  >
                    <Icon name='cross' />
                    <TextLabel aria-hidden='true' text={_t.message('dropdown.close')} />
                  </Button>
                </div>
              ) : null}
            <div className='dropdown__content'>
              {children}
            </div>
          </div>
        </Closable>
        <div className='dropdown__overlay' />
      </div>
    )
  }
}
