import {
  API_CONFIRMATION_POLL_TIMEOUT,
  API_CREATE_PROVISIONAL_BOOKING_REQUEST,
  API_REBOOKING_INITIATE_REQUEST,
  API_CANCELLATION_INITIATE_REQUEST,
  API_CHANGE_SEATS_INITIATE_REQUEST,
  API_ADDITIONAL_PRODUCTS_INITIATE_REQUEST,
  API_UPDATE_PASSENGERS_INITIATE_REQUEST,
  CLEAR_STATE
} from '../../../actions/types'

export const initialState = null

export default function (state = initialState, action) {
  switch (action.type) {
    case API_CREATE_PROVISIONAL_BOOKING_REQUEST:
    case API_REBOOKING_INITIATE_REQUEST:
    case API_CANCELLATION_INITIATE_REQUEST:
    case API_CHANGE_SEATS_INITIATE_REQUEST:
    case API_ADDITIONAL_PRODUCTS_INITIATE_REQUEST:
    case API_UPDATE_PASSENGERS_INITIATE_REQUEST:
      return initialState
    case API_CONFIRMATION_POLL_TIMEOUT:
      return {...state, status: 'timeout', handled: false}
    case CLEAR_STATE:
      return action.namespaces.includes('api.payment.paymentResult') ? initialState : state
    default:
      return state
  }
}
