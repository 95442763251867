import React, { Component } from 'react'
import GenericLayout from '../generic-layout'
import SeatSelector from '../../containers/aftersales/change-seats/seat-selector'
import MainMenu from '../../containers/base/main-menu/main-menu'
import GridLayout from '../../elements/grid/grid-layout'
import GridColumn from '../../elements/grid/grid-column'

export default class SeatSelectorLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <MainMenu />
            <SeatSelector {...this.props} />
          </GridColumn>
        </GridLayout>
      </GenericLayout>
    )
  }
}
