import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import BarcodeData from '../../../components/aftersales/read-ticket/barcode-data'
import { ticketDetailsSelector } from '../../../redux/selectors/api/v2/ticket-details'

class BarcodeDataContainer extends Component {
  static propTypes = {
    handleClearState: PropTypes.func.isRequired
  }

  componentWillUnmount () {
    this.props.handleClearState()
  }

  render () {
    return <BarcodeData {...this.props} />
  }
}

const mapStateToProps = state => ({
  barcodeData: ticketDetailsSelector(state)
})

const mapDispatchToProps = {
  handleClearState: () => clearState('api.v2.ticketDetails')
}

export default connect(mapStateToProps, mapDispatchToProps)(BarcodeDataContainer)
