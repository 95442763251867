import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import defer from 'lodash/defer'

export default class RenderOutsideApp extends Component {
  static propTypes = {
    containerClass: PropTypes.string,
    renderPlaceholder: PropTypes.bool,
    children: PropTypes.node.isRequired,
    handleUpdate: PropTypes.func
  }

  static defaultProps = {
    renderPlaceholder: false
  }

  constructor (...args) {
    super(...args)
    this.handleUpdate = this.handleUpdate.bind(this)
  }

  componentDidMount () {
    this.element = document.createElement('div')
    if (this.props.containerClass) {
      this.element.className = this.props.containerClass
    }
    document.body.appendChild(this.element)

    this._enqueueRender()
  }

  componentDidUpdate () {
    this._enqueueRender()
  }

  componentWillUnmount () {
    ReactDOM.unmountComponentAtNode(this.element)
    document.body.removeChild(this.element)
  }

  _enqueueRender () {
    ReactDOM.unstable_renderSubtreeIntoContainer(this, this.props.children, this.element, this.handleUpdate)
  }

  handleUpdate () {
    const readyCallback = this.props.renderPlaceholder
      ? () => {
        const width = this.element.offsetWidth
        const height = this.element.offsetHeight
        if (width !== this.state.width || height !== this.state.height) {
          this.setState({width, height})
        }
        this.props.handleUpdate && this.props.handleUpdate()
      }
      : this.props.handleUpdate

    if (readyCallback) {
      defer(readyCallback)
    }
  }

  render () {
    if (!this.props.renderPlaceholder) {
      return null
    }

    const className = classNames(
      'placeholder',
      this.props.containerClass ? `placeholder-${this.props.containerClass}` : ''
    )

    return <div className={className} style={{width: this.state.width, height: this.state.height}} />
  }
}
