import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Form from '../../../elements/form/form'
import TextLabel from '../../../elements/text-label/text-label'
import FeedbackMachine from '../../../containers/base/last-machine-error-feedback'
import Feedback, { FEEDBACK_WARNING } from '../../../elements/feedback/feedback'
import {
  PED_STATUS_AWAITING_PAYMENT,
  PED_STATUS_AUTHORIZATION_REQUIRED,
  PED_STATUS_SUCCESS,
  PED_STATUS_DECLINED,
  PED_STATUS_FAILURE,
  PED_STATUS_NO_RESPONSE,
  MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_PAYMENT_SUCCESS,
  MESSAGE_CREDIT_DEBIT_PAYMENT_PROCEED_PED_DEVICE,
  MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_AUTHORIZATION_DECLINED,
  MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_NO_RESPONSE_PED_DEVICE,
  MESSAGE_CREDIT_DEBIT_PAYMENT_RECEIPT_ERROR,
  MESSAGE_CREDIT_DEBIT_PAYMENT_RECEIPT_SIGNATURE_ERROR,
  MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_FAILURE_UNKNOWN,
  MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_FAILURE_CODE,
  MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_API_ERROR,
  MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_MANUAL_REFUND
} from '../../../constants'

const FEEDBACK_MESSAGES_MAP = {
  [MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_PAYMENT_SUCCESS]: 'credit-debit-payment.feedback.payment-success',
  [MESSAGE_CREDIT_DEBIT_PAYMENT_PROCEED_PED_DEVICE]: 'credit-debit-payment.feedback.proceed-ped-device',
  [MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_AUTHORIZATION_DECLINED]: 'credit-debit-payment.feedback.authorization-declined',
  [MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_NO_RESPONSE_PED_DEVICE]: 'credit-debit-payment.feedback.no-response-ped-device',
  [MESSAGE_CREDIT_DEBIT_PAYMENT_RECEIPT_ERROR]: 'credit-debit-payment.receipt-error',
  [MESSAGE_CREDIT_DEBIT_PAYMENT_RECEIPT_SIGNATURE_ERROR]: 'credit-debit-payment.receipt-signature-error',
  [MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_FAILURE_UNKNOWN]: 'credit-debit-payment.feedback.failure-unknown',
  [MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_FAILURE_CODE]: 'credit-debit-payment.feedback.failure-code.',
  [MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_API_ERROR]: 'credit-debit-payment.feedback.api-error',
  [MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_MANUAL_REFUND]: 'credit-debit-payment.feedback.manual-refund'
}

export default class CreditDebitForm extends Component {
  static propTypes = {
    amountToBePaid: PropTypes.number.isRequired,
    status: PropTypes.oneOf([
      PED_STATUS_AWAITING_PAYMENT,
      PED_STATUS_AUTHORIZATION_REQUIRED,
      PED_STATUS_SUCCESS,
      PED_STATUS_DECLINED,
      PED_STATUS_FAILURE,
      PED_STATUS_NO_RESPONSE
    ]),
    feedbackMessages: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired
      })
    ),
    authorizationMessage: PropTypes.string,
    resultCode: PropTypes.number,
    showForm: PropTypes.bool,
    disableForm: PropTypes.bool,
    receiptPrintError: PropTypes.bool,
    receiptSignaturePrintError: PropTypes.bool,
    form: PropTypes.shape({
      valid: PropTypes.bool,
      fields: PropTypes.shape({
        authorizationCode: PropTypes.object
      })
    })
  }

  renderFeedbackMessages () {
    return this.props.feedbackMessages.map(feedback => {
      let label
      if (feedback.text === MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_FAILURE_CODE) {
        const failureLabel = FEEDBACK_MESSAGES_MAP[MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_FAILURE_CODE]
        label = this.props.resultCode && _t.hasMessage(`${failureLabel}${this.props.resultCode}`)
          ? `${failureLabel}${this.props.resultCode}`
          : FEEDBACK_MESSAGES_MAP[MESSAGE_CREDIT_DEBIT_PAYMENT_FEEDBACK_FAILURE_UNKNOWN]
      } else {
        label = FEEDBACK_MESSAGES_MAP[feedback.text]
      }

      return <Feedback
        key={label}
        status={feedback.status}
        text={_t.message(label)}
      />
    })
  }

  render () {
    return (
      <Form formContext='credit-debit-payment'>
        <FeedbackMachine namespaces={['cashDrawer']} />
        {this.renderFeedbackMessages()}
        {this.props.authorizationMessage
          ? <Feedback status={FEEDBACK_WARNING} text={this.props.authorizationMessage} />
          : null
        }

        <fieldset>
          <Form.InputGroup name='amount-to-be-paid'>
            <Form.InputWrapper name='label'>
              <TextLabel
                name='amount-to-be-paid-description'
                text={_t.message('credit-debit-payment.amount-to-be-paid')}
              />
            </Form.InputWrapper>
            <Form.InputWrapper name='value'>
              <TextLabel
                id='amount-to-be-paid-value'
                name='amount-to-be-paid-value'
                text={_t.formatCurrency(this.props.amountToBePaid)}
              />
            </Form.InputWrapper>
          </Form.InputGroup>
        </fieldset>
        {this.props.showForm ? (
          <fieldset key='authorization-code'>
            <Form.InputGroup name='authorization-code'>
              <Form.InputWrapper name='label'>
                <Form.Label
                  htmlFor='authorization-code'
                  name='authorization-code'
                />
              </Form.InputWrapper>
              <Form.InputWrapper name='value'>
                <Form.TextField
                  {...this.props.form.fields.authorizationCode}
                  id='authorization-code'
                  name='authorization-code'
                  autoComplete='off'
                  autoFocus
                  disabled={this.props.disableForm}
                />
              </Form.InputWrapper>
            </Form.InputGroup>
          </fieldset>
        ) : null}
      </Form>
    )
  }
}
