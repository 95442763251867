import { createSelector } from 'reselect'
import { camelCaseKeys } from '../../../../misc/utils'
import { EMPTY_ARRAY } from '../../../../constants'

export const currentAgentSelector = createSelector(
  [
    state => state.api.user.user,
    state => state.api.user.agent.user
  ],
  (user, agent) => user && agent ? ({username: user.username, ...camelCaseKeys(agent)}) : null
)

export const agentMetaDataSelector = state => (state.api.user.agent.user && state.api.user.agent.user.meta_data) || EMPTY_ARRAY

export const agentBookingQueuesSelector = state => {
  if (state.api.user.agent.user && state.api.user.agent.user.booking_queues) {
    return state.api.user.agent.user.booking_queues
  }

  return EMPTY_ARRAY
}

export const hasAgentRoleSelector = role => createSelector(
  [
    state => state.api.user.agent.user
  ],
  agent => agent ? agent.agent_roles.some(agentRole => agentRole.toLowerCase().split(' ').join('') === role) : null
)
