import PropTypes from 'prop-types'
import React, { Component } from 'react'

import _t from 's3p-js-lib/src/translate'
import Form from '../../../../elements/form/form'
import TextLabel from '../../../../elements/text-label/text-label'
import Link from '../../../../elements/link/link'
import Loader, { TYPE_COMPONENT } from '../../../../elements/loader/loader'
import Icon from '../../../../elements/icon/icon'
import Button from '../../../../elements/button/button'
import Label from '../../../../elements/label/label'
import { CATEGORY_LAST_NAME } from 's3p-js-lib/src/forms/find-customers'

export default class FindCustomersForm extends Component {
  static propTypes = {
    cntResult: PropTypes.number.isRequired,
    searchCategories: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })).isRequired,
    form: PropTypes.shape({
      loadFormValues: PropTypes.func.isRequired,
      fields: PropTypes.shape({
        term: PropTypes.object.isRequired,
        category: PropTypes.object.isRequired
      }).isRequired,
      valid: PropTypes.bool.isRequired
    }).isRequired,
    findCustomers: PropTypes.func.isRequired,
    onFindCustomers: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onClearTerms: PropTypes.func.isRequired,
    resetCustomers: PropTypes.func.isRequired,
    isLoadingFindCustomers: PropTypes.bool.isRequired,
    isLoadingLinkToCustomer: PropTypes.bool.isRequired,
    isResultFiltered: PropTypes.bool.isRequired
  }

  componentDidMount () {
    this.props.form.loadFormValues({category: CATEGORY_LAST_NAME})
    const element = document.getElementById('booking-search-term')
    element && setTimeout(() => element.focus(), 200)
  }

  componentWillUnmount () {
    this.props.onReset()
  }

  render () {
    return (
      <Form formContext='booking-details.find-customers-dialog'>
        <div className='toolbar'>
          <div className='toolbar__item'>
            <Label
              htmlFor='term'
              name='term'
              text={_t.message(`booking-details.find-customers-dialog.form.term.placeholder`)}
            />
            <Form.TextField
              {...this.props.form.fields.term}
              id='booking-search-term'
              errorText={null}
              name='term'
            />
            <Button
              name='clear-term'
              className='secondary'
              onClick={this.props.onClearTerms}
              title={_t.message(`booking-details.find-customers-dialog.form.term.clear`)}
              tabIndex='-1'
            >
              <TextLabel>
                <Icon name='cross' className='align-left' />
                <TextLabel.Text text={_t.message(`booking-details.find-customers-dialog.form.term.clear`)} />
              </TextLabel>
            </Button>
          </div>
          <div className='toolbar__item'>
            <Label
              htmlFor='category'
              name='category'
              text={_t.message(`booking-details.find-customers-dialog.form.category.placeholder`)}
            />
            <Form.Select
              {...this.props.form.fields.category}
              id='booking-search-category'
              errorText={null}
              name='category'
              data={this.props.searchCategories.map(searchCategory => ({
                text: _t.message(searchCategory.text),
                value: searchCategory.value
              }))}
            />
          </div>
          <div className='toolbar__item'>
            <Button
              loading={this.props.isLoadingLinkToCustomer}
              className='primary'
              name='search'
              hotkey='proceed'
              onClick={this.props.onFindCustomers}
              disabled={!this.props.form.valid || this.props.isLoadingFindCustomers}
              type='submit'
            >
              <TextLabel>
                <Icon name='search' className='align-left' />
                <TextLabel.Text text={_t.message(`booking-details.find-customers-dialog.form.search`)} />
              </TextLabel>
            </Button>
          </div>
          <div className='toolbar--item'>
            {!this.props.isLoadingFindCustomers && this.props.isResultFiltered ? (
              <TextLabel
                name='results'
                text={_t.message('booking-details.find-customers-dialog.form.result-summary', {
                  count: this.props.cntResult
                })}
              />
            ) : null}
            {!this.props.isLoadingFindCustomers && this.props.isResultFiltered ? (
              <Link name='reset' onClick={this.props.onReset}>
                <TextLabel name='reset' text={_t.message(`booking-details.find-customers-dialog.form.reset`)} />
              </Link>
            ) : null}
          </div>
          {this.props.isLoadingFindCustomers ? <Loader type={TYPE_COMPONENT} /> : null}
        </div>
        {!this.props.isLoadingFindCustomers && !this.props.isResultFiltered ? (
          <TextLabel
            name='support-label'
            text={_t.message(`booking-details.find-customers-dialog.no-search-description`)}
          />
        ) : null}
      </Form>
    )
  }
}
