import _t from 's3p-js-lib/src/translate'
import { routerActions as baseRouterActions } from 'react-router-redux'

export const routerActions = {
  replace: path => baseRouterActions.replace(`/${_t.getLocales()}${path}`),
  push: path => baseRouterActions.push(`/${_t.getLocales()}${path}`)
}

export const goToErrorPage = () => routerActions.push('/error')

export const goToAftersalesBooking = bookingNumber => routerActions.push(`/aftersales/booking/${bookingNumber}`)
export const goToAftersalesBookings = () => routerActions.push(`/aftersales/bookings`)

export const goToAftersalesChangeFulfillment = bookingNumber => routerActions.push(`/aftersales/change-fulfillment/${bookingNumber}`)
export const goToAftersalesChangeFulfillmentVoidTickets = bookingNumber => routerActions.push(`/aftersales/change-fulfillment/void-tickets/${bookingNumber}`)

export const goToAftersalesUpdatePassengers = () => routerActions.push('/aftersales/update-passengers')
export const goToAftersalesUpdatePassengersVoidTickets = () => routerActions.push('/aftersales/update-passengers/void-tickets')
export const goToAftersalesUpdatePassengersReviewPayment = () => routerActions.push(`/aftersales/update-passengers/review-payment`)

export const goToAftersalesChangeSeats = () => routerActions.push('/aftersales/change-seats')
export const goToAftersalesChangeSeatsVoidTickets = () => routerActions.push('/aftersales/change-seats/void-tickets')
export const goToAftersalesChangeSeatsReviewPayment = () => routerActions.push('/aftersales/change-seats/review-payment')

export const goToAftersalesRebookingVoidTickets = bookingNumber => routerActions.push(`/aftersales/rebooking/void-tickets/${bookingNumber}`)
export const goToAftersalesRebookingJourneySearch = () => routerActions.push('/aftersales/rebooking/journey-search')

export const goToAftersalesCancellation = () => routerActions.push('/aftersales/cancellation')
export const goToAftersalesCancellationVoid = () => routerActions.push('/aftersales/cancellation/void')

export const goToReservationsJourneySearch = () => routerActions.push('/reservations/journey-search')
export const goToReservationsDetails = () => routerActions.push('/reservations/details')
export const goToReservationsReviewPayment = () => routerActions.push('/reservations/review-payment')

export const goToTickets = productFamily => routerActions.push(`/tickets?productFamily=${productFamily}`)

export const goToLeap = () => routerActions.push('/leap')
