import React from 'react'
import GridLayout from '../../../elements/grid/grid-layout'
import GridColumn from '../../../elements/grid/grid-column'
import CancelBy from '../../../containers/aftersales/cancellation/cancel-by'
import CancelItems from '../../../containers/aftersales/cancellation/cancel-items'
import WaveFees from '../../../containers/aftersales/cancellation/waive-fees'
import RefundDetails from '../../../containers/aftersales/cancellation/refund-details'

const CancellationLayout = () => (
  <GridLayout name='left-content'>
    <GridColumn name='left'>
      <CancelBy />
    </GridColumn>
    <GridColumn name='content'>
      <CancelItems />
      <WaveFees />
      <RefundDetails />
    </GridColumn>
  </GridLayout>
)

export default CancellationLayout
