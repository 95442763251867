import React, { Component } from 'react'
import PropTypes from '../../../misc/prop-types'
import _t from 's3p-js-lib/src/translate'
import countBy from 'lodash/countBy'
import TextLabel from '../../../elements/text-label/text-label'
import Icon from '../../../elements/icon/icon'
import Button from '../../../elements/button/button'

export default class SidebarCartItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      origin: PropTypes.shape({
        name: PropTypes.string.isRequired
      }),
      destination: PropTypes.shape({
        name: PropTypes.string.isRequired
      }),
      itemRefs: PropTypes.array.isRequired,
      passengers: PropTypes.array.isRequired,
      price: PropTypes.number.isRequired,
      productName: PropTypes.string.isRequired,
      validFrom: PropTypes.moment.isRequired
    }),
    removeItems: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    disabled: false
  }

  constructor (props, ...args) {
    super(props, ...args)
    this.handleRemoveItem = props.removeItems.bind(null, props.item.itemRefs)
  }

  render () {
    const passengerCount = countBy(this.props.item.passengers, 'type')
    return (
      <div className='cart-item'>
        <div className='cart-item__action'>
          <Button
            tabIndex='-1'
            type='button'
            name='remove-cart-item'
            className='alert'
            onClick={this.handleRemoveItem}
            disabled={this.props.disabled}
          >
            <TextLabel name='remove-cart-item'>
              <Icon name='subtract' className='align-left' />
              <TextLabel.Text name='remove-cart-item' text={_t.message('cart.remove-item')} />
            </TextLabel>
          </Button>
        </div>
        <div className='cart-item__content'>
          <div className='cart-item-content'>
            {this.props.item.origin
              ? <TextLabel name='origin'>
                <TextLabel.Text text={this.props.item.origin.name} />
                <Icon name='arrow-right' />
              </TextLabel>
              : null}
            {this.props.item.destination
              ? <TextLabel name='destination' text={this.props.item.destination.name} />
              : null}
            <TextLabel name='product-family' text={this.props.item.productName} />
            <TextLabel name='valid-from-date' text={this.props.item.validFrom.format('L')} />
          </div>
          <div className='cart-item-price'>
            {Object.keys(passengerCount).map(type => (
              <TextLabel
                key={type}
                name='number-of-passenger-type'
                text={`${passengerCount[type]}${type}`}
              />
            ))}
            <TextLabel name='product-price' text={_t.formatCurrency(this.props.item.price)} />
          </div>
        </div>
      </div>
    )
  }
}
