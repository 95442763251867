import { connect } from 'react-redux'
import { requestRefundVoucher } from 's3p-js-lib/src/redux/actions/api/payment/refund'
import { refundVoucherSelector } from 's3p-js-lib/src/redux/selectors/api/payment/refund-voucher'
import RefundVoucher from '../../../../../components/aftersales/booking/payment-details/refund/refund-voucher'
import { showModal } from '../../../../../redux/actions/containers/base/visible-element'
import { REFUND_VOUCHER_MODAL } from '../../../../../constants'

const mapDispatchToProps = (_, ownProps) => dispatch => ({
  onRefundVoucher: () => dispatch(async dispatch => {
    const result = await dispatch(requestRefundVoucher())
    if (result) {
      dispatch(showModal(REFUND_VOUCHER_MODAL))
      ownProps.nextStep()
    }
  })
})

const mapStateToProps = state => ({
  refundedVoucher: refundVoucherSelector(state),
  isLoadingRefund: state.api.loading.requestRefund,
  refundSuccess: state.containers.aftersales.booking.refund.success
})

export default connect(mapStateToProps, mapDispatchToProps)(RefundVoucher)
