import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Form from '../../../../elements/form/form'

export default class TravelingPassengerFieldSet extends Component {
  static propTypes = {
    field: PropTypes.shape({
      active: PropTypes.bool.isRequired,
      value: PropTypes.bool,
      onChange: PropTypes.func.isRequired
    }).isRequired
  }

  render () {
    return (
      <fieldset>
        <Form.InputGroup name='is-passenger'>
          <Form.Checkbox id='is-passenger' name='is-passenger' {...this.props.field}>
            <Form.Label htmlFor='is-passenger' name='is-passenger' />
          </Form.Checkbox>
        </Form.InputGroup>
      </fieldset>
    )
  }
}
