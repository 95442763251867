import { connect } from 'react-redux'
import { isOfflineSelectorCreator } from '../../../../redux/selectors/machine/offline'
import RetryReceipt from '../../../../components/base/finalize-booking/footer/retry-receipt'
import { printReceipt } from '../../../../redux/actions/containers/base/tiles/payment-methods'
import { DEVICE_ID_RECEIPT_PRINTER } from '../../../../constants'

const receiptPrinterOfflineSelector = isOfflineSelectorCreator(DEVICE_ID_RECEIPT_PRINTER)

const mapStateToProps = (state, ownProps) => ({
  disabled: ownProps.disabled || receiptPrinterOfflineSelector(state),
  loading: state.machine.loading.receiptMachinePrint
})

const mapDispatchToProps = {
  onRetry: printReceipt
}

export default connect(mapStateToProps, mapDispatchToProps)(RetryReceipt)
