import { connect } from 'react-redux'
import {
  fulfillmentMethodSelector,
  paymentRequiredSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import ReviewPayment from '../../../components/base/review-payment/review-payment'
import { selectedPaymentMethodSelector } from '../../../redux/selectors/containers/base/tiles/payment-methods'

const _paymentRequiredSelector = paymentRequiredSelector(bookingSelector)
const _fulfillmentMethodSelector = fulfillmentMethodSelector(bookingSelector)

const mapStateToProps = state => {
  const showFulfillmentMethodError = Boolean(bookingSelector(state) && !_fulfillmentMethodSelector(state))
  return {
    showPaymentMethods: Boolean(
      !showFulfillmentMethodError &&
      (_paymentRequiredSelector(state) || selectedPaymentMethodSelector(state))
    ),
    showFulfillmentMethodError,
    loading: state.api.v2.loading.confirmBooking
  }
}

export default connect(mapStateToProps)(ReviewPayment)
