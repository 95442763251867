import without from 'lodash/without'
import {
  CLEAR_STATE,
  USER_INPUT_CANCELLATION_SELECT_ITEM_IDS,
  USER_INPUT_CANCELLATION_DESELECT_ITEM_IDS
} from '../../../actions/types'

export const initialState = {
  selectedItemIds: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_INPUT_CANCELLATION_SELECT_ITEM_IDS:
      return {...state, selectedItemIds: [...state.selectedItemIds, ...action.itemIds]}
    case USER_INPUT_CANCELLATION_DESELECT_ITEM_IDS:
      return {...state, selectedItemIds: without(state.selectedItemIds, ...action.itemIds)}
    case CLEAR_STATE:
      return action.namespaces.includes('userInput.aftersales.cancellation') ? initialState : state
    default:
      return state
  }
}
