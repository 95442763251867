import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../text-label/text-label'
import Input from '../input/input'
import Label from '../label/label'
import HotkeyLabel from '../hotkey-label/hotkey-label'
import Hotkeys from '../utils/hotkeys'
import _t from 's3p-js-lib/src/translate'
import kebabCase from 'lodash/kebabCase'
import './check-radio-group.scss'

class RadioItem extends Component {
  static contextTypes = {
    formContext: PropTypes.string
  }

  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    groupName: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string,
    groupValue: PropTypes.string,
    disabled: PropTypes.bool,
    hotkey: PropTypes.string
  }

  constructor (props, ...args) {
    super(props, ...args)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)

    this.keyHandlers = {}
    if (props.hotkey) {
      this.keyHandlers[props.hotkey] = this.onChange
    }
  }

  onBlur () {
    this.props.onBlur && this.props.onBlur(this._getValue())
  }

  onChange () {
    if (this.props.onChange && !this._getChecked()) {
      this.props.onChange(this._getValue())
    }
  }

  _getValue () {
    return this.props.value || this.props.name
  }

  _getChecked () {
    return this.props.groupValue === this._getValue()
  }

  _renderContent () {
    if (this.props.children) {
      return this.props.children
    } else {
      const name = kebabCase(this.props.name)
      const message = `${this.context.formContext}.form.${kebabCase(this.props.groupName)}.values.${name}`
      return [
        <Label name={name} text={_t.message(message)} htmlFor={this.props.id} key='label' />,
        this.props.hotkey ? <HotkeyLabel hotkey={this.props.hotkey} key='hotkey' /> : null
      ]
    }
  }

  render () {
    const radio = (
      <Input.Radio
        name={this.props.groupName}
        id={this.props.id}
        checked={this._getChecked()}
        onBlur={this.onBlur}
        onChange={this.onChange}
        disabled={this.props.disabled}
      >
        {this._renderContent()}
      </Input.Radio>
    )

    return this.props.hotkey ? <Hotkeys handlers={this.keyHandlers}>{radio}</Hotkeys> : radio
  }
}

class RadioGroup extends Component {
  static contextTypes = {
    formContext: PropTypes.string
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    noLabel: PropTypes.bool
  }

  render () {
    const {name, value, children, noLabel, ...props} = this.props
    const message = `${this.context.formContext}.form.${kebabCase(name)}.label`
    return (
      <div className='check-radio-group'>
        {!noLabel && _t.hasMessage(message) ? (
          <div className='check-radio-group__description'>
            <TextLabel name='check-radio-group-description' text={_t.message(message)} />
          </div>
        ) : null}
        {React.Children.map(children, child => React.cloneElement(child, {
          ...props,
          groupName: name,
          groupValue: value
        }))}
      </div>
    )
  }
}

RadioGroup.RadioItem = RadioItem

export default RadioGroup
