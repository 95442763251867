import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Icon from '../icon/icon'
import classNames from 'classnames'
import HotkeyLabel from '../hotkey-label/hotkey-label'
import Hotkeys from '../utils/hotkeys'
import './button.scss'

export default class Button extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['button', 'submit']),
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    hotkey: PropTypes.string
  }

  static defaultProps = {
    loading: false,
    disabled: false,
    type: 'button'
  }

  constructor (props, ...args) {
    super(props, ...args)
    this.onClick = this.onClick.bind(this)
  }

  UNSAFE_componentWillMount () {
    if (this.props.hotkey) {
      this._setHotkeyHandler(this.props.hotkey)
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.hotkey !== nextProps.hotkey) {
      this._setHotkeyHandler(nextProps.hotkey)
    }
  }

  _setHotkeyHandler (hotkey) {
    this.keyHandlers = {}
    this.keyHandlers[hotkey] = this.onClick
  }

  onClick (event) {
    event.preventDefault()
    event.stopPropagation()
    if (!this.props.loading && !this.props.disabled) {
      if (this.props.onClick) {
        this.props.onClick(event)
      }
    }

    return false
  }

  render () {
    let {name, loading, disabled, className, children, hotkey, ...props} = this.props
    delete props.onClick

    className = classNames(
      'button',
      name ? `button-${name}` : '',
      loading ? 'state--is-loading' : '',
      className
    )
    disabled = loading || disabled

    const button = (
      <button {...props} onClick={this.onClick} className={className} disabled={disabled}>
        <div className='button__wrapper'>
          {loading ? <Icon name='loader' className='animation-loading' /> : null}
          {children}
        </div>
        {hotkey ? <HotkeyLabel hotkey={hotkey} /> : null}
      </button>
    )

    return hotkey ? <Hotkeys key={hotkey} handlers={this.keyHandlers} enabled={!disabled}>{button}</Hotkeys> : button
  }
}
