import {
  getCarriageLayoutsOrientation as baseGetCarriageLayoutsOrientation
} from 's3p-js-lib/src/redux/actions/api/base/seat-selector/carriage-layouts'
import { selectedSegmentIdSelector } from 's3p-js-lib/src/redux/selectors/user-input/base/seat-selector'
import {seatSelectionOptionsByLegIdSelector} from 's3p-js-lib/src/redux/selectors/api/orientation/seat-selection-options'

export const getCarriageLayoutsOrientation = () => async (dispatch, getState) => {
  const segmentId = selectedSegmentIdSelector(getState())
  const seatSelectionOption = seatSelectionOptionsByLegIdSelector(segmentId)(getState())
  return dispatch(
    baseGetCarriageLayoutsOrientation(
      seatSelectionOption.leg.serviceName,
      seatSelectionOption.leg.departureStation.UICStationCode,
      seatSelectionOption.leg.arrivalStation.UICStationCode,
      seatSelectionOption.leg.serviceScheduleDate
    )
  )
}

