import productSearchResult from './product-search-result'
import repeatLastBooking from './repeat-last-booking'
import shortcuts from './shortcuts'
import shortcut from './shortcut'

export default {
  productSearchResult,
  repeatLastBooking,
  shortcuts,
  shortcut
}
