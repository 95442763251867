import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import _t from 's3p-js-lib/src/translate'
import MainMenu from '../../../components/base/main-menu/main-menu'
import { showModal } from '../../../redux/actions/containers/base/visible-element'
import { currentPathnameSelector } from '../../../redux/selectors/containers/base/routing'
import { hasAgentRoleSelector } from 's3p-js-lib/src/redux/selectors/api/user/agent'
import { resetTickets } from '../../../redux/actions/containers/tickets/products'
import { resetReservations } from '../../../redux/actions/containers/reservations/finalize-booking'
import { SHIFT_MODAL, AGENT_ROLE_OPERATOR } from '../../../constants'
import { STATUS_AVAILABLE, STATUS_SELECTED, STATUS_DISABLED } from '../../../components/base/main-menu/main-menu-item'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { pendingPaymentsSelector } from '../../../redux/selectors/api/booking/payments'
import { ticketsApiLoadingSelector } from '../../../redux/selectors/containers/tickets/loading'

const _hasAgentRoleSelector = hasAgentRoleSelector(AGENT_ROLE_OPERATOR)
const _paymentsSelector = pendingPaymentsSelector(bookingSelector)

const mapStateToProps = state => {
  const pathname = currentPathnameSelector(state)
  const disabled = _paymentsSelector(state).length || ticketsApiLoadingSelector(state)
  const checkPathname = url => {
    if (disabled) {
      return STATUS_DISABLED
    }

    return pathname.indexOf(url) >= 0 ? STATUS_SELECTED : STATUS_AVAILABLE
  }

  return {
    statusTickets: checkPathname('/tickets'),
    statusReservations: checkPathname('/reservations'),
    statusAftersales: checkPathname('/aftersales'),
    statusBookings: checkPathname('/aftersales/bookings'),
    statusMaintenance: checkPathname('/maintenance'),
    statusTestCfd: checkPathname('/maintenance/test-cfd'),
    statusTestThermalPrinter: checkPathname('/maintenance/test-thermal-printer'),
    statusReadTicket: checkPathname('/aftersales/read-ticket'),
    statusVoucherToCash: checkPathname('/aftersales/voucher-to-cash'),
    statusLeap: checkPathname('/leap'),
    statusSetDarkness: checkPathname('/maintenance/set-darkness'),
    statusShift: disabled ? STATUS_DISABLED : STATUS_AVAILABLE,
    isAgentOperator: _hasAgentRoleSelector(state)
  }
}

const createHandleClick = url => () => dispatch => {
  dispatch([resetTickets(), resetReservations(), routerActions.push(`/${_t.getLocales()}${url}`)])
  return false
}

const mapDispatchToProps = {
  handleClickTickets: createHandleClick('/tickets'),
  handleClickReservations: createHandleClick('/reservations/journey-search'),
  handleClickBookings: createHandleClick('/aftersales/bookings'),
  handleClickReadTicket: createHandleClick('/aftersales/read-ticket'),
  handleClickVoucherToCash: createHandleClick('/aftersales/voucher-to-cash'),
  handleClickTestCfd: createHandleClick('/maintenance/test-cfd'),
  handleClickTestThermalPrinter: createHandleClick('/maintenance/test-thermal-printer'),
  handleClickLeap: createHandleClick('/leap'),
  handleClickSetDarkness: createHandleClick('/maintenance/set-darkness'),
  handleClickShift: () => showModal(SHIFT_MODAL)
}

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu)
