import PropTypes from 'prop-types'
import React, { Component } from 'react'
import JourneySection from './section'

export default class JourneyDetailsSections extends Component {
  static propTypes = {
    stations: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      transfer: PropTypes.object,
      arrivalTime: PropTypes.moment,
      departureTime: PropTypes.moment
    })),
    legs: PropTypes.array
  }

  _renderJourneySection (station, index, isTransfer) {
    const hasAlerts = (this.props.legs || []).some(leg => leg.travelInfo)
    const departureTime = station.transfer ? station.transfer.arrivalTime : station.departureTime
    const serviceProperties = this.props.legs && this.props.legs.length && (index === 0 || isTransfer)
      ? this.props.legs[0].serviceProperties
      : null

    return (
      <li key={`${index}${isTransfer ? '-transfer' : ''}`}>
        <JourneySection
          specialTransfer={isTransfer}
          showChange={Boolean(!isTransfer && station.transfer)}
          stationName={station.name}
          arrivalTime={station.arrivalTime}
          departureTime={departureTime}
          transfer={station.transfer}
          serviceProperties={serviceProperties}
          hasAlerts={hasAlerts}
        />
      </li>
    )
  }

  render () {
    return (
      <ul>
        {this.props.stations.map((station, index) => {
          return station.transfer
            ? [
              this._renderJourneySection(station, index),
              this._renderJourneySection(station, index, true)
            ]
            : this._renderJourneySection(station, index)
        })}
      </ul>
    )
  }
}
