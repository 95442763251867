import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Icon from '../../../../elements/icon/icon'
import TextLabel from '../../../../elements/text-label/text-label'
import TableHeader from '../../../../elements/table/table-header'
import { UI_TABLE_SORT_ASCENDING, UI_TABLE_SORT_DESCENDING } from 's3p-js-lib/src/constants'

const sortedPropType = PropTypes.oneOf([UI_TABLE_SORT_ASCENDING, UI_TABLE_SORT_DESCENDING, null])

export default class FindCustomersHeader extends Component {
  static propTypes = {
    onSortByFullName: PropTypes.func.isRequired,
    onSortByEmailAddress: PropTypes.func.isRequired,
    sortedByFullName: sortedPropType,
    sortedByEmailAddress: sortedPropType
  }

  renderSortIcon (sort) {
    return sort
      ? <Icon name={sort === UI_TABLE_SORT_ASCENDING ? 'chevron-up' : 'chevron-down'} className='align-right' />
      : null
  }

  render () {
    return (
      <thead>
        <tr>
          <TableHeader
            onClick={this.props.onSortByFullName}
            sort={this.props.sortedByFullName}
            sortable
          >
            <TextLabel name='fullName'>
              <TextLabel.Text text={_t.message('booking-details.find-customers-dialog.table.column-name')} />
              {this.renderSortIcon(this.props.sortedByFullName)}
            </TextLabel>
          </TableHeader>
          <TableHeader
            onClick={this.props.onSortByEmailAddress}
            sort={this.props.sortedByEmailAddress}
            sortable
          >
            <TextLabel name='emailAddress'>
              <TextLabel.Text text={_t.message('booking-details.find-customers-dialog.table.column-email')} />
              {this.renderSortIcon(this.props.sortedByEmailAddress)}
            </TextLabel>
          </TableHeader>
        </tr>
      </thead>
    )
  }
}
