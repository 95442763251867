import _t from 's3p-js-lib/src/translate'
import {originalBookingSelector} from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import {
  bookingSelector,
  bookingNumberSelector,
  fulfillmentMethodSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import {cancelItems} from 's3p-js-lib/src/redux/actions/api/aftersales/cancellation/cancel'
import {aftersalesConfirm} from 's3p-js-lib/src/redux/actions/api/aftersales/confirm'
import {selectedItemRefsSelector} from '../../../../selectors/user-input/aftersales/cancellation'
import {addBookingNote} from 's3p-js-lib/src/redux/actions/api/booking/add-booking-note'
import {aftersalesRevert} from 's3p-js-lib/src/redux/actions/api/aftersales/revert'
import {
  isCancellationVoidPageSelector,
  refundDetailsSelector
} from '../../../../selectors/containers/aftersales/cancellation'
import {
  isLeapFulfillment,
  isLeapTopUpFulfillment,
  isMagstripeOrTVMFulfillment
} from '../../../../../misc/utils'
import {
  canOverrideAftersalesRulesSelector,
  overrideValidationRulesSelector
} from '../../../../selectors/api/auth/auth'
import {additionalDetailValueSelector} from 's3p-js-lib/src/redux/selectors/api/booking/additional-details'
import {
  LEAP_STATUS_KEY,
  LEAP_STATUS_OK,
  LEAP_TOP_UP_KEY,
  LEAP_ADD_PRODUCT_KEY
} from '../../../../actions/containers/leap/leap'
import {
  showModal,
  hideElement
} from '../../../../actions/containers/base/visible-element'
import {elementVisibleSelector} from '../../../../selectors/containers/base/visible-element'
import {
  CANCELLATION_VOID_MODAL,
  PAYMENT_METHOD_CODE_VOUCHER,
  REFUND_VOUCHER_MODAL,
  PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD,
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_CODE_REFUND
} from '../../../../../constants'
import {clearState} from 's3p-js-lib/src/redux/actions/clear-state'
import {
  goToAftersalesBooking,
  goToAftersalesCancellation,
  goToAftersalesCancellationVoid
} from '../../base/routing'
import {shouldVoidMagstripeTicketsSelector} from '../../../../selectors/containers/aftersales/void-tickets'
import {cancellationAllowedSelector} from '../../../../selectors/api/booking/after-sales'
import {
  handleRefundPayment,
  initiateLocalRefund,
  setRefundStep
} from '../booking/refund'
import {paymentsSelector} from '../../../../selectors/api/booking/payments'
import {getBooking} from 's3p-js-lib/src/redux/actions/api/booking/booking'
import {requestRefundVoucher} from 's3p-js-lib/src/redux/actions/api/payment/refund'
import {displayNextCustomer} from '../../base/finalize-booking/next-customer'

const _bookingNumberSelector = bookingNumberSelector(originalBookingSelector)
const _fulfillmentMethodSelector = fulfillmentMethodSelector(originalBookingSelector)
const _canOverrideAftersalesRulesSelector = canOverrideAftersalesRulesSelector(originalBookingSelector)
const _overrideValidationRulesSelector = overrideValidationRulesSelector(originalBookingSelector)
const _cancellationAllowedSelector = cancellationAllowedSelector(originalBookingSelector)
const _additionalDetailTopUpStatusSelector = additionalDetailValueSelector(originalBookingSelector, `${LEAP_TOP_UP_KEY}_${LEAP_STATUS_KEY}`)
const _additionalDetailAddProductStatusSelector = additionalDetailValueSelector(originalBookingSelector, `${LEAP_ADD_PRODUCT_KEY}_${LEAP_STATUS_KEY}`)
const isVoidModalVisibleSelector = elementVisibleSelector(CANCELLATION_VOID_MODAL)
const _paymentsSelector = paymentsSelector(originalBookingSelector)

export const handleNavigationPrevious = () => async (dispatch, getState) => {
  await dispatch(aftersalesRevert(_bookingNumberSelector(getState())))
  dispatch(goToAftersalesCancellation())
}

export const handleNavigationNext = () => async (dispatch, getState) => {
  const state = getState()
  const fulfillmentMethod = _fulfillmentMethodSelector(state)
  const bookingNumber = _bookingNumberSelector(state)

  if (isCancellationVoidPageSelector(state)) {
    if (((isMagstripeOrTVMFulfillment(fulfillmentMethod) && !state.containers.aftersales.voidTickets.success) ||
        (isLeapTopUpFulfillment(fulfillmentMethod) && !state.containers.aftersales.cancellation.reverseLeap.success)) && !isVoidModalVisibleSelector(state)
    ) {
      return dispatch(showModal(CANCELLATION_VOID_MODAL))
    }
    dispatch(hideElement(CANCELLATION_VOID_MODAL))

    await dispatch(cancelItems(
      bookingNumber,
      selectedItemRefsSelector(state),
      state.userInput.aftersales.waiveFees,
      !_cancellationAllowedSelector(state) && _canOverrideAftersalesRulesSelector(state),
      _overrideValidationRulesSelector(state)
    ))

    if (
      (isLeapTopUpFulfillment(fulfillmentMethod) && !state.containers.aftersales.cancellation.reverseLeap.success) ||
      (isMagstripeOrTVMFulfillment(fulfillmentMethod) && !state.containers.aftersales.voidTickets.success)
    ) {
      dispatch(addBookingNote(
        _bookingNumberSelector(state),
        {queue: '', content: _t.message('aftersales.void-tickets.override-rights-used')}
      ))
    }
    if (refundDetailsSelector(getState()).fee === 0) {
      dispatch(confirmAndRefund())
    } else { dispatch(goToAftersalesCancellation()) }
  } else if (!bookingSelector(state)) {
    if (
      (isLeapTopUpFulfillment(fulfillmentMethod) && _additionalDetailTopUpStatusSelector(state) === LEAP_STATUS_OK) ||
      (isLeapFulfillment(fulfillmentMethod) && _additionalDetailAddProductStatusSelector(state) === LEAP_STATUS_OK && !_overrideValidationRulesSelector(state).length) ||
      (shouldVoidMagstripeTicketsSelector(originalBookingSelector)(state))
    ) {
      dispatch(goToAftersalesCancellationVoid())
    } else {
      await dispatch(cancelItems(
        bookingNumber,
        selectedItemRefsSelector(state),
        state.userInput.aftersales.waiveFees,
        !_cancellationAllowedSelector(state) && _canOverrideAftersalesRulesSelector(state),
        _overrideValidationRulesSelector(state)
      ))
      if (refundDetailsSelector(getState()).fee === 0) {
        dispatch(confirmAndRefund())
      }
    }
  } else {
    dispatch(confirmAndRefund())
  }
}

const confirmAndRefund = () => async (dispatch, getState) => {
  const state = getState()
  const bookingNumber = _bookingNumberSelector(state)
  await dispatch(aftersalesConfirm(bookingNumber))
  dispatch([goToAftersalesBooking(bookingNumber), clearState('userInput.aftersales.waiveFees'), displayNextCustomer()])
  const payments = _paymentsSelector(state).filter(payment => payment.amount > 0 && payment.method !== PAYMENT_METHOD_CODE_REFUND && payment.refundAvailable)
  const methods = [PAYMENT_METHOD_CODE_VOUCHER, PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD, PAYMENT_METHOD_CASH]
  if (payments && payments.length === 1 && methods.some(method => method === payments[0].method)) {
    await dispatch(getBooking(bookingNumber))
    dispatch(handleRefundPayment())
    switch (payments[0].method) {
      case PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD:
        await dispatch(initiateLocalRefund())
        dispatch(setRefundStep(PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD))
        break
      case PAYMENT_METHOD_CODE_VOUCHER:
        if (await dispatch(requestRefundVoucher())) {
          dispatch(showModal(REFUND_VOUCHER_MODAL))
        }
        break
      case PAYMENT_METHOD_CASH:
        await dispatch(initiateLocalRefund())
        dispatch(setRefundStep(PAYMENT_METHOD_CASH))
        break
      default:
        break
    }
  }
}
