import PropTypes from 'prop-types'
import React, { Component } from 'react'
import GridLayout from '../../elements/grid/grid-layout'
import GridColumn from '../../elements/grid/grid-column'
import MainMenu from '../../containers/base/main-menu/main-menu'
import Taskbar from '../../containers/base/taskbar/taskbar'
import StickyFooter from '../../elements/sticky-footer/sticky-footer'
import GenericLayout from '../generic-layout'
import ReviewPayment from '../../containers/base/review-payment/review-payment'
import FinalizeBooking from '../../containers/aftersales/review-payment/finalize-booking'
import Operation from '../../containers/aftersales/operation/operation'
import ReturnPaymentModal from '../../containers/aftersales/review-payment/return-payment-modal'

export default class ReviewPaymentLayout extends Component {
  static propTypes = {
    labelTitle: PropTypes.string.isRequired,
    labelInformation: PropTypes.string.isRequired
  }

  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <MainMenu />
            <Operation
              labelTitle={this.props.labelTitle}
              labelInformation={this.props.labelInformation}
            />
            <ReviewPayment />
            <ReturnPaymentModal />
            <StickyFooter>
              <Taskbar />
            </StickyFooter>
          </GridColumn>
        </GridLayout>
        <FinalizeBooking />
      </GenericLayout>
    )
  }
}
