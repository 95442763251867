import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getMachineStationInformation } from '../../../redux/actions/machine/station-information'
import {
  journeySearch,
  loadDefaultFormValues
} from '../../../redux/actions/containers/reservations/journey-search/journey-search'
import { machineStationSelector } from '../../../redux/selectors/containers/base/stations'
import { FORM_JOURNEY_SEARCH } from 's3p-js-lib/src/constants-form'
import JourneySearch from '../../base/journey-search/journey-search'

class JourneySearchContainer extends Component {
  static propTypes = {
    getMachineStationInformation: PropTypes.func.isRequired,
    hasDefaultOriginStation: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoadingJourneySearch: PropTypes.bool.isRequired,
    handleJourneySearch: PropTypes.func.isRequired,
    loadDefaultFormValues: PropTypes.func.isRequired
  }

  UNSAFE_componentWillMount () {
    if (!this.props.isLoading && !this.props.hasDefaultOriginStation) {
      this.props.getMachineStationInformation()
    }
  }

  render () {
    return (
      <JourneySearch
        formName={FORM_JOURNEY_SEARCH}
        isLoading={this.props.isLoading}
        handleJourneySearch={this.props.handleJourneySearch}
        isLoadingJourneySearch={this.props.isLoadingJourneySearch}
        loadDefaultFormValues={this.props.loadDefaultFormValues}
      />
    )
  }
}

const mapStateToProps = state => ({
  hasDefaultOriginStation: Boolean(machineStationSelector(state)),
  isLoading: state.machine.stationInformation.isLoading,
  isLoadingJourneySearch: state.api.loading.journeySearch
})

const mapDispatchToProps = {
  getMachineStationInformation,
  handleJourneySearch: journeySearch,
  loadDefaultFormValues
}

export default connect(mapStateToProps, mapDispatchToProps)(JourneySearchContainer)
