import React from 'react'
import { Route } from 'react-router'
import ReadTicketLayout from '../../layouts/aftersales/read-ticket'
import containAuth from '../../containers/base/auth'
import { AGENT_ROLE_OPERATOR } from '../../constants'

export default (
  <Route
    key='aftersales-read-ticket'
    path='/:lang/aftersales/read-ticket'
    component={containAuth(ReadTicketLayout, {roles: [AGENT_ROLE_OPERATOR]})}
  />
)
