import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Button from '../../../../elements/button/button'
import TextLabel from '../../../../elements/text-label/text-label'
import Icon from '../../../../elements/icon/icon'
import { CANCELLATION_VOID_MODAL } from '../../../../constants'
import Modal from '../../../../elements/modal/modal'
import Feedback, { FEEDBACK_INFORMATION } from '../../../../elements/feedback/feedback'

export default class Navigation extends Component {
  static propTypes = {
    onClickPrevious: PropTypes.func.isRequired,
    onClickNext: PropTypes.func.isRequired,
    nextButtonDisabled: PropTypes.bool,
    showPreviousButton: PropTypes.bool.isRequired,
    showConfirmButton: PropTypes.bool.isRequired,
    showNextSubtext: PropTypes.bool.isRequired,
    isLoadingCancel: PropTypes.bool.isRequired,
    isLoadingConfirm: PropTypes.bool.isRequired,
    isLoadingRevert: PropTypes.bool.isRequired,
    hasSelectedItems: PropTypes.bool.isRequired,
    total: PropTypes.number,
    currency: PropTypes.string,
    showVoidModal: PropTypes.bool,
    isMagstripe: PropTypes.bool,
    isLeap: PropTypes.bool
  }

  _renderPreviousButton () {
    return this.props.showPreviousButton
      ? (
        <Button
          name='previous-cancellation-step'
          className='primary'
          onClick={this.props.onClickPrevious}
          loading={this.props.isLoadingRevert}
          disabled={this.props.isLoadingCancel || this.props.isLoadingConfirm}
        >
          <TextLabel name='previous-cancellation-step'>
            <Icon name='chevron-left' className='medium align-left' />
            <TextLabel.Text text={_t.message('aftersales.cancellation.navigation.previous')} />
          </TextLabel>
          <TextLabel
            name='previous-cancellation-step-description'
            text={_t.message('aftersales.cancellation.navigation.previous-sub')}
          />
        </Button>
      ) : null
  }

  _renderNextButton () {
    const subtext = this.props.showConfirmButton
      ? _t.message(
        'aftersales.cancellation.navigation.confirm-sub-refund',
        {total: _t.formatCurrency(this.props.total, this.props.currency)}
      )
      : this.props.showNextSubtext ? _t.message('aftersales.cancellation.navigation.next-sub') : null

    return (
      <Button
        name='next-cancellation-step'
        className='tertiary'
        onClick={this.props.onClickNext}
        loading={this.props.isLoadingCancel || this.props.isLoadingConfirm}
        disabled={this.props.nextButtonDisabled || !this.props.hasSelectedItems || this.props.isLoadingRevert}
      >
        <div className='text-label-wrapper'>
          <TextLabel name='next-cancellation-step'>
            <TextLabel.Text text={_t.message(
              `aftersales.cancellation.navigation.${this.props.showConfirmButton ? 'confirm' : 'next'}`
            )} />
            <Icon name='chevron-right' className='medium align-right' />
          </TextLabel>
          {subtext ? <TextLabel name='next-cancellation-step-description' text={subtext} /> : null}
        </div>
      </Button>
    )
  }

  _renderModal () {
    return this.props.showVoidModal
      ? (
        <Modal
          name={CANCELLATION_VOID_MODAL}
          isCloseable
          header={<Modal.Header title={
            this.props.isLeap
              ? _t.message('aftersales.cancellation.reverse-leap.title')
              : this.props.isMagstripe
                ? _t.message('aftersales.void-tickets.title')
                : null
          } />}
          footer={<Modal.Footer onContinue={this.props.onClickNext} />}
        >
          <Feedback
            status={FEEDBACK_INFORMATION}
            text={
              this.props.isLeap
                ? _t.message('aftersales.cancellation.reverse-leap.can-override-cancellation')
                : this.props.isMagstripe
                  ? _t.message('aftersales.void-tickets.can-override-void-tickets')
                  : null
            }
          />
        </Modal>
      ) : null
  }

  render () {
    return (
      <div className='segment-navigation'>
        {this._renderPreviousButton()}
        {this._renderNextButton()}
        {this._renderModal()}
      </div>
    )
  }
}
