import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import classNames from 'classnames'
import TextLabel from '../../../../elements/text-label/text-label'
import ProductIcon from './product-icon'

const PREFIX = 'aftersales.booking.journey-overview.segment-product-set'

const getProductClassName = product => classNames('segment-product', {
  'state--is-available': !product.cancelled,
  'has--tooltip': !product.cancelled,
  'state--is-disabled': product.cancelled
})

class SegmentProductTile extends Component {
  static propTypes = {
    product: PropTypes.shape({
      cancelled: PropTypes.bool.isRequired,
      type: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired
    }).isRequired
  }

  render () {
    const product = {...this.props.product}
    return (
      <div className={getProductClassName(product)} tabIndex={product.cancelled ? null : '0'}>
        <div className='segment-product__icon'>
          <ProductIcon productType={product.type} className='xlarge' />
        </div>
        <div className='segment-product__content'>
          <TextLabel name='product-type' text={product.description} />
          <TextLabel name='product-amount' text={_t.message(`${PREFIX}.count`, product)} />
        </div>
      </div>
    )
  }
}
class SegmentProduct extends Component {
  static propTypes = {
    segmentId: PropTypes.string.isRequired,
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
      aftersalesRules: PropTypes.array.isRequired
    }).isRequired

  }

  render () {
    const {product} = {...this.props}
    return (
      <li>
        <SegmentProductTile product={product} />
      </li>
    )
  }
}

export default class SegmentProductSet extends Component {
  static propTypes = {
    segmentId: PropTypes.string.isRequired,
    products: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired
    })).isRequired
  }

  render () {
    const {segmentId, products} = {...this.props}
    return (<div className='segment-product-set'>
      <ul>
        {products.map(product => <SegmentProduct key={product.id} segmentId={segmentId} product={product} />)}
      </ul>
    </div>)
  }
}
