import { createSelector } from 'reselect'
import {
  PAYMENT_METHOD_CODE_REFUND,
  PAYMENT_METHOD_DEFERRED_PAYMENT
} from '../../../../../constants'
import { paymentsSelector } from '../../../api/booking/payments'

export const hasDeferredPaymentsOnlySelector = bookingSelector => createSelector(
  [
    paymentsSelector(bookingSelector)
  ],
  payments => (payments || []).every(payment => payment.method === PAYMENT_METHOD_CODE_REFUND || payment.method === PAYMENT_METHOD_DEFERRED_PAYMENT)
)

export const isRefundAvailableSelector = bookingSelector => createSelector(
  [
    paymentsSelector(bookingSelector),
    hasDeferredPaymentsOnlySelector(bookingSelector)
  ],
  (payments, hasDeferredPaymentsOnly) => !hasDeferredPaymentsOnly && payments.some(payment => payment.refundAvailable)
)
