import { UI_JOURNEY_RESULT_SET_SORT_KEY, USER_INPUT_JOURNEY_SEARCH_SHOW_DETAILS } from '../../../../actions/types'
import { KEY_DEPARTURE } from '../../../../selectors/containers/base/journey-search/journey-result-set'
import { TRAVEL_DIRECTION_OUTBOUND, TRAVEL_DIRECTION_INBOUND } from 's3p-js-lib/src/constants'

const initialState = {
  showDetailsRouteId: null,
  sortKey: {
    [TRAVEL_DIRECTION_OUTBOUND]: KEY_DEPARTURE,
    [TRAVEL_DIRECTION_INBOUND]: KEY_DEPARTURE
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_INPUT_JOURNEY_SEARCH_SHOW_DETAILS:
      return {
        ...state,
        showDetailsRouteId: action.routeId
      }
    case UI_JOURNEY_RESULT_SET_SORT_KEY:
      return {
        ...state,
        sortKey: {
          ...state.sortKey,
          [action.direction]: action.key
        }
      }
    default:
      return state
  }
}
