import {
  MACHINE_GET_TERMINAL_INFO_REQUEST,
  MACHINE_GET_TERMINAL_INFO_SUCCESS,
  MACHINE_GET_TERMINAL_INFO_FAILURE
} from '../../actions/types'

export default (state = {data: {}, isLoading: false}, action) => {
  switch (action.type) {
    case MACHINE_GET_TERMINAL_INFO_REQUEST:
      return {
        data: {},
        isLoading: true
      }
    case MACHINE_GET_TERMINAL_INFO_FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case MACHINE_GET_TERMINAL_INFO_SUCCESS:
      return {
        isLoading: false,
        data: {...action.response}
      }
    default:
      return state
  }
}
