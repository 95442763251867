import { createSelector } from 'reselect'
import sortBy from 'lodash/sortBy'
import { camelCaseKeys } from '../../../../misc/utils'
import { toTimezoneMoment } from '../../../../misc/date'

export const stationNameToSearchString = text => text ? text.toLowerCase().replace(/[^a-z0-9 ]/g, '') : ''

export const stationMapper = station => ({
  ...station,
  ...camelCaseKeys(station),
  UICStationCode: station._u_i_c_station_code,
  name: station.name || station._u_i_c_station_code,
  arrivalTime: station.arrival_timestamp ? toTimezoneMoment(station.arrival_timestamp) : undefined,
  departureTime: station.departure_timestamp ? toTimezoneMoment(station.departure_timestamp) : undefined,
  searchString: stationNameToSearchString(station.name)
})

export const stationsSelector = createSelector(
  [state => state.api.orientation.stations],
  stations => {
    if (!stations) {
      return null
    }

    stations = stations.map(stationMapper)
    const parentStations = stations.reduce(
      (parents, station) => {
        if (typeof station.parent === 'undefined') {
          parents[station.UICStationCode] = station
        }
        return parents
      },
      {}
    )

    return sortBy(
      stations,
      station => {
        const parent = station.parent && parentStations[station.parent]
          ? parentStations[station.parent].name
          : ''
        return parent + station.name
      }
    )
  }
)
