import DOMPurify from 'dompurify'
import Validator from 's3p-js-lib/src/forms/validator'

export default {
  fields: {
    authorizationCode: {
      normalizers: [
        DOMPurify.sanitize
      ],
      validators: [
        Validator.isRequired
      ]
    }
  }
}
