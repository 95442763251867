import { CLEAR_STATE } from '../../../../actions/types'
import {
  API_V2_GET_PRODUCT_FAMILIES_REQUEST,
  API_V2_GET_PRODUCT_FAMILIES_SUCCESS,
  API_V2_GET_PRODUCT_FAMILIES_FAILURE
} from '../../../../actions/types-v2'

export const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case API_V2_GET_PRODUCT_FAMILIES_REQUEST:
    case API_V2_GET_PRODUCT_FAMILIES_FAILURE:
      return initialState
    case API_V2_GET_PRODUCT_FAMILIES_SUCCESS:
      return action.response.data.product_families
    case CLEAR_STATE:
      return action.namespaces.includes('api.v2.meta.productFamilies') ? initialState : state
    default:
      return state
  }
}
