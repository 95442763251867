import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ComponentContainer from '../../elements/component-container/component-container'
import ProgressNavigation from '../../elements/progress-navigation/progress-navigation'
import Panel from '../../elements/panel/panel'
import Feedback, { FEEDBACK_ALERT } from '../../elements/feedback/feedback'
import _t from 's3p-js-lib/src/translate'
import './error.scss'

export default class Error extends Component {
  static propTypes = {
    handleGoBack: PropTypes.func.isRequired,
    errorCodes: PropTypes.array
  }

  render () {
    return (
      <ComponentContainer name='application-error'>
        <Panel
          header={<Panel.Header
            name='application-error'
            title={_t.message('application-error.header')}
            description={_t.message('application-error.description')}
          />}
        >
          <Feedback status={FEEDBACK_ALERT}>
            {this.props.errorCodes
              ? _t.message('application-error.known-error', {errorCode: this.props.errorCodes.join(', ')})
              : _t.message('application-error.unknown-error')
            }
          </Feedback>
          <ProgressNavigation
            previous={<ProgressNavigation.PreviousButton
              text={_t.message('application-error.go-back-button')}
              onClick={this.props.handleGoBack}
            />}
          />
        </Panel>
      </ComponentContainer>
    )
  }
}
