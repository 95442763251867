import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Modal from '../../../../elements/modal/modal'
import WizardList from '../../../../elements/wizard-list/wizard-list'
import RefundRecords from '../../../../containers/aftersales/booking/payment-details/refund/refund-records'
import RefundCash from '../../../../containers/aftersales/booking/payment-details/refund/refund-cash'
import RefundCreditCard from '../../../../containers/aftersales/booking/payment-details/refund/refund-credit-card'
import RefundVoucher from '../../../../containers/aftersales/booking/payment-details/refund/refund-voucher'
import { isPedPaymentMethod } from '../../../../misc/utils'
import {
  PAYMENT_METHOD_CODE_VOUCHER,
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD,
  REFUND_MODAL
} from '../../../../constants'

export default class RefundModal extends Component {
  static propTypes = {
    nextStep: PropTypes.func,
    activeStep: PropTypes.string,
    refundOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
    refundRecords: PropTypes.arrayOf(PropTypes.shape({
      method: PropTypes.string
    })).isRequired
  }

  _renderRefundType (creditCardActive, cashActive, voucherActive) {
    let refundElement = null
    if (cashActive) {
      refundElement = <RefundCash
        key='cash'
        nextStep={this.props.nextStep}
        amount={this.props.refundRecords.find(record => record.method === PAYMENT_METHOD_CASH).amount}
      />
    } else if (creditCardActive) {
      refundElement = <RefundCreditCard
        key='credit-card'
        nextStep={this.props.nextStep}
        amount={this.props.refundRecords.find(record => isPedPaymentMethod(record.method)).amount}
      />
    } else if (voucherActive) {
      refundElement = <RefundVoucher
        key='voucher'
        nextStep={this.props.nextStep}
        amount={this.props.refundRecords.length ? this.props.refundRecords.find(record => record.method === PAYMENT_METHOD_CODE_VOUCHER).amount : null}
      />
    }
    return refundElement
  }

  render () {
    const overviewActive = !this.props.activeStep
    const creditCardActive = this.props.activeStep === PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD
    const cashActive = this.props.activeStep === PAYMENT_METHOD_CASH
    const voucherActive = this.props.activeStep === PAYMENT_METHOD_CODE_VOUCHER
    return (
      <Modal
        isCloseable={false}
        name={REFUND_MODAL}
        header={<Modal.Title
          title={_t.message(`aftersales.refund-modal.title`)}
        />}
      >
        <WizardList>
          <WizardList.Item text={_t.message(`aftersales.refund-modal.steps.overview`)} active={overviewActive} />
          {this.props.refundOrder.includes(PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD)
            ? <WizardList.Item
              text={_t.message(`aftersales.refund-modal.steps.ped`)}
              active={creditCardActive}
            />
            : null}
          {this.props.refundOrder.includes(PAYMENT_METHOD_CASH)
            ? <WizardList.Item
              text={_t.message(`aftersales.refund-modal.steps.cash`)}
              active={cashActive}
            />
            : null}
          {this.props.refundOrder.includes(PAYMENT_METHOD_CODE_VOUCHER)
            ? <WizardList.Item
              text={_t.message(`aftersales.refund-modal.steps.voucher`)}
              active={voucherActive}
            />
            : null}
        </WizardList>
        {overviewActive ? <RefundRecords
          refundRecords={this.props.refundRecords}
          nextStep={this.props.nextStep}
        /> : null}
        {this.props.refundRecords.length ? this._renderRefundType(creditCardActive, cashActive, voucherActive) : null}
      </Modal>
    )
  }
}
