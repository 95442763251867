import request from './request'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'
import {snakeCaseKeys, stringifyQueryEncode as stringifyQuery} from '../misc/utils'
import fetchTextFile from './fetch-text-file'
import {clientRequestsV2, clientRequestsV3, requestClient} from '@s3p-js-deep-purple/api-client-requests'
import {prepareGetOfferPostData} from 's3p-js-lib/src/redux/actions/api/v2/journey-search-mapper'

requestClient.setBaseRequestClientOptions({})
requestClient.setBaseRequestClientRequester(request)

const preparePassengerDetailsData = passenger => omitBy({
  id: passenger.id,
  first_name: passenger.firstName || '',
  last_name: passenger.lastName || '',
  title: passenger.title || undefined,
  gender: passenger.gender || undefined,
  email: passenger.emailAddress || undefined,
  phone_number: passenger.phoneNumber || undefined,
  birth_date: passenger.birthDate || undefined,
  nationality: passenger.nationality || undefined,
  protect_privacy: 'protectPrivacy' in passenger ? passenger.protectPrivacy : undefined
}, isUndefined)

const Client = {
  async loadUserInformation (token) {
    return clientRequestsV2.getUserInformation({token})
  },

  async loadAgentUserInformation (token) {
    return clientRequestsV2.getAgentInformation({token})
  },

  async loadStations (token) {
    return clientRequestsV2.getStations({token})
  },

  async loadCmsBlocks (token, data) {
    const query = Array.isArray(data)
      ? {block: data}
      : data
    return request({
      endpoint: '/api/v2/cms?' + stringifyQuery(query),
      token
    })
  },

  async updateAgent (token, data) {
    return clientRequestsV2.updateAgent(
      {token},
      {...snakeCaseKeys(data)}
    )
  },

  async getBookings (token, fieldName, fieldValue) {
    const filter = fieldName && fieldValue ? {
      filter_field: fieldName,
      filter_value: fieldValue
    } : undefined
    return clientRequestsV2.getBookings({token}, filter)
  },

  async getBooking (token, bookingNumber) {
    return clientRequestsV2.getBooking({token}, {bookingNumber})
  },

  async cancelBooking (token, bookingNumber) {
    return clientRequestsV2.cancelBooking({token}, {bookingNumber})
  },

  async createProvisionalBooking (token, data, options = {}) {
    return clientRequestsV2.createBooking({...options, token}, data)
  },

  async getOffer (token, data) {
    return clientRequestsV2.journeySearch(
      {token},
      prepareGetOfferPostData(data, false)
    )
  },

  async rebookingJourneySearch (token, data) {
    return clientRequestsV2.journeySearch(
      {token},
      prepareGetOfferPostData(data, true)
    )
  },

  async addVoucher (token, bookingNumber, voucherCode) {
    return clientRequestsV2.addVoucher(
      {token},
      {booking_number: bookingNumber, voucher_code: voucherCode}
    )
  },

  async removeVoucher (token, bookingNumber, voucherCode) {
    return clientRequestsV2.removeVoucher(
      {token},
      {booking_number: bookingNumber, voucher_code: voucherCode}
    )
  },

  async requestRefund (token, bookingNumber, type) {
    return clientRequestsV2.requestRefund({token}, {booking_number: bookingNumber, type})
  },

  async addCustomerToBooking (token, bookingNumber, customerDetails) {
    return clientRequestsV2.addCustomer({token}, {bookingNumber, customer: customerDetails})
  },

  async updateBookingCustomer (token, bookingNumber, customerDetails) {
    return clientRequestsV2.updateCustomer({token}, {bookingNumber, customer: customerDetails})
  },

  async getPaymentMethods (token, bookingNumber) {
    return clientRequestsV2.getPaymentMethods({token}, {bookingNumber})
  },

  async getCarriageLayoutsOrientation (token, serviceName, fromStationUIC, toStationUIC, travelDate) {
    return clientRequestsV2.getCarriageLayouts({token}, {serviceName, fromStationUIC, toStationUIC, travelDate})
  },

  async getCarriageLayoutsAftersales (token, serviceName, fromStationUIC, toStationUIC, travelDate) {
    return clientRequestsV2.getCarriageLayouts({token}, {serviceName, fromStationUIC, toStationUIC, travelDate})
  },

  async changeSeatsInitiate (token, bookingNumber) {
    return clientRequestsV2.getBooking({token}, {bookingNumber})
  },

  async cancellationInitiate (token, bookingNumber) {
    return clientRequestsV2.getBooking({token}, {bookingNumber})
  },

  async cancellationCancel (token, bookingNumber, itemIds, waiveFees, overrideAftersalesRules, overrideValidationRules) {
    return clientRequestsV2.cancelItems(
      {token}, {
        bookingNumber,
        cancel_item_refs: itemIds,
        override_aftersales_fees: waiveFees,
        override_aftersales_rules: overrideAftersalesRules || undefined,
        override_validation_rules: overrideValidationRules.length ? overrideValidationRules : undefined
      }
    )
  },

  async rebookInitiate (token, bookingNumber) {
    return clientRequestsV2.getBooking({token}, {bookingNumber})
  },

  async updatePassengersInitiate (token, bookingNumber) {
    return clientRequestsV2.getBooking({token}, {bookingNumber})
  },

  async addBookingNote (token, bookingNumber, note) {
    return clientRequestsV2.addBookingNote(
      {token},
      {
        bookingNumber,
        content: note.content,
        booking_queue: note.queue
      }
    )
  },

  async aftersalesConfirm (token, bookingNumber) {
    return clientRequestsV2.confirmBooking({token}, {bookingNumber})
  },

  async aftersalesRevert (token, bookingNumber) {
    return clientRequestsV2.revertBooking({token}, {bookingNumber})
  },

  async additionalProductsInitiate (token, bookingNumber) {
    return clientRequestsV2.getBooking({token}, {bookingNumber})
  },

  async additionalProductsUpdate (token, bookingNumber, segments) {
    return clientRequestsV2.updateBooking({token}, {bookingNumber, segments})
  },

  async findCustomers (token, {
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    companyName,
    streetName,
    houseNumber,
    postalCode,
    city
  }) {
    const queryParams = omitBy({
      first_name: firstName,
      last_name: lastName,
      email: emailAddress,
      phone_number: phoneNumber,
      company_name: companyName,
      street: streetName,
      house_number: houseNumber,
      postal_code: postalCode,
      city
    }, isUndefined)

    return clientRequestsV3.getV3Customers({token}, queryParams)
  },

  async getJourneyDetails (token, services) {
    return clientRequestsV2.searchServices({token}, {service_identifiers: services})
  },

  async getDocumentTemplates (token, documentTemplateTypes) {
    return clientRequestsV2.getDocumentTemplates(
      {token},
      {template_types: documentTemplateTypes}
    )
  },

  async startAgentShift (token, amount, currency, deviceId, notes, metaData, location) {
    return clientRequestsV2.startAgentShift(
      {token},
      {
        floats: [{
          float_start_amount: amount,
          currency
        }],
        device_id: deviceId,
        notes: notes ? notes.map(note => ({content: note})) : undefined,
        meta_data: metaData,
        location
      }
    )
  },

  async getAgentShift (token) {
    return clientRequestsV2.getAgentShift({token})
  },

  async headAgentShift (token) {
    return clientRequestsV2.headAgentShift({token})
  },

  async endAgentShift (token, notes, metaData) {
    return clientRequestsV2.endAgentShift(
      {token},
      {
        notes: notes ? notes.map(note => ({content: note})) : undefined,
        meta_data: metaData
      }
    )
  },

  async addNotesAgentShift (token, notes) {
    return clientRequestsV2.addAgentShiftNotes(
      {token},
      {notes: notes.map(note => ({content: note}))}
    )
  },

  async getVouchers (token, voucherCode) {
    return clientRequestsV2.getVouchers({token}, {voucher_code: voucherCode})
  },

  async ticketVoidValidation (token, ticketNumber) {
    return clientRequestsV2.ticketVoidValidation({token}, {
      ticketNumber,
      void_types: ['ALL']
    })
  },

  async getTextFile (url) {
    return fetchTextFile(url, {
      method: 'GET'
    })
  },

  async ping () {
    return clientRequestsV2.ping()
  },

  async getProducts (token, data) {
    const query = {
      product_family: data.productFamily,
      segments: data.segments.map((segment, index) => ({
        id: segment.id || `segment_${index + 1}`,
        origin_station: segment.origin,
        destination_station: segment.destination,
        start_validity_date: segment.validFrom.format('YYYY-MM-DD'),
        direction: segment.direction
      })),
      passengers: data.passengers.map(snakeCaseKeys),
      product_type: data.productType
    }
    return clientRequestsV2.productSearch({token}, query)
  },

  async getProductFamilies (token, salesChannel) {
    return clientRequestsV2.getProductFamilies({token}, {sales_channel: salesChannel})
  },

  async getCurrencyRates (token) {
    return clientRequestsV2.getCurrencyRates({token})
  },

  async createBooking (token, segments, products, passengers, options = {}) {
    const data = {
      segments: segments.map(segment => ({
        origin: segment.origin,
        destination: segment.destination,
        start_validity_date: segment.departureDate,
        items: products.map(product => ({
          passenger_id: product.passengerId,
          tariff_code: product.tariffCode
        }))
      })),
      passengers: passengers.map((passenger, index) => ({
        ...snakeCaseKeys(passenger),
        id: `passenger_${index + 1}`
      }))
    }
    return clientRequestsV2.createBooking({token, ...options}, data)
  },

  async updateSegments (token, bookingNumber, segments, products) {
    const data = {
      segments: segments.map(segment => ({
        origin: segment.origin,
        destination: segment.destination,
        start_validity_date: segment.departureDate,
        items: products.map(product => ({
          passenger_id: product.passengerId,
          tariff_code: product.tariffCode
        }))
      }))
    }
    return clientRequestsV2.updateBooking({token}, {bookingNumber, ...data})
  },

  async updateFulfillmentMethod (token, bookingNumber, fulfillmentMethodCode) {
    return clientRequestsV2.updateFulfillmentMethod(
      {token},
      {bookingNumber, fulfillment_method_code: fulfillmentMethodCode}
    )
  },

  async removeItems (token, bookingNumber, itemRefs) {
    return clientRequestsV2.deleteBookingItems({token}, {bookingNumber, item_refs: itemRefs})
  },

  async confirmBooking (token, bookingNumber) {
    return clientRequestsV2.confirmBooking({token}, {bookingNumber})
  },

  async addAdditionalDetails (token, bookingNumber, additionalDetails) {
    return clientRequestsV2.addBookingAdditionalDetails(
      {token},
      {bookingNumber, additional_details: additionalDetails.map(snakeCaseKeys)}
    )
  },

  async updateAdditionalDetails (token, bookingNumber, additionalDetails) {
    return clientRequestsV2.updateBookingAdditionalDetails(
      {token},
      {bookingNumber, additional_details: additionalDetails.map(snakeCaseKeys)}
    )
  },

  async addPayments (token, payments, bookingNumber) {
    return clientRequestsV2.addPayments({token}, {bookingNumber, payments: payments.map(snakeCaseKeys)})
  },

  async updatePayments (token, payments, bookingNumber) {
    return clientRequestsV2.updatePayments({token}, {bookingNumber, payments: payments.map(snakeCaseKeys)})
  },

  async getShiftReport (token, shiftId, templateName) {
    return clientRequestsV2.agentShiftReport({token}, {shift_id: shiftId, template_name: templateName})
  },

  async getTicketDetails (token, ticketNumber, data) {
    return clientRequestsV2.getTicketDetails({token}, {ticketNumber, ...data})
  },

  async updatePassengers (token, bookingNumber, passengers) {
    return clientRequestsV2.updatePassengers(
      {token},
      {bookingNumber, passengers: (passengers && passengers.map(preparePassengerDetailsData))}
    )
  }
}

export default Client
