import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { routerActions } from '../../../../redux/actions/containers/base/routing'
import containRebookingFlow from '../../../aftersales/rebooking-flow'
import { updateSelectBundleWithSeatSelection } from 's3p-js-lib/src/redux/actions/api/orientation/update-select-bundle'
import { segmentsSelector } from '../../../../redux/selectors/containers/base/seat-selector/segment-navigation'
import {
  allSegmentsSeatSelectionOptionsSelector
} from '../../../../redux/selectors/containers/base/seat-selection/seat-selection'
import {
  isSeatSelectionValidSelector,
  hasPendingSeatSelectionSelector,
  selectedSeatsSelector,
  passengersSelector
} from 's3p-js-lib/src/redux/selectors/user-input/base/seat-selector'
import {
  seatSelectionAvailableOptionsSelector,
  seatSelectionUnavailableOptionsSelector
} from 's3p-js-lib/src/redux/selectors/user-input/base/seat-selection'
import containVisibleElement from '../../../base/visible-element'
import ProgressNavigation, { ABORT_DIALOG_NAME } from '../../../../components/base/seat-selector/progress-navigation'

const SegmentPropType = PropTypes.shape({
  leg: PropTypes.shape({
    id: PropTypes.string
  })
})

class ProgressNavigationContainer extends Component {
  static propTypes = {
    confirmSeatSelection: PropTypes.func.isRequired,
    selectedSeats: PropTypes.array,
    hasPendingChanges: PropTypes.bool,
    hasSelectedSeats: PropTypes.bool,
    isElementVisible: PropTypes.func,
    onHideElement: PropTypes.func,
    onShowModal: PropTypes.func,
    handleGoToSegment: PropTypes.func.isRequired,
    goForward: PropTypes.func,
    activeSegment: SegmentPropType,
    nextSegment: SegmentPropType,
    previousSegment: SegmentPropType
  }

  constructor (...args) {
    super(...args)

    this.handleAbort = this.handleAbort.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handlePrevious = this.handlePrevious.bind(this)
    this.handleAbortContinue = this.handleAbortContinue.bind(this)
  }

  _goToSegmentOrMainFlow (segment) {
    if (segment) {
      this.props.handleGoToSegment(segment.leg.id)
    } else {
      this.props.goForward()
    }
  }

  _confirmSegment (segment) {
    if (this.props.confirmSeatSelection(this.props.activeSegment.leg.id, this.props.selectedSeats)) {
      this._goToSegmentOrMainFlow(segment)
    }
  }

  handleAbortContinue () {
    this.props.onHideElement(ABORT_DIALOG_NAME)
    this._goToSegmentOrMainFlow()
  }

  handleAbort () {
    if (this.props.hasSelectedSeats) {
      this.props.onShowModal(ABORT_DIALOG_NAME)
    } else {
      this.handleAbortContinue()
    }
  }

  handleNext () {
    if (this.props.hasPendingChanges) {
      this._confirmSegment(this.props.nextSegment)
    } else {
      this._goToSegmentOrMainFlow(this.props.nextSegment)
    }
  }

  handlePrevious () {
    if (this.props.hasPendingChanges) {
      this._confirmSegment(this.props.previousSegment)
    } else {
      this._goToSegmentOrMainFlow(this.props.previousSegment)
    }
  }

  render () {
    return (
      <ProgressNavigation
        {...this.props}
        onNext={this.handleNext}
        handlePrevious={this.handlePrevious}
        onAbort={this.handleAbort}
        onAbortContinue={this.handleAbortContinue}
        showAbortDialog={this.props.isElementVisible(ABORT_DIALOG_NAME)}
        confirmSeatSelection={this.confirmSeatSelection}
      />
    )
  }
}

const unavailableSegmentsSelector =
  seatSelectionUnavailableOptionsSelector(allSegmentsSeatSelectionOptionsSelector)
const availableSegmentsSelector =
  segmentsSelector(seatSelectionAvailableOptionsSelector(allSegmentsSeatSelectionOptionsSelector))

const mapStateToProps = (state, ownProps) => {
  const passengers = passengersSelector(state, ownProps)
  const selectedSeats = selectedSeatsSelector(state, ownProps)
  const segments = availableSegmentsSelector(state, ownProps)

  const segmentIndex = segments.findIndex(segment => segment.leg.id === ownProps.segmentId)
  const segment = segmentIndex >= 0 ? segments[segmentIndex] : null
  const nextSegment = segmentIndex >= 0 && (segmentIndex + 1) < segments.length ? segments[segmentIndex + 1] : null
  const previousSegment = segmentIndex >= 1 ? segments[segmentIndex - 1] : null

  return {
    activeSegment: segment,
    previousSegment,
    nextSegment,
    selectedSeats,
    selectionsLeft: passengers.length - selectedSeats.length,
    isValid: isSeatSelectionValidSelector(state),
    isLoading: state.api.loading.updateSelectBundle,
    hasPendingChanges: hasPendingSeatSelectionSelector(state, ownProps),
    hasSelectedSeats: selectedSeatsSelector(state).length > 0,
    unavailableSegments: unavailableSegmentsSelector(state)
  }
}

const mapDispatchToProps = {
  handleGoToSegment: legId => routerActions.push(`/aftersales/rebooking/seat-selector/${legId}`),
  confirmSeatSelection: (legId, selectedSeats) => updateSelectBundleWithSeatSelection(legId, selectedSeats)
}

export default connect(mapStateToProps, mapDispatchToProps)(
  containRebookingFlow(containVisibleElement(ABORT_DIALOG_NAME)(ProgressNavigationContainer))
)
