import { connect } from 'react-redux'
import ProgressNavigation from '../../../components/aftersales/change-seats/progress-navigation'
import { handleUpdateChangeSeats } from '../../../redux/actions/containers/aftersales/change-seats/progress-navigation'
import {
  bookingSelector,
  paymentRequiredSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'

const _paymentRequiredSelector = paymentRequiredSelector(bookingSelector)

const mapStateToProps = state => ({
  disabled: !bookingSelector(state),
  loading: state.api.loading.aftersalesConfirm,
  isPaymentRequired: _paymentRequiredSelector(state)
})

const mapDispatchToProps = {
  onClickNext: handleUpdateChangeSeats
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressNavigation)
