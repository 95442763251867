import React from 'react'
import { connect } from 'react-redux'
import { HOTKEY_MAP_GLOBAL } from './constants'
import HotKeys from './elements/utils/hotkeys'
import PropTypes from './misc/prop-types'
import {modalIsActiveSelector} from './redux/selectors/containers/base/visible-element'

const BaseGlobalHotKeys = ({globalEnabled}) => globalEnabled ? <HotKeys keyMap={HOTKEY_MAP_GLOBAL} /> : null
BaseGlobalHotKeys.propTypes = {
  globalEnabled: PropTypes.bool
}

const mapStateToProps = state => ({
  globalEnabled: !modalIsActiveSelector(state)
})

const GlobalHotKeys = connect(mapStateToProps)(BaseGlobalHotKeys)

export default GlobalHotKeys
