import {
  MACHINE_GET_TERMINAL_INFO_REQUEST,
  MACHINE_GET_TERMINAL_INFO_SUCCESS,
  MACHINE_GET_TERMINAL_INFO_FAILURE
} from '../types'
import DeviceManager from '../../../machine/device-manager'

const getTerminalInfoRequest = () => ({type: MACHINE_GET_TERMINAL_INFO_REQUEST})
const getTerminalInfoSuccess = response => ({type: MACHINE_GET_TERMINAL_INFO_SUCCESS, response})
const getTerminalInfoFailure = machineError => ({type: MACHINE_GET_TERMINAL_INFO_FAILURE, machineError})

export const getMachineTerminalInformation = () => async dispatch => {
  try {
    dispatch(getTerminalInfoRequest())
    const response = await DeviceManager.getTerminalInformation()
    dispatch(getTerminalInfoSuccess(response))
  } catch (error) {
    dispatch(getTerminalInfoFailure(error))
  }
}
