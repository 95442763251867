import React, { Component } from 'react'
import PropTypes from '../../misc/prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Loader, { TYPE_BLOCKING } from '../../elements/loader/loader'
import { goToAftersalesBookings } from '../../redux/actions/containers/base/routing'
import {
  bookingNumberSelector,
  overviewBookingSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { getBooking } from 's3p-js-lib/src/redux/actions/api/booking/booking'

const container = WrappedComponent => (class LoadOverviewBookingContainer extends Component {
  static propTypes = {
    params: PropTypes.shape({
      bookingNumber: PropTypes.string
    }),
    bookingNumber: PropTypes.string,
    onBookingError: PropTypes.func.isRequired,
    loadingBooking: PropTypes.bool,
    getBooking: PropTypes.func.isRequired
  }

  constructor (props, ...args) {
    super(props, ...args)

    this.state = {
      loading: false
    }
  }

  UNSAFE_componentWillMount () {
    const bookingNumber = this.props.params && this.props.params.bookingNumber

    if (bookingNumber && bookingNumber !== this.props.bookingNumber) {
      this.setState({loading: true})
      this.props.getBooking(bookingNumber)
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.state.loading && nextProps.bookingNumber) {
      this.setState({loading: false})
    } else if (this.state.loading && !nextProps.bookingNumber && !nextProps.loadingBooking) {
      this.props.onBookingError()
    }
  }

  render () {
    if (this.state.loading) {
      return <Loader type={TYPE_BLOCKING} />
    }

    const props = {...this.props}
    delete props.bookingNumber
    delete props.loadingBooking
    delete props.getBooking
    delete props.onBookingError

    return <WrappedComponent {...props} />
  }
})

const connectState = () => {
  const _bookingNumberSelector = bookingNumberSelector(overviewBookingSelector)
  const mapStateToProps = state => ({
    loadingBooking: state.api.loading.getBooking,
    bookingNumber: _bookingNumberSelector(state)
  })

  const mapDispatchToProps = {
    onBookingError: goToAftersalesBookings,
    getBooking
  }

  return connect(mapStateToProps, mapDispatchToProps)
}

export default compose(
  connectState(),
  container
)
