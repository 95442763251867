import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import connectFormContainer from '../../base/form'
import { bookingSelector, isGroupBookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { passengersSelector, protectPrivacySelector } from 's3p-js-lib/src/redux/selectors/api/booking/passengers'
import { groupNameSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/passengers'
import { FORM_PASSENGER_DETAILS } from 's3p-js-lib/src/constants'
import PassengerDetails from '../../../components/base/passenger-details-booking/passenger-details'

const _protectPrivacySelector = protectPrivacySelector(bookingSelector)
const _passengersSelector = passengersSelector(bookingSelector)
const _isGroupBooking = isGroupBookingSelector(bookingSelector)
const _groupNameSelector = groupNameSelector(bookingSelector)

class PassengerDetailsContainer extends Component {
  static propTypes = {
    protectPrivacy: PropTypes.bool,
    isGroupBooking: PropTypes.bool,
    groupName: PropTypes.string,
    form: PropTypes.shape({
      loadFormValues: PropTypes.func
    })
  }

  UNSAFE_componentWillMount () {
    const values = {
      protectPrivacy: this.props.protectPrivacy
    }

    if (this.props.isGroupBooking && this.props.groupName) {
      values.groupName = this.props.groupName
    }

    this.props.form.loadFormValues(values)
  }

  render () {
    return <PassengerDetails {...this.props} />
  }
}

const mapStateToProps = state => ({
  protectPrivacy: _protectPrivacySelector(state),
  passengers: _passengersSelector(state),
  isGroupBooking: _isGroupBooking(state),
  groupName: _groupNameSelector(state)
})

export default connect(mapStateToProps)(
  connectFormContainer({formName: FORM_PASSENGER_DETAILS})(PassengerDetailsContainer)
)
