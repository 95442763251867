import {
  API_GET_CARRIAGE_LAYOUTS_ORIENTATION_REQUEST,
  API_GET_CARRIAGE_LAYOUTS_ORIENTATION_SUCCESS,
  API_GET_CARRIAGE_LAYOUTS_ORIENTATION_FAILURE,
  API_GET_CARRIAGE_LAYOUTS_AFTERSALES_REQUEST,
  API_GET_CARRIAGE_LAYOUTS_AFTERSALES_SUCCESS,
  API_GET_CARRIAGE_LAYOUTS_AFTERSALES_FAILURE,
  API_JOURNEY_SEARCH_REQUEST,
  USER_INPUT_SEAT_SELECTOR_DESTROY,
  CLEAR_STATE
} from '../../../../actions/types'

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_GET_CARRIAGE_LAYOUTS_ORIENTATION_REQUEST:
    case API_GET_CARRIAGE_LAYOUTS_ORIENTATION_FAILURE:
    case API_GET_CARRIAGE_LAYOUTS_AFTERSALES_REQUEST:
    case API_GET_CARRIAGE_LAYOUTS_AFTERSALES_FAILURE:
    case API_JOURNEY_SEARCH_REQUEST:
    case USER_INPUT_SEAT_SELECTOR_DESTROY:
      return initialState
    case API_GET_CARRIAGE_LAYOUTS_AFTERSALES_SUCCESS:
    case API_GET_CARRIAGE_LAYOUTS_ORIENTATION_SUCCESS:
      return action.response
    case CLEAR_STATE:
      return action.namespaces.includes('api.base.seatSelector.carriageLayouts') ? initialState : state
    default:
      return state
  }
}
