import { routerReducer as routing } from 'react-router-redux'
import baseReducers from 's3p-js-lib/src/redux/reducers'
import buildReducers from 's3p-js-lib/src/redux/lib/build-reducers'
import api from './api'
import containers from './containers'
import userInput from './user-input'
import machine from './machine'

export default buildReducers({
  ...baseReducers,
  api,
  containers,
  userInput,
  machine,
  routing
})
