import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import TestCfd from '../../components/maintenance/test-cfd'
import { testMachineDisplay, clearMachineDisplay } from '../../redux/actions/machine/display'

class TestCfdContainer extends Component {
  static propTypes = {
    testMachineDisplay: PropTypes.func.isRequired
  }

  UNSAFE_componentWillMount () {
    this.props.testMachineDisplay()
  }

  render () {
    return <TestCfd {...this.props} />
  }
}

const mapStateToProps = state => ({
  isLoadingTestDisplay: state.machine.loading.testMachineDisplay,
  isLoadingClearMachineDisplay: state.machine.loading.clearMachineDisplay,
  testStatus: state.machine.display.testStatus,
  clearStatus: state.machine.display.clearStatus
})

const mapDisplayToProps = {
  testMachineDisplay,
  clearMachineDisplay
}

export default connect(mapStateToProps, mapDisplayToProps)(TestCfdContainer)
