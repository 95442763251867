import PropTypes from 'prop-types'
import React, { Component } from 'react'
import FulfillmentMethodTile from '../../../components/base/tiles/fulfillment-method-tile'
import ComponentContainer from '../../../elements/component-container/component-container'
import Panel from '../../../elements/panel/panel'
import _t from 's3p-js-lib/src/translate'
import './read-ticket.scss'

export default class ReadTicket extends Component {
  static propTypes = {
    fulfillmentMethods: PropTypes.object,
    handleSelect: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
  }

  render () {
    return (
      <ComponentContainer name='read-ticket'>
        <Panel
          header={
            <Panel.Header
              name='read-ticket'
              title={_t.message('aftersales.read-ticket.title')}
              description={_t.message('aftersales.read-ticket.description')}
            />
          }
        >
          <div className='fulfillment-methods'>
            <div className='tile-set'>
              <FulfillmentMethodTile
                autoFocus
                object={this.props.fulfillmentMethods.magstripe}
                handleSelect={this.props.handleSelect}
                disabled={this.props.isLoading}
              />
              <FulfillmentMethodTile
                object={this.props.fulfillmentMethods.barcode}
                handleSelect={this.props.handleSelect}
                disabled={this.props.isLoading}
              />
            </div>
          </div>
        </Panel>
      </ComponentContainer>
    )
  }
}
