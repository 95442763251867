import { createSelector } from 'reselect'
import { rebookingSelectionSelector } from '../redux/selectors/user-input/aftersales/rebooking/selection'
import { TRAVEL_DIRECTION_OUTBOUND, TRAVEL_DIRECTION_INBOUND } from 's3p-js-lib/src/constants'
import journeySearchForm from 's3p-js-lib/src/forms/journey-search'

export default createSelector(
  [rebookingSelectionSelector, journeySearchForm],
  (travelDirection, journeySearchForm) => {
    const newForm = {
      ...journeySearchForm,
      fields: {
        ...journeySearchForm.fields,
        origin: {
          ...journeySearchForm.fields.origin,
          disabled: true
        },
        destination: {
          ...journeySearchForm.fields.destination,
          disabled: true
        },
        passengers: {
          ...journeySearchForm.fields.passengers,
          disabled: true
        }
      }
    }

    if (!travelDirection || travelDirection === TRAVEL_DIRECTION_INBOUND) {
      newForm.fields.outboundDate = {
        ...journeySearchForm.fields.outboundDate,
        disabled: true
      }
    }
    if (!travelDirection || travelDirection === TRAVEL_DIRECTION_OUTBOUND) {
      newForm.fields.inboundDate = {
        ...journeySearchForm.fields.inboundDate,
        disabled: true
      }
    }

    return newForm
  }
)
