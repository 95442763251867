import { createSelector } from 'reselect'
import { originalBookingSelector } from '../../../api/aftersales/booking'
import {
  canRebookProductsSelector,
  requiredInboundProductsSelector,
  requiredOutboundProductsSelector
} from '../../../api/booking/products'
import { journeySearchSelector } from '../../../api/orientation/journey-search'
import {
  TRAVEL_DIRECTION_OUTBOUND,
  TRAVEL_DIRECTION_INBOUND,
  TRAVEL_DIRECTION_BOTH
} from '../../../../../constants'

export const getSelectionFromJourneySearch = journeySearch => {
  if (!journeySearch) {
    return null
  }

  const departureDate = journeySearch.departure_date
  const returnDate = journeySearch.return_date

  return departureDate && !returnDate ? TRAVEL_DIRECTION_OUTBOUND
    : !departureDate && returnDate ? TRAVEL_DIRECTION_INBOUND
      : TRAVEL_DIRECTION_BOTH
}

export const rebookingDirectionSelector = createSelector(
  [
    canRebookProductsSelector(requiredOutboundProductsSelector(originalBookingSelector)),
    canRebookProductsSelector(requiredInboundProductsSelector(originalBookingSelector))
  ],
  (canRebookOutboundProducts, canRebookInboundProducts) => {
    return canRebookOutboundProducts && canRebookInboundProducts ? TRAVEL_DIRECTION_BOTH
      : canRebookOutboundProducts ? TRAVEL_DIRECTION_OUTBOUND
        : canRebookInboundProducts ? TRAVEL_DIRECTION_INBOUND : null
  }
)

export const rebookingSelectionSelector = createSelector(
  [
    state => state.userInput.aftersales.rebooking.selection,
    journeySearchSelector,
    rebookingDirectionSelector
  ],
  (currentSelection, journeySearch, rebookingDirection) =>
    currentSelection || getSelectionFromJourneySearch(journeySearch) || rebookingDirection
)

export const rebookingShowSelector = createSelector(
  [rebookingDirectionSelector],
  rebookingDirection => rebookingDirection === TRAVEL_DIRECTION_BOTH
)
