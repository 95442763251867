import React, { Component } from 'react'
import GenericLayout from '../generic-layout'
import MainMenu from '../../containers/base/main-menu/main-menu'
import ChangeFulfillment from '../../containers/aftersales/change-fulfillment/change-fulfillment'
import Operation from '../../containers/aftersales/operation/operation'
import ProgressNavigation from '../../containers/aftersales/change-fulfillment/progress-navigation'

export default class ChangeFulfillmentLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <MainMenu />
        <Operation
          labelTitle='aftersales.change-fulfillment.header.title'
          labelInformation='aftersales.change-fulfillment.header.information'
        />
        <ChangeFulfillment />
        <ProgressNavigation />
      </GenericLayout>
    )
  }
}
