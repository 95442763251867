import pick from 'lodash/pick'
import _t from 's3p-js-lib/src/translate'
import {
  FULFILLMENT_METHOD_CODE_MAGSTRIPE,
  FULFILLMENT_METHOD_CODE_LEAP,
  FULFILLMENT_METHOD_CODE_LEAP_TOP_UP,
  FULFILLMENT_METHOD_GIFT_VOUCHER,
  PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD,
  PAYMENT_METHOD_PD_CREDIT_DEBIT_CARD,
  FULFILLMENT_METHOD_CODE_TVM
} from '../constants'

export const getPropsForComponent = (props, Component) => pick(props, Object.keys(Component.propTypes))

export const displayFormatAmount = (label, amount, currency) => {
  const amountText = _t.formatCurrency(amount, currency)
  const totalLength = label.length + amountText.length

  if (totalLength > 20) {
    label = label.substring(0, 20 - amountText.length)
  }

  const repeatLength = totalLength > 20 ? 0 : 20 - totalLength

  return `${label}${' '.repeat(repeatLength)}${amountText}`
}

export const roundAmountToFiveCents = amount => Math.round(amount * 20) / 20

export const requireSeatSeparator = seat => isNaN(parseInt(seat.carriageNumber, 10)) === isNaN(parseInt(seat.seatNumber, 10))

const isFulfillmentCreator = code => fulfillmentMethod => fulfillmentMethod ? fulfillmentMethod.code === code : false

export const isMagstripeFulfillment = isFulfillmentCreator(FULFILLMENT_METHOD_CODE_MAGSTRIPE)
export const isTVMFulfillment = isFulfillmentCreator(FULFILLMENT_METHOD_CODE_TVM)
export const isMagstripeOrTVMFulfillment = fulfillmentMethod => isMagstripeFulfillment(fulfillmentMethod) || isTVMFulfillment(fulfillmentMethod)
export const isLeapFulfillment = isFulfillmentCreator(FULFILLMENT_METHOD_CODE_LEAP)
export const isLeapTopUpFulfillment = isFulfillmentCreator(FULFILLMENT_METHOD_CODE_LEAP_TOP_UP)
export const isGiftVoucherFulfillment = isFulfillmentCreator(FULFILLMENT_METHOD_GIFT_VOUCHER)
export const isAnyLeapFulfillment = fulfillmentMethod => isLeapFulfillment(fulfillmentMethod) || isLeapTopUpFulfillment(fulfillmentMethod)

export const isPedPaymentMethod = method => [PAYMENT_METHOD_BOM_CREDIT_DEBIT_CARD, PAYMENT_METHOD_PD_CREDIT_DEBIT_CARD].includes(method)

export const findMetaDataByKey = (metaData, key) => metaData.find(meta => meta.key === key)

export const validityWeekdaysToFullWeekdays = line => {
  if (!line) {
    return []
  }
  const splitLine = line.split(',')

  const validityWeekdays = []
  for (let i = 0; i < splitLine.length; i++) {
    switch (i) {
      case 0:
        if (splitLine[i]) { validityWeekdays.push('sunday') }
        break
      case 1:
        if (splitLine[i]) { validityWeekdays.push('monday') }
        break
      case 2:
        if (splitLine[i]) { validityWeekdays.push('tuesday') }
        break
      case 3:
        if (splitLine[i]) { validityWeekdays.push('wednesday') }
        break
      case 4:
        if (splitLine[i]) { validityWeekdays.push('thursday') }
        break
      case 5:
        if (splitLine[i]) { validityWeekdays.push('friday') }
        break
      case 6:
        if (splitLine[i]) { validityWeekdays.push('saturday') }
        break
    }
  }
  return validityWeekdays
}

export const parseToInt = value => /^\d+$/.test(value) ? parseInt(value) : null
