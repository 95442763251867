/* globals S3P_SETTINGS: true */

import { FETCH_ERROR_TYPE } from '../../misc/fetch-error'
import { MESSAGE_SEVERITY_ERROR } from 's3p-js-lib/src/constants'
import { HTTP_CODE_ORIGIN_UNREACHABLE } from '../../constants'
import {captureException} from '@s3p-js-deep-purple/sentry'

export default store => next => action => {
  if (
    action.machineError &&
    action.machineError instanceof Error &&
    action.machineError.errorType !== FETCH_ERROR_TYPE &&
    action.machineError.statusCode !== HTTP_CODE_ORIGIN_UNREACHABLE
  ) {
    window?.newrelic?.noticeError(action.machineError)
    const {machineError, ...extra} = action
    const isError = !machineError.response ||
      (machineError.response.messages || []).some(message => message.severity === MESSAGE_SEVERITY_ERROR)
    if (isError) {
      if (S3P_SETTINGS.s3Passenger.sentry.dsn) {
        const {sentryEventId, ...error} = machineError
        const fingerprint = []
        if (error.statusCode) {
          fingerprint.push(`statusCode_${error.statusCode}`)
        }

        extra.error = error
        extra.sentryEventId = sentryEventId
        captureException(machineError, {extra, fingerprint, level: 'error'})
      }

      if (S3P_SETTINGS.environment === 'development' && window.console) {
        console.error(action.type, action.machineError)
      }
    }
  }

  return next(action)
}
