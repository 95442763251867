import {
  selectedBundlesByJourneyIdSelector
} from 's3p-js-lib/src/redux/selectors/api/orientation/selected-bundles'
import {bundleByIdSelector, legsByRouteIdSelector} from 's3p-js-lib/src/redux/selectors/api/orientation/offer'
import {createSelector} from 'reselect'

const seatSelectionOptionStatusByBundleAndLegIdSelector = (bundleId, legId) => createSelector(
  bundleByIdSelector(bundleId),
  bundle =>
    bundle?.items.find(item => item.required && item.leg_ids.includes(legId))?.seat_selection_status
)

export const createV1SeatSelectionOptionsResponseSelector = journeyId => state => {
  const selectedBundlesIdentifiers = selectedBundlesByJourneyIdSelector(journeyId)(state)
  const seatSelectionOptions = selectedBundlesIdentifiers.reduce(
    (seatSelectionOptions, selectedBundleIdentifiers) => {
      legsByRouteIdSelector(selectedBundleIdentifiers.route_id)(state).forEach(
        leg => {
          seatSelectionOptions.push({
            leg,
            selected_seats: [],
            status: seatSelectionOptionStatusByBundleAndLegIdSelector(selectedBundleIdentifiers.id, leg.id)(state)
          })
        }
      )
      return seatSelectionOptions
    }, []
  )

  return {
    journey_id: journeyId,
    options: seatSelectionOptions
  }
}
