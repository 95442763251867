import React, { Component } from 'react'
import ReviewPaymentLayout from '../review-payment'

export default class ReviewPaymentUpdatePassengers extends Component {
  render () {
    return (
      <ReviewPaymentLayout
        labelTitle='aftersales.update-passengers.header.title'
        labelInformation='aftersales.update-passengers.header.information'
      />
    )
  }
}
