import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import TextLabel from '../../../elements/text-label/text-label'
import Icon from '../../../elements/icon/icon'
import Dropdown from '../../../elements/dropdown/dropdown'
import './taskbar.scss'

export const MESSAGE_MODAL = 'printer-status'

const iconMap = {
  BarcodeScannerDevice: 'fulfillment-method-homeprint',
  MagneticTicketPrinter: 'fulfillment-method-homeprint'
}

export default class TaskBar extends Component {
  static propTypes = {
    deviceMessages: PropTypes.array.isRequired,
    hasDeviceMessages: PropTypes.bool,
    failure: PropTypes.bool
  }

  render () {
    return this.props.hasDeviceMessages || this.props.failure ? (
      <div className='printer-status'>
        <Dropdown
          name={MESSAGE_MODAL}
          view={
            <Dropdown.View
              name={MESSAGE_MODAL}
              title={_t.message('taskbar.diagnostics.title')}
            >
              {this.props.deviceMessages.map(device =>
                device.messages.map((message, index) => (
                  <TextLabel name='taskbar-message' key={`${device.id}|${index}`}>
                    <Icon name={iconMap[device.id] || 'warning'} className='align-left' />
                    <span className='text'>{_t.message(
                      'taskbar.diagnostics.message',
                      {
                        name: device.name,
                        severity: message.severity,
                        message: message.translated ? message.message : _t.message(`machine.${message.message}`)
                      }
                    )}</span>
                  </TextLabel>
                )
                ))}
              {this.props.failure ? (
                <TextLabel name='taskbar-message'>
                  <Icon name='warning' className='align-left' />
                  <span className='text'>{_t.message('taskbar.diagnostics.failure')}</span>
                </TextLabel>
              ) : null}
            </Dropdown.View>
          }
        >
          <Icon name='warning' className='has-tooltip' />
        </Dropdown>
      </div>
    ) : null
  }
}
