import React, { Component } from 'react'
import GenericLayout from '../generic-layout'
import ChangeSeats from '../../containers/aftersales/change-seats/change-seats'
import MainMenu from '../../containers/base/main-menu/main-menu'
import ProgressNavigation from '../../containers/aftersales/change-seats/progress-navigation'
import GridLayout from '../../elements/grid/grid-layout'
import GridColumn from '../../elements/grid/grid-column'
import Operation from '../../containers/aftersales/operation/operation'
import WaiveFees from '../../containers/aftersales/change-seats/seat-selector/waive-fees'

export default class ChangeSeatsLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <MainMenu />
            <Operation
              labelTitle='aftersales.change-seats.header.title'
              labelInformation='aftersales.change-seats.header.information'
            />
            <WaiveFees />
            <ChangeSeats />
            <ProgressNavigation />
          </GridColumn>
        </GridLayout>
      </GenericLayout>
    )
  }
}
