import React, { Component } from 'react'
import PaymentModal from '../../../containers/base/payment-modal/payment-modal'
import CashFooter from '../../../containers/base/payment-modal/cash-footer'
import CashForm from '../../../containers/base/payment-modal/cash-form'

export default class CashModal extends Component {
  render () {
    return (
      <PaymentModal footer={<CashFooter />}>
        <CashForm />
      </PaymentModal>
    )
  }
}
