import { createSelector } from 'reselect'
import memoize from 'lodash/memoize'
import {
  bookingSelector,
  completedBookingSelector,
  overviewBookingSelector,
  totalPriceBookingSelector,
  additionalDetailsBookingSelector
} from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import {
  BOOKING_CANCELLED,
  BOOKING_CONFIRMED,
  BOOKING_PROVISIONAL,
  PAYMENT_STATUS_F
} from 's3p-js-lib/src/constants'
import { createSelectorWithoutMemoize } from '../../../../misc/selector'
import { paymentsWithoutFailedSelector } from './payments'
import { BOOKING_ON_HOLD_STATUS_ON_HOLD, BOOKING_EXPIRED } from '../../../../constants'
import moment from 'moment'

const bookingStatusSelector = memoize(bookingSelector => createSelector(
  [bookingSelector],
  booking => {
    if (booking) {
      if (booking.expiry_timestamp && moment(booking.expiry_timestamp).isBefore()) {
        return BOOKING_EXPIRED
      } else if (booking.expiry_timestamp) {
        return BOOKING_PROVISIONAL
      } else if (booking.cancelled_timestamp) {
        return BOOKING_CANCELLED
      } else if (booking.confirmed_timestamp) {
        return BOOKING_CONFIRMED
      }
    }
    return null
  }
))

const bookingOnHoldStatusSelector = bookingSelector => state => {
  const booking = bookingSelector(state)
  return booking && booking.on_hold ? booking.on_hold.status : null
}

export const isProvisionalSelector = bookingSelector => createSelectorWithoutMemoize(
  [bookingStatusSelector(bookingSelector)],
  status => status === BOOKING_PROVISIONAL
)

export const isConfirmedSelector = bookingSelector => createSelectorWithoutMemoize(
  [bookingStatusSelector(bookingSelector)],
  status => status === BOOKING_CONFIRMED
)

export const isCancelledSelector = bookingSelector => createSelectorWithoutMemoize(
  [bookingStatusSelector(bookingSelector)],
  status => status === BOOKING_CANCELLED
)

export const isExpiredSelector = bookingSelector => createSelectorWithoutMemoize(
  [bookingStatusSelector(bookingSelector)],
  status => status === BOOKING_EXPIRED
)

export const isOnHoldConfirmedSelector = bookingSelector => createSelectorWithoutMemoize(
  [
    isCancelledSelector(bookingSelector),
    bookingOnHoldStatusSelector(bookingSelector)
  ],
  (isCancelled, onHoldStatus) => !isCancelled && onHoldStatus === BOOKING_ON_HOLD_STATUS_ON_HOLD
)

export const isOnHoldExpiredSelector = bookingSelector => createSelectorWithoutMemoize(
  [
    isCancelledSelector(bookingSelector),
    bookingOnHoldStatusSelector(bookingSelector)
  ],
  (isCancelled, onHoldStatus) => isCancelled && onHoldStatus === BOOKING_ON_HOLD_STATUS_ON_HOLD
)

export const provisionalOrCompletedBookingSelector = createSelectorWithoutMemoize(
  [
    bookingSelector,
    completedBookingSelector
  ],
  (provisionalBooking, completedBooking) => provisionalBooking || completedBooking
)

export const provisionalOrOverviewBookingSelector = createSelectorWithoutMemoize(
  [
    bookingSelector,
    overviewBookingSelector
  ],
  (provisionalBooking, overviewBooking) => provisionalBooking || overviewBooking
)

export const completedOrProvisionalOrOverviewBookingSelector = createSelectorWithoutMemoize(
  [
    completedBookingSelector,
    bookingSelector,
    overviewBookingSelector
  ],
  (completedBooking, provisionalBooking, overviewBooking) => completedBooking || provisionalBooking || overviewBooking
)

export const completedOrProvisionalOrOriginalOrOverviewBookingSelector = createSelectorWithoutMemoize(
  [
    completedBookingSelector,
    bookingSelector,
    originalBookingSelector,
    overviewBookingSelector
  ],
  (completedBooking, provisionalBooking, originalBooking, overviewBooking) => completedBooking || provisionalBooking || originalBooking || overviewBooking
)

export const pendingTotalPriceToBePaidSelector = _bookingSelector => createSelector(
  [
    totalPriceBookingSelector(_bookingSelector),
    paymentsWithoutFailedSelector(_bookingSelector)
  ],
  (totalPrice, payments) => {
    const pendingPrice = payments.reduce(
      (_totalPrice, payment) => {
        if (payment.paymentStatus !== PAYMENT_STATUS_F) {
          const paymentAmount = payment.amount / (payment.currencyRate || 1)
          _totalPrice = _totalPrice - paymentAmount
        }

        return _totalPrice
      },
      totalPrice
    )
    return Math.round(pendingPrice * 100) / 100
  }
)

export const additionalOrCompletedOrOverviewBookingSelector = createSelectorWithoutMemoize(
  [
    completedBookingSelector,
    additionalDetailsBookingSelector,
    overviewBookingSelector
  ],
  (completedBookingSelector, additionalDetailsBookingSelector, overviewBookingSelector) => additionalDetailsBookingSelector || completedBookingSelector || overviewBookingSelector
)

export const completedOrOverviewBookingSelector = createSelectorWithoutMemoize(
  [
    completedBookingSelector,
    overviewBookingSelector
  ],
  (completedBookingSelector, overviewBookingSelector) => completedBookingSelector || overviewBookingSelector
)

export const originalOrOverviewBookingSelector = createSelectorWithoutMemoize(
  [
    originalBookingSelector,
    overviewBookingSelector
  ],
  (originalBooking, overviewBooking) => originalBooking || overviewBooking
)

export const provisionalOrOriginalOrOverviewBookingSelector = createSelectorWithoutMemoize(
  [
    bookingSelector,
    originalBookingSelector,
    overviewBookingSelector
  ],
  (bookingSelector, originalBookingSelector, overviewBookingSelector) => bookingSelector || originalBookingSelector || overviewBookingSelector
)

export const barcodeDocumentSelector = state => state.api.booking.barcodeDocument || null

export const giftVoucherDocumentSelector = state => state.api.booking.giftVoucherDocument || null

export const refundVoucherDocumentSelector = state => state.api.booking.refundVoucherDocument || null
