import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import _t from 's3p-js-lib/src/translate'
import containRebookingFlow from '../../rebooking-flow'
import SeatSelection from '../../../base/seat-selection/seat-selection'

const mapDispatchToProps = {
  handleManualSeatSelection: firstAvailableLegId =>
    routerActions.push(`/${_t.getLocales()}/aftersales/rebooking/seat-selector/${firstAvailableLegId}`)
}

export default containRebookingFlow(connect(null, mapDispatchToProps)(SeatSelection))
