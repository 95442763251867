import loadingReducer from '../../../lib/loading-reducer'
import {
  API_V2_GET_PRODUCTS_REQUEST,
  API_V2_GET_PRODUCTS_SUCCESS,
  API_V2_GET_PRODUCTS_FAILURE,
  API_V2_GET_PRODUCT_FAMILIES_REQUEST,
  API_V2_GET_PRODUCT_FAMILIES_SUCCESS,
  API_V2_GET_PRODUCT_FAMILIES_FAILURE,
  API_V2_GET_CURRENCY_RATES_REQUEST,
  API_V2_GET_CURRENCY_RATES_SUCCESS,
  API_V2_GET_CURRENCY_RATES_FAILURE,
  API_V2_CREATE_BOOKING_REQUEST,
  API_V2_CREATE_BOOKING_SUCCESS,
  API_V2_CREATE_BOOKING_FAILURE,
  API_V2_UPDATE_FULFILLMENT_METHOD_REQUEST,
  API_V2_UPDATE_FULFILLMENT_METHOD_SUCCESS,
  API_V2_UPDATE_FULFILLMENT_METHOD_FAILURE,
  API_V2_UPDATE_SEGMENTS_REQUEST,
  API_V2_UPDATE_SEGMENTS_SUCCESS,
  API_V2_UPDATE_SEGMENTS_FAILURE,
  API_V2_CONFIRM_BOOKING_REQUEST,
  API_V2_CONFIRM_BOOKING_SUCCESS,
  API_V2_CONFIRM_BOOKING_FAILURE,
  API_V2_ADD_ADDITIONAL_DETAILS_REQUEST,
  API_V2_ADD_ADDITIONAL_DETAILS_SUCCESS,
  API_V2_ADD_ADDITIONAL_DETAILS_FAILURE,
  API_V2_UPDATE_ADDITIONAL_DETAILS_REQUEST,
  API_V2_UPDATE_ADDITIONAL_DETAILS_SUCCESS,
  API_V2_UPDATE_ADDITIONAL_DETAILS_FAILURE,
  API_V2_ADD_PAYMENTS_REQUEST,
  API_V2_ADD_PAYMENTS_SUCCESS,
  API_V2_ADD_PAYMENTS_FAILURE,
  API_V2_UPDATE_PAYMENTS_REQUEST,
  API_V2_UPDATE_PAYMENTS_SUCCESS,
  API_V2_UPDATE_PAYMENTS_FAILURE,
  API_V2_REMOVE_ITEMS_REQUEST,
  API_V2_REMOVE_ITEMS_SUCCESS,
  API_V2_REMOVE_ITEMS_FAILURE,
  API_V2_GET_AGENT_SHIFT_REPORT_REQUEST,
  API_V2_GET_AGENT_SHIFT_REPORT_SUCCESS,
  API_V2_GET_AGENT_SHIFT_REPORT_FAILURE,
  API_V2_ADD_CUSTOMER_REQUEST,
  API_V2_ADD_CUSTOMER_SUCCESS,
  API_V2_ADD_CUSTOMER_FAILURE,
  API_V2_UPDATE_CUSTOMER_REQUEST,
  API_V2_UPDATE_CUSTOMER_SUCCESS,
  API_V2_UPDATE_CUSTOMER_FAILURE,
  API_V2_GET_TICKET_DETAILS_REQUEST,
  API_V2_GET_TICKET_DETAILS_SUCCESS,
  API_V2_GET_TICKET_DETAILS_FAILURE,
  API_V2_UPDATE_PASSENGERS_REQUEST,
  API_V2_UPDATE_PASSENGERS_SUCCESS,
  API_V2_UPDATE_PASSENGERS_FAILURE
} from '../../../actions/types-v2'

export default {
  getProducts: loadingReducer(
    API_V2_GET_PRODUCTS_REQUEST,
    API_V2_GET_PRODUCTS_SUCCESS,
    API_V2_GET_PRODUCTS_FAILURE
  ),
  getProductFamilies: loadingReducer(
    API_V2_GET_PRODUCT_FAMILIES_REQUEST,
    API_V2_GET_PRODUCT_FAMILIES_SUCCESS,
    API_V2_GET_PRODUCT_FAMILIES_FAILURE
  ),
  createBooking: loadingReducer(
    API_V2_CREATE_BOOKING_REQUEST,
    API_V2_CREATE_BOOKING_SUCCESS,
    API_V2_CREATE_BOOKING_FAILURE
  ),
  confirmBooking: loadingReducer(
    API_V2_CONFIRM_BOOKING_REQUEST,
    API_V2_CONFIRM_BOOKING_SUCCESS,
    API_V2_CONFIRM_BOOKING_FAILURE
  ),
  updateFulfillmentMethod: loadingReducer(
    API_V2_UPDATE_FULFILLMENT_METHOD_REQUEST,
    API_V2_UPDATE_FULFILLMENT_METHOD_SUCCESS,
    API_V2_UPDATE_FULFILLMENT_METHOD_FAILURE
  ),
  addPayments: loadingReducer(
    API_V2_ADD_PAYMENTS_REQUEST,
    API_V2_ADD_PAYMENTS_SUCCESS,
    API_V2_ADD_PAYMENTS_FAILURE
  ),
  updatePayments: loadingReducer(
    API_V2_UPDATE_PAYMENTS_REQUEST,
    API_V2_UPDATE_PAYMENTS_SUCCESS,
    API_V2_UPDATE_PAYMENTS_FAILURE
  ),
  currencyRates: loadingReducer(
    API_V2_GET_CURRENCY_RATES_REQUEST,
    API_V2_GET_CURRENCY_RATES_SUCCESS,
    API_V2_GET_CURRENCY_RATES_FAILURE
  ),
  removeItems: loadingReducer(
    API_V2_REMOVE_ITEMS_REQUEST,
    API_V2_REMOVE_ITEMS_SUCCESS,
    API_V2_REMOVE_ITEMS_FAILURE
  ),
  updateSegments: loadingReducer(
    API_V2_UPDATE_SEGMENTS_REQUEST,
    API_V2_UPDATE_SEGMENTS_SUCCESS,
    API_V2_UPDATE_SEGMENTS_FAILURE
  ),
  addAdditionalDetails: loadingReducer(
    API_V2_ADD_ADDITIONAL_DETAILS_REQUEST,
    API_V2_ADD_ADDITIONAL_DETAILS_SUCCESS,
    API_V2_ADD_ADDITIONAL_DETAILS_FAILURE
  ),
  updateAdditionalDetails: loadingReducer(
    API_V2_UPDATE_ADDITIONAL_DETAILS_REQUEST,
    API_V2_UPDATE_ADDITIONAL_DETAILS_SUCCESS,
    API_V2_UPDATE_ADDITIONAL_DETAILS_FAILURE
  ),
  getShiftReport: loadingReducer(
    API_V2_GET_AGENT_SHIFT_REPORT_REQUEST,
    API_V2_GET_AGENT_SHIFT_REPORT_SUCCESS,
    API_V2_GET_AGENT_SHIFT_REPORT_FAILURE
  ),
  addCustomer: loadingReducer(
    API_V2_ADD_CUSTOMER_REQUEST,
    API_V2_ADD_CUSTOMER_SUCCESS,
    API_V2_ADD_CUSTOMER_FAILURE
  ),
  updateCustomer: loadingReducer(
    API_V2_UPDATE_CUSTOMER_REQUEST,
    API_V2_UPDATE_CUSTOMER_SUCCESS,
    API_V2_UPDATE_CUSTOMER_FAILURE
  ),
  getTicketDetails: loadingReducer(
    API_V2_GET_TICKET_DETAILS_REQUEST,
    API_V2_GET_TICKET_DETAILS_SUCCESS,
    API_V2_GET_TICKET_DETAILS_FAILURE
  ),
  updatePassengers: loadingReducer(
    API_V2_UPDATE_PASSENGERS_REQUEST,
    API_V2_UPDATE_PASSENGERS_SUCCESS,
    API_V2_UPDATE_PASSENGERS_FAILURE
  )
}
