import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { routeSelector } from '../../../../redux/selectors/containers/base/journey-search/journey-result-set'
import { getJourneyDetails } from '../../../../redux/actions/api/orientation/journey-details'
import { showJourneyDetails } from '../../../../redux/actions/containers/base/journey-search/journey-details'
import {
  allServiceStationsSelectorCreator,
  himMessagesSelector
} from '../../../../redux/selectors/containers/base/journey-search/journey-details'
import JourneyDetails from '../../../../components/base/journey-search/journey-details/journey-details'
import { journeyDetailsServicesSelector } from 's3p-js-lib/src/redux/selectors/api/orientation/offer/journey-details'

const getAllServiceIdentifiers = route => route.legs.map(leg => leg.serviceIdentifier)

class JourneyDetailsContainer extends Component {
  static propTypes = {
    route: PropTypes.shape({
      legs: PropTypes.arrayOf(PropTypes.shape({
        serviceIdentifier: PropTypes.string.isRequired
      })).isRequired
    }),
    stations: PropTypes.array.isRequired,
    alreadyLoadedServices: PropTypes.arrayOf(PropTypes.string).isRequired,
    getJourneyDetails: PropTypes.func.isRequired
  }

  UNSAFE_componentWillMount () {
    if (this.props.route) {
      const notLoadedServices = this.props.route.legs
        .filter(leg => !this.props.alreadyLoadedServices.includes(leg.serviceIdentifier))
      if (notLoadedServices.length) {
        this.props.getJourneyDetails(notLoadedServices)
      }
    }
  }

  render () {
    return <JourneyDetails {...this.props} legs={this.props.route.legs} />
  }
}

const mapStateToProps = (state, ownProps) => {
  const _routeSelector = routeSelector(ownProps.routeId)
  const _allServiceStationsSelector = allServiceStationsSelectorCreator(ownProps.routeId)
  const _himMessagesSelector = himMessagesSelector(_routeSelector)
  const route = _routeSelector(state)
  const services = getAllServiceIdentifiers(route)

  return state => ({
    route,
    alreadyLoadedServices: journeyDetailsServicesSelector(state),
    himMessages: _himMessagesSelector(state),
    isLoadingJourneyDetails: state.api.loading.getJourneyDetails.some(service => services.includes(service)),
    stations: _allServiceStationsSelector(state)
  })
}

const mapDispatchToProps = {
  showJourneyDetails,
  getJourneyDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(JourneyDetailsContainer)
