import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import Icon from '../../../elements/icon/icon'
import Button from '../../../elements/button/button'
import TextLabel from '../../../elements/text-label/text-label'
import ComponentContainer from '../../../elements/component-container/component-container'
import PassengerSummary from '../../../containers/aftersales/operation/passengers-summary'
import './operation.scss'

export default class Operation extends Component {
  static propTypes = {
    labelTitle: PropTypes.string.isRequired,
    labelInformation: PropTypes.string.isRequired,
    bookingNumber: PropTypes.string,
    onClickAbort: PropTypes.func.isRequired,
    showPassengersSummary: PropTypes.bool,
    loading: PropTypes.bool,
    disabled: PropTypes.bool
  }

  render () {
    return this.props.bookingNumber
      ? (
        <ComponentContainer name='after-sales-operation'>
          <div className='operation'>
            <div className='operation__description'>
              <TextLabel name='sub-heading' text={_t.message(this.props.labelTitle)} />
              <TextLabel name='information'>
                <Icon name='information' className='medium align-left' />
                <TextLabel.Text text={_t.message(this.props.labelInformation)} />
              </TextLabel>
              <Button
                name='cancel-operation'
                className='tertiary small'
                loading={this.props.loading}
                onClick={this.props.onClickAbort}
                disabled={this.props.disabled}
                tabIndex='-1'
              >
                <Icon name='cross' className='xsmall align-left' />
                <TextLabel text={_t.message('aftersales.operation.abort-button')} />
              </Button>
            </div>
            <div className='operation__booking-number'>
              <TextLabel name='sub-heading' text={_t.message('aftersales.operation.booking-number')} />
              <TextLabel name='booking-number'>
                <Icon name='document' className='align-left' />
                <TextLabel.Text text={this.props.bookingNumber} />
              </TextLabel>
            </div>
            {this.props.showPassengersSummary ? <PassengerSummary /> : null}
          </div>
          {this.props.children}
        </ComponentContainer>
      ) : null
  }
}
