import PropTypes from 'prop-types'
import React, { Component } from 'react'
import SideBar from '../../../elements/sidebar/sidebar'
import TextLabel from '../../../elements/text-label/text-label'
import Icon from '../../../elements/icon/icon'
import Button from '../../../elements/button/button'
import _t from 's3p-js-lib/src/translate'
import EditModal from '../../../containers/tickets/sidebar/shortcuts/edit-modal'
import Shortcut from './shortcut'
import {DOM_IGNORE_CLASS} from '../../../elements/utils/closable'

const HOTKEYS = [
  'chooseNr1',
  'chooseNr2',
  'chooseNr3',
  'chooseNr4',
  'chooseNr5',
  'chooseNr6',
  'chooseNr7',
  'chooseNr8',
  'chooseNr9'
]

export default class SidebarShortcuts extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    handleShowCart: PropTypes.func.isRequired,
    hasBooking: PropTypes.bool,
    enableEditMode: PropTypes.func.isRequired,
    disableEditMode: PropTypes.func.isRequired,
    editModeEnabled: PropTypes.bool,
    selectedSequenceNumber: PropTypes.number,
    shortcuts: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      isEmpty: PropTypes.bool
    })).isRequired,
    useShortcut: PropTypes.func.isRequired,
    editShortcut: PropTypes.func.isRequired
  }

  constructor (...args) {
    super(...args)

    this.useShortcut = this.useShortcut.bind(this)
    this.handleEnableEditMode = this.handleEnableEditMode.bind(this)
  }

  useShortcut (shortcut) {
    if (this.props.hasBooking) {
      return
    }
    if (!this.props.editModeEnabled) {
      if (!shortcut.isEmpty) {
        this.props.useShortcut(shortcut)
      }
    } else {
      this.props.editShortcut(shortcut.sequenceNumber)
    }
  }

  handleEnableEditMode () {
    const shortcut = this.props.shortcuts.find(shortcut => shortcut.isEmpty)
    this.props.enableEditMode(shortcut ? shortcut.sequenceNumber : 1)
  }

  render () {
    return (
      <SideBar
        className={this.props.editModeEnabled ? `state--is-edit ${DOM_IGNORE_CLASS}` : ''}
        name='shortcut'
        title={_t.message('shortcuts.title')}
        description={_t.message('shortcuts.description')}
        header={this._renderHeaderWithButtons()}
      >
        <div className='shortcut-navigation'>
          <ul>
            {this.props.shortcuts.map((shortcut, index) => (
              <li key={shortcut.id}>
                <Shortcut
                  {...shortcut}
                  hotkey={HOTKEYS[index]}
                  disabled={this.props.disabled || this.props.hasBooking}
                  selected={this.props.editModeEnabled && shortcut.sequenceNumber === this.props.selectedSequenceNumber}
                  useShortcut={this.useShortcut}
                />
              </li>
            ))}
          </ul>
        </div>
        {this.props.editModeEnabled ? <EditModal onHideElement={this.props.disableEditMode} /> : null}
      </SideBar>
    )
  }

  _renderHeaderWithButtons () {
    return (
      <SideBar.Header>
        {this.props.hasBooking
          ? (
            <Button
              type='button'
              name='show-cart'
              tabIndex='-1'
              onClick={this.props.handleShowCart}
            >
              <TextLabel name='show-cart'>
                <Icon name='shopping-cart' />
                <TextLabel.Text name='show-cart' text={_t.message('cart.title')} />
              </TextLabel>
            </Button>
          ) : null}
        <Button type='button' name='edit-shortcut' tabIndex='-1' onClick={this.handleEnableEditMode}>
          <TextLabel name='edit-shortcut'>
            <Icon name='edit' />
            <TextLabel.Text name='edit-shortcut' text={_t.message('shortcuts.edit-button')} />
          </TextLabel>
        </Button>
      </SideBar.Header>
    )
  }
}
