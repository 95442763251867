import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import TextLabel from '../../../../elements/text-label/text-label'
import Icon from '../../../../elements/icon/icon'
import '../../../../elements/feedback/feedback.scss'

export default class HimMessage extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    className: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object
    ])
  }

  render () {
    const {title, content, className, ...other} = this.props
    return (
      <div {...other} className={classNames('him-message warning', className)} role='alert' aria-live='assertive'>
        <Icon name='warning' className='medium align-left' />
        <TextLabel name='him-title' text={title} />
        {content ? <TextLabel name='him-content' text={content} /> : null}
      </div>
    )
  }
}
