import React, { Component } from 'react'
import PropTypes from '../../../misc/prop-types'
import _t from 's3p-js-lib/src/translate'
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_WARNING } from 's3p-js-lib/src/constants'
import ComponentContainer from '../../../elements/component-container/component-container'
import Panel from '../../../elements/panel/panel'
import Icon from '../../../elements/icon/icon'
import Button from '../../../elements/button/button'
import TextLabel from '../../../elements/text-label/text-label'
import Feedback, {
  FEEDBACK_SUCCESS,
  FEEDBACK_ALERT,
  FEEDBACK_INFORMATION,
  FEEDBACK_WARNING
} from '../../../elements/feedback/feedback'
import {
  LAST_MACHINE_ERROR_NAMESPACE_LEAP_DEVICE,
  LEAP_ERROR_MESSAGE_AMOUNT_EXCEEDED,
  LEAP_ERROR_MESSAGE_AMOUNT_TOO_LOW,
  LEAP_ERROR_MESSAGE_CARD_EXPIRED_SINCE,
  LEAP_ERROR_MESSAGE_CARD_IS_BLOCKED,
  LEAP_ERROR_MESSAGE_LEAP_CARD_NOT_READ,
  LEAP_ERROR_MESSAGE_LEAP_CONFIGURATION_DATA_CORRUPT,
  LEAP_ERROR_MESSAGE_NO_CONFIGURATION,
  LEAP_ERROR_MESSAGE_PURSE_IS_BLOCKED,
  LEAP_REVERSE_ERROR_MESSAGE_BOOKING_HAS_NO_INFORMATION,
  LEAP_REVERSE_ERROR_MESSAGE_CANCEL_TIME_EXCEEDED,
  LEAP_REVERSE_ERROR_MESSAGE_REVERSE_FAILED,
  LEAP_REVERSE_ERROR_MESSAGE_WRONG_CARD_PRESENTED,
  LEAP_REVERSE_ERROR_MESSAGE_WRONG_LAST_EVENT_VALUE,
  LEAP_REVERSE_ERROR_MESSAGE_WRONG_LAST_TRANSACTION_CODE
} from '../../../constants'
import FeedbackMachine from '../../../containers/base/last-machine-error-feedback'
import './void-fulfillment.scss'

const LEAP_ERROR_MESSAGE_MAP = {
  [LEAP_ERROR_MESSAGE_CARD_EXPIRED_SINCE]: 'leap.data.card-expired-since',
  [LEAP_ERROR_MESSAGE_CARD_IS_BLOCKED]: 'leap.data.leap-card-is-blocked',
  [LEAP_ERROR_MESSAGE_PURSE_IS_BLOCKED]: 'leap.data.leap-purse-is-blocked',
  [LEAP_ERROR_MESSAGE_LEAP_CARD_NOT_READ]: 'leap.data.leap-card-not-read',
  [LEAP_ERROR_MESSAGE_LEAP_CONFIGURATION_DATA_CORRUPT]: 'leap.data.leap-configuration-data-corrupt',
  [LEAP_ERROR_MESSAGE_NO_CONFIGURATION]: 'leap.data.no-leap-configuration',
  [LEAP_ERROR_MESSAGE_AMOUNT_TOO_LOW]: 'finalize-booking-modal.leap.error.amount-too-low',
  [LEAP_ERROR_MESSAGE_AMOUNT_EXCEEDED]: 'finalize-booking-modal.leap.error.amount-exceeded',
  [LEAP_REVERSE_ERROR_MESSAGE_CANCEL_TIME_EXCEEDED]: 'aftersales.cancellation.reverse-leap.error.cancel-time-exceeded',
  [LEAP_REVERSE_ERROR_MESSAGE_WRONG_LAST_TRANSACTION_CODE]: 'aftersales.cancellation.reverse-leap.error.wrong-last-transaction-code',
  [LEAP_REVERSE_ERROR_MESSAGE_WRONG_CARD_PRESENTED]: 'aftersales.cancellation.reverse-leap.error.wrong-card-presented',
  [LEAP_REVERSE_ERROR_MESSAGE_WRONG_LAST_EVENT_VALUE]: 'aftersales.cancellation.reverse-leap.error.wrong-last-event-value',
  [LEAP_REVERSE_ERROR_MESSAGE_BOOKING_HAS_NO_INFORMATION]: 'aftersales.cancellation.reverse-leap.error.booking-has-no-information',
  [LEAP_REVERSE_ERROR_MESSAGE_REVERSE_FAILED]: 'aftersales.cancellation.reverse-leap.error.reverse-failed'
}

export default class ReverseLeap extends Component {
  static propTypes = {
    isRunning: PropTypes.bool.isRequired,
    success: PropTypes.bool,
    isLoadingRead: PropTypes.bool,
    isLoadingReverse: PropTypes.bool,
    processReverseLeap: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    canOverrideValidation: PropTypes.bool,
    expiryDate: PropTypes.moment,
    isleapAddProduct: PropTypes.bool
  }

  render () {
    return (
      this.props.isleapAddProduct
        ? (
          <Feedback
            status={FEEDBACK_WARNING}
            text={_t.message('aftersales.cancellation.reverse-leap.reverse-add-product-warning')}
          />
        ) : (
          <ComponentContainer name='cancellation-void-ticket'>
            <Panel
              header={
                <Panel.Header
                  name='cancellation-void-ticket'
                  title={_t.message('aftersales.cancellation.reverse-leap.title')}
                  description={_t.message('aftersales.cancellation.reverse-leap.description')}
                />
              }
            >
              <TextLabel name='information'>
                <Icon name='information' className='align-left' />
                <TextLabel.Text text={_t.message('aftersales.cancellation.reverse-leap.information')} />
              </TextLabel>
              {this.props.isRunning || this.props.errorMessage || this.props.success
                ? (
                  <div className='read-status'>
                    <TextLabel name='sub-heading' text={_t.message('aftersales.cancellation.reverse-leap.present')} />
                    {this.props.isLoadingRead
                      ? <TextLabel name='tickets' className='animation-blink'>
                        <Icon name='tickets' className='align-left' />
                        <TextLabel.Text text={_t.message('aftersales.cancellation.reverse-leap.read')} />
                      </TextLabel> : null
                    }
                    {this.props.isLoadingReverse
                      ? <TextLabel name='tickets'>
                        <Icon name='tickets' className='align-left' />
                        <TextLabel.Text text={_t.message('aftersales.cancellation.reverse-leap.reverse-executing')} />
                      </TextLabel> : null
                    }
                    <FeedbackMachine
                      namespaces={[LAST_MACHINE_ERROR_NAMESPACE_LEAP_DEVICE]}
                      severities={[MESSAGE_SEVERITY_WARNING, MESSAGE_SEVERITY_ERROR]}
                    />
                    {this.props.errorMessage
                      ? <Feedback status={FEEDBACK_ALERT} text={
                        _t.message(LEAP_ERROR_MESSAGE_MAP[this.props.errorMessage], {date: this.props.expiryDate ? this.props.expiryDate.format('L LT') : ''})
                      } />
                      : null}
                    {this.props.success
                      ? <Feedback
                        status={FEEDBACK_SUCCESS}
                        text={_t.message('aftersales.cancellation.reverse-leap.success')}
                      />
                      : null}
                  </div>
                ) : null}
              {!this.props.isRunning && !this.props.success
                ? (
                  <Button
                    name='start-reverse-leaps'
                    className='primary'
                    onClick={this.props.processReverseLeap}
                  >
                    <TextLabel
                      text={_t.message(
                        `aftersales.cancellation.reverse-leap.${this.props.errorMessage ? 'retry' : 'start'}`
                      )}
                    />
                  </Button>
                ) : null}
              {this.props.canOverrideValidation
                ? <Feedback
                  status={FEEDBACK_INFORMATION}
                  text={_t.message('aftersales.cancellation.reverse-leap.can-override-cancellation')}
                />
                : null}
            </Panel>
          </ComponentContainer>
        )
    )
  }
}
