import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Notes from '../../../components/aftersales/booking/notes'
import { bookingNotesSelector } from 's3p-js-lib/src/redux/selectors/api/booking/notes'
import { overviewBookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { showModal, hideElement } from '../../../redux/actions/containers/base/visible-element'
import { elementVisibleSelector } from '../../../redux/selectors/containers/base/visible-element'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import { FORM_BOOKING_NOTE } from 's3p-js-lib/src/constants-form'
import { CONTAINERS_AFTERSALES_BOOKING_NOTES } from '../../../constants'

const _bookingNotesSelector = bookingNotesSelector(overviewBookingSelector)
const _modalIsVisible = elementVisibleSelector(FORM_BOOKING_NOTE)

class NotesContainer extends Component {
  static propTypes = {
    clearState: PropTypes.func.isRequired
  }

  componentWillUnmount () {
    this.props.clearState(CONTAINERS_AFTERSALES_BOOKING_NOTES)
  }

  render () {
    return (
      <Notes {...this.props} />
    )
  }
}

const mapStateToProps = state => ({
  notes: _bookingNotesSelector(state),
  showModal: _modalIsVisible(state),
  noteSaved: state.containers.aftersales.booking.notes.saved
})

const mapDispatchToProps = {
  handleShowModal: () => showModal(FORM_BOOKING_NOTE),
  handleHideModal: () => hideElement(FORM_BOOKING_NOTE),
  clearState
}

export default connect(mapStateToProps, mapDispatchToProps)(NotesContainer)
