import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CONTAINERS_AFTERSALES_BOOKING_BOOKING } from '../../../constants'
import Booking from '../../../components/aftersales/booking/booking'
import { getBooking } from '../../../redux/actions/containers/aftersales/booking/booking'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'
import BookingError from '../../../components/aftersales/booking/booking-error'

class BookingContainer extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    getBooking: PropTypes.func.isRequired,
    clearState: PropTypes.func,
    bookingError: PropTypes.object
  }

  UNSAFE_componentWillMount () {
    this.props.getBooking()
  }

  componentWillUnmount () {
    this.props.clearState()
  }

  render () {
    return this.props.bookingError ? <BookingError /> : <Booking {...this.props} />
  }
}

const mapStateToProps = state => ({
  loading: state.containers.aftersales.booking.booking.loading,
  bookingError: state.api.base.lastApiErrors.getBooking
})

const mapDispatchToProps = (_, ownProps) => dispatch => ({
  getBooking: () => dispatch(getBooking(ownProps.bookingNumber)),
  clearState: () => dispatch(clearState([CONTAINERS_AFTERSALES_BOOKING_BOOKING]))
})

export default connect(mapStateToProps, mapDispatchToProps)(BookingContainer)
