import { createSelector } from 'reselect'
import moment from 'moment'
import { toUtcDateMoment } from 's3p-js-lib/src/misc/date'
import { TRAVEL_DIRECTION_OUTBOUND } from 's3p-js-lib/src/constants'
import { enrichedJourneySearchSelector } from 's3p-js-lib/src/redux/selectors/api/orientation/journey-search'
import { calendarsByDirectionSelector } from './calendar'

export const bestPricesSelector = direction => createSelector(
  [
    calendarsByDirectionSelector(direction),
    enrichedJourneySearchSelector
  ],
  (bestPrices, journeySearch) => {
    if (!bestPrices) {
      return {}
    }

    const today = toUtcDateMoment(moment())
    const isOutbound = direction === TRAVEL_DIRECTION_OUTBOUND
    const journeySearchDate = isOutbound ? journeySearch.departureDate.clone() : journeySearch.returnDate.clone()

    const resultBestPrices = {}
    for (let i = -2; i < 3; i++) {
      const date = toUtcDateMoment(journeySearchDate).add(i, 'days')
      const dateString = date.format('YYYY-MM-DD')
      const bestPriceByDate = bestPrices[dateString] || {}

      resultBestPrices[i] = {
        date,
        offset: i,
        price: bestPriceByDate.amount,
        available: date.diff(today, 'days') >= 0
      }
    }
    return resultBestPrices
  }
)
