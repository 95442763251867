import { createSelector } from 'reselect'
import orderBy from 'lodash/orderBy'
import {
  foundCustomersSelector as baseFoundCustomersSelector
} from 's3p-js-lib/src/redux/selectors/api/user/agent/found-customers'
import { tableSortSelector } from '../../../base/table'
import { SORT_FIND_CUSTOMERS } from '../../../../../../constants'

export const foundCustomersSelector = createSelector(
  [
    baseFoundCustomersSelector,
    tableSortSelector(SORT_FIND_CUSTOMERS.TABLE_NAME)
  ],
  (customers, sort) => {
    const iterate = sort.column === SORT_FIND_CUSTOMERS.FULL_NAME
      ? customer => `${customer.firstName} ${customer.lastName}`.trim()
      : sort.column

    return orderBy(customers, [iterate], [sort.order])
  }
)
