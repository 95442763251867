import { connect } from 'react-redux'
import { actionNotPossibleWithinTimeSelector } from '../../../../redux/selectors/containers/aftersales/void-tickets'
import VoidTickets from '../../void-tickets/void-tickets'

const _voidingNotPossibleWithinTimeSelector = actionNotPossibleWithinTimeSelector('SEATCHANGE')

const mapStateToProps = state => ({
  voidingNotPossibleWithinTime: _voidingNotPossibleWithinTimeSelector(state)
})

export default connect(mapStateToProps)(VoidTickets)
