export const initialState = false

export default (requestActionType, successActionType, failureActionType) => (state = initialState, action) => {
  switch (action.type) {
    case requestActionType:
      return true
    case successActionType:
    case failureActionType:
      return initialState
    default:
      return state
  }
}
