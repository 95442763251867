import PropTypes from 'prop-types'
import React, { Component } from 'react'
import containVisibleElement from '../../containers/base/visible-element'
import DropdownView from './dropdown-view'
import Tether from '../utils/tether'
import './dropdown.scss'

const DESKTOP_TOGGLE_BEHAVIOR_CLICK = 'click'
const DESKTOP_TOGGLE_BEHAVIOR_HOVER = 'hover'

class Dropdown extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    view: PropTypes.element.isRequired,
    onHideElement: PropTypes.func.isRequired,
    onShowElement: PropTypes.func.isRequired,
    elementVisibility: PropTypes.object.isRequired,
    desktopToggleBehavior: PropTypes.oneOf([DESKTOP_TOGGLE_BEHAVIOR_CLICK, DESKTOP_TOGGLE_BEHAVIOR_HOVER]),
    timeout: PropTypes.number
  }

  static defaultProps = {
    desktopToggleBehavior: DESKTOP_TOGGLE_BEHAVIOR_CLICK,
    timeout: 50
  }

  constructor (...args) {
    super(...args)

    this._setTarget = this._setTarget.bind(this)
    this._setTether = this._setTether.bind(this)
    this._domWatcher = this._domWatcher.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleHideElement = this.handleHideElement.bind(this)
  }

  handleClick () {
    if (!this._isVisible()) {
      this.props.onShowElement(this.props.name)
    }
  }

  handleHideElement () {
    if (this._isVisible()) {
      this.props.onHideElement(this.props.name)
    }
  }

  _isVisible () {
    return this.props.elementVisibility[this.props.name] === true
  }

  _setTarget (target) {
    if (target && this._tether && !this._target) {
      this._tether.init(target)
    }
    this._target = target
  }

  _setTether (tether) {
    if (tether && !this._tether && this._target) {
      tether.init(this._target)
    }
    this._tether = tether
  }

  _domWatcher () {
    return this._container && this._container.offsetWidth * 10000 + this._container.offsetHeight
  }

  render () {
    return (
      <react-wrapper class='react-wrapper' ref={this._setTarget}>
        <div className='dropdown-wrapper' {...this._tooltipEvents()}>{this.props.children}</div>
        {this._isVisible()
          ? (
            <Tether ref={this._setTether} DOMWatcher={this._domWatcher}>
              {this._renderView()}
            </Tether>
          ) : null
        }
      </react-wrapper>
    )
  }

  _renderView () {
    return (
      <div
        name='dropdown-container'
        className='dropdown-container'
        ref={container => { this._container = container }}
      >
        {React.cloneElement(this.props.view, {
          name: this.props.name,
          onHideElement: this.handleHideElement
        })}
      </div>
    )
  }

  _tooltipEvents () {
    return {
      onClick: this.handleClick
    }
  }
}

const DropdownWrapper = containVisibleElement()(Dropdown)
DropdownWrapper.View = DropdownView

export default DropdownWrapper
