import PropTypes from 'prop-types'
import React, { Component } from 'react'
import connectFormContainer from '../../base/form'
import PassengerFieldSet from '../../../components/base/passenger-details-booking/passenger-field-set'

class PassengerFieldSetContainer extends Component {
  static propTypes = {
    form: PropTypes.shape({
      loadFormValues: PropTypes.func.isRequired
    }).isRequired,
    passenger: PropTypes.object.isRequired
  }

  UNSAFE_componentWillMount () {
    this.props.form.loadFormValues({
      firstName: '',
      lastName: '',
      emailAddress: '',
      ...this.props.passenger
    })
  }

  render () {
    return <PassengerFieldSet {...this.props} />
  }
}

export default connectFormContainer()(PassengerFieldSetContainer)
