import { createSelector } from 'reselect'
import baseJourneySearchForm from 's3p-js-lib/src/forms/journey-search'

export default createSelector(
  [baseJourneySearchForm],
  journeySearchForm => ({
    ...journeySearchForm,
    fields: {
      ...journeySearchForm.fields,
      inboundDate: {
        ...journeySearchForm.fields.inboundDate,
        disabled: false
      }
    }
  })
)
