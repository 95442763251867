import {
  API_GET_BOOKING_SUCCESS,
  API_GET_BOOKING_FAILURE,
  CLEAR_STATE
} from 's3p-js-lib/src/redux/actions/types'
import { CONTAINERS_AFTERSALES_BOOKING_BOOKING } from '../../../../../constants'
import { UI_BOOKING_OVERVIEW_INITIATE } from '../../../../actions/types'

const initialState = {loading: false}

export default function (state = initialState, action) {
  switch (action.type) {
    case UI_BOOKING_OVERVIEW_INITIATE:
      return {loading: true}
    case API_GET_BOOKING_SUCCESS:
    case API_GET_BOOKING_FAILURE:
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_AFTERSALES_BOOKING_BOOKING) ? initialState : state
    default:
      return state
  }
}
