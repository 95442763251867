import {
  API_OAUTH_LOGOUT,
  API_ADD_VOUCHER_SUCCESS,
  API_REMOVE_VOUCHER_SUCCESS,
  API_CREATE_PROVISIONAL_BOOKING_SUCCESS,
  API_CREATE_PROVISIONAL_BOOKING_FAILURE,
  API_JOURNEY_SEARCH_REQUEST,
  API_REBOOKING_JOURNEY_SEARCH_REQUEST,
  API_PROCESS_DEEP_LINK_SUCCESS,
  API_UPDATE_BOOKING_SUCCESS,
  API_CONTINUE_PROVISIONAL_BOOKING_SUCCESS,
  API_CONTINUE_PROVISIONAL_BOOKING_FAILURE,
  API_AFTERSALES_CONFIRM_SUCCESS,
  API_AFTERSALES_CONFIRM_FAILURE,
  API_AFTERSALES_REVERT_SUCCESS,
  API_AFTERSALES_REVERT_FAILURE,
  API_CHANGE_SEATS_INITIATE_REQUEST,
  API_CHANGE_SEATS_SELECT_SEATS_SUCCESS,
  API_CHANGE_SEATS_SELECT_SEATS_FAILURE,
  API_REBOOKING_INITIATE_REQUEST,
  API_REBOOKING_CREATE_BOOKING_REQUEST,
  API_REBOOKING_CREATE_BOOKING_SUCCESS,
  API_REBOOKING_CREATE_BOOKING_FAILURE,
  API_CANCELLATION_INITIATE_REQUEST,
  API_CANCELLATION_CANCEL_SUCCESS,
  API_CANCELLATION_CANCEL_FAILURE,
  API_ADDITIONAL_PRODUCTS_INITIATE_REQUEST,
  API_ADDITIONAL_PRODUCTS_UPDATE_SUCCESS,
  API_ADDITIONAL_PRODUCTS_UPDATE_FAILURE,
  API_UPDATE_PASSENGERS_UPDATE_SUCCESS,
  API_UPDATE_PASSENGERS_UPDATE_REQUEST,
  API_UPDATE_PASSENGERS_UPDATE_FAILURE,
  API_UPDATE_SELECT_BUNDLE_SUCCESS,
  API_END_AGENT_SHIFT_SUCCESS,
  API_START_AGENT_SHIFT_SUCCESS,
  API_AGENT_LOGIN_SUCCESS,
  CLEAR_STATE
} from '../../../actions/types'
import {
  API_V2_CREATE_BOOKING_SUCCESS,
  API_V2_CREATE_BOOKING_FAILURE,
  API_V2_REMOVE_ITEMS_SUCCESS,
  API_V2_CONFIRM_BOOKING_SUCCESS,
  API_V2_ADD_PAYMENTS_SUCCESS,
  API_V2_UPDATE_FULFILLMENT_METHOD_SUCCESS,
  API_V2_UPDATE_PAYMENTS_SUCCESS,
  API_V2_UPDATE_SEGMENTS_SUCCESS,
  API_V2_ADD_CUSTOMER_SUCCESS,
  API_V2_UPDATE_CUSTOMER_SUCCESS
} from '../../../actions/types-v2'

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_REBOOKING_CREATE_BOOKING_SUCCESS:
    case API_UPDATE_PASSENGERS_UPDATE_SUCCESS:
    case API_CREATE_PROVISIONAL_BOOKING_SUCCESS:
    case API_UPDATE_BOOKING_SUCCESS:
    case API_CONTINUE_PROVISIONAL_BOOKING_SUCCESS:
    case API_CHANGE_SEATS_SELECT_SEATS_SUCCESS:
    case API_CANCELLATION_CANCEL_SUCCESS:
      return action.response.data.booking
    case API_ADD_VOUCHER_SUCCESS:
    case API_REMOVE_VOUCHER_SUCCESS:
      return action.response.data
    case API_ADDITIONAL_PRODUCTS_UPDATE_SUCCESS:
      return action.response.data.new_booking
    case API_PROCESS_DEEP_LINK_SUCCESS:
      return action.response.data.session.booking || initialState
    case API_CREATE_PROVISIONAL_BOOKING_FAILURE:
    case API_JOURNEY_SEARCH_REQUEST:
    case API_REBOOKING_JOURNEY_SEARCH_REQUEST:
    case API_CONTINUE_PROVISIONAL_BOOKING_FAILURE:
    case API_AFTERSALES_CONFIRM_SUCCESS:
    case API_AFTERSALES_CONFIRM_FAILURE:
    case API_AFTERSALES_REVERT_SUCCESS:
    case API_AFTERSALES_REVERT_FAILURE:
    case API_CHANGE_SEATS_INITIATE_REQUEST:
    case API_REBOOKING_INITIATE_REQUEST:
    case API_REBOOKING_CREATE_BOOKING_REQUEST:
    case API_REBOOKING_CREATE_BOOKING_FAILURE:
    case API_UPDATE_PASSENGERS_UPDATE_REQUEST:
    case API_UPDATE_PASSENGERS_UPDATE_FAILURE:
    case API_CANCELLATION_INITIATE_REQUEST:
    case API_CHANGE_SEATS_SELECT_SEATS_FAILURE:
    case API_CANCELLATION_CANCEL_FAILURE:
    case API_OAUTH_LOGOUT:
    case API_ADDITIONAL_PRODUCTS_INITIATE_REQUEST:
    case API_ADDITIONAL_PRODUCTS_UPDATE_FAILURE:
    case API_V2_CREATE_BOOKING_FAILURE:
    case API_V2_CONFIRM_BOOKING_SUCCESS:
    case API_END_AGENT_SHIFT_SUCCESS:
    case API_START_AGENT_SHIFT_SUCCESS:
    case API_AGENT_LOGIN_SUCCESS:
      return initialState
    case API_V2_UPDATE_FULFILLMENT_METHOD_SUCCESS:
    case API_V2_CREATE_BOOKING_SUCCESS:
    case API_V2_UPDATE_SEGMENTS_SUCCESS:
    case API_V2_ADD_PAYMENTS_SUCCESS:
    case API_V2_UPDATE_CUSTOMER_SUCCESS:
    case API_V2_ADD_CUSTOMER_SUCCESS:
    case API_V2_UPDATE_PAYMENTS_SUCCESS:
      return action.response.data.booking || initialState
    case API_UPDATE_SELECT_BUNDLE_SUCCESS:
      return action.response.data.booking || state
    case API_V2_REMOVE_ITEMS_SUCCESS:
      return action.response.data.booking.outbound_booking_tariff_segments.length
        ? action.response.data.booking
        : initialState
    case CLEAR_STATE:
      return action.namespaces.includes('api.booking.provisionalBooking') ? initialState : state
    default:
      return state
  }
}
