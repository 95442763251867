import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import connectFormContainer from '../../base/form'
import { bookingSelector, originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import { passengersSelector, protectPrivacySelector } from 's3p-js-lib/src/redux/selectors/api/booking/passengers'
import { groupNameSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/passengers'
import { FORM_PASSENGER_DETAILS_AFTERSALES } from 's3p-js-lib/src/constants'
import PassengerDetails from '../../../components/base/passenger-details-booking/passenger-details'

class UpdatePassengersContainer extends Component {
  static propTypes = {
    hasOriginalBooking: PropTypes.bool.isRequired,
    isGroupBooking: PropTypes.bool.isRequired,
    protectPrivacy: PropTypes.bool.isRequired,
    groupName: PropTypes.string,
    form: PropTypes.shape({
      loadFormValues: PropTypes.func.isRequired
    })
  }

  UNSAFE_componentWillMount () {
    const values = {
      protectPrivacy: this.props.protectPrivacy
    }

    if (this.props.isGroupBooking) {
      values.groupName = this.props.groupName
    }

    this.props.form.loadFormValues(values)
  }

  render () {
    return this.props.hasOriginalBooking ? <PassengerDetails {...this.props} /> : null
  }
}

const _passengersSelector = passengersSelector(bookingSelector)
const _groupNameSelector = groupNameSelector(bookingSelector)
const _protectPrivacySelector = protectPrivacySelector(bookingSelector)

const mapStateToProps = state => {
  const groupName = _groupNameSelector(state)
  const isGroupBooking = Boolean(groupName)
  return {
    isGroupBooking,
    groupName,
    protectPrivacy: _protectPrivacySelector(state),
    requestPassengers: !isGroupBooking,
    passengers: _passengersSelector(state),
    hasOriginalBooking: Boolean(originalBookingSelector(state))
  }
}

export default connect(mapStateToProps)(
  connectFormContainer({formName: FORM_PASSENGER_DETAILS_AFTERSALES})(
    UpdatePassengersContainer
  )
)
