import request from './request'
import { STATUS_ERROR } from './device-manager'
import { createError } from './response-handler'
import PromiseQueue from './promise-queue'

const pq = new PromiseQueue()

export const translateResponse = response => {
  const messages = []

  if ((response.status || response.Status) === STATUS_ERROR) {
    messages.push(createError('customer-display.not-responding'))
  }

  return messages
}

export default {
  requestQueue: [],
  handlingRequests: false,

  async displayLines (lines) {
    return pq.add(() => request({
      translateResponse,
      method: 'post',
      endpoint: '/vtm/devices/rest/CustomerDisplay/display',
      data: {
        Line1: lines[0] && typeof lines[0] === 'string' ? lines[0].substr(0, 20) : '',
        Line2: lines[1] && typeof lines[1] === 'string' ? lines[1].substr(0, 20) : '',
        Scroll: false
      }
    }))
  },

  async clearDisplay () {
    return pq.add(() => request({
      translateResponse,
      method: 'post',
      endpoint: '/vtm/devices/rest/CustomerDisplay/clear'
    }))
  },

  async testDisplay () {
    return pq.add(() => request({
      translateResponse,
      method: 'post',
      endpoint: '/vtm/devices/rest/CustomerDisplay/test'
    }))
  }
}
