import {
  UI_SELECT_READ_TICKET,
  UI_READ_BARCODE_REQUEST,
  UI_READ_BARCODE_SUCCESS,
  UI_READ_BARCODE_FAILURE
} from '../../../actions/types'
import { CLEAR_STATE } from 's3p-js-lib/src/redux/actions/types'
import { CONTAINERS_AFTERSALES_READTICKET } from '../../../../constants'

const initialState = {
  selected: null,
  loadingReadBarcode: false,
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UI_SELECT_READ_TICKET:
      return {...state, selected: action.fulfillmentMethod.code, error: false}
    case UI_READ_BARCODE_REQUEST:
      return {...state, loadingReadBarcode: true, error: false}
    case UI_READ_BARCODE_SUCCESS:
      return {...state, loadingReadBarcode: false, error: false}
    case UI_READ_BARCODE_FAILURE:
      return {...state, loadingReadBarcode: false, error: true}
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_AFTERSALES_READTICKET) ? initialState : state
    default:
      return state
  }
}
