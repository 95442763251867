import { connect } from 'react-redux'
import {
  bookingCancellationDataSelector,
  outboundCancellationDataSelector,
  inboundCancellationDataSelector,
  passengersCancellationDataSelector,
  isCancellationVoidPageSelector
} from '../../../redux/selectors/containers/aftersales/cancellation'
import {
  selectItemIds,
  deselectItemIds
} from 's3p-js-lib/src/redux/actions/user-input/aftersales/cancellation'
import { bookingSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import CancelBy from '../../../components/aftersales/cancellation/cancel-by'
import { hasAccessToAftersalesPartialCancellationSelector } from 's3p-js-lib/src/redux/selectors/api/auth/auth'

const partialCancellationAllowedSelector = hasAccessToAftersalesPartialCancellationSelector(originalBookingSelector)

const mapStateToProps = state => ({
  bookingCancellationData: bookingCancellationDataSelector(state),
  outboundCancellationData: outboundCancellationDataSelector(state),
  inboundCancellationData: inboundCancellationDataSelector(state),
  passengersCancellationData: passengersCancellationDataSelector(state),
  disabled: Boolean(isCancellationVoidPageSelector(state) || bookingSelector(state)),
  allowedPartialCancellation: partialCancellationAllowedSelector(state)
})

const mapDispatchToProps = {
  selectItemIds,
  deselectItemIds
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelBy)
