import {
  API_OAUTH_LOGOUT,
  API_AGENT_LOGIN_SUCCESS,
  API_END_AGENT_SHIFT_SUCCESS,
  API_START_AGENT_SHIFT_SUCCESS,
  CLEAR_STATE
} from '../../../actions/types'
import {
  API_V2_GET_PRODUCTS_REQUEST,
  API_V2_GET_PRODUCTS_SUCCESS,
  API_V2_GET_PRODUCTS_FAILURE
} from '../../../actions/types-v2'

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_V2_GET_PRODUCTS_REQUEST:
    case API_V2_GET_PRODUCTS_FAILURE:
    case API_END_AGENT_SHIFT_SUCCESS:
    case API_START_AGENT_SHIFT_SUCCESS:
    case API_AGENT_LOGIN_SUCCESS:
    case API_OAUTH_LOGOUT:
      return initialState
    case API_V2_GET_PRODUCTS_SUCCESS:
      return action.response.data?.products
    case CLEAR_STATE:
      return action.namespaces.includes('api.v2.search') ? initialState : state
    default:
      return state
  }
}
