import {
  API_GET_VOUCHERS_REQUEST,
  API_GET_VOUCHERS_SUCCESS,
  API_GET_VOUCHERS_FAILURE
} from '../../types'
import Client from '../../../../api/client'
import ensureToken from '../../../../api/ensure-token'

const getVouchersRequest = code => ({type: API_GET_VOUCHERS_REQUEST, code})
const getVouchersSuccess = response => ({type: API_GET_VOUCHERS_SUCCESS, response})
const getVouchersFailure = error => ({type: API_GET_VOUCHERS_FAILURE, error})

export const getVouchers = code => ensureToken(
  async (token, dispatch) => {
    try {
      dispatch(getVouchersRequest(code))
      const response = await Client.getVouchers(token, code)
      dispatch(getVouchersSuccess(response))
    } catch (error) {
      dispatch(getVouchersFailure(error))
      return false
    }
  }
)
