import { clearLastApiErrors } from 's3p-js-lib/src/redux/actions/api/base/last-api-errors'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'

import {
  CONTAINERS_BASE_TILES_PAYMENT_METHODS,
  CONTAINERS_BASE_PAYMENT_MODAL_CASH_FORM,
  CONTAINERS_BASE_PAYMENT_MODAL_CREDIT_DEBIT,
  CONTAINERS_BASE_FINALIZE_BOOKING_PRINT_TICKETS_MODAL,
  USERINPUT_AFTERSALES_CANCELLATION,
  CONTAINERS_RESERVATIONS_PASSENGER_PREFERENCES
} from '../../../../constants'

export const resetReservations = () => dispatch => {
  dispatch([
    clearState([
      'api.orientation.additionalProducts',
      'api.orientation.cheapestPrice',
      'api.orientation.journeyDetails',
      'api.orientation.journeySearch',
      'api.orientation.offer',
      'api.orientation.seatSelectionOptions',
      'api.orientation.selectedBundles',
      'api.orientation.specification',
      'api.booking.additionalDetailsBooking',
      'api.booking.aftersalesOriginalBooking',
      'api.booking.completedBooking',
      'api.booking.provisionalBooking',
      'api.modules.magstripe',
      'userInput.aftersales.waiveFees',
      'userInput.base.seatSelector',
      CONTAINERS_RESERVATIONS_PASSENGER_PREFERENCES,
      CONTAINERS_BASE_TILES_PAYMENT_METHODS,
      CONTAINERS_BASE_PAYMENT_MODAL_CASH_FORM,
      CONTAINERS_BASE_PAYMENT_MODAL_CREDIT_DEBIT,
      CONTAINERS_BASE_FINALIZE_BOOKING_PRINT_TICKETS_MODAL,
      USERINPUT_AFTERSALES_CANCELLATION
    ]),
    clearLastApiErrors(['addPayments', 'updatePayments'])
  ])
}
