import React, { Component } from 'react'
import PropTypes from '../../../../misc/prop-types'
import OriginDestinationBlock from '../../../../elements/origin-destination-block/origin-destination-block'
import SegmentPassengers from './segment-passengers'
import SegmentProductSet from './segment-product-set'

export default class Segment extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    showCancelledItems: PropTypes.bool.isRequired,
    tariffSegment: PropTypes.shape({
      departureDate: PropTypes.moment,
      arrivalDate: PropTypes.moment,
      originName: PropTypes.string,
      destinationName: PropTypes.string,
      serviceName: PropTypes.string
    }),
    enrichedPassengers: PropTypes.array,
    products: PropTypes.array.isRequired,
    feeSummary: PropTypes.object

  }

  render () {
    return (this.props.tariffSegment
      ? <OriginDestinationBlock
        originName={this.props.tariffSegment.originName}
        destinationName={this.props.tariffSegment.destinationName}
        departureMoment={this.props.tariffSegment.departureDate}
        arrivalMoment={this.props.tariffSegment.arrivalDate}
        serviceName={this.props.tariffSegment.serviceName}
      >
        <SegmentPassengers
          tableName={`booking-overview.segment.${this.props.id}`}
          passengers={this.props.enrichedPassengers}
          segmentId={this.props.id}
          feeSummary={this.props.feeSummary}
        />
        <SegmentProductSet
          segmentId={this.props.id}
          products={this.props.products}
        />
      </OriginDestinationBlock>
      : null
    )
  }
}
