import PropTypes from 'prop-types'
import React, { Component } from 'react'
import InputField from '../input/input'
import _t from 's3p-js-lib/src/translate'

export default class CurrencyInput extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number
  }

  constructor (props, ...args) {
    super(props, ...args)
    this.onChange = this.onChange.bind(this)
  }

  onChange (event) {
    const stripped = (event.target.value || '').replace(/[^0-9]/g, '').replace(/\b0+/g, '')
    const amount = (parseInt(stripped) || 0) / 100
    this.props.onChange(amount)
  }

  render () {
    const {value, ...props} = this.props
    delete props.onChange
    delete props.onBlur

    return (
      <InputField.Text
        {...props}
        onChange={this.onChange}
        onBlur={this.onChange}
        value={_t.formatNumber(value || 0.0, 'decimal')}
      />
    )
  }
}
