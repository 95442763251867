import {
  UI_CLEAR_LAST_API_ERROR
} from '../../../../actions/types'
import {
  API_V2_GET_PRODUCTS_REQUEST,
  API_V2_GET_PRODUCTS_SUCCESS,
  API_V2_GET_PRODUCTS_FAILURE,
  API_V2_GET_PRODUCT_FAMILIES_REQUEST,
  API_V2_GET_PRODUCT_FAMILIES_SUCCESS,
  API_V2_GET_PRODUCT_FAMILIES_FAILURE,
  API_V2_GET_CURRENCY_RATES_REQUEST,
  API_V2_GET_CURRENCY_RATES_SUCCESS,
  API_V2_GET_CURRENCY_RATES_FAILURE,
  API_V2_CREATE_BOOKING_REQUEST,
  API_V2_CREATE_BOOKING_SUCCESS,
  API_V2_CREATE_BOOKING_FAILURE,
  API_V2_UPDATE_FULFILLMENT_METHOD_REQUEST,
  API_V2_UPDATE_FULFILLMENT_METHOD_SUCCESS,
  API_V2_UPDATE_FULFILLMENT_METHOD_FAILURE,
  API_V2_UPDATE_SEGMENTS_REQUEST,
  API_V2_UPDATE_SEGMENTS_SUCCESS,
  API_V2_UPDATE_SEGMENTS_FAILURE,
  API_V2_CONFIRM_BOOKING_REQUEST,
  API_V2_CONFIRM_BOOKING_SUCCESS,
  API_V2_CONFIRM_BOOKING_FAILURE,
  API_V2_ADD_ADDITIONAL_DETAILS_REQUEST,
  API_V2_ADD_ADDITIONAL_DETAILS_SUCCESS,
  API_V2_ADD_ADDITIONAL_DETAILS_FAILURE,
  API_V2_UPDATE_ADDITIONAL_DETAILS_REQUEST,
  API_V2_UPDATE_ADDITIONAL_DETAILS_SUCCESS,
  API_V2_UPDATE_ADDITIONAL_DETAILS_FAILURE,
  API_V2_ADD_PAYMENTS_REQUEST,
  API_V2_ADD_PAYMENTS_SUCCESS,
  API_V2_ADD_PAYMENTS_FAILURE,
  API_V2_UPDATE_PAYMENTS_REQUEST,
  API_V2_UPDATE_PAYMENTS_SUCCESS,
  API_V2_UPDATE_PAYMENTS_FAILURE,
  API_V2_REMOVE_ITEMS_REQUEST,
  API_V2_REMOVE_ITEMS_SUCCESS,
  API_V2_REMOVE_ITEMS_FAILURE,
  API_V2_GET_AGENT_SHIFT_REPORT_REQUEST,
  API_V2_GET_AGENT_SHIFT_REPORT_SUCCESS,
  API_V2_GET_AGENT_SHIFT_REPORT_FAILURE,
  API_V2_ADD_CUSTOMER_REQUEST,
  API_V2_ADD_CUSTOMER_SUCCESS,
  API_V2_ADD_CUSTOMER_FAILURE,
  API_V2_UPDATE_CUSTOMER_REQUEST,
  API_V2_UPDATE_CUSTOMER_SUCCESS,
  API_V2_UPDATE_CUSTOMER_FAILURE,
  API_V2_GET_TICKET_DETAILS_REQUEST,
  API_V2_GET_TICKET_DETAILS_SUCCESS,
  API_V2_GET_TICKET_DETAILS_FAILURE
} from '../../../../actions/types-v2'
import { createNewState, clearErrors } from './last-api-errors'

export const initialState = {}

export default function lastApiError (state = initialState, action) {
  switch (action.type) {
    case UI_CLEAR_LAST_API_ERROR:
      return clearErrors(state, action.namespaces)
    case API_V2_GET_CURRENCY_RATES_REQUEST:
    case API_V2_GET_CURRENCY_RATES_SUCCESS:
    case API_V2_GET_CURRENCY_RATES_FAILURE:
      return createNewState('getCurrencyRates', state, action)
    case API_V2_GET_PRODUCTS_REQUEST:
    case API_V2_GET_PRODUCTS_SUCCESS:
    case API_V2_GET_PRODUCTS_FAILURE:
      return createNewState('getProducts', state, action)
    case API_V2_GET_PRODUCT_FAMILIES_REQUEST:
    case API_V2_GET_PRODUCT_FAMILIES_SUCCESS:
    case API_V2_GET_PRODUCT_FAMILIES_FAILURE:
      return createNewState('getProductFamilies', state, action)
    case API_V2_CREATE_BOOKING_REQUEST:
    case API_V2_CREATE_BOOKING_SUCCESS:
    case API_V2_CREATE_BOOKING_FAILURE:
      return createNewState('createBooking', state, action)
    case API_V2_UPDATE_SEGMENTS_REQUEST:
    case API_V2_UPDATE_SEGMENTS_SUCCESS:
    case API_V2_UPDATE_SEGMENTS_FAILURE:
      return createNewState('updateSegments', state, action)
    case API_V2_UPDATE_FULFILLMENT_METHOD_REQUEST:
    case API_V2_UPDATE_FULFILLMENT_METHOD_SUCCESS:
    case API_V2_UPDATE_FULFILLMENT_METHOD_FAILURE:
      return createNewState('updateFulfillmentMethod', state, action)
    case API_V2_REMOVE_ITEMS_REQUEST:
    case API_V2_REMOVE_ITEMS_SUCCESS:
    case API_V2_REMOVE_ITEMS_FAILURE:
      return createNewState('removeItems', state, action)
    case API_V2_CONFIRM_BOOKING_REQUEST:
    case API_V2_CONFIRM_BOOKING_SUCCESS:
    case API_V2_CONFIRM_BOOKING_FAILURE:
      return createNewState('confirmBooking', state, action)
    case API_V2_UPDATE_PAYMENTS_FAILURE:
    case API_V2_UPDATE_PAYMENTS_REQUEST:
    case API_V2_UPDATE_PAYMENTS_SUCCESS:
      return createNewState('updatePayments', state, action)
    case API_V2_ADD_PAYMENTS_FAILURE:
    case API_V2_ADD_PAYMENTS_REQUEST:
    case API_V2_ADD_PAYMENTS_SUCCESS:
      return createNewState('addPayments', state, action)
    case API_V2_ADD_ADDITIONAL_DETAILS_REQUEST:
    case API_V2_ADD_ADDITIONAL_DETAILS_SUCCESS:
    case API_V2_ADD_ADDITIONAL_DETAILS_FAILURE:
      return createNewState('addAdditionalDetails', state, action)
    case API_V2_UPDATE_ADDITIONAL_DETAILS_REQUEST:
    case API_V2_UPDATE_ADDITIONAL_DETAILS_SUCCESS:
    case API_V2_UPDATE_ADDITIONAL_DETAILS_FAILURE:
      return createNewState('updateAdditionalDetails', state, action)
    case API_V2_GET_AGENT_SHIFT_REPORT_REQUEST:
    case API_V2_GET_AGENT_SHIFT_REPORT_SUCCESS:
    case API_V2_GET_AGENT_SHIFT_REPORT_FAILURE:
      return createNewState('getShiftReport', state, action)
    case API_V2_ADD_CUSTOMER_REQUEST:
    case API_V2_ADD_CUSTOMER_SUCCESS:
    case API_V2_ADD_CUSTOMER_FAILURE:
      return createNewState('addCustomer', state, action)
    case API_V2_UPDATE_CUSTOMER_REQUEST:
    case API_V2_UPDATE_CUSTOMER_SUCCESS:
    case API_V2_UPDATE_CUSTOMER_FAILURE:
      return createNewState('updateCustomer', state, action)
    case API_V2_GET_TICKET_DETAILS_REQUEST:
    case API_V2_GET_TICKET_DETAILS_SUCCESS:
    case API_V2_GET_TICKET_DETAILS_FAILURE:
      return createNewState('getTicketDetails', state, action)
    default:
      return state
  }
}
