import ReactDOM from 'react-dom'
import pick from 'lodash/pick'
import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'
import qs from 'qs'
import ResponseCodes from 's3p-js-lib/src/api/response-codes'
import {
  TRAVEL_DIRECTION_OUTBOUND,
  TRAVEL_DIRECTION_INBOUND
} from '../constants'
import { captureMessage } from '@s3p-js-deep-purple/sentry'
import { bookingNumberSelector } from 's3p-js-lib/src/redux/selectors/api/booking/booking'
import { completedOrProvisionalOrOriginalOrOverviewBookingSelector } from '../../../src/redux/selectors/api/booking/booking'

export const camelCaseKeys = obj => Object.keys(obj).reduce((data, value) => {
  data[camelCase(value)] = obj[value]
  return data
}, {})

export const snakeCaseKeys = obj => Object.keys(obj).reduce((data, value) => {
  data[snakeCase(value)] = obj[value]
  return data
}, {})

export const serviceIdentifierCreator = service => {
  if (service.serviceIdentifier) {
    return service.serviceIdentifier
  } else {
    const name = service.serviceName || service.name
    const date = service.serviceScheduleDate || service.scheduleDate
    return `${name}|${date}`
  }
}

export const getUniqueBookingTariffSegmentIdentifier = tariffSegment => [
  tariffSegment.travel_date,
  tariffSegment.validity_service,
  tariffSegment.departure_station && tariffSegment.departure_station._u_i_c_station_code,
  tariffSegment.arrival_station && tariffSegment.arrival_station._u_i_c_station_code
].join('|')

export const getPropsForReactWidget = (props, Component, extraProps = []) => pick(props, [
  ...Object.keys(Component.ControlledComponent.propTypes),
  ...Object.keys(Component.propTypes),
  ...extraProps,
  'onClick',
  'onFocus',
  'onBlur'
])

export const mapDirectionToTravelDirection = direction =>
  direction === TRAVEL_DIRECTION_OUTBOUND ? 'outward' : 'return'

export const mapTravelDirectionToDirection = travelDirection =>
  travelDirection === 'outward' ? TRAVEL_DIRECTION_OUTBOUND : TRAVEL_DIRECTION_INBOUND

export const getUniqueLegIdentifier = leg => [
  leg.serviceScheduleDate,
  leg.serviceName,
  leg.departureStation.UICStationCode,
  leg.arrivalStation.UICStationCode
].join('|')

export const stringifyQuery = query => qs.stringify(query, {encode: false, skipNulls: true})

export const stringifyQueryEncode = query => qs.stringify(query, {encode: true, skipNulls: true})

export const indexBy = (items, propKey) => (items || []).reduce(
  (object, item) => {
    const key = item[propKey]
    object[key] = camelCaseKeys(item)
    return object
  },
  {}
)

export const findDOMNode = element => {
  // eslint-disable-next-line react/no-find-dom-node
  return ReactDOM.findDOMNode(element)
}

export const getCalendarTravelId = direction => `travel_${direction}`

export const timerRequest = (url, startDate) => {
  const date = new Date()
  const diff = Math.floor((date - startDate) / 1000)
  const errorMessage = `request to endpoint ${url} takes more than ${diff} secs.`
  // eslint-disable-next-line no-empty
  try { captureMessage(errorMessage) } finally {}

  return diff
}

const MASKED_FIELDS = [
  'card_identifier',
  'cardIdentifier',
  'masked_pan',
  'auth_code',
  'expiry_date'
]

const getBookingNumber = () => {
  const state = window.reduxStore && window.reduxStore.getState()
  return state ? bookingNumberSelector(completedOrProvisionalOrOriginalOrOverviewBookingSelector)(state) : 'unknown'
}

export const logNadManResponse = (endpoint, startDate, originalResponse) => {
  const date = new Date()
  const response = {...originalResponse}
  if (response.results) {
    const results = {...response.results}
    MASKED_FIELDS.forEach(key => {
      if (results[key]) {
        results[key] = 'xxxxx'
      }
    })
    response.results = results
  }

  try {
    captureMessage(
      endpoint,
      {
        level: ResponseCodes.severity.info,
        extra: {
          action: 'response',
          bookingNumber: getBookingNumber(),
          endpoint,
          start: startDate,
          end: date,
          duration: `${Math.floor((date - startDate))}ms`,
          response
        }
      }
    )
  } finally {
    // ignore errors
  }
}

export const logNadManRequest = (endpoint, startDate, data) => {
  try {
    captureMessage(
      endpoint,
      {
        level: ResponseCodes.severity.info,
        extra: {
          action: 'request',
          bookingNumber: getBookingNumber(),
          endpoint,
          start: startDate,
          data: data
        }
      }
    )
  } finally {
    // ignore errors
  }
}
