import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import SearchBookingForm from '../../../components/aftersales/bookings/bookings-search-form'
import { getBookings } from 's3p-js-lib/src/redux/actions/api/booking/bookings'
import { FORM_BOOKINGS_FILTER } from 's3p-js-lib/src/constants-form'
import connectFormContainer from '../../../containers/base/form'
import { bookingsSelector } from 's3p-js-lib/src/redux/selectors/api/booking/bookings'
import { availableCategoriesSelector } from 's3p-js-lib/src/redux/selectors/user-input/forms/bookings-filter'
import { clearState } from 's3p-js-lib/src/redux/actions/clear-state'

class SearchBookingFormContainer extends Component {
  static propTypes = {
    getBookings: PropTypes.func.isRequired,
    resetBookings: PropTypes.func.isRequired,
    bookings: PropTypes.array,
    categories: PropTypes.array,
    loading: PropTypes.bool,
    form: PropTypes.shape({
      loadFormValues: PropTypes.func.isRequired,
      fields: PropTypes.shape({
        category: PropTypes.object,
        term: PropTypes.object
      })
    })
  }

  constructor (...args) {
    super(...args)
    this.handleGetBookings = this.handleGetBookings.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.props.form.loadFormValues({category: this.props.categories[0]})
  }

  handleGetBookings () {
    this.props.getBookings(this.props.form.fields.category.value, this.props.form.fields.term.value)
  }

  render () {
    return (
      <SearchBookingForm
        form={this.props.form}
        bookings={this.props.bookings}
        handleGetBookings={this.handleGetBookings}
        resetBookings={this.props.resetBookings}
        categories={this.props.categories}
        loading={this.props.loading}
      />
    )
  }
}

const mapStateToProps = state => ({
  bookings: bookingsSelector(state),
  categories: availableCategoriesSelector(state),
  loading: state.api.loading.getBookings
})

const mapDispatchToProps = {
  getBookings,
  resetBookings: () => clearState('api.booking.bookingList')
}

export default connect(mapStateToProps, mapDispatchToProps)(
  connectFormContainer({formName: FORM_BOOKINGS_FILTER})(
    SearchBookingFormContainer
  )
)
