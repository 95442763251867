import {
  UI_LEAP_REVERSE_START, UI_LEAP_REVERSE_END
} from '../../../actions/types'

const initialState = {
  loading: false,
  error: null,
  success: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UI_LEAP_REVERSE_START:
      return {loading: true, error: null, success: false}
    case UI_LEAP_REVERSE_END:
      return {loading: false, error: action.error, success: Boolean(!action.error)}
    default:
      return state
  }
}
