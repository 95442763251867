import { connect } from 'react-redux'
import {
  passengersSelector,
  tariffSegmentSelector,
  productsSelector,
  feeSummarySelector
} from '../../../../redux/selectors/containers/aftersales/booking/segment'
import Segment from '../../../../components/aftersales/booking/segment-set/segment'

const mapStateToProps = (state, ownProps) => ({
  tariffSegment: tariffSegmentSelector(state, ownProps),
  enrichedPassengers: passengersSelector(state, ownProps),
  products: productsSelector(state, ownProps),
  feeSummary: feeSummarySelector(state, ownProps)
})

export default connect(mapStateToProps)(Segment)
