import React from 'react'
import { Route } from 'react-router'
import UpdatePassengersLayout from '../../layouts/aftersales/update-passengers'
import { isUpdatePassengersAvailableSelector } from '../../redux/selectors/containers/base/aftersales'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import container from '../../containers/aftersales/auth'

export default (
  <Route
    key='aftersales-update-passengers'
    path='/:lang/aftersales/update-passengers'
    component={container(
      isUpdatePassengersAvailableSelector(originalBookingSelector),
      UpdatePassengersLayout
    )}
  />
)
