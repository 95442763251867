import {
  API_V2_GET_TICKET_DETAILS_REQUEST,
  API_V2_GET_TICKET_DETAILS_SUCCESS,
  API_V2_GET_TICKET_DETAILS_FAILURE
} from '../../../actions/types-v2'
import { CLEAR_STATE } from '../../../actions/types'

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_V2_GET_TICKET_DETAILS_REQUEST:
    case API_V2_GET_TICKET_DETAILS_FAILURE:
      return initialState
    case API_V2_GET_TICKET_DETAILS_SUCCESS:
      return action.response.data.ticket_details
    case CLEAR_STATE:
      return action.namespaces.includes('api.v2.ticketDetails') ? initialState : state
    default:
      return state
  }
}
