import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Accordion from '../../elements/accordion/accordion'
import scroller from 's3p-js-lib/src/misc/scroller'
import defer from 'lodash/defer'

class AccordionGroup extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    openAccordionGroupItem: PropTypes.func,
    closeAccordionGroupItem: PropTypes.func,
    openItem: PropTypes.string,
    focusOnOpen: PropTypes.bool
  }

  static defaultProps = {
    openAccordionGroupItem: () => {},
    closeAccordionGroupItem: () => {},
    openItem: '',
    focusOnOpen: false
  }

  constructor (...args) {
    super(...args)

    this.toggleItem = this.toggleItem.bind(this)
  }

  toggleItem ({name}) {
    if (this.props.openItem === name) {
      this.props.closeAccordionGroupItem(this.props.name, name)
    } else {
      this.props.openAccordionGroupItem(this.props.name, name)
      if (this.props.focusOnOpen) {
        defer(() => scroller(`${name}-accordion-header`))
      }
    }
  }

  render () {
    return (
      <div className='accordion-group'>
        {React.Children.map(this.props.children, item => {
          return React.cloneElement(item, {
            expanded: this.props.openItem === item.props.name,
            onClick: item.props.available && this.toggleItem
          })
        })}
      </div>
    )
  }
}

AccordionGroup.Item = Accordion

export default AccordionGroup
