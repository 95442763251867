import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import classNames from 'classnames'
import TextLabel from '../text-label/text-label'
import Icon from '../icon/icon'
import './loader.scss'

export const TYPE_INLINE = 'TYPE_INLINE'
export const TYPE_COMPONENT = 'TYPE_COMPONENT'
export const TYPE_BLOCKING = 'TYPE_BLOCKING'

export default class Loader extends Component {
  static propTypes = {
    type: PropTypes.oneOf([TYPE_INLINE, TYPE_BLOCKING, TYPE_COMPONENT]),
    text: PropTypes.string
  }

  static defaultProps = {
    type: TYPE_INLINE,
    text: _t.message('loading')
  }

  render () {
    const className = classNames({
      'loader': true,
      'loader--inline': TYPE_INLINE === this.props.type,
      'loader--component': TYPE_COMPONENT === this.props.type,
      'loader--blocking': TYPE_BLOCKING === this.props.type
    })
    return (
      <div className={className} role='alert' aria-live='assertive' aria-busy='true'>
        <div className='loader__content'>
          <TextLabel name='loader-message'>
            <Icon name='loader' className='align-left animation-loading' />
            <TextLabel.Text text={this.props.text} />
          </TextLabel>
        </div>
      </div>
    )
  }
}
