import aftersales from './aftersales'
import auth from './auth'
import base from './base'
import booking from './booking'
import loading from './loading'
import orientation from './orientation'
import payment from './payment'
import user from './user'
import meta from './meta'
import v2 from './v2'

export default {
  auth,
  base,
  orientation,
  booking,
  loading,
  payment,
  aftersales,
  user,
  meta,
  v2
}
