import { connect } from 'react-redux'
import { FORM_PRODUCT_SEARCH } from 's3p-js-lib/src/constants-form'
import { formFieldValueSelectorCreator } from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import {
  FULFILLMENT_METHOD_CODE_LEAP_TOP_UP,
  FULFILLMENT_METHOD_CODE_LEAP
} from '../../constants'
import LeapOptionCheckBalanceModal, { LEAP_OPTION_CHECK_BALANCE_MODAL } from '../../components/tickets/modals/leap-option-check-balance-modal'
import { hideElement } from '../../redux/actions/containers/base/visible-element'
import { readLeap } from '../../redux/actions/machine/leap'
import { goToLeap } from '../../redux/actions/containers/base/routing'
import {resetTickets} from '../../redux/actions/containers/tickets/products'

const mapStateToProps = state => {
  const productSearch = formFieldValueSelectorCreator(FORM_PRODUCT_SEARCH, 'productFamily')(state)
  let type
  if (productSearch.code === FULFILLMENT_METHOD_CODE_LEAP_TOP_UP) {
    type = 'top-up'
  } else if (productSearch.code === FULFILLMENT_METHOD_CODE_LEAP) {
    type = 'add-product'
  }
  return {
    type
  }
}

const mapDispatchToProps = {
  continueToCheckBalance: () => [
    goToLeap(),
    readLeap(),
    hideElement(LEAP_OPTION_CHECK_BALANCE_MODAL)
  ],
  onClose: () => [hideElement(LEAP_OPTION_CHECK_BALANCE_MODAL), resetTickets()]
}

export default connect(mapStateToProps, mapDispatchToProps)(LeapOptionCheckBalanceModal)
