import React from 'react'
import { Route } from 'react-router'
import UpdatePassengersReviewPaymentLayout from '../../../layouts/aftersales/update-passengers/review-payment'
import { isUpdatePassengersAvailableSelector } from '../../../redux/selectors/containers/base/aftersales'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import container from '../../../containers/aftersales/auth'

export default (
  <Route
    key='aftersales-update-passengers-review-payment'
    path='/:lang/aftersales/update-passengers/review-payment'
    component={container(
      isUpdatePassengersAvailableSelector(originalBookingSelector),
      UpdatePassengersReviewPaymentLayout
    )}
  />
)
