import { createSelector } from 'reselect'
import { stationsSelector } from 's3p-js-lib/src/redux/selectors/api/base/stations'

export const machineStationSelector = createSelector(
  [
    stationsSelector,
    state => state.machine.stationInformation.data.stationCode
  ],
  (stations, code) => stations && stations.length && code
    ? stations.find(station => station.UICStationCode === code || station.synonyms.includes(code))
    : null
)
