import { connect } from 'react-redux'
import LeapModal from './leap-modal'
import { leapTopUp } from '../../../redux/actions/containers/leap/leap-top-up'

const mapStateToProps = state => {
  const topUp = state.containers.leap.topUp
  const isLoading = topUp.loading
  const topUpError = topUp.error
  const topUpSuccess = topUp.success

  return {
    isLoading,
    leapActionError: topUpError,
    leapActionSuccess: topUpSuccess
  }
}

const mapDispatchToProps = {
  handleLeapAction: leapTopUp
}

export default connect(mapStateToProps, mapDispatchToProps)(LeapModal)
