import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import TextLabel from '../text-label/text-label'
import './indicator.scss'

export const STATUS_SUCCESS = 'success'
export const STATUS_BUSY = 'busy'
export const STATUS_ERROR = 'error'

export default class Indicator extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    text: PropTypes.string
  }

  render () {
    const {name, text, className, children, ...rest} = this.props
    return (
      <div {...rest} className={classNames('indicator', `indicator-${name}`, className)}>
        {text ? <TextLabel name='indicator-message' text={text} /> : children}
      </div>
    )
  }
}
