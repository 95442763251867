import React, { Component } from 'react'
import GridLayout from '../../../elements/grid/grid-layout'
import GridColumn from '../../../elements/grid/grid-column'
import MainMenu from '../../../containers/base/main-menu/main-menu'
import Taskbar from '../../../containers/base/taskbar/taskbar'
import GenericLayout from '../../generic-layout'
import SeatSelectionContainer from '../../../containers/aftersales/rebooking/seat-selection/seat-selection'
import RebookingOperation from '../../../components/aftersales/rebooking/operation'
import StickyFooter from '../../../elements/sticky-footer/sticky-footer'
import OrientationSummary from '../../../components/reservations/orientation-summary'
import NextButton from '../../../containers/aftersales/rebooking/seat-selection/next-button'
import BookingProgressBar from '../../../containers/aftersales/rebooking/progress-bar'

export default class SeatSelectionLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <MainMenu />
            <BookingProgressBar />
            <RebookingOperation />
            <SeatSelectionContainer />
            <StickyFooter>
              <OrientationSummary nextButton={<NextButton />} />
              <Taskbar />
            </StickyFooter>
          </GridColumn>
        </GridLayout>
      </GenericLayout>
    )
  }
}
