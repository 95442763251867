import baseLoadingReducers from 's3p-js-lib/src/redux/reducers/api/loading'
import loadingReducer from 's3p-js-lib/src/redux/lib/loading-reducer'

import {
  API_GET_MAGSTRIPE_TICKETS_REQUEST,
  API_GET_MAGSTRIPE_TICKETS_SUCCESS,
  API_GET_MAGSTRIPE_TICKETS_FAILURE,
  API_GET_BARCODE_DOCUMENT_REQUEST,
  API_GET_BARCODE_DOCUMENT_SUCCESS,
  API_GET_BARCODE_DOCUMENT_FAILURE,
  API_V2_UPDATE_PASSENGERS_REQUEST,
  API_V2_UPDATE_PASSENGERS_SUCCESS,
  API_V2_UPDATE_PASSENGERS_FAILURE,
  API_GET_LEAP_CONFIGURATION_DATA_REQUEST,
  API_GET_LEAP_CONFIGURATION_DATA_SUCCESS,
  API_GET_LEAP_CONFIGURATION_DATA_FAILURE,
  API_GET_LEAP_SCHEME_WIDE_CODE_REQUEST,
  API_GET_LEAP_SCHEME_WIDE_CODE_SUCCESS,
  API_GET_LEAP_SCHEME_WIDE_CODE_FAILURE,
  API_CONFIRM_MAGSTRIPE_REQUEST,
  API_CONFIRM_MAGSTRIPE_SUCCESS,
  API_CONFIRM_MAGSTRIPE_FAILURE
} from '../../actions/types'

export default {
  ...baseLoadingReducers,
  getMagstripeTickets: loadingReducer(
    API_GET_MAGSTRIPE_TICKETS_REQUEST,
    API_GET_MAGSTRIPE_TICKETS_SUCCESS,
    API_GET_MAGSTRIPE_TICKETS_FAILURE
  ),
  confirmMagstripe: loadingReducer(
    API_CONFIRM_MAGSTRIPE_REQUEST,
    API_CONFIRM_MAGSTRIPE_SUCCESS,
    API_CONFIRM_MAGSTRIPE_FAILURE
  ),
  getBarcodeDocument: loadingReducer(
    API_GET_BARCODE_DOCUMENT_REQUEST,
    API_GET_BARCODE_DOCUMENT_SUCCESS,
    API_GET_BARCODE_DOCUMENT_FAILURE
  ),
  updatePassengers: loadingReducer(
    API_V2_UPDATE_PASSENGERS_REQUEST,
    API_V2_UPDATE_PASSENGERS_SUCCESS,
    API_V2_UPDATE_PASSENGERS_FAILURE
  ),
  getLeapConfigurationData: loadingReducer(
    API_GET_LEAP_CONFIGURATION_DATA_REQUEST,
    API_GET_LEAP_CONFIGURATION_DATA_SUCCESS,
    API_GET_LEAP_CONFIGURATION_DATA_FAILURE
  ),
  getLeapSchemeWideCode: loadingReducer(
    API_GET_LEAP_SCHEME_WIDE_CODE_REQUEST,
    API_GET_LEAP_SCHEME_WIDE_CODE_SUCCESS,
    API_GET_LEAP_SCHEME_WIDE_CODE_FAILURE
  )
}
