import Client from '../../../../../../api/client'
import ensureToken from '../../../../../../api/ensure-token'
import {
  API_V2_GET_AGENT_SHIFT_REPORT_REQUEST,
  API_V2_GET_AGENT_SHIFT_REPORT_SUCCESS,
  API_V2_GET_AGENT_SHIFT_REPORT_FAILURE
} from '../../../../types-v2'

const getShiftReportRequest = (shiftId, templateName) => ({type: API_V2_GET_AGENT_SHIFT_REPORT_REQUEST, shiftId, templateName})
const getShiftReportSuccess = response => ({type: API_V2_GET_AGENT_SHIFT_REPORT_SUCCESS, response})
const getShiftReportFailure = error => ({type: API_V2_GET_AGENT_SHIFT_REPORT_FAILURE, error})

export const getShiftReport = (shiftId, templateName) => ensureToken(async (token, dispatch) => {
  try {
    dispatch(getShiftReportRequest(shiftId, templateName))
    const response = await Client.getShiftReport(token, shiftId, templateName)
    dispatch(getShiftReportSuccess(response))
  } catch (error) {
    dispatch(getShiftReportFailure(error))
    throw error
  }
})
