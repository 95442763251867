import {
  API_GET_BOOKING_SUCCESS,
  API_GET_BOOKING_FAILURE,
  API_REQUEST_REFUND_SUCCESS,
  API_OAUTH_LOGOUT,
  API_ADD_BOOKING_NOTE_SUCCESS,
  API_AFTERSALES_CONFIRM_FAILURE,
  API_AFTERSALES_REVERT_FAILURE,
  CLEAR_STATE
} from '../../../actions/types'

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_REQUEST_REFUND_SUCCESS:
      return action.response.data.booking
    case API_GET_BOOKING_SUCCESS:
      return action.response.data.booking
    case API_ADD_BOOKING_NOTE_SUCCESS:
      return action.response.data
    case API_AFTERSALES_CONFIRM_FAILURE:
    case API_AFTERSALES_REVERT_FAILURE:
    case API_GET_BOOKING_FAILURE:
    case API_OAUTH_LOGOUT:
      return initialState
    case CLEAR_STATE:
      return action.namespaces.includes('api.booking.overviewBooking') ? initialState : state
    default:
      return state
  }
}
