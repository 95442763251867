import { bookingSelector } from '../redux/selectors/api/booking/booking'
import { bookingSelector as aftersalesBookingSelector } from '../redux/selectors/api/aftersales/booking'
import {
  FORM_AGENT_CHANGE_PASSWORD,
  FORM_AGENT_LOGIN,
  FORM_TYPE_AGENT_MODIFY,
  FORM_TYPE_AGENT_SHIFT_NOTE,
  FORM_TYPE_AGENT_SHIFT_START,
  FORM_BOOKING_NOTE,
  FORM_BOOKINGS_FILTER,
  FORM_CUSTOMER_DETAILS,
  FORM_FIND_CUSTOMERS,
  FORM_JOURNEY_SEARCH,
  FORM_PASSENGER_DETAILS,
  FORM_PASSENGER_DETAILS_AFTERSALES,
  FORM_PAYMENT_METHOD,
  FORM_PRODUCT_SEARCH,
  FORM_TYPE_REBOOK_JOURNEY_SEARCH,
  FORM_TYPE_REDEEM_VOUCHER,
  FORM_TYPE_VOUCHER_SEARCH
} from '../constants-form'
import agentChangePassword from './agent-change-password'
import agentLogin from './agent-login'
import agentModify from './agent-modify'
import agentShiftNote from './agent-shift-note'
import agentShiftStart from './agent-shift-start'
import bookingNote from './booking-note'
import bookingsFilter from './bookings-filter'
import customerDetails from './customer-details'
import findCustomers from './find-customers'
import journeySearch from './journey-search'
import passengerDetails from './passenger-details'
import paymentMethod from './payment-method'
import productSearch from './product-search'
import rebookJourneySearch from './rebook-journey-search'
import redeemVoucher from './redeem-voucher'
import voucherSearch from './voucher-search'

const forms = {
  [FORM_AGENT_CHANGE_PASSWORD]: agentChangePassword,
  [FORM_AGENT_LOGIN]: agentLogin,
  [FORM_TYPE_AGENT_MODIFY]: agentModify,
  [FORM_TYPE_AGENT_SHIFT_NOTE]: agentShiftNote,
  [FORM_TYPE_AGENT_SHIFT_START]: agentShiftStart,
  [FORM_BOOKING_NOTE]: bookingNote,
  [FORM_BOOKINGS_FILTER]: bookingsFilter,
  [FORM_CUSTOMER_DETAILS]: customerDetails,
  [FORM_FIND_CUSTOMERS]: findCustomers,
  [FORM_JOURNEY_SEARCH]: journeySearch,
  [FORM_PASSENGER_DETAILS]: passengerDetails(bookingSelector),
  [FORM_PASSENGER_DETAILS_AFTERSALES]: passengerDetails(aftersalesBookingSelector),
  [FORM_PAYMENT_METHOD]: paymentMethod,
  [FORM_PRODUCT_SEARCH]: productSearch,
  [FORM_TYPE_REBOOK_JOURNEY_SEARCH]: rebookJourneySearch,
  [FORM_TYPE_REDEEM_VOUCHER]: redeemVoucher,
  [FORM_TYPE_VOUCHER_SEARCH]: voucherSearch
}

// eslint-disable-next-line func-style
export function registerFormType (formName, formType) {
  forms[formName] = formType
}

// eslint-disable-next-line func-style
export function getFormType (formName) {
  const formType = forms[formName]
  if (!formType) {
    throw new Error(`No form could be found with name: ${formName}`)
  }

  return formType
}
