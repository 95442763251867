import React, { Component } from 'react'
import PropTypes from '../../../../misc/prop-types'
import _t from 's3p-js-lib/src/translate'
import ServiceProperties from '../../../../containers/base/journey-search/journey-result-set/service-properties'
import TextLabel from '../../../../elements/text-label/text-label'
import Icon from '../../../../elements/icon/icon'

const serviceTypeMap = {
  STATION: 'train',
  BUS: 'bus'
}

export default class JourneySection extends Component {
  static propTypes = {
    transfer: PropTypes.shape({
      transferType: PropTypes.string,
      transferDescription: PropTypes.string,
      transferTime: PropTypes.any.isRequired
    }),
    stationName: PropTypes.string.isRequired,
    arrivalTime: PropTypes.moment,
    departureTime: PropTypes.moment,
    serviceProperties: PropTypes.array,
    specialTransfer: PropTypes.bool,
    showChange: PropTypes.bool,
    hasAlerts: PropTypes.bool
  }

  static defaultProps = {
    specialTransfer: false,
    hasAlerts: false
  }

  _renderTransfer () {
    return (
      <div className='section-remarks'>
        <TextLabel name='section-remarks-type'>
          {serviceTypeMap[this.props.transfer.transferType]
            ? <Icon name={serviceTypeMap[this.props.transfer.transferType]} className='medium align-left' />
            : null
          }
          <TextLabel.Text text={_t.message('journey-result-set.journey-details.change')} />
        </TextLabel>
        {this.props.transfer.transferDescription ? <TextLabel
          name='section-remarks-description'
          text={this.props.transfer.transferDescription}
        /> : null}
        <TextLabel
          name='section-remarks-estimate'
          text={_t.message('journey-result-set.journey-details.transfer-time', {
            time: this.props.transfer.transferTime.humanize()
          })}
        />
      </div>
    )
  }

  render () {
    const type = this.props.transfer
      ? 'transfer'
      : this.props.arrivalTime && this.props.departureTime
        ? 'via'
        : this.props.arrivalTime
          ? 'destination'
          : this.props.departureTime
            ? 'origin'
            : ''

    return (
      <div className={`journey-details-section-set ${type}`}>
        <div className='section-track'>
          <div className='section-track__line' />
          {this.props.arrivalTime && this.props.departureTime ? <div className='section-track__marker' /> : null}
        </div>
        <div className='section'>
          <div className='section-information'>
            <div className='section-information__arrival'>
              {!this.props.specialTransfer && this.props.arrivalTime
                ? <TextLabel name='section-time' text={this.props.arrivalTime.format('LT')} />
                : null
              }
            </div>
            <div className='section-information__departure'>
              {!this.props.showChange && this.props.departureTime
                ? <TextLabel name='section-time' text={this.props.departureTime.format('LT')} />
                : null
              }
            </div>
            <div className='section-information__station'>
              <TextLabel name='station-name' text={this.props.stationName} />
            </div>
            <div className='section-information__features'>
              {this.props.serviceProperties
                ? (
                  <ServiceProperties
                    showDefaultIcons={false}
                    showAlert={false}
                    serviceProperties={this.props.serviceProperties}
                  />
                ) : null
              }
            </div>
            {this.props.showChange
              ? this._renderTransfer()
              : null
            }
          </div>
        </div>
      </div>
    )
  }
}
