import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Panel from '../../../elements/panel/panel'
import ComponentContainer from '../../../elements/component-container/component-container'
import _t from 's3p-js-lib/src/translate'

export default class SeatSelectorWrapper extends Component {
  static propTypes = {
    isLoadingSeatSelectionOptions: PropTypes.bool.isRequired
  }

  render () {
    return (
      <ComponentContainer name='seat-selector'>
        <Panel
          loading={this.props.isLoadingSeatSelectionOptions}
          header={<Panel.Header
            title={_t.message('seat-selector.title')}
            description={_t.message('seat-selector.description')}
            name='seat-selector'
          />}
        >
          {this.props.children}
        </Panel>
      </ComponentContainer>
    )
  }
}
