import stationInformation from './station-information'
import terminalInformation from './terminal-information'
import display from './display'
import cashDrawer from './cash-drawer'
import magneticTicketPrinter from './magnetic-ticket-printer'
import receiptPrinter from './receipt-printer'
import leap from './leap'
import pinPayment from './pin-payment'
import status from './status'
import lastMachineErrors from './last-machine-errors'
import loading from './loading'
import barcodeScanner from './barcode-scanner'

export default {
  lastMachineErrors,
  stationInformation,
  terminalInformation,
  display,
  cashDrawer,
  magneticTicketPrinter,
  receiptPrinter,
  leap,
  pinPayment,
  status,
  loading,
  barcodeScanner
}
