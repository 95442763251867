import { createSelector } from 'reselect'
import Validator from './validator'
import { FORM_PRODUCT_SEARCH } from '../constants-form'
import { formFieldValueSelectorCreator } from '../redux/selectors/user-input/base/form'

export const cannotBeSameStation = (value, {values}) =>
  value &&
  values.origin && values.destination &&
  values.origin.UICStationCode === values.destination.UICStationCode ? 'stations-are-equal' : null

export default createSelector(
  [formFieldValueSelectorCreator(FORM_PRODUCT_SEARCH, 'productFamily')],
  fieldValue => ({
    fields: {
      origin: {
        validators: [
          Validator.isRequired,
          Validator.isValidStationObject
        ]
      },
      destination: {
        validators: [
          Validator.isRequired,
          Validator.isValidStationObject,
          cannotBeSameStation
        ]
      },
      validFrom: {
        validators: [
          Validator.isRequired,
          Validator.isValidDate,
          Validator.cannotBeInThePast
        ]
      },
      passengers: {
        validators: [
          Validator.arrayAtLeastOneItem,
          Validator.isValidPassenger
        ]
      },
      productFamily: {
        validators: [
          Validator.isRequired
        ]
      }
    }
  })
)
