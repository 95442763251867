import { CLEAR_STATE } from 's3p-js-lib/src/redux/actions/types'
import {
  CONTAINERS_BASE_PAYMENT_MODAL_CREDIT_DEBIT,
  PED_RESULT_CODE_VOICE_AUTHORIZATION
} from '../../../../../constants'
import {
  MACHINE_COMPLETE_PIN_PAYMENT_REQUEST,
  MACHINE_COMPLETE_PIN_PAYMENT_SUCCESS,
  MACHINE_COMPLETE_PIN_PAYMENT_FAILURE,
  MACHINE_RECEIPT_PRINT_REQUEST,
  MACHINE_RECEIPT_PRINT_SUCCESS,
  MACHINE_RECEIPT_PRINT_FAILURE,
  UI_PRINTING_PED_RECEIPT_ERROR,
  UI_PRINTING_PED_RECEIPT_SUCCESS,
  UI_PRINTING_PED_FAILED_RECEIPT_ERROR,
  MACHINE_START_PIN_PAYMENT_SUCCESS,
  UI_PED_STATUS,
  UI_ADD_PED_PAYMENT
} from '../../../../actions/types'

const initialState = {
  status: null,
  showForm: false,
  loadingValidate: false,
  loadingDecline: false,
  receiptError: false,
  retryReceipt: false,
  receiptSignatureError: false,
  receiptPedFailedError: false,
  addPedPaymentCount: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UI_PED_STATUS:
      return {...state, status: action.status}
    case MACHINE_RECEIPT_PRINT_REQUEST:
      return {
        ...state,
        receiptError: false,
        receiptSignatureError: false,
        loadingValidate: true,
        loadingDecline: true
      }
    case MACHINE_RECEIPT_PRINT_SUCCESS:
    case MACHINE_RECEIPT_PRINT_FAILURE:
      return {
        ...state,
        loadingValidate: false,
        loadingDecline: false
      }
    case UI_PRINTING_PED_RECEIPT_ERROR:
      return {...state, receiptError: true, retryReceipt: true}
    case UI_PRINTING_PED_RECEIPT_SUCCESS:
      return {...state, receiptError: false, retryReceipt: false}
    case UI_PRINTING_PED_FAILED_RECEIPT_ERROR:
      return {...state, receiptPedFailedError: true}
    case UI_ADD_PED_PAYMENT:
      return {...state, addPedPaymentCount: state.addPedPaymentCount + 1}
    case MACHINE_COMPLETE_PIN_PAYMENT_REQUEST:
      return {
        ...state,
        loadingValidate: action.data.Valid === true.toString(),
        loadingDecline: action.data.Valid === false.toString()
      }
    case MACHINE_COMPLETE_PIN_PAYMENT_SUCCESS:
    case MACHINE_COMPLETE_PIN_PAYMENT_FAILURE:
      return {
        ...state,
        showForm: false,
        loadingValidate: false,
        loadingDecline: false
      }
    case MACHINE_START_PIN_PAYMENT_SUCCESS:
      return {
        ...state,
        showForm: action.response.results.result
          ? parseInt(action.response.results.result) === PED_RESULT_CODE_VOICE_AUTHORIZATION
          : false
      }
    case CLEAR_STATE:
      return action.namespaces.includes(CONTAINERS_BASE_PAYMENT_MODAL_CREDIT_DEBIT) ? initialState : state
    default:
      return state
  }
}
