import { createSelector } from 'reselect'
import { bundlesSelector } from '../../api/orientation/offer'

export const selectedUniqueBundleIdsSelector = createSelector(
  [
    state => state.api.orientation.offer,
    state => state.api.orientation.selectedBundles
  ],
  (offer, selectedBundles) => offer && selectedBundles && selectedBundles.map(selectedBundle => {
    const travel = offer.travels.find(travel => travel.id === selectedBundle.travel_id)
    const route = travel && travel.routes.find(route => route.id === selectedBundle.route_id)
    const bundle = route && route.bundles.find(bundle => bundle.id === selectedBundle.id)

    return bundle && bundle.unique_id
  })
)

export const bundlesBySelectedUniqueBundleIdsSelector = createSelector(
  [
    state => state.userInput.orientation.selectedUniqueBundleIds,
    bundlesSelector
  ],
  (uniqueBundleIds, bundles) => uniqueBundleIds && bundles && uniqueBundleIds.reduce(
    (selectedBundles, uniqueBundleId) => {
      const selectedBundle = bundles.find(bundle => bundle.uniqueId === uniqueBundleId)
      if (selectedBundle) {
        selectedBundles.push(selectedBundle)
      }
      return selectedBundles
    },
    []
  )
)
