import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CashForm from '../../../components/base/payment-modal/cash-form'
import {
  cashReceived as handleChangeAmountReceived,
  currencySwitch as handleChangeCurrency
} from '../../../redux/actions/containers/base/payment-modal/cash-form'
import { openMachineCashDrawer } from '../../../redux/actions/machine/cash-drawer'

class CashFormContainer extends Component {
  static propTypes = {
    openMachineCashDrawer: PropTypes.func.isRequired
  }

  UNSAFE_componentWillMount () {
    this.props.openMachineCashDrawer()
  }

  render () {
    return <CashForm {...this.props} />
  }
}

const mapStateToProps = state => ({
  amountReceived: state.containers.base.paymentModal.cashForm.received || 0,
  amountChange: state.containers.base.paymentModal.cashForm.change || 0,
  apiError: Boolean(state.api.base.lastApiErrors.v2.addPayments || state.api.base.lastApiErrors.v2.updatePayments),
  paymentCurrency: state.containers.base.paymentModal.cashForm.currency,
  amountToBePaid: state.containers.base.tiles.paymentMethods.amountToBePaid,
  amountToBePaidGBP: state.containers.base.tiles.paymentMethods.amountToBePaidGBP
})

const mapDispatchToProps = {
  handleChangeCurrency,
  openMachineCashDrawer,
  handleChangeAmountReceived
}

export default connect(mapStateToProps, mapDispatchToProps)(CashFormContainer)
