import React, { Component } from 'react'
import GridLayout from '../../elements/grid/grid-layout'
import GridColumn from '../../elements/grid/grid-column'
import MainMenu from '../../containers/base/main-menu/main-menu'
import GenericLayout from '../generic-layout'
import AdditionalProductsContainer from '../../containers/reservations/additional-products/additional-products'
import StickyFooter from '../../elements/sticky-footer/sticky-footer'
import OrientationSummary from '../../containers/reservations/orientation-summary'
import NextButton from '../../containers/reservations/additional-products/next-button'
import Taskbar from '../../containers/base/taskbar/taskbar'
import BookingProgressBar from '../../containers/reservations/progress-bar'

export default class AdditionalProductsLayout extends Component {
  render () {
    return (
      <GenericLayout>
        <GridLayout name='content'>
          <GridColumn name='content'>
            <MainMenu />
            <BookingProgressBar />
            <AdditionalProductsContainer />
            <StickyFooter>
              <OrientationSummary nextButton={<NextButton />} />
              <Taskbar />
            </StickyFooter>
          </GridColumn>
        </GridLayout>
      </GenericLayout>
    )
  }
}
