import { connect } from 'react-redux'
import { routerActions } from '../../../../redux/actions/containers/base/routing'
import { isLoadingToolbarActionsSelector } from '../../../../redux/selectors/containers/aftersales/booking/toolbar'
import ToolbarItem from '../../../../components/aftersales/booking/toolbar/toolbar-item'

const mapStateToProps = state => ({
  disabled: isLoadingToolbarActionsSelector(state)
})

const mapDispatchToProps = {
  onClick: () => routerActions.push('/aftersales/bookings')
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarItem)
