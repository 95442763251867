import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import ClickableRow from '../../../../elements/table/clickable-row'
import TextLabel from '../../../../elements/text-label/text-label'

export default class FindCustomersRow extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    customer: PropTypes.shape({
      fullName: PropTypes.string,
      emailAddress: PropTypes.string
    })
  }

  constructor (...args) {
    super(...args)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick () {
    this.props.onClick(this.props.customer)
  }

  render () {
    return (
      <ClickableRow onClick={this.handleClick}>
        <td data-th-label={_t.message('booking-details.find-customers-dialog.table.column-name')}>
          <TextLabel name='customer' text={this.props.customer.fullName} />
        </td>
        <td data-th-label={_t.message('booking-details.find-customers-dialog.table.column-email')}>
          <TextLabel name='email-address' text={this.props.customer.emailAddress} />
        </td>
      </ClickableRow>
    )
  }
}
