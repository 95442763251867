import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ComponentContainer from '../../../elements/component-container/component-container'
import Panel from '../../../elements/panel/panel'
import Form from '../../../elements/form/form'
import CancelEntireBooking from '../../../containers/aftersales/cancellation/cancel-by/cancel-entire-booking'
import CancelByDirection from '../../../containers/aftersales/cancellation/cancel-by/cancel-by-direction'
import CancelByPassengers from './cancel-by/cancel-by-passengers'
import _t from 's3p-js-lib/src/translate'
import './cancel-by.scss'

export default class CancelBy extends Component {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    allowedPartialCancellation: PropTypes.bool.isRequired,
    bookingCancellationData: PropTypes.object,
    outboundCancellationData: PropTypes.object,
    inboundCancellationData: PropTypes.object,
    passengersCancellationData: PropTypes.array,
    selectItemIds: PropTypes.func,
    deselectItemIds: PropTypes.func
  }

  render () {
    const {
      bookingCancellationData,
      outboundCancellationData,
      inboundCancellationData,
      passengersCancellationData,
      selectItemIds,
      deselectItemIds,
      disabled,
      allowedPartialCancellation
    } = this.props

    return bookingCancellationData
      ? (
        <ComponentContainer name='cancellation-cancel-by'>
          <Panel header={<Panel.Header
            name='cancellation-options'
            title={_t.message('aftersales.cancellation.cancel-by.title')}
            description={_t.message('aftersales.cancellation.cancel-by.description')} />
          }>
            <Form formContext='aftersales.cancellation.cancel-by'>
              <CancelEntireBooking
                bookingCancellationData={bookingCancellationData}
                onSelectItemIds={selectItemIds}
                onDeselectItemIds={deselectItemIds}
                disabled={disabled}
              />
              <CancelByDirection
                bookingCancellationData={bookingCancellationData}
                passengersCancellationData={passengersCancellationData}
                outboundCancellationData={outboundCancellationData}
                inboundCancellationData={inboundCancellationData}
                onSelectItemIds={selectItemIds}
                onDeselectItemIds={deselectItemIds}
                disabled={disabled || !allowedPartialCancellation}
              />
              <CancelByPassengers
                bookingCancellationData={bookingCancellationData}
                passengersCancellationData={passengersCancellationData}
                outboundCancellationData={outboundCancellationData}
                inboundCancellationData={inboundCancellationData}
                onSelectItemIds={selectItemIds}
                onDeselectItemIds={deselectItemIds}
                disabled={disabled || !allowedPartialCancellation}
              />
            </Form>
          </Panel>
        </ComponentContainer>
      ) : null
  }
}
