import {
  API_V2_CREATE_BOOKING_REQUEST,
  API_V2_CREATE_BOOKING_SUCCESS,
  API_V2_CREATE_BOOKING_FAILURE,
  API_V2_UPDATE_SEGMENTS_REQUEST,
  API_V2_UPDATE_SEGMENTS_SUCCESS,
  API_V2_UPDATE_SEGMENTS_FAILURE,
  API_V2_UPDATE_FULFILLMENT_METHOD_REQUEST,
  API_V2_UPDATE_FULFILLMENT_METHOD_SUCCESS,
  API_V2_UPDATE_FULFILLMENT_METHOD_FAILURE
} from '../../types-v2'
import Client from '../../../../../../src/api/client'
import ensureToken from '../../../../api/ensure-token'
import { segmentsSelector, passengersSelector } from '../../../selectors/api/v2/search'
import { bookingSelector, bookingNumberSelector } from '../../../selectors/api/booking/booking'
import ResponseCodes from '../../../../api/response-codes'
import {oauthLogout} from 's3p-js-lib/src/redux/actions/api/auth/logout'

export const UPDATE_BOOKING_HANDLED_ERRORS = [
  ResponseCodes.BOOKING_IS_EXPIRED,
  ResponseCodes.BOOKING_NOT_FOUND_FOR_BOOKING_NUMBER
]

const _bookingNumberSelector = bookingNumberSelector(bookingSelector)

const createBookingRequest = (segments, products, passengers) => ({
  type: API_V2_CREATE_BOOKING_REQUEST,
  segments,
  products,
  passengers
})
const createBookingSuccess = response => ({type: API_V2_CREATE_BOOKING_SUCCESS, response})
const createBookingFailure = error => ({type: API_V2_CREATE_BOOKING_FAILURE, error})

const updateSegmentsRequest = (bookingNumber, segments, products) => ({
  type: API_V2_UPDATE_SEGMENTS_REQUEST,
  bookingNumber,
  segments,
  products
})
const updateSegmentsSuccess = response => ({type: API_V2_UPDATE_SEGMENTS_SUCCESS, response})
const updateSegmentsFailure = error => ({type: API_V2_UPDATE_SEGMENTS_FAILURE, error})

export const updateFulfillmentMethodRequest = (bookingNumber, fulfillmentMethodCode) => ({
  type: API_V2_UPDATE_FULFILLMENT_METHOD_REQUEST,
  bookingNumber,
  fulfillmentMethodCode
})
export const updateFulfillmentMethodSuccess = response => ({type: API_V2_UPDATE_FULFILLMENT_METHOD_SUCCESS, response})
export const updateFulfillmentMethodFailure = error => ({type: API_V2_UPDATE_FULFILLMENT_METHOD_FAILURE, error})

export const createProductBooking = product => ensureToken(_createBooking(product))
export const updateSegments = product => ensureToken(_updateBooking(product))

export const addProduct = product => ensureToken(async (token, dispatch, getState) => {
  const bookingNumber = _bookingNumberSelector(getState())

  if (!bookingNumber) {
    return _createBooking(product)(token, dispatch, getState)
  } else {
    return _updateBooking(product)(token, dispatch, getState)
  }
})

export const updateFulfillmentMethod = (bookingNumber, fulfillmentMethodCode) =>
  ensureToken(async (token, dispatch) => {
    try {
      dispatch(updateFulfillmentMethodRequest(bookingNumber, fulfillmentMethodCode))

      const response = await Client.updateFulfillmentMethod(token, bookingNumber, fulfillmentMethodCode)
      dispatch(updateFulfillmentMethodSuccess(response))
    } catch (error) {
      dispatch(updateFulfillmentMethodFailure(error))
      if (hasInvalidSessionResponse(error)) {
        dispatch(oauthLogout())
        return false
      }

      if (UPDATE_BOOKING_HANDLED_ERRORS.includes(error.errorCode)) {
        return false
      }
      throw error
    }
  })

const _createBooking = product => (token, dispatch, getState) => {
  const state = getState()
  const segments = _getSegments(product, state)
  const products = getPassengerProductsByProduct(product)
  const passengers = Object.values(passengersSelector(state))

  return dispatch(createBooking(segments, products, passengers))
}

export const createBooking = (segments, products, passengers) => ensureToken(async (token, dispatch) => {
  try {
    dispatch(createBookingRequest(segments, products, passengers))
    const response = await Client.createBooking(token, segments, products, passengers)
    dispatch(createBookingSuccess(response))
  } catch (error) {
    dispatch(createBookingFailure(error))
    if (hasInvalidSessionResponse(error)) {
      dispatch(oauthLogout())
      return false
    }
    throw error
  }
})

const _updateBooking = product => async (token, dispatch, getState) => {
  const state = getState()
  const bookingNumber = _bookingNumberSelector(state)
  const segments = _getSegments(product, state)
  const products = getPassengerProductsByProduct(product)

  try {
    dispatch(updateSegmentsRequest(bookingNumber, segments, products))
    const response = await Client.updateSegments(token, bookingNumber, segments, products)
    dispatch(updateSegmentsSuccess(response))
  } catch (error) {
    dispatch(updateSegmentsFailure(error))
    if (hasInvalidSessionResponse(error)) {
      dispatch(oauthLogout())
      return false
    }

    if (UPDATE_BOOKING_HANDLED_ERRORS.includes(error.errorCode)) {
      return false
    }
    throw error
  }
}

const _getSegments = (product, state) => {
  const searchSegments = segmentsSelector(state)
  return product.segmentIds.map(id => searchSegments[id])
}

const hasInvalidSessionResponse = error => {
  return ([
    ResponseCodes.USER_OAUTH_AGENT_SESSION_EXPIRED,
    ResponseCodes.USER_OAUTH_INVALID_REFRESH_TOKEN,
    ResponseCodes.USER_AGENT_INCORRECT_AGENT_PERMISSIONS,
    ResponseCodes.USER_INVALID_SESSION_ID_FOR_AGENT
  ].includes(error.errorCode) &&
    ([400, 401, 403].includes(error.statusCode))
  )
}

const getPassengerProductsByProduct = product => product.passengerFares.map(passengerFare => ({
  passengerId: passengerFare.id,
  tariffCode: passengerFare.tariffCode
}))
