import React from 'react'
import { Route } from 'react-router'
import BookingsLayout from '../../layouts/aftersales/bookings'
import containAuth from '../../containers/base/auth'
import { AGENT_ROLE_OPERATOR } from '../../constants'

export default (
  <Route
    key='aftersales-bookings'
    path='/:lang/aftersales/bookings'
    component={containAuth(BookingsLayout, {roles: [AGENT_ROLE_OPERATOR]})}
  />
)
