import {
  API_JOURNEY_SEARCH_SUCCESS,
  API_PROCESS_DEEP_LINK_SUCCESS,
  API_PROCESS_DEEP_LINK_FAILURE,
  API_REBOOKING_INITIATE_REQUEST,
  API_REBOOKING_INITIATE_SUCCESS,
  API_REBOOKING_JOURNEY_SEARCH_SUCCESS,
  API_JOURNEY_SEARCH_REQUEST,
  API_REBOOKING_JOURNEY_SEARCH_REQUEST,
  API_CANCELLATION_INITIATE_REQUEST,
  API_CHANGE_SEATS_INITIATE_REQUEST,
  API_UPDATE_PASSENGERS_INITIATE_REQUEST,
  API_ADDITIONAL_PRODUCTS_INITIATE_REQUEST,
  API_AFTERSALES_CONFIRM_REQUEST,
  API_CREATE_PROVISIONAL_BOOKING_FAILURE,
  API_OAUTH_LOGOUT,
  CLEAR_STATE
} from '../../../actions/types'
import { journeySearchSelector } from '../../../selectors/api/aftersales/rebooking/journey-search'

const updateStateByBooking = (state, booking) => booking
  ? {
    ...state,
    ...journeySearchSelector(booking)
  }
  : state

export const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case API_JOURNEY_SEARCH_REQUEST:
    case API_REBOOKING_JOURNEY_SEARCH_REQUEST:
    case API_PROCESS_DEEP_LINK_FAILURE:
    case API_REBOOKING_INITIATE_REQUEST:
    case API_CANCELLATION_INITIATE_REQUEST:
    case API_CHANGE_SEATS_INITIATE_REQUEST:
    case API_UPDATE_PASSENGERS_INITIATE_REQUEST:
    case API_ADDITIONAL_PRODUCTS_INITIATE_REQUEST:
    case API_AFTERSALES_CONFIRM_REQUEST:
    case API_CREATE_PROVISIONAL_BOOKING_FAILURE:
    case API_OAUTH_LOGOUT:
      return initialState
    case API_JOURNEY_SEARCH_SUCCESS:
    case API_REBOOKING_JOURNEY_SEARCH_SUCCESS:
      return action.response.data.journey_search
    case API_PROCESS_DEEP_LINK_SUCCESS:
      return action.response.data.session.journey_search
        ? action.response.data.session.journey_search.journey_search : state
    case API_REBOOKING_INITIATE_SUCCESS:
      return updateStateByBooking(state, action.response.data.booking)
    case CLEAR_STATE:
      return action.namespaces.includes('journeySearch.offer') ||
      action.namespaces.includes('api.orientation.journeySearch') ? initialState : state
    default:
      return state
  }
}
