import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Icon from '../../../../elements/icon/icon'
import Modal from '../../../../elements/modal/modal'
import Button from '../../../../elements/button/button'
import TextLabel from '../../../../elements/text-label/text-label'
import _t from 's3p-js-lib/src/translate'

export default class ToolbarItem extends Component {
  static propTypes = {
    available: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    overrideAftersalesRules: PropTypes.bool,
    overrideValidationRules: PropTypes.bool,
    isElementVisible: PropTypes.func,
    onShowModal: PropTypes.func,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
  }

  constructor (...args) {
    super(...args)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick () {
    if (this.props.onClick) {
      if (this.props.overrideAftersalesRules || this.props.overrideValidationRules) {
        this.props.onShowModal(this.props.name)
      } else {
        this.props.onClick()
      }
    }
  }

  render () {
    const label = _t.message(this.props.label)
    const modalVisible = this.props.isElementVisible && this.props.isElementVisible(this.props.name)

    return this.props.available
      ? (
        <div className='toolbar__item'>
          <Button
            name={this.props.name}
            className='primary'
            onClick={this.handleClick}
            loading={this.props.loading && !modalVisible}
            disabled={this.props.disabled}
          >
            <Icon name={this.props.iconName} className='align-left' />
            <TextLabel text={label} />
          </Button>
          {modalVisible
            ? (
              <Modal
                name={this.props.name}
                header={<Modal.Header title={label} />}
                footer={<Modal.Footer
                  labelCancel='modal.no'
                  labelContinue='modal.yes'
                  onContinue={this.props.onClick}
                  isLoadingContinue={this.props.loading}
                />}
              >
                <TextLabel text={_t.message(
                  `booking.toolbar.override-${this.props.overrideValidationRules ? 'validation' : 'aftersales'}`
                )} />
              </Modal>
            ) : null}
        </div>
      ) : null
  }
}
