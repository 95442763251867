import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _t from 's3p-js-lib/src/translate'
import ComponentContainer from '../../elements/component-container/component-container'
import Panel from '../../elements/panel/panel'
import Button from '../../elements/button/button'
import ButtonGroup from '../../elements/button-group/button-group'
import TextLabel from '../../elements/text-label/text-label'
import Feedback, { FEEDBACK_SUCCESS, FEEDBACK_ALERT, FEEDBACK_INFORMATION } from '../../elements/feedback/feedback'
import { STATUS_UNKNOWN, STATUS_OK, STATUS_ERROR } from '../../machine/device-manager'
import './test.scss'

export default class setDarkness extends Component {
  static propTypes = {
    isLoadingSetDarkness: PropTypes.bool.isRequired,
    decreaseDarkness: PropTypes.func.isRequired,
    increaseDarkness: PropTypes.func.isRequired,
    testStatus: PropTypes.oneOf([STATUS_UNKNOWN, STATUS_OK, STATUS_ERROR]),
    printerDarkness: PropTypes.string
  }

  render () {
    let status, text
    if (this.props.isLoadingSetDarkness) {
      status = FEEDBACK_INFORMATION
      text = _t.message('maintenance.set-darkness.loading')
    } else if (STATUS_OK === this.props.testStatus) {
      status = FEEDBACK_SUCCESS
      text = _t.message('maintenance.set-darkness.feedback.success', {darknessLevel: this.props.printerDarkness})
    } else if (STATUS_ERROR === this.props.testStatus) {
      status = FEEDBACK_ALERT
      text = _t.message('maintenance.set-darkness.feedback.error')
    }

    return (
      <ComponentContainer name='maintenance-test'>
        <Panel
          header={
            <Panel.Header
              name='maintenance-set-darkness'
              title={_t.message('maintenance.set-darkness.title')}
              description={_t.message('maintenance.set-darkness.description')}
            />
          }
        >
          {status && text ? <Feedback status={status} text={text} /> : null}
          <ButtonGroup>
            <Button
              name='test-display'
              className='primary'
              loading={this.props.isLoadingSetDarkness}
              onClick={this.props.increaseDarkness}
            >
              <TextLabel text={_t.message('maintenance.set-darkness.button.increase')} />
            </Button>
            <Button
              name='clear-display'
              className='primary'
              loading={this.props.isLoadingSetDarkness}
              onClick={this.props.decreaseDarkness}
            >
              <TextLabel text={_t.message('maintenance.set-darkness.button.decrease')} />
            </Button>
          </ButtonGroup>
        </Panel>
      </ComponentContainer>
    )
  }
}
