import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ComponentContainer from '../../elements/component-container/component-container'
import LeapProductTabs from '../../containers/leap/products/leap-product-tabs'
import Panel from '../../elements/panel/panel'
import _t from 's3p-js-lib/src/translate'
import './leap-products.scss'

export default class LeapProducts extends Component {
  static propTypes = {
    hasLeapData: PropTypes.bool
  }

  render () {
    return this.props.hasLeapData
      ? (
        <ComponentContainer name='leap-products'>
          <Panel
            header={
              <Panel.Header
                name='leap-read'
                title={_t.message('leap.products.title')}
                description={_t.message('leap.products.description')}
              />
            }
          >
            <LeapProductTabs />
          </Panel>
        </ComponentContainer>
      ) : null
  }
}
