import { createSelector } from 'reselect'
import { productFamiliesSelector as baseProductFamiliesSelector } from '../../api/v2/meta/product-families'
import { formFieldValueSelectorCreator } from 's3p-js-lib/src/redux/selectors/user-input/base/form'
import { FORM_PRODUCT_SEARCH } from 's3p-js-lib/src/constants-form'

export const productFamiliesSelector = createSelector(
  [
    baseProductFamiliesSelector,
    formFieldValueSelectorCreator(FORM_PRODUCT_SEARCH, 'productFamily')
  ],
  (productFamilies, selectedProductFamily) => {
    return productFamilies.map(productFamily =>
      selectedProductFamily && productFamily.code === selectedProductFamily.code
        ? {...productFamily, selected: true}
        : productFamily
    )
  }
)
