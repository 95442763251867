import { createSelector } from 'reselect'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import { basePassengersSelector } from 's3p-js-lib/src/redux/selectors/api/booking/passengers'
import { productFamiliesSelector } from 's3p-js-lib/src/redux/selectors/api/booking/product-families'
import { toUtcDateMoment } from 's3p-js-lib/src/misc/date'
import { generateItemId } from 's3p-js-lib/src/redux/selectors/api/v2/search'
import { TRAVEL_DIRECTION_OUTWARD } from '../../../../constants'

const lastBookingSelector = state => state.containers.tickets.repeatLastBooking.lastBooking
const _productFamiliesSelector = productFamiliesSelector(lastBookingSelector)

export const passengerSelector = createSelector(
  [basePassengersSelector(lastBookingSelector)],
  passengers => passengers.map(passenger => ({
    id: passenger.id,
    type: passenger.type,
    disabilityType: passenger.disabilityType,
    discountCards: passenger.discountCards
  }))
)

export const disableRepeatLastBookingSelector = createSelector(
  [basePassengersSelector(lastBookingSelector)],
  passengers => !passengers || passengers.some(passenger => (
    passenger.discountCards && passenger.discountCards.length))
)

export const productsFromLastBookingSelector = state => {
  const booking = lastBookingSelector(state)
  const passengers = passengerSelector(state)
  const passengersObject = keyBy(passengers, 'id')
  const productFamilies = _productFamiliesSelector(state)
  let items = []

  booking.outbound_booking_tariff_segments.forEach(tariffSegment => {
    const validityStartDate = toUtcDateMoment(tariffSegment.validity_start_date)
    const currentDate = toUtcDateMoment()
    const segments = [{
      origin: tariffSegment.departure_station && tariffSegment.departure_station._u_i_c_station_code,
      destination: tariffSegment.arrival_station && tariffSegment.arrival_station._u_i_c_station_code,
      validFrom: validityStartDate.isBefore(currentDate) ? currentDate : validityStartDate,
      direction: TRAVEL_DIRECTION_OUTWARD
    }]
    const allProducts = tariffSegment.required_products.concat(tariffSegment.additional_products)
    const groupedByProductFamilies = groupBy(allProducts, 'product_family_id')
    Object.values(groupedByProductFamilies).forEach(productsByFamily => {
      const productFamily = productFamilies.find(
        productFamily => productFamily.id === productsByFamily[0].product_family_id
      )

      const groupedByProductCode = groupBy(productsByFamily, 'code')

      Object.values(groupedByProductCode).forEach(products => {
        const passengerFares = products.map(product => ({
          type: passengersObject[product.passenger_id].type,
          tariffCode: product.tariff_code
        }))

        items.push({
          segments,
          passengers,
          productFamily: productFamily.code,
          productId: generateItemId(products[0].code, passengerFares)
        })
      })
    })
  })

  return items
}
