import { connect } from 'react-redux'
import { processReverseLeap } from '../../../redux/actions/containers/aftersales/cancellation/reverse-leap'
import ReverseLeap from '../../../components/aftersales/cancellation/reverse-leap'
import { originalBookingSelector } from 's3p-js-lib/src/redux/selectors/api/aftersales/booking'
import { leapDataSelector } from '../../../redux/selectors/machine/leap'
import { canOverrideValidationRulesFullOrIssueSelector } from '../../../redux/selectors/api/auth/auth'

const _canOverrideValidationRulesFullOrIssueSelector = canOverrideValidationRulesFullOrIssueSelector(originalBookingSelector)

const mapStateToProps = state => {
  const data = leapDataSelector(state)
  return {
    ...state.containers.aftersales.cancellation.reverseLeap,
    isLoadingRead: state.machine.loading.readLeap,
    isLoadingReverse: state.machine.loading.reverseLeap,
    canOverrideValidation: _canOverrideValidationRulesFullOrIssueSelector(state),
    expiryDate: data && data.expiryDate
  }
}

const mapDispatchToProps = {
  processReverseLeap
}

export default connect(mapStateToProps, mapDispatchToProps)(ReverseLeap)
