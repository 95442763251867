/* globals S3P_SETTINGS: true */
/* globals DEBUG_MODE: true */

import {setSentrySettings, applySentryBrowser, Dedupe, Offline} from '@s3p-js-deep-purple/sentry'
import {HTTP_CODE_UNAUTHORIZED} from 's3p-js-lib/src/constants'
import ResponseCodes from 's3p-js-lib/src/api/response-codes'
import {
  API_GET_RECEIPT_DOCUMENT_FAILURE,
  API_OAUTH_REFRESH_TOKEN_FAILURE,
  API_OAUTH_TOKEN_ERROR,
  API_UNHANDLED_ERROR
} from 's3p-js-lib/src/redux/actions/types'
import get from 'lodash/get'
import {STATE_API_AUTH_OAUTH_TOKEN} from 's3p-js-lib/src/redux/reducers/api/auth/oauth-token'
import {
  API_GET_BARCODE_DOCUMENT_FAILURE,
  API_GET_GIFT_VOUCHER_DOCUMENT_FAILURE,
  API_GET_REFUND_VOUCHER_DOCUMENT_FAILURE
} from '../../redux/actions/types'

const getFullErrorCode = action => {
  const errorCode = action.error.errorCode
  const subCode = get(action, 'error.response.error.sub_code')
  return subCode ? `${errorCode}.${subCode}` : errorCode
}

setSentrySettings({
  appDebugMode: DEBUG_MODE,
  sentryEnvironment: S3P_SETTINGS.s3Passenger.sentry.environment || S3P_SETTINGS.environment,
  appVersion: S3P_SETTINGS.s3Passenger.release,
  dsn: S3P_SETTINGS.s3Passenger.sentry.dsn,
  defaultTags: {
    gitHash: S3P_SETTINGS.s3Passenger.gitHash,
    customer: S3P_SETTINGS.s3Passenger.customer,
    affix: S3P_SETTINGS.s3Passenger.affix,
    stage: S3P_SETTINGS.s3Passenger.stage
  },
  enableReportDialog: false,
  enableSessionReplay: true,
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
  additionalIntegrations: [
    new Offline({maxStoredEvents: 50}),
    new Dedupe()
  ],
  logLevel: S3P_SETTINGS.s3Passenger.sentry.logLevel,
  logErrorActionAsserter: action => {
    if (
      action.error &&
      action.error instanceof Error &&
      action.error.statusCode === 404 && [
        API_GET_RECEIPT_DOCUMENT_FAILURE,
        API_GET_BARCODE_DOCUMENT_FAILURE,
        API_GET_GIFT_VOUCHER_DOCUMENT_FAILURE,
        API_GET_REFUND_VOUCHER_DOCUMENT_FAILURE
      ].includes(action.type)
    ) {
      return false
    }
    return action.error &&
      action.error instanceof Error &&
      HTTP_CODE_UNAUTHORIZED !== action.error.statusCode
  },
  severityLevelActionResolver: action => {
    const code = getFullErrorCode(action)

    if (code) {
      const mappedSeverity = ResponseCodes.getSeverity(code)
      if (mappedSeverity) {
        return mappedSeverity
      }
    }

    const statusCode = action.error && action.error.statusCode
    if (
      statusCode && (
        [API_OAUTH_REFRESH_TOKEN_FAILURE, API_OAUTH_TOKEN_ERROR].includes(action.type) ||
        (API_UNHANDLED_ERROR === action.type && [401, 403, 404].includes(statusCode))
      )
    ) {
      return ResponseCodes.severity.info
    }

    return ResponseCodes.severity.error
  },
  fingerprintRequestedFromMap: new Map([]),
  authTokenResolver: () => window?.sessionStorage?.getItem(STATE_API_AUTH_OAUTH_TOKEN) || ''
})

export default applySentryBrowser
