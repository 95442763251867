/* globals fetch */

export default async function (url, options) {
  const response = await fetch(url, options)

  if (response.status >= 200 && response.status < 300) {
    return response.text()
  } else {
    const error = new Error(`${url}: ${response.statusText}`)
    error.statusCode = response.status
    try {
      error.response = await response.text()
    } catch (textError) {
      // response content could not be decoded, can be ignored
    }
    throw error
  }
}
