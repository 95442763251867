import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TextLabel from '../../../elements/text-label/text-label'
import DropdownList from '../../../elements/dropdown-list/dropdown-list'
import Icon from '../../../elements/icon/icon'
import Button from '../../../elements/button/button'
import Link from '../../../elements/link/link'
import Form from '../../../elements/form/form'
import kebabCase from 'lodash/kebabCase'
import _t from 's3p-js-lib/src/translate'

export default class SearchBookingForm extends Component {
  static propTypes = {
    categories: PropTypes.array.isRequired,
    handleGetBookings: PropTypes.func.isRequired,
    resetBookings: PropTypes.func.isRequired,
    bookings: PropTypes.array,
    loading: PropTypes.bool,
    form: PropTypes.shape({
      valid: PropTypes.bool,
      fields: PropTypes.shape({
        category: PropTypes.object,
        term: PropTypes.object
      })
    })
  }

  getCategoryLabel (value) {
    return value && _t.message(`bookings-filter.categories.${kebabCase(value)}`)
  }

  render () {
    return (
      <Form formContext='bookings-filter'>
        <div className='toolbar'>
          <div className='toolbar__item'>
            <Form.Label htmlFor='term' name='term' />
            <Form.TextField
              {...this.props.form.fields.term}
              name='term'
              id='term'
              autoFocus
            />
          </div>
          <div className='toolbar__item'>
            <Form.Label
              htmlFor='category'
              name='category'
              text={_t.message('bookings-filter.form.categories.label')}
            />
            <DropdownList
              {...this.props.form.fields.category}
              textField={this.getCategoryLabel}
              data={this.props.categories}
            />
          </div>
          <div className='toolbar__item'>
            <Button
              name='search-bookings'
              className='primary'
              onClick={this.props.handleGetBookings}
              disabled={!this.props.form.valid}
              loading={this.props.loading}
            >
              <TextLabel>
                <Icon name='search' className='align-left' />
                <TextLabel.Text text={_t.message('bookings-filter.form.search-bookings')} />
              </TextLabel>
            </Button>
          </div>
          {this.props.bookings
            ? <div className='toolbar__item'>
              <TextLabel
                name='results'
                text={_t.message('bookings-filter.form.results-found', {number: this.props.bookings.length})}
              />
              <Link>
                <TextLabel onClick={this.props.resetBookings} name='reset' text={_t.message('bookings-filter.form.reset')} />
              </Link>
            </div>
            : null }
        </div>
      </Form>
    )
  }
}
