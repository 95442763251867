import React, {Component} from 'react'
import ComponentContainer from '../../elements/component-container/component-container'
import Panel from '../../elements/panel/panel'
import Feedback, {FEEDBACK_ALERT} from '../../elements/feedback/feedback'
import _t from 's3p-js-lib/src/translate'
import './error.scss'

export default class PageNotFound extends Component {
  render () {
    return (
      <ComponentContainer name='application-error'>
        <Panel
          header={<Panel.Header
            name='application-error'
            title={_t.message('application-error.header')}
            description={_t.message('application-error.description')}
          />}
        >
          <Feedback status={FEEDBACK_ALERT} text={_t.message('application-error.page-not-found')} />
        </Panel>
      </ComponentContainer>
    )
  }
}
