import Client from '../../../../../api/client'
import ensureToken from 's3p-js-lib/src/api/ensure-token'
import {
  API_V2_GET_CALENDAR_FAILURE,
  API_V2_GET_CALENDAR_REQUEST,
  API_V2_GET_CALENDAR_SUCCESS
} from '../../../types-v2'

export const getCalendarRequest = data => ({type: API_V2_GET_CALENDAR_REQUEST, data})
export const getCalendarSuccess = response => ({type: API_V2_GET_CALENDAR_SUCCESS, response})
export const getCalendarFailure = error => ({type: API_V2_GET_CALENDAR_FAILURE, error})

export const getCalendar = data => ensureToken(async (token, dispatch) => {
  dispatch(getCalendarRequest(data))

  try {
    const response = await Client.getCalendar(token, data)
    dispatch(getCalendarSuccess(response))
  } catch (error) {
    dispatch(getCalendarFailure(error))
    throw error
  }
})
